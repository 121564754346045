import { ChangeEvent, memo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { formatDateDisplay, getToday } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import DatePicker from '@shared/ui/DatePicker'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type Props = {
  endAt?: string
  onChange: (endAt?: string) => void
}

const WorkflowEndAt = ({ endAt, onChange }: Props) => {
  const { t } = useTranslation(['workflow'])

  const handleOnChecked = (
    _e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) =>
    onChange(
      checked ? formatDateDisplay(getToday(), '-', 'YYYY/MM/DD') : undefined
    )

  const handleOnDateChange = (date?: Date) => {
    if (date) {
      onChange(formatDateDisplay(date, '-', 'YYYY/MM/DD'))
    }
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start">
      <FormControlLabel
        control={
          <Checkbox
            checked={Boolean(endAt)}
            color="primary"
            onChange={handleOnChecked}
          />
        }
        label={
          <Box component="span" fontSize={14} fontWeight={600}>
            {t('workflow:workflow_end_date_config')}
          </Box>
        }
      />
      <Box mr={2}>
        <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
      </Box>
      <DatePicker
        popperDisablePortal
        isDisabled={!Boolean(endAt)}
        value={new Date(endAt ?? getToday())}
        minDate={getToday()}
        isShowUnsetButton={false}
        onValueChanged={handleOnDateChange}
      />
    </Box>
  )
}

export default memo(WorkflowEndAt)
