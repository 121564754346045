const PROJECT_IKA_ID = 'PROJECT_IKA_ID'
const FIREBASE_AUTH_TIME = 'FIREBASE_AUTH_TIME'
const CDP_AUTH_TOKEN = 'CDP_AUTH_TOKEN'

const storage = {
  getProjectIKAId: () => {
    return window.localStorage.getItem(PROJECT_IKA_ID)
  },
  setProjectIKAId: (projectIKAId: string) => {
    window.localStorage.setItem(PROJECT_IKA_ID, projectIKAId)
  },
  removeProjectIKAId: () => {
    window.localStorage.removeItem(PROJECT_IKA_ID)
  },
  getFirebaseAuthTime: () => {
    return window.localStorage.getItem(FIREBASE_AUTH_TIME)
  },
  setFirebaseAuthTime: (authTime: string) => {
    window.localStorage.setItem(FIREBASE_AUTH_TIME, authTime)
  },
  removeFirebaseAuthTime: () => {
    window.localStorage.removeItem(FIREBASE_AUTH_TIME)
  },

  // CDP Token 僅存在單次 Session 過程
  getCDPAuthToken: () => {
    return window.sessionStorage.getItem(CDP_AUTH_TOKEN)
  },
  setCDPAuthToken: (token: string) => {
    window.sessionStorage.setItem(CDP_AUTH_TOKEN, token)
  },
  removeCDPAuthToken: () => {
    window.sessionStorage.removeItem(CDP_AUTH_TOKEN)
  },
}

export default storage
