import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import {
  INITIAL_PAGINATION_RESPONSE,
  ProductAigc,
  useGetProductsKeywordsAigcListProductsQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'

const SCROLL_DEBOUNCE_TIME = 2000
const PER_PAGE = DEFAULT_PER_PAGE * 2

type ReturnType = {
  aigcProductList: ProductAigc[]
  isFetching: boolean
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  searchName: string
  totalCount: number
  onSearchByName: (value: string) => void
  hasMore: boolean
  onLoadMore: () => void
}

const useAigcProductList = (): ReturnType => {
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [aigcProductList, setAigcProductList] = useState<ProductAigc[]>([])
  const [searchName, setSearchName] = useState<string>('')

  const {
    data: aigcListData = INITIAL_PAGINATION_RESPONSE,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useGetProductsKeywordsAigcListProductsQuery({
    pagination: { page, perPage: PER_PAGE },
    name: searchName,
  })

  const pageLimit = Math.floor(aigcListData.totalCount / PER_PAGE)
  const hasMore = !isLoading && page < pageLimit && aigcListData.totalCount > 0

  useEffect(() => {
    setAigcProductList([])
    setPage(DEFAULT_PAGE)
  }, [searchName])

  useEffect(() => {
    setAigcProductList(prev => [...prev, ...aigcListData.items])
  }, [aigcListData])

  const onLoadMore = useMemo(
    () =>
      debounce(() => {
        if (!hasMore) {
          return
        }

        setPage(page + 1)
      }, SCROLL_DEBOUNCE_TIME),
    [hasMore, page]
  )

  const onSearchByName = (value: string) => setSearchName(value)

  return {
    aigcProductList,
    isFetching,
    isLoading,
    isError,
    isSuccess,
    totalCount: aigcListData.totalCount,
    hasMore,
    searchName,
    onSearchByName,
    onLoadMore,
  }
}

export default useAigcProductList
