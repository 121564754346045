import camelcaseKeys from 'camelcase-keys'
import round from 'lodash/round'
import snakecaseKeys from 'snakecase-keys'

import {
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
} from '..'
import { api } from '../api'
import type {
  ChartDataFromApi,
  ChartDataWithEventIndex,
  EventRule,
  EventRuleCreatePayload,
  EventRuleExport,
  EventRuleNode,
  EventRuleUpdatePayload,
  PrivacyLevel,
} from './eventType'

export const transformChartData = (
  dataFromApi: ChartDataFromApi | { error: string }
): ChartDataWithEventIndex[] => {
  if ('error' in dataFromApi) {
    return []
  }

  const chartData: ChartDataWithEventIndex[] = []
  dataFromApi.measure_statistics.forEach(item => {
    chartData.push({
      eventIndex: item.measure_index,
      categories: [...dataFromApi.time_serials],
      series: item.statistics.map(({ group_by_values, values }) => ({
        // 多 map(String) 是為了讓 [null, 'ABC'] 能夠顯示為 "null - ABC"
        name: group_by_values.map(String).join(' - '),
        data: values.map(x => round(x, 2)),
      })),
    })
  })

  return chartData
}

const eventAnalyticsApi = api.injectEndpoints({
  endpoints: builder => ({
    getEventRuleSummary: builder.query<
      ChartDataWithEventIndex[],
      EventRuleNode
    >({
      query: payload => ({
        url: '/event_rules/preview_statistic_summary',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      transformResponse: transformChartData,
    }),
    getEventRuleList: builder.query<
      PaginationResponse<EventRule>,
      PaginationParams & { privacyLevel: PrivacyLevel; groupId: number }
    >({
      query: ({ page, perPage, privacyLevel, groupId }) => ({
        url: `/event_rules?event_rule_type=${privacyLevel}&page=${page}&per_page=${perPage}&group_id=${groupId}`,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: response =>
        camelcaseKeys<PaginationResponse<EventRule>>(
          response as PaginationResponse<EventRule>,
          {
            deep: true,
          }
        ),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'EventRuleList' as const,
                id,
              })),
              { type: 'EventRuleList', id: 'LIST' },
            ]
          : [{ type: 'EventRuleList', id: 'LIST' }],
    }),
    getEventRule: builder.query<EventRule, number>({
      query: id => ({
        url: `/event_rules/${id}`,
      }),
      transformResponse: response =>
        camelcaseKeys<EventRule>(response as EventRule, {
          deep: true,
        }),
      providesTags: (result, error, id) => [{ type: 'EventRuleList', id }],
    }),
    createEventRule: builder.mutation<void, EventRuleCreatePayload>({
      query: payload => ({
        url: '/event_rules',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: [{ type: 'EventRuleList', id: 'LIST' }],
    }),
    updateEventRule: builder.mutation<void, EventRuleUpdatePayload>({
      query: payload => ({
        url: `/event_rules/${payload.id}`,
        method: 'PUT',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: (result, error, payload) => [
        { type: 'EventRuleList', id: 'LIST' },
        { type: 'EventRuleList', id: payload.id },
      ],
    }),
    deleteEventRule: builder.mutation<void, number>({
      query: id => ({
        url: `/event_rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'EventRuleList', id: 'LIST' }],
    }),
    exportEventRuleCSV: builder.query<
      EventRuleExport,
      Omit<EventRuleNode, 'topN'>
    >({
      query: payload => ({
        url: `/event_rules/export`,
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      transformResponse: response =>
        camelcaseKeys(response as EventRuleExport, { deep: true }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetEventRuleSummaryQuery,
  useLazyGetEventRuleSummaryQuery,
  useGetEventRuleListQuery,
  useGetEventRuleQuery,
  useCreateEventRuleMutation,
  useUpdateEventRuleMutation,
  useDeleteEventRuleMutation,
  useLazyExportEventRuleCSVQuery,
} = eventAnalyticsApi
