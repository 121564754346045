import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { PAGE_ROOT } from '@shared/model/constants/routes'

import ApplicationRoutes from './application/ApplicationRoutes'
import ThirdPartyRoutes from './thirdParty/ThirdPartyRoutes'

export const SettingsRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.apiTokens}`}>
        <ApplicationRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.thirdParty}`}>
        <ThirdPartyRoutes />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.apiTokens}`} />
    </Switch>
  )
}

export default SettingsRoutes
