import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'

import { setGoogleAuth } from '@entities/auth/model/slices'
import { useLazyGetGoogleOAuthQuery } from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks/store'
import { openToast } from '@shared/model/slices'

import useIsValid from '../_shared/utils/useIsValid'

export const useGoogleOAuth = () => {
  const [getGoogleOAuth] = useLazyGetGoogleOAuthQuery()

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['common'])

  const login = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const googleAuth = await getGoogleOAuth({
        authorizationCode: code,
      }).unwrap()

      dispatch(setGoogleAuth(googleAuth))
    },
    onError: () => {
      dispatch(
        openToast({
          message: t('common:api_error.409_unsupported'),
          status: 'error',
        })
      )
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/adwords',
  })

  const refreshToken = useAppSelector(state => state.auth.google.refreshToken)

  const accessToken = useAppSelector(state => state.auth.google.accessToken)

  const expirationTime = useAppSelector(
    state => state.auth.google.expirationTime
  )

  const isAccessTokenValid = useIsValid(expirationTime)

  const auth = useMemo(
    () => ({ accessToken, refreshToken, isAccessTokenValid }),
    [accessToken, isAccessTokenValid, refreshToken]
  )

  return [auth, { login }] as const
}

export default useGoogleOAuth
