import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import type { ProductAigc } from '@shared/api/rtkQuery'
import ConfirmDialog from '@shared/ui/dialogs/ConfirmDialog'
import InfiniteScrollLoader from '@shared/ui/loaders/InfiniteScrollLoader'
import { SearchRoundedBar } from '@shared/ui/searchInput'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSelect: (values: {
    isSelectAll: boolean
    selectedList: ProductAigc[]
  }) => void
  defaultValues?: ProductAigc[]
  isSelectAll?: boolean
  data: ProductAigc[]
  hasMore: boolean
  onLoadMore: () => void
  searchName: string
  onSearchByName: (value: string) => void
  isFetching: boolean
  isLoading: boolean
}

const AigcProductsDialog = ({
  isOpen = false,
  onClose,
  onSelect,
  defaultValues = [],
  isSelectAll = false,
  data,
  hasMore,
  onLoadMore,
  searchName,
  onSearchByName,
  isFetching,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['common'])
  const [selectedList, setSelectedList] = useState<ProductAigc[]>(defaultValues)
  const [isSelectAllProducts, setIsSelectAllProducts] =
    useState<boolean>(isSelectAll)

  const handleOnSearch = (text: string) => onSearchByName(text)

  const handleOnConfirm = () => {
    onSelect({
      isSelectAll: isSelectAllProducts,
      selectedList,
    })

    onClose()
  }

  const handleOnCancel = () => onClose()

  return (
    <ConfirmDialog
      modalTitle={t('common:dropdownlist_default')}
      isOpen={isOpen}
      onConfirm={handleOnConfirm}
      onClose={handleOnCancel}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="48px"
        mb={3}
        sx={theme => ({
          borderBottom: `1px solid ${theme.colors.black6}`,
        })}
      >
        <SearchRoundedBar
          defaultValue={searchName}
          onSearch={handleOnSearch}
          placeholder={t('common:search')}
        />
      </Box>
      <Box>
        <Button
          disabled={isFetching || Boolean(searchName)}
          disableElevation
          disableFocusRipple
          disableRipple
          fullWidth
          variant="text"
          sx={theme => ({
            lineHeight: '18px',
            fontSize: '14px',
            fontWeight: '600',
            color: theme.colors.white,
            backgroundColor: theme.colors.brightBlue,
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: theme.colors.brightBlue,
            },
            '&:disabled': {
              backgroundColor: theme.colors.lightGreyBlue,
              color: theme.colors.white,
            },
          })}
          onClick={() => {
            setSelectedList([])
            setIsSelectAllProducts(!isSelectAllProducts)
          }}
        >
          {t('common:select_all')}
        </Button>
      </Box>

      <Box mt={3} maxHeight={300}>
        {data.map(product => {
          const isSelectedItem = Boolean(
            selectedList.find(p => p.productId === product.productId)
          )

          const isSelected = isSelectAllProducts || isSelectedItem

          return (
            <Box
              display="inline-block"
              textAlign="left"
              m={0.5}
              px={1.5}
              py={0.5}
              key={product.productId}
              minHeight="24px"
              fontWeight={600}
              fontSize={14}
              lineHeight="18px"
              sx={theme => ({
                backgroundColor: isSelected
                  ? theme.colors.textPrimaryBlue
                  : theme.colors.bgPaleGrey,
                cursor: 'pointer',
                color: isSelected
                  ? theme.colors.white
                  : theme.colors.brightBlue,
              })}
              onClick={() => {
                setIsSelectAllProducts(false)

                setSelectedList(prev =>
                  isSelectedItem
                    ? prev.filter(p => p.productId !== product.productId)
                    : [...prev, product]
                )
              }}
            >
              {product.name}
            </Box>
          )
        })}
        {isLoading && data.length === 0 && (
          <Box width="100%" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {hasMore && (
          <InfiniteScrollLoader isLoading={isFetching} loadMore={onLoadMore} />
        )}
      </Box>
    </ConfirmDialog>
  )
}

export default AigcProductsDialog
