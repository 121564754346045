import { Route, useRouteMatch } from 'react-router-dom'

import DetailInsightDataTable from './DetailInsightDataTable'
import OverviewDataTable from './OverviewDataTable'

const WorkflowsInsight = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Route exact path={`${path}/:id(\\d+)`}>
        <DetailInsightDataTable />
      </Route>

      <Route exact path={path}>
        <OverviewDataTable />
      </Route>
    </>
  )
}

export default WorkflowsInsight
