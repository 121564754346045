import * as amplitude from '@amplitude/analytics-browser'

import type {
  EventName,
  EventPropertiesMapped,
} from '@shared/model/types/amplitude'

export * from '@shared/model/types/amplitude'

export const initAmplitude = () => {
  amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    defaultTracking: true,
  })
}

type AmplitudeIdentify = {
  email: string
  lang: string
  projectId: string
  projectName: string
  userId: string
}

export const setIdentify = ({
  email,
  lang,
  projectId,
  projectName,
  userId,
}: AmplitudeIdentify) => {
  const identifyEvent = new amplitude.Identify()

  identifyEvent.set('email', email)
  identifyEvent.set('lang', lang)
  identifyEvent.set('projectId', projectId)
  identifyEvent.set('projectName', projectName)
  identifyEvent.set('userId', userId)

  amplitude.identify(identifyEvent)
}

export const trackEvent = <T extends EventName>(
  eventName: T,
  eventProperties?: EventPropertiesMapped[T]
) => {
  amplitude.track(
    // 將字串內的大寫字母前加上空格 ex. 'AudienceSearched' => 'Audience Searched'
    // 因為前者格式在程式碼比較容易複製 or 搜尋，而實際事件是用後者格式
    eventName.replace(/([A-Z])/g, ' $1').trim(),
    eventProperties
  )
}
