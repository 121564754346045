import { combineReducers } from '@reduxjs/toolkit'

import applicationEpics from './application/epics'
import applicationReducer, { ApplicationAction } from './application/slice'

export const epics = [...applicationEpics]

export type SettingsAction = ApplicationAction

export default combineReducers({
  application: applicationReducer,
})
