import styled from '@emotion/styled'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'

import { getTableCellPaddingRight } from './commonStyle'

type TableBodyCellProps = {
  isFirstRowCell?: boolean
  isLastRowCell?: boolean
  isNoWrap?: boolean
  isAlignRight?: boolean
  isAlignCenter?: boolean
  isDisabledStyle?: boolean
  isClickable?: boolean
  isWordBreakAll?: boolean
  paddingTop?: number
  paddingBottom?: number
}

const TableBodyCell = styled(
  ({
    isFirstRowCell,
    isLastRowCell,
    isNoWrap,
    isAlignRight,
    isAlignCenter,
    isDisabledStyle,
    isClickable,
    isWordBreakAll,
    paddingTop,
    paddingBottom,
    ...otherProps
  }: TableBodyCellProps & TableCellProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiTableCell {...otherProps} />
  )
)`
  && {
    padding-right: ${({ isLastRowCell, isAlignRight }) =>
      getTableCellPaddingRight(isLastRowCell, isAlignRight)}px;
    padding-left: ${({ isFirstRowCell }) => (isFirstRowCell ? 60 : 16)}px;
    ${isClickable => isClickable && `pointer-events: auto;`}
    border-bottom: none;

    color: ${({ isDisabledStyle, theme }) =>
      isDisabledStyle ? theme.colors.blueyGrey : theme.colors.textPrimaryBlue};
    font-size: 14px;
    font-family: inherit;
    white-space: ${({ isNoWrap }) => (isNoWrap ? 'nowrap' : 'normal')};
    word-break: ${({ isWordBreakAll }) =>
      isWordBreakAll ? 'break-all' : 'normal'};

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);

    transition: 0.25s;

    ${({ isClickable, theme }) =>
      isClickable &&
      `
        :hover {
          color: ${theme.colors.brightBlue};
          cursor: pointer;
        }
    `}

    ${({ isAlignCenter }) =>
      isAlignCenter &&
      `
        display: flex;
        justify-content: center;
    `}
  }

  &.MuiTableCell-root {
    padding-top: ${({ paddingTop }) => (paddingTop || 2) * 8}px;
    padding-bottom: ${({ paddingBottom }) => (paddingBottom || 2) * 8}px;
  }
`

export const TableBodyCellChild = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const TableCollapseCell = styled(
  ({
    isAllowHover,
    ...otherProps
  }: { isAllowHover?: boolean } & TableBodyCellProps & TableCellProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TableBodyCell {...otherProps} />
  )
)`
  background-color: ${({ theme }) => theme.colors.whiteTwo};

  &.MuiTableCell-root {
    padding: 0;
    box-shadow: ${({ theme }) => theme.colors.black6} 0px 1px 0px 0px inset;
  }

  ${({ isAllowHover, theme }) =>
    isAllowHover &&
    `
    .MuiTableCell-root:hover {
      background-color: ${theme.colors.dodgerBlue5};
    }
  `}
`

export default TableBodyCell
