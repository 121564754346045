import styled from '@emotion/styled'
import MuiAppBar from '@mui/material/AppBar'
import MuiButton from '@mui/material/Button'
import MuiToolbar from '@mui/material/Toolbar'

import { LAYOUT } from '@shared/model/constants/styles'

export const AppBar = styled(MuiAppBar)`
  && {
    background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
    box-shadow: none;
    transition: width 200ms cubic-bezier(0.4, 0, 0.6, 1);
  }
`

export const Toolbar = styled(MuiToolbar)`
  && {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding-right: ${LAYOUT.contentRightSideGap}px;
    padding-left: ${LAYOUT.contentLeftSideGap}px;
  }
`

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  opacity: 0.8;
`

export const ChosenValueText = styled.div`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.brightBlue};
`

export const ExpandIconWrapper = styled.div`
  display: flex;
  margin-left: 6px;
`

export const Button = styled(MuiButton)`
  && {
    padding: 0 16px;
    padding-right: 8px;

    height: 42px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.white};

    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;

    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.05);

    :hover {
      background-color: ${({ theme }) => theme.colors.lightGreyBlueTwo};
    }
  }
`

export const ActionIconsButton = styled(MuiButton)`
  && {
    display: flex;
    align-items: center;

    margin-right: 12px;
    width: 72px;
    height: 36px;
    border-radius: 18px;

    color: ${({ theme }) => theme.colors.lightNavyBlue};
    white-space: nowrap;

    :hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`
