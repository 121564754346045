import { api, transformResponseCamelCaseKeys } from '@shared/api/rtkQuery'

import {
  ConsumptionSummaryResponse,
  SearchUserParams,
  UserJourneyEvent,
  UserOverviewValue,
  UserProfile,
  UserProfileEventReport,
  UserProfileTagCategory,
} from './type'

const userProfile = api.injectEndpoints({
  endpoints: build => ({
    searchUser: build.query<UserOverviewValue[], SearchUserParams>({
      query: params => ({
        url: '/user_profile/users',
        params,
      }),
      transformResponse: (data: UserOverviewValue[]) =>
        transformResponseCamelCaseKeys(data),
    }),
    getUserProfile: build.query<UserProfile, { cdpUserId: string }>({
      query: params => ({
        url: '/user_profile/profile',
        params,
      }),
      transformResponse: (data: UserProfile) =>
        transformResponseCamelCaseKeys(data),
    }),
    getConsumptionSummary: build.query<
      ConsumptionSummaryResponse,
      { cdpUserId: string }
    >({
      query: params => ({
        url: '/user_profile/consumption_summary',
        params,
      }),
      transformResponse: (data: ConsumptionSummaryResponse) =>
        transformResponseCamelCaseKeys(data),
    }),
    getUserProfileEventReport: build.query<
      UserProfileEventReport,
      { cdpUserId: string }
    >({
      query: params => ({
        url: `/user_profile/event_report`,
        params,
      }),
      transformResponse: (data: UserProfileEventReport) =>
        transformResponseCamelCaseKeys(data),
    }),
    getUserProfileTagCategories: build.query<
      UserProfileTagCategory[],
      { cdpUserId: string }
    >({
      query: params => ({
        url: `/user_profile/tags`,
        params,
      }),
      transformResponse: (data: UserProfileTagCategory[]) =>
        transformResponseCamelCaseKeys(data),
    }),
    getUserJourneyEvents: build.query<
      UserJourneyEvent['events'],
      { cdpUserId: string }
    >({
      query: params => ({
        url: `/user_profile/trajectory`,
        params,
      }),
      transformResponse: (data: UserJourneyEvent) =>
        transformResponseCamelCaseKeys(data.events),
    }),
  }),
})

export const {
  useSearchUserQuery,
  useGetUserProfileQuery,
  useGetConsumptionSummaryQuery,
  useGetUserProfileEventReportQuery,
  useGetUserProfileTagCategoriesQuery,
  useGetUserJourneyEventsQuery,
} = userProfile
