import curry from 'lodash/fp/curry'

const filter = <T extends {}>(
  filterOptions: {
    field: keyof T
    value: T[keyof T]
  },
  data: T[]
) => data.filter(item => item[filterOptions.field] === filterOptions.value)

export const filterByKey = curry(filter)
