import { useEffect } from 'react'

import {
  resetCurrIngestionId,
  selectCurrIngestion,
} from '@features/infoBox/model/taskSlice'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'

type UseRefetchIngestionListProps = {
  refetch: () => void
}

export const useRefetchIngestionList = ({
  refetch,
}: UseRefetchIngestionListProps) => {
  const dispatch = useAppDispatch()
  const currIngestion = useAppSelector(selectCurrIngestion)

  useEffect(() => {
    return () => {
      dispatch(resetCurrIngestionId())
    }
  }, [dispatch])

  useEffect(() => {
    if (currIngestion?.status === 'succeeded') {
      refetch()
    }
  }, [currIngestion?.status, refetch])
}

export default useRefetchIngestionList
