import type { TagProps } from '@shared/api/rtkQuery'

import { status, updateMethod } from '../../_shared/constant'
import type { Filter, UpdateWayType } from '../../_shared/type'

export const filterBySearchText = (item: TagProps, search: string) =>
  !search || item.title.includes(search) || item.description.includes(search)

export const filterByStatus = (item: TagProps, filter: Filter['status']) => {
  if (!filter || !filter.length) {
    return true
  }

  return filter.some(ele => status[ele].includes(item.latestJobStatus))
}

export const filterByCreateWay = (
  item: TagProps,
  filter: Filter['createMethod']
) => {
  if (!filter || !filter.length) {
    return true
  }

  return filter.some(ele => ele === item.type)
}

export const filterByUpdateWay = (
  item: TagProps,
  filter: Filter['updateMethod']
) => {
  if (!filter || !filter.length) {
    return true
  }

  return filter.some(ele => {
    const condition = updateMethod[ele as UpdateWayType]

    return (
      condition.schedulingType === item.schedulingType &&
      (!condition.type || condition.type === item.type)
    )
  })
}
