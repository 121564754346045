import styled from '@emotion/styled'

import BaseCard from '@shared/ui/Card'

export const GreyBG = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 40px;
  padding: 16px 20px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.bgIceBlue};

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

export const TimeText = styled.div<{ isWarning?: boolean }>`
  margin-left: 4px;
  color: ${({ isWarning, theme }) =>
    isWarning ? theme.colors.chartRed : theme.colors.brightBlue};
`

export const Card = styled(BaseCard)`
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`
