import { useTranslation } from 'react-i18next'

export const useTagSourceOption = () => {
  const { t } = useTranslation(['tag'])

  return [
    {
      type: 'grading' as const,
      name: t('tag:tag_source.grading'),
    },
    {
      type: 'behavior' as const,
      name: t('tag:tag_source.behavior'),
    },
    {
      type: 'intelligent' as const,
      name: t('tag:tag_source.intelligent'),
    },
    {
      type: 'thirdParty' as const,
      name: t('tag:tag_source.third_party'),
    },
  ]
}
