import { useMemo } from 'react'
import styled from '@emotion/styled'

import { LAYOUT } from '@shared/model/constants/styles'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${LAYOUT.copyrightMarginVertical}px 0;

  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
  font-family: inherit;
`

const Copyright = () => {
  const year = useMemo(() => new Date().getFullYear(), [])

  return <Wrapper>Copyright © {year} iKala All Rights Reserved.</Wrapper>
}

export default Copyright
