import { api, transformResponseCamelCaseKeys } from '../api'
import type { Rfm, RfmListItem, RfmUserGroupsResponse } from './rfmType'

export const INIT_RFM_DATA: Rfm = {
  conditionDisplayName: '',
  days: 0,
  orderColumn: '',
  orderEvents: [],
  rawEvents: [],
  rfmDisplayName: '',
  rfmGroups: [],
  source: '',
}

const rfmApi = api.injectEndpoints({
  endpoints: builder => ({
    rfmList: builder.query<RfmListItem[], void>({
      query: () => ({
        url: `/rfm`,
        method: 'GET',
      }),
      transformResponse: (res: RfmListItem[]) =>
        transformResponseCamelCaseKeys(res),
      providesTags: ['Rfm'],
    }),
    getRfm: builder.query<Rfm, number>({
      query: id => `/rfm/${id}`,
      transformResponse: (res: Rfm) => transformResponseCamelCaseKeys(res),
      providesTags: (result, error, id) => [{ type: 'Rfm', id }],
    }),
    getRfmUserGroups: builder.query<
      RfmUserGroupsResponse,
      { cdpUserId: string }
    >({
      query: params => ({
        url: `/rfm/user_groups`,
        params,
      }),
      transformResponse: (res: RfmUserGroupsResponse) =>
        transformResponseCamelCaseKeys(res),
    }),
  }),
  overrideExisting: false,
})

export const { useRfmListQuery, useGetRfmQuery, useGetRfmUserGroupsQuery } =
  rfmApi
