import { useCallback } from 'react'
import { useImmerReducer } from 'use-immer'

import type { AudienceFilterNode } from '@shared/api/rtkQuery'

import {
  createInitialState,
  initializer,
  reducer,
} from '../audienceFilterReducer'

export const useAudienceFilter = (
  initialState: AudienceFilterNode = createInitialState()
) => {
  const [state, dispatch] = useImmerReducer(reducer, initialState, initializer)

  const onReset = useCallback(
    (payload?: AudienceFilterNode) => {
      dispatch({ type: 'reset', payload })
    },
    [dispatch]
  )

  return { values: state, onDispatch: dispatch, onReset }
}

export default useAudienceFilter
