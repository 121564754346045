import { useTranslation } from 'react-i18next'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import type { ReplacementDataMetadata } from '@shared/api/rtkQuery'
import {
  PREDEFINED_METADATA_TAG_ID_LIST,
  PredefinedMetadataTagId,
} from '@shared/model/constants/metadata'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'

import {
  MetadataTagOption,
  useSmsMetadataTagOptionList,
} from '../_shared/hooks'
import { RoundedButton } from '../_shared/styles'
import { INSERT_METADATA_TAG_COMMAND } from '../metadataTag/MetadataTagPlugin'

const MetadataTag = (): JSX.Element => {
  const [editor] = useLexicalComposerContext()
  const editorIsEditable = editor.isEditable()
  const { t } = useTranslation(['contentTemplate'])

  const metadataTagOptions = useSmsMetadataTagOptionList()

  const handleMetadataTagOptionClick = (
    option: Pick<MetadataTagOption, 'value'>
  ) => {
    editor.update(() => {
      const name = metadataTagOptions.find(x => x.value === option.value)?.label

      if (name) {
        const replacementDataKey = option.value
        const replacementDataType: ReplacementDataMetadata['type'] =
          PREDEFINED_METADATA_TAG_ID_LIST.includes(
            replacementDataKey as PredefinedMetadataTagId
          )
            ? 'unpurchased_products'
            : 'metadata'

        editor.dispatchCommand(INSERT_METADATA_TAG_COMMAND, {
          displayName: name,
          replacementKey: replacementDataKey,
          replacementData: {
            name: replacementDataKey,
            type: replacementDataType,
          },
        })
      }
    })
  }

  return (
    <NestedList
      anchorElem={
        <RoundedButton color="primary" disabled={!editorIsEditable}>
          <Icon icon={ICON.plusCircle} fontSize="small" color="inherit" />
          &nbsp;
          {t('contentTemplate:merge_tag')}
        </RoundedButton>
      }
      hasSearchBar
      isDisabled={!editorIsEditable}
      onChange={handleMetadataTagOptionClick}
      options={metadataTagOptions}
      popperClassName="nodrag"
      popperMaxHeight={140}
      popperWidth={340}
      popperZIndex={Z_INDEX.dialog}
    />
  )
}

export default MetadataTag
