import Box from '@mui/material/Box'

import theme from '@theme'

type Props = {
  children: React.ReactNode
}

const ChartWrapper = ({ children }: Props) => (
  <Box
    width="100%"
    sx={{
      '.audience-tooltip-wrapper': {
        display: 'flex',
        p: 1.5,
        backgroundColor: theme.colors.blackTwo,
        color: theme.colors.white,
      },

      '.audience-tooltip-column': {
        display: 'flex',
        flexDirection: 'column',
      },

      '.audience-tooltip-row': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mr: 1,
        height: '20px',
      },

      '.audience-tooltip-circle': {
        mr: 1,
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: theme.colors.white,
      },

      '.audience-tooltip-name': {
        overflow: 'hidden',
        pr: 1,
        maxWidth: '360px',
        height: '20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '.audience-tooltip-value': {
        ml: 1.5,
        height: '20px',
        textAlign: 'right',
      },

      '.audience-tooltip-value-right': {
        ml: 0,
        textAlign: 'right',
      },
    }}
  >
    {children}
  </Box>
)

export default ChartWrapper
