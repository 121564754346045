import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import type { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'

import {
  Dialog,
  DIALOG_PROGRESS_HEIGHT,
  DialogActions,
  DialogContent,
} from './base'

type Props = {
  className?: string
  confirmText?: string
  cancelText?: string
  contentOverflowY?: 'visible' | 'hidden' | 'scroll' | 'auto'
  contentStyles?: React.CSSProperties
  children?: React.ReactNode
  hasCancelBtn?: boolean
  hasConfirmBtn?: boolean
  isLoading?: boolean
  isOpen: boolean
  keepMounted?: boolean
  maxWidth?: DialogProps['maxWidth']
  modalTitle?: React.ReactNode
  onClose?: () => void
  onConfirm?: () => void
  scroll?: 'body' | 'paper'
}

export const ConfirmDialog = ({
  children,
  className,
  confirmText,
  cancelText,
  contentOverflowY = 'auto',
  contentStyles,
  hasCancelBtn = true,
  hasConfirmBtn = true,
  isLoading = false,
  isOpen,
  keepMounted,
  maxWidth = 'sm',
  modalTitle = '',
  onClose = () => {},
  onConfirm: handleConfirm = () => {},
  scroll = 'paper',
}: Props) => {
  const { t } = useTranslation('common')

  const handleClose = () => {
    if (!hasCancelBtn || isLoading) {
      return
    }

    onClose()
  }

  return (
    <Dialog
      className={className}
      // 設定 disableEnforceFocus 讓焦點能夠轉移至內部其他元件 (ex. 文字輸入框)
      // 文件提到一般情況不建議開啟，會使網頁閱讀器的可用性降低
      // ref: https://v4.mui.com/zh/api/modal/
      disableEnforceFocus
      fullWidth
      keepMounted={keepMounted}
      maxWidth={maxWidth}
      PaperProps={{ style: contentStyles }}
      onClose={handleClose}
      open={isOpen}
      overflowY={contentOverflowY}
      scroll={scroll}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box fontSize={16} mt={1}>
            {modalTitle}
          </Box>
          {hasCancelBtn && (
            <Box mx={1}>
              <IconButton onClick={handleClose}>
                <Icon icon={ICON.multiply} />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogTitle>
      {children && (
        <DialogContent overflowY={contentOverflowY}>{children}</DialogContent>
      )}

      <DialogActions>
        {hasCancelBtn && (
          <Button color="inherit" onClick={handleClose}>
            {cancelText ?? t('cancel')}
          </Button>
        )}

        {hasConfirmBtn && (
          <Button color="primary" disabled={isLoading} onClick={handleConfirm}>
            {confirmText ?? t('confirm')}
          </Button>
        )}
      </DialogActions>
      <Box height={DIALOG_PROGRESS_HEIGHT}>
        {isLoading && <LinearProgress />}
      </Box>
    </Dialog>
  )
}

export default ConfirmDialog
