import cloneDeep from 'lodash/cloneDeep'
import curry from 'lodash/fp/curry'

import { TRACING_URL_REGEX } from '@entities/mediumTemplates'
import type { ReplacementData, TracingUrl } from '@shared/api/rtkQuery'
import getTracingUrlId from '@shared/lib/utils/getTracingUrlId'
import { getIntSafe } from '@shared/lib/utils/number'

export const fetchTracingUrl = curry(
  (
    html: string,
    tracingUrlList: TracingUrl[],
    replacementData: ReplacementData
  ) => {
    let tracingUrlIdList: number[] = []

    // 取出所有 tracing url id
    const matchAll = html.matchAll(TRACING_URL_REGEX)

    for (const [, id] of matchAll) {
      tracingUrlIdList.push(getIntSafe(id))
    }

    // 去除重複
    tracingUrlIdList = [...new Set(tracingUrlIdList)]

    const replacementDataCloned = cloneDeep(replacementData)

    tracingUrlIdList.forEach(tracingUrlId => {
      const tracingUrl = tracingUrlList.find(item => item.id === tracingUrlId)

      if (!tracingUrl) {
        return
      }

      replacementDataCloned[getTracingUrlId(tracingUrlId)] = {
        type: 'tracing_url',
        urlParams: {
          tracingUrlId,
          name: tracingUrl.name,
          url: tracingUrl.url,
        },
      }
    })

    return replacementDataCloned
  }
)

export default fetchTracingUrl
