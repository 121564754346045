import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  ReactFlowInstance,
} from 'reactflow'
import cloneDeep from 'lodash/cloneDeep'

import {
  createNewEdge,
  createNewNode,
  NodeType,
  Workflow,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'

import { NODE_HEADER_HEIGHT, NODE_WIDTH } from '../../nodes/_shared/constants'
import { getLayoutedElements } from './utils'

export type BasicNodeOperation<T> = {
  onChange: (values: Partial<T>) => void
  onDelete: () => void
  onDeleteEdges: (type: 'source' | 'target') => void
  onNodeTypeChange: (type: NodeType) => void
  triggerId?: number
  usersInNode?: number
} & T

export type EdgeData = {
  onDelete?: () => void
}

const INIT_TRIGGER_NODE_POSITION = { x: 0, y: 0 }

export const connectionLimitMap = new Map<string, NodeType>([
  ['sms', 'action-send_sms'],
  ['email', 'action-send_email'],
  ['line_push', 'action-send_line_push'],
])

export const useWorkflow = (
  initFormValues: Workflow | undefined,
  setFormValues: React.Dispatch<React.SetStateAction<Workflow>>,
  reactFlowWrapper: React.RefObject<HTMLElement>,
  reactFlowInstance?: ReactFlowInstance
) => {
  const createOnNodeDataChange = useCallback(
    (id: string) => (values: Record<string, unknown>) => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nodes: prevFormValues.nodes.map(n => {
          if (n.id !== id) {
            return n
          }

          return {
            ...n,
            data: {
              ...n.data,
              ...cloneDeep(values),
            },
          }
        }),
      }))
    },
    [setFormValues]
  )

  const createOnNodeTypeChange = useCallback(
    (id: string) => (nodeType: NodeType) => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nodes: prevFormValues.nodes.map(n => {
          if (n.id !== id) {
            return n
          }

          return {
            ...n,
            type: nodeType,
          }
        }),
      }))
    },
    [setFormValues]
  )

  const createOnNodeDelete = useCallback(
    (id: string) => () => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nodes: prevFormValues.nodes.filter(n => n.id !== id),
        edges: prevFormValues.edges.filter(
          e => e.source !== id && e.target !== id
        ),
      }))
    },
    [setFormValues]
  )

  const createOnDeleteEdge = useCallback(
    (id: string) => (edgeType: 'source' | 'target') => {
      switch (edgeType) {
        case 'source':
          setFormValues(prevFormValues => ({
            ...prevFormValues,
            edges: prevFormValues.edges.filter(e => e.source !== id),
          }))
          break
        case 'target':
          setFormValues(prevFormValues => ({
            ...prevFormValues,
            edges: prevFormValues.edges.filter(e => e.target !== id),
          }))
          break
        default:
          break
      }
    },
    [setFormValues]
  )

  const applyNodeOperation = useCallback(
    (node: Node): Node<BasicNodeOperation<unknown>> => ({
      ...node,
      data: {
        ...node.data,
        onChange: createOnNodeDataChange(node.id),
        onDelete: createOnNodeDelete(node.id),
        onDeleteEdges: createOnDeleteEdge(node.id),
        onNodeTypeChange: createOnNodeTypeChange(node.id),
      },
    }),
    [
      createOnDeleteEdge,
      createOnNodeDataChange,
      createOnNodeDelete,
      createOnNodeTypeChange,
    ]
  )

  const createOnEdgeDelete = useCallback(
    (id: string) => () => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        edges: prevFormValues.edges.filter(e => e.id !== id),
      }))
    },
    [setFormValues]
  )

  const applyEdgeOperation = useCallback(
    (edge: Edge): Edge<EdgeData> => ({
      ...edge,
      data: {
        ...edge.data,
        onDelete: createOnEdgeDelete(edge.id),
      },
    }),
    [createOnEdgeDelete]
  )

  const onLayout = useCallback(() => {
    setFormValues(prevFormValues => {
      return {
        ...prevFormValues,
        ...getLayoutedElements(prevFormValues.nodes, prevFormValues.edges),
      }
    })
  }, [setFormValues])

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nodes: applyNodeChanges(changes, prevFormValues.nodes),
      }))
    },
    [setFormValues]
  )

  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        edges: applyEdgeChanges(changes, prevFormValues.edges),
      }))
    },
    [setFormValues]
  )

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['workflow'])

  const onConnect = useCallback(
    (connection: Connection) => {
      setFormValues(prevFormValues => {
        if (connection.source === connection.target) {
          // 不允許自身相連
          return prevFormValues
        }

        if (
          prevFormValues.edges.some(
            e =>
              (e.source === connection.source &&
                e.sourceHandle === connection.sourceHandle) ||
              (e.target === connection.target &&
                e.targetHandle === connection.targetHandle)
          )
        ) {
          dispatch(
            openToast({
              message: t('workflow:errors.connection_existed'),
              status: 'error',
            })
          )

          // 限定節點來源或目標均不能重複
          return prevFormValues
        }

        // 限制連線來源與目標的節點類型
        if (connectionLimitMap.has(connection.sourceHandle || '')) {
          const targetNodeType = connectionLimitMap.get(
            connection.sourceHandle || ''
          )

          if (targetNodeType && connection.targetHandle !== targetNodeType) {
            dispatch(
              openToast({
                message: t('workflow:errors.connection_best_channel_limit'),
                status: 'error',
              })
            )

            return prevFormValues
          }
        }

        return {
          ...prevFormValues,
          edges: prevFormValues.edges.concat(
            applyEdgeOperation(createNewEdge(connection))
          ),
        }
      })
    },
    [setFormValues, applyEdgeOperation, dispatch, t]
  )

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault()

      const type = event.dataTransfer.getData(
        'application/reactflow'
      ) as NodeType

      if (reactFlowWrapper.current && reactFlowInstance && type) {
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left - NODE_WIDTH / 2,
          y: event.clientY - reactFlowBounds.top - NODE_HEADER_HEIGHT / 2,
        })

        setFormValues(prevFormValues => ({
          ...prevFormValues,
          nodes: prevFormValues.nodes.concat(
            applyNodeOperation(createNewNode({ type, position }, {}))
          ),
        }))
      }
    },
    [applyNodeOperation, reactFlowInstance, reactFlowWrapper, setFormValues]
  )

  useEffect(() => {
    if (initFormValues === undefined) {
      setFormValues(prevFormValues => ({
        ...prevFormValues,
        nodes: [
          applyNodeOperation(
            createNewNode({
              // trigger node 初始狀態是動態名單
              type: 'trigger-enter_audience_rule',
              position: INIT_TRIGGER_NODE_POSITION,
            })
          ),
        ],
      }))

      return
    }

    setFormValues(prevFormValues => ({
      ...prevFormValues,
      ...initFormValues,
      edges: initFormValues.edges.map(applyEdgeOperation),
      nodes: initFormValues.nodes.map(applyNodeOperation),
    }))
  }, [applyEdgeOperation, applyNodeOperation, initFormValues, setFormValues])

  return {
    onLayout,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onDragOver,
    onDrop,
  }
}

export default useWorkflow
