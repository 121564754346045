import type { ActionSendEmailNodeData } from '../../actionSendEmailNodeSchema'

export const getDefaultValues = (): ActionSendEmailNodeData => ({
  campaignName: '',
  preheader: '',
  title: '',
  msgChannelId: undefined,
})

export default getDefaultValues
