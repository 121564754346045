import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TableCellProps } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'
import type { ParseKeys } from 'i18next'

import { ICON } from '@shared/model/constants/styles'
import { Tooltip } from '@shared/ui/tooltips'

import TableHeadCell from './TableCell/TableHeadCell'
import TableRow from './TableRow'

export type TableColumn<
  T extends {},
  U extends keyof T = keyof T
> = U extends keyof T
  ? {
      headerName: ParseKeys<'table'> | ''
      field: U
      type?: 'number' | 'string'
      valueFormatter?: (value: T[U], row: T) => React.ReactNode
      colRatio?: number
      paddingTop?: number
      paddingBottom?: number
      align?: TableCellProps['align']
      isSortable?: boolean
      info?: string
    }
  : never

type SortByType = string
type SortDirection = 'asc' | 'desc'

export type DataTableSortProps = {
  onSorting?: (headerName: SortByType, direction: SortDirection) => void
  defaultSortBy?: SortByType
  defaultSortDirection?: SortDirection
}

type TableHeadEnhancedProps<T extends Record<string, unknown>> = {
  columns: TableColumn<T>[]
} & DataTableSortProps

export const TableHeadEnhanced = <TField extends Record<string, unknown>>({
  columns,
  onSorting,
  defaultSortBy = '',
  defaultSortDirection = 'asc',
}: TableHeadEnhancedProps<TField>) => {
  const [stateSortDirection, setStateSortDirection] =
    useState<SortDirection>(defaultSortDirection)
  const [stateSortBy, setStateSortBy] = useState<SortByType>(defaultSortBy)
  const { t } = useTranslation('table')

  const totalColRatio = columns.reduce(
    (prev, curr) => prev + (curr.colRatio || 1),
    0
  )
  const getWidthPercent = (colRatio: number) =>
    ((colRatio / totalColRatio) * 100).toFixed(2)

  const handleSorting = (headerName: SortByType) => {
    if (stateSortBy === headerName) {
      setStateSortDirection(stateSortDirection === 'desc' ? 'asc' : 'desc')
    } else {
      setStateSortBy(headerName)
      setStateSortDirection('desc')
    }
  }

  useEffect(() => {
    if (onSorting && stateSortBy) {
      onSorting(stateSortBy, stateSortDirection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSortBy, stateSortDirection])

  return (
    <TableHead>
      <TableRow>
        {columns.map(
          (
            {
              field,
              headerName,
              type,
              align,
              colRatio = 1,
              isSortable = false,
              info = '',
            },
            idx
          ) => {
            const isFirstRowCell = idx === 0
            const isLastRowCell = idx === columns.length - 1
            const cellAlign = align || (type === 'number' ? 'right' : 'left')

            return (
              <TableHeadCell
                key={`${field.toString()}_${idx}`}
                isFirstRowCell={isFirstRowCell}
                isLastRowCell={isLastRowCell}
                width={`${getWidthPercent(colRatio)}%`}
                align={cellAlign}
                isClickable={isSortable}
              >
                {isSortable ? (
                  <TableSortLabel
                    active={stateSortBy === headerName}
                    direction={stateSortDirection}
                    onClick={() => handleSorting(headerName)}
                  >
                    {t(headerName as ParseKeys<'table'>).toUpperCase()}
                  </TableSortLabel>
                ) : (
                  t(headerName as ParseKeys<'table'>).toUpperCase()
                )}
                {info && (
                  <Tooltip title={info}>
                    <i className={ICON.infoCircle} />
                  </Tooltip>
                )}
              </TableHeadCell>
            )
          }
        )}
      </TableRow>
    </TableHead>
  )
}

export default TableHeadEnhanced
