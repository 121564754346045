import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'

import {
  Columns,
  Endpoint,
} from '@features/subscription/Modal/SubscriptionForm'
import {
  useGetSubscriptionListQuery,
  useGetSubscriptionQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { ConfirmDialog } from '@shared/ui/dialogs'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { REACT_FLOW_NO_DRAG } from '@widgets/workflow/_shared/nodes/_shared/constants'
import { useWorkflowFormState } from '@widgets/workflow/_shared/WorkflowFormStateContext'

import {
  SubscriptionNodeData,
  subscriptionNodeSchema,
} from './subscriptionNodeSchema'

type SubscriptionsNodeEditDialogProps = {
  isOpen: boolean
  initialValues: SubscriptionNodeData
  onConfirm: (values: SubscriptionNodeData) => void
  onClose: () => void
}
const SubscriptionNodeEditDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: SubscriptionsNodeEditDialogProps) => {
  const { t } = useTranslation(['common', 'workflow', 'contentTemplate'])
  const formState = useWorkflowFormState()
  const [page, setPage] = useState(DEFAULT_PAGE)

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema: subscriptionNodeSchema,
    onSubmit: values => {
      if (!formState.isEditable) {
        onClose()

        return
      }

      onConfirm(values)
    },
  })

  const { data: subscriptionData, isLoading: isSubscriptionLoading } =
    useGetSubscriptionQuery(values.subscriptionId, {
      skip: !values.subscriptionId,
    })

  const { subscriptionListOption, totalCount, isSubscriptionListLoading } =
    useGetSubscriptionListQuery(
      {
        page,
        perPage: DEFAULT_PER_PAGE,
        name: '',
      },
      {
        selectFromResult: ({ data, isLoading, isError }) => ({
          subscriptionListOption:
            !isLoading && data
              ? data.items.subscriptions.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))
              : [],
          totalCount: !isLoading && data ? data.totalCount : 0,
          isSubscriptionListLoading: isLoading,
          isSubscriptionError: isError,
        }),
      }
    )

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      maxWidth="md"
      isOpen={isOpen}
      modalTitle={t('workflow:node_subscriptions.edit_dialog.title')}
      onConfirm={handleSubmit}
      onClose={onClose}
      contentOverflowY="visible"
    >
      <Box>
        <Box
          fontSize={14}
          fontWeight={400}
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {t('workflow:node_subscriptions.edit_dialog.description')}
        </Box>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          mb={2}
        >
          <Box
            fontWeight="bold"
            fontSize={14}
            whiteSpace="nowrap"
            minWidth={100}
            sx={theme => ({
              color: theme.colors.textPrimaryBlue,
            })}
          >
            {t('common:name')}*
          </Box>

          <Box width="100%">
            <NestedList
              isFullWidth
              isDisabled={
                !formState.isEditable ||
                isSubscriptionLoading ||
                isSubscriptionListLoading
              }
              isError={touched.subscriptionId && Boolean(errors.subscriptionId)}
              label={
                subscriptionData?.name ||
                t(
                  'workflow:node_subscriptions.edit_dialog.subscription_list_placeholder'
                )
              }
              onChange={({ value }) => {
                setFieldValue('subscriptionId', +value)
                setFieldValue(
                  'subscriptionName',
                  subscriptionListOption.find(option => option.value === value)
                    ?.label || ''
                )
              }}
              options={subscriptionListOption || []}
              popperClassName={REACT_FLOW_NO_DRAG}
              popperDisablePortal
              value={values.subscriptionId}
              isShowPagination
              paginationProps={{
                totalCount,
                page: page - 1,
                rowsPerPage: DEFAULT_PER_PAGE,
                onPageChange: (_event, page) => setPage(page),
              }}
            />
          </Box>
        </Box>
      </Box>
      {subscriptionData && (
        <>
          <Columns values={subscriptionData.columns} isDisabled />
          <Endpoint value={subscriptionData.url} isDisabled />
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Box
              fontWeight="bold"
              fontSize={14}
              whiteSpace="nowrap"
              minWidth={100}
            />

            <Box
              width="100%"
              textAlign="left"
              fontSize={14}
              sx={theme => ({
                color: theme.colors.textSecondBlue,
              })}
            >
              {t('workflow:node_subscriptions.edit_dialog.remark')}
            </Box>
          </Box>
        </>
      )}
    </ConfirmDialog>
  )
}

export default SubscriptionNodeEditDialog
