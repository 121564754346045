import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { INPUT_WIDTH_MEDIUM } from '@features/filters/_shared'
import type {
  AggregatorOperator,
  AggregatorParameters,
} from '@shared/api/rtkQuery/audience'
import { NumberRangeInput, PrimaryGreyInput } from '@shared/ui/inputs'
import type { ThemeColor } from '@theme'

type Props = {
  operator: AggregatorOperator
  values: AggregatorParameters
  unitText?: string
  bgColor?: ThemeColor
  isDisabled: boolean
  onChange: (values: AggregatorParameters) => void
}

const AggregatorValueInput = ({
  operator,
  values,
  unitText,
  bgColor,
  isDisabled,
  onChange,
}: Props) => {
  const { t } = useTranslation(['audience'])

  const getMinMaxValue = useCallback(
    () =>
      operator === 'rank_asc_percent' ||
      operator === 'rank_desc_percent' ||
      operator === 'rank_between_percent'
        ? [1, 100]
        : [0, Number.MAX_SAFE_INTEGER],
    [operator]
  )

  const getSafeValue = useCallback(
    (currValue: number) => {
      const [minValue, maxValue] = getMinMaxValue()
      return Math.max(Math.min(currValue, maxValue), minValue)
    },
    [getMinMaxValue]
  )

  // 區間
  if (
    operator === 'between' ||
    operator === 'rank_between' ||
    operator === 'rank_between_percent'
  ) {
    const [start, end] = values
    return (
      <Box display="flex" alignItems="center">
        <NumberRangeInput
          values={[start, end]}
          bgColor={bgColor}
          startAdornment={<Box mr={1}>{t('audience:v3.between')}</Box>}
          centerAdornment={
            unitText ? <Box mx={1}>{unitText} ~</Box> : <Box mx={1}>~</Box>
          }
          endAdornment={unitText && <Box ml={1}>{unitText}</Box>}
          isFloat={false}
          isDisabled={isDisabled}
          onChange={([startValue, endValue]) => {
            onChange([
              getSafeValue(startValue as number),
              getSafeValue(endValue as number),
            ])
          }}
        />
      </Box>
    )
  }

  return (
    <PrimaryGreyInput
      type="number"
      value={values[0].toString()}
      bgColor={bgColor}
      width={INPUT_WIDTH_MEDIUM}
      marginBottomRatio={0}
      disabled={isDisabled}
      onChange={e => {
        const targetValue = getSafeValue(Number(e.target.value))
        onChange([targetValue])
      }}
    />
  )
}

export default AggregatorValueInput
