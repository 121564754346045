import type {
  AggregatorOperator,
  AggregatorOperatorBase,
  AggregatorOperatorVariant,
  MeasureAggregator,
  MetadataType,
} from '@shared/api/rtkQuery'

// ex.
// 未選擇欄位 => ['count', 'count_distinct']
// 欄位類型是 'INTEGER => ['count', 'count_distinct', 'sum', 'avg', 'max', 'min']
// 欄位類型是 'STRING' => ['count', 'count_distinct']
export const defaultAggregators: MeasureAggregator[] = [
  'count',
  'count_distinct',
]
export const dataTypeExtraAggregatorsMap: {
  [name in MetadataType]: MeasureAggregator[]
} = {
  INTEGER: ['sum', 'avg', 'max', 'min'],
  FLOAT: ['sum', 'avg', 'max', 'min'],
  STRING: [],
  BOOL: [],
  ENUM: [],
  DATE: [],
  TIMESTAMP: [],
}

export const aggregatorOperatorMap: {
  [name in MeasureAggregator]: AggregatorOperator[]
} = {
  count: [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
    'rank_asc',
    'rank_desc',
    'rank_between',
  ],
  count_distinct: ['=', '!=', '>', '>=', '<', '<=', 'between'],
  sum: [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
    'rank_asc',
    'rank_desc',
    'rank_between',
  ],
  avg: [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
    'rank_asc',
    'rank_desc',
    'rank_between',
  ],
  max: [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
    'rank_asc',
    'rank_desc',
    'rank_between',
  ],
  min: [
    '=',
    '!=',
    '>',
    '>=',
    '<',
    '<=',
    'between',
    'rank_asc',
    'rank_desc',
    'rank_between',
  ],
}

const OPERATOR_VARIANT: AggregatorOperatorVariant = 'percent'

export const parseUIOperator = (
  operator: AggregatorOperator | ''
): [AggregatorOperatorBase, AggregatorOperatorVariant | ''] => {
  if (operator.includes(OPERATOR_VARIANT)) {
    return [
      operator.replace(`_${OPERATOR_VARIANT}`, '') as AggregatorOperatorBase,
      OPERATOR_VARIANT,
    ]
  }

  return [operator as AggregatorOperatorBase, '']
}

export const defaultAggregatorOperatorParamsMap = new Map<
  AggregatorOperator,
  number[]
>([
  ['=', [0]],
  ['!=', [0]],
  ['>', [0]],
  ['>=', [0]],
  ['<', [0]],
  ['<=', [0]],
  ['between', [0, 0]],
  ['rank_asc', [0]],
  ['rank_desc', [0]],
  ['rank_between', [0, 0]],
  ['rank_asc_percent', [1]],
  ['rank_desc_percent', [1]],
  ['rank_between_percent', [1, 1]],
])
