import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import {
  RadioButton,
  RadioButtonWrapper,
} from '@widgets/analytics/event/_shared/ui/EventRuleChart'

import type { FunnelChartType } from './DateAndChart'

type Props = {
  chartType: FunnelChartType
  isDisabledBarChart: boolean
  onChartTypeChanged: (chartType: FunnelChartType) => void
}

const ChartTypeRadioButtons = ({
  chartType,
  isDisabledBarChart,
  onChartTypeChanged,
}: Props) => {
  const { t } = useTranslation(['dateAndChart'])

  return (
    <RadioButtonWrapper>
      <RadioButton
        isActive={chartType === 'funnel_chart'}
        onClick={() => {
          onChartTypeChanged('funnel_chart')
        }}
        isDisabled={isDisabledBarChart}
      >
        <Box mr={1}>
          <Icon
            icon={ICON.analytics}
            color={
              chartType === 'funnel_chart'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </Box>
        <Box component="span" fontWeight="bold">
          {t('dateAndChart:charts.funnel')}
        </Box>
      </RadioButton>
      <RadioButton
        isActive={chartType === 'trend_chart'}
        onClick={() => {
          onChartTypeChanged('trend_chart')
        }}
      >
        <Box mr={1}>
          <Icon
            icon={ICON.arrowGrowth}
            color={
              chartType === 'trend_chart'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </Box>
        <Box component="span" fontWeight="bold">
          {t('dateAndChart:charts.trend')}
        </Box>
      </RadioButton>
    </RadioButtonWrapper>
  )
}

export default ChartTypeRadioButtons
