import type { CampaignStatistic } from '@shared/api/rtkQuery'

/**
 * 把資料依照指定的 key ，找到對應的 value 進行分組
 */

export const groupData = (
  data: CampaignStatistic[],
  groupBy: keyof CampaignStatistic,
  option: {
    locales?: string
  } = {
    locales: 'zh-TW',
  }
) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((acc, item) => {
    const groupValue =
      groupBy === 'actionDate'
        ? (new Date(item.actionDate).toLocaleDateString(
            option.locales
          ) as string)
        : (item[groupBy] as string)

    if (!acc[groupValue]) {
      acc[groupValue] = []
    }

    acc[groupValue].push(item)

    return acc
  }, {} as Record<string, CampaignStatistic[]>)
}
