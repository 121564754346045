import { useTranslation } from 'react-i18next'

import { formatRTKQueryError } from '@entities/apiHandler'

import { ErrorWrapper } from '../Style'

type Props = {
  error: unknown
}
export const Error = ({ error }: Props) => {
  const { t } = useTranslation('common')

  const { statusCode } = formatRTKQueryError(error)

  switch (statusCode) {
    case 400:
      return <ErrorWrapper>{t('data_error')}</ErrorWrapper>
    case 500:
    default:
      return <ErrorWrapper>{t('failed_to_load_data')}</ErrorWrapper>
  }
}

export default Error
