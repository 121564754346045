export const checkIsExpired = (updateAt?: string, exportAt?: string) => {
  if (!updateAt || !exportAt) {
    return false
  }

  // 更新時間大於上次匯出的時間即算是過期
  return new Date(updateAt) > new Date(exportAt)
}

export default checkIsExpired
