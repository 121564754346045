import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import noPermissionBgUrl from '@shared/assets/images/iamModule/customerData.png'
import { DeepBlueGradientButton } from '@shared/ui/buttons'

type Props = {
  children: React.ReactNode
  isLoading: boolean
  isNoPermission: boolean
}

const NoPermission = ({ children, isLoading, isNoPermission }: Props) => {
  const { t } = useTranslation(['common', 'iam'])
  const history = useHistory()

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '600px',
          mb: '-100vh', // 將 Copyright 隱藏到網頁上方
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (isNoPermission) {
    return (
      <Box
        sx={{
          display: 'grid',
          justifyItems: 'center',
          alignContent: 'center',
          gap: 4,
          margin: '0 auto',
          mb: '-100vh', // 將 Copyright 隱藏到網頁上方
        }}
      >
        <img width="751" src={noPermissionBgUrl} alt="landing" />
        <Box
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            color: theme => theme.colors.textPrimaryBlue,
          }}
          component="h1"
        >
          {t('iam:no_permission_hint.title')}
        </Box>
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 500,
            color: theme => theme.colors.textPrimaryBlue,
          }}
          component="p"
        >
          {t('iam:no_permission_hint.description')}
        </Box>
        <DeepBlueGradientButton
          width={296}
          onClick={() => {
            history.goBack()
          }}
        >
          {t('common:back')}
        </DeepBlueGradientButton>
      </Box>
    )
  }

  return <>{children}</>
}

export default NoPermission
