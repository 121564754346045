import type { DashboardIndicator } from '../IndicatorList'
import type { ChartData } from '../types'
import formatIndicatorValue from './formatIndicatorValue'
import getDashboardIndicatorLatestList from './getDashboardIndicatorLatestList'
import getLatestSeries from './getLatestSeries'

export const getDashboardIndicatorLatest = ([
  chartData,
  chartDataCompared,
]: ChartData[]): DashboardIndicator[] => {
  // 代表多個維度
  if (chartData.series.length > 1) {
    const indicatorList = getDashboardIndicatorLatestList(chartData.series)
    const indicatorListCompared = getDashboardIndicatorLatestList(
      chartDataCompared?.series ?? []
    )

    const dashboardIndicatorList: DashboardIndicator[] = []

    indicatorList.forEach((indicator, index) => {
      dashboardIndicatorList.push({
        ...indicator,
        valueCompared: formatIndicatorValue(
          indicatorListCompared[index]?.value
        ),
      })
    })

    // 有 groupName 是群組
    if (chartData.series[0]?.groupName) {
      return [{ indicator: chartData.series[0].groupName ?? '' }].concat(
        dashboardIndicatorList
      )
    }

    return dashboardIndicatorList
  }

  const hasChartDataCompared = Boolean(chartDataCompared)

  const seriesLatest = getLatestSeries(chartData.series)
  const seriesLatestCompared = getLatestSeries(chartDataCompared?.series)

  return [
    {
      indicator: seriesLatest.name,
      label: seriesLatest.label ?? '',
      value: formatIndicatorValue(
        seriesLatest.data[seriesLatest.data.length - 1]
      ),
      valueCompared: hasChartDataCompared
        ? formatIndicatorValue(
            seriesLatestCompared.data[seriesLatestCompared.data.length - 1]
          )
        : undefined,
    },
  ]
}
