import type { Aggregator } from '@shared/api/rtkQuery'
import { DEFAULT_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'
import type { PredefinedField } from '@widgets/analytics/_shared'

export const MAX_TOP_N = 50

export const PREVIEW_BUTTON_MIN_WIDTH = 188

export const STATISTIC_MEASURE_FILTER_MAX_COUNT = 5

export const STATISTIC_MEASURES_MAX_COUNT = 10

export const AGGREGATOR_FIELD_MAP = new Map<
  Aggregator,
  { source: string; field: PredefinedField }
>([
  ['count', { source: DEFAULT_EVENT_TABLE_NAME, field: 'c_event_name' }],
  [
    'count_distinct',
    { source: DEFAULT_EVENT_TABLE_NAME, field: 'c_cdp_user_id' },
  ],
  [
    'count_per_user',
    { source: DEFAULT_EVENT_TABLE_NAME, field: 'c_event_name' },
  ],
])
