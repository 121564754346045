import type { Reducer } from 'use-immer'

import type { CampaignEventFilterNode } from '@shared/api/rtkQuery'

import {
  createMeasureFilterInitialState,
  reducer as measureReducer,
} from '../MeasureFilter'
import type { CampaignEventFilterAction } from './types'

export const createInitialState = (): CampaignEventFilterNode => ({
  type: 'campaign_event',
  node: {
    nodes: [],
    combination: 'and',
    type: 'group',
  },
})

export const initializer = (
  currState?: CampaignEventFilterNode
): CampaignEventFilterNode => {
  return currState || createInitialState()
}

export const reducer: Reducer<
  CampaignEventFilterNode,
  CampaignEventFilterAction
> = (draft, action) => {
  switch (action.type) {
    case 'addNestedMeasure': {
      const { index } = action.payload
      draft.node.nodes[index].nodes.push(createMeasureFilterInitialState())
      break
    }

    case 'removeNestedMeasure': {
      const currNestedGroups =
        draft.node.nodes[action.payload.parentIndex].nodes
      if (currNestedGroups.length === 1) {
        // 只剩最後一個 child 時將 parent 一併移除
        draft.node.nodes.splice(action.payload.parentIndex, 1)
        break
      }

      currNestedGroups.splice(action.payload.nestedIndex, 1)
      break
    }

    case 'updateNestedMeasure':
      measureReducer(
        draft.node.nodes[action.payload.parentIndex].nodes[
          action.payload.nestedIndex
        ],
        action.payload.measureAction
      )
      break

    case 'cloneNestedMeasure':
      draft.node.nodes[action.payload.targetParentIndex].nodes.push(
        draft.node.nodes[action.payload.targetParentIndex].nodes[
          action.payload.sourceNestedIndex
        ]
      )
      break

    case 'addAudienceFilterGroupItem': {
      draft.node.nodes.push({
        type: 'group',
        nodes: [createMeasureFilterInitialState()],
        combination: 'and',
      })
      break
    }

    case 'toggleAudienceFilterCombination':
      draft.node.combination = draft.node.combination === 'and' ? 'or' : 'and'
      break

    case 'toggleAudienceFilterGroupCombination':
      const currGroup = draft.node.nodes[action.payload]
      currGroup.combination = currGroup.combination === 'and' ? 'or' : 'and'
      break

    case 'reset':
      return initializer(action.payload)

    default:
      return draft
  }
}
