import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

type Props = {
  summary: string
  details: string
}

const ReadMore = ({ summary, details }: Props) => {
  const { t } = useTranslation(['common'])

  const [isToggle, setIsToggle] = useState<boolean>(false)

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box
          fontSize={14}
          mr={0.5}
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {summary}
        </Box>
        <Box
          onClick={() => setIsToggle(prev => !prev)}
          sx={theme => ({
            cursor: 'pointer',
            color: theme.colors.brightBlue,
            textDecoration: 'underline',
          })}
        >
          {t('common:read_more')}
        </Box>
      </Box>
      {isToggle && (
        <Box
          my={0.5}
          fontSize={14}
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {details}
        </Box>
      )}
    </Box>
  )
}

export default ReadMore
