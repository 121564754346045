import { TFunction } from 'i18next'

import type { CreationAndExportStatus } from '@shared/model/constants/listStatus'

export const getStatusText = (
  t: TFunction<('common' | 'audience')[]>,
  status?: CreationAndExportStatus
) => {
  if (status === 'failed') {
    return t('audience:export_csv_failure')
  }

  if (status === 'completed') {
    return t('audience:export_csv_success')
  }

  return t('audience:export_csv_description')
}

export default getStatusText
