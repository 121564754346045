import { api, transformResponseCamelCaseKeys } from './api'

export type UpdateTagGroup = {
  id: number
  body: {
    title?: string
    description?: string
  }
}

export type TagGroup = {
  id: number
  title: string
  description: string
}

const tagGroupApi = api.injectEndpoints({
  endpoints: builder => ({
    tagGroups: builder.query<TagGroup[], null>({
      query: () => ({
        url: `/tag_groups`,
        method: 'GET',
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as TagGroup[]),
      providesTags: ['TagGroup'],
    }),
    addTagGroup: builder.mutation<
      TagGroup,
      Pick<TagGroup, 'title' | 'description'>
    >({
      query: payload => ({
        url: `/tag_groups`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['TagGroup'],
    }),
    updateTagGroup: builder.mutation<void, UpdateTagGroup>({
      query: ({ id, body }) => ({
        url: `/tag_groups/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['TagGroup'],
    }),
    deleteTagGroupById: builder.mutation<void, number>({
      query: id => ({
        url: `/tag_groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TagGroup', 'Tag'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useTagGroupsQuery,
  useAddTagGroupMutation,
  useUpdateTagGroupMutation,
  useDeleteTagGroupByIdMutation,
} = tagGroupApi
