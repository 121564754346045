import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRTKQueryError } from '@entities/apiHandler'
import type { CreateContentTemplate } from '@shared/api/rtkQuery'
import {
  useCreateContentTemplateMutation,
  useUpdateContentTemplateMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { openToast } from '@shared/model/slices'

import { CREATE_SMS_STATUS_CODE } from '../models/constants'

export const useMutateSmsContentTemplate = () => {
  const { t } = useTranslation(['common', 'contentTemplate'])
  const dispatch = useAppDispatch()

  const [createContentTemplate, { isLoading: isAdding }] =
    useCreateContentTemplateMutation()

  const [updateContentTemplate, { isLoading: isUpdating }] =
    useUpdateContentTemplateMutation()

  const isLoading = isAdding || isUpdating

  const mutateSmsContentTemplate = useCallback(
    async ({
      body,
      id,
      onSuccess,
    }: {
      body: CreateContentTemplate
      id?: number
      onSuccess?: () => void
    }) => {
      try {
        if (id) {
          await updateContentTemplate({ id, body })
          onSuccess?.()

          return
        }

        const { id: newId } = await createContentTemplate(body).unwrap()

        trackEvent('MarketingAutomationTemplateConfigured', {
          templateId: newId,
          templateName: body.title,
          templateType: 'medium_template',
        })

        onSuccess?.()
      } catch (error) {
        const formatError = formatRTKQueryError(error)

        if (formatError.statusCode === CREATE_SMS_STATUS_CODE.DUPLICATE_NAME) {
          dispatch(
            openToast({
              message: t('common:errors.duplicate_name'),
              status: 'error',
            })
          )
          return
        }

        dispatch(
          openToast({
            message: id
              ? t('contentTemplate:update_data_failed')
              : t('common:failure_create'),
            status: 'error',
          })
        )
      }
    },
    [createContentTemplate, dispatch, t, updateContentTemplate]
  )
  return { mutateSmsContentTemplate, isLoading }
}

export default useMutateSmsContentTemplate
