import cloneDeep from 'lodash/cloneDeep'
import curry from 'lodash/fp/curry'

import { MERGE_TAG_REGEX } from '@entities/mediumTemplates'
import type { ReplacementData } from '@shared/api/rtkQuery'
import {
  PREDEFINED_METADATA_TAG_ID_LIST,
  PredefinedMetadataTagId,
} from '@shared/model/constants/metadata'

// 取出 {{mergeTag}} 的資料存進 data
export const fetchMergeTag = curry(
  (html: string, replacementData: ReplacementData) => {
    const replacementDataCloned = cloneDeep(replacementData)
    const matchAll = html.matchAll(MERGE_TAG_REGEX) || []

    for (const [, replacementDataKey] of matchAll) {
      if (
        PREDEFINED_METADATA_TAG_ID_LIST.includes(
          replacementDataKey as PredefinedMetadataTagId
        )
      ) {
        replacementDataCloned[replacementDataKey] = {
          type: 'unpurchased_products',
          name: replacementDataKey,
        }
        continue
      }

      replacementDataCloned[replacementDataKey] = {
        type: 'metadata',
        name: replacementDataKey,
      }
    }

    return replacementDataCloned
  }
)

export default fetchMergeTag
