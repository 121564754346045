import type { Aggregator, Measure } from '@shared/api/rtkQuery/audience'
import { DEFAULT_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'

export const defaultMeasureMap = new Map<Aggregator, Measure>([
  [
    'count',
    {
      aggregator: 'count',
      source: DEFAULT_EVENT_TABLE_NAME,
      field: 'c_event_name',
    },
  ],
  [
    'count_distinct',
    {
      aggregator: 'count_distinct',
      source: DEFAULT_EVENT_TABLE_NAME,
      field: 'c_created_at',
      unit: 'day',
    },
  ],
])
