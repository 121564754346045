import axios, { AxiosTransformer } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { apiV1 } from './_v1'

const baseURL = import.meta.env.VITE_API_URL_EDITOR

export const api = axios.create({
  baseURL,
  timeout: 5 * 60 * 1000,
  transformRequest: [
    data => data && snakecaseKeys(data, { deep: true }),
    ...(axios.defaults.transformRequest as AxiosTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosTransformer[]),
    data => data && camelcaseKeys(data, { deep: true }),
  ],
})

export const setApiHeaders = ({
  key,
  value,
}: {
  key: string
  value: string
}) => {
  if (!key || !value) {
    return
  }

  api.defaults.headers.common[key] = value
  apiV1.defaults.headers.common[key] = value
}

export const newCancelSource = () => axios.CancelToken.source()

export default api
