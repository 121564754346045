import type { PrivacyLevel } from '@shared/api/rtkQuery'
import { useSearchParams } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'

export const useEventRuleSearchParams = () => {
  const searchParams = useSearchParams()

  const groupId = getIntSafe(searchParams.get('groupId'))

  const privacyLevel: PrivacyLevel =
    // 避免使用者手動改 query 造成邏輯錯誤，限定 privacyLevel 只能是 public 或 private
    searchParams.get('privacyLevel') === 'private' ? 'private' : 'public'

  return { groupId, privacyLevel }
}
