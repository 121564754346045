import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { NodeType } from '@shared/api/rtkQuery/workflow'
import { useSearchParams } from '@shared/lib/hooks/url'
import { getIntSafe } from '@shared/lib/utils/number'
import { QUERY_STRING } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import DotLoader from '@shared/ui/loaders/DotLoader'
import theme from '@theme'
import { useNodeTypeMap } from '@widgets/workflow/_shared/hooks'
import { useWorkflowFormState } from '@widgets/workflow/_shared/WorkflowFormStateContext'

import {
  ACTION_BUTTON_MIN_WIDTH,
  NODE_CONTENT_MIN_HEIGHT,
  NODE_HEADER_HEIGHT,
  REACT_FLOW_NO_DRAG,
} from '../constants'
import NodeHeaderIcon from './NodeHeaderIcon'
import {
  Container,
  DeleteBtn,
  DeleteBtnContainer,
  NodeContent,
  PointerEventsInitial,
} from './styles'
import TooltipIcon from './TooltipIcon'

const DEFAULT_TRIGGER_ID = 0

type Props = {
  nodeType: NodeType
  onDelete?: () => void
  onEdit?: () => void
  tooltipContent?: React.ReactElement
  triggerId?: number
  usersInNode?: number
  // 當非 undefined 會依據值決定是否顯示「請點擊編輯設定節點內容」
  isSettled?: boolean
  isLoading?: boolean
  children?: React.ReactNode
  errorHint?: React.ReactNode
}

export const BasicNode = ({
  nodeType,
  onDelete: handleDelete,
  onEdit: handleEdit,
  tooltipContent,
  triggerId,
  usersInNode,
  isSettled,
  isLoading,
  errorHint,
  children,
}: Props) => {
  const { name: nodeName, iconUrl: nodeIconUrl } = useNodeTypeMap()[nodeType]

  const query = useSearchParams()
  const targetTriggerId = getIntSafe(
    query.get(QUERY_STRING.workflow.triggerId),
    DEFAULT_TRIGGER_ID
  )

  const [hasBeenOpened, setHasBeenOpened] = useState(false)

  useEffect(() => {
    if (
      triggerId !== DEFAULT_TRIGGER_ID &&
      targetTriggerId === triggerId &&
      !hasBeenOpened &&
      handleEdit
    ) {
      handleEdit()
      setHasBeenOpened(true)
    }
  }, [handleEdit, hasBeenOpened, targetTriggerId, triggerId])

  const formState = useWorkflowFormState()

  const isShowError =
    (formState.isSubmitted && !isSettled) || Boolean(errorHint)

  const { t } = useTranslation(['common', 'workflow'])

  const userCount = getIntSafe(usersInNode)

  return (
    <Container isError={isShowError}>
      {handleDelete && formState.isEditable && (
        <DeleteBtnContainer className={REACT_FLOW_NO_DRAG}>
          <DeleteBtn onClick={handleDelete}>
            <Icon
              color={theme.colors.white}
              icon={ICON.multiply}
              fontSize="small"
            />
          </DeleteBtn>
        </DeleteBtnContainer>
      )}

      <Box
        display="flex"
        alignItems="center"
        sx={{
          p: 1,
          pl: 2,
          height: NODE_HEADER_HEIGHT,
        }}
      >
        <NodeHeaderIcon
          isShowError={isShowError}
          nodeIconUrl={nodeIconUrl}
          tooltip={errorHint}
        />

        <Box
          sx={{
            ml: 4,
            flexGrow: 1,
            textAlign: 'center',
            color: theme => theme.colors.blackTwo,
          }}
        >
          {nodeName}
        </Box>

        <Box minWidth={ACTION_BUTTON_MIN_WIDTH}>
          {handleEdit && (
            <PointerEventsInitial>
              <Button
                className={REACT_FLOW_NO_DRAG}
                color="primary"
                onClick={handleEdit}
              >
                {formState.isEditable ? t('common:edit') : t('common:view')}
              </Button>
            </PointerEventsInitial>
          )}
        </Box>
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: NODE_CONTENT_MIN_HEIGHT,
          }}
        >
          <DotLoader />
        </Box>
      ) : (
        <NodeContent>
          {isSettled ? (
            children ?? (
              <>
                {userCount > 0 && (
                  <p>
                    {t('workflow:node_user_count', {
                      user_count: userCount,
                    })}
                  </p>
                )}

                <Box display="flex">
                  {t('workflow:node_hint_configured')}

                  {tooltipContent && <TooltipIcon title={tooltipContent} />}
                </Box>
              </>
            )
          ) : (
            <>{t('workflow:node_hint_default')}</>
          )}
        </NodeContent>
      )}
    </Container>
  )
}

export default BasicNode
