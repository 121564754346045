import snakecaseKeys from 'snakecase-keys'

import { api } from './api'
import { IamServiceModuleName } from './iamService'

const request = api.injectEndpoints({
  endpoints: builder => ({
    sendRequest: builder.mutation<
      void,
      {
        module: IamServiceModuleName
        business: 'provisioning'
      }
    >({
      query: payload => ({
        url: '/requests',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
    }),
  }),
  overrideExisting: false,
})

export const { useSendRequestMutation } = request
