import type { OperatorType } from './analytics/notificationRuleType'
import { api } from './api'

export type NotificationsDetailType = 'journey' | 'audiences' | 'thirdPartyTag'

export type NotificationType = {
  id: number
  title: string
  createdAt: string
  type: 'event' | 'tentacle' | 'general'
  archivedAt: string | null
  detail: { [key in NotificationsDetailType]: string[] } & {
    eventDisplayName: string
    name: string
    rule: {
      condition: {
        operator: OperatorType
        daysBefore: number
        value: number
      }
    }
  } & {
    message: string
  }
}

const NotificationsApi = api.injectEndpoints({
  endpoints: builder => ({
    // 傳入 latestId, isArchived 後，可以修改 latestId 之前的 notification isArchived 狀態
    patchNotification: builder.mutation<
      void,
      { latestId: number; isArchived: boolean }
    >({
      query: payload => ({
        url: '/notifications',
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
  overrideExisting: false,
})

export const { usePatchNotificationMutation } = NotificationsApi
