import { useImmerReducer } from 'use-immer'

import {
  createMeasureFilterInitialState,
  reducer,
  SimpleMeasureFilter,
  singleMeasureInitializer,
} from '../measureFilterReducer'

const useSingleMeasureFilter = (
  initialState: SimpleMeasureFilter = createMeasureFilterInitialState()
) => {
  const [state, dispatch] = useImmerReducer(
    reducer,
    initialState,
    singleMeasureInitializer
  )

  return { values: state, onDispatch: dispatch }
}

export default useSingleMeasureFilter
