import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { NotificationType } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import theme from '@theme'
import type { DaysBeforeType } from '@widgets/analytics/notificationRule/constant'

type Props = {
  notificationType: NotificationType
}

const Event = ({ notificationType }: Props) => {
  const { t } = useTranslation(['analytics'])

  return (
    <>
      <Box pt={2} pr={3} width="8px">
        {!notificationType.archivedAt && (
          <Box
            width="8px"
            height="8px"
            borderRadius="50%"
            sx={theme => ({
              backgroundColor: theme.colors.orangeyRed,
            })}
          />
        )}
      </Box>
      <Box
        component="i"
        py={0}
        px="7px"
        width="36px"
        height="36px"
        borderRadius="50%"
        fontSize="24px"
        lineHeight="36px"
        className={ICON.fileSearchAlt}
        sx={theme => ({
          backgroundColor: theme.colors.bgPaleGrey,
          color: theme.colors.brightBlue,
        })}
      />
      <Box flexShrink="1" flexGrow="1" padding="2px 14px">
        <Box
          component="h2"
          sx={theme => ({
            color: theme.colors.black,
          })}
        >
          {t('analytics:notification.accoding_to_we_detect', {
            eventName: notificationType.title,
          })}
        </Box>
        <Box
          margin="10px 4px"
          padding="0 18px"
          borderLeft={`4px solid ${theme.colors.lightBlueGrey}`}
        >
          <Box component="p" fontSize="13px" lineHeight="24px">{`${t(
            'analytics:notification.event'
          )} : ${notificationType.detail.eventDisplayName}`}</Box>
          <Box component="p" fontSize="13px" lineHeight="24px">
            {`${t('analytics:notification.compared_time')} : ${t(
              `analytics:notificationRule.daysBeforeOptions.${
                notificationType.detail.rule.condition.daysBefore.toString() as DaysBeforeType
              }`
            )}`}
          </Box>
          <Box component="p" fontSize="13px" lineHeight="24px">
            {`${t('analytics:notification.detect_condition')} : ${t(
              `analytics:notificationRule.operatorOptions.${notificationType.detail.rule.condition.operator}`
            )} ${notificationType.detail.rule.condition.value}`}
          </Box>
        </Box>
        <Box
          component="p"
          fontSize="13px"
          lineHeight="20px"
          sx={theme => ({
            color: theme.colors.lightGreyBlue,
          })}
        >
          {formatDateTimeDisplay(notificationType.createdAt)}
        </Box>
      </Box>
    </>
  )
}

export default Event
