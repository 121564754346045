import { CSSProperties, useEffect, useReducer } from 'react'
import Box from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'

import { DEFAULT_AT, DEFAULT_DURATION } from './_shared/constants'
import getTimeNodeData from './utils/getTimeNodeData'
import getTimeNodeType from './utils/getTimeNodeType'
import AdvDate from './AdvDate'
import AdvWeekday from './AdvWeekday'
import Duration from './Duration'
import { getInitState, INIT_STATE, reducer, TimeData } from './timeReducer'

type Props = {
  defaultTimeData?: TimeData
  direction?: CSSProperties['flexDirection']
  isEditable: boolean
  isError?: boolean
  isHideDateHourAndMinute?: boolean
  isHideDurationHourAndMinute?: boolean
  isHideWeekdayHourAndMinute?: boolean
  mb?: number
  minAdvDate?: Date
  onTimeDataChange: (timeData: TimeData) => void
  timeData?: TimeData
}

export const Timing = ({
  defaultTimeData = INIT_STATE.timeData,
  direction = 'row',
  isEditable,
  isError,
  isHideDateHourAndMinute = false,
  isHideDurationHourAndMinute = false,
  isHideWeekdayHourAndMinute = false,
  mb = 4,
  minAdvDate = new Date(),
  onTimeDataChange,
  timeData,
}: Props) => {
  const [timeState, dispatch] = useReducer(
    reducer,
    getInitState(timeData || defaultTimeData)
  )

  useEffect(
    () =>
      onTimeDataChange(getTimeNodeData(timeState.timeType, timeState.timeData)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeState]
  )

  const timeNodeType = getTimeNodeType(timeState.timeData)

  return (
    <Box mb={mb} width="100%">
      <RadioGroup name="timeNodeType" value={timeNodeType}>
        <Box display="flex" flexDirection={direction} width="100%">
          <Duration
            isError={isError}
            isEditable={isEditable}
            isColumnEditable={timeNodeType === 'duration'}
            isHideHourAndMinute={isHideDurationHourAndMinute}
            timeData={timeState.timeData}
            // 目前只有 duration 需要有不同預設值。
            defaultData={{
              duration: defaultTimeData?.duration || DEFAULT_DURATION,
            }}
            onTimeDataUpdate={timeData =>
              dispatch({
                type: 'update',
                payload: {
                  timeType: 'duration',
                  timeData,
                },
              })
            }
          />
        </Box>
        <Box mt={2} display="flex" flexDirection={direction} width="100%">
          <AdvDate
            isError={isError}
            isEditable={isEditable}
            isColumnEditable={timeNodeType === 'specifiedDay'}
            isHideHourAndMinute={isHideDateHourAndMinute}
            timeData={
              timeNodeType === 'specifiedDay'
                ? timeState.timeData
                : // 顯示預設值
                  {
                    at: DEFAULT_AT,
                  }
            }
            onTimeDataUpdate={timeData =>
              dispatch({
                type: 'update',
                payload: {
                  timeType: 'specifiedDay',
                  timeData,
                },
              })
            }
            minDate={minAdvDate}
          />
        </Box>
        <Box mt={2} display="flex" flexDirection={direction} width="100%">
          <AdvWeekday
            isError={isError}
            isEditable={isEditable}
            isColumnEditable={timeNodeType === 'dayOfWeek'}
            isHideHourAndMinute={isHideWeekdayHourAndMinute}
            timeData={
              timeNodeType === 'dayOfWeek'
                ? timeState.timeData
                : // 顯示預設值
                  {
                    at: DEFAULT_AT,
                  }
            }
            onTimeDataUpdate={timeData =>
              dispatch({
                type: 'update',
                payload: {
                  timeType: 'dayOfWeek',
                  timeData,
                },
              })
            }
          />
        </Box>
      </RadioGroup>
    </Box>
  )
}

export default Timing
