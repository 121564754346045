import { combineEpics } from 'redux-observable'

import apiHandlerEpics from '@entities/apiHandler/model/epics'
import authEpics from '@entities/auth/model/epics'
import notificationEpics from '@entities/notification/model/epics'
import taskEpics from '@features/infoBox/model/taskEpics'
import { epics as settingsEpics } from '@widgets/settings/store'

const rootEpic = combineEpics(
  ...apiHandlerEpics,
  ...taskEpics,
  ...authEpics,
  ...notificationEpics,
  ...settingsEpics
)

export default rootEpic
