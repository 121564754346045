import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'
import { spacing } from '@mui/system'

type ArrowDirection = 'left' | 'right'

export const ArrowButtonWrapper = styled.button<{ direction: ArrowDirection }>`
  position: absolute;

  right: ${({ direction }) => (direction === 'right' ? '0' : 'auto')};
  left: ${({ direction }) => (direction === 'left' ? '0' : 'auto')};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90px;
  height: 100%;
  border: none;
  transform: ${({ direction }) =>
    direction === 'left' ? 'rotate(-180deg)' : 'rotate(0deg)'};
  background: linear-gradient(
    270deg,
    #f5f7fa 45.29%,
    rgba(245, 247, 250, 0) 96.2%
  );
  cursor: pointer;
  z-index: 1;
`

export const ScrollMenuWrapper = styled.div<{
  hasLeftArrow: boolean
  hasRightArrow: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  padding-right: ${({ hasRightArrow }) => (hasRightArrow ? '75px' : '0')};
  padding-left: ${({ hasLeftArrow }) => (hasLeftArrow ? '100px' : '0')};

  width: 100%;
`

export const GroupNameButtonWrapper = styled(MuiButton)`
  && {
    position: relative;
    height: 34.5px;
    background-color: ${({ theme }) => theme.colors.borderColor};
    font-size: 13px;
    font-weight: bold;
    line-height: 34.5px;
    white-space: nowrap;
    ${spacing}
  }
`

export const SlideTrack = styled.div<{ posLeft: number }>`
  position: relative;
  display: flex;

  margin: 0 8px;

  transform: ${({ posLeft }) => `translateX(${posLeft}px)`};
  transition: 0.36s ease;
`
