import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  InputArea,
  InputAreaGrid,
  LabelArea,
  MUST_SUBSCRIPTION_COLUMNS,
} from '@features/subscription/_shared'
import type { SubscriptionFormValues } from '@shared/api/rtkQuery'
import { useMetadataUserProfileFromUsers } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList, { Option } from '@shared/ui/Dropdown/CheckboxList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type Props = {
  values: SubscriptionFormValues['columns']
  onChange?: (value: SubscriptionFormValues['columns']) => void
  isDisabled?: boolean
  isError?: boolean
}

const Columns = ({
  values,
  onChange,
  isError = false,
  isDisabled = false,
}: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const metadataOptions = useMetadataUserProfileFromUsers<Option>(
    ({ id, displayName }) => ({
      value: id,
      label: `${displayName} (${id})`,
      disabled: MUST_SUBSCRIPTION_COLUMNS.includes(id),
    })
  )

  const userProfileEntity = useMemo(
    () =>
      metadataOptions.reduce((acc, curr) => {
        acc[curr.value] = { label: curr.label }
        return acc
      }, {} as Record<string, { label: string }>),
    [metadataOptions]
  )

  return (
    <InputAreaGrid mb={2}>
      <LabelArea>
        {t('contentTemplate:subscription.dialog.common.columns')}*
      </LabelArea>
      <InputArea>
        <DropdownCheckboxList
          hasSearchBar
          popperDisablePortal
          isFullWidth
          isError={isError}
          isDisabled={isDisabled}
          bgColor={
            isDisabled ? theme.colors.veryLightBlue : theme.colors.bgPrimaryGrey
          }
          checkedValues={values}
          options={metadataOptions}
          label={t(
            'contentTemplate:subscription.dialog.common.column_placeholder'
          )}
          anchorElem={
            values.length > 0 ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                px={2}
                py={0.75}
                borderRadius={1}
                sx={theme => ({
                  backgroundColor: isDisabled
                    ? theme.colors.veryLightBlue
                    : theme.colors.bgPrimaryGrey,
                  cursor: isDisabled ? 'cursor' : 'pointer',
                })}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  gap={2}
                >
                  {values.map(selectedValue => (
                    <Box
                      key={`${selectedValue}`}
                      fontWeight="bold"
                      fontSize={13}
                      px={2}
                      py={0.5}
                      borderRadius={1}
                      sx={theme => ({
                        backgroundColor: theme.colors.textPrimaryBlue,
                        color: theme.colors.white,
                      })}
                    >
                      {userProfileEntity[selectedValue]?.label}
                    </Box>
                  ))}
                </Box>
                {!isDisabled && (
                  <Icon
                    icon={ICON.arrowDown}
                    fontSize="small"
                    color={theme.colors.textPrimaryBlue}
                  />
                )}
              </Box>
            ) : undefined
          }
          onChange={values => {
            const uniqueValues = new Set([...values])

            onChange?.(Array.from(uniqueValues))
          }}
        />
      </InputArea>
    </InputAreaGrid>
  )
}

export default Columns
