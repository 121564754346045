import type { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

import Card from '@shared/ui/Card'

import {
  CreateButton,
  CreateButtonProps,
  PreviewButton,
  PreviewButtonProps,
} from '../_shared/buttons'
import DonutChart from '../_shared/DonutChart'
type Props = {
  title: string
  userCount: number
} & CreateButtonProps &
  PreviewButtonProps

const TotalCountPreviewer = ({
  title,
  userCount,
  isLoading = false,
  isCreating = false,
  children,
  onPreview = () => {},
  onCreate = () => {},
}: PropsWithChildren<Props>) => {
  return (
    <Box sx={{ width: 264 }}>
      <Card p={3} mb={2}>
        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 200 }}>
          <DonutChart
            title={title}
            displayValue={userCount}
            isLoading={isLoading}
            isFullRadius
          />
        </Box>

        {children}

        <PreviewButton isLoading={isLoading} onPreview={onPreview} />
      </Card>

      <CreateButton isCreating={isCreating} onCreate={onCreate} />
    </Box>
  )
}

export default TotalCountPreviewer
