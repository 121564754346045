import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  useDeleteNotificationRuleMutation,
  useGetNotificationRuleListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { DataTable } from '@shared/ui/table'

import useColumns from './useColumns'

const Overview = () => {
  const { t } = useTranslation(['common', 'analytics'])

  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE)
  const [openDropdownId, setOpenDropdownId] = useState<number>(-1)
  const [deleteConfirmId, setDeleteConfirmId] = useState<number>()

  const dispatch = useAppDispatch()

  const { data, isFetching } = useGetNotificationRuleListQuery({
    page,
    perPage,
  })

  const [deleteData, { isLoading: isDeleting }] =
    useDeleteNotificationRuleMutation()

  const safeCloseAllPopup = useCallback(() => {
    // 需要照順序關閉彈窗避免頁面跳動
    setDeleteConfirmId(undefined)
  }, [])

  const columns = useColumns({
    openDropdownId,
    setOpenDropdownId,
    setDeleteConfirmId,
  })

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Breadcrumbs>
          <Box>{t('common:route.notification_rule')}</Box>
        </Breadcrumbs>
        <Link to={`/${CATEGORY.analytics}/${PAGE_ROOT.notificationRule}/new`}>
          <DeepBlueGradientButton width={200}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Link>
      </Box>
      <DataTable
        columns={columns}
        rows={data ? data.items : []}
        count={data ? data.totalCount : 0}
        isLoading={isFetching || isDeleting}
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="id"
        isRowClickable
        onPageChange={newPage => {
          setPage(newPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
      />
      <DeleteConfirmDialog
        isLoading={isDeleting}
        isOpen={deleteConfirmId !== undefined}
        modalTitle={t('analytics:notificationRule.sure_to_delete')}
        onClose={() => {
          setDeleteConfirmId(undefined)
        }}
        onConfirm={() => {
          if (deleteConfirmId) {
            deleteData(deleteConfirmId)
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                dispatch(
                  openToast({
                    message: t('common:failure_delete'),
                    status: 'error',
                  })
                )
              })
          }
        }}
      />
    </>
  )
}

export default Overview
