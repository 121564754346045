import { useEffect, useState } from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import { ICON } from '@shared/model/constants/styles'
import {
  MAX_LIST_NAME_LENGTH,
  TAG_GROUP_MAX_COUNT,
} from '@shared/model/constants/validation'
import { AddButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { MenuItem, MenuList } from '@shared/ui/menu'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { ItemTypes } from './constant'
import DraggableTag from './DraggableTag'
import { TagGroupType, TagSimpleType } from './type'

const TagsWrapper = styled.div`
  width: 100%;
  min-height: 60px;
`

const LoadingFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0.5;
`

const HorizontalLine = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -6px;
  margin-right: -20px;
  margin-bottom: 20px;
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

const ActionWrapper = styled.div`
  margin-top: -14px;
`

const GroupCardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;
  border-radius: 3px;
  box-shadow: 0px 4px 8px ${({ theme }) => theme.colors.black5};
`

const NoTagDiv = styled.div`
  margin-top: -20px;
  color: ${({ theme }) => theme.colors.textSecondBlue};
  font-size: 15px;
  font-weight: 500;
`

const GroupCard = styled(Card)<{ isTagOver: boolean }>`
  overflow: hidden;
  border: ${({ isTagOver }) =>
    isTagOver && `2px dashed ${theme.colors.brightBlue}`};
  background-color: ${({ isTagOver }) => isTagOver && theme.colors.bgIceBlue};
`

const LoadingProgress = styled(LinearProgress)`
  margin-top: -3px;
`

const AddButtonWrapper = styled.div`
  display: inline-block;
`

const TagBoard = ({
  data,
  isDisabled,
  selectedTagId,
  addTagGroup,
  updateTagGroup,
  deleteTagGroup,
  handleMoveTag,
  isAdding,
  isUpdating,
  isDeleting,
  OverGroupsAmountLimitation = false,
}: {
  data: TagGroupType
  isDisabled: boolean
  selectedTagId: string
  addTagGroup: (title: string) => void
  updateTagGroup: (id: number, title: string) => void
  deleteTagGroup: (id: number) => void
  handleMoveTag: (tag: TagSimpleType, targetGroupId: number) => void
  isAdding: boolean
  isUpdating: boolean
  isDeleting: boolean
  OverGroupsAmountLimitation?: boolean
}) => {
  const { t } = useTranslation(['common', 'tag'])

  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState<boolean>(false)
  const [isOpenAddDialog, setIsOpenAddDialog] = useState<boolean>(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false)
  const [addGroupName, setAddGroupName] = useState<string>('')
  const [addGroupNameError, setAddGroupNameError] = useState<string>('')
  const [renameGroupName, setRenameGroupName] = useState<string>('')
  const [renameGroupNameError, setRenameGroupNameError] = useState<string>('')

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item: TagSimpleType & { groupId: number }) => {
      handleMoveTag(item, data.id)
    },
    canDrop: item => item.groupId !== data.id,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  useEffect(() => {
    if (!isAdding) {
      setIsOpenAddDialog(false)
    }
  }, [isAdding])

  useEffect(() => {
    if (!isUpdating) {
      setIsOpenRenameDialog(false)
    }
  }, [isUpdating])

  return (
    <div>
      <GroupCardWrapper>
        <GroupCard isTagOver={isOver && canDrop} ref={dropRef}>
          <HeaderWrapper>
            <Title>{!data.id ? t('tag:no_group_tags') : data.title}</Title>
            <ActionWrapper>
              {!!data.id && (
                <Dropdown
                  isOpen={isOpenDropdown}
                  setIsOpen={setIsOpenDropdown}
                  placement="bottom-end"
                  anchorElem={
                    <IconButton>
                      <Icon
                        icon={ICON.ellipsisV}
                        fontSize="default"
                        color={theme.colors.textPrimaryBlue}
                      />
                    </IconButton>
                  }
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setIsOpenRenameDialog(true)
                        setRenameGroupName('')
                        setRenameGroupNameError('')
                      }}
                    >
                      {t('common:rename')}
                    </MenuItem>
                  </MenuList>
                  <MenuList>
                    <MenuItem onClick={() => setIsOpenDeleteDialog(true)}>
                      {t('tag:delete_tag_group')}
                    </MenuItem>
                  </MenuList>
                </Dropdown>
              )}
            </ActionWrapper>
          </HeaderWrapper>
          <TagsWrapper>
            {!data.tags.length && (
              <NoTagDiv>{t('tag:no_tags_in_group')}</NoTagDiv>
            )}
            {data.tags.map((tag: TagSimpleType) => {
              const id = `${tag.tagType}_${tag.id}`

              return (
                <DraggableTag
                  key={id}
                  groupId={data.id}
                  isTarget={selectedTagId === id}
                  data={tag}
                  deletable={Boolean(data.id)}
                  handleMoveTag={handleMoveTag}
                />
              )
            })}
          </TagsWrapper>
          {!data.id && (
            <>
              <HorizontalLine />
              <ConditionalTooltip
                title={t('tag:over_groups_amount_limitation', {
                  amount: TAG_GROUP_MAX_COUNT,
                })}
                isShow={OverGroupsAmountLimitation}
              >
                <AddButtonWrapper>
                  <AddButton
                    label={t('tag:add_tag_group')}
                    size="small"
                    bgColor="transparent"
                    color={theme.colors.textPrimaryBlue}
                    onClick={() => {
                      setIsOpenAddDialog(true)
                      setAddGroupName('')
                      setAddGroupNameError('')
                    }}
                    disabled={OverGroupsAmountLimitation}
                  />
                </AddButtonWrapper>
              </ConditionalTooltip>
            </>
          )}
        </GroupCard>
        {isDisabled && (
          <>
            <LoadingFilter />
            <LoadingProgress />
          </>
        )}
      </GroupCardWrapper>
      <ConfirmDialog
        isLoading={isUpdating}
        isOpen={isOpenRenameDialog}
        modalTitle={t('tag:rename')}
        onClose={() => {
          setIsOpenRenameDialog(false)
        }}
        onConfirm={() => {
          if (renameGroupName.length > MAX_LIST_NAME_LENGTH) {
            setRenameGroupNameError(
              t('common:errors.max_count', {
                count: MAX_LIST_NAME_LENGTH,
              })
            )
            return
          }
          updateTagGroup(data.id, renameGroupName)
        }}
      >
        <TextInput
          error={!!renameGroupNameError}
          fullWidth
          onChange={({ target }) => {
            setRenameGroupName(target.value)
          }}
          placeholder={t('tag:please_enter_group_name')}
          value={renameGroupName}
        />
        <TextInputErrorText>{renameGroupNameError}</TextInputErrorText>
      </ConfirmDialog>
      <ConfirmDialog
        isLoading={isAdding}
        isOpen={isOpenAddDialog}
        modalTitle={t('tag:add_tag_group')}
        onClose={() => {
          setIsOpenAddDialog(false)
        }}
        onConfirm={() => {
          if (addGroupName.length > MAX_LIST_NAME_LENGTH) {
            setAddGroupNameError(
              t('common:errors.max_count', {
                count: MAX_LIST_NAME_LENGTH,
              })
            )
            return
          }
          addTagGroup(addGroupName)
        }}
      >
        <TextInput
          error={!!addGroupNameError}
          fullWidth
          onChange={({ target }) => {
            setAddGroupName(target.value)
          }}
          placeholder={t('tag:please_enter_group_name')}
          value={addGroupName}
        />
        <TextInputErrorText>{addGroupNameError}</TextInputErrorText>
      </ConfirmDialog>
      <DeleteConfirmDialog
        isLoading={isDeleting}
        isOpen={isOpenDeleteDialog}
        onClose={() => {
          setIsOpenDeleteDialog(false)
        }}
        onConfirm={() => {
          deleteTagGroup(data.id)
        }}
        modalTitle={t('tag:delete_tag_group_title')}
      />
    </div>
  )
}

export default TagBoard
