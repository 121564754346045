import {
  type PaginationParams,
  type PaginationResponse,
  api,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  ThirdPartyAppCreatePayload,
  ThirdPartyAppGroup,
  ThirdPartyAppIntegration,
  ThirdPartyAppIntegrationListItem,
  ThirdPartyAppProvider,
} from './type'

const thirdPartyAppIntegration = api.injectEndpoints({
  endpoints: builder => ({
    getThirdPartyAppIntegrationList: builder.query<
      PaginationResponse<ThirdPartyAppIntegrationListItem>,
      // 後端 API 不提供 iterable 其作為篩選條件
      PaginationParams & { groupType?: Exclude<ThirdPartyAppGroup, 'iterable'> }
    >({
      query: payload => ({
        url: '/third_party_app_integrations',
        params: payload,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(
          res as PaginationResponse<ThirdPartyAppIntegrationListItem>
        ),
      providesTags: [{ type: 'ThirdPartyAppIntegration', id: 'LIST' }],
    }),
    getThirdPartyAppIntegrationGroups: builder.query<
      { types: ThirdPartyAppProvider[] },
      { groupType?: Exclude<ThirdPartyAppGroup, 'iterable'> }
    >({
      query: ({ groupType }) => ({
        url: '/third_party_app_integration_groups',
        params: { groupType },
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(
          res as { types: ThirdPartyAppProvider[] }
        ),
      providesTags: [{ type: 'ThirdPartyAppIntegrationGroup', id: 'LIST' }],
    }),
    getThirdPartyAppIntegration: builder.query<
      ThirdPartyAppIntegration,
      number
    >({
      query: id => `/third_party_app_integrations/${id}`,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as ThirdPartyAppIntegration),
      providesTags: (result, error, id) => [
        { type: 'ThirdPartyAppIntegration', id },
      ],
    }),
    createThirdPartyAppIntegration: builder.mutation<
      ThirdPartyAppIntegration,
      ThirdPartyAppCreatePayload
    >({
      query: payload => ({
        url: '/third_party_app_integrations',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        {
          type: 'ThirdPartyAppIntegration',
          id: 'LIST',
        },
        {
          type: 'MsgChannel',
          id: 'LIST',
        },
      ],
    }),
    patchUpdateThirdPartyAppIntegration: builder.mutation<
      ThirdPartyAppIntegration,
      Partial<ThirdPartyAppCreatePayload> & { id: number }
    >({
      query: payload => ({
        url: `/third_party_app_integrations/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, params) => [
        { type: 'ThirdPartyAppIntegration', id: 'LIST' },
        { type: 'ThirdPartyAppIntegration', id: params.id },
        { type: 'MsgChannel', id: 'LIST' },
      ],
    }),
    deleteThirdPartyAppIntegrationById: builder.mutation<void, number>({
      query: id => ({
        url: `/third_party_app_integrations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ThirdPartyAppIntegration', id: 'LIST' },
        { type: 'ThirdPartyTag', id: 'LIST' },
        { type: 'ThirdPartyTag' },
        { type: 'MsgChannel', id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetThirdPartyAppIntegrationListQuery,
  useGetThirdPartyAppIntegrationGroupsQuery,
  useGetThirdPartyAppIntegrationQuery,
  useCreateThirdPartyAppIntegrationMutation,
  usePatchUpdateThirdPartyAppIntegrationMutation,
  useDeleteThirdPartyAppIntegrationByIdMutation,
} = thirdPartyAppIntegration
