import type { DashboardIndicator } from '../IndicatorList'
import type { ChartData } from '../types'
import formatIndicatorValue from './formatIndicatorValue'
import getLatestSeries from './getLatestSeries'

export const getDashboardIndicatorSum = (
  chartData: ChartData[]
): DashboardIndicator[] => {
  const seriesLatest = getLatestSeries(chartData[0].series)
  const totalValue = seriesLatest.data.reduce((acc, curr) => acc + curr, 0)

  return [{ indicator: 'sum', value: formatIndicatorValue(totalValue) }]
}

export default getDashboardIndicatorSum
