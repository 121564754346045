import { MouseEvent } from 'react'

import { LightBlueGradientButton } from '@shared/ui/buttons'
import { CompleteIcon } from '@shared/ui/icons'

import { ICON_MARGIN_LEFT } from './constants'

type ButtonWithCompleteIconProps = {
  onClick: (e: MouseEvent<EventTarget>) => void
  width?: number
  wording?: string
}

export const ButtonWithCompleteIcon = ({
  onClick,
  width,
  wording,
}: ButtonWithCompleteIconProps) => (
  <LightBlueGradientButton width={width} onClick={onClick}>
    {wording}
    <CompleteIcon marginLeft={ICON_MARGIN_LEFT} />
  </LightBlueGradientButton>
)

export default ButtonWithCompleteIcon
