import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '@shared/api/rtkQuery/api'

import type {
  ProductAigc,
  ProductsKeywordsAigc,
  ProductsKeywordsAigcExport,
  ProductsKeywordsAigcParams,
} from './type'

const productsKeywordAigc = api.injectEndpoints({
  endpoints: builder => ({
    getProductsKeywordsAigcList: builder.query<
      ProductsKeywordsAigc[],
      ProductsKeywordsAigcParams & { pagination: PaginationParams }
    >({
      query: payload => ({
        url: '/products/keywords/aigc/list',
        method: 'POST',
        body: payload,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ProductsKeywordsAigc[]),
      providesTags: result =>
        result
          ? [
              ...result?.map(({ productId }) => ({
                type: 'ProductsKeywordsAigc' as const,
                id: productId,
              })),
              { type: 'ProductsKeywordsAigc', id: 'LIST' },
            ]
          : [{ type: 'ProductsKeywordsAigc', id: 'LIST' }],
    }),
    getProductsKeywordsAigcListProducts: builder.query<
      PaginationResponse<ProductAigc>,
      { pagination: PaginationParams; name?: string }
    >({
      query: payload => ({
        url: '/products/keywords/aigc/list_products',
        method: 'POST',
        body: payload,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as PaginationResponse<ProductAigc>),
      providesTags: result =>
        result
          ? [
              ...result.items?.map(({ productId }) => ({
                type: 'ProductsAigc' as const,
                id: productId,
              })),
              { type: 'ProductsAigc', id: 'LIST' },
            ]
          : [{ type: 'ProductsAigc', id: 'LIST' }],
    }),

    exportProductsKeywordsAigcListCSV: builder.mutation<
      ProductsKeywordsAigcExport,
      ProductsKeywordsAigcParams
    >({
      query: payload => ({
        url: '/products/keywords/aigc/export',
        method: 'POST',
        body: payload,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ProductsKeywordsAigcExport),
    }),
  }),
})

export const {
  useGetProductsKeywordsAigcListQuery,
  useGetProductsKeywordsAigcListProductsQuery,
  useExportProductsKeywordsAigcListCSVMutation,
} = productsKeywordAigc
