import Box from '@mui/material/Box'

import Tooltip from './Tooltip'
import type { Placement } from './types'

type ConditionalTooltipProps = {
  children: JSX.Element
  containerDisplay?: 'block' | 'inline-block'
  isShow: boolean
  placement?: Placement
  title: string
}

export const ConditionalTooltip = ({
  children,
  containerDisplay = 'inline-block',
  isShow,
  placement,
  title,
}: ConditionalTooltipProps) => {
  if (isShow) {
    return (
      <Tooltip title={title} placement={placement}>
        <Box sx={{ display: containerDisplay }}>{children}</Box>
      </Tooltip>
    )
  }

  return <>{children}</>
}
