import { forwardRef, useMemo } from 'react'
import type { EditorRef, UnlayerOptions } from 'react-email-editor'
import ReactEmailEditor from 'react-email-editor'
import { useTranslation } from 'react-i18next'

import { UNLAYER_TOOLS_COMPONENT_TYPE_OPTIONS } from '@entities/mediumTemplates'
import { useAppSelector } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'

type EmailEditorProps = {
  minHeight?: string | number
  onReady: () => void
}

export const EmailEditor = forwardRef<EditorRef, EmailEditorProps>(
  ({ minHeight, onReady }, ref) => {
    const projectIKAId = useAppSelector(state => state.auth.projectIKAId)
    const { i18n } = useTranslation(['common'])

    const options = useMemo<UnlayerOptions>(() => {
      const [, id] = projectIKAId.split('-')

      return {
        projectId: getIntSafe(import.meta.env.VITE_EMAIL_EDITOR_PROJECT_ID),
        user: {
          id: getIntSafe(`${import.meta.env.VITE_EMAIL_EDITOR_ENV_ID}${id}`),
        },
        displayMode: 'email',
        locale: i18n.language,
        translations: {
          'zh-TW': {
            'editor.placeholder.text': '這裡沒有內容。 從右側拖曳內容。',
            'labels.merge_tags': '顧客資料變數',
          },
        },
        features: {
          preheaderText: false,
        },
        customJS: [
          import.meta.env.DEV
            ? 'http://localhost:3000/lib/unlayer-custom-tool.umd.js'
            : import.meta.env.VITE_EMAIL_EDITOR_CUSTOM_JS_URL,
        ],
        tools: {
          'custom#commercial_tool': {
            properties: {
              componentType: {
                editor: {
                  data: {
                    options: UNLAYER_TOOLS_COMPONENT_TYPE_OPTIONS,
                  },
                },
              } as any,
            },
          },
        },
      }
    }, [i18n.language, projectIKAId])

    return (
      <ReactEmailEditor
        ref={ref}
        minHeight={minHeight}
        options={options}
        onReady={onReady}
      />
    )
  }
)

export default EmailEditor
