import { AxiosError } from 'axios'
import { push } from 'redux-first-history'
import { Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import {
  catchError,
  filter,
  mergeMap,
  startWith,
  switchMap,
} from 'rxjs/operators'

import {
  formatApiError,
  handleApiError,
  setLoading,
  startLoading,
} from '@entities/apiHandler'
import {
  deleteApplication as deleteApplicationApi,
  getApplicationDetail,
  getApplicationList,
  postCreateApplication,
} from '@shared/api/axios/_v1/application'
import { CATEGORY, PAGE_ROOT, PAGES } from '@shared/model/constants/routes'

import {
  createApplication,
  createApplicationFailure,
  createApplicationSuccess,
  deleteApplication,
  deleteApplicationFailure,
  deleteApplicationSuccess,
  fetchApplicationDetail,
  fetchApplicationDetailFailure,
  fetchApplicationDetailSuccess,
  fetchApplicationList,
  fetchApplicationListFailure,
  fetchApplicationListSuccess,
  fetchLastOneHundredApplicationList,
  fetchLastOneHundredApplicationListFailure,
  fetchLastOneHundredApplicationListSuccess,
  setIsApplicationDetailFetching,
  setIsApplicationFetching,
} from './slice'

const fetchApplicationListEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(fetchApplicationList.match),
    switchMap(action => {
      const { rowsPerPage } = state$.value.v1.settings.application
      const { page } = action.payload

      return from(getApplicationList({ rowsPerPage, currPage: page })).pipe(
        mergeMap(({ data, totalCount }) =>
          of(
            fetchApplicationListSuccess({ data, totalCount, page }),
            setLoading()
          )
        ),
        catchError((error: AxiosError) => {
          const err = formatApiError(error, PAGES.settingsApplicationOverview)

          return of(
            fetchApplicationListFailure(),
            setLoading(),
            handleApiError(err)
          )
        }),
        startWith(setIsApplicationFetching(true), startLoading())
      )
    })
  )

const createApplicationEpic: Epic<RootAction, RootAction, RootState> =
  actions$ =>
    actions$.pipe(
      filter(createApplication.match),
      switchMap(action => {
        const { name } = action.payload

        return from(postCreateApplication({ name })).pipe(
          mergeMap(res =>
            of(
              createApplicationSuccess(),
              push(`/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}/${res.appId}`)
            )
          ),
          catchError((error: AxiosError) => {
            const err = formatApiError(error, PAGES.settingApplicationCreate)

            return of(createApplicationFailure(), handleApiError(err))
          })
        )
      })
    )

const deleteApplicationEpic: Epic<RootAction, RootAction, RootState> =
  actions$ =>
    actions$.pipe(
      filter(deleteApplication.match),
      switchMap(action => {
        const { appId } = action.payload

        return from(deleteApplicationApi({ appId })).pipe(
          mergeMap(() =>
            of(
              deleteApplicationSuccess(),
              push(`/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}`)
            )
          ),
          catchError((error: AxiosError) => {
            const err = formatApiError(error, [
              PAGES.settingApplicationCreate,
              PAGES.settingApplicationDetail,
            ])

            return of(deleteApplicationFailure(), handleApiError(err))
          })
        )
      })
    )

const fetchApplicationDetailEpic: Epic<RootAction, RootAction, RootState> =
  actions$ =>
    actions$.pipe(
      filter(fetchApplicationDetail.match),
      switchMap(action => {
        const { appId } = action.payload

        return from(getApplicationDetail({ appId })).pipe(
          mergeMap(res => of(fetchApplicationDetailSuccess(res), setLoading())),
          catchError((error: AxiosError) => {
            const err = formatApiError(error, PAGES.settingApplicationDetail)

            return of(
              fetchApplicationDetailFailure(),
              setLoading(),
              handleApiError(err)
            )
          }),
          startWith(setIsApplicationDetailFetching(true), startLoading())
        )
      })
    )

const fetchLastOneHundredApplicationListEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = (actions$, state$) =>
  actions$.pipe(
    filter(fetchLastOneHundredApplicationList.match),
    switchMap(action => {
      const { lastOneHundredApplications } =
        state$.value.v1.settings.application

      if (lastOneHundredApplications.length) {
        return of(setIsApplicationFetching(false))
      }

      return from(getApplicationList({ rowsPerPage: 100, currPage: 0 })).pipe(
        mergeMap(res => of(fetchLastOneHundredApplicationListSuccess(res))),
        catchError((error: AxiosError) => {
          const err = formatApiError(error, PAGES.settingsApplicationOverview)

          return of(
            fetchLastOneHundredApplicationListFailure(),
            handleApiError(err)
          )
        }),
        startWith(setIsApplicationFetching(true))
      )
    })
  )

const epics = [
  fetchApplicationListEpic,
  createApplicationEpic,
  deleteApplicationEpic,
  fetchApplicationDetailEpic,
  fetchLastOneHundredApplicationListEpic,
]

export default epics
