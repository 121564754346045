export const UNPROCESSABLE_ENTITY_ERROR_CODE = {
  UPPER_LIMIT_ERROR: 1,

  // https://www.notion.so/ikala/db55a83673db4a75b6fddc662be84690?pvs=4#31fb64342268469db06a4110e8df604e
  // 圖表分組操作錯誤
  CONTAINS_MULTIPLE_DIMENSIONAL_GROUP_BY_FIELDS: 11002, // 用兩張以上的維度表做維度分析
  INVALID_GROUP_SOURCE: 11003, // 嘗試使用 user profile 相依的維度表分群
  // 以下有對應錯誤訊息
  DIMENSIONAL_GROUP_BY_AND_DIMENSIONAL_STATISTIC_MEASURE_ARE_INCONSISTENT: 11004, //用 A 維度表分群、B 維度表計算總數量
  USE_NON_DIMENSIONAL_MEASURE_WITH_DIMENSIONAL_GROUP_BY: 11005, //用維度表分群, 但嘗試用「總人數」、「總數量」等非維度表指標
  USING_DIMENSIONAL_GROUP_BY_WITH_RELEVANT_FIELD: 11006, //在漏斗開啟關聯屬性，且使用維度表相關分群
}

export const BAD_REQUEST_ERROR_CODE = {
  GROUP_ID_NOT_FOUND: 2,
}

export const STATUS_CODE = {
  BAD_REQUEST: 400,
  // 儲存其他人建立的私人圖表 => 403
  PERMISSION_DENIED: 403,
  UNPROCESSABLE_ENTITY: 422,
}

export const NO_DATA_ID = 0
