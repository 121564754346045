import { useTranslation } from 'react-i18next'

import {
  AUDIENCE_FILTER_EVENT_MAX_COUNT,
  AudienceFilterListItem,
  AudienceFilterListItemContainer,
  AudienceFilterListItemHeader,
  AudienceFilterNestedListItem,
  AudienceFilterNestedListItemContainer,
  FilterCombinator,
} from '@features/filters/_shared'
import type { CampaignEventFilterNode } from '@shared/api/rtkQuery'
import { useGetWorkflowListQuery } from '@shared/api/rtkQuery'
import { AddButton } from '@shared/ui/buttons'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import type { CampaignEventFilterAction } from './campaignEventFilterReducer'
import { createInitialState } from './campaignEventFilterReducer'
import { WORK_FLOW_LIST_QUERY_PARAMS } from './constants'
import MeasureFilter from './MeasureFilter'

type Props = {
  state?: CampaignEventFilterNode
  dispatch: (action: CampaignEventFilterAction) => void
  isDisabled: boolean
}

const CampaignEventFilter = ({
  state: { node: filter } = createInitialState(),
  dispatch,
  isDisabled = !dispatch,
}: Props) => {
  const { t } = useTranslation(['audience'])
  // 先 query workflow list，讓 child component 可以拿到 cache 的結果，減少等待時間
  useGetWorkflowListQuery(WORK_FLOW_LIST_QUERY_PARAMS)
  return (
    <>
      {filter.nodes.length > 0 && (
        <FilterCombinator
          combination={filter.combination}
          isDisabled={isDisabled}
          onChange={() => {
            dispatch({ type: 'toggleAudienceFilterCombination' })
          }}
        />
      )}

      <ul>
        {filter.nodes.map((item, index) => (
          <AudienceFilterListItem
            key={`workflow-event-filter-${index}`}
            data-combination={filter.combination.toUpperCase()}
          >
            {item.type === 'group' && item.nodes.length > 1 && (
              <AudienceFilterListItemHeader>
                <FilterCombinator
                  combination={item.combination}
                  isDisabled={isDisabled}
                  onChange={() => {
                    dispatch({
                      type: 'toggleAudienceFilterGroupCombination',
                      payload: index,
                    })
                  }}
                />
              </AudienceFilterListItemHeader>
            )}

            <AudienceFilterListItemContainer>
              {item.type === 'group' && (
                <>
                  <ul>
                    {item.nodes.map((nestedItem, nestedIndex) => (
                      <AudienceFilterNestedListItem
                        key={`workflow-event-filter-${index}-${nestedIndex}`}
                        data-combination={
                          item.nodes.length > 1
                            ? filter.nodes[index].combination.toUpperCase()
                            : ''
                        }
                      >
                        <AudienceFilterNestedListItemContainer>
                          <MeasureFilter
                            state={nestedItem}
                            isCloneDisabled={
                              item.nodes.length >=
                              AUDIENCE_FILTER_EVENT_MAX_COUNT
                            }
                            isDisabled={isDisabled}
                            dispatch={action => {
                              dispatch({
                                type: 'updateNestedMeasure',
                                payload: {
                                  parentIndex: index,
                                  nestedIndex,
                                  measureAction: action,
                                },
                              })
                            }}
                            onDelete={() => {
                              dispatch({
                                type: 'removeNestedMeasure',
                                payload: {
                                  parentIndex: index,
                                  nestedIndex,
                                },
                              })
                            }}
                            onClone={() => {
                              dispatch({
                                type: 'cloneNestedMeasure',
                                payload: {
                                  targetParentIndex: index,
                                  sourceNestedIndex: nestedIndex,
                                },
                              })
                            }}
                          />
                        </AudienceFilterNestedListItemContainer>
                      </AudienceFilterNestedListItem>
                    ))}
                  </ul>

                  {!isDisabled && (
                    <ConditionalTooltip
                      title={t('audience:v3.filter_max_hint_event', {
                        max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
                      })}
                      isShow={
                        item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT
                      }
                    >
                      <AddButton
                        label={t('audience:add')}
                        size="small"
                        color={theme.colors.textPrimaryBlue}
                        disabled={
                          item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT
                        }
                        onClick={() => {
                          dispatch({
                            type: 'addNestedMeasure',
                            payload: {
                              index,
                            },
                          })
                        }}
                      />
                    </ConditionalTooltip>
                  )}
                </>
              )}
            </AudienceFilterListItemContainer>
          </AudienceFilterListItem>
        ))}
      </ul>

      {!isDisabled && (
        <ConditionalTooltip
          title={t('audience:v3.filter_max_hint_event', {
            max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
          })}
          isShow={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
        >
          <AddButton
            label={t('audience:add')}
            size="small"
            disabled={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
            onClick={() => {
              dispatch({
                type: 'addAudienceFilterGroupItem',
              })
            }}
          />
        </ConditionalTooltip>
      )}
    </>
  )
}

export default CampaignEventFilter
