import type { Node } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'

import type { NodeType } from '../types'

export const createNewNode = (
  node: { type: NodeType; position: { x: number; y: number } },
  data: unknown = {}
): Node => ({ ...node, id: uuidv4(), data })

export default createNewNode
