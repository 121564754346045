import styled from '@emotion/styled'
import { spacing } from '@mui/system'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-template-areas: 'label input' '. error';
  align-items: center;
  gap: 8px;

  ${spacing}
`

export const GridLabel = styled.label`
  grid-area: label;

  font-weight: 500;
`

export const GridInput = styled.div`
  grid-area: input;

  font-weight: 500;
`

export const GridError = styled.p`
  grid-area: error;

  display: flex;
  align-items: center;

  min-height: 32px;
  color: ${({ theme }) => theme.colors.orangeyRed};
`
