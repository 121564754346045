import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import theme from '@theme'

const Container = styled.div<{ bgImageUrl: string }>`
  display: flex;

  margin: 64px auto;
  margin-bottom: 96px;

  width: 66vw;
  min-width: 840px;
  height: 30vw;
  min-height: 440px;

  background-image: url('${({ bgImageUrl }) => bgImageUrl}');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
`

const NoticeText = styled.div`
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

type Props = {
  title: string
  description: string
  bgImageUrl: string
  notice: string
}

const TrialFeatureCover = ({
  title,
  description,
  bgImageUrl,
  notice,
}: Props) => {
  return (
    <Container bgImageUrl={bgImageUrl}>
      <Box
        flexBasis="32vw"
        minWidth="360px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          component="h1"
          color={theme.colors.black}
          fontSize="44px"
          fontWeight={600}
          mb={2}
        >
          {title}
        </Box>

        <Box
          component="p"
          color={theme.colors.raven}
          fontSize="20px"
          fontWeight={400}
          maxWidth={350}
        >
          {description}
        </Box>

        <Box mt={2}>
          <NoticeText>{notice}</NoticeText>
        </Box>
      </Box>
    </Container>
  )
}

export default TrialFeatureCover
