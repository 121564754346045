import curry from 'lodash/curry'

import { UserJourneyEvent } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { getToday } from '@shared/lib/utils/time'
import { ONE_DAY_MS } from '@shared/model/constants/time'

export const filterUserJourneyEventsByPeriod = curry(
  (period: 'all' | 30 | 60 | 90 | 180, events: UserJourneyEvent['events']) =>
    period !== 'all'
      ? events.filter(event => {
          const eventDateTime = new Date(event.createdAt).getTime()
          const periodDateTime =
            getToday().getTime() - getIntSafe(period) * ONE_DAY_MS

          return eventDateTime > periodDateTime
        })
      : events
)

export default filterUserJourneyEventsByPeriod
