import type { TFunction } from 'i18next'

import type { FilterError } from '../../model/types'

export const getRelevanceAttributeError = (
  filterError: FilterError,
  t: TFunction<('analytics' | 'audience')[]>
) => {
  if (filterError.relevanceAttribute === 'REQUIRED_MINIMUM') {
    return t('analytics:funnel.error_msg.at_least_two_relevance_property')
  }
  if (filterError.relevanceAttribute === 'MULTIPLE_DATATYPE') {
    return t('analytics:funnel.error_msg.same_data_type')
  }
  return ''
}
