import { useRef, useState } from 'react'
import Menu from '@mui/material/Menu'
import type { MenuItemProps } from '@mui/material/MenuItem'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem } from '@shared/ui/menu'
import theme from '@theme'

type Props = {
  label: string
} & MenuItemProps

const NestedMenuItem = ({ label, children, ...MenuItemProps }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLabelHover, setIsLabelHover] = useState(false)
  const menuItemRef = useRef<HTMLLIElement>(null)

  const handleOpen = () => {
    setIsOpen(true)
    setIsLabelHover(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setIsLabelHover(false)
  }

  return (
    <div>
      <MenuItem
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...MenuItemProps}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        ref={menuItemRef}
      >
        {label}
        <Icon
          icon={ICON.arrowRight}
          fontSize="small"
          color={
            isLabelHover
              ? theme.colors.brightBlue
              : theme.colors.textPrimaryBlue
          }
        />
      </MenuItem>
      <Menu
        style={{ pointerEvents: 'none' }}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        {children}
      </Menu>
    </div>
  )
}

export default NestedMenuItem
