import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import camelCase from 'lodash/camelCase'

import type { OrderType } from '@shared/api/rtkQuery'
import type { Rfm } from '@shared/api/rtkQuery'
import { DataTable } from '@shared/ui/table'

import columns from './columns'

type Props = Pick<Rfm, 'rfmGroups' | 'source'>

type OrderBy = keyof Omit<Rfm['rfmGroups'][number], 'name'> | ''

const DistributionTable = ({ rfmGroups, source }: Props) => {
  const { t } = useTranslation(['rfm'])
  const [orderBy, setOrderBy] = useState<OrderBy>('')
  const [orderType, setOrderType] = useState<OrderType>('asc')

  const handleSorting = (orderby: string, orderType: string) => {
    setOrderBy(camelCase(orderby) as OrderBy)
    setOrderType(orderType as OrderType)
  }

  const sortedRfmGroups = useMemo(() => {
    if (!orderBy) {
      return [...rfmGroups]
    }

    const _sortedRfmGroups = [...rfmGroups].sort((a, b) =>
      orderType === 'asc'
        ? Number(a[orderBy]) - Number(b[orderBy])
        : Number(b[orderBy]) - Number(a[orderBy])
    )

    return _sortedRfmGroups
  }, [rfmGroups, orderBy, orderType])

  const tableColumns = useMemo(() => columns(source, t), [source, t])

  return (
    <DataTable
      rowIdKey="name"
      columns={tableColumns}
      rows={sortedRfmGroups}
      count={rfmGroups.length}
      onSorting={handleSorting}
      isHidePagination
      defaultSortBy="user_count"
      defaultSortDirection="desc"
    />
  )
}

export default DistributionTable
