import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

import { SendSmsDialog } from '@entities/mediumTemplates'
import type { SendType } from '@shared/api/rtkQuery'
import { extractImageTypeAndBase64 } from '@shared/lib/utils/textMessage/utils'

type Props = {
  sendType: SendType
  subject: string
  message: string
  uploadImageDataURL: string
  appIntegrationId: number
}

export const SendTestingTextMessageButton = ({
  sendType,
  subject,
  message,
  uploadImageDataURL,
  appIntegrationId,
}: Props) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false)

  const { t } = useTranslation(['contentTemplate'])

  const { imageType, base64 } = extractImageTypeAndBase64(uploadImageDataURL)

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        sx={{ ml: 4, width: 200, height: 42 }}
        onClick={() => setIsOpenDialog(true)}
      >
        {t('contentTemplate:send_test_sms')}
      </Button>

      <SendSmsDialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        sendType={sendType}
        integrationId={appIntegrationId}
        messageData={{
          subject,
          message,
          imageType,
          base64,
        }}
      />
    </>
  )
}

export default SendTestingTextMessageButton
