import {
  $applyNodeReplacement,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

import MetadataTagComponent, {
  Props as MetadataTagPayload,
} from './MetadataTagComponent'

export const NODE_TYPE = 'metadataTag'

export type SerializedMetadataTagNode = Spread<
  MetadataTagPayload & {
    type: typeof NODE_TYPE
    version: 1
  },
  SerializedLexicalNode
>

export class MetadataTagNode extends DecoratorNode<JSX.Element> {
  __displayName: string
  __replacementKey: string

  static getType(): string {
    return NODE_TYPE
  }

  static clone(node: MetadataTagNode): MetadataTagNode {
    return new MetadataTagNode(
      node.__displayName,
      node.__replacementKey,
      node.__key
    )
  }

  constructor(displayName: string, replacementKey: string, key?: NodeKey) {
    super(key)

    this.__displayName = displayName
    this.__replacementKey = replacementKey
  }

  static importJSON(
    serializedNode: SerializedMetadataTagNode
  ): MetadataTagNode {
    const node = $createMetadataTagNode(
      serializedNode.displayName,
      serializedNode.replacementKey
    )

    return node
  }

  exportJSON(): SerializedMetadataTagNode {
    return {
      displayName: this.__displayName,
      replacementKey: this.__replacementKey,
      nodeKey: this.getKey(),
      type: NODE_TYPE,
      version: 1,
    }
  }

  createDOM(): HTMLElement {
    return document.createElement('span')
  }

  updateDOM(): boolean {
    return false
  }

  decorate(): JSX.Element {
    return (
      <MetadataTagComponent
        displayName={this.__displayName}
        replacementKey={this.__replacementKey}
        nodeKey={this.getKey()}
      />
    )
  }
}

export const $createMetadataTagNode = (
  displayName: string,
  replacementKey: string
): MetadataTagNode =>
  $applyNodeReplacement(new MetadataTagNode(displayName, replacementKey))

export const $isMetadataTagNode = (
  node: LexicalNode | null | undefined
): node is MetadataTagNode => node instanceof MetadataTagNode

export default MetadataTagNode
