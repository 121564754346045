import styled from '@emotion/styled'

import { Z_INDEX } from '@shared/model/constants/styles'

import { INITIAL_POS, Placement } from './models'

export const Wrapper = styled.div<{
  isOpen: boolean
  top: number
  left: number
  width: number | 'auto'
  placement: Placement
  transformStyle: string
  positionType: string
}>`
  position: ${({ positionType }) => positionType || 'absolute'};
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => (width === 'auto' ? 'auto' : `${width}px`)};
  transform: ${({ transformStyle }) => transformStyle};

  opacity: ${({ isOpen, top, left }) =>
    isOpen && top !== INITIAL_POS && left !== INITIAL_POS ? 1 : 0};
  visibility: ${({ isOpen, top, left }) =>
    isOpen && top !== INITIAL_POS && left !== INITIAL_POS
      ? 'visible'
      : 'hidden'};

  z-index: ${Z_INDEX.popup};
  transition: visibility 0s, opacity 0.35s;
`
