import { useMemo } from 'react'

import type { CampaignStatistic } from '@shared/api/rtkQuery'
import {
  calculateTableSum,
  processData,
} from '@widgets/usageManagement/SendingAmount/lib/helper'

import useColumns from '../useColumns'
import useFooterColumns from '../useFooterColumns'

type Props = {
  data: CampaignStatistic[]
}

const useTable = ({ data }: Props) => {
  const tableData = useMemo(() => processData(data), [data])
  const tableSumData = useMemo(() => calculateTableSum(tableData), [tableData])
  const tableColumns = useColumns()
  const tableFooterColumns = useFooterColumns()

  return { tableData, tableSumData, tableColumns, tableFooterColumns }
}

export default useTable
