import styled from '@emotion/styled'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'

import Card from '@shared/ui/Card'
import { getTableCellPaddingRight } from '@shared/ui/table/TableCell/commonStyle'

export const TableContainerCard = styled(Card)`
  padding: 8px;
`

export const TableDetailHeadCell = styled(
  ({
    isFirstRowCell,
    isLastRowCell,
    width,
    ...otherProps
  }: { isFirstRowCell: boolean; isLastRowCell: boolean } & TableCellProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiTableCell {...otherProps} />
  )
)`
  && {
    padding-top: 4px;
    padding-right: ${({ isLastRowCell }) =>
      getTableCellPaddingRight(isLastRowCell)}px;
    padding-bottom: 4px;
    padding-left: ${({ isFirstRowCell }) => (isFirstRowCell ? 60 : 16)}px;
    width: ${({ width }) => width || 'auto'};

    border-bottom: none;
    background-color: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-family: inherit;
    letter-spacing: 1.25px;
    white-space: nowrap;
  }
`
