import { Connection, Edge, MarkerType } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'

export const createNewEdge = ({
  source,
  sourceHandle,
  target,
  targetHandle,
}: Connection): Edge => ({
  source: source || '',
  sourceHandle,
  target: target || '',
  targetHandle,
  id: uuidv4(),
  markerEnd: { type: MarkerType.ArrowClosed },
})

export default createNewEdge
