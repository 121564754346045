import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'

import {
  TAG_TYPE_INTERNAL,
  TagRuleValues,
  useCreateTagMutation,
  useLazyGetTagPreviewQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import type { TagInfoValues } from '@widgets/tag/_shared/components/TagInfo'
import { GRADING_TAG_PATH } from '@widgets/tag/_shared/constants'

import useHandleApiError from './useHandleApiError'
import useTagValidator from './useTagValidator'

export const useGradingTag = () => {
  const [tagValues, setTagValues] = useState<
    TagInfoValues<'grading'> & { tagRules: TagRuleValues[] }
  >({
    title: '',
    description: '',
    schedulingType: 'none',
    type: TAG_TYPE_INTERNAL,
    groupId: 0,
    tagRules: [],
  })

  const { tagDataValidator } = useTagValidator()

  const history = useHistory()

  const [handleTagApiError] = useHandleApiError('grading')

  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation()

  const handleTagCreate = useCallback(async () => {
    const isValid = await tagDataValidator('grading', tagValues)

    if (!isValid) {
      return
    }

    try {
      await createTag(tagValues).unwrap()

      history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${GRADING_TAG_PATH}`)
    } catch (e) {
      handleTagApiError(e)
    }
  }, [createTag, handleTagApiError, history, tagDataValidator, tagValues])

  const [selectTagRuleIndex, setSelectTagRuleIndex] = useState<number>(-1)

  const [getTagPreview, { data: previewData, isFetching: isFetchingPreview }] =
    useLazyGetTagPreviewQuery()

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['tag'])

  const handleTagPreview = useCallback(async () => {
    if (!tagValues.tagRules) {
      return
    }

    try {
      await getTagPreview({ tagRules: tagValues.tagRules }).unwrap()
    } catch {
      dispatch(
        openToast({
          message: t('tag:failed_to_get_preview_data'),
          status: 'error',
        })
      )
    }
  }, [dispatch, getTagPreview, t, tagValues.tagRules])

  const handleTagRuleCopy = useCallback(
    (name: string, index: number) => {
      setTagValues(prev => ({
        ...prev,
        tagRules: prev.tagRules.concat({
          ...cloneDeep(prev.tagRules[index]),
          tagValue: name,
        }),
      }))

      setSelectTagRuleIndex(tagValues.tagRules.length)
    },
    [tagValues.tagRules.length]
  )

  const handleTagRuleCreate = useCallback(
    (rule: TagRuleValues) => {
      setTagValues(prev => ({
        ...prev,
        tagRules: prev.tagRules.concat(rule),
      }))

      setSelectTagRuleIndex(tagValues.tagRules.length)
    },
    [tagValues.tagRules.length]
  )

  const handleTagRuleUpdate = useCallback(
    (rule: Partial<TagRuleValues>) => {
      setTagValues(prev => ({
        ...prev,
        tagRules: prev.tagRules.map((tagRule, index) => {
          if (index !== selectTagRuleIndex) {
            return tagRule
          }

          return {
            ...tagRule,
            ...rule,
          }
        }),
      }))
    },
    [selectTagRuleIndex]
  )

  const handleTagRuleDelete = useCallback((deleteIndex: number) => {
    setTagValues(prev => ({
      ...prev,
      tagRules: prev.tagRules.filter((tagRule, index) => deleteIndex !== index),
    }))

    setSelectTagRuleIndex(0)
  }, [])

  const handleTagRuleRename = useCallback(
    (name: string) => {
      setTagValues(prev => ({
        ...prev,
        tagRules: prev.tagRules.map((tagRule, index) => {
          if (index !== selectTagRuleIndex) {
            return tagRule
          }

          return {
            ...tagRule,
            tagValue: name,
          }
        }),
      }))
    },
    [selectTagRuleIndex]
  )

  const userCount = useMemo(() => {
    if (!previewData || !previewData.cdpUsersCount || !previewData.valueInfos) {
      return 0
    }

    return previewData.valueInfos.reduce((acc, curr) => acc + curr.count, 0)
  }, [previewData])

  return {
    handleTagCreate,
    handleTagPreview,
    handleTagRuleCopy,
    handleTagRuleCreate,
    handleTagRuleDelete,
    handleTagRuleRename,
    handleTagRuleUpdate,
    isCreatingTag,
    isFetchingPreview,
    previewData,
    selectTagRuleIndex,
    setSelectTagRuleIndex,
    setTagValues,
    tagValues,
    userCount,
  }
}

export default useGradingTag
