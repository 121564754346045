import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { FacebookAdsErrorFromFacebook } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CreationAndExportStatus } from '@shared/model/constants/listStatus'
import ContactUs from '@shared/ui/ContactUs'

export const useFormatFacebookAdsExportStatus = () => {
  const { t } = useTranslation(['audience', 'common'])

  const formatFacebookAdsExportStatus = useCallback(
    (
      status: CreationAndExportStatus,
      updatedAt?: string,
      fbError?: FacebookAdsErrorFromFacebook
    ) => {
      if (status === 'completed') {
        return (
          <Trans
            ns="audience"
            // 上次完成匯出時間：YYYY-MM-DD-HH-MM-SS
            i18nKey="export_audience_succeed"
            components={{
              Highlight: (
                <Box
                  component="span"
                  sx={{
                    color: theme => theme.colors.brightBlue,
                    fontWeight: 500,
                  }}
                />
              ),
            }}
            values={{
              updateAt: formatDateTimeDisplay(updatedAt ?? ''),
            }}
          />
        )
      }

      if (status === 'failed') {
        if (fbError) {
          return `FB ${fbError.message} - ${fbError.errorUserTitle} : ${fbError.errorUserMsg}`
        }

        return (
          <>
            {t('common:failure_export') + t('common:dot')}
            {t('common:please_try_again_or')}
            <ContactUs text={t('common:contact_us')} />
          </>
        )
      }

      return ''
    },
    [t]
  )

  return formatFacebookAdsExportStatus
}

export default useFormatFacebookAdsExportStatus
