import { useTranslation } from 'react-i18next'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import {
  BAD_REQUEST_ERROR_CODE,
  STATUS_CODE,
  UNPROCESSABLE_ENTITY_ERROR_CODE,
} from '@widgets/analytics/_shared'

type UseErrorHandlerProps = {
  type: 'event' | 'funnel'
}

const useErrorHandler = ({ type }: UseErrorHandlerProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['analytics', 'common'])
  const funnelMaxCount = useAppSelector(
    state => state.auth.plan.analytics.funnel.maxCount
  )

  const eventMaxCount = useAppSelector(
    state => state.auth.plan.analytics.event.maxCount
  )

  const handleError = (e: unknown) => {
    const formattedError = formatRTKQueryError(e)

    if (
      formattedError.statusCode === STATUS_CODE.BAD_REQUEST &&
      formattedError.data?.errorCode ===
        BAD_REQUEST_ERROR_CODE.GROUP_ID_NOT_FOUND
    ) {
      dispatch(
        openToast({
          message: t('analytics:common.error_msg.group_id_is_not_exist'),
          status: 'error',
        })
      )

      return
    }

    if (formattedError.statusCode === STATUS_CODE.UNPROCESSABLE_ENTITY) {
      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.UPPER_LIMIT_ERROR
      ) {
        if (type === 'event') {
          dispatch(
            openToast({
              message: t('analytics:event.error_msg.upper_limit', {
                maxCount: eventMaxCount,
              }),
              status: 'error',
            })
          )
          return
        }

        if (type === 'funnel') {
          dispatch(
            openToast({
              message: t('analytics:funnel.error_msg.upper_limit', {
                maxCount: funnelMaxCount,
              }),
              status: 'error',
            })
          )
          return
        }
      }

      // 分組操作錯誤
      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.CONTAINS_MULTIPLE_DIMENSIONAL_GROUP_BY_FIELDS
      ) {
        dispatch(
          openToast({
            message: t(
              'analytics:common.error_msg.analyzing_field_configuration_error'
            ),
            status: 'error',
          })
        )

        return
      }

      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.INVALID_GROUP_SOURCE
      ) {
        dispatch(
          openToast({
            message: t(
              'analytics:common.error_msg.analyzing_field_configuration_error'
            ),
            status: 'error',
          })
        )

        return
      }

      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.DIMENSIONAL_GROUP_BY_AND_DIMENSIONAL_STATISTIC_MEASURE_ARE_INCONSISTENT
      ) {
        dispatch(
          openToast({
            message: t(
              'analytics:common.error_msg.dimensional_group_by_and_dimensional_statistic_measure_are_inconsistent'
            ),
            status: 'error',
          })
        )

        return
      }

      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.USE_NON_DIMENSIONAL_MEASURE_WITH_DIMENSIONAL_GROUP_BY
      ) {
        dispatch(
          openToast({
            message: t(
              'analytics:common.error_msg.use_non_dimensional_measure_with_dimensional_group_by'
            ),
            status: 'error',
          })
        )

        return
      }

      if (
        formattedError.data?.errorCode ===
        UNPROCESSABLE_ENTITY_ERROR_CODE.USING_DIMENSIONAL_GROUP_BY_WITH_RELEVANT_FIELD
      ) {
        dispatch(
          openToast({
            message: t(
              'analytics:common.error_msg.using_dimensional_group_by_with_relevant_field'
            ),
            status: 'error',
          })
        )

        return
      }
    }

    if (
      formattedError.statusCode === STATUS_CODE.BAD_REQUEST ||
      formattedError.statusCode === STATUS_CODE.PERMISSION_DENIED
    ) {
      dispatch(
        openToast({
          message: t('common:api_error.403_unsupported'),
          status: 'error',
        })
      )
      return
    }

    dispatch(handleApiError(formattedError))
  }

  return { handleError }
}

export default useErrorHandler
