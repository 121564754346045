import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { useDeleteProductsKeywordsBlacklistMutation } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type Props = {
  keyword: string
  isDeletable?: boolean
}

const Keyword = ({ keyword, isDeletable = false }: Props) => {
  const [deleteBlacklistKeyword, { isLoading: isDeleting }] =
    useDeleteProductsKeywordsBlacklistMutation()

  const handleOnDelete = () => deleteBlacklistKeyword(keyword)

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={0.5}
      px={1.5}
      mr={1.5}
      mb={1}
      borderRadius="2px"
      fontSize={14}
      fontWeight={600}
      lineHeight="18px"
      sx={theme => ({
        backgroundColor: theme.colors.bgPaleGrey,
        color: theme.colors.brightBlue,
      })}
    >
      {keyword}
      {isDeletable && (
        <IconButton
          size="small"
          disabled={isDeleting}
          onClick={handleOnDelete}
          sx={{
            padding: '0',
            width: '10px',
            height: '10px',
            fontSize: '14px',
            marginLeft: '8px',
          }}
        >
          <Icon
            id="icon-multiply"
            icon={ICON.multiply}
            color={theme.colors.black}
            fontSize="inherit"
          />
        </IconButton>
      )}
    </Box>
  )
}

export default Keyword
