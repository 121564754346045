import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  IamServiceModuleName,
  useIamServiceEntityQuery,
  useSendRequestMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import theme from '@theme'

import { Container } from './styles'

type Props = {
  moduleName: IamServiceModuleName
  noPermission: {
    title: string
    description: string
    bgImageUrl: string
  }
  children: React.ReactNode
}

export const RequireIAM = ({ moduleName, noPermission, children }: Props) => {
  const {
    data: iamServiceEntity = { entities: {}, ids: [] },
    isLoading,
    isSuccess,
  } = useIamServiceEntityQuery()

  const currentModule = iamServiceEntity.entities[moduleName]

  const [sendRequest] = useSendRequestMutation()

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['iam'])

  if (isLoading || !currentModule) {
    return (
      // 為了避免 API 呼叫過程出現 footer 造成畫面閃爍
      // 透過負外距將 Copyright 隱藏到網頁上方，雖然可恥但是有用
      <Box
        mb="-100vh"
        bgcolor={theme.colors.bgPrimaryGrey}
        position="relative"
      />
    )
  }

  if (isSuccess && !currentModule.activated) {
    return (
      <Container bgImageUrl={noPermission.bgImageUrl}>
        <Box
          flexBasis="32vw"
          minWidth="360px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box
            component="h1"
            color={theme.colors.black}
            fontSize="44px"
            fontWeight={600}
            mb={2}
          >
            {noPermission.title}
          </Box>

          <Box
            component="p"
            color={theme.colors.raven}
            fontSize="20px"
            fontWeight={400}
            mb={6}
          >
            {noPermission.description}
          </Box>

          <DeepBlueGradientButton
            onClick={async () => {
              try {
                await sendRequest({
                  module: moduleName,
                  business: 'provisioning',
                }).unwrap()

                dispatch(
                  openToast({
                    message: t('iam:require_iam.get_support_success'),
                  })
                )
              } catch (e) {
                const typedError = formatRTKQueryError(e)
                dispatch(handleApiError(typedError))
              }
            }}
            mb={4}
            width={200}
          >
            {t('iam:require_iam.get_support')}
          </DeepBlueGradientButton>
        </Box>
      </Container>
    )
  }

  return <>{children}</>
}

export default RequireIAM
