import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Overview from './Overview'

export const ConsumptionsRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}`}>
        <Overview />
      </Route>

      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default ConsumptionsRoutes
