import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'

export type FacebookAdAccount = {
  id: string
  name: string
  // 由於既有邏輯只判斷是否存在 business 屬性，故不細訂出所有細節，有需要可以參考文件：
  // https://developers.facebook.com/docs/marketing-api/reference/business/
  business?: Record<string, unknown>
}

const whitelist = [`https://graph.facebook.com/v17.0`]

const isWhitelisted = (url: string): boolean =>
  whitelist.some(whitelistedUrl => url.startsWith(whitelistedUrl))

export const fetchFacebookAdsAccounts = async (
  userId: string,
  accessToken: string,
  accounts: FacebookAdAccount[] = [],
  // https://developers.facebook.com/docs/graph-api/reference/user/adaccounts/
  url: string = `https://graph.facebook.com/v17.0/${userId}/adaccounts`
): Promise<FacebookAdAccount[] | undefined> => {
  try {
    if (!isWhitelisted(url)) {
      throw new Error('URL is not whitelisted')
    }

    const res = await axios.get<{
      data: FacebookAdAccount[]
      paging?: { next: string }
    }>(url, {
      params: {
        access_token: accessToken,
        fields: 'name,account_id,business',
      },
    })

    const camelCasedData = camelcaseKeys(res.data, { deep: true })

    if (!camelCasedData.data) {
      return []
    }

    // fetch business account
    const businessAccounts = camelCasedData.data.filter(
      acc => acc.business !== undefined
    )

    accounts = accounts.concat(businessAccounts)

    // Facebook 使用 cursor-based 的 pagination，只要有 next 屬性就遞迴呼叫直到取得所有資料
    // https://developers.facebook.com/docs/graph-api/results?locale=en_US
    if (res.data.paging?.next) {
      return fetchFacebookAdsAccounts(
        userId,
        accessToken,
        accounts,
        res.data.paging.next
      )
    }

    return accounts
  } catch (e) {
    return []
  }
}
