import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  type ExportDesignFunction,
  SendEmailDialog,
} from '@entities/mediumTemplates'
import { useAppSelector } from '@shared/lib/hooks/store'
import { ICON, LAYOUT } from '@shared/model/constants/styles'
import ActionBar from '@shared/ui/ActionBar'

import { Button, CircularProgress } from './style'

type Props = {
  isSaving: boolean
  onSave: () => void
  onExportDesign: ExportDesignFunction
}

const SaveTemplateAndSendEmailActionBar = ({
  isSaving,
  onSave,
  onExportDesign,
}: Props) => {
  const { t } = useTranslation(['contentTemplate', 'common'])

  const [isOpenSendEmailDialog, setIsOpenSendEmailDialog] = useState(false)

  const isEnableTestingEmail = useAppSelector(
    state => state.auth.plan.contentTemplate.testingEmail
  )

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="space-between"
        mb={LAYOUT.breadcrumbsMarginBottom + 'px'}
      >
        <ActionBar>
          {isEnableTestingEmail && (
            <Button
              variant="contained"
              color="inherit"
              startIcon={<i className={ICON.envelope} />}
              onClick={() => setIsOpenSendEmailDialog(true)}
            >
              {t('contentTemplate:send_test_email')}
            </Button>
          )}

          <Button
            color="primary"
            variant="contained"
            disabled={isSaving}
            startIcon={
              isSaving ? (
                <CircularProgress size={12} />
              ) : (
                <i className={ICON.save} />
              )
            }
            onClick={onSave}
          >
            {isSaving ? t('common:saving') : t('common:save')}
          </Button>
        </ActionBar>
      </Box>

      {isOpenSendEmailDialog && (
        <SendEmailDialog
          onClose={() => setIsOpenSendEmailDialog(false)}
          onExportDesign={onExportDesign}
        />
      )}
    </>
  )
}

export default SaveTemplateAndSendEmailActionBar
