import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import Box from '@mui/material/Box'

import RequireIAM from '@features/auth/ui/RequireIAM'
import ContentTemplateBgUrl from '@shared/assets/images/iamModule/contentTemplate.svg'
import { PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'

import CreateButton from './ui/ContentSettingCreateButton'
import ContentSettingTab from './ui/ContentSettingTab'
import ContentTemplateCreate from './ui/ContentTemplate/Create'
import ContentTemplateEdit from './ui/ContentTemplate/Edit'
import ContentTemplateOverview from './ui/ContentTemplate/Overview'
import SubscriptionOverview from './ui/MaSubscription/Overview'
import MaTracingUrlOverview from './ui/MaTracingUrl/Overview'

export const ContentSettingRoutes = () => {
  const { path } = useRouteMatch()

  const { t } = useTranslation(['common', 'iam'])

  return (
    <RequireIAM
      moduleName="msg_template"
      noPermission={{
        title: t('common:route.content_setting'),
        description: t('iam:require_iam.content_template'),
        bgImageUrl: ContentTemplateBgUrl,
      }}
    >
      <Switch>
        <Route path={`${path}/${PAGE_ROOT.contentTemplate}/new`}>
          <ContentTemplateCreate />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.contentTemplate}/:contentTemplateId`}>
          <ContentTemplateEdit />
        </Route>

        <Route path={path}>
          <Breadcrumbs actionBar={<CreateButton />}>
            <Box>{t('common:route.content_setting')}</Box>
          </Breadcrumbs>

          <ContentSettingTab />

          <Switch>
            <Route path={`${path}/${PAGE_ROOT.contentTemplate}`}>
              <ContentTemplateOverview />
            </Route>

            <Route path={`${path}/${PAGE_ROOT.maTracingUrl}`}>
              <MaTracingUrlOverview />
            </Route>

            <Route path={`${path}/${PAGE_ROOT.maSubscription}`}>
              <SubscriptionOverview />
            </Route>

            <Redirect to={`${path}/${PAGE_ROOT.contentTemplate}`} />
          </Switch>
        </Route>
      </Switch>
    </RequireIAM>
  )
}
