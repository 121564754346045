import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import SectionTitle from '@shared/ui/SectionTitle'
import TagTypeRadioGroup, {
  TagType,
} from '@widgets/tag/_shared/components/TagType'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

import type { TagSourceType } from '../type'
import { BehaviorTagCopier, GradingTagCopier } from './TagCopier'

type Props = {
  tagType: TagSourceType
}

const TagCopy = ({ tagType }: Props) => {
  const { id } = useParams<{ id: string }>()
  const tagId = getIntSafe(id)

  const { t } = useTranslation(['tag', 'common'])

  const tagTypes = [
    { tagSourceType: 'grading', isEnable: false },
    { tagSourceType: 'behavior', isEnable: false },
    { tagSourceType: 'thirdParty', isEnable: false },
  ] as TagType[]

  return (
    <>
      <Breadcrumbs>
        <Link
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${TagSourceRoutesMap.getRoute(
            tagType
          )}`}
        >
          {t('common:route.tag_list')}
        </Link>
        <Box>{t('tag:create_tag')}</Box>
      </Breadcrumbs>

      <SectionTitle title={t('tag:create_step_1_select_tag_type')} />

      <TagTypeRadioGroup tagTypes={tagTypes} value={tagType} />
      {tagType === 'grading' && <GradingTagCopier tagId={tagId} />}
      {tagType === 'behavior' && <BehaviorTagCopier tagId={tagId} />}
    </>
  )
}

export default TagCopy
