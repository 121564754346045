import styled from '@emotion/styled'

export const TEMPLATE_GAP = 3
const ITEMS_PER_ROW = 3

type TemplateOptionProps = {
  title: string
  img?: string
  onSelect: () => void
  isSelected: boolean
}

const Template = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;

  padding: 16px;
  padding-bottom: 8px;

  width: calc(
    (100% - ${(ITEMS_PER_ROW - 1) * TEMPLATE_GAP * 8}px) / ${ITEMS_PER_ROW}
  );
  height: 200px;

  border: 3px solid transparent;
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.brightBlue : theme.colors.veryLightGrey};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.veryLightGrey};

  &:hover {
    cursor: pointer;
  }
`

const TemplateImgContainer = styled.div`
  flex: 1;

  overflow: hidden;
  padding: 0 48px;
`

const TemplateImg = styled.img`
  width: 100%;
`

const TemplateName = styled.p`
  overflow: hidden;
  margin-top: 8px;
  width: 100%;

  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const TemplateOption = ({
  title,
  img,
  onSelect,
  isSelected,
}: TemplateOptionProps) => {
  return (
    <Template onClick={onSelect} isSelected={isSelected}>
      <TemplateImgContainer>
        <TemplateImg src={img} />
      </TemplateImgContainer>
      <TemplateName>{title}</TemplateName>
    </Template>
  )
}

export default TemplateOption
