import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import type { EventRuleTimeRangeUnit } from '@shared/api/rtkQuery'
import { useI18nOptions } from '@shared/lib/hooks/i18n'
import { NumberOrString } from '@shared/lib/utils/type'
import { ICON } from '@shared/model/constants/styles'
import type { EventChartType } from '@shared/ui/charts'
import DateRangePicker, {
  getLastNDays,
  getLastSamePeriod,
  getLastYearSameDate,
  getRangeType,
} from '@shared/ui/DateRangePicker'
import Dropdown from '@shared/ui/Dropdown'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { COMPARE_DURATION, Unit, UNIT_OPTIONS } from './models/constants'

const CONTAINER_MIN_HEIGHT = 44

type DateAndUnitControlProps = {
  chartType: EventChartType
  timeRangeParams: NumberOrString[]
  timeRangeParamsCompared?: NumberOrString[]
  unit: EventRuleTimeRangeUnit
  onTimeRangeParamsChange: (currTimeRangeParams: NumberOrString[]) => void
  onTimeRangeParamsComparedChange: (
    currTimeRangeParams?: NumberOrString[]
  ) => void
  onUnitChange: (unit: EventRuleTimeRangeUnit) => void
}

const DateAndUnitControl = ({
  chartType,
  timeRangeParams,
  timeRangeParamsCompared,
  unit,
  onTimeRangeParamsChange,
  onTimeRangeParamsComparedChange,
  onUnitChange,
}: DateAndUnitControlProps) => {
  const { t } = useTranslation(['dateAndChart'])
  const unitOptions = useI18nOptions(UNIT_OPTIONS, 'dateAndChart')
  const [isShowCompareMenu, setIsShowCompareMenu] = useState(false)
  const [isInitialShowCompareCalendar, setIsInitialShowCompareCalendar] =
    useState(false)

  const onCompareMenuItemSelected = (value: keyof typeof COMPARE_DURATION) => {
    // 靜態時間 ['2021-12-15T00:00:00.000Z', '2021-12-15T23:59:59.999Z']
    // 動態時間 [-2, 0, 'day']
    const { startDate: currStartDate, endDate: currEndDate } =
      getRangeType(timeRangeParams) === 'absolute_between'
        ? {
            startDate: new Date(timeRangeParams[0]),
            endDate: new Date(timeRangeParams[1]),
          }
        : // 目前 UI 來說，「過去 N 天」只需要考慮第一個參數
          getLastNDays(Math.abs(Number(timeRangeParams[0])))

    switch (value) {
      case COMPARE_DURATION.CUSTOMIZE: {
        onTimeRangeParamsComparedChange([])
        setIsInitialShowCompareCalendar(true)
        break
      }
      case COMPARE_DURATION.LAST_SAME_PERIOD: {
        const { startDate, endDate } = getLastSamePeriod(
          currStartDate,
          currEndDate
        )
        onTimeRangeParamsComparedChange([
          startDate.toISOString(),
          endDate.toISOString(),
        ])
        setIsInitialShowCompareCalendar(false)
        break
      }
      case COMPARE_DURATION.LAST_YEAR_SAME_DURATION: {
        const { startDate } = getLastYearSameDate(currStartDate)
        const { endDate } = getLastYearSameDate(currEndDate)
        onTimeRangeParamsComparedChange([
          startDate.toISOString(),
          endDate.toISOString(),
        ])
        setIsInitialShowCompareCalendar(false)
        break
      }
    }
    setIsShowCompareMenu(false)
  }

  const isCompareDisabled = chartType !== 'line_chart'

  const chartTypeDisplayMap: Record<EventChartType, string> = {
    line_chart: t('dateAndChart:charts.line'),
    bar_chart: t('dateAndChart:charts.bar'),
    pie_chart: t('dateAndChart:charts.pie'),
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      gap={1}
      minHeight={CONTAINER_MIN_HEIGHT}
    >
      <>
        <Icon
          icon={ICON.schedule}
          color={theme.colors.brightBlue}
          fontSize="small"
        />

        <DropdownList
          options={unitOptions}
          value={unit}
          onValueChanged={option => {
            onUnitChange(option.value as Unit)
          }}
          disabled={chartType === 'pie_chart'}
          bgColor={theme.colors.bgPrimaryGrey}
        />
      </>

      <DateRangePicker
        values={timeRangeParams}
        durationBgColor={theme.colors.bgPrimaryGrey}
        placement="bottom-end"
        onChange={onTimeRangeParamsChange}
      />

      <Box display="flex" alignItems="center">
        {timeRangeParamsCompared === undefined ? (
          <Dropdown
            isDisabled={isCompareDisabled}
            isOpen={isShowCompareMenu}
            placement="bottom-end"
            setIsOpen={setIsShowCompareMenu}
            anchorElem={
              <ConditionalTooltip
                isShow={isCompareDisabled}
                title={t('dateAndChart:chart_not_support_compare_date', {
                  chart: chartTypeDisplayMap[chartType],
                })}
              >
                <Button
                  color="primary"
                  disabled={isCompareDisabled}
                  startIcon={
                    <Icon
                      icon={ICON.plusCircle}
                      color="inherit"
                      fontSize="small"
                    />
                  }
                >
                  {t('dateAndChart:compare_to')}
                </Button>
              </ConditionalTooltip>
            }
          >
            <MenuList>
              <MenuItem
                onClick={() => {
                  onCompareMenuItemSelected('LAST_SAME_PERIOD')
                }}
              >
                {t('dateAndChart:last_same_period')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onCompareMenuItemSelected('LAST_YEAR_SAME_DURATION')
                }}
              >
                {t('dateAndChart:last_year_same_duration')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onCompareMenuItemSelected('CUSTOMIZE')
                }}
              >
                {t('dateAndChart:customize')}
              </MenuItem>
            </MenuList>
          </Dropdown>
        ) : (
          <Box
            sx={{
              mr: 1,
              color: theme => theme.colors.brightBlue,
              fontWeight: 500,
            }}
          >
            {t('dateAndChart:compare_to')}
          </Box>
        )}
        {timeRangeParamsCompared && (
          <>
            <Box mr={0.5} display="inline">
              <DateRangePicker
                values={timeRangeParamsCompared}
                durationBgColor={theme.colors.bgPrimaryGrey}
                placement="bottom-end"
                initialOpen={isInitialShowCompareCalendar}
                onChange={onTimeRangeParamsComparedChange}
              />
            </Box>
            <IconButton
              onClick={() => {
                onTimeRangeParamsComparedChange(undefined)
              }}
            >
              <Icon icon={ICON.times} fontSize="small" />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  )
}

export default DateAndUnitControl
