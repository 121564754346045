import { api, transformResponseCamelCaseKeys } from '@shared/api/rtkQuery'

import {
  GetUserValuesFromAIParams,
  UserProfileConfig,
  UserValuesFromAIResponse,
} from './type'

const aiUserProfile = api.injectEndpoints({
  endpoints: build => ({
    getUserValuesFromAi: build.query<
      UserValuesFromAIResponse,
      GetUserValuesFromAIParams
    >({
      query: params => ({
        url: 'ai_user_profile/user_values',
        params,
      }),
      transformResponse: (data: UserValuesFromAIResponse) =>
        transformResponseCamelCaseKeys(data),
    }),
    getUserProfileConfig: build.query<
      Array<UserProfileConfig>,
      { type: 'pltv' | 'retention' }
    >({
      query: params => ({
        url: '/ai_user_profile/configs',
        params,
      }),
      transformResponse: (data: Array<UserProfileConfig>) =>
        transformResponseCamelCaseKeys(data),
      providesTags: (_result, _error, arg) => [
        { type: 'UserProfileConfigs', id: arg?.type },
      ],
    }),
  }),
})

export const { useGetUserValuesFromAiQuery, useGetUserProfileConfigQuery } =
  aiUserProfile
