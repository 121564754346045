import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  ProductHashTagsOrderBy,
  ProductHashTagsOrderType,
  useGetProductHashTagsQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable } from '@shared/ui/table'

import { useSharedSetState, useSharedState } from '../SharedStateContext'
import columns from './columns'

type Props = {
  productId: string
}

const ProductTagsTable = ({ productId }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])
  const sharedState = useSharedState()
  const setSharedState = useSharedSetState()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [orderBy, setOrderBy] = useState<ProductHashTagsOrderBy>('mention_rate')
  const [orderType, setOrder] = useState<ProductHashTagsOrderType>('desc')
  const {
    data: productTagsList = INITIAL_PAGINATION_RESPONSE,
    isFetching: isProductTagsListFetching,
    isSuccess: isProductTagsListSuccess,
  } = useGetProductHashTagsQuery({
    productId,
    page,
    perPage,
    orderBy,
    orderType,
  })

  useEffect(() => {
    if (isProductTagsListSuccess && productTagsList.items.length === 0) {
      setSharedState(prev => ({
        ...prev,
        isEmptyTags: true,
      }))

      return
    }

    setSharedState(prev => ({
      ...prev,
      isEmptyTags: false,
    }))
  }, [isProductTagsListSuccess, productTagsList.items.length, setSharedState])

  const handleSorting = (orderBy: string, orderType: string) => {
    switch (orderBy) {
      case 'ai_marketing_assistant_mention_rate':
        setOrderBy('mention_rate')
        break

      case 'ai_marketing_assistant_post_count':
        setOrderBy('post_count')
        break

      case 'ai_marketing_assistant_sponsor_count':
        setOrderBy('sponsor_count')
        break

      default:
        setOrderBy(orderBy as ProductHashTagsOrderBy)
        break
    }

    setOrder(orderType as ProductHashTagsOrderType)
    setPage(DEFAULT_PAGE)
    setPerPage(DEFAULT_PER_PAGE)
  }

  if (sharedState.isEmptyTags) {
    return null
  }

  return (
    <Box mt={5}>
      <SectionTitle
        title={t(
          'aiMarketingAssistant:kol_recommendation.product_tags_analysis'
        )}
      />
      <DataTable
        rowIdKey="hashtag"
        columns={columns}
        rows={isProductTagsListFetching ? [] : productTagsList.items}
        count={productTagsList.totalCount}
        page={page - 1}
        rowsPerPage={perPage}
        isLoading={isProductTagsListFetching}
        onSorting={handleSorting}
        onPageChange={newPage => {
          setPage(newPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
        defaultSortBy="ai_marketing_assistant_mention_rate"
        defaultSortDirection={orderType}
      />
    </Box>
  )
}

export default ProductTagsTable
