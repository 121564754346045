import ReactApexChart from 'react-apexcharts'

import { formatNumber } from '@shared/lib/utils/number'
import type { ChartData } from '@shared/ui/charts'
import theme from '@theme'

import { getBarBorderRadius, getBarHeightPercent } from './helpers'

const BAR_CHART_HEIGHT = 500

type Props = {
  data?: ChartData
  tagUserCount: number
}

const BarChart = ({
  data = { series: [], categories: [] },
  tagUserCount,
}: Props) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: false },
    },
    colors: [theme.colors.powderBlue],
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      offsetX: 16,
      formatter: function (val: number, { dataPointIndex }) {
        const percentage = tagUserCount
          ? ((val / tagUserCount) * 100).toFixed(2)
          : 0

        const label = data.categories[dataPointIndex]

        return `${label}：${formatNumber(val)} (${percentage}%)`
      },
      style: { fontSize: '14px', colors: [theme.colors.black] },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: getBarBorderRadius(data.categories.length),
        barHeight: getBarHeightPercent(data.categories.length),
        dataLabels: { position: 'bottom' },
      },
    },
    xaxis: {
      categories: data.categories,
      labels: {
        style: { colors: theme.colors.textSecondBlue },
        formatter: value => `${formatNumber(+value)}`,
      },
    },
    yaxis: { show: false },
    legend: { show: false },
    grid: {
      borderColor: theme.colors.borderColor,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    tooltip: { enabled: false },
  }

  return (
    <ReactApexChart
      options={options}
      series={data.series}
      type="bar"
      height={BAR_CHART_HEIGHT}
    />
  )
}

export default BarChart
