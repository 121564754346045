import styled from '@emotion/styled'

import { LAYOUT } from '@shared/model/constants/styles'

type OuterProps = {
  disabled?: boolean
  hasInput?: boolean
  isError?: boolean
}

export const Outer = styled.div<OuterProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;

  padding: 8px 16px;
  width: 100%;
  min-height: 42px;

  border: ${({ isError, theme }) =>
    `1px solid ${isError ? theme.colors.orangeyRed : 'transparent'}`};
  border-radius: ${LAYOUT.audienceBuilder.inputBorderRadius}px;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colors.veryLightBlue : theme.colors.bgPrimaryGrey};

  font-size: 13px;
  font-weight: 500;
  cursor: ${({ disabled, hasInput }) =>
    disabled || !hasInput ? 'default' : 'text'};

  :focus-within {
    border: ${({ disabled, isError, theme }) =>
      `1px solid ${
        disabled
          ? 'transparent'
          : isError
          ? theme.colors.orangeyRed
          : theme.colors.brightBlue
      }`};
    outline: none;
  }
`

export const Tag = styled.span`
  display: inline-flex;
  align-items: center;

  padding: 0 8px;

  height: 24px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.textPrimaryBlue};
  color: ${({ theme }) => theme.colors.white};
`

export const InvisibleInput = styled.input<{ isError?: boolean }>`
  flex-grow: 1;
  height: 24px;

  border: none;
  background-color: inherit;

  color: ${({ isError, theme }) =>
    isError ? theme.colors.orangeyRed : theme.colors.black};

  font-family: inherit;
  font-weight: inherit;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSecondBlue};
    opacity: 1;
  }
`
