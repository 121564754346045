import * as yup from 'yup'

import i18n from '@i18n'
import type { ReplacementData } from '@shared/api/rtkQuery'
import {
  ACTION_URL_WORD,
  TRACING_URL_WORD,
} from '@shared/model/constants/contentTemplate'
import { MAX_REPLACEMENT_DATA_SMS_COUNT } from '@shared/model/constants/validation'

import type { TimeData } from '../_shared/Timing/timeReducer'

export type ActionSendSmsNodeData = {
  campaignName?: string
  content?: string
  replacementData?: ReplacementData
  retryHour?: number
  title?: string
  reactionTiming?: TimeData
  msgChannelId?: number
}

export const actionSendSmsNodeSchema = yup.object<ActionSendSmsNodeData>({
  campaignName: yup.string().required(),
  content: yup.string().required(),
  retryHour: yup.number().required(),
  title: yup.string(),
  replacementData: yup.mixed<ReplacementData>().test(
    'is-valid',
    () =>
      i18n.t('contentTemplate:errors.merge_tag_max_usage', {
        amount_limitation: MAX_REPLACEMENT_DATA_SMS_COUNT,
      }),
    function (replacementData) {
      if (!replacementData) {
        return true
      }

      return (
        Object.keys(replacementData).length <= MAX_REPLACEMENT_DATA_SMS_COUNT
      )
    }
  ),
  reactionTiming: yup.mixed<TimeData>().test(
    'is-valid',
    () =>
      i18n.t('workflow:errors.node_sms_reaction_timing_invalid_tracking_url'),
    function (value) {
      if (!value) {
        return true
      }

      const replacementData = this.parent.replacementData

      return replacementData
        ? Object.keys(replacementData).some(
            key =>
              key.includes(ACTION_URL_WORD) || key.includes(TRACING_URL_WORD)
          )
        : false
    }
  ),
  msgChannelId: yup.number().required(),
})
