import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import {
  POLLING_INTERVAL,
  useExportProductsKeywordsAigcListCSVMutation,
  useGetDataExportsQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getYYYYMMDDHHMMSS } from '@shared/lib/utils/time'
import { openToast } from '@shared/model/slices'
import CSVButton from '@shared/ui/buttons/CSVButton'

type Props = {
  isStartAnalysis: boolean
  productIdList: string[]
  allProduct: boolean
}

const ExportCSVButton = ({
  isStartAnalysis,
  productIdList,
  allProduct,
}: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])
  const dispatch = useAppDispatch()

  const [dataExportJobId, setDataExportId] = useState<string>('')
  const [pollingInterval, setPollingInterval] = useState<number>()

  const [exportCSV, { isLoading: isExporting, isError: isExportError }] =
    useExportProductsKeywordsAigcListCSVMutation()
  const {
    data: exportInfo = null,
    isFetching: isDataExportLoading,
    isError: isDataExportError,
  } = useGetDataExportsQuery(
    { id: dataExportJobId },
    {
      skip: !dataExportJobId,
      pollingInterval,
    }
  )

  useEffect(() => {
    if (isStartAnalysis) {
      setDataExportId('')
    }
  }, [isStartAnalysis])

  useEffect(() => {
    if (
      exportInfo?.status === 'processing' ||
      exportInfo?.status === 'created'
    ) {
      setPollingInterval(POLLING_INTERVAL)
      return
    }

    setPollingInterval(undefined)
  }, [exportInfo, exportInfo?.status, isDataExportError, isExportError])

  const handleDownload = async () => {
    if (exportInfo?.downloadUrls) {
      try {
        const { data: csvData } = await axios.get(exportInfo?.downloadUrls[0])

        const blob = new Blob([csvData], {
          type: 'text/csv;charset=utf-8;',
        })

        const $a = document.createElement('a')
        $a.href = window.URL.createObjectURL(blob)

        const nowDate = getYYYYMMDDHHMMSS(new Date())

        $a.download = `autotag_${nowDate}.csv`
        $a.style.display = 'none'
        document.body.appendChild($a)
        $a.click()
        $a.remove()
      } catch (e) {
        dispatch(
          openToast({
            message: t(
              'aiMarketingAssistant:auto_tag_analysis.export_download_failed'
            ),
            status: 'error',
          })
        )
      }
    }
  }

  const handleExport = async () => {
    try {
      const { dataExportJobId } = await exportCSV({
        productIdList,
        allProduct,
      }).unwrap()

      setDataExportId(dataExportJobId)
    } catch (e) {
      dispatch(
        openToast({
          message: t('aiMarketingAssistant:auto_tag_analysis.export_failed'),
          status: 'error',
        })
      )
    }
  }

  // 檔案處理中或是 api 請求中
  const isProcessing = [
    exportInfo?.status === 'processing',
    exportInfo?.status === 'created',
    isDataExportLoading,
    isExporting,
  ].some(Boolean)

  // 發生 api 錯誤
  const isError = [
    exportInfo?.status === 'failed',
    isExportError,
    isDataExportError,
  ].some(Boolean)

  // 檔案處理成功，且檔案類型是 product_keyword_aigc
  const isSuccess = [
    exportInfo?.downloadUrls &&
      exportInfo?.status === 'succeeded' &&
      exportInfo?.resourceType === 'product_keyword_aigc',
  ].some(Boolean)

  const status = useMemo(() => {
    if (!dataExportJobId) {
      return 'none'
    }

    if (isProcessing) {
      return 'processing'
    }

    if (isSuccess) {
      return 'completed'
    }

    if (isError) {
      return 'failed'
    }

    return 'none'
  }, [dataExportJobId, isProcessing, isSuccess, isError])

  return (
    <CSVButton
      status={status}
      onExportCSV={handleExport}
      onDownloadCSV={handleDownload}
      isDisabled={false}
      isExpired={false}
      buttonText={{
        export: t('aiMarketingAssistant:auto_tag_analysis.export_to_format', {
          format: 'CSV',
        }),
      }}
    />
  )
}

export default ExportCSVButton
