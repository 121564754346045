import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  CreateIngestionParams,
  IngestionData,
  IngestionErrorCode,
  IngestionResourceType,
  IngestionStatus,
} from '@shared/model/types/ingestion'

/**
 * 1. ingestion: 顧客資料、事件 & 標籤匯入 (上傳進度)
 */
type TaskType = 'ingestion'

type TaskErrorCode = undefined | 'schemaMigration'

type TaskIngestion = IngestionData & { type: TaskType; name: string }

export type TaskEntity = TaskIngestion

type TaskId = `${TaskType}_${IngestionResourceType}_${number}`

export type CreateIngestionPayload<T extends IngestionResourceType> = {
  createParams: CreateIngestionParams<T>
  resourceType: T
  file: File
}

const getTaskId = (
  type: TaskType,
  id: number,
  resourceType: IngestionResourceType
): TaskId => `${type}_${resourceType}_${id}`

export const IMPORT_TAG_USER_TYPE: IngestionResourceType = 'tag_user'
export const IMPORT_BEHAVIOR_TAG_USER_TYPE: IngestionResourceType =
  'behavior_tag_user'
export interface State {
  isCreatingIngestion: boolean
  ids: TaskId[]
  entities: {
    [id: TaskId]: TaskEntity
  }
  /**
   * 由於可以同時建立多個 ingestion，此欄位僅能代表最後送出而非最後完成的項目
   */
  currIngestionId?: number
  currIngestionResourceType?: IngestionResourceType
  errorCode: TaskErrorCode
}

const initialState: State = {
  isCreatingIngestion: false,
  ids: [],
  entities: {},
  errorCode: undefined,
}

const task = createSlice({
  name: 'task',
  initialState,
  reducers: {
    createIngestion: (
      state,
      _action: PayloadAction<CreateIngestionPayload<IngestionResourceType>>
    ) => {
      state.isCreatingIngestion = true
      state.errorCode = undefined
    },
    createIngestionSuccess: (
      state,
      action: PayloadAction<IngestionData & { name: string }>
    ) => {
      const ingestionId = action.payload.id
      const resourceType = action.payload.resourceType

      state.currIngestionId = ingestionId
      state.currIngestionResourceType = resourceType
      state.isCreatingIngestion = false

      const taskId = getTaskId('ingestion', ingestionId, resourceType)

      state.ids.push(taskId)
      state.entities[taskId] = {
        ...action.payload,
        type: 'ingestion',
      }
    },
    createIngestionFailure: (
      state,
      action: PayloadAction<TaskErrorCode | undefined>
    ) => {
      state.isCreatingIngestion = false

      if (action.payload) {
        state.errorCode = action.payload
      }
    },

    pollingIngestion: (
      state,
      action: PayloadAction<{
        id: number
        resourceType: IngestionResourceType
      }>
    ) => {},
    pollingIngestionSuccess: (
      state,
      action: PayloadAction<{
        id: number
        status: IngestionStatus
        errorCode?: IngestionErrorCode
        errorMessage?: string
        resourceType: IngestionResourceType
      }>
    ) => {
      const { id, status, errorCode, errorMessage, resourceType } =
        action.payload

      const taskId = getTaskId('ingestion', id, resourceType)
      const currTask = state.entities[taskId] as IngestionData

      currTask.status = status
      currTask.errorCode = errorCode ?? 'none'
      currTask.errorMessage = errorMessage ?? ''
    },
    pollingIngestionFailure: (
      state,
      action: PayloadAction<{
        id: number
        resourceType: IngestionResourceType
      }>
    ) => {
      state.isCreatingIngestion = false

      const { id, resourceType } = action.payload
      const taskId = getTaskId('ingestion', id, resourceType)
      const currTask = state.entities[taskId] as IngestionData

      currTask.status = 'failed'
      currTask.errorCode = 'internal'
    },
    resetTasks: state => {
      state.ids = initialState.ids
      state.entities = initialState.entities
    },
    resetCurrIngestionId: state => {
      state.currIngestionId = undefined
      state.currIngestionResourceType = undefined
    },
  },
})

export const {
  createIngestion,
  createIngestionFailure,
  createIngestionSuccess,
  pollingIngestion,
  pollingIngestionFailure,
  pollingIngestionSuccess,
  resetTasks,
  resetCurrIngestionId,
} = task.actions

export type TaskAction =
  | ReturnType<typeof createIngestion>
  | ReturnType<typeof createIngestionFailure>
  | ReturnType<typeof createIngestionSuccess>
  | ReturnType<typeof pollingIngestion>
  | ReturnType<typeof pollingIngestionFailure>
  | ReturnType<typeof pollingIngestionSuccess>
  | ReturnType<typeof resetTasks>
  | ReturnType<typeof resetCurrIngestionId>

export default task.reducer

export const selectCurrIngestion = createSelector(
  [
    (state: RootState) => state.infoBox.task.currIngestionId,
    (state: RootState) => state.infoBox.task.currIngestionResourceType,
    (state: RootState) => state.infoBox.task.entities,
  ],
  (ingestionId, ingestionResourceType, entities) =>
    ingestionId && ingestionResourceType
      ? entities[getTaskId('ingestion', ingestionId, ingestionResourceType)]
      : undefined
)

export const selectIsAllTasksDone = createSelector(
  [
    (state: RootState) => state.infoBox.task.ids,
    (state: RootState) => state.infoBox.task.entities,
  ],
  (ids, entities) => {
    const isAllTasksDone = ids.every(
      x => entities[x].status === 'succeeded' || entities[x].status === 'failed'
    )

    return ids.length === 0 || isAllTasksDone
  }
)
