import type { TagUserSummary } from '@shared/api/rtkQuery'
import type { ChartData } from '@shared/ui/charts'

export const transformChartDataList = (
  tagUserSummaries?: TagUserSummary[]
): ChartData[] => {
  if (!tagUserSummaries) {
    return []
  }

  return tagUserSummaries.reduce<ChartData[]>(
    (acc, curr) =>
      acc.concat({
        categories: curr.values.map(x => x.value),
        series: [{ name: '', data: curr.values.map(x => x.count) }],
      }),
    []
  )
}

export const getTagUserCount = (tagUserChartData?: ChartData<number>) => {
  if (!tagUserChartData) {
    return undefined
  }

  return tagUserChartData.series.reduce(
    (acc, curr) =>
      acc + curr.data.reduce((accData, currData) => accData + currData, 0),
    0
  )
}
