import MuiSnackbar from '@mui/material/Snackbar'

import { ICON } from '@shared/model/constants/styles'

import { Error, Icon, ToastContent, Transition } from './styles'

type ToastProps = {
  isOpen: boolean
  message: string
  status?: 'success' | 'error'
  onClose: () => void
}

export const Toast = ({
  isOpen,
  message,
  status = 'success',
  onClose,
}: ToastProps) => (
  <MuiSnackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={isOpen}
    autoHideDuration={5000}
    TransitionComponent={Transition}
    onClose={() => {
      onClose()
    }}
  >
    <ToastContent elevation={6} isError={status === 'error'}>
      <Icon>
        {status === 'error' ? (
          <Error>
            <i className={ICON.timesCircle} />
          </Error>
        ) : (
          <i className={ICON.checkCircle} />
        )}
      </Icon>
      {message}
    </ToastContent>
  </MuiSnackbar>
)

export default Toast
