import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  AudienceRuleUserExportIterable,
  AudienceRuleUserExportMAAC,
  AudienceRuleUserExportSms,
  AudienceRuleUserExportSuper8,
  MAToolErrorCode,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import ContactUs from '@shared/ui/ContactUs'

import { isAudienceRuleUserExportSms } from './utils'

export const useAudienceRuleUserExportStatus = (
  audienceRuleId: number,
  provider: ThirdPartyAppProvider,
  latestExport?:
    | AudienceRuleUserExportIterable
    | AudienceRuleUserExportMAAC
    | AudienceRuleUserExportSuper8
    | AudienceRuleUserExportSms
    | null
) => {
  const { t } = useTranslation(['audience', 'common'])

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  if (!latestExport) {
    return ''
  }

  if (isAudienceRuleUserExportSms(latestExport)) {
    return latestExport.status === 'failed'
      ? t('audience:export_sms.hint_failed')
      : ''
  }

  if (latestExport.status === 'completed') {
    return (
      <Trans
        ns="audience"
        // 上次完成匯出時間：YYYY-MM-DD-HH-MM-SS
        i18nKey="export_audience_succeed"
        components={{
          Highlight: (
            <Box
              component="span"
              sx={{ color: theme => theme.colors.brightBlue, fontWeight: 500 }}
            />
          ),
        }}
        values={{
          updateAt: formatDateTimeDisplay(latestExport.updatedAt),
        }}
      />
    )
  }

  if (latestExport.status !== 'failed') {
    return ''
  }

  const exportFailure = `${
    provider === 'iterable'
      ? `Iterable list ID: ${audienceRuleId}`
      : latestExport.name
  } ${t('audience:export_failure')}${t('common:dot')}`

  if (latestExport.errorCode === MAToolErrorCode.ExceedLimit) {
    return `${exportFailure}${t('audience:export_exceed_limit', {
      maTool: getThirdPartyProviderName(provider),
    })}`
  }

  if (latestExport.errorCode === MAToolErrorCode.Internal) {
    return (
      <>
        {exportFailure}
        {t('common:please_try_again_or')}
        <ContactUs text={t('common:contact_us')} />
      </>
    )
  }

  if (latestExport.errorCode === MAToolErrorCode.Unauthorized) {
    return `${exportFailure}${t('audience:export_invalid_api_key', {
      maTool: getThirdPartyProviderName(provider),
    })}`
  }

  if (
    latestExport.errorCode ===
    MAToolErrorCode.ThirdPartyApplicationIntegrationNotSet
  ) {
    return `${exportFailure}${t(
      'audience:third_party_application_integration_not_set'
    )}`
  }

  if (latestExport.errorCode === MAToolErrorCode.ResourceNotFound) {
    return `${exportFailure}${t('audience:export_resource_not_found', {
      maTool: getThirdPartyProviderName(provider),
    })}`
  }

  return ''
}

export default useAudienceRuleUserExportStatus
