import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MuiAvatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'

import { logout } from '@entities/auth/model/slices'
import { toggleIsOpen } from '@entities/notification/model/slices'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import FEATURE_TOGGLE from '@shared/model/constants/featureToggle'
import {
  SUPPORTED_LANGUAGE_MAP,
  SUPPORTED_LANGUAGES,
  SupportedLanguage,
} from '@shared/model/constants/locales'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import { ActionIconsButton } from './styles'

const ActionIcons = () => {
  const { t, i18n } = useTranslation('common')

  const isNotificationAllRead = useAppSelector(
    state => state.notification.isAllRead
  )

  const isLanguageSwitchEnabled = useAppSelector(
    state => state.auth.plan.header.languageSwitch
  )

  const userInfo = useAppSelector(state => state.auth.userInfo)

  const dispatch = useAppDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleLangClick = (nextLang: SupportedLanguage) => () => {
    if (nextLang !== i18n.language) {
      i18n.changeLanguage(nextLang)
      setIsOpenLanguage(false)
    }
  }

  const handleAvatarLinkClick = () => {
    setIsOpenAvatar(false)
  }

  const [isOpenLanguage, setIsOpenLanguage] = useState(false)
  const [isOpenAvatar, setIsOpenAvatar] = useState(false)

  return (
    <Box display="flex" alignItems="center">
      {isLanguageSwitchEnabled && (
        <Dropdown
          isOpen={isOpenLanguage}
          setIsOpen={setIsOpenLanguage}
          popperDisablePortal
          anchorElem={
            <ActionIconsButton>
              <Icon
                icon={ICON.globe}
                color={theme.colors.lightNavyBlue}
                fontSize="small"
              />
              {SUPPORTED_LANGUAGE_MAP[i18n.language as SupportedLanguage]}
            </ActionIconsButton>
          }
        >
          {SUPPORTED_LANGUAGES.map(({ value, label }) => (
            <MenuItem key={value} onClick={handleLangClick(value)}>
              {label}
            </MenuItem>
          ))}
        </Dropdown>
      )}

      <IconButton
        sx={{
          ml: -1,
          mr: 2,
          ':hover': {
            backgroundColor: theme => theme.colors.white,
          },
        }}
        onClick={() => dispatch(toggleIsOpen())}
      >
        <Badge
          color="error"
          overlap="circular"
          // 當 badgeContent === 0 時，會隱藏 Badge 元件
          // 由於我們使用 variant="dot" 因此後面的數字 1 實際不會顯示出來
          badgeContent={isNotificationAllRead ? 0 : 1}
          variant="dot"
        >
          <Icon
            icon={ICON.bell}
            color={theme.colors.lightNavyBlue}
            fontSize="small"
          />
        </Badge>
      </IconButton>

      <Dropdown
        isOpen={isOpenAvatar}
        setIsOpen={setIsOpenAvatar}
        popperDisablePortal
        anchorElem={
          <MuiAvatar src={userInfo.photoURL} sx={{ cursor: 'pointer' }} />
        }
      >
        <MenuItem
          component={Link}
          to={`/${CATEGORY.userCenter}/${PAGE_ROOT.users}`}
          onClick={handleAvatarLinkClick}
        >
          {t('route.iam_user')}
        </MenuItem>

        <MenuItem
          component={Link}
          to={`/${CATEGORY.userCenter}/${PAGE_ROOT.policies}`}
          onClick={handleAvatarLinkClick}
        >
          {t('route.iam_policy')}
        </MenuItem>

        {FEATURE_TOGGLE.consumptions && (
          <MenuItem
            component={Link}
            to={`/${CATEGORY.userCenter}/${PAGE_ROOT.consumptions}`}
            onClick={handleAvatarLinkClick}
          >
            {t('route.iam_consumptions')}
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>{t('log_out')}</MenuItem>
      </Dropdown>
    </Box>
  )
}

export default ActionIcons
