import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiCircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import { TagProps, TagType } from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import theme from '@theme'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'

import { ALL_RENDERING_STATUS, createMethod } from '../_shared/constant'
import type { TagJobStatus } from '../_shared/type'

type Props = {
  openDropdownTagId: number | undefined
  tagGroupMap: TagGroupMapType
  setOpenDropdownTagId: React.Dispatch<React.SetStateAction<number>>
}

const useIntelligentTagColumns = ({
  openDropdownTagId,
  tagGroupMap,
  setOpenDropdownTagId,
}: Props) => {
  const { t } = useTranslation(['common', 'tag'])

  const columns: TableColumn<TagProps>[] = useMemo(
    () => [
      {
        field: 'latestJobStatus',
        headerName: '',
        colRatio: 0.5,
        paddingTop: 1,
        paddingBottom: 1,
        valueFormatter: status => {
          if (status === 'failed') {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton>
                  <Icon
                    icon={'uil uil-times-circle'}
                    color={theme.colors.orangeyRed}
                  />
                </IconButton>
              </Box>
            )
          }

          if (status === '') {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton>
                  <Icon
                    icon={'uil uil-times-circle'}
                    color={theme.colors.orangeyRed}
                  />
                </IconButton>
              </Box>
            )
          }

          if (ALL_RENDERING_STATUS.includes(status as TagJobStatus)) {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <MuiCircularProgress size={18} />
              </Box>
            )
          }

          return null
        },
      },
      {
        field: 'title',
        colRatio: 1.5,
        headerName: 'tag_name',
      },
      {
        field: 'groupId',
        colRatio: 1.5,
        headerName: 'tag_group_list',
        valueFormatter: groupId => {
          if (typeof groupId === 'number') {
            return tagGroupMap[getIntSafe(groupId)]
          }
          return ''
        },
      },
      {
        field: 'latestTagUsersCount',
        colRatio: 1.5,
        headerName: 'people_amount',

        valueFormatter: count => {
          if (typeof count === 'number') {
            return (
              <span style={{ whiteSpace: 'nowrap' }}>
                {formatNumber(count)}
              </span>
            )
          }
          return '-'
        },
        align: 'right',
      },
      {
        field: 'type',
        headerName: 'create_way',
        valueFormatter: type => createMethod(t)[type as TagType],
      },
      {
        field: 'type',
        headerName: 'update_way',
        valueFormatter: type => (
          <Box component="span" sx={{ color: theme.colors.brightBlue }}>
            {type === 'internal_offline' ? t('tag:update_way.scheduled') : '-'}
          </Box>
        ),
      },
      {
        field: 'createdAt',
        headerName: 'created_time',
        valueFormatter: createdTime =>
          formatDateTimeDisplay(createdTime as string),
      },
      { field: 'creatorName', headerName: 'created_by' },
      {
        field: 'latestTagUsersCreatedAt',
        headerName: 'latest_tag_version',
        valueFormatter: latestTagUsersCreatedTime => {
          return latestTagUsersCreatedTime
            ? formatDateTimeDisplay(latestTagUsersCreatedTime as string)
            : '-'
        },
      },

      {
        field: 'id',
        colRatio: 0.5,
        headerName: '',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: tagId => {
          return (
            <div
              onClick={event => {
                // 避免觸發 TableRow 的導頁行為
                event.stopPropagation()
              }}
            >
              <Dropdown
                isOpen={openDropdownTagId === tagId}
                placement="left-start"
                setIsOpen={currIsOpen => {
                  if (currIsOpen === false) {
                    // 代表 clickAway
                    setOpenDropdownTagId(-1)
                    return
                  }
                  setOpenDropdownTagId(tagId as number)
                }}
                anchorElem={
                  <IconButton>
                    <Icon
                      icon={'uil uil-ellipsis-v'}
                      fontSize="small"
                      color={'#27527e'}
                    />
                  </IconButton>
                }
              >
                <MenuList onMouseLeave={() => setOpenDropdownTagId(-1)}>
                  <Link
                    to={`/${CATEGORY.cdm}/${
                      PAGE_ROOT.tags
                    }/${TagSourceRoutesMap.getRoute('intelligent')}/${tagId}`}
                  >
                    <MenuItem>{t('tag:tag_detail')}</MenuItem>
                  </Link>
                </MenuList>
              </Dropdown>
            </div>
          )
        },
      },
    ],
    [tagGroupMap, openDropdownTagId, t, setOpenDropdownTagId]
  )

  return columns
}

export default useIntelligentTagColumns
