import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Aggregator, DIMENSION_SOURCE_PREFIX } from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { NestedOption } from '@shared/ui/Dropdown/NestedList'

import {
  dataTypeExtraAggregatorsMap,
  defaultAggregators,
  dimensionExtraAggregators,
} from './constants'

export const useAggregatorOptions = (eventName: string) => {
  const { t } = useTranslation(['common', 'analytics', 'metadata', 'audience'])
  const { eventEntity } = useMetadataQuery()

  const parentAggregatorEntity = useMemo(
    () =>
      new Map<Aggregator, string>([
        ['count', t('analytics:event.aggregators.count')],
        ['count_distinct', t('analytics:event.aggregators.user_count')],
        ['count_per_user', t('analytics:event.aggregators.avg_user_count')],
      ]),
    [t]
  )

  const childAggregatorEntity = useMemo(
    () =>
      new Map<Aggregator, string>([
        ['count_distinct', t('analytics:event.aggregators.distinct_count')],
        ['avg', t('analytics:event.aggregators.avg')],
        ['max', t('analytics:event.aggregators.max')],
        ['min', t('analytics:event.aggregators.min')],
        ['sum', t('analytics:event.aggregators.sum')],
        ['sum_per_user', t('analytics:event.aggregators.avg_user_sum')],
        ['count_id', t('analytics:event.aggregators.count_id')],
        [
          'count_distinct_id',
          t('analytics:event.aggregators.count_distinct_id'),
        ],
        [
          'count_id_per_user',
          t('analytics:event.aggregators.count_id_per_user'),
        ],
        [
          'count_distinct_id_per_user',
          t('analytics:event.aggregators.count_distinct_id_per_user'),
        ],
      ]),
    [t]
  )

  const aggregatorOptions: NestedOption[] = useMemo(() => {
    const defaultOptions = defaultAggregators.map(x => ({
      label: parentAggregatorEntity.get(x),
      value: x,
    })) as NestedOption[]

    if (eventName === '') {
      return defaultOptions
    }

    const currentEvent = eventEntity.events.entities[eventName]

    const extraAggregatorOptions = currentEvent?.propertiesIds.reduce(
      (options, propertyId) => {
        const {
          displayName,
          id: field,
          dataType,
          tableName: source,
          isFromColumnOfDimTable,
        } = currentEvent.propertiesEntity[propertyId]

        if (source.startsWith(DIMENSION_SOURCE_PREFIX)) {
          return options
        }

        const extraAggregators = isFromColumnOfDimTable
          ? dimensionExtraAggregators
          : dataTypeExtraAggregatorsMap[dataType]

        options.push({
          label: displayName,
          value: createMetadataPropertyId(source, field),
          options: extraAggregators.map(aggregator => ({
            label: childAggregatorEntity.get(aggregator) ?? '',
            value: aggregator,
          })),
        })

        return options
      },
      [] as NestedOption[]
    )

    return defaultOptions.concat(extraAggregatorOptions || [])
  }, [
    childAggregatorEntity,
    eventEntity.events,
    parentAggregatorEntity,
    eventName,
  ])

  return aggregatorOptions
}

export default useAggregatorOptions
