import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList } from 'react-window'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'

import type { ReplacementDataTracingURL } from '@shared/api/rtkQuery'
import { useGetTracingUrlListQuery } from '@shared/api/rtkQuery'
import getTracingUrlId from '@shared/lib/utils/getTracingUrlId'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import RoundedSearchBar from '@shared/ui/Dropdown/RoundedSearchBar'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem } from '@shared/ui/menu'
import { Tooltip } from '@shared/ui/tooltips'

import { RoundedButton } from '../_shared/styles'
import { INSERT_UTM_COMMAND } from '../utm/UtmPlugin'

// 先手動將高寬度設成與 NestedList 相同，這樣跟 toolBar/MetadataTag 並排樣式才會一致
const MENU_ITEM_HEIGHT = 34
const MENU_WIDTH = 340
const MENU_MAX_HEIGHT = 140

type UtmMenuItemProps = {
  name: string
  tooltipTitle: string
  style?: React.CSSProperties
  onClick: () => void
}

const UtmMenuItem = ({
  name,
  tooltipTitle,
  style,
  onClick,
}: UtmMenuItemProps) => (
  <MenuItem sx={{ px: 5 }} onClick={onClick} style={style}>
    <Tooltip title={tooltipTitle} placement="right">
      <Box
        sx={{
          width: '100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {name}
      </Box>
    </Tooltip>
  </MenuItem>
)

const Utm = (): JSX.Element => {
  const { t } = useTranslation(['contentTemplate'])
  const [editor] = useLexicalComposerContext()
  const editorIsEditable = editor.isEditable()

  const [isOpen, setIsOpen] = useState(false)

  const { data: tracingUrlList = [], isFetching: isTracingUrlListLoading } =
    useGetTracingUrlListQuery()
  const [searchText, setSearchText] = useState('')

  const filteredTracingUrlList = searchText
    ? tracingUrlList.filter(({ name }) =>
        name.toLowerCase().includes(searchText.toLowerCase())
      )
    : tracingUrlList

  const menuMaxHeight = Math.min(
    MENU_MAX_HEIGHT,
    filteredTracingUrlList.length * MENU_ITEM_HEIGHT
  )

  const handleItemClick = ({
    tracingUrlId,
    name,
    url,
  }: ReplacementDataTracingURL['urlParams']) => {
    editor.dispatchCommand(INSERT_UTM_COMMAND, {
      replacementKey: getTracingUrlId(tracingUrlId),
      replacementData: {
        type: 'tracing_url',
        urlParams: {
          tracingUrlId,
          name,
          url,
        },
      },
    })

    setSearchText('')
    setIsOpen(false)
  }

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popperZIndex={Z_INDEX.dialog}
      isDisabled={!editorIsEditable}
      anchorElem={
        <RoundedButton
          disabled={!editorIsEditable}
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <Icon icon={ICON.plusCircle} fontSize="small" color="inherit" />
          &nbsp;
          {t('contentTemplate:utm_link')}
        </RoundedButton>
      }
    >
      <RoundedSearchBar
        autoFocus
        value={searchText}
        placeholder={t('common:search')}
        onChange={setSearchText}
      />
      <Divider />
      {isTracingUrlListLoading ? (
        <Box
          width={MENU_WIDTH}
          height={MENU_MAX_HEIGHT}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <FixedSizeList
          width={MENU_WIDTH}
          height={menuMaxHeight}
          itemSize={MENU_ITEM_HEIGHT}
          itemCount={filteredTracingUrlList.length}
        >
          {({ index, style }) => (
            <UtmMenuItem
              tooltipTitle={filteredTracingUrlList[index].url}
              name={filteredTracingUrlList[index].name}
              style={style}
              onClick={() =>
                handleItemClick({
                  tracingUrlId: filteredTracingUrlList[index].id,
                  name: filteredTracingUrlList[index].name,
                  url: filteredTracingUrlList[index].url,
                })
              }
            />
          )}
        </FixedSizeList>
      )}
    </Dropdown>
  )
}

export default Utm
