import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import Box from '@mui/material/Box'

import {
  type FlowSubTypeMapped,
  useGetWorkflowNodesAvailabilityQuery,
} from '@shared/api/rtkQuery'
import { getToday, getTomorrow } from '@shared/lib/utils/time'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import ReadMore from '@shared/ui/ReadMore'

import { REACT_FLOW_NO_DRAG } from '../_shared/constants'
import Timing from '../_shared/Timing'
import type { TimeData } from '../_shared/Timing/timeReducer'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import { TimingNodeSchema, timingNodeSchema } from './timingNodeSchema'

export type TimingNodeType = FlowSubTypeMapped['timing'] | 'manual'
type TimingNodeEditDialogProps = {
  isOpen: boolean
  initialValues: TimingNodeSchema
  onConfirm: (values: TimeData) => void
  onClose: () => void
}

const TimingNodeEditDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: TimingNodeEditDialogProps) => {
  const [t] = useTranslation(['workflow'])

  const [values, setValues] = useState<TimingNodeSchema>(initialValues)

  const isValid = useMemo(() => timingNodeSchema.isValidSync(values), [values])

  const handleOnTimeChange = (timeValues: TimingNodeSchema) =>
    setValues(prev => ({
      ...timeValues,
      type: prev.type,
    }))

  const formState = useWorkflowFormState()

  const handleConfirm = () => {
    if (!formState.isEditable) {
      onClose()
      return
    }

    if (isValid) {
      onConfirm(values)
    }
  }

  const { data: features } = useGetWorkflowNodesAvailabilityQuery()

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      isOpen={isOpen}
      modalTitle={t('workflow:node_timing_delay_configuration')}
      maxWidth="md"
      onConfirm={handleConfirm}
      onClose={onClose}
      contentOverflowY="visible"
    >
      <Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Box
            flex={1}
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {t('workflow:node_timing_executive_type.label')}
          </Box>
          <Box flex={5}>
            <DropdownList
              isFullWidth
              popperDisablePortal
              options={[
                {
                  label: t(
                    'workflow:node_timing_executive_type.options.manual'
                  ),
                  value: 'manual',
                },
                {
                  label: t('workflow:node_timing_executive_type.options.best'),
                  value: 'best',
                },
              ]}
              value={values?.type || 'manual'}
              disabled={
                !formState.isEditable || features?.bestEngagement === false
              }
              onValueChanged={option =>
                setValues(prev => ({
                  ...prev,
                  type: option.value as TimingNodeType,
                }))
              }
            />
          </Box>
        </Box>

        {values?.type === 'best' && (
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Box flex={1} />
            <Box flex={5} mt={1} width="100%">
              <ReadMore
                summary={t('workflow:node_timing_executive_type_best_summary')}
                details={t('workflow:node_timing_executive_type_best_details')}
              />
            </Box>
          </Box>
        )}

        <Divider
          sx={theme => ({
            marginTop: '16px',
            marginBottom: '16px',
            width: '100%',
            color: theme.colors.black6,
          })}
        />
        <Timing
          isEditable={formState.isEditable}
          timeData={values}
          onTimeDataChange={handleOnTimeChange}
          isError={!isValid}
          direction="column"
          isHideDateHourAndMinute={values?.type === 'best'}
          isHideDurationHourAndMinute={values?.type === 'best'}
          isHideWeekdayHourAndMinute={values?.type === 'best'}
          minAdvDate={values?.type === 'best' ? getTomorrow() : getToday()}
        />
      </Box>
    </ConfirmDialog>
  )
}

export default TimingNodeEditDialog
