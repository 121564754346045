import styled from '@emotion/styled'

import { DeepBlueGradientButton } from '@shared/ui/buttons'

// 範例： Audiences Detail 的 Try again 按鈕
export const RedButton = styled(DeepBlueGradientButton)`
  && {
    background-image: none;
    background-color: ${({ theme }) => theme.colors.chartRed};
    box-shadow: 0 5px 19px 0 rgba(255, 97, 79, 0.59);

    &:hover {
      background-image: none;
      background-color: ${({ theme }) => theme.colors.darkCoral};
    }
  }
`

export default RedButton
