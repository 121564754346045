import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useDeletePolicyMutation, usePolicyQuery } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, RedButton } from '@shared/ui/buttons'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { Tooltip } from '@shared/ui/tooltips'

import PolicyForm, {
  BUTTON_WIDTH,
  checkIsEditable,
  createPolicyServiceEntity,
  FormValues,
  STATUS_CODE,
} from './PolicyForm'

const Detail = () => {
  const { id } = useParams<{ id: string }>()
  const policyId = getIntSafe(id)

  const { data: policy, isLoading: isLoadingPolicy } = usePolicyQuery(
    policyId,
    {
      skip: policyId === 0,
    }
  )

  const formValues = useMemo<FormValues | undefined>(
    () => createPolicyServiceEntity(policy),
    [policy]
  )

  const [deletePolicy, { isLoading: isDeletingPolicy }] =
    useDeletePolicyMutation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['common'])

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)

  const isEditable = checkIsEditable(policy)

  return (
    <>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.userCenter}/${PAGE_ROOT.policies}`}>
          {t('common:route.iam_policy')}
        </Link>
        {policy?.name && (
          <Tooltip title={policy.name}>
            <Box>{policy.name}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>

      <PolicyForm
        formValues={formValues}
        isLoading={isLoadingPolicy}
        isDisabled
      >
        {isEditable && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={4}>
              <RedButton
                width={BUTTON_WIDTH}
                onClick={() => setIsShowDeleteDialog(true)}
              >
                {t('common:delete')}
              </RedButton>
            </Box>

            <DeepBlueGradientButton
              width={BUTTON_WIDTH}
              onClick={() => {
                history.push(
                  `/${CATEGORY.userCenter}/${PAGE_ROOT.policies}/${id}/edit`
                )
              }}
            >
              {t('common:edit')}
            </DeepBlueGradientButton>
          </Box>
        )}
      </PolicyForm>

      <DeleteConfirmDialog
        modalTitle={t('common:delete_header', { name: policy?.name })}
        isOpen={isShowDeleteDialog}
        isLoading={isDeletingPolicy}
        onClose={() => {
          setIsShowDeleteDialog(false)
        }}
        onConfirm={async () => {
          try {
            await deletePolicy(getIntSafe(id)).unwrap()

            setIsShowDeleteDialog(false)

            history.push(`/${CATEGORY.userCenter}/${PAGE_ROOT.policies}`)
          } catch (error) {
            const typedError = formatRTKQueryError(error)

            if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
              dispatch(
                openToast({
                  message: t('common:this_function_is_not_allowed'),
                  status: 'error',
                })
              )
              return
            }

            dispatch(handleApiError(typedError))
          }
        }}
      />
    </>
  )
}

export default Detail
