import type { ParseKeys } from 'i18next'

import theme from '@theme'

export type LevelListElement = {
  name: string
  visible: boolean
}

type Option = {
  label: ParseKeys<'common'>
  value: number
}

export const ITEM_COUNT_OPTIONS: Option[] = [
  {
    label: 'top_n_items',
    value: 5,
  },
  {
    label: 'top_n_items',
    value: 10,
  },
  {
    label: 'top_n_items',
    value: 15,
  },
  {
    label: 'top_n_items',
    value: 20,
  },
  {
    label: 'top_n_items',
    value: 25,
  },
  {
    label: 'top_n_items',
    value: 30,
  },
  {
    label: 'top_n_items',
    value: 40,
  },
  {
    label: 'top_n_items',
    value: 50,
  },
]

export const COLORS = [
  theme.colors.chartBlue,
  theme.colors.chartAqua,
  theme.colors.chartPeriwinkle,
  theme.colors.chartMaize,
  theme.colors.chartRed,
  theme.colors.darkPastelGreen,
  theme.colors.sky,
  theme.colors.bilobaFlower,
  theme.colors.texasRose,
  theme.colors.salmonTwo,

  theme.colors.blueTwo,
  theme.colors.irisBlue,
  theme.colors.blueVioletTwo,
  theme.colors.deepSkyBlueThree,
  theme.colors.goldenYellow,
  theme.colors.pumpkin,
  theme.colors.lightSeaGreen,
  theme.colors.fuchsiaTwo,
  theme.colors.internationalOrange,
  theme.colors.alizarin,

  theme.colors.cobalt,
  theme.colors.bondiBlue,
  theme.colors.perrywinkle,
  theme.colors.cloud,
  theme.colors.nepal,
  theme.colors.tuscany,
  theme.colors.darkPeach,
  theme.colors.tequila,
  theme.colors.wewak,
  theme.colors.onahau,

  theme.colors.bakersChocolate,
  theme.colors.deepPink,
  theme.colors.freeSpeechRed,
  theme.colors.bilbao,
  theme.colors.mangoTango,
  theme.colors.radicalRedTwo,
  theme.colors.wattle,
  theme.colors.mediumBlue,
  theme.colors.textPrimaryBlue,
  theme.colors.blackPearlTwo,

  theme.colors.tealBlue,
  theme.colors.blueMarguerite,
  theme.colors.kabul,
  theme.colors.purple,
  theme.colors.purpleHeart,
  theme.colors.hotMagenta,
  theme.colors.torchRed,
  theme.colors.lawnGreen,
  theme.colors.heliotrope,
  theme.colors.neonPink,
]

export const CONTROL_SECTION_WIDTH = 200
