import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import { useMutateSmsContentTemplate } from '@entities/mediumTemplates/hooks'
import SendSmsDialog from '@entities/mediumTemplates/ui/SendSmsDialog'
import SmsLexicalEditor from '@entities/mediumTemplates/ui/SmsEditor'
import SmsTemplate, {
  TemplateSms,
} from '@entities/mediumTemplates/ui/SmsTemplate'
import type { ContentTemplate, ReplacementData } from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import { MAX_REPLACEMENT_DATA_SMS_COUNT } from '@shared/model/constants/validation'
import { Dialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'

import {
  Container,
  DialogActions,
  ErrorMessage,
  Field,
  ImportExampleDialogActions,
  Input,
  ProgressContainer,
  TextareaTitle,
  Title,
} from './styles'

type CreateSmsDialogProps = {
  data?: Partial<
    Pick<ContentTemplate, 'id' | 'title' | 'body' | 'replacementData'>
  >
  onClose: () => void
  onLoading?: (isLoading: boolean) => void
}

export const CreateSmsDialog = ({
  data,
  onClose,
  onLoading,
}: CreateSmsDialogProps) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const [title, setTitle] = useState(data?.title || '')
  const [message, setMessage] = useState(data?.body || '')
  const [replacementData, setReplacementData] = useState<ReplacementData>(
    data?.replacementData || {}
  )
  const [showDialog, setShowDialog] = useState<
    'importExample' | 'sendSms' | undefined
  >(undefined)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [selection, setSelection] = useState<TemplateSms>()

  const { mutateSmsContentTemplate, isLoading: isUpdatingSmsContentTemplate } =
    useMutateSmsContentTemplate()

  const isEnableTestingSms = useAppSelector(
    state => state.auth.plan.contentTemplate.testingSms
  )

  if (showDialog === 'importExample') {
    return (
      <Dialog
        open
        onClose={() => setShowDialog(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <Container>
          <Title>
            {t('contentTemplate:please_select_sms_content_template')}
            <IconButton onClick={() => setShowDialog(undefined)}>
              <Icon icon={ICON.multiply} />
            </IconButton>
          </Title>

          <SmsTemplate onChange={setSelection} />

          <ImportExampleDialogActions>
            <Button
              color="inherit"
              onClick={() => {
                setShowDialog(undefined)
              }}
            >
              {t('common:cancel')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (selection) {
                  setShowDialog(undefined)
                  setTitle(selection.title)
                  setMessage(selection.body)
                  setReplacementData(selection.replacementData || {})
                }
              }}
            >
              {t('contentTemplate:select')}
            </Button>
          </ImportExampleDialogActions>
        </Container>
      </Dialog>
    )
  }

  if (showDialog === 'sendSms') {
    return (
      <SendSmsDialog
        isOpen
        isEnableProviderSelector
        onClose={() => setShowDialog(undefined)}
        sendType="sms"
        messageData={{
          subject: title,
          message,
          replacementData,
        }}
      />
    )
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <Container>
        <Title>
          {t('contentTemplate:create_sms_content_template')}
          <IconButton onClick={onClose}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Title>
        <Input
          color="primary"
          name="name"
          value={title}
          placeholder={t('contentTemplate:please_enter_content_template_name')}
          fullWidth={true}
          error={false}
          onChange={e => {
            setTitle(e.target.value)
          }}
        />
        <TextareaTitle>
          {t('contentTemplate:sms_content')}

          <div>
            {isEnableTestingSms && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => setShowDialog('sendSms')}
              >
                {t('contentTemplate:send_test_sms')}
              </Button>
            )}

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowDialog('importExample')}
              startIcon={<Icon icon={ICON.plusCircle} color="inherit" />}
            >
              {t('contentTemplate:load_content_template')}
            </Button>
          </div>
        </TextareaTitle>
        <Field>
          <SmsLexicalEditor
            isEditable
            content={message}
            replacementData={replacementData}
            onContentChange={setMessage}
            onReplacementDataChange={setReplacementData}
          />
        </Field>
        <ErrorMessage>{errorMsg}</ErrorMessage>
      </Container>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          {t('common:cancel')}
        </Button>

        <Button
          disabled={!title || !message}
          onClick={() => {
            if (
              Object.keys(replacementData).length >
              MAX_REPLACEMENT_DATA_SMS_COUNT
            ) {
              setErrorMsg(
                t('contentTemplate:errors.merge_tag_max_usage', {
                  amount_limitation: MAX_REPLACEMENT_DATA_SMS_COUNT,
                })
              )
              return
            }

            setErrorMsg('')
            onLoading?.(isUpdatingSmsContentTemplate)
            mutateSmsContentTemplate({
              body: {
                title,
                mediumType: 'sms',
                body: message,
                sentBodyGenParams: '',
                previewUrl: '',
                replacementData: replacementData,
              },
              id: data?.id,
              onSuccess: onClose,
            })
          }}
        >
          {t('common:save')}
        </Button>
      </DialogActions>

      <ProgressContainer>
        {isUpdatingSmsContentTemplate && <LinearProgress />}
      </ProgressContainer>
    </Dialog>
  )
}

export default CreateSmsDialog
