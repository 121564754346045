import * as yup from 'yup'

// 後端實際使用的是 to_node，這邊是前端存下要作為顯示使用的。
export type LogicBestChannelNodeData = {
  email: boolean
  linePush: boolean
  sms: boolean
}

export type LogicBestChannelType = keyof LogicBestChannelNodeData

export const logicBestChannelNodeSchema = yup
  .object<LogicBestChannelNodeData>()
  .test(
    'at-least-two',
    'validation:at-least-two',
    value => Object.values(value).filter(Boolean).length >= 2
  )
