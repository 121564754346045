import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import LoadingContainer from '@entities/Loader/LoadingContainer'
import AudienceFilter, {
  AudienceFilterAction,
  createAudienceFilterInitialState,
  useAudienceFilter,
} from '@features/filters/AudienceFilter'
import {
  TagRuleValues,
  useCreateTagRuleMutation,
  useDeleteTagRuleMutation,
  useUpdateTagRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import { CarouselItem, CarouselWrapper } from '@shared/ui/carousel'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import DotLoader from '@shared/ui/loaders/DotLoader'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import {
  AddLevelButton,
  CancelButton,
  ContentWrapper,
  DescriptionRow,
  DialogInput,
  EditButton,
  EditButtonGroup,
  HeaderTitle,
  HeaderWrapper,
  HorizontalLine,
  InputDescription,
  StyledCard,
  Text,
} from './styles'
import TagDropdown from './TagDropdown'

export const TAG_RULE_MAX_COUNT = 24

const CAROUSEL_CLASS_NAME = {
  wrapper: 'carousel-wrapper',
  item: 'carousel-item',
}

type TagRuleAction = {
  type: 'create' | 'copy' | 'rename'
  title: string
  index: number
}

type Props = {
  // 傳入 tagId 代表是編輯既有的標籤，會出現額外的「編輯」按鈕
  tagId?: number
  values: TagRuleValues[]
  selectTagRuleIndex: number
  isFetching: boolean
  onSelectTagRuleIndexChange: (index: number) => void
  // 以下操作都是「新增」才需要，因此標記為 optional
  onUpdate?: (rule: Partial<TagRuleValues>) => void
  onCreate?: (rule: TagRuleValues) => void
  onCopy?: (name: string, index: number) => void
  onRename?: (name: string) => void
  onDelete?: (index: number) => void
}

const TagRule = ({
  tagId,
  values,
  selectTagRuleIndex,
  isFetching,
  onSelectTagRuleIndexChange,
  onUpdate,
  onCreate,
  onCopy,
  onRename,
  onDelete,
}: Props) => {
  const { t } = useTranslation(['tag', 'common'])

  const [deleteTagRuleIndex, setDeleteTagRuleIndex] = useState<number>()

  // 新增標籤 (tagId === undefined) 初始即可修改內容
  // 已建立的標籤 (tagId !== undefined) 初始是不能修改內容，需要額外點選「編輯」按鈕
  const [canModify, setCanModify] = useState(tagId === undefined)

  const [description, setDescription] = useState<string>('')

  const selectTagRule = useMemo<TagRuleValues | undefined>(
    () => values[selectTagRuleIndex],
    [selectTagRuleIndex, values]
  )

  const {
    values: audienceFilter,
    onDispatch,
    onReset: onAudienceFilterReset,
  } = useAudienceFilter()

  const [isAudienceFilterDirty, setIsAudienceFilterDirty] = useState(false)

  const onAudienceFilterDispatch = useCallback(
    (action: AudienceFilterAction) => {
      onDispatch(action)

      setIsAudienceFilterDirty(true)
    },
    [onDispatch]
  )

  useEffect(() => {
    if (!values[selectTagRuleIndex]) {
      return
    }

    // 僅在用戶切換不同標籤分級時，才將 audienceFilter 重置
    onAudienceFilterReset(values[selectTagRuleIndex].filter)

    setDescription(values[selectTagRuleIndex].description)

    // 切換 selectTagRuleIndex 重設 isAudienceFilterDirty
    setIsAudienceFilterDirty(false)

    // 刻意不將 values 加進 deps list 是因為會造成無窮迴圈
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTagRuleIndex, onAudienceFilterReset])

  useEffect(() => {
    // 需要這個判斷是因為在「新增」or「編輯」行為不同
    // 1. 「新增」需要將 audienceFilter 透過更新回 parent，最後整頁送出打 API
    // 2. 「編輯」則是在切換 tagRule 時就需要各自打 API 儲存
    if (tagId !== undefined) {
      return
    }

    // 只在「新增」時透過 onUpdate 更新回 parent
    onUpdate?.({ filter: audienceFilter })
  }, [tagId, onUpdate, audienceFilter])

  const [createTagRule, { isLoading: isCreatingTagRule }] =
    useCreateTagRuleMutation()

  const [updateTagRule, { isLoading: isUpdatingTagRule }] =
    useUpdateTagRuleMutation()

  const [deleteTagRule, { isLoading: isDeletingTagRule }] =
    useDeleteTagRuleMutation()

  const reduxDispatch = useAppDispatch()

  const saveCurrentTagRule = async () => {
    if (!selectTagRule || !selectTagRule.id || tagId === undefined) {
      return
    }

    try {
      await updateTagRule({
        id: selectTagRule.id,
        tagId,
        body: {
          tagValue: selectTagRule.tagValue,
          description,
          filter: audienceFilter,
        },
      }).unwrap()

      setIsAudienceFilterDirty(false)
    } catch {
      reduxDispatch(
        openToast({
          message: t('tag:error_occurred'),
          status: 'error',
        })
      )
    }
  }

  const saveRemind = async (callback: () => void) => {
    if (tagId === undefined || isAudienceFilterDirty === false) {
      callback()
      return
    }

    if (window.confirm(t('tag:unsaved_changes_will_be_lost'))) {
      await saveCurrentTagRule()
    }

    callback()
  }

  const [tagRuleAction, setTagRuleAction] = useState<TagRuleAction>()

  const handleTagRuleActionConfirm = async (action?: TagRuleAction) => {
    if (action === undefined) {
      return
    }

    if (values.some(x => x.tagValue === action.title)) {
      reduxDispatch(
        openToast({
          message: t('tag:tag_group_name_already_exists'),
          status: 'error',
        })
      )
      return
    }

    if (
      (action.type === 'create' || action.type === 'copy') &&
      values.length === TAG_RULE_MAX_COUNT
    ) {
      reduxDispatch(
        openToast({
          message: t('tag:tag_group_maximum_limit_reached', {
            num: TAG_RULE_MAX_COUNT,
          }),
          status: 'error',
        })
      )
      return
    }

    const addTagRuleStrategy: Record<
      TagRuleAction['type'],
      () => Promise<void> | void
    > = tagId
      ? {
          create: async () => {
            await createTagRule({
              id: tagId,
              body: {
                tagValue: action.title,
                description: '',
                filter: createAudienceFilterInitialState(),
              },
            }).unwrap()

            // 預選倒數第一個項目
            onSelectTagRuleIndexChange(-1)
          },
          copy: async () => {
            await createTagRule({
              id: tagId,
              body: {
                tagValue: action.title,
                description: values[action.index].description,
                filter: values[action.index].filter,
              },
            }).unwrap()

            // 預選倒數第一個項目
            onSelectTagRuleIndexChange(-1)
          },
          rename: async () => {
            const { id } = values[action.index]

            if (id) {
              await updateTagRule({
                id,
                tagId,
                body: { tagValue: action.title },
              }).unwrap()
            }
          },
        }
      : {
          create: () =>
            onCreate?.({
              tagValue: action.title,
              description: '',
              filter: createAudienceFilterInitialState(),
            }),
          copy: () => onCopy?.(action.title, action.index),
          rename: () => onRename?.(action.title),
        }

    await addTagRuleStrategy[action.type]()

    setTagRuleAction(undefined)
  }

  return (
    <>
      {isFetching ? (
        <Box display="flex" justifyContent="center">
          <DotLoader />
        </Box>
      ) : (
        <>
          <StyledCard>
            <HeaderWrapper>
              <HeaderTitle>
                {values.length
                  ? t('tag:divide_users_who_meet_into_n_groups', {
                      num: values.length,
                    })
                  : t('tag:please_add_at_least_group')}
                <Tooltip title={t('tag:create_subcategories_under_tags')}>
                  <Box display={'inline-block'} ml="4px">
                    <Icon
                      icon={ICON.infoCircle}
                      fontSize="small"
                      color={theme.colors.textPrimaryBlue}
                    />
                  </Box>
                </Tooltip>
              </HeaderTitle>

              {tagId !== undefined && (
                <>
                  {canModify ? (
                    <EditButtonGroup>
                      <CancelButton
                        onClick={() => {
                          if (selectTagRule) {
                            setDescription(selectTagRule.description)
                            onAudienceFilterReset(selectTagRule.filter)
                          }

                          setIsAudienceFilterDirty(false)
                          setCanModify(false)
                        }}
                      >
                        {t('common:cancel')}
                      </CancelButton>

                      <EditButton onClick={() => saveCurrentTagRule()}>
                        {t('tag:save_current_tag_group')}
                      </EditButton>

                      <EditButton
                        onClick={() => {
                          saveCurrentTagRule()
                          setCanModify(false)
                        }}
                      >
                        {t('tag:save')}
                      </EditButton>
                    </EditButtonGroup>
                  ) : (
                    <EditButtonGroup>
                      <EditButton onClick={() => setCanModify(true)}>
                        {t('common:edit')}
                      </EditButton>
                    </EditButtonGroup>
                  )}
                </>
              )}
            </HeaderWrapper>

            <LoadingContainer isLoading={isUpdatingTagRule}>
              <Box
                sx={{
                  p: '16px 0',
                  backgroundColor: theme.colors.bgPrimaryGrey,
                  width: '100%',
                }}
              >
                <CarouselWrapper
                  key={`${values.length}_${canModify}`}
                  carouselClassname={CAROUSEL_CLASS_NAME}
                >
                  <Box
                    sx={{
                      p: '0 35px',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    {values.map((ele: TagRuleValues, index: number) => {
                      return (
                        <CarouselItem
                          classname={CAROUSEL_CLASS_NAME.item}
                          key={`rule_${index}`}
                        >
                          <TagDropdown
                            isActive={index === selectTagRuleIndex}
                            key={`rule_${index}`}
                            name={ele.tagValue}
                            disabled={!canModify}
                            deleteDisabled={values.length === 1}
                            onClick={() => {
                              saveRemind(() => {
                                onSelectTagRuleIndexChange(index)
                              })
                            }}
                            onDelete={() => {
                              if (tagId) {
                                setDeleteTagRuleIndex(index)
                                return
                              }

                              onDelete?.(index)
                            }}
                            onCopy={() => {
                              setTagRuleAction({
                                type: 'copy',
                                title: '',
                                index,
                              })
                            }}
                            onRename={() => {
                              setTagRuleAction({
                                type: 'rename',
                                title: ele.tagValue,
                                index,
                              })
                            }}
                          />
                        </CarouselItem>
                      )
                    })}

                    {values.length < TAG_RULE_MAX_COUNT && canModify !== false && (
                      <CarouselItem classname={CAROUSEL_CLASS_NAME.item}>
                        <AddLevelButton
                          variant="outlined"
                          size="small"
                          style={{ fontSize: '12px' }}
                          color="primary"
                          onClick={() => {
                            saveRemind(() => {
                              setTagRuleAction({
                                type: 'create',
                                title: '',
                                index: 0,
                              })
                            })
                          }}
                          startIcon={<i className={ICON.plusCircle} />}
                        >
                          {t('tag:add_group')}
                        </AddLevelButton>
                      </CarouselItem>
                    )}
                  </Box>
                </CarouselWrapper>
              </Box>

              <ContentWrapper>
                <DescriptionRow>
                  <Text>{t('tag:description')}</Text>

                  <InputDescription
                    type="text"
                    marginRightRatio={1}
                    placeholder={t('tag:enter_short_description_for_group')}
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value)

                      setIsAudienceFilterDirty(true)
                    }}
                    // 只有 TagCreator 與 TagCopier 才需要透過 onUpdate 更新回 parent，後續整頁送出建立標籤
                    // 編輯模式會元件內直接打 API 儲存
                    onBlur={() => onUpdate?.({ description })}
                    disabled={!canModify || !selectTagRule}
                  />
                </DescriptionRow>

                {values.length > 0 && (
                  <>
                    <HorizontalLine />

                    <AudienceFilter
                      state={audienceFilter}
                      dispatch={onAudienceFilterDispatch}
                      isDisabled={!canModify}
                    />
                  </>
                )}
              </ContentWrapper>
            </LoadingContainer>
          </StyledCard>
        </>
      )}

      <DeleteConfirmDialog
        isOpen={deleteTagRuleIndex !== undefined}
        isLoading={isDeletingTagRule}
        modalTitle={t('tag:sure_to_delete_this_group')}
        onClose={() => {
          setDeleteTagRuleIndex(undefined)
        }}
        onConfirm={async () => {
          const { tagId, id } = values[deleteTagRuleIndex!]

          if (!tagId || !id) {
            return
          }

          try {
            await deleteTagRule({ tagId, id }).unwrap()

            // 預選刪除後的第一個項目
            // 不寫成 onSelectTagRuleIndexChange(0) 原因是如果刪除第 0 個項目
            // selectTagRuleIndex 就不會變化，造成更新 filter 的 effect 不重新執行
            onSelectTagRuleIndexChange(1 - values.length)

            setDeleteTagRuleIndex(undefined)
          } catch {
            reduxDispatch(
              openToast({
                message: t('common:failure_delete'),
                status: 'error',
              })
            )
          }
        }}
      />

      <ConfirmDialog
        modalTitle={t('tag:new_group_name')}
        isOpen={Boolean(tagRuleAction)}
        isLoading={isCreatingTagRule || isUpdatingTagRule}
        onClose={() => setTagRuleAction(undefined)}
        onConfirm={() => handleTagRuleActionConfirm(tagRuleAction)}
      >
        <DialogInput
          color="primary"
          name="name"
          value={tagRuleAction?.title ?? ''}
          placeholder={t('tag:please_add_new_group_name')}
          fullWidth={true}
          error={false}
          onChange={({ target: { value } }) => {
            setTagRuleAction(prev => ({ ...prev!, title: value }))
          }}
        />
      </ConfirmDialog>
    </>
  )
}

export default TagRule
