import { ReplacementData } from '@shared/api/rtkQuery/contentTemplate'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import { ACTION_URL_WORD } from '@shared/model/constants/contentTemplate'
import { regexReplacementDataActionURL } from '@shared/model/constants/validation'

export const extractActionUrlList = (message: string) => {
  const matches = message.matchAll(regexReplacementDataActionURL)

  return Array.from(matches).reduce<string[]>(
    (acc, [, actionURL]) => acc.concat(actionURL),
    []
  )
}

// 根據當前 message 移除在 replacementData 內未使用到的 action_url
export const removeUnusedActionUrl = (
  message: string,
  replacementData: ReplacementData
) => {
  const replacementDataCloned = cloneDeep(replacementData)

  const usedActionUrlList = extractActionUrlList(message)

  for (const [key, value] of Object.entries(replacementData)) {
    if (value.type === ACTION_URL_WORD && !usedActionUrlList.includes(key)) {
      delete replacementDataCloned[key]
    }
  }

  return replacementDataCloned
}

// 取得 action url 最大的數字 index
export const getLargestIndexOfActionUrl = (data: ReplacementData) => {
  let maxIndex = 0
  for (const [key, value] of Object.entries(data)) {
    if (value.type === ACTION_URL_WORD) {
      let index = parseInt(key.replace(`${ACTION_URL_WORD}_`, ''), 10)
      if (Number.isInteger(index) && index > maxIndex) {
        maxIndex = index
      }
    }
  }
  return maxIndex
}
