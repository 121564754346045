import { createContext, useMemo, useState } from 'react'

const initialState = {
  isSidebarOpen: true,
  setIsSidebarOpen: (isSidebarOpen: boolean) => {},
}

export const SidebarContext = createContext(initialState)

export const SidebarProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const context = useMemo(
    () => ({
      isSidebarOpen,
      setIsSidebarOpen,
    }),
    [isSidebarOpen]
  )

  return (
    <SidebarContext.Provider value={context}>
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext
