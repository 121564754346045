import Box from '@mui/material/Box'

import theme from '@theme'

import { Description, Notice, Title } from './styles'

type Props = {
  title: string
  description: string
  imageUrl: string
  notice?: string
}

const FeatureIntroduction = ({
  title,
  description,
  imageUrl,
  notice,
}: Props) => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="stretch"
      borderRadius={1.5}
      bgcolor={theme.colors.bgIceBlue}
      mb={6}
    >
      <Box pl={3} py={2} maxWidth={850} mr={5}>
        <Title>{title}</Title>
        <Description>{description}</Description>
        {notice && <Notice>{notice}</Notice>}
      </Box>
      <Box display="flex" alignItems="center" py={1} pr={4}>
        <img src={imageUrl} alt={title} />
      </Box>
    </Box>
  )
}

export default FeatureIntroduction
