import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { FormikErrors, FormikTouched } from 'formik'

import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { PrimaryGreyInput, PrimaryGreyTextarea } from '@shared/ui/inputs'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import type { FormValues } from '../ExportTextMessage'
import { Icon, Label } from '../styles'

type FieldValues = Pick<
  FormValues,
  'subject' | 'message' | 'uploadImageDataURL'
>

type Props = {
  values: FieldValues
  touched: FormikTouched<FieldValues>
  errors: FormikErrors<FieldValues>
  priceEvaluation: React.ReactNode
  onSubjectChange: (subject: string) => void
  onMessageChange: (message: string) => void
  onUploadImageDataUrlChange: (uploadImageDataURL: string) => void
}

export const MmsFields = ({
  values,
  touched,
  errors,
  priceEvaluation,
  onSubjectChange,
  onMessageChange,
  onUploadImageDataUrlChange,
}: Props) => {
  const { t } = useTranslation(['audience'])

  const [uploadImage, setUploadImage] = useState<File>()

  const handleUploadImage = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const firstFile = files?.[0]

    if (!firstFile) {
      return
    }

    setUploadImage(firstFile)

    const reader = new FileReader()
    reader.onload = ({ target }) => {
      if (target) {
        onUploadImageDataUrlChange(target.result as string)
      }
    }
    reader.readAsDataURL(firstFile)
  }

  const fileInputEl = useRef<HTMLInputElement>(null)

  return (
    <>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Label>
          {t('audience:export_sms.title', {
            message_type: 'MMS',
          })}
          &nbsp;
          <Tooltip title={t(`audience:export_sms.title_hint.mms`)}>
            <i className={ICON.infoCircle} />
          </Tooltip>
          &nbsp;*
        </Label>

        <Box sx={{ flexGrow: 1 }}>
          <PrimaryGreyInput
            fullWidth
            placeholder={t('audience:export_sms.title_placeholder', {
              message_type: 'MMS',
            })}
            value={values.subject}
            isError={touched.subject && errors.subject !== undefined}
            onChange={({ target: { value } }) => onSubjectChange(value)}
          />

          {touched.subject && errors.subject && (
            <Box sx={{ color: theme => theme.colors.orangeyRed, mt: 1 }}>
              {errors.subject}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex', mb: 4 }}>
        <Label>{t('audience:export_sms.image')} *</Label>

        <Box sx={{ flexGrow: 1 }}>
          <input
            hidden
            ref={fileInputEl}
            type="file"
            onChange={handleUploadImage}
          />

          <DeepBlueGradientButton
            width={120}
            onClick={() => fileInputEl.current?.click()}
          >
            {t('audience:export_sms.upload')}
          </DeepBlueGradientButton>

          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              ml: 2,
            }}
          >
            {uploadImage && (
              <>
                <Icon size={18}>
                  <i className={ICON.paperclip} />
                </Icon>

                <Box
                  component="span"
                  sx={{
                    color: theme => theme.colors.textPrimaryBlue,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const a = document.createElement('a')
                    a.href = window.URL.createObjectURL(uploadImage)
                    a.download = uploadImage.name
                    a.style.display = 'none'
                    document.body.appendChild(a)
                    a.click()
                    a.remove()
                  }}
                >
                  {uploadImage.name}
                </Box>

                <Icon
                  size={20}
                  clickable
                  color={theme.colors.black}
                  onClick={() => {
                    setUploadImage(undefined)
                    onUploadImageDataUrlChange('')
                  }}
                >
                  <i className={ICON.times} />
                </Icon>
              </>
            )}

            {touched.uploadImageDataURL && errors.uploadImageDataURL && (
              <Box sx={{ color: theme => theme.colors.orangeyRed }}>
                {errors.uploadImageDataURL}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Label>{t('audience:export_sms.message')}</Label>

        <Box sx={{ flexGrow: 1 }}>
          <PrimaryGreyTextarea
            placeholder={t('audience:export_sms.message_placeholder')}
            isError={touched.message && errors.message !== undefined}
            onChange={({ target: { value } }) => onMessageChange(value)}
            resize="none"
            minHeight={96}
            value={values.message}
          />

          <Box>
            {touched.message && errors.message !== undefined && (
              <Box sx={{ color: theme => theme.colors.orangeyRed, mt: 1 }}>
                {errors.message}
              </Box>
            )}

            <Box sx={{ mt: 1 }}>{priceEvaluation}</Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MmsFields
