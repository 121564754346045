const calculateEventRowDataByPage = (
  tableData: Record<string, string[]>[],
  curPage: number,
  perPage: number
) =>
  tableData.slice(
    curPage * perPage,
    Math.min(tableData.length, (curPage + 1) * perPage)
  )

export default calculateEventRowDataByPage
