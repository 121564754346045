import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import type { CampaignMediumEvent, MediumType } from '@shared/api/rtkQuery'
import { useGetCampaignsEventSchemaQuery } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import mapCampaignEventSchemaToOptions from './utils/mapCampaignEventSchemaToOptions'
import { CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT } from '../constants'

type Props = {
  isDisabled: boolean
  isDisabledAddTriggerFilterNode: boolean
  mediumType: MediumType
  mediumEvent: CampaignMediumEvent
  onMediumTypeChange: (value: MediumType) => void
  onMediumEventChange: (value: CampaignMediumEvent) => void
  onAddTriggerFilterNode: () => void
}

const CampaignMediumGroupFilter = ({
  isDisabled,
  isDisabledAddTriggerFilterNode,
  mediumType,
  mediumEvent,
  onMediumTypeChange,
  onMediumEventChange,
  onAddTriggerFilterNode,
}: Props) => {
  const { t } = useTranslation(['audience'])
  const { data, isLoading } = useGetCampaignsEventSchemaQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      data: mapCampaignEventSchemaToOptions(data),
      isLoading,
    }),
  })

  if (isLoading) {
    return <CircularProgress />
  }

  if (!data) {
    return null
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <DropdownList
          bgColor={theme.colors.white}
          options={data.mediumTypeOptions}
          value={mediumType}
          disabled={isDisabled}
          popperDisablePortal
          onValueChanged={({ value }) => {
            onMediumTypeChange(value as MediumType)
          }}
        />
        <DropdownList
          bgColor={theme.colors.white}
          options={data.campaignMediumEventOptionsDict[mediumType]}
          value={mediumEvent}
          disabled={isDisabled}
          popperDisablePortal
          onValueChanged={({ value }) => {
            onMediumEventChange(value as CampaignMediumEvent)
          }}
        />
      </Box>
      {!isDisabled && (
        <Tooltip
          title={
            isDisabledAddTriggerFilterNode
              ? t('audience:v3.filter_max_hint_condition', {
                  max: CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT,
                })
              : t('audience:v3.filter_add')
          }
        >
          <span>
            <IconButton
              disabled={isDisabledAddTriggerFilterNode}
              onClick={onAddTriggerFilterNode}
            >
              <Icon
                icon={ICON.plusCircle}
                color={theme.colors.textPrimaryBlue}
              />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  )
}

export default CampaignMediumGroupFilter
