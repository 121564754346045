import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetConsumptionsQuery,
} from '@shared/api/rtkQuery'
import { NumberOrString } from '@shared/lib/utils/type'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import DateRangePicker, {
  DatePickerWrapper,
  getLastNDays,
} from '@shared/ui/DateRangePicker'
import { DataTable } from '@shared/ui/table'
import theme from '@theme'

import columns from './columns'

const Overview = () => {
  const { t } = useTranslation('consumptions')
  const lastMonthRange = getLastNDays(30)
  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)

  const [timeRangeParams, setTimeRangeParams] = useState<NumberOrString[]>([
    lastMonthRange.startDate.toISOString(),
    lastMonthRange.endDate.toISOString(),
  ])

  const { data: consumptionData = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useGetConsumptionsQuery({
      page,
      perPage,
      startTime: timeRangeParams[0] as string,
      endTime: timeRangeParams[1] as string,
    })

  const tableColumns = useMemo(() => columns(t), [t])

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Breadcrumbs>
          <Box>{t('common:route.iam_consumptions')}</Box>
        </Breadcrumbs>
        <Box display="flex" justifyContent="start" alignItems="center">
          <DatePickerWrapper>
            <DateRangePicker
              values={timeRangeParams}
              durationBgColor={theme.colors.white}
              onChange={setTimeRangeParams}
            />
          </DatePickerWrapper>
        </Box>
      </Box>
      <DataTable
        columns={tableColumns}
        rows={isFetching ? [] : consumptionData.items}
        count={consumptionData.totalCount}
        page={page - 1}
        rowsPerPage={perPage}
        isLoading={isFetching}
        onPageChange={newPage => {
          setPage(newPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
      />
    </>
  )
}

export default Overview
