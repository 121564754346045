import { removeSpaces } from '@shared/lib/utils/string'

const removeObjectValueSpaces = <T extends object>(input: T): T =>
  Object.entries(input).reduce(
    (collation: { [key in string]: unknown }, [key, value]) => {
      collation[key] = typeof value === 'string' ? removeSpaces(value) : value
      return collation
    },
    {}
  ) as T

export default removeObjectValueSpaces
