import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import * as firebaseui from 'firebaseui'

import { signInFirebase } from '@entities/auth/model/slices'
import { firebaseApp } from '@shared/lib/firebase'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { useSearchParams } from '@shared/lib/hooks/url'
import { QUERY_STRING } from '@shared/model/constants/routes'
import ContactUs from '@shared/ui/ContactUs'

import {
  FirebaseUIWidgetWrapper,
  Footer,
  IKalaLogoIcon,
  Img,
  LogoWrapper,
  TextWrapper,
  Wrapper,
} from './styles'

import 'firebaseui/dist/firebaseui.css'

export const SignIn = () => {
  const isNoProjectListError = useAppSelector(
    state => state.auth.isNoProjectListError
  )

  let ui = firebaseui.auth.AuthUI.getInstance()
  if (!ui) {
    const auth = getAuth(firebaseApp)
    ui = new firebaseui.auth.AuthUI(auth)
  }

  const dispatch = useAppDispatch()
  const checkFirebaseAuth = useCallback(() => {
    dispatch(signInFirebase())

    // do not redirect automatically, leave it to developer
    return false
  }, [dispatch])

  const uiConfig = useMemo(
    () => ({
      signInFlow: 'popup',
      signInSuccessUrl: '',
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          scopes: ['https://www.googleapis.com/auth/adwords'],
        },
      ],
      tosUrl: '',
      privacyPolicyUrl: '',
      callbacks: {
        signInSuccessWithAuthResult: checkFirebaseAuth,
      },
    }),
    [checkFirebaseAuth]
  )

  const isAuth = useAppSelector(state => state.auth.isAuth)
  const query = useSearchParams()
  const history = useHistory()

  const { t } = useTranslation(['common'])

  useEffect(() => {
    if (isAuth === false) {
      ui?.start('#firebaseui-auth-container', uiConfig)
      return
    }

    // 已經登入的狀態不會停留在 /signIn 頁面
    const returnURL = decodeURIComponent(
      query.get(QUERY_STRING.auth.returnURL) || ''
    )
    const targetURL = returnURL && returnURL.startsWith('/') ? returnURL : '/'
    history.push(targetURL)
  }, [history, query, isAuth, ui, uiConfig])

  return (
    <Wrapper>
      <LogoWrapper>
        <IKalaLogoIcon src="/images/ikala-cdp-logo.png" />
        <FirebaseUIWidgetWrapper isHidden={isNoProjectListError}>
          <div id="firebaseui-auth-container" />
        </FirebaseUIWidgetWrapper>

        {isNoProjectListError && <Img src="/images/error-alert.svg" />}
      </LogoWrapper>
      <Footer>
        {/* 用 div 把句子切開，會讓游標選取 email 更容易 */}
        <TextWrapper>{t('common:sign_in.hint1')}</TextWrapper>
        <TextWrapper>
          <ContactUs />
        </TextWrapper>
        <TextWrapper>
          {t('common:sign_in.hint2')}
          <a href="tel:+886-2-87681110">+886-2-87681110</a>
        </TextWrapper>
      </Footer>
    </Wrapper>
  )
}

export default SignIn
