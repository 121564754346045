import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'

import type { TracingUrlInsight } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import type { TableColumn } from '@shared/ui/table'
import { DataTable } from '@shared/ui/table'

type TracingUrlsDialogProps = {
  isOpen: boolean
  onClose: () => void
  tracingUrlInsights: Array<TracingUrlInsight>
}

const TracingUrlsDialog = ({
  isOpen,
  tracingUrlInsights,
  onClose: handleClose,
}: TracingUrlsDialogProps) => {
  const { t } = useTranslation(['common', 'workflow'])

  const columns: TableColumn<TracingUrlInsight>[] = [
    {
      headerName: 'name',
      field: 'id',
      valueFormatter: (_, { name, archivedAt }) => (
        <>
          <p>{name}</p>
          {archivedAt && (
            <Typography
              sx={{
                color: theme => theme.colors.brightBlue,
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 1,
              }}
            >
              <Icon
                color="inherit"
                fontSize="small"
                icon={ICON.exclamationTriangle}
                component="span"
              />
              {t('workflow:errors.tracing_url_is_archived')}
            </Typography>
          )}
        </>
      ),
      colRatio: 2,
    },
    { headerName: 'url', field: 'url', colRatio: 2 },
    {
      headerName: 'click_uu_count',
      field: 'clickUuCount',
      align: 'right',
      colRatio: 1,
    },
  ]

  return (
    <ConfirmDialog
      modalTitle={t('common:route.ma_tracing_url')}
      isOpen={isOpen}
      maxWidth="lg"
      hasConfirmBtn={false}
      onClose={handleClose}
    >
      <DataTable
        containerType="border"
        columns={columns}
        rows={tracingUrlInsights}
        isHidePagination
      />
    </ConfirmDialog>
  )
}

export default TracingUrlsDialog
