export const NO_DATA_ID = 0
export const NO_DATA = 'no_data'

export const CHART_CARD_HEIGHT = 425
export const CARD_MIN_HEIGHT = 80
export const CHART_HEIGHT = 300
export type ChartType =
  | 'memberNonMember'
  | 'memberStatus'
  | 'gender'
  | 'ageGender'
  | 'city'
  | 'rfm'
  | 'retention'

export const ALL_CHART_TYPE: ChartType[] = [
  'memberNonMember',
  'memberStatus',
  'gender',
  'ageGender',
  'city',
  'rfm',
  'retention',
]
