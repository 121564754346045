import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import {
  EventRule,
  INITIAL_PAGINATION_RESPONSE,
  PrivacyLevel,
  useGetEventRuleGroupListQuery,
  useGetEventRuleListQuery,
} from '@shared/api/rtkQuery'
import InfiniteScrollLoader from '@shared/ui/loaders/InfiniteScrollLoader'

import {
  GROUP_UPPER_BOUNDARY,
  LIST_PER_PAGE,
  SCROLL_DEBOUNCE_TIME,
} from './_shared/constants'
import Empty from './_shared/Empty'
import { ChartWrapper, Content } from './_shared/Style'
import EventChart from './EventChart'

type Props = {
  privacyLevel: PrivacyLevel
  activeGroupId: number
}

const EventList = ({ privacyLevel, activeGroupId }: Props) => {
  const [page, setPage] = useState<number>(1)
  const [data, setData] = useState<EventRule[]>([])

  const {
    data: listData = INITIAL_PAGINATION_RESPONSE,
    isLoading,
    isSuccess,
  } = useGetEventRuleListQuery({
    page,
    perPage: LIST_PER_PAGE,
    privacyLevel,
    groupId: activeGroupId,
  })

  const { groupListData } = useGetEventRuleGroupListQuery(
    { eventRuleType: privacyLevel, page: 1, perPage: GROUP_UPPER_BOUNDARY },
    {
      selectFromResult: ({ data = [] }) => {
        return {
          groupListData: data.map(group => ({
            ...group,
            disabled: group.id === activeGroupId,
          })),
        }
      },
    }
  )

  const pageLimit = listData.totalCount / LIST_PER_PAGE
  const hasMore = page < pageLimit && listData.totalCount > 0
  const isFirstFetching = isLoading && listData.items.length === 0

  useEffect(() => {
    setData(prev => {
      const filteredData = listData.items.filter(
        item => !prev.find(d => d.id === item.id)
      )

      return [...prev, ...filteredData]
    })
  }, [listData.items])

  const handleScrollDebounced = useMemo(
    () =>
      debounce(() => {
        if (hasMore) {
          setPage(page + 1)
        }
      }, SCROLL_DEBOUNCE_TIME),
    [hasMore, page]
  )

  const handleDeleteItemFromList = (id: number) => {
    setData(prev => prev.filter(d => d.id !== id))
  }

  return (
    <>
      <Content>
        {data.map(({ id, title, dashboardConfig, filters }) => (
          <ChartWrapper key={`event_${privacyLevel}_${id}`}>
            <EventChart
              eventId={id}
              title={title}
              chartType={dashboardConfig.chartType}
              metric={dashboardConfig.displayVisibility}
              filters={filters}
              groupList={groupListData}
              updateList={handleDeleteItemFromList}
            />
          </ChartWrapper>
        ))}
      </Content>

      {!isLoading && data.length === 0 && (
        <Empty
          analyticsType="event"
          privacyLevel={privacyLevel}
          groupId={activeGroupId}
        />
      )}

      {((isSuccess && hasMore) || isFirstFetching) && (
        <InfiniteScrollLoader
          isLoading={isLoading}
          loadMore={handleScrollDebounced}
        />
      )}
    </>
  )
}

export default EventList
