import AudienceDetailStateProvider from '@widgets/audience/lib/AudienceDetailContext'

import SectionBreadcrumbs from './SectionBreadcrumbs'
import SectionFeatureIntroduction from './SectionFeatureIntroduction'
import SectionTab from './SectionTab'

type Props = {
  children: React.ReactNode
}

export const AudienceDetailLayout = ({ children }: Props) => (
  <AudienceDetailStateProvider>
    <SectionFeatureIntroduction />
    <SectionBreadcrumbs />
    <SectionTab />
    {children}
  </AudienceDetailStateProvider>
)

export default AudienceDetailLayout
