import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAudienceSummaryMemberNonMemberQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'

import DonutChart from './DonutChart'

const MembersNonMembers = () => {
  const { t } = useTranslation(['audience'])
  const { audienceId } = useAudienceDetailState()

  const { data, isError, isSuccess, isFetching, isLoading, isUninitialized } =
    useGetAudienceSummaryMemberNonMemberQuery(audienceId, {
      skip: audienceId === NO_DATA_ID,
    })

  // 根據 value 排序
  const sortedData = useMemo(() => {
    if (!data) {
      return []
    }

    const { totalMembers, totalNonMembers } = data

    return [
      {
        name: t('audience:total_members'),
        value: totalMembers,
      },
      {
        name: t('audience:total_non_members'),
        value: totalNonMembers,
      },
    ].sort((a, b) => b.value - a.value)
  }, [data, t])

  return (
    <BasicCard
      chartName="MembersNonMembers"
      subject={t('audience:main_components')}
      title={sortedData[0]?.name || ''}
      nonMembers={getIntSafe(data?.totalNonMembers, '').toLocaleString()}
      isFetching={isFetching}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isUninitialized || isLoading}
    >
      <DonutChart
        labels={sortedData.map(item => item.name)}
        series={sortedData.map(item => item.value)}
        total={getIntSafe(data?.totalUsers, 0)}
      />
    </BasicCard>
  )
}

export default MembersNonMembers
