import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  INITIAL_THIRD_PARTY_TAG_DATA,
  useGetThirdPartyTagQuery,
} from '@shared/api/rtkQuery'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { RedButton } from '@shared/ui/buttons'
import { Tooltip } from '@shared/ui/tooltips'
import { TagDeleteConfirmDialog } from '@widgets/tag/_shared/components/TagDialogs'
import TagStatistic from '@widgets/tag/_shared/components/TagStatistic'
import ThirdPartyForm from '@widgets/tag/_shared/components/ThirdPartyForm'
import { THIRD_PARTY_TAG_PATH } from '@widgets/tag/_shared/constants'

const ThirdPartyDetail = () => {
  const { t } = useTranslation(['common', 'tag', 'settings'])
  const { id: tagId = '' } = useParams<{ id: string }>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)

  const {
    data: tagData = INITIAL_THIRD_PARTY_TAG_DATA,
    isLoading: isTagLoading,
  } = useGetThirdPartyTagQuery(tagId)

  const history = useHistory()

  const goToOverview = () =>
    history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${THIRD_PARTY_TAG_PATH}`)

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Breadcrumbs>
          <Link
            to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${THIRD_PARTY_TAG_PATH}`}
          >
            {t('common:route.tag_list')}
          </Link>
          <Tooltip title={tagData.name}>
            <Box>{tagData.name}</Box>
          </Tooltip>
        </Breadcrumbs>

        <RedButton
          onClick={() => setIsOpenDeleteDialog(true)}
          disabled={isTagLoading}
        >
          {t('common:delete')}
        </RedButton>
      </Box>
      <ThirdPartyForm
        isLoading={isTagLoading}
        tagId={tagId}
        name={tagData.name}
        description={tagData.description}
        thirdPartyAppId={tagData.thirdPartyAppId}
        appType={tagData.appType}
      />
      <TagStatistic tagStatisticalData={tagData.tagStatistics} />
      <TagDeleteConfirmDialog
        tagId={tagId}
        isOpen={isOpenDeleteDialog}
        onClose={() => setIsOpenDeleteDialog(false)}
        onFinished={goToOverview}
      />
    </>
  )
}

export default ThirdPartyDetail
