import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { Epic } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, delay, filter, mergeMap } from 'rxjs/operators'

import { formatApiError, handleApiError } from '@entities/apiHandler'
import { ingestionAPI, putUploadFileToGCS } from '@shared/api/axios/ingestion'
import { POLLING_INTERVAL } from '@shared/api/rtkQuery'
import type {
  CreateIngestionParams,
  IngestionData,
  IngestionResourceType,
} from '@shared/model/types/ingestion'

import {
  createIngestion,
  createIngestionFailure,
  createIngestionSuccess,
  pollingIngestion,
  pollingIngestionFailure,
  pollingIngestionSuccess,
} from './taskSlice'

const createIngestionObservable = <T extends IngestionResourceType>(payload: {
  resourceType: IngestionResourceType
  createParams: CreateIngestionParams<T>
}) => ingestionAPI[payload.resourceType].create(payload.createParams)

const handlePutProcessIngestionAction = ({ resourceType, id }: IngestionData) =>
  ingestionAPI[resourceType].trigger(id)

const handleIngestionSuccess = (ingestion: IngestionData, file: File) =>
  of(
    createIngestionSuccess({
      ...ingestion,
      name: file.name,
    }),
    pollingIngestion({
      id: ingestion.id,
      resourceType: ingestion.resourceType,
    })
  )

const createIngestionEpic: Epic<RootAction, RootAction, RootState> = action$ =>
  action$.pipe(
    filter(createIngestion.match),
    mergeMap(({ payload: { file, resourceType, createParams } }) =>
      from(createIngestionObservable({ createParams, resourceType })).pipe(
        mergeMap(ingestion =>
          of(ingestion.id).pipe(
            mergeMap(() =>
              putUploadFileToGCS({ uploadURL: ingestion.gcsPutUrl, file })
            ),
            mergeMap(() => handlePutProcessIngestionAction(ingestion)),
            mergeMap(() => handleIngestionSuccess(ingestion, file))
          )
        ),
        catchError((error: AxiosError) => {
          // 當後端 schema 正在 migration 時會回應 409，並提示用戶稍後再試
          if (error.response?.status === 409) {
            return of(createIngestionFailure('schemaMigration'))
          }

          return of(
            createIngestionFailure(),
            handleApiError(formatApiError(error))
          )
        })
      )
    )
  )

const pollingIngestionObservable = (
  action: PayloadAction<{
    id: number
    resourceType: IngestionResourceType
  }>
) => ingestionAPI[action.payload.resourceType].query(action.payload.id)

const pollingIngestionEpic: Epic<RootAction, RootAction, RootState> =
  actions$ =>
    actions$.pipe(
      filter(pollingIngestion.match),
      mergeMap(action =>
        from(pollingIngestionObservable(action)).pipe(
          mergeMap(({ id, status, errorCode, errorMessage, resourceType }) => {
            if (status !== 'failed' && status !== 'succeeded') {
              return concat(
                of(pollingIngestionSuccess({ id, status, resourceType })),
                of(pollingIngestion({ id, resourceType })).pipe(
                  delay(POLLING_INTERVAL)
                )
              )
            }

            return of(
              pollingIngestionSuccess({
                id,
                status,
                errorCode,
                errorMessage,
                resourceType,
              })
            )
          }),
          catchError((error: AxiosError) =>
            of(
              pollingIngestionFailure(action.payload),
              handleApiError(formatApiError(error))
            )
          )
        )
      )
    )

const epics = [createIngestionEpic, pollingIngestionEpic]

export default epics
