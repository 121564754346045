import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  GroupByField,
  TAG_TYPE_INTERNAL_OFFLINE,
  useTagListQuery,
} from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  resolveMetadataPropertyId,
  useGetMetadataProperty,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { ICON } from '@shared/model/constants/styles'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import CategoryList, { Category } from '@shared/ui/Dropdown/CategoryList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import getEventPropertyLabel from '../utils/getEventPropertyLabel'
import { STATISTIC_MEASURE_GROUPING_MAX_COUNT } from './constants'
import { DeleteButton } from './styles'

type OnAddPayload = {
  source: string
  field: string
}

export type StatisticMeasureGroupingFilterError = 'OUT_OF_RANGE' | ''

type Props = {
  categoryList: Category[]
  error?: StatisticMeasureGroupingFilterError
  eventNameList: string[]
  values: GroupByField[]
  onAdd: (payload: OnAddPayload) => void
  onUpdate: (payload: OnAddPayload & { index: number }) => void
  onDelete: (payload: number) => void
}

export const StatisticMeasureGroupingFilter = ({
  categoryList,
  error = '',
  eventNameList,
  values,
  onAdd,
  onUpdate,
  onDelete,
}: Props) => {
  const { t } = useTranslation(['analytics', 'common'])

  const { tagList = [] } = useTagListQuery(
    { usable: true, page: DEFAULT_PAGE, perPage: DEFAULT_TAG_LIST_PRE_PAGE },
    {
      selectFromResult: ({ data = [] }) => ({
        tagList: data.filter(({ type }) => type !== TAG_TYPE_INTERNAL_OFFLINE),
      }),
    }
  )

  const getMetadataProperty = useGetMetadataProperty('')

  const { eventEntity } = useMetadataQuery()

  const getDisplayName = useCallback(
    (source: string, field: string) => {
      return (
        tagList.find(x => x.tagUsersTableName === source)?.title ||
        getMetadataProperty(source, field).displayName ||
        getEventPropertyLabel(
          eventNameList,
          eventEntity['events'],
          createMetadataPropertyId(source, field)
        )
      )
    },
    [tagList, eventNameList, eventEntity, getMetadataProperty]
  )

  return (
    <>
      <Box mb={2} component="h2">
        {t('analytics:event.group_by')}
        {error === 'OUT_OF_RANGE' && (
          <Box ml={2} component="span" color={theme.colors.chartRed}>
            {t('analytics:event.group_by_out_of_range')}
          </Box>
        )}
      </Box>

      <Box display="flex" alignItems="center" height={32}>
        {values.map(({ source, field }, index) => {
          const displayName = getDisplayName(source, field) || ''
          const currValue = createMetadataPropertyId(source, field)

          return (
            <Box key={`${index}_${currValue}`} display="inline" mr={1}>
              <CategoryList
                anchorElem={
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<i className={ICON.draggabledots} />}
                    endIcon={
                      <DeleteButton
                        onClick={event => {
                          event.stopPropagation()

                          onDelete(index)
                        }}
                      >
                        <i className={ICON.times} />
                      </DeleteButton>
                    }
                  >
                    {displayName}
                  </Button>
                }
                categories={categoryList}
                selectedValue={currValue}
                onValueChanged={propertyId => {
                  const { source, field } =
                    resolveMetadataPropertyId(propertyId)

                  onUpdate({ source, field, index })
                }}
              />
            </Box>
          )
        })}

        {values.length < STATISTIC_MEASURE_GROUPING_MAX_COUNT && (
          <CategoryList
            categories={categoryList}
            anchorElem={
              values.length === 0 ? (
                <Box mr={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    startIcon={<i className={ICON.draggabledots} />}
                  >
                    <Box mr={2}>{t('common:all')}</Box>
                  </Button>
                </Box>
              ) : (
                <IconButton>
                  <Icon
                    icon={ICON.plusCircle}
                    color={theme.colors.brightBlue}
                  />
                </IconButton>
              )
            }
            onValueChanged={propertyId => {
              onAdd(resolveMetadataPropertyId(propertyId))
            }}
          />
        )}
      </Box>
    </>
  )
}

export default StatisticMeasureGroupingFilter
