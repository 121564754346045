export const CATEGORY = {
  aiMarketingAssistant: 'ai-ma',
  analytics: 'analytics',
  cdm: 'cdm',
  dataImport: 'data-import',
  ma: 'ma',
  settings: 'settings',
  userCenter: 'user-center',
  usageManagement: 'usage-management',
} as const

export const PAGE_ROOT = {
  aiMarketingAssistant: {
    root: 'ai-ma',
    socialMediaLabelAnalysis: 'social-media-label-analysis',
    kolRecommend: 'kol-recommend',
    autoTagAnalysis: 'auto-tag-analysis',
    autoTagEstablish: 'auto-tag-establish',
  },
  analysis: 'analysis',
  apiTokens: 'api-tokens',
  audiences: 'audiences',
  configuration: 'configuration',
  consumptions: 'consumptions',
  contentSetting: 'content-setting',
  contentTemplate: 'content-template',
  dashboard: 'dashboard',
  edit: 'edit',
  error404: '404',
  error500: '500',
  events: 'events',
  exportTextMessage: 'export-text-message',
  funnel: 'funnel',
  home: 'home',
  import: 'import',
  insight: {
    root: 'insight',
    campaignInsight: 'campaign-insight',
    workflowInsight: 'workflow-insight',
  },
  journeys: 'journeys',
  maTracingUrl: 'ma-tracing-url',
  maSubscription: 'ma-subscription',
  new: 'new',
  notificationRule: 'notification-rule',
  policies: 'policies',
  privacyPolicy: 'privacy-policy',
  rfm: 'rfm',
  senders: 'senders',
  signIn: 'signin',
  sendingAmount: 'sending-amount',
  tagGroups: 'tag-group',
  tags: 'tags',
  termsOfService: 'terms-of-service',
  thirdParty: 'third-party-apps',
  userProfile: 'users',
  users: 'users',
  v3: {
    importUsers: 'import-users',
    importEvents: 'import-events',
    importTags: 'import-tags',
    importDimensionTables: 'import-dimension-tables',
  },
  workflows: 'workflows',
} as const

export const QUERY_STRING = {
  audiences: {
    type: 'type',
    referenceId: 'reference_id',
    templateId: 'template_id',
    templateParams: 'template_params',
  },
  auth: {
    serviceId: 'service_id',
    ssoToken: 'sso_token',
    returnURL: 'return_url',
  },
  common: {
    referenceId: 'reference_id',
    name: 'name',
  },
  rfm: {
    type: 'type',
  },
  userProfile: {
    type: 'type',
  },
  workflow: {
    triggerId: 'trigger_id',
  },
}

/**
 * @deprecated 頁面客製的錯誤自行處理，不需要透過 handleApiError
 */
export const PAGES = {
  settingApplicationCreate: 'settingApplicationCreate',
  settingApplicationDetail: 'settingApplicationDetail',
  settingsApplicationOverview: 'settingsApplicationOverview',
} as const

export type Pages = keyof typeof PAGES

/**
 * @deprecated
 * v1 版本 API 多數透過 epics 發送，因此會維護 PATH_RULES，確保在 handleApiError 可以追回到錯誤是從哪個頁面發出，若使用 RTKQuery 可以在當前頁面處理錯誤。
 *
 * 沒有頁面用到就需要將 path 從 PATH_RULES 移除，直到整個物件為空。
 */
export const PATH_RULES = {
  [PAGES.settingApplicationCreate]: /\/settings\/api-tokens\/new/,
  [PAGES.settingApplicationDetail]: /\/settings\/api-tokens\/\d+/,
  [PAGES.settingsApplicationOverview]: /\/settings\/api-tokens/,
}

export const PAGE_MODE = {
  copy: 'copy',
  create: 'create',
  detail: 'detail',
  edit: 'edit',
  import: 'import',
} as const
