import type { ParseKeys } from 'i18next'
import * as yup from 'yup'

import axios from '@shared/api/axios'
import type {
  AudienceFilterNode,
  CampaignEventCampaignGroupFilterNode,
  CampaignMeasureFilterNode,
  Combination,
  Group,
  TracingUrl,
  WorkflowFromApi,
} from '@shared/api/rtkQuery'

export const AUDIENCE_FILTER_SCHEMA_ERRORS: Record<
  string,
  ParseKeys<'Workflow'>
> = {
  WORKFLOW_HAS_BEEN_DELETED: 'errors.workflow_has_been_deleted',
  TRACING_URL_HAS_BEEN_DELETED: 'errors.tracing_url_is_archived_plz_recreate',
}

export const audienceFilterSchema = yup.object<AudienceFilterNode>({
  filters: yup
    .array()
    .of(
      yup.object().shape({
        type: yup
          .mixed<AudienceFilterNode['filters'][number]['type']>()
          .oneOf(['event', 'campaign_event', 'user_profile_tag']),
        node: yup.object().when('type', {
          is: 'campaign_event',
          then: schema =>
            schema.shape({
              type: yup.string<Group['type']>().oneOf(['group']),
              nodes: yup.array().of(
                yup.object().shape({
                  type: yup.string<Group['type']>().oneOf(['group']),
                  nodes: yup.array().of(
                    yup.object().shape({
                      type: yup
                        .string<CampaignMeasureFilterNode['type']>()
                        .oneOf(['campaign_measure']),
                      attributeFilter: yup.object().shape({
                        nodes: yup.array().of(
                          yup.object().shape({
                            type: yup.string<Group['type']>().oneOf(['group']),
                            nodes: yup.array().of(
                              yup.object().shape({
                                type: yup
                                  .string<'condition' | 'group'>()
                                  .oneOf(['condition', 'group']),
                                nodes: yup.array().when('type', {
                                  is: 'group',
                                  then: schema =>
                                    schema.of(
                                      yup.object().shape({
                                        type: yup
                                          .string<'condition'>()
                                          .oneOf(['condition']),
                                        field: yup
                                          .string<
                                            CampaignEventCampaignGroupFilterNode['field']
                                          >()
                                          .oneOf([
                                            'trigger_group_type',
                                            'trigger_group_id',
                                            'campaign_id',
                                            'tracing_url_id',
                                          ]),
                                        params: yup
                                          .array()
                                          .when('field', {
                                            is: 'trigger_group_id',
                                            then: schema =>
                                              schema.of(
                                                yup
                                                  .number()
                                                  .test(
                                                    'has-workflow',
                                                    AUDIENCE_FILTER_SCHEMA_ERRORS.WORKFLOW_HAS_BEEN_DELETED,
                                                    async id =>
                                                      (
                                                        await axios.get<WorkflowFromApi>(
                                                          `/workflows/${id}`
                                                        )
                                                      ).data.status !==
                                                      'deleted'
                                                  )
                                              ),
                                          })
                                          .when('field', {
                                            is: 'tracing_url_id',
                                            then: schema =>
                                              schema.of(
                                                yup
                                                  .number()
                                                  .test(
                                                    'has-tracing-url',
                                                    AUDIENCE_FILTER_SCHEMA_ERRORS.TRACING_URL_HAS_BEEN_DELETED,
                                                    async id =>
                                                      (
                                                        await axios.get<TracingUrl>(
                                                          `/tracing_urls/${id}`
                                                        )
                                                      ).data.archivedAt ===
                                                      undefined
                                                  )
                                              ),
                                          }),
                                      })
                                    ),
                                }),
                                combination: yup
                                  .string<Combination>()
                                  .oneOf(['and', 'or']),
                              })
                            ),
                          })
                        ),
                        combination: yup
                          .string<Combination>()
                          .oneOf(['and', 'or']),
                      }),
                    })
                  ),
                })
              ),
              combination: yup.string<Combination>().oneOf(['and', 'or']),
            }),
        }),
      })
    )
    .min(1)
    .required(),
  combination: yup.mixed<Combination>().oneOf(['and', 'or']),
})

export default audienceFilterSchema
