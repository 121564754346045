import { ReplacementData } from '@shared/api/rtkQuery/contentTemplate'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import { regexReplacementDataMergeTag } from '@shared/model/constants/validation'
import { NestedOption } from '@shared/ui/Dropdown/NestedList'

export const extractMergeTagList = (message: string) => {
  const matches = message.matchAll(regexReplacementDataMergeTag)

  return Array.from(matches).reduce<string[]>(
    (acc, [, mergeTag]) => acc.concat(mergeTag),
    []
  )
}

// 因為有可能 merge tag 是用複製貼上或是打字的方式，故直接清空 merge tag 全部重新抓取
export const refreshReplacementData = (
  message: string,
  options: NestedOption[] = [],
  replacementData: ReplacementData
) => {
  const replacementDataCloned = cloneDeep(replacementData)

  const usedMergeTagList = extractMergeTagList(message)

  // 清掉所有 merge tag
  for (const [key, value] of Object.entries(replacementData)) {
    if (value.type === 'metadata') {
      delete replacementDataCloned[key]
    }
  }

  usedMergeTagList.forEach(mergeTag => {
    if (options.find(option => option.value === mergeTag) !== undefined) {
      replacementDataCloned[mergeTag] = {
        type: 'metadata',
        name: mergeTag,
      }
    }
  })

  return replacementDataCloned
}
