import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import { AggregatorFilter, CombinationButton } from '@features/filters/_shared'
import {
  ATTRIBUTE_FILTER_MAX_COUNT,
  AUDIENCE_FILTER_EVENT_MAX_COUNT,
} from '@features/filters/_shared'
import AttributeFilter from '@features/filters/AttributeFilter'
import type {
  MeasureFilterNode,
  NotHappenedOperator,
} from '@shared/api/rtkQuery'
import {
  getCategoriesOfClassification,
  useCategoriesOfMetadata,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { ICON } from '@shared/model/constants/styles'
import DateRangePicker from '@shared/ui/DateRangePicker'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import type { MeasureFilterAction } from './measureFilterReducer'

type Props = {
  state: MeasureFilterNode
  isCloneDisabled: boolean
  isDisabled: boolean
  dispatch: (action: MeasureFilterAction) => void
  onDelete: () => void
  onClone: () => void
}

const MeasureFilter = ({
  state: {
    measure,
    attributeFilter,
    eventName,
    eventOperator,
    timeRangeParams,
    params,
  },
  isCloneDisabled,
  isDisabled,
  dispatch,
  onDelete: handleDelete,
  onClone,
}: Props) => {
  const { t, i18n } = useTranslation(['audience', 'metadata', 'common'])

  const { eventEntity, userProfileEntity, userProfileIntelligence, isLoading } =
    useMetadataQuery()

  const categoriesOfMetadata = useCategoriesOfMetadata(eventName)

  const categoriesOfClassification = getCategoriesOfClassification(eventEntity)

  if (isLoading) {
    return <CircularProgress />
  }

  if (!eventEntity || !userProfileEntity || !userProfileIntelligence) {
    return null
  }

  const happenOperatorOptions: {
    label: string
    value: '' | NotHappenedOperator
  }[] = [
    {
      label: t('metadata:operator.happened'),
      value: '',
    },
    {
      label: t('metadata:operator.not_happened'),
      value: 'not_happened',
    },
  ]

  // 此處將 `event_operator` 轉成 `happenOperator` 與 `aggregatorOperator`
  // happenOperator => 發生 or 未發生
  // aggregatorOperator => 繼續往下傳給 AggregatorFilter
  const [happenOperator, aggregatorOperator] =
    eventOperator === 'not_happened'
      ? ([eventOperator, ''] as const)
      : (['', eventOperator] as const)

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={2}>{t('audience:v3.time_range')}</Box>
        <Box mr={1.5}>
          <DateRangePicker
            values={timeRangeParams}
            isDisabled={isDisabled}
            popperDisablePortal
            isFutureEnable={true}
            onChange={values => {
              dispatch({
                type: 'updateTimeRangeParams',
                payload: values,
              })
            }}
          />
        </Box>

        {!isDisabled && (
          <>
            <Tooltip
              title={
                isCloneDisabled
                  ? t('audience:v3.filter_max_hint_event', {
                      max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
                    })
                  : t('audience:v3.filter_duplicate')
              }
            >
              <span>
                <IconButton disabled={isCloneDisabled} onClick={onClone}>
                  <Icon icon={ICON.copy} color={theme.colors.textPrimaryBlue} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('audience:v3.filter_delete')}>
              <IconButton onClick={handleDelete}>
                <Icon icon={ICON.times} color={theme.colors.black} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>

      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={1}>
          <DropdownList
            key={i18n.language}
            bgColor={theme.colors.white}
            options={happenOperatorOptions}
            value={happenOperator}
            disabled={isDisabled}
            popperDisablePortal
            onValueChanged={({ value }) => {
              dispatch({
                type: 'updateEventOperator',
                payload: value as NotHappenedOperator,
              })
            }}
          />
        </Box>

        <Box mr={1}>
          <CategoryList
            defaultLabel={t('common:dropdownlist_default')}
            bgColor={theme.colors.white}
            categories={categoriesOfClassification}
            selectedValue={eventName}
            isDisabled={isDisabled}
            popperDisablePortal
            onValueChanged={eventName => {
              dispatch({
                type: 'updateEventName',
                payload: eventName,
              })
            }}
          />
        </Box>

        {!isDisabled && eventName && (
          <Tooltip
            title={
              attributeFilter.nodes.length >= ATTRIBUTE_FILTER_MAX_COUNT
                ? t('audience:v3.filter_max_hint_condition', {
                    max: ATTRIBUTE_FILTER_MAX_COUNT,
                  })
                : t('audience:v3.filter_add')
            }
          >
            <span>
              <IconButton
                disabled={
                  attributeFilter.nodes.length >= ATTRIBUTE_FILTER_MAX_COUNT
                }
                onClick={() => {
                  const currentEvent = eventEntity.events.entities[eventName]
                  const [firstEventPropertyId] = currentEvent.propertiesIds

                  if (firstEventPropertyId) {
                    const {
                      tableName: source,
                      id: field,
                      dataType,
                      repeated,
                    } = currentEvent.propertiesEntity[firstEventPropertyId]

                    dispatch({
                      type: 'addAttributeFilterCondition',
                      payload: {
                        source,
                        field,
                        dataType,
                        repeated,
                      },
                    })
                    return
                  }

                  const [firstUserPropertyId] = userProfileEntity.ids

                  if (firstUserPropertyId) {
                    const {
                      tableName: source,
                      id: field,
                      dataType,
                      repeated,
                    } = userProfileEntity.entities[firstUserPropertyId]

                    dispatch({
                      type: 'addAttributeFilterCondition',
                      payload: {
                        source: source,
                        field: field,
                        dataType: dataType,
                        repeated,
                      },
                    })
                  }
                }}
              >
                <Icon
                  icon={ICON.plusCircle}
                  color={theme.colors.textPrimaryBlue}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>

      {eventName && attributeFilter.nodes.length > 0 && (
        <Box display="flex" alignItems="center" mb={1}>
          <Box mr={1}>
            {isDisabled ? (
              <CombinationButton disabled={isDisabled}>
                {attributeFilter.combination === 'and'
                  ? t('audience:v3.and')
                  : t('audience:v3.or')}
              </CombinationButton>
            ) : (
              <Tooltip title={t('audience:v3.filter_toggle')}>
                <CombinationButton
                  onClick={() => {
                    dispatch({
                      type: 'toggleAttributeFilterCombination',
                    })
                  }}
                >
                  {attributeFilter.combination === 'and'
                    ? t('audience:v3.and')
                    : t('audience:v3.or')}
                </CombinationButton>
              </Tooltip>
            )}
          </Box>

          <Box
            sx={{
              padding: 0,
              paddingLeft: 3,
              borderLeft: theme => `5px solid ${theme.colors.brightSkyBlue}`,
            }}
          >
            <ul>
              {attributeFilter.nodes.map((filter, index) => (
                <li key={index}>
                  <Box display="flex" alignItems="center" my={1}>
                    <Box mr={1}>
                      <AttributeFilter
                        eventName={eventName}
                        categories={categoriesOfMetadata}
                        field={filter.field}
                        dimOperator={filter.dimOperator}
                        operator={filter.operator}
                        params={filter.params}
                        source={filter.source}
                        bgColor={theme.colors.white}
                        isDisabled={isDisabled}
                        onChange={currValue => {
                          dispatch({
                            type: 'updateAttributeFilterCondition',
                            payload: { index, value: currValue },
                          })
                        }}
                      />
                    </Box>

                    {!isDisabled && (
                      <>
                        <Tooltip
                          title={
                            attributeFilter.nodes.length >=
                            ATTRIBUTE_FILTER_MAX_COUNT
                              ? t('audience:v3.filter_max_hint_condition', {
                                  max: ATTRIBUTE_FILTER_MAX_COUNT,
                                })
                              : t('audience:v3.filter_duplicate')
                          }
                        >
                          <span>
                            <IconButton
                              disabled={
                                attributeFilter.nodes.length >=
                                ATTRIBUTE_FILTER_MAX_COUNT
                              }
                              onClick={() => {
                                dispatch({
                                  type: 'cloneAttributeFilterCondition',
                                  payload: { sourceIndex: index },
                                })
                              }}
                            >
                              <Icon
                                icon={ICON.copy}
                                color={theme.colors.textPrimaryBlue}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={t('audience:v3.filter_delete')}>
                          <IconButton
                            onClick={() => {
                              dispatch({
                                type: 'removeAttributeFilterCondition',
                                payload: index,
                              })
                            }}
                          >
                            <Icon icon={ICON.times} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </li>
              ))}
            </ul>
          </Box>
        </Box>
      )}

      {/** 發生門檻 */}
      {measure !== undefined && params !== undefined && (
        <AggregatorFilter
          field={measure.field}
          aggregator={measure.aggregator}
          operator={aggregatorOperator}
          values={params}
          eventName={eventName}
          isDisabled={isDisabled}
          onAggregatorChange={({ field, aggregator }) => {
            dispatch({
              type: 'updateMeasure',
              payload: { aggregator, field },
            })
          }}
          onOperatorChange={currOperator => {
            dispatch({ type: 'updateEventOperator', payload: currOperator })
          }}
          onValuesChange={currValues => {
            dispatch({
              type: 'updateParams',
              payload: currValues,
            })
          }}
        />
      )}
    </>
  )
}

export default MeasureFilter
