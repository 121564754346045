import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'

import { useGetAudienceSummaryUserProfileSummaryQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { Tooltip } from '@shared/ui/tooltips'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { CHART_HEIGHT, NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'
import EmptyData from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/EmptyData'

import useGroupData from '../_shared/hooks/useGroupData'

const City = () => {
  const { t } = useTranslation(['audience'])
  const { audienceId } = useAudienceDetailState()

  const {
    audienceCityData,
    isError,
    isSuccess,
    isFetching,
    isLoading,
    isUninitialized,
  } = useGetAudienceSummaryUserProfileSummaryQuery(
    { audienceId, type: 'city' },
    {
      skip: audienceId === NO_DATA_ID,
      selectFromResult: ({
        data,
        isError,
        isSuccess,
        isFetching,
        isLoading,
        isUninitialized,
      }) => ({
        // 取前五筆資料
        audienceCityData: data
          ? {
              ...data,
              orderedByCountSummary: data.orderedByCountSummary.slice(0, 5),
            }
          : null,
        isError,
        isSuccess,
        isFetching,
        isLoading,
        isUninitialized,
      }),
    }
  )

  const chartTitle = useMemo(() => {
    if (!audienceCityData) {
      return ''
    }
    return t(`audience:${audienceCityData?.orderedByCountSummary[0].groupName}`)
  }, [audienceCityData, t])

  const groupData = useGroupData({
    orderedByCountSummary: audienceCityData?.orderedByCountSummary,
  })

  return (
    <BasicCard
      chartName="city"
      isSuccess={isSuccess}
      isLoading={isUninitialized || isLoading}
      isFetching={isFetching}
      isError={isError}
      subject={t('audience:main_city')}
      title={chartTitle}
      nonMembers={getIntSafe(
        audienceCityData?.totalNonMembers,
        ''
      ).toLocaleString()}
    >
      {audienceCityData && (
        <Box
          height={CHART_HEIGHT}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          {groupData.map(item => {
            const ratioValue = `${(item.ratio * 100).toFixed(1)}%`

            return (
              <Box key={item.groupName} mb={1} width="100%">
                <Box
                  fontSize={16}
                  fontWeight={400}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  sx={theme => ({
                    color: theme.colors.textPrimaryBlue,
                  })}
                >
                  {item.groupName}
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box width="80%">
                    <Tooltip
                      title={
                        <Box display="flex" justifyContent="flex-start">
                          <Box
                            textOverflow="ellipsis"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            mr={3}
                          >
                            {t(`audience:${item.groupName}`)}
                          </Box>
                          <Box whiteSpace="nowrap">{`${t(
                            'audience:unit.people',
                            { number: item.count.toLocaleString() }
                          )} (${ratioValue})`}</Box>
                        </Box>
                      }
                    >
                      <LinearProgress
                        variant="determinate"
                        value={item.ratio * 100}
                        sx={theme => ({
                          height: '14px',
                          borderRadius: '8px',
                          backgroundColor: '#f5f5f5',
                          span: {
                            borderRadius: '8px',
                            backgroundColor: theme.colors.chartBlue,
                          },
                        })}
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    fontSize={16}
                    fontWeight={700}
                    width="20%"
                    textAlign="right"
                    whiteSpace="nowrap"
                  >
                    {ratioValue}
                  </Box>
                </Box>
              </Box>
            )
          })}
        </Box>
      )}
      {!audienceCityData && <EmptyData />}
    </BasicCard>
  )
}

export default City
