export const getTableCellPaddingRight = (
  isLastRowCell = false,
  isAlignRight = false
) => {
  if (isAlignRight) {
    return 48
  }
  if (isLastRowCell) {
    return 60
  }
  return 16
}
