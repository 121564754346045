import styled from '@emotion/styled'
import CardMedia from '@mui/material/CardMedia'

import type { IconClassName } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import type { ThemeColor } from '@theme'
import theme from '@theme'

const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  width: 64px;
  height: 44px;

  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.white};
  font-size: 28px;
`

type Props<T extends 'img' | 'icon'> = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  component: T
  src: T extends 'icon' ? IconClassName : string
  color?: ThemeColor | 'inherit'
}

const Thumbnail = <T extends 'img' | 'icon'>({
  component,
  src,
  color = theme.colors.textPrimaryBlue,
  ...props
}: Props<T>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ThumbnailWrapper {...props}>
      {component === 'icon' ? (
        <Icon color={color} fontSize="inherit" icon={src as IconClassName} />
      ) : (
        <CardMedia component="img" image={src} />
      )}
    </ThumbnailWrapper>
  )
}

export default Thumbnail
