import {
  SerializedElementNode,
  SerializedLexicalNode,
  SerializedTextNode,
} from 'lexical'

import {
  NODE_TYPE as METADATA_TAG_NODE_TYPE,
  SerializedMetadataTagNode,
} from '../../metadataTag/nodes/MetadataTagNode'
import {
  NODE_TYPE as UTM_NODE_TYPE,
  SerializedUtmNode,
} from '../../utm/nodes/UtmNode'

export const composePureText = (nodeList: SerializedLexicalNode[]): string => {
  const textContent = nodeList.reduce((text, node, currentIndex) => {
    const paragraph = (node as SerializedElementNode).children.reduce(
      (acc, curr) => {
        if (curr.type === 'text') {
          return acc + (curr as SerializedTextNode).text
        }

        if (curr.type === UTM_NODE_TYPE) {
          return acc + `{{${(curr as SerializedUtmNode).replacementKey}}}`
        }

        if (curr.type === METADATA_TAG_NODE_TYPE) {
          return (
            acc + `{{${(curr as SerializedMetadataTagNode).replacementKey}}}`
          )
        }

        return acc
      },
      ''
    )

    if (currentIndex === nodeList.length - 1) {
      return text + paragraph
    }

    return `${text + paragraph}\n`
  }, '')

  return textContent
}
