type MessageType = 'sms' | 'mms'

// 根據文件 (EVERY8D) 設定的點數間隔
// ex:
// 字數為 70 個字以下消耗 1 點
// 字數介於 71 ~ 134 個字以下消耗 2 點
// ...
// ref: https://www.teamplus.tech/2019/wp-content/uploads/2021/02/EVERY8D-HTTP-API%E6%96%87%E4%BB%B6-v2.1-cht_2021.02.23-E8D.pdf
const textLengthCostMap = [
  {
    textLength: 70,
    cost: 1,
  },
  {
    textLength: 134,
    cost: 2,
  },
  {
    textLength: 201,
    cost: 3,
  },
  {
    textLength: 268,
    cost: 4,
  },
  {
    textLength: 333,
    cost: 5,
  },
]

export const getCostByTextLength = (count: number): number => {
  if (count === 0) {
    return 0
  }

  for (let i = 0; i < textLengthCostMap.length; i++) {
    const { textLength, cost } = textLengthCostMap[i]
    if (textLength >= count) {
      return cost
    }
  }

  const { textLength: maxTextLength, cost: maxCost } =
    textLengthCostMap[textLengthCostMap.length - 1]

  return maxCost + getCostByTextLength(count - maxTextLength)
}

export const getCostPerMessage = (
  messageType: MessageType,
  contentLength: number,
  totalKB: number
) => {
  if (messageType === 'sms') {
    return getCostByTextLength(contentLength || 0)
  }

  if (messageType === 'mms') {
    // ref: https://www.teamplus.tech/2019/wp-content/uploads/2021/02/EVERY8D-HTTP-API%E6%96%87%E4%BB%B6-v2.1-cht_2021.02.23-E8D.pdf
    return totalKB <= 50 ? 3 : 5
  }

  return 0
}

export const base64ToKb = (base64Text: string) => {
  const bytes = atob(base64Text).length

  return +(bytes / 1024).toFixed(2)
}

export const textToKb = (text: string) => {
  const encoder = new TextEncoder()
  const bytes = encoder.encode(text).length

  return +(bytes / 1024).toFixed(2)
}

export const extractImageTypeAndBase64 = (dataURL: string) => {
  const match = dataURL.match(/^data:image\/(.+?);base64,(.+)$/)

  if (!match) {
    return { imageType: undefined, base64: undefined }
  }

  const [, imageType, base64] = match

  return { imageType, base64 }
}

// 簡訊的最小預約時間是 10 分鐘後
export const getLastReserveTime = () => {
  const lastReserveTime = new Date(+new Date() + 10 * 60 * 1000)
  lastReserveTime.setSeconds(0)

  return lastReserveTime
}
