import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { spacing, SpacingProps } from '@mui/system'

import { LAYOUT } from '@shared/model/constants/styles'
import theme from '@theme'

const getBorderStyle = (disabled = false, isError = false) => {
  if (disabled) {
    return '1px solid transparent'
  }
  if (isError) {
    return `1px solid ${theme.colors.orangeyRed}`
  }
  return '1px solid transparent'
}

const getFocusBorderStyle = (isError = false) => {
  if (isError) {
    return `1px solid ${theme.colors.orangeyRed}`
  }
  return `1px solid ${theme.colors.brightBlue}`
}

type PrimaryGreyInputProps = {
  marginRightRatio?: number
  marginLeftRatio?: number
  marginBottomRatio?: number
  width?: number
  color?: string
  disabled?: boolean
  isError?: boolean
  bgColor?: string
  height?: number
  disabledColor?: string
  fullWidth?: boolean
} & SpacingProps

const Input = styled.input<PrimaryGreyInputProps>`
  margin-right: ${({ marginRightRatio }) => (marginRightRatio || 0) * 12}px;
  margin-bottom: ${({ marginBottomRatio }) => (marginBottomRatio || 0) * 12}px;
  margin-left: ${({ marginLeftRatio }) => (marginLeftRatio || 0) * 12}px;
  padding: 8px 16px;
  width: ${({ width, fullWidth }) =>
    fullWidth ? '100%' : width ? `${width}px` : 'auto'};
  min-width: ${({ width }) => (width ? 'auto' : '100px')};
  height: ${({ height }) => height || LAYOUT.audienceBuilder.heightOfRow}px;

  border: ${({ disabled, isError }) => getBorderStyle(disabled, isError)};
  border-radius: ${LAYOUT.audienceBuilder.inputBorderRadius}px;
  background-color: ${({ disabled, bgColor, theme }) =>
    disabled
      ? theme.colors.veryLightBlue
      : bgColor || theme.colors.bgPrimaryGrey};

  color: ${({ color, disabled, disabledColor, theme }) =>
    disabled
      ? /* disabled 的話先用 disabledColor */
        disabledColor || theme.colors.black
      : /* 不是 disabled 的話先用 color */
        color || theme.colors.black};
  font-size: 13px;
  font-family: inherit;
  font-weight: 500;
  text-overflow: ellipsis;

  cursor: ${({ disabled }) => (disabled ? 'default' : 'text')};

  :focus {
    border: ${({ isError }) => getFocusBorderStyle(isError)};
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSecondBlue};
    opacity: 1;
  }
  ${spacing}
`

type Props = PrimaryGreyInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >

export const PrimaryGreyInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => (
    <Input
      type={props.type || 'text'}
      placeholder={props.placeholder || ''}
      min={props.min}
      max={props.max}
      disabled={props.disabled}
      autoComplete="off"
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
)

export default PrimaryGreyInput
