import { useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'

import { MsgSender, useGetMsgSenderListQuery } from '@shared/api/rtkQuery'

type UseMsgSenderOptionsParams = {
  msgChannelId?: number
  msgChannelOptions: { value: string; integrationType: string }[]
}

export const useMsgSenderOptions = ({
  msgChannelId,
  msgChannelOptions,
}: UseMsgSenderOptionsParams) => {
  const selectMsgSenderOptions = useMemo(
    () =>
      createSelector(
        [(state: { data?: MsgSender[] }) => state.data],
        msgSenderList => ({
          msgSenderOptions: (msgSenderList ?? [])
            .filter(
              msgSender =>
                msgSender.integrationType ===
                msgChannelOptions.find(
                  msgChannel => msgChannel.value === String(msgChannelId)
                )?.integrationType
            )
            .map(msgSender => ({
              label: `${msgSender.name} | ${msgSender.email}`,
              value: msgSender.id,
            })),
        })
      ),
    [msgChannelOptions, msgChannelId]
  )

  const { msgSenderOptions } = useGetMsgSenderListQuery(undefined, {
    selectFromResult: selectMsgSenderOptions,
  })

  return msgSenderOptions
}

export default useMsgSenderOptions
