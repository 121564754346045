import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

import { setAllRead, toggleIsOpen } from '@entities/notification/model/slices'
import { pollingNotification } from '@entities/notification/model/slices'
import { usePatchNotificationMutation } from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import NotificationHeader from './NotificationHeader'
import NotificationItem from './NotificationItem'

const NotificationBar = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['analytics'])

  const isOpenNotificationBar = useAppSelector(
    state => state.notification.isOpen
  )
  const notifications = useAppSelector(
    state => state.notification.notifications
  )

  const [patchNotification] = usePatchNotificationMutation()

  const archiveAllNotification = () => {
    if (notifications.length) {
      patchNotification({
        latestId: notifications[0].id,
        isArchived: true,
      }).then(() => {
        dispatch(setAllRead())
      })
    }
  }

  useEffect(() => {
    dispatch(pollingNotification())
  }, [dispatch])

  return (
    <Drawer
      sx={{
        zIndex: 1099,
        '& .MuiPaper-root': {
          paddingTop: '74px',
          width: '440px',
        },
      }}
      hideBackdrop={true}
      anchor={'right'}
      open={isOpenNotificationBar}
      onClick={() => {
        dispatch(toggleIsOpen())
      }}
    >
      <NotificationHeader archiveAllNotification={archiveAllNotification} />
      {notifications.map(notificationData => {
        return (
          <NotificationItem key={notificationData.id} data={notificationData} />
        )
      })}
      {notifications.length === 0 && (
        <Box display="flex" justifyContent="center" mt="20px">
          <Icon
            icon={ICON.bell}
            color={theme.colors.textPrimaryBlue}
            fontSize="default"
          />
          <span
            style={{ marginTop: '2px', color: theme.colors.textPrimaryBlue }}
          >
            {t('analytics:notification.your_notifications_will_be_here')}
          </span>
        </Box>
      )}
    </Drawer>
  )
}

export default NotificationBar
