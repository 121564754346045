import { combineReducers } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'

import apiHandlerReducer from '@entities/apiHandler/model/slices'
import authReducer from '@entities/auth/model/slices'
import notificationReducer from '@entities/notification/model/slices'
import infoBoxReducer from '@features/infoBox/model/infoBoxSlice'
import rtkQuery, { mockApi as mockRtkQuery } from '@shared/api/rtkQuery'
import toastReducer from '@shared/model/slices/toastSlice'
import settingsReducer from '@widgets/settings/store'

const v1Reducer = combineReducers({
  settings: settingsReducer,
})

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() })

const rootReducer = combineReducers({
  v1: v1Reducer,
  router: routerReducer,
  apiHandler: apiHandlerReducer,
  infoBox: infoBoxReducer,
  notification: notificationReducer,
  auth: authReducer,
  toast: toastReducer,
  [rtkQuery.reducerPath]: rtkQuery.reducer,
  ...(import.meta.env.DEV && {
    [mockRtkQuery.reducerPath]: mockRtkQuery.reducer, // mock api
  }),
})

export type RootState = ReturnType<typeof rootReducer>

export { createReduxHistory, routerMiddleware }

export default rootReducer
