import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'

import { ICON } from '@shared/model/constants/styles'
import { ClickAwayListener, Popup } from '@shared/ui'
import { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

import { TEN_ITEMS_HEIGHT } from '../../model/constants'
import { Button, ChosenValueText, ExpandIconWrapper, Title } from './styles'

interface Props {
  defaultOption: Option
  options: Option[]
  onValueChange: (ika_id: string) => void
}

// TODO: maybe integrate /Header/DropdownList into /components/DropdownList
const DropdownList = ({ options, onValueChange, defaultOption }: Props) => {
  const [currOption, setCurrOption] = useState<Option>(defaultOption)
  const [anchorId, setAnchorId] = useState<string>('')

  const headerDropdownAnchorId = 'header-dd-anchor-id'

  const handlePopupMenuClose = () => {
    setAnchorId('')
  }

  const handlePopupMenuOpen = () => {
    setAnchorId(headerDropdownAnchorId)
  }

  const handleValueChange = (option: Option) => {
    const ika_id = option.value as string

    handlePopupMenuClose()
    setCurrOption(option)
    onValueChange(ika_id)
  }

  const { t } = useTranslation(['common'])

  return (
    <>
      <Button id={headerDropdownAnchorId} onClick={handlePopupMenuOpen}>
        <Title>{t('common:all_accounts')}</Title>
        <ChosenValueText>{currOption.label}</ChosenValueText>
        <ExpandIconWrapper>
          <Icon
            icon={ICON.arrowDown}
            fontSize="small"
            color={theme.colors.lightNavyBlue}
          />
        </ExpandIconWrapper>
      </Button>

      <ClickAwayListener
        anchorIds={[anchorId]}
        onClickAway={handlePopupMenuClose}
      >
        <Popup
          anchorId={anchorId}
          closePopup={handlePopupMenuClose}
          customStyle={{
            offsetTop: 5,
            offsetLeft: 0,
          }}
          placement="bottom"
        >
          <Paper>
            <MenuList maxHeight={TEN_ITEMS_HEIGHT}>
              {options.map((option, idx) => (
                <MenuItem
                  key={`${option.value}_${idx}`}
                  onClick={() => {
                    if (currOption.value !== option.value) {
                      handleValueChange(option)
                    }
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popup>
      </ClickAwayListener>
    </>
  )
}

export default DropdownList
