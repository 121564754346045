import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Box, { BoxProps } from '@mui/material/Box'

export const LineClamp = styled(
  ({ maxLine, ...otherProps }: { maxLine?: number } & BoxProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Box {...otherProps} />
  )
)`
  ${({ maxLine }) => {
    if (!maxLine) {
      return ``
    }

    if (maxLine === 1) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    }

    return css`
      /* line-clamp 必須 display -webkit-box or -webkit-inline-box 才能 work */
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${maxLine};
      overflow-wrap: break-word;
    `
  }}
`
