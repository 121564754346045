import * as yup from 'yup'

export const subscriptionNodeSchema = yup.object({
  subscriptionId: yup.number().required(),
  subscriptionName: yup.string().required(),
  subscriptionNodeId: yup.number(),
})

export interface SubscriptionNodeData
  extends yup.InferType<typeof subscriptionNodeSchema> {}
