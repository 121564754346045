import { useTranslation } from 'react-i18next'

import AiMarketingLayout from '../_shared/AiMarketingLayout'
import Overview from './Overview'

export const SocialMediaTagsAnalysisRoute = () => {
  const { t } = useTranslation(['aiMarketingAssistant', 'common'])

  return (
    <AiMarketingLayout
      title={t('aiMarketingAssistant:social_media_label_analysis.empty_title')}
      breadcrumbText={t('common:route.social_media_label_analysis')}
    >
      {productId =>
        Boolean(productId) && <Overview key={productId} productId={productId} />
      }
    </AiMarketingLayout>
  )
}
