import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { ProductKol } from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import theme from '@theme'

import SocialMediaPlatformData from './SocialMediaPlatformData'

type Props = {
  name: string
  platformNames: string[]
  allFollowers: number
  tags: ProductKol['tags']
  media: ProductKol['media']
}

const Information = ({
  name,
  platformNames,
  tags,
  allFollowers,
  media,
}: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mr={3}
    >
      <Box
        width="100%"
        fontSize={22}
        fontWeight={600}
        lineHeight="140%"
        color={theme.colors.textPrimaryBlue}
        mb={1}
      >
        {name}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="flex-start"
        mb={1.5}
      >
        {platformNames.map((tag, index) => (
          <Box
            key={`${index}_${tag}`}
            component="span"
            fontSize={13}
            fontWeight={600}
            color={theme.colors.textPrimaryBlue}
            px={0.5}
            sx={{
              borderRight: `1px solid ${theme.colors.borderColor}`,
              '&:first-of-type': {
                paddingLeft: 0,
              },
              '&:last-of-type': {
                borderRight: 'none',
              },
            }}
          >
            {tag}
          </Box>
        ))}
      </Box>
      {tags && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-start"
          mb={2}
          flexWrap="wrap"
          fontSize={14}
          sx={theme => ({ color: theme.colors.textPrimaryBlue })}
        >
          <Box fontWeight={600}>
            {t('aiMarketingAssistant:kol_recommendation.tags')}：
          </Box>
          {tags.map((tag, index) => (
            <Box key={`${index}_${tag}`} fontWeight={400}>
              {index === 0 ? tag : `、${tag}`}
            </Box>
          ))}
        </Box>
      )}
      <Box
        width="100%"
        fontSize={14}
        fontWeight={600}
        lineHeight="20px"
        color={theme.colors.textPrimaryBlue}
        mb={2}
      >
        {t('aiMarketingAssistant:kol_recommendation.all_fans')}：
        {formatNumber(allFollowers)}
      </Box>
      <Box display="flex" justifyContent="flex-start" width="100%">
        {media.map(v => (
          <SocialMediaPlatformData
            key={v.platform}
            platform={v.platform}
            follower={v.follower}
          />
        ))}
      </Box>
    </Box>
  )
}

export default Information
