import { useState } from 'react'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import {
  DEEP_BLUE_GRADIENT_BUTTON_WIDTH,
  DeepBlueGradientButton as BaseDeepBlueGradientButton,
} from '@shared/ui/buttons'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import Dropdown from '.'

const PADDING_X = 10

type DeepBlueGradientButtonProps = {
  buttonText: string
  children: React.ReactNode
  width?: number
}

const DeepBlueGradientButton = ({
  buttonText,
  children,
  width = DEEP_BLUE_GRADIENT_BUTTON_WIDTH,
}: DeepBlueGradientButtonProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dropdown
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popperWidth={width}
      anchorElem={
        <BaseDeepBlueGradientButton
          width={width}
          paddingLeft={PADDING_X}
          paddingRight={PADDING_X}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box component="p" sx={{ flexGrow: 1 }}>
              {buttonText}
            </Box>
            <Box
              component="span"
              sx={{
                paddingLeft: 1,
                borderLeft: `1px solid  rgba(255, 255, 255, 0.6)`,
              }}
            >
              <Icon
                id="icon-filter"
                icon={ICON.arrowDown}
                color={theme.colors.white}
              />
            </Box>
          </Box>
        </BaseDeepBlueGradientButton>
      }
    >
      {children}
    </Dropdown>
  )
}

export default DeepBlueGradientButton
