import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useAppSelector } from '@shared/lib/hooks/store'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import SectionTitle from '@shared/ui/SectionTitle'
import TagTypeRadioGroup, {
  type TagType,
} from '@widgets/tag/_shared/components/TagType'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

import type { TagSourceType } from '../type'
import {
  BehaviorTagCreator,
  GradingTagCreator,
  ThirdPartyTagCreator,
} from './TagCreator'

const TagCreate = () => {
  const { tagSourceType } = useParams<{ tagSourceType: TagSourceType }>()

  const { t } = useTranslation(['tag', 'common'])
  const [importTagType, setImportTagType] = useState<TagSourceType>(
    tagSourceType || 'grading'
  )

  const isEnableThirdParty = useAppSelector(
    state => state.auth.plan.tag.thirdParty.import
  )

  const tagTypes = [
    { tagSourceType: 'grading', isEnable: true },
    { tagSourceType: 'behavior', isEnable: true },
    {
      tagSourceType: 'thirdParty',
      isEnable: isEnableThirdParty,
      tooltipKey: isEnableThirdParty ? '' : 'common:trial_not_support',
    },
  ] as TagType[]

  return (
    <>
      <Breadcrumbs>
        <Link
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${TagSourceRoutesMap.getRoute(
            importTagType
          )}`}
        >
          {t('common:route.tag_list')}
        </Link>
        <Box>{t('tag:create_tag')}</Box>
      </Breadcrumbs>

      <SectionTitle title={t('tag:create_step_1_select_tag_type')} />

      <TagTypeRadioGroup
        tagTypes={tagTypes}
        value={importTagType}
        onChange={setImportTagType}
      />

      {importTagType === 'grading' && <GradingTagCreator />}
      {importTagType === 'thirdParty' && <ThirdPartyTagCreator />}
      {importTagType === 'behavior' && <BehaviorTagCreator />}
    </>
  )
}

export default TagCreate
