import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getIntSafe } from '@shared/lib/utils/number'
import type { NumberOrString } from '@shared/lib/utils/type'
import { ICON } from '@shared/model/constants/styles'
import { Button as ButtonWithoutBG, ButtonWithBG } from '@shared/ui/dialogs'
import Dropdown from '@shared/ui/Dropdown'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput as Input } from '@shared/ui/inputs'
import theme, { ThemeColor } from '@theme'

import { SHORTCUTS } from './constants'
import {
  getPastFutureDurationText,
  getToday,
  getValueType,
  parseIntSafeAbs,
  ValueType,
} from './helpers'
import {
  ButtonWrapper,
  Card,
  DurationButton,
  Header,
  HorizontalLine,
  ModeTab,
  ReactCalendar,
  ShortcutButton,
  ShortcutWrapper,
  Wrapper,
} from './style'

const DYNAMIC_RANGE_MIN_VALUE = 0

type Props = {
  durationBgColor?: ThemeColor
  initialOpen?: boolean
  isDisabled?: boolean
  onChange: (values: NumberOrString[]) => void
  placement?: 'bottom-start' | 'bottom-end'
  popperDisablePortal?: boolean
  values: NumberOrString[]
  isHideStaticTab?: boolean
  isHideDynamicTab?: boolean
  isFutureEnable?: boolean
}

const DateRangePicker = ({
  durationBgColor,
  initialOpen = false,
  isDisabled = false,
  onChange,
  placement,
  popperDisablePortal,
  isHideStaticTab = false,
  isHideDynamicTab = false,
  isFutureEnable = false,
  values,
}: Props) => {
  const { i18n, t } = useTranslation(['common', 'dateAndChart', 'dashboard'])
  const [isOpen, setIsOpen] = useState(false)

  const dynamicOptions = useMemo(() => {
    return [
      {
        value: '-1',
        label: t('common:past'),
      },
      {
        value: '1',
        label: t('common:future'),
      },
    ]
  }, [t])

  const valueType = getValueType(values)
  const [tab, setTab] = useState<ValueType>(() => {
    if (isHideStaticTab && valueType === 'static') {
      return 'dynamic'
    } else if (isHideDynamicTab && valueType === 'dynamic') {
      return 'static'
    }

    return valueType
  })
  const [dynamicOption, setDynamicOption] = useState<Option>(() => {
    if (valueType === 'static') {
      return dynamicOptions[0]
    }
    if (typeof values[0] === 'number' && values[0] < 0) {
      return dynamicOptions[0]
    }
    return dynamicOptions[1]
  })
  const [dynamicRange, setDynamicRange] = useState<number[]>(() => {
    if (valueType === 'static') {
      return [DYNAMIC_RANGE_MIN_VALUE, DYNAMIC_RANGE_MIN_VALUE + 1]
    }
    // 未來模式 [2, 3, 'days'] 和過去模式 [-3, -2, 'days] 都會藉由這邊切換回 [2, 3]
    return [values[0], values[1]]
      .map(val => parseIntSafeAbs(val))
      .sort((a, b) => a - b)
  })
  const [calendarDate, setCalendarDate] = useState<Date[] | null>(() => {
    if (valueType === 'dynamic') {
      const today = getToday()
      return [today.nowStart, today.nowEnd]
    }

    if (valueType === 'static' && values.length === 2) {
      return [new Date(values[0]), new Date(values[1])]
    }

    return null
  })

  // 為避免定位錯誤，待 anchor 顯示後才透過 effect 開啟日期選擇視窗
  useEffect(() => {
    if (initialOpen) {
      setIsOpen(true)
    }
  }, [initialOpen])

  const handleDateChange = (targetDates: Date | Date[]) => {
    if (Array.isArray(targetDates) && targetDates.length === 2) {
      setCalendarDate(targetDates)
    }
  }

  const handleShortcutButtonClick =
    ([startDate, endDate]: Date[]) =>
    () => {
      setCalendarDate([startDate, endDate])
      setIsOpen(false)

      onChange([startDate.toISOString(), endDate.toISOString()])
    }

  const handleConfirmButtonClick = () => {
    if (tab === 'dynamic') {
      // [2, 3] 會切換成未來模式 [2, 3, 'days'] 或過去模式 [-3, -2, 'days']
      const timeRange = dynamicRange
        .map(val => val * getIntSafe(dynamicOption.value))
        .sort((a, b) => a - b)
      onChange([...timeRange, 'day'])
      setIsOpen(false)
      return
    }

    if (tab === 'static' && calendarDate !== null) {
      const [startDate, endDate] = calendarDate
      onChange([startDate.toISOString(), endDate.toISOString()])
      setIsOpen(false)
      return
    }
  }

  return (
    <Dropdown
      anchorElem={
        <DurationButton
          bgColor={durationBgColor}
          disabled={isDisabled}
          onClick={() => {
            if (isDisabled) {
              return
            }
            setIsOpen(true)
          }}
        >
          {values.length === 0
            ? t('dateAndChart:select_date')
            : getPastFutureDurationText(values, t)}
          {!isDisabled && (
            <Icon icon={ICON.arrowDown} color={theme.colors.textPrimaryBlue} />
          )}
        </DurationButton>
      }
      popperDisablePortal={popperDisablePortal}
      placement={placement}
      isOpen={isOpen}
      isDisabled={isDisabled}
      setIsOpen={setIsOpen}
    >
      <Card>
        <Wrapper>
          <ModeTab
            isHide={isHideStaticTab}
            isActive={tab === 'static'}
            onClick={() => setTab('static')}
          >
            {t('dateAndChart:type.static')}
          </ModeTab>

          <ModeTab
            isHide={isHideDynamicTab}
            isActive={tab === 'dynamic'}
            onClick={() => setTab('dynamic')}
          >
            {t('dateAndChart:type.dynamic')}
          </ModeTab>
        </Wrapper>

        {!isHideStaticTab && tab === 'static' && (
          <>
            <Wrapper>
              <Header>{t('dateAndChart:quick_select')}</Header>
              <Header>{t('dateAndChart:customize')}</Header>
            </Wrapper>
            <Wrapper>
              <ShortcutWrapper>
                {SHORTCUTS.map(({ text, getDateRange, value = 0 }) => {
                  const { startDate, endDate } = getDateRange(value)
                  return (
                    <ShortcutButton
                      key={`${text}_${value}`}
                      onClick={handleShortcutButtonClick([startDate, endDate])}
                    >
                      {t(text, { days: value })}
                    </ShortcutButton>
                  )
                })}
              </ShortcutWrapper>
              <ReactCalendar
                locale={i18n.language}
                selectRange
                value={calendarDate}
                onChange={handleDateChange}
                formatShortWeekday={(locale, date) => {
                  // 客製星期的字樣
                  const zhTW = ['日', '一', '二', '三', '四', '五', '六']
                  const enUS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

                  return locale === 'zh-TW'
                    ? zhTW[new Date(date).getDay()]
                    : enUS[new Date(date).getDay()]
                }}
              />
            </Wrapper>
          </>
        )}
        {!isHideDynamicTab && tab === 'dynamic' && (
          <Wrapper alignItems="center" padding="20px">
            {`${t('common:from')}  `}
            {isFutureEnable ? (
              <DropdownList
                key={`${dynamicOption}_${i18n.language}`}
                defaultOption={dynamicOption}
                options={dynamicOptions}
                onValueChanged={option => {
                  setDynamicOption(option)
                }}
                marginRightRatio={1}
              />
            ) : (
              t('common:past')
            )}

            {t('common:order')}
            <Input
              value={dynamicRange[0].toString()}
              type="number"
              min={DYNAMIC_RANGE_MIN_VALUE.toString()}
              onChange={e => {
                const value = getIntSafe(e.target.value)
                const endDay = value > dynamicRange[1] ? value : dynamicRange[1]
                setDynamicRange([getIntSafe(e.target.value), endDay])
              }}
              width={80}
              marginRightRatio={1}
              marginLeftRatio={1}
            />
            {`${t('common:days')} ${t('common:to')} ${t('common:order')}`}
            <Input
              value={dynamicRange[1].toString()}
              type="number"
              min={dynamicRange[0].toString()}
              onBlur={e => {
                if (dynamicRange[0] > getIntSafe(e.target.value)) {
                  setDynamicRange([dynamicRange[0], dynamicRange[0]])
                }
              }}
              onChange={e => {
                setDynamicRange([dynamicRange[0], getIntSafe(e.target.value)])
              }}
              marginRightRatio={1}
              marginLeftRatio={1}
              width={80}
            />
            {t('common:days')}
          </Wrapper>
        )}

        <HorizontalLine />

        <Wrapper justifyContent="flex-end">
          <ButtonWrapper>
            <ButtonWithoutBG
              onClick={() => {
                setIsOpen(false)
              }}
            >
              {t('common:cancel')}
            </ButtonWithoutBG>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonWithBG onClick={handleConfirmButtonClick}>
              {t('common:confirm')}
            </ButtonWithBG>
          </ButtonWrapper>
        </Wrapper>
      </Card>
    </Dropdown>
  )
}

export default DateRangePicker
