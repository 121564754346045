import type {
  Aggregator,
  CampaignEventCampaignFilterNode,
  CampaignEventCampaignTracingUrlFilterNode,
  CampaignEventCampaignTriggerGroupFilterNode,
  CampaignEventCampaignTriggerGroupTypeFilterNode,
  Measure,
} from '@shared/api/rtkQuery'
import { DEFAULT_CAMPAIGN_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'

export const MEDIUM_GROUP_INDEX = 0
export const MEDIUM_GROUP_MEDIUM_TYPE_INDEX = 0
export const MEDIUM_GROUP_ACTION_INDEX = 1

export const TRIGGER_GROUP_INDEX = 1
export const TRIGGER_GROUP_TYPE_INDEX = 0
export const TRIGGER_GROUP_WORKFLOW_INDEX = 1
export const TRIGGER_GROUP_CAMPAIGN_INDEX = 2
export const TRIGGER_GROUP_TRACING_URL_INDEX = 3

export const CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT = 3

export const DEFAULT_TRIGGER_GROUP_TYPE_VALUE: CampaignEventCampaignTriggerGroupTypeFilterNode =
  {
    type: 'condition',
    field: 'trigger_group_type',
    operator: '=',
    params: ['workflow'],
    source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
  }

export const DEFAULT_TRIGGER_GROUP_VALUE: CampaignEventCampaignTriggerGroupFilterNode =
  {
    type: 'condition',
    field: 'trigger_group_id',
    operator: '=',
    params: undefined,
    source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
  }

export const DEFAULT_CAMPAIGN_VALUE: CampaignEventCampaignFilterNode = {
  type: 'condition',
  field: 'campaign_id',
  operator: '=',
  params: undefined,
  source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
}

export const DEFAULT_TRACING_URL_VALUE: CampaignEventCampaignTracingUrlFilterNode =
  {
    type: 'condition',
    field: 'tracing_url_id',
    operator: '=',
    params: undefined,
    source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
  }

export const DEFAULT_MEASURE_MAP = new Map<Aggregator, Measure>([
  [
    'count',
    {
      aggregator: 'count',
      source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
      field: 'action',
    },
  ],
  [
    'count_distinct',
    {
      aggregator: 'count_distinct',
      source: DEFAULT_CAMPAIGN_EVENT_TABLE_NAME,
      field: 'created_at',
      unit: 'day',
    },
  ],
])
