import type { ParseKeys } from 'i18next'

import {
  getLastMonthStartEnd,
  getLastNDays,
  getLastWeekStartEnd,
  getLastYearStartEnd,
  getThisMonthStartEnd,
  getThisWeekStartEnd,
  getThisYearStartEnd,
  getTodayStartEnd,
  getYesterdayStartEnd,
} from './helpers'

// 今日，昨日，本週，上週，本月，上月，本年，去年，過去 7 天，過去 14 天，過去 30 天，過去 60 天，過去 90 天，過去 180 天
export const SHORTCUTS: {
  text: ParseKeys<['dateAndChart' | 'common']>
  value?: number
  getDateRange: (value: number) => { startDate: Date; endDate: Date }
}[] = [
  {
    text: 'dateAndChart:today',
    getDateRange: getTodayStartEnd,
  },
  {
    text: 'dateAndChart:yesterday',
    getDateRange: getYesterdayStartEnd,
  },
  {
    text: 'dateAndChart:this.week',
    getDateRange: getThisWeekStartEnd,
  },
  {
    text: 'dateAndChart:last.week',
    getDateRange: getLastWeekStartEnd,
  },
  {
    text: 'dateAndChart:this.month',
    getDateRange: getThisMonthStartEnd,
  },
  {
    text: 'dateAndChart:last.month',
    getDateRange: getLastMonthStartEnd,
  },
  {
    text: 'dateAndChart:this.year',
    getDateRange: getThisYearStartEnd,
  },
  {
    text: 'dateAndChart:last.year',
    getDateRange: getLastYearStartEnd,
  },
  {
    text: 'common:last_n_days',
    value: 7,
    getDateRange: getLastNDays,
  },
  {
    text: 'common:last_n_days',
    value: 14,
    getDateRange: getLastNDays,
  },
  {
    text: 'common:last_n_days',
    value: 30,
    getDateRange: getLastNDays,
  },
  {
    text: 'common:last_n_days',
    value: 60,
    getDateRange: getLastNDays,
  },
  {
    text: 'common:last_n_days',
    value: 90,
    getDateRange: getLastNDays,
  },
  {
    text: 'common:last_n_days',
    value: 180,
    getDateRange: getLastNDays,
  },
]

export type UNIT = 'day' | 'week' | 'month'

export const UNIT_OPTIONS: { label: ParseKeys<'dateAndChart'>; value: UNIT }[] =
  [
    {
      label: 'by.day',
      value: 'day',
    },
    {
      label: 'by.week',
      value: 'week',
    },
    {
      label: 'by.month',
      value: 'month',
    },
  ]

export const COMPARE_DURATION = {
  LAST_SAME_PERIOD: 'LAST_SAME_PERIOD',
  LAST_YEAR_SAME_DURATION: 'LAST_YEAR_SAME_DURATION',
  CUSTOMIZE: 'CUSTOMIZE',
} as const

export const COMPARE_DURATION_OPTIONS: {
  label: ParseKeys<'dateAndChart'>
  value: keyof typeof COMPARE_DURATION
}[] = [
  {
    label: 'last_same_period',
    value: COMPARE_DURATION.LAST_SAME_PERIOD,
  },
  {
    label: 'last_year_same_duration',
    value: COMPARE_DURATION.LAST_YEAR_SAME_DURATION,
  },
  {
    label: 'customize',
    value: COMPARE_DURATION.CUSTOMIZE,
  },
]
