import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { Simplify } from '@shared/lib/utils/type'

type ToastStatus = 'success' | 'error'

type ToastPayload = {
  message: string
  status?: ToastStatus
}

type State = ToastPayload & {
  isOpen: boolean
}

const initialState: State = {
  isOpen: false,
  message: '',
  status: 'success',
}

const toast = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (
      state,
      {
        payload: { message, status = 'success' },
      }: PayloadAction<Simplify<ToastPayload>>
    ) => {
      state.isOpen = true
      state.message = message
      state.status = status
    },
    closeToast: state => {
      state.isOpen = false
    },
  },
})

export const { openToast, closeToast } = toast.actions

export default toast.reducer
