import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { CircularProgress, Paper } from '@mui/material'

import { ICON, LAYOUT } from '@shared/model/constants/styles'
import ClickAwayListener from '@shared/ui/ClickAwayListener'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput as Input } from '@shared/ui/inputs'
import { MenuItem, MenuList } from '@shared/ui/menu'
import Popup from '@shared/ui/Popup'
import theme from '@theme'

const Wrapper = styled.div`
  position: relative;
`

const StyledInput = styled(Input)`
  padding-right: 30px;
`

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-50%);

  display: flex;
  align-items: center;

  padding-right: 4px;
  height: ${LAYOUT.audienceBuilder.heightOfRow}px;

  cursor: pointer;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 140px;
`

export const StyledLoader = styled(CircularProgress)`
  && {
    color: ${({ theme }) => theme.colors.brightBlue};
  }
`

interface Option {
  label: string
  value: string
}

interface Props {
  type: string
  uniqueId: number | string
  defaultValue: string
  onChange: (value: string) => void
  options: Option[]
  isFetching: boolean
  fetchData: (props: { type: string; value: string }) => void
  onPopupMenuClose?: () => void
  disabled?: boolean
  isError?: boolean
  placeholder?: string
  bgColor?: string
}

const debounceTime = 300

const TextSearchField = ({
  type,
  uniqueId,
  defaultValue,
  onChange,
  options,
  isFetching,
  fetchData,
  onPopupMenuClose,
  disabled,
  isError,
  placeholder,
  bgColor,
}: Props) => {
  const timerFetch = useRef<ReturnType<typeof setTimeout>>()
  const timerUpdateState = useRef<ReturnType<typeof setTimeout>>()
  const [anchorId, setAnchorId] = useState('')
  const [currValue, setCurrValue] = useState(defaultValue || '')
  const textInputId = `anchor-id-audience-builder-text-input_${uniqueId}`
  const isShowResetValueButton = Boolean(currValue) && !disabled

  // TODO: refactor debounce, how to pass React.MutableRefObject( createRef/ useRef ) down
  const debounceFetch = (value: string) => {
    if (timerFetch.current) {
      clearTimeout(timerFetch.current)
    }
    timerFetch.current = setTimeout(() => {
      if (value) {
        fetchData({ type, value })
      }
    }, debounceTime)
  }

  const debounceUpdateParentState = (value: string) => {
    if (timerUpdateState.current) {
      clearTimeout(timerUpdateState.current)
    }
    timerUpdateState.current = setTimeout(() => {
      onChange(value)
    }, debounceTime)
  }

  const handlePopupMenuClose = () => {
    setAnchorId('')
    if (onPopupMenuClose) {
      onPopupMenuClose()
    }
  }

  const handlePopupMenuOpen = () => {
    setAnchorId(textInputId)
  }

  const handleValueChanged = (value: string) => {
    setCurrValue(value)
    debounceUpdateParentState(value)
  }

  useEffect(() => {
    // reset local state by props
    setCurrValue(defaultValue)
  }, [defaultValue])

  return (
    <Wrapper>
      <StyledInput
        type="text"
        id={textInputId}
        value={currValue}
        onFocus={() => {
          handlePopupMenuOpen()
          if (currValue) {
            debounceFetch(currValue)
          }
        }}
        onChange={e => {
          const value = e?.target?.value || ''
          handleValueChanged(value)
          debounceFetch(value)
          handlePopupMenuOpen()
        }}
        marginRightRatio={1}
        disabled={disabled}
        isError={isError}
        placeholder={placeholder}
        bgColor={bgColor}
      />

      {isShowResetValueButton && (
        <IconWrapper
          onClick={() => {
            handleValueChanged('')
          }}
        >
          <Icon
            icon={ICON.timesCircle}
            fontSize="small"
            color={theme.colors.textSecondBlue}
          />
        </IconWrapper>
      )}

      <ClickAwayListener
        // do not close popup when input is clicked
        anchorIds={[anchorId, textInputId]}
        onClickAway={handlePopupMenuClose}
      >
        <Popup
          anchorId={anchorId}
          closePopup={() => {
            if (!isFetching) {
              // do not close popup when fetching
              handlePopupMenuClose()
            }
          }}
          customStyle={{
            offsetTop: 5,
            offsetLeft: 0,
          }}
          placement="bottom-start"
        >
          <Paper>
            <MenuList>
              {isFetching ? (
                <LoadingWrapper>
                  <StyledLoader />
                </LoadingWrapper>
              ) : (
                options.map(option => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      if (currValue !== option.value) {
                        handleValueChanged(option.value)
                      }
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))
              )}
            </MenuList>
          </Paper>
        </Popup>
      </ClickAwayListener>
    </Wrapper>
  )
}

export default TextSearchField
