import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { ProgressIcon } from '@shared/ui/icons'

import { ICON_MARGIN_LEFT } from './constants'

type ButtonWithProcessingIconProps = {
  width?: number
  wording?: string
}

export const ButtonWithProcessingIcon = ({
  width,
  wording,
}: ButtonWithProcessingIconProps) => (
  <DeepBlueGradientButton disabled width={width}>
    {wording}
    <ProgressIcon marginLeft={ICON_MARGIN_LEFT} />
  </DeepBlueGradientButton>
)

export default ButtonWithProcessingIcon
