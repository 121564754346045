import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { AppBar, Toolbar } from '@mui/material'

import { PAGE_ROOT } from '@shared/model/constants/routes'
import { BlackBGWhiteTextButton, BlueBorderButton } from '@shared/ui/buttons'

export const HEADER_HEIGHT = 64

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`

const StyledAppBar = styled(AppBar)`
  && {
    width: 100%;
    height: ${HEADER_HEIGHT}px;

    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.05);
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
`

const StyledToolbar = styled(Toolbar)`
  && {
    display: flex;
    justify-content: space-between;
    padding: 0 36px;
  }
`

const IKalaLogoIcon = styled.img`
  height: 26px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${HEADER_HEIGHT}px;
`

const TitleWrapper = styled.div`
  position: relative;
`

const Title = styled.div`
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 32px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;

  @media (max-width: 1080px) {
    top: 20px;
    font-size: 24px;
  }

  @media (max-width: 900px) {
    top: 20px;
    font-size: 20px;
  }
`

const Subtitle = styled.div`
  position: absolute;
  top: 88px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 32px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;
  white-space: nowrap;

  @media (max-width: 1080px) {
    top: 66px;
    font-size: 24px;
  }

  @media (max-width: 900px) {
    top: 50px;
    font-size: 20px;
  }
`

const Img = styled.img`
  width: 100%;
`

const TextWrapper = styled.div`
  margin: 60px 0 48px 0;
`

const Text = styled.div`
  display: flex;
  justify-content: center;

  font-size: 18px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`

const InfoBoxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
  width: 100%;
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px 12px;
  width: 290px;
  border-top: 4px solid ${({ theme }) => theme.colors.brightBlue};
  border-bottom: 4px solid ${({ theme }) => theme.colors.brightBlue};
`

const InfoTitle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 18px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 700;
`

const Info = styled.div`
  color: ${({ theme }) => theme.colors.raven};
  font-size: 15px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  text-align: center;
  line-height: 1.7;
`

const BoldText = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;
  line-height: 1.7;
`

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 160px;
  background: linear-gradient(180deg, #0f57cf 0%, #0f52c5 100%);
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 900;
`

const ButtonWrapper = styled.div`
  margin-left: 40px;
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 36px;
  width: 100%;
  height: ${HEADER_HEIGHT}px;

  background-color: #0041af;

  color: #fff;
  font-size: 12px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Anchor = styled.div`
  margin-left: 32px;
  color: #fff;
  text-decoration: underline;
  text-transform: uppercase;
`

export const Footer = () => {
  const year = useMemo(() => new Date().getFullYear(), [])

  return (
    <FooterWrapper>
      <div>Copyright © {year} iKala All Rights Reserved.</div>
      <LinkWrapper>
        <Link to={`/${PAGE_ROOT.privacyPolicy}`}>
          <Anchor>Privacy policy</Anchor>
        </Link>

        <Link to={`/${PAGE_ROOT.termsOfService}`}>
          <Anchor>{`Terms & conditions`}</Anchor>
        </Link>
      </LinkWrapper>
    </FooterWrapper>
  )
}

export const Header = () => (
  <StyledAppBar position="fixed">
    <StyledToolbar>
      <IKalaLogoIcon src="/images/ikala-cdp-logo.png" />

      <Link to={`/${PAGE_ROOT.signIn}`}>
        <BlueBorderButton>LOG IN</BlueBorderButton>
      </Link>
    </StyledToolbar>
  </StyledAppBar>
)

enum LOADED_STATE {
  none = 0,
  firstImage = 1,
  allDone = 2,
}

const ProductIntro = () => {
  const [loadedState, setLoadedState] = useState<LOADED_STATE>(0)

  const handleImageOnLoaded = () => {
    setLoadedState(prev => {
      if (prev === LOADED_STATE.none) {
        return LOADED_STATE.firstImage
      }
      if (prev === LOADED_STATE.firstImage) {
        return LOADED_STATE.allDone
      }
      return prev
    })
  }

  const isLoaded = loadedState === LOADED_STATE.allDone

  return (
    <Wrapper>
      <Header />

      <Content>
        <TitleWrapper>
          {isLoaded && (
            <>
              <Title>iKala CDP</Title>
              <Subtitle>
                Create a comprehensive single view of customers
              </Subtitle>
            </>
          )}
          <Img
            src="/images/product-intro-functionality.png"
            onLoad={handleImageOnLoaded}
          />
        </TitleWrapper>

        <TextWrapper>
          {isLoaded && (
            <>
              <Text>
                Consolidate business data. Know your customers. Deliver
                personalized messages.
              </Text>
              <Text>Engage better. Drive performance-based success.</Text>
            </>
          )}
        </TextWrapper>

        <Img
          src="/images/product-intro-landing-page.png"
          onLoad={handleImageOnLoaded}
        />

        <InfoBoxWrapper>
          {isLoaded && (
            <>
              <InfoBox>
                <InfoTitle>{`Unify & Sync`}</InfoTitle>
                <Info>
                  Collect and sort data across the business units. Consolidate{' '}
                  <BoldText>in-house</BoldText> and{' '}
                  <BoldText> third-party data </BoldText>in a unified platform
                  for effective data alignment and management.
                </Info>
              </InfoBox>

              <InfoBox>
                <InfoTitle>{`Single Customer View`}</InfoTitle>
                <Info>
                  Track all customer behaviors and interactions with real-time
                  events. <BoldText>Visualize customer profiles</BoldText> for
                  better understand your customer without technical skills.
                </Info>
              </InfoBox>

              <InfoBox>
                <InfoTitle>{`Acquire & Activate`}</InfoTitle>
                <Info>
                  Machine Learning-enabled business analytics to build a{' '}
                  <BoldText>customer-centric marketing</BoldText> engage
                  strategy. Deliver personalized messages through multi-channel
                  campaigns.
                </Info>
              </InfoBox>
            </>
          )}
        </InfoBoxWrapper>
      </Content>

      {isLoaded && (
        <>
          <Banner>
            Ready to Drive Your Data-based Success?
            <Link to={`/${PAGE_ROOT.signIn}`}>
              <ButtonWrapper>
                <BlackBGWhiteTextButton width={180} height={42}>
                  LOG IN
                </BlackBGWhiteTextButton>
              </ButtonWrapper>
            </Link>
          </Banner>

          <Footer />
        </>
      )}
    </Wrapper>
  )
}

export default ProductIntro
