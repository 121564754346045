import { ALL_CHART_TYPE, ChartType } from '@widgets/audience/model/constant'

export const checkAllChartError = (
  chartError: { [x in ChartType]?: boolean }
): boolean => {
  // 如果沒有全部圖表都完成是 return false
  if (Object.keys(chartError).length !== ALL_CHART_TYPE.length) {
    return false
  }

  return Object.values(chartError).every(error => error)
}
