import ReactApexChart from 'react-apexcharts'

import type { ChartData } from '@shared/ui/charts'
import theme from '@theme'

import {
  ChartContainer,
  checkHasGroup,
  fullDateDisplay,
  getDisplayData,
  getHeight,
  getLongestSeriesLenIdx,
  getSparseXLables,
  getTooltipHTMLString,
  getTooltipHTMLStringLineGroup,
  getTransposeArray,
  simplifyDateDisplay,
  SizeMode,
  useChartDataSeriesGroupEntity,
} from './_shared'

type Props = {
  chartTitle?: string
  colors: string[]
  data: ChartData[]
  size?: SizeMode
}

export const LineChart = ({
  chartTitle = '',
  colors,
  data,
  size = 'large',
}: Props) => {
  /*
    // example
    data = [
      {
        categories: ['1/1', '1/2', '1/3', '1/4'],
        series: [
          {
            name: '安安',
            data: [5, 6, 7, 8]
          },
          {
            name: '你好',
            data: [9, null, 3, 10]
          },
          {
            name: '嗎嗎',
            data: [31, 12, 30, 25]
          }
        ]
      },
      {
        categories: ['10/1', '10/2', '10/3', '10/4'],
        series: [
          {
            name: '安安',
            data: [1, 14, 27]
          },
          {
            name: '你好',
            data: [20, 21, 22, 23]
          },
          {
            name: '嗎嗎',
            data: [18, 9, null, 23]
          }
        ]
      }
    ]
  */

  /*
    data.map(({ categories }) => categories) = 
    [
      ['1/1', '1/2', '1/3', '1/4'],
      ['10/1', '10/2', '10/3', '10/4']
    ]
    =>
    transposeCategories = 
    [
      ['1/1', '10/1'],
      ['1/2', '10/2'],
      ['1/3', '10/3'],
      ['1/4', '10/4'],
    ]
  */
  const transposeCategories = getTransposeArray<string>(
    data.map(({ categories }) => categories.map(fullDateDisplay))
  )

  const dateDisplay = getTransposeArray<string>(
    data.map(({ categories }) => categories.map(simplifyDateDisplay))
  )
  /*
    xAxisCategories = ['1/1 / 10/1', '1/2 / 10/2', '1/3 / 10/3', '1/4 / 10/4']
  */
  const xAxisCategories = dateDisplay.map(arr => arr.join(' / '))
  const series = data.map(({ series }) => series).flat()

  // 在現在時間與對比時間中，選 series 長度較長的 idx 做 iteration
  const { longestSeriesLenIdx } = getLongestSeriesLenIdx(data)

  const chartDataSeriesGroupEntity = useChartDataSeriesGroupEntity(data)

  /*
    偶數的 series 每條線都是實線，array 裡面放 0，表示寬度為 0
    基數的 series 每條線都是虛線，array 裡面放 5，表示虛線的寬度
    dashArray = [0, 0, 0, 0, 5, 5, 5, 5]
  */
  const dashArray = data
    .map(({ series }, idx) => series.map(() => (idx % 2 === 0 ? 0 : 5)).flat())
    .flat()

  const height = getHeight(size)

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [...colors, ...colors],
    stroke: {
      width: 3,
      curve: 'smooth',
      dashArray,
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: getSparseXLables(xAxisCategories, size),
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
      min: 0,
    },
    tooltip: {
      fixed: {
        enabled: size !== 'large',
        offsetY: height,
      },
      custom: function ({
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) {
        /*
          dataPointIndex = 1
          header = ['some-chart-title', '1/2', '10/2']
        */
        const dateLabels = Array.isArray(transposeCategories[dataPointIndex])
          ? transposeCategories[dataPointIndex]
          : []
        const header = [chartTitle, ...dateLabels]

        // 超過 1 個群組，代表有多個 group 顯示另一種 Tooltip UI
        if (checkHasGroup(chartDataSeriesGroupEntity)) {
          return getTooltipHTMLStringLineGroup({
            header,
            entity: chartDataSeriesGroupEntity,
            colors,
            dataPointIndex,
            data,
          })
        }

        /*
          name = ['安安', '你好', '嗎嗎']
        */
        const names = data[longestSeriesLenIdx].series.map(
          ({ name, label }) => `${name} | ${label}`
        )

        /*
          data2D = [
            [6, 14],
            [null, 21],
            [12, 9]
          ]
        */
        const data2D = data[longestSeriesLenIdx].series.map((obj, idx) => [
          getDisplayData(data?.[0]?.series?.[idx]?.data?.[dataPointIndex]),
          getDisplayData(data?.[1]?.series?.[idx]?.data?.[dataPointIndex]),
        ])

        return getTooltipHTMLString({ header, colors, names, data2D })
      },
    },
    grid: {
      borderColor: theme.colors.borderColor,
    },
  }

  return (
    <ChartContainer sx={{ height }}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height}
      />
    </ChartContainer>
  )
}

export default LineChart
