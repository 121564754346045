/**
 * 計算 table 資料總和
 *
 * @typeparam T 類型參數，代表表資料中每一行的類型，必須是 Record<string, unknown> 的子類型。
 * @param tableData 要聚合的表資料，必須是 T[] 類型的陣列。
 * @param option 可選的組態項，用於指定初始列值和要忽略的 key。
 * @param option.initialColumnValues? 指定總和資料初始的列值，如果沒有指定則默認為空對象。類型為 Partial<T>。
 * @param option.ignoreKeys? 指定要忽略的key，這些 key 不會被計算到結果中。類型為 (keyof T)[]。
 * @returns 計算後的資料，與原始資料的類型相同。類型為 T。
 */

const useTableAggregationData = <T extends Record<string, unknown> = {}>(
  tableData: T[],
  option: {
    initialColumnValues?: Partial<T>
    ignoreKeys?: (keyof T)[]
  } = {}
): T => {
  const { initialColumnValues = {}, ignoreKeys = [] } = option

  const tableAggregationData = tableData.reduce(
    (acc: Record<string, unknown>, cur) => {
      Object.entries(cur).forEach(([key, value]) => {
        if (!ignoreKeys.includes(key)) {
          if (typeof value === 'number') {
            acc[key] = ((acc[key] as number) || 0) + value
          }
          if (typeof value === 'string') {
            acc[key] = `${(acc[key] as string) || ''}-${value}`
          }
          if (Array.isArray(value)) {
            acc[key] = ((acc[key] as unknown[]) || []).concat(value)
          }
        }
      })
      return acc
    },
    { ...initialColumnValues }
  )

  return tableAggregationData as T
}

export default useTableAggregationData
