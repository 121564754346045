import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type Design,
  type TemplateEmail,
  EmailTemplate,
} from '@entities/mediumTemplates'
import { ConfirmDialog } from '@shared/ui/dialogs'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (editorJson?: Design) => void
}

const SelectTemplateDialog = ({
  isOpen,
  onClose: handleClose,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const [selection, setSelection] = useState<TemplateEmail>()

  return (
    <ConfirmDialog
      isOpen={isOpen}
      maxWidth="md"
      modalTitle={t('contentTemplate:please_select_sms_content_template')}
      onConfirm={() => {
        onSubmit(selection?.editorJson)
      }}
      onClose={handleClose}
    >
      <EmailTemplate onSelect={setSelection} />
    </ConfirmDialog>
  )
}

export default SelectTemplateDialog
