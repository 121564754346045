import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import round from 'lodash/round'

import { formatNumberByLang } from '@shared/lib/utils/number'
import theme from '@theme'

import { ChartContainer, getHeight, SizeMode } from './_shared'

export type DonutData = {
  labels: string[]
  series: number[]
  totalLabel?: string
}

type Props = {
  donutData: DonutData
  colors: string[]
  size?: SizeMode
}

export const DonutChart = ({ size = 'large', colors, donutData }: Props) => {
  const total = donutData.series.reduce((acc, cur) => acc + cur, 0)
  const { i18n } = useTranslation(['common'])
  const language = i18n.language
  const pieStartAngle = round((donutData.series[0] / total) * 180, 0) || 0

  const options: ApexCharts.ApexOptions = {
    labels: donutData.labels,
    chart: {
      type: 'donut',
      parentHeightOffset: 0,
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: 'bottom',
      offsetY: 7,
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 20,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    stroke: {
      width: 0,
    },
    colors: colors,
    dataLabels: {
      distributed: false,
      formatter: val => round(+val) + '%',
      dropShadow: { enabled: false },
    },
    plotOptions: {
      pie: {
        startAngle: -pieStartAngle,
        endAngle: 360 - pieStartAngle,
        customScale: 1,
        donut: {
          labels: {
            show: true,
            name: {
              fontWeight: 400,
              color: theme.colors.black,
              fontSize: '12px',
            },
            value: {
              color: theme.colors.dark,
              // fontSize: '32px', // 不指定value的字型大小, 讓apexchart來決定, 因為 `200 Thousand` 會破版
              fontWeight: 500,
            },
            total: {
              showAlways: true,
              show: true,
              label: donutData?.totalLabel || 'Total',
              formatter: () =>
                formatNumberByLang(total, {
                  language,
                  digits: 0,
                }),
            },
          },
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, w }) => {
        const value = series[seriesIndex]
        const label = w.config.labels[seriesIndex]

        const result: string[] = []

        result.push(`<div class="tooltip-column">`)
        result.push(
          `<div class="tooltip-name tooltip-name-center">${label}</div>`
        )
        result.push(
          `<div class="tooltip-value tooltip-value-left">${Number(
            value
          ).toLocaleString()} (${round(value / total, 2) * 100}%)</div>`
        )
        result.push(`</div>`)

        return `<div class="tooltip-wrapper">${result.join('')}</div>`
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  }

  const height = getHeight(size)

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <ChartContainer>
        <ReactApexChart
          height={height}
          options={options}
          series={donutData.series}
          type="donut"
        />
      </ChartContainer>
    </Box>
  )
}

export default DonutChart
