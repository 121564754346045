import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import type { Simplify } from '@shared/lib/utils/type'

import {
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
} from '..'
import { api } from '../api'
import { PrivacyLevel } from './eventType'
import {
  ChartDataFromApi,
  FunnelAnalyticsNode,
  FunnelRule,
  FunnelRuleCreatePayload,
  FunnelRuleExport,
} from './funnelType'

const funnelAnalyticsApi = api.injectEndpoints({
  endpoints: builder => ({
    getFunnelRuleSummary: builder.query<ChartDataFromApi, FunnelAnalyticsNode>({
      query: payload => ({
        url: '/funnel_rules/preview_statistic_summary',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
    }),

    getFunnelRuleList: builder.query<
      PaginationResponse<FunnelRule>,
      PaginationParams & { privacyLevel: PrivacyLevel; groupId: number }
    >({
      query: ({ page, perPage, privacyLevel, groupId }) => ({
        url: `/funnel_rules?funnel_rule_type=${privacyLevel}&page=${page}&per_page=${perPage}&group_id=${groupId}`,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: response =>
        camelcaseKeys<PaginationResponse<FunnelRule>>(
          response as PaginationResponse<FunnelRule>,
          {
            deep: true,
          }
        ),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'FunnelRuleList' as const,
                id,
              })),
              { type: 'FunnelRuleList', id: 'LIST' },
            ]
          : [{ type: 'FunnelRuleList', id: 'LIST' }],
    }),

    getFunnelRule: builder.query<FunnelRule, number>({
      query: id => ({
        url: `/funnel_rules/${id}`,
      }),
      transformResponse: response =>
        camelcaseKeys<FunnelRule>(response as FunnelRule, { deep: true }),
      providesTags: (result, error, id) => [{ type: 'FunnelRuleList', id }],
    }),

    createFunnelRule: builder.mutation<void, FunnelRuleCreatePayload>({
      query: payload => ({
        url: '/funnel_rules',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: [{ type: 'FunnelRuleList', id: 'LIST' }],
    }),

    updateFunnelRule: builder.mutation<void, Simplify<Omit<FunnelRule, 'iam'>>>(
      {
        query: payload => ({
          url: `/funnel_rules/${payload.id}`,
          method: 'PUT',
          body: snakecaseKeys(payload, { deep: true }),
        }),
        invalidatesTags: (result, error, payload) => [
          { type: 'FunnelRuleList', id: 'LIST' },
          { type: 'FunnelRuleList', id: payload.id },
        ],
      }
    ),

    deleteFunnelRule: builder.mutation<void, number>({
      query: id => ({
        url: `/funnel_rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FunnelRuleList', id: 'LIST' }],
    }),

    exportFunnelRuleCSV: builder.query<FunnelRuleExport, FunnelAnalyticsNode>({
      query: payload => ({
        url: `/funnel_rules/export`,
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      transformResponse: response =>
        camelcaseKeys(response as FunnelRuleExport, { deep: true }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetFunnelRuleSummaryQuery,
  useLazyGetFunnelRuleSummaryQuery,
  useGetFunnelRuleListQuery,
  useGetFunnelRuleQuery,
  useCreateFunnelRuleMutation,
  useUpdateFunnelRuleMutation,
  useDeleteFunnelRuleMutation,
  useLazyExportFunnelRuleCSVQuery,
} = funnelAnalyticsApi
