import { SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'

import CheckboxList, { Option } from '@shared/ui/CheckboxList'
import Dropdown from '@shared/ui/Dropdown'
import type { Placement } from '@shared/ui/Popup'
import theme from '@theme'

import RoundedSearchBar from './RoundedSearchBar'

export type { Option }

const CheckboxDropdownWrapper = styled.div<{
  hasShadow?: boolean
  isFullWidth?: boolean
}>`
  display: inline-block;

  width: ${({ isFullWidth }) => isFullWidth && '100%'};
  box-shadow: ${({ theme, hasShadow }) =>
    hasShadow && `0px 4px 9px ${theme.colors.black6}`};
`

type Props = {
  anchorElem?: JSX.Element
  bgColor?: string
  checkedValues: string[]
  hasSearchBar?: boolean
  hasSelectAll?: boolean
  hasShadow?: boolean
  isDisabled?: boolean
  isError?: boolean
  isFullWidth?: boolean
  label?: string
  onChange: (value: string[]) => void
  onClose?: () => void
  options: Option[]
  popperClassName?: string
  popperDisablePortal?: boolean
  placement?: Placement
  searchBarPlaceholder?: string
  selectAllLabel?: string
  updateOn?: 'blur' | 'change'
  maxVisibleListHeight?: number
}

const DropdownCheckboxList = ({
  anchorElem,
  bgColor = theme.colors.white,
  checkedValues,
  hasSearchBar = false,
  hasSelectAll,
  hasShadow,
  isDisabled = false,
  isError = false,
  isFullWidth,
  label = '',
  onChange,
  onClose,
  options,
  placement = 'bottom-start',
  popperClassName,
  popperDisablePortal = false,
  searchBarPlaceholder,
  selectAllLabel,
  updateOn = 'blur',
  maxVisibleListHeight = 320,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')

  const [newCheckedValues, setNewCheckedValues] =
    useState<string[]>(checkedValues)

  const handleClose = (isOpen: SetStateAction<boolean>) => {
    setIsOpen(isOpen)

    if (isOpen === false) {
      onChange(newCheckedValues)
      setSearchText('')
      onClose?.()
    }
  }

  const handleChange = (value: string[]) => {
    setNewCheckedValues(value)

    if (updateOn === 'change') {
      onChange(value)
    }
  }

  const filteredOptions = useMemo(
    () => options.filter(option => option.label.includes(searchText)),
    [options, searchText]
  )

  const { t } = useTranslation(['common'])

  return (
    <CheckboxDropdownWrapper hasShadow={hasShadow} isFullWidth={isFullWidth}>
      <Dropdown
        isError={isError}
        isDisabled={isDisabled}
        placement={placement}
        popperClassName={popperClassName}
        popperDisablePortal={popperDisablePortal}
        bgColor={bgColor}
        isOpen={isOpen}
        label={label}
        anchorElem={anchorElem}
        setIsOpen={handleClose}
        isFullWidth={isFullWidth}
      >
        {hasSearchBar && (
          <>
            <RoundedSearchBar
              onChange={setSearchText}
              placeholder={searchBarPlaceholder ?? t('common:search')}
              autoFocus
              value={searchText}
            />
            <Divider />
          </>
        )}

        <CheckboxList
          maxHeight={maxVisibleListHeight}
          selectAllLabel={selectAllLabel}
          checkedValues={newCheckedValues}
          onChange={handleChange}
          options={filteredOptions}
          hasSelectAll={hasSelectAll}
        />
      </Dropdown>
    </CheckboxDropdownWrapper>
  )
}

export default DropdownCheckboxList
