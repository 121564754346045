import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import type { FunnelChartData } from '@shared/api/rtkQuery'
import {
  ChartContainer,
  getHeight,
  getSparseXLables,
  getTooltipHTMLString,
  getTransposeArray,
  simplifyDateDisplay,
  SizeMode,
} from '@shared/ui/charts'
import theme from '@theme'

import { getTooltipData } from './helpers'

type Props = {
  colors: string[]
  data: FunnelChartData
  size?: SizeMode
}

const LineChart = ({ colors, data, size = 'large' }: Props) => {
  /*
    // example
    data = {
      categories: ['10/1', '10/2', '10/3', '10/4'],
      series: [
        {
          name: '日本 - 男性',
          data: [100, 75, 83, 60],
          rawData: [8, 6, 5, 3],
        },
      ],
    }
  */
  const { t } = useTranslation(['dateAndChart'])

  const { categories, series } = data || {
    categories: [],
    series: [],
  }
  const xAxisCategories = categories.map(simplifyDateDisplay)

  const transposeConversionRates = getTransposeArray<number | null | string>(
    series.map(({ data }) => data)
  )
  const transposeConversionNumbers = getTransposeArray<number | null | string>(
    series.map(({ rawData }) => rawData)
  )
  const transposeLostRates = getTransposeArray<number | null | string>(
    data.series.map(({ lostRate }) => lostRate)
  )
  const transposeLostNumbers = getTransposeArray<number | null | string>(
    data.series.map(({ lostNumber }) => lostNumber)
  )

  const height = getHeight(size)

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        // 資料數量不多顯示動畫，否則太花時間
        // enabled: series.length <= 10,
        // speed: 400,
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [...colors, ...colors],
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: getSparseXLables(xAxisCategories, size),
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
      min: 0,
    },
    tooltip: {
      fixed: {
        enabled: size !== 'large',
        offsetY: height,
      },
      custom: function ({
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) {
        /*
          dataPointIndex = 1
          header = ['some-chart-title', '1/2', '10/2']
        */
        const date = xAxisCategories[dataPointIndex]
        const header = [
          `${date}`,
          t('dateAndChart:statistics.conversion_number'),
          t('dateAndChart:statistics.conversion_rate'),
          t('dateAndChart:statistics.loss_number'),
          t('dateAndChart:statistics.loss_rate'),
        ]

        /*
          name = ['安安', '你好', '嗎嗎']
        */
        const names = data.series.map(({ name }) => name)
        /*
          data2D = [
            [6, 14],
            [null, 21],
            [12, 9]
          ]
        */
        const data2D = getTooltipData({
          dataPointIndex,
          transposeConversionRates,
          transposeConversionNumbers,
          transposeLostRates,
          transposeLostNumbers,
        })

        return getTooltipHTMLString({
          header,
          colors,
          names,
          data2D,
        })
      },
    },
    grid: {
      borderColor: theme.colors.borderColor,
    },
  }

  return (
    <ChartContainer sx={{ height }}>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={height}
      />
    </ChartContainer>
  )
}

export default LineChart
