import { Fragment } from 'react'
import styled from '@emotion/styled'

type Props = {
  text: string
  target?: string
  targetColor?: string
}

const Text = styled.span`
  white-space: pre-line;
`

const Highlight = styled.span`
  color: ${({ theme, color }) => color || theme.colors.brightBlue};
`

const HighlightText = ({ text, target = '', targetColor }: Props) => {
  if (target.length === 0) {
    return <>{text}</>
  }

  return (
    <Text>
      {text.split(target).map((text, index) => {
        if (index === 0) {
          return <Fragment key={index}>{text}</Fragment>
        }

        return (
          <Fragment key={index}>
            <Highlight color={targetColor}>{target}</Highlight>
            {text}
          </Fragment>
        )
      })}
    </Text>
  )
}

export default HighlightText
