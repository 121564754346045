import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormik } from 'formik'

import {
  useCreateAudienceRuleJobMutation,
  useCreateWorkflowMutation,
  useGetWorkflowQuery,
  Workflow,
} from '@shared/api/rtkQuery'
import { useAppDispatch, useSearchParams } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { getIntSafe } from '@shared/lib/utils/number'
import {
  CATEGORY,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog } from '@shared/ui/dialogs'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import theme from '@theme'

import { fetchAudienceId, getFormTriggerNodeType } from './_shared/utils'
import WorkflowForm from './_shared/WorkflowForm'
import WorkflowFormStateProvider from './_shared/WorkflowFormStateContext'
import { useProcessWorkflowError } from './_shared'

const NO_DATA_ID = 0
const MA_TITLE_MAX_LENGTH = 100

type FormValues = {
  name: string
  workflowData?: Workflow
  isEnableOnSave: boolean
}

const WorkflowCreate = () => {
  const query = useSearchParams()
  const referenceId = getIntSafe(
    query.get(QUERY_STRING.common.referenceId),
    NO_DATA_ID
  )

  const { data: workflow, isFetching: isFetchingReferenceData } =
    useGetWorkflowQuery(referenceId, {
      skip: referenceId === NO_DATA_ID,
    })

  const referenceData = useMemo<Workflow | undefined>(() => {
    if (!workflow) {
      return undefined
    }

    return {
      ...workflow,
      status: 'draft',
    }
  }, [workflow])

  const [createWorkflow, { isLoading: isCreatingWorkflow }] =
    useCreateWorkflowMutation()

  const [createAudienceRuleJob, { isLoading: isCreatingAudienceRuleJob }] =
    useCreateAudienceRuleJobMutation()

  const dispatch = useAppDispatch()

  const [processWorkflowError] = useProcessWorkflowError()

  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false)
  const [isSubmitDraft, setIsSubmitDraft] = useState<boolean>(false)

  const handleWorkflowFormSubmit = async (formValues: Workflow) => {
    formik.setFieldValue('workflowData', formValues)

    setIsSubmitDraft(false)
    setIsOpenConfirmDialog(true)
  }

  const handleWorkflowFormSubmitDraft = (formValues: Workflow) => {
    formik.setFieldValue('workflowData', formValues)

    setIsSubmitDraft(true)
    setIsOpenConfirmDialog(true)
  }

  const history = useHistory()

  const formik = useFormik<FormValues>({
    initialValues: {
      name: '',
      workflowData: undefined,
      isEnableOnSave: false,
    },
    validate: values => {
      const errors: Record<string, string> = {}

      if (!values.name) {
        errors.name = t('workflow:errors.workflow_name_required')
      }

      if (values.name.length > MA_TITLE_MAX_LENGTH) {
        errors.name = t('common:input.limit_word_length', {
          max_length: MA_TITLE_MAX_LENGTH,
        })
      }

      return errors
    },
    onSubmit: async ({ name, isEnableOnSave, workflowData }) => {
      if (!workflowData) {
        return
      }

      const status = (() => {
        if (isSubmitDraft) {
          return 'draft'
        }

        if (isEnableOnSave) {
          return 'enabled'
        }

        return 'disabled'
      })()

      try {
        const reply = await createWorkflow({
          ...workflowData,
          name,
          status,
        }).unwrap()

        const targetAudienceId = fetchAudienceId(workflowData.nodes)
        const triggerNodeType = getFormTriggerNodeType(workflowData.nodes)

        if (
          isEnableOnSave &&
          targetAudienceId &&
          !isSubmitDraft &&
          triggerNodeType === 'trigger-enter_audience_rule'
        ) {
          await createAudienceRuleJob(targetAudienceId)
        }

        dispatch(openToast({ message: t('common:save_success') }))

        if (isSubmitDraft && reply?.workflowId) {
          trackEvent('MarketingAutomationDrafted', {
            workflowId: reply.workflowId,
            workflowName: name,
          })

          history.push(
            `/${CATEGORY.ma}/${PAGE_ROOT.workflows}/${reply.workflowId}/edit`
          )

          return
        }

        trackEvent('MarketingAutomationCreated', {
          workflowId: reply?.workflowId,
          workflowName: name,
        })

        history.push(`/${CATEGORY.ma}/${PAGE_ROOT.workflows}`)
      } catch (error) {
        processWorkflowError(error, workflowData.name)
      }
    },
  })

  const { t } = useTranslation(['common', 'workflow', 'audience'])

  const triggerNodeType = getFormTriggerNodeType(
    formik.values.workflowData?.nodes ?? []
  )

  const audienceType = useMemo(() => {
    if (!triggerNodeType) {
      return ''
    }

    if (triggerNodeType === 'trigger-enter_audience_rule') {
      return 'dynamic'
    }

    if (triggerNodeType === 'trigger-current_audience_rule') {
      return 'static'
    }
  }, [triggerNodeType])

  return (
    <WorkflowFormStateProvider>
      <WorkflowForm
        initFormValues={referenceData}
        isLoading={isFetchingReferenceData}
        isSubmitting={
          !isSubmitDraft && (isCreatingWorkflow || isCreatingAudienceRuleJob)
        }
        isSubmittingDraft={isSubmitDraft && isCreatingWorkflow}
        onSubmit={handleWorkflowFormSubmit}
        onSubmitDraft={handleWorkflowFormSubmitDraft}
      />

      <ConfirmDialog
        isLoading={isCreatingWorkflow || isCreatingAudienceRuleJob}
        isOpen={isOpenConfirmDialog}
        modalTitle={
          <Box>
            {isSubmitDraft && t('workflow:save_draft')}
            {!isSubmitDraft &&
              audienceType &&
              t('workflow:save_workflow', {
                audience_type: t(`audience:type_${audienceType}`),
              })}
            {!isSubmitDraft && (
              <Box
                display="inline"
                color={theme.colors.textSecondBlue}
                fontSize="14px"
                ml="10px"
              >
                {`(${t('workflow:cant_edit_after_saving')})`}
              </Box>
            )}
          </Box>
        }
        onClose={() => setIsOpenConfirmDialog(false)}
        onConfirm={formik.handleSubmit}
      >
        <TextInput
          color="primary"
          fullWidth
          name="name"
          placeholder={t('workflow:save_workflow_placeholder')}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
        />
        <TextInputErrorText>
          {formik.touched.name && formik.errors.name}
        </TextInputErrorText>

        {!isSubmitDraft && (
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.isEnableOnSave}
                color="primary"
                name="isEnableOnSave"
                onChange={formik.handleChange}
              />
            }
            label={
              <Box component="span" sx={{ fontSize: 14 }}>
                {audienceType === 'static' &&
                  t('workflow:workflow_confirm_enable_label')}
                {audienceType === 'dynamic' &&
                  t(
                    'workflow:workflow_confirm_enable_label_with_dynamic_audience'
                  )}
              </Box>
            }
          />
        )}
      </ConfirmDialog>
    </WorkflowFormStateProvider>
  )
}

export default WorkflowCreate
