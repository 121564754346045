import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import {
  BehaviorTagSchedulingType,
  DATA_SOURCE_TYPE_IMPORT,
  DataSourceType,
  TagSchedulingType,
  TagType,
  useTagGroupsQuery,
} from '@shared/api/rtkQuery'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import Card from '@shared/ui/Card'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { PrimaryGreyInput } from '@shared/ui/inputs'

import useHandleError from '../../hooks/useHandleApiError'
import useTagValidator from '../../hooks/useTagValidator'
import { TagSourceType } from '../../type'
import BehaviorUpdateMethod from './BehaviorUpdateMethod'
import GenerationMethod from './GenerationMethod'
import { InputArea, InputAreaGrid, LabelArea, Text } from './styles'
import UpdateMethod from './UpdateMethod'

type CommonTagInfoValues = {
  id?: number
  title: string
  description: string
  groupId: number
}

export type TagInfoValues<T extends TagSourceType> = CommonTagInfoValues &
  (T extends 'grading' | 'intelligent'
    ? { type: TagType; schedulingType: TagSchedulingType }
    : T extends 'behavior'
    ? {
        dataSourceType: DataSourceType
        schedulingType: BehaviorTagSchedulingType
      }
    : never)

export type Props<T extends TagSourceType> = {
  tagSourceType?: Exclude<TagSourceType, 'thirdParty'>
  isEnableEdit?: boolean
  values: TagInfoValues<T>
  onChange: (values: TagInfoValues<T>) => void
  onUpdate?: (values: TagInfoValues<T>) => Promise<void>
  onCancel?: () => void
}

const TagInfo = <T extends Exclude<TagSourceType, 'thirdParty'>>({
  tagSourceType = 'grading',
  isEnableEdit = false,
  values,
  onChange,
  onUpdate,
  onCancel,
}: Props<T>) => {
  // isEnableEdit= false 的時候表示在create tag, isEnableEdit= true 的時候表示在edit tag
  const [isEdit, setIsEdit] = useState(!isEnableEdit)
  const [handleTagApiError] = useHandleError(tagSourceType)
  const { infoDataValidator, behaviorInfoDataValidator } = useTagValidator()

  const { t } = useTranslation(['tag', 'common'])

  const { options: tagGroupOptions, isTagGroupSuccess } = useTagGroupsQuery(
    null,
    {
      selectFromResult: ({ data = [], isSuccess }) => {
        const options: Option[] = [{ label: t('tag:no_group'), value: 0 }]

        return {
          options: data.reduce((acc, curr) => {
            return acc.concat({ label: curr.title, value: curr.id })
          }, options),
          isTagGroupSuccess: isSuccess,
        }
      },
    }
  )

  const handleTagInfoUpdate = async () => {
    if (!isEnableEdit || !onUpdate) {
      return
    }

    if (
      tagSourceType === 'grading' &&
      !infoDataValidator(values as unknown as TagInfoValues<'grading'>)
    ) {
      return
    }

    if (
      tagSourceType === 'behavior' &&
      !behaviorInfoDataValidator(values as unknown as TagInfoValues<'behavior'>)
    ) {
      return
    }

    try {
      await onUpdate({ ...values })

      setIsEdit(false)
    } catch (e) {
      handleTagApiError(e)
    }
  }

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel()
    }

    setIsEdit(false)
  }

  const defaultOption = useMemo(
    () =>
      isTagGroupSuccess
        ? tagGroupOptions.find(option => option.value === values.groupId)
        : tagGroupOptions[0],
    [isTagGroupSuccess, tagGroupOptions, values.groupId]
  )

  const isEditable = useMemo(() => {
    // 智慧標籤不可編輯
    if (tagSourceType === 'intelligent') {
      return false
    }

    return isEdit
  }, [isEdit, tagSourceType])

  return (
    <Card mb={5} p={4}>
      <InputAreaGrid mb={3}>
        <LabelArea>
          <Text>{`${t('tag:tag_name')}*`}</Text>
        </LabelArea>

        <InputArea>
          <PrimaryGreyInput
            fullWidth
            type="text"
            value={values.title}
            width={260}
            marginRightRatio={4}
            disabled={!isEditable}
            onChange={e => {
              onChange({
                ...values,
                title: e.target.value.slice(0, MAX_LIST_NAME_LENGTH),
              })
            }}
          />
          <InputAreaGrid>
            <LabelArea>
              <Text>{`${t('tag:tag_group')}*`}</Text>
            </LabelArea>

            <InputArea>
              <DropdownList
                key={`${tagGroupOptions[tagGroupOptions.length - 1]?.label}_${
                  values.groupId
                }`}
                maxWidth={240}
                defaultOption={defaultOption}
                options={tagGroupOptions}
                onValueChanged={option => {
                  if (!isTagGroupSuccess) {
                    return
                  }

                  onChange({
                    ...values,
                    groupId: parseInt(option.value as string, 10),
                  })
                }}
                uniqueId={'tag-group'}
                isMinWidth
                disabled={!isEditable}
                popupMaxWidth={460}
              />
            </InputArea>
          </InputAreaGrid>
          {isEnableEdit && (
            <Box display="flex" fontSize={16} whiteSpace="nowrap">
              {isEditable ? (
                <>
                  <Button onClick={handleOnCancel} color="inherit">
                    {t('common:cancel')}
                  </Button>

                  <Button
                    onClick={handleTagInfoUpdate}
                    color="primary"
                    type="submit"
                  >
                    {t('common:save')}
                  </Button>
                </>
              ) : (
                <Button onClick={() => setIsEdit(true)} color="primary">
                  {t('common:edit')}
                </Button>
              )}
            </Box>
          )}
        </InputArea>
      </InputAreaGrid>

      <InputAreaGrid mb={3}>
        <LabelArea>
          <Text>{t('tag:tag_description')}</Text>
        </LabelArea>

        <InputArea>
          <PrimaryGreyInput
            fullWidth
            type="text"
            placeholder={t('tag:enter_short_description_for_tag')}
            value={values.description}
            disabled={!isEditable}
            onChange={e => {
              onChange({ ...values, description: e.target.value })
            }}
          />
        </InputArea>
      </InputAreaGrid>

      <Box
        mb={4}
        sx={theme => ({
          borderBottom: `1px solid ${theme.colors.black6}`,
        })}
      />
      {tagSourceType === 'behavior' &&
        // 匯入的行為標籤不需要顯示產生方式
        (values as unknown as TagInfoValues<'behavior'>).dataSourceType !==
          DATA_SOURCE_TYPE_IMPORT && (
          <GenerationMethod
            isEdit={isEditable}
            dataSourceType={
              (values as unknown as TagInfoValues<'behavior'>).dataSourceType
            }
            onChange={dataSourceType => {
              onChange({ ...values, dataSourceType })
            }}
          />
        )}

      {tagSourceType !== 'behavior' && (
        <UpdateMethod
          isEdit={isEditable}
          tagType={
            (values as unknown as TagInfoValues<'grading' | 'intelligent'>).type
          }
          value={
            (values as unknown as TagInfoValues<'grading' | 'intelligent'>)
              .schedulingType
          }
          onChange={schedulingType => {
            onChange({ ...values, schedulingType })
          }}
        />
      )}

      {tagSourceType === 'behavior' && (
        <BehaviorUpdateMethod
          isEdit={isEditable}
          dataSourceType={
            (values as unknown as TagInfoValues<'behavior'>).dataSourceType
          }
          value={
            (values as unknown as TagInfoValues<'behavior'>).schedulingType
          }
          onChange={schedulingType => {
            onChange({ ...values, schedulingType })
          }}
        />
      )}
    </Card>
  )
}

export default TagInfo
