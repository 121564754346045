import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Caret } from '@shared/ui/Accordion'
import type { ChartData } from '@shared/ui/charts'
import { COLORS, useChartDataSeriesGroupEntity } from '@shared/ui/charts'
import { TooltipWhenOverflowX } from '@shared/ui/tooltips'
import theme from '@theme'

import {
  Checkbox,
  Circle,
  Figcaption,
  Figure,
  Item,
  Label,
  List,
} from './styles'

type VisibilityListProps = {
  data: ChartData[]
  visibilityFlags: boolean[]
  setVisibilityFlags: Dispatch<SetStateAction<boolean[]>>
}

export const VisibilityList = ({
  data,
  visibilityFlags,
  setVisibilityFlags,
}: VisibilityListProps) => {
  const { t } = useTranslation(['userProfile'])

  const chartDataSeriesGroupEntity = useChartDataSeriesGroupEntity(data)
  const [isOpenFigureList, setIsOpenFigureList] = useState<boolean[]>([])

  useEffect(() => {
    setIsOpenFigureList(
      Array<boolean>(chartDataSeriesGroupEntity.ids.length).fill(true)
    )
  }, [chartDataSeriesGroupEntity])

  return (
    <>
      <Item
        onClick={() => {
          setVisibilityFlags(prev =>
            prev.every(x => x === true)
              ? prev.map(() => false)
              : prev.map(() => true)
          )
        }}
      >
        <Checkbox
          checked={visibilityFlags.every(x => x === true)}
          color="primary"
          size="small"
        />
        <Label isVisible>{t('userProfile:select_all')}</Label>
      </Item>

      {chartDataSeriesGroupEntity.ids.map((id, index) => (
        <Figure key={id} open={isOpenFigureList[index]}>
          {id && (
            <Figcaption
              onClick={() => {
                setIsOpenFigureList(prev => {
                  const newList = [...prev]
                  newList[index] = !newList[index]
                  return newList
                })
              }}
            >
              <div>
                <Caret
                  direction={isOpenFigureList[index] ? 'down' : 'right'}
                  color={theme.colors.black}
                />
              </div>
              {id}
            </Figcaption>
          )}
          <List>
            {chartDataSeriesGroupEntity.entities[id].map(
              ({ name, colorIndex }, idx) => (
                <li key={`${name}_${idx}`}>
                  <Item
                    onClick={() => {
                      setVisibilityFlags(prev => {
                        const newFlags = [...prev]
                        newFlags[colorIndex] = !prev[colorIndex]
                        return newFlags
                      })
                    }}
                  >
                    <Circle
                      bgColor={COLORS[colorIndex]}
                      isVisible={visibilityFlags[colorIndex]}
                    />
                    <TooltipWhenOverflowX title={name}>
                      <Label isVisible={visibilityFlags[colorIndex]}>
                        {name}
                      </Label>
                    </TooltipWhenOverflowX>
                  </Item>
                </li>
              )
            )}
          </List>
        </Figure>
      ))}
    </>
  )
}

export default VisibilityList
