import { useMemo } from 'react'
import IconButton from '@mui/material/IconButton'

import { AudienceRule, JobStatus } from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { TableColumn } from '@shared/ui/table'
import theme from '@theme'

import ActionMenuList from './ActionMenuList'
import StatusWithTooltip from './StatusWithTooltip'

type Props = {
  createAudienceRuleJob: (audienceRuleId: number) => void
  isOpenDeleteDialog: boolean
  isOpenRenameDialog: boolean
  openActionMenuId: number | undefined
  setOpenActionMenuId: React.Dispatch<React.SetStateAction<number | undefined>>
  setIsOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  setRenameText: React.Dispatch<React.SetStateAction<string>>
  setIsOpenRenameDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const useAudienceListColumns = ({
  createAudienceRuleJob,
  isOpenDeleteDialog,
  isOpenRenameDialog,
  openActionMenuId,
  setOpenActionMenuId,
  setIsOpenDeleteDialog,
  setRenameText,
  setIsOpenRenameDialog,
}: Props) => {
  const columns: TableColumn<AudienceRule>[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'latestJobStatus',
        colRatio: 0.5,
        valueFormatter: status => {
          return <StatusWithTooltip status={status as JobStatus} />
        },
      },
      {
        headerName: 'name',
        field: 'title',
      },
      {
        headerName: 'population',
        field: 'latestUserCount',
        colRatio: 0.5,
        align: 'right',
        valueFormatter: value => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatNumber(value as number)}
          </span>
        ),
      },
      {
        headerName: 'create_time',
        field: 'createdAt',
        valueFormatter: value => formatDateTimeDisplay(value as string),
      },
      {
        headerName: 'last_update_time',
        field: 'latestAudienceRuleUserCreatedAt',
        valueFormatter: value => formatDateTimeDisplay(value as string),
      },
      {
        headerName: 'creator',
        field: 'creatorName',
      },
      {
        headerName: '',
        field: 'id',
        colRatio: 0.5,
        paddingTop: 1,
        paddingBottom: 1,
        valueFormatter: (_, { id, latestJobStatus, title }) => {
          const currId = getIntSafe(id)

          return (
            <Dropdown
              isOpen={openActionMenuId === currId}
              setIsOpen={() => {
                if (isOpenDeleteDialog || isOpenRenameDialog) {
                  setOpenActionMenuId(currId)
                  return
                }
                setOpenActionMenuId(undefined)
              }}
              anchorElem={
                <IconButton
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()
                    setOpenActionMenuId(currId)
                  }}
                >
                  <Icon
                    icon={ICON.ellipsisV}
                    color={theme.colors.textPrimaryBlue}
                    fontSize="small"
                  />
                </IconButton>
              }
              placement="left-start"
            >
              <ActionMenuList
                audienceRuleId={currId}
                status={latestJobStatus as JobStatus}
                onDeleteDialogOpen={() => {
                  setIsOpenDeleteDialog(true)
                }}
                onRenameDialogOpen={() => {
                  setRenameText(title)
                  setIsOpenRenameDialog(true)
                }}
                onCreateAudienceRuleJob={() => {
                  createAudienceRuleJob(currId)
                  setOpenActionMenuId(undefined)
                }}
              />
            </Dropdown>
          )
        },
      },
    ],
    [
      createAudienceRuleJob,
      isOpenDeleteDialog,
      isOpenRenameDialog,
      openActionMenuId,
      setIsOpenDeleteDialog,
      setIsOpenRenameDialog,
      setOpenActionMenuId,
      setRenameText,
    ]
  )

  return columns
}

export default useAudienceListColumns
