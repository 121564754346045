import type { DashboardIndicator } from '../IndicatorList'
import type { ChartData } from '../types'
import formatIndicatorValue from './formatIndicatorValue'
import getLatestSeries from './getLatestSeries'

export const getDashboardIndicatorAverage = (
  chartData: ChartData[]
): DashboardIndicator[] => {
  const seriesLatest = getLatestSeries(chartData[0].series)
  const dataLength = seriesLatest.data.length
  if (dataLength === 0) {
    return []
  }

  const valueTotal = seriesLatest.data.reduce((acc, curr) => acc + curr, 0)
  const valueAverage = +(valueTotal / dataLength).toFixed(2)

  return [{ indicator: 'average', value: formatIndicatorValue(valueAverage) }]
}

export default getDashboardIndicatorAverage
