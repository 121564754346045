import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { go, push } from 'redux-first-history'
import { Epic } from 'redux-observable'
import { concat, EMPTY, from, iif, of, throwError } from 'rxjs'
import { catchError, delay, filter, mergeMap } from 'rxjs/operators'

import { getCDPAuthToken, getProjectList } from '@shared/api/axios/auth'
import { firebaseApp } from '@shared/lib/firebase'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { getDateTimestampSafe } from '@shared/lib/utils/time'
import {
  CATEGORY,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'

import { decodeCdpAuthToken } from '../utils'
import {
  changePlan,
  changeProjectIKAId,
  fetchCDPAuthToken,
  fetchCDPAuthTokenSuccess,
  fetchProjectList,
  fetchProjectListFailure,
  fetchProjectListSuccess,
  initAuth,
  initAuthDone,
  initFirebaseAuth,
  initSSOAuth,
  logout,
  logoutDone,
  refreshFirebaseAuth,
  setFirebaseAuth,
  setProjectIKAId,
  setUserInfo,
  signInFirebase,
} from './slices'

// TODO: design error message later
const ERROR_MESSAGE = {
  FIREBASE_AUTH_INIT: 'firebase auth init error',
  FIREBASE_AUTH: 'firebase auth error',
  FIREBASE_LOGOUT: 'firebase logout error',
  FIREBASE_ID_TOKEN: 'firebase get idToken error',
  CDP_PROJECT_LIST: 'cdp projectId not found',
  CDP_AUTH_TOKEN: 'cdp auth token error',
  GOOGLE_ACCESS_TOKEN: 'google access token error',
}

const twelveHours = 12 * 60 * 60 * 1000
const fiftyMins = 50 * 60 * 1000

const initAuthEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(initAuth.match),
    mergeMap(() => {
      const cdpAuthToken = state$.value.auth.cdpAuthToken
      const payload = decodeCdpAuthToken(cdpAuthToken)

      if (payload && payload.authType === 'sso') {
        return concat(
          of(fetchCDPAuthTokenSuccess(cdpAuthToken), initAuthDone()),
          of(logout()).pipe(delay(payload.expirationTime))
        )
      }

      const { location } = state$.value.router

      if (location) {
        const { pathname, search } = location

        const query = new URLSearchParams(search)
        const serviceId = query.get(QUERY_STRING.auth.serviceId)
        const ssoToken = query.get(QUERY_STRING.auth.ssoToken)

        if (pathname === `/${PAGE_ROOT.signIn}` && serviceId && ssoToken) {
          return of(initSSOAuth({ serviceId, jwt: ssoToken }))
        }
      }

      return of(initFirebaseAuth())
    })
  )

/*
  Firebase Auth Flow: 
    1. 初始化時，從 localStorage 拿 projectIKAId 放進 redux
    2. 從 firebase 拿到 idToken 與 accessToken
    3. idToken 放進 axios headers 與 redux
    4. 發 api /projects 拿到 projectList
      - 檢查 redux.projectIKAId 是否在 projectList 裡
        - 如果沒有，更新 redux.projectIKAId 與 localStorage.projectIKAId 成 projectList 的第一筆
        - 如果有，維持
    5. 發 api /token 拿到 cdpAuthToken
      - 帶入 step.4 更新的 redux.projectIKAId 與 redux.idToken
    6. cdpAuthToken 放進 axios headers 與 redux

    TODO: cdpAuthToken 目前直接存進 axios instance, 可以不用存在 redux
 */

/*
  app 初始化階段
  若已經在登入狀態，重新整理頁面時要能夠繞過 singIn，進入認證流程
*/
const initFirebaseAuthEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(initFirebaseAuth.match),
    mergeMap(() => {
      const { authTime: firebaseAuthTime } = state$.value.auth.firebase
      const now = new Date().getTime()

      // TODO: test only
      console.log(
        'in initFirebaseAuth, check now - authTime >= twelveHours: ',
        now - firebaseAuthTime >= twelveHours,
        '\nauthTime: ',
        new Date(firebaseAuthTime).toLocaleString(),
        '\nnow: ',
        new Date(now).toLocaleString()
      )

      // TODO: to delete or not
      if (now - firebaseAuthTime >= twelveHours) {
        console.log(
          `it's been 12 hours since last time you're authenticated by firebase, last auth time is ${new Date(
            firebaseAuthTime
          ).toLocaleString()}. please login again.`
        )
        return of(logout())
      }

      const auth = getAuth(firebaseApp)

      return from(
        new Promise(
          (
            resolve: (value?: unknown) => void,
            reject: (err?: string | Error) => void
          ) => {
            onAuthStateChanged(
              auth,
              user => {
                if (user) {
                  resolve()
                }
                reject('not auth')
              },
              err => {
                reject(err)
              }
            )
          }
        )
      ).pipe(
        mergeMap(() => of(signInFirebase())),
        catchError(err => {
          console.log(`${ERROR_MESSAGE.FIREBASE_AUTH_INIT}: ${err}`)
          return of(logout())
        })
      )
    })
  )

const refreshFirebaseAuthEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(refreshFirebaseAuth.match),
    mergeMap(() => {
      const { authTime: firebaseAuthTime } = state$.value.auth.firebase
      const now = new Date().getTime()

      // TODO: test only
      console.log(
        'in refreshFirebaseAuth, check now - authTime >= twelveHours: ',
        now - firebaseAuthTime >= twelveHours,
        '\nauthTime: ',
        new Date(firebaseAuthTime).toLocaleString(),
        '\nnow: ',
        new Date(now).toLocaleString()
      )

      // TODO: to delete or not
      if (now - firebaseAuthTime >= twelveHours) {
        console.log(
          `it's been 12 hours since last time you're authenticated by firebase, last auth time is ${new Date(
            firebaseAuthTime
          ).toLocaleString()}. please login again.`
        )
      }

      return iif(
        () => now - firebaseAuthTime >= twelveHours,
        of(logout()),
        of(signInFirebase())
      )
    })
  )

const signInFirebaseEpic: Epic<RootAction, RootAction, RootState> = actions$ =>
  actions$.pipe(
    filter(signInFirebase.match),
    mergeMap(() =>
      from(
        new Promise(
          (
            resolve: (data: {
              idToken: string
              userInfo: { displayName: string; photoURL: string; email: string }
              authTime: number
            }) => void,
            reject: (err: string) => void
          ) => {
            const currentUser = getAuth(firebaseApp).currentUser

            if (currentUser) {
              currentUser
                .getIdTokenResult(true)
                .then(({ authTime, token, expirationTime }) => {
                  // TODO: test only
                  console.log(
                    'in signInFirebase',
                    '\nauthTime: ',
                    new Date(authTime).toLocaleString(),
                    '\nexpirationTime: ',
                    new Date(expirationTime).toLocaleString(),
                    '\nnow: ',
                    new Date().toLocaleString()
                  )

                  resolve({
                    idToken: token,
                    userInfo: {
                      displayName: currentUser.displayName || '',
                      photoURL: currentUser.photoURL || '',
                      email: currentUser.email || '',
                    },
                    authTime: getDateTimestampSafe(authTime),
                  })
                })
                .catch(err => {
                  reject(err)
                })
            } else {
              reject(ERROR_MESSAGE.FIREBASE_AUTH)
            }
          }
        )
      ).pipe(
        mergeMap(({ idToken, userInfo, authTime }) =>
          concat(
            of(
              setFirebaseAuth({ idToken, authTime }),
              setUserInfo(userInfo),
              fetchProjectList()
            ),
            of(refreshFirebaseAuth()).pipe(delay(fiftyMins))
          )
        ),
        catchError(err => {
          console.log(`${ERROR_MESSAGE.FIREBASE_AUTH}: ${err}`)
          return of(logout())
        })
      )
    )
  )

const fetchProjectIdsEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(fetchProjectList.match),
    mergeMap(() =>
      from(getProjectList()).pipe(
        mergeMap(projectList => {
          if (projectList.length) {
            const project =
              projectList.find(
                // 從  storage.getProjectIKAId() 設定的 IKAId
                x => x.ikaId === state$.value.auth.projectIKAId
              ) ?? projectList[0]

            return of(
              fetchProjectListSuccess({ projectList, ikaId: project.ikaId }),
              changePlan(project.planType),
              fetchCDPAuthToken()
            )
          }

          return throwError(new Error(ERROR_MESSAGE.CDP_PROJECT_LIST))
        }),
        catchError(err => {
          console.log(err)
          return of(fetchProjectListFailure(), logout())
        })
      )
    )
  )

const initSSOAuthEpic: Epic<RootAction, RootAction, RootState> = actions$ =>
  actions$.pipe(
    filter(initSSOAuth.match),
    mergeMap(action => of(fetchCDPAuthToken(action.payload)))
  )

const fetchCDPAuthTokenEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(fetchCDPAuthToken.match),
    mergeMap(action =>
      from(
        getCDPAuthToken(
          action.payload || {
            ikaId: state$.value.auth.projectIKAId,
            firebaseIdToken: state$.value.auth.firebase.idToken,
          }
        )
      ).pipe(
        mergeMap(token => {
          const cdpAuthPayload = decodeCdpAuthToken(token)

          if (cdpAuthPayload === undefined) {
            return EMPTY
          }

          const searchParams = new URLSearchParams(
            state$.value.router.location?.search
          )
          const returnUrl = searchParams.get(QUERY_STRING.auth.returnURL) ?? ''

          trackEvent('LoginSuccess', { userId: cdpAuthPayload.userId })

          return concat(
            of(fetchCDPAuthTokenSuccess(token), initAuthDone()),
            // 如果網址出現 returnUrl 就進行轉導
            returnUrl ? of(push(returnUrl)) : EMPTY,
            of(logout()).pipe(delay(cdpAuthPayload.expirationTime))
          )
        }),
        catchError(() => {
          console.log(`${ERROR_MESSAGE.CDP_AUTH_TOKEN}`)

          trackEvent('LoginFail')

          return of(logout())
        })
      )
    )
  )

const logoutEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(logout.match),
    mergeMap(() => {
      const auth = getAuth(firebaseApp)

      return from(auth.signOut()).pipe(
        mergeMap(() => {
          trackEvent('LogoutSuccess')

          return of(logoutDone())
        }),
        catchError(err => {
          console.log(`${ERROR_MESSAGE.FIREBASE_LOGOUT}: ${err}`)

          trackEvent('LogoutFail')

          return of(logoutDone())
        })
      )
    })
  )

const changeProjectIKAIdEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(changeProjectIKAId.match),
    mergeMap(action => {
      const searchParams = new URLSearchParams(
        state$.value.router.location?.search
      )
      searchParams.append(QUERY_STRING.auth.returnURL, `/${CATEGORY.cdm}`)

      return of(
        setProjectIKAId(action.payload),
        // 直接轉導會讓用戶在重整前看到頁面變化，因此先將路由放到 query 重整後再轉導
        push(`?${searchParams}`),
        go(0)
      )
    })
  )

const epics = [
  changeProjectIKAIdEpic,
  fetchCDPAuthTokenEpic,
  fetchProjectIdsEpic,
  initAuthEpic,
  initFirebaseAuthEpic,
  initSSOAuthEpic,
  logoutEpic,
  refreshFirebaseAuthEpic,
  signInFirebaseEpic,
]

export default epics
