type Feature = 'consumptions'

const FEATURE_TOGGLE = (
  import.meta.env.VITE_FEATURE_ENABLE_LIST?.split(',') || []
).reduce((acc, curr) => {
  acc[curr as Feature] = true
  return acc
}, {} as Record<Feature, boolean>)

export default FEATURE_TOGGLE
