import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import throttle from 'lodash/throttle'

import { formatRTKQueryError } from '@entities/apiHandler'
import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  INITIAL_PAGINATION_RESPONSE,
  TAG_TYPE_INTERNAL_OFFLINE,
  useAddTagGroupMutation,
  useDeleteTagGroupByIdMutation,
  useGetBehaviorTagListQuery,
  usePatchBehaviorTagMutation,
  useTagGroupsQuery,
  useTagListQuery,
  useUpdateTagGroupMutation,
  useUpdateTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import { TAG_GROUP_MAX_COUNT } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { SearchInput } from '@shared/ui/searchInput'

import { STATUS_CODE } from './constant'
import TagBoard from './TagBoard'
import type { TagGroupMapType, TagSimpleType } from './type'

const SCROLL_THROTTLE_DURATION = 50
const HIGHEST_SCROLL_SPEED = 250
const LOWEST_SCROLL_SPEED = 10
const SCROLL_SENSE_AREA_HEIGHT = 300

// 分成慢速區域和快速區域，讓慢速區域大一點比較好操作，而不是像原本一樣速度等比成長
const SLOW_AREA_HIGHEST_SPEED =
  (HIGHEST_SCROLL_SPEED - LOWEST_SCROLL_SPEED) / 5 + LOWEST_SCROLL_SPEED
const SLOW_AREA_HEIGHT = (SCROLL_SENSE_AREA_HEIGHT / 3) * 2
const QUICK_AREA_HEIGHT = SCROLL_SENSE_AREA_HEIGHT - SLOW_AREA_HEIGHT

// 有 groupId 為 0 ，為 未分類 的分類，但 api 取資料不會有該資料，只好自己建
const DEFAULT_TAG_DATA_MAP: TagGroupMapType = {
  0: {
    id: 0,
    title: '',
    description: '',
    tags: [],
  },
}

const countSpeed = (distanceToEdge: number) => {
  if (distanceToEdge > QUICK_AREA_HEIGHT) {
    return (
      ((SCROLL_SENSE_AREA_HEIGHT - distanceToEdge) / SLOW_AREA_HEIGHT) *
        (SLOW_AREA_HIGHEST_SPEED - LOWEST_SCROLL_SPEED) +
      LOWEST_SCROLL_SPEED
    )
  } else {
    return (
      ((QUICK_AREA_HEIGHT - distanceToEdge) / QUICK_AREA_HEIGHT) *
        (HIGHEST_SCROLL_SPEED - SLOW_AREA_HIGHEST_SPEED) +
      SLOW_AREA_HIGHEST_SPEED
    )
  }
}

const scrollMore = (toY: number) => {
  window.scrollTo({
    top: window.scrollY + toY,
    behavior: 'smooth',
  })
}

const scrollWhileDragging = (evt: DragEvent) => {
  const heightOfHeader = 74
  const containerHeight =
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ) - heightOfHeader

  const cursorPosY = evt.clientY - heightOfHeader

  if (cursorPosY < SCROLL_SENSE_AREA_HEIGHT) {
    scrollMore(0 - countSpeed(cursorPosY))
  } else if (containerHeight - cursorPosY < SCROLL_SENSE_AREA_HEIGHT) {
    scrollMore(countSpeed(containerHeight - cursorPosY))
  }
}

const TagGroupList = () => {
  const { t } = useTranslation(['common', 'tag'])
  const dispatch = useAppDispatch()

  const [searchGroupText, setSearchGroupText] = useState<string>('')
  const [selectedTagId, setSelectedTagId] = useState<string>('')

  const [addTagGroup, { isLoading: isAdding }] = useAddTagGroupMutation()
  const [updateTagGroup, { isLoading: isUpdating }] =
    useUpdateTagGroupMutation()
  const [deleteTagGroup, { isLoading: isDeleting }] =
    useDeleteTagGroupByIdMutation()
  const [updateTag, { isLoading: isUpdatingTag }] = useUpdateTagMutation()
  const [patchBehaviorTag, { isLoading: isUpdatingBehaviorTag }] =
    usePatchBehaviorTagMutation()

  const { data: groups, isLoading: isFetching } = useTagGroupsQuery(null, {
    refetchOnMountOrArgChange: true,
  })

  const { tagList, isFetchingTags } = useTagListQuery(
    {
      page: 1,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data = [], isLoading }) => ({
        tagList: data.filter(({ type }) => type !== TAG_TYPE_INTERNAL_OFFLINE),
        isFetchingTags: isLoading,
      }),
    }
  )

  const { behaviorTagList, isFetchingBehaviorTags } =
    useGetBehaviorTagListQuery(
      {
        page: -1,
        perPage: -1,
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({
          data = INITIAL_PAGINATION_RESPONSE,
          isLoading,
        }) => ({
          behaviorTagList: data.items,
          isFetchingBehaviorTags: isLoading,
        }),
      }
    )

  const addTagGroupFunc = useCallback(
    (title: string) => {
      addTagGroup({ title, description: '' })
        .unwrap()
        .catch(err => {
          const formattedError = formatRTKQueryError(err)

          if (formattedError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
            dispatch(
              openToast({
                message: t('common:errors.duplicate_name'),
                status: 'error',
              })
            )
          } else {
            dispatch(
              openToast({
                message: t('common:failure_create'),
                status: 'error',
              })
            )
          }
        })
    },
    [addTagGroup, dispatch, t]
  )

  const updateTagGroupFunc = useCallback(
    (id: number, title: string) => {
      updateTagGroup({ id, body: { title, description: '' } })
        .unwrap()
        .catch(err => {
          dispatch(
            openToast({
              message: t('common:failure_update'),
              status: 'error',
            })
          )
        })
    },
    [updateTagGroup, dispatch, t]
  )

  const deleteTagGroupFunc = useCallback(
    (id: number) => {
      deleteTagGroup(id)
        .unwrap()
        .then(res => {
          dispatch(
            openToast({
              message: t('tag:succeed_delete_group'),
              status: 'success',
            })
          )
        })
        .catch(err => {
          dispatch(
            openToast({
              message: t('common:failure_delete'),
              status: 'error',
            })
          )
        })
    },
    [deleteTagGroup, dispatch, t]
  )

  const handleMoveTag = useCallback(
    async (tag: TagSimpleType, targetGroupId: number) => {
      try {
        const tagId = tag.id
        const tagType = tag.tagType

        if (!tagId || !tagType) {
          return
        }

        if (tagType === 'grading') {
          await updateTag({ id: tagId, groupId: targetGroupId })
        }

        if (tagType === 'behavior') {
          await patchBehaviorTag({ id: tagId, groupId: targetGroupId })
        }

        setSelectedTagId(`${tagType}_${tagId}`)
      } catch (error) {
        dispatch(
          openToast({
            message: t('common:failure_update'),
            status: 'error',
          })
        )
      }
    },
    [updateTag, patchBehaviorTag, dispatch, t]
  )

  const allTagData = useMemo(() => {
    const newAllTagData = cloneDeep(DEFAULT_TAG_DATA_MAP)

    if (groups) {
      groups.forEach(group => {
        newAllTagData[group.id] = {
          id: group.id,
          title: group.title,
          description: group.description,
          tags: [],
        }
      })

      if (tagList.length > 0) {
        tagList.forEach(tag => {
          if (newAllTagData[tag.groupId]) {
            newAllTagData[tag.groupId].tags.push({
              id: tag.id,
              title: tag.title,
              tagType: 'grading',
            })
          }
        })
      }

      if (behaviorTagList.length > 0) {
        behaviorTagList.forEach(tag => {
          if (newAllTagData[tag.groupId]) {
            newAllTagData[tag.groupId].tags.push({
              id: tag.id,
              title: tag.title,
              tagType: 'behavior',
            })
          }
        })
      }
    }

    return newAllTagData
  }, [tagList, groups, behaviorTagList])

  const isLoading =
    isUpdatingTag ||
    isFetching ||
    isFetchingTags ||
    isFetchingBehaviorTags ||
    isUpdatingBehaviorTag

  useEffect(() => {
    window.document.body.addEventListener(
      'dragover',
      throttle(scrollWhileDragging, SCROLL_THROTTLE_DURATION)
    )

    return () => {
      window.document.body.removeEventListener(
        'dragover',
        throttle(scrollWhileDragging, SCROLL_THROTTLE_DURATION)
      )
    }
  }, [])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Breadcrumbs>
          <Box>{t('tag:tag_group')}</Box>
        </Breadcrumbs>
        <Box>
          <SearchInput
            placeholder={t('common:search')}
            onSearch={word => setSearchGroupText(word)}
            hasShadow
          />
        </Box>
      </Box>
      {/* 因為順序要是 id 為 0 的先(未分類)，接著由 id 最大的往下排列，故這樣寫 */}
      <TagBoard
        key={allTagData[0].id}
        data={allTagData[0]}
        isDisabled={isLoading}
        selectedTagId={selectedTagId}
        addTagGroup={addTagGroupFunc}
        updateTagGroup={updateTagGroupFunc}
        deleteTagGroup={deleteTagGroupFunc}
        handleMoveTag={handleMoveTag}
        isAdding={isAdding}
        isUpdating={isUpdating}
        isDeleting={isDeleting}
        OverGroupsAmountLimitation={
          groups && groups.length >= TAG_GROUP_MAX_COUNT
        }
      />
      {Object.values(allTagData)
        .reverse()
        .map(group => {
          if (
            (searchGroupText &&
              group.id &&
              !group.title.includes(searchGroupText)) ||
            group.id === 0
          ) {
            return null
          }
          return (
            <TagBoard
              key={group.id}
              data={group}
              isDisabled={isLoading}
              selectedTagId={selectedTagId}
              addTagGroup={addTagGroupFunc}
              updateTagGroup={updateTagGroupFunc}
              deleteTagGroup={deleteTagGroupFunc}
              handleMoveTag={handleMoveTag}
              isAdding={isAdding}
              isUpdating={isUpdating}
              isDeleting={isDeleting}
            />
          )
        })}
    </>
  )
}

export default TagGroupList
