import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { CreateSmsDialog } from '@entities/mediumTemplates'
import {
  ContentTemplate,
  INITIAL_PAGINATION_RESPONSE,
  MediumType,
  useContentTemplateListQuery,
  useDeleteContentTemplateByIdMutation,
  useUpdateContentTemplateMutation,
} from '@shared/api/rtkQuery'
import TrialContentTemplate from '@shared/assets/images/trial/trialContentTemplate.svg'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { SearchInput } from '@shared/ui/searchInput'
import { DataTable } from '@shared/ui/table'

import MediumTypeDropdownList from './MediumTypeDropdownList'
import { Wrapper } from './styles'
import useColumns from './useColumn'

const STATUS_CODE = {
  DUPLICATE_NAME: 409,
}

const Overview = () => {
  const { t } = useTranslation(['common', 'contentTemplate'])
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation<{
    page: number
  }>()

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.contentTemplate.introduction
  )

  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE)
  const [search, setSearch] = useState<string>()
  const [isUpdatingSmsTemplate, setUpdatingSmsTemplate] = useState(false)
  const [selectedMediumTypes, setSelectedMediumTypes] = useState<MediumType[]>(
    []
  )
  const [openDropdownId, setOpenDropdownId] = useState<
    ContentTemplate['id'] | undefined
  >(undefined)
  const [deleteConfirmId, setDeleteConfirmId] = useState<
    ContentTemplate['id'] | undefined
  >(undefined)
  const [smsConfig, setSmsConfig] = useState<
    | Partial<
        Pick<ContentTemplate, 'id' | 'title' | 'body' | 'replacementData'>
      >
    | undefined
  >(undefined)
  const [renameId, setRenameId] = useState<ContentTemplate['id'] | undefined>(
    undefined
  )
  const [renameText, setRenameText] = useState<ContentTemplate['title']>('')

  const { data = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useContentTemplateListQuery({
      page,
      perPage,
      mediumTypes: selectedMediumTypes,
      title: search,
    })

  const [updateData, { isLoading: isUpdating }] =
    useUpdateContentTemplateMutation()

  const [deleteData, { isLoading: isDeleting }] =
    useDeleteContentTemplateByIdMutation()

  const onTableRowClick = (
    data: Pick<
      ContentTemplate,
      'id' | 'title' | 'body' | 'replacementData' | 'mediumType'
    >
  ) => {
    if (data.mediumType === 'email') {
      history.push(`${pathname}/${data.id}`)
      return
    }

    if (data.mediumType === 'sms') {
      setSmsConfig({
        id: data.id,
        title: data.title,
        body: data.body,
        replacementData: data.replacementData,
      })
    }
  }

  const handleMediumTypeDropdownListChange = useCallback(
    (values: string[]) => {
      setSelectedMediumTypes(values as MediumType[])
      setPage(1)
    },
    [setPage]
  )

  const safeCloseAllPopup = useCallback(() => {
    // 需要照順序關閉彈窗避免頁面跳動
    setDeleteConfirmId(undefined)
    setRenameId(undefined)
  }, [])

  const errors = {
    renameText:
      renameText.length === 0
        ? t('common:errors.max_count', {
            count: MAX_LIST_NAME_LENGTH,
          })
        : '',
  }

  const columns = useColumns({
    openDropdownId,
    setOpenDropdownId,
    setSmsConfig,
    setRenameId,
    setRenameText,
    setDeleteConfirmId,
  })

  return (
    <Wrapper>
      {isShowInstruction && (
        <FeatureInstruction
          title={t(
            'contentTemplate:instruction.contentTemplate_instruction_title'
          )}
          description={t(
            'contentTemplate:instruction.contentTemplate_instruction_description'
          )}
          notice={t(
            'contentTemplate:instruction.contentTemplate_instruction_notice'
          )}
          imageUrl={TrialContentTemplate}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={5}
      >
        <Box display="flex" alignItems="center">
          <MediumTypeDropdownList
            onChange={handleMediumTypeDropdownListChange}
          />
        </Box>
        <Box>
          <SearchInput
            hasShadow
            placeholder={t('common:search')}
            onSearch={searchWord => {
              setPage(1)
              setSearch(searchWord)
            }}
          />
        </Box>
      </Box>
      <DataTable
        columns={columns}
        rows={data.items}
        count={data ? data.totalCount : 0}
        isLoading={
          isFetching || isUpdating || isDeleting || isUpdatingSmsTemplate
        }
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="id"
        isRowClickable
        onRowClick={onTableRowClick}
        onPageChange={newPage => setPage(newPage + 1)}
        onRowsPerPageChange={setPerPage}
      />
      {Boolean(smsConfig) && (
        <CreateSmsDialog
          data={smsConfig}
          onClose={() => setSmsConfig(undefined)}
          onLoading={setUpdatingSmsTemplate}
        />
      )}

      <DeleteConfirmDialog
        isLoading={isDeleting}
        isOpen={deleteConfirmId !== undefined}
        modalTitle={t('contentTemplate:sure_to_delete')}
        onClose={() => setDeleteConfirmId(undefined)}
        onConfirm={async () => {
          try {
            if (deleteConfirmId) {
              await deleteData(deleteConfirmId).unwrap()
              safeCloseAllPopup()
            }
          } catch (error) {
            dispatch(handleApiError(formatRTKQueryError(error)))
          }
        }}
      />

      <ConfirmDialog
        isLoading={isUpdating}
        isOpen={renameId !== undefined}
        maxWidth="xs"
        modalTitle={t('common:rename')}
        onClose={safeCloseAllPopup}
        onConfirm={async () => {
          try {
            if (renameId && renameText) {
              await updateData({
                id: renameId,
                body: {
                  title: renameText,
                },
              }).unwrap()
              safeCloseAllPopup()
            }
          } catch (error) {
            const formatError = formatRTKQueryError(error)
            if (formatError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
              dispatch(
                openToast({
                  message: t('common:errors.duplicate_name'),
                  status: 'error',
                })
              )
              return
            }

            dispatch(handleApiError(formatError))
          }
        }}
      >
        <TextInput
          fullWidth
          placeholder={t(
            'contentTemplate:please_enter_new_content_template_name'
          )}
          error={Boolean(errors.renameText)}
          onChange={({ target }) => {
            const newContentTemplateTitle = target.value.substring(
              0,
              MAX_LIST_NAME_LENGTH
            )
            setRenameText(newContentTemplateTitle)
          }}
          value={renameText}
        />
        <TextInputErrorText>
          {errors.renameText && <span>{errors.renameText}</span>}
        </TextInputErrorText>
      </ConfirmDialog>
    </Wrapper>
  )
}

export default Overview
