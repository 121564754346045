import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import camelcaseKeys from 'camelcase-keys'

import {
  TAG_TYPE_INTERNAL,
  TAG_TYPE_OFFLINE,
  TagRuleValues,
  useDeleteTagMutation,
  useGetTagQuery,
  useUpdateTagMutation,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import ActionBar from '@shared/ui/ActionBar'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import DotLoader from '@shared/ui/loaders/DotLoader'
import SectionTitle from '@shared/ui/SectionTitle'
import { Tooltip } from '@shared/ui/tooltips'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

import TagInfo, { TagInfoValues } from '../components/TagInfo'
import TagRule from '../components/TagRule'
import { TagSourceType } from '../type'
import TagImportById from './TagImportById'
import TagResult from './TagResult'

type Props = {
  tagSourceType: Extract<TagSourceType, 'grading' | 'intelligent'>
}

export const TagDetail = ({ tagSourceType }: Props) => {
  const { id } = useParams<{ id: string }>()
  const tagId = getIntSafe(id)

  const [tagValues, setTagValues] = useState<
    TagInfoValues<typeof tagSourceType> & { tagRules?: TagRuleValues[] }
  >()

  const [selectTagRuleIndex, setSelectTagRuleIndex] = useState<number>(-1)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { t } = useTranslation(['tag', 'common'])
  const history = useHistory()

  const { data: tag, isLoading, isSuccess } = useGetTagQuery(tagId)
  const [deleteTag, { isLoading: isDeletingTag }] = useDeleteTagMutation()
  const [updateTag] = useUpdateTagMutation()

  useEffect(() => {
    if (!tag) {
      return
    }

    setTagValues({
      id: tag.id,
      title: tag.title,
      description: tag.description,
      type: tag.type,
      schedulingType: tag.schedulingType,
      groupId: tag.groupId,
      tagRules: (tag.tagRules ?? []).map(tagRule => ({
        ...tagRule,
        // FIXME: 不確定 filter 為什麼定義成字串，先維持原狀，將來將轉換抽到 rtk 層級
        filter: camelcaseKeys(JSON.parse(tagRule.filter), { deep: true }),
      })),
    })

    const tagRuleLength = tag.tagRules?.length ?? 0

    setSelectTagRuleIndex(
      // 允許將 selectTagRuleIndex 設成負索引，當 tag 改變後再轉成正索引
      // 確保 TagRule 在新增/刪除 + 重新打 API 後，selectTagRuleIndex 至少還會改變一次，用以觸發 effect 與重新渲染條件
      prevIndex => (prevIndex + tagRuleLength) % tagRuleLength
    )
  }, [tag])

  const handleUpdate = async (values: TagInfoValues<typeof tagSourceType>) => {
    try {
      await updateTag(values).unwrap()
    } catch (e) {
      throw e
    }
  }

  const handleInfoOnCancel = () => {
    if (!tag || !tagValues) {
      return
    }

    setTagValues({
      ...tagValues,
      title: tag.title,
      description: tag.description,
      groupId: tag.groupId,
      schedulingType: tag.schedulingType,
    })
  }

  const overviewLink: string = `/${CATEGORY.cdm}/${
    PAGE_ROOT.tags
  }/${TagSourceRoutesMap.getRoute(tagSourceType)}`

  return (
    <>
      <Box display="flex" width="100%" justifyContent="space-between">
        <Breadcrumbs>
          <Link to={overviewLink}>{t('common:route.tag_list')}</Link>
          {tag?.title && (
            <Tooltip title={tag.title}>
              <Box>{tag.title}</Box>
            </Tooltip>
          )}
        </Breadcrumbs>
        {tagSourceType !== 'intelligent' && isSuccess && (
          <ActionBar>
            <Button
              onClick={() => setIsDeleteDialogOpen(true)}
              startIcon={<i className={ICON.trashAlt} />}
              color="error"
              variant="contained"
            >
              {t('common:delete')}
            </Button>
          </ActionBar>
        )}
      </Box>

      {isLoading || !tagValues ? (
        <Box display="flex" justifyContent="center">
          <DotLoader />
        </Box>
      ) : (
        <>
          <TagInfo
            tagSourceType={tagSourceType}
            isEnableEdit={tagSourceType !== 'intelligent'}
            values={tagValues as TagInfoValues<typeof tagSourceType>}
            onChange={tagInfo =>
              setTagValues(prev => ({ ...prev, ...tagInfo }))
            }
            onUpdate={handleUpdate}
            onCancel={handleInfoOnCancel}
          />

          {(tagValues as TagInfoValues<typeof tagSourceType>).type ===
            TAG_TYPE_INTERNAL && (
            <>
              <SectionTitle title={t('tag:tag_rule')} />
              <TagRule
                tagId={tagId}
                values={tagValues.tagRules ?? []}
                isFetching={isLoading}
                selectTagRuleIndex={selectTagRuleIndex}
                onSelectTagRuleIndexChange={setSelectTagRuleIndex}
              />
            </>
          )}

          {(tagValues as TagInfoValues<typeof tagSourceType>).type ===
            TAG_TYPE_OFFLINE && (
            <>
              <SectionTitle title={t('tag:import_tag_data')} />
              <TagImportById tagId={tagId} />
            </>
          )}

          <SectionTitle title={t('tag:tag_result')} />

          <TagResult
            tagId={tagId}
            latestJobStatus={tag?.latestJobStatus}
            latestTagUsersCreatedAt={tag?.latestTagUsersCreatedAt ?? ''}
          />
          {tagSourceType !== 'intelligent' && (
            <DeleteConfirmDialog
              isOpen={isDeleteDialogOpen}
              isLoading={isDeletingTag}
              modalTitle={t('tag:sure_want_to_delete_this_tag')}
              onClose={() => setIsDeleteDialogOpen(false)}
              onConfirm={async () => {
                await deleteTag(tagId).unwrap()

                history.push(
                  `/${CATEGORY.cdm}/${
                    PAGE_ROOT.tags
                  }/${TagSourceRoutesMap.getRoute(tagSourceType)}`
                )

                setIsDeleteDialogOpen(false)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default TagDetail
