import styled from '@emotion/styled'

import { BlueBorderButton } from '@shared/ui/buttons'

export const BlueBorderDownloadButton = styled(BlueBorderButton)<{
  isDownloaded?: boolean
}>`
  && {
    width: 100px;
    height: 34px;
    border-color: ${({ isDownloaded, theme }) =>
      isDownloaded ? theme.colors.lightBlueGrey : theme.colors.brightBlue};
    color: ${({ isDownloaded, theme }) =>
      isDownloaded ? theme.colors.lightBlueGrey : theme.colors.brightBlue};
  }
`
