import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { formatRTKQueryError } from '@entities/apiHandler'
import { useUpdateContentTemplateMutation } from '@shared/api/rtkQuery/contentTemplate'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import { STATUS_CODE } from '@widgets/contentSetting/ui/ContentTemplate/constants'

import { Title } from './style'

type Props = {
  title: string
  contentTemplateId: string
  onSetTitle: (value: string) => void
}

const TitleEditor = ({ title, contentTemplateId, onSetTitle }: Props) => {
  const [titleEditable, setTitleEditable] = useState(false)
  const { t } = useTranslation(['common', 'contentTemplate'])
  const dispatch = useAppDispatch()
  const titleInputRef = useRef<HTMLInputElement>(null)
  const [updateContentTemplate, { isLoading }] =
    useUpdateContentTemplateMutation()

  const handleUpdateTitle = async () => {
    if (!titleInputRef?.current?.value) {
      return
    }

    const newTitle = titleInputRef.current.value
    try {
      await updateContentTemplate({
        id: parseInt(contentTemplateId, 10),
        body: {
          title: newTitle,
        },
      }).unwrap()
      setTitleEditable(false)
      onSetTitle(newTitle)
      dispatch(
        openToast({
          message: t('contentTemplate:save_succeed'),
          status: 'success',
        })
      )
    } catch (error) {
      const formatError = formatRTKQueryError(error)
      if (formatError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
        dispatch(
          openToast({
            message: t('common:errors.duplicate_name'),
            status: 'error',
          })
        )
        return
      }
      dispatch(
        openToast({
          message: t('contentTemplate:save_failed'),
          status: 'error',
        })
      )
    }
  }

  const handleEditor = () => {
    setTitleEditable(true)
    if (!titleInputRef?.current?.value) {
      return
    }
    titleInputRef.current.value = title
  }

  if (titleEditable) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TextField
          inputRef={titleInputRef}
          variant="standard"
          size="small"
          label=""
          defaultValue={title}
          InputProps={{
            sx: {
              fontSize: '24px',
              paddingTop: '3px',
            },
          }}
        />

        <Button
          color="inherit"
          disableRipple
          onClick={() => {
            setTitleEditable(false)
          }}
        >
          {t('common:cancel')}
        </Button>
        <Button
          disabled={isLoading}
          disableRipple
          onClick={handleUpdateTitle}
          startIcon={
            isLoading ? (
              <CircularProgress
                size={12}
                sx={{
                  marginRight: '4px',
                  color: theme.colors.black25,
                }}
              />
            ) : null
          }
        >
          {t('common:save')}
        </Button>
      </Box>
    )
  }
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={title}>
        <Title>{title}</Title>
      </Tooltip>

      <Button disableRipple onClick={handleEditor}>
        {t('common:edit')}
      </Button>
    </Box>
  )
}

export default TitleEditor
