import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { EventRuleNode } from '@shared/api/rtkQuery'
import {
  getCategoriesOfClassification,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { AddButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import {
  StatisticMeasureGroupingFilter,
  useCategoriesOfIntersectionEvents,
} from '@widgets/analytics/_shared'

import { STATISTIC_MEASURES_MAX_COUNT } from '../../models/constants'
import type { EventRuleFilterAction } from '../../models/eventRuleFilterReducer'
import type { EventRuleFilterErrors } from '../../types'
import { selectStatisticMeasureEventNameList } from '../../utils'
import StatisticMeasureFilter from './StatisticMeasureFilter'
import { StatisticMeasureListItem } from './styles'
type Props = {
  state: EventRuleNode
  dispatch: (action: EventRuleFilterAction) => void
  errors: EventRuleFilterErrors
}

const EventRuleFilter = ({ state, dispatch, errors }: Props) => {
  const { t } = useTranslation(['analytics', 'audience'])

  const { eventEntity } = useMetadataQuery()
  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )

  const eventNameList = selectStatisticMeasureEventNameList(state)

  const { groupByFields = [] } = state

  const categoriesOfIntersectionEvents = useCategoriesOfIntersectionEvents(
    eventNameList,
    { isNeedDimensionColumnFromEvent: true, groupByFields }
  )

  const isDisabledAddButton =
    state.statisticMeasures.length === STATISTIC_MEASURES_MAX_COUNT

  return (
    <>
      <Card mb={5}>
        <Box mb={2} component="h2">
          {t('analytics:event.page_title')}
        </Box>
        <ul>
          {state.statisticMeasures.map((item, index) => (
            // 使用 index 作為 key，若有排序需求需自行產生 id
            <StatisticMeasureListItem key={index}>
              <StatisticMeasureFilter
                state={item}
                currIndex={index}
                isDeletable={state.statisticMeasures.length > 1}
                isReplicable={state.statisticMeasures.length < 10}
                dispatch={dispatch}
              />
            </StatisticMeasureListItem>
          ))}
        </ul>

        <CategoryList
          categories={categoriesOfClassification}
          anchorElem={
            <ConditionalTooltip
              title={t('audience:v3.filter_max_hint_event_analytics', {
                max: STATISTIC_MEASURES_MAX_COUNT,
              })}
              isShow={isDisabledAddButton}
            >
              <AddButton
                label={t('audience:add')}
                size="small"
                disabled={isDisabledAddButton}
              />
            </ConditionalTooltip>
          }
          isDisabled={isDisabledAddButton}
          onValueChanged={eventName => {
            dispatch({
              type: 'addStatisticMeasuresItem',
              payload: { eventName },
            })
          }}
        />
      </Card>
      <Card>
        <StatisticMeasureGroupingFilter
          categoryList={categoriesOfIntersectionEvents}
          error={errors.groupByFields}
          eventNameList={eventNameList}
          values={state.groupByFields}
          onAdd={({ source, field }) => {
            dispatch({
              type: 'addGroupByFields',
              payload: { field, source },
            })
          }}
          onUpdate={({ source, field, index: targetIndex }) => {
            dispatch({
              type: 'updateGroupByFields',
              payload: { targetIndex, field, source },
            })
          }}
          onDelete={targetIndex => {
            dispatch({
              type: 'removeGroupByFields',
              payload: {
                targetIndex,
              },
            })
          }}
        />
      </Card>
    </>
  )
}

export default memo(EventRuleFilter)
