import type { NodeProps } from 'reactflow'
import * as yup from 'yup'

import type { NodeType } from '@shared/api/rtkQuery'

import EdgeWithDelete from './EdgeWithDelete'
import {
  ActionSendEmailNode,
  actionSendEmailNodeSchema,
  ActionSendLineNode,
  actionSendLineNodeSchema,
  ActionSendSmsNode,
  actionSendSmsNodeSchema,
  ExitNode,
  LogicBestChannelNode,
  logicBestChannelNodeSchema,
  LogicEventFilterNode,
  logicEventFilterNodeSchema,
  LogicGroupNode,
  logicGroupNodeSchema,
  SubscriptionNode,
  subscriptionNodeSchema,
  TimingNode,
  timingNodeSchema,
  TriggerNode,
  triggerNodeSchema,
} from './nodes'

export const STATUS_CODE = {
  NODE_ID_INVALID: 400,
  REACH_MAX_WORKFLOW: 406,
  DUPLICATE_NAME: 409,
  RESOURCE_INVALID: 410,
  NODE_INVALID: 422,
  UPDATE_RACE_CONDITION: 423,
  INTERNAL_SERVER_ERROR: 500,
}

export const NODE_TYPE_COMPONENT_MAP: {
  [name in NodeType]: React.ComponentType<NodeProps>
} = {
  'trigger-current_audience_rule': TriggerNode,
  'trigger-enter_audience_rule': TriggerNode,
  timing: TimingNode,
  'timing-best': TimingNode,
  'logic-event_filter': LogicEventFilterNode,
  'logic-group': LogicGroupNode,
  'logic-best_channel': LogicBestChannelNode,
  'action-send_email': ActionSendEmailNode,
  'action-send_line_push': ActionSendLineNode,
  'action-send_sms': ActionSendSmsNode,
  'action-publish_subscription': SubscriptionNode,
  exit: ExitNode,
}

export const NODE_TYPE_SCHEMA_MAP: {
  [name in NodeType]: yup.AnyObjectSchema | undefined
} = {
  'trigger-enter_audience_rule': triggerNodeSchema,
  'trigger-current_audience_rule': triggerNodeSchema,
  timing: timingNodeSchema,
  'timing-best': timingNodeSchema,
  'logic-event_filter': logicEventFilterNodeSchema,
  'logic-best_channel': logicBestChannelNodeSchema,
  'logic-group': logicGroupNodeSchema,
  'action-send_email': actionSendEmailNodeSchema,
  'action-send_line_push': actionSendLineNodeSchema,
  'action-send_sms': actionSendSmsNodeSchema,
  'action-publish_subscription': subscriptionNodeSchema,
  exit: undefined,
}

export const EDGE_TYPE_COMPONENT_MAP = { edgeWithDelete: EdgeWithDelete }
