import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import RequireIAM from '@features/auth/ui/RequireIAM'
import WorkflowBgUrl from '@shared/assets/images/iamModule/workflow.svg'

import WorkflowCreate from './WorkflowCreate'
import WorkflowEdit from './WorkflowEdit'
import WorkflowOverview from './WorkflowOverview'

export const WorkflowRoutes = () => {
  const { path } = useRouteMatch()

  const { t } = useTranslation(['common', 'iam'])

  return (
    <RequireIAM
      moduleName="mkt_journey"
      noPermission={{
        title: t('common:route.workflow'),
        description: t('iam:require_iam.automation_journey'),
        bgImageUrl: WorkflowBgUrl,
      }}
    >
      <Switch>
        <Route path={`${path}/new`}>
          <WorkflowCreate />
        </Route>

        <Route path={`${path}/:id(\\d+)`}>
          <Switch>
            <Route path={`${path}/:id/edit`}>
              <WorkflowEdit />
            </Route>

            <Redirect to={`${path}/:id/edit`} />
          </Switch>
        </Route>

        <Route path={path}>
          <WorkflowOverview />
        </Route>

        <Redirect to={path} />
      </Switch>
    </RequireIAM>
  )
}

export default WorkflowRoutes
