import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  min-width: 640px;
  color: ${({ theme }) => theme.colors.textSecondBlue};

  & .MuiFormControlLabel-label {
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
    font-size: 14px;
  }

  & .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
`

export const Section = styled.section<{ hasBorderBottom?: boolean }>`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: ${({ hasBorderBottom, theme }) =>
    hasBorderBottom ? `1px solid ${theme.colors.black6}` : 'none'};
`

export const Icon = styled.span<{
  color?: string
  size?: number
  lineHeight?: number
  clickable?: boolean
}>`
  padding: 0 8px;

  color: ${({ color }) => color || 'inherit'};
  font-size: ${({ size }) => size || 24}px;
  line-height: ${({ lineHeight = 0 }) =>
    lineHeight !== 0 ? lineHeight + 'px' : 'inherit'};

  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`

export const Label = styled.span`
  align-self: flex-start;
  flex-shrink: 0;
  flex-basis: 120px;

  margin-right: 24px;

  font-weight: 500;
  line-height: 42px;
`
