import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import UserCreate from './Create'
import UserDetail from './Detail'
import UserEdit from './Edit'
import UserList from './List'

export const UserRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <UserCreate />
      </Route>

      <Route path={`${path}/:id/edit`}>
        <UserEdit />
      </Route>

      <Route path={`${path}/:id`}>
        <UserDetail />
      </Route>

      <Route path={`${path}`}>
        <UserList />
      </Route>

      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default UserRoutes
