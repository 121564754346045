import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImagePreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 102px;
  height: 102px;
  border: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
  border-radius: 6px;
  background-color: ${({ theme }) => `${theme.colors.white}`};
`

export const ImagePreview = styled.img`
  width: 100%;
`

export const TextPreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding: 15px 4px;
  width: 102px;
  height: 102px;
  border: ${({ theme }) => `1px solid ${theme.colors.borderColor}`};
  border-radius: 6px;
  font-size: 10px;
`

export const TextPreview = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`
