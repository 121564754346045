import Box from '@mui/material/Box'

import AgeGender from './AgeGender'
import City from './City'
import Gender from './Gender'
import MembersNonMembers from './MembersNonMembers'
import MemberStatus from './MemberStatus'
import Retention from './Retention'
import Rfm from './Rfm'

const Charts = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    flexWrap="wrap"
    width="100%"
  >
    <MembersNonMembers />
    <MemberStatus />
    <Gender />
    <AgeGender />
    <City />
    <Rfm />
    <Retention />
  </Box>
)

export default Charts
