import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Overview from './Overview'

export const RfmRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Overview />
      </Route>

      <Redirect to={path} />
    </Switch>
  )
}
