import camelcaseKeys from 'camelcase-keys'

import {
  isErrorWithMessage,
  isFetchBaseQueryError,
} from '@shared/api/rtkQuery/helpers'

import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_STATUS } from '../model/constants'

export const formatRTKQueryError = <
  T extends Record<string, unknown> = Record<string, unknown>
>(
  error: unknown
): {
  statusCode: number
  message: string
  data?: T
} => {
  if (isFetchBaseQueryError(error) && typeof error.status === 'number') {
    return {
      statusCode: error.status,
      message:
        'error' in error ? (error.error as string) : JSON.stringify(error.data),
      data:
        typeof error.data === 'object'
          ? (camelcaseKeys(error.data as T, {
              deep: true,
            }) as T)
          : undefined,
    }
  }

  if (isErrorWithMessage(error)) {
    return {
      statusCode: DEFAULT_ERROR_STATUS,
      message: error.message,
    }
  }

  return {
    statusCode: DEFAULT_ERROR_STATUS,
    message: DEFAULT_ERROR_MESSAGE,
  }
}
