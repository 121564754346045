import TagSourceRouteMapClass from './utils/TagSourceRouteMap'
import type { TagRoutes } from './type'

export const STATUS_CODE = {
  THIRD_TAG_SOURCE_CONFLICT: 409,
  THIRD_PARTY_TAG_DUPLICATE_NAME: 409,
  DUPLICATE_NAME: 409,
  GROUP_NOT_FOUND: 404,
  EXCEEDED_MAXIMUM_LIMIT: 422,
}

export const GRADING_TAG_PATH: TagRoutes = 'grading'
export const THIRD_PARTY_TAG_PATH: TagRoutes = 'third-party'
export const INTELLIGENT_TAG_PATH: TagRoutes = 'intelligent'
export const BEHAVIOR_TAG_PATH: TagRoutes = 'behavior'

export const TagSourceRoutesMap = new TagSourceRouteMapClass([
  ['grading', GRADING_TAG_PATH],
  ['behavior', BEHAVIOR_TAG_PATH],
  ['intelligent', INTELLIGENT_TAG_PATH],
  ['thirdParty', THIRD_PARTY_TAG_PATH],
])
