type Props = {
  classname: string
  children: React.ReactNode
}

const CarouselItem = ({ classname, children }: Props) => {
  return <div className={classname}>{children}</div>
}

export default CarouselItem
