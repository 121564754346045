import { EdgeProps, getBezierPath } from 'reactflow'
import IconButton from '@mui/material/IconButton'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

const BUTTON_SIZE = 32

const EdgeWithDelete = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  data: { onDelete },
}: EdgeProps) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />

      <foreignObject
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        overflow="visible"
        x={labelX - BUTTON_SIZE / 2}
        y={labelY - BUTTON_SIZE / 2}
      >
        <IconButton
          sx={{
            width: BUTTON_SIZE,
            height: BUTTON_SIZE,
            backgroundColor: theme => theme.colors.blackTwo,
            ':hover': {
              backgroundColor: theme => theme.colors.black,
            },
          }}
          onClick={() => onDelete(id)}
        >
          <Icon
            color={theme.colors.white}
            icon={ICON.multiply}
            fontSize="small"
          />
        </IconButton>
      </foreignObject>
    </>
  )
}

export default EdgeWithDelete
