import type { Placement } from '../models'

export const getTransform = (placement: Placement) => {
  let translateX = 0
  let translateY = 0

  switch (placement) {
    case 'right-end':
    case 'top-start':
      translateY = -100
      break
    case 'right':
      translateY = -50
      break
    case 'left-start':
    case 'bottom-end':
      translateX = -100
      break
    case 'left':
      translateX = -100
      translateY = -50
      break
    case 'left-end':
    case 'top-end':
      translateX = -100
      translateY = -100
      break
    case 'top':
      translateX = -50
      translateY = -100
      break
    case 'bottom':
      translateX = -50
      break
    case 'right-start':
    case 'bottom-start':
    default:
      break
  }
  return `translate(${translateX}%, ${translateY}%)`
}

export default getTransform
