import { useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import MuiTablePagination, {
  TablePaginationProps as MuiTablePaginationProps,
} from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'

import { getIntSafe } from '@shared/lib/utils/number'

type TablePaginationProps = {
  isShowPageInput?: boolean
} & MuiTablePaginationProps

const TablePaginationContainer = styled.div`
  position: relative;
  padding-right: 16px;

  & .MuiTablePagination-root {
    margin-top: 6px;
    color: ${({ theme }) => theme.colors.textSecondBlue};
    font-family: inherit;
  }
`

const PageInputContainer = styled.form`
  position: absolute;
  display: flex;
  align-items: center;
  margin: 0 24px;

  height: 100%;

  color: ${({ theme }) => theme.colors.textSecondBlue};
  z-index: 1;
`

const PageInput = styled(TextField)`
  & .MuiInputBase-input {
    padding: 8px;
    color: ${({ theme }) => theme.colors.textSecondBlue};
    text-align: right;
  }

  & .MuiInputBase-root {
    margin-right: 8px;
  }

  & .MuiOutlinedInput-root {
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.black25};
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.brightBlue};
    }
  }

  width: 60px;
`

const TablePagination = ({
  page,
  count,
  rowsPerPageOptions,
  rowsPerPage,
  labelRowsPerPage,
  isShowPageInput = false,
  onPageChange,
  onRowsPerPageChange,
}: TablePaginationProps) => {
  const [targetPage, setTargetPage] = useState(`${page + 1}`)

  useEffect(() => {
    setTargetPage(`${page + 1}`)
  }, [page])

  const maxPage = useMemo(() => {
    // count = 1  -> maxPage = getIntSafe((1 - 1) / 10)  === 0 (共一頁)
    // count = 10 -> maxPage = getIntSafe((10 - 1) / 10) === 0 (共一頁)
    // count = 11 -> maxPage = getIntSafe((11 - 1) / 10) === 1 (共兩頁)
    if (count > 0) {
      return getIntSafe((count - 1) / rowsPerPage)
    }

    return 0
  }, [count, rowsPerPage])

  const handleCurrPageChanged = () => {
    const availablePage = Math.max(
      Math.min(maxPage, getIntSafe(targetPage) - 1),
      0
    )
    setTargetPage(`${availablePage + 1}`)

    if (availablePage !== page) {
      onPageChange(null, availablePage)
    }
  }

  return (
    <TablePaginationContainer>
      {isShowPageInput && (
        <PageInputContainer
          onSubmit={event => {
            event.preventDefault()
            handleCurrPageChanged()
          }}
        >
          <PageInput
            variant="outlined"
            value={targetPage}
            onChange={event => {
              setTargetPage(event.target.value)
            }}
            onBlur={() => {
              handleCurrPageChanged()
            }}
          />
          / {maxPage + 1}
        </PageInputContainer>
      )}
      <MuiTablePagination
        component="div"
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={labelRowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </TablePaginationContainer>
  )
}

export default TablePagination
