import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createSelector } from '@reduxjs/toolkit'

import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  GroupByField,
  Tag,
  TAG_TYPE_INTERNAL_OFFLINE,
  useTagListQuery,
} from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  getCategoriesOfTagUsers,
  getCategoriesOfUserProfileEntity,
  mergeMetadataCategoryById,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import type { Category } from '@shared/ui/Dropdown/CategoryList'

import getEventPropertyLabel from './getEventPropertyLabel'

const INIT_TAG_LIST_DATA: Tag[] = []

/**
 * Custom hook that retrieves the categories of intersection events based on the provided event name list.
 * @param eventNameList - The list of event names.
 * @param groupByFields - The list of group by fields.
 * @param options - Optional configuration for the hook.
 * @param options.isNeedDimensionColumnFromEvent - Flag indicating whether dimension columns from events are needed. Default is false.
 * @returns An array of merged metadata categories.
 */

export const useCategoriesOfIntersectionEvents = (
  eventNameList: string[],
  {
    isNeedDimensionColumnFromEvent = false,
    groupByFields = [],
  }: {
    isNeedDimensionColumnFromEvent?: boolean
    groupByFields?: GroupByField[]
  } = {}
) => {
  const { t } = useTranslation(['audience'])

  const { eventEntity, userProfileEntity, userProfileIntelligence } =
    useMetadataQuery()

  const categoriesOfUserProfile = useMemo(
    () =>
      getCategoriesOfUserProfileEntity(
        {
          id: 'users',
          name: t('audience:v3.user_profile'),
          entity: userProfileEntity,
        },
        { skipDimensionColumn: true, skipRepeated: true }
      ),
    [t, userProfileEntity]
  )

  const categoriesOfPltvUserProfile = useMemo(
    () =>
      getCategoriesOfUserProfileEntity(
        {
          id: 'users_profile_intelligence',
          name: t('audience:v3.user_profile_intelligence'),
          entity: userProfileIntelligence,
        },
        { skipDimensionColumn: true, skipRepeated: true }
      ),
    [t, userProfileIntelligence]
  )

  const selectCategoriesOfTagUsers = useMemo(
    () =>
      createSelector(
        [(result: { data?: Tag[] }) => result.data ?? INIT_TAG_LIST_DATA],
        tagList => ({
          categoriesOfTagUsers: getCategoriesOfTagUsers(
            tagList.filter(({ type }) => type !== TAG_TYPE_INTERNAL_OFFLINE),
            t
          ),
        })
      ),
    [t]
  )

  const { categoriesOfTagUsers } = useTagListQuery(
    {
      usable: true,
      page: 1,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    { selectFromResult: selectCategoriesOfTagUsers }
  )

  const { eventPropertiesCategories, eventDimCategories } = useMemo(() => {
    const eventPropertiesCategories = {
      id: 'events_properties',
      name: t('audience:v3.event_attributes'),
      options: [] as Category['options'],
    }

    const eventDimCategories = {
      id: 'events_dim',
      name: t('audience:v3.dimension_table'),
      options: [] as Category['options'],
    }

    const firstEvent = eventEntity.events.entities[eventNameList[0]]

    if (firstEvent) {
      eventNameList
        .map(event => eventEntity.events.entities[event]?.propertiesIds || []) // 取出所有 eventNameList 下的 propertiesIds
        .reduce((prev, curr) => curr.filter(Set.prototype.has, new Set(prev))) // 取交集
        .forEach(propertyId => {
          const eventPropertyLabel = getEventPropertyLabel(
            eventNameList,
            eventEntity.events,
            propertyId
          )

          const {
            tableName: source,
            id: field,
            category,
            isFromColumnOfDimTable,
          } = firstEvent.propertiesEntity[propertyId]

          const specificDimTableName =
            groupByFields.find(({ source }) => source.startsWith('dimension'))
              ?.source ?? ''

          // foreign key 不能加入維度分析 (isFromColumnOfDimTable 可以用來判斷是不是 foreign key)
          if (isFromColumnOfDimTable) {
            return
          }

          if (category === 'dimension') {
            if (specificDimTableName && specificDimTableName !== source) {
              return
            }

            eventDimCategories.options.push({
              label: eventPropertyLabel,
              value: createMetadataPropertyId(source, field),
            })

            return
          }

          if (category === 'event') {
            eventPropertiesCategories.options.push({
              label: eventPropertyLabel,
              value: createMetadataPropertyId(source, field),
            })
          }
        })
    }

    return { eventPropertiesCategories, eventDimCategories }
  }, [t, eventEntity, eventNameList, groupByFields])

  const categoriesOfIntersectionEvents = useMemo(() => {
    return mergeMetadataCategoryById(
      [eventPropertiesCategories]
        .concat(isNeedDimensionColumnFromEvent ? eventDimCategories : [])
        .concat(categoriesOfUserProfile)
        .concat(categoriesOfTagUsers)
        .concat(categoriesOfPltvUserProfile)
    )
  }, [
    categoriesOfPltvUserProfile,
    categoriesOfTagUsers,
    categoriesOfUserProfile,
    eventDimCategories,
    eventPropertiesCategories,
    isNeedDimensionColumnFromEvent,
  ])

  return categoriesOfIntersectionEvents
}

export default useCategoriesOfIntersectionEvents
