import { PartialDeep } from '@shared/lib/utils/type'
import { getToday } from '@shared/ui/DateRangePicker'

import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  BehaviorTag,
  BehaviorTagPayload,
  BehaviorTagPreview,
  BehaviorTagPreviewPayload,
  DataSourceType,
} from './type'

export const DATA_SOURCE_TYPE_IMPORT: DataSourceType = 'import'
export const DATA_SOURCE_TYPE_AUTO_AIGC_RULE: DataSourceType =
  'auto_aigc_with_event_rule'

export const INIT_BEHAVIOR_TAG_VALUE: BehaviorTagPayload = {
  title: '',
  description: '',
  groupId: 0,
  schedulingType: 'none',
  dataSourceType: DATA_SOURCE_TYPE_IMPORT,
  autoAigcWithEventRule: {
    relatedAigcTargetIds: [],
    relatedAllAigcTarget: false,
    aigcTargetName: 'ga_eec_items', // 固定
    eventName: '',
    timeRangeType: 'absolute_between',
    timeRangeParams: [
      getToday().nowStart.toISOString(),
      getToday().nowEnd.toISOString(),
    ],
  },
}

const behaviorTagApi = api.injectEndpoints({
  endpoints: builder => ({
    getBehaviorTagList: builder.query<
      PaginationResponse<BehaviorTag>,
      PaginationParams
    >({
      query: params => ({
        url: '/behavior_tag',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as PaginationResponse<BehaviorTag>),

      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'BehaviorTag' as const,
                id,
              })),
              { type: 'BehaviorTag', id: 'LIST' },
            ]
          : [{ type: 'BehaviorTag', id: 'LIST' }],
    }),
    getBehaviorTag: builder.query<BehaviorTag, number>({
      query: id => `/behavior_tag/${id}`,
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as BehaviorTag),
      providesTags: (result, error, id) => [{ type: 'BehaviorTag', id }],
    }),
    createBehaviorTag: builder.mutation<BehaviorTag, BehaviorTagPayload>({
      query: payload => ({
        url: '/behavior_tag',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BehaviorTag', id: 'LIST' },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),
    patchBehaviorTag: builder.mutation<
      BehaviorTag,
      PartialDeep<BehaviorTagPayload & { id: number }>
    >({
      query: payload => ({
        url: `/behavior_tag/${payload.id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BehaviorTag', id: arg.id },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),
    deleteBehaviorTag: builder.mutation<void, number>({
      query: id => ({
        url: `/behavior_tag/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'BehaviorTag', id },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),
    getPreviewBehaviorTag: builder.query<
      BehaviorTagPreview,
      BehaviorTagPreviewPayload
    >({
      query: payload => ({
        url: '/behavior_tag/auto_aigc_with_event/preview_rule_user_count',
        method: 'POST',
        body: payload,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as BehaviorTagPreview),
    }),
    postBehaviorTagJob: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/behavior_tag/auto_aigc_with_event/${id}/create_job`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'BehaviorTag', id: arg.id },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetBehaviorTagListQuery,
  useGetBehaviorTagQuery,
  useCreateBehaviorTagMutation,
  usePatchBehaviorTagMutation,
  useDeleteBehaviorTagMutation,
  useLazyGetPreviewBehaviorTagQuery,
  usePostBehaviorTagJobMutation,
} = behaviorTagApi
