import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import { Info, Score } from '@entities/rfm/ui'
import type { RfmUserGroup } from '@shared/api/rtkQuery'

import { segmentStyleConfigMap } from './constants'

type Props = {
  data: RfmUserGroup
}

const RfmCard = ({ data: { userGroupName, rfmInfo } }: Props) => {
  const { t } = useTranslation(['rfm'])
  const [segmentStyleConfig] = useState(() =>
    segmentStyleConfigMap.get(userGroupName)
  )

  if (!segmentStyleConfig) {
    return null
  }

  const { themeColor, displayNameI18n, range } = segmentStyleConfig

  return (
    <Paper
      component="li"
      sx={{ borderRadius: '4px', boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)' }}
    >
      <Box
        sx={{
          color: theme => theme.colors.white,
          backgroundColor: themeColor,
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          borderTopRightRadius: '4px',
          borderTopLeftRadius: '4px',
          alignItems: 'center',
          py: 2,
          px: 4,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, max-content)',
            fontSize: '24px',
            fontWeight: 600,
            gap: 2,
          }}
        >
          <Box>{rfmInfo.rfmDisplayName}</Box>
          <Box>{t(`rfm:${displayNameI18n}`)}</Box>
        </Box>
        <Avatar
          sx={{ width: '54px', height: '54px', justifySelf: 'flex-end' }}
          src={`/images/avatar-${userGroupName}.png`}
        />
      </Box>
      <Box
        sx={{
          py: 2,
          px: 4,
        }}
      >
        <Info
          sx={{
            p: 3,
          }}
          conditionDisplayName={rfmInfo.conditionDisplayName}
          rawEvents={rfmInfo.rawEvents}
          orderEvents={rfmInfo.orderEvents}
          orderColumn={rfmInfo.orderColumn}
        />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 336px)',
            gap: 3,
          }}
        >
          <Score badge="R" range={range.rScore} />
          <Score badge="F" range={range.fScore} />
        </Box>
      </Box>
    </Paper>
  )
}

export default RfmCard
