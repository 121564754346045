import { useTranslation } from 'react-i18next'

import {
  useUpdateEventRuleGroupMutation,
  useUpdateFunnelRuleGroupMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'

import type { AnalyticsType } from '../../_shared/type'
import ChartGroupDialog from './ChartGroupDialog'
type Props = {
  analyticsType: AnalyticsType
  id: number
  name: string
  isOpen: boolean
  onClose: () => void
}

const EditChartGroup = ({
  isOpen,
  onClose,
  analyticsType,
  id,
  name,
}: Props) => {
  const { t } = useTranslation(['dashboard', 'common'])

  const [updateEventRuleGroup, { isLoading: isUpdatingEventRuleGroup }] =
    useUpdateEventRuleGroupMutation()

  const [updateFunnelRuleGroup, { isLoading: isUpdatingFunnelRuleGroup }] =
    useUpdateFunnelRuleGroupMutation()

  const dispatch = useAppDispatch()

  const handleSubmit = async (name: string) => {
    try {
      if (analyticsType === 'funnel') {
        await updateFunnelRuleGroup({ name, id }).unwrap()
      } else {
        await updateEventRuleGroup({ name, id }).unwrap()
      }

      dispatch(
        openToast({
          message: t('common:save_success'),
          status: 'success',
        })
      )
    } catch (e) {
      openToast({
        message: t('common:save_failure'),
        status: 'error',
      })
    }

    onClose()
  }

  return (
    <ChartGroupDialog
      name={name}
      mode="edit"
      isOpen={isOpen}
      isSubmitting={isUpdatingEventRuleGroup || isUpdatingFunnelRuleGroup}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}
export default EditChartGroup
