import {
  ChartDataFromApi,
  FunnelLineChartDataByStep,
  MetadataEventEntity,
} from '@shared/api/rtkQuery'

import { getGroupByValues } from './getGroupByValues'

export const transformPreviewResponseLineChart = (
  res: ChartDataFromApi,
  eventEntity: MetadataEventEntity
) => {
  const categories = res.time_series

  if (res.by_day_statistic === null) {
    const empty: FunnelLineChartDataByStep = {
      all: {
        eventName: 'all',
        categories,
        series: [],
      },
    }

    res.event_step_names.forEach((eventName, idx) => {
      empty[idx] = {
        eventName:
          eventEntity.events.entities[eventName]?.displayName || eventName,
        categories,
        series: [],
      }
    })

    return {
      chart: empty,
    }
  }

  const byDaySeries: FunnelLineChartDataByStep = {
    all: {
      eventName: 'all',
      categories,
      series: [
        {
          name: '',
          data: res.by_day_statistic.all_step_conversion_rates || [0],
          rawData: res.by_day_statistic.funnel_statistics[
            res.by_day_statistic.funnel_statistics.length - 1
          ]?.day_unique_users || [0],
          lostRate: res.by_day_statistic.all_step_conversion_rates.map(
            r => 100 - r
          ) || [0],
          lostNumber: res.by_day_statistic.all_step_conversion_rates.map(
            (_, idx) => {
              const firstStepUU =
                res.by_day_statistic.funnel_statistics[0].day_unique_users[
                  idx
                ] || 0
              const finalStepUU =
                res.by_day_statistic.funnel_statistics[
                  res.by_day_statistic.funnel_statistics.length - 1
                ].day_unique_users[idx] || 0

              return firstStepUU - finalStepUU
            }
          ) || [0],
        },
      ],
    },
  }
  res.by_day_statistic.funnel_statistics.forEach((d, idxStep) => {
    const eventName = res.event_step_names[d.step]
    byDaySeries[d.step] = {
      eventName:
        eventEntity.events.entities[eventName]?.displayName || eventName,
      categories,
      series: [
        {
          name: '',
          data: d.conversion_rates || [0],
          rawData: d.day_unique_users || [0],
          lostRate: d.conversion_rates.map(r => 100 - r),
          lostNumber: d.conversion_rates.map((_, idxDate) => {
            if (idxStep === 0) {
              return 0
            }

            const currStepUU =
              res.by_day_statistic.funnel_statistics[idxStep].day_unique_users[
                idxDate
              ] || 0
            const prevStepUU =
              res.by_day_statistic.funnel_statistics[idxStep - 1]
                .day_unique_users[idxDate] || 0

            return prevStepUU - currStepUU
          }),
        },
      ],
    }
  })

  const byDayGroupSeries: FunnelLineChartDataByStep = {
    all: {
      eventName: 'all',
      categories,
      series: res.by_day_group_statistics.map(d => ({
        name: getGroupByValues(d.group_by_values),
        data: d.all_step_conversion_rates || [0],
        rawData: d.funnel_statistics[d.funnel_statistics.length - 1]
          ?.day_unique_users || [0],
        lostRate: d.all_step_conversion_rates.map(r => 100 - r) || [0],
        lostNumber: d.all_step_conversion_rates.map((_, idxDate) => {
          const firstStepUU =
            d.funnel_statistics[0].day_unique_users[idxDate] || 0
          const finalStepUU =
            d.funnel_statistics[d.funnel_statistics.length - 1]
              .day_unique_users[idxDate] || 0

          return firstStepUU - finalStepUU
        }),
      })),
    },
  }
  res.by_day_group_statistics.forEach(d => {
    const name = getGroupByValues(d.group_by_values)
    d.funnel_statistics.forEach((s, idxStep) => {
      if (!byDayGroupSeries[s.step]) {
        const eventName = res.event_step_names[s.step]
        byDayGroupSeries[s.step] = {
          eventName:
            eventEntity.events.entities[eventName]?.displayName || eventName,
          categories,
          series: [],
        }
      }
      byDayGroupSeries[s.step].series.push({
        name,
        data: s.conversion_rates,
        rawData: s.day_unique_users,
        lostRate: s.conversion_rates.map(r => 100 - r),
        lostNumber: s.conversion_rates.map((_, idxDate) => {
          if (idxStep === 0) {
            return 0
          }

          const currStepUU =
            d.funnel_statistics[idxStep].day_unique_users[idxDate] || 0
          const prevStepUU =
            d.funnel_statistics[idxStep - 1].day_unique_users[idxDate] || 0

          return prevStepUU - currStepUU
        }),
      })
    })
  })

  return {
    chart: res.by_day_group_statistics.length ? byDayGroupSeries : byDaySeries,
  }
}
