import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  DeleteSubscription,
  EditSubscription,
} from '@features/subscription/Modal'
import {
  Subscription,
  useGetSubscriptionListQuery,
  useValidateSubscriptionUrlMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { openToast } from '@shared/model/slices'
import { SearchBar } from '@shared/ui/searchInput'
import { DataTable } from '@shared/ui/table'

import useColumns from './useColumns'

const Overview = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['common', 'contentTemplate'])
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE)
  const [searchName, setSearchName] = useState('')
  const [openActionMenuId, setOpenActionMenuId] = useState<
    Subscription['id'] | undefined
  >(undefined)

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false)
  const [subscriptionData, setSubscriptionData] = useState<
    Subscription | undefined
  >(undefined)

  const {
    data = {
      items: {
        subscriptions: [],
      },
      totalCount: 0,
    },
    isLoading,
  } = useGetSubscriptionListQuery({
    page,
    perPage,
    name: searchName,
  })

  const [validateSubscriptionUrl] = useValidateSubscriptionUrlMutation()

  const validateUrl = async () => {
    safeCloseAllPopup()

    try {
      if (!subscriptionData) {
        return
      }

      const { statusCode, responseMessage } = await validateSubscriptionUrl({
        id: getIntSafe(subscriptionData.id, 0),
        url: subscriptionData.url,
      }).unwrap()

      if (statusCode === 200) {
        dispatch(
          openToast({
            status: 'success',
            message: t(
              'contentTemplate:subscription.dialog.common.validate_endpoint_success'
            ),
          })
        )

        return
      }

      dispatch(
        openToast({
          status: 'error',
          message: t(
            'contentTemplate:subscription.dialog.common.validate_endpoint_fail',
            {
              message: responseMessage,
            }
          ),
        })
      )
    } catch (error) {
      const typeError = formatRTKQueryError(error)

      dispatch(handleApiError(typeError))
    }
  }

  const safeCloseAllPopup = useCallback(() => {
    // 需要照順序關閉彈窗避免頁面跳動
    setOpenActionMenuId(undefined)
    setIsOpenEditModal(false)
    setIsOpenDeleteDialog(false)
    setSubscriptionData(undefined)
  }, [])

  const columns = useColumns({
    openActionMenuId,
    isOpenDeleteDialog,
    isOpenEditModal,
    setOpenActionMenuId,
    setIsOpenDeleteDialog,
    setIsOpenEditModal,
    validateUrl,
  })

  useEffect(() => {
    if (data.items.subscriptions.length === 0) {
      return
    }

    setSubscriptionData(
      data.items.subscriptions.find(item => item.id === openActionMenuId)
    )
  }, [data.items.subscriptions, openActionMenuId])

  return (
    <>
      <Box mb={4} display="flex" justifyContent="flex-end" alignItems="center">
        <SearchBar
          defaultValue={searchName}
          onSearch={value => {
            setSearchName(value)
            setPage(DEFAULT_PAGE)
          }}
          placeholder={t('common:search')}
        />
      </Box>
      <DataTable
        columns={columns}
        rows={data.items.subscriptions}
        count={data.totalCount}
        isLoading={isLoading}
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="id"
        onPageChange={newPage => setPage(newPage + 1)}
        onRowsPerPageChange={setPerPage}
        isRowClickable
        onRowClick={({ id }) => {
          setSubscriptionData(
            data.items.subscriptions.find(item => item.id === id)
          )

          setIsOpenEditModal(true)
        }}
      />
      <DeleteSubscription
        isOpen={isOpenDeleteDialog}
        onClose={safeCloseAllPopup}
        subscription={subscriptionData}
      />
      {subscriptionData && (
        <EditSubscription
          isOpen={isOpenEditModal}
          onClose={safeCloseAllPopup}
          subscription={subscriptionData}
        />
      )}
    </>
  )
}

export default Overview
