import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import theme from '@theme'

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  line-height: 20px;
`

const SetAllRead = styled.p`
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
`

const Icon = styled.i`
  margin-left: 2px;
  font-size: 14px;
`

const NotificationHeader = ({
  archiveAllNotification,
}: {
  archiveAllNotification: () => void
}) => {
  const { t } = useTranslation(['analytics'])

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      borderBottom={`1px solid ${theme.colors.borderColor}`}
      p="16px 20px"
    >
      <Title>{t('analytics:notification.notification')}</Title>
      <SetAllRead
        onClick={e => {
          e.stopPropagation()
          archiveAllNotification()
        }}
      >
        {t('analytics:notification.mark_all_read')}
        <Icon className={ICON.checkCircle} />
      </SetAllRead>
    </Box>
  )
}

export default NotificationHeader
