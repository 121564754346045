import { cloneElement, forwardRef } from 'react'

export const ChildrenWrapper = forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement>
>((props, ref) => {
  return cloneElement(props.children as React.ReactElement<any>, {
    ref,
  })
})

export default ChildrenWrapper
