import curry from 'lodash/fp/curry'

import type { EventRuleNode } from '@shared/api/rtkQuery'
import { createMetadataPropertyId } from '@shared/lib/utils/metadata'

import type { EventRuleFilterErrors } from '../types'

export const getEventRuleFilterErrors = curry(
  (
    intersectionEventAttributeList: string[],
    filter: EventRuleNode
  ): EventRuleFilterErrors => {
    const errors: EventRuleFilterErrors = {}

    for (let item of filter.statisticMeasures) {
      if (item.eventName === '') {
        errors.statisticMeasures = 'EMPTY_EVENT_NAME'

        break
      }

      for (let attributeFilter of item.attributeFilter.nodes) {
        if (attributeFilter.params?.length === 0) {
          errors.statisticMeasures = 'EMPTY_ATTRIBUTE_PARAMS'
        }
      }
    }

    if (
      filter.groupByFields.length > 0 &&
      filter.groupByFields.some(
        ({ source, field }) =>
          !intersectionEventAttributeList.includes(
            createMetadataPropertyId(source, field)
          )
      )
    ) {
      errors.groupByFields = 'OUT_OF_RANGE'
    }

    if (filter.timeRangeParams.length === 0) {
      errors.timeRangeParams = 'EMPTY'
    }

    return errors
  }
)
