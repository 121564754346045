import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetMaacTemplateListQuery,
} from '@shared/api/rtkQuery'
import { DataTable } from '@shared/ui/table'

import { ROWS_PER_PAGE_OPTIONS } from './_shared/constants'
import { Radio } from './_shared/styles'
import { INIT_PAGE, LIST_PER_PAGE } from '../../_shared/constants'
import type { ActionSendLineNodeData } from '../actionSendLineNodeSchema'

type Props = {
  maacTemplateConfig: ActionSendLineNodeData['maacConfig']
  integrationId: number
  isEditable: boolean
  setTemplateConfig: (config: ActionSendLineNodeData['maacConfig']) => void
}

export const MAAC = memo(
  ({
    maacTemplateConfig,
    integrationId,
    isEditable,
    setTemplateConfig,
  }: Props) => {
    const { t } = useTranslation(['workflow'])

    const [page, setPage] = useState<number>(INIT_PAGE)
    const [perPage, setPerPage] = useState<number>(LIST_PER_PAGE)

    const {
      data: maacTemplateListData = INITIAL_PAGINATION_RESPONSE,
      isFetching: isFetchingTemplateList,
      isError: isErrorTemplateList,
    } = useGetMaacTemplateListQuery({
      page,
      perPage,
      integrationId,
    })

    const dataTableRows = useMemo(
      () =>
        maacTemplateListData.items.map(x => ({
          ...x,
          isDisabled: x.parameters.length > 0 || !isEditable,
        })),
      [isEditable, maacTemplateListData.items]
    )

    useEffect(() => {
      if (
        dataTableRows.length <= 0 ||
        maacTemplateConfig ||
        isFetchingTemplateList ||
        isErrorTemplateList
      ) {
        return
      }

      // 第一個沒被 disabled 的項目
      const firstNotDisabledItem = dataTableRows.find(x => !x.isDisabled)

      setTemplateConfig({ templateId: firstNotDisabledItem?.id ?? 0 })
    }, [
      dataTableRows,
      isErrorTemplateList,
      isFetchingTemplateList,
      maacTemplateConfig,
      setTemplateConfig,
    ])

    const handleRowsPerPageChange = (rowsPerPage: number) => {
      setPage(1)
      setPerPage(rowsPerPage)
    }

    if (isErrorTemplateList) {
      return null
    }

    return (
      <>
        <Box mb={2} fontWeight="medium">
          {t('workflow:node_action_line_template')}
        </Box>

        <Box mb={2}>
          <DataTable
            columns={[
              {
                field: 'id',
                headerName: '',
                colRatio: 0.5,
                valueFormatter: (_, { id, isDisabled }) => (
                  <Radio
                    color="primary"
                    checked={maacTemplateConfig?.templateId === id}
                    disabled={isDisabled}
                  />
                ),
              },
              {
                field: 'id',
                headerName: 'content_template_id',
                colRatio: 0.5,
              },
              { field: 'name', headerName: 'content_template_name' },
              {
                field: 'parameters',
                headerName: '',
                valueFormatter: parameters => {
                  if (Array.isArray(parameters)) {
                    return parameters.length > 0
                      ? t('workflow:node_action_line_template_not_supported')
                      : ''
                  }

                  return ''
                },
              },
            ]}
            containerType="border"
            count={maacTemplateListData.totalCount}
            isLoading={isFetchingTemplateList}
            isRowClickable
            page={page - 1}
            rows={dataTableRows}
            rowsPerPage={perPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={newPage => setPage(newPage + 1)}
            onRowsPerPageChange={handleRowsPerPageChange}
            onRowClick={({ id }) => setTemplateConfig({ templateId: id })}
          />
        </Box>
      </>
    )
  }
)

export default MAAC
