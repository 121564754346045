import Box from '@mui/material/Box'

const scores = [1, 2, 3, 4, 5]

type Props = {
  badge: 'R' | 'F' | 'M'
  range: number[]
}

export const Score = ({ badge, range }: Props) => (
  <Box flex={1} display="flex" mb={1}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mr={2}
      width={40}
      height={40}
      borderRadius="10px"
      fontSize={24}
      fontWeight={500}
      sx={{
        color: theme => theme.colors.lightNavyBlue,
        border: theme => `solid 1px ${theme.colors.lightNavyBlue}`,
      }}
    >
      {badge}
    </Box>
    <Box flex={1} display="flex" flexDirection="column">
      <Box
        mb={0.5}
        fontSize={11}
        fontWeight={600}
        sx={{
          color: theme => theme.colors.lightNavyBlue,
        }}
      >
        score
      </Box>
      <Box display="flex" mb={0.5}>
        {scores.map(bar => (
          <Box
            key={bar}
            flex={1}
            height={6}
            borderRadius="12px"
            sx={{
              backgroundColor: theme => theme.colors.bgPaleGrey,
              backgroundImage: theme =>
                range.includes(bar)
                  ? `linear-gradient(315deg, ${theme.colors.deepSkyBlue} 0%, ${theme.colors.electricBlueTwo} 100%)`
                  : `linear-gradient(0deg, ${theme.colors.bgPaleGrey} 0%, ${theme.colors.bgPaleGrey} 100%)`,
            }}
          />
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {scores.map(char => (
          <Box
            mb={0.5}
            fontSize={11}
            fontWeight={600}
            sx={{
              color: theme => theme.colors.lightNavyBlue,
            }}
            key={char}
          >
            {char}
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
)

export default Score
