import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import LoadingContainer from '@entities/Loader/LoadingContainer'
import { Info } from '@entities/rfm/ui'
import {
  INIT_RFM_DATA,
  RfmGroup,
  useGetRfmQuery,
  useRfmListQuery,
} from '@shared/api/rtkQuery'
import instructionImageUrl from '@shared/assets/images/dashboard/trialDashboard.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { UniIcon as Icon } from '@shared/ui/icons'
import SectionTitle from '@shared/ui/SectionTitle'
import theme from '@theme'

import DistributionTable from './DistributionTable'
import RfmDonutChart from './RfmDonutChart'
import SegmentCardsSection from './SegmentCardsSection'

const Overview = () => {
  const { rfmOptions, isRfmOptionsSuccess } = useRfmListQuery(undefined, {
    selectFromResult: ({ data = [], isSuccess }) => ({
      rfmOptions: data.map(ele => {
        return {
          value: ele.id,
          label: ele.rfmDisplayName,
        }
      }),
      isRfmOptionsSuccess: isSuccess,
    }),
  })

  const [rfmId, setRfmId] = useState<number>(0)

  const { rfmData, isRfmDataSuccess } = useGetRfmQuery(rfmId, {
    skip: !rfmId,
    selectFromResult: ({ data = INIT_RFM_DATA, isSuccess }) => {
      if (!isSuccess) {
        return {
          rfmData: {
            ...data,
            rfmGroups: [
              { name: 'champions', userCount: 0 },
              { name: 'promising', userCount: 0 },
              { name: 'new', userCount: 0 },
              { name: 'cannot_lose', userCount: 0 },
              { name: 'at_risk', userCount: 0 },
              { name: 'lost', userCount: 0 },
            ] as RfmGroup[],
          },
          isRfmDataSuccess: false,
        }
      }

      return {
        rfmData: {
          ...data,
          rfmGroups: [...data.rfmGroups].sort(
            (a, b) => b.userCount - a.userCount
          ),
        },
        isRfmDataSuccess: true,
      }
    },
  })

  useEffect(() => {
    if (!rfmId && rfmOptions.length > 0) {
      setRfmId(rfmOptions[0].value)
    }
  }, [rfmId, rfmOptions])

  const planType = useAppSelector(state => state.auth.plan.planType)

  const { t } = useTranslation(['rfm', 'common'])

  return (
    <>
      {isRfmOptionsSuccess && rfmOptions.length === 0 && (
        <FeatureInstruction
          title={t('rfm:instruction.title')}
          description={t('rfm:instruction.description')}
          notice={
            planType === 'standard'
              ? t('rfm:instruction.notice_standard')
              : t('rfm:instruction.notice_trial')
          }
          imageUrl={instructionImageUrl}
        />
      )}

      <LoadingContainer isLoading={!isRfmDataSuccess}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Breadcrumbs sx={{ margin: 0 }}>
              <Box>{t('common:route.rfm')}</Box>
            </Breadcrumbs>

            <Box m="0 10px" fontSize="18px">
              <Icon
                id="icon-filter"
                icon={ICON.filter}
                color={theme.colors.brightBlue}
              />
            </Box>

            <DropdownList
              bgColor={theme.colors.white}
              hasShadow
              options={rfmOptions}
              value={rfmId}
              onValueChanged={option => {
                setRfmId(getIntSafe(option.value))
              }}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Box mr="10px">
              <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
            </Box>

            <Card
              style={{
                padding: '8px 18px',
                height: '36px',
                borderRadius: '4px',
              }}
            >{`${rfmData.days} ${t('common:days')}`}</Card>
          </Box>
        </Box>

        <Info
          sx={{
            backgroundColor: theme => theme.colors.bgIceBlue,
            minHeight: '88px',
            width: '100%',
            padding: '24px 32px',
            paddingBottom: 2,
            borderRadius: '6px',
            my: 4,
          }}
          conditionDisplayName={rfmData.conditionDisplayName}
          rawEvents={rfmData.rawEvents}
          orderEvents={rfmData.orderEvents}
          orderColumn={rfmData.orderColumn}
        />

        <SectionTitle title={t('rfm:rfm_group')} />

        <SegmentCardsSection rfmGroups={rfmData.rfmGroups} />

        <SectionTitle title={t('rfm:population_bar_chart_title')} />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
        >
          <Box flex={2}>
            <DistributionTable
              rfmGroups={rfmData.rfmGroups}
              source={rfmData.source}
            />
          </Box>

          <Card
            style={{
              flex: '1',
              marginLeft: '32px',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <RfmDonutChart rfmGroups={rfmData.rfmGroups} />
          </Card>
        </Box>
      </LoadingContainer>
    </>
  )
}

export default Overview
