import styled from '@emotion/styled'

export const AudiencePreviewerField = styled.div`
  display: flex;
  justify-content: space-between;

  margin: 16px 0;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`

export const Origin = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  transition: 0.25s;
`

export const PercentageWrapper = styled.div`
  position: relative;

  color: ${({ theme }) => theme.colors.dark};
  font-size: 44px;
  font-family: inherit;
  font-weight: 500;
  letter-spacing: -2px;
`

export const Percentage = styled.div`
  display: flex;
  justify-content: center;
`

export const PercentageIcon = styled.div`
  position: absolute;
  top: 8px;
  right: -16px;

  font-size: 20px;
`

export const PercentageWording = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -10px;

  color: ${({ theme }) => theme.colors.textSecondBlue};
  font-size: 16px;
`
