import { forwardRef, Fragment, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import MuiButton, { ButtonProps } from '@mui/material/Button'

import { formatDateDisplay } from '@shared/lib/utils/time'
import { LAYOUT } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { ThemeColor } from '@theme'

const Container = styled.div``

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
`

const Button = styled(MuiButton)`
  && {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blackTwo};
    :hover {
      background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
      color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

const HorizontalLine = styled.div`
  margin-bottom: 4px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightBlueGrey};
`

// copy from DatetimeDurationRule
export const DateInput = styled(
  forwardRef<
    HTMLButtonElement,
    ButtonProps & {
      marginRightRatio?: number
      disabled?: boolean
      isError?: boolean
      bgColor?: ThemeColor
    }
  >(({ marginRightRatio, disabled, isError, bgColor, ...otherProps }, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton {...otherProps} ref={ref} />
  ))
)`
  && {
    margin-right: ${({ marginRightRatio }) => (marginRightRatio || 0) * 12}px;
    padding: 0 16px;
    min-width: 80px;
    height: ${LAYOUT.audienceBuilder.heightOfRow}px;

    border: 1px solid transparent;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.orangeyRed : 'transparent'};
    border-radius: 3px;
    background-color: ${({ disabled, theme, bgColor }) =>
      disabled
        ? theme.colors.veryLightBlueTwo
        : bgColor || theme.colors.bgPrimaryGrey};

    color: ${({ theme }) => theme.colors.lightNavyBlue};
    font-size: 13px;
    font-family: inherit;
    font-weight: 500;

    text-transform: none;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    :hover {
      background-color: ${({ theme }) => theme.colors.lightGreyBlueTwo};
    }
  }
`

type Props = {
  bgColor?: ThemeColor
  isDisabled?: boolean
  isError?: boolean
  isShowUnsetButton?: boolean
  maxDate?: Date
  minDate?: Date
  onValueChanged: (value?: Date) => void
  popperDisablePortal?: boolean
  value?: Date
}

const DatePicker = ({
  bgColor,
  isDisabled,
  isError,
  isShowUnsetButton = true,
  maxDate,
  minDate,
  onValueChanged,
  popperDisablePortal,
  value,
}: Props) => {
  const { i18n, t } = useTranslation('common')
  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  return (
    <Fragment>
      <Dropdown
        anchorElem={
          <DateInput
            bgColor={bgColor}
            onClick={() => {
              if (isDisabled) {
                return
              }
              setIsOpen(prevIsOpen => !prevIsOpen)
            }}
            ref={anchorRef}
            isError={isError}
            disabled={isDisabled}
          >
            {value ? formatDateDisplay(value) : t('date_not_set')}
          </DateInput>
        }
        isOpen={isOpen}
        isDisabled={isDisabled}
        setIsOpen={setIsOpen}
        popperDisablePortal={popperDisablePortal}
      >
        <Container>
          <Calendar
            locale={i18n.language}
            maxDate={maxDate}
            minDate={minDate}
            value={value || null}
            onChange={date => {
              onValueChanged(date as Date)
              setIsOpen(false)
            }}
          />
          {isShowUnsetButton && (
            <ButtonWrapper>
              <HorizontalLine />
              <Button
                onClick={() => {
                  onValueChanged(undefined)
                  setIsOpen(false)
                }}
              >
                {t('date_not_set')}
              </Button>
            </ButtonWrapper>
          )}
        </Container>
      </Dropdown>
    </Fragment>
  )
}

export default DatePicker
