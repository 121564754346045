import cloneDeep from 'lodash/cloneDeep'

import { EventRuleNode } from '@shared/api/rtkQuery'
import { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType } from '@shared/ui/DateRangePicker'

export const getFilterCompared = (
  filter: EventRuleNode,
  timeRangeParams: NumberOrString[]
): EventRuleNode => ({
  ...cloneDeep(filter),
  timeRangeParams,
  timeRangeType: getRangeType(timeRangeParams),
})
