import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import debounce from 'lodash/debounce'

import { useTagUserSummariesQuery } from '@shared/api/rtkQuery'
import { dateRange } from '@shared/lib/utils/time'
import {
  formatDateDisplay,
  formatDateTimeDisplay,
} from '@shared/lib/utils/time'
import {
  ChartData,
  ChartDataSeries,
  COLORS,
  getRatioIndex,
} from '@shared/ui/charts'
import DotLoader from '@shared/ui/loaders/DotLoader'
import RangeSlider, { MAX_RANGE_SLIDER_VALUE } from '@shared/ui/RangeSlider'
import theme from '@theme'

import { CONTROL_SECTION_WIDTH, LevelListElement } from './constants'
import StackedColumnChart from './StackedColumnChart'
import VisibilityList from './VisibilityList'

const ControlSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  margin-top: 29px;
  margin-left: 20px;
  padding-top: 4px;
  width: ${CONTROL_SECTION_WIDTH}px;
  max-height: 300px;
  border-top: 2px solid ${theme.colors.black6};

  /* 為了讓 Sidebar 開闔時 Chart 的中間變化狀態不會看起來像破版 */
  background-color: ${({ theme }) => theme.colors.white};
  /* 高於 ChartSection 即可 */
  z-index: 2;
`

const ChartInfoTitle = styled.span`
  margin-right: 20px;
  margin-bottom: 16px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
`

const FlexWrapper = styled.div`
  display: flex;
  width: 100%;
`

const Loading = styled(DotLoader)<{ isShow: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: ${({ isShow }) => (isShow ? 'initial' : 'none')};
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  z-index: 1;
  transition: opacity 0.5s;
`

const ChartSection = styled.div`
  width: calc(100% - ${CONTROL_SECTION_WIDTH}px);
  min-height: 365px;
`

const LoadingWrapper = styled.div<{ isDisabled: boolean }>`
  position: relative;
  width: 100%;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  transition: opacity 0.5s;
`

const ChartStatus = styled.span`
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.chartRed};
  font-size: 18px;
  font-weight: 700;
`

const ChartInfoTime = styled.span`
  font-size: 18px;
  font-weight: 700;
`

const initialStackedColumnChartData = [
  {
    categories: [],
    series: [],
  },
]

type DataArray = ChartData<number | null>[]

type UserData = { value: string; count: number }

type Props = {
  tagId: number
  timeRangeParams: string[]
  finishedTime: string
  status: string
}

const StackedColumnChartArea = ({
  timeRangeParams,
  tagId,
  status,
  finishedTime,
}: Props) => {
  const [levelList, setLevelList] = useState<LevelListElement[]>([])
  const [completeData, setCompleteData] = useState<DataArray>()
  const [displayRangeData, setDisplayRangeData] = useState<DataArray>()
  const [rangeValue, setRangeValue] = useState<number[]>([0, 100])

  const { t } = useTranslation(['table', 'tag'])

  const { data, isFetching } = useTagUserSummariesQuery(
    {
      id: tagId,
      startDate: timeRangeParams[0],
      endDate: timeRangeParams[1],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !tagId || !timeRangeParams,
    }
  )

  const debounceUpdateDisplayRangeData = useMemo(
    () =>
      debounce(
        ({
          startValue,
          endValue,
        }: {
          startValue: number
          endValue: number
        }) => {
          if (!!completeData) {
            // 根據 len 決定 rangeValue 與 categories idx 的比例尺
            const categoriesArrLen = completeData[0].categories.length || 0
            // 根據 rangeValue 取出擷取過後的 startIdx & endIdx
            const startIdx = getRatioIndex(
              startValue / MAX_RANGE_SLIDER_VALUE,
              categoriesArrLen
            )
            const endIdx = getRatioIndex(
              endValue / MAX_RANGE_SLIDER_VALUE,
              categoriesArrLen
            )

            // 過濾 categoris & series
            const newDisplayData = completeData.map(d => {
              const newCategories = d.categories.slice(startIdx, endIdx + 1)
              const newSeries = d.series.map(s => {
                // 這裡可能要先防呆，如果 data engineer 還沒有把 data.length 對齊 categories
                const newData = s.data.slice(startIdx, endIdx + 1)
                return {
                  name: s.name,
                  data: newData,
                }
              })

              return {
                categories: newCategories,
                series: newSeries,
              }
            })

            setDisplayRangeData(newDisplayData)
          }
        },
        300
      ),
    [completeData]
  )

  const handleDisplayDataRangeChanged = (
    event: Event,
    newRangeValue: number | number[]
  ) => {
    const [startValue, endValue] = newRangeValue as number[]
    debounceUpdateDisplayRangeData({ startValue, endValue })
    setRangeValue([startValue, endValue])
  }

  useEffect(() => {
    if (!!data && !!data.length) {
      const allDate = dateRange(timeRangeParams[0], timeRangeParams[1])
      let cursor = 0
      const categories: string[] = []
      const allLevelObject: { [key: string]: (number | null)[] } = {}
      data.forEach(ele => {
        ele.values.forEach(val => {
          allLevelObject[val.value] = []
        })
      })
      const allLevelArray: string[] = Object.keys(allLevelObject)

      const setDataIntoLevel = (target: UserData[]) => {
        let levelDataLength = 0
        target.forEach((e: UserData) => {
          allLevelObject[e.value].push(e.count)
          levelDataLength = allLevelObject[e.value].length
        })
        Object.keys(allLevelObject).forEach(key => {
          if (allLevelObject[key].length < levelDataLength) {
            allLevelObject[key].push(null)
          }
        })
      }

      allDate.forEach(date => {
        // 不得大於當天
        if (new Date(date) <= new Date(formatDateDisplay(new Date()))) {
          if (data[cursor].date === date) {
            categories.push(date)
            setDataIntoLevel(data[cursor].values)
          } else if (cursor === data.length - 1) {
            if (new Date(date) > new Date(data[cursor].date)) {
              categories.push(date)
              setDataIntoLevel(data[cursor].values)
            }
          } else if (data[cursor + 1].date === date) {
            cursor++
            categories.push(date)
            setDataIntoLevel(data[cursor].values)
          } else if (
            new Date(data[cursor].date) < new Date(date) &&
            new Date(data[cursor + 1].date) > new Date(date)
          ) {
            categories.push(date)
            setDataIntoLevel(data[cursor].values)
          }
        }
      })

      const series: { name: string; data: (number | null)[] }[] = []
      Object.entries(allLevelObject).forEach(([key, value]) => {
        series.push({ name: key, data: value })
      })
      setCompleteData([{ categories, series }])
      setRangeValue([0, 100])
      setDisplayRangeData([{ categories, series }])
      setLevelList(
        allLevelArray.map(level => {
          return {
            name: level,
            visible: true,
          }
        })
      )
    } else if (!!data && data.length === 0) {
      setDisplayRangeData([])
      setCompleteData([])
      setLevelList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const colors = COLORS.map((color, idx) =>
    levelList[idx] ? color : undefined
  ).filter(Boolean) as string[]

  const filterVisibility = (targetData: ChartData<number | null>[]) => {
    if (!!targetData?.length) {
      const bufferArray: ChartDataSeries<number | null>[] = []
      targetData[0].series.forEach(ele => {
        levelList.forEach(level => {
          if (level.name === ele.name) {
            if (level.visible) {
              bufferArray.push({ ...ele })
            } else {
              const temp = ele.data.map(x => null)
              bufferArray.push({ ...ele, data: temp })
            }
          }
        })
      })
      return [
        {
          categories: [...targetData[0].categories],
          series: bufferArray,
        },
      ]
    } else {
      return initialStackedColumnChartData
    }
  }

  return (
    <LoadingWrapper isDisabled={isFetching}>
      <FlexWrapper>
        <ChartSection>
          {!!completeData && !!displayRangeData && (
            <>
              <div>
                <ChartInfoTitle>{t('table:latest_tag_version')}</ChartInfoTitle>
                <LastRenderTime status={status} finishedTime={finishedTime} />
              </div>
              <StackedColumnChart
                key={'normal'}
                chartTitle={t('tag:trends')}
                data={filterVisibility(displayRangeData)}
                colors={colors}
                mode={'normal'}
              />

              {!!completeData?.[0]?.categories &&
                completeData[0].categories.length > 1 && (
                  <RangeSlider
                    data={completeData}
                    rangeValue={rangeValue}
                    handleDisplayDataRangeChanged={
                      handleDisplayDataRangeChanged
                    }
                  />
                )}
            </>
          )}
        </ChartSection>

        <ControlSection>
          {!!levelList.length && (
            <VisibilityList levelList={levelList} setLevelList={setLevelList} />
          )}
        </ControlSection>

        <Loading isShow={isFetching} />
      </FlexWrapper>
    </LoadingWrapper>
  )
}

const LastRenderTime = ({
  status,
  finishedTime,
}: {
  status: string
  finishedTime: string
}) => {
  if (!!finishedTime) {
    const [date, time] = formatDateTimeDisplay(finishedTime).split(' ')
    return (
      <>
        <ChartInfoTime>{`${date} ${time}`}</ChartInfoTime>
      </>
    )
  }
  return <ChartStatus>{status}</ChartStatus>
}

export default StackedColumnChartArea
