import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { COLORS } from '@shared/ui/charts'
import theme from '@theme'
import { getChartTooltipRow } from '@widgets/audience/lib/chartUtils'
import { CHART_HEIGHT } from '@widgets/audience/model/constant'
import ChartWrapper from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/ChartWrapper'

type Props = {
  series: number[]
  categories: string[]
  total: number
}

const ColumnChart = ({ series, categories, total }: Props) => {
  const { t } = useTranslation(['audience'])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: [
      // series 移除最後一筆 no_data 資料，填上COLORS[0]。最後一筆資料是無資料的顏色
      ...Array(series.slice(0, -1).length).fill(COLORS[0]),
      theme.colors.blueyGrey,
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textPrimaryBlue,
          fontSize: '14px',
          fontWeight: 600,
        },
        formatter: value =>
          t('audience:unit.people', {
            number: (+value).toLocaleString(),
          }),
      },
    },
    tooltip: {
      custom: ({ dataPointIndex, w }) => {
        const {
          config: { series, colors },
        } = w

        const value = t('audience:unit.people', {
          number: (+series[0].data[dataPointIndex]).toLocaleString(),
        })

        const color = colors[dataPointIndex]
        const name = series[0].name[dataPointIndex]
        const ration = total ? series[0].data[dataPointIndex] / total : 0

        const result: string[] = []

        result.push(
          getChartTooltipRow({
            label: `${t('audience:retention_rate')} ${name}`,
            color,
            value,
            ration,
          })
        )

        return `<div class="audience-tooltip-wrapper"><div class="audience-tooltip-column">${result.join(
          ''
        )}</div></div>`
      },
    },
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      <ChartWrapper>
        <ReactApexChart
          type="bar"
          height={CHART_HEIGHT}
          options={options}
          series={[
            {
              data: series,
              name: categories,
            },
          ]}
        />
      </ChartWrapper>
    </Box>
  )
}

export default ColumnChart
