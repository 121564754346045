import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { ThirdPartyAppProvider } from '@shared/api/rtkQuery'
import { THIRD_PARTY_APP_PROVIDER_DISPLAY_NAME_MAP } from '@shared/model/constants/thirdParty'

export const useThirdPartyProviderName = () => {
  const { t } = useTranslation(['settings'])

  const getThirdPartyProviderName = useCallback(
    (provider: ThirdPartyAppProvider): string => {
      if (provider === 'every8d_exclusive') {
        return t('settings:every8d_customized_site')
      }

      if (provider === 'mitake') {
        return t('settings:mitake')
      }

      if (provider === 'newsleopard') {
        return t('settings:newsleopard')
      }

      return THIRD_PARTY_APP_PROVIDER_DISPLAY_NAME_MAP[provider]
    },
    [t]
  )

  return { getThirdPartyProviderName }
}

export default useThirdPartyProviderName
