import Box from '@mui/material/Box'

import IconWrapper from './IconWrapper'

// svg 沒有要改顏色就放 /public/images 動態載入
export const CompleteIcon = ({
  marginLeft,
  id = '',
}: {
  marginLeft?: number
  id?: string
}) => (
  <IconWrapper id={id} marginLeft={marginLeft}>
    <Box
      component="img"
      sx={{ width: '100%' }}
      src="/images/icon-complete.svg"
    />
  </IconWrapper>
)

export default CompleteIcon
