import type { ParseKeys } from 'i18next'

import type {
  ResourceType,
  ResourceTypeAction,
} from '@shared/model/types/ingestion'

export const resourceTypeActionOptionMap: {
  [name in ResourceType]: {
    resourceTypeAction: ResourceTypeAction
    i18nKey: ParseKeys<['dataImport']>
  }[]
} = {
  user_profile: [
    {
      i18nKey: 'dataImport:resource_type_action.user_profile.upsert',
      resourceTypeAction: 'upsert',
    },
    {
      i18nKey: 'dataImport:resource_type_action.user_profile.delete',
      resourceTypeAction: 'delete',
    },
  ],
  event: [
    {
      i18nKey: 'dataImport:resource_type_action.event.insert',
      resourceTypeAction: 'insert',
    },
  ],
  dimension: [
    {
      i18nKey: 'dataImport:resource_type_action.dimension.insert',
      resourceTypeAction: 'insert',
    },
  ],
}

// 限制上傳檔案大小 2GB
export const MAX_FILE_SIZE = 2e9

export type TemplateFile = { filename: string }

export const resourceTypeActionTemplateMap: {
  [name in `${ResourceType}_${ResourceTypeAction}`]?: TemplateFile | null
} = {
  user_profile_upsert: {
    filename: 'user_profile_upsert_template.ndjson',
  },
  user_profile_delete: {
    filename: 'user_profile_delete_template.ndjson',
  },

  event_insert: {
    filename: 'user_event_insert_template.ndjson',
  },
}

export const SUPPORTED_FILE_EXTENSION = '.ndjson'
export const DESCRIPTION_MAX_LENGTH = 300
