import ReactApexChart from 'react-apexcharts'
import Box from '@mui/material/Box'
import i18n from 'i18next'

import { COLORS } from '@shared/ui/charts'
import theme from '@theme'
import { getChartTooltipRow } from '@widgets/audience/lib/chartUtils'
import { CHART_HEIGHT } from '@widgets/audience/model/constant'
import ChartWrapper from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/ChartWrapper'

type Props = {
  series: Array<{
    name: string
    data: number[]
  }>
  categories: string[]
  total: number
}

const StackedColumnChart = ({ series, categories, total }: Props) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: COLORS,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
      },
    },
    legend: {
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        radius: 12,
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textPrimaryBlue,
          fontSize: '14px',
          fontWeight: 600,
        },
        formatter: value =>
          i18n.t('audience:unit.people', {
            number: value.toLocaleString(),
          }),
      },
    },
    tooltip: {
      custom: ({
        dataPointIndex,
        w: {
          config: { series, colors },
        },
      }: {
        dataPointIndex: number
        w: {
          config: {
            series: { name: string; data: { [x: string]: number } }[]
            colors: string[]
          }
        }
      }) => {
        const result: string[] = []

        series.forEach((s, idx: number) => {
          const count = s.data[dataPointIndex]
          const color = colors[idx]

          result.push(
            getChartTooltipRow({
              label: s.name,
              color,
              value: i18n.t('audience:unit.people', {
                number: count.toLocaleString(),
              }),
              ration: count / total,
            })
          )
        })

        return `<div class="audience-tooltip-wrapper">
                  <div class="audience-tooltip-column">${result.join('')}</div>
                </div>`
      },
    },
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
    >
      <ChartWrapper>
        <ReactApexChart
          type="bar"
          height={CHART_HEIGHT}
          options={options}
          series={series}
        />
      </ChartWrapper>
    </Box>
  )
}

export default StackedColumnChart
