import { useTranslation } from 'react-i18next'
import { TableCellProps } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import type { ParseKeys } from 'i18next'

import { TableRow } from '@shared/ui/table'
import { TableDetailHeadCell } from '@widgets/usageManagement/SendingAmount/ui/_shared/style'

export type TableColumn<
  T extends {},
  U extends keyof T = keyof T
> = U extends keyof T
  ? {
      headerName: ParseKeys<'table'> | ''
      field: U
      type?: 'number' | 'string'
      valueFormatter?: (value: T[U], row: T) => React.ReactNode
      colRatio?: number
      align?: TableCellProps['align']
    }
  : never

export const TableDetailHead = <TField extends Record<string, unknown>>({
  columns,
}: {
  columns: TableColumn<TField>[]
}) => {
  const { t } = useTranslation('table')

  const totalColRatio = columns.reduce(
    (prev, curr) => prev + (curr.colRatio || 1),
    0
  )
  const getWidthPercent = (colRatio: number) =>
    ((colRatio / totalColRatio) * 100).toFixed(2)

  return (
    <TableHead>
      <TableRow>
        {columns.map(
          ({ field, headerName, type, align, colRatio = 1 }, idx) => {
            const isFirstRowCell = idx === 0
            const isLastRowCell = idx === columns.length - 1
            const cellAlign = align || (type === 'number' ? 'right' : 'left')

            return (
              <TableDetailHeadCell
                key={`${field.toString()}_${idx}`}
                isFirstRowCell={isFirstRowCell}
                isLastRowCell={isLastRowCell}
                width={`${getWidthPercent(colRatio)}%`}
                align={cellAlign}
                sx={{ padding: '4px' }}
              >
                {t(headerName as ParseKeys<'table'>)}
              </TableDetailHeadCell>
            )
          }
        )}
      </TableRow>
    </TableHead>
  )
}

export default TableDetailHead
