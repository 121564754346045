export const NODE_WIDTH = 240
export const NODE_HEADER_HEIGHT = 60
export const NODE_CONTENT_MIN_HEIGHT = 88
export const ACTION_BUTTON_MIN_WIDTH = 60

export const INIT_PAGE = 1
export const LIST_PER_PAGE = 10
export const CAMPAIGN_NAME_MAX_LENGTH = 100

export const REACT_FLOW_NO_DRAG = 'nodrag'

export const HANDLE_SOURCE_MARGIN_X = 32
