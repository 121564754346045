import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import AudienceFilter from '@features/filters/AudienceFilter'
import {
  AudienceRuleUser,
  INITIAL_PAGINATION_RESPONSE,
  useGetAudienceRuleQuery,
  useGetAudienceRuleUserListQuery,
} from '@shared/api/rtkQuery'
import useThirdPartyGroupProviderListMap from '@shared/lib/hooks/useThirdPartyGroupProviderListMap'
import {
  type AudienceListExported,
  trackEvent,
} from '@shared/lib/utils/amplitude'
import { getIntSafe } from '@shared/lib/utils/number'
import {
  checkIsOver24Hours,
  formatDateTimeDisplay,
} from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST_AUDIENCE } from '@shared/model/constants/thirdParty'
import Accordion from '@shared/ui/Accordion'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable, TableColumn } from '@shared/ui/table'
import theme from '@theme'
import { NO_DATA_ID } from '@widgets/audience/model/constant'

import ExportCSV from './ExportCSV'
import ExportFacebookAds from './ExportFacebookAds'
import ExportGoogleAds from './ExportGoogleAds'
import ExportThirdParty from './ExportThirdParty'
import { Card, GreyBG, TimeText } from './styles'

const AudienceDetailExport = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const audienceId = getIntSafe(id, NO_DATA_ID)
  const { data: audienceRule } = useGetAudienceRuleQuery(audienceId, {
    skip: audienceId === NO_DATA_ID,
  })

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const {
    data: audienceRuleUserListData = INITIAL_PAGINATION_RESPONSE,
    isFetching: isFetchingAudienceRuleUserList,
  } = useGetAudienceRuleUserListQuery(
    { id: audienceId, page, perPage },
    { skip: audienceId === NO_DATA_ID }
  )
  const columns: TableColumn<AudienceRuleUser>[] = [
    { headerName: 'cdp_user_id', field: 'cdpUserId' },
    { headerName: 'user_id', field: 'appUserId' },
    {
      headerName: 'create_time',
      field: 'createdAt',
      valueFormatter: value => formatDateTimeDisplay(value as string),
    },
  ]

  const isOver24Hours = audienceRule
    ? checkIsOver24Hours(audienceRule.latestAudienceRuleUserCreatedAt)
    : false

  const thirdPartyGroupProviderListMap = useThirdPartyGroupProviderListMap(
    THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST_AUDIENCE
  )

  const { t } = useTranslation(['common', 'audience'])

  const handleExported = (type: AudienceListExported['exportType']) => () => {
    if (!audienceRule) {
      return
    }

    trackEvent('AudienceListExport', {
      audienceCount: audienceRule.latestUserCount,
      audienceRuleId: audienceRule.id,
      exportType: type,
      schedulingType: audienceRule.schedulingType,
    })
  }

  return (
    <>
      {audienceRule && (
        <>
          {audienceRule.schedulingType === 'default' &&
            Boolean(audienceRule.latestAudienceRuleUserCreatedAt) && (
              <GreyBG>
                {`${t('audience:type_dynamic_update_title')}${t(
                  'audience:type_dynamic_update_time'
                )}`}
                <TimeText isWarning={isOver24Hours}>
                  {formatDateTimeDisplay(
                    audienceRule.latestAudienceRuleUserCreatedAt
                  )}
                </TimeText>
              </GreyBG>
            )}

          <SectionTitle title={t('common:export')} />

          <ExportCSV
            audienceRuleId={audienceRule.id}
            latestAudienceRuleUserCreatedAt={
              audienceRule.schedulingType === 'default' &&
              Boolean(audienceRule.latestAudienceRuleUserCreatedAt)
                ? audienceRule.latestAudienceRuleUserCreatedAt
                : ''
            }
            mb={5}
            onExported={handleExported('csv')}
          />

          <ExportGoogleAds
            audienceRuleId={audienceRule.id}
            audienceRuleTitle={audienceRule.title}
            schedulingType={audienceRule.schedulingType}
            mb={5}
            onExported={handleExported('google_ads')}
          />

          <ExportFacebookAds
            audienceRuleId={audienceRule.id}
            audienceRuleTitle={audienceRule.title}
            schedulingType={audienceRule.schedulingType}
            mb={5}
            onExported={handleExported('facebook_ads')}
          />

          <ExportThirdParty
            group="line"
            audienceRuleId={audienceRule.id}
            audienceRuleTitle={audienceRule.title}
            schedulingType={audienceRule.schedulingType}
            thirdPartyAppList={
              thirdPartyGroupProviderListMap['line'].thirdPartyAppList
            }
            mb={5}
            onExported={handleExported('line')}
          />

          {/* 僅在用戶設定過 iterable API Key 才顯示區塊 */}
          {thirdPartyGroupProviderListMap['iterable'].thirdPartyAppList.length >
            0 && (
            <ExportThirdParty
              group="iterable"
              audienceRuleId={audienceRule.id}
              audienceRuleTitle={audienceRule.title}
              schedulingType={audienceRule.schedulingType}
              thirdPartyAppList={
                thirdPartyGroupProviderListMap['iterable'].thirdPartyAppList
              }
              mb={5}
              onExported={handleExported('iterable')}
            />
          )}

          <SectionTitle title={t('audience:export_sms.send')} />

          <ExportThirdParty
            group="sms"
            audienceRuleId={audienceRule.id}
            audienceRuleTitle={audienceRule.title}
            schedulingType={audienceRule.schedulingType}
            thirdPartyAppList={
              thirdPartyGroupProviderListMap['sms'].thirdPartyAppList
            }
            mb={5}
            onExported={handleExported('sms')}
          />

          <SectionTitle
            title={t('audience:section_title_list_of_conditions_and_users')}
          />

          <Box
            sx={theme => ({
              mb: 5,
              boxShadow: `0px 4px 9px ${theme.colors.black5}`,
            })}
          >
            <Accordion
              label={t('audience:conditions')}
              color={theme.colors.textPrimaryBlue}
              bgColor={theme.colors.white}
              summarySize="lg"
              isDefaultCollapse
              labelOnRightSide={t('common:view_details')}
            >
              <Card>
                <AudienceFilter state={audienceRule.filter} />
              </Card>
            </Accordion>
          </Box>
        </>
      )}

      <DataTable
        columns={columns}
        rows={audienceRuleUserListData.items}
        count={audienceRuleUserListData.totalCount}
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="cdpUserId"
        isLoading={isFetchingAudienceRuleUserList}
        isRowClickable
        onRowClick={rowData => {
          history.push(
            `/${CATEGORY.cdm}/${PAGE_ROOT.userProfile}/${rowData.cdpUserId}`
          )
        }}
        onPageChange={nextPage => {
          setPage(nextPage + 1)
        }}
        onRowsPerPageChange={nextPerPage => {
          setPerPage(nextPerPage)
        }}
      />
    </>
  )
}

export default AudienceDetailExport
