import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import Box from '@mui/material/Box'

import { BasicNode, createGetHandleStyles, HandleSourceText } from '../_shared'
import type { BasicNodeOperation } from '../../hooks'
import LogicGroupNodeEditDialog, {
  DEFAULT_VALUES,
} from './LogicGroupNodeEditDialog'
import {
  LogicGroupNodeData,
  logicGroupNodeSchema,
} from './logicGroupNodeSchema'

const HANDLE_IDS = ['group_a', 'group_b', 'group_c', 'group_d']

type Props = BasicNodeOperation<Partial<LogicGroupNodeData>>

const LogicGroupNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const updateNodeInternals = useUpdateNodeInternals()

    const handleConfirm = async (values: Partial<LogicGroupNodeData>) => {
      if (initialValues.groups?.length !== values.groups?.length) {
        updateNodeInternals(id)
        onDeleteEdges('source')
      }

      onChange(values)
      setIsOpenDialog(false)
    }

    const handleClose = async () => {
      setIsOpenDialog(false)
    }

    const groupCount = useMemo(
      () => initialValues.groups?.length ?? 2,
      [initialValues.groups?.length]
    )

    const getHandleStyles = useMemo(
      () => createGetHandleStyles(groupCount),
      [groupCount]
    )

    const stringifyValues = JSON.stringify(initialValues)

    const isSettled = useMemo(
      () => logicGroupNodeSchema.isValidSync(JSON.parse(stringifyValues)),
      [stringifyValues]
    )

    const { t } = useTranslation(['workflow'])

    return (
      <>
        <BasicNode
          nodeType="logic-group"
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isSettled={isSettled}
        >
          <p>{t('workflow:node_logic_group.node_hint', { groupCount })}</p>
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        <Box>
          {(initialValues.groups ?? DEFAULT_VALUES.GROUPS['2']).map(
            (groupValue, index) => (
              <Handle
                key={index}
                type="source"
                position={Position.Bottom}
                id={HANDLE_IDS[index]}
                style={getHandleStyles(index)}
                isConnectable={isConnectable}
              >
                <HandleSourceText>{groupValue}%</HandleSourceText>
              </Handle>
            )
          )}
        </Box>

        {/** 當判斷為真才渲染彈窗目的是取消時不會讓用戶看到重設後的內容 */}
        {isOpenDialog && (
          <LogicGroupNodeEditDialog
            initialValues={initialValues}
            isOpen
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        )}
      </>
    )
  }
)

export default LogicGroupNode
