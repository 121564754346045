import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import type { ConversionRateData } from '@shared/api/rtkQuery'
import { TooltipWhenOverflowX } from '@shared/ui/tooltips'
import {
  CONTROL_SECTION_MARGIN_LEFT,
  CONTROL_SECTION_WIDTH,
} from '@widgets/analytics/event/_shared/ui/EventRuleChart'

const Wrapper = styled(Box)`
  overflow-y: scroll;
  max-width: ${CONTROL_SECTION_MARGIN_LEFT + CONTROL_SECTION_WIDTH}px;
  height: 365px;
`

const Label = styled.div<{ fontSize?: number }>`
  overflow: hidden;
  max-width: 180px;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: ${({ fontSize }) => fontSize || 13}px;
  font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
`

const Value = styled.div<{ fontSize?: number }>`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ fontSize }) => fontSize || 24}px;
  font-weight: 700;
`

type Props = {
  groupConversionRates: ConversionRateData[]
}

const ConversionRate = ({ groupConversionRates }: Props) => {
  const { t } = useTranslation('dateAndChart')

  return (
    <Wrapper mr={1} pr={1.5}>
      {groupConversionRates.map(({ label, conversionRate }, idx) => {
        const i18nLabel =
          idx === 0
            ? `${t('statistics.conversion_rate_total')}`
            : `${t('statistics.conversion_rate_total')} - ${label}`

        return (
          <Box mb={3} key={idx}>
            <Box mb={0.25}>
              <TooltipWhenOverflowX title={i18nLabel}>
                <Label fontSize={idx === 0 ? 15 : 13}>{i18nLabel}</Label>
              </TooltipWhenOverflowX>
            </Box>
            <Value fontSize={idx === 0 ? 40 : 24}>{`${conversionRate.toFixed(
              2
            )}%`}</Value>
          </Box>
        )
      })}
    </Wrapper>
  )
}

export default ConversionRate
