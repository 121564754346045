import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'

type Props = {
  name: string
  list?: string[]
}

const TagDetail = ({ name, list = [] }: Props) => {
  const { t } = useTranslation(['common'])
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

  const handleOnClick = () => setIsCollapsed(!isCollapsed)

  const displayList = isCollapsed ? list.slice(0, 3) : list
  const hasCollapseButton = list.length > 3
  const hasList = list.length > 0

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      gap={1}
      mb={1}
    >
      <Box
        sx={theme => ({
          padding: '3px 15px',
          backgroundColor: theme.colors.bgPaleGrey,
          fontSize: 13,
          fontWeight: 600,
          color: theme.colors.brightBlue,
          borderRadius: '4px',
        })}
      >
        {name}
      </Box>
      {hasList && (
        <Box
          width="22px"
          minWidth="22px"
          sx={theme => ({
            height: '1px',
            backgroundColor: theme.colors.textPrimaryBlue,
          })}
        />
      )}
      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
        {displayList.map((l, x) => (
          <Box
            key={`${l}_${x}`}
            sx={theme => ({
              padding: '3px 15px',
              backgroundColor: theme.colors.textPrimaryBlue,
              fontSize: 13,
              fontWeight: 600,
              color: theme.colors.white,
              borderRadius: '4px',
            })}
          >
            {l}
          </Box>
        ))}
        {hasCollapseButton && (
          <Tooltip
            title={isCollapsed ? t('common:unfold') : t('common:collapse')}
          >
            <IconButton
              onClick={handleOnClick}
              disableRipple
              size="small"
              sx={theme => ({
                width: '20px',
                height: '20px',
                color: theme.colors.white,
                transform: isCollapsed ? 'none' : 'rotate(180deg)',
                backgroundColor: theme.colors.textPrimaryBlue,
                '&:hover': {
                  backgroundColor: theme.colors.brightBlue,
                },
              })}
            >
              <Icon icon={ICON.arrowDown} color="inherit" fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default TagDetail
