import Box from '@mui/material/Box'

type Props = {
  label: React.ReactNode
  value?: React.ReactNode
}

const ProfileRow = ({ label, value }: Props) => {
  return (
    <Box>
      <Box
        component="label"
        sx={{ mb: 1, fontSize: '16px', fontWeight: 'bold' }}
      >
        {label}
      </Box>
      <Box
        sx={{
          mb: 1,
          fontSize: '14px',
          color: theme => theme.colors.textSecondBlue,
        }}
      >
        {value || '-'}
      </Box>
    </Box>
  )
}

export default ProfileRow
