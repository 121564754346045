import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import axios from 'axios'

import { useLazyExportFunnelRuleCSVQuery } from '@shared/api/rtkQuery'
import funnelTrailImgUrl from '@shared/assets/images/analytics/funnel/funnelTrial.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { formatFunnelRule, trackEvent } from '@shared/lib/utils/amplitude'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import type { AnalyticsFormHeaderRenderProps } from '@widgets/analytics/_shared/types'
import {
  getExportCsvFileName,
  PREVIEW_BUTTON_MIN_WIDTH,
} from '@widgets/analytics/event/_shared'

import useFunnelForm from '../../lib/hooks/useFunnelForm'
import { getRelevanceAttributeError } from '../../lib/utils'
import type {
  FunnelChartSettings,
  FunnelRuleFormValues,
} from '../../model/types'
import DateAndChart from './DateAndChart'
import FunnelAnalyticsFilter from './FunnelAnalyticsFilter'
import FunnelFormConfirmDialog from './FunnelFormConfirmDialog'

type FunnelFormProps = {
  formValues?: FunnelRuleFormValues
  isFetchingFormValues?: boolean
  isSubmitting: boolean
  header: AnalyticsFormHeaderRenderProps
  onSubmit: (payload: FunnelRuleFormValues) => Promise<void>
}

export const FunnelForm = ({
  formValues,
  isFetchingFormValues = false,
  isSubmitting,
  header,
  onSubmit,
}: FunnelFormProps) => {
  const { t } = useTranslation(['audience', 'analytics'])

  const {
    previewData,
    isFetchingPreviewData,
    filter,
    hasError,
    filterErrors,
    setFilterErrors,
    dispatch,
    onFunnelSummaryPreview,
    chartSettings,
    setChartSettings,
    handleChartTypeChanged,
    resetFormValues,
    handleTimeRangeParamsChange,
  } = useFunnelForm({ initialValues: formValues })

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)
  const currentUserEmail = useAppSelector(state => state.auth.userInfo.email)
  const maxCount = useAppSelector(
    state => state.auth.plan.analytics.funnel.maxCount
  )

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.analytics.funnel.introduction
  )

  useEffect(() => {
    if (!formValues) {
      return
    }

    resetFormValues(formValues)
  }, [dispatch, formValues, resetFormValues])

  const [exportFunnelRule, { data: exportResult, isFetching: isExporting }] =
    useLazyExportFunnelRuleCSVQuery()
  const exportDownloadURL = exportResult?.downloadUrl
  const exportCsvFileName = getExportCsvFileName(
    filter.timeRangeParams,
    'funnel'
  )

  useEffect(() => {
    if (exportDownloadURL) {
      axios.get(exportDownloadURL).then(res => {
        const blob = new Blob([res.data], {
          type: 'text/csv;charset=utf-8;',
        })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = exportCsvFileName
        a.style.display = 'none'

        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    }
    // 僅在 exportDownloadURL 變化時，重新下載 CSV 檔案
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDownloadURL])

  const handleFunnelDialogCancel = () => {
    if (isSubmitting) {
      return
    }
    setIsShowConfirmDialog(false)
  }

  const handleFunnelRulePreview = () => {
    onFunnelSummaryPreview(filter)

    trackEvent('FunnelAnalysisPreviewed', formatFunnelRule(filter))
  }

  const handleFunnelRuleExport = () => {
    exportFunnelRule(filter)

    trackEvent('FunnelAnalysisExported', formatFunnelRule(filter))
  }

  const handleFunnelSubmit = async (formData: FunnelChartSettings) => {
    setChartSettings(formData)

    await onSubmit({
      title: formData.title,
      dashboardConfig: {
        chartType: formData.chartType,
      },
      iam:
        formData.privacyLevel === 'public'
          ? null
          : { ownerId: currentUserEmail },
      groupId: formData.groupId,
      filter,
    })

    trackEvent('FunnelAnalysisCreated', formatFunnelRule(filter))

    setIsShowConfirmDialog(false)
  }

  return (
    <>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('analytics:funnel.instruction.title')}
          description={t('analytics:funnel.instruction.description')}
          notice={t('analytics:funnel.instruction.notice', { maxCount })}
          imageUrl={funnelTrailImgUrl}
        />
      )}
      {header({
        isExportingCSV: isExporting,
        isExportDisabled: hasError,
        isSaveDisabled: hasError,
        onReset: () => resetFormValues(),
        onExport: handleFunnelRuleExport,
        onShowConfirmDialog: () => setIsShowConfirmDialog(true),
      })}

      <Box mb={5}>
        <FunnelAnalyticsFilter
          state={filter}
          dispatch={dispatch}
          onValidate={setFilterErrors}
        />
      </Box>

      <Box display="flex" alignItems="center" mb={5}>
        <DeepBlueGradientButton
          minWidth={PREVIEW_BUTTON_MIN_WIDTH}
          disabled={isFetchingPreviewData || hasError}
          onClick={handleFunnelRulePreview}
        >
          {t('audience:v3.preview_event_analytics')}
        </DeepBlueGradientButton>

        {hasError && (
          <Box ml={3}>
            <Box
              sx={{
                color: theme => theme.colors.orangeyRed,
                fontSize: 13,
                fontWeight: 500,
              }}
            >
              {getRelevanceAttributeError(filterErrors, t)}
            </Box>
          </Box>
        )}
      </Box>

      <DateAndChart
        data={previewData}
        chartType={chartSettings.chartType}
        timeRangeParams={filter.timeRangeParams}
        isLoading={isFetchingPreviewData || isFetchingFormValues}
        onChartTypeChanged={handleChartTypeChanged}
        onTimeRangeParamsChange={handleTimeRangeParamsChange}
      />

      <FunnelFormConfirmDialog
        initialValues={chartSettings}
        isOpen={isShowConfirmDialog}
        isDisableChartType={false}
        isDisablePrivacyLevel={formValues !== undefined}
        isSubmitting={isSubmitting}
        onCancel={handleFunnelDialogCancel}
        onConfirm={handleFunnelSubmit}
      />
    </>
  )
}

export default FunnelForm
