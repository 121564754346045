import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { Tab } from '@shared/ui/tabs'
import { CONTENT_SETTING_PATH } from '@widgets/contentSetting/model/constants'

const ContentSettingTab = () => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const { pathname } = useLocation()

  return (
    <Tab
      tabOptions={[
        {
          type: CONTENT_SETTING_PATH.CONTENT_TEMPLATE,
          name: t('common:route.content_template_list'),
        },
        {
          type: CONTENT_SETTING_PATH.MA_TRACING_URL,
          name: t('common:route.ma_tracing_url'),
        },
        {
          type: CONTENT_SETTING_PATH.MA_SUBSCRIPTION,
          name: t('common:route.ma_subscription'),
        },
      ]}
      currTab={pathname}
      onTabChange={tabPath => {
        history.push(tabPath)
      }}
    />
  )
}

export default ContentSettingTab
