import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import Card from '@shared/ui/Card'
import { CHART_CARD_HEIGHT } from '@widgets/audience/model/constant'

const LoadingCard = () => (
  <Box width="48%" mb={2}>
    <Card>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height={CHART_CARD_HEIGHT}
      >
        <CircularProgress />
      </Box>
    </Card>
  </Box>
)

export default LoadingCard
