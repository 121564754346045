import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { PAGE_ROOT } from '@shared/model/constants/routes'

import SendingAmount from './SendingAmount'

export const UsageManagement = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.sendingAmount}`}>
        <SendingAmount />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.sendingAmount}`} />
    </Switch>
  )
}

export default UsageManagement
