import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
} from './api'
import type { PolicyFromApi } from './policy'

type ProjectUserFromApi = {
  id: number
  email: string
  created_by: string
  created_at: string
  description?: string
  last_activity: number
  policies?: PolicyFromApi[]
}

const transformResponseProjectUserList = (
  data: PaginationResponse<ProjectUserFromApi>
) => camelcaseKeys(data, { deep: true })

const transformResponseProjectUser = (data: ProjectUserFromApi) =>
  camelcaseKeys(data, { deep: true })

export type ProjectUser = ReturnType<typeof transformResponseProjectUser>

type ProjectUserListParams = PaginationParams & {
  search?: string
}

type ProjectUserCreateParams = {
  email: string
  description: string
  policyIds: number[]
}

type ProjectUserUpdateParams = {
  id: number
} & ProjectUserCreateParams

const projectUser = api.injectEndpoints({
  endpoints: builder => ({
    projectUserList: builder.query<
      PaginationResponse<ProjectUser>,
      ProjectUserListParams
    >({
      query: params => ({
        url: '/iam/project_users',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponseProjectUserList,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'ProjectUser' as const,
                id,
              })),
              { type: 'ProjectUser', id: 'LIST' },
            ]
          : [{ type: 'ProjectUser', id: 'LIST' }],
    }),
    projectUser: builder.query<ProjectUser, number>({
      query: id => ({
        url: `/iam/project_users/${id}`,
      }),
      transformResponse: transformResponseProjectUser,
      providesTags: (result, error, arg) => [{ type: 'ProjectUser', id: arg }],
    }),
    createProjectUser: builder.mutation<void, ProjectUserCreateParams>({
      query: params => ({
        url: '/iam/project_users',
        method: 'POST',
        body: snakecaseKeys(params, { deep: true }),
      }),
      invalidatesTags: [{ type: 'ProjectUser', id: 'LIST' }],
    }),
    updateProjectUser: builder.mutation<void, ProjectUserUpdateParams>({
      query: params => ({
        url: `/iam/project_users/${params.id}`,
        method: 'PUT',
        body: snakecaseKeys(params, { deep: true }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ProjectUser', id: 'LIST' },
        { type: 'ProjectUser', id: arg.id },
      ],
    }),
    deleteProjectUser: builder.mutation<void, number>({
      query: id => ({
        url: `/iam/project_users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'ProjectUser', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useProjectUserListQuery,
  useProjectUserQuery,
  useCreateProjectUserMutation,
  useUpdateProjectUserMutation,
  useDeleteProjectUserMutation,
} = projectUser
