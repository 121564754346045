import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import type {
  LineProvider,
  ThirdPartyTagFormValues,
} from '@shared/api/rtkQuery'
import { checkIsTitle } from '@shared/lib/utils/validation'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'

const useThirdPartySchema = () => {
  const { t } = useTranslation(['common', 'settings', 'tag'])

  const thirdPartySchema = useMemo(
    () =>
      yup.object<ThirdPartyTagFormValues>().shape({
        name: yup
          .string()
          .required(t('common:errors.required'))
          .test(
            'checkIsTitle',
            t('settings:hint_name', { words: MAX_LIST_NAME_LENGTH }),
            checkIsTitle
          ),
        description: yup.string().nullable(),
        thirdPartyAppId: yup.number().min(1, t('common:errors.required')),
        appType: yup
          .string()
          .oneOf<LineProvider>(['maac', 'super8', 'coolbe_wyeth', 'omnichat'])
          .required(t('common:errors.required')),
      }),
    [t]
  )

  return { thirdPartySchema }
}

export default useThirdPartySchema
