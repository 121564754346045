import { memo } from 'react'
import Box from '@mui/material/Box'

import theme from '@theme'
export type Props = {
  isShow: boolean
  text: string
  position: {
    x: number
    y: number
  }
}

const Tooltip = ({ isShow, text, position }: Props) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width="auto"
    position="absolute"
    top={position.y}
    left={position.x}
    borderRadius={2}
    p={2}
    whiteSpace="nowrap"
    color={theme.colors.white}
    sx={{
      transition: 'all 0.3s ease',
      opacity: isShow ? 1 : 0,
      transform: `translate(calc(-50%),calc(-100%))`,
      userSelect: 'none',
      backgroundColor: theme.colors.blackTwo,
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '99%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: 0,
        borderLeft: `6px solid transparent`,
        borderRight: `6px solid transparent`,
        borderTop: `6px solid ${theme.colors.blackTwo}`,
      },
    }}
  >
    {text}
  </Box>
)

export default memo(Tooltip)
