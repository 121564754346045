import {
  PaginationParams,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import { api } from '../api'
import type { ProductKolList, ProductKolTagsList } from './productKolType'

const aiMarketingAssistantProductKolApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductKol: builder.query<
      ProductKolList,
      PaginationParams & { productId: string; tag?: string }
    >({
      query: ({ productId, page, perPage, tag }) => ({
        url: `/products/${productId}/kols`,
        params: { page, perPage, tag },
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ProductKolList),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ kolId }) => ({
                type: 'ProductKol' as const,
                id: kolId,
              })),
              { type: 'ProductKol', id: 'LIST' },
            ]
          : [{ type: 'ProductKol', id: 'LIST' }],
    }),
    getProductKolTags: builder.query<ProductKolTagsList, { productId: string }>(
      {
        query: ({ productId }) => ({
          url: `/products/${productId}/kol_tags`,
        }),

        providesTags: result =>
          result
            ? [
                ...result.map(v => ({
                  type: 'ProductKolTag' as const,
                  id: v,
                })),
                { type: 'ProductKolTag', id: 'LIST' },
              ]
            : [{ type: 'ProductKolTag', id: 'LIST' }],
      }
    ),
  }),
})

export const { useGetProductKolQuery, useGetProductKolTagsQuery } =
  aiMarketingAssistantProductKolApi
