import type { Workflow } from '@shared/api/rtkQuery'

export const createInitialWorkflow = (): Workflow => ({
  id: undefined,
  edges: [],
  name: '',
  nodes: [],
  sameUserRunLimit: 1,
  sameUserRunLimitEnabled: false,
  status: 'draft',
  userLifetimeLimit: 1,
  userLifetimeLimitEnabled: false,
  conversionEventEnable: false,
  conversionEvent: '',
  conversionValue: '',
})

export default createInitialWorkflow
