import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { CombinationButton } from '@features/filters/_shared'
import AttributeFilter from '@features/filters/AttributeFilter'
import type { FunnelFilterStep } from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  getCategoriesOfClassification,
  resolveMetadataPropertyId,
  useCategoriesOfMetadata,
  useGetMetadataProperty,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import useCategoriesOfEventProperties from '@shared/lib/utils/metadata/useCategoriesOfEventProperties'
import { ICON } from '@shared/model/constants/styles'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import {
  FUNNEL_STEP_FILTER_MAX_COUNT,
  FUNNEL_STEPS_MAX_COUNT,
} from '@widgets/analytics/funnel/_shared'

import type { FunnelAnalyticsFilterAction } from './funnelAnalyticsFilterReducer'

const EventContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
`

const EventSelector = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 24px;
  border-left: 5px solid ${({ theme }) => theme.colors.brightBlue};
`

const AttributeFilterContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black6};
`

const AttributeFilterList = styled.ul`
  padding-left: 24px;

  border-left: 5px solid ${({ theme }) => theme.colors.brightLightBlueTwo};
`

const AttributeFilterListItem = styled.li`
  display: flex;
  align-items: center;
`

const StepNumber = styled.span`
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 32px;
  font-weight: 500;
`

const Text = styled.span`
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 13px;
  font-weight: 500;
`

const Img = styled.img<{
  direction?: 'right' | 'down'
}>`
  width: 16px;
  height: 16px;

  transform: ${({ direction }) =>
    direction === 'right' ? 'rotate(270deg)' : 'rotate(0deg)'};
`

type Props = {
  state: FunnelFilterStep
  currIndex: number
  isDeletable: boolean
  isReplicable: boolean
  isRelevanceAttribute: boolean
  dispatch: (action: FunnelAnalyticsFilterAction) => void
}

const StepFilter = ({
  state,
  currIndex,
  isDeletable,
  isReplicable,
  isRelevanceAttribute,
  dispatch,
}: Props) => {
  const [isOpenAttribute, setIsOpenAttribute] = useState(true)

  const { eventEntity } = useMetadataQuery()

  const { t } = useTranslation(['audience', 'analytics', 'common'])

  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )

  const categoriesOfAttributeFilter = useCategoriesOfMetadata(state.eventName)

  const categoriesOfEventProperties = useCategoriesOfEventProperties(
    state.eventName,
    { skipDimensionColumn: true }
  )

  const getMetadataProperty = useGetMetadataProperty(state.eventName)

  const isAttributeFilterFull =
    state.attributeFilter.nodes.length >= FUNNEL_STEP_FILTER_MAX_COUNT

  return (
    <>
      <EventContainer>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={48}
          mr={1}
        >
          <StepNumber>{currIndex + 1}</StepNumber>
        </Box>

        <EventSelector>
          <Box display="inline" mr={1}>
            <CategoryList
              categories={categoriesOfClassification}
              selectedValue={state.eventName}
              onValueChanged={targetEventName => {
                dispatch({
                  type: 'updateEventName',
                  payload: {
                    targetIndex: currIndex,
                    eventName: targetEventName,
                  },
                })
              }}
            />
          </Box>

          {isRelevanceAttribute && (
            <>
              <Box display="inline" mr={1}>
                <Text>{`& ${t('analytics:funnel.relevance_attribute')}`}</Text>
              </Box>

              <CategoryList
                categories={categoriesOfEventProperties}
                selectedValue={createMetadataPropertyId(
                  state.relevanceSource,
                  state.relevanceField
                )}
                isDisabled={!isRelevanceAttribute}
                enableResetButton={true}
                onValueChanged={propertyId => {
                  if (!propertyId) {
                    dispatch({
                      type: 'updateRelevanceAttribute',
                      payload: {
                        targetIndex: currIndex,
                        relevanceField: '',
                        relevanceSource: '',
                      },
                    })
                    return
                  }

                  const { source: relevanceSource, field: relevanceField } =
                    resolveMetadataPropertyId(propertyId)

                  const { dataType: currDataType } = getMetadataProperty(
                    relevanceSource,
                    relevanceField
                  )

                  if (currDataType) {
                    dispatch({
                      type: 'updateRelevanceAttribute',
                      payload: {
                        targetIndex: currIndex,
                        relevanceField,
                        relevanceSource,
                      },
                    })
                  }
                }}
              />
            </>
          )}

          {state.eventName && (
            <>
              <Tooltip
                title={
                  isAttributeFilterFull
                    ? t('audience:v3.filter_max_hint_condition', {
                        max: FUNNEL_STEP_FILTER_MAX_COUNT,
                      })
                    : t('audience:v3.filter_add')
                }
              >
                {/** 為了讓 IconButton 被 disabled 時能夠正常顯示 tooltip */}
                <Box display="inline-block">
                  <IconButton
                    disabled={isAttributeFilterFull}
                    onClick={() => {
                      const [firstCategory] = categoriesOfAttributeFilter
                      if (firstCategory && firstCategory.options.length > 0) {
                        const [{ value: propertyId }] = firstCategory.options

                        const { source, field } =
                          resolveMetadataPropertyId(propertyId)

                        const { dataType, repeated } = getMetadataProperty(
                          source,
                          field
                        )

                        dispatch({
                          type: 'addAttributeFilter',
                          payload: {
                            source,
                            field,
                            dataType,
                            repeated,
                            parentIndex: currIndex,
                          },
                        })
                      }
                    }}
                  >
                    <Icon
                      icon={ICON.plusCircle}
                      color={theme.colors.textPrimaryBlue}
                    />
                  </IconButton>
                </Box>
              </Tooltip>

              <Tooltip
                title={
                  isReplicable
                    ? t('common:duplicate')
                    : t('audience:v3.filter_max_hint_event_analytics', {
                        max: FUNNEL_STEPS_MAX_COUNT,
                      })
                }
              >
                <Box display="inline-block">
                  <IconButton
                    disabled={!isReplicable}
                    onClick={() => {
                      dispatch({
                        type: 'duplicateStep',
                        payload: { index: currIndex },
                      })
                    }}
                  >
                    <Icon
                      icon={ICON.copy}
                      color={theme.colors.textPrimaryBlue}
                    />
                  </IconButton>
                </Box>
              </Tooltip>
            </>
          )}

          {/* Duplicate 新增 step，要考慮上限 */}

          {isDeletable && (
            <IconButton
              onClick={() => {
                dispatch({
                  type: 'removeStep',
                  payload: { index: currIndex },
                })
              }}
            >
              <Icon icon={ICON.times} />
            </IconButton>
          )}
        </EventSelector>
      </EventContainer>

      {state.eventName !== '' && state.attributeFilter.nodes.length > 0 && (
        <AttributeFilterContainer>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            ml={6.5}
            mr={2}
          >
            <CombinationButton
              onClick={() => {
                setIsOpenAttribute(prev => !prev)
              }}
            >
              <Img
                direction={isOpenAttribute ? 'down' : 'right'}
                src={`/images/icon-circle-arrow.svg`}
                alt={'toggle'}
              />
            </CombinationButton>
          </Box>

          {isOpenAttribute ? (
            <>
              <Box mr={1} display="inline">
                <Tooltip title={t('audience:v3.filter_toggle')}>
                  <CombinationButton
                    onClick={() => {
                      dispatch({
                        type: 'toggleAttributeFilterCombination',
                        payload: { index: currIndex },
                      })
                    }}
                  >
                    {state.attributeFilter.combination === 'and'
                      ? t('audience:v3.and')
                      : t('audience:v3.or')}
                  </CombinationButton>
                </Tooltip>
              </Box>

              <AttributeFilterList>
                {state.attributeFilter.nodes.map((node, index) => (
                  <AttributeFilterListItem key={index}>
                    <Box mr={1}>
                      <AttributeFilter
                        categories={categoriesOfAttributeFilter}
                        eventName={state.eventName}
                        field={node.field}
                        source={node.source}
                        operator={node.operator}
                        dimOperator={node.dimOperator}
                        params={node.params}
                        onChange={currValue => {
                          dispatch({
                            type: 'updateAttributeFilter',
                            payload: {
                              parentIndex: currIndex,
                              targetIndex: index,
                              ...currValue,
                            },
                          })
                        }}
                      />
                    </Box>

                    <Tooltip
                      title={
                        isAttributeFilterFull
                          ? t('audience:v3.filter_max_hint_condition', {
                              max: FUNNEL_STEP_FILTER_MAX_COUNT,
                            })
                          : t('common:duplicate')
                      }
                    >
                      <Box display="inline-block">
                        <IconButton
                          disabled={isAttributeFilterFull}
                          onClick={() => {
                            dispatch({
                              type: 'duplicateAttributeFilter',
                              payload: {
                                parentIndex: currIndex,
                                targetIndex: index,
                              },
                            })
                          }}
                        >
                          <Icon
                            icon={ICON.copy}
                            color={theme.colors.textPrimaryBlue}
                          />
                        </IconButton>
                      </Box>
                    </Tooltip>

                    <IconButton
                      onClick={() => {
                        dispatch({
                          type: 'removeAttributeFilter',
                          payload: {
                            parentIndex: currIndex,
                            targetIndex: index,
                          },
                        })
                      }}
                    >
                      <Icon icon={ICON.times} />
                    </IconButton>
                  </AttributeFilterListItem>
                ))}
              </AttributeFilterList>
            </>
          ) : (
            <Box pl={1.5}>
              <Text>{t('analytics:funnel.attribute_all')}</Text>
            </Box>
          )}
        </AttributeFilterContainer>
      )}
    </>
  )
}

export default StepFilter
