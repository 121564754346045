import Box from '@mui/material/Box'

import type { NotificationType } from '@shared/api/rtkQuery'
import theme from '@theme'

import Event from './NotificationItemType/Event'
import General from './NotificationItemType/General'
import ThirdPartyApp from './NotificationItemType/ThirdPartyApp'

const NotificationItem = ({ data }: { data: NotificationType }) => {
  return (
    <Box
      display="flex"
      p="18px 20px"
      borderBottom={`1px solid ${theme.colors.borderColor}`}
      width="100%"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      {data.type === 'event' && <Event notificationType={data} />}
      {data.type === 'tentacle' && <ThirdPartyApp notificationType={data} />}
      {data.type === 'general' && <General notificationType={data} />}
    </Box>
  )
}

export default NotificationItem
