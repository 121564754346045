import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ReplacementData } from '@shared/api/rtkQuery'
import type {
  CampaignStatus,
  SubscriptionStatus,
} from '@shared/api/rtkQuery/workflow'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

const useStatusText = (status: CampaignStatus | SubscriptionStatus) => {
  const { t } = useTranslation(['workflow'])

  switch (status) {
    case 'authorize_failed':
      return t('workflow:campaigns.errors.authorize_failed')

    case 'maac_template_deleted':
      return t('workflow:campaigns.errors.maac_template_deleted')

    case 'maac_template_with_params':
      return t('workflow:campaigns.errors.maac_template_with_params')

    case 'ses_invalid_msg_sender':
      return t('workflow:campaigns.errors.ses_invalid_msg_sender')

    case 'msg_channel_not_found':
      return t('workflow:errors.third_party_has_been_deleted')

    // 訂閱名單錯誤
    case 'bad':
      return t('workflow:errors.subscription_endpoint_invalid')

    case 'deleted':
      return t('workflow:errors.subscription_endpoint_deleted')

    default:
      return ''
  }
}

const CampaignError = ({
  status,
  replacementData,
}: {
  status: CampaignStatus | SubscriptionStatus
  replacementData?: ReplacementData
}) => {
  const { t } = useTranslation(['workflow'])
  const statusText = useStatusText(status)
  const {
    checkHasArchivedTracingUrlInReplacementData:
      hasArchivedTracingUrlInReplacementDataChecker,
  } = useTracingUrlStatus()
  const hasArchivedTracingUrlText =
    hasArchivedTracingUrlInReplacementDataChecker(replacementData)
      ? t('workflow:errors.tracing_url_is_archived')
      : ''

  const hintList = [statusText, hasArchivedTracingUrlText].filter(Boolean)

  if (!hintList.length) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {hintList.map(text => (
        <Box
          key={text}
          display="flex"
          alignItems="center"
          color={theme.colors.brightBlue}
          fontWeight={600}
          mt={0.5}
        >
          <Box mr={0.5}>
            <Icon
              color="inherit"
              fontSize="small"
              icon={ICON.exclamationTriangle}
            />
          </Box>
          {text}
        </Box>
      ))}
    </Box>
  )
}

export default CampaignError
