import type { FunnelChartSettings } from '../../model/types'

export const createInitialFunnelChartSettings = (
  privacyLevel: FunnelChartSettings['privacyLevel'] = 'public',
  groupId: FunnelChartSettings['groupId'] = 0
): FunnelChartSettings => ({
  title: '',
  chartType: 'funnel_chart',
  privacyLevel,
  groupId,
})
