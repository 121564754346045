import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import {
  FunnelRule,
  FunnelRuleGroup,
  useDeleteFunnelRuleMutation,
  useUpdateFunnelRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import { getDurationText } from '@shared/ui/DateRangePicker'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { NestedMenu } from '@shared/ui/menu'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'
import { usePreviewResponse } from '@widgets/analytics/funnel/_shared'
import type { FunnelChartType } from '@widgets/analytics/funnel/_shared/ui/FunnelForm/DateAndChart'

import Error from '../_shared/Error'
import {
  Card,
  CircularProgress,
  DurationWrapper,
  Header,
  Title,
} from '../_shared/Style'
import { LoadingWrapper } from '../_shared/Style'
import BarChartControl from './BarChartControl'
import LineChartControl from './LineChartControl'

type Props = {
  funnelId: number
  title: string
  chartType: FunnelChartType
  filter: FunnelRule['filter']
  groupList?: FunnelRuleGroup[]
  updateList?: (eventId: number) => void
}

const FunnelChart = ({
  funnelId,
  title,
  chartType,
  filter,
  groupList = [],
  updateList,
}: Props) => {
  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true,
  })

  const { t } = useTranslation(['common', 'dateAndChart', 'dashboard'])

  const {
    data: previewData,
    isFetching: isFetchingPreviewData,
    error: previewDataError,
  } = usePreviewResponse(filter, {
    inView,
  })

  const [deleteFunnelRule, { isLoading: isDeletingFunnelRule }] =
    useDeleteFunnelRuleMutation()

  const [updateFunnelRule, { isLoading: isUpdatingFunnelRule }] =
    useUpdateFunnelRuleMutation()

  const [isOpenActionPopup, setIsOpenActionPopup] = useState(false)
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const dispatch = useAppDispatch()

  const handleGroupListMenuItemClick = (group: FunnelRuleGroup) => {
    updateFunnelRule({
      title,
      dashboardConfig: { chartType },
      filter,
      id: funnelId,
      groupId: group.id,
    })
      .unwrap()
      .then(() => {
        if (updateList) {
          updateList(funnelId)
        }
      })
      .catch(() => {
        dispatch(
          openToast({
            message: t('dashboard:error.update_group_error'),
            status: 'error',
          })
        )
      })
  }

  return (
    <Card ref={ref}>
      <Header>
        <Title>{title}</Title>

        <Dropdown
          anchorElem={
            <IconButton>
              <Icon
                icon={ICON.ellipsisV}
                fontSize="small"
                color={theme.colors.textPrimaryBlue}
              />
            </IconButton>
          }
          isOpen={isOpenActionPopup}
          setIsOpen={setIsOpenActionPopup}
          placement="bottom-end"
        >
          <MenuList>
            <Link
              to={`/${CATEGORY.analytics}/${PAGE_ROOT.funnel}/${funnelId}/edit`}
            >
              <MenuItem>{t('common:edit')}</MenuItem>
            </Link>

            {groupList.length > 0 && (
              <NestedMenu label={t('common:group')}>
                <Box maxWidth={600}>
                  {groupList.map(({ id, name, disabled }) => (
                    <MenuItem
                      disabled={disabled}
                      key={`${name}_${id}`}
                      onClick={() => handleGroupListMenuItemClick({ id, name })}
                    >
                      <Box
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {name}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              </NestedMenu>
            )}

            <MenuItem
              onClick={() => {
                setIsOpenActionPopup(false)
                setIsShowDeleteDialog(true)
              }}
            >
              {t('common:delete')}
            </MenuItem>
          </MenuList>
        </Dropdown>
      </Header>

      <DurationWrapper>
        {getDurationText(filter.timeRangeParams, t)}
      </DurationWrapper>

      {(isFetchingPreviewData ||
        isDeletingFunnelRule ||
        isUpdatingFunnelRule ||
        isDeleted) && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {!isFetchingPreviewData && !isUpdatingFunnelRule && previewDataError && (
        <Error error={previewDataError} />
      )}

      {!isFetchingPreviewData &&
        !previewDataError &&
        !isDeletingFunnelRule &&
        !isUpdatingFunnelRule &&
        !isDeleted &&
        inView && (
          <>
            {chartType === 'funnel_chart' && (
              <BarChartControl previewData={previewData.funnel_chart} />
            )}

            {chartType === 'trend_chart' && (
              <LineChartControl
                funnelId={funnelId}
                previewData={previewData.trend_chart}
              />
            )}
          </>
        )}

      <DeleteConfirmDialog
        modalTitle={t('common:delete_header', { name: title })}
        isOpen={isShowDeleteDialog}
        onClose={() => {
          setIsShowDeleteDialog(false)
        }}
        onConfirm={() => {
          deleteFunnelRule(funnelId)
            .unwrap()
            .then(() => {
              setIsDeleted(true)

              if (updateList) {
                updateList(funnelId)
              }
            })
          setIsShowDeleteDialog(false)
        }}
      />
    </Card>
  )
}

export default FunnelChart
