import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'

import { checkIsEmail } from '@shared/lib/utils/validation'
import { ICON } from '@shared/model/constants/styles'
import Accordion from '@shared/ui/Accordion'
import Card from '@shared/ui/Card'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import theme from '@theme'

import ServiceListItem from './ServiceListItem'
import { Container, HorizontalLine, Label, ListItem } from './styled'
import UserPolicyDropdownList from './UserPolicyDropdownList'
import { PolicyServiceEntity } from './utils'

export * from './utils'

export const BUTTON_WIDTH = 200

export const STATUS_CODE = {
  NO_PERMISSION: 403,
  DUPLICATE_EMAIL: 409,
  REACH_LIMIT: 406,
}

export type FormValues = {
  email: string
  description: string
  userPolicyList: PolicyServiceEntity[]
}

const INITIAL_FORM_VALUES: FormValues = {
  email: '',
  description: '',
  userPolicyList: [],
}

type Props = {
  formValues?: FormValues
  isDisabled?: boolean
  onSubmit?: (values: FormValues) => Promise<void>
  children: React.ReactNode
}

const UserForm = ({
  formValues,
  isDisabled,
  onSubmit = () => Promise.resolve(),
  children,
}: Props) => {
  const { t } = useTranslation(['common', 'iam'])

  const formik = useFormik<FormValues>({
    initialValues: formValues ?? INITIAL_FORM_VALUES,
    enableReinitialize: true,
    validate: values => {
      const errors: Record<string, string> = {}

      if (!checkIsEmail(values.email)) {
        errors['email'] = t('iam:user.email_error')
      }

      if (values.userPolicyList.length === 0) {
        errors['userPolicyList'] = t('iam:user.policy_required')
      }

      return errors
    },
    onSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" alignItems="flex-start" flex={1} mr={5}>
            <Label mr={3}>{t('iam:user.email')}</Label>
            <Box flex={1}>
              <PrimaryGreyInput
                type="text"
                value={formik.values.email}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('email', value.toLowerCase())
                }
                mr={1}
                isError={formik.touched.email && Boolean(formik.errors.email)}
                disabled={isDisabled || Boolean(formValues)}
                fullWidth
              />
              <Label isError>
                {formik.touched.email && formik.errors.email}
              </Label>
            </Box>
          </Box>

          <Box display="flex" alignItems="flex-start" flex={1}>
            <Label mr={3}>{t('common:description')}</Label>
            <Box flex={1}>
              <PrimaryGreyInput
                type="text"
                value={formik.values.description}
                onChange={({ target: { value } }) =>
                  formik.setFieldValue('description', value)
                }
                mr={1}
                disabled={isDisabled}
                fullWidth
              />
            </Box>
          </Box>
        </Box>

        <HorizontalLine />

        <Box display="flex" alignItems="flex-start" mb={8}>
          <Label mr={3}>{t('iam:user.policy')}</Label>
          <Box flex={1}>
            {formik.values.userPolicyList.map(policy => (
              <Container key={policy.name}>
                <Accordion
                  label={`${policy.name} (${t('iam:user.n_services', {
                    count: policy.serviceEntity.ids.length,
                  })})`}
                >
                  <ul>
                    <ListItem color={theme.colors.blueyGrey}>
                      <Box flex={2}>{t('iam:user.service')}</Box>
                      <Box flex={3}>{t('iam:user.actions')}</Box>
                    </ListItem>

                    {policy.serviceEntity.ids.map(serviceName => (
                      <ServiceListItem
                        key={serviceName}
                        serviceName={serviceName}
                        actionGroupEntity={
                          policy.serviceEntity.entities[serviceName]
                        }
                      />
                    ))}
                  </ul>
                </Accordion>

                {!isDisabled && (
                  <IconButton
                    sx={{ ml: 1 }}
                    onClick={() => {
                      formik.setFieldValue(
                        'userPolicyList',
                        formik.values.userPolicyList.filter(
                          x => x.id !== policy.id
                        )
                      )
                    }}
                  >
                    <Icon icon={ICON.times} color={theme.colors.black} />
                  </IconButton>
                )}
              </Container>
            ))}

            {!isDisabled && (
              <>
                <Container>
                  <UserPolicyDropdownList
                    values={formik.values.userPolicyList}
                    onChange={values =>
                      formik.setFieldValue('userPolicyList', values)
                    }
                  />
                </Container>

                <Box mt={2} color={theme.colors.orangeyRed}>
                  <>
                    {formik.touched.userPolicyList &&
                      formik.errors.userPolicyList}
                  </>
                </Box>
              </>
            )}
          </Box>
        </Box>

        {children}
      </Card>
    </form>
  )
}

export default UserForm
