import Box from '@mui/material/Box'

import type { UserProfileEventReport } from '@shared/api/rtkQuery'
import type { TableColumn } from '@shared/ui/table'

const calculateEventReportColumns = (
  eventReport: UserProfileEventReport['eventReport']
): TableColumn<Record<string, any>>[] =>
  eventReport.map(({ displayName, name }) => ({
    headerName: displayName,
    field: name,
    valueFormatter: values => {
      if (Array.isArray(values)) {
        return (
          <Box>
            {values.map((value, id) => (
              <Box key={id}>{value}</Box>
            ))}
          </Box>
        )
      }

      return '-'
    },
  }))

export default calculateEventReportColumns
