import { styled } from '@mui/material/styles'
import MuiSwitch from '@mui/material/Switch'

const SWITCH_WIDTH = 48
const SWITCH_HEIGHT = 24
const SWITCH_PADDING = 2

// ref: https://mui.com/material-ui/react-switch/#customization
const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: SWITCH_WIDTH,
  height: SWITCH_HEIGHT,
  padding: 0,
  '& .MuiSwitch-track': {
    borderRadius: SWITCH_HEIGHT / 2,
    backgroundColor: theme.colors.lightGreyBlue,
    opacity: 1,
  },
  '& .MuiSwitch-switchBase': {
    padding: SWITCH_PADDING,
    color: theme.colors.white,

    '&.Mui-checked': {
      transform: `translateX(${SWITCH_WIDTH / 2}px)`,
      color: theme.palette.common.white,

      '& + .MuiSwitch-track': {
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },

    '&.Mui-disabled': {
      color: theme.palette.common.white,

      '&.Mui-checked': {
        color: theme.palette.common.white,
      },

      '& + .MuiSwitch-track': {
        borderColor: theme.colors.white,
        backgroundColor: theme.palette.grey[200],
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: SWITCH_HEIGHT - (SWITCH_PADDING + SWITCH_PADDING),
    height: SWITCH_HEIGHT - (SWITCH_PADDING + SWITCH_PADDING),
    boxShadow: 'none',
  },
}))

export default Switch
