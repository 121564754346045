import { memo, PropsWithChildren } from 'react'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import { useTrackAndPageInfo } from './hook/useTrackAndPageInfo'
import { ArrowButtonWrapper, ScrollMenuWrapper, SlideTrack } from './style'

type Props = {
  carouselClassname: {
    wrapper: string
    item: string
  }
}

const CarouselWrapper = ({
  children,
  carouselClassname,
}: PropsWithChildren<Props>) => {
  const { posInfo, pageInfo, setPageInfo, trackPos, setTrackPos } =
    useTrackAndPageInfo({
      lengthOfData: children && Array.isArray(children) ? children.length : 0,
      wrapperId: carouselClassname.wrapper,
      itemClassname: carouselClassname.item,
    })

  const handlePageButtonClick = (increment: number) => () => {
    const nextCurrPage = pageInfo.curr + increment
    if (nextCurrPage < 0 || nextCurrPage > pageInfo.total) {
      return
    }

    const nextOffsetIdx = posInfo.current.pageMapToOffsetIdx[nextCurrPage]
    const nextOffsetLeft = posInfo.current.offsetLefts[nextOffsetIdx]
    const nextOffsetRight = nextCurrPage
      ? posInfo.current.offsetLefts[nextOffsetIdx + 1]
      : 0

    if (increment > 0) {
      if (nextOffsetLeft === Math.abs(trackPos)) {
        setTrackPos(-nextOffsetRight)
      } else {
        setTrackPos(-nextOffsetLeft)
      }
    } else if (increment < 0) {
      if (nextOffsetRight === Math.abs(trackPos)) {
        setTrackPos(-nextOffsetLeft)
      } else {
        setTrackPos(-nextOffsetRight)
      }
    }

    setPageInfo({ ...pageInfo, curr: nextCurrPage })
  }

  return (
    <ScrollMenuWrapper
      id={carouselClassname.wrapper}
      hasLeftArrow={pageInfo.curr !== 0}
      hasRightArrow={pageInfo.curr !== pageInfo.total}
    >
      {pageInfo.curr !== 0 && (
        <ArrowButtonWrapper
          direction="left"
          onClick={handlePageButtonClick(-1)}
        >
          <Icon
            id="icon-arrow-left"
            icon={ICON.arrowRight}
            color={theme.colors.textPrimaryBlue}
          />
        </ArrowButtonWrapper>
      )}
      <SlideTrack posLeft={trackPos}>{children}</SlideTrack>
      {pageInfo.curr !== pageInfo.total && (
        <ArrowButtonWrapper
          direction="right"
          onClick={handlePageButtonClick(1)}
        >
          <Icon
            id="icon-arrow-right"
            icon={ICON.arrowRight}
            color={theme.colors.textPrimaryBlue}
          />
        </ArrowButtonWrapper>
      )}
    </ScrollMenuWrapper>
  )
}

export default memo(CarouselWrapper)
