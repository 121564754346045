import type { EventRuleNode } from '@shared/api/rtkQuery'

import type { EventAnalysisPreviewed } from '../core'

export const formatEventRule = (
  source: EventRuleNode
): EventAnalysisPreviewed => {
  const target: EventAnalysisPreviewed = {
    aggregator: [],
    eventName: [],
    field: [],
    groupBy: [],
  }

  source.statisticMeasures.forEach(statisticMeasure => {
    target.aggregator.push(statisticMeasure.aggregator)
    target.eventName.push(statisticMeasure.eventName)
    target.field.push(statisticMeasure.field)
  })

  source.groupByFields.forEach(groupByField => {
    target.groupBy.push(groupByField.field)
  })

  return target
}
