import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { usePolicyQuery, useUpdatePolicyMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, SimpleButton } from '@shared/ui/buttons'
import { Tooltip } from '@shared/ui/tooltips'

import PolicyForm, {
  BUTTON_WIDTH,
  checkIsEditable,
  createActionsFromServiceEntity,
  createPolicyServiceEntity,
  FormValues,
  STATUS_CODE,
} from './PolicyForm'

const Edit = () => {
  const { id } = useParams<{ id: string }>()
  const policyId = getIntSafe(id)

  const { data: policy, isLoading: isLoadingPolicy } = usePolicyQuery(
    policyId,
    {
      skip: policyId === 0,
    }
  )

  const formValues = useMemo<FormValues | undefined>(
    () => createPolicyServiceEntity(policy),
    [policy]
  )

  const [updatePolicy, { isLoading: isUpdatingPolicy }] =
    useUpdatePolicyMutation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const handleSubmit = async (values: FormValues) => {
    const actions = createActionsFromServiceEntity(values.serviceEntity)

    try {
      await updatePolicy({ ...values, actions, id: policyId }).unwrap()

      history.push(`/${CATEGORY.userCenter}/${PAGE_ROOT.policies}/${policyId}`)
    } catch (error) {
      const typedError = formatRTKQueryError(error)

      if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
        dispatch(
          openToast({
            message: t('common:this_function_is_not_allowed'),
            status: 'error',
          })
        )
        return
      }

      dispatch(handleApiError(typedError))
    }
  }

  const { t } = useTranslation(['common'])

  const isEditable = checkIsEditable(policy)

  return (
    <>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.userCenter}/${PAGE_ROOT.policies}`}>
          {t('common:route.iam_policy')}
        </Link>
        {policy?.name && (
          <Tooltip title={policy.name}>
            <Box>{policy.name}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>

      <PolicyForm
        formValues={formValues}
        onSubmit={handleSubmit}
        isLoading={isLoadingPolicy}
        isSubmitting={isUpdatingPolicy}
      >
        {isEditable && (
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={4}>
              <SimpleButton
                onClick={() => {
                  history.push(
                    `/${CATEGORY.userCenter}/${PAGE_ROOT.policies}/${policyId}`
                  )
                }}
              >
                {t('common:cancel')}
              </SimpleButton>
            </Box>

            <DeepBlueGradientButton width={BUTTON_WIDTH} type="submit">
              {t('common:apply')}
            </DeepBlueGradientButton>
          </Box>
        )}
      </PolicyForm>
    </>
  )
}

export default Edit
