import { useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
} as const

type InfiniteScrollLoaderProps = {
  isLoading: boolean
  loadMore: () => void
}

export const InfiniteScrollLoader = ({
  loadMore,
  isLoading,
}: InfiniteScrollLoaderProps) => {
  const loaderRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isLoading) {
      return
    }

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadMore()
        }
      })
    }, OPTIONS)

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [isLoading, loadMore])

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      ref={loaderRef}
      height={65}
      width="100%"
    >
      <CircularProgress color="primary" />
    </Box>
  )
}

export default InfiniteScrollLoader
