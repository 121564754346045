type FormatExportAudienceRuleTitlePayload = {
  title: string
  isDynamic: boolean
}

export const formatExportAudienceRuleTitle = ({
  title,
  isDynamic,
}: FormatExportAudienceRuleTitlePayload) => {
  const timestamp = new Date().getTime()
  const interval = 1 // 未來在建立動態名單時可設定「多久更新一次名單」

  return isDynamic
    ? `${title}-${interval}-${timestamp}`
    : `${title}-${timestamp}`
}
