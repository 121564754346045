import type { DashboardIndicator } from '../IndicatorList'
import type { ChartData } from '../types'
import formatIndicatorValue from './formatIndicatorValue'

const INDICATOR_LATEST_LIST_MAX_COUNT = 3

/**
 * 取得最新指標的列表資料
 * @param series 圖表全部的 series 資料
 * @returns 最新指標的列表資料
 */
export const getDashboardIndicatorLatestList = (
  series: ChartData['series']
): DashboardIndicator[] =>
  series
    .map(item => ({
      indicator: item.name,
      value: item.data[item.data.length - 1],
      label: item.label ?? '',
    }))
    .sort((x, y) => y.value - x.value)
    .map(item => ({ ...item, value: formatIndicatorValue(item.value) }))
    .slice(0, INDICATOR_LATEST_LIST_MAX_COUNT)

export default getDashboardIndicatorLatestList
