import isNumber from 'lodash/fp/isNumber'

import type {
  ConsumptionSummaryDetail,
  ConsumptionSummaryResponse,
} from '@shared/api/rtkQuery'
import { formatCurrency, formatNumber } from '@shared/lib/utils/number'

import type { UserValuesTableColumn } from '../../types'
import { CONSUMPTION_SUMMARY_KEYS, FIELD_DICT } from './constants'

type ConsumptionSummaryDetailKey = keyof ConsumptionSummaryDetail

const getFormatCurrencyInt = (value: unknown, isCurrency: boolean) => {
  if (isNumber(value)) {
    return isCurrency ? formatCurrency(value) : `${formatNumber(value)}`
  }

  return '-'
}

const mapConsumptionSummaryToTableColumn = (
  data?: Partial<ConsumptionSummaryResponse>
): UserValuesTableColumn[] => {
  if (!data) {
    return Object.values(FIELD_DICT).map(fieldName => ({
      fieldName,
      '30': '-',
      '60': '-',
      '90': '-',
      '180': '-',
    }))
  }

  return Object.entries(FIELD_DICT).map(([key, value]) => {
    const {
      summary30D = '-',
      summary60D = '-',
      summary90D = '-',
      summary180D = '-',
    } = CONSUMPTION_SUMMARY_KEYS.reduce((collection, current) => {
      collection[current] = getFormatCurrencyInt(
        data[current]?.[key as ConsumptionSummaryDetailKey],
        key === 'revenue'
      )
      return collection
    }, {} as Record<keyof ConsumptionSummaryResponse, string>)
    return {
      fieldName: value,
      '30': summary30D,
      '60': summary60D,
      '90': summary90D,
      '180': summary180D,
    }
  })
}

export default mapConsumptionSummaryToTableColumn
