import { useMemo } from 'react'

import { CoolBeWyethTemplate } from '@shared/api/rtkQuery'
import type { TableColumn } from '@shared/ui/table'
import { Radio } from '@widgets/workflow/_shared/nodes/ActionSendLineNode/templates/_shared/styles'

type Props = {
  templateId?: string
}

const useColumns = ({ templateId }: Props) => {
  const columns: TableColumn<CoolBeWyethTemplate & { isDisabled: boolean }>[] =
    useMemo(
      () => [
        {
          field: 'id',
          headerName: '',
          colRatio: 0.5,
          valueFormatter: (id, { isDisabled }) => (
            <Radio
              color="primary"
              checked={templateId === id}
              disabled={isDisabled}
            />
          ),
        },
        {
          field: 'id',
          headerName: 'content_template_id',
          colRatio: 0.5,
        },
        { field: 'name', headerName: 'content_template_name' },
      ],
      [templateId]
    )

  return columns
}

export default useColumns
