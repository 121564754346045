import type { TFunction } from 'i18next'

import type { TagType } from '@shared/api/rtkQuery'

import type { SchedulingType, TagJobStatus, UpdateWayType } from './type'

export const ALL_RENDERING_STATUS: TagJobStatus[] = [
  'processing',
  'created',
  'uploaded',
  'validating',
  'validated',
]

export const status: Record<string, TagJobStatus[]> = {
  finished: ['succeeded'],
  rendering: ['created', 'processing', 'uploaded', 'validating', 'validated'],
  failed: ['failed', ''],
}

export const updateMethod: Record<
  UpdateWayType,
  { schedulingType: SchedulingType; type?: 'internal' | 'offline' }
> = {
  routine: { schedulingType: 'default' },
  manual: { schedulingType: 'none', type: 'internal' },
  import: { schedulingType: 'none', type: 'offline' },
}

export const createMethod = (
  t: TFunction<('common' | 'tag')[]>
): Record<TagType, string> => ({
  offline: t('tag:import'),
  internal: t('tag:rule_creation'),
  internal_offline: '',
})
