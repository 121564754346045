import BiDirectionalMap from '@shared/lib/utils/BidirectionalMap'

import { GRADING_TAG_PATH } from '../../constants'
import type { TagRoutes, TagSourceType } from '../../type'

export default class TagSourceRouteMap extends BiDirectionalMap<
  TagSourceType,
  TagRoutes
> {
  public getSourceType = (route: TagRoutes): TagSourceType =>
    this.getKey(route) || 'grading'

  public getRoute = (sourceType: TagSourceType): TagRoutes =>
    this.getValue(sourceType) || GRADING_TAG_PATH
}
