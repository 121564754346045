import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'
import MuiCircularProgress from '@mui/material/CircularProgress'

export const CircularProgress = styled(MuiCircularProgress)`
  && {
    margin-right: 4px;
    color: rgba(0, 0, 0, 0.26);
  }
`

export const Button = styled(MuiButton)`
  &.MuiButtonBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.bgIceBlue};
  }
`
