import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import Loader from '@entities/Loader'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { GCS_BUCKET } from '@shared/model/constants/gcs'
import { ICON } from '@shared/model/constants/styles'
import type {
  ResourceType,
  ResourceTypeAction,
} from '@shared/model/types/ingestion'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'
import theme from '@theme'

import {
  DESCRIPTION_MAX_LENGTH,
  SUPPORTED_FILE_EXTENSION,
} from './_shared/constants'
import { MAX_FILE_SIZE } from './_shared/constants'
import {
  resourceTypeActionOptionMap,
  resourceTypeActionTemplateMap,
} from './_shared/constants'
import { useBeforeUnload } from './_shared/hook'
import FileUpload, { UploadStatus } from './fileUpload'

export type ImportButtonClickPayload = {
  description: string
  file: File
  action: ResourceTypeAction
}

type Props = {
  type: ResourceType
  buttonWord?: string
  isShowActionSelect?: boolean
  isImportButtonDisabled?: boolean
  onImportButtonClick: (params: ImportButtonClickPayload) => void
}

const ImportFile = ({
  type,
  buttonWord,
  isShowActionSelect = true,
  isImportButtonDisabled = false,
  onImportButtonClick,
}: Props) => {
  const {
    t,
    i18n,
    ready: isI18nReady,
  } = useTranslation(['common', 'dataImport'])

  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('initial')
  const [description, setDescription] = useState<string>('')

  const isCreatingIngestion = useAppSelector(
    state => state.infoBox.task.isCreatingIngestion
  )
  const taskErrorCode = useAppSelector(state => state.infoBox.task.errorCode)

  const dispatch = useAppDispatch()

  useBeforeUnload(file)

  useEffect(() => {
    if (uploadStatus === 'end' && file) {
      setDescription(file.name)
    }
  }, [dispatch, file, uploadStatus])

  const [currResourceTypeAction, setCurrResourceTypeAction] =
    useState<ResourceTypeAction>('upsert')

  const handleImportButtonClick = () => {
    if (file && description) {
      onImportButtonClick({
        description,
        file,
        action: currResourceTypeAction,
      })
      setFile(null)
      setUploadStatus('initial')
    }
  }

  if (!isI18nReady) {
    return null
  }

  const resourceTypeActionOptions: Option[] = resourceTypeActionOptionMap[
    type
  ].map(({ resourceTypeAction, i18nKey }) => ({
    label: t(i18nKey) as string,
    value: resourceTypeAction,
  }))
  const templateFile =
    resourceTypeActionTemplateMap[`${type}_${currResourceTypeAction}` as const]

  return (
    <>
      <Box mb={3} display="flex" alignItems="center">
        {isShowActionSelect && (
          <DropdownList
            key={i18n.language}
            options={resourceTypeActionOptions}
            onValueChanged={option => {
              setCurrResourceTypeAction(option.value as ResourceTypeAction)
              setFile(null)
              setUploadStatus('initial')
            }}
            marginRightRatio={2}
          />
        )}
        {templateFile && (
          <Box
            component="span"
            display="inline-flex"
            sx={theme => ({
              cursor: 'pointer',
              color: theme.colors.textPrimaryBlue,
              '& a:link': {
                textDecoration: 'underline',
              },
            })}
          >
            <Icon
              icon={ICON.paperclip}
              color={theme.colors.textPrimaryBlue}
              fontSize="small"
            />
            <a
              href={`${GCS_BUCKET.importDataTemplate}/${templateFile.filename}`}
              download
            >
              {templateFile.filename}
            </a>
          </Box>
        )}
      </Box>

      <Box mb={2}>
        <FileUpload
          fileMaxSize={MAX_FILE_SIZE}
          fileExtension={SUPPORTED_FILE_EXTENSION}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          setFile={setFile}
        />
      </Box>

      {file && (
        <>
          <Box mb={1}>
            <Box
              sx={theme => ({
                color: theme.colors.textPrimaryBlue,
              })}
              component="p"
            >
              {t('dataImport:upload.description')} *
            </Box>
          </Box>
          <Box mb={5}>
            <PrimaryGreyTextarea
              value={description}
              isError={description.length === 0}
              maxLength={DESCRIPTION_MAX_LENGTH}
              onChange={({ target: { value } }) => {
                setDescription(value)
              }}
            />
          </Box>
        </>
      )}

      <Box mb={1} display="flex" justifyContent="flex-end" alignItems="center">
        {taskErrorCode === 'schemaMigration' && (
          <Box mr={2} color={theme.colors.chartRed}>
            {t('dataImport:upload.schema_migration')}
          </Box>
        )}
        <DeepBlueGradientButton
          disabled={!file || isImportButtonDisabled || description.length === 0}
          onClick={handleImportButtonClick}
          width={200}
        >
          {buttonWord ?? t('common:import')}
        </DeepBlueGradientButton>
      </Box>
      {isCreatingIngestion && <Loader isWhiteBG />}
    </>
  )
}

export default ImportFile
