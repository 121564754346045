import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { ICON } from '@shared/model/constants/styles'
import { useTrackAndPageInfo } from '@shared/ui/carousel'
import type { ChartData } from '@shared/ui/charts'
import { COLORS, getLongestSeriesLenIdx } from '@shared/ui/charts'
import { UniIcon as Icon } from '@shared/ui/icons'

import {
  Checkbox,
  Circle,
  ControlSection,
  IconWrapper,
  Item,
  ItemTrack,
  ItemWrapper,
  Label,
  Page,
} from './style'

export const CLASSNAME = {
  slideWrapper: 'DASHBOARD_EVENT_ANALYSIS_SLIDE_WRAPPER',
  slideItem: 'DASHBOARD_EVENT_ANALYSIS_SLIDE_ITEM',
}

const getTrackWrapperId = (id: number) => `${CLASSNAME.slideWrapper}_${id}`

type Props = {
  id: number
  data: ChartData[]
  visibilityFlags: boolean[]
  setVisibilityFlags: Dispatch<SetStateAction<boolean[]>>
}

const GroupPagination = ({
  id,
  data,
  visibilityFlags,
  setVisibilityFlags,
}: Props) => {
  const { t } = useTranslation('userProfile')

  const { longestSeriesLenIdx, maxLen } = getLongestSeriesLenIdx(data)
  const { posInfo, pageInfo, setPageInfo, trackPos, setTrackPos } =
    useTrackAndPageInfo({
      lengthOfData: data.length || 0,
      wrapperId: getTrackWrapperId(id),
      itemClassname: CLASSNAME.slideItem,
    })

  const handlePageButtonClick = (increment: number) => () => {
    const nextCurrPage = pageInfo.curr + increment
    if (nextCurrPage < 0 || nextCurrPage > pageInfo.total) {
      return
    }

    const nextOffsetIdx = posInfo.current.pageMapToOffsetIdx[nextCurrPage]
    const nextOffsetLeft = posInfo.current.offsetLefts[nextOffsetIdx]

    setTrackPos(-nextOffsetLeft)
    setPageInfo({ ...pageInfo, curr: nextCurrPage })
  }

  return (
    <ControlSection>
      <ItemWrapper
        id={getTrackWrapperId(id)}
        display="flex"
        alignItems="center"
        justifyContent={maxLen > 1 ? 'flex-start' : 'center'}
      >
        <ItemTrack posLeft={trackPos}>
          {maxLen > 1 && (
            <Item
              className={CLASSNAME.slideItem}
              onClick={() => {
                setVisibilityFlags(prev =>
                  prev.every(x => x === true)
                    ? prev.map(() => false)
                    : prev.map(() => true)
                )
              }}
            >
              <Checkbox
                checked={visibilityFlags.every(x => x === true)}
                color="primary"
                size="small"
              />
              <Label isVisible>{t('select_all')}</Label>
            </Item>
          )}

          {data[longestSeriesLenIdx].series.map(({ name }, idx) => (
            <Item
              // 相同事件會造成 name & groupName 重複，為了避免警告使用 idx 做 key
              key={idx}
              className={CLASSNAME.slideItem}
              onClick={() => {
                if (maxLen > 1) {
                  setVisibilityFlags(prev => {
                    const newFlags = [...prev]
                    newFlags[idx] = !prev[idx]
                    return newFlags
                  })
                }
              }}
            >
              <Circle bgColor={COLORS[idx]} isVisible={visibilityFlags[idx]} />
              <Label isVisible={visibilityFlags[idx]}>{name}</Label>
            </Item>
          ))}
        </ItemTrack>
      </ItemWrapper>

      {maxLen > 1 && (
        <>
          <IconWrapper
            isCollapseRightt
            disabled={pageInfo.curr === 0}
            onClick={handlePageButtonClick(-1)}
          >
            <Icon icon={ICON.arrowLeft} />
          </IconWrapper>

          <Page>{`${pageInfo.curr + 1}/${pageInfo.total + 1}`}</Page>

          <IconWrapper
            isCollapseLeft
            disabled={pageInfo.curr === pageInfo.total}
            onClick={handlePageButtonClick(1)}
          >
            <Icon icon={ICON.arrowRight} />
          </IconWrapper>
        </>
      )}
    </ControlSection>
  )
}

export default GroupPagination
