import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  AudienceRuleFormValues,
  useCreateAudienceRuleMutation,
  useGetAudienceRuleQuery,
} from '@shared/api/rtkQuery/audience'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { useSearchParams } from '@shared/lib/hooks/url'
import { formatAudienceFilter, trackEvent } from '@shared/lib/utils/amplitude'
import { getIntSafe } from '@shared/lib/utils/number'
import {
  CATEGORY,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'

import getTemplate from '../lib/getTemplate'
import AudienceForm from './AudienceForm'

const NO_DATA_ID = 0
const STATUS_CODE = { CREATE_MAX_COUNT: 406 }

const AudienceCreate = () => {
  const maxCount = useAppSelector(
    state => state.auth.plan.audience.dynamicList.maxCount
  )
  const query = useSearchParams()
  const referenceId = getIntSafe(
    query.get(QUERY_STRING.common.referenceId),
    NO_DATA_ID
  )

  const templateParams = query.get(QUERY_STRING.audiences.templateParams) || ''
  const templateId = query.get(QUERY_STRING.audiences.templateId) || ''

  const { data: referenceData, isLoading } = useGetAudienceRuleQuery(
    referenceId,
    { skip: referenceId === NO_DATA_ID }
  )
  const [createAudienceRule, { isLoading: isSubmitting }] =
    useCreateAudienceRuleMutation()

  const { t } = useTranslation(['audience', 'common'])

  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleSubmit = async (formValues: AudienceRuleFormValues) => {
    try {
      await createAudienceRule(formValues).unwrap()

      trackEvent('AudienceListCreated', formatAudienceFilter(formValues.filter))

      history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`)
    } catch (error) {
      const formatError = formatRTKQueryError(error)

      if (formatError.statusCode === STATUS_CODE.CREATE_MAX_COUNT) {
        // 靜態名單不會有數量限制，因此此訊息一定是動態名單
        dispatch(
          openToast({
            message: t('audience:create_max_count_warning', { maxCount }),
            status: 'error',
          })
        )
        return
      }

      dispatch(handleApiError(formatError))
    }
  }

  const formData = useMemo(() => {
    if (referenceId !== NO_DATA_ID) {
      return referenceData
    }

    if (templateId && templateParams) {
      const filter = getTemplate(templateId, templateParams)

      return {
        title: '',
        description: '',
        schedulingType: 'none',
        filter,
      } as AudienceRuleFormValues
    }

    return undefined
  }, [referenceData, referenceId, templateId, templateParams])

  const overviewLink = `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`

  return (
    <>
      <Breadcrumbs>
        <Link to={overviewLink}>{t('common:route.audience_list')}</Link>
        <Box>{t('audience:create')}</Box>
      </Breadcrumbs>

      <AudienceForm
        formValues={formData}
        isLoading={isLoading}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default AudienceCreate
