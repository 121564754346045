import { getLastNDays } from '@shared/ui/DateRangePicker'

export const getDateByType = (source: string | number) => {
  if (typeof source === 'number') {
    const { startDate } = getLastNDays(Math.abs(source))
    return startDate
  }

  return new Date(source)
}
