import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { formatRTKQueryError } from '@entities/apiHandler'
import LoadingContainer from '@entities/Loader/LoadingContainer'
import {
  NotificationRuleFormValues,
  useCreateNotificationRuleMutation,
  useGetNotificationRuleQuery,
} from '@shared/api/rtkQuery/analytics'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'

import { NO_DATA_ID, STATUS_CODE } from '../constant'
import Form from './NotificationRuleForm'

type Props = {
  isCreateMode?: boolean
}

const Detail = ({ isCreateMode }: Props) => {
  const { t } = useTranslation(['common'])
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { notificationRuleId } = useParams<{ notificationRuleId: string }>()
  const id = getIntSafe(notificationRuleId, NO_DATA_ID)

  const { data: formValues, isLoading: isNotificationRuleLoading } =
    useGetNotificationRuleQuery(id, {
      skip: id === NO_DATA_ID || isCreateMode,
    })
  const [createNotificationRule, { isLoading: isCreatingNotificationRule }] =
    useCreateNotificationRuleMutation()

  const handleSubmit = (result: NotificationRuleFormValues) => {
    createNotificationRule(result)
      .unwrap()
      .then(() => {
        history.push(`/${CATEGORY.analytics}/${PAGE_ROOT.notificationRule}`)
      })
      .catch(err => {
        const formatError = formatRTKQueryError(err)
        if (formatError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
          dispatch(
            openToast({
              message: t('common:errors.duplicate_name'),
              status: 'error',
            })
          )
          return
        }
        dispatch(
          openToast({
            message: t('common:failure_create'),
            status: 'error',
          })
        )
      })
  }

  return (
    <>
      <LoadingContainer isLoading={isNotificationRuleLoading}>
        {!isNotificationRuleLoading && (
          <Form
            id={id}
            formValues={formValues}
            isCreating={isCreatingNotificationRule}
            onSubmit={handleSubmit}
            isCreateMode={isCreateMode}
          />
        )}
      </LoadingContainer>
    </>
  )
}

export default Detail
