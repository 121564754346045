import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { api, PaginationResponse, paginationResponseHandler } from '../api'
import type {
  Consumptions,
  ConsumptionsFromApi,
  ConsumptionsParam,
} from './type'

export const transformResponseConsumptionFromApi = (
  data: PaginationResponse<ConsumptionsFromApi>
) => camelcaseKeys(data, { deep: true })

const consumptions = api.injectEndpoints({
  endpoints: builder => ({
    getConsumptions: builder.query<Consumptions, ConsumptionsParam>({
      query: params => ({
        method: 'GET',
        url: `accounting/consumptions`,
        params: snakecaseKeys(params, { deep: true }),
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponseConsumptionFromApi,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Consumptions' as const,
                id,
              })),
              'Consumptions',
            ]
          : ['Consumptions'],
    }),
  }),
  overrideExisting: false,
})

export const { useGetConsumptionsQuery } = consumptions
