import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { formatRTKQueryError } from '@entities/apiHandler'
import NoPermission from '@features/auth/ui/NoPermission'
import RequireIAM from '@features/auth/ui/RequireIAM'
import { useSearchUserQuery } from '@shared/api/rtkQuery'
import noPermissionBgUrl from '@shared/assets/images/iamModule/customerData.png'

import { STATUS_CODES } from './_shared'
import UserProfileDetailRoutes from './UserProfileDetail'
import UserProfileList from './UserProfileList'

const SEARCH_USER_DEFAULT_PER_PAGE = 5

export const UserProfileRoutes = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(['common', 'iam'])

  // 先打 API 看是否 403
  // 如果 403 代表該使用者，沒有權限
  const { isNoPermission, isLoading } = useSearchUserQuery(
    {
      searchColumnAlias: 'name',
      searchValue: '',
      searchOperator: 'like',
      perPage: SEARCH_USER_DEFAULT_PER_PAGE,
      page: 1,
    },
    {
      selectFromResult: ({ error, isFetching }) => ({
        isNoPermission:
          formatRTKQueryError(error).statusCode === STATUS_CODES.NO_PERMISSION,
        isLoading: isFetching,
      }),
    }
  )
  return (
    <NoPermission isLoading={isLoading} isNoPermission={isNoPermission}>
      <RequireIAM
        moduleName="user_profile"
        noPermission={{
          title: t('common:route.user_profile'),
          description: t('iam:no_permission'),
          bgImageUrl: noPermissionBgUrl,
        }}
      >
        <Switch>
          <Route exact path={path}>
            <UserProfileList />
          </Route>

          <Route path={`${path}/:userId`}>
            <UserProfileDetailRoutes />
          </Route>

          <Redirect to={path} />
        </Switch>
      </RequireIAM>
    </NoPermission>
  )
}
