import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type { ProductList, ProductListFromApi } from './productType'

export const transformResponseProductFromApi = (
  data: PaginationResponse<ProductListFromApi>
) => transformResponseCamelCaseKeys(data)

const aiMarketingAssistantProductApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductsList: builder.query<
      ProductList,
      PaginationParams & { name?: string }
    >({
      query: params => ({
        url: `/products`,
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponseProductFromApi,
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ productId }) => ({
                type: 'Product' as const,
                id: productId,
              })),
              { type: 'Product', id: 'LIST' },
            ]
          : [{ type: 'Product', id: 'LIST' }],
    }),
  }),
})

export const { useGetProductsListQuery } = aiMarketingAssistantProductApi
