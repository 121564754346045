import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { NumberOrString } from '@shared/lib/utils/type'
import { ICON } from '@shared/model/constants/styles'
import Card from '@shared/ui/Card'
import { COLORS } from '@shared/ui/charts'
import DateRangePicker from '@shared/ui/DateRangePicker'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import {
  Container,
  CONTROL_SECTION_WIDTH,
  Loading,
  LoadingWrapper,
  TOP_N_OPTIONS,
} from '@widgets/analytics/event/_shared/ui/EventRuleChart'

import type { FunnelChart } from '../../../model/types'
import BarChartControl from './BarChartControl'
import BarChartDisplayControl, {
  HorizontalLine,
} from './BarChartDisplayControl'
import ChartTypeRadioButtons from './ChartTypeRadioButtons'
import LineChartControl from './LineChartControl'
import LineChartDisplayControl from './LineChartDisplayControl'

export type FunnelChartType = 'funnel_chart' | 'trend_chart'

export const DEFAULT_TOP_N_TO_SHOW = 5

type Props = {
  data: FunnelChart
  chartType: FunnelChartType
  timeRangeParams: NumberOrString[]
  isDisabledBarChart?: boolean
  isLoading: boolean
  onChartTypeChanged: (chartType: FunnelChartType) => void
  onTimeRangeParamsChange: (timeRangeParams: NumberOrString[]) => void
}

const DateAndChart = ({
  data,
  chartType,
  timeRangeParams,
  isDisabledBarChart = false,
  isLoading,
  onChartTypeChanged: handleChartTypeChanged,
  onTimeRangeParamsChange: handleTimeRangeParamsChange,
}: Props) => {
  const { t, i18n } = useTranslation('common')
  const { funnel_chart, trend_chart } = data

  const [visibilityFlags, setVisibilityFlags] = useState<boolean[]>([])
  const [step, setStep] = useState<number | string>('all')
  const [topN, setTopN] = useState(DEFAULT_TOP_N_TO_SHOW)

  const itemCountOptions: Option[] = TOP_N_OPTIONS.map(({ label, value }) => ({
    label: t(label, { num: value }) as string,
    value,
  }))
  const colors = COLORS.map((color, idx) =>
    visibilityFlags[idx] ? color : undefined
  ).filter(Boolean) as string[]

  useEffect(() => {
    setStep('all')
    setTopN(DEFAULT_TOP_N_TO_SHOW)
  }, [data])

  return (
    <Container>
      <LoadingWrapper isDisabled={isLoading}>
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <ChartTypeRadioButtons
              chartType={chartType}
              onChartTypeChanged={handleChartTypeChanged}
              isDisabledBarChart={isDisabledBarChart}
            />

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
              gap={1}
            >
              {chartType === 'funnel_chart' && (
                <BarChartDisplayControl
                  previewData={funnel_chart}
                  visibilityFlags={visibilityFlags}
                  setVisibilityFlags={setVisibilityFlags}
                />
              )}

              {chartType === 'trend_chart' && (
                <LineChartDisplayControl
                  previewData={trend_chart}
                  step={step}
                  setStep={setStep}
                />
              )}

              <Icon
                icon={ICON.schedule}
                color={theme.colors.brightBlue}
                fontSize="small"
              />

              <DateRangePicker
                values={timeRangeParams}
                durationBgColor={theme.colors.bgPrimaryGrey}
                placement="bottom-end"
                onChange={handleTimeRangeParamsChange}
              />
            </Box>
          </Box>

          <Box mt={3} mb={2}>
            <HorizontalLine />
          </Box>

          {chartType === 'trend_chart' && (
            <Box display="flex" justifyContent="flex-end" mb={-5}>
              <Box width={CONTROL_SECTION_WIDTH}>
                <DropdownList
                  key={`${i18n.language}_${topN}`}
                  defaultOption={itemCountOptions.find(x => x.value === topN)}
                  options={itemCountOptions}
                  onValueChanged={option => {
                    setTopN(option.value as number)
                  }}
                  uniqueId={`trend-chart-item-count-dropdown`}
                  bgColor={theme.colors.bgPrimaryGrey}
                  isFullWidth
                />
              </Box>
            </Box>
          )}

          <Box display="flex" mt={4} mb={2}>
            {chartType === 'funnel_chart' && (
              <BarChartControl
                previewData={funnel_chart}
                colors={colors}
                visibilityFlags={visibilityFlags}
                setVisibilityFlags={setVisibilityFlags}
              />
            )}

            {chartType === 'trend_chart' && (
              <LineChartControl
                previewData={trend_chart}
                topN={topN}
                step={step}
                colors={colors}
                visibilityFlags={visibilityFlags}
                setVisibilityFlags={setVisibilityFlags}
              />
            )}
          </Box>
        </Card>
      </LoadingWrapper>
      <Loading isShow={isLoading} />
    </Container>
  )
}

export default memo(DateAndChart)
