import type { CreatorType } from '@shared/model/types/ingestion'

const creatorTypeMap: Record<CreatorType, string> = {
  editor: 'CDP Portal',
  application: 'Import API',
}

export const formatCreatorTypeDisplay = (creatorType: string) => {
  if (creatorType in creatorTypeMap) {
    return creatorTypeMap[creatorType as CreatorType]
  }

  return ''
}

export default formatCreatorTypeDisplay
