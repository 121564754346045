import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import ImportFile from '@entities/ImportFile'
import { useRefetchIngestionList } from '@features/infoBox/lib/hooks'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import { createIngestion } from '@features/infoBox/model/taskSlice'
import {
  INITIAL_PAGINATION_RESPONSE,
  useGetDimensionIngestionListQuery,
  useMetadataDimensionTableListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import formatCreatorTypeDisplay from '@shared/lib/utils/formatCreatorTypeDisplay'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import type { DimensionIngestion } from '@shared/model/types/ingestion'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable, TableColumn } from '@shared/ui/table'
import theme from '@theme'

const IMPORT_FILE_TYPE = 'dimension'

const ImportDimensionTables = () => {
  const { t } = useTranslation(['dataImport', 'common'])

  const [subType, setSubType] = useState('')

  const { options: dimensionTableOptions } = useMetadataDimensionTableListQuery(
    undefined,
    {
      selectFromResult: result => {
        if (result.data) {
          return {
            options: result.data.map<Option>(x => ({
              label: x.tableDisplayName,
              value: x.subType,
            })),
          }
        }

        return { options: [] }
      },
    }
  )

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const {
    data: ingestionList = INITIAL_PAGINATION_RESPONSE,
    isLoading,
    refetch,
  } = useGetDimensionIngestionListQuery({
    page,
    perPage,
  })

  const columns: TableColumn<DimensionIngestion>[] = useMemo(
    () => [
      {
        headerName: 'imported_time',
        colRatio: 0.75,
        field: 'importedTime',
        valueFormatter: value => formatDateTimeDisplay(value as string),
      },
      {
        headerName: 'imported_type',
        colRatio: 0.5,
        field: 'creatorType',
        valueFormatter: value => formatCreatorTypeDisplay(value as string),
      },
      {
        headerName: 'description',
        field: 'description',
      },
      {
        headerName: 'imported_by',
        field: 'creatorName',
      },
    ],
    []
  )

  useRefetchIngestionList({ refetch })

  const dispatch = useAppDispatch()

  return (
    <>
      <Box mb={5}>
        <Breadcrumbs>
          <Box>{t('common:route.v3.import_dimension_tables')}</Box>
        </Breadcrumbs>
        <Card>
          <Box display="flex" alignItems="center" mb={4}>
            <Box
              sx={{
                mr: 4,
                color: theme.colors.lightNavyBlue,
                fontWeight: 500,
              }}
            >
              {t('dataImport:dimension.dimension_table_name')}
            </Box>

            <DropdownList
              options={dimensionTableOptions}
              onValueChanged={option => setSubType(option.value as string)}
              value={subType}
            />
          </Box>

          <Box
            sx={{
              mb: 4,
              borderBottom: theme => `1px solid ${theme.colors.black6}`,
            }}
          />

          <ImportFile
            type={IMPORT_FILE_TYPE}
            isShowActionSelect={false}
            isImportButtonDisabled={!subType}
            onImportButtonClick={({ action, description, file }) => {
              if (!subType) {
                return
              }

              const payload: CreateIngestionPayload<'dimension'> = {
                createParams: {
                  action,
                  description,
                  dimensionSubType: subType,
                  resourceType: IMPORT_FILE_TYPE,
                },
                file,
                resourceType: IMPORT_FILE_TYPE,
              }

              dispatch(createIngestion(payload))
            }}
          />
        </Card>
      </Box>

      <SectionTitle
        title={t('dataImport:imported_dimension_table_management')}
      />

      <DataTable
        rows={ingestionList.items}
        columns={columns}
        // 元件預設 page 為 0
        page={page - 1}
        rowsPerPage={perPage}
        count={ingestionList.totalCount}
        isLoading={isLoading}
        onPageChange={currPage => setPage(currPage + 1)}
        onRowsPerPageChange={setPerPage}
      />
    </>
  )
}

export default ImportDimensionTables
