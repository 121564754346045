import { MouseEvent } from 'react'

import { RedButton } from '@shared/ui/buttons'
import { RedoIcon } from '@shared/ui/icons'

import { ICON_MARGIN_LEFT } from './constants'

type ButtonWithFailedIconProps = {
  onClick: (e: MouseEvent<EventTarget>) => void
  width?: number
  wording?: string
}

export const ButtonWithFailedIcon = ({
  onClick,
  width,
  wording,
}: ButtonWithFailedIconProps) => (
  <RedButton width={width} onClick={onClick}>
    {wording}
    <RedoIcon marginLeft={ICON_MARGIN_LEFT} />
  </RedButton>
)

export default ButtonWithFailedIcon
