import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useEmailEditor } from '@entities/mediumTemplates'
import { useContentTemplateQuery } from '@shared/api/rtkQuery'
import { useSearchParams } from '@shared/lib/hooks/url'
import { QUERY_STRING } from '@shared/model/constants/routes'
import { CONTENT_TEMPLATE_ROOT_PATH } from '@widgets/contentSetting/ui/ContentTemplate/constants'
import shouldRedirectByMediumType from '@widgets/contentSetting/ui/ContentTemplate/utils/shouldRedirectByMediumType'

const useInit = () => {
  const emailEditor = useEmailEditor()
  const { onLoadDesign, isReady } = emailEditor
  const history = useHistory()
  const searchParams = useSearchParams()
  const referenceId = searchParams.get(QUERY_STRING.common.referenceId) || ''
  const [selectTemplateDialogVisible, setSelectTemplateDialogVisible] =
    useState(false)
  const [saveTemplateDialogVisible, setSaveTemplateDialogVisible] =
    useState(false)

  const { data, isFetching } = useContentTemplateQuery(
    parseInt(referenceId, 10),
    {
      refetchOnMountOrArgChange: true,
      skip: !referenceId,
    }
  )

  useEffect(() => {
    if (!shouldRedirectByMediumType(data?.mediumType || null)) {
      return
    }
    history.push(CONTENT_TEMPLATE_ROOT_PATH)
  }, [data?.mediumType, history])

  useEffect(() => {
    if (referenceId || !isReady) {
      return
    }

    setSelectTemplateDialogVisible(true)
  }, [referenceId, isReady])

  useEffect(() => {
    if (!isReady || !referenceId || !data?.editorJson) {
      return
    }

    onLoadDesign(data.editorJson)
  }, [referenceId, isReady, data, onLoadDesign])

  return {
    isFetching,
    selectTemplateDialogVisible,
    saveTemplateDialogVisible,
    setSelectTemplateDialogVisible,
    setSaveTemplateDialogVisible,
    emailEditor,
  }
}

export default useInit
