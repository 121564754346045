import { useDrag } from 'react-dnd'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

import { ItemTypes } from './constant'
import type { TagSimpleType } from './type'

const Tag = styled.div<{ isTarget: boolean }>`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 14px;
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 8px;
  max-width: 100%;
  height: 24px;
  border: ${({ isTarget, theme }) =>
    isTarget
      ? `1px solid ${theme.colors.brightBlue}`
      : `1px solid ${theme.colors.tagBackground}`};
  border-radius: 2px;
  background-color: ${({ isTarget, theme }) =>
    isTarget ? theme.colors.powderBlue : theme.colors.tagBackground};
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 13px;
  font-weight: 500;
  cursor: move;
`

const ContentWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  width: 100%;
`

const Title = styled.span`
  cursor: pointer;
`

const TagLink = styled(Link)`
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const DraggableIcon = styled.span`
  margin-right: 4px;
  font-size: 12px;
`

const DeleteIcon = styled.span`
  margin-left: 6px;
  font-size: 9px;
  cursor: pointer;
`

const DraggableTag = ({
  data,
  groupId,
  isTarget,
  deletable,
  handleMoveTag,
}: {
  data: TagSimpleType
  groupId: number
  isTarget: boolean
  deletable: boolean
  handleMoveTag: (tag: TagSimpleType, targetGroupId: number) => void
}) => {
  const [, dragRef] = useDrag({
    type: ItemTypes.CARD,
    item: { groupId, ...data },
  })

  const tagLink = `/${CATEGORY.cdm}/${
    PAGE_ROOT.tags
  }/${TagSourceRoutesMap.getRoute(data.tagType)}/${data.id}`

  return (
    <Tag ref={dragRef} isTarget={isTarget}>
      <ContentWrapper>
        <DraggableIcon>
          <Icon
            id="icon-draggabledots"
            icon={ICON.draggabledots}
            color={theme.colors.brightBlue}
            fontSize="inherit"
          />
        </DraggableIcon>
        <TagLink to={tagLink}>
          <Title>{data.title}</Title>
        </TagLink>
        {deletable && (
          <DeleteIcon onClick={() => handleMoveTag(data, 0)}>
            <Icon
              id="icon-multiply"
              icon={ICON.multiply}
              color={theme.colors.blackTwo}
              fontSize="inherit"
            />
          </DeleteIcon>
        )}
      </ContentWrapper>
    </Tag>
  )
}

export default DraggableTag
