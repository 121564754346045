import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  ProductHashTagsOrderBy,
  ProductHashTagsOrderType,
  ProductTagsFromApi,
  ProductTagsList,
} from './productHashtagsType'

export const transformResponseProductTagsFromApi = (
  data: PaginationResponse<ProductTagsFromApi>
) => transformResponseCamelCaseKeys(data)

const aiMarketingAssistantProductHashtagsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductHashTags: builder.query<
      ProductTagsList,
      PaginationParams & {
        productId: string
        orderBy?: ProductHashTagsOrderBy
        orderType?: ProductHashTagsOrderType
      }
    >({
      query: ({ productId, page, perPage, orderBy, orderType }) => ({
        url: `/products/${productId}/hashtags`,
        params: { page, perPage, orderBy, orderType },
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponseProductTagsFromApi,
      providesTags: result =>
        result
          ? [
              ...result.items?.map(({ id }) => ({
                type: 'ProductHashtags' as const,
                id,
              })),
              { type: 'ProductHashtags', id: 'LIST' },
            ]
          : [{ type: 'ProductHashtags', id: 'LIST' }],
    }),
  }),
})

export const { useGetProductHashTagsQuery } =
  aiMarketingAssistantProductHashtagsApi
