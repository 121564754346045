import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import MuiRadio from '@mui/material/Radio'
import MuiTextField from '@mui/material/TextField'

import type { SchedulingType } from '@shared/api/rtkQuery/audience'
import { ICON } from '@shared/model/constants/styles'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'

import useValidateAudienceListName from '../../lib/useValidateAudienceListName'

const Title = styled.div`
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 16px;
  font-weight: 500;
`

const Radio = styled(MuiRadio)`
  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.brightBlue};
  }

  &.MuiRadio-colorSecondary.Mui-disabled {
    color: ${({ theme }) => theme.colors.blueyGrey};
  }
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-weight: 500;
`

const SubLabel = styled.label`
  margin-left: 38px;
  color: ${({ theme }) => theme.colors.textSecondBlue};
`

export const TextField = styled(MuiTextField)`
  min-height: 54px;
  && {
    input {
      ::placeholder {
        color: ${({ theme }) => theme.colors.lightNavyBlue};
        font-size: 13px;
      }
    }
  }
`

type ChangePayload = {
  name: string
  schedulingType: SchedulingType
}

type Props = {
  title: string
  schedulingType: SchedulingType
  isShow: boolean
  isSubmitting: boolean
  onChange: ({ name, schedulingType }: ChangePayload) => void
  onCancel: () => void
  onConfirm: () => void
}

const AudienceFormConfirmDialog = ({
  title,
  schedulingType,
  isShow,
  isSubmitting,
  onChange,
  onCancel,
  onConfirm: handleConfirm,
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])
  const { audienceListNameValidation } = useValidateAudienceListName(title)

  const handleCancel = () => {
    if (isSubmitting) {
      return
    }
    onCancel()
  }

  return (
    <Dialog open={isShow} onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <Title>{t('audience:create_dialog_title')}</Title>
          <IconButton onClick={handleCancel}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Box>

        <TextField
          autoFocus
          variant="standard"
          margin="dense"
          placeholder={t('audience:create_dialog_input_placeholder')}
          helperText={audienceListNameValidation.message}
          fullWidth
          error={!audienceListNameValidation.valid}
          value={title}
          onChange={e => {
            const value = e.target.value || ''
            const newListName = value.substring(0, MAX_LIST_NAME_LENGTH)
            onChange({
              name: newListName,
              schedulingType,
            })
          }}
        />

        <Box mb={1.5}>
          <Label>
            <Radio
              checked={schedulingType === 'none'}
              size="small"
              onChange={() => {
                onChange({
                  name: title,
                  schedulingType: 'none',
                })
              }}
            />
            {t('audience:type_static_name')}
          </Label>
          <SubLabel>{t('audience:type_static_description')}</SubLabel>
        </Box>

        <Box mb={1.5}>
          <Label>
            <Radio
              checked={schedulingType === 'default'}
              size="small"
              onChange={() => {
                onChange({
                  name: title,
                  schedulingType: 'default',
                })
              }}
            />
            {t('audience:type_dynamic_name')}
          </Label>
          <SubLabel>{t('audience:type_dynamic_description')}</SubLabel>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel}>{t('common:cancel')}</Button>
        <Button
          isPrimaryColor
          disabled={!audienceListNameValidation.valid || isSubmitting}
          onClick={handleConfirm}
        >
          {t('common:create')}
        </Button>
      </DialogActions>

      <Box height={4}>{isSubmitting && <LinearProgress />}</Box>
    </Dialog>
  )
}

export default AudienceFormConfirmDialog
