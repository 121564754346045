import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  INITIAL_PAGINATION_RESPONSE,
  POLLING_INTERVAL,
  SchedulingType,
  useCreateAudienceRuleJobMutation,
  useDeleteAudienceRuleMutation,
  useGetAudienceRuleListQuery,
  usePatchUpdateAudienceRuleMutation,
} from '@shared/api/rtkQuery'
import TrialAudience from '@shared/assets/images/trial/trialAudience.svg'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { SearchBar } from '@shared/ui/searchInput'
import { DataTable } from '@shared/ui/table'
import { Tab, TabOptions } from '@shared/ui/tabs'

import useValidateAudienceListName from '../../lib/useValidateAudienceListName'
import useAudienceListColumns from './useAudienceListColumns'

type RouteState = {
  page: number
  perPage: number
  schedulingType: SchedulingType
}

const createRouteState = (
  state: RouteState = {
    page: DEFAULT_PAGE,
    perPage: DEFAULT_PER_PAGE,
    schedulingType: 'none',
  }
) => state

const AudienceList = () => {
  const {
    pathname,
    state: { page, perPage, schedulingType } = createRouteState(),
  } = useLocation<RouteState>()

  const history = useHistory()

  const { t } = useTranslation(['audience', 'common'])

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.audience.introduction
  )
  const maxCount = useAppSelector(
    state => state.auth.plan.audience.dynamicList.maxCount
  )

  const [pollingInterval, setPollingInterval] = useState<number>()
  const [searchText, setSearchText] = useState<string>('')

  const tabOptions: TabOptions<SchedulingType> = useMemo(
    () => [
      {
        type: 'none',
        name: t('audience:type_static'),
        info: t('audience:instruction.static'),
      },
      {
        type: 'default',
        name: t('audience:type_dynamic'),
        info: t('audience:instruction.dynamic'),
      },
    ],
    [t]
  )

  const {
    data: audienceRuleListData = INITIAL_PAGINATION_RESPONSE,
    isFetching: isFetchingAudienceRuleList,
  } = useGetAudienceRuleListQuery(
    {
      page,
      perPage,
      schedulingTypes: schedulingType,
      title: searchText,
    },
    { pollingInterval }
  )

  useEffect(() => {
    if (
      audienceRuleListData.items.some(
        x => x.latestJobStatus !== 'failed' && x.latestJobStatus !== 'succeeded'
      )
    ) {
      setPollingInterval(POLLING_INTERVAL)
      return
    }

    setPollingInterval(undefined)
  }, [audienceRuleListData])

  const [openActionMenuId, setOpenActionMenuId] = useState<number>()
  const [renameText, setRenameText] = useState('')
  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState(false)
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)

  const [createAudienceRuleJob, { isLoading: isCreatingAudienceRuleJob }] =
    useCreateAudienceRuleJobMutation()

  const [patchUpdateAudienceRule, { isLoading: isUpdatingAudienceRule }] =
    usePatchUpdateAudienceRuleMutation()

  const [deleteAudienceRule, { isLoading: isDeletingAudienceRule }] =
    useDeleteAudienceRuleMutation()

  const columns = useAudienceListColumns({
    createAudienceRuleJob,
    openActionMenuId,
    isOpenRenameDialog,
    isOpenDeleteDialog,
    setOpenActionMenuId,
    setRenameText,
    setIsOpenRenameDialog,
    setIsOpenDeleteDialog,
  })

  const { audienceListNameValidation } = useValidateAudienceListName(renameText)

  const dispatch = useAppDispatch()

  const safeCloseAllPopup = useCallback(() => {
    // 需要照順序關閉彈窗避免頁面跳動
    setOpenActionMenuId(undefined)
    setIsOpenRenameDialog(false)
    setIsOpenDeleteDialog(false)
  }, [])

  return (
    <>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('audience:instruction.audience_instruction_title')}
          description={t(
            'audience:instruction.audience_instruction_description'
          )}
          notice={t('audience:instruction.audience_instruction_notice', {
            maxCount,
          })}
          imageUrl={TrialAudience}
        />
      )}

      <Breadcrumbs
        actionBar={
          <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/new`}>
            <DeepBlueGradientButton width={200}>
              {t('audience:create')}
            </DeepBlueGradientButton>
          </Link>
        }
      >
        <Box>{t('common:route.audience_list')}</Box>
      </Breadcrumbs>

      <Tab
        tabOptions={tabOptions}
        currTab={schedulingType}
        onTabChange={nextSchedulingType => {
          history.push(
            pathname,
            createRouteState({
              page: DEFAULT_PAGE,
              perPage,
              schedulingType: nextSchedulingType,
            })
          )

          setSearchText('')
        }}
      />
      <Box mb={4} display="flex" justifyContent="flex-end" alignItems="center">
        <SearchBar
          key={schedulingType}
          defaultValue={searchText}
          onSearch={setSearchText}
          placeholder={t('common:search')}
        />
      </Box>

      <DataTable
        columns={columns}
        count={audienceRuleListData.totalCount}
        isLoading={isFetchingAudienceRuleList || isCreatingAudienceRuleJob}
        isRowClickable
        onPageChange={newPage => {
          history.push(
            pathname,
            createRouteState({
              page: newPage + 1,
              perPage,
              schedulingType,
            })
          )
        }}
        onRowsPerPageChange={newPerPage => {
          history.push(
            pathname,
            createRouteState({
              page: DEFAULT_PAGE,
              perPage: newPerPage,
              schedulingType,
            })
          )
        }}
        // 元件預設 page 為 0
        page={page - 1}
        rows={audienceRuleListData.items}
        rowsPerPage={perPage}
      />

      <ConfirmDialog
        isLoading={isUpdatingAudienceRule}
        isOpen={isOpenRenameDialog}
        maxWidth="xs"
        modalTitle={t('audience:create_dialog_title_rename')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (
            openActionMenuId &&
            renameText &&
            audienceListNameValidation.valid
          ) {
            patchUpdateAudienceRule({ id: openActionMenuId, title: renameText })
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                dispatch(handleApiError(formatRTKQueryError(error)))
              })
          }
        }}
      >
        <TextInput
          fullWidth
          placeholder={t('audience:create_dialog_input_placeholder')}
          error={!audienceListNameValidation.valid}
          onChange={({ target }) => {
            const newAudienceTitle = target.value.substring(
              0,
              MAX_LIST_NAME_LENGTH
            )
            setRenameText(newAudienceTitle)
          }}
          value={renameText}
        />
        <TextInputErrorText>
          {audienceListNameValidation.message && (
            <span>{audienceListNameValidation.message}</span>
          )}
        </TextInputErrorText>
      </ConfirmDialog>

      <DeleteConfirmDialog
        isLoading={isDeletingAudienceRule}
        isOpen={isOpenDeleteDialog}
        maxWidth="xs"
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (openActionMenuId) {
            deleteAudienceRule(openActionMenuId)
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                dispatch(handleApiError(formatRTKQueryError(error)))
              })
          }
        }}
        modalTitle={t('audience:dialog_delete_list_title')}
      />
    </>
  )
}

export default AudienceList
