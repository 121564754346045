import {
  ThirdPartyAppProvider,
  useExportAudienceRuleUserIterableMutation,
  useExportAudienceRuleUserMAACMutation,
  useExportAudienceRuleUserSuper8Mutation,
} from '@shared/api/rtkQuery'

export const useExportAudienceRuleUser = (provider: ThirdPartyAppProvider) => {
  switch (provider) {
    // 因為 EVERY8D 需要其他資訊會在單獨的頁面匯出，但為了程式寫法一致回傳空函式
    // omnichat 不支援匯出
    case 'every8d':
    case 'every8d_exclusive':
    case 'omnichat':
      return () => [undefined, { isLoading: false }] as const
    case 'iterable':
      return useExportAudienceRuleUserIterableMutation
    case 'maac':
      return useExportAudienceRuleUserMAACMutation
    case 'super8':
      return useExportAudienceRuleUserSuper8Mutation

    default:
      throw new Error(`Invalid provider: ${provider}`)
  }
}

export default useExportAudienceRuleUser
