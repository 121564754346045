import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import Box from '@mui/material/Box'
import type { EditorState } from 'lexical'

import { ReplacementData } from '@shared/api/rtkQuery'

import { useSmsMetadataTagOptionList } from './_shared/hooks'
import AutoFocusPlugin from './_shared/plugin/AutoFocusPlugin'
import BlurPlugin from './_shared/plugin/BlurPlugin'
import WordCount, {
  WProps as WordCountProps,
} from './_shared/plugin/WordCountPlugin'
import { composeEditorState, composePureText } from './_shared/utils'
import MetadataTagPlugin from './metadataTag/MetadataTagPlugin'
import MetadataTagNode from './metadataTag/nodes/MetadataTagNode'
import UtmToolBar from './toolBar/SmsToolbar'
import { UtmNode } from './utm/nodes/UtmNode'
import UtmPlugin from './utm/UtmPlugin'

type SmsEditorProps = {
  isError?: boolean
  isEditable: boolean
  content: string
  onReplacementDataChange: (replacementData: ReplacementData) => void
  onContentChange: (content: string) => void
  replacementData?: ReplacementData
} & WordCountProps

export const SmsEditor = ({
  isError = false,
  isEditable,
  content,
  onReplacementDataChange,
  onContentChange,
  replacementData = {},
  // TODO - 未來 every8d 搬進 third party gateway 後，就可以統一用 useGetSmsCreditQuery 取得 credit，就可以移除掉 smsProvider
  smsProvider,
  integrationId = 0,
}: SmsEditorProps) => {
  const { t } = useTranslation(['contentTemplate'])

  const metadataTagOptions = useSmsMetadataTagOptionList()

  const editorConfig = useMemo(
    () => {
      if (metadataTagOptions.length === 0) {
        return null
      }

      return {
        namespace: 'smsEditor',
        editable: isEditable,
        onError(error: Error) {
          throw error
        },
        nodes: [UtmNode, MetadataTagNode],
        editorState: JSON.stringify({
          root: {
            children: composeEditorState(
              content,
              replacementData,
              metadataTagOptions
            ),
            direction: 'ltr',
            type: 'root',
          },
        }),
      }
    },

    /** 因為 initialConfig 變化時 LexicalComposer 不會重新 render
     *  加上 content, replacementData 變化會比較頻繁，
     *  只在 metadataTagOptions 變化才需要重新建立 config。
     * */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metadataTagOptions]
  )

  // 由於 LexicalComposer 需要 editorConfig，如果是空則不渲染畫面
  if (editorConfig === null) {
    return null
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Box
        position="relative"
        p={1}
        pt={2}
        borderRadius="4px"
        fontSize="13px"
        fontWeight={500}
        lineHeight={1.7}
        sx={theme => ({
          color: theme.colors.black,
          border: `1px solid ${
            isError ? theme.colors.orangeyRed : 'transparent'
          }`,
          backgroundColor: isEditable
            ? theme.colors.bgPrimaryGrey
            : theme.colors.veryLightBlue,
          '&:focus-within': {
            border: `1px solid ${theme.colors.brightBlue}`,
          },
        })}
      >
        <RichTextPlugin
          contentEditable={
            <Box>
              <ContentEditable
                style={{
                  outline: 'transparent',
                  padding: '0 8px 48px 8px',
                }}
              />
            </Box>
          }
          placeholder={
            <Box
              position="absolute"
              top={0}
              mt={2}
              ml={1}
              fontSize="13px"
              sx={theme => ({
                color: theme.colors.textSecondBlue,
              })}
            >
              {t('contentTemplate:please_enter_sms_content')}
            </Box>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <UtmToolBar />
        <UtmPlugin
          replacementData={replacementData}
          onReplacementDataChange={onReplacementDataChange}
        />
        <MetadataTagPlugin
          replacementData={replacementData}
          onReplacementDataChange={onReplacementDataChange}
        />
        <BlurPlugin
          replacementData={replacementData}
          onReplacementDataChange={onReplacementDataChange}
        />
      </Box>
      <WordCount smsProvider={smsProvider} integrationId={integrationId} />
      <OnChangePlugin
        onChange={(editorState: EditorState) => {
          editorState.read(() => {
            // pure content composer
            const content = editorState.toJSON().root.children

            const pureText = composePureText(content)

            onContentChange(pureText)
          })
        }}
      />
      <AutoFocusPlugin autoFocus={!isEditable} />
    </LexicalComposer>
  )
}

export default SmsEditor
