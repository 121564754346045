import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'

type Props = {
  isEditing: boolean
  onCancel: VoidFunction
  onEdit: VoidFunction
  editLabel: string
}

export const TextFieldActionButtons = ({
  isEditing,
  onEdit: handleEdit,
  onCancel: handleCancel,
  editLabel,
}: Props) => {
  const { t } = useTranslation(['common'])

  return (
    <>
      {isEditing ? (
        <>
          <Button
            variant="text"
            color="inherit"
            disableRipple
            onClick={handleCancel}
          >
            {t('common:cancel')}
          </Button>

          <Button variant="text" color="primary" type="submit">
            {t('common:save')}
          </Button>
        </>
      ) : (
        <Button
          variant="text"
          color="primary"
          disableRipple
          onClick={handleEdit}
        >
          {editLabel}
        </Button>
      )}
    </>
  )
}

export default TextFieldActionButtons
