import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  ProjectUser,
  useProjectUserListQuery,
} from '@shared/api/rtkQuery'
import TrialUser from '@shared/assets/images/trial/trialUser.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { SearchBar } from '@shared/ui/searchInput'
import { DataTable, TableColumn } from '@shared/ui/table'

type RouteState = {
  page: number
  perPage: number
  search: string
}

// 使用函式可以確保在建立 routeState 有填入必要的參數
const createRouteState = ({ page, perPage, search }: RouteState) => ({
  page,
  perPage,
  search,
})

const List = () => {
  const { t } = useTranslation(['common', 'iam'])

  const {
    pathname,
    state: routeState = createRouteState({
      page: 1,
      perPage: 10,
      search: '',
    }),
  } = useLocation<RouteState>()

  const {
    data: projectUserList = INITIAL_PAGINATION_RESPONSE,
    isLoading: isLoadingProjectUserList,
  } = useProjectUserListQuery({
    page: routeState.page,
    perPage: routeState.perPage,
    search: routeState.search,
  })

  const history = useHistory()

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.iam.user.introduction
  )

  const onRouteStateChange = (
    field: keyof RouteState,
    value: string | number
  ) => {
    history.push(
      `${pathname}`,
      createRouteState({
        ...routeState,
        [field]: value,
      })
    )
  }

  const columns: TableColumn<
    Pick<ProjectUser, 'email' | 'lastActivity' | 'createdAt' | 'createdBy'>
  >[] = [
    { headerName: 'email', field: 'email' },
    {
      headerName: 'last_activity',
      field: 'lastActivity',
      valueFormatter: day =>
        getIntSafe(day) === 0 ? '-' : t('iam:user.n_days', { day }),
    },
    {
      headerName: 'create_time',
      field: 'createdAt',
      valueFormatter: date => formatDateTimeDisplay(date as string),
    },
    { headerName: 'creator', field: 'createdBy' },
  ]

  return (
    <>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('iam:user.instruction.user_instruction_title')}
          description={t('iam:user.instruction.user_instruction_description')}
          notice={t('iam:user.instruction.user_instruction_notice')}
          imageUrl={TrialUser}
        />
      )}

      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Box>{t('common:route.iam_user')}</Box>
        </Breadcrumbs>
        <Link to={`/${CATEGORY.userCenter}/${PAGE_ROOT.userProfile}/new`}>
          <DeepBlueGradientButton width={200}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Link>
      </Box>

      <Box display="flex" justifyContent="flex-end" mb={2}>
        <SearchBar
          key={routeState.search}
          defaultValue={routeState.search}
          placeholder={t('iam:user.search_bar_placeholder')}
          onSearch={search => onRouteStateChange('search', search)}
        />
      </Box>

      <DataTable
        columns={columns}
        rows={projectUserList.items}
        count={projectUserList.totalCount}
        isLoading={isLoadingProjectUserList}
        page={routeState.page - 1}
        rowsPerPage={routeState.perPage}
        isRowClickable
        isShowCopyrightWhenLoading
        onPageChange={page => onRouteStateChange('page', page + 1)}
        onRowsPerPageChange={perPage => onRouteStateChange('perPage', perPage)}
      />
    </>
  )
}

export default List
