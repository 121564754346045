import camelcaseKeys from 'camelcase-keys'

import cloneDeep from '@shared/lib/utils/cloneDeep'
import getJsonSafe from '@shared/lib/utils/getJsonSafe'

import {
  type PaginationParams,
  type PaginationResponse,
  api,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  ContentTemplate,
  ContentTemplateFromApi,
  CoolBeWyethTemplate,
  CreateContentTemplate,
  ImageUploadUrl,
  MaacTemplate,
  MediumType,
  MessageSender,
  OmnichatTemplate,
  PublicImage,
  SendEmail,
  Super8Template,
  UpdateContentTemplate,
} from './type'

const transformResponseContentTemplate = (
  data: ContentTemplateFromApi
): ContentTemplate => {
  switch (data.medium_type) {
    case 'email': {
      const { editorJson } = getJsonSafe<{
        editorJson: ContentTemplate['editorJson']
      }>(data.body)

      return {
        ...camelcaseKeys(data, {
          deep: true,
        }),
        editorJson,
      }
    }
    // 若是將 replacement data 裡的東西轉成 camel case 反而會造成比對困難，像是 action_url_1
    default: {
      const replacementData = cloneDeep(data.replacement_data || {})
      return {
        ...camelcaseKeys(data, {
          deep: true,
        }),
        replacementData: { ...replacementData },
      }
    }
  }
}

const transformResponseContentTemplateList = (data: {
  items: ContentTemplateFromApi[]
  totalCount: number
}) => ({
  items: data.items.map(transformResponseContentTemplate),
  totalCount: data.totalCount,
})

const contentTemplateApi = api.injectEndpoints({
  endpoints: builder => ({
    contentTemplateList: builder.query<
      ReturnType<typeof transformResponseContentTemplateList>,
      PaginationParams & { mediumTypes?: MediumType[]; title?: string }
    >({
      query: params => ({
        url: '/medium_templates',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponseContentTemplateList,
      providesTags: ['ContentTemplate'],
    }),
    contentTemplate: builder.query<
      ReturnType<typeof transformResponseContentTemplate>,
      number
    >({
      query: id => `/medium_templates/${id}`,
      transformResponse: transformResponseContentTemplate,
      providesTags: ['ContentTemplateSingle'],
    }),
    createContentTemplate: builder.mutation<
      ContentTemplateFromApi,
      CreateContentTemplate
    >({
      query: body => ({
        url: '/medium_templates',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ContentTemplate'],
    }),
    updateContentTemplate: builder.mutation<void, UpdateContentTemplate>({
      query: ({ id, body }) => ({
        url: `/medium_templates/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ContentTemplate'],
    }),
    deleteContentTemplateById: builder.mutation<void, number>({
      query: id => ({
        url: `/medium_templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ContentTemplate'],
    }),
    sendTestingEmail: builder.mutation<void, SendEmail>({
      query: body => ({
        url: '/medium_templates/testing_email',
        method: 'POST',
        body,
      }),
    }),
    getImageUploadUrl: builder.mutation<ImageUploadUrl, void>({
      query: () => ({
        url: '/medium_templates/images',
        method: 'POST',
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ImageUploadUrl),
    }),
    setImagePublic: builder.mutation<
      PublicImage,
      { contentType: string; imagePath: string }
    >({
      query: body => ({
        url: '/medium_templates/images/publish',
        method: 'PUT',
        body,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as PublicImage),
    }),
    getMaacTemplateList: builder.query<
      PaginationResponse<MaacTemplate>,
      PaginationParams & { integrationId: number }
    >({
      query: params => ({
        url: '/maac/templates',
        params,
        responseHandler: paginationResponseHandler,
      }),
      providesTags: ['ContentTemplateMaac'],
    }),
    getDefaultMessageSender: builder.query<MessageSender, void>({
      query: () => ({
        url: '/msg_senders/default',
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as MessageSender),
    }),
    getSuper8TemplateList: builder.query<
      PaginationResponse<Super8Template>,
      PaginationParams & { integrationId: number }
    >({
      query: params => ({
        url: '/super8/templates',
        params,
        responseHandler: paginationResponseHandler,
      }),
      providesTags: ['ContentTemplateSuper8'],
    }),
    getCoolBeWyethTemplateList: builder.query<
      CoolBeWyethTemplate[],
      {
        integrationId: number
        typeTemplate: 'system_template' | 'combined_template'
      }
    >({
      query: params => ({
        url: '/coolbe_wyeth/templates',
        params,
      }),
      providesTags: ['ContentTemplateCoolBeWyeth'],
    }),
    getOmnichatGroupList: builder.query<
      OmnichatTemplate[],
      { integrationId: number }
    >({
      query: params => ({
        url: '/omnichat/template_groups',
        params,
      }),
      providesTags: ['ContentTemplateOmnichatGroup'],
    }),
    getOmnichatTemplateList: builder.query<
      OmnichatTemplate[],
      { integrationId: number; groupId: string }
    >({
      query: ({ groupId, integrationId }) => ({
        url: `/omnichat/templates/${groupId}`,
        params: {
          integrationId,
        },
      }),
      providesTags: ['ContentTemplateOmnichat'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateContentTemplateMutation,
  useContentTemplateListQuery,
  useContentTemplateQuery,
  useDeleteContentTemplateByIdMutation,
  useGetDefaultMessageSenderQuery,
  useGetImageUploadUrlMutation,
  useSetImagePublicMutation,
  useGetMaacTemplateListQuery,
  useSendTestingEmailMutation,
  useUpdateContentTemplateMutation,
  useGetSuper8TemplateListQuery,
  useGetCoolBeWyethTemplateListQuery,
  useGetOmnichatTemplateListQuery,
  useGetOmnichatGroupListQuery,
} = contentTemplateApi
