import type { ParseKeys } from 'i18next'

import type { IamServiceModuleName } from '@shared/api/rtkQuery'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON, IconClassName } from '@shared/model/constants/styles'

// 用 CDPRoute 比較不會跟 React-route-dom 撞名
export type CDPRoute = {
  path: string
  text: ParseKeys<'common'>
  badgeText?: string
  requiredIAMs?: IamServiceModuleName[]
}

export type SidebarCategory = {
  category: string
  text: ParseKeys<'common'>
  icon: IconClassName
  routes: CDPRoute[]
  requiredIAMs?: IamServiceModuleName[]
}

// ROUTES_BY_CATEGORY 裡的 obj 的 category 只有 UI 顯示會用到
export const ROUTES_BY_CATEGORY: SidebarCategory[] = [
  {
    category: CATEGORY.cdm,
    text: 'route.category.customer_data_management',
    icon: ICON.userSquare,
    routes: [
      {
        path: `/${CATEGORY.cdm}/${PAGE_ROOT.userProfile}`,
        text: 'route.user_profile',
        badgeText: 'NEW',
      },
      {
        path: `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`,
        text: 'route.audience_list',
      },
      {
        path: `/${CATEGORY.cdm}/${PAGE_ROOT.tags}/grading`,
        text: 'route.tag_list',
      },
      {
        path: `/${CATEGORY.cdm}/${PAGE_ROOT.tagGroups}`,
        text: 'route.tag_group_list',
      },
    ],
  },
  {
    category: CATEGORY.ma,
    text: 'route.category.marketing_automation',
    icon: ICON.megaphone,
    routes: [
      {
        path: `/${CATEGORY.ma}/${PAGE_ROOT.workflows}`,
        text: 'route.workflow',
      },
      {
        path: `/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.workflowInsight}`,
        text: 'route.workflow_insight',
      },
      {
        path: `/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.campaignInsight}`,
        text: 'route.campaign_insight',
      },
      {
        path: `/${CATEGORY.ma}/${PAGE_ROOT.contentSetting}`,
        text: 'route.content_setting',
      },
    ],
  },
  {
    category: CATEGORY.analytics,
    text: 'route.category.analytics',
    icon: ICON.chart,
    routes: [
      {
        path: `/${CATEGORY.analytics}/${PAGE_ROOT.rfm}`,
        text: 'route.rfm',
      },
      {
        path: `/${CATEGORY.analytics}/${PAGE_ROOT.dashboard}`,
        text: 'route.dashboard',
      },
      {
        path: `/${CATEGORY.analytics}/${PAGE_ROOT.events}`,
        text: 'route.analytics_events',
      },
      {
        path: `/${CATEGORY.analytics}/${PAGE_ROOT.funnel}`,
        text: 'route.analytics_funnel',
      },
      {
        path: `/${CATEGORY.analytics}/${PAGE_ROOT.notificationRule}`,
        text: 'route.notification_rule',
      },
    ],
  },
  {
    category: CATEGORY.dataImport,
    text: 'route.category.data_import',
    icon: ICON.database,
    routes: [
      {
        path: `/${CATEGORY.dataImport}/${PAGE_ROOT.v3.importUsers}`,
        text: 'route.v3.import_users',
      },
      {
        path: `/${CATEGORY.dataImport}/${PAGE_ROOT.v3.importEvents}`,
        text: 'route.v3.import_events',
      },
      {
        path: `/${CATEGORY.dataImport}/${PAGE_ROOT.v3.importTags}`,
        text: 'route.v3.import_tags',
      },
      {
        path: `/${CATEGORY.dataImport}/${PAGE_ROOT.v3.importDimensionTables}`,
        text: 'route.v3.import_dimension_tables',
      },
    ],
  },
  {
    category: CATEGORY.aiMarketingAssistant,
    text: 'route.category.ai_marketing_assistant',
    icon: ICON.robot,
    requiredIAMs: ['intelligent_analysis'],
    routes: [
      {
        path: `/${CATEGORY.aiMarketingAssistant}/${PAGE_ROOT.aiMarketingAssistant.socialMediaLabelAnalysis}`,
        text: 'route.social_media_label_analysis',
      },
      {
        path: `/${CATEGORY.aiMarketingAssistant}/${PAGE_ROOT.aiMarketingAssistant.kolRecommend}`,
        text: 'route.kol_recommend',
      },
      {
        path: `/${CATEGORY.aiMarketingAssistant}/${PAGE_ROOT.aiMarketingAssistant.autoTagEstablish}`,
        text: 'route.auto_tag_establish',
      },
      {
        path: `/${CATEGORY.aiMarketingAssistant}/${PAGE_ROOT.aiMarketingAssistant.autoTagAnalysis}`,
        text: 'route.auto_tag_analysis',
      },
    ],
  },
  {
    category: CATEGORY.settings,
    text: 'route.category.settings',
    icon: ICON.channel,
    routes: [
      {
        path: `/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}`,
        text: 'route.applications',
      },
      {
        path: `/${CATEGORY.settings}/${PAGE_ROOT.thirdParty}`,
        text: 'route.third_party',
      },
    ],
  },
  {
    category: CATEGORY.usageManagement,
    text: 'route.category.usage_management',
    icon: ICON.bill,
    routes: [
      {
        path: `/${CATEGORY.usageManagement}/${PAGE_ROOT.sendingAmount}`,
        text: 'route.sending_amount',
      },
    ],
  },
]
