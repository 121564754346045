import { useTranslation } from 'react-i18next'

import { ICON } from '@shared/model/constants/styles'
import type { BarChartDisplayType } from '@shared/ui/charts'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { RadioButton, RadioButtonWrapper } from './styles'

type BarChartDisplayTypeRadioButtonsProps = {
  displayType: BarChartDisplayType
  onDisplayTypeChange: (displayType: BarChartDisplayType) => void
}

export const BarChartDisplayTypeRadioButtons = ({
  displayType,
  onDisplayTypeChange,
}: BarChartDisplayTypeRadioButtonsProps) => {
  const { t } = useTranslation(['dateAndChart'])

  return (
    <RadioButtonWrapper>
      <Tooltip title={t('dateAndChart:charts.bar')}>
        <RadioButton
          isActive={displayType === 'bar'}
          onClick={() => onDisplayTypeChange('bar')}
        >
          <Icon
            icon={ICON.signalAlt3}
            color={
              displayType === 'bar'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </RadioButton>
      </Tooltip>
      <Tooltip title={t('dateAndChart:charts.bar_ratio')}>
        <RadioButton
          isActive={displayType === 'percentage'}
          onClick={() => onDisplayTypeChange('percentage')}
        >
          <Icon
            icon={ICON.percentage}
            color={
              displayType === 'percentage'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </RadioButton>
      </Tooltip>
    </RadioButtonWrapper>
  )
}

export default BarChartDisplayTypeRadioButtons
