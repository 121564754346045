import { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import type { SpacingProps } from '@mui/system'

import Card from '@shared/ui/Card'

type Props = {
  imageURL: string
  title: string
  description: React.ReactNode
  cardDetail?: React.ReactNode
} & SpacingProps

export const ExportCard = ({
  imageURL,
  title,
  description,
  cardDetail,
  children,
  ...spacingProps
}: PropsWithChildren<Props>) => {
  return (
    <Card
      py={2}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...spacingProps}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 96,
        }}
      >
        <Box
          component="img"
          src={imageURL}
          alt="third-party-group-logo"
          sx={{ width: 40, height: 40, ml: 1, mr: 3 }}
        />

        <Box
          flexGrow={1}
          sx={{ color: theme => theme.colors.textSecondBlue, mr: 4 }}
        >
          <Box
            component="p"
            sx={{
              color: theme => theme.colors.lightNavyBlue,
              fontSize: 16,
              fontWeight: 500,
            }}
          >
            {title}
          </Box>

          {description}
        </Box>

        {children}
      </Box>

      {cardDetail}
    </Card>
  )
}

export default ExportCard
