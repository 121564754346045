import {
  type AudienceFilterNode,
  CampaignEventMediumTypeFilterNode,
} from '@shared/api/rtkQuery'
import { type AudienceListCreated } from '@shared/model/types/amplitude'

export const formatAudienceFilter = (
  source: AudienceFilterNode
): AudienceListCreated => {
  return source.filters.reduce<AudienceListCreated>(
    (acc, filter) => {
      if (filter.type === 'event') {
        const eventNameList = filter.node.nodes.flatMap(group =>
          group.nodes.map(node => node.eventName)
        )
        acc.eventFilter.push(...eventNameList)

        return acc
      }

      if (filter.type === 'campaign_event') {
        const mediumTypeList = filter.node.nodes.flatMap(group =>
          group.nodes.flatMap(node =>
            node.attributeFilter.nodes.flatMap(attr =>
              (
                attr.nodes.filter(
                  x => x.type === 'condition' && x.field === 'medium_type'
                ) as CampaignEventMediumTypeFilterNode[]
              ).flatMap(x => x.params)
            )
          )
        )
        acc.campaignEventFilter.push(...mediumTypeList)

        return acc
      }

      if (filter.type === 'user_profile_tag') {
        const userProfileTagList = filter.node.nodes.flatMap(group =>
          group.nodes.map(node => ({
            field: node.field,
            operator: node.operator,
            params: node.params,
          }))
        )
        acc.userProfileTagFilter.push(...userProfileTagList)

        return acc
      }

      return acc
    },
    { eventFilter: [], campaignEventFilter: [], userProfileTagFilter: [] }
  )
}
