import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import type { Workflow } from '@shared/api/rtkQuery'
import {
  getCategoriesOfClassification,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { Z_INDEX } from '@shared/model/constants/styles'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import NestedList, { NestedOption } from '@shared/ui/Dropdown/NestedList'

/**
 * 對應欄位
 * conversionEvent: classification event
 * conversionValue: eventProperties
 */

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
} & Pick<
  Workflow,
  'conversionEventEnable' | 'conversionEvent' | 'conversionValue'
>

const MaCampaignConversionConfiguration = ({
  conversionEventEnable,
  conversionEvent,
  conversionValue,
  onChange,
}: Props) => {
  const { t } = useTranslation(['workflow'])
  const {
    eventEntity,
    isLoading: isMetadataLoading,
    isErrorEventEntity,
  } = useMetadataQuery()

  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )

  // set default value
  useEffect(() => {
    if (!isMetadataLoading && conversionEventEnable) {
      if (!conversionEvent && !isErrorEventEntity) {
        const { classification } = eventEntity

        const defaultEvent =
          classification.entities[classification.ids[0]].events[0]

        onChange({
          target: {
            name: 'conversionEvent',
            value: defaultEvent,
          },
        } as React.ChangeEvent<HTMLInputElement>)
      }
    }
  }, [
    onChange,
    isMetadataLoading,
    isErrorEventEntity,
    eventEntity,
    conversionValue,
    conversionEvent,
    conversionEventEnable,
  ])

  const conversionValueOptions: NestedOption[] = useMemo(() => {
    const currentEvent = eventEntity.events.entities[conversionEvent]

    const extraConversionValueOptions = currentEvent?.propertiesIds
      /** 過濾只有INTEGER/FLOAT 且 tableName是'events' 因為目前只會支援events table */
      .filter(propertyId => {
        return (
          currentEvent.propertiesEntity[propertyId].tableName === 'events' &&
          (currentEvent.propertiesEntity[propertyId].dataType === 'INTEGER' ||
            currentEvent.propertiesEntity[propertyId].dataType === 'FLOAT')
        )
      })
      .map(propertyId => {
        const { displayName: label, id: value } =
          currentEvent.propertiesEntity[propertyId]

        return {
          label,
          value,
        }
      })

    return extraConversionValueOptions || []
  }, [eventEntity.events, conversionEvent])

  return (
    <Box>
      <Box mb={1.5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={conversionEventEnable}
              color="primary"
              name="conversionEventEnable"
              onChange={onChange}
            />
          }
          label={
            <Box component="span" sx={{ fontSize: 14 }} fontWeight={600}>
              {t('workflow:conversion_configuration_event')}
            </Box>
          }
        />
        <CategoryList
          categories={categoriesOfClassification}
          selectedValue={conversionEvent}
          isDisabled={isMetadataLoading || !conversionEventEnable}
          popperZIndex={Z_INDEX.dialog}
          onValueChanged={id => {
            onChange({
              target: {
                name: 'conversionEvent',
                value: id,
              },
            } as React.ChangeEvent<HTMLInputElement>)

            onChange({
              target: {
                name: 'conversionValue',
                value: '',
              },
            } as React.ChangeEvent<HTMLInputElement>)
          }}
        />
      </Box>

      {conversionValueOptions.length > 0 && (
        <Box display="flex" alignItems="center">
          <Box fontSize={14} fontWeight={600} ml={4} mr={2}>
            {t('workflow:conversion_configuration_event_value')}
          </Box>
          <NestedList
            options={conversionValueOptions}
            value={conversionValue}
            isDisabled={isMetadataLoading || !conversionEventEnable}
            hasSearchBar
            popperZIndex={Z_INDEX.dialog}
            onChange={({ value }) =>
              onChange({
                target: {
                  name: 'conversionValue',
                  value: value,
                },
              } as React.ChangeEvent<HTMLInputElement>)
            }
          />
        </Box>
      )}
    </Box>
  )
}

export default MaCampaignConversionConfiguration
