import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { CombinationButton } from '@features/filters/_shared'
import AttributeFilter from '@features/filters/AttributeFilter'
import type { Aggregator, StatisticMeasure } from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  getCategoriesOfClassification,
  resolveMetadataPropertyId,
  useCategoriesOfMetadata,
  useGetMetadataProperty,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { ICON } from '@shared/model/constants/styles'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import {
  checkIsPredefinedField,
  useAggregatorOptions,
} from '@widgets/analytics/_shared'

import {
  STATISTIC_MEASURE_FILTER_MAX_COUNT,
  STATISTIC_MEASURES_MAX_COUNT,
} from '../../models/constants'
import type { EventRuleFilterAction } from '../../models/eventRuleFilterReducer'
import {
  AttributeFilterContainer,
  AttributeFilterList,
  EventContainer,
  EventSelector,
} from './styles'

type Props = {
  state: StatisticMeasure
  currIndex: number
  isDeletable: boolean
  isReplicable: boolean
  dispatch: (action: EventRuleFilterAction) => void
}

const StatisticMeasureFilter = ({
  state,
  currIndex,
  isDeletable,
  isReplicable,
  dispatch,
}: Props) => {
  const { eventEntity } = useMetadataQuery()

  const { t } = useTranslation(['common', 'analytics', 'metadata', 'audience'])

  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )
  const categoriesOfMetadata = useCategoriesOfMetadata(state.eventName)

  const getMetadataProperty = useGetMetadataProperty(state.eventName)

  const aggregatorOptions = useAggregatorOptions(state.eventName)

  const isAttributeFilterFull =
    state.attributeFilter.nodes.length >= STATISTIC_MEASURE_FILTER_MAX_COUNT

  return (
    <>
      <EventContainer>
        {/** 選擇事件 */}
        <EventSelector>
          <Box display="inline" mr={1}>
            <CategoryList
              categories={categoriesOfClassification}
              selectedValue={state.eventName}
              onValueChanged={targetEventName => {
                dispatch({
                  type: 'updateEventName',
                  payload: {
                    targetIndex: currIndex,
                    eventName: targetEventName,
                  },
                })
              }}
            />
          </Box>
          {state.eventName && (
            <>
              {/* // 選擇事件屬性 */}
              <Box display="inline" mr={1}>
                <NestedList
                  options={aggregatorOptions}
                  parentValue={
                    checkIsPredefinedField(state.field)
                      ? undefined
                      : createMetadataPropertyId(state.source, state.field)
                  }
                  value={state.aggregator}
                  hasSearchBar
                  onChange={({
                    parentValue: propertyId = '',
                    value: aggregator,
                  }) => {
                    const { source, field } =
                      resolveMetadataPropertyId(propertyId)

                    dispatch({
                      type: 'updateAggregator',
                      payload: {
                        aggregator: aggregator as Aggregator,
                        field,
                        source,
                        targetIndex: currIndex,
                      },
                    })
                  }}
                />
              </Box>
              <Tooltip
                title={
                  isAttributeFilterFull
                    ? t('audience:v3.filter_max_hint_condition', {
                        max: STATISTIC_MEASURE_FILTER_MAX_COUNT,
                      })
                    : t('audience:v3.filter_add')
                }
              >
                {/** 為了讓 IconButton 被 disabled 時能夠正常顯示 tooltip */}
                <Box display="inline-block">
                  <IconButton
                    disabled={isAttributeFilterFull}
                    onClick={() => {
                      const [firstCategory] = categoriesOfMetadata
                      if (firstCategory && firstCategory.options.length > 0) {
                        const [{ value: propertyId }] = firstCategory.options

                        const { source, field } =
                          resolveMetadataPropertyId(propertyId)

                        const { dataType, repeated } = getMetadataProperty(
                          source,
                          field
                        )

                        dispatch({
                          type: 'addAttributeFilter',
                          payload: {
                            source,
                            field,
                            dataType,
                            repeated,
                            parentIndex: currIndex,
                          },
                        })
                      }
                    }}
                  >
                    <Icon
                      icon={ICON.plusCircle}
                      color={theme.colors.textPrimaryBlue}
                    />
                  </IconButton>
                </Box>
              </Tooltip>
            </>
          )}
          {state.eventName && (
            <Tooltip
              title={
                isReplicable
                  ? t('common:copy')
                  : t('audience:v3.filter_max_hint_event_analytics', {
                      max: STATISTIC_MEASURES_MAX_COUNT,
                    })
              }
            >
              <Box display="inline-block">
                <IconButton
                  disabled={!isReplicable}
                  onClick={() =>
                    dispatch({
                      type: 'duplicateStatisticMeasuresItem',
                      payload: { index: currIndex },
                    })
                  }
                >
                  <Icon icon={ICON.copy} color={theme.colors.textPrimaryBlue} />
                </IconButton>
              </Box>
            </Tooltip>
          )}
          {isDeletable && (
            <IconButton
              onClick={() => {
                dispatch({
                  type: 'removeStatisticMeasuresItem',
                  payload: { index: currIndex },
                })
              }}
            >
              <Icon icon={ICON.times} />
            </IconButton>
          )}
        </EventSelector>
      </EventContainer>

      {state.eventName !== '' && state.attributeFilter.nodes.length > 0 && (
        <AttributeFilterContainer>
          <Box mr={1}>
            <Tooltip title={t('audience:v3.filter_toggle')}>
              <CombinationButton
                onClick={() => {
                  dispatch({
                    type: 'toggleAttributeFilterCombination',
                    payload: { index: currIndex },
                  })
                }}
              >
                {state.attributeFilter.combination === 'and'
                  ? t('audience:v3.and')
                  : t('audience:v3.or')}
              </CombinationButton>
            </Tooltip>
          </Box>
          <AttributeFilterList>
            {state.attributeFilter.nodes.map((node, index) => (
              <Box
                key={index}
                sx={{ display: 'flex', alignItems: 'center', my: 1 }}
              >
                <Box mr={1}>
                  <AttributeFilter
                    categories={categoriesOfMetadata}
                    eventName={state.eventName}
                    field={node.field}
                    source={node.source}
                    operator={node.operator}
                    dimOperator={node.dimOperator}
                    params={node.params}
                    onChange={currValue => {
                      dispatch({
                        type: 'updateAttributeFilter',
                        payload: {
                          parentIndex: currIndex,
                          targetIndex: index,
                          ...currValue,
                        },
                      })
                    }}
                  />
                </Box>
                <Tooltip
                  title={
                    isAttributeFilterFull
                      ? t('audience:v3.filter_max_hint_condition', {
                          max: STATISTIC_MEASURE_FILTER_MAX_COUNT,
                        })
                      : t('common:copy')
                  }
                >
                  <Box display="inline-block">
                    <IconButton
                      disabled={isAttributeFilterFull}
                      onClick={() =>
                        dispatch({
                          type: 'duplicateAttributeFilter',
                          payload: {
                            parentIndex: currIndex,
                            targetIndex: index,
                          },
                        })
                      }
                    >
                      <Icon
                        icon={ICON.copy}
                        color={theme.colors.textPrimaryBlue}
                      />
                    </IconButton>
                  </Box>
                </Tooltip>

                <IconButton
                  onClick={() => {
                    dispatch({
                      type: 'removeAttributeFilter',
                      payload: { parentIndex: currIndex, targetIndex: index },
                    })
                  }}
                >
                  <Icon icon={ICON.times} />
                </IconButton>
              </Box>
            ))}
          </AttributeFilterList>
        </AttributeFilterContainer>
      )}
    </>
  )
}

export default StatisticMeasureFilter
