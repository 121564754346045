import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  AudienceRuleFormValues,
  useGetAudienceRuleQuery,
  usePatchUpdateAudienceRuleMutation,
} from '@shared/api/rtkQuery/audience'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { Tooltip } from '@shared/ui/tooltips'

import AudienceForm from './AudienceForm'

const NO_DATA_ID = 0

const AudienceEdit = () => {
  const { t } = useTranslation(['audience', 'common'])

  const { id } = useParams<{ id: string }>()
  const audienceRuleId = getIntSafe(id, NO_DATA_ID)

  const { data: audienceRule, isLoading: isFetchingAudienceRule } =
    useGetAudienceRuleQuery(audienceRuleId, {
      skip: audienceRuleId === NO_DATA_ID,
    })

  const [updateAudienceRule, { isLoading: isSubmitting }] =
    usePatchUpdateAudienceRuleMutation()

  const history = useHistory()
  const dispatch = useAppDispatch()

  const handleSubmit = (formValues: AudienceRuleFormValues) => {
    const id = audienceRule?.id
    if (id) {
      updateAudienceRule({ id, ...formValues })
        .unwrap()
        .then(() => {
          history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${id}`)
        })
        .catch(error => {
          const formatError = formatRTKQueryError(error)

          dispatch(handleApiError(formatError))
        })
    }
  }

  const overviewLink = `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`

  return (
    <>
      <Breadcrumbs>
        <Link to={overviewLink}>{t('common:route.audience_list')}</Link>
        {audienceRule?.title && (
          <Tooltip title={audienceRule.title}>
            <Box>{audienceRule.title}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>

      <AudienceForm
        formValues={audienceRule}
        isLoading={isFetchingAudienceRule || !audienceRule}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default AudienceEdit
