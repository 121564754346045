import styled from '@emotion/styled'
import { spacing, SpacingProps } from '@mui/system'

import { LAYOUT } from '@shared/model/constants/styles'

export const PrimaryGreyTextarea = styled.textarea<
  {
    isDisableFocusBorder?: boolean
    isError?: boolean
    minHeight?: number
    resize?: string
  } & SpacingProps
>`
  display: block;
  padding: 8px 16px;

  width: 100%;
  min-height: ${({
    // 96 大約是四行文字的高度
    minHeight = 96,
  }) => minHeight}px;

  border: ${({ isDisableFocusBorder }) =>
    isDisableFocusBorder ? 'none' : '1px solid transparent'};
  border-color: ${({ isError, theme }) =>
    isError ? theme.colors.orangeyRed : 'transparent'};
  border-radius: ${LAYOUT.audienceBuilder.inputBorderRadius}px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.veryLightBlue : theme.colors.bgPrimaryGrey};
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  resize: ${({ resize }) => (resize ? resize : 'both')};

  :focus {
    border-color: ${({ theme, isError }) =>
      isError ? theme.colors.orangeyRed : theme.colors.brightBlue};
    outline: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSecondBlue};
    opacity: 1;
  }

  ${spacing}
`

export default PrimaryGreyTextarea
