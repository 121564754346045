import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import LoadingContainer from '@entities/Loader/LoadingContainer'
import {
  AUDIENCE_PREVIEWER_STICKY_TOP,
  AudiencePreviewer,
  AudiencePreviewerField,
} from '@entities/previewers'
import AudienceFilter, {
  useAudienceFilter,
} from '@features/filters/AudienceFilter'
import {
  type AudienceRuleFormValues,
  type AudienceRulePreview,
  type SchedulingType,
  useLazyGetAudienceRulePreviewQuery,
} from '@shared/api/rtkQuery'
import { formatAudienceFilter, trackEvent } from '@shared/lib/utils/amplitude'
import { formatCurrency, formatNumber } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import Card from '@shared/ui/Card'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import AudienceFormConfirmDialog from './AudienceFormConfirmDialog'

const INIT_DATA: AudienceRulePreview = {
  usersCount: 0,
  totalCount: 0,
  pltv180DAvg: undefined,
  pltv30DAvg: undefined,
  arpu180D: undefined,
}

type Props = {
  formValues?: AudienceRuleFormValues
  isLoading: boolean
  isSubmitting: boolean
  onSubmit: (payload: AudienceRuleFormValues) => void
}

const AudienceForm = ({
  formValues,
  isLoading,
  isSubmitting,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'audience', 'rfm'])

  const [{ title, schedulingType }, setState] = useState<{
    title: string
    schedulingType: SchedulingType
  }>({
    title: '',
    schedulingType: 'none',
  })

  const {
    values: audienceFilter,
    onDispatch: onAudienceFilterDispatch,
    onReset: onAudienceFilterReset,
  } = useAudienceFilter()

  const [
    getAudienceRulePreview,
    {
      data: {
        usersCount,
        totalCount,
        pltv180DAvg,
        pltv30DAvg,
        arpu180D,
      } = INIT_DATA,
      isFetching: isFetchingAudienceRulePreview,
    },
  ] = useLazyGetAudienceRulePreviewQuery()

  const handleAudienceFilterPreview = async () => {
    try {
      const { usersCount = 0 } = await getAudienceRulePreview(
        audienceFilter
      ).unwrap()

      trackEvent('AudienceListPreviewed', {
        audienceCount: usersCount,
        ...formatAudienceFilter(audienceFilter),
      })
    } catch (error) {
      console.error('Failed to preview audience filter', error)
    }
  }

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  useEffect(() => {
    if (!formValues) {
      onAudienceFilterReset()
      return
    }

    setState({
      title: formValues.title,
      schedulingType: formValues.schedulingType,
    })

    onAudienceFilterReset(formValues.filter)
  }, [formValues, onAudienceFilterReset])

  return (
    <LoadingContainer isLoading={isLoading}>
      <Box display="flex">
        <Card>
          <AudienceFilter
            state={audienceFilter}
            dispatch={onAudienceFilterDispatch}
          />
          {/* <pre>{JSON.stringify(audienceFilter, null, 4)}</pre> */}
        </Card>

        {/** sticky 需要 parent 可以滾動，因此將 marginLeft 另外寫 */}
        <Box sx={{ ml: 2 }}>
          <Box sx={{ position: 'sticky', top: AUDIENCE_PREVIEWER_STICKY_TOP }}>
            <AudiencePreviewer
              donutTitle={t('audience:of_users')}
              userCount={usersCount}
              totalCount={totalCount}
              isLoading={isFetchingAudienceRulePreview}
              isCreating={isSubmitting}
              onPreview={handleAudienceFilterPreview}
              onCreate={() => setIsShowConfirmDialog(true)}
            >
              <AudiencePreviewerField>
                <p>{t('rfm:population_alt')}</p>
                <p>
                  {isFetchingAudienceRulePreview
                    ? '-'
                    : formatNumber(usersCount)}
                </p>
              </AudiencePreviewerField>

              {arpu180D !== undefined && (
                <AudiencePreviewerField>
                  <Box display="flex">
                    {t('audience:v3.model.arpu_180d')}
                    <Tooltip title={t('audience:instruction.arpu_180d')}>
                      <Box ml="4px">
                        <Icon
                          color={theme.colors.textPrimaryBlue}
                          fontSize="small"
                          icon={ICON.infoCircle}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <p>
                    {isLoading
                      ? '-'
                      : formatCurrency(arpu180D, { fractionDigits: 0 })}
                  </p>
                </AudiencePreviewerField>
              )}

              {pltv30DAvg !== undefined && (
                <AudiencePreviewerField>
                  <Box display="flex">
                    {t('audience:v3.model.pltv_30d_avg')}
                    <Tooltip title={t('audience:instruction.pltv_30d_avg')}>
                      <Box ml="4px">
                        <Icon
                          color={theme.colors.textPrimaryBlue}
                          fontSize="small"
                          icon={ICON.infoCircle}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <p>
                    {isLoading
                      ? '-'
                      : formatCurrency(pltv30DAvg, { fractionDigits: 0 })}
                  </p>
                </AudiencePreviewerField>
              )}

              {pltv180DAvg !== undefined && (
                <AudiencePreviewerField>
                  <Box display="flex">
                    {t('audience:v3.model.pltv_180d_avg')}
                    <Tooltip title={t('audience:instruction.pltv_180d_avg')}>
                      <Box ml="4px">
                        <Icon
                          color={theme.colors.textPrimaryBlue}
                          fontSize="small"
                          icon={ICON.infoCircle}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <p>
                    {isLoading
                      ? '-'
                      : formatCurrency(pltv180DAvg, { fractionDigits: 0 })}
                  </p>
                </AudiencePreviewerField>
              )}
            </AudiencePreviewer>
          </Box>
        </Box>

        <AudienceFormConfirmDialog
          title={title}
          schedulingType={schedulingType}
          isShow={isShowConfirmDialog}
          isSubmitting={isSubmitting}
          onChange={payload => {
            setState({
              title: payload.name,
              schedulingType: payload.schedulingType,
            })
          }}
          onConfirm={() =>
            onSubmit({
              title,
              description: '',
              schedulingType,
              filter: audienceFilter,
            })
          }
          onCancel={() => setIsShowConfirmDialog(false)}
        />
      </Box>
    </LoadingContainer>
  )
}

export default AudienceForm
