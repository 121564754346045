import type { Option } from '@shared/ui/Dropdown/DropdownList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import theme from '@theme'

type CampaignTriggerGroupDropdownListProps = {
  disabled?: boolean
  error?: string
  onChange: (value: number) => void
  options: Option[]
  placeholder: string
  value?: number
}

const CampaignTriggerGroupDropdownList = ({
  disabled,
  error,
  onChange,
  options,
  placeholder,
  value,
}: CampaignTriggerGroupDropdownListProps) => {
  if (!disabled) {
    return (
      <DropdownList
        isFullWidth
        bgColor={theme.colors.white}
        options={options}
        value={value}
        isError={Boolean(error)}
        isPopupAlignAnchorWidth
        disabled={disabled}
        disabledPreselect
        defaultOption={{
          label: '',
          value: '',
        }}
        hasSearchBar
        placeholder={placeholder}
        popperDisablePortal
        onValueChanged={({ value }) => {
          onChange(value as number)
        }}
      />
    )
  }

  if (error) {
    return (
      <DropdownList
        isFullWidth
        color={theme.colors.orangeyRed}
        bgColor={theme.colors.white}
        isError
        options={[]}
        disabled
        disabledPreselect
        value={undefined}
        placeholder={error}
        onValueChanged={() => {}}
      />
    )
  }

  return (
    <DropdownList
      isFullWidth
      bgColor={theme.colors.white}
      options={options}
      value={value}
      disabled
      disabledPreselect
      placeholder={placeholder}
      onValueChanged={() => {}}
    />
  )
}

export default CampaignTriggerGroupDropdownList
