import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { TracingUrlInsight } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'

import TracingUrlsDialog from './TracingUrlsDialog'

type Props = {
  children: React.ReactNode
  tracingUrlInsights?: Array<TracingUrlInsight>
}

const TableCellWithHint = ({ children, tracingUrlInsights }: Props) => {
  const { t } = useTranslation(['common'])

  const [isOpen, setIsOpen] = useState(false)

  if (!tracingUrlInsights?.length) {
    return <>{children}</>
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Box>{children}</Box>
        <Box
          sx={{
            color: theme => theme.colors.brightBlue,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 0.5,
            cursor: 'pointer',
          }}
          onClick={() => setIsOpen(true)}
        >
          <span>{tracingUrlInsights.length}</span>
          <span>{t('common:route.ma_tracing_url')}</span>
          <span>
            <Icon color="inherit" fontSize="small" icon={ICON.link} />
          </span>
        </Box>
      </Box>

      {tracingUrlInsights && (
        <TracingUrlsDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          tracingUrlInsights={tracingUrlInsights}
        />
      )}
    </>
  )
}

export default TableCellWithHint
