import styled from '@emotion/styled'
import { spacing } from '@mui/system'

export const Container = styled.div`
  font-weight: 500;
`

export const TextFieldGrid = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr auto;
  grid-template-areas:
    'label input action'
    '.     error .';
  align-items: center;
  ${spacing}
`

export const TextFieldLabel = styled.div`
  grid-area: label;
  margin-right: 8px;
`

export const TextFieldInput = styled.div`
  grid-area: input;
`

export const TextFieldAction = styled.div`
  grid-area: action;
`

export const TextFieldError = styled.div`
  grid-area: error;
  color: ${({ theme }) => theme.colors.chartRed};
  line-height: 2;
`
