import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type TemplateEmail, EmailTemplate } from '@entities/mediumTemplates'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { REACT_FLOW_NO_DRAG } from '@widgets/workflow/_shared/nodes/_shared'

type Props = {
  onClose: () => void
  onConfirm: (template?: TemplateEmail) => void
}

const SelectEmailTemplateDialog = ({ onClose, onConfirm }: Props) => {
  const [template, setTemplate] = useState<TemplateEmail>()
  const { t } = useTranslation(['contentTemplate'])

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      isOpen
      maxWidth="md"
      modalTitle={t('contentTemplate:please_select_sms_content_template')}
      onConfirm={() => {
        onConfirm(template)
      }}
      onClose={onClose}
    >
      <EmailTemplate defaultTab="default" onSelect={setTemplate} />
    </ConfirmDialog>
  )
}

export default SelectEmailTemplateDialog
