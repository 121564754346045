import Box from '@mui/material/Box'

import {
  ProductsKeywordsAigcParams,
  useGetProductsKeywordsAigcListQuery,
} from '@shared/api/rtkQuery'
import { MAX_TAG_ANALYSIS_COUNT } from '@shared/model/constants/validation'
import DotLoader from '@shared/ui/loaders/DotLoader'

import ProductKeyword from './ProductKeyword'

type Props = ProductsKeywordsAigcParams & { isStartAnalysis: boolean }

const ProductKeywordOverview = ({
  productIdList = [],
  allProduct,
  isStartAnalysis,
}: Props) => {
  const { data: productKeywordList = [], isFetching } =
    useGetProductsKeywordsAigcListQuery(
      {
        productIdList,
        allProduct,
        // 因為有硬上限 300 筆，所以 productIdList 和 allProduct 需要套用
        pagination: {
          page: 1,
          perPage: MAX_TAG_ANALYSIS_COUNT,
        },
      },
      {
        skip: (!productIdList.length && !allProduct) || !isStartAnalysis,
      }
    )

  if (isFetching) {
    return (
      <Box
        width="100"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <DotLoader />
      </Box>
    )
  }

  return (
    <>
      {productKeywordList.map(product => (
        <ProductKeyword
          key={product.productId}
          name={product.name}
          keywords={product.keywords}
          extendKeywords={product.extendKeywords}
        />
      ))}
    </>
  )
}

export default ProductKeywordOverview
