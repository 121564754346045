import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  padding-top: 30vh;
  width: 100%;
  height: 100vh;
`

export const LogoWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const IKalaLogoIcon = styled.img`
  height: 47px;
`

export const FirebaseUIWidgetWrapper = styled.div<{ isHidden?: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`

export const Img = styled.img`
  position: absolute;
  top: 50px;
  height: 50vh;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10vh;
  width: 100%;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;

  a {
    margin: 0 2px;
    color: ${({ theme }) => theme.colors.lightNavyBlue};
  }
`

export const TextWrapper = styled.div`
  margin: 0px 4px;
  white-space: nowrap;
`
