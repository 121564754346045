import { Fragment, useEffect } from 'react'

export const ClickAwayListener = (props: {
  children: React.ReactNode
  anchorIds: string[]
  onClickAway: () => void
}) => {
  const { children, onClickAway, anchorIds } = props

  useEffect(() => {
    if (anchorIds.every(Boolean)) {
      const doc = window.document
      const anchorElements = anchorIds.map(id =>
        doc.querySelector<HTMLDivElement>(`#${id}`)
      )

      const handleClickAway = (event: MouseEvent) => {
        if (
          doc.documentElement &&
          doc.documentElement.contains(event.target as Node) &&
          !anchorElements.some(element =>
            element ? element.contains(event.target as Node) : false
          )
        ) {
          onClickAway()
        }
      }

      doc.addEventListener('click', handleClickAway)

      /*
        useEffect 要回傳把 eventListener 清掉
        否則 anchorIds 可能會記成上一次 hover 的 anchorIds
      */
      return () => {
        doc.removeEventListener('click', handleClickAway)
      }
    }
  }, [anchorIds, onClickAway])

  return <Fragment>{children}</Fragment>
}

export default ClickAwayListener
