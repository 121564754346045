import { DEFAULT_LABEL_WIDTH } from './models/constants'
import type { FieldProps } from './models/types'
import { PrimaryGreyInput, PrimaryGreyTextarea } from '../../inputs'
import * as Field from './styles'

export interface TextFieldProps extends FieldProps {
  multiline?: boolean
}

export const TextField = ({
  description,
  error,
  isDisabled,
  isFullWidth,
  label,
  labelWidth = DEFAULT_LABEL_WIDTH,
  multiline = false,
  name,
  onChange,
  placeholder,
  value,
}: TextFieldProps) => {
  const Component = multiline ? PrimaryGreyTextarea : PrimaryGreyInput

  return (
    <Field.Container>
      <Field.Label htmlFor={name} labelWidth={labelWidth}>
        {label}
      </Field.Label>

      <Field.Input>
        <Component
          disabled={isDisabled}
          fullWidth={isFullWidth}
          id={name}
          isError={Boolean(error)}
          name={name}
          onChange={({ target: { value } }) => onChange?.(value)}
          placeholder={placeholder}
          resize="none"
          value={value as string}
        />

        {description && <Field.Description>{description}</Field.Description>}

        {error && <Field.Error>{error}</Field.Error>}
      </Field.Input>
    </Field.Container>
  )
}

export default TextField
