import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import Card from '@shared/ui/Card'
import {
  useAudienceDetailSetState,
  useAudienceDetailState,
} from '@widgets/audience/lib/AudienceDetailContext'
import {
  CARD_MIN_HEIGHT,
  CHART_CARD_HEIGHT,
} from '@widgets/audience/model/constant'

import LoadingCard from '../LoadingCard'

type Props = {
  chartName: string
  subject: string
  title?: React.ReactNode
  nonMembers?: number | string
  dropdownListNode?: React.ReactNode
  children: React.ReactNode
  // 以下 api 狀態使用都參考 rtkQuery 定義
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}

const BasicCard = ({
  chartName,
  subject,
  title,
  nonMembers,
  dropdownListNode,
  children,
  isFetching,
  isError,
  isSuccess,
  isLoading,
}: Props) => {
  const { t } = useTranslation(['audience'])
  const { isEmptyAudience, isChartLoading } = useAudienceDetailState()
  const setAudienceDetailState = useAudienceDetailSetState()

  useEffect(() => {
    setAudienceDetailState(prev => ({
      ...prev,
      isChartLoading: prev.isChartLoading && isLoading,
      // 只要有任一個圖表回傳 success: true 代表設定任一種圖表
      hasChartSettings: prev.hasChartSettings || isSuccess,
      isChartError: {
        ...prev.isChartError,
        [chartName]: isError,
      },
    }))
  }, [isSuccess, isLoading, isError, setAudienceDetailState, chartName])

  if (isError || isEmptyAudience || isChartLoading) {
    return null
  }

  if (isFetching) {
    return <LoadingCard />
  }

  return (
    <Box width="48%" mb={2}>
      <Card>
        <Box height={CHART_CARD_HEIGHT}>
          <Box minHeight={CARD_MIN_HEIGHT}>
            <Box
              width="100%"
              mb={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={theme => ({
                color: theme.colors.blueyGrey,
              })}
            >
              <Box fontWeight={600} fontSize={16}>
                {subject}
              </Box>
              {nonMembers && (
                <Box
                  fontWeight={600}
                  fontSize={14}
                  whiteSpace="nowrap"
                  sx={theme => ({
                    color: theme.colors.textSecondBlue,
                  })}
                >
                  {t('audience:excluding_non_members', {
                    nonMembers,
                  })}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {title && (
                <Box
                  fontWeight={600}
                  fontSize={24}
                  sx={theme => ({
                    color: theme.colors.black,
                  })}
                  textOverflow="ellipsis"
                  overflow="hidden"
                  whiteSpace="nowrap"
                >
                  {title}
                </Box>
              )}
              {dropdownListNode}
            </Box>
          </Box>
          <Divider sx={{ mt: 1, mb: 3 }} />
          {children}
        </Box>
      </Card>
    </Box>
  )
}

export default BasicCard
