import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetOmnichatTemplateListQuery } from '@shared/api/rtkQuery'
import DropdownList from '@shared/ui/Dropdown/NestedList'
import {
  INIT_PAGE,
  LIST_PER_PAGE,
  REACT_FLOW_NO_DRAG,
} from '@widgets/workflow/_shared/nodes/_shared/constants'

import type { OmnichatConfig } from '../../actionSendLineNodeSchema'
import { selectOmnichatTemplateListOption } from './selector'

type Props = {
  groupId?: OmnichatConfig['groupId']
  templateId: OmnichatConfig['templateId']
  integrationId: number
  isEditable: boolean
  isError?: boolean
  setTemplateId: (id: OmnichatConfig['templateId']) => void
}

export const OmnichatTemplateSelect = memo(
  ({
    groupId = '',
    templateId,
    integrationId,
    isEditable,
    isError = false,
    setTemplateId,
  }: Props) => {
    const { t } = useTranslation(['workflow'])
    const [page, setPage] = useState<number>(INIT_PAGE)
    const [searchTxt, setSearchTxt] = useState<string>('')

    const { templateOption, isTemplateLoading } =
      useGetOmnichatTemplateListQuery(
        {
          integrationId,
          groupId,
        },
        {
          skip: !groupId,
          selectFromResult: selectOmnichatTemplateListOption,
        }
      )

    const dropdownListLabel = useMemo(() => {
      const template = templateOption.find(({ value }) => value === templateId)

      return (
        template?.label ??
        t('workflow:node_action_line_template_omnichat_robot_module')
      )
    }, [templateOption, t, templateId])

    const filteredOption = useMemo(
      () =>
        templateOption.filter(({ label }) =>
          label.toLowerCase().includes(searchTxt.toLocaleLowerCase())
        ),
      [templateOption, searchTxt]
    )

    const dropdownRobotOption = useMemo(() => {
      const startIndex = (page - 1) * LIST_PER_PAGE
      const endIndex = startIndex + LIST_PER_PAGE

      return filteredOption.slice(startIndex, endIndex)
    }, [page, filteredOption])

    const handleSearchTitleChange = (searchTxt: string) => {
      setSearchTxt(searchTxt)
      setPage(INIT_PAGE)
    }

    const handleOnGroupChange = ({ value }: { value: string }) =>
      setTemplateId(value)

    return (
      <DropdownList
        isError={isError}
        isFullWidth
        hasSearchBar
        isDisabled={isTemplateLoading || !isEditable || !groupId}
        label={dropdownListLabel}
        onChange={handleOnGroupChange}
        onFilterChange={handleSearchTitleChange}
        options={dropdownRobotOption}
        popperClassName={REACT_FLOW_NO_DRAG}
        popperDisablePortal
        value={templateId}
        isShowPagination
        placement="bottom-start"
        paginationProps={{
          totalCount: filteredOption.length,
          page: page - 1,
          rowsPerPage: LIST_PER_PAGE,
          onPageChange: (_event, page) => setPage(page + 1),
        }}
      />
    )
  }
)

export default OmnichatTemplateSelect
