import { createSelector } from '@reduxjs/toolkit'

import {
  INITIAL_PAGINATION_RESPONSE,
  MsgChannelList,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'

export const selectIntegrationTypeList = createSelector<
  [
    (state: { data?: MsgChannelList }) => MsgChannelList['items'],
    (state: { isLoading: boolean }) => boolean
  ],
  { integrationTypeList: ThirdPartyAppProvider[]; isLoading: boolean }
>(
  [
    state => (state.data ?? INITIAL_PAGINATION_RESPONSE).items,
    state => state.isLoading,
  ],
  (msgChannelList, isLoading) => ({
    integrationTypeList: Array.from(
      new Set(msgChannelList.map(item => item.integrationType))
    ),
    isLoading,
  })
)
