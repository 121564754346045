import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import Loader from '@entities/Loader'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { GCS_BUCKET } from '@shared/model/constants/gcs'
import { ICON } from '@shared/model/constants/styles'
import type {
  ResourceTypeAction,
  TagResourceType,
} from '@shared/model/types/ingestion'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'
import theme from '@theme'

import { MAX_FILE_SIZE } from '../_shared/constants'
import {
  DESCRIPTION_MAX_LENGTH,
  SUPPORTED_FILE_EXTENSION,
} from '../_shared/constants'
import { useBeforeUnload } from '../_shared/hook'
import FileUpload, { UploadStatus } from '../fileUpload'
import { tagResourceTypeActionTemplateMap } from './constants'
export type ImportButtonClickPayload = {
  description: string
  file: File
  action: ResourceTypeAction
  tagResourceType: TagResourceType
}

type Props = {
  tagResourceActionType: {
    [keyof in TagResourceType]?: ResourceTypeAction[]
  }
  onImportButtonClick: (params: ImportButtonClickPayload) => void
  buttonWord?: string
  defaultResourceActionType?: `${TagResourceType}.${ResourceTypeAction}` | ''
}

const ImportTagFile = ({
  tagResourceActionType,
  onImportButtonClick,
  buttonWord,
  defaultResourceActionType = '',
}: Props) => {
  const {
    t,
    i18n,
    ready: isI18nReady,
  } = useTranslation(['common', 'dataImport'])
  const [file, setFile] = useState<File | null>(null)
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('initial')
  const [description, setDescription] = useState<string>('')
  const [currResourceTypeAction, setCurrResourceTypeAction] = useState(
    defaultResourceActionType
  )

  const isCreatingIngestion = useAppSelector(
    state => state.infoBox.task.isCreatingIngestion
  )
  const taskErrorCode = useAppSelector(state => state.infoBox.task.errorCode)

  const dispatch = useAppDispatch()

  useBeforeUnload(file)

  useEffect(() => {
    if (uploadStatus === 'end' && file) {
      setDescription(file.name)
    }
  }, [dispatch, file, uploadStatus])

  const handleImportButtonClick = () => {
    if (file && description) {
      const [tagResourceType, action] = currResourceTypeAction.split('.')

      onImportButtonClick({
        description,
        file,
        action: action as ResourceTypeAction,
        tagResourceType: tagResourceType as TagResourceType,
      })

      setFile(null)
      setUploadStatus('initial')
    }
  }

  /**
   *  將 {tag_user: [...actions], behavior_tag_user:[...actions]}
   *  的結構轉成 { label: '...', value:`${resourceType}.${action}` }[]
   **/

  const resourceTypeActionOptions: Option[] = useMemo(
    () =>
      Object.keys(tagResourceActionType).reduce<Option[]>(
        (options, resourceType) => {
          const resourceTypeActions =
            tagResourceActionType?.[resourceType as TagResourceType] || []

          resourceTypeActions.forEach(action => {
            const option = {
              label: isI18nReady
                ? t(
                    `dataImport:resource_type_action.${resourceType}.${action}` as ParseKeys<
                      ['dataImport']
                    >
                  )
                : '',
              value: `${resourceType}.${action}`,
              tooltip: t(
                `dataImport:tooltip_hint.${resourceType}` as ParseKeys<
                  ['dataImport']
                >
              ),
            }

            options.push(option as Option)
          })

          return options
        },
        []
      ),
    [isI18nReady, t, tagResourceActionType]
  )

  const templateFile = currResourceTypeAction
    ? tagResourceTypeActionTemplateMap[currResourceTypeAction]
    : null

  return (
    <>
      <Box
        pb={4}
        display="flex"
        alignItems="center"
        sx={theme => ({
          borderBottom: `1px solid ${theme.colors.black6}`,
        })}
      >
        <DropdownList
          placeholder="請選擇要上傳的標籤類型"
          value={currResourceTypeAction}
          key={i18n.language}
          options={resourceTypeActionOptions}
          onValueChanged={option => {
            setCurrResourceTypeAction(
              option.value as `${TagResourceType}.${ResourceTypeAction}`
            )
            setFile(null)
            setUploadStatus('initial')
          }}
          marginRightRatio={2}
        />

        {templateFile && (
          <Box
            component="span"
            display="inline-flex"
            sx={theme => ({
              cursor: 'pointer',
              color: theme.colors.textPrimaryBlue,
              '& a:link': {
                textDecoration: 'underline',
              },
            })}
          >
            <Icon
              icon={ICON.paperclip}
              color={theme.colors.textPrimaryBlue}
              fontSize="small"
            />
            <a
              href={`${GCS_BUCKET.importDataTemplate}/${templateFile.filename}`}
              download
            >
              {templateFile.filename}
            </a>
          </Box>
        )}
      </Box>

      {currResourceTypeAction && (
        <Box mb={2} mt={5}>
          <FileUpload
            fileMaxSize={MAX_FILE_SIZE}
            fileExtension={SUPPORTED_FILE_EXTENSION}
            uploadStatus={uploadStatus}
            setUploadStatus={setUploadStatus}
            setFile={setFile}
          />
        </Box>
      )}

      {file && (
        <>
          <Box mb={1}>
            <Box
              sx={theme => ({
                color: theme.colors.textPrimaryBlue,
              })}
              component="p"
            >
              {t('dataImport:upload.description')} *
            </Box>
          </Box>
          <Box mb={5}>
            <PrimaryGreyTextarea
              value={description}
              isError={description.length === 0}
              maxLength={DESCRIPTION_MAX_LENGTH}
              onChange={({ target: { value } }) => {
                setDescription(value)
              }}
            />
          </Box>
        </>
      )}

      <Box mb={1} display="flex" justifyContent="flex-end" alignItems="center">
        {taskErrorCode === 'schemaMigration' && (
          <Box mr={2} color={theme.colors.chartRed}>
            {t('dataImport:upload.schema_migration')}
          </Box>
        )}
        {currResourceTypeAction && (
          <DeepBlueGradientButton
            disabled={!file || description.length === 0}
            onClick={handleImportButtonClick}
            width={200}
          >
            {buttonWord || t('common:import')}
          </DeepBlueGradientButton>
        )}
      </Box>
      {isCreatingIngestion && <Loader isWhiteBG />}
    </>
  )
}

export default ImportTagFile
