import styled from '@emotion/styled'
import Slider from '@mui/material/Slider'

import { ICON } from '@shared/model/constants/styles'
import {
  ChartData,
  FullRangeLineChart,
  getLongestSeriesLenIdx,
  getRatioIndex,
  simplifyDateDisplay,
} from '@shared/ui/charts'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

export const MAX_RANGE_SLIDER_VALUE = 100

// 為了要對 apexcharts 的位置，下面的 px 都是 magic number
const SlideWrapper = styled.div`
  position: relative;
  margin-top: -12px;
  margin-bottom: 12px;
  padding-right: 16px;
  padding-left: 56px;
  height: 36px;
`

const IconWrapper = styled.div`
  position: absolute;
  left: 18px;
  margin-right: 12px;
`

const DateStartAndEndWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: -18px;
  margin-left: -18px;
`

const DateLabel = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  font-family: inherit;
  font-weight: 500;
`

type RangeSliderProps = {
  data: ChartData<number | null>[]
  rangeValue: number[]
  handleDisplayDataRangeChanged: (
    event: Event,
    newRangeValue: number | number[]
  ) => void
}

const RangeSlider = ({
  data,
  rangeValue,
  handleDisplayDataRangeChanged,
}: RangeSliderProps) => {
  const { longestSeriesLenIdx } = getLongestSeriesLenIdx(data)

  const categories = data[longestSeriesLenIdx]?.categories || []
  const startDateText = simplifyDateDisplay(categories[0])
  const endDateText = simplifyDateDisplay(categories[categories.length - 1])

  function valueLabelFormat(value: number) {
    const targetIdx = getRatioIndex(
      value / MAX_RANGE_SLIDER_VALUE,
      categories.length
    )

    return `${simplifyDateDisplay(categories[targetIdx])}`
  }

  return (
    <SlideWrapper>
      <IconWrapper>
        <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
      </IconWrapper>

      <FullRangeLineChart data={data} />
      <Slider
        value={rangeValue}
        onChange={handleDisplayDataRangeChanged}
        aria-labelledby="range-slider"
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="auto"
        disableSwap
      />
      <DateStartAndEndWrapper>
        <DateLabel>{startDateText}</DateLabel>
        <DateLabel>{endDateText}</DateLabel>
      </DateStartAndEndWrapper>
    </SlideWrapper>
  )
}

export default RangeSlider
