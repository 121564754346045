import * as yup from 'yup'

import { audienceFilterSchema } from '@features/filters/AudienceFilter'
import type { AudienceFilterNode } from '@shared/api/rtkQuery'

export type LogicEventFilterNodeData = {
  filter?: Partial<AudienceFilterNode>
}

export const logicEventFilterNodeSchema = yup.object<LogicEventFilterNodeData>({
  filter: audienceFilterSchema,
})
