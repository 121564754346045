import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { PAGE_ROOT } from '@shared/model/constants/routes'

import { ConsumptionsRoutes } from './consumptions'
import { PolicyRoutes } from './policy'
import { UserRoutes } from './user'

export const UserCenterRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={`${path}/${PAGE_ROOT.policies}`}>
          <PolicyRoutes />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.users}`}>
          <UserRoutes />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.consumptions}`}>
          <ConsumptionsRoutes />
        </Route>

        <Redirect to={`${path}/${PAGE_ROOT.policies}`} />
      </Switch>
    </>
  )
}

export default UserCenterRoutes
