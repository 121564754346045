import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { TagStatus, useTagUserSummariesQuery } from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import Card from '@shared/ui/Card'
import DateRangePicker, {
  getLastNDays,
  getToday,
} from '@shared/ui/DateRangePicker'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import BarChart from './charts/BarChart'
import ChartTypeRadioButtons, {
  ChartType,
} from './charts/ChartTypeRadioButtons'
import StackedColumnChartArea from './charts/StackedColumnChartArea'
import useTagStatusDescription from './useTagStatusDescription'
import { getTagUserCount, transformChartDataList } from './utils'

const today = getToday()

type Props = {
  tagId: number
  latestJobStatus?: TagStatus
  latestTagUsersCreatedAt: string
}

const TagResult = ({
  tagId,
  latestJobStatus,
  latestTagUsersCreatedAt,
}: Props) => {
  const { tagUserChartData } = useTagUserSummariesQuery(
    {
      id: tagId,
      startDate: latestTagUsersCreatedAt,
      endDate: latestTagUsersCreatedAt,
    },
    {
      skip: !latestTagUsersCreatedAt,
      selectFromResult: result => {
        if (!result.data) {
          return { tagUserChartData: undefined }
        }

        const [tagUserChartData] = transformChartDataList(result.data)

        return { tagUserChartData }
      },
    }
  )

  const tagUserCount = useMemo(
    () => getTagUserCount(tagUserChartData),
    [tagUserChartData]
  )

  const tagStatusDescription = useTagStatusDescription(latestJobStatus)

  const [chartType, setChartType] = useState<ChartType>(
    'populationDistribution'
  )

  const [timeRangeParams, setTimeRangeParams] = useState([
    today.nowStart.toISOString(),
    today.nowEnd.toISOString(),
  ])

  const { t } = useTranslation(['table', 'tag'])

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <ChartTypeRadioButtons
          chartType={chartType}
          onChartTypeChange={setChartType}
        />

        {chartType === 'trends' && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 2 }}>
              <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
            </Box>

            <DateRangePicker
              values={timeRangeParams}
              durationBgColor={theme.colors.bgPrimaryGrey}
              placement="bottom-end"
              onChange={timeRange => {
                if (typeof timeRange[0] === 'number') {
                  const { startDate, endDate } = getLastNDays(
                    Math.abs(timeRange[0])
                  )
                  setTimeRangeParams([
                    startDate.toISOString(),
                    endDate.toISOString(),
                  ])

                  return
                }

                setTimeRangeParams(timeRange as string[])
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          my: 2,
          borderBottom: theme => `1px solid ${theme.colors.black6}`,
        }}
      />

      {chartType === 'populationDistribution' && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontWeight: 500,
              color: theme => theme.colors.lightNavyBlue,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {t('tag:people_distribution.total')}

              <Box
                component="span"
                sx={{
                  ml: 2,
                  mr: 4,
                  fontSize: 40,
                  fontWeight: 700,
                  color: theme => theme.colors.black,
                }}
              >
                {tagUserCount !== undefined ? formatNumber(tagUserCount) : '--'}
              </Box>

              {t('table:latest_tag_version')}

              <Box
                component="span"
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  mx: 2,
                  color: theme => theme.colors.black,
                }}
              >
                {latestTagUsersCreatedAt ? (
                  formatDateTimeDisplay(latestTagUsersCreatedAt)
                ) : (
                  <Box
                    component="span"
                    sx={{ color: theme => theme.colors.chartRed }}
                  >
                    {tagStatusDescription}
                  </Box>
                )}
              </Box>
            </Box>

            {t('tag:show_top_tag_groups')}
          </Box>

          <BarChart data={tagUserChartData} tagUserCount={tagUserCount ?? 0} />
        </>
      )}

      {chartType === 'trends' && (
        <StackedColumnChartArea
          status={tagStatusDescription}
          finishedTime={latestTagUsersCreatedAt}
          timeRangeParams={timeRangeParams}
          tagId={tagId}
        />
      )}
    </Card>
  )
}

export default TagResult
