import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { MAToolErrorCode } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CreationAndExportStatus } from '@shared/model/constants/listStatus'
import ContactUs from '@shared/ui/ContactUs'

export const useFormatGoogleAdsExportStatus = () => {
  const { t } = useTranslation(['audience', 'common'])

  const formatGoogleAdsExportStatus = useCallback(
    (
      resourceId: string,
      status: CreationAndExportStatus,
      updatedAt: string,
      errorCode: MAToolErrorCode
    ) => {
      const listId = Boolean(resourceId)
        ? `Google Ads list ID：${resourceId} `
        : ''

      if (status === 'completed') {
        return (
          <Trans
            ns={['audience']}
            i18nKey="audience:export_audience_succeed"
            components={{
              Highlight: (
                <Box
                  component="span"
                  sx={{
                    color: theme => theme.colors.brightBlue,
                    fontWeight: 500,
                  }}
                />
              ),
            }}
            values={{
              updateAt: formatDateTimeDisplay(updatedAt),
            }}
          />
        )
      }

      if (status === 'failed') {
        const message = `${listId}${t('audience:export_failure')}`

        if (errorCode === MAToolErrorCode.GACustomerMatchNotEnabled) {
          return `${message}${t('common:dot')}${t(
            'audience:export_google_ads.export_failure_customer_match'
          )}`
        }

        if (errorCode === MAToolErrorCode.ActionNotPermitted) {
          return `${message}${t('common:dot')}${t(
            'audience:export_google_ads.export_failure_level'
          )}`
        }

        if (errorCode === MAToolErrorCode.ExceedLimit) {
          return `${message}${t('common:dot')}${t(
            'audience:export_exceed_limit',
            { maTool: 'Google Ads' }
          )}`
        }

        return (
          <>
            {message + t('common:dot')}
            {t('common:please_try_again_or')}
            <ContactUs text={t('common:contact_us')} />
          </>
        )
      }

      return ''
    },
    [t]
  )

  return formatGoogleAdsExportStatus
}

export default useFormatGoogleAdsExportStatus
