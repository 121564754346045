import Box from '@mui/material/Box'

import { getFloatSafe, getIntSafe } from '@shared/lib/utils/number'
import { NumberOrString } from '@shared/lib/utils/type'
import { ThemeColor } from '@theme'

import PrimaryGreyInput from './PrimaryGreyInput'

const INPUT_WIDTH_MEDIUM = 80

const parseNumber = (value: NumberOrString, isFloat?: boolean) => {
  return isFloat ? getFloatSafe(value, '') : getIntSafe(value, '')
}

type NumberRangeInputProps = {
  // 允許使用者清空輸入框
  values: [NumberOrString, NumberOrString]
  bgColor?: ThemeColor
  startAdornment?: React.ReactNode
  centerAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  isFloat?: boolean
  isDisabled?: boolean
  step?: number
  min?: number
  max?: number
  onChange: (values: [NumberOrString, NumberOrString]) => void
}

export const NumberRangeInput = ({
  values,
  bgColor,
  startAdornment,
  centerAdornment = <Box mx={1}>~</Box>,
  endAdornment,
  isFloat,
  isDisabled,
  // 0 表示不限制，會走 default 行為
  step = 0,
  min,
  max,
  onChange,
}: NumberRangeInputProps) => {
  const [start, end] = values

  const startValue = parseNumber(start, isFloat)
  const endValue = parseNumber(end, isFloat)
  const handleBlur = () => {
    if (startValue > endValue) {
      onChange([startValue, startValue])
    }
  }
  return (
    <Box display="flex" alignItems="center">
      {startAdornment}
      <PrimaryGreyInput
        type="number"
        value={start.toString()}
        bgColor={bgColor}
        width={INPUT_WIDTH_MEDIUM}
        marginBottomRatio={0}
        isError={start === ''}
        disabled={isDisabled}
        step={step}
        min={min}
        max={max}
        onChange={e => {
          onChange([parseNumber(e.target.value, isFloat), endValue])
        }}
        onBlur={handleBlur}
      />
      {centerAdornment}
      <PrimaryGreyInput
        type="number"
        value={end.toString()}
        bgColor={bgColor}
        width={INPUT_WIDTH_MEDIUM}
        marginBottomRatio={0}
        step={step}
        min={min}
        max={max}
        isError={end === ''}
        disabled={isDisabled}
        onChange={e => {
          onChange([startValue, parseNumber(e.target.value, isFloat)])
        }}
        onBlur={handleBlur}
      />
      {endAdornment}
    </Box>
  )
}

export default NumberRangeInput
