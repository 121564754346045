import type { TFunction } from 'i18next'

import type {
  Consumptions,
  ProviderType,
  TriggerType,
} from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import type { TableColumn } from '@shared/ui/table'

const columns = (
  t: TFunction<'consumptions'>
): TableColumn<Consumptions['items'][number]>[] => [
  {
    headerName: 'medium_type',
    field: 'type',
  },
  {
    headerName: 'integration_type',
    field: 'providerType',
    valueFormatter: value => t(`service_provider.${value as ProviderType}`),
  },
  {
    headerName: 'trigger_type',
    field: 'triggerType',
    valueFormatter: value => {
      return t(`trigger_type.${value as TriggerType}`)
    },
  },
  {
    headerName: 'sent_uu_count',
    field: 'amount',
    align: 'right',
    valueFormatter: value =>
      typeof value === 'number' ? formatNumber(value) : '-',
  },
  {
    headerName: 'cost',
    field: 'cost',
    align: 'right',
    valueFormatter: value =>
      typeof value === 'number' ? formatNumber(value) : '-',
  },
]

export default columns
