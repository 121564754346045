import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'

const CONTENT_SETTING_PATH_ROOT =
  `/${CATEGORY.ma}/${PAGE_ROOT.contentSetting}` as const
export const CONTENT_SETTING_PATH = {
  ROOT: CONTENT_SETTING_PATH_ROOT,
  CONTENT_TEMPLATE: `${CONTENT_SETTING_PATH_ROOT}/${PAGE_ROOT.contentTemplate}`,
  MA_TRACING_URL: `${CONTENT_SETTING_PATH_ROOT}/${PAGE_ROOT.maTracingUrl}`,
  MA_SUBSCRIPTION: `${CONTENT_SETTING_PATH_ROOT}/${PAGE_ROOT.maSubscription}`,
} as const

export const TRACING_URL_LIMIT = 1000
