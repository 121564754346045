import styled from '@emotion/styled'
import MuiInput from '@mui/material/Input'
import { spacing } from '@mui/system'

export const TextInputErrorText = styled.p`
  margin-top: 8px;

  height: 24px;
  color: ${({ theme }) => theme.colors.chartRed};
  font-weight: 500;

  ${spacing}
`

export const TextInput = styled(MuiInput)`
  &.MuiInput-root {
    /* 為了視覺對齊增加的間距 */
    margin-right: 16px;
  }

  > .MuiInput-input {
    color: ${({ theme }) => theme.colors.black};
    font-size: 14px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textSecondBlue};
      opacity: 1;
    }
  }

  &.Mui-error:after {
    border-bottom-color: ${({ theme }) => theme.colors.chartRed};
  }
`

export default TextInput
