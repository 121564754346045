import { useEffect, useState } from 'react'

const getRemainingMs = (expirationTime: number) => {
  const now = new Date().getTime()
  return expirationTime - now
}

export const useIsValid = (expirationTime: number) => {
  const [isValid, setIsValid] = useState(getRemainingMs(expirationTime) > 0)

  useEffect(() => {
    const remainingMs = getRemainingMs(expirationTime)

    if (0 >= remainingMs) {
      return
    }

    setIsValid(true)

    const timer = setTimeout(() => {
      setIsValid(false)
    }, remainingMs)

    return () => {
      clearTimeout(timer)
    }
  }, [expirationTime])

  return isValid
}

export default useIsValid
