import i18n from 'i18next'
import * as yup from 'yup'

import type { SubscriptionFormValues } from '@shared/api/rtkQuery'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'

export const subscriptionSchema = yup.object<SubscriptionFormValues>({
  name: yup
    .string()
    .required()
    .max(
      MAX_LIST_NAME_LENGTH,
      () =>
        `${i18n.t('common:errors.max_count', { count: MAX_LIST_NAME_LENGTH })}`
    ),
  columns: yup.array().of(yup.string()).required().min(1),
  // url 需要是有效的網址
  url: yup
    .string()
    .required()
    .test({
      name: 'url',
      message: 'URL is invalid',
      test: value => {
        try {
          new URL(value)

          return true
        } catch {
          return false
        }
      },
    }),
})
