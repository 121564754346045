import { useMemo } from 'react'

import { WORK_FLOW_LIST_QUERY_PARAMS } from '@features/filters/CampaignEventFilter/constants'
import type { CampaignMediumEvent, MediumType } from '@shared/api/rtkQuery'
import {
  useGetCampaignsMediumActionMappingInfoQuery,
  useGetWorkflowListQuery,
} from '@shared/api/rtkQuery'

import {
  getTriggerGroupOptions,
  mapCampaignsMediumActionMappingInfoByMediumEvents,
} from './utils'

type Props = { mediumType: MediumType; mediumEvent: CampaignMediumEvent }
const useGetTriggerGroupOptions = ({ mediumEvent, mediumType }: Props) => {
  const {
    data: campaignsMediumActionDict,
    isLoading: isLoadingCampaignsMediumActionMappingInfo,
  } = useGetCampaignsMediumActionMappingInfoQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      data: mapCampaignsMediumActionMappingInfoByMediumEvents(data),
    }),
  })
  const { data: workflowList, isFetching: isLoadingWorkflowList } =
    useGetWorkflowListQuery(WORK_FLOW_LIST_QUERY_PARAMS)

  return useMemo(() => {
    const isLoading =
      isLoadingCampaignsMediumActionMappingInfo || isLoadingWorkflowList
    if (!campaignsMediumActionDict || !workflowList || isLoading) {
      return {
        isLoading,
        data: [],
      }
    }

    return {
      data: getTriggerGroupOptions({
        data: workflowList.items,
        sentConfigs: campaignsMediumActionDict[mediumType][mediumEvent],
        mediumType,
      }),
      isLoading,
    }
  }, [
    campaignsMediumActionDict,
    isLoadingCampaignsMediumActionMappingInfo,
    isLoadingWorkflowList,
    mediumEvent,
    mediumType,
    workflowList,
  ])
}

export default useGetTriggerGroupOptions
