import {
  formatNumber,
  getFloatSafe,
  getIntSafe,
} from '@shared/lib/utils/number'

export const getTooltipData = ({
  dataPointIndex,
  transposeConversionRates,
  transposeConversionNumbers,
  transposeLostRates,
  transposeLostNumbers,
}: {
  dataPointIndex: number
  transposeConversionRates: (number | null | string)[][]
  transposeConversionNumbers: (number | null | string)[][]
  transposeLostRates: (number | null | string)[][]
  transposeLostNumbers: (number | null | string)[][]
}) =>
  transposeConversionRates[dataPointIndex].map((rate, i) => {
    const safeConversionRate = getFloatSafe(rate)
    const safeConversionNumber = formatNumber(
      getIntSafe(transposeConversionNumbers[dataPointIndex][i])
    )

    const safeLostRate = getFloatSafe(transposeLostRates[dataPointIndex][i])
    const safeLostNumber = formatNumber(
      getIntSafe(transposeLostNumbers[dataPointIndex][i])
    )

    return [
      safeConversionNumber,
      `${safeConversionRate.toFixed(2)}%`,
      safeLostNumber,
      `${safeLostRate.toFixed(2)}%`,
    ]
  })

/**
 * 最多顯示步驟    5     3     2    1
 *            |-----|----|-----|--->
 * 分組數量    0     10    15    20
 */

const MAX_GROUP_NUM = 21

const BarChartGroupMapping: Record<string, number> = {
  '10': 5,
  '15': 3,
  '20': 2,
  [MAX_GROUP_NUM]: 1,
}

export const getMaxVisibleGroupNum = (barInGroupNum: number) => {
  const group = Object.keys(BarChartGroupMapping).find(
    group => barInGroupNum <= +group
  )

  return BarChartGroupMapping[group || MAX_GROUP_NUM]
}
