import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { TFunction } from 'i18next'

import { TAG_TYPE_INTERNAL, TAG_TYPE_OFFLINE } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList from '@shared/ui/Dropdown/CheckboxList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import { Filter } from '../_shared/type'

const statusOptions = (t: TFunction<'tag'>) => {
  return [
    {
      label: t('render_status.succeeded'),
      value: 'finished',
    },
    {
      label: t('render_status.calculating'),
      value: 'rendering',
    },
    {
      label: t('render_status.failed'),
      value: 'failed',
    },
  ]
}

const createOptions = (t: TFunction<'tag'>) => {
  return [
    {
      label: t('create_way.rule_creation'),
      value: TAG_TYPE_INTERNAL,
    },
    {
      label: t('create_way.import'),
      value: TAG_TYPE_OFFLINE,
    },
  ]
}

const updateOptions = (t: TFunction<'tag'>) => {
  return [
    {
      label: t('update_way.scheduled'),
      value: 'routine',
    },
    {
      label: t('update_way.manual'),
      value: 'manual',
    },
    {
      label: t('update_way.import'),
      value: 'import',
    },
  ]
}

type Props = {
  onChange: (result: Filter) => void
  selectedOptions: Filter
}

const OptionsSelect = ({ onChange, selectedOptions }: Props) => {
  const { t } = useTranslation('tag')

  return (
    <>
      <Box mx="10px" sx={{ fontSize: '18px' }}>
        <Icon
          id="icon-filter"
          icon={ICON.filter}
          color={theme.colors.brightBlue}
          fontSize="small"
        />
      </Box>
      <Box mx={1}>
        <DropdownCheckboxList
          hasShadow
          checkedValues={selectedOptions?.status || []}
          hasSelectAll
          label={t('status')}
          onChange={status =>
            onChange({
              ...selectedOptions,
              status,
            })
          }
          options={statusOptions(t)}
        />
      </Box>
      <Box mx={1}>
        <DropdownCheckboxList
          hasShadow
          checkedValues={selectedOptions?.createMethod || []}
          hasSelectAll
          label={t('method')}
          onChange={createMethod =>
            onChange({
              ...selectedOptions,
              createMethod,
            })
          }
          options={createOptions(t)}
        />
      </Box>
      <Box mx={1}>
        <DropdownCheckboxList
          hasShadow
          checkedValues={selectedOptions?.updateMethod || []}
          hasSelectAll
          label={t('update_type')}
          onChange={updateMethod =>
            onChange({
              ...selectedOptions,
              updateMethod,
            })
          }
          options={updateOptions(t)}
        />
      </Box>
    </>
  )
}

export default OptionsSelect
