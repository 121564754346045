import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'
import { spacing, SpacingProps } from '@mui/system'

type BlueBorderButtonProps = {
  bgColor?: string
  color?: string
  disabled?: boolean
  paddingLeft?: number
  paddingRight?: number
  size?: 'medium' | 'small'
} & SpacingProps

// 範例： AudienceBuilder 的 Add Rule 按鈕 以及 Preview 按鈕
export const BlueBorderButton = styled(
  ({
    bgColor,
    color,
    disabled,
    paddingLeft,
    paddingRight,
    size,
    ...otherProps
  }: BlueBorderButtonProps & Omit<ButtonProps, 'color'>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} />
  )
)`
  && {
    padding: ${({ size }) => (size === 'medium' ? '4px 16px' : 0)};
    padding-right: ${({ paddingRight, size }) =>
      paddingRight || size === 'small' ? 22 : 16}px;
    padding-left: ${({ paddingLeft, size }) =>
      paddingLeft || size === 'small' ? 22 : 16}px;

    border: 1px solid
      ${({ disabled, color, theme }) =>
        disabled
          ? theme.colors.lightGreyBlue
          : color || theme.colors.brightBlue};
    border-radius: 3px;
    background-color: ${({ bgColor, theme }) => bgColor || theme.colors.white};
    color: ${({ disabled, color, theme }) =>
      disabled ? theme.colors.lightGreyBlue : color || theme.colors.brightBlue};

    text-transform: none;

    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    :hover {
      background-color: ${({ color, theme }) =>
        color || theme.colors.brightBlue};
      color: ${({ theme }) => theme.colors.white};

      /* 複寫 Icon 顏色 */
      div {
        color: ${({ theme }) => theme.colors.white};
      }
    }

    ${spacing}
  }
`
export default BlueBorderButton
