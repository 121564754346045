import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import type { FormikProps } from 'formik'

import { NotificationRuleFormValues } from '@shared/api/rtkQuery'
import {
  useDeleteNotificationRuleMutation,
  usePatchNotificationRuleMutation,
} from '@shared/api/rtkQuery/analytics/notificationRule'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { RedButton } from '@shared/ui/buttons'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

const Button = styled(MuiButton)`
  &.MuiButtonBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.bgIceBlue};
  }
`

const DeleteRedButton = styled(RedButton)`
  && {
    box-shadow: ${({ theme }) => `0px 0px 0px 0px ${theme.colors.white}`};
  }
`

type Props = {
  id: number | undefined
  name: NotificationRuleFormValues['name']
  onFieldChange: FormikProps<NotificationRuleFormValues>['setFieldValue']
  onFieldReset: (field: keyof NotificationRuleFormValues) => void
  onFormReset: () => void
  isCreateMode?: boolean
  errors: Record<string, string>
  onShowConfirmDialog: () => void
}

const NotificationRuleHeader = ({
  id,
  name,
  onFieldChange,
  onFieldReset,
  onFormReset,
  isCreateMode,
  onShowConfirmDialog,
  errors,
}: Props) => {
  const { t } = useTranslation(['analytics', 'common'])
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [titleEditable, setTitleEditable] = useState<boolean>(false)

  const [deleteData, { isLoading: isDeleting }] =
    useDeleteNotificationRuleMutation()

  const [patchNotificationRule, { isLoading: isPatching }] =
    usePatchNotificationRuleMutation()

  const notificationOverviewUrl = `/${CATEGORY.analytics}/${PAGE_ROOT.notificationRule}`

  const handleOnPatch = async () => {
    if (name) {
      try {
        await patchNotificationRule({
          id: id as number,
          name,
        }).unwrap()
        dispatch(
          openToast({
            message: t('common:save_success'),
          })
        )
      } catch (error) {
        dispatch(
          openToast({
            message: t('common:save_failure'),
            status: 'error',
          })
        )
      }

      setTitleEditable(false)
    }
  }

  const handleOnDelete = async () => {
    if (id) {
      try {
        await deleteData(id).unwrap()
        history.push(notificationOverviewUrl)
      } catch (e) {
        dispatch(
          openToast({
            message: t('common:failure_delete'),
            status: 'error',
          })
        )
      }
    }
  }

  const handleReset = () => {
    onFieldReset('name')
    setTitleEditable(false)
  }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={5}
    >
      <Box display="flex">
        <Breadcrumbs sx={{ margin: 0 }}>
          <Link to={notificationOverviewUrl}>
            {t('common:route.notification_rule')}
          </Link>
          {isCreateMode && <Box>{t('common:create')}</Box>}
          {!isCreateMode && !titleEditable && name && (
            <Tooltip title={name}>
              <Box>{name}</Box>
            </Tooltip>
          )}
          {!isCreateMode && titleEditable && (
            <TextField
              disabled={isPatching}
              variant="standard"
              size="small"
              value={name}
              InputProps={{
                style: {
                  fontSize: '24px',
                  paddingTop: '3px',
                },
              }}
              onChange={e => onFieldChange('name', e.target.value)}
            />
          )}
        </Breadcrumbs>

        {!isCreateMode && !titleEditable && (
          <MuiButton
            disabled={isPatching}
            onClick={() => setTitleEditable(true)}
            sx={{
              '&.MuiButton-root': {
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            }}
          >
            {t('common:edit')}
          </MuiButton>
        )}
        {!isCreateMode && titleEditable && (
          <>
            <MuiButton
              disabled={isPatching}
              onClick={handleReset}
              sx={{
                color: 'inherit',

                '&.MuiButton-root': {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              {t('common:cancel')}
            </MuiButton>
            <MuiButton
              disabled={isPatching}
              onClick={handleOnPatch}
              sx={{
                '&.MuiButton-root': {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              {t('common:save')}
            </MuiButton>
          </>
        )}
      </Box>

      {isCreateMode && (
        <Box
          py={0.5}
          px={1.5}
          borderRadius={'3px'}
          bgcolor={theme.colors.bgIceBlue}
        >
          <Box display="inline" mr={1}>
            <Button
              variant="contained"
              color="inherit"
              startIcon={<i className={ICON.redo} />}
              onClick={onFormReset}
            >
              {t('analytics:event.reset')}
            </Button>
          </Box>

          <Button
            color="primary"
            variant="contained"
            startIcon={<i className={ICON.save} />}
            onClick={() => {
              if (errors.filter) {
                dispatch(
                  openToast({
                    message: errors.filter,
                    status: 'error',
                  })
                )
                return
              }
              onShowConfirmDialog()
            }}
          >
            {t('analytics:event.save')}
          </Button>
        </Box>
      )}

      {!isCreateMode && (
        <DeleteRedButton
          width={100}
          onClick={() => setIsDeleteDialogOpen(true)}
        >
          <i className={ICON.trashAlt} />
          {t('common:delete')}
        </DeleteRedButton>
      )}
      <DeleteConfirmDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isDeleting}
        modalTitle={t('analytics:notificationRule.sure_to_delete')}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleOnDelete}
      />
    </Box>
  )
}

export default memo(NotificationRuleHeader)
