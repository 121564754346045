import { ChartData, COLORS } from '@shared/ui/charts'

/**
 * 將包含群組資訊的列表資料根據各事件最多數量合併到同一個資料集
 * @param chartDataList 來源資料 (每個群組一份資料)
 * @param maxCount 每個事件顯示的數量
 * @returns 包含多個群組資訊的單一資料圖表
 */
export const mergeToChartData = (
  chartDataList: ChartData[],
  maxCount: number
): ChartData => {
  const [chartData] = chartDataList

  return {
    categories: chartData.categories,
    series: chartDataList
      .map(x =>
        x.series.slice(
          0,
          Math.min(Math.floor(COLORS.length / chartDataList.length), maxCount)
        )
      )
      .flat(),
  }
}
