import { createSelector } from '@reduxjs/toolkit'

import {
  type PaginationResponse,
  type ThirdPartyAppIntegrationListItem,
  INITIAL_PAGINATION_RESPONSE,
} from '@shared/api/rtkQuery'

export const selectAvailableThirdParty = createSelector<
  [
    (state: {
      data?: PaginationResponse<ThirdPartyAppIntegrationListItem>
    }) => ThirdPartyAppIntegrationListItem[],
    (state: { isFetching: boolean }) => boolean
  ],
  {
    thirdPartyList: ThirdPartyAppIntegrationListItem[]
    isStatusListFetching: boolean
  }
>(
  [
    state =>
      (state.data ?? INITIAL_PAGINATION_RESPONSE).items.filter(
        item => !item.managedByCdp
      ),
    state => state.isFetching,
  ],
  (thirdPartyList, isStatusListFetching) => ({
    thirdPartyList,
    isStatusListFetching,
  })
)
