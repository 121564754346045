import { useTranslation } from 'react-i18next'

import { SubscriptionStatus } from '@shared/api/rtkQuery'

export const useErrorHint = (status: SubscriptionStatus) => {
  const { t } = useTranslation(['workflow'])

  switch (status) {
    case 'bad':
      return t('workflow:errors.subscription_endpoint_invalid')

    case 'deleted':
      return t('workflow:errors.subscription_endpoint_deleted')

    default:
      return ''
  }
}
