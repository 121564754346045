import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cloneDeep from 'lodash/cloneDeep'

import {
  EventRuleNode,
  useLazyGetEventRuleSummaryQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { NumberOrString } from '@shared/lib/utils/type'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import { MAX_TOP_N } from '../models/constants'
import type { EventChartTitle } from '../types'
import { getChartDataDisplayList, getFilterCompared } from '../utils'

type UseEventRuleChartEditList = {
  topN: number
  timeRangeParamsCompared?: NumberOrString[]
  eventNameList: EventChartTitle[]
}

export const useEventRuleChartEditList = ({
  topN,
  timeRangeParamsCompared,
  eventNameList,
}: UseEventRuleChartEditList) => {
  const appDispatch = useAppDispatch()
  const { t } = useTranslation(['analytics'])
  const { handleError } = useErrorHandler({ type: 'event' })

  const [
    getEventRuleSummary,
    {
      data: previewData,
      isFetching: isFetchingPreviewData,
      error: summaryError,
    },
  ] = useLazyGetEventRuleSummaryQuery()

  const [
    getEventRuleSummaryCompared,
    {
      data: previewDataCompared,
      isFetching: isFetchingPreviewDataCompared,
      error: summaryComparedError,
    },
  ] = useLazyGetEventRuleSummaryQuery()

  // 記住前次結果直到用戶做出下次查詢，避免圖表因為篩選條件改變丟失事件名稱資訊
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoEventNameList = useMemo(() => eventNameList, [previewData])

  const chartDataList = useMemo(
    () =>
      getChartDataDisplayList(
        previewData,
        timeRangeParamsCompared === undefined ||
          timeRangeParamsCompared.length === 0
          ? undefined
          : previewDataCompared,
        memoEventNameList,
        topN
      ),
    [
      topN,
      memoEventNameList,
      previewData,
      previewDataCompared,
      timeRangeParamsCompared,
    ]
  )

  const getEventRuleChartDataList = useCallback(
    (filter: EventRuleNode, timeRangeCompared?: NumberOrString[]) => {
      const filterCloned = cloneDeep(filter)
      // 在編輯模式總是拿最多 topN 的資料，避免切換選項就打 API
      filterCloned.topN = MAX_TOP_N

      if (timeRangeCompared && timeRangeCompared.length > 0) {
        getEventRuleSummaryCompared(
          getFilterCompared(filterCloned, timeRangeCompared)
        )
      }

      getEventRuleSummary(filterCloned)
    },
    [getEventRuleSummary, getEventRuleSummaryCompared]
  )

  const isFetching = isFetchingPreviewData || isFetchingPreviewDataCompared

  useEffect(() => {
    const error = summaryError || summaryComparedError

    if (error) {
      handleError(error)
    }
  }, [appDispatch, handleError, summaryComparedError, summaryError, t])

  return [getEventRuleChartDataList, { chartDataList, isFetching }] as const
}
