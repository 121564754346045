import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { useAppSelector } from '@shared/lib/hooks/store'
import { ICON } from '@shared/model/constants/styles'
import ActionBar from '@shared/ui/ActionBar'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { Tooltip } from '@shared/ui/tooltips'

const EXPORT_CSV_PROGRESS_FONT_SIZE = 16
const EXPORT_CSV_CONTAINER_WIDTH = 28

const Button = styled(MuiButton)`
  &.MuiButtonBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.colors.bgIceBlue};
  }
`

type Props = {
  children: React.ReactNode
  isExportDisabled: boolean
  isExportingCSV: boolean
  isSaveDisabled: boolean
  onExport: () => void
  onReset: () => void
  onShowConfirmDialog: () => void
}

export const AnalyticsFormBreadcrumbs = ({
  children,
  isExportingCSV,
  isExportDisabled,
  isSaveDisabled,
  onExport: handleExport,
  onReset: handleReset,
  onShowConfirmDialog: handleShowConfirmDialog,
}: Props) => {
  const isEnableExporting = useAppSelector(
    state => state.auth.plan.analytics.export
  )

  const { t } = useTranslation(['analytics', 'common'])

  return (
    <Breadcrumbs
      actionBar={
        <ActionBar>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<i className={ICON.redo} />}
            onClick={handleReset}
          >
            {t('analytics:event.reset')}
          </Button>

          <Tooltip
            title={
              !isEnableExporting ? t('common:errors.trial_not_support') : ''
            }
          >
            <Box component="span">
              <Button
                variant="contained"
                color="inherit"
                disabled={
                  isExportDisabled || isExportingCSV || !isEnableExporting
                }
                startIcon={
                  isExportingCSV ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      width={EXPORT_CSV_CONTAINER_WIDTH}
                    >
                      <CircularProgress
                        size={EXPORT_CSV_PROGRESS_FONT_SIZE}
                        color={'inherit'}
                      />
                    </Box>
                  ) : (
                    <i className={ICON.import} />
                  )
                }
                onClick={handleExport}
              >
                {t('analytics:event.export')}
              </Button>
            </Box>
          </Tooltip>

          <Button
            color="primary"
            variant="contained"
            disabled={isSaveDisabled}
            startIcon={<i className={ICON.save} />}
            onClick={handleShowConfirmDialog}
          >
            {t('analytics:event.save')}
          </Button>
        </ActionBar>
      }
    >
      {children}
    </Breadcrumbs>
  )
}

export default AnalyticsFormBreadcrumbs
