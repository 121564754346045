import { useMemo } from 'react'

import { useMetadataUserProfileFromUsers } from '@shared/lib/hooks'
import {
  PREDEFINED_METADATA_TAG_ENTITY,
  PREDEFINED_METADATA_TAG_ID_LIST,
} from '@shared/model/constants/metadata'
import type { NestedOption } from '@shared/ui/Dropdown/NestedList'

import { getMetadataTagDisplayName } from '../utils'

export type MetadataTagOption = NestedOption & {
  id: string
  displayName: string
}

export const useSmsMetadataTagOptionList = () => {
  const userProfileList = useMetadataUserProfileFromUsers(
    ({ id, displayName }) => ({ id, displayName })
  )

  const metadataTagOptions = useMemo<MetadataTagOption[]>(() => {
    if (userProfileList.length === 0) {
      return []
    }

    return userProfileList
      .concat(
        PREDEFINED_METADATA_TAG_ID_LIST.map(
          id => PREDEFINED_METADATA_TAG_ENTITY[id]
        )
      )
      .map(({ id, displayName }) => ({
        id,
        displayName,
        label: getMetadataTagDisplayName(displayName, id),
        value: id,
      }))
  }, [userProfileList])

  return metadataTagOptions
}

export default useSmsMetadataTagOptionList
