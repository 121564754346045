import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'

import { ConfirmDialog } from '@shared/ui/dialogs'
import TextInput from '@shared/ui/inputs/TextInput'

type FormValues = {
  title: string
}

type SaveTemplateDialogProps = {
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onSubmit: (values: FormValues) => void
}

const SaveTemplateDialog = ({
  isOpen,
  isLoading = false,
  onClose: handleClose,
  onSubmit: handleSubmit,
}: SaveTemplateDialogProps) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const { values, touched, errors, handleChange, submitForm } = useFormik({
    initialValues: { title: '' },
    validate: values => {
      const errors: Partial<Record<keyof FormValues, string>> = {}

      if (values.title === '') {
        errors.title = t('common:errors.required')
      }

      return errors
    },
    onSubmit: handleSubmit,
  })

  return (
    <ConfirmDialog
      modalTitle={t('contentTemplate:save_new_content_template')}
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={handleClose}
      onConfirm={submitForm}
    >
      <TextInput
        name="title"
        disabled={isLoading}
        value={values.title}
        placeholder={t('contentTemplate:please_enter_content_template_name')}
        fullWidth
        error={touched.title && Boolean(errors.title)}
        onChange={handleChange}
      />

      <Box
        component="p"
        sx={theme => ({ mt: 2, color: theme.colors.textSecondBlue })}
      >
        {t('contentTemplate:save_reminder')}
      </Box>
    </ConfirmDialog>
  )
}

export default SaveTemplateDialog
