import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import { api, PaginationResponse, paginationResponseHandler } from './api'

export type PolicyType = 'default' | 'custom'

export type PolicyFromApi = {
  id: number
  name: string
  type: PolicyType
  description: string
  creator: string
  created_at: string
  services?: {
    name: string
    activated: boolean
    action_groups: {
      name: string
      actions: string[]
    }[]
  }[]
}

const transformResponsePolicyList = (data: PaginationResponse<PolicyFromApi>) =>
  camelcaseKeys(data, { deep: true })

const transformResponsePolicy = (data: PolicyFromApi) =>
  camelcaseKeys(data, { deep: true })

export type Policy = ReturnType<typeof transformResponsePolicy>

export type PolicyListParams = {
  page: number
  perPage: number
  types: PolicyType[]
  search: string
}

const policy = api.injectEndpoints({
  endpoints: builder => ({
    policyList: builder.query<
      ReturnType<typeof transformResponsePolicyList>,
      PolicyListParams
    >({
      query: params => ({
        url: '/iam/policies',
        params: snakecaseKeys(params, { deep: true }),
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: transformResponsePolicyList,
      providesTags: [{ type: 'Policy', id: 'LIST' }],
    }),
    policy: builder.query<Policy, number>({
      query: id => ({
        url: `/iam/policies/${id}`,
      }),
      transformResponse: transformResponsePolicy,
      providesTags: (result, error, arg) => [{ type: 'Policy', id: arg }],
    }),
    createPolicy: builder.mutation<
      void,
      { name: string; description: string; actions: string[] }
    >({
      query: params => ({
        url: '/iam/policies',
        method: 'POST',
        body: snakecaseKeys(params, { deep: true }),
      }),
      invalidatesTags: [{ type: 'Policy', id: 'LIST' }],
    }),
    updatePolicy: builder.mutation<
      void,
      { id: number; name: string; description?: string; actions: string[] }
    >({
      query: params => ({
        url: `/iam/policies/${params.id}`,
        method: 'PUT',
        body: snakecaseKeys(params, { deep: true }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Policy', id: 'LIST' },
        { type: 'Policy', id: arg.id },
      ],
    }),
    deletePolicy: builder.mutation<void, number>({
      query: id => ({
        url: `/iam/policies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Policy', id: 'LIST' }],
    }),
    checkPolicyExist: builder.query<boolean, string>({
      query: name => ({
        url: '/iam/policies',
        params: { name },
      }),
      transformResponse: (response: unknown[]) => response.length > 0,
    }),
  }),
  overrideExisting: false,
})

export const {
  usePolicyListQuery,
  usePolicyQuery,
  useLazyPolicyQuery,
  useCreatePolicyMutation,
  useUpdatePolicyMutation,
  useDeletePolicyMutation,
  useLazyCheckPolicyExistQuery,
} = policy
