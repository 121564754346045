import styled from '@emotion/styled'
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell'

import { getTableCellPaddingRight } from './commonStyle'

type TableHeadCellProps = {
  isFirstRowCell?: boolean
  isLastRowCell?: boolean
  isAlignRight?: boolean
  isClickable?: boolean
}

const TableHeadCell = styled(
  ({
    isFirstRowCell,
    isLastRowCell,
    isAlignRight,
    isClickable,
    width,
    ...otherProps
  }: TableHeadCellProps & TableCellProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiTableCell {...otherProps} />
  )
)`
  && {
    padding-right: ${({ isLastRowCell, isAlignRight }) =>
      getTableCellPaddingRight(isLastRowCell, isAlignRight)}px;
    padding-left: ${({ isFirstRowCell }) => (isFirstRowCell ? 60 : 16)}px;
    ${isClickable => isClickable && `pointer-events: auto;`}
    width: ${({ width }) => width || 'auto'};

    border-bottom: none;
    color: ${({ theme }) => theme.colors.blueyGrey};
    font-size: 14px;
    font-family: inherit;
    letter-spacing: 1.25px;
    white-space: nowrap;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.06);
  }
`

export default TableHeadCell
