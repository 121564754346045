import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import type { ConversionRateData, FunnelChartData } from '@shared/api/rtkQuery'
import { getLongestSeriesLenIdx } from '@shared/ui/charts'
import {
  ChartSection,
  checkDataExist,
} from '@widgets/analytics/event/_shared/ui/EventRuleChart'

import type { FunnelChart } from '../../../model/types'
import BarChart from './BarChart'
import ConversionRate from './ConversionRate'
import { DEFAULT_TOP_N_TO_SHOW } from './DateAndChart'
import { getMaxVisibleGroupNum } from './helpers'
import RangeScrollbar from './RangeScrollbar'

type Props = {
  previewData: FunnelChart['funnel_chart']
  colors: string[]
  visibilityFlags: boolean[]
  setVisibilityFlags: Dispatch<SetStateAction<boolean[]>>
}

const BarChartControl = ({
  previewData,
  colors,
  visibilityFlags,
  setVisibilityFlags,
}: Props) => {
  const { chart: data, groupConversionRates } = previewData as {
    groupConversionRates: ConversionRateData[]
    chart: FunnelChartData
  }
  const [displayRangeData, setDisplayRangeData] =
    useState<FunnelChartData>(data)
  const [startIdx, setStartIdx] = useState(0)

  /*
    最後要放在 chart 上的資料會受到兩個因素控制
    - displayRangeData 決定 x 軸顯示資料的範圍
    - visibilityFlags 決定顯示 grouped 資料的範圍
  */
  const visibleData = useMemo(
    () => ({
      categories: displayRangeData.categories,
      series: displayRangeData.series
        .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
        .filter(Boolean) as FunnelChartData['series'],
    }),
    [displayRangeData, visibilityFlags]
  )

  useEffect(() => {
    setStartIdx(0)

    if (data.categories.length <= 0) {
      setDisplayRangeData({ categories: [], series: [] })
      setVisibilityFlags([])
      return
    }

    const { maxLen } = getLongestSeriesLenIdx([data])
    const defaultVisibilityFlags = Array(maxLen)
      .fill(true)
      .map((_, idx) => (idx < DEFAULT_TOP_N_TO_SHOW ? true : false))

    setVisibilityFlags(defaultVisibilityFlags)

    setDisplayRangeData(data)
  }, [data, setVisibilityFlags, setDisplayRangeData])

  const numOfCategories = visibleData.categories.length
  const maxDisplayDataNum = getMaxVisibleGroupNum(visibleData.series.length)
  const hasRangeScrollbar = visibleData.categories.length > maxDisplayDataNum

  return (
    <>
      <ConversionRate groupConversionRates={groupConversionRates} />

      <ChartSection>
        <BarChart
          startIdx={startIdx}
          data={visibleData}
          colors={colors}
          maxVisibleChartsNum={maxDisplayDataNum}
        />
        {checkDataExist([data]) && hasRangeScrollbar && (
          <RangeScrollbar
            lengthOfData={numOfCategories}
            setStartIdx={setStartIdx}
            maxVisibleChartsNum={maxDisplayDataNum}
          />
        )}
      </ChartSection>
    </>
  )
}

export default BarChartControl
