import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'

import type { ContentTemplate } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import theme from '@theme'
import { CONTENT_SETTING_PATH } from '@widgets/contentSetting/model/constants'

import {
  ImagePreview,
  ImagePreviewWrapper,
  TextPreview,
  TextPreviewWrapper,
} from './styles'

type Props = {
  openDropdownId: ContentTemplate['id'] | undefined
  setOpenDropdownId: Dispatch<SetStateAction<ContentTemplate['id'] | undefined>>
  setSmsConfig: Dispatch<
    SetStateAction<
      | Partial<
          Pick<ContentTemplate, 'id' | 'title' | 'body' | 'replacementData'>
        >
      | undefined
    >
  >
  setRenameId: Dispatch<SetStateAction<ContentTemplate['id'] | undefined>>
  setRenameText: Dispatch<SetStateAction<ContentTemplate['title']>>
  setDeleteConfirmId: Dispatch<
    SetStateAction<ContentTemplate['id'] | undefined>
  >
}

const useColumns = ({
  openDropdownId,
  setOpenDropdownId,
  setSmsConfig,
  setRenameId,
  setRenameText,
  setDeleteConfirmId,
}: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const history = useHistory()

  const columns: TableColumn<ContentTemplate>[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'preview',
        valueFormatter: (_, { body, mediumType, previewUrl }) => {
          if (mediumType === 'sms') {
            return (
              <TextPreviewWrapper>
                <TextPreview>
                  <p>{body}</p>
                </TextPreview>
              </TextPreviewWrapper>
            )
          }

          if (mediumType === 'email') {
            try {
              return (
                <ImagePreviewWrapper>
                  <ImagePreview src={previewUrl} alt="" />
                </ImagePreviewWrapper>
              )
            } catch (err) {
              return null
            }
          }

          return null
        },
      },
      { field: 'id', headerName: 'content_template_id' },
      {
        field: 'mediumType',
        headerName: 'type',
        valueFormatter: mediumType => {
          if (mediumType === 'sms') {
            return t('common:medium_type.sms')
          }

          if (mediumType === 'email') {
            return t('common:medium_type.email')
          }

          return <>{mediumType}</>
        },
      },
      {
        field: 'title',
        colRatio: 1.5,
        headerName: 'content_template_name',
      },
      { field: 'editor', headerName: 'last_editor' },
      {
        field: 'updatedAt',
        headerName: 'last_update_time',
        valueFormatter: updatedTime => {
          return updatedTime
            ? formatDateTimeDisplay(updatedTime as string)
            : '-'
        },
      },
      {
        field: 'id',
        colRatio: 0.5,
        headerName: '',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: (
          _,
          { id, title, body, replacementData, mediumType }
        ) => (
          <div
            onClick={event => {
              // 避免觸發 TableRow 的導頁行為
              event.stopPropagation()
            }}
          >
            <Dropdown
              isOpen={openDropdownId === id}
              placement="left-start"
              setIsOpen={currIsOpen => {
                if (currIsOpen === false) {
                  // 代表 clickAway
                  setOpenDropdownId(undefined)
                  return
                }
                setOpenDropdownId(id)
              }}
              anchorElem={
                <IconButton>
                  <Icon
                    icon={'uil uil-ellipsis-v'}
                    fontSize="small"
                    color={theme.colors.textPrimaryBlue}
                  />
                </IconButton>
              }
            >
              <MenuList onMouseLeave={() => setOpenDropdownId(undefined)}>
                <MenuItem
                  onClick={() => {
                    if (mediumType === 'sms') {
                      setSmsConfig({
                        title,
                        body,
                        replacementData,
                      })

                      return
                    }

                    if (mediumType === 'email') {
                      setSmsConfig(undefined)
                      history.push(
                        `${CONTENT_SETTING_PATH.CONTENT_TEMPLATE}/new?reference_id=${id}`
                      )
                      return
                    }
                  }}
                >
                  {t('contentTemplate:copy_and_edit_content_template')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setRenameId(id)
                    setRenameText(title)
                  }}
                >
                  {t('common:rename')}
                </MenuItem>
                <MenuItem onClick={() => setDeleteConfirmId(id)}>
                  {t('common:delete')}
                </MenuItem>
              </MenuList>
            </Dropdown>
          </div>
        ),
      },
    ],
    [
      t,
      openDropdownId,
      setOpenDropdownId,
      setSmsConfig,
      history,
      setRenameId,
      setRenameText,
      setDeleteConfirmId,
    ]
  )

  return columns
}

export default useColumns
