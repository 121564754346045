import pipe from 'lodash/fp/pipe'

import type { CampaignStatistic } from '@shared/api/rtkQuery'

import { calculateAmount, filterByKey, groupData, sortDataByDate } from '.'

export const processData = (data: CampaignStatistic[]) => {
  if (!data.length) {
    return []
  }

  // 根據 actionDate 由新到舊排序，保證最新的資料在最上面
  const sortedData = sortDataByDate(data, 'desc')

  // 根據 actionDate 分組，因為會有多筆相同日期的資料
  const groupedDataByData = groupData(sortedData, 'actionDate', {
    locales: 'zh-TW',
  })

  // 計算各日期的總發送量、總發送金額，並且轉成 array
  const tableGroupDataWithSum = Object.entries(groupedDataByData).map(
    ([dateKey, details]) => {
      const totalEmailCounts = pipe(
        filterByKey({
          field: 'mediumType',
          value: 'email',
        }),
        calculateAmount('actionCount')
      )(details)

      const totalEmailAmount = pipe(
        filterByKey({
          field: 'mediumType',
          value: 'email',
        }),
        calculateAmount('totalPrice')
      )(details)

      const totalSmsCounts = pipe(
        filterByKey({
          field: 'mediumType',
          value: 'sms',
        }),
        calculateAmount('actionCount')
      )(details)

      const totalSmsAmount = pipe(
        filterByKey({
          field: 'mediumType',
          value: 'sms',
        }),
        calculateAmount('totalPrice')
      )(details)

      return {
        id: dateKey,
        date: dateKey,
        totalEmailCounts,
        totalEmailAmount,
        totalSmsCounts,
        totalSmsAmount,
        totalAmount: totalEmailAmount + totalSmsAmount,
        // 根據 triggerGroupId(workflowId) 分組
        details: Object.entries(groupData(details, 'triggerGroupId')).map(
          ([triggerGroupId, items]) => ({
            triggerGroupId,
            triggerGroupName: items.find(
              item => String(item.triggerGroupId) === triggerGroupId
            )?.triggerGroupName,
            campaignDetails: items,
          })
        ),
      }
    }
  )

  return tableGroupDataWithSum
}

export type UsageDataType = ReturnType<typeof processData>[number]
export type UsageDataItemType = UsageDataType['details'][number]
