import type { ParseKeys } from 'i18next'

import type { SegmentType } from '@shared/api/rtkQuery'
import theme from '@theme'

export const SEGMENT_TYPE = {
  atRisk: 'at_risk',
  promising: 'promising',
  champions: 'champions',
  lost: 'lost',
  new: 'new',
  canNotLose: 'cannot_lose',
} as {
  atRisk: 'at_risk'
  promising: 'promising'
  champions: 'champions'
  lost: 'lost'
  new: 'new'
  canNotLose: 'cannot_lose'
}

export const SEGMENT_DISPLAY_NAME_I18N_KEY: {
  [name in SegmentType]: ParseKeys<'rfm'>
} = {
  [SEGMENT_TYPE.atRisk]: `segment.${SEGMENT_TYPE.atRisk}`,
  [SEGMENT_TYPE.canNotLose]: `segment.${SEGMENT_TYPE.canNotLose}`,
  [SEGMENT_TYPE.champions]: `segment.${SEGMENT_TYPE.champions}`,
  [SEGMENT_TYPE.lost]: `segment.${SEGMENT_TYPE.lost}`,
  [SEGMENT_TYPE.new]: `segment.${SEGMENT_TYPE.new}`,
  [SEGMENT_TYPE.promising]: `segment.${SEGMENT_TYPE.promising}`,
}

export const SEGMENT_RANGE = {
  [SEGMENT_TYPE.atRisk]: { rScore: [1, 2], fScore: [2, 3, 4] },
  [SEGMENT_TYPE.canNotLose]: { rScore: [1, 2], fScore: [5] },
  [SEGMENT_TYPE.champions]: { rScore: [3, 4, 5], fScore: [3, 4, 5] },
  [SEGMENT_TYPE.lost]: { rScore: [1, 2], fScore: [1] },
  [SEGMENT_TYPE.new]: { rScore: [5], fScore: [1, 2] },
  [SEGMENT_TYPE.promising]: { rScore: [3, 4], fScore: [1, 2] },
}

export type SegmentStyleConfig = {
  segmentType: SegmentType
  displayNameI18n: ParseKeys<'rfm'>
  themeColor: string
  range: {
    rScore: number[]
    fScore: number[]
  }
}

export const SEGMENT_STYLE_CONFIG: SegmentStyleConfig[] = [
  {
    segmentType: SEGMENT_TYPE.champions,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.champions],
    themeColor: theme.colors.chartBlue,
    range: SEGMENT_RANGE[SEGMENT_TYPE.champions],
  },
  {
    segmentType: SEGMENT_TYPE.atRisk,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.atRisk],
    themeColor: theme.colors.chartMaize,
    range: SEGMENT_RANGE[SEGMENT_TYPE.atRisk],
  },
  {
    segmentType: SEGMENT_TYPE.promising,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.promising],
    themeColor: theme.colors.chartPeriwinkle,
    range: SEGMENT_RANGE[SEGMENT_TYPE.promising],
  },
  {
    segmentType: SEGMENT_TYPE.new,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.new],
    themeColor: theme.colors.chartAqua,
    range: SEGMENT_RANGE[SEGMENT_TYPE.new],
  },
  {
    segmentType: SEGMENT_TYPE.canNotLose,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.canNotLose],
    themeColor: theme.colors.chartLightBlue,
    range: SEGMENT_RANGE[SEGMENT_TYPE.canNotLose],
  },
  {
    segmentType: SEGMENT_TYPE.lost,
    displayNameI18n: SEGMENT_DISPLAY_NAME_I18N_KEY[SEGMENT_TYPE.lost],
    themeColor: theme.colors.salmon,
    range: SEGMENT_RANGE[SEGMENT_TYPE.lost],
  },
]

export type CountType = 'userBased' | 'sessionBased'

export const COUNT_TYPE = {
  userBased: 'userBased',
  sessionBased: 'sessionBased',
} as const
