import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  GoogleAdsCustomer,
  MAToolErrorCode,
  POLLING_INTERVAL,
  SchedulingType,
  useGetAudienceRuleUserExportListGoogleAdsQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import {
  checkIsNeedPollingExportInfo,
  ExportActionButtons,
  UPDATE_PERIOD_WEEKLY_TEXT_MAP,
  UPDATE_POLICY_TEXT_MAP,
} from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport/_shared'

import useFormatGoogleAdsExportStatus from './useFormatGoogleAdsExportStatus'
import { formatGoogleAdsExportList } from './utils'

type Props = {
  audienceRuleId: number
  schedulingType: SchedulingType
  customerList?: GoogleAdsCustomer[]
  onCustomerIdExport: (id: string) => void
  onAutoExportIdCancel: (id: number) => void
}

const GoogleAdsExportList = ({
  audienceRuleId,
  schedulingType,
  customerList,
  onCustomerIdExport,
  onAutoExportIdCancel,
}: Props) => {
  const [isNeedPolling, setIsNeedPolling] = useState(false)

  const { data: exportList } = useGetAudienceRuleUserExportListGoogleAdsQuery(
    audienceRuleId,
    { pollingInterval: isNeedPolling ? POLLING_INTERVAL : undefined }
  )

  const exportListByCustomerId = useMemo(
    () => formatGoogleAdsExportList(customerList, exportList),
    [exportList, customerList]
  )

  useEffect(() => {
    if (exportListByCustomerId.length === 0) {
      return
    }

    setIsNeedPolling(
      exportListByCustomerId.some(x => checkIsNeedPollingExportInfo(x.status))
    )
  }, [exportListByCustomerId])

  const formatGoogleAdsExportStatus = useFormatGoogleAdsExportStatus()

  const { t } = useTranslation(['common', 'audience'])

  if (exportListByCustomerId.length === 0) {
    return null
  }

  return (
    <Box sx={{ borderTop: theme => `1px solid ${theme.colors.black6}` }}>
      {exportListByCustomerId.map(
        ({
          id,
          accountName,
          customerId,
          errorCode = MAToolErrorCode.None,
          resourceId = '',
          status = 'none',
          updatedAt = '',
          updatePeriod,
          updatePeriodType,
          updatePolicy,
        }) => {
          const exportStatusDesc = formatGoogleAdsExportStatus(
            resourceId,
            status,
            updatedAt,
            errorCode
          )

          return (
            <Box
              key={customerId}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 2,
                minHeight: 88,
                borderBottom: theme => `1px solid ${theme.colors.black6}`,
              }}
            >
              <Box sx={{ flexGrow: 1, fontWeight: 500, mr: 4 }}>
                <p>
                  {customerId}
                  <Box
                    component="span"
                    sx={{ color: theme => theme.colors.brightBlue, ml: 2 }}
                  >
                    {accountName}
                  </Box>
                </p>

                {status === 'completed' && (
                  <>
                    {updatePolicy && (
                      <Box sx={{ mt: 1 }}>
                        {t('audience:export.update_policy')}
                        {t('common:punctuation.colon')}

                        <Box
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                          {/* 取代 or 新增 */}
                          {t(UPDATE_POLICY_TEXT_MAP.LABEL[updatePolicy])}
                          &nbsp;
                          <Tooltip
                            title={
                              t(
                                UPDATE_POLICY_TEXT_MAP.TOOLTIP[updatePolicy]
                              ) as string
                            }
                          >
                            <span>
                              <Icon icon={ICON.infoCircle} fontSize="small" />
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    )}

                    {updatePeriodType && Number.isInteger(updatePeriod) && (
                      <Box component="p" sx={{ mt: 1 }}>
                        {t('audience:export.update_period')}
                        {t('common:punctuation.colon')}
                        {updatePeriodType === 'daily' &&
                          t('audience:export.update_period_daily_text', {
                            day: updatePeriod,
                          })}

                        {updatePeriodType === 'weekly' &&
                          t(UPDATE_PERIOD_WEEKLY_TEXT_MAP[updatePeriod!])}

                        {updatePeriodType === 'monthly' && (
                          <>
                            {t('audience:export.update_period_monthly')}
                            &nbsp;
                            {updatePeriod === -1
                              ? t('common:month.last_day')
                              : t('common:month.n_day', {
                                  day: updatePeriod,
                                })}
                          </>
                        )}
                      </Box>
                    )}
                  </>
                )}

                {exportStatusDesc && (
                  <Box
                    component="p"
                    sx={{ mt: 1, color: theme => theme.colors.textSecondBlue }}
                  >
                    {exportStatusDesc}
                  </Box>
                )}
              </Box>

              <ExportActionButtons
                status={status}
                schedulingType={schedulingType}
                onExport={() => onCustomerIdExport(customerId)}
                onRetry={() => onCustomerIdExport(customerId)}
                onAutoExportCancel={() => {
                  if (id === undefined) {
                    return
                  }

                  onAutoExportIdCancel(id)
                }}
              />
            </Box>
          )
        }
      )}
    </Box>
  )
}

export default GoogleAdsExportList
