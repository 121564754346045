import type { ChartData } from '../types'

/* 
    選出最長的 series.length 之 idx
    example:
      response4 = [
        {
          labels: ['1月1號', '1月2號', '1月3號', '1月4號'],
          series: [
            {
              group: ['ios'],
              values: [5, 6, 7, 8]
            },
            {
              group: ['android'],
              values: [15, 16, 17, 18]
            }
          ]
        },
        {
          labels: ['10月1號', '10月2號', '10月3號', '10月4號'],
          series: [
            {
              group: ['ios'],
              values: [1, 2, 3, 4]
            },
            {
              group: ['android'],
              values: [7, 8, 9, 10]
            },
            {
              group: ['win'],
              values: [70, 0, 99, 100]
            }
          ]
        }
      ];
  
      // response4[1].series.length 最長
      return {
        longestSeriesLenIdx: 1,
        maxLen: 3
      }
  */
export const getLongestSeriesLenIdx = (data: ChartData<number | null>[]) => {
  let longestSeriesLenIdx = 0
  let maxLen = 0

  data.forEach((d, idx) => {
    if (d.series.length > maxLen) {
      maxLen = d.series.length
      longestSeriesLenIdx = idx
    }
  })

  return { longestSeriesLenIdx, maxLen }
}
