import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import {
  IamServiceActionGroup,
  useIamServiceEntityQuery,
} from '@shared/api/rtkQuery'
import Accordion, { Caret } from '@shared/ui/Accordion'
import { Checkbox } from '@shared/ui/CheckboxList'
import theme from '@theme'

const getGroupCheckedCount = (
  group: IamServiceActionGroup,
  actionRecord: Record<string, boolean>
) =>
  group.actions.reduce(
    (prev, curr) => (actionRecord[curr] ? prev + 1 : prev),
    0
  )

const getTotalCheckedCount = (actionRecord: Record<string, boolean>) => {
  let count = 0
  for (let actionName in actionRecord) {
    count += actionRecord[actionName] ? 1 : 0
  }
  return count
}

type Props = {
  serviceName: string
  actionRecord: Record<string, boolean>
  onChangeActionRecord: (value: Record<string, boolean>) => void
  isDisabled: boolean
  isShowError: boolean
}

const ServiceAccordion = ({
  serviceName,
  actionRecord,
  onChangeActionRecord,
  isDisabled,
  isShowError,
}: Props) => {
  const { data: iamServiceEntity } = useIamServiceEntityQuery()

  const { t } = useTranslation(['iam'])

  const [collapseRecord, setCollapseRecord] = useState<Record<string, boolean>>(
    {}
  )

  const onGroupCollapse = (groupName: string) => {
    setCollapseRecord(prev => ({
      ...prev,
      [groupName]: !prev[groupName],
    }))
  }

  const onGroupCheckboxToggle = (group: IamServiceActionGroup) => {
    // 1. 選取一個以上的項目 (count !== 0) -> 點擊後全部取消
    // 2. 沒有選取任何項目 (count === 0) -> 點擊後全部選取
    const checked = getGroupCheckedCount(group, actionRecord) === 0

    const record = group.actions.reduce<Record<string, boolean>>(
      (acc, curr) => {
        acc[curr] = checked
        return acc
      },
      {}
    )

    onChangeActionRecord({
      ...actionRecord,
      ...record,
    })
  }

  const onActionRecordToggle = (actionName: string) => {
    onChangeActionRecord({
      ...actionRecord,
      [actionName]: !actionRecord[actionName],
    })
  }

  const totalCount = getTotalCheckedCount(actionRecord)

  return (
    <Accordion
      label={`${t(
        `iam:${`policy.${serviceName}.name` as ParseKeys<'iam'>}`
      )} (${totalCount} ${t('iam:policy.actions')})`}
    >
      <Box
        py={2.5}
        px={5}
        bgcolor={theme.colors.bgPrimaryGrey}
        color={theme.colors.textPrimaryBlue}
      >
        <Box component="h3" mb={1}>
          {t('iam:policy.actions')}
          {isShowError && totalCount === 0 && (
            <Box component="span" ml={1} color={theme.colors.orangeyRed}>
              {t('iam:policy.service_required')}
            </Box>
          )}
        </Box>
        <Box ml={2} component="ul" width="100%">
          {iamServiceEntity?.entities[serviceName]?.actionGroups.map(group => {
            const checkedCount = getGroupCheckedCount(group, actionRecord)

            return (
              <Box
                component="li"
                style={{
                  cursor: 'pointer',
                  pointerEvents: isDisabled ? 'none' : 'auto',
                }}
                key={group.name}
                onClick={() => onGroupCollapse(group.name)}
              >
                <Caret
                  direction={collapseRecord[group.name] ? 'right' : 'down'}
                />
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={event => {
                    event.stopPropagation()
                    onGroupCheckboxToggle(group)
                  }}
                >
                  <Checkbox
                    checked={checkedCount > 0}
                    indeterminate={
                      checkedCount > 0 && checkedCount < group.actions.length
                    }
                    disabled={isDisabled}
                    color="primary"
                  />
                  {t(`iam:${`policy.${group.name}` as ParseKeys<'iam'>}`)}
                </span>

                {!collapseRecord[group.name] && (
                  <Box display="flex" flexWrap="wrap" ml={5}>
                    {group.actions.map(action => (
                      <Box
                        component="span"
                        style={{
                          cursor: 'pointer',
                          pointerEvents: isDisabled ? 'none' : 'auto',
                        }}
                        key={action}
                        onClick={(
                          event: React.MouseEvent<HTMLSpanElement, MouseEvent>
                        ) => {
                          event.stopPropagation()
                          onActionRecordToggle(action)
                        }}
                      >
                        <Checkbox
                          checked={actionRecord[action] || false}
                          disabled={isDisabled}
                          color="primary"
                        />
                        {t(
                          `iam:${
                            `policy.${serviceName}.${action}` as ParseKeys<'iam'>
                          }`
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Accordion>
  )
}

export default ServiceAccordion
