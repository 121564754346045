import { ChartDataWithEventIndex } from '@shared/api/rtkQuery'

import { EventChartTitle } from '../types'

/**
 * 將來源資料補上群組名稱，方便後續資料處理
 * @param chartDataList 來源資料
 * @param eventTitleList 當前選擇的事件名稱列表
 * @returns 包含群組名稱的來源資料
 */
export const addGroupName = (
  chartDataList: ChartDataWithEventIndex[],
  eventTitleList: EventChartTitle[]
) =>
  chartDataList.map(({ eventIndex, categories, series }) => ({
    eventIndex,
    categories: [...categories],
    series: series.map(({ name, data }) => ({
      // 資料有做 group by 來源才會有 name 欄位：
      // 1. 來源有 name 欄位 => groupName 欄位是當前選擇的事件名稱 (by index)
      // 2. 來源沒有 name 欄位 => groupName 留空，並將事件名稱設定在 name
      groupName: name ? eventTitleList[eventIndex]?.displayName : '',
      name: name || eventTitleList[eventIndex]?.displayName,
      label: eventTitleList[eventIndex]?.label || '',
      data: [...data],
    })),
  }))
