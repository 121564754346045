import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  MsgSender,
  useDeleteMsgSenderMutation,
  useGetMsgSenderListQuery,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { openToast } from '@shared/model/slices'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { DataTable } from '@shared/ui/table'

import { useWorkflowSenderListColumns } from './hooks'

const INIT_MSG_SENDER_LIST: MsgSender[] = []

export const WorkflowSenderList = () => {
  const [deleteId, setDeleteId] = useState<number>()

  const columns = useWorkflowSenderListColumns({ onDelete: setDeleteId })

  const [deleteMsgSender, { isLoading: isDeleting }] =
    useDeleteMsgSenderMutation()

  const dispatch = useDispatch()

  const { t } = useTranslation(['workflow', 'common'])

  const handleDeleteConfirm = async () => {
    if (!deleteId) {
      return
    }

    try {
      await deleteMsgSender(deleteId).unwrap()

      setDeleteId(undefined)
    } catch {
      dispatch(
        openToast({
          status: 'error',
          message: t('common:failure_delete'),
        })
      )
    }
  }

  const { data: msgSenderList = INIT_MSG_SENDER_LIST, isLoading } =
    useGetMsgSenderListQuery()

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const selectedItemName = useMemo(() => {
    const msgSender = msgSenderList.find(({ id }) => id === deleteId)

    if (!msgSender) {
      return ''
    }

    return `${getThirdPartyProviderName(msgSender.integrationType)} / ${
      msgSender.name
    } / ${msgSender.email}`
  }, [deleteId, getThirdPartyProviderName, msgSenderList])

  return (
    <>
      <DataTable isLoading={isLoading} columns={columns} rows={msgSenderList} />

      <DeleteConfirmDialog
        isLoading={isDeleting}
        isOpen={Boolean(deleteId)}
        modalTitle={t('workflow:senders.delete_confirm', {
          item: selectedItemName,
        })}
        onClose={() => setDeleteId(undefined)}
        onConfirm={handleDeleteConfirm}
      />
    </>
  )
}

export default WorkflowSenderList
