import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import type { ConversionRateData, FunnelChartData } from '@shared/api/rtkQuery'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import CheckboxList from '@shared/ui/CheckboxList'
import Dropdown from '@shared/ui/Dropdown'
import { SearchBar } from '@shared/ui/searchInput'
import theme from '@theme'

import type { FunnelChart } from '../../../model/types'

export const HorizontalLine = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black6};
`

type Props = {
  previewData: FunnelChart['funnel_chart']
  visibilityFlags: boolean[]
  setVisibilityFlags: Dispatch<SetStateAction<boolean[]>>
}

const BarChartDisplayControl = ({
  previewData,
  visibilityFlags,
  setVisibilityFlags,
}: Props) => {
  const { t, i18n } = useTranslation(['dateAndChart', 'iam', 'common'])
  const [isOpen, setIsOpen] = useState(false)
  const [filterText, setFilterText] = useState('')
  const { chart: data } = previewData as {
    groupConversionRates: ConversionRateData[]
    chart: FunnelChartData
  }

  const groupOptions = useMemo(
    () => data.series.map(d => ({ label: d.name, value: d.name })),
    [data.series]
  )
  const selectedGroupList = groupOptions
    .filter((o, i) => visibilityFlags[i])
    .map(option => option.value)

  const displayOptions = groupOptions.filter(({ label }) =>
    label.includes(filterText)
  )

  return (
    <>
      {groupOptions.length > 1 && (
        <Box mr={1}>
          <Dropdown
            key={i18n.language}
            label={`${t('dateAndChart:group_to_display')} | ${
              visibilityFlags.filter(Boolean).length
            }/${data.series.length}`}
            bgColor={theme.colors.white}
            isOpen={isOpen}
            isShowFilterIcon
            setIsOpen={setIsOpen}
          >
            <SearchBar
              defaultValue={filterText}
              placeholder={t('common:placeholder_search')}
              onSearch={setFilterText}
            />

            <HorizontalLine />

            <CheckboxList
              options={displayOptions}
              checkedValues={selectedGroupList}
              onChange={(options, current) => {
                const currItemIndex = groupOptions.findIndex(
                  ({ value }) => value === current
                )
                const nextVisibilityFlags = cloneDeep(visibilityFlags)
                nextVisibilityFlags[currItemIndex] =
                  !visibilityFlags[currItemIndex]
                setVisibilityFlags(nextVisibilityFlags)
              }}
            />
          </Dropdown>
        </Box>
      )}
    </>
  )
}

export default BarChartDisplayControl
