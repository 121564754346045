import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { trackEvent } from '@shared/lib/utils/amplitude'
import { useSharedState } from '@widgets/insight/lib/SharedStateContext'

import Head from '../../_shared/Head'
import WithCampaignDataTable from './WorkflowInsightWithCampaignDataTable'
import WithSubscriptionDataTable from './WorkflowInsightWithSubscriptionDataTable'

const Overview = () => {
  const { t } = useTranslation(['common'])
  const sharedState = useSharedState()

  useEffect(() => {
    const [startDate, endDate] = sharedState.timeRangeParams as string[]

    trackEvent('AutomationJourneyPerformanceFiltered', {
      filterEndDate: endDate,
      filterStartDate: startDate,
      filterText: sharedState.searchText,
      filterType: sharedState.filterType,
    })
  }, [sharedState])

  useEffect(() => {
    trackEvent('AutomationJourneyPerformanceViewed')
  }, [])

  return (
    <>
      <Head
        title={t('common:route.workflow_insight')}
        hasWorkflowOnlyWithSubscriptionFilter
      />
      {sharedState.filterType === 'subscription' ? (
        <WithSubscriptionDataTable />
      ) : (
        <WithCampaignDataTable />
      )}
    </>
  )
}

export default Overview
