import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { type TemplateSms, SmsTemplate } from '@entities/mediumTemplates'
import { ConfirmDialog } from '@shared/ui/dialogs'

import { REACT_FLOW_NO_DRAG } from '../../_shared/constants'

type SelectSmsTemplateDialogProps = {
  isOpen: boolean
  onConfirm: (template: TemplateSms) => void
  onClose: () => void
}

export const SelectSmsTemplateDialog = ({
  isOpen,
  onConfirm,
  onClose,
}: SelectSmsTemplateDialogProps) => {
  const [template, setTemplate] = useState<TemplateSms>()

  const handleConfirm = () => {
    if (!template) {
      return
    }

    onConfirm(template)
  }

  const { t } = useTranslation(['contentTemplate'])

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      isOpen={isOpen}
      maxWidth="lg"
      modalTitle={t('contentTemplate:please_select_sms_content_template')}
      onConfirm={handleConfirm}
      onClose={onClose}
    >
      <Box mb={2}>
        <SmsTemplate onChange={setTemplate} />
      </Box>
    </ConfirmDialog>
  )
}

export default SelectSmsTemplateDialog
