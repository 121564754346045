import { useTranslation } from 'react-i18next'
import { Link, useLocation, useParams } from 'react-router-dom'

import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { TabItem, TabWrapper } from '@shared/ui/tabs'

const SectionTab = () => {
  const { t } = useTranslation(['audience'])
  const { id } = useParams<{ id: string }>()
  const audienceId = getIntSafe(id)
  const path = `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${audienceId}`

  const { pathname } = useLocation()

  return (
    <TabWrapper>
      <Link to={path}>
        <TabItem isSelected={path === pathname}>
          {t(`audience:layout.configure`)}
        </TabItem>
      </Link>

      <Link to={`${path}/${PAGE_ROOT.analysis}`}>
        <TabItem isSelected={`${path}/${PAGE_ROOT.analysis}` === pathname}>
          {t(`audience:layout.analysis`)}
        </TabItem>
      </Link>
    </TabWrapper>
  )
}

export default SectionTab
