import { useTranslation } from 'react-i18next'

import { TooltipWhenOverflowX } from '@shared/ui/tooltips'

import { getIndicatorDisplayText } from '../utils/getIndicatorDisplayText'
import {
  IndicatorItem,
  IndicatorLabel,
  IndicatorValue,
  IndicatorWrapper,
} from './styles'

export type DashboardIndicator = {
  indicator: string
  label?: string
  value?: number | string
  valueCompared?: number | string
}

type Props = {
  indicatorList: DashboardIndicator[]
}

export const IndicatorList = ({ indicatorList = [] }: Props) => {
  const { t } = useTranslation(['dateAndChart'])

  return (
    <IndicatorWrapper>
      {indicatorList.map(({ indicator, value, label, valueCompared }, idx) => {
        const hasValue = typeof value !== 'undefined'
        const displayText = getIndicatorDisplayText(indicator, t)

        return (
          <IndicatorItem key={`${indicator}_${idx}`}>
            <TooltipWhenOverflowX title={displayText}>
              <IndicatorLabel>{displayText}</IndicatorLabel>
            </TooltipWhenOverflowX>

            {hasValue && (
              <IndicatorValue>
                {value}

                {typeof valueCompared !== 'undefined' && ` / ${valueCompared}`}
              </IndicatorValue>
            )}

            {label && (
              <TooltipWhenOverflowX title={label}>
                <IndicatorLabel>{label}</IndicatorLabel>
              </TooltipWhenOverflowX>
            )}
          </IndicatorItem>
        )
      })}
    </IndicatorWrapper>
  )
}

export default IndicatorList
