import styled from '@emotion/styled'
import MuiButton from '@mui/material/Button'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiInput from '@mui/material/Input'

import Card from '@shared/ui/Card'
import { DialogContent } from '@shared/ui/dialogs'
import { PrimaryGreyInput as Input } from '@shared/ui/inputs'

export const StyledCard = styled(Card)`
  margin-bottom: 40px;
  padding: 0;
  transition: all 1s ease-in-out;
`

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 12px 0;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 24px 32px;
`

export const HeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
`

export const EditButtonGroup = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 16px;
`

export const DialogInput = styled(MuiInput)`
  > .MuiInput-input {
    font-size: 14px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textSecondBlue};
      opacity: 1;
    }
  }
`

export const Container = styled(DialogContent)`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.textSecondBlue};

  & .MuiFormControlLabel-label {
    font-size: 14px;
  }

  & .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
`

export const EditButton = styled.div`
  align-self: flex-start;
  margin-left: 20px;
  color: ${({ theme }) => theme.colors.brightBlue};
  cursor: pointer;
`

export const CancelButton = styled.div`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  cursor: pointer;
`

export const AddLevelButton = styled(MuiButton)`
  && {
    margin-right: 20px;
    width: 120px;
  }
`

export const ContentWrapper = styled.div`
  padding: 32px;
`

export const DescriptionRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
`

export const Text = styled.div`
  margin-right: 24px;
  width: 70px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
`

export const InputDescription = styled(Input)`
  flex-grow: 1;
`
export const DialogActions = styled(MuiDialogActions)`
  & > Button {
    text-transform: none;
  }

  &.MuiDialogActions-root {
    padding: 0 20px 16px 20px;
  }
`

export const HorizontalLine = styled.div`
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`
