import {
  $applyNodeReplacement,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

import {
  ReplacementDataActionURL,
  ReplacementDataTracingURL,
} from '@shared/api/rtkQuery/contentTemplate'

import UtmComponent, { Props as UtmPayload } from './UtmComponent'

export const NODE_TYPE = 'utm'

export type SerializedUtmNode = Spread<
  UtmPayload & {
    type: typeof NODE_TYPE
    version: 1
  },
  SerializedLexicalNode
>

export class UtmNode extends DecoratorNode<JSX.Element> {
  __replacementKey: string
  __replacementData: ReplacementDataActionURL | ReplacementDataTracingURL

  static getType(): string {
    return NODE_TYPE
  }

  static clone(node: UtmNode): UtmNode {
    return new UtmNode(
      node.__replacementKey,
      node.__replacementData,
      node.__key
    )
  }

  constructor(
    replacementKey: string,
    replacementData: ReplacementDataActionURL | ReplacementDataTracingURL,
    key?: NodeKey
  ) {
    super(key)

    this.__replacementKey = replacementKey
    this.__replacementData = replacementData
  }

  static importJSON(serializedNode: SerializedUtmNode): UtmNode {
    const node = $createUtmNode(
      serializedNode.replacementKey,
      serializedNode.replacementData
    )

    return node
  }

  exportJSON(): SerializedUtmNode {
    return {
      nodeKey: this.getKey(),
      replacementKey: this.__replacementKey,
      replacementData: this.__replacementData,
      type: NODE_TYPE,
      version: 1,
    }
  }

  createDOM(): HTMLElement {
    return document.createElement('span')
  }

  updateDOM(): boolean {
    return false
  }

  setUtmReplacementData(
    replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
  ): void {
    const writable = this.getWritable()

    writable.__replacementData = replacementData
  }

  getUtmReplacementData():
    | ReplacementDataActionURL
    | ReplacementDataTracingURL {
    return this.__replacementData
  }

  decorate(): JSX.Element {
    return (
      <UtmComponent
        replacementKey={this.__replacementKey}
        replacementData={this.__replacementData}
        nodeKey={this.getKey()}
      />
    )
  }
}

export const $createUtmNode = (
  replacementKey: string,
  replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
): UtmNode =>
  $applyNodeReplacement(new UtmNode(replacementKey, replacementData))

export const $isUtmNode = (
  node: LexicalNode | null | undefined
): node is UtmNode => node instanceof UtmNode
