import Box from '@mui/material/Box'

type Props = {
  label?: React.ReactNode
  required?: boolean
  children: React.ReactNode
}
const FormRow = ({ label, required, children }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {label && (
        <Box
          sx={{
            color: theme => theme.colors.textPrimaryBlue,
            fontWeight: 500,
          }}
        >
          <span>{label}</span>
          {required && <span>&nbsp;*</span>}
        </Box>
      )}

      {children}
    </Box>
  )
}
export default FormRow
