type Weekday = 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat'
export const WEEKDAYS: Weekday[] = [
  'sun',
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
]
/**
 * if maxLength === 2, text === 7, return 07;
 * if maxLength === 2, text === 17, return 17;
 * if maxLength === 3, text === 5, return 005;
 * if maxLength === 2, text === 120, return 120;
 * @param text
 * @param maxLength 總共顯示的位數
 * @param fillString 填補的字元
 */
const padStart = (
  text: number | string,
  maxLength: number = 2,
  fillString: string = '0'
) => `${text}`.padStart(maxLength, fillString)

/**
 * if second === (17 * 60 * 60) + (3 * 60) + 19, return 17:03:19
 * @param second
 */
export const formatSecondToHHMMSS = (second: number) => {
  let diff = second
  const hour = Math.floor(diff / 60 / 60)
  diff -= hour * 60 * 60
  const min = Math.floor(diff / 60)
  diff -= min * 60
  const sec = diff
  return `${padStart(hour)}:${padStart(min)}:${padStart(sec)}`
}

export const isValidDate = (date: any) => {
  const dateObj = new Date(date)

  return (
    Object.prototype.toString.call(dateObj) === '[object Date]' &&
    !isNaN(dateObj.getTime())
  )
}

export const isISODate = (str: string) => {
  // 瀏覽器 new Date().toISOString() 是 2021-05-05T16:00:00.000Z
  const reg3digit = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/

  // backend 回傳 2021-05-05T16:00:00.000000Z
  const reg6digit = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z/
  // 新版事件分析 API 回傳的日期格式含時區 2022-01-04T00:00:00+08:00
  const dateTimeWithTimezone =
    /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/

  if (
    reg3digit.test(str) ||
    reg6digit.test(str) ||
    dateTimeWithTimezone.test(str)
  ) {
    return isValidDate(str)
  }

  return false
}

export const getDateTimestampSafe = (date: any) => {
  const dateObj = new Date(date)

  return Object.prototype.toString.call(dateObj) === '[object Date]' &&
    !isNaN(dateObj.getTime())
    ? dateObj.getTime()
    : new Date().getTime()
}

export const getYYYYMMDDHHMMSS = (timeStamp?: Date) => {
  const target = new Date(getDateTimestampSafe(timeStamp))
  const yy = padStart(target.getFullYear())
  const mm = padStart(target.getMonth() + 1)
  const dd = padStart(target.getDate())
  const hh = padStart(target.getHours())
  const mminutes = padStart(target.getMinutes())
  const ss = padStart(target.getSeconds())

  return `${yy}${mm}${dd}${hh}${mminutes}${ss}`
}

export const getMMDDWithHyphen = (timeStamp: any) => {
  const target = new Date(getDateTimestampSafe(timeStamp))
  const mm = padStart(target.getMonth() + 1)
  const dd = padStart(target.getDate())

  return `${mm}-${dd}`
}

export const getYYYYMMDDWithHyphen = (timeStamp: any) => {
  const target = new Date(getDateTimestampSafe(timeStamp))
  const yy = padStart(target.getFullYear())
  const mmdd = getMMDDWithHyphen(timeStamp)

  return `${yy}-${mmdd}`
}

export const getYYYYMMDD = (timeStamp: Date) => {
  const target = new Date(getDateTimestampSafe(timeStamp))
  const yy = padStart(target.getFullYear())
  const mm = padStart(target.getMonth() + 1)
  const dd = padStart(target.getDate())

  return `${yy}${mm}${dd}`
}

export const checkIsOver24Hours = (date: string) =>
  new Date().getTime() - new Date(getDateTimestampSafe(date)).getTime() >
  24 * 60 * 60 * 1000

export const getToday = () => {
  const now = new Date()
  now.setHours(0, 0, 0, 0)
  return now
}

export const getYesterday = () => {
  const today = getToday()
  return new Date(+today - 24 * 60 * 60 * 1000)
}

export const getTomorrow = () => {
  const today = getToday()
  return new Date(+today + 24 * 60 * 60 * 1000)
}

export const formatDateTimeDisplay = (
  date: Date | string | number,
  defaultValue = '-'
) => {
  if (!date) {
    return defaultValue
  }
  const currDate = new Date(getDateTimestampSafe(date))
  const YYYY = padStart(currDate.getFullYear(), 4)
  const MM = padStart(currDate.getMonth() + 1)
  const DD = padStart(currDate.getDate())
  const hh = padStart(currDate.getHours())
  const mm = padStart(currDate.getMinutes())
  const ss = padStart(currDate.getSeconds())
  return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}`
}

export const formatDateDisplay = (
  date: Date | string | number,
  defaultValue = '-',
  formatType: string = 'YYYY-MM-DD'
) => {
  if (!date) {
    return defaultValue
  }
  const currDate = new Date(getDateTimestampSafe(date))
  const YYYY = padStart(currDate.getFullYear(), 4)
  const MM = padStart(currDate.getMonth() + 1)
  const DD = padStart(currDate.getDate())

  switch (formatType) {
    case 'YYYY-MM-DD':
      return `${YYYY}-${MM}-${DD}`
    case 'YYYY/MM/DD':
      return `${YYYY}/${MM}/${DD}`
    case 'MM-DD':
      return `${MM}-${DD}`
    case 'MM/DD':
      return `${MM}/${DD}`
    default:
      return `${YYYY}-${MM}-${DD}`
  }
}

export const formatTimeDisplay = (
  date: Date | string | number,
  hasSecond: boolean = false,
  defaultValue = '-'
) => {
  if (!date) {
    return defaultValue
  }
  const currDate = new Date(getDateTimestampSafe(date))
  const hh = padStart(currDate.getHours())
  const mm = padStart(currDate.getMinutes())

  if (hasSecond) {
    const ss = padStart(currDate.getSeconds())
    return `${hh}:${mm}:${ss}`
  }

  return `${hh}:${mm}`
}

export const formatDateWithWeekday = (
  date: Date | string | number,
  locale?: string
): string => {
  if (!date) {
    return '-'
  }
  const currDate = new Date(getDateTimestampSafe(date))

  const weekday = currDate.toLocaleString(locale, {
    weekday: 'short',
  })

  if (locale === 'zh-TW') {
    // remove 週
    return weekday.split('')[1]
  }

  return weekday
}

export const dateRange = (
  startDate: Date | string | number,
  endDate: Date | string | number,
  steps: number = 1
) => {
  const dateArray = []
  let currentDate = new Date(getDateTimestampSafe(startDate))

  while (currentDate <= new Date(getDateTimestampSafe(endDate))) {
    dateArray.push(formatDateDisplay(currentDate))
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps)
  }

  return dateArray
}

export const getBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone
