import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import ImportTagFile, {
  ImportButtonClickPayload,
} from '@entities/ImportFile/ImportTagFile'
import { useRefetchIngestionList } from '@features/infoBox/lib/hooks'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import { createIngestion } from '@features/infoBox/model/taskSlice'
import { INITIAL_PAGINATION_RESPONSE } from '@shared/api/rtkQuery'
import {
  useDeleteTagUserIngestionMutation,
  useGetTagUserIngestionListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import formatCreatorTypeDisplay from '@shared/lib/utils/formatCreatorTypeDisplay'
import { getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import type { TagUserIngestion } from '@shared/model/types/ingestion'
import Card from '@shared/ui/Card'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable, TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

type Props = {
  tagId: number
}

const TagImportById = ({ tagId }: Props) => {
  const { t } = useTranslation(['common', 'dataImport', 'tag'])

  const [confirmDeleteId, setConfirmDeleteId] = useState<number>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const {
    data: offlineTagUserBatchListData = INITIAL_PAGINATION_RESPONSE,
    isFetching,
    refetch,
  } = useGetTagUserIngestionListQuery(
    { tagId, page, perPage },
    { refetchOnMountOrArgChange: true }
  )

  const dispatch = useAppDispatch()

  useRefetchIngestionList({ refetch })

  const [deleteOfflineTagUserBatch, { isLoading: isDeleting }] =
    useDeleteTagUserIngestionMutation()

  const isLoading = isFetching || isDeleting

  const columns: TableColumn<TagUserIngestion>[] = useMemo(
    () => [
      { headerName: 'imported_id', field: 'id', colRatio: 0.5 },
      {
        headerName: 'imported_time',
        colRatio: 0.75,
        field: 'importedTime',
        valueFormatter: value => formatDateTimeDisplay(value as string),
      },
      {
        headerName: 'imported_type',
        colRatio: 0.5,
        field: 'creatorType',
        valueFormatter: value => formatCreatorTypeDisplay(value as string),
      },
      {
        headerName: 'imported_description',
        field: 'description',
      },
      {
        headerName: 'imported_by',
        field: 'creatorName',
      },
      {
        headerName: '',
        field: 'id',
        colRatio: 0.5,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: id => {
          return (
            <Tooltip title={t('common:delete')}>
              <IconButton
                disabled={isLoading}
                onClick={event => {
                  event.stopPropagation()
                  setConfirmDeleteId(getIntSafe(id))
                  setIsOpenDeleteDialog(true)
                }}
              >
                <Icon
                  icon={ICON.trashAlt}
                  color={theme.colors.textPrimaryBlue}
                />
              </IconButton>
            </Tooltip>
          )
        },
      },
    ],
    [isLoading, t]
  )

  return (
    <>
      <Box mb={5}>
        <Card>
          <ImportTagFile
            defaultResourceActionType="tag_user.insert"
            tagResourceActionType={{
              tag_user: ['insert'],
            }}
            onImportButtonClick={({
              description,
              file,
              action,
              tagResourceType,
            }: ImportButtonClickPayload) => {
              const createBehaviorTagUserPayload: CreateIngestionPayload<'behavior_tag_user'> =
                {
                  createParams: {
                    action,
                    description,
                    tagId,
                  },
                  file,
                  resourceType: 'behavior_tag_user',
                }
              const createTagUserPayload: CreateIngestionPayload<'tag_user'> = {
                createParams: {
                  description,
                  resourceType: 'tag_user',
                  resourceId: tagId,
                },
                resourceType: 'tag_user',
                file,
              }

              const payload =
                tagResourceType === 'behavior_tag_user'
                  ? createBehaviorTagUserPayload
                  : createTagUserPayload

              dispatch(createIngestion(payload))
            }}
          />
        </Card>
      </Box>

      <SectionTitle title={t('tag:imported_tag_management')} />

      <Box mb={5}>
        <DataTable
          rows={offlineTagUserBatchListData.items}
          columns={columns}
          // 元件預設 page 為 0
          page={page - 1}
          rowsPerPage={perPage}
          count={offlineTagUserBatchListData.totalCount}
          isLoading={isLoading}
          onPageChange={currPage => {
            setPage(currPage + 1)
          }}
          onRowsPerPageChange={setPerPage}
        />
      </Box>

      <DeleteConfirmDialog
        isOpen={isOpenDeleteDialog}
        modalTitle={t('dataImport:imported_event_delete_confirm', {
          id: confirmDeleteId,
        })}
        onClose={() => {
          setIsOpenDeleteDialog(false)
          setConfirmDeleteId(undefined)
        }}
        onConfirm={() => {
          if (confirmDeleteId) {
            deleteOfflineTagUserBatch(confirmDeleteId)
            setIsOpenDeleteDialog(false)
          }
        }}
      />
    </>
  )
}

export default TagImportById
