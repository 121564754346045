import { useMemo } from 'react'

import type { SendType } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import {
  base64ToKb,
  getCostPerMessage,
  textToKb,
} from '@shared/lib/utils/textMessage/utils'
import { getTextWithoutBrackets } from '@widgets/audience/lib/getTextWithoutBrackets'

export const useTotalCostAndSize = (
  sendType: SendType,
  message: string,
  mobileCount: number = 0,
  uploadImageDataURL: string
) => {
  const messageWithoutCustomVariables = getTextWithoutBrackets(message)
  const messageLength = messageWithoutCustomVariables.length
  const base64StartIndex = uploadImageDataURL.indexOf(',') + 1

  const uploadImageBase64 = uploadImageDataURL.slice(base64StartIndex)

  const totalKB =
    textToKb(getTextWithoutBrackets(message)) + base64ToKb(uploadImageBase64)
  const costPerMessage = getCostPerMessage(
    sendType,
    getTextWithoutBrackets(message).length,
    totalKB
  )

  const totalCost = useMemo(() => {
    return costPerMessage * getIntSafe(mobileCount)
  }, [mobileCount, costPerMessage])

  return {
    messageLength,
    totalCost,
    totalKB,
  }
}

export default useTotalCostAndSize
