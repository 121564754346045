import type { CampaignStatistic } from '@shared/api/rtkQuery'

export const sortDataByDate = (
  data: CampaignStatistic[],
  order: 'desc' | 'asc'
) =>
  data.toSorted((a, b) =>
    order === 'asc'
      ? new Date(a.actionDate).getTime() - new Date(b.actionDate).getTime()
      : new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime()
  )
