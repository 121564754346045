import styled from '@emotion/styled'

import { MenuItem } from '@shared/ui/menu'
import { TablePagination as BaseTablePagination } from '@shared/ui/table'

export const Container = styled.div<{ width?: number }>`
  margin-top: 4px;
  width: ${({ width }) => (width ? `${width}px` : '200px')};

  && ${MenuItem} {
    padding: 8px 48px;
    font-family: inherit;
    white-space: pre-line;
  }
`

export const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

export const IconContainer = styled.span`
  position: absolute;
  right: 16px;

  &:hover #icon-arrow-right {
    color: ${({ theme }) => theme.colors.brightBlue};
  }
`

export const TablePagination = styled(BaseTablePagination)`
  &.MuiTablePagination-root {
    margin-top: 0;
  }

  & > .MuiTablePagination-toolbar {
    padding-right: 6px;
    min-height: 48px;
  }

  & .MuiButtonBase-root {
    padding: 8px;
  }
`
