import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import type { FunnelChart } from '../../../model/types'

type Props = {
  previewData: FunnelChart['trend_chart']
  step: number | string
  setStep: Dispatch<SetStateAction<number | string>>
}

const LineChartDisplayControl = ({ previewData, step, setStep }: Props) => {
  const { t } = useTranslation('dateAndChart')
  const data = previewData['chart']

  /* 
    UI 顯示：所有步驟、步驟1->步驟2、步驟2->步驟3、... 過濾掉 步驟0->步驟1
    data 的 key 是 0-based，所以要看 步驟1->步驟2，是要看 data["1"]
  */
  const stepOptions = useMemo(
    () =>
      Object.keys(data)
        .sort((a: any, b: any) => b - a)
        .reverse()
        .filter(key => key !== '0')
        .map(key => {
          if (key === 'all') {
            return {
              label: t('step_all'),
              value: 'all',
            }
          }
          return {
            label: `${t('step')}${getIntSafe(key)} -> ${t('step')}${
              getIntSafe(key) + 1
            }`,
            value: getIntSafe(key),
          }
        }),
    [data, t]
  )

  return (
    <>
      {stepOptions.length > 1 && (
        <Box display="flex" alignItems="center" mr={1}>
          <Box mr={1}>
            <Icon icon={ICON.listOl} color={theme.colors.brightBlue} />
          </Box>

          <DropdownList
            bgColor={theme.colors.bgPrimaryGrey}
            options={stepOptions}
            onValueChanged={option => setStep(option.value)}
            value={step}
          />
        </Box>
      )}
    </>
  )
}

export default LineChartDisplayControl
