import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError } from '@entities/apiHandler'
import { type Design, EmailEditor } from '@entities/mediumTemplates'
import { useCreateContentTemplateMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { CONTENT_TEMPLATE_ERROR_CODE } from '@shared/model/constants/contentTemplate'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { CONTENT_SETTING_PATH } from '@widgets/contentSetting/model/constants'

import useInit from './hooks/useInit'
import SaveTemplateAndSendEmailActionBar from '../_shared/SaveTemplateAndSendEmailActionBar'
import { STATUS_CODE } from '../constants'
import SaveTemplateDialog from './SaveTemplateDialog'
import SelectTemplateDialog from './SelectTemplateDialog'

const Create = () => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const {
    isFetching,
    emailEditor: {
      isReady,
      isExporting,
      emailEditorRef,
      onExportDesign,
      onReady,
      onLoadDesign,
    },
    selectTemplateDialogVisible,
    saveTemplateDialogVisible,
    setSelectTemplateDialogVisible,
    setSaveTemplateDialogVisible,
  } = useInit()

  const history = useHistory()

  const [createContentTemplate, { isLoading }] =
    useCreateContentTemplateMutation()

  const isCreatingTemplate = isLoading || isExporting

  const dispatch = useAppDispatch()

  const onCreate = async (values: { title: string }) => {
    if (!isReady || isCreatingTemplate) {
      return
    }

    const { html, design, previewUrl, replacementData } = await onExportDesign()

    try {
      const { id } = await createContentTemplate({
        title: values.title,
        mediumType: 'email',
        sentBodyGenParams: html,
        body: JSON.stringify({ editorJson: design }),
        previewUrl,
        replacementData,
      }).unwrap()

      trackEvent('MarketingAutomationTemplateConfigured', {
        templateId: id,
        templateName: values.title,
        templateType: 'medium_template',
      })

      history.push(CONTENT_SETTING_PATH.CONTENT_TEMPLATE)
    } catch (error) {
      const typedError = formatRTKQueryError<{ errorCode: number }>(error)

      if (
        typedError.data?.errorCode ===
        CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_EMAIL_PERSONALIZED_BLOCK
      ) {
        dispatch(
          openToast({
            message: t('contentTemplate:errors.duplicate_personalized_block'),
            status: 'error',
          })
        )
        return
      }

      if (typedError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
        dispatch(
          openToast({
            message: t('common:errors.duplicate_name'),
            status: 'error',
          })
        )
        return
      }

      dispatch(
        openToast({
          message: t('contentTemplate:save_failed'),
          status: 'error',
        })
      )
    }
  }

  const onSubmitSelectTemplate = (template: Design | undefined) => {
    setSelectTemplateDialogVisible(false)
    if (!template) {
      return
    }

    onLoadDesign(template)
  }

  if (isFetching) {
    return null
  }

  return (
    <>
      <Breadcrumbs
        actionBar={
          <SaveTemplateAndSendEmailActionBar
            isSaving={isCreatingTemplate}
            onSave={() => {
              setSaveTemplateDialogVisible(true)
            }}
            onExportDesign={onExportDesign}
          />
        }
      >
        <Link to={`${CONTENT_SETTING_PATH.CONTENT_TEMPLATE}`}>
          {t('common:route.content_setting')}
        </Link>
        <Box>{t('contentTemplate:build_email_template')}</Box>
      </Breadcrumbs>

      <EmailEditor minHeight="85vh" ref={emailEditorRef} onReady={onReady} />

      <SaveTemplateDialog
        isOpen={saveTemplateDialogVisible}
        isLoading={isCreatingTemplate}
        onClose={() => {
          setSaveTemplateDialogVisible(false)
        }}
        onSubmit={onCreate}
      />

      <SelectTemplateDialog
        isOpen={selectTemplateDialogVisible}
        onClose={() => {
          setSelectTemplateDialogVisible(false)
        }}
        onSubmit={onSubmitSelectTemplate}
      />
    </>
  )
}

export default Create
