import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'

import type { WorkflowInsightDetail } from '@shared/api/rtkQuery'
import Switch from '@shared/ui/Switch'

type ColumnVisibilityModel = Record<
  keyof Omit<
    WorkflowInsightDetail,
    'id' | 'workflowLastEnabledAt' | 'mediumType'
  >,
  boolean
>

const ColumnsPanel = () => {
  const { t } = useTranslation(['table'])
  const gridApiContext = useGridApiContext()
  const allColumns = useGridSelector(
    gridApiContext,
    gridColumnDefinitionsSelector
  )
  const columnVisibilityModel = useGridSelector(
    gridApiContext,
    gridColumnVisibilityModelSelector
  )

  const handleShowAllColumns = () => {
    const nextColumnVisibilityModel = allColumns.reduce(
      (acc, column) => ({
        ...acc,
        [column.field]: true,
      }),
      {} as ColumnVisibilityModel
    )
    gridApiContext.current.setColumnVisibilityModel(nextColumnVisibilityModel)
  }

  const handleVisibleColumnsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name: field, checked } = event.target as HTMLInputElement

    const hiddenColumnsLength = Object.values({
      ...columnVisibilityModel,
      [field]: checked,
    }).filter(value => !value).length

    if (hiddenColumnsLength !== allColumns.length) {
      gridApiContext.current.setColumnVisibility(field, checked)
    }
  }

  return (
    <Box overflow="scroll" width="100%">
      <Box
        sx={theme => ({
          borderBottom: `0.7px solid ${theme.colors.black6}`,
          paddingTop: 1,
          paddingBottom: 0.5,
          paddingRight: 2,
          paddingLeft: 2,
          marginBottom: 0.5,
        })}
      >
        <Button
          fullWidth
          sx={theme => ({
            color: theme.colors.brightBlue,
            fontWeight: 600,
            fontSize: '14px',
            justifyContent: 'flex-start',
          })}
          onClick={handleShowAllColumns}
        >
          {t('table:show_all_columns')}
        </Button>
      </Box>
      {allColumns.map(column => {
        // 如果沒有設定 headerName，就不顯示在 Panel 上
        if (!column.headerName) {
          return null
        }

        return (
          <Box
            py={1}
            px={2}
            key={column.field}
            sx={theme => ({
              '&:hover': {
                backgroundColor: theme.colors.veryLightBlueThree,
                color: theme.colors.brightBlue,
              },
            })}
          >
            <FormControlLabel
              control={
                <Switch
                  disabled={!column.hideable}
                  checked={columnVisibilityModel[column.field] !== false}
                  name={column.field}
                  onChange={handleVisibleColumnsChange}
                />
              }
              label={
                <Box ml={1} fontWeight={600} fontSize={14}>
                  {column.headerName}
                </Box>
              }
              sx={{
                marginLeft: 0,
              }}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default ColumnsPanel
