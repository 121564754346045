import type { ParseKeys } from 'i18next'

type Option = {
  label: ParseKeys<'common'>
  value: number
}

export const TOP_N_OPTIONS: Option[] = [
  {
    label: 'top_n_items',
    value: 5,
  },
  {
    label: 'top_n_items',
    value: 10,
  },
  {
    label: 'top_n_items',
    value: 15,
  },
  {
    label: 'top_n_items',
    value: 20,
  },
  {
    label: 'top_n_items',
    value: 25,
  },
  {
    label: 'top_n_items',
    value: 30,
  },
  {
    label: 'top_n_items',
    value: 40,
  },
  {
    label: 'top_n_items',
    value: 50,
  },
]

export const CONTROL_SECTION_WIDTH = 200

export const CONTROL_SECTION_HEIGHT = 44

export const CONTROL_SECTION_MARGIN_LEFT = 20

export const EVENT_CHART_HEIGHT = 400

export const EVENT_CHART_MARGIN_RIGHT = 16

export const EVENT_CHART_VISIBILITY_LIST_HEIGHT = 380

export type Unit = 'day' | 'week' | 'month' | 'all'

export const UNIT_OPTIONS: { label: ParseKeys<'dateAndChart'>; value: Unit }[] =
  [
    {
      label: 'by.day',
      value: 'day',
    },
    {
      label: 'by.week',
      value: 'week',
    },
    {
      label: 'by.month',
      value: 'month',
    },
    {
      label: 'by.all',
      value: 'all',
    },
  ]

export const COMPARE_DURATION = {
  LAST_SAME_PERIOD: 'LAST_SAME_PERIOD',
  LAST_YEAR_SAME_DURATION: 'LAST_YEAR_SAME_DURATION',
  CUSTOMIZE: 'CUSTOMIZE',
} as const
