import type { SizeMode } from '../types'

export const getSparseXLables = (arr: string[], sizeMode: SizeMode) => {
  // 儀表板橫軸顯示 8~13 個左右的 X 軸時間點
  // 事件分析橫軸顯示 14~22 個左右的 X 軸時間點
  const LIMIT = sizeMode === 'small' ? 9 : 15
  const TOLERANCE_RANGE = sizeMode === 'small' ? 4 : 7
  const total = arr.length

  // 長度 13/22 以下全顯示
  if (total <= LIMIT + TOLERANCE_RANGE) {
    return arr
  }

  /* 
    這個情況，因為 Math.floor(total / LIMIT) === 1
    又 X % 1 永遠等於 0，所以 arr 會全選，沒有刪減效果
    所以要出怪招拿掉幾個點
    example: 
      arr.length = 17, 目標 10
      diff = 17 - 10 = 7
      quotient = 2
      arr 變成每兩個挑一個
      input [0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16]
      output [0 2 4 6 8 10 12 14 16]
  */
  if (total < LIMIT * 2) {
    const diff = total - LIMIT
    const quotient = Math.floor(total / diff)

    return arr.map((x, i) =>
      i % quotient !== Math.floor(quotient / 2) ? x : ''
    )
  }

  const quotient = Math.floor(total / LIMIT)

  return arr.map((x, i) => (i % quotient === 0 ? x : ''))
}
