import { useTranslation } from 'react-i18next'

import { formatDateWithWeekday } from '@shared/lib/utils/time'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getDuration } from '@shared/ui/DateRangePicker/helpers'

import { DurationItem, DurationWrapper, SimpleText } from '../_shared/Style'

type Props = {
  isHideLatestDate?: boolean
  isMultiEvents?: boolean
  isGroupingData?: boolean
  timeRangeParams: NumberOrString[]
  compareToTimeRangeParams: NumberOrString[]
}

const Duration = ({
  isHideLatestDate = false,
  isMultiEvents = false,
  isGroupingData = false,
  timeRangeParams,
  compareToTimeRangeParams,
}: Props) => {
  const { i18n, t } = useTranslation(['dateAndChart', 'common', 'dashboard'])
  const { dynamicDuration, staticDuration, isSameDate, latestDate } =
    getDuration(timeRangeParams, t)

  const hasComparedDate =
    compareToTimeRangeParams && compareToTimeRangeParams.length > 0

  const weekday = formatDateWithWeekday(latestDate, i18n.language)

  const latestDateWithWeekday = `${latestDate} (${weekday})`
  const displayLatestDate = `${t('dateAndChart:latest_date')} : `

  if (hasComparedDate) {
    const {
      dynamicDuration: comparedDynamicDuration,
      staticDuration: comparedStaticDuration,
      isSameDate: isComparedSameDate,
      latestDate: comparedLatestDate,
    } = getDuration(compareToTimeRangeParams, t)

    const staticDate = isSameDate ? latestDate : staticDuration
    const staticComparedDate = isComparedSameDate
      ? comparedLatestDate
      : comparedStaticDuration

    if ((isGroupingData && isMultiEvents) || isHideLatestDate) {
      return (
        <DurationWrapper>
          <DurationItem isHideBorder>
            {dynamicDuration ?? staticDate}
          </DurationItem>
          <SimpleText>{t('dateAndChart:compare_to')}</SimpleText>
          <DurationItem>
            {comparedDynamicDuration ?? staticComparedDate}
          </DurationItem>
        </DurationWrapper>
      )
    }

    const compareToWeekday = formatDateWithWeekday(
      comparedLatestDate,
      i18n.language
    )

    const compareToLatestDate = `${comparedLatestDate} (${compareToWeekday})`

    return (
      <DurationWrapper>
        {
          <DurationItem
            isHideBorder
          >{`${displayLatestDate}${latestDateWithWeekday}`}</DurationItem>
        }
        <SimpleText>{t('dateAndChart:compare_to')}</SimpleText>
        {<DurationItem>{compareToLatestDate}</DurationItem>}
      </DurationWrapper>
    )
  }

  if ((isGroupingData && isMultiEvents) || isHideLatestDate) {
    return (
      <DurationWrapper>
        {isSameDate && <DurationItem>{latestDateWithWeekday}</DurationItem>}
        {dynamicDuration && <DurationItem>{dynamicDuration}</DurationItem>}
        {!isSameDate && staticDuration && (
          <DurationItem>{staticDuration}</DurationItem>
        )}
      </DurationWrapper>
    )
  }

  return (
    <DurationWrapper>
      <DurationItem>{`${displayLatestDate}${latestDateWithWeekday}`}</DurationItem>
      {dynamicDuration && <DurationItem>{dynamicDuration}</DurationItem>}
      {!isSameDate && staticDuration && (
        <DurationItem>{staticDuration}</DurationItem>
      )}
    </DurationWrapper>
  )
}

export default Duration
