import {
  ThirdPartyAppProvider,
  useCancelAutoExportIterableMutation,
  useCancelAutoExportMAACMutation,
  useCancelAutoExportSuper8Mutation,
} from '@shared/api/rtkQuery'

export const useCancelAudienceRuleUserAutoExport = (
  provider: ThirdPartyAppProvider
) => {
  switch (provider) {
    // 因為 EVERY8D 不會有自動匯出，但為了程式寫法一致回傳空函式
    // omnichat 不支援匯出
    case 'every8d':
    case 'every8d_exclusive':
    case 'omnichat':
      return () => [undefined, { isLoading: false }] as const
    case 'iterable':
      return useCancelAutoExportIterableMutation
    case 'maac':
      return useCancelAutoExportMAACMutation
    case 'super8':
      return useCancelAutoExportSuper8Mutation

    default:
      throw new Error(`Invalid provider: ${provider}`)
  }
}

export default useCancelAudienceRuleUserAutoExport
