import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { CONTENT_TEMPLATE_ERROR_CODE } from '@shared/model/constants/contentTemplate'
import { openToast } from '@shared/model/slices'

import { STATUS_CODE } from '../constants'

export const useProcessWorkflowError = () => {
  const dispatch = useAppDispatch()

  const maxCount = useAppSelector(state => state.auth.plan.workflow.maxCount)

  const { t } = useTranslation(['workflow'])

  const handleWorkflowError = useCallback(
    (error: unknown, workflowName: string) => {
      const formattedError =
        formatRTKQueryError<{ nodeUuid: string; errorCode: number }>(error)

      if (formattedError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
        dispatch(
          openToast({
            message: t('workflow:errors.workflow_name_invalid'),
            status: 'error',
          })
        )
        return
      }

      if (formattedError.statusCode === STATUS_CODE.NODE_INVALID) {
        dispatch(
          openToast({
            message: t('workflow:errors.workflow_invalid', {
              name: workflowName,
            }),
            status: 'error',
          })
        )
        return
      }

      if (formattedError.statusCode === STATUS_CODE.REACH_MAX_WORKFLOW) {
        dispatch(
          openToast({
            message: t('workflow:instruction.upper_limit', {
              maxCount: maxCount,
            }),
            status: 'error',
          })
        )
        return
      }

      if (
        formattedError.statusCode === STATUS_CODE.NODE_ID_INVALID &&
        formattedError.data?.errorCode ===
          CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_EMAIL_PERSONALIZED_BLOCK
      ) {
        dispatch(
          openToast({
            message:
              t('workflow:errors.node_id_invalid', {
                node_id: formattedError.data?.nodeUuid ?? '',
              }) +
              `: ${t('contentTemplate:errors.duplicate_personalized_block')}`,
            status: 'error',
          })
        )
        return
      }

      if (
        formattedError.statusCode === STATUS_CODE.RESOURCE_INVALID ||
        formattedError.statusCode === STATUS_CODE.NODE_ID_INVALID
      ) {
        dispatch(
          openToast({
            message: t('workflow:errors.node_id_invalid', {
              node_id: formattedError.data?.nodeUuid ?? '',
            }),
            status: 'error',
          })
        )
        return
      }

      if (formattedError.statusCode === STATUS_CODE.UPDATE_RACE_CONDITION) {
        dispatch(
          openToast({
            message: t('workflow:errors.workflow_status_race_condition'),
            status: 'error',
          })
        )
        return
      }

      dispatch(handleApiError(formattedError))
    },
    [dispatch, maxCount, t]
  )

  return [handleWorkflowError]
}

export default useProcessWorkflowError
