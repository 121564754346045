import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import type { ParseKeys } from 'i18next'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  useIamServiceEntityQuery,
  useLazyCheckPolicyExistQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import { checkIsTitle } from '@shared/lib/utils/validation'
import { ICON } from '@shared/model/constants/styles'
import Accordion from '@shared/ui/Accordion'
import { AddButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import Dropdown from '@shared/ui/Dropdown'
import { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import DotLoader from '@shared/ui/loaders/DotLoader'
import { MenuList } from '@shared/ui/menu'
import { MenuItem } from '@shared/ui/menu'
import theme from '@theme'

import ServiceAccordion from './ServiceAccordion'

export * from './utils'

export const BUTTON_WIDTH = 200

export const STATUS_CODE = {
  NO_PERMISSION: 403,
}

type ServiceEntity = {
  ids: string[]
  entities: {
    [id: string]: {
      isActivated: boolean
      actionRecord: Record<string, boolean>
    }
  }
}

export type FormValues = {
  name: string
  description: string
  serviceEntity: ServiceEntity
}

const INITIAL_FORM_VALUES: FormValues = {
  name: '',
  description: '',
  serviceEntity: { ids: [], entities: {} },
}

type Props = {
  formValues?: FormValues
  isDisabled?: boolean
  isLoading?: boolean
  isSubmitting?: boolean
  onSubmit?: (values: FormValues) => Promise<void>
  children: React.ReactNode
}

const PolicyForm = ({
  formValues,
  isDisabled = false,
  isLoading = false,
  isSubmitting = false,
  onSubmit = () => Promise.resolve(),
  children,
}: Props) => {
  const { t, i18n } = useTranslation(['common', 'iam'])

  const [checkPolicyExist] = useLazyCheckPolicyExistQuery()

  const formik = useFormik<FormValues>({
    initialValues: formValues ?? INITIAL_FORM_VALUES,
    enableReinitialize: true,
    validate: values => {
      const errors: Record<string, string> = {}

      if (!checkIsTitle(values.name)) {
        errors.name = t('iam:policy.policy_name_error', {
          words: 100,
        })
      }

      if (values.serviceEntity.ids.length === 0) {
        errors.serviceEntity = t('iam:policy.service_required')
      }

      return errors
    },
    onSubmit: async values => {
      if (isSubmitting) {
        return
      }

      // 每個 service 都至少勾選一個選項
      if (
        values.serviceEntity.ids.some(id => {
          const actionEntity = values.serviceEntity.entities[id]

          if (!actionEntity.isActivated) {
            return false
          }

          let count = 0

          for (let action in actionEntity.actionRecord) {
            count += actionEntity.actionRecord[action] ? 1 : 0
          }

          return count === 0
        })
      ) {
        return
      }

      try {
        // 僅在沒有 formValues (建立頁) 的時候驗證名稱是否重複
        if (!formValues && (await checkPolicyExist(values.name).unwrap())) {
          formik.setFieldError(
            'name',
            t('iam:policy.policy_name_error_duplicate')
          )

          return
        }
      } catch (error) {
        const typedError = formatRTKQueryError(error)
        dispatch(handleApiError(typedError))
        return
      }

      await onSubmit(values)
    },
  })

  const { data: iamServiceEntity = { ids: [], entities: {} } } =
    useIamServiceEntityQuery()

  const dispatch = useAppDispatch()

  const [isShowAddMenu, setIsShowAddMenu] = useState(false)

  const options: Option[] = iamServiceEntity.ids
    // 過濾頁面上已經選擇的項目
    .filter(
      serviceName =>
        iamServiceEntity.entities[serviceName].activated &&
        !formik.values.serviceEntity.ids.some(s => s === serviceName)
    )
    .map(serviceName => ({
      label: serviceName,
      value: serviceName,
    }))

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box display="flex" flex={1} alignItems="flex-start" mr={3}>
            <Box display="flex" alignItems="center" mr={3} height={42}>
              {t('common:name')}
            </Box>
            <Box flex={1}>
              <PrimaryGreyInput
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                fullWidth
                isError={formik.touched.name && Boolean(formik.errors.name)}
                // 當有 formValues (編輯頁) 則 disabled
                disabled={isDisabled || formValues !== undefined}
              />
              <Box
                display="flex"
                alignItems="center"
                color={theme.colors.chartRed}
                height={48}
              >
                {formik.touched.name && formik.errors.name}
              </Box>
            </Box>
          </Box>

          <Box display="flex" flex={1} alignItems="flex-start">
            <Box display="flex" alignItems="center" mr={3} height={42}>
              {t('common:description')}
            </Box>
            <Box flex={1}>
              <PrimaryGreyInput
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                fullWidth
                disabled={isDisabled}
              />
            </Box>
          </Box>
        </Box>

        <Box mb={4} borderBottom={` 1px solid ${theme.colors.black6}`} />

        <Box display="flex" alignItems="flex-start" mb={8}>
          <Box display="flex" alignItems="center" mr={3} height={42}>
            {t('iam:policy.service')}
          </Box>

          <Box flex={1}>
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <DotLoader />
              </Box>
            ) : (
              <>
                {formik.values.serviceEntity.ids.map((serviceName, idx) => {
                  const isShowBorderBottom =
                    idx !== formik.values.serviceEntity.ids.length - 1 ||
                    !isDisabled

                  return (
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      mb={isShowBorderBottom ? 3 : 0}
                      pb={isShowBorderBottom ? 3 : 0}
                      borderBottom={
                        isShowBorderBottom
                          ? `1px solid ${theme.colors.brightBlue}`
                          : ''
                      }
                      key={serviceName}
                    >
                      {formik.values.serviceEntity.entities[serviceName]
                        .isActivated ? (
                        <ServiceAccordion
                          serviceName={serviceName}
                          actionRecord={
                            formik.values.serviceEntity.entities[serviceName]
                              .actionRecord
                          }
                          onChangeActionRecord={record => {
                            const entity = cloneDeep(
                              formik.values.serviceEntity
                            )

                            entity.entities[serviceName].actionRecord = record

                            formik.setFieldValue('serviceEntity', entity)
                          }}
                          isDisabled={isDisabled}
                          isShowError={Boolean(formik.touched.serviceEntity)}
                        />
                      ) : (
                        <Accordion
                          label={`${serviceName} (${t(
                            'iam:policy.permission_denied'
                          )})`}
                        >
                          <Box
                            py={2.5}
                            px={5}
                            bgcolor={theme.colors.bgPrimaryGrey}
                            color={theme.colors.textPrimaryBlue}
                          >
                            {t('iam:policy.permission_denied_hint')}
                          </Box>
                        </Accordion>
                      )}

                      {!isDisabled && (
                        <Box ml={1}>
                          <IconButton
                            onClick={() => {
                              const entity = cloneDeep(
                                formik.values.serviceEntity
                              )

                              entity.ids = entity.ids.filter(
                                x => x !== serviceName
                              )
                              entity.entities[serviceName] = {
                                isActivated:
                                  iamServiceEntity.entities[serviceName]
                                    .activated,
                                actionRecord: {},
                              }

                              formik.setFieldValue('serviceEntity', entity)
                            }}
                          >
                            <Icon
                              icon={ICON.times}
                              color={theme.colors.black}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  )
                })}

                {!isDisabled && (
                  <>
                    <Dropdown
                      key={i18n.language}
                      anchorElem={
                        <AddButton
                          label={t('iam:policy.add')}
                          disabled={options.length === 0}
                        />
                      }
                      isOpen={isShowAddMenu}
                      setIsOpen={setIsShowAddMenu}
                    >
                      <Box
                        sx={{
                          width: 240,
                          maxHeight: 168,
                          overflowY: 'scroll',
                        }}
                      >
                        <MenuList>
                          {options.map(option => (
                            <MenuItem
                              py={1}
                              px={5}
                              key={`${option.value}`}
                              disabled={option.disabled}
                              onClick={() => {
                                const entity = cloneDeep(
                                  formik.values.serviceEntity
                                )
                                const serviceName = option.value as string

                                entity.ids.push(serviceName)
                                entity.entities[serviceName] = {
                                  isActivated:
                                    iamServiceEntity.entities[serviceName]
                                      .activated,
                                  actionRecord: {},
                                }

                                formik.setFieldValue('serviceEntity', entity)

                                setIsShowAddMenu(false)
                              }}
                            >
                              <Box
                                component="span"
                                overflow="hidden"
                                textOverflow="ellipsis"
                              >
                                {t(
                                  `iam:policy.${option.label}.name` as ParseKeys<
                                    ['iam']
                                  >
                                )}
                              </Box>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Box>
                    </Dropdown>

                    <Box mt={2} color={theme.colors.orangeyRed}>
                      <>
                        {formik.touched.serviceEntity &&
                          formik.errors.serviceEntity}
                      </>
                    </Box>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>

        {children}
      </Card>
    </form>
  )
}

export default PolicyForm
