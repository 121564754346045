import type { TFunction } from 'i18next'

import {
  DIMENSION_SOURCE_PREFIX,
  MetadataEventEntity,
  MetadataUserProfileEntity,
  TagRuleList,
} from '@shared/api/rtkQuery'
import type { Category } from '@shared/ui/Dropdown/CategoryList'

import { createMetadataPropertyId } from './helpers'

export const DEFAULT_EVENT_TABLE_NAME = 'events'
export const DEFAULT_CAMPAIGN_EVENT_TABLE_NAME = 'campaign_events'
export const TAG_FIELD = 'value'

export const getCategoriesOfClassification = ({
  classification,
  events,
}: MetadataEventEntity): Category[] =>
  classification.ids.map(classificationId => {
    const curr = classification.entities[classificationId]
    return {
      id: classificationId,
      name: curr.displayName,
      options: (curr.events || []).map(eventId => ({
        label: events.entities[eventId].displayName,
        value: eventId,
      })),
    }
  })

type GetCategoriesOfUserProfileEntityOption = {
  skipDimensionColumn?: boolean
  skipRepeated?: boolean
}

export const getCategoriesOfUserProfileEntity = (
  userProfile: {
    id: string
    name: string
    entity: MetadataUserProfileEntity
  },
  option: GetCategoriesOfUserProfileEntityOption = {
    skipDimensionColumn: false,
    skipRepeated: false,
  }
): Category[] => [
  {
    id: userProfile.id,
    name: userProfile.name,
    options: userProfile.entity.ids.reduce((options, propertyId) => {
      const {
        displayName,
        tableName: source,
        id: field,
        isFromColumnOfDimTable,
        repeated,
      } = userProfile.entity.entities[propertyId]

      // 此欄位只有事件分析的 aggregator 可以使用
      if (isFromColumnOfDimTable || (option.skipRepeated && repeated)) {
        return options
      }

      if (
        option.skipDimensionColumn &&
        source.startsWith(DIMENSION_SOURCE_PREFIX)
      ) {
        return options
      }

      options.push({
        label: displayName,
        value: createMetadataPropertyId(source, field),
      })

      return options
    }, [] as Category['options']),
  },
]

export const getCategoriesOfTagUsers = (
  tagRuleList: TagRuleList,
  t: TFunction<'audience'[]>
): Category[] => [
  {
    id: 'tags',
    name: t('audience:v3.all_tags'),
    options: tagRuleList.map(x => ({
      label: x.title,
      value: `${x.tagUsersTableName}.${TAG_FIELD}`,
    })),
  },
]
