import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetSuper8TemplateListQuery,
} from '@shared/api/rtkQuery'
import { DataTable } from '@shared/ui/table'

import { ROWS_PER_PAGE_OPTIONS } from './_shared/constants'
import { Radio } from './_shared/styles'
import { INIT_PAGE, LIST_PER_PAGE } from '../../_shared/constants'
import type { ActionSendLineNodeData } from '../actionSendLineNodeSchema'

type Props = {
  super8TemplateConfig: ActionSendLineNodeData['super8Config']
  integrationId: number
  isEditable: boolean
  setTemplateConfig: (config: ActionSendLineNodeData['super8Config']) => void
}

export const Super8 = memo(
  ({
    super8TemplateConfig,
    integrationId,
    isEditable,
    setTemplateConfig,
  }: Props) => {
    const { t } = useTranslation(['workflow'])

    const [page, setPage] = useState<number>(INIT_PAGE)
    const [perPage, setPerPage] = useState<number>(LIST_PER_PAGE)

    const {
      data: super8TemplateListData = INITIAL_PAGINATION_RESPONSE,
      isFetching: isFetchingTemplateList,
      isError: isErrorTemplateList,
    } = useGetSuper8TemplateListQuery({
      page,
      perPage,
      integrationId,
    })

    const dataTableRows = useMemo(
      () =>
        super8TemplateListData.items.map(x => ({
          ...x,
          isDisabled: !isEditable,
        })),
      [isEditable, super8TemplateListData.items]
    )

    useEffect(() => {
      if (
        dataTableRows.length <= 0 ||
        super8TemplateConfig ||
        isFetchingTemplateList ||
        isErrorTemplateList
      ) {
        return
      }

      setTemplateConfig({ templateId: dataTableRows[0].id })
    }, [
      dataTableRows,
      isFetchingTemplateList,
      isErrorTemplateList,
      super8TemplateConfig,
      setTemplateConfig,
    ])

    const handleRowsPerPageChange = (rowsPerPage: number) => {
      setPage(1)
      setPerPage(rowsPerPage)
    }

    if (isErrorTemplateList) {
      return null
    }

    return (
      <>
        <Box mb={2} fontWeight="medium">
          {t('workflow:node_action_line_template')}
        </Box>

        <Box mb={2}>
          <DataTable
            columns={[
              {
                field: 'id',
                headerName: '',
                colRatio: 0.5,
                valueFormatter: (_, { id, isDisabled }) => (
                  <Radio
                    color="primary"
                    checked={super8TemplateConfig?.templateId === id}
                    disabled={isDisabled}
                  />
                ),
              },
              {
                field: 'id',
                headerName: 'content_template_id',
                colRatio: 0.5,
              },
              { field: 'name', headerName: 'content_template_name' },
            ]}
            containerType="border"
            count={super8TemplateListData.totalCount}
            isLoading={isFetchingTemplateList}
            isRowClickable
            page={page - 1}
            rows={dataTableRows}
            rowsPerPage={perPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onPageChange={newPage => setPage(newPage + 1)}
            onRowsPerPageChange={handleRowsPerPageChange}
            onRowClick={({ id }) =>
              setTemplateConfig({ templateId: String(id) })
            }
          />
        </Box>
      </>
    )
  }
)

export default Super8
