import * as yup from 'yup'

import i18n from '@i18n'
import type {
  CoolBeWyethTemplateType,
  LineProvider,
} from '@shared/api/rtkQuery'

import { CAMPAIGN_NAME_MAX_LENGTH } from '../_shared/constants'
import type { TimeData } from '../_shared/Timing/timeReducer'

type BasicConfig<IType> = {
  templateId: IType
}

export type CoolbeWyethConfig = BasicConfig<string> & {
  messageTemplateType: CoolBeWyethTemplateType
}

export type OmnichatConfig = BasicConfig<string> & {
  groupId: string
}

export type ActionSendLineNodeData = {
  campaignName?: string
  reactionTiming?: TimeData
  msgChannelId?: number
  maacConfig?: BasicConfig<number>
  super8Config?: BasicConfig<string>
  trialConfig?: BasicConfig<string>
  coolbeWyethConfig?: CoolbeWyethConfig
  lineProvider?: LineProvider
  omnichatConfig?: OmnichatConfig
}

export const actionSendLineNodeSchema = yup
  .object<ActionSendLineNodeData>()
  .shape({
    campaignName: yup
      .string()
      .required()
      .max(CAMPAIGN_NAME_MAX_LENGTH, () =>
        i18n.t('common:errors.max_count', { count: CAMPAIGN_NAME_MAX_LENGTH })
      ),
    reactionTiming: yup.mixed<TimeData>().nullable(),
    msgChannelId: yup.number().required(),
    maacConfig: yup.object<BasicConfig<number>>().when('lineProvider', {
      is: 'maac',
      then: () =>
        yup.object<BasicConfig<number>>().shape({
          templateId: yup.number().required(),
        }),
    }),
    super8Config: yup.object<BasicConfig<string>>().when('lineProvider', {
      is: 'super8',
      then: () =>
        yup
          .object<BasicConfig<string>>()
          .shape({ templateId: yup.string().required() }),
    }),
    coolbeWyethConfig: yup.object<CoolbeWyethConfig>().when('lineProvider', {
      is: 'coolbe_wyeth',
      then: () =>
        yup.object<CoolbeWyethConfig>().shape({
          templateId: yup.string().required(),
          messageTemplateType: yup.string().required(),
        }),
    }),
    omnichatConfig: yup.object<OmnichatConfig>().when('lineProvider', {
      is: 'omnichat',
      then: () =>
        yup.object<OmnichatConfig>().shape({
          templateId: yup.string().required(),
          groupId: yup.string().required(),
        }),
    }),

    trialConfig: yup.object<BasicConfig<string>>().nullable(),
  })
