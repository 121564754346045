import { useEffect } from 'react'

export const useBeforeUnload = (file: File | null) => {
  useEffect(() => {
    // 若已經有檔案上傳至瀏覽器，重整或是關閉網頁會跳提示
    const handleWindowBeforeUnload = (e: BeforeUnloadEvent) => {
      if (file) {
        e.preventDefault()
        e.returnValue = ''
        return ''
      }
    }

    window.addEventListener('beforeunload', handleWindowBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleWindowBeforeUnload)
    }
  }, [file])
}
