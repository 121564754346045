import camelCase from 'lodash/fp/camelCase'

import type {
  CampaignMediumEvent,
  CampaignsMediumActionMappingInfo,
  MediumType,
} from '@shared/api/rtkQuery'

/**
 * 把
 * {
      mediumType: 'email',
      thirdPartyService: [
        {
          provider: 'ses',
          events: ['unsubscription', 'reception', 'complaint', 'sent', 'open', 'skip', 'click', 'bounce'],
        },
        {
          provider: 'newsleopard',
          events: ['unsubscription', 'reception', 'complaint', 'sent', 'open', 'skip', 'click', 'bounce'],
        },
      ],
    },
 * 整理成
 * {
 *   email: {
      unsubscription: [ "sesConfig", "newsleopardConfig" ],
      reception: [ "sesConfig", "newsleopardConfig" ],
      complaint: [ "sesConfig", "newsleopardConfig" ],
      sent: [ "sesConfig", "newsleopardConfig" ],
      open: [ "sesConfig", "newsleopardConfig" ],
      skip: [ "sesConfig", "newsleopardConfig" ],
      click: [ "sesConfig", "newsleopardConfig" ],
      bounce: [ "sesConfig", "newsleopardConfig" ]
    }
  }
 */
const mapCampaignsMediumActionMappingInfoByMediumEvents = (
  data?: CampaignsMediumActionMappingInfo
) =>
  data?.mediums.reduce((collection, current) => {
    collection[current.mediumType] = current.thirdPartyService.reduce(
      (eventMap, service) => {
        service.events.forEach(event => {
          if (!eventMap[event]) {
            eventMap[event] = []
          }

          // 拿來找 useGetWorkflowListQuery -> campaign.sentConfig[provider_config]
          // ex. ses -> ses_config -> sesConfig
          eventMap[event].push(camelCase(`${service.provider}_config`))
        })

        return eventMap
      },
      {} as Record<CampaignMediumEvent, string[]>
    )

    return collection
  }, {} as Record<MediumType, Record<CampaignMediumEvent, string[]>>)

export default mapCampaignsMediumActionMappingInfoByMediumEvents
