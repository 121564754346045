import type { Design } from '@entities/mediumTemplates'
import blankPath from '@shared/assets/images/contentTemplateDefaultExamples/blank.jpg'
import oneColumnPath from '@shared/assets/images/contentTemplateDefaultExamples/one_column.jpg'
import oneToThreeColumnPath from '@shared/assets/images/contentTemplateDefaultExamples/one_to_three_column.jpg'
import oneToTwoColumnPath from '@shared/assets/images/contentTemplateDefaultExamples/one_to_two_column.jpg'
import prodListPath from '@shared/assets/images/contentTemplateDefaultExamples/prod_list.jpg'

export type TemplateType =
  | 'blank'
  | 'one_column'
  | 'one_to_two_column'
  | 'one_to_three_column'
  | 'product_list'
  | 'unpurchased_product_list'

export type TemplateEmail = {
  id: number
  title: string
  editorJson?: Design
  previewUrl: string
}

const templates: TemplateEmail[] = [
  {
    id: 1,
    title: 'blank',
    editorJson: {
      counters: {
        u_column: 1,
        u_row: 1,
      },
      body: {
        id: undefined,
        rows: [
          {
            id: 'PI56rI__-q',
            cells: [1],
            columns: [
              {
                id: 'tu9d7Ny0bs',
                contents: [],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: true,
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          preheaderText: '',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
        },
      },
    },
    previewUrl: blankPath,
  },
  {
    id: 2,
    title: 'one_column',
    editorJson: {
      counters: {
        u_column: 1,
        u_row: 1,
        u_content_image: 1,
        u_content_text: 2,
        u_content_heading: 1,
        u_content_divider: 2,
      },
      body: {
        id: undefined,
        rows: [
          {
            id: 'f-Vv9LdX-I',
            cells: [1],
            columns: [
              {
                id: 'xVXBT6EorH',
                contents: [
                  {
                    id: 'qKEL5Pgvp2',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '品牌 logo',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_1',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'aHvFV9DUhv',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_1',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'emAKKRPgJ9',
                    type: 'heading',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      headingType: 'h1',
                      fontFamily: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                      },
                      fontSize: '22px',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_heading_1',
                        htmlClassNames: 'u_content_heading',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '主題標題',
                    },
                  },
                  {
                    id: 'bV-VedphHn',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_1',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">內文，請把您要傳遞給會員的內容放在這邊，並透過編輯器的功能，調整內文設計與格式。</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">並可透過「顧客資料變數功能」，將 CDP 內的顧客資料變數套用，讓每一個會員收到客製化的專屬訊息。</p>',
                    },
                  },
                  {
                    id: 'NtRVVK0dzE',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_2',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'Lb3VLkLzFz',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_2',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">信件頁尾：建議放入官方信箱，社群網站等等聯繫資訊。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: true,
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          preheaderText: '',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
        },
      },
    },
    previewUrl: oneColumnPath,
  },
  {
    id: 3,
    title: 'one_to_two_column',
    editorJson: {
      counters: {
        u_column: 6,
        u_row: 5,
        u_content_image: 8,
        u_content_heading: 1,
        u_content_text: 8,
        u_content_divider: 2,
      },
      body: {
        id: undefined,
        rows: [
          {
            id: 'bM4GdxMdjP',
            cells: [1],
            columns: [
              {
                id: 'QHGUrjwJJC',
                contents: [
                  {
                    id: 'B-4egPB4Eu',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '品牌 logo',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_1',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'tZjKUVvUxr',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_1',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'xHX85xk7-3',
            cells: [1],
            columns: [
              {
                id: 'O5hj2ZlNt4',
                contents: [
                  {
                    id: 'eYlbNxqiph',
                    type: 'heading',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      headingType: 'h1',
                      fontFamily: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                      },
                      fontSize: '22px',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_heading_1',
                        htmlClassNames: 'u_content_heading',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '主題標題',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_4',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_3',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: '8a3o9LOXtt',
            cells: [1],
            columns: [
              {
                id: 'wDHqMTpAk5',
                contents: [
                  {
                    id: 'XssYY9Awur',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_1',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">內文，請把您要傳遞給會員的內容放在這邊，並透過編輯器的功能，調整內文設計與格式。</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">並可透過「顧客資料變數功能」，將 CDP 內的顧客資料變數套用，讓每一個會員收到客製化的專屬訊息。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_5',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_4',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'M3-j7-BbmS',
            cells: [1, 1],
            columns: [
              {
                id: 're_o0FUUUz',
                contents: [
                  {
                    id: 'GXSKQjKVfX',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_3',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: '2WIzFsdHYW',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_2',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: '7YXr-1MAzg',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_5',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'nxCfGL9va2',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_4',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: 'lh7l4gc2oB',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_7',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'WVrIl3ui7S',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_6',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_2',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: '77YhB6acFA',
                contents: [
                  {
                    id: 'y8oEHSbWov',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_4',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'Q5FPoxdmr5',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_3',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: '-24zTNBnRg',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_6',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'zoiRpiT4L-',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_5',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: 'kcU5aoXlIl',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_8',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'REKktZHbGK',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_7',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_3',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_2',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'gw1aWbd1B6',
            cells: [1],
            columns: [
              {
                id: 'G4ZMlrfqSq',
                contents: [
                  {
                    id: 'r0H-0h9Hx8',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_2',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'G6BSgX3Xem',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_8',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">信件頁尾：建議放入官方信箱，社群網站等等聯繫資訊。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_6',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_5',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: true,
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          preheaderText: '',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
        },
      },
    },
    previewUrl: oneToTwoColumnPath,
  },
  {
    id: 4,
    title: 'one_to_three_column',
    editorJson: {
      counters: {
        u_column: 18,
        u_row: 9,
        u_content_image: 14,
        u_content_heading: 1,
        u_content_text: 14,
        u_content_divider: 2,
      },
      body: {
        id: undefined,
        rows: [
          {
            id: 'bM4GdxMdjP',
            cells: [1],
            columns: [
              {
                id: 'QHGUrjwJJC',
                contents: [
                  {
                    id: 'B-4egPB4Eu',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '品牌 logo',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_1',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'tZjKUVvUxr',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_1',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'xHX85xk7-3',
            cells: [1],
            columns: [
              {
                id: 'O5hj2ZlNt4',
                contents: [
                  {
                    id: 'eYlbNxqiph',
                    type: 'heading',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      headingType: 'h1',
                      fontFamily: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                      },
                      fontSize: '22px',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_heading_1',
                        htmlClassNames: 'u_content_heading',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '主題標題',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_4',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_3',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: '8a3o9LOXtt',
            cells: [1],
            columns: [
              {
                id: 'wDHqMTpAk5',
                contents: [
                  {
                    id: 'XssYY9Awur',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_1',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">內文，請把您要傳遞給會員的內容放在這邊，並透過編輯器的功能，調整內文設計與格式。</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">並可透過「顧客資料變數功能」，將 CDP 內的顧客資料變數套用，讓每一個會員收到客製化的專屬訊息。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_5',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_4',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'u6vQcxLhIX',
            cells: [1, 1, 1],
            columns: [
              {
                id: 'pmuSNt-1i3',
                contents: [
                  {
                    id: 'J9plMF1K-r',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_9',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_7',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'XLhnQF4VRm',
                contents: [
                  {
                    id: 'IGv4xIRgMZ',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_10',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_8',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'JNXV-W2aGn',
                contents: [
                  {
                    id: 'IwEAf03pu0',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_11',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_9',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_6',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'oohy5G8WR-',
            cells: [1, 1, 1],
            columns: [
              {
                id: 'zjqyb_1Ypk',
                contents: [
                  {
                    id: 'TR32v_EkD0',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_9',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_16',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'JKheDhXWim',
                contents: [
                  {
                    id: 'XD6hXCeAFq',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_10',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_17',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'ehbkstT8iX',
                contents: [
                  {
                    id: '6N_buPbeql',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_11',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_18',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_9',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'kTR-lfav9L',
            cells: [1, 1, 1],
            columns: [
              {
                id: '9FHkozuoen',
                contents: [
                  {
                    id: 'uic0frwfGX',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_12',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_13',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'dAql47iytj',
                contents: [
                  {
                    id: 'yFT1KMEfI3',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_13',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_14',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'hjwqyVUlrN',
                contents: [
                  {
                    id: 'SyzpHM84V8',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_14',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_15',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_8',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'c_zZMYpZnO',
            cells: [1, 1, 1],
            columns: [
              {
                id: 'j1VTuTJ-2f',
                contents: [
                  {
                    id: 'kC2x-B16nt',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_12',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_10',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'o3NVDlmCGi',
                contents: [
                  {
                    id: 'Jw9zIJz6wR',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_13',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_11',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'bBXXDCd2FQ',
                contents: [
                  {
                    id: 'R_5tjrvGRB',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_14',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配上方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_12',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_7',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'gw1aWbd1B6',
            cells: [1],
            columns: [
              {
                id: 'G4ZMlrfqSq',
                contents: [
                  {
                    id: 'r0H-0h9Hx8',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_2',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'G6BSgX3Xem',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_8',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">信件頁尾：建議放入官方信箱，社群網站等等聯繫資訊。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_6',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_5',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: true,
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          preheaderText: '',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
        },
      },
    },
    previewUrl: oneToThreeColumnPath,
  },
  {
    id: 5,
    title: 'product_list',
    editorJson: {
      counters: {
        u_column: 8,
        u_row: 5,
        u_content_image: 6,
        u_content_text: 8,
        u_content_heading: 1,
        u_content_divider: 3,
      },
      body: {
        id: undefined,
        rows: [
          {
            id: '',
            cells: [1],
            columns: [
              {
                id: 'xVXBT6EorH',
                contents: [
                  {
                    id: 'qKEL5Pgvp2',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '品牌 logo',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_1',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'aHvFV9DUhv',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_1',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'emAKKRPgJ9',
                    type: 'heading',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      headingType: 'h1',
                      fontFamily: {
                        label: 'Arial',
                        value: 'arial,helvetica,sans-serif',
                      },
                      fontSize: '22px',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_heading_1',
                        htmlClassNames: 'u_content_heading',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '主題標題',
                    },
                  },
                  {
                    id: 'bV-VedphHn',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_1',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">全信摘要主文，請把您要傳遞給會員的內容放在這邊，並透過編輯器的功能，調整內文設計與格式。</p>\n<p style="font-size: 14px; line-height: 140%;">&nbsp;</p>\n<p style="font-size: 14px; line-height: 140%;">並可透過「顧客資料變數功能」，將 CDP 內的顧客資料變數套用，讓每一個會員收到客製化的專屬訊息。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'UbVaMh5WK3',
            cells: [1, 2],
            columns: [
              {
                id: 'rvMqF_qTYF',
                contents: [
                  {
                    id: 'jlbzvWJpPU',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_2',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_2',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'BX_e4dOuFZ',
                contents: [
                  {
                    id: 'g7JjRiFQUR',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_3',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配左方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_3',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_2',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'CSo87LWySN',
            cells: [1, 2],
            columns: [
              {
                id: 'IofsBBoDM-',
                contents: [
                  {
                    id: 't6KFRuvETF',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_3',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_4',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'ls3a42XZwR',
                contents: [
                  {
                    id: 'qTJzlg-2NR',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_4',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配左方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_5',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_3',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'yAoNMCyg-g',
            cells: [1, 2],
            columns: [
              {
                id: 'p-Rt30ikYp',
                contents: [
                  {
                    id: '5aT3h_qqbj',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_4',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'boDzecD_vx',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_5',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'qBZe3Iku2m',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://cdn.tools.unlayer.com/image/placeholder.png',
                        width: 800,
                        height: 200,
                      },
                      textAlign: 'center',
                      altText: '',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_6',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_6',
                    htmlClassNames: 'u_column',
                  },
                },
              },
              {
                id: 'p7Un-kAnRw',
                contents: [
                  {
                    id: 'wABX09nB1b',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_5',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配左方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: 'r503YrG5tp',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_8',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配左方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                  {
                    id: 'Vjo0mmrQJT',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_7',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">搭配左方圖片的介紹內文，譬如促銷商品，或近期重大消息之說明</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_7',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_4',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
          {
            id: 'IWj3Wr23Ls',
            cells: [1],
            columns: [
              {
                id: 'lL7WdWCb5T',
                contents: [
                  {
                    id: '5crdEf77DH',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_3',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: '1RFbed87E_',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_6',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="font-size: 14px; line-height: 140%;">信件頁尾：建議放入官方信箱，社群網站等等聯繫資訊。</p>',
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_8',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_5',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: true,
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          preheaderText: '',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
        },
      },
    },
    previewUrl: prodListPath,
  },
  {
    id: 6,
    title: 'unpurchased_product_list',
    editorJson: {
      counters: {
        u_column: 10,
        u_row: 6,
        u_content_custom_product: 1,
        u_content_image: 2,
        u_content_text: 4,
        u_content_divider: 1,
        u_content_heading: 4,
        u_content_button: 2,
        u_content_custom_unpurchased_product: 16,
        u_content_menu: 1,
        u_content_custom_ikala_custom_tool: 4,
        u_content_custom_commercial_tool: 4,
      },
      body: {
        id: 'WLbhDpY_mb',
        rows: [
          {
            id: '5rBNUf5rm9',
            cells: [1],
            columns: [
              {
                id: 'N6f-loAryu',
                contents: [
                  {
                    id: 'RYngqatlf2',
                    type: 'image',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      src: {
                        url: 'https://via.placeholder.com/192x68/FFF/000?text=your%20logo',
                        width: 192,
                        height: 68,
                      },
                      textAlign: 'center',
                      altText: 'your logo',
                      action: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_image_1',
                        htmlClassNames: 'u_content_image',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      _override: {
                        mobile: {},
                      },
                    },
                  },
                  {
                    id: 'cc5mJN4Ve7',
                    type: 'text',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      fontSize: '14px',
                      textAlign: 'left',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_text_1',
                        htmlClassNames: 'u_content_text',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<p style="line-height: 140%;">親愛的顧客您好，您在購物車內尚有商品未結帳：</p>',
                    },
                  },
                  {
                    id: '4Z9Vl46flX',
                    type: 'divider',
                    values: {
                      width: '100%',
                      border: {
                        borderTopWidth: '1px',
                        borderTopStyle: 'solid',
                        borderTopColor: '#BBBBBB',
                      },
                      textAlign: 'center',
                      containerPadding: '10px',
                      anchor: '',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_divider_1',
                        htmlClassNames: 'u_content_divider',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                    },
                  },
                  {
                    id: 'kEDOh9L0Z8',
                    type: 'heading',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      headingType: 'h1',
                      fontWeight: 700,
                      fontSize: '24px',
                      textAlign: 'center',
                      lineHeight: '140%',
                      linkStyle: {
                        inherit: true,
                        linkColor: '#0000ee',
                        linkHoverColor: '#0000ee',
                        linkUnderline: true,
                        linkHoverUnderline: true,
                      },
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_heading_1',
                        htmlClassNames: 'u_content_heading',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<span><span><span><span><span><span><span>購物車中尚有商品未結帳</span></span></span></span></span></span></span>',
                    },
                  },
                  {
                    id: 'gA_pXIzcHE',
                    type: 'custom',
                    slug: 'commercial_tool',
                    values: {
                      displayCondition: null,
                      containerPadding: '10px',
                      anchor: '',
                      _meta: {
                        htmlID: 'u_content_custom_commercial_tool_1',
                        htmlClassNames: 'u_content_custom_commercial_tool',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      componentType: 'unpurchasedProduct',
                      productImage: {
                        url: 'https://via.placeholder.com/384x384?text=Product',
                      },
                      productName: '商品',
                      productPrice: '0',
                      mergeTagGroup: 'personalized_replacement',
                      mergeTagRule: 'repeat',
                    },
                  },
                  {
                    id: 'pyXecGmWU5',
                    type: 'button',
                    values: {
                      containerPadding: '10px',
                      anchor: '',
                      href: {
                        name: 'web',
                        values: {
                          href: '',
                          target: '_blank',
                        },
                      },
                      buttonColors: {
                        color: '#FFFFFF',
                        backgroundColor: '#000000',
                        hoverColor: '#FFFFFF',
                        hoverBackgroundColor: '#3AAEE0',
                      },
                      size: {
                        autoWidth: true,
                        width: '100%',
                      },
                      fontSize: '14px',
                      textAlign: 'center',
                      lineHeight: '120%',
                      padding: '10px 20px',
                      border: {},
                      borderRadius: '4px',
                      hideDesktop: false,
                      displayCondition: null,
                      _meta: {
                        htmlID: 'u_content_button_1',
                        htmlClassNames: 'u_content_button',
                      },
                      selectable: true,
                      draggable: true,
                      duplicatable: true,
                      deletable: true,
                      hideable: true,
                      text: '<span style="line-height: 16.8px;">來去看看</span>',
                      calculatedWidth: 96,
                      calculatedHeight: 37,
                    },
                  },
                ],
                values: {
                  backgroundColor: '',
                  padding: '0px',
                  border: {},
                  borderRadius: '0px',
                  _meta: {
                    htmlID: 'u_column_1',
                    htmlClassNames: 'u_column',
                  },
                },
              },
            ],
            values: {
              displayCondition: null,
              columns: false,
              backgroundColor: '',
              columnsBackgroundColor: '',
              backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: 'no-repeat',
                size: 'custom',
                position: 'center',
                customPosition: ['50%', '50%'],
              },
              padding: '0px',
              anchor: '',
              hideDesktop: false,
              _meta: {
                htmlID: 'u_row_1',
                htmlClassNames: 'u_row',
              },
              selectable: true,
              draggable: true,
              duplicatable: true,
              deletable: true,
              hideable: true,
            },
          },
        ],
        values: {
          popupPosition: 'center',
          popupWidth: '600px',
          popupHeight: 'auto',
          borderRadius: '10px',
          contentAlign: 'center',
          contentVerticalAlign: 'center',
          contentWidth: '500px',
          fontFamily: {
            label: 'Arial',
            value: 'arial,helvetica,sans-serif',
          },
          textColor: '#000000',
          popupBackgroundColor: '#FFFFFF',
          popupBackgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'cover',
            position: 'center',
          },
          popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
          popupCloseButton_position: 'top-right',
          popupCloseButton_backgroundColor: '#DDDDDD',
          popupCloseButton_iconColor: '#000000',
          popupCloseButton_borderRadius: '0px',
          popupCloseButton_margin: '0px',
          popupCloseButton_action: {
            name: 'close_popup',
            attrs: {
              onClick:
                "document.querySelector('.u-popup-container').style.display = 'none';",
            },
          },
          backgroundColor: '#ffffff',
          linkStyle: {
            body: true,
            linkColor: '#0000ee',
            linkHoverColor: '#0000ee',
            linkUnderline: true,
            linkHoverUnderline: true,
          },
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: 'no-repeat',
            size: 'custom',
            position: 'center',
          },
          _meta: {
            htmlID: 'u_body',
            htmlClassNames: 'u_body',
          },
          preheaderText: 'E',
        },
      },
      schemaVersion: 16,
    },
    previewUrl: prodListPath,
  },
]

export default templates
