import camelcaseKeys from 'camelcase-keys'

import type { ReplacementData } from '@shared/api/rtkQuery'

import type { Props as MetadataTagNodeType } from '../../metadataTag/nodes/MetadataTagComponent'
import { NODE_TYPE as METADATA_TAG_NODE_TYPE } from '../../metadataTag/nodes/MetadataTagNode'
import type { Props as UtmNodeType } from '../../utm/nodes/UtmComponent'
import { NODE_TYPE as UTM_NODE_TYPE } from '../../utm/nodes/UtmNode'
import type { NodeType } from '../type'
import { getMetadataTagDisplayName } from './getMetadataTagDisplayName'

export const composeEditorState = (
  content: string,
  replacementData: ReplacementData,
  metadataTags: { displayName: string; id: string }[]
): NodeType[] => {
  return content.split('\n').reduce((nodeList, newLine) => {
    if (!newLine) {
      nodeList.push({
        indent: 0,
        type: 'paragraph',
        children: [],
      })

      return nodeList
    }

    if (newLine) {
      // 所有的可取代變數都會有{{}}包起來, 用正規表達式找出所有的變數
      const paragraph = newLine
        .split(/{{(.+?)}}/g)
        .reduce((paragraphNodeList, replacementDataKey) => {
          if (replacementDataKey) {
            const nodeReplacementData = replacementData?.[replacementDataKey]

            if (!nodeReplacementData) {
              paragraphNodeList.push({
                type: 'text',
                text: replacementDataKey,
              })

              return paragraphNodeList
            }

            if (
              nodeReplacementData.type === 'action_url' ||
              nodeReplacementData.type === 'tracing_url'
            ) {
              paragraphNodeList.push({
                type: UTM_NODE_TYPE,
                replacementKey: replacementDataKey,
                replacementData: camelcaseKeys(nodeReplacementData, {
                  deep: true,
                }),
              } as Omit<UtmNodeType, 'nodeKey'>)

              return paragraphNodeList
            }

            if (
              nodeReplacementData.type === 'metadata' ||
              nodeReplacementData.type === 'unpurchased_products'
            ) {
              const metadataTag = metadataTags.find(
                item => item.id === replacementDataKey
              )

              if (metadataTag) {
                paragraphNodeList.push({
                  type: METADATA_TAG_NODE_TYPE,
                  displayName: getMetadataTagDisplayName(
                    metadataTag.displayName,
                    metadataTag.id
                  ),
                  replacementKey: metadataTag.id,
                } as Omit<MetadataTagNodeType, 'nodeKey'>)

                return paragraphNodeList
              }
            }
          }

          return paragraphNodeList
        }, [] as NodeType['children'])

      nodeList.push({
        type: 'paragraph',
        indent: 0,
        children: paragraph,
      })
    }

    return nodeList
  }, [] as NodeType[])
}
