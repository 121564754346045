import type { CampaignEventCampaignGroupFilterNode } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'

import {
  TRIGGER_GROUP_CAMPAIGN_INDEX,
  TRIGGER_GROUP_TRACING_URL_INDEX,
  TRIGGER_GROUP_WORKFLOW_INDEX,
} from '../../constants'

const TRIGGER_GROUP_INDEX_DICT = {
  workflow: TRIGGER_GROUP_WORKFLOW_INDEX,
  campaign: TRIGGER_GROUP_CAMPAIGN_INDEX,
  tracingUrl: TRIGGER_GROUP_TRACING_URL_INDEX,
}

const getSafeTriggerGroupParam = (
  nodes: CampaignEventCampaignGroupFilterNode[],
  field: keyof typeof TRIGGER_GROUP_INDEX_DICT
) => {
  const fieldIndex = TRIGGER_GROUP_INDEX_DICT[field]

  const workflowId = nodes[fieldIndex]?.params?.[0]
  return getIntSafe(workflowId, undefined) || undefined
}

export default getSafeTriggerGroupParam
