import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'
import Box from '@mui/material/Box'

import type { NodeType } from '@shared/api/rtkQuery'

import BasicNode, { TooltipIcon } from '../_shared/BasicNode'
import { dayOfWeekMap } from '../_shared/Timing/_shared/constants'
import type { BasicNodeOperation } from '../../hooks/useWorkflow'
import TimingNodeEditDialog, { TimingNodeType } from './TimingNodeEditDialog'
import { TimingNodeSchema, timingNodeSchema } from './timingNodeSchema'

type Props = BasicNodeOperation<TimingNodeSchema>

const TimingNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const { t } = useTranslation(['workflow'])
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const [timingNodeType, setTimingNodeType] = useState<TimingNodeType>(
      type === 'timing-best' ? 'best' : 'manual'
    )

    const handleConfirm = (values: TimingNodeSchema) => {
      const { type = 'manual', ...rest } = values

      setTimingNodeType(type)
      onNodeTypeChange(type === 'best' ? 'timing-best' : 'timing')

      onChange(rest)
      setIsOpenDialog(false)
    }

    const dayOfWeekMapMemo = useMemo<{ [name: number]: string }>(
      () => dayOfWeekMap(),
      []
    )

    const isNeedShowHourAndMinute = useMemo(
      () => timingNodeType !== 'best',
      [timingNodeType]
    )

    return (
      <>
        <BasicNode
          nodeType={type as NodeType}
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isSettled={timingNodeSchema.isValidSync(initialValues)}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            {timingNodeType === 'best' &&
              t('workflow:node_timing_executive_type_best')}
            {initialValues.duration && (
              <>
                {isNeedShowHourAndMinute
                  ? t('workflow:node_timing_delay_rule_duration', {
                      day: initialValues.duration.day,
                      hour: initialValues.duration.hour,
                      minute: initialValues.duration.minute,
                    })
                  : t('workflow:node_timing_delay_rule_duration_best', {
                      day: initialValues.duration.day,
                    })}
              </>
            )}
            {initialValues.specifiedDay && (
              <>
                {t('workflow:node_timing_delay_rule_specified_day')}
                {initialValues.specifiedDay.year}-
                {initialValues.specifiedDay.month}-
                {initialValues.specifiedDay.day}{' '}
                {isNeedShowHourAndMinute &&
                  `${initialValues.at?.hour?.toString().padStart(2, '0')} :
                  ${initialValues.at?.minute?.toString().padStart(2, '0')}`}
              </>
            )}
            {initialValues.dayOfWeek && (
              <>
                {initialValues.dayOfWeek.length ===
                Object.keys(dayOfWeekMapMemo).length ? (
                  <>
                    {t('workflow:node_timing_delay_rule_specified_day')}{' '}
                    {t('workflow:node_timing_delay_unit.weekday.everyday')}
                  </>
                ) : (
                  <>
                    {t('workflow:node_timing_delay_rule_day_of_week')}
                    {initialValues.dayOfWeek
                      .map(day => dayOfWeekMapMemo[day])
                      .join('/')}
                  </>
                )}
                {isNeedShowHourAndMinute &&
                  ` ${initialValues.at?.hour
                    ?.toString()
                    .padStart(2, '0')}:${initialValues.at?.minute
                    ?.toString()
                    .padStart(2, '0')}`}
              </>
            )}

            <TooltipIcon title={id} />
          </Box>
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={timingNodeType}
        />

        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />

        {isOpenDialog && (
          <TimingNodeEditDialog
            isOpen={isOpenDialog}
            initialValues={{ ...initialValues, type: timingNodeType }}
            onConfirm={handleConfirm}
            onClose={() => setIsOpenDialog(false)}
          />
        )}
      </>
    )
  }
)

export default TimingNode
