export type TimeData = {
  duration?: {
    day: number
    hour: number
    minute: number
  }
  at?: {
    hour: number
    minute: number
  }
  specifiedDay?: {
    year: number
    month: number
    day: number
  }
  dayOfWeek?: number[]
}

export type TimeType = 'duration' | 'dayOfWeek' | 'specifiedDay'

type Action = 'update'
type State = {
  timeType: TimeType
  timeData: TimeData
}

export const INIT_STATE: State = {
  timeType: 'duration',
  timeData: {
    duration: { day: 0, hour: 0, minute: 0 },
  },
}

export const getInitState = (timeData: TimeData): State => {
  if (timeData.duration) {
    return {
      timeType: 'duration',
      timeData,
    }
  }

  if (timeData.dayOfWeek) {
    return {
      timeType: 'dayOfWeek',
      timeData,
    }
  }

  if (timeData.specifiedDay) {
    return {
      timeType: 'specifiedDay',
      timeData,
    }
  }

  return INIT_STATE
}

export const reducer = (
  state: State,
  action: {
    type: Action
    payload: State
  }
) => {
  switch (action.type) {
    case 'update':
      return action.payload

    default:
      return state
  }
}
