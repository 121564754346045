import type { OperatorType } from '@shared/api/rtkQuery'

export const operators: OperatorType[] = [
  'absolute_higher',
  'absolute_lower',
  'relative_increase',
  'relative_decrease',
  'relative_percentage_increase',
  'relative_percentage_decrease',
]

export type DaysBeforeType = '1' | '7'

export type PeriodsType = '1'

export const daysBefores: DaysBeforeType[] = ['1', '7']

export const periods: PeriodsType[] = ['1']
export const NO_DATA_ID = 0

export const STATUS_CODE = {
  DUPLICATE_NAME: 409,
}
