import type { Action, PayloadAction } from '@reduxjs/toolkit'
import type { Reducer } from 'use-immer'

import type { NotHappenedOperator, TimeRangeType } from '@shared/api/rtkQuery'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType, getToday } from '@shared/ui/DateRangePicker'

export type SimpleMeasureFilter = {
  eventName: string
  timeRangeType: TimeRangeType
  timeRangeParams: NumberOrString[]
  eventOperator: '' | NotHappenedOperator
}

export const createMeasureFilterInitialState = (
  eventName: string = ''
): SimpleMeasureFilter => {
  const today = getToday()

  return {
    eventName,
    timeRangeType: 'absolute_between',
    timeRangeParams: [today.nowStart.toISOString(), today.nowEnd.toISOString()],
    eventOperator: '',
  }
}

export const singleMeasureInitializer = (
  currState?: SimpleMeasureFilter
): SimpleMeasureFilter => currState || createMeasureFilterInitialState()

export type MeasureFilterAction =
  | PayloadAction<SimpleMeasureFilter, 'setMeasureFilterState'>
  | PayloadAction<string, 'updateEventName'>
  | PayloadAction<NumberOrString[], 'updateTimeRangeParams'>
  | PayloadAction<NotHappenedOperator | '', 'updateEventOperator'>
  | Action<'reset'>

export const reducer: Reducer<SimpleMeasureFilter, MeasureFilterAction> = (
  draft,
  action
) => {
  switch (action.type) {
    case 'updateEventName':
      draft.eventName = action.payload

      break

    case 'updateTimeRangeParams':
      draft.timeRangeType = getRangeType(action.payload)
      draft.timeRangeParams = action.payload
      break

    case 'updateEventOperator':
      draft.eventOperator = action.payload

      break

    case 'setMeasureFilterState':
      draft.eventName = action.payload.eventName
      draft.timeRangeType = action.payload.timeRangeType
      draft.timeRangeParams = action.payload.timeRangeParams
      draft.eventOperator = action.payload.eventOperator

      break

    case 'reset':
      return singleMeasureInitializer()

    default:
      throw new Error('SingleMeasureFilter: action type is undefined')
  }
}
