import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'

import type {
  NotificationRule,
  NotificationRuleList,
} from '@shared/api/rtkQuery'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import theme from '@theme'

import type { PeriodsType } from '../constant'

type Params = {
  openDropdownId: number
  setOpenDropdownId: React.Dispatch<React.SetStateAction<number>>
  setDeleteConfirmId: React.Dispatch<React.SetStateAction<number | undefined>>
}

const useColumns = ({
  openDropdownId,
  setOpenDropdownId,
  setDeleteConfirmId,
}: Params) => {
  const { t } = useTranslation(['analytics', 'common'])

  const columns: TableColumn<NotificationRuleList['items'][number]>[] = [
    {
      field: 'name',
      colRatio: 1.5,
      headerName: 'name',
    },
    {
      field: 'rule',
      colRatio: 1,
      headerName: 'notification_period',
      valueFormatter: value => {
        const rule = value as NotificationRule['rule']

        return (
          <>
            {t(
              `analytics:notificationRule.periodOptions.${
                String(rule.triggerTime.periodDays) as PeriodsType
              }`
            )}
          </>
        )
      },
    },
    {
      field: 'lastEditorUserEmail',
      colRatio: 1.5,
      headerName: 'last_edited_by',
    },
    {
      field: 'id',
      colRatio: 0.5,
      headerName: '',
      paddingTop: 0.5,
      paddingBottom: 0.5,
      valueFormatter: id => {
        return (
          <div
            onClick={event => {
              // 避免觸發 TableRow 的導頁行為
              event.stopPropagation()
            }}
          >
            <Dropdown
              isOpen={openDropdownId === (id as number)}
              placement="left-start"
              setIsOpen={currIsOpen => {
                if (currIsOpen === false) {
                  // 代表 clickAway
                  setOpenDropdownId(-1)
                  return
                }
                setOpenDropdownId(id as number)
              }}
              anchorElem={
                <IconButton>
                  <Icon
                    icon={'uil uil-ellipsis-v'}
                    fontSize="small"
                    color={theme.colors.textPrimaryBlue}
                  />
                </IconButton>
              }
            >
              <MenuList onMouseLeave={() => setOpenDropdownId(-1)}>
                <MenuItem
                  onClick={() => {
                    setDeleteConfirmId(id as number)
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              </MenuList>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return columns
}

export default useColumns
