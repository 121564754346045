import { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useImmerReducer } from 'use-immer'

import type { FunnelAnalyticsNode, PrivacyLevel } from '@shared/api/rtkQuery'
import { useSearchParams } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { getIntSafe } from '@shared/lib/utils/number'
import type { NumberOrString } from '@shared/lib/utils/type'
import { checkHasError } from '@shared/lib/utils/validation/form'

import {
  FunnelChartSettings,
  FunnelChartType,
  FunnelRuleFormValues,
} from '../../model/types'
import {
  createInitialFunnelAnalyticsNode,
  FilterError,
  funnelAnalyticsReducer,
  initializer,
} from '../../ui/FunnelForm/FunnelAnalyticsFilter'
import { createInitialFunnelChartSettings } from '../utils'
import { useLazyPreviewResponse } from '.'

type UseFunnelFormProps = {
  initialValues?: FunnelRuleFormValues
}

export const useFunnelForm = ({ initialValues }: UseFunnelFormProps) => {
  const history = useHistory()
  const urlQuery = useSearchParams()
  const defaultGroupId = getIntSafe(urlQuery.get('groupId'))

  // 避免使用者手動改 query 造成邏輯錯誤，限定 privacyLevel 只能是 public 或 private
  const defaultPrivacyLevel: PrivacyLevel =
    urlQuery.get('privacyLevel') === 'private' ? 'private' : 'public'

  const [chartSettings, setChartSettings] = useState<FunnelChartSettings>(
    createInitialFunnelChartSettings(defaultPrivacyLevel, defaultGroupId)
  )

  const [filterErrors, setFilterErrors] = useState<FilterError>({})
  const hasError = useMemo(() => checkHasError(filterErrors), [filterErrors])

  const [filter, dispatch] = useImmerReducer(
    funnelAnalyticsReducer,
    createInitialFunnelAnalyticsNode(),
    initializer
  )

  const {
    data: previewData,
    isFetching: isFetchingPreviewData,
    getFunnelRuleSummary,
  } = useLazyPreviewResponse()

  const onFunnelSummaryPreview = useCallback(
    (filter: FunnelAnalyticsNode) => {
      if (hasError) {
        return
      }

      getFunnelRuleSummary(filter)
    },
    [getFunnelRuleSummary, hasError]
  )

  const resetFormValues = useCallback(
    (values?: FunnelRuleFormValues) => {
      if (values === undefined) {
        // 重設表單
        dispatch({
          type: 'reset',
          payload: undefined,
        })

        setChartSettings(
          createInitialFunnelChartSettings(
            initialValues?.iam ? 'private' : 'public'
          )
        )

        history.replace(history.location.pathname)

        return
      }

      dispatch({
        type: 'reset',
        payload: values.filter,
      })

      setChartSettings({
        title: values.title,
        chartType: values.dashboardConfig.chartType,
        groupId: values.groupId,
        privacyLevel: values.iam ? 'private' : 'public',
      })

      // 取得圖表資訊
      getFunnelRuleSummary(values.filter)
    },
    [dispatch, initialValues?.iam, getFunnelRuleSummary, history]
  )

  const handleChartTypeChanged = useCallback(
    (currChartType: FunnelChartType) => {
      trackEvent('ChartViewToggled', {
        analysisType: 'funnel',
        chartType: currChartType,
      })

      setChartSettings(prev => ({
        ...prev,
        chartType: currChartType,
      }))
    },
    [setChartSettings]
  )

  const handleTimeRangeParamsChange = useCallback(
    (currTimeRangeParams: NumberOrString[]) => {
      dispatch({
        type: 'updateTimeRangeParams',
        payload: {
          timeRangeParams: currTimeRangeParams,
          onChanged: onFunnelSummaryPreview,
        },
      })
    },
    [dispatch, onFunnelSummaryPreview]
  )

  return {
    previewData,
    isFetchingPreviewData,
    filter,
    chartSettings,
    filterErrors,
    hasError,
    dispatch,
    onFunnelSummaryPreview,
    setChartSettings,
    setFilterErrors,
    resetFormValues,
    handleChartTypeChanged,
    handleTimeRangeParamsChange,
  }
}

export default useFunnelForm
