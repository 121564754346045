import { EventRuleNode } from '@shared/api/rtkQuery'
import { formatDateDisplay } from '@shared/lib/utils/time'

import { getDateByType } from './getDateByType'

export const getExportCsvFileName = (
  timeRangeParams: EventRuleNode['timeRangeParams'],
  type: 'event' | 'funnel' = 'event'
) => {
  const startDateFormatted = formatDateDisplay(
    getDateByType(timeRangeParams[0])
  )
  const endDateFormatted = formatDateDisplay(getDateByType(timeRangeParams[1]))

  return `${type}-analytics-${startDateFormatted}-${endDateFormatted}.csv`
}
