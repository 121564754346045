import { PropsWithChildren, useRef } from 'react'
import styled from '@emotion/styled'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'

import { Z_INDEX } from '@shared/model/constants/styles'
import ActionButton from '@shared/ui/Dropdown/ActionButton'
import type { Placement } from '@shared/ui/Popup'

const AnchorContainer = styled.div<{ isFullWidth?: boolean }>`
  display: inline-block;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
`

const Container = styled.div`
  margin-top: 4px;
`

type Props = {
  anchorElem?: JSX.Element
  anchorId?: string
  bgColor?: string
  enableResetButton?: boolean
  hasMinWidth?: boolean
  isDisabled?: boolean
  isError?: boolean
  isFullWidth?: boolean
  isOpen: boolean
  isShowFilterIcon?: boolean
  label?: string
  marginRightRatio?: number
  onValueChanged?: (value: string) => void
  placement?: Placement
  popperClassName?: string
  popperDisablePortal?: boolean
  popperWidth?: number | 'auto'
  popperZIndex?: number
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Dropdown = ({
  anchorElem,
  anchorId,
  bgColor,
  children,
  enableResetButton = false,
  hasMinWidth,
  isDisabled,
  isError,
  isFullWidth,
  isOpen,
  isShowFilterIcon,
  label = '',
  marginRightRatio,
  onValueChanged = () => {},
  placement = 'bottom-start',
  popperClassName = '',
  popperDisablePortal,
  popperWidth = 'auto',
  popperZIndex = Z_INDEX.popup,
  setIsOpen,
}: PropsWithChildren<Props>) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <AnchorContainer
        ref={anchorRef}
        isFullWidth={isFullWidth}
        onClick={() => {
          if (!isDisabled) {
            setIsOpen(true)
          }
        }}
      >
        {anchorElem || (
          <ActionButton
            isFullWidth={isFullWidth}
            anchorId={anchorId}
            label={label}
            bgColor={bgColor}
            isError={isError}
            isMinWidth={hasMinWidth}
            isShowFilterIcon={isShowFilterIcon}
            isDisabled={isDisabled}
            marginRightRatio={marginRightRatio}
            enableResetButton={enableResetButton}
            onReset={() => {
              onValueChanged('')
            }}
          />
        )}
      </AnchorContainer>
      <Popper
        className={popperClassName}
        open={isOpen}
        anchorEl={anchorRef.current}
        placement={placement}
        role={undefined}
        transition
        // ref: https://mui.com/zh/material-ui/react-popper/
        // 設定為 true 能讓彈窗盡量保持在瀏覽器可視範圍內
        // 此處設定為 false 是希望讓彈窗盡量維持原本的樣式，但空間不足時會疊加外層容器高度
        modifiers={[{ name: 'flip', enabled: false }]}
        disablePortal={popperDisablePortal}
        style={{ zIndex: popperZIndex, width: popperWidth }}
      >
        {({ TransitionProps }) => (
          <Grow
            // ref: https://mui.com/zh/material-ui/react-popper/#transitions
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' || placement === 'right-start'
                  ? 'top left'
                  : 'top right',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={event => {
                  // 點擊 anchor 不會觸發關閉選單的行為
                  if (anchorRef.current?.contains(event.target as Node)) {
                    return
                  }

                  setIsOpen(false)
                }}
              >
                <Container>{children}</Container>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default Dropdown
