import type { SearchColumnAlias } from '@shared/api/rtkQuery'
import { useSearchParams } from '@shared/lib/hooks/url'

import { SearchQueryEnum } from './_shared/constants'
import Landing from './Landing'
import UserTable from './UserTable'

const UserProfileList = () => {
  const searchParams = useSearchParams()
  const searchColumn = searchParams.get(
    SearchQueryEnum.filter
  ) as SearchColumnAlias
  const searchValue = searchParams.get(SearchQueryEnum.query)

  if (!searchColumn || !searchValue) {
    return <Landing />
  }

  return (
    <UserTable
      key={`${searchValue}-${searchColumn}`}
      searchColumn={searchColumn}
      searchValue={searchValue}
    />
  )
}

export default UserProfileList
