import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import { formatNumber, getFloatSafe } from '@shared/lib/utils/number'
import type { ChartData } from '@shared/ui/charts'
import theme from '@theme'

import {
  BarChartDisplayType,
  ChartContainer,
  checkHasGroup,
  fullDateDisplay,
  getHeight,
  getSparseXLables,
  getTooltipHTMLString,
  getTooltipHTMLStringBarGroup,
  getTransposeArray,
  simplifyDateDisplay,
  SizeMode,
  useChartDataSeriesGroupEntity,
} from './_shared'

type Props = {
  chartTitle?: string
  colors: string[]
  data: ChartData
  size?: SizeMode
  displayType?: BarChartDisplayType
}

export const BarChart = ({
  chartTitle = '',
  colors,
  data,
  size = 'large',
  displayType = 'bar',
}: Props) => {
  const { t } = useTranslation(['dateAndChart'])

  const { categories, series } = data ?? {
    categories: [],
    series: [],
  }

  const xAxisCategories = categories.map(simplifyDateDisplay)
  const fullHeaderDateDisplay = categories.map(fullDateDisplay)

  /*
    // example
    data = [
      {
        categories: ['1/1', '1/2', '1/3', '1/4'],
        series: [
          {
            name: '安安',
            data: [5, 6, 7, 8]
          },
          {
            name: '你好',
            data: [9, null, 3, 10]
          },
          {
            name: '嗎嗎',
            data: [31, 12, 30, 25]
          }
        ]
      }
    ]
  */

  const transposeSeries = getTransposeArray<number | null | string>(
    series.map(({ data }) => data)
  )
  const totalByEachXAxis = transposeSeries.map(arr =>
    arr.reduce((acc, curr) => getFloatSafe(acc) + getFloatSafe(curr), 0)
  ) as number[]

  const chartDataSeriesGroupEntity = useChartDataSeriesGroupEntity([data])

  const height = getHeight(size)

  // 讓直方圖的資料從上往下排列，將資料與顏色反轉
  const seriesReversed = series.toReversed()
  const colorsReversed = colors.slice(0, series.length).toReversed()

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: displayType === 'bar' ? undefined : '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors: colorsReversed,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: getSparseXLables(xAxisCategories, size),
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: theme.colors.borderColor,
    },
    tooltip: {
      fixed: {
        enabled: size !== 'large',
        offsetY: height,
      },
      custom: function ({
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) {
        // dataPointIndex = 1
        // header = ['1/2', '數值', '百分比]
        const header = [
          `${chartTitle} (${fullHeaderDateDisplay[dataPointIndex]})`,
          t('dateAndChart:value'),
          t('dateAndChart:percent'),
        ]
        // name = ['安安', '你好', '嗎嗎']
        const names = data.series.map(({ name, label }) => `${name} | ${label}`)

        // 超過 1 個群組，代表有多個 group 顯示另一種 Tooltip UI
        if (checkHasGroup(chartDataSeriesGroupEntity)) {
          return getTooltipHTMLStringBarGroup({
            header,
            entity: chartDataSeriesGroupEntity,
            colors,
            dataPointIndex,
            data: [data],
            totalByEachXAxis,
            transposeSeries,
          })
        }
        /*
          data2D = [
            [6, '33%'],
            [null, '0%'],
            [12, '67%']
          ]
        */
        const data2D = transposeSeries[dataPointIndex].map(num => [
          formatNumber(num as number),
          `${Math.round(
            getFloatSafe(getFloatSafe(num) / totalByEachXAxis[dataPointIndex]) *
              100
          )}%`,
        ])

        return getTooltipHTMLString({
          header,
          colors,
          names,
          data2D,
        })
      },
    },
  }

  return (
    <ChartContainer sx={{ height }}>
      <ReactApexChart
        key={displayType}
        options={options}
        series={seriesReversed}
        type="bar"
        height={height}
      />
    </ChartContainer>
  )
}

export default BarChart
