import camelcaseKeys from 'camelcase-keys'

import type { NumberOrString } from '@shared/lib/utils/type'

import type { PaginationResponse } from '../api'
import {
  api,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  CampaignFromApi,
  CampaignList,
  CampaignParams,
  CampaignsEventSchema,
  CampaignsMediumActionMappingInfo,
  CampaignStatistic,
  CampaignStatisticExport,
  CreateTracingUrlParams,
  TracingUrl,
} from './type'

export const transformResponseCampaignFromApi = (
  data: PaginationResponse<CampaignFromApi>
) => camelcaseKeys(data, { deep: true })

const campaign = api.injectEndpoints({
  endpoints: builder => ({
    getCampaignsList: builder.query<CampaignList, CampaignParams>({
      query: params => ({
        method: 'GET',
        url: 'campaigns',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (data: PaginationResponse<CampaignFromApi>) =>
        transformResponseCamelCaseKeys(data),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'CampaignList' as const,
                id,
              })),
              'CampaignList',
            ]
          : ['CampaignList'],
    }),
    getCampaignsMediumActionMappingInfo: builder.query<
      CampaignsMediumActionMappingInfo,
      void
    >({
      query: () => ({
        method: 'GET',
        url: '/medium_action_mapping_info',
      }),
      transformResponse: (data: CampaignsMediumActionMappingInfo) =>
        transformResponseCamelCaseKeys(data),
    }),
    // 不將 result 轉成 camelCase  因為 後端回傳的 value_mapping[n].rules 裡面的 keys ，前端回直接拿來用
    getCampaignsEventSchema: builder.query<CampaignsEventSchema, void>({
      query: () => ({
        method: 'GET',
        url: '/event_schema',
      }),
    }),
    getTracingUrlList: builder.query<TracingUrl[], void>({
      query: () => ({
        method: 'GET',
        url: '/tracing_urls/all',
      }),
      transformResponse: (data: TracingUrl[]) =>
        transformResponseCamelCaseKeys(data),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'TracingUrl' as const,
                id,
              })),
              'TracingUrl',
            ]
          : ['TracingUrl'],
    }),
    getTracingUrl: builder.query<TracingUrl, number>({
      query: id => ({
        url: `/tracing_urls/${id}`,
        method: 'GET',
      }),
      transformResponse: (data: TracingUrl) =>
        transformResponseCamelCaseKeys(data),
      providesTags: (result, error, id) => [{ type: 'TracingUrl', id }],
    }),
    createTracingUrl: builder.mutation<void, CreateTracingUrlParams>({
      query: body => ({
        url: '/tracing_urls',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TracingUrl'],
    }),
    deleteTracingUrl: builder.mutation<void, number>({
      query: id => ({
        url: `/tracing_urls/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'TracingUrl', id },
        'Workflow',
      ],
    }),
    getCampaignsStatistic: builder.query<
      CampaignStatistic[],
      { startTime: NumberOrString; endTime: NumberOrString }
    >({
      query: body => ({
        method: 'POST',
        url: '/campaigns/statistic',
        body,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as CampaignStatistic[]),
      providesTags: result =>
        result
          ? [
              ...result.map(({ campaignId, triggerGroupId }) => ({
                type: 'CampaignsStatistic' as const,
                id: `${triggerGroupId}_${campaignId}`,
              })),
              { type: 'CampaignsStatistic', id: 'LIST' },
            ]
          : [{ type: 'CampaignsStatistic', id: 'LIST' }],
    }),
    getCampaignsStatisticExport: builder.mutation<
      CampaignStatisticExport,
      { startTime: NumberOrString; endTime: NumberOrString }
    >({
      query: body => ({
        url: '/campaigns/statistic/export',
        body,
        method: 'POST',
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as CampaignStatisticExport),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCampaignsListQuery,
  useGetCampaignsEventSchemaQuery,
  useGetCampaignsMediumActionMappingInfoQuery,
  useGetTracingUrlListQuery,
  useCreateTracingUrlMutation,
  useDeleteTracingUrlMutation,
  useGetTracingUrlQuery,
  useGetCampaignsStatisticQuery,
  useGetCampaignsStatisticExportMutation,
} = campaign
