import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import {
  DataGrid,
  GridColumnMenuColumnsItem,
  GridColumnMenuContainer,
  GridColumnMenuProps,
} from '@mui/x-data-grid'

import type { WorkflowSubscriptionInsightDetail } from '@shared/api/rtkQuery'
import useTableAggregationData from '@shared/lib/hooks/useTableAggregationData'
import SectionTitle from '@shared/ui/SectionTitle'
import { ColumnsPanel } from '@widgets/insight/ui/workflow/DetailInsightDataTable/_shared/DataGridSlots'

import useColumns from './useColumns'

type Props = {
  title: string
  isLoading: boolean
  data: WorkflowSubscriptionInsightDetail[]
}

const SORT_IGNORE_ID = 'workflow_subscription_insight_aggregation'

const Table = ({ title, isLoading, data }: Props) => {
  const { t } = useTranslation(['table', 'insight'])

  const tableColumns = useColumns(SORT_IGNORE_ID)

  const tableAggregationData = useTableAggregationData(data, {
    initialColumnValues: {
      nodeId: SORT_IGNORE_ID,
      name: t('insight:amount'),
    },
    ignoreKeys: ['nodeId', 'name'],
  })

  return (
    <Box mb={5} width="100%">
      <SectionTitle title={title} />
      <DataGrid
        loading={isLoading}
        rows={data.concat([tableAggregationData])}
        columns={tableColumns}
        getRowClassName={params => {
          if (params.row.nodeId === SORT_IGNORE_ID) {
            return 'MuiDataGrid-row--transparent MuiDataGrid-row--boldFont MuiDataGrid-row--blackColor'
          }

          return ''
        }}
        getRowId={row => `${row.nodeId}_${row.name}`}
        slots={{
          columnMenuHideIcon: () => <></>,
          columnMenuManageColumnsIcon: () => <></>,
          columnsPanel: () => <ColumnsPanel />,
          columnMenu: ({
            hideMenu,
            colDef,
            ...others
          }: GridColumnMenuProps) => (
            <GridColumnMenuContainer
              hideMenu={hideMenu}
              colDef={colDef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...others}
            >
              <GridColumnMenuColumnsItem onClick={hideMenu} colDef={colDef} />
            </GridColumnMenuContainer>
          ),
        }}
        getRowHeight={() => 'auto'}
        rowSelection={false}
        localeText={{
          columnMenuHideColumn: t('table:hide_column'),
          columnMenuManageColumns: t('table:manage_columns'),
          columnMenuLabel: '',
        }}
        autoHeight
        disableRowSelectionOnClick
        disableVirtualization
        disableColumnFilter
        hideFooter
      />
    </Box>
  )
}

export default Table
