import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import type { FormikProps } from 'formik'

import {
  NotificationRuleFormValues,
  usePatchNotificationRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { openToast } from '@shared/model/slices'
import Card from '@shared/ui/Card'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import theme from '@theme'

import NotificationTarget from './setting/NotificationTarget'
type Props = {
  isCreateMode: boolean
  id: number | undefined
  periodOptions: { label: string; value: string }[]
  isPublic: NotificationRuleFormValues['isPublic']
  userIds: NotificationRuleFormValues['userIds']
  triggerTime: NotificationRuleFormValues['rule']['triggerTime']
  notificationTypes: NotificationRuleFormValues['notificationTypes']
  onFieldChange: FormikProps<NotificationRuleFormValues>['setFieldValue']
  reset: (field: string) => void
}

const NotificationSetting = ({
  isCreateMode,
  id,
  periodOptions,
  isPublic,
  userIds,
  triggerTime,
  notificationTypes,
  onFieldChange,
  reset,
}: Props) => {
  const { t } = useTranslation(['analytics', 'common'])
  const dispatch = useAppDispatch()
  const [isEditable, setIsEditable] = useState<boolean>(false)

  const [patchNotificationRule, { isLoading: isPatching }] =
    usePatchNotificationRuleMutation()

  const handleOnPatch = async () => {
    if (id && !isCreateMode && isEditable) {
      try {
        await patchNotificationRule({
          id,
          userIds: isPublic ? [] : userIds,
          notificationTypes,
          rule: {
            triggerTime,
          },
        }).unwrap()

        dispatch(
          openToast({
            message: t('common:save_success'),
          })
        )
      } catch (error) {
        dispatch(
          openToast({
            message: t('common:save_failure'),
            status: 'error',
          })
        )
      }
    }

    setIsEditable(false)
  }

  const handleCancel = () => {
    setIsEditable(false)
    reset('isPublic')
    reset('userIds')
    reset('notificationTypes')
    reset('rule.triggerTime')
  }

  const handleUserIdsChange = (
    userIds: NotificationRuleFormValues['userIds']
  ) => {
    onFieldChange('userIds', userIds)
  }

  const isEditableMode = (isCreateMode || isEditable) && !isPatching

  return (
    <Card mb={5}>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box width="80%">
          <Box
            mb={2.5}
            pb={2.5}
            component="h2"
            sx={{
              borderBottom: theme => `1px solid ${theme.colors.black6}`,
            }}
          >
            <Box component="span" mr={5} lineHeight="42px" whiteSpace="nowrap">
              {t('analytics:notificationRule.notification_period')}
            </Box>
            <DropdownList
              disabled={!isEditableMode}
              key={`${periodOptions[0].label}_${triggerTime.periodDays}`}
              defaultOption={periodOptions.find(
                option => getIntSafe(option.value) === triggerTime.periodDays
              )}
              options={periodOptions}
              onValueChanged={option => {
                onFieldChange(
                  'rule.triggerTime.periodDays',
                  getIntSafe(option.value)
                )
              }}
              bgColor={theme.colors.bgPrimaryGrey}
            />
          </Box>
          <Box
            mb={2.5}
            pb={2.5}
            component="h2"
            display="flex"
            sx={{
              borderBottom: theme => `1px solid ${theme.colors.black6}`,
            }}
          >
            <Box component="span" mr={5} lineHeight="42px" whiteSpace="nowrap">
              {t('analytics:notificationRule.notification_target')}
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              width="100%"
            >
              <RadioGroup
                name="sendType"
                value={isPublic ? 'all' : 'specific'}
                onChange={e =>
                  onFieldChange('isPublic', e.target.value === 'all')
                }
                sx={{
                  marginRight: 5,
                }}
              >
                <FormControlLabel
                  value="all"
                  disabled={!isEditableMode}
                  control={<Radio size="small" color="primary" />}
                  label={
                    <Box fontSize={13} fontWeight={500}>
                      {t('analytics:notificationRule.notification_target_all')}
                    </Box>
                  }
                />
                <Box display="flex" width="100%">
                  <FormControlLabel
                    disabled={!isEditableMode}
                    value="specific"
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Box fontSize={13} fontWeight={500} minWidth={100}>
                        {t(
                          'analytics:notificationRule.notification_target_specific'
                        )}
                      </Box>
                    }
                  />
                  <NotificationTarget
                    isDisabled={isPublic || !isEditableMode}
                    userIds={userIds}
                    onUserIdsChange={handleUserIdsChange}
                  />
                </Box>
              </RadioGroup>
            </Box>
          </Box>
          <Box mb={2} component="h2">
            <Box component="span" mr={5} lineHeight="42px" whiteSpace="nowrap">
              {t('analytics:notificationRule.notification_method')}
            </Box>
            <Checkbox
              size="small"
              disabled={!isEditableMode}
              checked={notificationTypes.byEmail}
              onChange={() =>
                onFieldChange(
                  'notificationTypes.byEmail',
                  !notificationTypes.byEmail
                )
              }
              sx={{
                marginTop: '-3px',
                marginLeft: '-9px',
              }}
            />
            <span>{t('analytics:notificationRule.notified_by_email')}</span>
          </Box>
        </Box>

        <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
          {!isCreateMode && !isEditable && (
            <MuiButton
              disabled={isPatching}
              onClick={() => setIsEditable(true)}
              sx={{
                paddingTop: 0,
                '&.MuiButton-root': {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              {t('common:edit')}
            </MuiButton>
          )}
          {!isCreateMode && isEditable && (
            <>
              <MuiButton
                disabled={isPatching}
                onClick={handleCancel}
                sx={{
                  color: 'inherit',
                  paddingTop: 0,
                  '&.MuiButton-root': {
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              >
                {t('common:cancel')}
              </MuiButton>
              <MuiButton
                disabled={isPatching}
                onClick={handleOnPatch}
                sx={{
                  paddingTop: 0,
                  '&.MuiButton-root': {
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              >
                {t('common:save')}
              </MuiButton>
            </>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default memo(NotificationSetting)
