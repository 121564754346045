import { Trans } from 'react-i18next'
import Box from '@mui/material/Box'

import type { Combination } from '@shared/api/rtkQuery/audience'

import CombinationButtonWithTooltip from './CombinationButtonWithTooltip'

type Props = {
  combination: Combination
  isDisabled?: boolean
  onChange: () => void
}

export const FilterCombinator = ({
  combination,
  isDisabled,
  onChange,
}: Props) => {
  const handleChange = () => {
    if (isDisabled) {
      return
    }
    onChange()
  }
  return (
    <Box mb={2}>
      <Trans
        ns={['audience']}
        i18nKey="audience:v3.filter_combinator_text"
        components={{
          Combinator: (
            <CombinationButtonWithTooltip
              combination={combination}
              isDisable={isDisabled}
              handleCombinatorButtonClick={handleChange}
            />
          ),
        }}
      />
    </Box>
  )
}

export default FilterCombinator
