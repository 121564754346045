import { useMemo } from 'react'

import type { ChartData } from '@shared/ui/charts'

import { getChartDataSeriesGroupEntity } from '../utils/getChartDataSeriesGroupEntity'

export const useChartDataSeriesGroupEntity = (chartDataList: ChartData[]) =>
  useMemo(
    () => getChartDataSeriesGroupEntity(chartDataList[0]?.series || []),
    [chartDataList]
  )

export default useChartDataSeriesGroupEntity
