import { useTranslation } from 'react-i18next'

import {
  InputArea,
  InputAreaGrid,
  LabelArea,
} from '@features/subscription/_shared'
import type { SubscriptionFormValues } from '@shared/api/rtkQuery'
import { PrimaryGreyInput } from '@shared/ui/inputs'

type Props = {
  value: SubscriptionFormValues['name']
  onChange: (value: SubscriptionFormValues['name']) => void
  isDisabled?: boolean
  isError?: boolean
}

const Name = ({
  value,
  onChange,
  isDisabled = false,
  isError = false,
}: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  return (
    <InputAreaGrid mb={2}>
      <LabelArea>{t('common:name')}*</LabelArea>
      <InputArea>
        <PrimaryGreyInput
          disabled={isDisabled}
          isError={isError}
          fullWidth
          onChange={({ target: { value } }) => {
            onChange(value)
          }}
          placeholder={t(
            'contentTemplate:subscription.dialog.common.name_placeholder'
          )}
          value={value}
        />
      </InputArea>
    </InputAreaGrid>
  )
}

export default Name
