import type { UsageDataType } from '.'

export const calculateTableSum = (data: UsageDataType[]) => {
  const tableSumData = data.reduce(
    (acc, data) => ({
      ...acc,
      email: {
        counts: acc.email.counts + data.totalEmailCounts,
        amount: acc.email.amount + data.totalEmailAmount,
      },
      sms: {
        counts: acc.sms.counts + data.totalSmsCounts,
        amount: acc.sms.amount + data.totalSmsAmount,
      },
      totalAmount: acc.totalAmount + data.totalAmount,
    }),
    {
      email: {
        counts: 0,
        amount: 0,
      },
      sms: {
        counts: 0,
        amount: 0,
      },
      totalAmount: 0,
      name: 'Total',
    }
  )

  return tableSumData
}

export type UsageDataSumType = ReturnType<typeof calculateTableSum>
