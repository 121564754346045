import { useTranslation } from 'react-i18next'

import type { TracingUrlInsight } from '@shared/api/rtkQuery'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'

type Props = {
  tracingUrlInsights?: Array<TracingUrlInsight>
}

const useGetErrorHint = ({ tracingUrlInsights }: Props) => {
  const { t } = useTranslation(['workflow'])
  const { checkTracingUrlIsArchived } = useTracingUrlStatus()

  if (!tracingUrlInsights?.length) {
    return ''
  }

  const hasError = tracingUrlInsights
    .map(item => checkTracingUrlIsArchived(item.id, item.archivedAt))
    .some(Boolean)

  return hasError
    ? t('workflow:errors.tracing_url_is_archived_plz_recreate')
    : ''
}

export default useGetErrorHint
