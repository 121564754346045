import { formatNumber } from '@shared/lib/utils/number'

export const getDisplayData = (data: number | string | null) => {
  if (data === undefined) {
    return ''
  }

  if (typeof data === 'number') {
    return formatNumber(data)
  }

  // null, 0 are ok to display
  return data
}
