import camelcaseKeys from 'camelcase-keys'

import { api } from './api'

export type IamServiceModuleName =
  | 'analytics_dashboard'
  | 'application'
  | 'audience'
  | 'data_import'
  | 'iam'
  | 'integration'
  | 'mkt_journey'
  | 'msg_template'
  | 'pltv'
  | 'rfm'
  | 'single_sign_on_application'
  | 'tagging'
  | 'user_profile'
  | 'intelligent_analysis'

export type IamServiceActionGroup = {
  name: string
  actions: string[]
}

export type IamServiceModule = {
  name: IamServiceModuleName
  activated: boolean
  actionGroups: IamServiceActionGroup[]
}

type IamServiceEntity = {
  ids: IamServiceModuleName[]
  entities: Record<string, IamServiceModule>
}

const iamService = api.injectEndpoints({
  endpoints: builder => ({
    iamServiceEntity: builder.query<IamServiceEntity, void>({
      query: () => '/iam/services',
      transformResponse: (result: IamServiceModule[]) =>
        result.reduce(
          (acc, curr) => {
            acc.ids.push(curr.name)
            acc.entities[curr.name] = camelcaseKeys(curr, { deep: true })

            return acc
          },
          { ids: [], entities: {} } as IamServiceEntity
        ),
    }),
  }),
  overrideExisting: false,
})

export const { useIamServiceEntityQuery } = iamService
