import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid'

import { formatNumber } from '@shared/lib/utils/number'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import { sortRowWithIgnoreId } from '@widgets/insight/ui/workflow/DetailInsightDataTable/_shared/utils'

const CELL_MIN_WIDTH = 120

const useColumns = (ignoreSortIdPattern: string): GridColDef[] => {
  const customSortComparator = sortRowWithIgnoreId(ignoreSortIdPattern)

  const { t } = useTranslation(['table', 'insight'])

  const columns: GridColDef[] = [
    {
      headerName: t('table:subscription_name'),
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
      field: 'name',
      sortable: false,
      filterable: false,
      type: 'string',
    },
    {
      headerName: t('table:subscription_export_count'),
      headerAlign: 'right',
      field: 'exportCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      sortComparator: customSortComparator,
      type: 'number',
      valueFormatter: ({ value }) => formatNumber(value),
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            zIndex: Z_INDEX.tooltip,
          }}
        >
          {colDef.headerName}
          <Tooltip title={t('insight:subscription_export_count_tooltip')}>
            <Box ml={0.5} fontSize="12">
              <Icon
                fontSize="inherit"
                icon={ICON.infoCircle}
                color={theme.colors.textSecondBlue}
              />
            </Box>
          </Tooltip>
        </Box>
      ),
    },
    {
      headerName: t('table:subscription_exported_user_count'),
      headerAlign: 'right',
      field: 'exportedUserCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      sortComparator: customSortComparator,
      filterable: false,
      type: 'number',
      valueFormatter: ({ value }) => formatNumber(value),
      renderHeader: ({ colDef }: GridColumnHeaderParams) => (
        <Box
          display="flex"
          alignItems="center"
          sx={{
            zIndex: Z_INDEX.tooltip,
          }}
        >
          {colDef.headerName}
          <Tooltip
            title={t('insight:subscription_exported_user_count_tooltip')}
          >
            <Box ml={0.5} fontSize="12">
              <Icon
                fontSize="inherit"
                icon={ICON.infoCircle}
                color={theme.colors.textSecondBlue}
              />
            </Box>
          </Tooltip>
        </Box>
      ),
    },
  ]

  return columns
}

export default useColumns
