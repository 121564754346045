import Box from '@mui/material/Box'

import type { DashboardIndicator } from '@shared/ui/charts'

type PieChartIndicatorListProps = {
  indicatorList: DashboardIndicator[]
}

export const PieChartIndicatorList = ({
  indicatorList,
}: PieChartIndicatorListProps) => {
  return (
    <ul>
      {indicatorList.map((item, idx) => (
        <li key={idx}>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 500,
              color: theme => theme.colors.textPrimaryBlue,
            }}
          >
            {item.indicator}
          </Box>
          <Box
            sx={{
              fontSize: 40,
              fontWeight: 700,
              color: theme => theme.colors.black,
            }}
          >
            {item.value}
          </Box>
        </li>
      ))}
    </ul>
  )
}

export default PieChartIndicatorList
