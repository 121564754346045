import { useCallback, useEffect, useState } from 'react'

import type { EventRuleTimeRangeUnit } from '@shared/api/rtkQuery'
import { trackEvent } from '@shared/lib/utils/amplitude'
import type { NumberOrString } from '@shared/lib/utils/type'
import type { BarChartDisplayType, EventChartType } from '@shared/ui/charts'

import type { EventRuleFormValues, EventRuleSettings } from '../types'
import { createInitialEventRuleSettings } from '../utils'
import useEventRuleFilter from './useEventRuleFilter'
import { useEventRuleSearchParams } from './useEventRuleSearchParams'

type UseEventRuleFormParams = {
  initialValues?: EventRuleFormValues
}

export const useEventRuleForm = ({ initialValues }: UseEventRuleFormParams) => {
  const { filter, errors: filterErrors, dispatch } = useEventRuleFilter()

  const { groupId, privacyLevel } = useEventRuleSearchParams()

  const [eventRuleSettings, setEventRuleSettings] = useState<EventRuleSettings>(
    createInitialEventRuleSettings(privacyLevel, groupId)
  )

  const resetValues = useCallback(() => {
    dispatch({
      type: 'reset',
      payload: undefined,
    })

    setEventRuleSettings(
      createInitialEventRuleSettings(initialValues?.iam ? 'private' : 'public')
    )
  }, [dispatch, initialValues?.iam])

  const handleChartTypeChanged = useCallback(
    (chartType: EventChartType) => {
      trackEvent('ChartViewToggled', {
        analysisType: 'event',
        chartType: chartType,
      })

      setEventRuleSettings(prev => ({
        ...prev,
        chartType,
        indicator:
          // 切換至 pie chart 時，將指標設定為「總計」
          // 切換至其他 chart 時，則將指標設定為「最新」
          chartType === 'pie_chart'
            ? { ...prev.indicator, latest: false, sum: true, avg: false }
            : { ...prev.indicator, latest: true, sum: false, avg: false },
      }))

      dispatch({
        type: 'updateTimeRangeUnit',
        payload: { timeRangeUnit: chartType === 'pie_chart' ? 'all' : 'day' },
      })
    },
    [dispatch]
  )

  const handleUnitChange = useCallback(
    (timeRangeUnit: EventRuleTimeRangeUnit) => {
      dispatch({
        type: 'updateTimeRangeUnit',
        payload: { timeRangeUnit },
      })
    },
    [dispatch]
  )

  const handleTimeRangeParamsChange = useCallback(
    (timeRangeParams: NumberOrString[]) => {
      dispatch({
        type: 'updateTimeRangeParams',
        payload: { timeRangeParams },
      })
    },
    [dispatch]
  )

  const handleTopNChange = useCallback((topN: number) => {
    setEventRuleSettings(prev => ({
      ...prev,
      topN,
    }))
  }, [])

  const handleTimeRangeParamsComparedChange = useCallback(
    (timeRangeParams: NumberOrString[] | undefined) => {
      setEventRuleSettings(prev => ({
        ...prev,
        timeRangeParamsCompared: timeRangeParams,
        // 有對比時間，切換到 line chart
        chartType: timeRangeParams ? 'line_chart' : prev.chartType,
      }))

      // 有對比時間，將時間單位切換為「天」
      if (timeRangeParams) {
        dispatch({
          type: 'updateTimeRangeUnit',
          payload: { timeRangeUnit: 'day' },
        })
      }
    },
    [dispatch]
  )

  const handleBarChartTypeChange = useCallback(
    (barChartType: BarChartDisplayType) => {
      setEventRuleSettings(prev => ({
        ...prev,
        indicator: {
          ...prev.indicator,
          type: barChartType,
        },
      }))
    },
    []
  )

  useEffect(() => {
    if (!initialValues) {
      return
    }

    const [filter, filterCompared] = initialValues.filters

    dispatch({ type: 'reset', payload: filter })

    setEventRuleSettings({
      title: initialValues.title,
      chartType: initialValues.dashboardConfig.chartType,
      indicator: initialValues.dashboardConfig.displayVisibility,
      privacyLevel: initialValues.iam ? 'private' : 'public',
      groupId: initialValues.groupId,
      timeRangeParamsCompared: filterCompared?.timeRangeParams,
      topN: filter.topN,
    })
  }, [dispatch, initialValues])

  return {
    values: {
      filter,
      eventRuleSettings,
    },
    setEventRuleSettings,
    errors: {
      filter: filterErrors,
    },
    dispatch,
    handleBarChartTypeChange,
    handleChartTypeChanged,
    handleTimeRangeParamsChange,
    handleTimeRangeParamsComparedChange,
    handleTopNChange,
    handleUnitChange,
    resetValues,
  }
}
