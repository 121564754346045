import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import type { TimeRangeUnit } from '../analytics'
import { api } from '../api'
import type { MediumType } from '../contentTemplate'

type ConfigurationTypePeriod = `${MediumType}_${TimeRangeUnit}`

type WorkflowConfigurationFromApi = {
  ma_workflow_same_user_run_limit_enabled: boolean
  ma_workflow_same_user_run_limit: number
  ma_campaign_user_sent_limit_enabled: boolean
  ma_campaign_user_sent_limit_period_hours: number
  ma_campaign_user_sent_limit_count: number
} & Record<
  `ma_campaign_${ConfigurationTypePeriod}_sent_limit_enabled`,
  boolean
> &
  Record<`ma_campaign_${ConfigurationTypePeriod}_sent_limit`, number>

const transformResponseWorkflowConfiguration = (
  data: WorkflowConfigurationFromApi
) => camelcaseKeys(data, { deep: true })

export type WorkflowConfiguration = ReturnType<
  typeof transformResponseWorkflowConfiguration
>

export const createInitialWorkflowConfiguration =
  (): WorkflowConfiguration => ({
    maWorkflowSameUserRunLimit: 1,
    maWorkflowSameUserRunLimitEnabled: false,
    maCampaignUserSentLimitCount: 1,
    maCampaignUserSentLimitEnabled: false,
    maCampaignUserSentLimitPeriodHours: 1,
    maCampaignEmailDaySentLimit: 1,
    maCampaignEmailDaySentLimitEnabled: false,
    maCampaignEmailMonthSentLimit: 1,
    maCampaignEmailMonthSentLimitEnabled: false,
    maCampaignEmailWeekSentLimit: 1,
    maCampaignEmailWeekSentLimitEnabled: false,
    maCampaignLinePushDaySentLimit: 1,
    maCampaignLinePushDaySentLimitEnabled: false,
    maCampaignLinePushMonthSentLimit: 1,
    maCampaignLinePushMonthSentLimitEnabled: false,
    maCampaignLinePushWeekSentLimit: 1,
    maCampaignLinePushWeekSentLimitEnabled: false,
    maCampaignSmsDaySentLimit: 1,
    maCampaignSmsDaySentLimitEnabled: false,
    maCampaignSmsMonthSentLimit: 1,
    maCampaignSmsMonthSentLimitEnabled: false,
    maCampaignSmsWeekSentLimit: 1,
    maCampaignSmsWeekSentLimitEnabled: false,
  })

type AccountSendCountsPeriodFromApi = `${TimeRangeUnit}_sent_count`

type AccountSendCountsFromApi = Record<
  AccountSendCountsPeriodFromApi,
  {
    medium_type: MediumType
    count: number
    started_at: string
  }
>

export const transformResponseAccountSentCountsSummary = (
  data: AccountSendCountsFromApi
) => camelcaseKeys(data, { deep: true })

const workflowConfiguration = api.injectEndpoints({
  endpoints: builder => ({
    getWorkflowConfiguration: builder.query<WorkflowConfiguration, void>({
      query: () => '/account_configuration',
      transformResponse: transformResponseWorkflowConfiguration,
      providesTags: ['WorkflowConfiguration'],
    }),
    updateWorkflowConfiguration: builder.mutation<void, WorkflowConfiguration>({
      query: payload => ({
        url: '/account_configuration',
        method: 'PUT',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: ['WorkflowConfiguration'],
    }),
    getAccountSentCountsByMediumType: builder.query<
      ReturnType<typeof transformResponseAccountSentCountsSummary>,
      MediumType
    >({
      query: mediumType => ({
        url: '/ma_campaign/account_sent_counts/current_summary',
        params: snakecaseKeys({ mediumType }),
      }),
      transformResponse: transformResponseAccountSentCountsSummary,
    }),
  }),
  overrideExisting: false,
})

export const {
  useLazyGetAccountSentCountsByMediumTypeQuery,
  useGetWorkflowConfigurationQuery,
  useUpdateWorkflowConfigurationMutation,
} = workflowConfiguration
