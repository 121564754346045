import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { MediumType } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList, { Option } from '@shared/ui/Dropdown/CheckboxList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type Props = {
  onChange: (value: MediumType[]) => void
}

const MediumTypeFilter = ({ onChange }: Props) => {
  const { t } = useTranslation(['common'])
  const [selectedMediumTypeList, setSelectedMediumTypeList] = useState<
    string[]
  >([])

  const mediumTypeFilterOptions: Option[] = useMemo(
    () => [
      {
        label: t('common:medium_type.email'),
        value: 'email',
      },
      {
        label: t('common:medium_type.sms'),
        value: 'sms',
      },
      {
        label: t('common:medium_type.line_push'),
        value: 'line_push',
      },
    ],
    [t]
  )

  const handleDropdownCheckboxListChange = (values: string[]) => {
    setSelectedMediumTypeList(values)
    onChange(values as MediumType[])
  }

  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Box mr={1}>
        <Icon icon={ICON.filter} color={theme.colors.brightBlue} />
      </Box>
      <DropdownCheckboxList
        hasShadow
        checkedValues={selectedMediumTypeList}
        hasSelectAll
        label={t('table:medium_type')}
        onChange={handleDropdownCheckboxListChange}
        options={mediumTypeFilterOptions}
      />
    </Box>
  )
}

export default memo(MediumTypeFilter)
