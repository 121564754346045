import Box from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

type Props = {
  children: React.ReactNode
  sx?: SxProps<Theme>
}

const FeatureBadge = ({ sx = {}, children }: Props) => {
  return (
    <Box
      sx={{
        display: 'inline',
        fontSize: 10,
        paddingX: 1,
        paddingY: 0.125,
        borderRadius: 3,
        background: theme =>
          `linear-gradient(90deg, ${theme.colors.electricBlueTwo} 0%, ${theme.colors.deepSkyBlue} 100%)`,
        color: theme => theme.colors.white,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default FeatureBadge
