import i18n from 'i18next'
import * as yup from 'yup'

export type FormValues = {
  emails: string[]
  msgChannelId?: number
  msgSenderId?: number | null
  preheader?: string
  tagInput: string
  target: 'self' | 'specific'
  title?: string
}

const EMAIL_AMOUNT_LIMIT = 5

export const schema = yup.object({
  target: yup.string().oneOf(['self', 'specific']).required(),
  msgChannelId: yup.number().required(),
  msgSenderId: yup.number().required(),
  tagInput: yup.string().when('target', {
    is: 'specific',
    then: () => yup.string().email(() => i18n.t('common:errors.invalid')),
  }),
  emails: yup.array().when('target', {
    is: 'specific',
    then: schema =>
      schema
        .min(1, () => i18n.t('common:errors.required'))
        .max(EMAIL_AMOUNT_LIMIT, () =>
          i18n.t('contentTemplate:send_email_result.up_to_five')
        ),
  }),
  title: yup.string().required(),
})
