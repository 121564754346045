import styled from '@emotion/styled'

export const Container = styled.div<{ bgImageUrl: string }>`
  display: flex;

  margin: 64px auto;
  margin-bottom: 96px;

  width: 66vw;
  min-width: 840px;
  height: 30vw;
  min-height: 440px;

  background-image: url('${({ bgImageUrl }) => bgImageUrl}');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
`
