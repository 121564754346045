import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { ButtonWithFailedIcon } from '@shared/ui/buttons'

import { UploadStatus } from './FileUpload'

const Text = styled.div<{ isError?: boolean }>`
  margin: 0 8px;

  color: ${({ theme, isError }) =>
    isError ? theme.colors.orangeyRed : theme.colors.textPrimaryBlue};
`

type Props = {
  uploadStatus: UploadStatus
  onTryAgainButtonClick?: () => void
}

const StatusCaption = ({
  uploadStatus,
  onTryAgainButtonClick = () => {},
}: Props) => {
  const { t } = useTranslation(['dataImport', 'audience'])

  if (uploadStatus === 'error') {
    return (
      <ButtonWithFailedIcon
        wording={t('audience:try_again')}
        onClick={onTryAgainButtonClick}
      />
    )
  }

  if (uploadStatus === 'start') {
    return <Text>{t('dataImport:caption.processing')}</Text>
  }

  if (uploadStatus === 'end') {
    return <Text>{t('dataImport:caption.success')}</Text>
  }

  return null
}

export default StatusCaption
