import { useMemo, useRef } from 'react'

import { MetadataProperty } from '@shared/api/rtkQuery'

import { useMetadataQuery } from '../utils/metadata'

export const useMetadataUserProfileFromUsers = <T>(
  callback: (value: MetadataProperty) => T
) => {
  const callbackRef = useRef(callback)

  const { userProfileEntity } = useMetadataQuery()

  const userProfilePropertyList = useMemo(
    () =>
      userProfileEntity.userTableIds.reduce<T[]>(
        (acc, curr) =>
          acc.concat(callbackRef.current(userProfileEntity.entities[curr])),
        []
      ),
    [userProfileEntity.entities, userProfileEntity.userTableIds]
  )

  return userProfilePropertyList
}

export default useMetadataUserProfileFromUsers
