import { useState } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { NodeKey } from 'lexical'

import Chip from '@entities/mediumTemplates/ui/SmsEditor/_shared/Chip'
import UtmLinkDialog from '@entities/mediumTemplates/ui/SmsEditor/utm/nodes/UtmLinkDialog'
import {
  DELETE_UTM_COMMAND,
  UPDATE_UTM_COMMAND,
  UPDATE_UTM_REPLACEMENT_DATA_COMMAND,
} from '@entities/mediumTemplates/ui/SmsEditor/utm/UtmPlugin'
import type { ReplacementDataActionURL } from '@shared/api/rtkQuery'

export type Props = {
  replacementKey: string
  replacementData: ReplacementDataActionURL
  nodeKey: NodeKey
}

const ActionUrlChip = ({ replacementKey, replacementData, nodeKey }: Props) => {
  const [editor] = useLexicalComposerContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const editorIsEditable = editor.isEditable()

  const handleOnClick = () => {
    if (editorIsEditable) {
      setIsOpen(true)
    }
  }

  const handleOnConfirm = (data: ReplacementDataActionURL) => {
    editor.dispatchCommand(UPDATE_UTM_COMMAND, {
      nodeKey,
      replacementData: data,
    })

    // dispatch command to update the replacement_data
    editor.dispatchCommand(UPDATE_UTM_REPLACEMENT_DATA_COMMAND, {
      replacementKey,
      replacementData: data,
    })

    setIsOpen(false)
  }

  const handleRemoveUtm = () => {
    editor.dispatchCommand(DELETE_UTM_COMMAND, {
      nodeKey,
      replacementKey,
    })
  }

  return (
    <>
      <UtmLinkDialog
        isOpen={isOpen}
        onSaveLink={data => handleOnConfirm(data)}
        onCancel={() => setIsOpen(false)}
        data={replacementData}
      />

      <Chip
        disabled={!editorIsEditable}
        onClick={handleOnClick}
        onDelete={handleRemoveUtm}
      >
        {replacementKey}
      </Chip>
    </>
  )
}

export default ActionUrlChip
