import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'

import {
  ReplacementData,
  SendType,
  useSendEvery8dMmsMutation,
  useSendEvery8dSmsMutation,
  useSendSmsTestMutation,
} from '@shared/api/rtkQuery'
import {
  INITIAL_PAGINATION_RESPONSE,
  useGetMsgChannelListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { checkIsTaiwanPhone } from '@shared/lib/utils/validation'
import { Z_INDEX } from '@shared/model/constants/styles'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog } from '@shared/ui/dialogs'
import NestedList from '@shared/ui/Dropdown/NestedList'
import TagsField from '@shared/ui/inputs/TagsField'
import theme from '@theme'

import useSendSmsSchema, { SendSmsSchema } from './useSendSmsSchema'

type MessageData = {
  subject: string
  message: string
  imageType?: string
  base64?: string
  replacementData?: ReplacementData
}

type SendSmsDialogProps = {
  className?: string
  isOpen: boolean
  onClose: () => void
  integrationId?: number
  sendType: SendType
  onFinished?: () => void
  isEnableProviderSelector?: boolean
  messageData: MessageData
}

export const SendSmsDialog = ({
  className,
  isOpen,
  onClose: handleClose,
  isEnableProviderSelector = false,
  sendType,
  messageData,
  integrationId = 0,
}: SendSmsDialogProps) => {
  const { t } = useTranslation([
    'common',
    'contentTemplate',
    'workflow',
    'settings',
  ])

  const dispatch = useAppDispatch()

  const [sendEvery8dMms, { isLoading: isEvery8dMmsSending }] =
    useSendEvery8dMmsMutation()
  const [senSmsTest, { isLoading: isSmsTestSending }] = useSendSmsTestMutation()
  const [sendEvery8dSms, { isLoading: isEvery8dSmsSending }] =
    useSendEvery8dSmsMutation()

  const { sendSmsSchema } = useSendSmsSchema()

  const { msgChannelOptions = [], isMsgChannelLoading } =
    useGetMsgChannelListQuery(
      {
        page: 1,
        perPage: THIRD_PARTY_MAX_COUNT,
        mediumType: 'sms',
      },
      {
        selectFromResult: ({
          data = INITIAL_PAGINATION_RESPONSE,
          isLoading,
        }) => ({
          msgChannelOptions: data.items.map(
            ({ title, integrationId, integrationType }) => ({
              value: integrationId,
              label: title,
              integrationType,
            })
          ),
          isMsgChannelLoading: isLoading,
        }),
      }
    )

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const formik = useFormik<SendSmsSchema>({
    enableReinitialize: true,
    initialValues: {
      tagInput: '',
      phoneNumbers: [],
      integrationId,
    },
    validationSchema: sendSmsSchema,
    onSubmit: async (values, action) => {
      try {
        // 只有 every8d 需要走舊有的 api, 未來新的 provider 如果實作在 third party gateway 裡面的話都是走 sms/* 路徑
        if (smsProvider === 'every8d' || smsProvider === 'every8d_exclusive') {
          if (sendType === 'sms') {
            await sendEvery8dSms({
              destinations: values.phoneNumbers.join(),
              integrationId: values.integrationId,
              subject: messageData.subject,
              message: messageData.message,
              replacementData: messageData.replacementData,
            }).unwrap()
          }

          if (sendType === 'mms') {
            await sendEvery8dMms({
              destinations: values.phoneNumbers.join(),
              integrationId: values.integrationId,
              subject: messageData.subject,
              message: messageData.message,
              replacementData: messageData.replacementData,
              imageType: messageData.imageType,
              attachment: messageData.base64,
            }).unwrap()
          }
        } else {
          await senSmsTest({
            destinations: values.phoneNumbers,
            integrationId: values.integrationId,
            subject: messageData.subject,
            message: messageData.message,
          }).unwrap()
        }

        dispatch(
          openToast({
            message: t('contentTemplate:send_test_sms_result_succeed'),
          })
        )

        handleClose()
      } catch (error) {
        dispatch(
          openToast({
            message: t('contentTemplate:send_test_sms_result_failed'),
            status: 'error',
          })
        )
      }
      action.resetForm()
    },
  })

  const tagInputError = formik.touched.tagInput && formik.errors.tagInput
  const phoneNumbersError =
    formik.touched.phoneNumbers && formik.errors.phoneNumbers
  const integrationIdError =
    formik.touched.integrationId && formik.errors.integrationId

  const smsProvider = msgChannelOptions.find(
    ({ value }) => value === formik.values.integrationId
  )?.integrationType

  return (
    <ConfirmDialog
      className={className}
      confirmText={t('contentTemplate:send')}
      isLoading={isEvery8dMmsSending || isSmsTestSending || isEvery8dSmsSending}
      isOpen={isOpen}
      modalTitle={t('contentTemplate:send_test_sms')}
      onConfirm={formik.handleSubmit}
      onClose={handleClose}
    >
      {isEnableProviderSelector && (
        <Box mb={2}>
          <Box color={theme.colors.textSecondBlue} mb={2}>
            {t('workflow:node_action_third_party')}*
          </Box>

          <Box mb={1}>
            <NestedList
              anchorId="integrationId"
              isDisabled={isMsgChannelLoading}
              isError={Boolean(integrationIdError)}
              label={t('workflow:node_action_third_party_select_hint')}
              onChange={async ({ value }) => {
                await formik.setFieldValue('integrationId', value)
              }}
              options={msgChannelOptions}
              popperZIndex={Z_INDEX.dialog}
              value={formik.values.integrationId}
            />
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            {smsProvider ? (
              <Box display="flex">{`${t(
                'settings:service'
              )}：SMS-${getThirdPartyProviderName(smsProvider)}`}</Box>
            ) : (
              Boolean(integrationIdError) && (
                <Box color={theme.colors.orangeyRed}>{integrationIdError}</Box>
              )
            )}
          </Box>
        </Box>
      )}

      <Box color={theme.colors.textSecondBlue} mb={2}>
        {t('contentTemplate:send_test_sms_hint')}
      </Box>

      <TagsField
        helperText={(tagInputError || phoneNumbersError) as string}
        maxCount={5}
        onChange={phoneNumbers =>
          formik.setFieldValue('phoneNumbers', phoneNumbers)
        }
        onInputChange={text => formik.setFieldValue('tagInput', text.trim())}
        onValidate={text => {
          if (!checkIsTaiwanPhone(text)) {
            formik.setFieldError('phoneNumbers', t('common:errors.invalid'))
            formik.setFieldTouched('phoneNumbers', true)
            return false
          }

          return true
        }}
        placeholder={t('contentTemplate:send_test_sms_placeholder')}
        values={formik.values.phoneNumbers}
      />
    </ConfirmDialog>
  )
}

export default SendSmsDialog
