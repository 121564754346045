import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetRfmUserGroupsQuery } from '@shared/api/rtkQuery'
import { Card } from '@shared/ui'
import SectionTitle from '@shared/ui/SectionTitle'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import TagDetail from './TagDetail'

const Rfm = () => {
  const { t } = useTranslation(['tag', 'userProfile', 'common'])
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const { data: rfmTagData = null, isFetching: isRfmTagDataFetching } =
    useGetRfmUserGroupsQuery({ cdpUserId })

  const hasNoData =
    (!rfmTagData || rfmTagData?.rfms.length === 0) && !isRfmTagDataFetching

  if (isRfmTagDataFetching) {
    return (
      <Box mb={5}>
        <SectionTitle title={t('tag:tag_source.intelligent')} />
        <LoadingSkeleton height={125} />
      </Box>
    )
  }

  return (
    <Box>
      <SectionTitle title={t('tag:tag_source.intelligent')} />
      {!isRfmTagDataFetching && (
        <Card mb={5}>
          <Box fontSize={16} lineHeight="24px" fontWeight={600} mb={2}>
            RFM
          </Box>
          <Box>
            {hasNoData && (
              <Box
                fontSize={15}
                lineHeight="21px"
                fontWeight={500}
                sx={theme => ({
                  color: theme.colors.textSecondBlue,
                })}
              >
                {t('common:empty_data')}
              </Box>
            )}
            {rfmTagData?.rfms.map(rfmTag => (
              <Box
                key={`${rfmTag.userGroupName}_${rfmTag.rfmInfo.rfmId}`}
                mb={3}
              >
                <TagDetail
                  name={rfmTag.rfmInfo.rfmDisplayName}
                  list={[t(`userProfile:rfm.${rfmTag.userGroupName}`)]}
                />
              </Box>
            ))}
          </Box>
        </Card>
      )}
    </Box>
  )
}

export default Rfm
