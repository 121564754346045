import { ChartData } from '@shared/ui/charts'

import { ChartDataSeriesGroupEntity } from '../types'

export const getChartDataSeriesGroupEntity = (series: ChartData['series']) => {
  const entity: ChartDataSeriesGroupEntity = series.reduce(
    (acc, curr, currIndex) => {
      const groupName = curr.groupName
        ? `${curr.groupName} | ${curr.label}`
        : ''

      if (groupName in acc.entities) {
        acc.entities[groupName].push({
          ...curr,
          colorIndex: currIndex,
        })
        return acc
      }

      acc.ids.push(groupName)
      acc.entities[groupName] = [{ ...curr, colorIndex: currIndex }]
      return acc
    },
    {
      ids: [],
      entities: {},
    } as ChartDataSeriesGroupEntity
  )

  return entity
}
