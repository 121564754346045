import type { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

import Card from '@shared/ui/Card'

import {
  CreateButton,
  CreateButtonProps,
  PreviewButton,
  PreviewButtonProps,
} from '../_shared/buttons'
import DonutChart from '../_shared/DonutChart'
import { getPercent } from '../utils'

type Props = {
  title?: string
  donutTitle: string
  userCount: number
  totalCount: number
} & CreateButtonProps &
  PreviewButtonProps

const AudiencePreviewer = ({
  title,
  userCount,
  totalCount,
  donutTitle,
  isLoading = false,
  isCreating = false,
  children,
  onPreview = () => {},
  onCreate = () => {},
}: PropsWithChildren<Props>) => {
  const percent = totalCount === 0 ? 0 : getPercent(userCount, totalCount)

  return (
    <Box sx={{ width: 264 }}>
      <Card p={3} mb={2}>
        {title && <p>{title}</p>}

        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 200 }}>
          <DonutChart
            title={donutTitle}
            displayValue={percent}
            symbol="%"
            isLoading={isLoading}
          />
        </Box>

        {children}

        <PreviewButton isLoading={isLoading} onPreview={onPreview} />
      </Card>

      <CreateButton isCreating={isCreating} onCreate={onCreate} />
    </Box>
  )
}

export default AudiencePreviewer
