import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import TrialApplicationImgUrl from '@shared/assets/images/trial/trialApplications.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import TrialFeatureCover from '@shared/ui/TrialFeatureCover'

import Detail from './Detail'
import Overview from './Overview'

const ApplicationRoutes = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(['common'])

  const isApplicationDisabled = useAppSelector(
    state => state.auth.plan.settings.application.disabled
  )

  return isApplicationDisabled ? (
    <TrialFeatureCover
      title={t('common:route.applications')}
      description={t('common:trial.applications.description')}
      notice={t('common:trial.applications.notice')}
      bgImageUrl={TrialApplicationImgUrl}
    />
  ) : (
    <Switch>
      <Route exact path={`${path}/new`}>
        <Detail isCreateMode />
      </Route>

      <Route path={`${path}/:applicationId`}>
        <Detail />
      </Route>

      <Route path={`${path}`}>
        <Overview />
      </Route>

      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default ApplicationRoutes
