import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'

import type { Combination } from '@shared/api/rtkQuery/audience'
import { Tooltip } from '@shared/ui/tooltips'

const CombinationButton = styled(
  forwardRef<
    HTMLButtonElement,
    ButtonProps & { buttonType?: Combination; disabled?: boolean }
  >(({ buttonType, disabled, ...otherProps }, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} ref={ref} />
  ))
)`
  && {
    margin: 0 4px;
    width: 56px;
    height: 24px;

    border: 1px solid transparent;
    border-color: ${({ buttonType, theme }) =>
      buttonType === 'and' ? 'transparent' : theme.colors.brightBlue};
    border-radius: 4px;

    background-color: ${({ buttonType, theme }) =>
      buttonType === 'and' ? theme.colors.brightBlue : theme.colors.white};
    color: ${({ buttonType, theme }) =>
      buttonType === 'and' ? theme.colors.white : theme.colors.brightBlue};

    font-size: inherit;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    :hover {
      background-color: ${({ buttonType, theme }) =>
        buttonType === 'and' ? theme.colors.brightBlue : theme.colors.white};
    }
  }
`

type CombinationButtonWithTooltipProps = {
  combination: Combination
  isDisable?: boolean
  handleCombinatorButtonClick: () => void
}

const CombinationButtonWithTooltip = ({
  combination,
  isDisable = false,
  handleCombinatorButtonClick,
}: CombinationButtonWithTooltipProps) => {
  const { t } = useTranslation('audience')

  return isDisable ? (
    <CombinationButton buttonType={combination} disabled>
      {combination === 'and'
        ? t('rule_combinator_all')
        : t('rule_combinator_any')}
    </CombinationButton>
  ) : (
    <Tooltip title={t('rule_combinator_change_wording')}>
      <CombinationButton
        buttonType={combination}
        onClick={handleCombinatorButtonClick}
      >
        {combination === 'and'
          ? t('rule_combinator_all')
          : t('rule_combinator_any')}
      </CombinationButton>
    </Tooltip>
  )
}

export default CombinationButtonWithTooltip
