import qs from 'qs'
import snakecaseKeys from 'snakecase-keys'

import type { ReplacementDataActionURL } from '@shared/api/rtkQuery/contentTemplate'
import checkHasUtmProperties from '@shared/lib/utils/checkHasUtmProperties'
import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'

const mapPreviewUrlWithUtm = (
  urlParams: ReplacementDataActionURL['urlParams']
) => {
  const { host, ...utmObj } = urlParams
  if (!host) {
    return ''
  }

  if (checkHasUtmProperties(host)) {
    return host
  }

  const [origin, query] = host.split('?')
  const queryStringObj = {
    ...qs.parse(query),
    ...deleteEmptyKeys(snakecaseKeys(utmObj)),
  }
  const queryString = qs.stringify(queryStringObj)

  return [origin, queryString].filter(Boolean).join('?')
}

export default mapPreviewUrlWithUtm
