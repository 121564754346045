import type { Node } from 'reactflow'

import type { NodeType } from '@shared/api/rtkQuery'

import type { TriggerNodeData } from '../nodes'

export const fetchAudienceId = (nodes: Node[]) => {
  const triggerNode = (nodes as Node<unknown, NodeType>[]).find(node => {
    return (
      node.type === 'trigger-enter_audience_rule' ||
      node.type === 'trigger-current_audience_rule'
    )
  }) as Node<TriggerNodeData>

  return triggerNode?.data?.audienceRuleId ?? null
}
