import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetConsumptionSummaryQuery,
  useGetUserValuesFromAiQuery,
} from '@shared/api/rtkQuery'

import {
  mapConsumptionSummaryToTableColumn,
  mapUserValuesToTableColumn,
} from './utils'

const useUserValuesTableColumn = () => {
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const {
    data: consumptionSummaries,
    isFetching: isConsumptionSummaryLoading,
  } = useGetConsumptionSummaryQuery(
    { cdpUserId },
    {
      selectFromResult: ({ data, isFetching }) => ({
        data: mapConsumptionSummaryToTableColumn(data),
        isFetching,
      }),
    }
  )
  const { data: userValues, isFetching: isUserValuesLoading } =
    useGetUserValuesFromAiQuery(
      { cdpUserId, type: 'pltv' },
      {
        selectFromResult: ({ data, isFetching }) => ({
          data: mapUserValuesToTableColumn(data),
          isFetching,
        }),
      }
    )

  return useMemo(() => {
    const isLoading = isConsumptionSummaryLoading || isUserValuesLoading
    return {
      isLoading,
      // data.length === 0 時，data table 才會長出 skeleton
      // 所以 isLoading 時，讓 data 為 empty array
      data: isLoading ? [] : [...userValues, ...consumptionSummaries],
    }
  }, [
    consumptionSummaries,
    isConsumptionSummaryLoading,
    isUserValuesLoading,
    userValues,
  ])
}

export default useUserValuesTableColumn
