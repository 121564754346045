import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { GridColDef } from '@mui/x-data-grid'

import type {
  CampaignInsight,
  MediumType,
  ThirdPartyAppProvider,
  TracingUrlInsight,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { Tooltip } from '@shared/ui/tooltips'
import {
  ALL_PROVIDERS,
  insightValueFormatter,
  insightValuesFormatterByProvider,
} from '@widgets/insight/lib/utils'
import { ErrorHint, TableCellWithHint } from '@widgets/insight/ui/_shared'
import { sortRowWithIgnoreId } from '@widgets/insight/ui/workflow/DetailInsightDataTable/_shared/utils'

const CELL_MIN_WIDTH = 120

const useColumns = (
  mediumType: MediumType,
  conversion: {
    isConversionEnable: boolean
    conversionEventDisplayName: string
    conversionValueDisplayName: string
  },
  ignoreSortIdPattern: string
): GridColDef[] => {
  const { t } = useTranslation(['table', 'insight'])
  const { getThirdPartyProviderName } = useThirdPartyProviderName()
  const customSortComparator = sortRowWithIgnoreId(ignoreSortIdPattern)

  const commonColumns: GridColDef<CampaignInsight>[] = [
    {
      headerName: '',
      field: 'tracingUrls',
      flex: 1,
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: ({ value }) => {
        if (!value) {
          return ''
        }

        return <ErrorHint tracingUrlInsights={value as TracingUrlInsight[]} />
      },
    },
    {
      headerName: t('table:campaign_name'),
      headerAlign: 'left',
      flex: 1,
      minWidth: 280,
      field: 'title',
      sortable: false,
      filterable: false,
      type: 'string',
      renderCell: ({ value, row }) => {
        if (!value) {
          return ''
        }

        return (
          <TableCellWithHint tracingUrlInsights={row.tracingUrls}>
            {value}
          </TableCellWithHint>
        )
      },
    },
    {
      headerName: t('table:marketing_channels'),
      headerAlign: 'left',
      field: 'msgChannelTitle',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      sortable: false,
      filterable: false,
      type: 'string',
    },
    {
      headerName: t('table:type'),
      headerAlign: 'left',
      field: 'groupByValues',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      sortable: false,
      filterable: false,
      renderCell: ({ value, row }) => {
        const [integrationType] = value
        const { id } = row

        if (id === ignoreSortIdPattern) {
          return t('insight:amount')
        }

        return integrationType
          ? getThirdPartyProviderName(integrationType)
          : t('insight:empty_third_party_provider')
      },
    },
    {
      headerName: t('table:sent_uu_count'),
      headerAlign: 'right',
      field: 'sentUuCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      filterable: false,
      align: 'right',
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { mediumType, groupByValues } = row

        // 全類型支援，不額外判斷 ignoreSortIdPattern
        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], // ThirdPartyAppProvider
          },
          {
            allowedMedium: {
              email: [ALL_PROVIDERS],
              sms: [ALL_PROVIDERS],
              line_push: [ALL_PROVIDERS],
            },
          }
        )
      },
    },
    {
      headerName: t('table:reception_uu_count'),
      headerAlign: 'right',
      field: 'receptionUuCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      filterable: false,
      align: 'right',
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { mediumType, groupByValues } = row

        // 因為 line_push 全不支援，其餘類型全支援。所以也不額外判斷 ignoreSortIdPattern
        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], // ThirdPartyAppProvider
          },
          {
            allowedMedium: {
              email: [ALL_PROVIDERS],
              sms: [ALL_PROVIDERS],
            },
          }
        )
      },
    },
    {
      headerName: t('table:reception_uu_count_rate'),
      headerAlign: 'right',
      field: 'receptionUuCountRate',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id, mediumType, groupByValues } = row

        if (id === ignoreSortIdPattern) {
          return '-'
        }

        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], // ThirdPartyAppProvider
          },
          {
            isPercentage: true,
            allowedMedium: {
              email: [ALL_PROVIDERS],
              sms: [ALL_PROVIDERS],
            },
          }
        )
      },
    },
    {
      headerName: t('table:open_uu_count'),
      headerAlign: 'right',
      field: 'openUuCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      filterable: false,
      align: 'right',
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id, mediumType, groupByValues } = row
        // ignoreSortIdPattern 在 line_push 需要全顯示
        if (id === ignoreSortIdPattern) {
          return insightValuesFormatterByProvider(
            {
              value,
              mediumType: mediumType,
              provider: groupByValues[0], // ThirdPartyAppProvider
            },
            {
              allowedMedium: {
                email: [ALL_PROVIDERS],
                line_push: [ALL_PROVIDERS],
              },
            }
          )
        }

        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], //ThirdPartyAppProvider
          },
          {
            allowedMedium: {
              email: [ALL_PROVIDERS],
              line_push: ['maac', 'super8', 'omnichat'],
            },
          }
        )
      },
    },
    {
      headerName: t('table:open_uu_per_sent'),
      headerAlign: 'right',
      field: 'openUuPerSent',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id, mediumType, groupByValues } = row

        if (id === ignoreSortIdPattern) {
          return '-'
        }

        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], // ThirdPartyAppProvider
          },
          {
            isPercentage: true,
            allowedMedium: {
              email: [ALL_PROVIDERS],
              line_push: ['maac', 'super8', 'omnichat'],
            },
          }
        )
      },
    },
    {
      headerName: t('table:click_uu_count'),
      headerAlign: 'right',
      field: 'clickUuCount',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id, mediumType, groupByValues } = row

        if (id === ignoreSortIdPattern) {
          return insightValuesFormatterByProvider(
            {
              value,
              mediumType: mediumType,
              provider: groupByValues[0], //ThirdPartyAppProvider,
            },
            {
              allowedMedium: {
                email: [ALL_PROVIDERS],
                line_push: [ALL_PROVIDERS],
                sms: [ALL_PROVIDERS],
              },
            }
          )
        }

        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0] as ThirdPartyAppProvider,
          },
          {
            allowedMedium: {
              email: [ALL_PROVIDERS],
              line_push: ['super8', 'coolbe_wyeth', 'maac', 'omnichat'],
              sms: [ALL_PROVIDERS],
            },
          }
        )
      },
    },
    {
      headerName: t('table:click_uu_per_sent'),
      headerAlign: 'right',
      field: 'clickUuPerSent',
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id, mediumType, groupByValues } = row

        if (id === ignoreSortIdPattern) {
          return '-'
        }

        return insightValuesFormatterByProvider(
          {
            value,
            mediumType: mediumType,
            provider: groupByValues[0], // ThirdPartyAppProvider
          },
          {
            isPercentage: true,
            allowedMedium: {
              email: [ALL_PROVIDERS],
              line_push: ['super8', 'coolbe_wyeth', 'maac', 'omnichat'],
              sms: [ALL_PROVIDERS],
            },
          }
        )
      },
    },
    {
      headerName: t('table:conversion_uu_count'),
      headerAlign: 'right',
      field: 'conversionUuCount',
      sortable: conversion.isConversionEnable,
      filterable: false,
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id } = row

        if (id === ignoreSortIdPattern && conversion.isConversionEnable) {
          return insightValueFormatter(value)
        }

        const isEnableConversionValue =
          conversion.isConversionEnable &&
          Boolean(conversion.conversionEventDisplayName)

        const tooltipTitle = isEnableConversionValue
          ? `${t('insight:conversion_event')}：${
              conversion.conversionEventDisplayName
            }`
          : `${t('insight:no_conversion_event')}`

        const displayValue = isEnableConversionValue
          ? insightValueFormatter(value)
          : '-'

        // ignoreSortIdPattern 不顯示 tooltip
        if (row.id === ignoreSortIdPattern) {
          return (
            <Box width="100%" textAlign="right">
              {displayValue}
            </Box>
          )
        }

        return (
          <Tooltip title={tooltipTitle}>
            <Box width="100%" textAlign="right">
              {displayValue}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      headerName: t('table:conversion_uu_per_sent'),
      headerAlign: 'right',
      field: 'conversionUuPerSent',
      sortable:
        conversion.isConversionEnable &&
        Boolean(conversion.conversionValueDisplayName),
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id } = row

        // ignoreSortIdPattern 不顯示
        if (id === ignoreSortIdPattern) {
          return '-'
        }

        return conversion.isConversionEnable
          ? insightValueFormatter(value, {
              isPercentage: true,
            })
          : '-'
      },
    },
    {
      headerName: t('table:conversion_value'),
      headerAlign: 'right',
      field: 'conversionValue',
      sortable:
        conversion.isConversionEnable &&
        Boolean(conversion.conversionValueDisplayName),
      flex: 1,
      minWidth: CELL_MIN_WIDTH,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value, row }) => {
        const { id } = row

        const isEnableConversionValue =
          conversion.isConversionEnable &&
          Boolean(conversion.conversionValueDisplayName)

        const tooltipTitle = isEnableConversionValue
          ? `${t('insight:conversion_value')}：${
              conversion.conversionValueDisplayName
            }`
          : `${t('insight:no_conversion_value')}`

        const displayValue = isEnableConversionValue
          ? insightValueFormatter(value)
          : '-'

        // ignoreSortIdPattern 不顯示 tooltip
        if (id === ignoreSortIdPattern) {
          return (
            <Box width="100%" textAlign="right">
              {displayValue}
            </Box>
          )
        }

        return (
          <Tooltip title={tooltipTitle}>
            <Box width="100%" textAlign="right">
              {displayValue}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      headerName: t('table:conversion_value_per_sent'),
      headerAlign: 'right',
      field: 'conversionValuePerSent',
      sortable:
        conversion.isConversionEnable &&
        Boolean(conversion.conversionValueDisplayName),
      flex: 1,
      minWidth: CELL_MIN_WIDTH * 2,
      align: 'right',
      filterable: false,
      sortComparator: customSortComparator,
      renderCell: ({ value }) =>
        // 全支援，不需要額外判斷 ignoreSortIdPattern
        conversion.isConversionEnable &&
        Boolean(conversion.conversionValueDisplayName)
          ? insightValueFormatter(value)
          : '-',
    },
  ]

  switch (mediumType) {
    case 'email':
      return [
        ...commonColumns,
        {
          headerName: t('table:bounce_uu_count'),
          headerAlign: 'right',
          field: 'bounceUuCount',
          flex: 1,
          minWidth: CELL_MIN_WIDTH,
          align: 'right',
          filterable: false,
          sortComparator: customSortComparator,
          renderCell: ({ value }) => insightValueFormatter(value),
        },
        {
          headerName: t('table:bounce_uu_per_sent'),
          headerAlign: 'right',
          field: 'bounceUuPerSent',
          flex: 1,
          minWidth: CELL_MIN_WIDTH,
          align: 'right',
          filterable: false,
          sortComparator: customSortComparator,
          renderCell: ({ value, row }) => {
            const { id } = row

            if (id === ignoreSortIdPattern) {
              return '-'
            }

            return insightValueFormatter(value, {
              isPercentage: true,
            })
          },
        },
        {
          headerName: t('table:complaint_uu_count'),
          headerAlign: 'right',
          field: 'complaintUuCount',
          align: 'right',
          flex: 1,
          minWidth: CELL_MIN_WIDTH,
          filterable: false,
          sortComparator: customSortComparator,
          renderCell: ({ value }) => insightValueFormatter(value),
        },
        {
          headerName: t('table:complaint_uu_per_sent'),
          headerAlign: 'right',
          field: 'complaintUuPerSent',
          align: 'right',
          flex: 1,
          minWidth: CELL_MIN_WIDTH,
          filterable: false,
          sortComparator: customSortComparator,
          renderCell: ({ value, row }) => {
            const { id } = row

            if (id === ignoreSortIdPattern) {
              return '-'
            }

            return insightValueFormatter(value, {
              isPercentage: true,
            })
          },
        },
      ]
    case 'sms':
    case 'line_push':
    default:
      return commonColumns
  }
}

export default useColumns
