import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { ProductKol } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { BlueBorderButton } from '@shared/ui/buttons'

type Props = {
  thumbnailUrl?: ProductKol['thumbnailUrl']
  contactUrl?: ProductKol['contactUrl']
}

const KOL_DEFAULT_LINK = 'https://www.kolradar.com/'
const KOL_UTM =
  'utm_source=cdp&utm_medium=kol_recommendation&utm_campaign=cdp_contact_kol&utm_content=contact_button'

const Avatar = ({ thumbnailUrl, contactUrl }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      mr={7}
    >
      {thumbnailUrl ? (
        <Box
          width={150}
          height={150}
          mb={2}
          borderRadius="50%"
          sx={theme => ({
            backgroundImage: `url(${thumbnailUrl})`,
            backgroundColor: theme.colors.lightGreyBlueTwo,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          })}
        />
      ) : (
        <Box
          width={150}
          height={150}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontSize={90}
          borderRadius="50%"
          className={ICON.usersAlt}
          sx={theme => ({
            backgroundColor: theme.colors.lightGreyBlueTwo,
          })}
        />
      )}

      <a
        href={`${contactUrl || KOL_DEFAULT_LINK}?${KOL_UTM}`}
        target="_blank"
        rel="noreferrer noopener"
        style={{ width: '100%' }}
      >
        <BlueBorderButton fullWidth>
          {t('aiMarketingAssistant:kol_recommendation.contact')}
        </BlueBorderButton>
      </a>
    </Box>
  )
}

export default Avatar
