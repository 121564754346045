import { useCallback, useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'
import isEqual from 'lodash/isEqual'

import type {
  ReplacementData,
  ReplacementDataTracingURL,
  TracingUrl,
} from '@shared/api/rtkQuery'
import { useGetTracingUrlListQuery } from '@shared/api/rtkQuery'

const useTracingUrlStatus = () => {
  const selectTracingUrlDict = useMemo(
    () =>
      createSelector(
        [
          (state: { data?: TracingUrl[]; isLoading: boolean }) => state.data,
          (state: { data?: TracingUrl[]; isLoading: boolean }) =>
            state.isLoading,
        ],
        (data = [], isLoading) => ({
          tracingUrlDict:
            data.reduce<Record<number, TracingUrl>>((collation, current) => {
              collation[current.id] = current
              return collation
            }, {}) || {},
          isLoading,
        }),
        { memoizeOptions: { equalityCheck: isEqual } }
      ),
    []
  )

  const { tracingUrlDict, isLoading } = useGetTracingUrlListQuery(undefined, {
    selectFromResult: selectTracingUrlDict,
  })

  const checkTracingUrlIsArchived = useCallback(
    (id: number, archivedAt: string | undefined = undefined) => {
      if (isLoading) {
        return false
      }

      if (Boolean(archivedAt)) {
        return true
      }

      // 有些地方即使 archived API 也不會回傳 archived 資訊，只能透過比對 all tracing url list 來確認
      return !tracingUrlDict[id]
    },
    [isLoading, tracingUrlDict]
  )

  const conversionReplacementDataToTracingUrlIdList = useCallback(
    (data: ReplacementData | undefined) => {
      if (!data) {
        return []
      }
      const replacementData = camelcaseKeys(data, {
        deep: true,
      }) as ReplacementData

      const tracingUrlIdList = Object.values(replacementData)
        .filter(item => item.type === 'tracing_url')
        .map(
          item => (item as ReplacementDataTracingURL).urlParams?.tracingUrlId
        )
        .filter(Boolean)

      return tracingUrlIdList
    },
    []
  )

  const checkHasArchivedTracingUrlInReplacementData = useCallback(
    (data: ReplacementData | undefined) =>
      conversionReplacementDataToTracingUrlIdList(data).some(item =>
        checkTracingUrlIsArchived(item)
      ),
    [conversionReplacementDataToTracingUrlIdList, checkTracingUrlIsArchived]
  )

  const getTracingUrlLength = useCallback(
    (id: number) => tracingUrlDict[id]?.length || 0,
    [tracingUrlDict]
  )

  return {
    checkTracingUrlIsArchived,
    conversionReplacementDataToTracingUrlIdList,
    checkHasArchivedTracingUrlInReplacementData,
    getTracingUrlLength,
  }
}

export default useTracingUrlStatus
