import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import type { WorkflowListItem } from '@shared/api/rtkQuery/workflow'
import { QUERY_STRING } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { TableColumn } from '@shared/ui/table'
import {
  defaultValueFormatter,
  Table,
  TableBody,
  TableBodyCell,
  TableCollapseCell,
  TableRow,
} from '@shared/ui/table'
import Thumbnail from '@shared/ui/Thumbnail'
import theme from '@theme'

import WorkflowNodeError from './WorkflowNodeError'

type Props = {
  data: WorkflowListItem
  columns: TableColumn<WorkflowListItem>[]
  currPage: number
}

const WorkflowListRow = ({ data, columns, currPage }: Props) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false)

  const { pathname } = useLocation()

  const createURL = useCallback(
    (triggerId?: number) => {
      const targetURL = `${pathname}/${data.id}/edit`
      const params = new URLSearchParams()

      if (triggerId) {
        params.append(QUERY_STRING.workflow.triggerId, `${triggerId}`)
      }

      return {
        pathname: targetURL,
        search: `?${params}`,
        state: {
          fromPage: currPage,
        },
      }
    },
    [currPage, data.id, pathname]
  )

  const history = useHistory()
  const { t } = useTranslation(['workflow'])
  const hasRowDetail =
    (data.campaigns && data.campaigns.length > 0) ||
    (data.subscriptions && data.subscriptions.length > 0)

  return (
    <>
      <TableRow isAllowHoverOnChild isBodyCell isClickable>
        {columns.map(
          (
            {
              align,
              field,
              paddingBottom,
              paddingTop,
              valueFormatter = defaultValueFormatter,
            },
            columnIndex
          ) => {
            const isSecondRowCell = columnIndex === 1
            const isLastRowCell = columnIndex === columns.length - 1
            const campaignCount =
              (data.campaigns && data.campaigns?.length) ?? 0
            const subscriptionCount =
              (data.subscriptions && data.subscriptions?.length) ?? 0

            const unTypedValueFormatter = valueFormatter as (
              value: unknown,
              data: WorkflowListItem
            ) => React.ReactNode

            const currValue = Array.isArray(field)
              ? field.map((item: keyof WorkflowListItem) => data[item])
              : data[field]

            const { pathname, search, state } = createURL()

            return (
              <TableBodyCell
                align={align}
                isLastRowCell={isLastRowCell}
                isWordBreakAll
                key={`${columnIndex}`}
                onClick={() => {
                  if (
                    isSecondRowCell &&
                    (campaignCount > 0 || subscriptionCount > 0)
                  ) {
                    setIsOpenCollapse(prev => !prev)
                    return
                  }

                  history.push(pathname + search, state)
                }}
                paddingBottom={paddingBottom}
                paddingTop={paddingTop}
              >
                {isSecondRowCell ? (
                  campaignCount > 0 || subscriptionCount > 0 ? (
                    <>
                      {unTypedValueFormatter(currValue, data)}

                      <Box
                        alignItems="center"
                        color={theme.colors.brightBlue}
                        display="flex"
                        fontWeight={600}
                      >
                        {campaignCount + subscriptionCount}{' '}
                        {t('workflow:campaigns.name')}
                        <Icon
                          icon={isOpenCollapse ? ICON.arrowUp : ICON.arrowDown}
                          color="inherit"
                        />
                      </Box>
                    </>
                  ) : (
                    <Link to={{ pathname, search, state }}>
                      <div>{unTypedValueFormatter(currValue, data)}</div>
                    </Link>
                  )
                ) : (
                  <>{unTypedValueFormatter(currValue, data)}</>
                )}
              </TableBodyCell>
            )
          }
        )}
      </TableRow>

      {hasRowDetail && (
        <TableRow>
          <TableCollapseCell isAllowHover colSpan={columns.length}>
            <Collapse in={isOpenCollapse} timeout="auto" unmountOnExit>
              <Table>
                <TableBody>
                  {data?.campaigns?.map(campaign => {
                    const { pathname, search, state } = createURL(
                      campaign.triggerId
                    )

                    return (
                      <TableRow key={campaign.id} isClickable>
                        <TableBodyCell colSpan={columns.length} isFirstRowCell>
                          <Link to={{ pathname, search, state }}>
                            <Box display="flex" alignItems="center">
                              <Box mr={3}>
                                {campaign.mediumType === 'email' && (
                                  <Thumbnail
                                    component="img"
                                    src={campaign.previewUrl}
                                  />
                                )}

                                {campaign.mediumType === 'sms' && (
                                  <Thumbnail
                                    component="icon"
                                    src={ICON.commentAltMessage}
                                  />
                                )}

                                {campaign.mediumType === 'line_push' && (
                                  <Thumbnail component="icon" src={ICON.line} />
                                )}
                              </Box>
                              <Box>
                                <p>{campaign.title}</p>
                                <WorkflowNodeError
                                  status={campaign.lastFinishSendStatus}
                                  replacementData={campaign.replacementData}
                                />
                              </Box>
                            </Box>
                          </Link>
                        </TableBodyCell>
                      </TableRow>
                    )
                  })}
                  {data?.subscriptions?.map(subscription => {
                    const { pathname, search, state } = createURL(
                      subscription.triggerId
                    )

                    return (
                      <TableRow
                        key={`subscription_${subscription.triggerId}`}
                        isClickable
                      >
                        <TableBodyCell colSpan={columns.length} isFirstRowCell>
                          <Link to={{ pathname, search, state }}>
                            <Box display="flex" alignItems="center">
                              <Box mr={3}>
                                <Thumbnail
                                  component="icon"
                                  src={ICON.userCheck}
                                />
                              </Box>
                              <Box>
                                <p>{subscription.subscriptionName}</p>
                                <WorkflowNodeError
                                  status={subscription.lastFinishSendStatus}
                                />
                              </Box>
                            </Box>
                          </Link>
                        </TableBodyCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCollapseCell>
        </TableRow>
      )}
    </>
  )
}

export default WorkflowListRow
