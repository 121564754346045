import styled from '@emotion/styled'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiInput from '@mui/material/Input'

import { DialogContent } from '@shared/ui/dialogs'

export const DROPDOWN_MENU_WIDTH = 400

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 12px 0;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

export const Container = styled(DialogContent)`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.textSecondBlue};

  & .MuiFormControlLabel-label {
    font-size: 14px;
  }

  & .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
`

export const Label = styled.span<{ isError?: boolean }>`
  flex-shrink: 0;
  flex-basis: 120px;

  margin-right: 24px;

  color: ${({ theme, isError }) =>
    isError ? theme.colors.chartRed : theme.colors.textPrimaryBlue};
  font-weight: 500;
`

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    padding: 0 20px 16px 20px;
  }
`

export const ProgressContainer = styled.div`
  height: 4px;
`

export const Input = styled(MuiInput)`
  &.MuiInput-root {
    /* 為了視覺對齊增加的間距 */
    margin-right: 16px;
  }

  > .MuiInput-input {
    font-size: 14px;

    &::placeholder {
      color: ${({ theme }) => theme.colors.textSecondBlue};
      opacity: 1;
    }
  }
`

export const HelperText = styled.p`
  margin-top: 16px;

  height: 20px;
  color: ${({ theme }) => theme.colors.chartRed};
  font-weight: 500;
`
