import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { IconClassName } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'

type LoadingButtonProps = {
  isLoading: boolean
  icon: IconClassName
  children: React.ReactNode
} & ButtonProps

export const LoadingButton = ({
  isLoading,
  icon,
  children,
  ...otherProps
}: LoadingButtonProps) => (
  <Button
    color="primary"
    variant="contained"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps}
    startIcon={
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={24}
      >
        {isLoading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          <Icon icon={icon} color="inherit" fontSize="small" />
        )}
      </Box>
    }
  >
    {children}
  </Button>
)

export default LoadingButton
