import escape from 'lodash/escape'

import { getFloatSafe } from '@shared/lib/utils/number'
import { NumberOrString } from '@shared/lib/utils/type'

import { ChartData, ChartDataSeriesGroupEntity } from '../types'
import { getDisplayData } from './getDisplayData'

export const getTooltipHTMLStringBarGroup = ({
  header,
  entity,
  colors,
  dataPointIndex,
  data,
  totalByEachXAxis,
  transposeSeries,
}: {
  header: string[]
  entity: ChartDataSeriesGroupEntity
  colors: string[]
  dataPointIndex: number
  data: ChartData[]
  totalByEachXAxis: number[]
  transposeSeries: (NumberOrString | null)[][]
}) => {
  const columns = Array<string>(header.length).fill('')

  entity.ids.forEach(id => {
    for (let i = 0; i < columns.length; i++) {
      if (i === 0) {
        columns[i] += `
          <div class="tooltip-name">
            ${id} | ${header[i]}
          </div>
        `
        continue
      }

      columns[i] += `
        <div class="tooltip-value">
          ${header[i]}
        </div>
      `
    }

    entity.entities[id].forEach(item => {
      for (let i = 0; i < columns.length; i++) {
        if (i === 0) {
          columns[i] += `
            <div class="tooltip-row">
              <div class="tooltip-circle"
                    style="background-color:${colors[item.colorIndex]};">
              </div>
              <div class="tooltip-name">
                ${escape(item.name)}
              </div>
            </div>
          `
          continue
        }

        if (i === columns.length - 1) {
          columns[i] += `
            <div class="tooltip-value">
              ${Math.round(
                getFloatSafe(
                  getFloatSafe(
                    transposeSeries[dataPointIndex][item.colorIndex]
                  ) / totalByEachXAxis[dataPointIndex]
                ) * 100
              )}%
            </div>
          `
          continue
        }

        columns[i] += `
          <div class="tooltip-value">
            ${getDisplayData(
              data?.[i - 1]?.series?.[item.colorIndex]?.data?.[dataPointIndex]
            )}
          </div>
        `
      }
    })
  })

  return `
    <div class="tooltip-wrapper">
      ${columns.map(x => `<div class="tooltip-column">${x}</div>`).join('')}
    </div>
  `
}
