import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  PolicyListParams,
  useLazyPolicyQuery,
  usePolicyListQuery,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import PaginationListWithSearchBar from '@shared/ui/Dropdown/PaginationListWithSearchBar'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

import { PolicyServiceEntity, transformToPolicyServiceEntity } from './utils'

const DROPDOWN_POPUP = {
  HEIGHT: 230,
  WIDTH: 315,
}

type Props = {
  values: PolicyServiceEntity[]
  onChange: (values: PolicyServiceEntity[]) => void
}

const UserPolicyDropdownList = ({ values, onChange }: Props) => {
  const { t, i18n } = useTranslation(['iam'])

  const [params, setParams] = useState<PolicyListParams>({
    page: 1,
    perPage: 5,
    search: '',
    types: ['default', 'custom'],
  })

  const [getPolicy] = useLazyPolicyQuery()

  const {
    data: policyList = INITIAL_PAGINATION_RESPONSE,
    isLoading: isLoadingPolicyList,
  } = usePolicyListQuery(params)

  const onProjectUserPolicyAdd = async (policyId: number) => {
    const policy = await getPolicy(policyId).unwrap()

    const policyInfo = transformToPolicyServiceEntity(policy)

    onChange([...values, policyInfo])
  }

  return (
    <PaginationListWithSearchBar
      key={i18n.language}
      isFetching={isLoadingPolicyList}
      paginationInfo={{
        totalCount: policyList.totalCount,
        rowsPerPage: params.perPage,
        currPage: params.page - 1,
      }}
      onPageChange={(page: number) => {
        setParams(prevState => ({
          ...prevState,
          page: page + 1,
        }))
      }}
      onItemSearch={search => {
        setParams(prevState => ({
          ...prevState,
          page: 1,
          search,
        }))
      }}
      label={t('iam:user.add')}
      childrenHeight={DROPDOWN_POPUP.HEIGHT}
      childrenWidth={DROPDOWN_POPUP.WIDTH}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '8px 24px',
          borderBottom: `1px solid ${theme.colors.black6}`,
          color: `${theme.colors.blueyGrey}`,
          fontSize: 10,
        }}
      >
        <Box flexGrow={1}>{t('iam:user.policy_name')}</Box>
        <Box flexGrow={1}>{t('iam:user.policy_type')}</Box>
      </Box>
      <MenuList>
        {policyList.items.map(item => (
          <MenuItem
            key={item.id}
            sx={{
              '&.MuiButtonBase-root.MuiMenuItem-gutters': {
                padding: '8px 24px',
              },
            }}
            onClick={() => {
              onProjectUserPolicyAdd(getIntSafe(item.id))
            }}
            disabled={values.some(p => p.id === item.id)}
          >
            <Box
              sx={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mr: 2,
              }}
            >
              {item.name}
            </Box>
            <Box flexGrow={1}>
              {t(
                `iam:user.${
                  item.type === 'default' ? 'policy_default' : 'policy_custom'
                }`
              )}
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </PaginationListWithSearchBar>
  )
}

export default UserPolicyDropdownList
