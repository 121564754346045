import * as yup from 'yup'

import axios from '@shared/api/axios'

// 進行 remote 驗證需要反覆打 API，使用 cache 減少請求次數
export const TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP = new Map<number, Date>()

const INVALID_AUDIENCE_RULE_IDS = new Set<number>()

// 參考 RTKQ 將快取過期時間設定為 1 分鐘
const CACHE_EXPIRATION = 1000 * 60

const checkHasCacheAndNotExpired = (id: number) =>
  TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP.get(id)! >
  new Date(Date.now() - CACHE_EXPIRATION)

export const triggerNodeSchema = yup.object({
  audienceRuleId: yup
    .number()
    .required()
    .test('is-exist', 'Audience rule not found', async id => {
      if (!id || INVALID_AUDIENCE_RULE_IDS.has(id)) {
        return false
      }

      // 在快取有效時間內就直接回傳成功，不打 API
      if (checkHasCacheAndNotExpired(id)) {
        return true
      }

      try {
        await axios.get(`audience_rules/${id}`)
        TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP.set(id, new Date())

        return true
      } catch {
        INVALID_AUDIENCE_RULE_IDS.add(id)

        return false
      }
    }),
})

interface TriggerNodeData extends yup.InferType<typeof triggerNodeSchema> {}

export type { TriggerNodeData }
