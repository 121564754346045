import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { usePostProductsKeywordsBlacklistMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { regexChineseOrAlphabetOrNumbers } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import { AddButton } from '@shared/ui/buttons'
import { ConfirmDialog } from '@shared/ui/dialogs'
import KeywordField from '@shared/ui/inputs/TagsField'
import theme from '@theme'

const AddBlacklistButton = () => {
  const { t } = useTranslation(['common', 'aiMarketingAssistant'])

  const { setFieldValue, handleSubmit, values, resetForm, errors } = useFormik<{
    keywords: string[]
  }>({
    initialValues: {
      keywords: [],
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      // 不能接受空白, 支援中英數字
      // 至少有一組
      // 不能有重複的

      keywords: yup
        .array()
        .of(
          yup
            .string()
            .required(() => t('common:errors.not_allowed_empty'))
            .matches(regexChineseOrAlphabetOrNumbers, {
              message: t('common:errors.only_support_chinese_english_number'),
              excludeEmptyString: true,
            })
        )
        .min(1, () => t('common:errors.required'))
        .test(
          'unique',
          t('common:errors.duplicate_name'),
          value => new Set(value).size === value?.length
        ),
    }),
    onSubmit: async values => {
      try {
        await postBlacklistKeywords(values.keywords).unwrap()

        resetForm()
      } catch (error) {
        dispatch(
          openToast({
            message: t('common:save_failure'),
            status: 'error',
          })
        )
      }

      setIsModalOpen(false)
    },
  })

  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [postBlacklistKeywords, { isLoading: isCreating }] =
    usePostProductsKeywordsBlacklistMutation()

  const handleClose = () => {
    setIsModalOpen(false)
    resetForm()
  }
  const handleKeywordChange = (values: string[]) =>
    setFieldValue('keywords', values)

  const keywordsError = Array.isArray(errors.keywords)
    ? errors.keywords.find(Boolean) // 找到第一個有錯誤訊息的
    : errors.keywords

  return (
    <>
      <Box>
        <AddButton
          label={t('aiMarketingAssistant:auto_tag_analysis.tag_blacklist')}
          size="small"
          bgColor="transparent"
          color={theme.colors.textPrimaryBlue}
          onClick={() => setIsModalOpen(true)}
          disabled={isCreating}
        />
      </Box>
      <ConfirmDialog
        modalTitle={t('aiMarketingAssistant:auto_tag_analysis.tag_blacklist')}
        confirmText={t('common:confirm')}
        isOpen={isModalOpen}
        isLoading={isCreating}
        onConfirm={handleSubmit}
        onClose={handleClose}
      >
        <KeywordField
          onChange={handleKeywordChange}
          placeholder={t(
            'aiMarketingAssistant:auto_tag_analysis.tag_blacklist_placeholder'
          )}
          values={values.keywords}
          helperText={keywordsError}
        />
      </ConfirmDialog>
    </>
  )
}

export default AddBlacklistButton
