import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { UserProfileEventReport } from '@shared/api/rtkQuery'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable } from '@shared/ui/table'

import {
  calculateEventReportColumns,
  calculateEventReportData,
  calculateEventRowDataByPage,
} from './utils'

type Props = {
  userProfileEventReport: UserProfileEventReport
}

const USER_PROFILE_EVENT_REPORT_PER_PAGE = 20

const ReportTable = ({ userProfileEventReport }: Props) => {
  const { t } = useTranslation(['userProfile'])
  const [page, setPage] = useState<number>(0)

  // tableColumn, tableData 是為了 DataTable 的 props 而做的轉換
  const tableColumn = useMemo(
    () => calculateEventReportColumns(userProfileEventReport.eventReport),
    [userProfileEventReport.eventReport]
  )

  const tableData = useMemo(
    () => calculateEventReportData(userProfileEventReport.eventReport),
    [userProfileEventReport.eventReport]
  )

  // 因為 BQ 的成本問題，這邊是 BE 回覆 100 筆，由前端作分頁
  const rowDataByPage = useMemo(
    () =>
      calculateEventRowDataByPage(
        tableData,
        page,
        USER_PROFILE_EVENT_REPORT_PER_PAGE
      ),
    [tableData, page]
  )

  if (!userProfileEventReport.active) {
    return null
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionTitle title={userProfileEventReport.reportName} />
        <Box
          fontWeight="500"
          fontSize={14}
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {t('userProfile:event_report_table_memo', { dataLength: 100 })}
        </Box>
      </Box>
      <DataTable
        columns={tableColumn}
        rows={rowDataByPage}
        page={page}
        rowsPerPage={USER_PROFILE_EVENT_REPORT_PER_PAGE}
        rowsPerPageOptions={[USER_PROFILE_EVENT_REPORT_PER_PAGE]}
        onPageChange={setPage}
        count={tableData.length}
      />
    </Box>
  )
}

export default ReportTable
