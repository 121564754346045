import { useMemo } from 'react'

import {
  FunnelAnalyticsNode,
  useLazyGetFunnelRuleSummaryQuery,
} from '@shared/api/rtkQuery'
import { useMetadataQuery } from '@shared/lib/utils/metadata'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import { FunnelChart } from '../../model/types'
import {
  getInitPreviewResponse,
  transformPreviewResponseBarChart,
  transformPreviewResponseLineChart,
} from '../utils'

export const useLazyPreviewResponse = (): {
  data: FunnelChart
  isFetching: boolean
  getFunnelRuleSummary: (arg: FunnelAnalyticsNode) => void
} => {
  const { eventEntity } = useMetadataQuery()

  const [getFunnelRuleSummary, { data, isFetching, error }] =
    useLazyGetFunnelRuleSummaryQuery()

  const { handleError } = useErrorHandler({ type: 'funnel' })

  const previewData = useMemo(() => {
    if (error) {
      handleError(error)
    }

    if (!data || isFetching || error || 'error' in data) {
      return getInitPreviewResponse()
    }

    const funnel = transformPreviewResponseBarChart(data, eventEntity)
    const trend = {
      chart: transformPreviewResponseLineChart(data, eventEntity).chart,
      totalConversionData: funnel.totalConversionData,
    }
    return {
      funnel_chart: funnel,
      trend_chart: trend,
    }
  }, [error, data, isFetching, eventEntity, handleError])

  return {
    data: previewData,
    isFetching,
    getFunnelRuleSummary,
  }
}
