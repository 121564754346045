import Box from '@mui/material/Box'
import type { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'

import { Dialog, DIALOG_PROGRESS_HEIGHT } from './base'

type FormBuilderProps = {
  children?: React.ReactNode
  isLoading?: boolean
  isOpen: boolean
  maxWidth?: DialogProps['maxWidth']
  modalTitle?: React.ReactNode
  onClose?: () => void
}

export const FormBuilderDialog = ({
  children,
  isLoading = false,
  isOpen,
  maxWidth = 'sm',
  modalTitle = '',
  onClose: handleClose,
}: FormBuilderProps) => (
  <Dialog fullWidth maxWidth={maxWidth} onClose={handleClose} open={isOpen}>
    <DialogTitle>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box fontSize={16} mt={1}>
          {modalTitle}
        </Box>

        <Box mx={1}>
          <IconButton onClick={handleClose}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Box>
      </Box>
    </DialogTitle>

    {children}

    <Box height={DIALOG_PROGRESS_HEIGHT}>{isLoading && <LinearProgress />}</Box>
  </Dialog>
)

export default FormBuilderDialog
