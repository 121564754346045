import { useTranslation } from 'react-i18next'

import {
  Subscription,
  useDeleteSubscriptionMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'

type Props = {
  isOpen: boolean
  onClose: () => void
  subscription: Subscription | undefined
}

const DeleteSubscription = ({ isOpen, onClose, subscription }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['contentTemplate'])

  const [deleteSubscription, { isLoading: isDeleting }] =
    useDeleteSubscriptionMutation()

  const handleDelete = async () => {
    if (!subscription) {
      return
    }

    try {
      await deleteSubscription(subscription.id).unwrap()

      onClose()
    } catch (error) {
      dispatch(
        openToast({
          status: 'error',
          message: t(
            'contentTemplate:subscription.dialog.delete_subscription.delete_fail'
          ),
        })
      )
    }
  }

  return (
    <DeleteConfirmDialog
      isLoading={isDeleting}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleDelete}
      modalTitle={t(
        'contentTemplate:subscription.dialog.delete_subscription.modal_title',
        {
          name: subscription?.name,
        }
      )}
    />
  )
}

export default DeleteSubscription
