import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import type { FunnelChartData } from '@shared/api/rtkQuery'
import { getFloatSafe } from '@shared/lib/utils/number'
import {
  ChartContainer,
  getHeight,
  getTooltipHTMLString,
  getTransposeArray,
  SizeMode,
} from '@shared/ui/charts'
import theme from '@theme'

import { getTooltipData } from './helpers'

type Props = {
  startIdx: number
  colors: string[]
  data: FunnelChartData
  size?: SizeMode
  maxVisibleChartsNum?: number
}

const BarChart = ({
  startIdx = 0,
  colors,
  data,
  size = 'large',
  maxVisibleChartsNum = 5,
}: Props) => {
  /*
    // example
    data = {
      categories: ['第一步', '第二步', '第三步', '第四步'],
      series: [
        {
          name: 'A',
          data: [100, 75, 83, 60],
          rawData: [8, 6, 5, 3],
        },
        {
          name: 'B',
          data: [100, 60, 67, 50],
          rawData: [5, 3, 2, 1],
        },
      ],
    }
  */
  const { t } = useTranslation(['dateAndChart'])

  const transposeConversionRates = getTransposeArray<number | null | string>(
    data.series.map(({ data }) => data)
  )
  const transposeConversionNumbers = getTransposeArray<number | null | string>(
    data.series.map(({ rawData }) => rawData)
  )
  const transposeLostRates = getTransposeArray<number | null | string>(
    data.series.map(({ lostRate }) => lostRate)
  )
  const transposeLostNumbers = getTransposeArray<number | null | string>(
    data.series.map(({ lostNumber }) => lostNumber)
  )

  const displayData = {
    categories: data.categories.slice(startIdx, startIdx + maxVisibleChartsNum),
    series: data.series.map(d => ({
      ...d,
      data: d.data.slice(startIdx, startIdx + maxVisibleChartsNum),
      rawData: d.rawData.slice(startIdx, startIdx + maxVisibleChartsNum),
    })),
  }

  const height = getHeight(size)

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors,
    dataLabels: {
      enabled: true,
      offsetY: -16,
      style: {
        fontSize: '12px',
        colors: ['#353232'],
      },
      formatter: function (value) {
        return `${getFloatSafe(value).toFixed(1)}%`
      },
      background: {
        enabled: true,
        foreColor: '#ffffff',
        padding: 6,
        borderRadius: 4,
        opacity: 1,
        borderWidth: 0,
        dropShadow: {},
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '50%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    stroke: {
      width: 2,
      colors: ['#fff'],
    },
    xaxis: {
      categories: displayData.categories.map(
        (c, idx) => `${t('step')}${idx + 1 + startIdx} ${c}`
      ),
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
      max: 100,
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: theme.colors.borderColor,
    },
    tooltip: {
      fixed: {
        enabled: size !== 'large',
        offsetY: height,
      },
      custom: function ({
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
        w: any
      }) {
        // header = [TBD, '轉換人數', '轉換率', '流失人數', '流失率',]
        const header = [
          `${data.categories[dataPointIndex + startIdx]}`,
          t('dateAndChart:statistics.conversion_number'),
          t('dateAndChart:statistics.conversion_rate'),
          t('dateAndChart:statistics.loss_number'),
          t('dateAndChart:statistics.loss_rate'),
        ]
        // name = ['groupA', 'groupB']
        const names = data.series.map(({ name }) => name)

        /*
          第一步： dataPointIndex === 0
          data2D = [
            [100, '100%', 0, '0%'],
            [100, '100%', 0, '0%'],
          ]
          第二步：dataPointIndex === 1
          data2D = [
            [6, '75%', 2, '25%'],
            [3, '60%', 2, '40%'],
          ]
        */
        const data2D = getTooltipData({
          dataPointIndex: dataPointIndex + startIdx,
          transposeConversionRates,
          transposeConversionNumbers,
          transposeLostRates,
          transposeLostNumbers,
        })

        return getTooltipHTMLString({
          header,
          colors,
          names,
          data2D,
        })
      },
    },
  }

  return (
    <ChartContainer sx={{ height }}>
      <ReactApexChart
        // tooltip 不明原因會抓到上一次的 data，導致顯示錯誤，這裡強制 remount，雖然可恥但是有用
        key={`${displayData?.series?.[0]?.data?.[0]}_${displayData?.series?.[0]?.name}}`}
        options={options}
        series={displayData.series}
        type="bar"
        height={height}
      />
    </ChartContainer>
  )
}

export default BarChart
