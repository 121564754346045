import styled from '@emotion/styled'

const Wrapper = styled.div`
  margin-bottom: 25px;
`

const Title = styled.div`
  margin-bottom: 8px;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-family: inherit;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.13px;
  line-height: 1.5;
`

const HorizontalLine = styled.div`
  width: 52px;
  height: 3px;
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.deepSkyBlue} 99%,
    ${({ theme }) => theme.colors.brightBlue} 0%
  );
`

type Props = {
  title: string
}

const SectionTitle = ({ title }: Props) => (
  <Wrapper>
    <Title>{title}</Title>
    <HorizontalLine />
  </Wrapper>
)

export default SectionTitle
