import { api, transformResponseCamelCaseKeys } from '../api'
import type {
  AgeGender,
  MembersNonMembers,
  Retention,
  UserProfileSummary,
} from './type'

// 圖表資料需要隨著後台設定馬上更新，BE 會實作 cache，所以把 keepUnusedDataFor 設成 0，不額外在 useQuery 設定 refetchOnMountOrArgChange
const audienceSummaryApi = api.injectEndpoints({
  endpoints: builder => ({
    getAudienceSummaryMemberNonMember: builder.query<MembersNonMembers, number>(
      {
        query: id => ({
          url: `/audience_summary/${id}/member_non_member`,
        }),
        keepUnusedDataFor: 0,
        transformResponse: res =>
          transformResponseCamelCaseKeys(res as MembersNonMembers),
      }
    ),
    getAudienceSummaryUserProfileSummary: builder.query<
      UserProfileSummary,
      {
        audienceId: number
        type: 'gender' | 'member_status' | 'city'
      }
    >({
      query: ({ audienceId, type }) => ({
        url: `/audience_summary/${audienceId}/${type}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as UserProfileSummary),
    }),
    getAudienceSummaryAgeGender: builder.query<AgeGender, number>({
      query: id => ({
        url: `/audience_summary/${id}/age_gender`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as AgeGender),
    }),
    getAudienceSummaryRfm: builder.query<
      UserProfileSummary,
      {
        audienceId: number
        rfmId: number
      }
    >({
      query: ({ audienceId, rfmId }) => ({
        url: `/audience_summary/${audienceId}/rfm/${rfmId}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as UserProfileSummary),
    }),
    getAudienceSummaryRetention: builder.query<
      Retention,
      {
        audienceId: number
        retentionId: number
      }
    >({
      query: ({ audienceId, retentionId }) => ({
        url: `/audience_summary/${audienceId}/retention/${retentionId}`,
      }),
      keepUnusedDataFor: 0,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as Retention),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAudienceSummaryMemberNonMemberQuery,
  useGetAudienceSummaryUserProfileSummaryQuery,
  useGetAudienceSummaryAgeGenderQuery,
  useGetAudienceSummaryRfmQuery,
  useGetAudienceSummaryRetentionQuery,
} = audienceSummaryApi
