import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import type { EventFilterNode } from '@shared/api/rtkQuery'
import { useMetadataQuery } from '@shared/lib/utils/metadata'
import { AddButton } from '@shared/ui/buttons'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import {
  AUDIENCE_FILTER_EVENT_MAX_COUNT,
  AudienceFilterListItem,
  AudienceFilterListItemContainer,
  AudienceFilterListItemHeader,
  AudienceFilterNestedListItem,
  AudienceFilterNestedListItemContainer,
  FilterCombinator,
} from '../_shared'
import { createInitialState } from './eventFilterReducer'
import type { EventFilterAction } from './eventFilterReducer.type'
import MeasureFilter from './MeasureFilter'

type Props = {
  state?: EventFilterNode
  dispatch: (action: EventFilterAction) => void
  isDisabled: boolean
}

const EventFilter = ({
  state: { node: filter } = createInitialState(),
  dispatch,
  isDisabled = !dispatch,
}: Props) => {
  const { t } = useTranslation(['audience'])

  const { eventEntity } = useMetadataQuery()

  const getFirstEventName = useCallback(
    () =>
      eventEntity.classification.entities[eventEntity.classification.ids[0]]
        ?.events[0],
    [eventEntity]
  )

  return (
    <>
      {filter.nodes.length > 0 && (
        <FilterCombinator
          combination={filter.combination}
          isDisabled={isDisabled}
          onChange={() => {
            dispatch({ type: 'toggleAudienceFilterCombination' })
          }}
        />
      )}

      <ul>
        {filter.nodes.map((item, index) => (
          <AudienceFilterListItem
            key={`event-filter-${index}`}
            data-combination={filter.combination.toUpperCase()}
          >
            {item.type === 'group' && item.nodes.length > 1 && (
              <AudienceFilterListItemHeader>
                <FilterCombinator
                  combination={item.combination}
                  isDisabled={isDisabled}
                  onChange={() => {
                    dispatch({
                      type: 'toggleAudienceFilterGroupCombination',
                      payload: index,
                    })
                  }}
                />
              </AudienceFilterListItemHeader>
            )}

            <AudienceFilterListItemContainer>
              {item.type === 'group' && (
                <>
                  <ul>
                    {item.nodes.map((nestedItem, nestedIndex) => (
                      <AudienceFilterNestedListItem
                        key={`event-filter-${index}-${nestedIndex}`}
                        data-combination={
                          item.nodes.length > 1
                            ? filter.nodes[index].combination.toUpperCase()
                            : ''
                        }
                      >
                        <AudienceFilterNestedListItemContainer>
                          <MeasureFilter
                            state={nestedItem}
                            isCloneDisabled={
                              item.nodes.length >=
                              AUDIENCE_FILTER_EVENT_MAX_COUNT
                            }
                            isDisabled={isDisabled}
                            dispatch={action => {
                              dispatch({
                                type: 'updateNestedMeasure',
                                payload: {
                                  parentIndex: index,
                                  nestedIndex,
                                  measureAction: action,
                                },
                              })
                            }}
                            onDelete={() => {
                              dispatch({
                                type: 'removeNestedMeasure',
                                payload: {
                                  parentIndex: index,
                                  nestedIndex,
                                },
                              })
                            }}
                            onClone={() => {
                              dispatch({
                                type: 'cloneNestedMeasure',
                                payload: {
                                  targetParentIndex: index,
                                  sourceNestedIndex: nestedIndex,
                                },
                              })
                            }}
                          />
                        </AudienceFilterNestedListItemContainer>
                      </AudienceFilterNestedListItem>
                    ))}
                  </ul>

                  {!isDisabled && (
                    <ConditionalTooltip
                      title={t('audience:v3.filter_max_hint_event', {
                        max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
                      })}
                      isShow={
                        item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT
                      }
                    >
                      <AddButton
                        label={t('audience:add')}
                        size="small"
                        color={theme.colors.textPrimaryBlue}
                        disabled={
                          item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT
                        }
                        onClick={() => {
                          const eventName = getFirstEventName()
                          dispatch({
                            type: 'addNestedMeasure',
                            payload: {
                              index,
                              eventName,
                            },
                          })
                        }}
                      />
                    </ConditionalTooltip>
                  )}
                </>
              )}
            </AudienceFilterListItemContainer>
          </AudienceFilterListItem>
        ))}
      </ul>

      {!isDisabled && (
        <ConditionalTooltip
          title={t('audience:v3.filter_max_hint_event', {
            max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
          })}
          isShow={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
        >
          <AddButton
            label={t('audience:add')}
            size="small"
            disabled={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
            onClick={() => {
              const eventName = getFirstEventName()
              dispatch({
                type: 'addAudienceFilterGroupItem',
                payload: { eventName },
              })
            }}
          />
        </ConditionalTooltip>
      )}
    </>
  )
}

export default EventFilter
