import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import trialDashboard from '@shared/assets/images/dashboard/trialDashboard.svg'
import { PAGE_ROOT } from '@shared/model/constants/routes'
import FeatureIntroduction from '@shared/ui/FeatureIntroduction'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { checkAllChartError } from '@widgets/audience/lib/chartUtils'

export const SectionFeatureIntroduction = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation(['aiMarketingAssistant', 'audience'])
  const { isChartLoading, isChartError } = useAudienceDetailState()
  const isAllChartsError = checkAllChartError(isChartError)

  const isVisible = useMemo(
    () =>
      pathname.includes(PAGE_ROOT.analysis) &&
      isAllChartsError &&
      !isChartLoading,
    [isAllChartsError, isChartLoading, pathname]
  )

  if (!isVisible) {
    return null
  }

  return (
    <FeatureIntroduction
      title={t('audience:audience_analysis')}
      description={t('audience:audience_analysis_description')}
      notice={t('aiMarketingAssistant:empty_desc')}
      imageUrl={trialDashboard}
    />
  )
}

export default SectionFeatureIntroduction
