import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { NodeType } from '@shared/api/rtkQuery'
import iconAbUrl from '@shared/assets/images/icons/icon-ab.svg'
import iconBestChannel from '@shared/assets/images/icons/icon-ai-magic.svg'
import iconCheckSquareUrl from '@shared/assets/images/icons/icon-check-square.svg'
import iconClockUrl from '@shared/assets/images/icons/icon-clock.svg'
import iconCodeBranchUrl from '@shared/assets/images/icons/icon-code-branch.svg'
import iconCommentAltLinesUrl from '@shared/assets/images/icons/icon-comment-alt-lines.svg'
import iconEnvelopeAltUrl from '@shared/assets/images/icons/icon-envelope-alt.svg'
import iconLineUrl from '@shared/assets/images/icons/icon-line.svg'
import iconPlayUrl from '@shared/assets/images/icons/icon-play.svg'
import iconUserCheck from '@shared/assets/images/icons/icon-user-check.svg'

type NodeTypeMap = {
  [name in NodeType]: { iconUrl?: string; name: string; badgeText?: string }
}

export const useNodeTypeMap = () => {
  const { t } = useTranslation(['common', 'workflow'])

  const nodeTypeMap = useMemo<NodeTypeMap>(
    () => ({
      'trigger-enter_audience_rule': {
        iconUrl: iconPlayUrl,
        name: t('workflow:node_trigger'),
      },
      'trigger-current_audience_rule': {
        iconUrl: iconPlayUrl,
        name: t('workflow:node_trigger'),
      },
      timing: {
        iconUrl: iconClockUrl,
        name: t('workflow:node_timing_delay'),
      },
      'timing-best': {
        iconUrl: iconClockUrl,
        name: t('workflow:node_timing_delay'),
      },
      'logic-best_channel': {
        iconUrl: iconBestChannel,
        name: t('workflow:node_logic_best_channel.title'),
        badgeText: 'NEW',
      },
      'logic-event_filter': {
        iconUrl: iconCodeBranchUrl,
        name: t('workflow:node_logic_condition'),
      },
      'logic-group': {
        iconUrl: iconAbUrl,
        name: 'A/B Test',
        badgeText: 'NEW',
      },
      'action-send_email': {
        iconUrl: iconEnvelopeAltUrl,
        name: t('common:medium_type.email'),
      },
      'action-send_line_push': {
        iconUrl: iconLineUrl,
        name: t('common:medium_type.line_push'),
      },
      'action-send_sms': {
        iconUrl: iconCommentAltLinesUrl,
        name: t('common:medium_type.sms'),
      },
      'action-publish_subscription': {
        iconUrl: iconUserCheck,
        name: t('workflow:node_subscriptions.title'),
        badgeText: 'NEW',
      },
      exit: {
        iconUrl: iconCheckSquareUrl,
        name: t('workflow:node_exit'),
      },
    }),
    [t]
  )

  return nodeTypeMap
}
