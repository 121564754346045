import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

import api from './api'

export type AuthType = 'firebase' | 'sso'

export interface JWTPayload {
  auth_type: AuthType
  email: string
  exp: number
  ika_id: string
  project_name: string
  user_id: number
}

export type FirebaseAuthPayload = {
  firebaseIdToken: string
  ikaId: string
}

export type SSOAuthPayload = {
  serviceId: string
  jwt: string
}

export type GetCDPAuthTokenPayload = FirebaseAuthPayload | SSOAuthPayload

type GetCDPAuthTokenResponse = {
  authToken: string
}

export const getCDPAuthToken = async (payload: GetCDPAuthTokenPayload) => {
  const res = await api.get<GetCDPAuthTokenResponse>('/token', {
    params: snakecaseKeys(payload),
  })
  return res.data.authToken
}

export type ProjectPlanType = 'trial' | 'standard' | 'lite'

interface ProjectIdInfoFromApi {
  id: number
  ika_id: string
  plan_type: ProjectPlanType
}

export const getProjectList = async () => {
  const res = await api.get<ProjectIdInfoFromApi[]>('/projects', {
    headers: {
      'cache-control': 'no-cache',
      Accept: '*/*',
    },
  })

  return res.data?.map(item => camelcaseKeys(item, { deep: true })) ?? []
}
