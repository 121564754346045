import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import ConfirmDialog from '@shared/ui/dialogs/ConfirmDialog'
import { Button as BaseButton } from '@shared/ui/Dropdown/ActionButton'
import RoundedSearchBar from '@shared/ui/Dropdown/RoundedSearchBar'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme, { ThemeColor } from '@theme'

const Button = styled(BaseButton)`
  &&.MuiButton-root {
    padding-right: ${({ isDisabled }) => (isDisabled ? 16 : 8)}px;

    height: auto;
    background-color: ${({ bgColor, theme, isDisabled }) =>
      isDisabled
        ? theme.colors.veryLightBlueTwo
        : bgColor || theme.colors.bgPrimaryGrey};

    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
  }

  & > .MuiButton-label {
    color: ${({ theme }) => theme.colors.lightNavyBlue};
    font-size: 13px;
  }
`

export type Option = { label: string; value: string }

type Props = {
  bgColor?: ThemeColor
  isDisabled?: boolean
  isRequired?: boolean
  onValueChanged: (values: string[]) => void
  options: Option[]
  values: string[]
}

const TagListDialog = ({
  bgColor,
  isDisabled,
  isRequired = false,
  onValueChanged,
  options,
  values,
}: Props) => {
  const { t } = useTranslation('common')
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedValues, setSelectedValues] = useState<string[]>(values)
  const [filterText, setFilterText] = useState<string>('')

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(filterText.toLowerCase())
  )

  const handleOnConfirm = () => {
    setIsDialogOpen(false)
    onValueChanged(selectedValues)
  }

  useEffect(() => {
    setSelectedValues(values)
  }, [values])

  return (
    <>
      <Button
        bgColor={bgColor}
        isDisabled={isDisabled}
        isError={isRequired ? values.length === 0 : false}
        onClick={() => setIsDialogOpen(true)}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          flexWrap="wrap"
          mr={1}
          minHeight={32}
        >
          {values.length > 0 ? (
            values.map(value => (
              <Box
                display="inline-block"
                textAlign="left"
                m={0.5}
                px={1.5}
                py={0.5}
                key={value}
                minHeight="24px"
                sx={{
                  backgroundColor: theme.colors.textPrimaryBlue,
                }}
                borderRadius="2px"
                color={theme.colors.white}
              >
                {options.find(x => x.value === value)?.label || value}
              </Box>
            ))
          ) : (
            <>{t('dropdownlist_default')}</>
          )}
        </Box>
        {!isDisabled && (
          <Icon
            icon={ICON.arrowDown}
            fontSize="small"
            color={theme.colors.textPrimaryBlue}
          />
        )}
      </Button>

      <ConfirmDialog
        modalTitle={t('dropdownlist_default')}
        isOpen={isDialogOpen}
        onConfirm={handleOnConfirm}
        onClose={() => setIsDialogOpen(false)}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="48px"
          borderBottom={`1px solid ${theme.colors.black6}`}
        >
          <RoundedSearchBar
            value={filterText}
            placeholder={t('placeholder_search')}
            autoFocus
            onChange={setFilterText}
          />
        </Box>
        <Box mt={3}>
          {filteredOptions.map(({ value }) => {
            const isSelected = selectedValues.indexOf(value) > -1

            return (
              <Box
                display="inline-block"
                textAlign="left"
                m={0.5}
                px={1.5}
                py={0.5}
                key={value}
                minHeight="24px"
                sx={{
                  backgroundColor: isSelected
                    ? theme.colors.textPrimaryBlue
                    : theme.colors.bgPaleGrey,
                  cursor: 'pointer',
                }}
                borderRadius="2px"
                color={
                  isSelected ? theme.colors.white : theme.colors.brightBlue
                }
                onClick={() => {
                  if (isSelected) {
                    setSelectedValues(selectedValues.filter(x => x !== value))

                    return
                  }

                  setSelectedValues(prev => [...prev, value])
                }}
              >
                {options.find(x => x.value === value)?.label || value}
              </Box>
            )
          })}
        </Box>
      </ConfirmDialog>
    </>
  )
}

export default TagListDialog
