import type {
  CampaignEventFilterNode,
  EventFilterNode,
  UserProfileAndTagFilterNode,
} from '@shared/api/rtkQuery'

export const createFilterEntity = (
  filters: (
    | EventFilterNode
    | UserProfileAndTagFilterNode
    | CampaignEventFilterNode
  )[]
) => {
  return filters.reduce<{
    event: EventFilterNode | undefined
    user_profile_tag: UserProfileAndTagFilterNode | undefined
    campaign_event: CampaignEventFilterNode | undefined
  }>(
    (acc, filter) => {
      // 同樣的程式碼分開寫是為了讓 union type 被 narrowing
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html
      if (filter.type === 'event') {
        acc[filter.type] = filter
      }

      if (filter.type === 'user_profile_tag') {
        acc[filter.type] = filter
      }

      if (filter.type === 'campaign_event') {
        acc[filter.type] = filter
      }

      return acc
    },
    { event: undefined, user_profile_tag: undefined, campaign_event: undefined }
  )
}

export default createFilterEntity
