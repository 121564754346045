import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'

type Props = {
  width?: number | string
  height?: number | string
}

const LoadingSkeleton = ({ width, height }: Props) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
        boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)',
      }}
    >
      <CircularProgress />
    </Paper>
  )
}

export default LoadingSkeleton
