import type { MetadataEventEntity } from '@shared/api/rtkQuery'

export const getEventPropertyLabel = (
  eventNameList: string[],
  eventsEntity: MetadataEventEntity['events'],
  propertyId: string
) => {
  const displayNameList = eventNameList.map(
    eventName =>
      eventsEntity.entities[eventName]?.propertiesEntity[propertyId]
        ?.displayName ?? ''
  )

  return Array.from(new Set(displayNameList)).join(' | ')
}

export default getEventPropertyLabel
