import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { useGetWorkflowListQuery } from '@shared/api/rtkQuery'
import TrialWorkflow from '@shared/assets/images/trial/trialWorkflow.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import DeepBlueGradientButton from '@shared/ui/Dropdown/DeepBlueGradientButton'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { TabItem, TabWrapper } from '@shared/ui/tabs'
import { ConditionalTooltip } from '@shared/ui/tooltips'

import WorkflowConfiguration from '../WorkflowConfiguration'
import { WorkflowSenderCreate, WorkflowSenderList } from '../WorkflowSenders'
import WorkflowList from './WorkflowList'

const WorkflowOverview = () => {
  const { t } = useTranslation(['common', 'workflow'])

  const maxCount = useAppSelector(state => state.auth.plan.workflow.maxCount)
  const isShowInstruction = useAppSelector(
    state => state.auth.plan.workflow.introduction
  )

  const { path, url } = useRouteMatch()
  const { pathname } = useLocation()

  // 打 List 只是為了拿 totalCount，若後端有其他 API 可以取代
  const {
    data: { totalCount } = { totalCount: undefined },
    isSuccess: isWorkflowListSuccess,
    isLoading: isWorkflowListLoading,
  } = useGetWorkflowListQuery({
    page: 1,
    perPage: 10,
    name: '',
    statusTypes: [],
  })

  const isWorkflowListExceededLimit = useMemo(() => {
    if (totalCount === undefined) {
      return false
    }

    return totalCount >= maxCount
  }, [totalCount, maxCount])

  return (
    <>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('workflow:instruction.workflow_instruction_title')}
          description={t(
            'workflow:instruction.workflow_instruction_description'
          )}
          notice={t('workflow:instruction.workflow_instruction_notice', {
            maxCount,
          })}
          imageUrl={TrialWorkflow}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Breadcrumbs>
          <Box>{t('common:route.workflow')}</Box>
        </Breadcrumbs>

        <DeepBlueGradientButton buttonText={t('common:create')}>
          <MenuList>
            <ConditionalTooltip
              title={t('workflow:instruction.upper_limit', {
                maxCount: maxCount,
              })}
              containerDisplay="block"
              placement="left"
              isShow={isWorkflowListSuccess && isWorkflowListExceededLimit}
            >
              <MenuItem
                component={Link}
                to={`${path}/${PAGE_ROOT.new}`}
                disabled={isWorkflowListLoading || isWorkflowListExceededLimit}
              >
                {t('common:route.workflow')}

                {isWorkflowListLoading && (
                  <CircularProgress size={16} sx={{ ml: 1 }} />
                )}
              </MenuItem>
            </ConditionalTooltip>

            <MenuItem
              component={Link}
              to={`${url}/${PAGE_ROOT.senders}/${PAGE_ROOT.new}`}
            >
              {t('common:route.workflow_senders')}
            </MenuItem>
          </MenuList>
        </DeepBlueGradientButton>
      </Box>

      <TabWrapper>
        <Link to={url}>
          <TabItem isSelected={url === pathname}>
            {t('workflow:workflow_list')}
          </TabItem>
        </Link>

        <Link to={`${url}/${PAGE_ROOT.configuration}`}>
          <TabItem
            isSelected={`${url}/${PAGE_ROOT.configuration}` === pathname}
          >
            {t('workflow:workflow_setting')}
          </TabItem>
        </Link>

        <Link to={`${url}/${PAGE_ROOT.senders}`}>
          <TabItem
            isSelected={pathname.startsWith(`${url}/${PAGE_ROOT.senders}`)}
          >
            {t('common:route.workflow_senders')}
          </TabItem>
        </Link>
      </TabWrapper>

      <Switch>
        <Route path={path} exact>
          <WorkflowList />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.configuration}`}>
          <WorkflowConfiguration />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.senders}`}>
          <WorkflowSenderList />

          <Route path={`${path}/${PAGE_ROOT.senders}/${PAGE_ROOT.new}`}>
            <WorkflowSenderCreate />
          </Route>
        </Route>

        <Redirect to={path} />
      </Switch>
    </>
  )
}

export default WorkflowOverview
