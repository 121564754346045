import {
  type PaginationParams,
  api,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type {
  CdpUserThirdPartyTag,
  ThirdPartyTag,
  ThirdPartyTagFormValues,
  ThirdPartyTagList,
} from './type'

export const INITIAL_THIRD_PARTY_TAG_DATA: ThirdPartyTag = {
  id: undefined,
  name: '',
  description: '',
  appType: '',
  thirdPartyAppId: undefined,
  appName: '',
  tagStatistics: undefined,
}

const thirdPartyApiUrl = '/third_party_tags'

const thirdPartyTag = api.injectEndpoints({
  endpoints: build => ({
    createThirdPartyTag: build.mutation<void, ThirdPartyTagFormValues>({
      query: body => ({
        url: thirdPartyApiUrl,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        { type: 'ThirdPartyTag', id: 'LIST' },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),
    getThirdPartyTag: build.query<ThirdPartyTag, string>({
      query: id => ({
        url: `${thirdPartyApiUrl}/${id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ThirdPartyTag),
      providesTags: [{ type: 'ThirdPartyTagDetail' }],
    }),
    updateThirdPartyTag: build.mutation<
      ThirdPartyTagFormValues,
      { id: string } & Pick<ThirdPartyTagFormValues, 'name' | 'description'>
    >({
      query: body => ({
        url: `${thirdPartyApiUrl}/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) {
          // invalid no cache when error
          return []
        }

        return [
          { type: 'ThirdPartyTag', id: arg.id },
          { type: 'ThirdPartyTagDetail' },
        ]
      },
    }),
    deleteThirdPartyTag: build.mutation<void, string>({
      query: id => ({
        url: `${thirdPartyApiUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        { type: 'ThirdPartyTag', id: 'LIST' },
        { type: 'ThirdPartyTagDetail' },
        { type: 'MetadataUserProfiles', id: 'LIST' },
      ],
    }),

    getThirdPartyTagList: build.query<ThirdPartyTagList, PaginationParams>({
      query: params => ({
        url: thirdPartyApiUrl,
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as ThirdPartyTagList),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'ThirdPartyTag' as const,
                id,
              })),
              { type: 'ThirdPartyTag', id: 'LIST' },
            ]
          : [{ type: 'ThirdPartyTag', id: 'LIST' }],
    }),
    getCdpUserThirdPartyTagList: build.query<
      CdpUserThirdPartyTag,
      { cdpUserId: string }
    >({
      query: params => ({
        url: `${thirdPartyApiUrl}/user_tags`,
        params,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as CdpUserThirdPartyTag),
      providesTags: (result, err, arg) => [
        { type: 'CdpUserThirdPartyTagList', id: arg.cdpUserId },
      ],
    }),
  }),
})

export const {
  useCreateThirdPartyTagMutation,
  useGetThirdPartyTagQuery,
  useUpdateThirdPartyTagMutation,
  useDeleteThirdPartyTagMutation,
  useGetThirdPartyTagListQuery,
  useGetCdpUserThirdPartyTagListQuery,
} = thirdPartyTag
