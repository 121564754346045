import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { Tooltip } from '@shared/ui/tooltips'

import type { FormValues } from './ExportTextMessage'

type Props = {
  sendType: FormValues['sendType']
  validCount?: number
  messageLength: number
  totalKB: number
  totalCost: number
  credit: number
  provider: string
}

export const PriceEvaluation = ({
  sendType,
  validCount = 0,
  messageLength = 0,
  totalCost,
  totalKB,
  credit,
  provider,
}: Props) => {
  const { t } = useTranslation(['audience'])

  return (
    <>
      <Box sx={{ display: 'inline-block', fontWeight: 'bold' }}>
        {t('audience:export_sms.calculator')}
      </Box>
      <Tooltip
        title={t('audience:export_sms.calculator_hint', {
          sms_provider: provider.toUpperCase(),
        })}
      >
        <i className={ICON.infoCircle} />
      </Tooltip>
      &nbsp;&nbsp;
      {/* mms: 0.03 KB / 280KB (上限) | 14 則 | 42 點 */}
      {/* sms: 13 字 | 14 則 | 42 點 */}
      {t('audience:export_sms.calculator_detail_1', {
        message_size: t(
          `audience:export_sms.calculator_message_size.${sendType}`,
          {
            size: sendType === 'sms' ? messageLength : totalKB,
          }
        ),
        count: validCount,
        total_cost: totalCost,
      })}
      <Box component="span" sx={{ color: theme => theme.colors.brightBlue }}>
        {t('audience:export_sms.calculator_detail_2', {
          credit,
        })}
      </Box>
    </>
  )
}

export default PriceEvaluation
