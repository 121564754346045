import type { NestedOption } from '../../types'

export const getLabelFromNestedOptions = <
  T extends string | number | undefined
>(
  options: NestedOption<T>[],
  value: T,
  parentValue?: T
) => {
  if (parentValue) {
    const level1Option = options.find(x => x.value === parentValue)
    if (level1Option && level1Option.options) {
      const level2Option = level1Option.options.find(x => x.value === value)
      return `${level1Option.label} - ${level2Option?.label}`
    }
  }

  return options.find(x => x.value === value)?.label
}
