import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import List from '@mui/material/List'
import BaseListItem from '@mui/material/ListItem'

import Dropdown from '@shared/ui/Dropdown'
import RoundedSearchBar from '@shared/ui/Dropdown/RoundedSearchBar'
import HighlightText from '@shared/ui/HighlightText'
import type { ThemeColor } from '@theme'

const Container = styled.div`
  display: flex;

  width: 640px;
  height: 248px;

  font-size: 13px;
  font-weight: 500;
`

const Navigator = styled.div`
  flex-shrink: 0;
  flex-basis: 185px;

  overflow-y: scroll;
  border-right: solid 1px ${({ theme }) => theme.colors.black6};

  & .MuiListItem-root {
    border-left: solid 3px transparent;
    transition: border-left 0.5s;

    &.Mui-selected,
    &.Mui-selected:hover {
      border-left-color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

const ListItem = styled(BaseListItem)`
  &.MuiListItem-root {
    padding-left: 32px;
    color: ${({ theme }) => theme.colors.textSecondBlue};
    cursor: pointer;
    overflow-wrap: anywhere;

    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: ${({ theme }) => theme.colors.veryLightBlueThree};
      color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  position: relative;
  overflow-y: scroll;
  scroll-behavior: smooth;
`

const SearchBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

const CategoryName = styled.h3`
  padding-left: 24px;

  background-color: ${({ theme }) => theme.colors.bgIceBlue};
  color: ${({ theme }) => theme.colors.blueyGrey};
  font-size: 10px;
  line-height: 24px;
`

const ItemContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;

  .MuiListItem-root {
    flex-basis: 50%;
  }
`

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 64px;

  color: ${({ theme }) => theme.colors.textSecondBlue};
`

const getCategoryHTMLElementId = (id: string) => `category-id-${id}`

type FilteredCategory = {
  id: string
  options: string[]
}

type EntityCategory = {
  id: string
  name: string
  options: string[]
}

type OptionCategory = {
  id: string
  name: string
  categoryId: string
}

type Entity = {
  categories: {
    byId: { [id: string]: EntityCategory }
    allIds: string[]
  }
  options: {
    byId: { [id: string]: OptionCategory }
  }
}

export type Category = {
  id: string
  name: string
  options: {
    label: string
    value: string
  }[]
}

type Props = {
  categories: Category[]
  selectedValue?: string
  anchorElem?: JSX.Element
  bgColor?: ThemeColor
  defaultLabel?: string
  isDisabled?: boolean
  enableResetButton?: boolean
  popperDisablePortal?: boolean
  popperZIndex?: number
  onValueChanged: (id: string) => void
}

const CategoryList = ({
  categories,
  selectedValue = '',
  anchorElem,
  bgColor,
  defaultLabel,
  isDisabled,
  enableResetButton = false,
  popperDisablePortal,
  popperZIndex,
  onValueChanged,
}: Props) => {
  const { t } = useTranslation('common')

  const [filterText, setFilterText] = useState('')
  const [activeCategoryId, setActiveCategoryId] = useState<string>()
  const [isOpen, setIsOpen] = useState(false)

  const mainRef = useRef<HTMLDivElement>(null)

  // 資料正規化以便後續存取特定項目資料
  const entities = useMemo(() => {
    const reduced = categories.reduce<Entity>(
      (acc, curr) => {
        const options: string[] = []
        curr.options.forEach(item => {
          acc.options.byId[item.value] = {
            id: item.value,
            name: item.label,
            categoryId: curr.id,
          }
          options.push(item.value)
        })

        acc.categories.byId[curr.id] = {
          id: curr.id,
          name: curr.name,
          options,
        }
        acc.categories.allIds.push(curr.id)

        return acc
      },
      { categories: { byId: {}, allIds: [] }, options: { byId: {} } }
    )

    return reduced
  }, [categories])

  useEffect(() => {
    if (activeCategoryId && mainRef.current && document) {
      const targetId = getCategoryHTMLElementId(activeCategoryId)
      const elem = document.getElementById(targetId)
      if (elem) {
        mainRef.current.scrollTo(0, elem.offsetTop)
      }
    }
  }, [activeCategoryId])

  // 紀錄篩選後的項目數量，為零則顯示空值圖
  const filteredCategories = categories.reduce<FilteredCategory[]>(
    (prev, curr) => {
      const filteredOptions = curr.options
        .filter(item => item.label.includes(filterText))
        .map(item => item.value)

      if (filteredOptions.length > 0) {
        prev.push({ id: curr.id, options: filteredOptions })
      }

      return prev
    },
    []
  )

  const currActiveCategoryId =
    activeCategoryId ||
    entities.options.byId[selectedValue]?.categoryId ||
    entities.categories.allIds[0]

  return (
    <Dropdown
      label={
        entities.options.byId[selectedValue]?.name ||
        defaultLabel ||
        t('dropdownlist_default')
      }
      isOpen={isOpen}
      anchorElem={anchorElem}
      bgColor={bgColor}
      isDisabled={isDisabled}
      setIsOpen={setIsOpen}
      enableResetButton={enableResetButton}
      popperDisablePortal={popperDisablePortal}
      popperZIndex={popperZIndex}
      onValueChanged={onValueChanged}
    >
      <Container>
        <Navigator>
          <List disablePadding>
            {entities.categories.allIds.map(id => {
              const currCategory = entities.categories.byId[id]
              return (
                <ListItem
                  selected={currActiveCategoryId === id}
                  key={id}
                  onClick={() => {
                    setActiveCategoryId(id)
                  }}
                >
                  {currCategory.name} ({currCategory.options.length})
                </ListItem>
              )
            })}
          </List>
        </Navigator>
        <MainContainer>
          <SearchBarContainer>
            <RoundedSearchBar
              value={filterText}
              placeholder={t('placeholder_search')}
              autoFocus
              onChange={setFilterText}
            />
          </SearchBarContainer>
          <Main ref={mainRef}>
            {filteredCategories.length === 0 ? (
              <Empty>{t('no_match')}</Empty>
            ) : (
              filteredCategories.map(({ id: categoryId, options }) => {
                const category = entities.categories.byId[categoryId]
                return (
                  <section
                    key={getCategoryHTMLElementId(categoryId)}
                    id={getCategoryHTMLElementId(categoryId)}
                  >
                    <CategoryName>{category.name}</CategoryName>
                    <ItemContainer>
                      {options.map(optionId => {
                        const currItem = entities.options.byId[optionId]
                        return (
                          <ListItem
                            key={optionId}
                            selected={optionId === selectedValue}
                            onClick={() => {
                              setFilterText('')
                              setIsOpen(false)
                              setActiveCategoryId(currItem.categoryId)
                              onValueChanged(optionId)
                            }}
                          >
                            <HighlightText
                              text={currItem.name}
                              target={filterText}
                            />
                          </ListItem>
                        )
                      })}
                    </ItemContainer>
                  </section>
                )
              })
            )}
          </Main>
        </MainContainer>
      </Container>
    </Dropdown>
  )
}

export default CategoryList
