import cloneDeep from 'lodash/cloneDeep'

import { ChartDataWithEventIndex } from '@shared/api/rtkQuery'
import { ChartData } from '@shared/ui/charts'

/**
 * 對齊來源資料與來源比對資料的群組順序
 * @param chartDataList 來源資料
 * @param chartDataListCompared 來源比對資料
 * @returns 群組順序對齊的來源資料與來源比對資料
 */
export const filterAndSortDataCompared = (
  chartDataList: ChartDataWithEventIndex[],
  chartDataListCompared: ChartDataWithEventIndex[]
) => {
  const chartDataListComparedSorted: ChartData[] = []

  chartDataList.forEach(currData => {
    const chartDataCompared = chartDataListCompared.find(
      x => x.eventIndex === currData.eventIndex
    )
    if (chartDataCompared === undefined) {
      return
    }

    const seriesList: ChartData['series'] = []

    currData.series.forEach(({ groupName, name }) => {
      const targetSeries = chartDataCompared.series.find(
        x => x.groupName === groupName && x.name === name
      ) || {
        name,
        groupName,
        data: Array<number>(chartDataCompared.categories.length).fill(0),
      }
      seriesList.push(cloneDeep(targetSeries))
    })

    chartDataListComparedSorted.push({
      categories: [...chartDataCompared.categories],
      series: seriesList,
    })
  })
  return [chartDataList, chartDataListComparedSorted]
}
