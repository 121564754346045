import styled from '@emotion/styled'

import { INDICATOR_HEIGHT } from '../constants'

export const IndicatorWrapper = styled.div`
  display: flex;
  align-items: flex-stat;

  width: 100%;
  height: ${INDICATOR_HEIGHT}px;
`

export const IndicatorItem = styled.div`
  flex-shrink: 1;
  flex-basis: ${INDICATOR_HEIGHT}px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 28%;
  color: ${({ theme }) => theme.colors.blueyGrey};

  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;
`

export const IndicatorLabel = styled.div`
  overflow: hidden;

  max-width: 90%;
  height: 20px;

  text-overflow: ellipsis;
  white-space: nowrap;
`

export const IndicatorValue = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: 500;
`
