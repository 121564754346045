import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import type { FunnelAnalyticsNode } from '@shared/api/rtkQuery'
import { useI18nOptions } from '@shared/lib/hooks/i18n'
import { getIntSafe } from '@shared/lib/utils/number'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { PrimaryGreyInput as Input } from '@shared/ui/inputs'

import type { FunnelAnalyticsFilterAction } from './funnelAnalyticsFilterReducer'

const Text = styled.span`
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 13px;
  font-weight: 500;
`

type Unit = 'day' | 'hour' | 'minite'

const UNIT_OPTIONS: { label: ParseKeys<'dateAndChart'>; value: Unit }[] = [
  {
    label: 'unit.day',
    value: 'day',
  },
  {
    label: 'unit.hour',
    value: 'hour',
  },
  {
    label: 'unit.minite',
    value: 'minite',
  },
]

const UnitConfig = {
  day: {
    min: 1,
    max: 365,
    toMins: 1440,
    default: 7,
  },
  hour: {
    min: 1,
    max: 8760,
    toMins: 60,
    default: 1,
  },
  minite: {
    min: 1,
    max: 525600,
    toMins: 1,
    default: 1,
  },
} as const

const INITIAL_VALUE = 0

const getUnitAndValue = (mins: number): [Unit, number] => {
  if (Number.isInteger(mins / UnitConfig.day.toMins)) {
    return ['day', mins / UnitConfig.day.toMins]
  }

  if (Number.isInteger(mins / UnitConfig.hour.toMins)) {
    return ['hour', mins / UnitConfig.hour.toMins]
  }

  return ['minite', mins]
}

type Props = {
  state: FunnelAnalyticsNode
  dispatch: (action: FunnelAnalyticsFilterAction) => void
}

const TimeWindow = ({ state, dispatch }: Props) => {
  const [t] = useTranslation('analytics')
  const [unit, setUnit] = useState<Unit>('day')
  const [currValue, setCurrValue] = useState(INITIAL_VALUE)

  const unitOptions = useI18nOptions(UNIT_OPTIONS, 'dateAndChart')

  useEffect(() => {
    const [nextUnit, nextCurrValue] = getUnitAndValue(state.timeWindowMinutes)
    setUnit(nextUnit)
    setCurrValue(nextCurrValue)
  }, [state.timeWindowMinutes])

  return (
    <>
      <Box display="inline" mr={1}>
        <Text>{t('funnel.time_window')}</Text>
      </Box>

      <Box display="inline" mr={1}>
        <Input
          value={currValue.toString()}
          type="number"
          min={UnitConfig[unit].min}
          max={UnitConfig[unit].max}
          width={88}
          onChange={e => {
            const value = Math.max(
              Math.min(getIntSafe(e.target.value), UnitConfig[unit].max),
              UnitConfig[unit].min
            )

            dispatch({
              type: 'updateTimeWindowMinites',
              payload: {
                mins: value * UnitConfig[unit].toMins,
              },
            })
            setCurrValue(value)
          }}
        />
      </Box>

      <Box display="inline" mr={1}>
        <DropdownList
          // 註：因為是 uncontrolled 元件，需要 remount 以綁定最新的值
          key={`${unitOptions[0].label}_${unit}`}
          options={unitOptions}
          defaultOption={unitOptions.find(x => x.value === unit)}
          onValueChanged={({ value }) => {
            const nextUnit = value as Unit
            const nextCurrValue = UnitConfig[nextUnit].default

            dispatch({
              type: 'updateTimeWindowMinites',
              payload: {
                mins: nextCurrValue * UnitConfig[nextUnit].toMins,
              },
            })
            setUnit(nextUnit)
            setCurrValue(nextCurrValue)
          }}
        />
      </Box>
    </>
  )
}

export default TimeWindow
