export const LAYOUT = {
  // Sidebar
  sidebarWidth: 240,
  sidebarWidthCollapse: 72,

  // ListItem on Sidebar
  listItemPaddingLeft: 35,
  listItemPaddingLeftSidebarOpen: 24,
  listItemPaddingLeftNested: 85,
  listItemPaddingRight: 10,
  listItemHeight: 57,
  listItemHeightNested: 44,
  listItemBorderLeft: 3,

  // Header
  headerHeight: 74,

  // Content
  contentRightSideGap: 40,
  contentLeftSideGap: 40,
  contentMarginTop: 40,
  contentMinWidth: 1024,
  breadcrumbsMarginBottom: 40,
  copyrightMarginVertical: 40,

  // Audience Builder
  audienceBuilder: {
    heightOfRow: 42,
    inputBorderRadius: 4,
  },

  // Breakpoint
  bk768: 768,
  bk1024: 1024,
}

// https://iconscout.com/unicons/explore/line
export const ICON = {
  analytics: 'uil uil-analytics',
  android: 'uil uil-android',
  angleDoubleUp: 'uil uil-angle-double-up',
  angleDown: 'uil uil-angle-down',
  angleRight: 'uil uil-angle-right',
  apple: 'uil uil-apple',
  apps: 'uil uil-apps',
  arrowDown: 'uil uil-angle-down',
  arrowGrowth: 'uil uil-arrow-growth',
  arrowLeft: 'uil uil-angle-left',
  arrowRight: 'uil uil-angle-right',
  arrowRightB: 'uil uil-angle-right-b',
  arrowUp: 'uil uil-angle-up',
  bell: 'uil uil-bell',
  bill: 'uil uil-bill',
  box: 'uil uil-box',
  browser: 'uil uil-browser',
  calendarAlt: 'uil uil-calendar-alt',
  capture: 'uil uil-capture',
  channel: 'uil uil-channel',
  chart: 'uil uil-chart',
  chartBar: 'uil uil-chart-bar',
  chartLine: 'uil uil-chart-line',
  chartPie: 'uil uil-chart-pie',
  checkCircle: 'uil uil-check-circle',
  checkSquare: 'uil uil-check-square',
  clock: 'uil uil-clock',
  cloudUpload: 'uil uil-cloud-upload',
  codeBranch: 'uil uil-code-branch',
  commentAltLines: 'uil uil-comment-alt-lines',
  commentAltMessage: 'uil uil-comment-alt-message',
  copy: 'uil uil-copy',
  createDashboard: 'uil uil-create-dashboard',
  database: 'uil uil-database',
  documentLayoutLeft: 'uil uil-document-layout-left',
  dollarAlt: 'uil uil-dollar-alt',
  draggabledots: 'uil uil-draggabledots',
  editAlt: 'uil uil-edit-alt',
  ellipsisH: 'uil uil-ellipsis-h',
  ellipsisV: 'uil uil-ellipsis-v',
  envelope: 'uil uil-envelope',
  envelopeAlt: 'uil uil-envelope-alt',
  exclamationTriangle: 'uil uil-exclamation-triangle',
  eye: 'uil uil-eye',
  fileAlt: 'uil uil-file-alt',
  fileCheckAlt: 'uil uil-file-check-alt',
  fileEditAlt: 'uil uil-file-edit-alt',
  filePlusAlt: 'uil uil-file-plus-alt',
  fileSearchAlt: 'uil uil-file-search-alt',
  fileTimesAlt: 'uil uil-file-times-alt',
  fileUploadAlt: 'uil uil-file-upload-alt',
  filter: 'uil uil-filter',
  folderOpen: 'uil uil-folder-open',
  globe: 'uil uil-globe',
  import: 'uil uil-import',
  infoCircle: 'uil-info-circle',
  line: 'uil uil-line',
  link: 'uil uil-link',
  listOl: 'uil uil-list-ol',
  megaphone: 'uil uil-megaphone',
  mouse: 'uil uil-mouse',
  multiply: 'uil uil-multiply',
  paperclip: 'uil uil-paperclip',
  pen: 'uil uil-pen',
  percentage: 'uil uil-percentage',
  play: 'uil uil-play',
  plusCircle: 'uil uil-plus-circle',
  redo: 'uil uil-redo',
  robot: 'uil uil-robot',
  save: 'uil uil-save',
  schedule: 'uil uil-schedule',
  search: 'uil uil-search',
  setting: 'uil uil-setting',
  shoppingBag: 'uil uil-shopping-bag',
  shoppingCart: 'uil uil-shopping-cart',
  signalAlt3: 'uil uil-signal-alt-3',
  slidersVAlt: 'uil uil-sliders-v-alt',
  sync: 'uil uil-sync',
  thumbsUp: 'uil uil-thumbs-up',
  times: 'uil uil-times',
  timesCircle: 'uil uil-times-circle',
  trash: 'uil uil-trash',
  trashAlt: 'uil uil-trash-alt',
  userCheck: 'uil uil-user-check',
  usersAlt: 'uil uil-users-alt',
  userSquare: 'uil uil-user-square',
  windows: 'uil uil-windows',
} as const

// https://stackoverflow.com/questions/50044023/get-union-type-from-indexed-object-values
export type IconClassName = (typeof ICON)[keyof typeof ICON]

export const Z_INDEX = {
  // material-ui default value
  dialog: 1300,
  // material-ui default value
  sidebar: 1200,
  // material-ui default value
  header: 1100,
  loader: 1000,
  popup: 1000,
  tooltip: 900,
  infoBox: 900,
}
