import { useMemo } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getRoot } from 'lexical'

import { getAllNodes } from '@entities/mediumTemplates/ui/SmsEditor/_shared/utils'
import { $isUtmNode } from '@entities/mediumTemplates/ui/SmsEditor/utm/nodes/UtmNode'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import { getCostPerMessage } from '@shared/lib/utils/textMessage/utils'

export const useWordCount = () => {
  const { getTracingUrlLength } = useTracingUrlStatus()
  const [editor] = useLexicalComposerContext()
  const editorState = editor.getEditorState()

  const { contentPureTextLength, allTracingUrlLength } = editorState.read(
    () => {
      const root = $getRoot()

      /* 把換行符號更換成一個空白字完 */
      const contentPureTextLength = root
        .getTextContent()
        .replace(/\n\n/g, ' ').length

      const allTracingUrlLength = getAllNodes(root).reduce((acc, node) => {
        if (node && $isUtmNode(node)) {
          const utmReplacementData = node.getUtmReplacementData()

          if (utmReplacementData?.type === 'tracing_url') {
            const tracingId = utmReplacementData.urlParams?.tracingUrlId
            const tracingUrlLength = getTracingUrlLength(tracingId) + 2 // 實際發送出去會再前後加上空白，所以要加 2

            return acc + tracingUrlLength
          }
        }

        return acc
      }, 0)

      return { contentPureTextLength, allTracingUrlLength }
    }
  )

  const { count, cost } = useMemo(() => {
    /* 不計算 metadataTag 的長度 */
    const count = contentPureTextLength + allTracingUrlLength
    const cost = getCostPerMessage('sms', count, 0)

    return {
      count,
      cost,
    }
  }, [contentPureTextLength, allTracingUrlLength])

  return {
    count,
    cost,
  }
}
export default useWordCount
