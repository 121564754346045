import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import type { MediumType } from '@shared/api/rtkQuery'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getLastNDays } from '@shared/ui/DateRangePicker'

export type InsightFilterType = 'campaign' | 'subscription'

type SharedState = {
  timeRangeParams: NumberOrString[]
  searchText: string
  mediumType: MediumType[]
  filterType: InsightFilterType
}

const StateContext = createContext<SharedState | null>(null)
const SetStateContext = createContext<Dispatch<
  SetStateAction<SharedState>
> | null>(null)

export const createInitState = (): SharedState => {
  const lastWeekRange = getLastNDays(7)

  return {
    timeRangeParams: [
      lastWeekRange.startDate.toISOString(),
      lastWeekRange.endDate.toISOString(),
    ],
    searchText: '',
    mediumType: [],
    filterType: 'campaign',
  }
}

export const SharedStateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [sharedState, setSharedState] = useState<SharedState>(createInitState())

  return (
    <StateContext.Provider value={sharedState}>
      <SetStateContext.Provider value={setSharedState}>
        {children}
      </SetStateContext.Provider>
    </StateContext.Provider>
  )
}

export function useSharedState() {
  const context = useContext(StateContext)

  if (!context) {
    throw new Error('useSharedState must be used within the StateProvider')
  }

  return context
}

export function useSetSharedState() {
  const context = useContext(SetStateContext)
  if (!context) {
    throw new Error(
      'useSharedState must be used within the useSharedStateProvider'
    )
  }
  return context
}

export default SharedStateProvider
