import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRTKQueryError } from '@entities/apiHandler'
import { useAppDispatch } from '@shared/lib/hooks'
import {
  MAXIMUM_BEHAVIOR_TAG_COUNT,
  MAXIMUM_TAG_COUNT,
} from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'

import { STATUS_CODE } from '../../constants'
import { TagSourceType } from '../../type'

const useHandleError = (tagSourceType: TagSourceType) => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation(['tag', 'common', 'settings', 'audience'])

  const handleTagApiError = useCallback(
    (e: unknown) => {
      const typedError = formatRTKQueryError(e)

      if (typedError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
        dispatch(
          openToast({
            message: t('common:errors.duplicate_name'),
            status: 'error',
          })
        )

        return
      }

      if (typedError.statusCode === STATUS_CODE.GROUP_NOT_FOUND) {
        dispatch(
          openToast({
            message: t('tag:tag_group_error_not_found'),
            status: 'error',
          })
        )

        return
      }

      if (typedError.statusCode === STATUS_CODE.EXCEEDED_MAXIMUM_LIMIT) {
        if (tagSourceType === 'behavior') {
          dispatch(
            openToast({
              message: t('tag:behavior_tag_exceed_maximum_limit', {
                num: MAXIMUM_BEHAVIOR_TAG_COUNT,
              }),
              status: 'error',
            })
          )
          return
        }

        if (tagSourceType === 'grading') {
          dispatch(
            openToast({
              message: t('tag:grading_tag_exceed_maximum_limit', {
                num: MAXIMUM_TAG_COUNT,
              }),
              status: 'error',
            })
          )
          return
        }
      }

      dispatch(
        openToast({
          message: t('tag:tag_name_error_occurred'),
          status: 'error',
        })
      )
    },
    [dispatch, t, tagSourceType]
  )

  return [handleTagApiError]
}

export default useHandleError
