import { useTranslation } from 'react-i18next'

import {
  SEGMENT_STYLE_CONFIG,
  SegmentStyleConfig,
} from '@entities/rfm/model/constants'
import type { Rfm } from '@shared/api/rtkQuery'
import DonutChart, { DonutData } from '@shared/ui/charts/DonutChart'

type Props = Pick<Rfm, 'rfmGroups'>

const RfmDonutChart = ({ rfmGroups }: Props) => {
  const { t } = useTranslation(['rfm'])

  const segmentCards = rfmGroups.map(group => {
    const { name, userCount } = group
    const config = SEGMENT_STYLE_CONFIG.find(
      config => config.segmentType === name
    )

    return {
      ...config,
      userCount,
    } as SegmentStyleConfig & { userCount: number }
  })

  const themeColors = segmentCards.map(({ themeColor }) => themeColor)

  const chartData = segmentCards.reduce(
    (acc, { userCount, displayNameI18n }) => {
      acc.series.push(userCount)
      acc.labels.push(t(`rfm:${displayNameI18n}`) as string)

      return acc
    },
    { series: [], labels: [] } as DonutData
  )

  return (
    <DonutChart
      colors={themeColors}
      donutData={{ ...chartData, totalLabel: t('rfm:total_members') }}
    />
  )
}

export default RfmDonutChart
