import styled from '@emotion/styled'
import MuiCircularProgress from '@mui/material/CircularProgress'

import BaseCard from '@shared/ui/Card'

export const Card = styled(BaseCard)`
  padding: 16px 32px;
  height: 100%;
  min-height: 400px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.div`
  overflow: hidden;
  max-width: calc(100% - 70px);

  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 16px;
  font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
`

export const DurationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  height: 20px;
  color: ${({ theme }) => theme.colors.textSecondBlue};
  font-size: 13px;
`

export const DurationItem = styled.div<{ isHideBorder?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 13px;
  border-right: ${({ theme, isHideBorder }) =>
    isHideBorder ? 'none' : `1px solid ${theme.colors.textSecondBlue}`};
  font-weight: 500;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    border-right: none;
  }
`

export const CircularProgress = styled(MuiCircularProgress)`
  && {
    color: ${({ theme }) => theme.colors.brightBlue};
  }
`

export const SimpleText = styled.span`
  color: ${({ theme }) => theme.colors.brightBlue};
  font-weight: 500;
`

export const ChartWrapper = styled.div`
  margin-bottom: 24px;
  width: calc((100% - 24px) / 2);
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;

  min-width: 1028px;

  ${ChartWrapper}:nth-of-type(even) {
    margin-left: 24px;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 340px;
`

export const ErrorWrapper = styled(LoadingWrapper)`
  margin-top: 24px;
  margin-bottom: 16px;
  height: 309px;

  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 15px;
`

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  margin-bottom: 18vh;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
`

export const EmptyTextWrapper = styled.div`
  margin-bottom: 21px;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.165px;
  line-height: 34px;
`
