import { AudienceRuleUserExport, POLLING_INTERVAL } from '@shared/api/rtkQuery'

import { checkIsNeedPollingExportInfo } from '../checkIsNeedPollingExportInfo'

export const getExportPollingInterval = (
  status?: AudienceRuleUserExport['status']
) => {
  if (checkIsNeedPollingExportInfo(status)) {
    return POLLING_INTERVAL
  }

  return undefined
}
