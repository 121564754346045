import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { PointerEventsInitial } from './styles'

type Props = {
  isShowError?: boolean
  nodeIconUrl?: string
  tooltip?: React.ReactNode
}

const ErrorIcon = () => (
  <Icon icon={ICON.exclamationTriangle} color={theme.colors.chartRed} />
)

const NodeHeaderIcon = ({ nodeIconUrl, isShowError, tooltip }: Props) => {
  if (isShowError) {
    if (tooltip) {
      return (
        <Tooltip title={tooltip}>
          <PointerEventsInitial>
            <ErrorIcon />
          </PointerEventsInitial>
        </Tooltip>
      )
    }
    return <ErrorIcon />
  }

  return (
    <Box
      component="img"
      src={nodeIconUrl}
      alt="icon"
      sx={{ width: 24, height: 24 }}
    />
  )
}

export default NodeHeaderIcon
