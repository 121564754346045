import { EventChartIndicator } from '@shared/api/rtkQuery'
import theme from '@theme'

import { DashboardIndicator } from './IndicatorList'
import { ChartData } from './types'
import {
  getDashboardIndicatorAverage,
  getDashboardIndicatorLatest,
  getDashboardIndicatorSum,
} from './utils'

export const COLORS = [
  theme.colors.chartBlue,
  theme.colors.chartAqua,
  theme.colors.chartPeriwinkle,
  theme.colors.chartMaize,
  theme.colors.chartRed,
  theme.colors.darkPastelGreen,
  theme.colors.sky,
  theme.colors.bilobaFlower,
  theme.colors.texasRose,
  theme.colors.salmonTwo,

  theme.colors.blueTwo,
  theme.colors.irisBlue,
  theme.colors.blueVioletTwo,
  theme.colors.deepSkyBlueThree,
  theme.colors.goldenYellow,
  theme.colors.pumpkin,
  theme.colors.lightSeaGreen,
  theme.colors.fuchsiaTwo,
  theme.colors.internationalOrange,
  theme.colors.alizarin,

  theme.colors.cobalt,
  theme.colors.bondiBlue,
  theme.colors.perrywinkle,
  theme.colors.cloud,
  theme.colors.nepal,
  theme.colors.tuscany,
  theme.colors.darkPeach,
  theme.colors.tequila,
  theme.colors.wewak,
  theme.colors.onahau,

  theme.colors.bakersChocolate,
  theme.colors.deepPink,
  theme.colors.freeSpeechRed,
  theme.colors.bilbao,
  theme.colors.mangoTango,
  theme.colors.radicalRedTwo,
  theme.colors.wattle,
  theme.colors.mediumBlue,
  theme.colors.textPrimaryBlue,
  theme.colors.blackPearlTwo,

  theme.colors.tealBlue,
  theme.colors.blueMarguerite,
  theme.colors.kabul,
  theme.colors.purple,
  theme.colors.purpleHeart,
  theme.colors.hotMagenta,
  theme.colors.torchRed,
  theme.colors.lawnGreen,
  theme.colors.heliotrope,
  theme.colors.neonPink,
]

export const METRIC_LIST: EventChartIndicator[] = ['sum', 'latest', 'avg']

export const INDICATOR_MAP: Map<
  EventChartIndicator,
  (chartData: ChartData[]) => DashboardIndicator[]
> = new Map([
  ['sum', getDashboardIndicatorSum],
  ['latest', getDashboardIndicatorLatest],
  ['avg', getDashboardIndicatorAverage],
])

export const INDICATOR_HEIGHT = 72
