import { useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'
import Box from '@mui/material/Box'

import DotLoader from '@shared/ui/loaders/DotLoader'

import {
  Origin,
  Percentage,
  PercentageIcon,
  PercentageWording,
  PercentageWrapper,
  Wrapper,
} from '../../styles'
import { heightOfDonut, radialBarChartConfig, widthOfDonut } from './constant'

type Props = {
  title: string
  isLoading: boolean
  displayValue: number
  symbol?: string
  isFullRadius?: boolean
}

const DonutChart = ({
  title,
  displayValue,
  isLoading,
  symbol,
  isFullRadius = false,
}: Props) => {
  const series = useMemo(() => {
    if (isLoading) {
      return [0]
    }

    if (isFullRadius && displayValue !== 0) {
      return [100]
    }

    return [displayValue]
  }, [displayValue, isFullRadius, isLoading])

  return (
    <Wrapper>
      <ReactApexChart
        options={radialBarChartConfig}
        series={series}
        type="radialBar"
        width={widthOfDonut}
        height={heightOfDonut}
      />

      <Origin>
        {isLoading ? (
          <Box ml={1}>
            <DotLoader />
          </Box>
        ) : (
          <>
            <PercentageWrapper>
              <Percentage>{displayValue}</Percentage>
              {symbol && <PercentageIcon>{symbol}</PercentageIcon>}
            </PercentageWrapper>

            <PercentageWording>{title}</PercentageWording>
          </>
        )}
      </Origin>
    </Wrapper>
  )
}

export default DonutChart
