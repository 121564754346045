import type { EventRule } from '@shared/api/rtkQuery'

import { INDICATOR_MAP, METRIC_LIST } from '../constants'
import { DashboardIndicator } from '../IndicatorList'
import type { ChartData } from '../types'

export const getDashboardIndicatorList = (
  chartDataList: ChartData[],
  metric: EventRule['dashboardConfig']['displayVisibility']
): DashboardIndicator[] => {
  if (chartDataList.length === 0) {
    return []
  }

  const dashboardIndicatorList: DashboardIndicator[] = []

  METRIC_LIST.filter(key => metric[key]).forEach(key => {
    const getIndicatorFunc = INDICATOR_MAP.get(key)

    if (getIndicatorFunc) {
      dashboardIndicatorList.push(...getIndicatorFunc(chartDataList))
    }
  })

  return dashboardIndicatorList
}
