import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ROWS_PER_PAGE_OPTIONS } from '@shared/model/constants/table'

export interface ApplicationData {
  appId: number
  ikaId: string
  clientId: string
  clientSecret: string
  name: string
  type: 'server'
  createdTime: string
  creator: string
}

interface State {
  isFetching: boolean
  isCreateFetching: boolean
  isDetailFetching: boolean
  totalCount: number
  currPage: number
  rowsPerPage: number
  dataCollectionByAppId: {
    [key: string]: ApplicationData
  }
  appIdByEachPage: {
    [key: string]: string[]
  }
  currApplication: ApplicationData
  lastOneHundredApplications: ApplicationData[]
}

const initialState: State = {
  isFetching: false,
  isCreateFetching: false,
  isDetailFetching: false,
  totalCount: 0,
  currPage: 0,
  rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
  dataCollectionByAppId: {},
  appIdByEachPage: {},
  currApplication: {
    appId: 0,
    ikaId: '',
    clientId: '',
    clientSecret: '',
    name: '',
    type: 'server',
    createdTime: '',
    creator: '',
  },
  lastOneHundredApplications: [],
}

const settings = createSlice({
  name: 'application',
  initialState,
  reducers: {
    resetTableState: state => {
      state.totalCount = 0
      state.currPage = 0
      state.rowsPerPage = ROWS_PER_PAGE_OPTIONS[0]
      state.dataCollectionByAppId = {}
      state.appIdByEachPage = {}
    },
    changeCurrPage: (state, action: PayloadAction<number>) => {
      state.currPage = action.payload
    },
    changeRowsPerPage: (state, action: PayloadAction<number>) => {
      state.rowsPerPage = action.payload
      state.currPage = 0

      state.appIdByEachPage = {}
    },
    fetchApplicationList: (
      state,
      action: PayloadAction<{ page: number }>
    ) => {},
    fetchApplicationListSuccess: (
      state,
      action: PayloadAction<{
        data: ApplicationData[]
        totalCount: number
        page: number
      }>
    ) => {
      state.isFetching = false

      const { data, totalCount, page } = action.payload
      const dataCollectionByAppId = data.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.appId]: curr,
        }),
        state.dataCollectionByAppId
      )

      state.dataCollectionByAppId = dataCollectionByAppId
      state.totalCount = totalCount
      state.appIdByEachPage[page] = data.map(({ appId }) => `${appId}`)
      state.currPage = page
    },
    fetchApplicationListFailure: state => {
      state.isFetching = false
      state.appIdByEachPage = {}
    },
    setIsApplicationFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload
    },
    createApplication: (state, action: PayloadAction<{ name: string }>) => {
      state.isCreateFetching = true
    },
    createApplicationSuccess: state => {
      state.isCreateFetching = false
    },
    createApplicationFailure: state => {
      state.isCreateFetching = false
    },
    deleteApplication: (state, action: PayloadAction<{ appId: number }>) => {
      state.isCreateFetching = true
    },
    deleteApplicationSuccess: state => {
      state.isCreateFetching = false
      state.currApplication = initialState.currApplication
    },
    deleteApplicationFailure: state => {
      state.isCreateFetching = false
      state.currApplication = initialState.currApplication
    },
    fetchApplicationDetail: (
      state,
      action: PayloadAction<{ appId: number }>
    ) => {},
    fetchApplicationDetailSuccess: (
      state,
      action: PayloadAction<ApplicationData>
    ) => {
      state.isDetailFetching = false
      state.currApplication = action.payload
    },
    fetchApplicationDetailFailure: state => {
      state.isDetailFetching = false
    },
    setIsApplicationDetailFetching: (state, action: PayloadAction<boolean>) => {
      state.isDetailFetching = action.payload
    },
    resetApplicationDetail: state => {
      state.currApplication = initialState.currApplication
    },

    fetchLastOneHundredApplicationList: () => {},
    fetchLastOneHundredApplicationListSuccess: (
      state,
      action: PayloadAction<{ data: ApplicationData[]; totalCount: number }>
    ) => {
      state.isFetching = false

      state.lastOneHundredApplications = action.payload.data
    },
    fetchLastOneHundredApplicationListFailure: state => {
      state.isFetching = false
      state.lastOneHundredApplications = []
    },
  },
})

const { actions, reducer } = settings

export const {
  resetTableState,
  changeCurrPage,
  changeRowsPerPage,
  fetchApplicationList,
  fetchApplicationListSuccess,
  fetchApplicationListFailure,
  setIsApplicationFetching,
  createApplication,
  createApplicationSuccess,
  createApplicationFailure,
  deleteApplication,
  deleteApplicationSuccess,
  deleteApplicationFailure,
  fetchApplicationDetail,
  fetchApplicationDetailSuccess,
  fetchApplicationDetailFailure,
  setIsApplicationDetailFetching,
  resetApplicationDetail,
  fetchLastOneHundredApplicationList,
  fetchLastOneHundredApplicationListSuccess,
  fetchLastOneHundredApplicationListFailure,
} = actions

export type ApplicationAction =
  | ReturnType<typeof resetTableState>
  | ReturnType<typeof changeCurrPage>
  | ReturnType<typeof changeRowsPerPage>
  | ReturnType<typeof fetchApplicationList>
  | ReturnType<typeof fetchApplicationListSuccess>
  | ReturnType<typeof fetchApplicationListFailure>
  | ReturnType<typeof setIsApplicationFetching>
  | ReturnType<typeof createApplication>
  | ReturnType<typeof createApplicationSuccess>
  | ReturnType<typeof createApplicationFailure>
  | ReturnType<typeof deleteApplication>
  | ReturnType<typeof deleteApplicationSuccess>
  | ReturnType<typeof deleteApplicationFailure>
  | ReturnType<typeof fetchApplicationDetail>
  | ReturnType<typeof fetchApplicationDetailSuccess>
  | ReturnType<typeof fetchApplicationDetailFailure>
  | ReturnType<typeof setIsApplicationDetailFetching>
  | ReturnType<typeof resetApplicationDetail>
  | ReturnType<typeof fetchLastOneHundredApplicationList>
  | ReturnType<typeof fetchLastOneHundredApplicationListSuccess>
  | ReturnType<typeof fetchLastOneHundredApplicationListFailure>

export default reducer

const getAppIdsPerPage = (state: RootState) => {
  const { currPage, appIdByEachPage } = state.v1.settings.application
  return appIdByEachPage[currPage] || []
}

export const itemsPerPageSelector = createSelector(
  [
    state => state.v1.settings.application.dataCollectionByAppId,
    getAppIdsPerPage,
  ],
  (dataCollectionByAppId, appIdsPerPage) =>
    appIdsPerPage.map(appId => dataCollectionByAppId[appId]).filter(Boolean)
)
