import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Aggregator, StatisticMeasure } from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { checkIsPredefinedField } from '@widgets/analytics/_shared/utils'

import type { EventChartTitle } from '../types'

export const useChartTitleList = (
  statisticMeasureList: StatisticMeasure[]
): EventChartTitle[] => {
  const { eventEntity } = useMetadataQuery()

  const { t } = useTranslation(['analytics'])
  const aggregatorParentEntity = useMemo(
    () =>
      new Map<Aggregator, string>([
        ['count', t('analytics:event.aggregators.count')],
        ['count_distinct', t('analytics:event.aggregators.user_count')],
        ['count_per_user', t('analytics:event.aggregators.avg_user_count')],
      ]),
    [t]
  )
  const aggregatorChildEntity = useMemo(
    () =>
      new Map<Aggregator, string>([
        ['avg', t('analytics:event.aggregators.avg')],
        ['count_distinct', t('analytics:event.aggregators.distinct_count')],
        ['max', t('analytics:event.aggregators.max')],
        ['min', t('analytics:event.aggregators.min')],
        ['sum', t('analytics:event.aggregators.sum')],
        ['sum_per_user', t('analytics:event.aggregators.avg_user_sum')],
        ['count_id', t('analytics:event.aggregators.count_id')],
        [
          'count_distinct_id',
          t('analytics:event.aggregators.count_distinct_id'),
        ],
        [
          'count_id_per_user',
          t('analytics:event.aggregators.count_id_per_user'),
        ],
        [
          'count_distinct_id_per_user',
          t('analytics:event.aggregators.count_distinct_id_per_user'),
        ],
      ]),
    [t]
  )

  const chartTitleList = useMemo(
    () =>
      statisticMeasureList.map(({ eventName, source, field, aggregator }) => {
        if (eventName === '') {
          return { displayName: '', label: '' }
        }

        const currentEvent = eventEntity.events.entities[eventName]
        const displayName = currentEvent?.displayName

        const aggregatorDisplayName = checkIsPredefinedField(field)
          ? aggregatorParentEntity.get(aggregator)
          : aggregatorChildEntity.get(aggregator)

        const label = checkIsPredefinedField(field)
          ? aggregatorDisplayName
          : `${
              currentEvent?.propertiesEntity[
                createMetadataPropertyId(source, field)
              ]?.displayName
            } ｜ ${aggregatorDisplayName}`

        return {
          displayName,
          label: label || '',
        }
      }),
    [
      aggregatorChildEntity,
      aggregatorParentEntity,
      eventEntity.events,
      statisticMeasureList,
    ]
  )

  return chartTitleList
}
