import i18n from '@i18n'

import audienceListNameSchema from '../model/audienceListNameSchema'

const validateAudienceListName = async (value: string) => {
  try {
    // yup 的行爲是，invalid 會直接 throw error
    await audienceListNameSchema.validate(value)
    return {
      valid: true,
      message: '',
    }
  } catch (error) {
    const message =
      (error as { errors: string[] })?.errors?.[0] ??
      i18n.t('common:errors.invalid')

    return {
      valid: false,
      message,
    }
  }
}

export default validateAudienceListName
