import { useGetWorkflowListQuery } from '@shared/api/rtkQuery'

export const WORK_FLOW_LIST_QUERY_PARAMS: Parameters<
  typeof useGetWorkflowListQuery
>[0] = {
  page: 1,
  perPage: 300,
  name: '',
  statusTypes: ['disabled', 'enabled', 'end'],
}
