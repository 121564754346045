import type {
  CreateDimensionIngestionParams,
  DimensionIngestion,
} from '@shared/model/types/ingestion'

import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'

const dimension = api.injectEndpoints({
  endpoints: builder => ({
    getDimensionIngestionList: builder.query<
      PaginationResponse<DimensionIngestion>,
      PaginationParams
    >({
      query: params => ({
        method: 'GET',
        url: '/dimension/ingestions',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (data: PaginationResponse<DimensionIngestion>) =>
        transformResponseCamelCaseKeys(data),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'DimensionIngestion' as const,
                id,
              })),
              { type: 'DimensionIngestion', id: 'LIST' },
            ]
          : [{ type: 'DimensionIngestion', id: 'LIST' }],
    }),
    createDimensionIngestion: builder.mutation<
      void,
      CreateDimensionIngestionParams
    >({
      query: params => ({
        method: 'POST',
        url: `/dimension/ingestions`,
        body: params,
      }),
      invalidatesTags: ['DimensionIngestion'],
    }),
    deleteDimensionIngestion: builder.mutation<void, number>({
      query: id => ({
        method: 'DELETE',
        url: `/dimension/ingestions/${id}`,
      }),
    }),
    getDimensionIngestion: builder.query<DimensionIngestion, number>({
      query: id => ({
        method: 'GET',
        url: `/dimension/ingestions/${id}`,
      }),
      transformResponse: (data: DimensionIngestion) =>
        transformResponseCamelCaseKeys(data),
      providesTags: ['DimensionIngestion'],
    }),
    triggerDimensionIngestion: builder.mutation<void, number>({
      query: id => ({
        method: 'POST',
        url: `/dimension/ingestions/${id}/trigger`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateDimensionIngestionMutation,
  useTriggerDimensionIngestionMutation,
  useGetDimensionIngestionListQuery,
  useGetDimensionIngestionQuery,
  useDeleteDimensionIngestionMutation,
} = dimension
