import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useCreatePolicyMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, SimpleButton } from '@shared/ui/buttons'

import PolicyForm, {
  BUTTON_WIDTH,
  createActionsFromServiceEntity,
  FormValues,
  STATUS_CODE,
} from './PolicyForm'

const Create = () => {
  const [createPolicy, { isLoading: isCreatingPolicy }] =
    useCreatePolicyMutation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const userPolicyOverviewLink = `/${CATEGORY.userCenter}/${PAGE_ROOT.policies}`

  const handleSubmit = async (values: FormValues) => {
    const actions = createActionsFromServiceEntity(values.serviceEntity)

    try {
      await createPolicy({ ...values, actions }).unwrap()

      history.push(userPolicyOverviewLink)
    } catch (error) {
      const typedError = formatRTKQueryError(error)

      if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
        dispatch(
          openToast({
            message: t('common:this_function_is_not_allowed'),
            status: 'error',
          })
        )
        return
      }

      dispatch(handleApiError(typedError))
    }
  }

  const { t } = useTranslation(['common'])

  return (
    <>
      <Breadcrumbs>
        <Link to={userPolicyOverviewLink}>{t('common:route.iam_policy')}</Link>
        <Box>{t('common:create')}</Box>
      </Breadcrumbs>

      <PolicyForm onSubmit={handleSubmit} isSubmitting={isCreatingPolicy}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box mr={4}>
            <SimpleButton
              onClick={() => {
                history.push(userPolicyOverviewLink)
              }}
            >
              {t('common:cancel')}
            </SimpleButton>
          </Box>

          <DeepBlueGradientButton width={BUTTON_WIDTH} type="submit">
            {t('common:create')}
          </DeepBlueGradientButton>
        </Box>
      </PolicyForm>
    </>
  )
}

export default Create
