import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import EventRuleCreate from './EventRuleCreate'
import EventRuleEdit from './EventRuleEdit'

export const EventRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <EventRuleCreate />
      </Route>

      <Route exact path={`${path}/:id/edit`}>
        <EventRuleEdit />
      </Route>

      <Redirect to={`${path}/new`} />
    </Switch>
  )
}

export default EventRoutes
