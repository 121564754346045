import { useTranslation } from 'react-i18next'

import type {
  AudienceRuleUserExportIterable,
  AudienceRuleUserExportMAAC,
  AudienceRuleUserExportSms,
  AudienceRuleUserExportSuper8,
  SchedulingType,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'

import { isAudienceRuleUserExportSms } from './utils'

export const useAudienceRuleUserExportDetails = (
  audienceRuleId: number,
  schedulingType: SchedulingType,
  provider: ThirdPartyAppProvider,
  latestExport?:
    | AudienceRuleUserExportIterable
    | AudienceRuleUserExportMAAC
    | AudienceRuleUserExportSuper8
    | AudienceRuleUserExportSms
    | null
) => {
  const { t } = useTranslation(['audience', 'common'])

  if (!latestExport || latestExport.status !== 'completed') {
    return []
  }

  if (isAudienceRuleUserExportSms(latestExport)) {
    return [
      {
        label: latestExport.isBooking
          ? t('audience:export_sms.detail.reserve_time')
          : t('audience:export_sms.detail.create_at'),
        text: latestExport.isBooking
          ? formatDateTimeDisplay(latestExport.sendTime)
          : formatDateTimeDisplay(latestExport.createdAt),
      },
      {
        label: t('audience:export_sms.detail.user_count'),
        text: latestExport.totalCount,
      },
      {
        label: t('audience:export_sms.detail.phone_count'),
        text: latestExport.validCount,
      },
      {
        label: t('audience:export_sms.detail.sync_to_provider_count', {
          sms_provider: provider.toUpperCase(),
        }),
        text: latestExport.successCount,
      },
      {
        label: t('audience:export_sms.detail.to_telecom_count', {
          sms_provider: provider.toUpperCase(),
        }),
        text: latestExport.toTelecom ?? 0,
      },
      ...(latestExport.sendType === 'sms'
        ? [
            {
              label: t('audience:export_sms.detail.to_phone_count'),
              text: latestExport.toPhone ?? 0,
            },
          ]
        : []),
      ...(latestExport.batchIds
        ? [
            {
              label: t('audience:export_sms.detail.batch_ids', {
                sms_provider: provider.toUpperCase(),
              }),
              text: latestExport.batchIds,
            },
          ]
        : []),
    ]
  }

  return [
    ...(provider === 'iterable'
      ? [
          {
            label: 'Iterable List ID',
            text: audienceRuleId,
          },
        ]
      : []),
    { label: t('common:name'), text: latestExport.name },
    {
      label: t('common:type'),
      text:
        schedulingType === 'default'
          ? t('audience:type_dynamic')
          : t('audience:type_static'),
    },
    {
      label: t('audience:export_counts'),
      text: latestExport.validCount,
    },
    {
      label: t('audience:export_counts_succeed'),
      text: latestExport.successCount,
    },
  ]
}

export default useAudienceRuleUserExportDetails
