import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import FunnelCreate from './FunnelCreate'
import FunnelEdit from './FunnelEdit'

export const FunnelRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <FunnelCreate />
      </Route>

      <Route exact path={`${path}/:id/edit`}>
        <FunnelEdit />
      </Route>

      <Redirect to={`${path}/new`} />
    </Switch>
  )
}

export default FunnelRoutes
