import styled from '@emotion/styled'
import { spacing, SpacingProps } from '@mui/system'

export const FieldGrid = styled.div<{ isHideBorder?: boolean } & SpacingProps>`
  display: grid;

  grid-template-columns: 140px 1fr;
  align-items: center;
  gap: 16px;

  border-bottom: ${({ theme, isHideBorder }) =>
    isHideBorder ? '' : `1px solid ${theme.colors.black6}`};

  ${spacing}
`

export const Label = styled.label`
  flex-shrink: 0;
  flex-basis: 140px;
  margin-right: 16px;

  font-weight: 500;
`

export const HandleSourceText = styled.div<{ left?: number }>`
  position: absolute;
  bottom: 12px;
  left: ${({ left = -1 }) => left * 8}px;
  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
`
