export const SCROLL_DEBOUNCE_TIME = 2000
export const LIST_PER_PAGE = 10

// ref: https://gitlab.corp.ikala.tv/dmp/lego/-/merge_requests/1131/diffs?commit_id=402513afbf7d8ffe258d41b30580bd0c988ee36b
export const STATUS_CODE = {
  CREATE_MAX_COUNT: 400,
}

export const GROUP_UPPER_BOUNDARY = 100

export const GROUP_NAME_ACTION_BUTTON_ID = 'group-name-action-button'
