import pipe from 'lodash/fp/pipe'

import { MAX_STEPS } from './constants'

/**
 * @param input number
 * @returns number
 * @example
 * // 900 -> 10
 * // 1 -> 0.1
 * // 0 -> 0
 */
const calculateNumberInputStep = (input: number | undefined) => {
  if (!input) {
    //  return 0 表示會走 HTML default 行為
    return 0
  }

  return pipe(
    Math.abs,
    Math.log10,
    value => Math.floor(value) - 1,
    value => Math.pow(10, value),
    value => Math.min(value, MAX_STEPS)
  )(input)
}

export default calculateNumberInputStep
