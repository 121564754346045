import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import {
  DEFAULT_LABEL_WIDTH,
  FILE_UPDATE_NAME_MAX_WIDTH,
  FILE_UPLOAD_BUTTON_WIDTH,
} from './models/constants'
import type { FieldProps } from './models/types'
import * as Field from './styles'

export interface FileUploadFieldProps extends FieldProps {}

export const FileUploadField = ({
  description,
  error,
  isDisabled,
  label,
  labelWidth = DEFAULT_LABEL_WIDTH,
  name,
  onChange,
}: FileUploadFieldProps) => {
  const fileInputEl = useRef<HTMLInputElement>(null)

  const [uploadImage, setUploadImage] = useState<File>()

  const handleUploadImage = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const firstFile = files?.[0]

    if (!firstFile) {
      return
    }

    setUploadImage(firstFile)

    const reader = new FileReader()
    reader.onload = ({ target }) => {
      if (target) {
        onChange?.(target.result as string)
      }
    }
    reader.readAsDataURL(firstFile)
  }

  const { t } = useTranslation(['audience'])

  return (
    <Field.Container>
      <Field.Label htmlFor={name} labelWidth={labelWidth}>
        {label}
      </Field.Label>

      <Field.Input>
        <Box sx={{ display: 'flex' }}>
          <input
            hidden
            id={name}
            onChange={handleUploadImage}
            ref={fileInputEl}
            type="file"
          />

          <DeepBlueGradientButton
            disabled={isDisabled}
            onClick={() => fileInputEl.current?.click()}
            sx={{ flexShrink: 0 }}
            width={FILE_UPLOAD_BUTTON_WIDTH}
          >
            {t('audience:export_sms.upload')}
          </DeepBlueGradientButton>

          {uploadImage && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, ml: 2 }}>
              <Icon icon={ICON.paperclip} />

              <Box
                component="span"
                sx={{
                  color: theme => theme.colors.textPrimaryBlue,
                  minWidth: FILE_UPDATE_NAME_MAX_WIDTH,
                  textDecoration: 'underline',
                  wordBreak: 'break-word',
                }}
              >
                {uploadImage.name}
              </Box>

              <Box
                component="span"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setUploadImage(undefined)
                  onChange?.('')

                  if (fileInputEl.current) {
                    fileInputEl.current.value = ''
                  }
                }}
              >
                <Icon color={theme.colors.black} icon={ICON.times} />
              </Box>
            </Box>
          )}
        </Box>

        {description && <Field.Description>{description}</Field.Description>}

        {error && <Field.Error>{error}</Field.Error>}
      </Field.Input>
    </Field.Container>
  )
}

export default FileUploadField
