/**
 * 全站標題的最小長度
 */
export const MIN_LIST_NAME_LENGTH = 1

/**
 * 全站標題的最大長度
 */
export const MAX_LIST_NAME_LENGTH = 100

/**
 * 動態受眾名單的數量上限
 */
export const AUDIENCE_RULE_DYNAMIC_LIST_MAX_COUNT = 300

/**
 * 標籤分類的數量上限
 */
export const TAG_GROUP_MAX_COUNT = 300

/**
 * 級別標籤 + 智慧標籤的數量上限
 * */

export const MAXIMUM_TAG_COUNT = 300

/**
 * 行為標籤的數量上限
 */

export const MAXIMUM_BEHAVIOR_TAG_COUNT = 300

/**
 * 1. 中日韓的漢字
 * 2. 英數字
 * 3. 長度為 1 ~ 100 字
 * https://www.cns11643.gov.tw/pageView.jsp?ID=9&SN=&lang=tw#encode7
 */
export const regexTitle = /[\u4E00-\u9FFFa-zA-Z0-9 \-_]{1,100}/

/**
 * 字串前後有空白字元
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/Trim
 */
export const regexSpaceAround = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/

/**
 * 判斷是否為 Email 格式
 * http://emailregex.com/
 */
export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * 台灣電話號碼，目前只檢查是否為 10 碼數字
 */
export const regexTaiwanPhone = /^\d{10}$/

/**
 * 純英數字
 */
export const regexAlphabetOrNumbers = /^[a-zA-Z0-9]*$/

/**
 * 中英數字, 包含空白
 */

export const regexChineseOrAlphabetOrNumbers = /^[\u4E00-\u9FFFa-zA-Z0-9 ]*$/

/**
 * 找出文字包含的 replacementData ex. {{gender}} {{ name }}
 */
export const regexReplacementDataMergeTag = /{{\s*([a-zA-Z0-9_]+)\s*}}/g

/**
 * 找出文字內包含的 actionURL ex. {{ action_url_1 }} {{ action_url_2 }}
 *
 * 此格式在進行 camelCase 與 snakeCase 轉換時會有問題，但由於要向後相容而維持現狀
 * ex. action_url_1 => actionUrl1 => action_url1
 */
export const regexReplacementDataActionURL = /{{\s*(action_url_[0-9]+)\s*}}/g

/**
 * 使用 EVERY8D 簡訊 replacementData 最大允許的數量
 */
export const MAX_REPLACEMENT_DATA_SMS_COUNT = 5

/**
 * 中文字
 */

export const regexChinese = new RegExp('^([\u4e00-\u9fa5]+)$')

/**
 * @name 受眾名單自訂名稱
 * @description
 * 1. 中文
 * 2. 英文
 * 3. 數字
 * 4. _
 * 5. .
 * 6. \-
 * 7. 全形字
 */
export const regexAudienceListName = /^[\p{L}\p{N}_.\-\u4E00-\u9FFF]+$/u

export const MAX_TAG_ANALYSIS_COUNT = 300
