import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'
import type { SpacingProps } from '@mui/system'

type DeepBlueGradientButtonProps = {
  disabled?: boolean
  isFullWidth?: boolean
  minWidth?: number
  paddingLeft?: number
  paddingRight?: number
  width?: number
}

// 範例： AudienceBuilder 的 Create 按鈕
export const DeepBlueGradientButton = styled(
  ({
    disabled,
    isFullWidth,
    minWidth,
    paddingLeft,
    paddingRight,
    width,
    ...otherProps
  }: DeepBlueGradientButtonProps & ButtonProps & SpacingProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} />
  )
)`
  && {
    padding: 4px 20px;
    padding-right: ${({ paddingRight }) => paddingRight || 20}px;
    padding-left: ${({ paddingLeft }) => paddingLeft || 20}px;
    width: ${({ width, isFullWidth }) =>
      // 1 如果指定 isFullWidth=true 則優先設定寬度為 100%
      // 2 否則依據是否有設定 width 屬性
      // 3 寬度為 auto
      isFullWidth ? '100%' : width ? `${width}px` : 'auto'};
    min-width: ${({ minWidth }) => (minWidth ? `${minWidth}px` : 'auto')};
    height: 42px;
    border-radius: 3px;

    ${({ disabled, theme }) =>
      disabled
        ? `
          box-shadow: 0 5px 19px 0 ${theme.colors.brownishGrey65};
          background-color: ${theme.colors.lightGreyBlue};
      `
        : `
          box-shadow: 0 5px 19px 0 ${theme.colors.clearBlue65};
          background-image: linear-gradient(
            to left,
            ${theme.colors.deepSkyBlue},
            ${theme.colors.electricBlueTwo}
          );
      `}

    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    font-family: inherit;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0.5px;
    line-height: 1.71;

    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    :hover {
      /* transition 目前不支援 background-image */
      background-image: linear-gradient(
        ${({ theme }) => theme.colors.brightBlue},
        ${({ theme }) => theme.colors.brightBlue}
      );
    }
  }
`

export default DeepBlueGradientButton
