import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@emotion/react'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Provider as LoggerProvider } from '@rollbar/react'

import App, { store } from '@app'
import { rollbarConfig } from '@shared/lib/rollbar'
import theme, { muiTheme } from '@theme'

import '@shared/lib/firebase'
import '@i18n'

import '@iconscout/unicons/css/line.css'
import './index.css'

const domNode = document.getElementById('root')
const root = createRoot(domNode!)

root.render(
  <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_APP_ID!}>
    <MuiThemeProvider theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <LoggerProvider config={rollbarConfig}>
              <App />
            </LoggerProvider>
          </DndProvider>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  </GoogleOAuthProvider>
)
