import { useTranslation } from 'react-i18next'
import type { Namespace, ParseKeys } from 'i18next'

import { Option } from '@shared/ui/Dropdown/DropdownList'

/*
  options = [
    { value: MEMBER_TYPE.all, label: TRANSLATION.audience.user_type_all },
    { value: MEMBER_TYPE.member, label: TRANSLATION.audience.user_type_member }
  ]
  
  result = [
    { value: MEMBER_TYPE.all, label: '全部' },
    { value: MEMBER_TYPE.member, label: '僅限登入會員' }
  ]
*/
export const useI18nOptions = (
  options: Option[],
  ns: Namespace = 'audience'
) => {
  const { t } = useTranslation([
    'audience',
    'rfm',
    'dataImport',
    'common',
    'dateAndChart',
  ])

  return options.map(({ label, value }) => ({
    value,
    label: (ns === 'rfm'
      ? t(`rfm:${label as ParseKeys<'rfm'>}`)
      : ns === 'dataImport'
      ? t(`dataImport:${label as ParseKeys<'dataImport'>}`)
      : ns === 'common'
      ? t(`common:${label as ParseKeys<'common'>}`)
      : ns === 'dateAndChart'
      ? t(`dateAndChart:${label as ParseKeys<'dateAndChart'>}`)
      : t(`audience:${label as ParseKeys<'audience'>}`)) as string,
  }))
}
