import Box from '@mui/material/Box'
import MuiBreadcrumbs, {
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@mui/material/Breadcrumbs'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type BreadcrumbsProps = MuiBreadcrumbsProps & {
  actionBar?: React.ReactNode
}

const Breadcrumbs = ({ actionBar, ...otherProps }: BreadcrumbsProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    }}
  >
    <MuiBreadcrumbs
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
      separator={
        <Icon icon={ICON.arrowRight} color={theme.colors.textPrimaryBlue} />
      }
    />

    {actionBar}
  </Box>
)

export default Breadcrumbs
