import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import { ReplacementDataActionURL } from '@shared/api/rtkQuery/contentTemplate'
import mapPreviewUrlWithUtm from '@shared/lib/utils/mapPreviewUrlWithUtm'
import { ICON } from '@shared/model/constants/styles'
import { Dialog, DialogContent } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'
import { PrimaryGreyInput as TextInput } from '@shared/ui/inputs'
import theme from '@theme'

import useInit from './hooks/useInit'
import { Required, Text, Title } from './styles'

type UtmLinkDialogProps = {
  onSaveLink: (utmData: ReplacementDataActionURL) => void
  onCancel: () => void
  data: ReplacementDataActionURL
  isOpen: boolean
}

/**
 * @deprecated
 * https://www.notion.so/ikala/2023H2_MA_-PRD_10-e93080e7911346e09116ec64f557adf1 上線後，就不會再用這個 component 新增 UTM Link
 * 但因為過去的資料需要 preview ，所以先保留起來
 */
const UtmLinkDialog = ({
  onSaveLink,
  onCancel,
  data,
  isOpen,
}: UtmLinkDialogProps) => {
  const { state, setState } = useInit({ data, isOpen })
  const previewUrl = mapPreviewUrlWithUtm(state.form)

  const { t } = useTranslation(['contentTemplate', 'common'])

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          color: theme.colors.textSecondBlue,
          ml: '8px',
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
          },
        }}
      >
        <Title>
          {t('contentTemplate:utm_link')}
          <IconButton onClick={onCancel}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Title>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>URL</Text>
          <div style={{ width: '100%' }}>
            <TextInput
              fullWidth
              placeholder={t('contentTemplate:utm_placeholder.url')}
              type="url"
              value={state.form.host}
              onChange={e => {
                setState({ type: 'SET_HOST', payload: e.target.value })
              }}
            />
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>utm campaign id</Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_campaign_id')}
            value={state.form.utmCampaignId}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_CAMPAIGN_ID', payload: e.target.value })
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>
            utm campaign source <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_source')}
            value={state.form.utmSource}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_SOURCE', payload: e.target.value })
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>
            utm campaign medium <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_medium')}
            value={state.form.utmMedium}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_MEDIUM', payload: e.target.value })
            }}
          />
        </Box>

        <Box display="flex" alignItems="center" mt={4}>
          <Text>
            utm campaign name <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_campaign')}
            value={state.form.utmCampaign}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_CAMPAIGN', payload: e.target.value })
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>utm campaign term</Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_term')}
            value={state.form.utmTerm}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_TERM', payload: e.target.value })
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" mt={4}>
          <Text>utm campaign content</Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_content')}
            value={state.form.utmContent}
            disabled={state.isUTMFieldsDisabled}
            onChange={e => {
              setState({ type: 'SET_UTM_CONTENT', payload: e.target.value })
            }}
          />
        </Box>

        <Box
          my={3.5}
          width="100%"
          height="1px"
          sx={{ backgroundColor: theme.colors.bgPaleGrey }}
        />
        <PrimaryGreyTextarea
          style={{ width: '100%' }}
          resize={'none'}
          disabled={true}
          value={previewUrl || '-'}
        />
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          pt={3.5}
          pb={5}
        >
          <Button color="inherit" onClick={onCancel}>
            {t('common:cancel')}
          </Button>
          <Button
            color="primary"
            disabled={!state.form.host}
            onClick={() => {
              onSaveLink({
                type: 'action_url',
                urlParams: state.form,
              })
            }}
          >
            {t('common:confirm')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default UtmLinkDialog
