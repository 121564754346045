import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import { Checkbox as BaseCheckbox } from '@shared/ui/CheckboxList'
import { TooltipWhenOverflowX } from '@shared/ui/tooltips'

import { COLORS, CONTROL_SECTION_WIDTH } from './constants'
import { LevelListElement } from './constants'

const Item = styled.div`
  display: flex;
  align-items: center;

  margin-right: 8px;
  margin-bottom: 4px;

  cursor: pointer;
`

const Checkbox = styled(BaseCheckbox)`
  &.MuiButtonBase-root {
    margin-left: -3px;
    padding-right: 3px;
    padding-left: 0px;
  }
`

const Label = styled.div<{ isVisible: boolean }>`
  overflow: hidden;

  max-width: ${CONTROL_SECTION_WIDTH}px;

  color: ${({ isVisible, theme }) =>
    isVisible ? theme.colors.textPrimaryBlue : theme.colors.cloudyBlue};
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Circle = styled.div<{ bgColor?: string; isVisible?: boolean }>`
  flex-shrink: 0;

  margin-right: 8px;
  width: 12px;
  height: 12px;

  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.white};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.4)};
`

const VisibilityList = ({
  levelList,
  setLevelList,
}: {
  levelList: LevelListElement[]
  setLevelList: Dispatch<SetStateAction<LevelListElement[]>>
}) => {
  const { t } = useTranslation('userProfile')

  return (
    <>
      <Item
        onClick={() => {
          setLevelList(prev =>
            prev.every(x => x.visible === true)
              ? prev.map(x => {
                  return {
                    ...x,
                    visible: false,
                  }
                })
              : prev.map(x => {
                  return {
                    ...x,
                    visible: true,
                  }
                })
          )
        }}
      >
        <Checkbox
          checked={levelList.every(x => x.visible === true)}
          color="primary"
          size="small"
        />
        <Label isVisible>{t('select_all')}</Label>
      </Item>

      {levelList.map(({ name, visible }, idx) => (
        <Item
          key={`${name}_${idx}`}
          onClick={() => {
            setLevelList(prev => {
              const newList = [...prev]
              newList[idx] = {
                ...newList[idx],
                visible: !newList[idx].visible,
              }
              return newList
            })
          }}
        >
          <Circle bgColor={COLORS[idx]} isVisible={visible} />
          <TooltipWhenOverflowX title={name}>
            <Label isVisible={visible}>{name}</Label>
          </TooltipWhenOverflowX>
        </Item>
      ))}
    </>
  )
}

export default VisibilityList
