import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import type { EventChartType } from '@shared/ui/charts'
import { UniIcon as Icon } from '@shared/ui/icons'
import { ConditionalTooltip } from '@shared/ui/tooltips'

import { RadioButton, RadioButtonWrapper } from './styles'

type EventChartTypeRadioButtonsProps = {
  chartType: EventChartType
  onChartTypeChanged: (chartType: EventChartType) => void
  hasTimeRangeParamsCompared: boolean
}

export const EventChartTypeRadioButtons = ({
  chartType,
  onChartTypeChanged,
  hasTimeRangeParamsCompared,
}: EventChartTypeRadioButtonsProps) => {
  const { t } = useTranslation(['dateAndChart'])

  return (
    <RadioButtonWrapper>
      <RadioButton
        isActive={chartType === 'line_chart'}
        onClick={() => {
          onChartTypeChanged('line_chart')
        }}
      >
        <Box mr={1}>
          <Icon icon={ICON.chartLine} color="inherit" fontSize="small" />
        </Box>
        <Box component="span" fontWeight="bold">
          {t('dateAndChart:charts.line')}
        </Box>
      </RadioButton>

      <ConditionalTooltip
        isShow={hasTimeRangeParamsCompared}
        title={t('dateAndChart:chart_not_support_compare_date', {
          chart: t('dateAndChart:charts.bar'),
        })}
      >
        <RadioButton
          isActive={chartType === 'bar_chart'}
          onClick={() => {
            onChartTypeChanged('bar_chart')
          }}
          isDisabled={hasTimeRangeParamsCompared}
        >
          <Box mr={1}>
            <Icon icon={ICON.chartBar} color="inherit" fontSize="small" />
          </Box>
          <Box component="span" fontWeight="bold">
            {t('dateAndChart:charts.bar')}
          </Box>
        </RadioButton>
      </ConditionalTooltip>

      <ConditionalTooltip
        isShow={hasTimeRangeParamsCompared}
        title={t('dateAndChart:chart_not_support_compare_date', {
          chart: t('dateAndChart:charts.pie'),
        })}
      >
        <RadioButton
          isActive={chartType === 'pie_chart'}
          onClick={() => {
            onChartTypeChanged('pie_chart')
          }}
          isDisabled={hasTimeRangeParamsCompared}
        >
          <Box mr={1}>
            <Icon icon={ICON.chartPie} color="inherit" fontSize="small" />
          </Box>
          <Box component="span" fontWeight="bold">
            {t('dateAndChart:charts.pie')}
          </Box>
        </RadioButton>
      </ConditionalTooltip>
    </RadioButtonWrapper>
  )
}

export default EventChartTypeRadioButtons
