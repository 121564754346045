import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'

import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

const RuleTagButton = styled(MuiButton)`
  && {
    position: relative;
    margin-right: 16px;
    padding: 0 12px;
    height: 34px;
    background-color: ${({ theme }) => theme.colors.borderColor};
  }
`

interface Props {
  isActive?: boolean
  name: string
  disabled?: boolean
  deleteDisabled: boolean
  onDelete: () => void
  onCopy: () => void
  onRename: () => void
  onClick: () => void
}

const TagDropdown = ({
  isActive = false,
  name,
  disabled = false,
  deleteDisabled = false,
  onDelete,
  onClick,
  onCopy,
  onRename,
}: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const { t } = useTranslation('common')

  const actionOption = useMemo(
    () =>
      [
        {
          label: t('delete'),
          value: 'delete',
          func: onDelete,
        },
        {
          label: t('copy'),
          value: 'duplicate',
          func: onCopy,
        },
        {
          label: t('rename'),
          value: 'rename',
          func: onRename,
        },
      ].filter(ele => ele.value !== 'delete' || !deleteDisabled),
    [deleteDisabled, onCopy, onDelete, onRename, t]
  )

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        setIsOpen={currIsOpen => {
          if (!currIsOpen) {
            // 代表 clickAway
            setDropdownOpen(false)
            return
          }
        }}
        popperWidth={140}
        anchorElem={
          <RuleTagButton
            color="primary"
            style={{
              backgroundColor: isActive
                ? theme.colors.brightBlue
                : theme.colors.textPrimaryBlue,
            }}
            variant="contained"
            size="small"
            startIcon={null}
            onClick={onClick}
            endIcon={
              disabled ? undefined : (
                <i
                  onClick={() => {
                    setDropdownOpen(!dropdownOpen)
                  }}
                  style={{
                    color: theme.colors.white,
                  }}
                  className={ICON.ellipsisV}
                />
              )
            }
          >
            <Box
              component="span"
              sx={{
                ml: disabled ? 0 : 0.5,
                textTransform: 'none',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Box>
          </RuleTagButton>
        }
      >
        <MenuList onMouseLeave={() => {}}>
          {actionOption.map((option, idx) => (
            <MenuItem
              key={`${option.value}_${idx}`}
              onClick={() => {
                option.func()
                setDropdownOpen(false)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    </>
  )
}

export default TagDropdown
