import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import type { Option } from '@shared/ui/Dropdown/DropdownList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import type { InsightFilterType } from '@widgets/insight/lib/SharedStateContext'

type Props = {
  onChange: (value: InsightFilterType) => void
}

const WorkflowSubscriptionFilter = ({ onChange }: Props) => {
  const { t } = useTranslation(['insight'])
  const [
    selectedWorkflowOnlyWithSubscriptionFilterOption,
    setSelectedWorkflowOnlyWithSubscriptionFilterOption,
  ] = useState<Option>()

  const workflowOnlyWithSubscriptionFilterOptions: Option[] = useMemo(
    () => [
      {
        label: t(
          'insight:workflow_summary.campaign_subscription_filter_option.with_campaign'
        ),
        value: 'campaign',
        tooltip: t(
          'insight:workflow_summary.campaign_subscription_filter_option.with_campaign_option_tooltip'
        ),
      },
      {
        label: t(
          'insight:workflow_summary.campaign_subscription_filter_option.with_subscription'
        ),
        value: 'subscription',
        tooltip: t(
          'insight:workflow_summary.campaign_subscription_filter_option.with_subscription_option_tooltip'
        ),
      },
    ],
    [t]
  )

  // 初始化需要等到 i18n 載入完成才能設定預設值
  useEffect(() => {
    setSelectedWorkflowOnlyWithSubscriptionFilterOption(
      workflowOnlyWithSubscriptionFilterOptions[0]
    )
  }, [workflowOnlyWithSubscriptionFilterOptions])

  return (
    <Box display="flex" alignItems="center" mr={2}>
      <Box mr={1}>
        <Icon icon={ICON.filter} color={theme.colors.brightBlue} />
      </Box>
      <DropdownList
        bgColor={theme.colors.white}
        hasShadow
        options={workflowOnlyWithSubscriptionFilterOptions}
        uniqueId="workflowOnlyWithSubscriptionFilter"
        value={
          selectedWorkflowOnlyWithSubscriptionFilterOption?.value || 'campaign'
        }
        onValueChanged={option => {
          setSelectedWorkflowOnlyWithSubscriptionFilterOption(option)
          onChange(option.value as InsightFilterType)
        }}
      />
    </Box>
  )
}

export default memo(WorkflowSubscriptionFilter)
