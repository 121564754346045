import { memo, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import Box from '@mui/material/Box'

import { useGetSubscriptionQuery } from '@shared/api/rtkQuery'
import { useWorkflowFormState } from '@widgets/workflow/_shared/WorkflowFormStateContext'

import BasicNode, { TooltipIcon } from '../_shared/BasicNode'
import type { BasicNodeOperation } from '../../hooks/useWorkflow'
import { useErrorHint } from './hooks'
import SubscriptionNodeEditDialog from './SubscriptionNodeEditDialog'
import { subscriptionNodeSchema } from './subscriptionNodeSchema'
import { SubscriptionNodeData } from './subscriptionNodeSchema'

type Props = BasicNodeOperation<SubscriptionNodeData>

const SubscriptionNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const formState = useWorkflowFormState()

    const { data, isLoading } = useGetSubscriptionQuery(
      initialValues.subscriptionId,
      {
        skip: !initialValues.subscriptionId,
      }
    )

    // 可編輯不顯示錯誤
    const errorHint = useErrorHint(
      isLoading || !initialValues.subscriptionId || formState.isEditable
        ? ''
        : data?.healthStatus || 'bad'
    )

    const handleConfirm = (values: SubscriptionNodeData) => {
      onChange(values)
      setIsOpenDialog(false)
    }

    const handleClose = () => setIsOpenDialog(false)

    const nodeName = formState.isEditable
      ? `${initialValues.subscriptionName}`
      : `${initialValues.subscriptionName} {${String(
          initialValues.subscriptionNodeId
        ).padStart(2, '0')}}`

    return (
      <>
        <BasicNode
          nodeType="action-publish_subscription"
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isSettled={subscriptionNodeSchema.isValidSync(initialValues)}
          errorHint={errorHint}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box component="span">{nodeName}</Box>
            <TooltipIcon title={id} />
          </Box>
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />
        <SubscriptionNodeEditDialog
          isOpen={isOpenDialog}
          initialValues={initialValues}
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      </>
    )
  }
)

export default SubscriptionNode
