import curry from 'lodash/curry'

import { UserJourneyEvent } from '@shared/api/rtkQuery'

export const filterUserJourneyEventsByEventNameList = curry(
  (eventNameList: string[], events: UserJourneyEvent['events']) =>
    eventNameList.length > 0
      ? events.filter(event => eventNameList.includes(event.name))
      : events
)

export default filterUserJourneyEventsByEventNameList
