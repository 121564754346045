import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

import type { Rfm } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import CharacterIcon from './CharacterIcon'

type Props = Pick<
  Rfm,
  'conditionDisplayName' | 'rawEvents' | 'orderColumn' | 'orderEvents'
> & {
  sx?: SxProps<Theme>
}

export const Info = ({
  conditionDisplayName,
  rawEvents,
  orderEvents,
  orderColumn,
  sx,
}: Props) => {
  const { t } = useTranslation(['rfm', 'common'])

  return (
    <Box
      fontSize="40px"
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ ...sx }}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flex="1 0"
      >
        <Icon
          icon={ICON.usersAlt}
          color={theme.colors.textPrimaryBlue}
          fontSize="inherit"
        />
        <Box display="flex" flexDirection="column" ml="10px">
          <Box
            fontSize="15px"
            color={theme.colors.textPrimaryBlue}
            fontWeight={600}
          >
            {t('rfm:root')}
          </Box>
          <Box fontSize="13px" color={theme.colors.textSecondBlue}>
            {conditionDisplayName}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flex="1 0"
      >
        <CharacterIcon character="R" />
        <Box display="flex" flexDirection="column" ml="10px">
          <Box
            fontSize="15px"
            color={theme.colors.textPrimaryBlue}
            fontWeight={600}
          >
            {t('rfm:last_interaction')}
          </Box>
          <Box fontSize="13px" color={theme.colors.textSecondBlue}>
            {t('rfm:according_event', { event: rawEvents.join('、') })}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flex="1 0"
      >
        <CharacterIcon character="F" />
        <Box display="flex" flexDirection="column" ml="10px">
          <Box
            fontSize="15px"
            color={theme.colors.textPrimaryBlue}
            fontWeight={600}
          >
            {t('rfm:interaction_frequency')}
          </Box>
          <Box fontSize="13px" color={theme.colors.textSecondBlue}>
            {t('rfm:according_event', { event: rawEvents.join('、') })}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        flex="1 0"
      >
        <CharacterIcon character="M" />
        <Box display="flex" flexDirection="column" ml="10px">
          <Box
            fontSize="15px"
            color={theme.colors.textPrimaryBlue}
            fontWeight={500}
          >
            {t('rfm:interaction_value')}
          </Box>
          <Box fontSize="13px" color={theme.colors.textSecondBlue}>
            {t('rfm:according_event_count', {
              events: orderEvents.join(t('common:symbol.comma')),
              name: orderColumn,
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Info
