import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

type Props = {
  autoFocus: boolean
}

const AutoFocusPlugin = ({ autoFocus }: Props): null => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (autoFocus) {
      editor.focus()
    }
  }, [editor, autoFocus])

  return null
}

export default AutoFocusPlugin
