import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useGetWorkflowSubscriptionInsightDetailQuery } from '@shared/api/rtkQuery'
import { useSharedState } from '@widgets/insight/lib/SharedStateContext'

import Table from './Table'

type Props = {
  id: number
}

const SubscriptionTable = ({ id }: Props) => {
  const { t } = useTranslation(['insight'])
  const sharedState = useSharedState()

  const { subscriptionInsightData = [], isSubscriptionFetching } =
    useGetWorkflowSubscriptionInsightDetailQuery(
      {
        id,
        start: String(sharedState.timeRangeParams[0]),
        end: String(sharedState.timeRangeParams[1]),
      },
      {
        skip: !id,
        selectFromResult: ({ data = [], isFetching }) => {
          const subscriptionInsightData = data.map(item => ({
            ...item,
            // 配合 x-data-grid 的 field 只能取用單一欄位的限制。重新組合 name 的值後，就可以不需要在 x-data-grid 使用 renderHeader 來處理
            name: `${item.name}${item.nodeId.toString().padStart(2, '0')}`,
          }))

          return {
            subscriptionInsightData,
            isSubscriptionFetching: isFetching,
          }
        },
      }
    )

  if (subscriptionInsightData.length === 0) {
    return null
  }

  return (
    <Box mb={5} width="100%">
      <Table
        title={t('insight:subscription_summary')}
        isLoading={isSubscriptionFetching}
        data={subscriptionInsightData}
      />
    </Box>
  )
}

export default SubscriptionTable
