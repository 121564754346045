import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { JobStatus } from '@shared/api/rtkQuery/audience'
import {
  CATEGORY,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'
import { MenuItem, MenuList } from '@shared/ui/menu'

type ActionPopupProps = {
  audienceRuleId: number
  status: JobStatus
  onCreateAudienceRuleJob: (e: MouseEvent<EventTarget>) => void
  onDeleteDialogOpen: (e: MouseEvent<EventTarget>) => void
  onRenameDialogOpen: (e: MouseEvent<EventTarget>) => void
}

const ActionMenuList = ({
  audienceRuleId,
  status,
  onCreateAudienceRuleJob: handleCreateAudienceRuleJob,
  onDeleteDialogOpen: handleDeleteDialogOpen,
  onRenameDialogOpen: handleRenameDialogOpen,
}: ActionPopupProps) => {
  const { t } = useTranslation(['common', 'audience'])

  const isAvailable = status === 'succeeded'

  return (
    <MenuList
      onClick={(event: MouseEvent) => {
        event.stopPropagation()
      }}
    >
      {isAvailable && [
        <MenuItem key="refresh-list" onClick={handleCreateAudienceRuleJob}>
          {t('audience:audience_builder_mode.refresh_list')}
        </MenuItem>,

        <Link
          key="copy-and-change-condition"
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/new?${QUERY_STRING.audiences.referenceId}=${audienceRuleId}`}
        >
          <MenuItem>
            {t('audience:audience_builder_mode.import_filter')}
          </MenuItem>
        </Link>,

        <Link
          key="view-detail"
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${audienceRuleId}`}
        >
          <MenuItem>{t('audience:audience_builder_mode.detail')}</MenuItem>
        </Link>,
      ]}

      <MenuItem onClick={handleRenameDialogOpen}>{t('common:rename')}</MenuItem>

      <MenuItem onClick={handleDeleteDialogOpen}>{t('common:delete')}</MenuItem>
    </MenuList>
  )
}

export default ActionMenuList
