import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import ImportFile from '@entities/ImportFile'
import { useRefetchIngestionList } from '@features/infoBox/lib/hooks'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import { createIngestion } from '@features/infoBox/model/taskSlice'
import {
  INITIAL_PAGINATION_RESPONSE,
  POLLING_INTERVAL,
  useDeleteEventIngestionMutation,
  useGetEventIngestionListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import formatCreatorTypeDisplay from '@shared/lib/utils/formatCreatorTypeDisplay'
import { getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { openToast } from '@shared/model/slices'
import type { EventIngestion } from '@shared/model/types/ingestion'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import SectionTitle from '@shared/ui/SectionTitle'
import { DataTable, TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

const IMPORT_FILE_TYPE = 'event'
const ERROR_SCHEMA_MIGRATION_STATUS_CODE = 409
const CIRCULAR_PROGRESS_SIZE = 20

const ImportEvents = () => {
  const { t } = useTranslation(['common', 'dataImport'])

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [isNeedPolling, setIsNeedPolling] = useState(false)

  const {
    data: eventIngestionList = INITIAL_PAGINATION_RESPONSE,
    isLoading,
    refetch,
  } = useGetEventIngestionListQuery(
    { page, perPage },
    { pollingInterval: isNeedPolling ? POLLING_INTERVAL : undefined }
  )

  useEffect(() => {
    if (eventIngestionList.items.length === 0) {
      return
    }

    setIsNeedPolling(
      eventIngestionList.items.some(x => x.deleteStatus === 'deleting')
    )
  }, [eventIngestionList])

  useRefetchIngestionList({ refetch })

  const [confirmDeleteId, setConfirmDeleteId] = useState<number>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)

  const [deleteEventIngestion, { isLoading: isDeleting }] =
    useDeleteEventIngestionMutation()

  const columns: TableColumn<EventIngestion>[] = useMemo(
    () => [
      { headerName: 'imported_id', field: 'id', colRatio: 0.5 },
      {
        headerName: 'imported_time',
        colRatio: 0.75,
        field: 'importedTime',
        valueFormatter: value => formatDateTimeDisplay(value as string),
      },
      {
        headerName: 'imported_type',
        colRatio: 0.5,
        field: 'creatorType',
        valueFormatter: value => formatCreatorTypeDisplay(value as string),
      },
      {
        headerName: 'imported_description',
        field: 'description',
      },
      {
        headerName: 'imported_by',
        field: 'creatorName',
      },
      {
        headerName: '',
        field: 'id',
        colRatio: 0.5,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        align: 'center',
        valueFormatter: (_, { id, isDeletable, deleteStatus }) => {
          if (deleteStatus === 'deleting') {
            return <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
          }

          if (!isDeletable) {
            return
          }

          return (
            <Tooltip title={t('common:delete')}>
              <Box display="inline-block">
                <IconButton
                  onClick={event => {
                    event.stopPropagation()
                    setConfirmDeleteId(getIntSafe(id))
                    setIsOpenDeleteDialog(true)
                  }}
                >
                  <Icon
                    icon={ICON.trashAlt}
                    color={theme.colors.textPrimaryBlue}
                    fontSize="small"
                  />
                </IconButton>
              </Box>
            </Tooltip>
          )
        },
      },
    ],
    [t]
  )

  const dispatch = useAppDispatch()

  const handleDelete = (id?: number) => async () => {
    if (!id) {
      return
    }

    try {
      await deleteEventIngestion(id).unwrap()
    } catch (error) {
      const formatError = formatRTKQueryError(error)
      if (formatError.statusCode === ERROR_SCHEMA_MIGRATION_STATUS_CODE) {
        dispatch(
          openToast({
            message: t('dataImport:upload.schema_migration'),
            status: 'error',
          })
        )
        return
      }

      dispatch(handleApiError(formatError))
    } finally {
      setIsOpenDeleteDialog(false)
    }
  }

  return (
    <>
      <Box mb={5}>
        <Breadcrumbs>
          <Box>{t('common:route.v3.import_events')}</Box>
        </Breadcrumbs>
        <Card>
          <ImportFile
            type={IMPORT_FILE_TYPE}
            onImportButtonClick={({ description, file }) => {
              const payload: CreateIngestionPayload<'event'> = {
                resourceType: IMPORT_FILE_TYPE,
                createParams: {
                  resourceType: IMPORT_FILE_TYPE,
                  description,
                },
                file,
              }
              dispatch(createIngestion(payload))
            }}
          />
        </Card>
      </Box>

      <SectionTitle title={t('dataImport:imported_event_management')} />
      <DataTable
        rows={eventIngestionList.items}
        columns={columns}
        // 元件預設 page 為 0
        page={page - 1}
        rowsPerPage={perPage}
        count={eventIngestionList.totalCount}
        isLoading={isLoading}
        onPageChange={currPage => {
          setPage(currPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
      />

      <DeleteConfirmDialog
        isLoading={isDeleting}
        isOpen={isOpenDeleteDialog}
        modalTitle={t('dataImport:imported_event_delete_confirm', {
          id: confirmDeleteId,
        })}
        onClose={() => {
          setIsOpenDeleteDialog(false)
          setConfirmDeleteId(undefined)
        }}
        onConfirm={handleDelete(confirmDeleteId)}
      />
    </>
  )
}

export default ImportEvents
