import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import type {
  AudienceRuleUserExportStatus,
  SchedulingType,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import { PAGE_ROOT } from '@shared/model/constants/routes'
import {
  ButtonWithFailedIcon,
  ButtonWithProcessingIcon,
} from '@shared/ui/buttons'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { Tooltip } from '@shared/ui/tooltips'

import {
  EXPORT_BUTTON_TEXT_MAP,
  EXPORT_BUTTON_WIDTH,
  SUPPORT_EXPORT_SMS_PROVIDER_LIST,
} from './constants'

type Props = {
  schedulingType: SchedulingType
  provider?: ThirdPartyAppProvider
  status?: AudienceRuleUserExportStatus
  thirdPartyAppId?: number
  onExport?: () => void
  onRetry?: () => void
  onAutoExportCancel?: () => void
}

export const ExportActionButtons = ({
  schedulingType,
  provider,
  status,
  thirdPartyAppId,
  onExport = () => {},
  onRetry = () => {},
  onAutoExportCancel = () => {},
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])

  const { pathname } = useLocation()

  if (status === 'processing' || status === 'initialized') {
    return (
      <ButtonWithProcessingIcon
        width={EXPORT_BUTTON_WIDTH}
        wording={t('audience:processing')}
      />
    )
  }

  if (provider && SUPPORT_EXPORT_SMS_PROVIDER_LIST.includes(provider)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Link
          to={`${pathname}/${PAGE_ROOT.thirdParty}/${thirdPartyAppId}/${PAGE_ROOT.exportTextMessage}`}
        >
          <DeepBlueGradientButton width={EXPORT_BUTTON_WIDTH}>
            {t('audience:export_sms.create')}
          </DeepBlueGradientButton>
        </Link>

        {status === 'failed' && (
          <ButtonWithFailedIcon
            width={EXPORT_BUTTON_WIDTH}
            wording={t('audience:try_again')}
            onClick={onRetry}
          />
        )}
      </Box>
    )
  }

  if (
    schedulingType === 'default' &&
    (provider === 'super8' || provider === 'omnichat')
  ) {
    return (
      <Tooltip title={t('audience:export.not_supported')}>
        <span>
          <DeepBlueGradientButton width={EXPORT_BUTTON_WIDTH} disabled>
            {t('common:export_auto')}
          </DeepBlueGradientButton>
        </span>
      </Tooltip>
    )
  }

  if (schedulingType === 'none' && provider === 'omnichat') {
    return (
      <Tooltip title={t('audience:export.not_supported_manual')}>
        <span>
          <DeepBlueGradientButton width={EXPORT_BUTTON_WIDTH} disabled>
            {t('common:export_manual')}
          </DeepBlueGradientButton>
        </span>
      </Tooltip>
    )
  }

  if (schedulingType === 'default' && status === 'completed') {
    return (
      <DeepBlueGradientButton
        width={EXPORT_BUTTON_WIDTH}
        onClick={onAutoExportCancel}
      >
        {t('audience:export_cancel_auto_export')}
      </DeepBlueGradientButton>
    )
  }

  if (status === 'failed') {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {schedulingType === 'default' && (
          <DeepBlueGradientButton
            width={EXPORT_BUTTON_WIDTH}
            onClick={onAutoExportCancel}
          >
            {t('audience:export_cancel_auto_export')}
          </DeepBlueGradientButton>
        )}

        <ButtonWithFailedIcon
          width={EXPORT_BUTTON_WIDTH}
          wording={t('audience:try_again')}
          onClick={onRetry}
        />
      </Box>
    )
  }

  return (
    <DeepBlueGradientButton width={EXPORT_BUTTON_WIDTH} onClick={onExport}>
      {t(EXPORT_BUTTON_TEXT_MAP[schedulingType])}
    </DeepBlueGradientButton>
  )
}

export default ExportActionButtons
