import type { Action, PayloadAction } from '@reduxjs/toolkit'
import type { Reducer } from 'use-immer'

import { aggregatorOperatorMap } from '@features/filters/_shared'
import { defaultAggregatorOperatorParamsMap } from '@features/filters/_shared/AggregatorFilter'
import type {
  AggregatorParameters,
  CampaignMeasureFilterNode,
  CampaignMediumEvent,
  EventOperator,
  Measure,
  MediumType,
} from '@shared/api/rtkQuery'
import { DEFAULT_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType, getToday } from '@shared/ui/DateRangePicker'

import {
  DEFAULT_CAMPAIGN_VALUE,
  DEFAULT_MEASURE_MAP,
  DEFAULT_TRACING_URL_VALUE,
  DEFAULT_TRIGGER_GROUP_TYPE_VALUE,
  DEFAULT_TRIGGER_GROUP_VALUE,
  MEDIUM_GROUP_ACTION_INDEX,
  MEDIUM_GROUP_INDEX,
  MEDIUM_GROUP_MEDIUM_TYPE_INDEX,
  TRIGGER_GROUP_CAMPAIGN_INDEX,
  TRIGGER_GROUP_INDEX,
  TRIGGER_GROUP_TRACING_URL_INDEX,
  TRIGGER_GROUP_WORKFLOW_INDEX,
} from './constants'

export const createMeasureFilterInitialState =
  (): CampaignMeasureFilterNode => {
    const today = getToday()
    return {
      type: 'campaign_measure',
      attributeFilter: {
        nodes: [
          {
            type: 'group',
            nodes: [
              {
                type: 'condition',
                field: 'medium_type',
                operator: '=',
                params: ['email'],
                source: 'campaign_events',
              },
              {
                type: 'condition',
                field: 'action',
                operator: '=',
                params: ['sent'],
                source: 'campaign_events',
              },
            ],
            combination: 'and',
          },
        ],
        combination: 'and',
      },
      eventOperator: '=',
      params: [0],
      measure: {
        aggregator: 'count',
        source: 'campaign_events',
        field: 'action',
      },
      timeRangeType: 'absolute_between',
      timeRangeParams: [
        today.nowStart.toISOString(),
        today.nowEnd.toISOString(),
      ],
    }
  }

export type MeasureFilterAction =
  | Action<'toggleTriggerGroupFilterCombination'>
  | Action<'addTriggerGroupFilter'>
  | PayloadAction<number, 'cloneTriggerGroupFilter'>
  | PayloadAction<
      {
        index: number
        value: number
      },
      'updateWorkflowFilter'
    >
  | PayloadAction<
      {
        index: number
        value: number
      },
      'updateCampaignFilter'
    >
  | PayloadAction<
      {
        index: number
        value: number
      },
      'updateTracingUrlFilter'
    >
  | PayloadAction<number, 'removeTriggerGroupFilter'>
  | PayloadAction<EventOperator | '', 'updateCampaignOperator'>
  | PayloadAction<Pick<Measure, 'aggregator' | 'field'>, 'updateMeasure'>
  | PayloadAction<string, 'updateEventName'>
  | PayloadAction<NumberOrString[], 'updateTimeRangeParams'>
  | PayloadAction<AggregatorParameters, 'updateParams'>
  | PayloadAction<MediumType, 'updateMediumType'>
  | PayloadAction<CampaignMediumEvent, 'updateMediumEvent'>

export const reducer: Reducer<CampaignMeasureFilterNode, MeasureFilterAction> =
  (draft, action) => {
    switch (action.type) {
      case 'toggleTriggerGroupFilterCombination':
        if (!draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]) {
          return
        }
        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].combination =
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].combination === 'and'
            ? 'or'
            : 'and'
        break
      case 'addTriggerGroupFilter': {
        if (!draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]) {
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX] = {
            type: 'group',
            nodes: [
              {
                type: 'group',
                nodes: [DEFAULT_TRIGGER_GROUP_TYPE_VALUE],
                combination: 'and',
              },
            ],
            combination: 'and',
          }
          return
        }

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]?.nodes.push({
          type: 'group',
          nodes: [DEFAULT_TRIGGER_GROUP_TYPE_VALUE],
          combination: 'and',
        })
        break
      }
      case 'cloneTriggerGroupFilter':
        if (
          !draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]?.nodes?.[
            action.payload
          ]
        ) {
          return
        }
        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes.push(
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[action.payload]
        )
        break
      case 'updateWorkflowFilter': {
        if (!draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]) {
          return
        }
        const { index, value } = action.payload

        const isWorkflowConditionEmpty =
          !draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_WORKFLOW_INDEX
          ]

        if (isWorkflowConditionEmpty) {
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_WORKFLOW_INDEX
          ] = {
            ...DEFAULT_TRIGGER_GROUP_VALUE,
          }
        }

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
          TRIGGER_GROUP_WORKFLOW_INDEX
        ].params = [value]

        // when workflow id changes, campaign id, tracing url id also need to initialize

        const { length } =
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[
          index
        ].nodes.splice(TRIGGER_GROUP_WORKFLOW_INDEX + 1, length)

        break
      }
      case 'updateCampaignFilter': {
        if (!draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]) {
          return
        }
        const { index, value } = action.payload

        if (
          !draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_CAMPAIGN_INDEX
          ]
        ) {
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_CAMPAIGN_INDEX
          ] = { ...DEFAULT_CAMPAIGN_VALUE, params: [value] }

          return
        }

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
          TRIGGER_GROUP_CAMPAIGN_INDEX
        ].params = [value]

        // when campaign id changes tracing url id also need to initialize
        const { length } =
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes
        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[
          index
        ].nodes.splice(TRIGGER_GROUP_CAMPAIGN_INDEX + 1, length)

        break
      }
      case 'updateTracingUrlFilter': {
        if (!draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]) {
          return
        }
        const { index, value } = action.payload

        if (
          !draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_TRACING_URL_INDEX
          ]
        ) {
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
            TRIGGER_GROUP_TRACING_URL_INDEX
          ] = { ...DEFAULT_TRACING_URL_VALUE, params: [value] }

          return
        }

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX].nodes[index].nodes[
          TRIGGER_GROUP_CAMPAIGN_INDEX
        ].params = [value]

        break
      }
      case 'removeTriggerGroupFilter':
        // 如果只剩下一個 trigger group，就把整個 node 從 attributeFilter 刪除
        if (
          draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]?.nodes.length === 1
        ) {
          draft.attributeFilter.nodes.splice(TRIGGER_GROUP_INDEX, 1)
          return
        }

        draft.attributeFilter.nodes[TRIGGER_GROUP_INDEX]?.nodes.splice(
          action.payload,
          1
        )
        break
      case 'updateCampaignOperator': {
        draft.eventOperator = action.payload

        // 未發生
        if (action.payload === 'not_happened') {
          draft.measure = undefined
          draft.params = undefined
          return
        }

        // 發生
        if (action.payload === '') {
          const { measure, params } = createMeasureFilterInitialState()
          draft.measure = measure
          draft.params = params
          return
        }

        // 其他透過 AggregatorFilter 更新的 Operator
        const currDefaultAggregatorValue =
          defaultAggregatorOperatorParamsMap.get(action.payload)
        draft.params = currDefaultAggregatorValue
        break
      }
      case 'updateMeasure': {
        const { aggregator, field } = action.payload
        const predefinedMeasure = DEFAULT_MEASURE_MAP.get(aggregator)
        draft.measure = predefinedMeasure ?? {
          aggregator,
          field,
          source: DEFAULT_EVENT_TABLE_NAME,
        }
        const [firstOperator] = aggregatorOperatorMap[aggregator]
        const defaultParams =
          defaultAggregatorOperatorParamsMap.get(firstOperator)
        draft.eventOperator = firstOperator
        draft.params = defaultParams
        break
      }
      case 'updateTimeRangeParams':
        draft.timeRangeType = getRangeType(action.payload)
        draft.timeRangeParams = action.payload
        break
      case 'updateParams':
        draft.params = action.payload
        break
      case 'updateMediumType': {
        draft.attributeFilter.nodes[MEDIUM_GROUP_INDEX].nodes[
          MEDIUM_GROUP_MEDIUM_TYPE_INDEX
        ].params = [action.payload]

        // when medium type change, action also need to initialize
        draft.attributeFilter.nodes[MEDIUM_GROUP_INDEX].nodes[
          MEDIUM_GROUP_ACTION_INDEX
        ].params = ['sent']

        // when medium type change, trigger group need to initialize
        draft.attributeFilter.nodes.splice(TRIGGER_GROUP_INDEX, 1)

        break
      }
      case 'updateMediumEvent': {
        draft.attributeFilter.nodes[MEDIUM_GROUP_INDEX].nodes[
          MEDIUM_GROUP_ACTION_INDEX
        ].params = [action.payload]

        // when medium event change, trigger group need to initialize
        draft.attributeFilter.nodes.splice(TRIGGER_GROUP_INDEX, 1)
        break
      }
      default:
        return draft
    }
  }
