const MARGIN_X = 32

export const createGetHandleStyles = (total: number = 2) => {
  if (total < 2) {
    return () => undefined
  }

  const handleStylesMap: Map<number, React.CSSProperties> = new Map()

  const step = `calc((100% - ${MARGIN_X * 2}px) / ${total - 1})`

  for (let i = 0; i < total; i++) {
    handleStylesMap.set(i, { left: `calc(${step} * ${i} + ${MARGIN_X}px)` })
  }

  return (index: number) => handleStylesMap.get(index)
}

export default createGetHandleStyles
