import { useState } from 'react'
import { Collapse } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import {
  defaultValueFormatter,
  Table,
  TableBody,
  TableBodyCell,
  TableCollapseCell,
  TableColumn,
  TableRow,
} from '@shared/ui/table'
import theme from '@theme'
import type { UsageDataType } from '@widgets/usageManagement/SendingAmount/lib/helper'
import useDetailColumns from '@widgets/usageManagement/SendingAmount/lib/hooks/useDetailColumns'

import TableDetailHead from './DetailTable/TableDetailHead'
import TableDetailRow from './DetailTable/TableDetailRow'

type Props<T extends {}> = {
  columns: TableColumn<T>[]
  data: T
  hasDetail?: boolean
}

const Row = <TItem extends {}>({
  columns,
  data,
  hasDetail = false,
}: Props<TItem>) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState<boolean>(false)

  const detailColumns = useDetailColumns()

  return (
    <>
      <TableRow isBodyCell>
        {columns.map(
          (
            { align, field, valueFormatter = defaultValueFormatter },
            columnIndex
          ) => {
            const unTypedValueFormatter = valueFormatter as (
              value: unknown,
              data: TItem
            ) => React.ReactNode

            const currValue = Array.isArray(field)
              ? field.map((item: keyof TItem) => data[item])
              : data[field]

            const isFirstColumn = columnIndex === 0

            return (
              <TableBodyCell
                align={align}
                isWordBreakAll
                key={`${columnIndex}`}
              >
                <>
                  {isFirstColumn && hasDetail && (
                    <IconButton
                      onClick={() => setIsCollapseOpen(prev => !prev)}
                    >
                      <Icon
                        color={theme.colors.textPrimaryBlue}
                        icon={isCollapseOpen ? ICON.arrowUp : ICON.arrowDown}
                      />
                    </IconButton>
                  )}
                  {unTypedValueFormatter(currValue, data)}
                </>
              </TableBodyCell>
            )
          }
        )}
      </TableRow>
      {hasDetail && (
        <TableRow isBodyCell>
          <TableCollapseCell colSpan={columns.length}>
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <Table>
                <TableDetailHead columns={detailColumns} />
                <TableBody>
                  {(data as unknown as UsageDataType).details.map(detail => (
                    <TableDetailRow
                      key={detail.triggerGroupName}
                      columns={detailColumns}
                      detailData={detail}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCollapseCell>
        </TableRow>
      )}
    </>
  )
}

export default Row
