import Box, { BoxProps } from '@mui/material/Box'

import YoutubeSvg from '@shared/assets/images/icons/icon-youtube.svg?react'

export const YoutubeIcon = (props: BoxProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box {...props}>
    <YoutubeSvg />
  </Box>
)

export default YoutubeIcon
