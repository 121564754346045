import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import camelcaseKeys from 'camelcase-keys'

import {
  AUDIENCE_PREVIEWER_STICKY_TOP,
  AudiencePreviewer,
  AudiencePreviewerField,
} from '@entities/previewers'
import { useGetTagQuery } from '@shared/api/rtkQuery/tag'
import { formatNumber } from '@shared/lib/utils/number'
import SectionTitle from '@shared/ui/SectionTitle'
import TagInfo, { TagInfoValues } from '@widgets/tag/_shared/components/TagInfo'
import TagRule from '@widgets/tag/_shared/components/TagRule'
import useGradingTag from '@widgets/tag/_shared/hooks/useGradingTag'

type Props = {
  tagId?: number
}

const Grading = ({ tagId = 0 }: Props) => {
  const {
    handleTagPreview,
    handleTagRuleCopy,
    handleTagRuleCreate,
    handleTagCreate,
    handleTagRuleDelete,
    handleTagRuleRename,
    handleTagRuleUpdate,
    isCreatingTag,
    isFetchingPreview,
    previewData,
    selectTagRuleIndex,
    setSelectTagRuleIndex,
    setTagValues,
    tagValues,
    userCount,
  } = useGradingTag()

  const { data: tag, isLoading: isLoadingTag } = useGetTagQuery(tagId, {
    skip: !tagId,
  })

  useEffect(() => {
    if (!tag) {
      return
    }

    setTagValues({
      title: tag.title,
      description: tag.description,
      type: tag.type,
      schedulingType: tag.schedulingType,
      groupId: tag.groupId,
      tagRules: (tag.tagRules ?? []).map(tagRule => ({
        ...tagRule,
        // FIXME: 不確定 filter 為什麼定義成字串，先維持原狀，將來將轉換抽到 rtk 層級
        filter: camelcaseKeys(JSON.parse(tagRule.filter), { deep: true }),
      })),
    })

    const tagRuleLength = tag.tagRules?.length ?? 0

    setSelectTagRuleIndex(tagRuleLength - 1)
  }, [setSelectTagRuleIndex, setTagValues, tag])

  const { t } = useTranslation(['tag', 'common', 'audience'])

  return (
    <>
      <SectionTitle title={t('tag:create_step_2_fill_tag_information')} />

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <TagInfo
            values={tagValues}
            onChange={tagInfo =>
              setTagValues(prev => ({
                ...prev,
                ...tagInfo,
                schedulingType: (tagInfo as TagInfoValues<'grading'>)
                  .schedulingType,
              }))
            }
          />

          <SectionTitle title={t('tag:create_step_3_select_tag_source_rule')} />

          <TagRule
            values={tagValues.tagRules}
            isFetching={false}
            selectTagRuleIndex={selectTagRuleIndex}
            onCopy={handleTagRuleCopy}
            onCreate={handleTagRuleCreate}
            onUpdate={handleTagRuleUpdate}
            onDelete={handleTagRuleDelete}
            onRename={handleTagRuleRename}
            onSelectTagRuleIndexChange={setSelectTagRuleIndex}
          />
        </Box>

        {/** sticky 需要 parent 可以滾動，因此將 marginLeft 另外寫 */}
        <Box sx={{ ml: 2 }}>
          <Box sx={{ position: 'sticky', top: AUDIENCE_PREVIEWER_STICKY_TOP }}>
            <AudiencePreviewer
              donutTitle={t('audience:of_users')}
              title={`${t('tag:total_tagged')}/${t('tag:total_people')}`}
              userCount={userCount}
              totalCount={previewData?.cdpUsersCount ?? 0}
              isLoading={isFetchingPreview}
              isCreating={isCreatingTag || isLoadingTag}
              onPreview={handleTagPreview}
              onCreate={handleTagCreate}
            >
              <AudiencePreviewerField>
                <p>{t('tag:total')}</p>
                <p>
                  {isFetchingPreview
                    ? '-'
                    : formatNumber(previewData?.cdpUsersCount ?? 0)}
                </p>
              </AudiencePreviewerField>

              {previewData?.valueInfos.map((item, idx) => {
                return (
                  <AudiencePreviewerField key={idx}>
                    <p>{item.value}</p>
                    <p>{formatNumber(item.count)}</p>
                  </AudiencePreviewerField>
                )
              })}
            </AudiencePreviewer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Grading
