import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'
import clamp from 'lodash/clamp'

import { getIntSafe } from '@shared/lib/utils/number'
import { Z_INDEX } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { PrimaryGreyInput } from '@shared/ui/inputs'

import { REACT_FLOW_NO_DRAG } from '../_shared/constants'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import {
  LogicGroupNodeData,
  logicGroupNodeSchema,
} from './logicGroupNodeSchema'
import * as Field from './styles'

export const DEFAULT_VALUES = {
  GROUPS: {
    '2': [50, 50],
    '3': [33, 33, 34],
    '4': [25, 25, 25, 25],
  },
  INPUT: { WIDTH: 88, MAX: 99, MIN: 1 },
}

type LogicGroupingNodeEditDialogProps = {
  isOpen: boolean
  initialValues: Partial<LogicGroupNodeData>
  onConfirm: (values: Partial<LogicGroupNodeData>) => void
  onClose: () => void
}

export const LogicGroupNodeEditDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: LogicGroupingNodeEditDialogProps) => {
  const formState = useWorkflowFormState()

  const { values, touched, errors, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      groups: DEFAULT_VALUES.GROUPS['2'],
      ...initialValues,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: logicGroupNodeSchema,
    onSubmit: async values => {
      if (!formState.isEditable) {
        onClose()
        return
      }

      onConfirm(values)
    },
  })

  const { t } = useTranslation(['workflow'])

  const options: Option[] = useMemo(
    () =>
      Object.keys(DEFAULT_VALUES.GROUPS).map(value => ({
        label: t('workflow:node_logic_group.edit_dialog.n_group', {
          groupCount: value,
        }),
        value,
      })),
    [t]
  )

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      maxWidth="md"
      isOpen={isOpen}
      modalTitle={t('workflow:node_logic_group.edit_dialog.title')}
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Field.Grid mb={2}>
        <Field.GridLabel>
          {t('workflow:node_logic_group.edit_dialog.group_count')}
        </Field.GridLabel>

        <Field.GridInput>
          <DropdownList
            options={options}
            popperZIndex={Z_INDEX.dialog}
            value={`${values.groups.length}`}
            disabled={!formState.isEditable}
            onValueChanged={option => {
              const targetValue = option.value as '2' | '3' | '4'
              const groups = DEFAULT_VALUES.GROUPS[targetValue] ?? []

              setFieldValue('groups', groups)
            }}
          />
        </Field.GridInput>
      </Field.Grid>

      <Field.Grid>
        <Field.GridLabel>
          {t('workflow:node_logic_group.edit_dialog.group_percent')}
        </Field.GridLabel>

        <Field.GridInput>
          {values.groups.map((group, index) => (
            <Box key={index} sx={{ display: 'inline' }}>
              <PrimaryGreyInput
                width={DEFAULT_VALUES.INPUT.WIDTH}
                value={group}
                isError={touched.groups && Boolean(errors.groups)}
                disabled={!formState.isEditable}
                onChange={({ target: { value } }) => {
                  const groups = [...values.groups]
                  groups[index] = clamp(
                    getIntSafe(value, DEFAULT_VALUES.INPUT.MIN),
                    DEFAULT_VALUES.INPUT.MIN,
                    DEFAULT_VALUES.INPUT.MAX
                  )

                  setFieldValue('groups', groups)
                }}
              />

              <Box sx={{ display: 'inline', mx: 1 }}>
                % &nbsp; {index !== values.groups.length - 1 && ' : '}
              </Box>
            </Box>
          ))}
        </Field.GridInput>

        <Field.GridError>{touched.groups && errors.groups}</Field.GridError>
      </Field.Grid>
    </ConfirmDialog>
  )
}

export default LogicGroupNodeEditDialog
