import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import {
  INITIAL_STATE_EVENT,
  NotHappenedOperator,
  useMetadataEventEntityQuery,
} from '@shared/api/rtkQuery'
import { getCategoriesOfClassification } from '@shared/lib/utils/metadata'
import DateRangePicker from '@shared/ui/DateRangePicker'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import theme from '@theme'

import type {
  MeasureFilterAction,
  SimpleMeasureFilter,
} from './measureFilterReducer'

type Props = {
  state: SimpleMeasureFilter
  isDisabled?: boolean
  dispatch: (action: MeasureFilterAction) => void
  isDisabledHappenOperator?: boolean
  filterTableNames?: string[]
}

const MeasureFilter = ({
  state: { eventName, timeRangeParams },
  isDisabled = false,
  dispatch,
  isDisabledHappenOperator = false,
  filterTableNames,
}: Props) => {
  const { t, i18n } = useTranslation(['audience', 'metadata', 'common'])

  const { data: eventEntity = INITIAL_STATE_EVENT, isLoading } =
    useMetadataEventEntityQuery({
      filterTableNames: filterTableNames,
    })

  const categoriesOfClassification = getCategoriesOfClassification(
    eventEntity
  ).filter(({ options }) => options.length > 0)

  const firstEvent = useMemo(
    () => categoriesOfClassification[0]?.options[0]?.value ?? '',
    [categoriesOfClassification]
  )

  useEffect(() => {
    if (firstEvent && !isLoading && !eventName) {
      dispatch({
        type: 'updateEventName',
        payload: firstEvent,
      })
    }
  }, [dispatch, eventName, firstEvent, isLoading])

  if (isLoading) {
    return <CircularProgress />
  }

  const happenOperatorOptions: {
    label: string
    value: '' | NotHappenedOperator
  }[] = [
    {
      label: t('metadata:operator.happened'),
      value: '',
    },
    {
      label: t('metadata:operator.not_happened'),
      value: 'not_happened',
    },
  ]

  return (
    <Box
      px={4}
      py={3}
      sx={theme => ({
        backgroundColor: theme.colors.bgPrimaryGrey,
        borderRadius: '5px',
        border: `1px solid ${theme.colors.brightBlue}`,
      })}
    >
      <Box
        width="100%"
        py={0.5}
        pl={3}
        sx={theme => ({
          borderLeft: `5px solid ${theme.colors.brightBlue}`,
        })}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <Box mr={2}>{t('audience:v3.time_range')}</Box>
          <Box mr={1.5}>
            <DateRangePicker
              values={timeRangeParams}
              isDisabled={isDisabled}
              popperDisablePortal
              isFutureEnable={true}
              onChange={values => {
                dispatch({
                  type: 'updateTimeRangeParams',
                  payload: values,
                })
              }}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={1}>
          <Box mr={1}>
            <DropdownList
              key={i18n.language}
              bgColor={theme.colors.white}
              options={happenOperatorOptions}
              value={happenOperatorOptions[0].value}
              popperDisablePortal
              disabled={isDisabled || isDisabledHappenOperator}
              onValueChanged={value =>
                dispatch({
                  type: 'updateEventOperator',
                  payload: value.value as NotHappenedOperator | '',
                })
              }
            />
          </Box>

          <Box mr={1}>
            <CategoryList
              defaultLabel={t('common:dropdownlist_default')}
              bgColor={theme.colors.white}
              categories={categoriesOfClassification}
              selectedValue={eventName}
              isDisabled={isDisabled}
              popperDisablePortal
              onValueChanged={eventName => {
                dispatch({
                  type: 'updateEventName',
                  payload: eventName,
                })
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MeasureFilter
