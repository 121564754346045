import axios from 'axios'
import qs from 'qs'

const baseURLV1 = import.meta.env.VITE_API_URL_DASHBOARD

export const apiV1 = axios.create({
  baseURL: baseURLV1,
  timeout: 5 * 60 * 1000,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
})

export const newCancelSource = () => axios.CancelToken.source()

export default apiV1
