import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { ProductList } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import ConfirmDialog from '@shared/ui/dialogs/ConfirmDialog'
import { Button as BaseButton } from '@shared/ui/Dropdown/ActionButton'
import { UniIcon as Icon } from '@shared/ui/icons'
import InfiniteScrollLoader from '@shared/ui/loaders/InfiniteScrollLoader'
import { SearchRoundedBar } from '@shared/ui/searchInput'
import theme from '@theme'

export const SCROLL_DEBOUNCE_TIME = 2000

const Button = styled(BaseButton)`
  &&.MuiButton-root {
    padding-right: ${({ isDisabled }) => (isDisabled ? 16 : 8)}px;

    width: 100%;

    height: auto;
    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.veryLightBlueTwo : theme.colors.bgPrimaryGrey};

    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
  }

  & > .MuiButton-label {
    color: ${({ theme }) => theme.colors.lightNavyBlue};
    font-size: 13px;
  }
`

export type Option = { label: string; value: string }

type Props = {
  data: ProductList['items']
  isDisabled?: boolean
  search: string
  isLoading: boolean
  enabledInfiniteScrollLoader: boolean
  onLoadMore: () => void
  onChange: (value: string) => void
  onSearchByName: (value: string) => void
}

const ProductListDialog = ({
  data,
  isDisabled = false,
  isLoading,
  search,
  enabledInfiniteScrollLoader,
  onChange,
  onLoadMore,
  onSearchByName,
}: Props) => {
  const { t } = useTranslation(['common', 'aiMarketingAssistant'])
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<Option>()
  const [selectedProduct, setSelectedProduct] = useState<Option>()

  const handleOnConfirm = () => {
    setIsDialogOpen(false)
    setSelectedProduct(selectedValue)
  }

  const handleOnCancel = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <Card mb={5}>
        <Box mb={2} component="h2">
          {t('aiMarketingAssistant:product_selection')}
        </Box>
        <Box width="100%">
          <Button isDisabled={isDisabled} onClick={() => setIsDialogOpen(true)}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              mr={1}
              minHeight={32}
            >
              {selectedProduct ? (
                <Box
                  display="inline-block"
                  textAlign="left"
                  m={0.5}
                  px={1.5}
                  py={0.5}
                  minHeight="24px"
                  borderRadius="2px"
                  color={theme.colors.lightNavyBlue}
                >
                  {selectedProduct.label}
                </Box>
              ) : (
                <>{t('common:dropdownlist_default')}</>
              )}
            </Box>
            {!isDisabled && (
              <Icon
                icon={ICON.arrowDown}
                fontSize="small"
                color={theme.colors.textPrimaryBlue}
              />
            )}
          </Button>
        </Box>
      </Card>
      <Box mb={5}>
        <DeepBlueGradientButton
          width={200}
          disabled={isDisabled || !selectedProduct}
          onClick={() => {
            if (selectedProduct) {
              onChange(selectedProduct.value)
            }
          }}
        >
          {t('common:analysis')}
        </DeepBlueGradientButton>
      </Box>

      <ConfirmDialog
        modalTitle={t('common:dropdownlist_default')}
        isOpen={isDialogOpen}
        onConfirm={handleOnConfirm}
        onClose={handleOnCancel}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="48px"
          borderBottom={`1px solid ${theme.colors.black6}`}
        >
          <SearchRoundedBar
            defaultValue={search}
            onSearch={text => {
              onSearchByName(text)
            }}
            placeholder={t('common:search')}
          />
        </Box>
        <Box mt={3} maxHeight={300}>
          {data.map(({ productId, name }) => {
            const isSelected = selectedValue?.value === productId

            return (
              <Box
                display="inline-block"
                textAlign="left"
                m={0.5}
                px={1.5}
                py={0.5}
                key={productId}
                minHeight="24px"
                sx={{
                  backgroundColor: isSelected
                    ? theme.colors.textPrimaryBlue
                    : theme.colors.bgPaleGrey,
                  cursor: 'pointer',
                }}
                fontWeight={500}
                fontSize={14}
                lineHeight="20px"
                borderRadius="2px"
                color={
                  isSelected ? theme.colors.white : theme.colors.lightNavyBlue
                }
                onClick={() =>
                  setSelectedValue({ label: name, value: productId })
                }
              >
                {name}
              </Box>
            )
          })}

          {enabledInfiniteScrollLoader && (
            <InfiniteScrollLoader isLoading={isLoading} loadMore={onLoadMore} />
          )}
        </Box>
      </ConfirmDialog>
    </>
  )
}

export default ProductListDialog
