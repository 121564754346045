import i18n from 'i18next'

import {
  FOOTER_CONTENT_STYLE,
  FOOTER_STYLE,
  LINK_STYLE,
  UNSUBSCRIBE_KEYWORD,
  UNSUBSCRIBE_KEYWORD_REG_EXP,
} from '../models'

export const appendUnsubscribeFooter = (html: string) => {
  // 有塞過 footer 的就不處理，不然會有兩個 footer
  if (UNSUBSCRIBE_KEYWORD_REG_EXP.test(html)) {
    return html
  }

  // {{ xxxx }} 會 被 react-i18next 處理成空值
  // 故用 replace 的方式 work around
  const content = i18n
    .t('contentTemplate:unsubscribe_content', {
      style: LINK_STYLE,
      href: UNSUBSCRIBE_KEYWORD,
    })
    .replace(UNSUBSCRIBE_KEYWORD, `{{${UNSUBSCRIBE_KEYWORD}}}`)

  return html.replace(
    '</body>',
    // 這樣處理是因爲 `justify-content: center` 不知道為何在 email 上會被移除
    `<footer style="${FOOTER_STYLE}"><div style="${FOOTER_CONTENT_STYLE}">${content}</div></footer></body>` +
      // 以下這段標記是用來隱藏電子豹的取消訂閱連結
      // eslint-disable-next-line no-template-curly-in-string
      '<!-- ${UNSUBSCRIBE} -->'
  )
}

export default appendUnsubscribeFooter
