import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { ProductKol } from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import {
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  YoutubeIcon,
} from '@shared/ui/icons'

type MediaData = Pick<ProductKol, 'media'>

type MediaPlatformLowerCase = 'facebook' | 'youtube' | 'instagram' | 'tiktok'

export type Props = Partial<MediaData['media'][number]>

const SocialMediaPlatformData = ({ platform, follower }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])

  const icons = {
    Facebook: <FacebookIcon mr={1} display="flex" alignItems="center" />,
    YouTube: <YoutubeIcon mr={1} display="flex" alignItems="center" />,
    Instagram: <InstagramIcon mr={1} display="flex" alignItems="center" />,
    TikTok: <TiktokIcon mr={1} display="flex" alignItems="center" />,
  }

  const icon = icons[platform as keyof typeof icons]

  // 不在列表內的平台不顯示
  if (!icon) {
    return null
  }

  return (
    <Box mr={3}>
      <Box
        fontWeight={400}
        fontSize={13}
        lineHeight="15px"
        mb={0.5}
        sx={theme => ({
          color: theme.colors.textSecondBlue,
        })}
      >
        {platform &&
          t(
            `aiMarketingAssistant:kol_recommendation.${
              platform.toLowerCase() as MediaPlatformLowerCase
            }_followers`
          )}
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box display="flex" alignItems="center" justifyContent="center">
          {icon}
        </Box>
        <Box
          fontWeight={400}
          fontSize={18}
          lineHeight="28px"
          sx={theme => ({
            color: theme.colors.black,
          })}
        >
          {formatNumber(follower || 0)}
        </Box>
      </Box>
    </Box>
  )
}

export default SocialMediaPlatformData
