import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '@shared/api/rtkQuery/api'

import {
  CreateWorkflowResponse,
  Workflow,
  WorkflowFeature,
  WorkflowFromApi,
  WorkflowListItem,
  WorkflowStatus,
} from './types'
import { convertWorkflowNodeForApi, convertWorkflowNodeFromApi } from './utils'

const transformResponseWorkflow = ({
  nodes,
  ...otherProps
}: WorkflowFromApi) => ({
  ...transformResponseCamelCaseKeys(otherProps),
  ...convertWorkflowNodeFromApi(nodes),
})

const transformPayloadWorkflow = ({
  edges,
  nodes,
  ...otherProps
}: Partial<Workflow>) => ({
  ...otherProps,
  nodes: convertWorkflowNodeForApi({ nodes, edges }),
})

const workflow = api.injectEndpoints({
  endpoints: builder => ({
    createWorkflow: builder.mutation<CreateWorkflowResponse, Workflow>({
      query: payload => ({
        url: `/workflows`,
        method: 'POST',
        body: transformPayloadWorkflow(payload),
      }),
      transformResponse: (data: CreateWorkflowResponse) =>
        transformResponseCamelCaseKeys(data),
      invalidatesTags: ['Workflow'],
    }),
    getWorkflowList: builder.query<
      PaginationResponse<WorkflowListItem>,
      PaginationParams & { name: string; statusTypes: WorkflowStatus[] }
    >({
      query: params => ({
        url: '/workflows',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (data: PaginationResponse<WorkflowListItem>) =>
        transformResponseCamelCaseKeys(data),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'Workflow' as const,
                id,
              })),
              'Workflow',
            ]
          : ['Workflow'],
    }),
    patchUpdateWorkflow: builder.mutation<
      void,
      { id: number } & Partial<Workflow>
    >({
      query: payload => ({
        url: `/workflows/${payload.id}`,
        // 雖然 API 使用 PUT 但行為是 PATCH
        method: 'PUT',
        body: transformPayloadWorkflow(payload),
      }),
      invalidatesTags: (result, error, arg) => {
        if (error) {
          // invalid no cache when error
          return []
        }

        return ['Workflow', { type: 'Workflow', id: arg.id }]
      },
    }),
    deleteWorkflow: builder.mutation<void, number>({
      query: id => ({
        url: `/workflows/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Workflow'],
    }),
    getWorkflow: builder.query<Workflow, number>({
      query: id => ({
        url: `/workflows/${id}`,
      }),
      transformResponse: transformResponseWorkflow,
      providesTags: (result, error, id) => [{ type: 'Workflow', id }],
    }),
    getWorkflowNodesAvailability: builder.query<WorkflowFeature, void>({
      query: () => '/workflows/nodes/availability',
      transformResponse: (data: WorkflowFeature) =>
        transformResponseCamelCaseKeys(data),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateWorkflowMutation,
  useDeleteWorkflowMutation,
  useGetWorkflowListQuery,
  useGetWorkflowQuery,
  usePatchUpdateWorkflowMutation,
  useGetWorkflowNodesAvailabilityQuery,
} = workflow
