import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAudienceSummaryUserProfileSummaryQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'
import EmptyData from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/EmptyData'

import useGroupData from '../_shared/hooks/useGroupData'
import DonutChart from './DonutChart'

const Gender = () => {
  const { t } = useTranslation(['audience'])
  const { audienceId } = useAudienceDetailState()

  const { data, isError, isSuccess, isFetching, isLoading, isUninitialized } =
    useGetAudienceSummaryUserProfileSummaryQuery(
      { audienceId, type: 'gender' },
      {
        skip: audienceId === NO_DATA_ID,
      }
    )

  const chartTitle = useMemo(() => {
    if (!data) {
      return ''
    }

    return t(`audience:${data.orderedByCountSummary[0].groupName}`)
  }, [data, t])

  const groupNameData = useGroupData({
    orderedByCountSummary: data?.orderedByCountSummary,
  })

  return (
    <BasicCard
      chartName="gender"
      isSuccess={isSuccess}
      isLoading={isUninitialized || isLoading}
      isError={isError}
      isFetching={isFetching}
      subject={t('audience:main_gender')}
      title={chartTitle}
      nonMembers={getIntSafe(data?.totalNonMembers, '').toLocaleString()}
    >
      {data ? (
        <DonutChart
          series={groupNameData.map(({ count }) => count)}
          labels={groupNameData.map(({ groupName }) => groupName)}
          total={data.totalMembers}
        />
      ) : (
        <EmptyData />
      )}
    </BasicCard>
  )
}

export default Gender
