import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormik } from 'formik'
import mustache from 'mustache'

import {
  EmailEditor,
  recoverUrlFromReplacementData,
  SendEmailDialog,
  useEmailEditor,
  useMsgSenderOptions,
} from '@entities/mediumTemplates'
import { useAppSelector } from '@shared/lib/hooks/store'
import useGetMsgChannelForSelectOptionsQuery from '@shared/lib/hooks/useGetMsgChannelForSelectOptionsQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { createPreviewTemplateData } from '@shared/model/constants/contentTemplate'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import theme from '@theme'
import { useWorkflowFormState } from '@widgets/workflow/_shared/WorkflowFormStateContext'

import {
  DEFAULT_TIMING_DATA,
  Label,
  REACT_FLOW_NO_DRAG,
  Timing,
} from '../../_shared'
import type { ActionSendEmailNodeData } from '../actionSendEmailNodeSchema'
import { actionSendEmailNodeSchema } from '../actionSendEmailNodeSchema'
import { EMAIL_EDITOR_HEIGHT } from '../constants'
import SelectEmailTemplateDialog from './SelectEmailTemplateDialog'
import { getDefaultValues } from './utils'

type Props = {
  id: string
  initialValues: ActionSendEmailNodeData
  isOpen: boolean
  onConfirm: (data: ActionSendEmailNodeData) => void
  onClose: () => void
}

const ActionSendEmailNodeDialog = ({
  id,
  initialValues,
  isOpen,
  onConfirm,
  onClose,
}: Props) => {
  const { t } = useTranslation(['contentTemplate', 'workflow', 'settings'])

  const {
    isReady,
    isExporting,
    emailEditorRef,
    onExportDesign,
    onLoadDesign,
    onReady,
  } = useEmailEditor()

  const [openDialog, setOpenDialog] = useState<
    'edit' | 'template' | 'testEmail'
  >('edit')

  const formState = useWorkflowFormState()
  const isEnableTestingEmail = useAppSelector(
    state => state.auth.plan.workflow.testingEmail
  )

  const { msgChannelOptions, isMsgChannelLoading } =
    useGetMsgChannelForSelectOptionsQuery({ mediumType: 'email' })

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    setValues,
    touched,
    setTouched,
  } = useFormik<ActionSendEmailNodeData>({
    initialValues: {
      ...getDefaultValues(),
      ...initialValues,
    },
    validationSchema: actionSendEmailNodeSchema,
    onSubmit: async data => {
      if (!formState.isEditable) {
        onClose()
        return
      }

      const { html, design, replacementData, previewUrl } =
        await onExportDesign({ preheaderText: values.preheader })

      onConfirm({
        ...data,
        content: html,
        editorJson: design,
        previewUrl,
        replacementData,
        reactionTiming: data.reactionTiming,
      })
    },
  })

  useEffect(() => {
    if (!formState.isSubmitted || !isOpen) {
      return
    }

    setTouched(
      { campaignName: true, preheader: true, title: true, msgChannelId: true },
      true
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitted, isOpen])

  const checkIsError = (name: keyof ActionSendEmailNodeData) =>
    Boolean(errors[name]) && touched[name]

  useEffect(() => {
    if (!isReady || !values.editorJson) {
      return
    }

    onLoadDesign(values.editorJson)
  }, [values.editorJson, isReady, onLoadDesign])

  const thirdPartyAppType = useMemo(
    () =>
      msgChannelOptions.find(
        ({ value }) => value === String(values.msgChannelId)
      )?.providerName,
    [msgChannelOptions, values.msgChannelId]
  )

  const handleClose = () => {
    setValues({
      ...getDefaultValues(),
      ...initialValues,
    })
    // 重設 touched 是為了讓表單的錯誤資訊隱藏，避免下次開啟彈窗會直接顯示紅框
    setTouched({})

    if (initialValues.editorJson) {
      onLoadDesign(initialValues.editorJson)
    }

    onClose()
  }

  const isEnableTracking = useMemo(
    () => Boolean(values.reactionTiming),
    [values.reactionTiming]
  )

  const previewHtml = useMemo(
    () =>
      mustache.render(
        recoverUrlFromReplacementData(values.content, values.replacementData),
        createPreviewTemplateData()
      ),
    [values.content, values.replacementData]
  )

  const msgSenderOptions = useMsgSenderOptions({
    msgChannelId: values.msgChannelId,
    msgChannelOptions,
  })

  return (
    <>
      <ConfirmDialog
        className={REACT_FLOW_NO_DRAG}
        isLoading={isExporting}
        isOpen={isOpen && openDialog === 'edit'}
        // 避免每次開啟 dialog 都重新初始 react-email-editor
        keepMounted
        maxWidth="lg"
        modalTitle={t('workflow:node_action_email_configuration')}
        onConfirm={handleSubmit}
        onClose={handleClose}
      >
        <Box pb={2} mb={2} borderBottom={`1px solid ${theme.colors.black6}`}>
          <Box display="flex" alignItems="center" mb={2}>
            <Label htmlFor={`msgChannelId_${id}`}>
              {t('contentTemplate:send_test_email_form.labels.third_party')} *
            </Label>

            <NestedList
              anchorId={`msgChannelId_${id}`}
              isFullWidth
              isDisabled={isMsgChannelLoading || !formState.isEditable}
              isError={checkIsError('msgChannelId')}
              label={t(
                'contentTemplate:send_test_email_form.placeholders.third_party'
              )}
              onChange={({ value }) => {
                setFieldValue('msgChannelId', getIntSafe(value))
                setFieldValue('msgSenderId', null)
              }}
              options={msgChannelOptions}
              popperZIndex={Z_INDEX.dialog}
              value={String(values.msgChannelId)}
            />
          </Box>
          {thirdPartyAppType && (
            <Box display="flex" alignItems="center" mb={2}>
              <Label />

              <Box>
                {t('contentTemplate:send_test_email_form.labels.service')}：
                {thirdPartyAppType}
              </Box>
            </Box>
          )}

          <Box display="flex" alignItems="center" mb={2}>
            <Label>{t('workflow:node_action_email_from_name')} *</Label>

            <DropdownList
              disabled={!formState.isEditable}
              isError={checkIsError('msgSenderId')}
              isFullWidth
              onValueChanged={({ value }) =>
                setFieldValue('msgSenderId', value)
              }
              options={msgSenderOptions}
              popperZIndex={Z_INDEX.dialog}
              value={values.msgSenderId}
            />
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Label htmlFor={`campaignName_${id}`}>
              {t('workflow:node_action_campaign_name')} *
            </Label>

            <PrimaryGreyInput
              disabled={!formState.isEditable}
              fullWidth
              // 因為 keepMounted 故額外加上 id
              id={`campaignName_${id}`}
              isError={checkIsError('campaignName')}
              name="campaignName"
              onChange={event => {
                setFieldValue('campaignName', event.target.value)
              }}
              placeholder={t('workflow:node_action_campaign_name_placeholder')}
              value={values.campaignName}
            />
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Label htmlFor={`title_${id}`}>
              {t('workflow:node_action_email_subject')} *
            </Label>

            <PrimaryGreyInput
              disabled={!formState.isEditable}
              fullWidth
              // 因為 keepMounted 故額外加上 id
              id={`title_${id}`}
              isError={checkIsError('title')}
              name="title"
              onChange={event => {
                setFieldValue('title', event.target.value)
              }}
              placeholder={t('workflow:node_action_email_subject_placeholder')}
              value={values.title}
            />
          </Box>

          <Box display="flex" alignItems="center" mb={2}>
            <Label htmlFor={`preheader_${id}`}>
              {t('workflow:node_action_email_preheader')} *
            </Label>

            <PrimaryGreyInput
              disabled={!formState.isEditable}
              fullWidth
              // 因為 keepMounted 故額外加上 id
              id={`preheader_${id}`}
              isError={checkIsError('preheader')}
              name="preheader"
              onChange={event => {
                setFieldValue('preheader', event.target.value)
              }}
              placeholder={t(
                'workflow:node_action_email_preheader_placeholder'
              )}
              value={values.preheader}
            />
          </Box>
        </Box>

        <Box
          width="100%"
          mb={2}
          pb={2}
          borderBottom={`1px solid ${theme.colors.black6}`}
        >
          <Box display="flex" alignItems="center" width="100%">
            <Label htmlFor={`isEnableTracking_${id}`}>
              {t('workflow:node_action_email_open_condition')}
            </Label>

            <Box width="100%">
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isEnableTracking}
                      color="primary"
                      disabled={!formState.isEditable}
                      id={`isEnableTracking_${id}`}
                      onChange={() => {
                        setFieldValue(
                          'reactionTiming',
                          values.reactionTiming
                            ? undefined
                            : DEFAULT_TIMING_DATA
                        )
                      }}
                    />
                  }
                  label={
                    <Box fontSize={14} fontWeight="bold">
                      {t('workflow:node_action_sms_track')}
                    </Box>
                  }
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" width="100%">
            <Label />

            <Collapse in={isEnableTracking} timeout="auto">
              <Box pl={3} pt={2} borderTop={`1px solid ${theme.colors.black6}`}>
                <Timing
                  isEditable={formState.isEditable && isEnableTracking}
                  timeData={values.reactionTiming}
                  onTimeDataChange={data => {
                    if (isEnableTracking) {
                      setFieldValue('reactionTiming', data)
                    }
                  }}
                  isHideDurationHourAndMinute
                  defaultTimeData={DEFAULT_TIMING_DATA}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          {t('workflow:node_action_email_content')}
          <div>
            {isEnableTestingEmail && (
              <Button
                color="primary"
                disabled={!formState.isEditable || !values.msgChannelId}
                onClick={() => {
                  setOpenDialog('testEmail')
                }}
                sx={{ mr: 2 }}
                variant="outlined"
              >
                {t('contentTemplate:send_test_email')}
              </Button>
            )}

            <Button
              color="primary"
              disabled={!formState.isEditable}
              onClick={() => {
                setOpenDialog('template')
              }}
              startIcon={<Icon icon={ICON.plusCircle} color="inherit" />}
              variant="outlined"
            >
              {t('contentTemplate:load_content_template')}
            </Button>
          </div>
        </Box>

        <Box
          sx={theme => ({
            border: `1px solid ${theme.colors.black6}`,
            borderRadius: '10px',
            overflow: 'hidden',
          })}
        >
          {formState.isEditable ? (
            <EmailEditor
              minHeight={EMAIL_EDITOR_HEIGHT}
              onReady={onReady}
              ref={emailEditorRef}
            />
          ) : (
            <Box
              dangerouslySetInnerHTML={{
                __html: previewHtml,
              }}
              maxHeight={EMAIL_EDITOR_HEIGHT}
              overflow="scroll"
            />
          )}
        </Box>

        <Box
          component="p"
          sx={theme => ({ mt: 2, color: theme.colors.textSecondBlue })}
        >
          {t('workflow:node_action_email_reminder')}
        </Box>
      </ConfirmDialog>

      {openDialog === 'template' && (
        <SelectEmailTemplateDialog
          onConfirm={template => {
            if (!template || !template.editorJson) {
              return
            }

            setFieldValue('editorJson', template.editorJson)
            setOpenDialog('edit')
          }}
          onClose={() => {
            setOpenDialog('edit')
          }}
        />
      )}

      {openDialog === 'testEmail' && values.msgChannelId && (
        <SendEmailDialog
          className={REACT_FLOW_NO_DRAG}
          defaultValues={{
            msgChannelId: values.msgChannelId,
            msgSenderId: values.msgSenderId,
            preheader: values.preheader,
            title: values.title ?? '',
          }}
          isLoading={isExporting}
          onClose={() => setOpenDialog('edit')}
          onExportDesign={onExportDesign}
        />
      )}
    </>
  )
}

export default ActionSendEmailNodeDialog
