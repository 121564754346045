import styled from '@emotion/styled'

const REACT_FLOW_NODES_Z_INDEX = 5

export const Wrapper = styled.div`
  flex-grow: 1;
  height: 85vh;

  & .react-flow__controls {
    top: 10px;
    right: 10px;
    bottom: auto;
    left: auto;
  }

  & .react-flow__edge-path {
    stroke-width: 3;
    stroke: ${({ theme }) => theme.colors.blackTwo};
  }

  & .react-flow__edge.selected .react-flow__edge-path {
    stroke: ${({ theme }) => theme.colors.brightBlue};
  }

  & .react-flow__handle {
    position: absolute;

    width: 12px;
    height: 12px;

    border: 3px solid ${({ theme }) => theme.colors.blackTwo};
    border-radius: 100%;
    background: ${({ theme }) => theme.colors.white};

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;

      display: inline-block;
      width: 32px;
      height: 32px;

      content: '';
      transform: translate(-50%, -50%);
    }
  }

  & .react-flow__handle-top {
    top: -6px;
  }

  & .react-flow__handle-bottom {
    bottom: -6px;
  }
`

export const CheckboxOuter = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  min-height: 44px;
`

export const WorkflowDisableHint = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);

  padding: 12px 64px;

  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.bgPaleGrey};
  color: ${({ theme }) => theme.colors.brightBlue};

  z-index: ${REACT_FLOW_NODES_Z_INDEX};
`

export const Aside = styled.aside`
  padding: 24px;

  background-color: ${({ theme }) => theme.colors.white};
`

export const AsideTitle = styled.div`
  padding: 12px 24px;

  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const CategoryList = styled.ul`
  overflow-y: auto;
  max-height: 60vh;

  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const CategoryListItem = styled.li`
  background-color: ${({ theme }) => theme.colors.borderColor};
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
`

export const CategoryTitle = styled.span`
  padding: 0 24px;

  font-size: 12px;
  line-height: 2.5;
`

export const NodeList = styled.ul`
  padding: 24px;

  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
`

export const NodeListItem = styled.li`
  display: flex;
  align-items: center;

  padding: 16px;

  width: 220px;
  height: 56px;

  border: 1px solid ${({ theme }) => theme.colors.blackTwo};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};

  cursor: ${({ theme, draggable }) => (draggable ? 'grab' : 'default')};

  & + & {
    margin-top: 24px;
  }
`
