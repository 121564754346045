import { useEffect, useRef, useState } from 'react'

export const useTrackAndPageInfo = ({
  lengthOfData,
  wrapperId,
  itemClassname,
}: {
  lengthOfData: number
  wrapperId: string
  itemClassname: string
}) => {
  const [pageInfo, setPageInfo] = useState({ curr: 0, total: 0 })
  const [trackPos, setTrackPos] = useState(0)

  const posInfo = useRef({
    windowWidth: 0, // 記住 window 的總長度，
    pageMapToOffsetIdx: [0] as number[], // 記住每一頁的起點 item 的 idx
    offsetLefts: [...Array(lengthOfData)].map(x => 0), // 每個 item 的起始位置
  })

  useEffect(() => {
    const wrapper: any = document.querySelector<HTMLElement>(`#${wrapperId}`)
    if (wrapper) {
      posInfo.current.windowWidth = wrapper.offsetWidth
    }
  }, [wrapperId])

  useEffect(() => {
    let count = 0
    /* 
      同時會有多個 item, 所以要加上 wrapperId 確保只拿到各自的 items 計算 offset 與頁數
    */
    const elems = document.querySelectorAll<HTMLElement>(
      `#${wrapperId} .${itemClassname}`
    )

    elems.forEach((elem, idx) => {
      const currOffsetLeft = elem.offsetLeft
      const currOffsetWidth = elem.offsetWidth

      posInfo.current.offsetLefts[idx] = currOffsetLeft

      const prevOffsetIdx =
        posInfo.current.pageMapToOffsetIdx[
          posInfo.current.pageMapToOffsetIdx.length - 1
        ] || 0
      const prevOffset = posInfo.current.offsetLefts[prevOffsetIdx]

      // 這裡是判斷哪些 item 要當成下一頁的開頭，只要該 item 有部分被外層 window 遮蔽，就是下一頁的起始點
      // -8 是 magic number，為了讓 item 在頁面上可以顯示比較完整
      if (
        currOffsetLeft +
          currOffsetWidth -
          prevOffset -
          posInfo.current.windowWidth >
        -8
      ) {
        count += 1
        posInfo.current.pageMapToOffsetIdx.push(idx)
      }
    })

    setPageInfo(prev => ({ ...prev, total: count }))
  }, [wrapperId, itemClassname])

  return {
    posInfo,
    pageInfo,
    setPageInfo,
    trackPos,
    setTrackPos,
  }
}
