import type { FunnelAnalyticsNode } from '@shared/api/rtkQuery'

import type { FunnelAnalysisPreviewed } from '../core'

export const formatFunnelRule = (
  source: FunnelAnalyticsNode
): FunnelAnalysisPreviewed => {
  const target: FunnelAnalysisPreviewed = {
    eventName: [],
    groupBy: [],
  }

  source.funnelSteps.forEach(funnelStep => {
    target.eventName.push(funnelStep.eventName)
  })

  source.groupByFields.forEach(groupByField => {
    target.groupBy.push(groupByField.field)
  })

  return target
}
