import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import Box from '@mui/material/Box'

import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'

import {
  BasicNode,
  createGetHandleStyles,
  HandleSourceText,
  TooltipIcon,
} from '../_shared'
import { useMsgChannel } from '../_shared/hooks'
import type { BasicNodeOperation } from '../../hooks/useWorkflow'
import ActionSendSmsNodeEditDialog from './ActionSendSmsNodeEditDialog'
import {
  ActionSendSmsNodeData,
  actionSendSmsNodeSchema,
} from './actionSendSmsNodeSchema'

type Props = BasicNodeOperation<ActionSendSmsNodeData>

const ActionSendSmsNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    isConnectable,
    type,
  }: NodeProps<Props>) => {
    const { t } = useTranslation([
      'audience',
      'contentTemplate',
      'workflow',
      'common',
      'settings',
    ])

    const {
      checkHasArchivedTracingUrlInReplacementData:
        hasArchivedTracingUrlInReplacementDataChecker,
    } = useTracingUrlStatus()

    const isTrackingEnabled = Boolean(initialValues.reactionTiming)

    const hasArchivedTracingUrl = hasArchivedTracingUrlInReplacementDataChecker(
      initialValues.replacementData
    )

    const stringifyValues = JSON.stringify(initialValues)

    const isSettled = useMemo(
      () => actionSendSmsNodeSchema.isValidSync(JSON.parse(stringifyValues)),
      [stringifyValues]
    )

    const updateNodeInternals = useUpdateNodeInternals()

    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const handleConfirm = (values: Partial<ActionSendSmsNodeData>) => {
      const hasOpenRateSwitched =
        Boolean(initialValues.reactionTiming) !== Boolean(values.reactionTiming)

      if (onDeleteEdges && hasOpenRateSwitched) {
        onDeleteEdges('source')
      }

      onChange(values)
      setIsOpenDialog(false)
    }

    const handleClose = () => {
      setIsOpenDialog(false)
    }

    const {
      msgChannel: smsProvider,
      isLoading,
      isSuccess,
    } = useMsgChannel({
      msgChannelId: initialValues.msgChannelId,
      mediumType: 'sms',
    })

    useEffect(() => {
      if (isSuccess && !smsProvider) {
        onChange({
          ...initialValues,
          msgChannelId: undefined,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, smsProvider, onChange])

    useEffect(() => {
      // 在 isTrackingEnabled 變化時才更新，因為 Handle 位置會變化，不在這邊刪除 Edge。刪除 Edge 在 handleConfirm 裡面處理，避免第一次進來時就刪除 Edge
      updateNodeInternals(id)
    }, [id, isTrackingEnabled, updateNodeInternals])

    const getHandleStyles = useMemo(() => createGetHandleStyles(2), [])

    return (
      <>
        <BasicNode
          nodeType="action-send_sms"
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isLoading={isLoading}
          isSettled={isSettled}
          errorHint={
            hasArchivedTracingUrl
              ? t('workflow:errors.tracing_url_is_archived_plz_recreate')
              : ''
          }
        >
          {smsProvider && (
            <Box sx={{ display: 'flex' }}>
              {smsProvider?.providerName}

              <TooltipIcon
                title={
                  <>
                    <p>{id}</p>
                    <p>
                      {t('workflow:node_action_campaign_hint', {
                        name: initialValues.campaignName,
                      })}
                    </p>
                  </>
                }
              />
            </Box>
          )}
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        {!isTrackingEnabled ? (
          <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
          />
        ) : (
          <>
            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={isConnectable}
              style={getHandleStyles(0)}
              id="yes"
            >
              <HandleSourceText>
                {t('workflow:node_action_sms_clicked_handler')}
              </HandleSourceText>
            </Handle>

            <Handle
              type="source"
              position={Position.Bottom}
              style={getHandleStyles(1)}
              isConnectable={isConnectable}
              id="no"
            >
              <HandleSourceText left={-2}>
                {t('workflow:node_action_sms_ignore_handler')}
              </HandleSourceText>
            </Handle>
          </>
        )}

        {isOpenDialog && (
          <ActionSendSmsNodeEditDialog
            isOpen={isOpenDialog}
            initialValues={initialValues}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        )}
      </>
    )
  }
)

export default ActionSendSmsNode
