import styled from '@emotion/styled'

import { DEFAULT_LABEL_WIDTH } from './models/constants'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

export const Label = styled.label<{ labelWidth?: number }>`
  flex-shrink: 0;
  flex-basis: ${({ labelWidth = DEFAULT_LABEL_WIDTH }) => labelWidth}px;

  display: flex;
  align-items: center;

  min-height: 42px;
  font-weight: 500;
`

export const Input = styled.div`
  flex: 1;
`

const HelperText = styled.p`
  display: flex;
  align-items: center;

  margin-top: 16px;
`

export const Description = styled(HelperText)`
  color: ${({ theme }) => theme.colors.textSecondBlue};
`

export const Error = styled(HelperText)`
  color: ${({ theme }) => theme.colors.orangeyRed};
  font-weight: 500;
`
