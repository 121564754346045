import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

export const RadioButton = styled.div<{
  isActive?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  align-items: center;

  padding: 4px 8px;

  border-radius: 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.bgPrimaryGrey};
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brightBlue : theme.colors.textSecondBlue};

  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  transition: 0.25s;

  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brightBlue};

    /* icon */
    div {
      color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

export const RadioButtonWrapper = styled.div`
  flex-shrink: 0;
  display: flex;

  padding: 4px 6px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};

  ${RadioButton}:not(:first-of-type) {
    margin-left: 6px;
  }
`

export type ChartType = 'populationDistribution' | 'trends'

type Props = {
  chartType: ChartType
  onChartTypeChange: (chartType: ChartType) => void
}

const ChartTypeRadioButtons = ({ chartType, onChartTypeChange }: Props) => {
  const { t } = useTranslation(['tag'])

  return (
    <RadioButtonWrapper>
      <RadioButton
        isActive={chartType === 'populationDistribution'}
        onClick={() => onChartTypeChange('populationDistribution')}
      >
        <Box mr={0.5}>
          <Icon
            icon={ICON.analytics}
            color={
              chartType === 'populationDistribution'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
          />
        </Box>

        {t('tag:population_distribution')}
      </RadioButton>

      <RadioButton
        isActive={chartType === 'trends'}
        onClick={() => onChartTypeChange('trends')}
      >
        <Box mr={0.5}>
          <Icon
            icon={ICON.arrowGrowth}
            color={
              chartType === 'trends'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
          />
        </Box>

        {t('tag:trends')}
      </RadioButton>
    </RadioButtonWrapper>
  )
}

export default ChartTypeRadioButtons
