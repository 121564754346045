import { useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import { changeProjectIKAId } from '@entities/auth/model/slices'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'

import ActionIcons from './ActionIcons'
import DropdownList from './DropdownList'
import { AppBar, Toolbar } from './styles'

const Header = () => {
  const projectList = useAppSelector(state => state.auth.projectList)
  const projectIKAId = useAppSelector(state => state.auth.projectIKAId)

  const options = projectList.map(({ ikaId }) => ({
    label: ikaId,
    value: ikaId,
  }))
  const defaultOption = { label: projectIKAId, value: projectIKAId }

  const dispatch = useAppDispatch()

  const handleChangeProjectId = (projectIKAId: string) => {
    dispatch(changeProjectIKAId(projectIKAId))
  }

  const project = useMemo(
    () => projectList.find(x => x.ikaId === projectIKAId),
    [projectIKAId, projectList]
  )

  useEffect(() => {
    if (!project) {
      return
    }

    window.dataLayer.push({
      ikaId: project.ikaId,
      planType: project.planType,
    })
  }, [project])

  return (
    <AppBar position="sticky">
      <Toolbar>
        <Box>
          <DropdownList
            defaultOption={defaultOption}
            options={options}
            onValueChange={handleChangeProjectId}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ActionIcons />
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  )
}

export default Header
