import { ElementType } from 'react'
import type { SxProps, Theme } from '@mui/material'
import Box from '@mui/material/Box'

import type { IconClassName } from '@shared/model/constants/styles'
import type { ThemeColor } from '@theme'

import { ICON_FONT_SIZE_MAP, IconFontSize } from './models'

type UniIconProps = {
  icon: IconClassName
  color?: ThemeColor | 'inherit'
  fontSize?: IconFontSize
  id?: string
  opacity?: number
  component?: ElementType
  sx?: SxProps<Theme>
}

export const UniIcon = ({
  icon,
  color,
  fontSize: size = 'default',
  id = '',
  opacity,
  component,
  sx,
}: UniIconProps) => {
  if (component) {
    return (
      <Box
        id={id}
        sx={{
          color,
          opacity,
          fontSize: ICON_FONT_SIZE_MAP[size],
          ...sx,
        }}
        component={component}
      >
        <i className={icon} />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '1em',
        height: '1em',
        color: theme => color ?? theme.colors.black,
        fontSize: ICON_FONT_SIZE_MAP[size],
        opacity: typeof opacity === 'number' ? opacity : 1,
        transition: 'color 0.25s',
        ...sx,
      }}
      id={id}
    >
      <i className={icon} />
    </Box>
  )
}

export default UniIcon
