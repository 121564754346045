import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import MuiIconButton from '@mui/material/IconButton'

import Loader from '@entities/Loader'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { checkIsTitle } from '@shared/lib/utils/validation'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON, LAYOUT } from '@shared/model/constants/styles'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import {
  DeepBlueGradientButton,
  RedButton,
  SimpleButton,
} from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import { createRouteState } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import {
  createApplication,
  deleteApplication,
  fetchApplicationDetail,
  resetApplicationDetail,
} from './slice'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 880px;
`

const Row = styled.div<{
  justifyContent?:
    | 'flex-end'
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?:
    | 'flex-end'
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
`

const Input = styled(PrimaryGreyInput)`
  flex: 1;
  margin: 0;
  padding-left: 16px;
`

const Text = styled.div<{ isError?: boolean; marginRightRatio?: number }>`
  display: flex;
  align-items: center;

  margin-right: ${({ marginRightRatio }) => (marginRightRatio || 0) * 8}px;
  min-width: 80px;
  height: ${LAYOUT.audienceBuilder.heightOfRow}px;

  color: ${({ isError, theme }) =>
    isError ? theme.colors.chartRed : theme.colors.lightNavyBlue};
  font-size: 14px;
  font-weight: 500;
`

const HorizontalLine = styled.div`
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

const CancelButtonWrapper = styled.div`
  margin-right: 8px;
`

const IconButton = styled(MuiIconButton)`
  && {
    margin-left: 8px;

    :hover {
      color: ${({ theme }) => theme.colors.brightBlue};

      /* 複寫 Icon 顏色 */
      div {
        color: ${({ theme }) => theme.colors.brightBlue};
      }
    }
  }
`

const GridWrapper = styled.div`
  display: grid;
  grid-gap: 24px 64px;
  grid-template-columns: 3fr 4fr;
  grid-template-rows: repeat(2, auto-fit);
  grid-template-areas:
    'type clientId'
    'none clientSecret';

  margin-bottom: 48px;
`

const AreaType = styled.div`
  grid-area: type;
`

const AreaClientId = styled.div`
  grid-area: clientId;
`

const AreaClientSecret = styled.div`
  grid-area: clientSecret;
`

const ErrorMsgWrapper = styled.div``

const clientIdInput = 'application-client-id-input'
const clientSecretInput = 'application-client-secret-input'

const CopyIcon = ({ onClick }: { onClick: () => void }) => (
  <IconButton onClick={onClick}>
    <Icon
      icon={ICON.copy}
      fontSize="small"
      color={theme.colors.textSecondBlue}
    />
  </IconButton>
)

const routeStateCreator = ({ page = 0 }: { page?: number }) => ({
  page,
})

const Detail = ({ isCreateMode }: { isCreateMode?: boolean }) => {
  const { t } = useTranslation(['common', 'settings'])
  const { isLoading } = useAppSelector(state => state.apiHandler)
  const { isCreateFetching, currApplication } = useAppSelector(
    state => state.v1.settings.application
  )
  const { applicationId = '' } = useParams<{ applicationId: string }>()
  const { type, clientId, clientSecret, appId, name } = currApplication

  const [isOpenDialog, setIsOpenDialog] = useState(false)
  const [appName, setAppName] = useState(name)
  const [isShowHint, setIsShowHint] = useState(false)
  const history = useHistory()
  const { state: routeState = createRouteState({}) } = useLocation<{
    fromPage: number
  }>()

  const handleGoBackToOverview = () => {
    history.push(
      `/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}`,
      routeStateCreator({ page: routeState.fromPage })
    )
  }

  const dispatch = useAppDispatch()
  const handleCreateApplication = () => {
    dispatch(createApplication({ name: appName }))
  }

  const handleDeleteApplication = () => {
    if (appId) {
      dispatch(deleteApplication({ appId }))
    }
    setIsOpenDialog(false)
  }

  const handleDialogOpen = (e: MouseEvent<EventTarget>) => {
    e.stopPropagation()
    setIsOpenDialog(true)
  }

  const handleDialogClose = () => {
    setIsOpenDialog(false)
  }

  const handleCopy = (inputId: string) => () => {
    const input = document.querySelector(
      `#${inputId}`
    ) as HTMLInputElement | null

    if (input) {
      input.disabled = false
      input.focus()
      input.select()
      const successful = document.execCommand('copy')

      const whichText =
        inputId === clientIdInput ? 'Client ID' : 'Client Secret'
      const info = successful ? `${whichText} was copied.` : 'Try again.'

      alert(info)
      input.disabled = true
    }
  }

  useEffect(() => {
    const appId = getIntSafe(applicationId)

    if (!isCreateMode && appId) {
      dispatch(fetchApplicationDetail({ appId }))
    }

    return () => {
      dispatch(resetApplicationDetail())
    }
  }, [applicationId, dispatch, isCreateMode])

  useEffect(() => {
    // 進入 create 模式 name 為 falsy，進入 detail 模式，name 起初為 falsy，拿到 api response 後要更新
    if (name) {
      setAppName(name)
    }
  }, [name])

  return (
    <Wrapper>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}`}>
          {t('common:route.applications')}
        </Link>
        {isCreateMode && <Box>{t('common:create')}</Box>}
        {!isCreateMode && name && (
          <Tooltip title={name}>
            <Box>{name}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>

      {!isLoading && (
        <Card>
          <Row alignItems="flex-start">
            <Text>{t('common:name')}</Text>
            <ErrorMsgWrapper>
              <PrimaryGreyInput
                type="text"
                value={appName}
                onChange={e => {
                  const value = (e && e.target && e.target.value) || ''
                  const appName = value.slice(0, MAX_LIST_NAME_LENGTH)
                  setAppName(appName)
                  setIsShowHint(!checkIsTitle(appName))
                }}
                width={400}
                marginRightRatio={1}
                disabled={!isCreateMode}
                isError={isShowHint}
              />
              <Text isError>
                {isShowHint
                  ? t('settings:hint_name', {
                      words: MAX_LIST_NAME_LENGTH,
                    })
                  : ''}
              </Text>
            </ErrorMsgWrapper>
          </Row>

          <HorizontalLine />

          <GridWrapper>
            <AreaType>
              <Row>
                <Text>{t('common:type')}</Text>
                <Input
                  type="text"
                  value={type || '-'}
                  marginRightRatio={1}
                  disabled
                />
              </Row>
            </AreaType>
            <AreaClientId>
              <Row>
                <Text>Client ID</Text>
                <Input
                  id={clientIdInput}
                  type="text"
                  value={clientId || '-'}
                  marginRightRatio={1}
                  disabled
                />
                {!isCreateMode && (
                  <CopyIcon onClick={handleCopy(clientIdInput)} />
                )}
              </Row>
            </AreaClientId>
            <AreaClientSecret>
              <Row>
                <Text>Secret</Text>
                <Input
                  id={clientSecretInput}
                  type="text"
                  value={clientSecret || '-'}
                  marginRightRatio={1}
                  disabled
                />
                {!isCreateMode && (
                  <CopyIcon onClick={handleCopy(clientSecretInput)} />
                )}
              </Row>
            </AreaClientSecret>
          </GridWrapper>

          <Row justifyContent="flex-end">
            <CancelButtonWrapper>
              <SimpleButton onClick={handleGoBackToOverview}>
                {isCreateMode ? t('common:cancel') : t('common:back')}
              </SimpleButton>
            </CancelButtonWrapper>

            {isCreateMode ? (
              <DeepBlueGradientButton
                width={200}
                onClick={handleCreateApplication}
                disabled={!Boolean(appName)}
              >
                {t('common:create')}
              </DeepBlueGradientButton>
            ) : (
              <RedButton
                width={200}
                onClick={(e: MouseEvent<EventTarget>) => {
                  if (appId) {
                    handleDialogOpen(e)
                  } else {
                    console.log('no appId')
                  }
                }}
              >
                {t('common:delete')}
              </RedButton>
            )}
          </Row>
        </Card>
      )}

      <DeleteConfirmDialog
        isOpen={isOpenDialog}
        modalTitle={t('common:delete_header', { name })}
        onClose={handleDialogClose}
        onConfirm={handleDeleteApplication}
      />

      {isCreateFetching && <Loader isWhiteBG />}
    </Wrapper>
  )
}

export default Detail
