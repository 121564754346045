import type {
  BehaviorTagIngestion,
  CreateBehaviorTagIngestionParams,
} from '@shared/model/types/ingestion'

import { api, transformResponseCamelCaseKeys } from '../api'

const behaviorTag = api.injectEndpoints({
  endpoints: builder => ({
    createBehaviorTagIngestion: builder.mutation<
      void,
      CreateBehaviorTagIngestionParams
    >({
      query: params => ({
        url: `/behavior_tag/ingestion`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['BehaviorTagIngestion'],
    }),
    getBehaviorTagIngestion: builder.query<BehaviorTagIngestion, number>({
      query: id => ({
        url: `/behavior_tag/ingestion/${id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as BehaviorTagIngestion),
      providesTags: ['TagUserIngestion'],
    }),
    triggerBehaviorTagIngestion: builder.mutation<void, number>({
      query: id => ({
        url: `/behavior_tag/ingestion/${id}/trigger`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateBehaviorTagIngestionMutation,
  useGetBehaviorTagIngestionQuery,
  useLazyGetBehaviorTagIngestionQuery,
} = behaviorTag
