import { getIntSafe } from '@shared/lib/utils/number'

import api from './index'

interface ApplicationDataFromApi {
  id: number
  ika_id: string
  client_id: string
  client_secret: string
  name: string
  type: 'server'
  created_at: string
  creator: string
}

interface ApplicationData {
  appId: number
  ikaId: string
  clientId: string
  clientSecret: string
  name: string
  type: 'server'
  createdTime: string
  creator: string
}

const getApplicationDataFormat = (data: ApplicationDataFromApi) => ({
  appId: data.id,
  ikaId: data.ika_id,
  clientId: data.client_id,
  clientSecret: data.client_secret,
  name: data.name,
  type: data.type,
  createdTime: data.created_at,
  creator: data.creator,
})

export const getApplicationList = async (props: {
  rowsPerPage: number
  currPage: number
}) => {
  const { rowsPerPage, currPage } = props

  const res = await api.get<ApplicationDataFromApi[]>('/applications', {
    params: {
      page: currPage + 1,
      per_page: rowsPerPage,
    },
  })

  const data: ApplicationData[] = (res.data || []).map(data =>
    getApplicationDataFormat(data)
  )

  const totalCount = getIntSafe(res.headers.total)

  return { data, totalCount }
}

export const postCreateApplication = async (props: { name: string }) => {
  const { name } = props

  const res = await api.post<ApplicationDataFromApi>('/applications', {
    name,
    type: 'server', // 現在除了 server 沒有支援其他 type
  })

  const data = getApplicationDataFormat(res.data || {})

  return data
}

export const deleteApplication = async (props: { appId: number }) => {
  const { appId } = props

  const res = await api.delete<ApplicationDataFromApi>(`/applications/${appId}`)

  return res
}

export const getApplicationDetail = async (props: { appId: number }) => {
  const { appId } = props

  const res = await api.get<ApplicationDataFromApi>(`/applications/${appId}`)

  const data = getApplicationDataFormat(res.data || {})

  return data
}
