import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import SingleMeasureFilter, {
  MeasureFilterAction,
  SimpleMeasureFilter,
} from '@features/filters/SingleMeasureFilter'
import Card from '@shared/ui/Card'

import useHandleApiError from '../../hooks/useHandleApiError'

type Props = {
  measureFilterState: SimpleMeasureFilter
  onDispatch: React.Dispatch<MeasureFilterAction>
  onUpdate?: () => Promise<void>
  isEnableEdit?: boolean
  isDisabled?: boolean
  onCancel?: () => void
}

const TagSingleMeasure = ({
  isEnableEdit = false,
  measureFilterState,
  onDispatch,
  onUpdate,
  isDisabled = false,
  onCancel,
}: Props) => {
  const { t } = useTranslation(['tag', 'common'])
  // isEnableEdit= false 的時候表示在create tag, isEnableEdit= true 的時候表示在edit tag
  const [isEdit, setIsEdit] = useState<boolean>(!isEnableEdit)
  const [handleTagApiError] = useHandleApiError('behavior')

  const handleOnUpdate = async () => {
    if (!onUpdate || !isEnableEdit) {
      return
    }

    try {
      await onUpdate()
    } catch (e) {
      handleTagApiError(e)
    } finally {
      setIsEdit(false)
    }
  }

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel()
    }

    setIsEdit(false)
  }

  return (
    <Card mb={5}>
      <Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent={isEnableEdit ? 'space-between' : 'flex-start'}
          sx={theme => ({
            borderBottom: `1px solid ${theme.colors.black6}`,
          })}
        >
          <Box
            fontWeight={500}
            fontSize={14}
            lineHeight="20px"
            mb={2.5}
            pb={2.5}
            sx={theme => ({
              color: theme.colors.black,
            })}
          >
            {t('tag:behavior_tag_recommend_event_config')}
          </Box>
          {isEnableEdit && (
            <Box display="flex" fontSize={16} whiteSpace="nowrap">
              {isEdit ? (
                <>
                  <Button onClick={handleOnCancel} color="inherit">
                    {t('common:cancel')}
                  </Button>

                  <Button
                    onClick={handleOnUpdate}
                    color="primary"
                    type="submit"
                  >
                    {t('common:save')}
                  </Button>
                </>
              ) : (
                <Button onClick={() => setIsEdit(true)} color="primary">
                  {t('common:edit')}
                </Button>
              )}
            </Box>
          )}
        </Box>
        <SingleMeasureFilter
          state={measureFilterState}
          dispatch={onDispatch}
          isDisabled={isDisabled || !isEdit}
          isDisabledHappenOperator
          filterTableNames={['dimension_ga_eec_items']}
        />
      </Box>
    </Card>
  )
}

export default TagSingleMeasure
