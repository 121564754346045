import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  POLLING_INTERVAL,
  TAG_TYPE_INTERNAL_OFFLINE,
  TagProps,
  useDeleteTagMutation,
  usePostTagJobMutation,
  useTagGroupsQuery,
  useTagListQuery,
  useUpdateTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { createRouteState } from '@shared/lib/utils/routeUtils'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { SearchInput } from '@shared/ui/searchInput'
import { DataTable } from '@shared/ui/table'
import theme from '@theme'
import type { TagRouteSate } from '@widgets/tag/_shared/type'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'

import useFilter from './hook/useFilter'
import { ALL_RENDERING_STATUS } from '../_shared/constant'
import type { Filter } from '../_shared/type'
import useHandleApiError from '../../_shared/hooks/useHandleApiError'
import OptionsSelect from './OptionsSelect'
import useLocalTagColumns from './useLocalTagColumns'

type Props = {
  routeState: TagRouteSate
}

const Grading = ({ routeState: { page, perPage } }: Props) => {
  const { t } = useTranslation(['common', 'audience', 'tag'])
  const [handleTagApiError] = useHandleApiError('grading')

  const {
    pathname,
    state: routeState = createRouteState({
      page,
      perPage,
      filter: {
        searchText: '',
        status: [],
        updateMethod: [],
        createMethod: [],
      },
    }),
  } = useLocation<TagRouteSate<{ filter: Filter }>>()

  const history = useHistory<TagRouteSate<{ filter: Filter }>>()

  const [pollingInterval, setPollingInterval] = useState<number>()

  const { data = [], isLoading } = useTagListQuery(
    {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    {
      pollingInterval,
      selectFromResult: ({ data = [], isLoading }) => ({
        data: data.filter(({ type }) => type !== TAG_TYPE_INTERNAL_OFFLINE),
        isLoading,
      }),
    }
  )

  const { tagGroupMap, isLadingTagGroups } = useTagGroupsQuery(null, {
    selectFromResult: ({ data = [], isLoading }) => ({
      tagGroupMap: data.reduce(
        (acc, cur) => {
          acc[cur.id as number] = cur.title
          return acc
        },
        { 0: t('tag:no_group') } as TagGroupMapType
      ),

      isLadingTagGroups: isLoading,
    }),
  })

  const [updateTag, { isLoading: isUpdatingTag }] = useUpdateTagMutation()
  const [deleteTag, { isLoading: isDeletingTag }] = useDeleteTagMutation()
  const [postTagJob] = usePostTagJobMutation()

  const dispatch = useAppDispatch()

  const handleChangePage = (newPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: newPage + 1,
      })
    )
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: DEFAULT_PAGE,
        perPage: rowsPerPage,
      })
    )
  }

  const [openDropdownTagId, setOpenDropdownTagId] = useState<number>(-1)
  const [deleteConfirmTagId, setDeleteConfirmTagId] = useState<number>()
  const [renameTagId, setRenameTagId] = useState<number>()
  const [renameTagTitle, setRenameTagTitle] = useState<string>('')

  const filteredData = useFilter({ data, filter: routeState.filter })

  const errors = {
    renameText:
      renameTagTitle.length === 0
        ? t('common:errors.max_count', {
            count: MAX_LIST_NAME_LENGTH,
          })
        : '',
  }

  const isNeedPolling = data.filter((item: TagProps) => {
    return ALL_RENDERING_STATUS.includes(item.latestJobStatus)
  }).length

  useEffect(() => {
    if (isNeedPolling) {
      setPollingInterval(POLLING_INTERVAL)
      return
    }

    setPollingInterval(undefined)
  }, [isNeedPolling])

  const safeCloseAllPopup = () => {
    // 需要照順序關閉彈窗避免頁面跳動
    setRenameTagId(undefined)
    setDeleteConfirmTagId(undefined)
  }

  const handlePostJob = useCallback(
    (id: number) => {
      postTagJob({ id: String(id) })
        .then(() => {
          dispatch(
            openToast({
              message: t('tag:updating'),
              status: 'success',
            })
          )
        })
        .catch(() => {
          openToast({
            message: t('tag:update_failed'),
            status: 'error',
          })
        })
    },
    [dispatch, postTagJob, t]
  )

  const columns = useLocalTagColumns({
    openDropdownTagId,
    tagGroupMap,
    setOpenDropdownTagId,
    setRenameTagId,
    setRenameTagTitle,
    setDeleteConfirmTagId,
    handlePostJob,
  })

  const filteredDataByPage = filteredData.items.slice(
    (routeState.page - 1) * routeState.perPage,
    Math.min(filteredData.items.length, routeState.page * routeState.perPage)
  )

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Box display="flex" alignItems="center">
          <OptionsSelect
            selectedOptions={routeState.filter}
            onChange={result => {
              history.push(
                pathname,
                createRouteState({
                  ...routeState,
                  page: DEFAULT_PAGE,
                  filter: {
                    ...routeState.filter,
                    ...result,
                  },
                })
              )
            }}
          />
        </Box>

        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            mb="16px"
            sx={{
              boxShadow: `0px 4px 9px ${theme.colors.black6}`,
            }}
          >
            <SearchInput
              key={routeState.filter?.searchText}
              defaultValue={routeState.filter?.searchText}
              placeholder={t('common:search')}
              onSearch={searchWord => {
                history.push(
                  pathname,
                  createRouteState({
                    ...routeState,
                    page: DEFAULT_PAGE,
                    filter: {
                      ...routeState.filter,
                      searchText: searchWord,
                    },
                  })
                )
              }}
            />
          </Box>
        </Box>
      </Box>

      <DataTable
        columns={columns}
        rows={filteredDataByPage}
        count={filteredData.totalCount}
        page={routeState.page - 1}
        rowsPerPage={routeState.perPage}
        rowIdKey="id"
        isRowClickable
        isLoading={isLoading || isLadingTagGroups}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteConfirmDialog
        isOpen={deleteConfirmTagId !== undefined}
        isLoading={isDeletingTag}
        modalTitle={t('tag:sure_want_to_delete_this_tag')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (deleteConfirmTagId) {
            deleteTag(+deleteConfirmTagId)
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                handleTagApiError(error)
                dispatch(handleApiError(formatRTKQueryError(error)))
              })
          }
        }}
      />

      <ConfirmDialog
        isLoading={isUpdatingTag}
        isOpen={Boolean(renameTagId)}
        maxWidth="xs"
        modalTitle={t('tag:new_tag_name')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (renameTagId && renameTagTitle) {
            updateTag({
              id: +renameTagId,
              title: renameTagTitle,
            })
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                const formatError = formatRTKQueryError(error)
                handleTagApiError(error)

                dispatch(handleApiError(formatError))
              })
          }
        }}
      >
        <TextInput
          fullWidth
          placeholder={t('tag:please_enter_new_tag_name')}
          error={Boolean(errors.renameText)}
          onChange={({ target }) => {
            const newTagTitle = target.value.substring(0, MAX_LIST_NAME_LENGTH)
            setRenameTagTitle(newTagTitle)
          }}
          value={renameTagTitle}
        />
        <TextInputErrorText>
          {errors.renameText && <span>{errors.renameText}</span>}
        </TextInputErrorText>
      </ConfirmDialog>
    </>
  )
}

export default Grading
