import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import RoundedSearchBar from '@shared/ui/Dropdown/RoundedSearchBar'
import HighlightText from '@shared/ui/HighlightText'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { Placement } from '@shared/ui/Popup'
import theme, { ThemeColor } from '@theme'

import { MENU_LIST_MAX_HEIGHT } from './constants'
import {
  Container,
  IconContainer,
  SearchBarContainer,
  TablePagination,
} from './styles'
import type { NestedOption } from './types'
import { getLabelFromNestedOptions } from './utils'

type Props<T extends string | number = string> = {
  isFullWidth?: boolean
  anchorElem?: JSX.Element
  anchorId?: string
  bgColor?: ThemeColor
  hasSearchBar?: boolean
  isDisabled?: boolean
  isError?: boolean
  label?: string
  onChange: (payload: { parentValue?: T; value: T }) => void
  onFilterChange?: (filterText: string) => void
  options: NestedOption<T>[]
  parentValue?: T
  popperClassName?: string
  popperDisablePortal?: boolean
  popperMaxHeight?: number
  popperWidth?: number
  popperZIndex?: number
  value?: T
  isShowPagination?: boolean
  paginationProps?: {
    totalCount: number
    page: number
    rowsPerPage: number
    onPageChange: (event: unknown, newPage: number) => void
  }
  placement?: Placement
}

const NestedList = <T extends string | number>({
  isFullWidth = false,
  anchorElem,
  anchorId,
  bgColor,
  hasSearchBar,
  isDisabled,
  isError,
  label,
  onChange,
  onFilterChange,
  options,
  parentValue,
  popperClassName,
  popperDisablePortal,
  popperMaxHeight = MENU_LIST_MAX_HEIGHT,
  popperWidth,
  popperZIndex,
  value,
  isShowPagination = false,
  paginationProps,
  placement = 'bottom-start',
}: Props<T>) => {
  const { t } = useTranslation(['common'])

  const [isOpen, setIsOpen] = useState(false)
  const [nestedOpenId, setNestedOpenId] = useState<string | number>()
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    if (isOpen) {
      return
    }

    // 關閉選單 (isOpen === false) 時設定 cleanup 函式
    // 當再次開啟選單時會先執行先前的 cleanup 函式，故不會看到上次打的搜尋文字
    return () => {
      setFilterText('')
    }
  }, [isOpen])

  const currLabel =
    getLabelFromNestedOptions(options, value, parentValue) ||
    label ||
    t('common:dropdownlist_default')

  return (
    <Dropdown
      isFullWidth={isFullWidth}
      anchorElem={anchorElem}
      anchorId={anchorId}
      bgColor={bgColor}
      isDisabled={isDisabled}
      isError={isError}
      isOpen={isOpen}
      label={currLabel}
      popperClassName={popperClassName}
      popperDisablePortal={popperDisablePortal}
      popperZIndex={popperZIndex}
      setIsOpen={setIsOpen}
      placement={placement}
    >
      <Container width={popperWidth}>
        {hasSearchBar && (
          <SearchBarContainer>
            <RoundedSearchBar
              value={filterText}
              placeholder={t('common:search')}
              autoFocus
              onChange={filterText => {
                setFilterText(filterText)
                onFilterChange?.(filterText)
              }}
            />
          </SearchBarContainer>
        )}
        <MenuList disablePadding maxHeight={popperMaxHeight}>
          {options
            .filter(parent => parent.label.includes(filterText))
            .map((parent, idx) => {
              if (parent.options && parent.options.length > 0) {
                return (
                  <li key={`${parent.value}-${idx}`}>
                    <ul>
                      <Dropdown
                        isOpen={nestedOpenId === parent.value}
                        setIsOpen={() => {
                          setNestedOpenId(undefined)
                        }}
                        isFullWidth
                        popperZIndex={popperZIndex}
                        popperClassName={popperClassName}
                        popperDisablePortal={popperDisablePortal}
                        anchorElem={
                          <MenuItem
                            key={parent.value}
                            onMouseEnter={() => {
                              setNestedOpenId(parent.value)
                            }}
                            onClick={event => {
                              event.stopPropagation()
                            }}
                          >
                            <HighlightText
                              text={parent.label}
                              target={filterText}
                            />
                            <IconContainer>
                              <Icon
                                id="icon-arrow-right"
                                icon={ICON.arrowRight}
                                color={theme.colors.textPrimaryBlue}
                              />
                            </IconContainer>
                          </MenuItem>
                        }
                        placement="right-start"
                      >
                        {/* 第二階層選單 */}
                        <Container>
                          <MenuList>
                            {parent.options.map(child => (
                              <MenuItem
                                key={child.value as string}
                                onClick={() => {
                                  onChange({
                                    parentValue: parent.value,
                                    value: child.value,
                                  })
                                  setIsOpen(false)
                                  setNestedOpenId(undefined)
                                }}
                              >
                                {child.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Container>
                      </Dropdown>
                    </ul>
                  </li>
                )
              }

              return (
                /* 第一階層選單 */
                <MenuItem
                  disabled={parent.disabled}
                  key={`${parent.value}-${idx}`}
                  onClick={() => {
                    if (parent.disabled) {
                      return
                    }

                    onChange({
                      value: parent.value,
                    })
                    setIsOpen(false)
                  }}
                  onMouseEnter={() => {
                    setNestedOpenId(undefined)
                  }}
                >
                  <HighlightText text={parent.label} target={filterText} />
                </MenuItem>
              )
            })}
        </MenuList>
      </Container>
      {isShowPagination && paginationProps && (
        <TablePagination
          count={paginationProps.totalCount}
          rowsPerPageOptions={[paginationProps.rowsPerPage]}
          rowsPerPage={paginationProps.rowsPerPage}
          page={paginationProps.page}
          onPageChange={paginationProps.onPageChange}
        />
      )}
    </Dropdown>
  )
}

export default NestedList
