import Box from '@mui/material/Box'

import theme from '@theme'

export const CharacterIcon = ({ character }: { character: string }) => {
  return (
    <Box
      width="40px"
      height="40px"
      borderRadius="10px"
      fontSize="20px"
      lineHeight="40px"
      textAlign="center"
      flexShrink={0}
      fontWeight={500}
      border={`1px solid ${theme.colors.lightNavyBlue}`}
      color={theme.colors.lightNavyBlue}
    >
      {character}
    </Box>
  )
}

export default CharacterIcon
