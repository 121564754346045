import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import get from 'lodash/get'
import { useImmerReducer } from 'use-immer'

import {
  NotificationRule,
  NotificationRuleFormValues,
  UPDATE_HOUR,
} from '@shared/api/rtkQuery'
import { ConfirmDialog } from '@shared/ui/dialogs'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import SectionTitle from '@shared/ui/SectionTitle'
import {
  createInitialEventRuleNode,
  eventRuleFilterReducer,
  initializer,
} from '@widgets/analytics/event/_shared/models/eventRuleFilterReducer'

import NotificationCondition from './form/NotificationCondition'
import NotificationRuleHeader from './form/NotificationRuleHeader'
import NotificationSetting from './form/NotificationSetting'
import { daysBefores, operators, periods } from '../constant'

type Props = {
  id: number
  isCreateMode?: boolean
  isCreating: boolean
  formValues?: NotificationRule
  onSubmit: (result: NotificationRuleFormValues) => void
}

const DEFAULT_VALUE = 0

const NotificationRuleForm = ({
  id,
  isCreateMode = false,
  isCreating,
  formValues,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['analytics', 'common'])

  const periodOptions = useMemo(() => {
    return periods.map(ele => {
      return {
        label: t(`analytics:notificationRule.periodOptions.${ele}`),
        value: ele,
      }
    })
  }, [t])

  const [eventFilter, eventFilterDispatch] = useImmerReducer(
    eventRuleFilterReducer,
    createInitialEventRuleNode(),
    initializer
  )

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState<boolean>(false)

  const initialValues = useMemo(
    () => ({
      name: '',
      isPublic: !formValues?.users || formValues?.users.length === 0, // users 為空的時候，isPublic 為 true
      userIds: formValues?.users?.map(user => user.id) || [],

      notificationTypes: {
        byEmail: false,
        byPortal: true,
        ...formValues?.notificationTypes,
      },
      rule: {
        triggerTime: {
          hour: UPDATE_HOUR,
          periodDays: 1,
          ...formValues?.rule.triggerTime,
        },
        condition: {
          operator: operators[0],
          daysBefore: +daysBefores[0],
          value: DEFAULT_VALUE,
          ...formValues?.rule.condition,
        },
      },
      ...formValues,
    }),
    [formValues]
  )

  const notificationForm = useFormik<NotificationRuleFormValues>({
    initialValues,
    validate: values => {
      const errors: Partial<Record<keyof NotificationRuleFormValues, string>> =
        {}

      if (!values.name) {
        errors.name = t('analytics:notificationRule.errors.no_name_error')
      }

      if (!values.filter?.statisticMeasures[0].eventName) {
        errors.filter = t(
          'analytics:notificationRule.select_notification_condition'
        )
      }

      return errors
    },

    onSubmit: values => onSubmit(values),
  })

  useEffect(() => {
    if (initialValues) {
      eventFilterDispatch({
        type: 'reset',
        payload: initialValues.filter,
      })

      notificationForm.setFieldTouched('filter', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, eventFilterDispatch])

  useEffect(
    () => {
      notificationForm.setFieldValue('filter', eventFilter)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventFilter]
  )

  const handleReset = (field: string) => {
    const initialFormValues = get(initialValues, field)

    notificationForm.setFieldValue(field, initialFormValues)
    notificationForm.setFieldTouched(field, false)

    // filter 需要 reset eventAnalyticsFilter
    if (field === 'filter') {
      eventFilterDispatch({
        type: 'reset',
        payload: initialFormValues,
      })
    }
  }

  const handleResetForm = () => {
    notificationForm.resetForm()

    eventFilterDispatch({
      type: 'reset',
      payload: createInitialEventRuleNode(),
    })

    notificationForm.setFieldTouched('filter', true)
  }

  return (
    <>
      <NotificationRuleHeader
        id={id}
        onFieldReset={handleReset}
        onFormReset={handleResetForm}
        onFieldChange={notificationForm.setFieldValue}
        name={notificationForm.values.name}
        isCreateMode={isCreateMode}
        errors={notificationForm.errors as Record<string, string>}
        onShowConfirmDialog={() => setIsSaveDialogOpen(true)}
      />

      <NotificationCondition
        id={id}
        reset={handleReset}
        onFieldChange={notificationForm.setFieldValue}
        isCreateMode={isCreateMode}
        eventFilter={eventFilter}
        eventFilterDispatch={eventFilterDispatch}
        condition={notificationForm.values.rule.condition}
      />

      <SectionTitle
        title={t('analytics:notificationRule.notification_setting')}
      />
      <NotificationSetting
        reset={handleReset}
        onFieldChange={notificationForm.setFieldValue}
        id={id}
        isCreateMode={isCreateMode}
        periodOptions={periodOptions}
        triggerTime={notificationForm.values.rule.triggerTime}
        isPublic={notificationForm.values.isPublic}
        userIds={notificationForm.values.userIds}
        notificationTypes={notificationForm.values.notificationTypes}
      />
      <ConfirmDialog
        isLoading={isCreating}
        isOpen={isSaveDialogOpen}
        modalTitle={t('analytics:notificationRule.save_notification_rule')}
        onClose={() => {
          setIsSaveDialogOpen(false)
        }}
        onConfirm={notificationForm.submitForm}
      >
        <TextInput
          fullWidth
          onChange={({ target }) =>
            notificationForm.setFieldValue('name', target.value)
          }
          placeholder={t(
            'analytics:notificationRule.please_enter_notification_rule_name'
          )}
          value={notificationForm.values.name}
        />
        <TextInputErrorText>
          {notificationForm.touched.name && notificationForm.errors.name}
        </TextInputErrorText>
      </ConfirmDialog>
    </>
  )
}

export default NotificationRuleForm
