import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import BaseChip from '@mui/material/Chip'

import { Tooltip } from '@shared/ui/tooltips'
export const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: 0.7px solid ${({ theme }) => theme.colors.lightGreyBlueOne};
`

type TabItemProps = {
  isSelected: boolean
}

export const TabItem = styled(
  ({ isSelected, ...otherProps }: TabItemProps & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} />
  )
)<{ isSelected?: boolean }>`
  && {
    margin-right: 8px;
    padding: 16px 24px;

    border-radius: 3px;

    color: ${({ theme }) => theme.colors.brownishGrey};
    font-size: 16px;
    font-family: inherit;
    font-weight: 500;
    text-transform: none;
    letter-spacing: normal;
    line-height: 1.5;
    cursor: pointer;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    transition: 0.2s;

    :hover {
      border-bottom: 3px solid ${({ theme }) => theme.colors.brightBlue};
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.brightBlue};
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.05);
    }

    ${({ isSelected, theme }) =>
      isSelected &&
      `
        color: ${theme.colors.brightBlue};
        background-color: ${theme.colors.white};
        box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.05);
        border-bottom: 3px solid ${theme.colors.brightBlue};
    `}
  }
`

const Chip = styled(BaseChip)`
  &.MuiChip-root {
    padding: 16px 20px;
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
    font-size: 14px;
    font-weight: 500;
  }
  &.MuiChip-colorPrimary {
    color: ${({ theme }) => theme.colors.white};
  }
`

export type TabOptions<T> = Array<{
  type: T
  name: string
  info?: string
}>

interface Props<T extends {}> {
  currTab: T
  tabOptions: TabOptions<T>
  onTabChange: (tab: T) => void
  tabStyle?: 'brick' | 'chip'
}

const Tab = <T extends {}>({
  tabOptions,
  currTab,
  onTabChange,
  tabStyle = 'brick',
}: Props<T>) => {
  if (tabStyle === 'brick') {
    return (
      <TabWrapper>
        {tabOptions.map(({ type, name, info }, idx) => (
          <Tooltip title={info ?? ''} key={`${type}_${name}_${idx}`}>
            <div>
              <TabItem
                isSelected={type === currTab}
                onClick={() => {
                  onTabChange(type)
                }}
              >
                {name}
              </TabItem>
            </div>
          </Tooltip>
        ))}
      </TabWrapper>
    )
  } else if (tabStyle === 'chip') {
    return (
      <Box display="flex" mb={1} mt={-2}>
        {tabOptions.map(({ type, name }, idx) => (
          <Box mr={1} key={`${type}_${name}_${idx}`}>
            <Chip
              color={type === currTab ? 'primary' : 'default'}
              onClick={() => {
                onTabChange(type)
              }}
              label={name}
            />
          </Box>
        ))}
      </Box>
    )
  }
  return null
}

export default Tab
