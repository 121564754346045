import curry from 'lodash/curry'

import type { UserJourneyEvent } from '@shared/api/rtkQuery'
import type { Entity } from '@shared/model/types/common'

type UserJourneyGroupByDateEntity = Entity<{
  events: {
    createdAt: string
    name: string
    propertyRecord: Record<string, unknown>
  }[]
}>

export const groupUserJourneyEventsByDate = curry(
  (
    locales: 'zh-TW',
    events: UserJourneyEvent['events']
  ): UserJourneyGroupByDateEntity => {
    const result = events.reduce(
      (acc, event) => {
        const groupName = new Date(event.createdAt).toLocaleDateString(locales)

        if (!acc.entities[groupName]) {
          acc.ids.push(groupName)
          acc.entities[groupName] = { events: [] }
        }

        acc.entities[groupName].events.push({
          createdAt: event.createdAt,
          name: event.name,
          propertyRecord: event.columns.reduce((record, property) => {
            record[`${property.tableName}.${property.name}`] = property.value
            return record
          }, {} as Record<string, unknown>),
        })

        return acc
      },
      { ids: [], entities: {} } as UserJourneyGroupByDateEntity
    )

    return result
  }
)
