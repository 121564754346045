import type {
  MediumType,
  ReplacementDataTracingURL,
  WorkflowListItem,
} from '@shared/api/rtkQuery'

const getTriggerGroupOptions = ({
  data,
  sentConfigs = [],
  mediumType,
}: {
  data: WorkflowListItem[]
  sentConfigs: string[]
  mediumType: MediumType
}) => {
  return data
    .filter(item =>
      item.campaigns?.some(campaign => campaign.mediumType === mediumType)
    )
    .map(item => ({
      campaigns: item.campaigns
        ?.filter(
          campaign =>
            campaign.mediumType === mediumType &&
            sentConfigs.some(config => Boolean(campaign.sentConfig[config]))
        )
        .map(campaign => ({
          value: campaign.id,
          label: campaign.title,
          tracingUrls: Object.values(campaign?.replacementData || {})
            .filter(item => item.type === 'tracing_url')
            .map(tracingUrl => ({
              label: (tracingUrl as ReplacementDataTracingURL).urlParams.name,
              value: (tracingUrl as ReplacementDataTracingURL).urlParams
                .tracingUrlId,
            })),
        })),
      label: item.name,
      value: item.id,
    }))
}

export default getTriggerGroupOptions
