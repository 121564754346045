import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import {
  AggregatorFilter,
  AUDIENCE_FILTER_EVENT_MAX_COUNT,
} from '@features/filters/_shared'
import { CombinationButton } from '@features/filters/_shared'
import type {
  CampaignMeasureFilterNode,
  NotHappenedOperator,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import DateRangePicker from '@shared/ui/DateRangePicker'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import CampaignMediumGroupFilter from './CampaignMediumGroupFilter'
import CampaignTriggerGroupFilter from './CampaignTriggerGroupFilter'
import { CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT } from './constants'
import type { MeasureFilterAction } from './measureFilterReducer'
import {
  getSafeMediumGroupParam,
  getSafeTriggerGroupParam,
  sortTheCampaignAttributeFilter,
} from './utils'

type Props = {
  state: CampaignMeasureFilterNode
  isCloneDisabled: boolean
  isDisabled: boolean
  dispatch: (action: MeasureFilterAction) => void
  onDelete: () => void
  onClone: () => void
}

const MeasureFilter = ({
  state: { measure, attributeFilter, eventOperator, timeRangeParams, params },
  isCloneDisabled,
  isDisabled,
  dispatch,
  onDelete: handleDelete,
  onClone,
}: Props) => {
  const { t, i18n } = useTranslation(['audience', 'metadata', 'common'])

  const [
    mediumGroupNodes,
    triggerGroupNodes = { type: 'group', nodes: [], combination: 'or' },
  ] = sortTheCampaignAttributeFilter(attributeFilter.nodes)

  const happenOperatorOptions: {
    label: string
    value: '' | NotHappenedOperator
  }[] = [
    {
      label: t('metadata:operator.happened'),
      value: '',
    },
    {
      label: t('metadata:operator.not_happened'),
      value: 'not_happened',
    },
  ]

  // 此處將 `event_operator` 轉成 `happenOperator` 與 `aggregatorOperator`
  // happenOperator => 發生 or 未發生
  // aggregatorOperator => 繼續往下傳給 AggregatorFilter
  const [happenOperator, aggregatorOperator] =
    eventOperator === 'not_happened'
      ? ([eventOperator, ''] as const)
      : (['', eventOperator] as const)

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Box mr={2}>{t('audience:v3.time_range')}</Box>
        <Box mr={1.5}>
          <DateRangePicker
            values={timeRangeParams}
            isDisabled={isDisabled}
            popperDisablePortal
            isFutureEnable={true}
            onChange={values => {
              dispatch({
                type: 'updateTimeRangeParams',
                payload: values,
              })
            }}
          />
        </Box>

        {!isDisabled && (
          <>
            <Tooltip
              title={
                isCloneDisabled
                  ? t('audience:v3.filter_max_hint_event', {
                      max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
                    })
                  : t('audience:v3.filter_duplicate')
              }
            >
              <span>
                <IconButton disabled={isCloneDisabled} onClick={onClone}>
                  <Icon icon={ICON.copy} color={theme.colors.textPrimaryBlue} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t('audience:v3.filter_delete')}>
              <IconButton onClick={handleDelete}>
                <Icon icon={ICON.times} color={theme.colors.black} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>

      <Box display="flex" alignItems="center" mb={1} gap={1}>
        <Box>
          <DropdownList
            key={i18n.language}
            bgColor={theme.colors.white}
            options={happenOperatorOptions}
            value={happenOperator}
            disabled={isDisabled}
            popperDisablePortal
            onValueChanged={({ value }) => {
              dispatch({
                type: 'updateCampaignOperator',
                payload: value as NotHappenedOperator,
              })
            }}
          />
        </Box>

        <CampaignMediumGroupFilter
          isDisabled={isDisabled}
          isDisabledAddTriggerFilterNode={
            triggerGroupNodes.nodes.length >=
            CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT
          }
          mediumType={getSafeMediumGroupParam({
            data: mediumGroupNodes,
            field: 'medium_type',
            defaultValue: 'email',
          })}
          mediumEvent={getSafeMediumGroupParam({
            data: mediumGroupNodes,
            field: 'action',
            defaultValue: 'sent',
          })}
          onMediumTypeChange={value => {
            dispatch({
              type: 'updateMediumType',
              payload: value,
            })
          }}
          onMediumEventChange={value => {
            dispatch({
              type: 'updateMediumEvent',
              payload: value,
            })
          }}
          onAddTriggerFilterNode={() => {
            dispatch({
              type: 'addTriggerGroupFilter',
            })
          }}
        />
      </Box>

      {triggerGroupNodes.nodes.length > 0 && (
        <Box display="flex" alignItems="center" mb={1}>
          <Box mr={1}>
            {isDisabled ? (
              <CombinationButton disabled={isDisabled}>
                {triggerGroupNodes.combination === 'and'
                  ? t('audience:v3.and')
                  : t('audience:v3.or')}
              </CombinationButton>
            ) : (
              <Tooltip title={t('audience:v3.filter_toggle')}>
                <CombinationButton
                  onClick={() => {
                    dispatch({
                      type: 'toggleTriggerGroupFilterCombination',
                    })
                  }}
                >
                  {triggerGroupNodes.combination === 'and'
                    ? t('audience:v3.and')
                    : t('audience:v3.or')}
                </CombinationButton>
              </Tooltip>
            )}
          </Box>

          <Box
            sx={{
              padding: 0,
              paddingLeft: 3,
              borderLeft: theme => `5px solid ${theme.colors.brightSkyBlue}`,
            }}
          >
            <ul>
              {triggerGroupNodes.nodes.map((filter, index) => (
                <Box component="li" key={index} display="flex" gap={1}>
                  <Box display="flex" alignItems="center" my={1}>
                    <CampaignTriggerGroupFilter
                      isDisabled={isDisabled}
                      isDisableCloneOrDelete={
                        triggerGroupNodes.nodes.length >=
                        CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT
                      }
                      mediumType={getSafeMediumGroupParam({
                        data: mediumGroupNodes,
                        field: 'medium_type',
                        defaultValue: 'email',
                      })}
                      mediumEvent={getSafeMediumGroupParam({
                        data: mediumGroupNodes,
                        field: 'action',
                        defaultValue: 'sent',
                      })}
                      workflowId={getSafeTriggerGroupParam(
                        filter.nodes,
                        'workflow'
                      )}
                      campaignId={getSafeTriggerGroupParam(
                        filter.nodes,
                        'campaign'
                      )}
                      tracingUrlId={getSafeTriggerGroupParam(
                        filter.nodes,
                        'tracingUrl'
                      )}
                      onWorkflowEventChange={value => {
                        dispatch({
                          type: 'updateWorkflowFilter',
                          payload: {
                            value,
                            index,
                          },
                        })
                      }}
                      onCampaignChange={value => {
                        dispatch({
                          type: 'updateCampaignFilter',
                          payload: {
                            value,
                            index,
                          },
                        })
                      }}
                      onTracingUrlChange={value => {
                        dispatch({
                          type: 'updateTracingUrlFilter',
                          payload: {
                            value,
                            index,
                          },
                        })
                      }}
                      onCloneTriggerGroupFilter={() => {
                        dispatch({
                          type: 'cloneTriggerGroupFilter',
                          payload: index,
                        })
                      }}
                      onDeleteTriggerGroupFilter={() => {
                        dispatch({
                          type: 'removeTriggerGroupFilter',
                          payload: index,
                        })
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </ul>
          </Box>
        </Box>
      )}

      {/** 發生門檻 */}
      {measure !== undefined && params !== undefined && (
        <AggregatorFilter
          field={measure.field}
          aggregator={measure.aggregator}
          operator={aggregatorOperator}
          values={params}
          eventName={''}
          isDisabled={isDisabled}
          onAggregatorChange={({ field, aggregator }) => {
            dispatch({
              type: 'updateMeasure',
              payload: { aggregator, field },
            })
          }}
          onOperatorChange={currOperator => {
            dispatch({ type: 'updateCampaignOperator', payload: currOperator })
          }}
          onValuesChange={currValues => {
            dispatch({
              type: 'updateParams',
              payload: currValues,
            })
          }}
        />
      )}
    </>
  )
}

export default MeasureFilter
