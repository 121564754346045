import { useEffect, useRef, useState } from 'react'

import ChildrenWrapper from './ChildrenWrapper'
import Tooltip from './Tooltip'
import type { Placement } from './types'
import { isOverflowX } from './utils'

/* 
  使用情境：只有在顯示字超出外層元素 發生 overflow 出現 ellipsis(...)時，才顯示 Tooltip

  方法：先用 最下面的 element 判斷是否 overflow，如果有，再替換成有 Tooltip 包覆的 children
  
  會用到 TooltipWhenOverflowX 一定是本身有實作 style overflow:hidden 的元件(<Child>)

  <TooltipWhenOverflowX title={text}>
    <Child>
      {text}
    </Child>
  </TooltipWhenOverflowX>

  因此在 ChildrenWrapper 的 type 先按照 https://stackoverflow.com/a/42261933 說的

    > If you're sure that child is always a ReactElement then cast it
*/

type TooltipWhenOverflowXProps = {
  title: string
  children: JSX.Element | null
  placement?: Placement
}

export const TooltipWhenOverflowX = ({
  children,
  title,
  placement = 'top',
}: TooltipWhenOverflowXProps) => {
  const ref = useRef<HTMLElement | null>(null)
  const [isShowTooltip, setIsShowTooltip] = useState(false)

  useEffect(() => {
    if (!ref.current) {
      return
    }
    setIsShowTooltip(isOverflowX(ref.current))
  }, [children])

  if (!children) {
    return null
  }

  if (isShowTooltip) {
    return (
      <Tooltip title={title} placement={placement}>
        {children}
      </Tooltip>
    )
  }

  return <ChildrenWrapper ref={ref}>{children}</ChildrenWrapper>
}
