import styled from '@emotion/styled'
import { spacing } from '@mui/system'

export const Text = styled.div`
  grid-area: label;
  margin-right: 24px;
  width: 100px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
  font-weight: 500;
`

export const InputAreaGrid = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-areas: 'label input';
  align-items: center;
  justify-items: flex-start;
  width: 100%;
  ${spacing}
`

export const LabelArea = styled.div`
  grid-area: label;
  ${spacing}
`

export const InputArea = styled.div`
  display: flex;
  grid-area: input;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${spacing}
`
