import { useEffect, useState } from 'react'

import validateAudienceListName from './validateAudienceListName'

const useValidateAudienceListName = (value: string) => {
  const [validation, setValidation] = useState({
    valid: false,
    message: '',
  })

  useEffect(() => {
    validateAudienceListName(value).then(setValidation)
  }, [value])

  return {
    audienceListNameValidation: validation,
  }
}

export default useValidateAudienceListName
