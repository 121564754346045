// Utm的內容是utm node, 所以不另外新增node/component
// 操作replacement_data的部分, 會透過command的方式, 由外部傳入的onReplacementDataChange, onReplacementDataDelete來處理

import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getNodeByKey,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
  NodeKey,
} from 'lexical'

import {
  ReplacementData,
  ReplacementDataActionURL,
  ReplacementDataTracingURL,
} from '@shared/api/rtkQuery'

import { $createUtmNode, $isUtmNode, UtmNode } from './nodes/UtmNode'

type InsertUtmCommand = {
  replacementKey: string
  replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
}

type UpdateUtmCommand = {
  nodeKey: NodeKey
  replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
}

type DeleteUtmCommand = {
  nodeKey: string
  replacementKey: string
}

type UpdateUtmReplacementDataCommand = {
  replacementKey: string
  replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
}

export const INSERT_UTM_COMMAND: LexicalCommand<InsertUtmCommand> =
  createCommand('INSERT_UTM_COMMAND')

export const UPDATE_UTM_COMMAND: LexicalCommand<UpdateUtmCommand> =
  createCommand('UPDATE_UTM_COMMAND')

export const DELETE_UTM_COMMAND: LexicalCommand<DeleteUtmCommand> =
  createCommand('DELETE_UTM_COMMAND')

export const UPDATE_UTM_REPLACEMENT_DATA_COMMAND: LexicalCommand<UpdateUtmReplacementDataCommand> =
  createCommand('UPDATE_UTM_REPLACEMENT_DATA_COMMAND')

type Props = {
  replacementData?: ReplacementData
  onReplacementDataChange: (replacementData: ReplacementData) => void
}

const UtmPlugin = ({
  replacementData,
  onReplacementDataChange,
}: Props): null => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([UtmNode])) {
      throw new Error(
        'UtmPlugin: UtmNode not registered on editor (initialConfig.nodes)'
      )
    }

    return mergeRegister(
      editor.registerCommand<InsertUtmCommand>(
        INSERT_UTM_COMMAND,
        payload => {
          const node = $createUtmNode(
            payload.replacementKey,
            payload.replacementData
          )
          $insertNodes([node])

          // 如果是parent是root, 就包一層p, 游標會自動移到p的最後面
          if ($isRootOrShadowRoot(node.getParentOrThrow())) {
            $wrapNodeInElement(node, $createParagraphNode).selectEnd()
          }

          onReplacementDataChange({
            ...replacementData,
            [payload.replacementKey]: payload.replacementData,
          })

          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<UpdateUtmCommand>(
        UPDATE_UTM_COMMAND,
        payload => {
          const node = $getNodeByKey(payload.nodeKey)

          if (node && $isUtmNode(node)) {
            node.setUtmReplacementData(payload.replacementData)
          }

          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<DeleteUtmCommand>(
        DELETE_UTM_COMMAND,
        payload => {
          const node = $getNodeByKey(payload.nodeKey)

          if (node && $isUtmNode(node) && replacementData) {
            const cloneReplacementData = { ...replacementData }

            node.remove()

            delete cloneReplacementData[payload.replacementKey]

            onReplacementDataChange(cloneReplacementData)
          }

          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<UpdateUtmReplacementDataCommand>(
        UPDATE_UTM_REPLACEMENT_DATA_COMMAND,
        payload => {
          onReplacementDataChange({
            ...replacementData,
            [payload.replacementKey]: payload.replacementData,
          })

          return true
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, onReplacementDataChange, replacementData])

  return null
}

export default UtmPlugin
