import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import { closeDialog } from '@entities/apiHandler'
import Loader from '@entities/Loader'
import InfoBox from '@features/infoBox'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { setIdentify } from '@shared/lib/utils/amplitude'
import { LAYOUT } from '@shared/model/constants/styles'
import Copyright from '@shared/ui/Copyright'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@shared/ui/dialogs'

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch()

  const handleDialogClose = () => dispatch(closeDialog())

  const email = useAppSelector(state => state.auth.userInfo.email)
  const isLoading = useAppSelector(state => state.apiHandler.isLoading)
  const isOpenDialog = useAppSelector(state => state.apiHandler.isOpenDialog)
  const message = useAppSelector(state => state.apiHandler.message)
  const projectId = useAppSelector(state => state.auth.projectIKAId)
  const projectName = useAppSelector(state => state.auth.projectName)
  const userId = useAppSelector(state => state.auth.userInfo.userId)

  const { i18n, t } = useTranslation(['common'])

  useEffect(() => {
    setIdentify({ projectId, email, lang: i18n.language, projectName, userId })
  }, [projectId, email, i18n.language, projectName, userId])

  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: `${LAYOUT.contentMarginTop}px`,
        paddingRight: `${LAYOUT.contentRightSideGap}px`,
        paddingLeft: `${LAYOUT.contentLeftSideGap}px`,
        transition: 'margin 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      }}
    >
      {children}

      {isLoading ? <Loader /> : <Copyright />}

      <InfoBox />

      <Dialog
        open={isOpenDialog}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          {t(message as ParseKeys<'common'>) as string}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleDialogClose}>{t('common:close')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ContentWrapper
