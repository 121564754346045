import type { Reducer } from 'use-immer'

import {
  getDefaultAttributeParams,
  getDimOperators,
  metadataTypeAttributeOperatorMap,
  resolveUiOperator,
} from '@features/filters/AttributeFilter'
import type { UserProfileAndTagFilterNode } from '@shared/api/rtkQuery'

import type { UserProfileAndTagAction } from './userProfileAndTagReducer.type'

export const createInitialState = (): UserProfileAndTagFilterNode => ({
  type: 'user_profile_tag',
  node: {
    nodes: [],
    combination: 'and',
    type: 'group',
  },
})

export const initializer = (
  currState?: UserProfileAndTagFilterNode
): UserProfileAndTagFilterNode => {
  return currState || createInitialState()
}

export const reducer: Reducer<
  UserProfileAndTagFilterNode,
  UserProfileAndTagAction
> = (draft, action) => {
  switch (action.type) {
    case 'addNestedCondition': {
      const { source, field, dataType, repeated, index } = action.payload

      const [firstOperator] = metadataTypeAttributeOperatorMap[dataType]

      const [firstDimOperator] = getDimOperators(source, dataType, repeated)

      const defaultParams = getDefaultAttributeParams(dataType, firstOperator)

      draft.node.nodes[index].nodes.push({
        type: 'condition',
        source,
        field,
        dimOperator: firstDimOperator,
        operator: resolveUiOperator(firstOperator),
        params: defaultParams,
      })
      break
    }

    case 'updateNestedCondition':
      draft.node.nodes[action.payload.parentIndex].nodes[
        action.payload.nestedIndex
      ] = action.payload.value
      break

    case 'removeNestedCondition':
      const currNestedGroups =
        draft.node.nodes[action.payload.parentIndex].nodes
      if (currNestedGroups.length === 1) {
        // 只剩最後一個 child 時將 parent 一併移除
        draft.node.nodes.splice(action.payload.parentIndex, 1)
        break
      }

      currNestedGroups.splice(action.payload.nestedIndex, 1)
      break

    case 'addAudienceFilterGroupItem':
      const { source, field, dataType, repeated } = action.payload

      const [firstOperator] = metadataTypeAttributeOperatorMap[dataType]

      const [firstDimOperator] = getDimOperators(source, dataType, repeated)

      const defaultParams = getDefaultAttributeParams(dataType, firstOperator)

      draft.node.nodes.push({
        type: 'group',
        nodes: [
          {
            type: 'condition',
            source,
            field,
            dimOperator: firstDimOperator,
            operator: resolveUiOperator(firstOperator),
            params: defaultParams,
          },
        ],
        combination: 'and',
      })
      break

    case 'toggleAudienceFilterCombination':
      draft.node.combination = draft.node.combination === 'and' ? 'or' : 'and'
      break

    case 'toggleAudienceFilterGroupCombination':
      const currGroup = draft.node.nodes[action.payload]
      currGroup.combination = currGroup.combination === 'and' ? 'or' : 'and'
      break

    case 'reset':
      return initializer(action.payload)

    default:
      return draft
  }
}
