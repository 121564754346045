import { useTranslation } from 'react-i18next'

import AudienceFilter, {
  useAudienceFilter,
} from '@features/filters/AudienceFilter'
import { ConfirmDialog } from '@shared/ui/dialogs'

import { REACT_FLOW_NO_DRAG } from '../_shared/constants'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import {
  LogicEventFilterNodeData,
  logicEventFilterNodeSchema,
} from './logicEventFilterNodeSchema'
import { getAudienceFilterInitialState } from './utils'

type LogicEventFilterNodeEditDialogProps = {
  isOpen: boolean
  initialValues: Partial<LogicEventFilterNodeData>
  onConfirm: (values: Partial<LogicEventFilterNodeData>) => void
  onClose: () => void
}

export const LogicEventFilterNodeEditDialog = ({
  initialValues,
  isOpen,
  onConfirm,
  onClose,
}: LogicEventFilterNodeEditDialogProps) => {
  const {
    values: audienceFilter,
    onDispatch: onAudienceFilterDispatch,
    onReset: onAudienceFilterReset,
  } = useAudienceFilter(getAudienceFilterInitialState(initialValues.filter))

  const formState = useWorkflowFormState()

  const handleConfirm = async () => {
    if (!formState.isEditable) {
      onClose()
      return
    }

    const values = { filter: audienceFilter }

    if (await logicEventFilterNodeSchema.isValid(values)) {
      onConfirm(values)
    }
  }

  const handleClose = () => {
    onAudienceFilterReset(getAudienceFilterInitialState(initialValues.filter))

    onClose()
  }

  const { t } = useTranslation(['workflow'])

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      maxWidth="md"
      isOpen={isOpen}
      modalTitle={t('workflow:node_logic_condition_configuration')}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <AudienceFilter
        state={audienceFilter}
        dispatch={onAudienceFilterDispatch}
        isDisabled={!formState.isEditable}
      />
    </ConfirmDialog>
  )
}

export default LogicEventFilterNodeEditDialog
