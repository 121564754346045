import type { ReplacementData } from '@shared/api/rtkQuery/contentTemplate'

export type AudienceRuleUserExportStatus =
  | 'none'
  | 'initialized'
  | 'processing'
  | 'completed'
  | 'failed'
  | 'cancelled'

export interface AudienceRuleUserExport {
  id: number
  audienceRuleId: number
  status: AudienceRuleUserExportStatus
  createdAt: string
  updatedAt: string
  error?: string
}

export interface AudienceRuleUserExportCSV extends AudienceRuleUserExport {
  downloadUrls: string[]
  totalCount: number
}
// ref: https://gitlab.corp.ikala.tv/dmp/lego/-/blob/develop/src/go/exporter/dao/export-common/export-common.go#L56
export enum MAToolErrorCode {
  None = 0,
  Publish = 1,
  DaoOperation = 2,
  Internal = 3,
  Unauthorized = 4,
  ActionNotPermitted = 5,
  ExceedLimit = 6,
  ThirdPartyApplicationIntegrationNotSet = 7,
  ErrUnknownUserProfileColumn = 8,
  ErrTooManyParamFields = 9,
  ResourceNotFound = 10,

  GACreateList = 0x00000100,
  GAAddUsers = 0x00000200,
  GARenameUserList = 0x00000300,
  GACustomerMatchNotEnabled = 0x00000400,

  FbCreateAudience = 0x00001000,
  FbAppendUsers = 0x00002000,
}

export interface AudienceRuleUserExportMATool extends AudienceRuleUserExport {
  updatedAt: string
  name: string
  totalCount: number
  validCount: number
  successCount: number
  failCount: number
  errorCode: MAToolErrorCode
  resourceId?: string
  integrationId?: number
  integrationName?: string
  lineOaName?: string
}

export interface AudienceRuleUserExportIterable
  extends AudienceRuleUserExportMATool {
  iterableListId: string
}

export interface AudienceRuleUserExportMAAC
  extends AudienceRuleUserExportMATool {
  maacListId: string
}
export interface AudienceRuleUserExportSuper8
  extends AudienceRuleUserExportMATool {
  maacListId: string
}

export type SendType = 'sms' | 'mms'

// ref: https://gitlab.corp.ikala.tv/dmp/lego/-/blob/develop/src/go/exporter/rest/util/util.go#L27
export type MAToolError =
  | 'none'
  | 'internal'
  | 'unauthorized'
  | 'limit_of_number_of_exported_users_exceeded'
  | 'action_not_permitted'
  | 'customer_match_not_enabled'

export interface AudienceRuleUserExportSms extends AudienceRuleUserExport {
  totalCount: number
  validCount: number
  successCount: number
  error: MAToolError
  batchIds: string
  sendType: SendType | ''
  sendTime: string
  isBooking: boolean
  toTelecom?: number
  toPhone?: number
}

export type ExportAudienceRuleUserMAToolPayload = {
  audienceRuleId: number
  name: string
  integrationId: number
}

export type ExportAudienceRuleUserSmsPayload = {
  audienceRuleId: number
  name: string
  type: SendType
  subject: string
  message: string
  sendTime: string
  retryTime: number
  attachment?: { binary: string; type: string }
  isBooking: boolean
  integrationId: number
}

export type RetryLatestExportSmsPayload = {
  audienceRuleId: number
  integrationId: number
}

export type Sms = {
  destinations: string | string[]
  message: string
  replacementData?: ReplacementData
  subject?: string
  integrationId: number
}

export type Mms = Sms & { attachment?: string; imageType?: string }

export type GoogleAdsCustomer = {
  customerId: string
  descriptiveName: string
}

export type GoogleAdsError =
  | 'none'
  | 'internal'
  | 'unauthorized'
  | 'action_not_permitted'
  | 'customer_match_not_enabled'
  | 'limit_of_number_of_exported_users_exceeded'

export type ExportUpdatePolicy = 'replace' | 'add'

type ExportUpdatePeriodType = 'daily' | 'weekly' | 'monthly'

export interface AudienceRuleUserExportGoogleAds
  extends AudienceRuleUserExport {
  customerId: string
  error: GoogleAdsError
  errorCode: MAToolErrorCode
  resourceId: string
  updatePolicy?: ExportUpdatePolicy
  updatePeriodType?: ExportUpdatePeriodType
  updatePeriod?: number
  totalCount: number
  validCount: number
}

export type ExportAudienceRuleUserGoogleAds = {
  audienceRuleId: number
  customerId: string
  name: string
  description: string
  googleAccessToken: string
  googleRefreshToken: string
  updatePolicy?: ExportUpdatePolicy
  updatePeriodType?: ExportUpdatePeriodType
  updatePeriod?: number
}

export type FacebookAdsError =
  | 'none'
  | 'internal'
  | 'unauthorized'
  | 'dao_operation'
  | 'exceed_limit'
  | 'facebook_create_audience'
  | 'facebook_append_users'

export type FacebookAdsErrorFromFacebook = {
  message?: string
  type?: string
  code?: number
  errorSubcode?: number
  isTransient?: boolean
  errorUserTitle?: string
  errorUserMsg?: string
  fbtraceId?: string
}

export interface AudienceRuleUserExportFacebookAds {
  id: number
  audienceRuleId: number
  accountId: string
  status: AudienceRuleUserExportStatus
  createdAt: string
  name: string
  updatedAt?: string
  updatePolicy?: ExportUpdatePolicy
  error?: FacebookAdsError
  fbError?: FacebookAdsErrorFromFacebook
  successCount: number
  failCount: number
}

export type ExportAudienceRuleUserFacebookAds = {
  accountId: string
  audienceRuleId: number
  facebookAccessToken: string
  id?: number
  name: string
  updatePolicy?: ExportUpdatePolicy
}

export type GetAudienceRuleUserExportPayload = {
  audienceRuleId: number
  integrationIds?: number
  withDetail?: boolean
}
