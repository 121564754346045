import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  SmsProvider,
  useGetEvery8dCreditQuery,
  useGetSmsCreditQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { Tooltip } from '@shared/ui/tooltips'

import useWordCount from './hooks/useWordCount'

const NO_DATA_ID = 0

export type WProps = {
  integrationId?: number
  smsProvider?: SmsProvider
}

const WordCountPlugin = ({
  integrationId = NO_DATA_ID,
  smsProvider,
}: WProps) => {
  const { t } = useTranslation(['audience'])

  const { count, cost } = useWordCount()

  // 目前只有 mitake 實作在 third party gateway, 未來 every8d 搬進 third party gateway 後，就可以統一用 useGetSmsCreditQuery 取得 credit
  const isEvery8dProvider =
    smsProvider === 'every8d' || smsProvider === 'every8d_exclusive'

  const { smsEvery8dCredit } = useGetEvery8dCreditQuery(
    {
      integrationId,
    },
    {
      skip: !isEvery8dProvider || integrationId === NO_DATA_ID,
      selectFromResult: ({ data }) => ({ smsEvery8dCredit: data || 0 }),
    }
  )

  const { smsCredit } = useGetSmsCreditQuery(
    { integrationId },
    {
      skip: isEvery8dProvider || integrationId === NO_DATA_ID,
      selectFromResult: ({ data }) => ({ smsCredit: data || 0 }),
    }
  )

  return (
    <Box
      mt={1.5}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Box mr={0.5} fontWeight="bold">
        {t('audience:export_sms.calculator')}
      </Box>
      <Box mr={1}>
        <Tooltip
          title={t('audience:export_sms.calculator_hint_with_all_sms_provider')}
        >
          <i className={ICON.infoCircle} />
        </Tooltip>
      </Box>

      <Box mr={1}>
        {t('audience:export_sms.calculator_detail_with_massage_size_and_cost', {
          message_size: count,
          total_cost: cost,
        })}
      </Box>
      {smsProvider && Boolean(integrationId) && (
        <Box
          fontWeight={500}
          sx={theme => ({
            color: theme.colors.brightBlue,
          })}
        >
          {t('audience:export_sms.calculator_detail_2', {
            credit: isEvery8dProvider ? smsEvery8dCredit : smsCredit,
          })}
        </Box>
      )}
    </Box>
  )
}

export default WordCountPlugin
