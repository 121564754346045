import styled from '@emotion/styled'
import { spacing, SpacingProps } from '@mui/system'

export const InputAreaGrid = styled.div<
  { flexAlignItems?: string } & SpacingProps
>`
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-areas: 'label input';
  align-items: ${({ flexAlignItems }) => flexAlignItems || 'center'};
  justify-items: flex-start;
  width: 100%;
  ${spacing}
`

export const LabelArea = styled.div`
  grid-area: label;
  margin-right: 24px;
  width: 100px;
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 14px;
  font-weight: bold;
  ${spacing}
`

export const InputArea = styled.div`
  display: flex;
  grid-area: input;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  ${spacing}
`
