import cloneDeep from 'lodash/cloneDeep'

import { createMetadataPropertyId } from '@shared/lib/utils/metadata'

import { api, transformResponseCamelCaseKeys } from '../api'
import {
  MetadataColumn,
  MetadataEvent,
  MetadataEventEntity,
  MetadataUserProfileEntity,
} from './types'

export const DIMENSION_SOURCE_PREFIX = 'dimension_'

export const INITIAL_STATE_EVENT: Readonly<MetadataEventEntity> = {
  classification: {
    ids: [],
    entities: {},
  },
  events: {
    ids: [],
    entities: {},
  },
}

export const INITIAL_STATE_USER_PROFILE: Readonly<MetadataUserProfileEntity> = {
  ids: [],
  entities: {},
}

type MetadataUserProfileFromUsersEntity = MetadataUserProfileEntity & {
  userTableIds: string[]
}

export const INITIAL_STATE_USER_PROFILE_FROM_USERS: MetadataUserProfileFromUsersEntity =
  {
    ids: [],
    entities: {},
    userTableIds: [],
  }

const transformMetadataEventEntity = (
  data: MetadataEvent[],
  filterTableNames?: string[]
): MetadataEventEntity => {
  const metadataEventEntity: MetadataEventEntity = data.reduce((acc, curr) => {
    const {
      category: categoryId,
      categoryDisplayName,
      name: eventId,
      displayName,
      columns,
    } = curr

    if (!(categoryId in acc.classification.entities)) {
      acc.classification.ids.push(categoryId)
      acc.classification.entities[categoryId] = {
        id: categoryId,
        displayName: categoryDisplayName,
        events: [],
      }
    }

    const filterTableSet = new Set(filterTableNames)

    if (
      !filterTableNames ||
      columns.some(column => filterTableSet.has(column.tableName))
    ) {
      acc.classification.entities[categoryId].events.push(eventId)

      acc.events.ids.push(eventId)
      acc.events.entities[eventId] = {
        id: eventId,
        displayName,
        propertiesIds: [],
        propertiesEntity: {},
      }

      for (let item of columns) {
        const {
          name: id,
          displayName,
          type,
          range,
          description,
          tableName,
          isFromColumnOfDimTable,
          repeated,
          category,
        } = item

        // tableName 在 filter 是 source 欄位
        // id 在 filter 是 field 欄位
        const propertyId = createMetadataPropertyId(tableName, id)

        if (!(propertyId in acc.events.entities[eventId].propertiesEntity)) {
          acc.events.entities[eventId].propertiesIds.push(propertyId)
          acc.events.entities[eventId].propertiesEntity[propertyId] = {
            id,
            displayName,
            dataType: type,
            description,
            range: range ?? {},
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
      }
    }

    return acc
  }, cloneDeep(INITIAL_STATE_EVENT))

  return metadataEventEntity
}

const transformMetadataUserProfileEntity = (
  data: MetadataColumn[]
): {
  userProfileEntity: MetadataUserProfileFromUsersEntity
  userThirdPartyTagEntity: MetadataUserProfileEntity
  userProfileIntelligence: MetadataUserProfileEntity
  userProfileDimension: MetadataUserProfileEntity
  userAutoTagEntity: MetadataUserProfileEntity
  userBehaviorTagEntity: MetadataUserProfileEntity
} => {
  const userProfileEntity = cloneDeep(INITIAL_STATE_USER_PROFILE_FROM_USERS)
  const userThirdPartyTagEntity = cloneDeep(INITIAL_STATE_USER_PROFILE)
  const userProfileIntelligence = cloneDeep(INITIAL_STATE_USER_PROFILE)
  const userProfileDimension = cloneDeep(INITIAL_STATE_USER_PROFILE)
  const userAutoTagEntity = cloneDeep(INITIAL_STATE_USER_PROFILE)
  const userBehaviorTagEntity = cloneDeep(INITIAL_STATE_USER_PROFILE)

  data.forEach(
    ({
      name: id,
      displayName,
      type,
      range,
      description,
      tableName,
      isFromColumnOfDimTable,
      repeated,
      category,
    }) => {
      // tableName 在 filter 是 source 欄位
      // id 在 filter 是 field 欄位
      const propertyId = createMetadataPropertyId(tableName, id)

      // 第三方標籤
      if (category === 'third-party-tag') {
        userThirdPartyTagEntity.ids.push(propertyId)

        if (!(propertyId in userThirdPartyTagEntity.entities)) {
          userThirdPartyTagEntity.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
        return
      }

      // 智慧顧客資料
      if (category === 'AI') {
        userProfileIntelligence.ids.push(propertyId)

        if (!(propertyId in userProfileIntelligence.entities)) {
          userProfileIntelligence.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
        return
      }

      // 使用者畫像
      if (category === 'user_profile') {
        userProfileEntity.ids.push(propertyId)

        if (tableName === 'users') {
          userProfileEntity.userTableIds.push(propertyId)
        }

        if (!(propertyId in userProfileEntity.entities)) {
          userProfileEntity.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
      }

      // 維度表
      if (category === 'dimension') {
        userProfileDimension.ids.push(propertyId)

        if (!(propertyId in userProfileDimension.entities)) {
          userProfileDimension.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
      }

      // 行為標籤(匯入)
      // 行為標籤(自動化規則)

      if (category === 'behavior-tag-import') {
        userBehaviorTagEntity.ids.push(propertyId)

        if (!(propertyId in userBehaviorTagEntity.entities)) {
          userBehaviorTagEntity.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
      }

      if (category === 'behavior-tag-auto-aigc-with-event-rule') {
        userAutoTagEntity.ids.push(propertyId)

        if (!(propertyId in userAutoTagEntity.entities)) {
          userAutoTagEntity.entities[propertyId] = {
            id,
            displayName,
            dataType: type,
            range: range ?? {},
            description,
            tableName,
            isFromColumnOfDimTable,
            repeated,
            category,
          }
        }
      }
    }
  )

  return {
    userProfileEntity,
    userThirdPartyTagEntity,
    userProfileIntelligence,
    userProfileDimension,
    userAutoTagEntity,
    userBehaviorTagEntity,
  }
}

type MetadataDimensionTable = {
  subType: string
  tableDisplayName: string
}

const metadataApi = api.injectEndpoints({
  endpoints: builder => ({
    metadataEventEntity: builder.query<
      MetadataEventEntity,
      { filterTableNames?: string[] } | void
    >({
      query: () => '/metadata_property/events',
      transformResponse: (res, _meta, arg) =>
        transformMetadataEventEntity(
          // 為了後續方便將 transformResponseCamelCaseKeys 拉到全域，暫時寫成兩階段處理 response
          transformResponseCamelCaseKeys(res as MetadataEvent[]),
          arg?.filterTableNames
        ),
      providesTags: () => [{ type: 'MetadataEvents', id: 'LIST' }],
    }),
    metadataUserProfileEntity: builder.query<
      {
        userProfileEntity: MetadataUserProfileFromUsersEntity
        userThirdPartyTagEntity: MetadataUserProfileEntity
        userProfileIntelligence: MetadataUserProfileEntity
        userProfileDimension: MetadataUserProfileEntity
        userAutoTagEntity: MetadataUserProfileEntity
        userBehaviorTagEntity: MetadataUserProfileEntity
      },
      void
    >({
      query: () => '/metadata_property/user_profiles',
      // 為了後續方便將 transformResponseCamelCaseKeys 拉到全域，暫時寫成兩階段處理 response
      transformResponse: res =>
        transformMetadataUserProfileEntity(
          transformResponseCamelCaseKeys(res as MetadataColumn[])
        ),
      providesTags: () => [{ type: 'MetadataUserProfiles', id: 'LIST' }],
    }),
    metadataDimensionTableList: builder.query<MetadataDimensionTable[], void>({
      query: () => '/metadata_property/dimension_tables',
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MetadataDimensionTable[]),
      providesTags: result =>
        result
          ? [
              ...result.map(({ tableDisplayName }) => ({
                type: 'MetadataDimensionTables' as const,
                id: tableDisplayName,
              })),
              { type: 'MetadataDimensionTables', id: 'LIST' },
            ]
          : [{ type: 'MetadataDimensionTables', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useMetadataEventEntityQuery,
  useMetadataUserProfileEntityQuery,
  useMetadataDimensionTableListQuery,
} = metadataApi
