import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import type { BarChartDisplayType } from '@shared/ui/charts'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

const RadioButton = styled.div<{
  isActive?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  align-items: center;

  padding: 4px;

  border-radius: 4px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brightBlue : theme.colors.textSecondBlue};

  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  transition: 0.25s;

  :hover {
    color: ${({ theme }) => theme.colors.brightBlue};

    /* icon */
    div {
      color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

type BarChartDisplayTypeRadioButtonsProps = {
  displayType: BarChartDisplayType
  onDisplayTypeChange: (displayType: BarChartDisplayType) => void
}

const BarChartDisplayTypeRadioButtons = ({
  displayType,
  onDisplayTypeChange,
}: BarChartDisplayTypeRadioButtonsProps) => {
  const { t } = useTranslation(['dateAndChart'])

  return (
    <Box sx={{ display: 'flex' }}>
      <Tooltip title={t('dateAndChart:charts.bar')}>
        <RadioButton
          isActive={displayType === 'bar'}
          onClick={() => onDisplayTypeChange('bar')}
        >
          <Icon
            icon={ICON.signalAlt3}
            color={
              displayType === 'bar'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </RadioButton>
      </Tooltip>
      <Tooltip title={t('dateAndChart:charts.bar_ratio')}>
        <RadioButton
          isActive={displayType === 'percentage'}
          onClick={() => onDisplayTypeChange('percentage')}
        >
          <Icon
            icon={ICON.percentage}
            color={
              displayType === 'percentage'
                ? theme.colors.brightBlue
                : theme.colors.textSecondBlue
            }
            fontSize="small"
          />
        </RadioButton>
      </Tooltip>
    </Box>
  )
}

export default BarChartDisplayTypeRadioButtons
