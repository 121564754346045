import { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList from '@shared/ui/Dropdown/CheckboxList'
import DropdownList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import { REACT_FLOW_NO_DRAG } from '@widgets/workflow/_shared/nodes/_shared/constants'

import {
  dayOfWeekMap,
  DEFAULT_AT,
  DEFAULT_VALUE,
  hourOptions,
  minuteOptions,
  WEEKDAY,
} from '../_shared/constants'
import type { TimeData } from '../timeReducer'

export type AdvWeekdayNodeData = Pick<TimeData, 'at' | 'dayOfWeek'>

type Props = {
  isError?: boolean
  isEditable: boolean
  isHideHourAndMinute?: boolean
  isColumnEditable?: boolean
  timeData: AdvWeekdayNodeData
  onTimeDataUpdate: (timeData: AdvWeekdayNodeData) => void
}

const AdvWeekday = ({
  isEditable,
  isError = false,
  isHideHourAndMinute = false,
  isColumnEditable = false,
  timeData,
  onTimeDataUpdate,
}: Props) => {
  const popupMaxHeight = 160

  const { t } = useTranslation(['workflow'])
  const dayOfWeekOptions = useMemo(
    () =>
      Object.keys(dayOfWeekMap()).map(day => ({
        value: day,
        label: dayOfWeekMap()[+day],
      })),
    []
  )

  const isEveryDay =
    timeData.dayOfWeek?.length === Object.keys(dayOfWeekOptions).length
  const isEmptyDayOfWeek = !timeData.dayOfWeek?.length

  const isDisabled = !isEditable || !isColumnEditable
  const hasError = isError && !isDisabled

  return (
    <>
      <FormControlLabel
        disabled={!isEditable}
        value="dayOfWeek"
        control={<Radio color="primary" />}
        onChange={() =>
          onTimeDataUpdate({
            at: DEFAULT_AT,
            dayOfWeek: [WEEKDAY.MONDAY],
          })
        }
        label={
          <Box
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {t('workflow:node_timing_delay_advanced_options_day_of_week')}
          </Box>
        }
        sx={{ marginRight: 0 }}
      />
      <Box sx={{ display: 'flex', ml: 4, alignItems: 'center' }}>
        <Box
          sx={{
            mr: 1.5,
            height: '50px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'nowrap' }}>
          <Box sx={{ mr: 2, py: 0.5, display: 'flex', flexWrap: 'nowrap' }}>
            <DropdownCheckboxList
              anchorElem={
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    py: 1,
                    px: 2,
                    cursor: 'pointer',
                    backgroundColor:
                      !isEditable || !isColumnEditable
                        ? theme.colors.veryLightBlue
                        : theme.colors.bgPrimaryGrey,
                    borderRadius: '4px',
                    border: hasError
                      ? `1px solid ${theme.colors.chartRed}`
                      : 'none',
                  }}
                >
                  {isEveryDay && (
                    <Box
                      sx={{
                        mr: 1,
                        px: 2,
                        py: 0.5,
                        textAlign: 'center',
                        background: theme.colors.textPrimaryBlue,
                        borderRadius: '2px',
                        color: theme.colors.white,
                        fontWeight: 600,
                        fontSize: '13px',
                        lineHeight: '18px',
                      }}
                    >
                      {t('workflow:node_timing_delay_unit.weekday.everyday')}
                    </Box>
                  )}

                  {!isEveryDay &&
                    timeData.dayOfWeek?.map(day => (
                      <Box
                        key={day}
                        sx={{
                          mr: 1,
                          px: 2,
                          py: 0.5,
                          textAlign: 'center',
                          background: theme.colors.textPrimaryBlue,
                          borderRadius: '2px',
                          color: theme.colors.white,
                          fontWeight: 600,
                          fontSize: '13px',
                          lineHeight: '18px',
                        }}
                      >
                        {dayOfWeekMap()[day]}
                      </Box>
                    ))}

                  {isEmptyDayOfWeek && (
                    <Box>{t('common:dropdownlist_default')}</Box>
                  )}
                  <Icon
                    icon={ICON.arrowDown}
                    color={theme.colors.textPrimaryBlue}
                  />
                </Box>
              }
              checkedValues={dayOfWeekOptions
                .filter(day => (timeData.dayOfWeek ?? []).includes(+day.value))
                .map(item => item.value)
                .toSorted()}
              hasSelectAll
              isDisabled={isDisabled}
              isError={hasError}
              label=""
              maxVisibleListHeight={popupMaxHeight}
              onChange={values =>
                onTimeDataUpdate({
                  ...timeData,
                  dayOfWeek: values.map(value => +value).sort(),
                })
              }
              options={dayOfWeekOptions}
              popperClassName={REACT_FLOW_NO_DRAG}
              popperDisablePortal
              selectAllLabel={t(
                'workflow:node_timing_delay_unit.weekday.everyday'
              )}
              updateOn="change"
            />
          </Box>

          {!isHideHourAndMinute && (
            <Box
              sx={{
                py: 0.5,
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                alignItems: 'center',
                whiteSpace: 'nowrap',
              }}
            >
              <Trans
                ns="common"
                i18nKey="time_selector"
                components={{
                  Hours: (
                    <DropdownList
                      isDisabled={!isEditable || !isColumnEditable}
                      onChange={({ value }) => {
                        const data = {
                          hour: getIntSafe(value, DEFAULT_VALUE.HOUR),
                          minute: timeData.at?.minute || DEFAULT_VALUE.MINUTE,
                        }

                        onTimeDataUpdate({
                          ...timeData,
                          at: data,
                        })
                      }}
                      options={hourOptions}
                      popperClassName={REACT_FLOW_NO_DRAG}
                      popperDisablePortal
                      value={`${timeData.at?.hour || DEFAULT_VALUE.HOUR}`}
                      popperMaxHeight={popupMaxHeight}
                    />
                  ),
                  Minutes: (
                    <DropdownList
                      isDisabled={!isEditable || !isColumnEditable}
                      onChange={({ value }) => {
                        const data = {
                          hour: timeData.at?.hour || DEFAULT_VALUE.HOUR,
                          minute: getIntSafe(value, DEFAULT_VALUE.MINUTE),
                        }

                        onTimeDataUpdate({
                          ...timeData,
                          at: data,
                        })
                      }}
                      options={minuteOptions}
                      popperClassName={REACT_FLOW_NO_DRAG}
                      popperDisablePortal
                      value={`${timeData.at?.minute || DEFAULT_VALUE.MINUTE}`}
                      popperMaxHeight={popupMaxHeight}
                    />
                  ),
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default AdvWeekday
