import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'

import type {
  ReplacementData,
  ReplacementDataActionURL,
} from '@shared/api/rtkQuery/contentTemplate'
import { useMetadataUserProfileFromUsers } from '@shared/lib/hooks'
import cloneDeep from '@shared/lib/utils/cloneDeep'
import {
  getLargestIndexOfActionUrl,
  removeUnusedActionUrl,
} from '@shared/lib/utils/replacementData/actionUrl'
import { refreshReplacementData } from '@shared/lib/utils/replacementData/mergeTag'
import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { Dialog, DialogContent } from '@shared/ui/dialogs'
import NestedList, { NestedOption } from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'
import { PrimaryGreyInput as TextInput } from '@shared/ui/inputs'

const HTTPS_PROTOCOL = 'https://'
const INIT_SELECTION = 0
const TEXTAREA_MIN_HEIGHT = 140
const MERGE_TAG_POPPER_WIDTH = 340
const MERGE_TAG_POPPER_MAX_HEIGHT = 140

const Container = styled(DialogContent)`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.textSecondBlue};

  & .MuiFormControlLabel-label {
    font-size: 14px;
  }

  & .MuiInputBase-input::placeholder {
    font-size: 14px;
  }
`

const TextareaContainer = styled.div<{
  isDisabled?: boolean
  isError?: boolean
}>`
  border: ${({ theme, isError }) =>
    `1px solid ${isError ? theme.colors.orangeyRed : 'transparent'}`};
  border-radius: 4px;

  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.veryLightBlueTwo : theme.colors.bgPrimaryGrey};

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.brightBlue};
  }
`

const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 6px 0;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

const HttpsAddonBefore = styled.p`
  position: absolute;
  top: 12px;
  left: 16px;
  font-size: 13px;
  font-family: inherit;
  font-weight: 500;
`

const UrlRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
`

const Text = styled.div`
  width: 200px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
`

const Required = styled.span`
  color: ${({ theme }) => theme.colors.chartRed};
`

const RoundedButton = styled(Button)`
  && {
    border-radius: 18px;
    background-color: transparent;

    :focus,
    :hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const HorizontalLine = styled.div`
  margin: 30px 0;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.bgPaleGrey};
`

const CreateShortenUrlButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 28px;
  padding-bottom: 40px;
`

const createReplacementDataTag = (text: string) => `{{${text}}}`

type SmsTextareaProps = {
  id?: string
  isDisabled?: boolean
  isError?: boolean
  minHeight?: number
  onChange?: (value: string) => void
  onChangeReplacementData?: (replacementData: ReplacementData) => void
  popperClassName?: string
  popperMaxHeight?: number
  popperWidth?: number
  popperZIndex?: number
  replacementData?: ReplacementData
  showUtmButton?: boolean
  value?: string
}

export const SmsTextarea = ({
  id,
  isDisabled,
  isError,
  minHeight = TEXTAREA_MIN_HEIGHT,
  onChange = () => {},
  onChangeReplacementData = () => {},
  popperClassName,
  popperMaxHeight = MERGE_TAG_POPPER_MAX_HEIGHT,
  popperWidth = MERGE_TAG_POPPER_WIDTH,
  popperZIndex,
  replacementData = {},
  showUtmButton = false,
  value,
}: SmsTextareaProps) => {
  const { t } = useTranslation(['contentTemplate'])

  const mergeTagOptions = useMetadataUserProfileFromUsers<NestedOption>(
    ({ id, displayName }) => ({ label: `${displayName} (${id})`, value: id })
  )

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [selection, setSelection] = useState(INIT_SELECTION)
  const [showUtmLinkPage, setShowUtmLinkPage] = useState<boolean>(false)
  const [lastReplacementDataCache, setLastReplacementDataCache] =
    useState<ReplacementData>()
  const [actionUrlIndex, setActionUrlIndex] = useState<number>(0)

  const getReplacementDataText = useCallback(
    (
      text: string,
      selectionStart: number,
      selectionEnd: number,
      tagText: string
    ) => {
      const replacementDataTag = createReplacementDataTag(tagText)

      return {
        targetText:
          text.slice(INIT_SELECTION, selectionStart) +
          replacementDataTag +
          text.slice(selectionEnd),
        targetSelection: selectionStart + replacementDataTag.length,
      }
    },
    []
  )

  const handleMergeTagOptionClick = (option: Pick<NestedOption, 'value'>) => {
    if (!textareaRef.current) {
      return
    }

    const { selectionStart, selectionEnd, value } = textareaRef.current
    const { targetText, targetSelection } = getReplacementDataText(
      value,
      selectionStart,
      selectionEnd,
      option.value
    )

    onChange(targetText)
    setSelection(targetSelection)

    onChangeReplacementData({
      [option.value]: { type: 'metadata', name: option.value },
      ...refreshReplacementData(targetText, mergeTagOptions, replacementData),
    })
  }

  const insertActionUrlTag = (linkReplacementData: ReplacementData) => {
    if (!textareaRef.current) {
      return
    }

    const { selectionStart, selectionEnd, value } = textareaRef.current

    const [firstKey] = Object.keys(linkReplacementData)
    const { targetText, targetSelection } = getReplacementDataText(
      value,
      selectionStart,
      selectionEnd,
      firstKey
    )

    onChange(targetText)
    setSelection(targetSelection)

    const targetReplacementData = {
      ...linkReplacementData,
      ...cloneDeep<ReplacementData>(replacementData),
    }

    onChangeReplacementData(
      removeUnusedActionUrl(targetText, targetReplacementData)
    )
  }

  const handleTextareaBlur = () => {
    if (!value) {
      return
    }

    // 焦點離開輸入框後根據當前文字移除多餘的 replacementData
    onChangeReplacementData(
      refreshReplacementData(
        value,
        mergeTagOptions,
        removeUnusedActionUrl(value, replacementData)
      )
    )
  }

  useEffect(() => {
    if (selection === INIT_SELECTION || !textareaRef.current) {
      return
    }

    textareaRef.current.setSelectionRange(selection, selection)
    textareaRef.current.focus()
  }, [selection])

  useEffect(() => {
    if (!actionUrlIndex) {
      setActionUrlIndex(getLargestIndexOfActionUrl(replacementData))
    }
  }, [replacementData, actionUrlIndex])

  return (
    <>
      <TextareaContainer isDisabled={isDisabled} isError={isError}>
        <PrimaryGreyTextarea
          disabled={isDisabled}
          id={id}
          isDisableFocusBorder
          minHeight={minHeight}
          onBlur={handleTextareaBlur}
          onChange={({ target }) => onChange(target.value)}
          pb={6}
          placeholder={t('contentTemplate:please_enter_sms_content')}
          ref={textareaRef}
          resize="none"
          value={value}
        />

        <Box m={1} mt={0.5}>
          <NestedList
            anchorElem={
              <RoundedButton color="primary" disabled={isDisabled}>
                <Icon icon={ICON.plusCircle} fontSize="small" color="inherit" />
                &nbsp;
                {t('contentTemplate:merge_tag')}
              </RoundedButton>
            }
            hasSearchBar
            isDisabled={isDisabled}
            onChange={handleMergeTagOptionClick}
            options={mergeTagOptions}
            popperClassName={popperClassName}
            popperMaxHeight={popperMaxHeight}
            popperWidth={popperWidth}
            popperZIndex={popperZIndex}
          />
          {showUtmButton && (
            <RoundedButton
              disabled={isDisabled}
              color="primary"
              onClick={() => {
                setShowUtmLinkPage(true)
              }}
            >
              <Icon icon={ICON.plusCircle} fontSize="small" color="inherit" />
              &nbsp;
              {t('contentTemplate:utm_link')}
            </RoundedButton>
          )}
        </Box>
      </TextareaContainer>
      <UtmLinkDialog
        isOpen={showUtmLinkPage}
        onCancel={() => setShowUtmLinkPage(false)}
        onSaveLink={(linkObj: ReplacementData) => {
          setShowUtmLinkPage(false)
          insertActionUrlTag(linkObj)
          setLastReplacementDataCache(linkObj)
          setActionUrlIndex(prev => prev + 1)
        }}
        cache={lastReplacementDataCache}
        actionUrlIndex={actionUrlIndex}
      />
    </>
  )
}

type UtmLinkDialogProps = {
  isOpen: boolean
  onSaveLink: (link: ReplacementData) => void
  onCancel: () => void
  cache: ReplacementData | undefined
  actionUrlIndex: number
}

const UtmLinkDialog = ({
  isOpen,
  onSaveLink,
  onCancel,
  cache,
  actionUrlIndex,
}: UtmLinkDialogProps) => {
  const [url, setUrl] = useState<string>('')
  const [utmCampaignId, setUtmCampaignId] = useState<string>('')
  const [utmMedium, setUtmMedium] = useState<string>('')
  const [utmSource, setUtmSource] = useState<string>('')
  const [utmCampaign, setUtmCampaign] = useState<string>('')
  const [utmTerm, setUtmTerm] = useState<string>('')
  const [utmContent, setUtmContent] = useState<string>('')

  const { t } = useTranslation(['contentTemplate'])

  useEffect(() => {
    if (cache) {
      const cacheUtm = cache[Object.keys(cache)[0]] as ReplacementDataActionURL
      setUrl(cacheUtm.urlParams.host.replace(HTTPS_PROTOCOL, ''))
      setUtmCampaignId(cacheUtm.urlParams.utmCampaignId)
      setUtmMedium(cacheUtm.urlParams.utmMedium)
      setUtmSource(cacheUtm.urlParams.utmSource)
      setUtmCampaign(cacheUtm.urlParams.utmCampaign)
      setUtmTerm(cacheUtm.urlParams.utmTerm)
      setUtmContent(cacheUtm.urlParams.utmContent)
    }
  }, [cache])

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="md" fullWidth>
      <Container>
        <Title>
          {t('contentTemplate:utm_link')}
          <IconButton onClick={onCancel}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Title>
        <UrlRow>
          <Text>URL</Text>
          <div style={{ width: '100%', position: 'relative' }}>
            <HttpsAddonBefore>{HTTPS_PROTOCOL}</HttpsAddonBefore>
            <TextInput
              style={{ paddingLeft: '58px', width: '100%' }}
              placeholder={t('contentTemplate:utm_placeholder.url')}
              type="url"
              value={url}
              onChange={e => {
                setUrl(
                  e.target.value.replace('http://', '').replace('https://', '')
                )
              }}
            />
          </div>
        </UrlRow>
        <UrlRow>
          <Text>utm campaign id</Text>
          <TextInput
            type="text"
            fullWidth
            placeholder={t('contentTemplate:utm_placeholder.utm_campaign_id')}
            value={utmCampaignId}
            onChange={e => {
              setUtmCampaignId(e.target.value)
            }}
          />
        </UrlRow>
        <UrlRow>
          <Text>
            utm campaign source <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            style={{ width: '100%' }}
            placeholder={t('contentTemplate:utm_placeholder.utm_source')}
            value={utmSource}
            onChange={e => {
              setUtmSource(e.target.value)
            }}
          />
        </UrlRow>
        <UrlRow>
          <Text>
            utm campaign medium <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            style={{ width: '100%' }}
            placeholder={t('contentTemplate:utm_placeholder.utm_medium')}
            value={utmMedium}
            onChange={e => {
              setUtmMedium(e.target.value)
            }}
          />
        </UrlRow>

        <UrlRow>
          <Text>
            utm campaign name <Required>*</Required>
          </Text>
          <TextInput
            type="text"
            style={{ width: '100%' }}
            placeholder={t('contentTemplate:utm_placeholder.utm_campaign')}
            value={utmCampaign}
            onChange={e => {
              setUtmCampaign(e.target.value)
            }}
          />
        </UrlRow>
        <UrlRow>
          <Text>utm campaign term</Text>
          <TextInput
            type="text"
            style={{ width: '100%' }}
            placeholder={t('contentTemplate:utm_placeholder.utm_term')}
            value={utmTerm}
            onChange={e => {
              setUtmTerm(e.target.value)
            }}
          />
        </UrlRow>
        <UrlRow>
          <Text>utm campaign content</Text>
          <TextInput
            type="text"
            style={{ width: '100%' }}
            placeholder={t('contentTemplate:utm_placeholder.utm_content')}
            value={utmContent}
            onChange={e => {
              setUtmContent(e.target.value)
            }}
          />
        </UrlRow>
        <HorizontalLine />
        <PrimaryGreyTextarea
          style={{ width: '100%' }}
          resize={'none'}
          disabled={true}
          value={
            url
              ? `${HTTPS_PROTOCOL}${url}?utm_campaign_id=${utmCampaignId}&utm_medium=${utmMedium}&utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_term=${utmTerm}&utm_content=${utmContent}`
              : '-'
          }
        />
        <CreateShortenUrlButtonContainer>
          <DeepBlueGradientButton
            width={200}
            disabled={!url}
            onClick={() => {
              onSaveLink({
                [`action_url_${actionUrlIndex + 1}`]: {
                  type: 'action_url',
                  urlParams: {
                    host: `${HTTPS_PROTOCOL}${url}`,
                    utmCampaignId,
                    utmMedium,
                    utmSource,
                    utmCampaign,
                    utmTerm,
                    utmContent,
                  },
                },
              })
            }}
          >
            {t('contentTemplate:produce_shorten_url_var')}
          </DeepBlueGradientButton>
        </CreateShortenUrlButtonContainer>
      </Container>
    </Dialog>
  )
}

export default SmsTextarea
