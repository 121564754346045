import { useTranslation } from 'react-i18next'

import type { TagStatus } from '@shared/api/rtkQuery'

export const useTagStatusDescription = (status?: TagStatus) => {
  const { t } = useTranslation(['tag'])

  if (status === undefined) {
    return t('tag:tag_render_status.loading')
  }

  if (status === '') {
    return t('tag:tag_render_status.waiting_for_calculation')
  }

  if (status === 'succeeded') {
    return t('tag:tag_render_status.succeeded')
  }

  if (status === 'failed') {
    return t('tag:tag_render_status.failed')
  }

  return t('tag:tag_render_status.calculating')
}

export default useTagStatusDescription
