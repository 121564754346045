import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  useDeleteProjectUserMutation,
  useProjectUserQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, RedButton } from '@shared/ui/buttons'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { Tooltip } from '@shared/ui/tooltips'

import UserForm, {
  BUTTON_WIDTH,
  STATUS_CODE,
  transformToPolicyServiceEntity,
} from './UserForm'

const Detail = () => {
  const { id } = useParams<{ id: string }>()
  const userId = getIntSafe(id)

  const { projectUser } = useProjectUserQuery(userId, {
    selectFromResult: result => ({
      isLoadingProjectUser: result.isLoading,
      projectUser: result.data
        ? {
            id: result.data.id,
            email: result.data.email,
            description: result.data.description ?? '',
            userPolicyList:
              result.data.policies?.map(transformToPolicyServiceEntity) ?? [],
          }
        : undefined,
    }),
  })

  const [deleteProjectUser, { isLoading: isDeletingProjectUser }] =
    useDeleteProjectUserMutation()

  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)

  const { t } = useTranslation(['common', 'iam'])

  const history = useHistory()

  const dispatch = useAppDispatch()

  return (
    <>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.userCenter}/${PAGE_ROOT.users}`}>
          {t('common:route.iam_user')}
        </Link>
        {projectUser?.email && (
          <Tooltip title={projectUser.email}>
            <Box>{projectUser.email}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>

      <UserForm formValues={projectUser} isDisabled>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={4}>
            <RedButton
              width={BUTTON_WIDTH}
              onClick={() => {
                setIsShowDeleteDialog(true)
              }}
            >
              {t('common:delete')}
            </RedButton>
          </Box>

          <DeepBlueGradientButton
            width={BUTTON_WIDTH}
            onClick={() => {
              history.push(
                `/${CATEGORY.userCenter}/${PAGE_ROOT.users}/${userId}/edit`
              )
            }}
          >
            {t('common:edit')}
          </DeepBlueGradientButton>
        </Box>
      </UserForm>

      {projectUser && (
        <DeleteConfirmDialog
          modalTitle={t('common:delete_header', { name: projectUser.email })}
          isOpen={isShowDeleteDialog}
          isLoading={isDeletingProjectUser}
          onClose={() => {
            setIsShowDeleteDialog(false)
          }}
          onConfirm={async () => {
            try {
              await deleteProjectUser(projectUser.id).unwrap()

              history.push(`/${CATEGORY.userCenter}/${PAGE_ROOT.users}`)
            } catch (error) {
              const typedError = formatRTKQueryError(error)

              if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
                dispatch(
                  openToast({
                    message: t('common:this_function_is_not_allowed'),
                    status: 'error',
                  })
                )
                return
              }

              handleApiError(typedError)
            }
          }}
        />
      )}
    </>
  )
}

export default Detail
