import isNumber from 'lodash/isNumber'

import { formatNumber, formatNumberWithSign } from '@shared/lib/utils/number'

export const formatIndicatorValue = (value: number | string | undefined) =>
  isNumber(value)
    ? (value as number) < 1000
      ? formatNumber(value as number)
      : formatNumberWithSign(value as number)
    : value

export default formatIndicatorValue
