export type EventRulePieChartData = {
  labels: string[]
  series: number[]
}

export const createInitialEventRulePieChartData =
  (): EventRulePieChartData => ({
    labels: [],
    series: [],
  })
