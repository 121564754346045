import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { AigcProductsDialog, useAigcProductList } from '@features/aigcProducts'
import { ProductAigc } from '@shared/api/rtkQuery'
import trialDashboard from '@shared/assets/images/dashboard/trialDashboard.svg'
import { ICON } from '@shared/model/constants/styles'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import FeatureIntroduction from '@shared/ui/FeatureIntroduction'
import { UniIcon as Icon } from '@shared/ui/icons'
import SectionTitle from '@shared/ui/SectionTitle'
import theme from '@theme'

import Blacklist from './Blacklist'
import ExportCSVButton from './ExportCSVButton'
import ProductKeywordList from './ProductKeywordOverview'

export const AutoTagAnalysis = () => {
  const { t } = useTranslation(['common', 'aiMarketingAssistant'])
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false)
  const [selectedProducts, setSelectedProducts] = useState<ProductAigc[]>([])
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false)
  const [isStartAnalysis, setIsStartAnalysis] = useState<boolean>(false)
  const [isShowResult, setIsShowResult] = useState<boolean>(false)
  const {
    aigcProductList,
    isFetching,
    isLoading,
    isSuccess,
    hasMore,
    onSearchByName,
    searchName,
    onLoadMore,
    totalCount,
  } = useAigcProductList()

  const hasSelectAll = isSelectAll
  const hasSelectProduct = !isSelectAll && selectedProducts.length > 0
  const hasEmpty = !isSelectAll && selectedProducts.length === 0
  // 沒有搜尋條件的情況下，如果沒有資料的話，表示需要設定 GA EEC
  const isNeedToSetGaEec = !totalCount && !searchName

  const handleOnSelect = (values: {
    isSelectAll: boolean
    selectedList: ProductAigc[]
  }) => {
    setIsSelectAll(values.isSelectAll)
    setSelectedProducts(values.selectedList)
    setIsStartAnalysis(false)
  }

  const handelOnClose = () => setIsProductDialogOpen(false)
  const handleOnOpen = () => setIsProductDialogOpen(true)
  const handleOnStartAnalysis = () => {
    setIsStartAnalysis(true)
    setIsShowResult(true)
  }

  return (
    <>
      {isNeedToSetGaEec && (
        <FeatureIntroduction
          title={t('aiMarketingAssistant:auto_tag_analysis.empty_title')}
          description={''}
          notice={t('aiMarketingAssistant:empty_desc')}
          imageUrl={trialDashboard}
        />
      )}
      <Box
        sx={{
          filter: isNeedToSetGaEec ? 'blur(4px)' : 'none',
        }}
      >
        <Box display="flex" width="100%" justifyContent="flex">
          <Breadcrumbs>
            <Box>{t('common:route.auto_tag_analysis')}</Box>
          </Breadcrumbs>
        </Box>
        <Card>
          <Box
            fontWeight={500}
            fontSize={14}
            lineHeight="20px"
            mb={2.5}
            sx={theme => ({
              color: theme.colors.textPrimaryBlue,
            })}
          >
            {t('aiMarketingAssistant:auto_tag_analysis.select_tag')}
          </Box>

          <Button
            disabled={isNeedToSetGaEec}
            onClick={handleOnOpen}
            disableRipple
            fullWidth
            sx={theme => ({
              position: 'relative',
              backgroundColor: theme.colors.bgPrimaryGrey,
              color: theme.colors.textPrimaryBlue,
              lineHeight: '20px',
              fontSize: '14px',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '16px',
            })}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems={hasSelectProduct ? 'flex-start' : 'center'}
              flexWrap="wrap"
              mr={1}
              minHeight={32}
              maxHeight={320}
              overflow="auto"
            >
              {hasSelectAll && (
                <Box
                  display="inline-block"
                  textAlign="left"
                  m={0.5}
                  px={1.5}
                  py={0.5}
                  minHeight="24px"
                  borderRadius="2px"
                  fontWeight={600}
                  fontSize={14}
                  lineHeight="18px"
                  sx={theme => ({
                    color: theme.colors.white,
                    backgroundColor: theme.colors.textPrimaryBlue,
                  })}
                >
                  {t('common:select_all')}
                </Box>
              )}
              {hasSelectProduct &&
                selectedProducts.map(product => (
                  <Box
                    key={product.productId}
                    display="inline-block"
                    textAlign="left"
                    m={0.5}
                    px={1.5}
                    py={0.5}
                    minHeight="24px"
                    borderRadius="2px"
                    fontWeight={600}
                    fontSize={14}
                    lineHeight="18px"
                    sx={theme => ({
                      color: theme.colors.white,
                      backgroundColor: theme.colors.textPrimaryBlue,
                    })}
                  >
                    {product.name}
                  </Box>
                ))}
              {hasEmpty && (
                <> {t('aiMarketingAssistant:auto_tag_analysis.select_tag')}</>
              )}
            </Box>
            <Box
              sx={
                hasSelectProduct
                  ? {
                      position: 'absolute',
                      top: 'calc(50% - 10px + 3px)', // 10px: icon size/2, 3px: padding-top/2
                      right: '8px', // 8xp padding-right
                    }
                  : {}
              }
            >
              <Icon
                icon={ICON.arrowDown}
                fontSize="small"
                color={theme.colors.textPrimaryBlue}
              />
            </Box>
          </Button>
        </Card>
        <Box mt={5}>
          <DeepBlueGradientButton
            width={200}
            disabled={hasEmpty}
            onClick={handleOnStartAnalysis}
          >
            {t('common:analysis')}
          </DeepBlueGradientButton>
        </Box>

        {isShowResult && (
          <>
            <Box
              mt={5}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <SectionTitle
                title={t(
                  'aiMarketingAssistant:auto_tag_analysis.analysis_result'
                )}
              />
              <ExportCSVButton
                isStartAnalysis={isStartAnalysis}
                productIdList={selectedProducts.map(
                  ({ productId }) => productId
                )}
                allProduct={isSelectAll}
              />
            </Box>
            <Blacklist />
            <ProductKeywordList
              productIdList={selectedProducts.map(({ productId }) => productId)}
              allProduct={isSelectAll}
              isStartAnalysis={isStartAnalysis}
            />
          </>
        )}
      </Box>
      {!isNeedToSetGaEec && (
        <AigcProductsDialog
          isFetching={isFetching}
          hasMore={isSuccess && hasMore}
          onSearchByName={onSearchByName}
          searchName={searchName}
          onLoadMore={onLoadMore}
          data={aigcProductList}
          isOpen={isProductDialogOpen}
          isSelectAll={isSelectAll}
          onSelect={handleOnSelect}
          onClose={handelOnClose}
          isLoading={isLoading}
        />
      )}
    </>
  )
}
