import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

import { formatNumber, getFloatSafe } from '@shared/lib/utils/number'
import {
  ChartContainer,
  ChartData,
  getHeight,
  getSparseXLables,
  getTooltipHTMLString,
  getTransposeArray,
  simplifyDateDisplay,
  SizeMode,
} from '@shared/ui/charts'
import theme from '@theme'

type StackedColumnChartProps = {
  chartTitle: string
  colors: string[]
  data: ChartData<number | null>[]
  size?: SizeMode
  mode?: 'normal' | 'ratio'
}

const StackedColumnChart = (props: StackedColumnChartProps) => {
  const { t } = useTranslation('dateAndChart')
  // BarChart 不支援 對比時間
  const { chartTitle, colors, data, size = 'large', mode = 'normal' } = props
  const [firstData] = data
  const { categories, series } = firstData || {
    categories: [],
    series: [],
  }
  const xAxisCategories = categories.map(simplifyDateDisplay)
  /*
    // example
    data = [
      {
        categories: ['1/1', '1/2', '1/3', '1/4'],
        series: [
          {
            name: '安安',
            data: [5, 6, 7, 8]
          },
          {
            name: '你好',
            data: [9, null, 3, 10]
          },
          {
            name: '嗎嗎',
            data: [31, 12, 30, 25]
          }
        ]
      }
    ]
  */

  const transposeSeries = getTransposeArray<number | null | string>(
    series.map(({ data }) => data)
  )
  const totalByEachXAxis = transposeSeries.map(arr =>
    arr.reduce((acc, curr) => getFloatSafe(acc) + getFloatSafe(curr), 0)
  ) as number[]

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      stackType: mode === 'ratio' ? '100%' : undefined,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: false,
        columnWidth: '50%',
      },
    },
    xaxis: {
      categories: getSparseXLables(xAxisCategories, size),
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textSecondBlue,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: theme.colors.borderColor,
    },
    tooltip: {
      fixed: {
        enabled: size === 'small',
      },
      custom: function ({
        dataPointIndex,
      }: {
        series: number[][]
        seriesIndex: number
        dataPointIndex: number
      }) {
        // record the index of null data, no display that one
        const nullIndex: number[] = []
        let total: number = 0

        /*
          data2D = [
            [6, '33%'],
            [null, '0%'],
            [12, '67%']
          ]
        */
        const data2D: [number | string, string][] = []
        transposeSeries[dataPointIndex].forEach((num, index) => {
          if (num !== null) {
            data2D.push([
              formatNumber(+num),
              `${Math.round(
                getFloatSafe(
                  getFloatSafe(num) / totalByEachXAxis[dataPointIndex]
                ) * 100
              )}%`,
            ])
            total = total + getFloatSafe(num)
          } else {
            nullIndex.push(index)
          }
        })

        // dataPointIndex = 1
        // header = ['1/2', '數值', '百分比]
        const header = [`${chartTitle} (${total})`, t('value'), t('percent')]

        // name = ['安安', '你好', '嗎嗎']
        const names: string[] = []
        firstData.series.forEach(({ name }, index) => {
          if (!nullIndex.includes(index)) {
            names.push(name)
          }
        })

        const colorsResult: string[] = []
        colors.forEach((col, index) => {
          if (!nullIndex.includes(index)) {
            colorsResult.push(col)
          }
        })

        return getTooltipHTMLString({
          header,
          colors: colorsResult,
          names,
          data2D,
        })
      },
    },
  }

  const height = getHeight(size)

  return (
    <ChartContainer>
      <ReactApexChart
        // tooltip 不明原因會抓到上一次的 data，導致顯示錯誤，這裡強制 remount，雖然可恥但是有用
        key={`${firstData?.series?.[0]?.data?.[0]}_${firstData?.series?.[0]?.name}}`}
        options={options}
        series={series}
        type="bar"
        height={height}
      />
    </ChartContainer>
  )
}

export default StackedColumnChart
