import { createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import type { EventRuleNode } from '@shared/api/rtkQuery'

export const selectStatisticMeasureEventNameList = createSelector(
  (filter: EventRuleNode) => filter.statisticMeasures,
  statisticMeasures => statisticMeasures.map(x => x.eventName),
  { memoizeOptions: { resultEqualityCheck: isEqual } }
)

export default selectStatisticMeasureEventNameList
