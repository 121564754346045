import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type NodeProps,
  Handle,
  Position,
  useUpdateNodeInternals,
} from 'reactflow'
import Box from '@mui/material/Box'

import { type LineProvider } from '@shared/api/rtkQuery'

import {
  BasicNode,
  createGetHandleStyles,
  HandleSourceText,
  TooltipIcon,
} from '../_shared'
import { useMsgChannel } from '../_shared/hooks'
import type { BasicNodeOperation } from '../../hooks/useWorkflow'
import ActionSendLineNodeEditDialog from './ActionSendLineNodeEditDialog'
import {
  type ActionSendLineNodeData,
  actionSendLineNodeSchema,
} from './actionSendLineNodeSchema'

type Props = BasicNodeOperation<ActionSendLineNodeData>

const ActionSendLineNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    isConnectable,
    type,
  }: NodeProps<Props>) => {
    const { t } = useTranslation(['workflow', 'settings'])
    const updateNodeInternals = useUpdateNodeInternals()
    const isTrackingEnabled = Boolean(initialValues.reactionTiming)
    const [isOpenDialog, setIsOpenDialog] = useState(false)
    const stringifyValues = JSON.stringify(initialValues)

    const isSettled = useMemo(
      () => actionSendLineNodeSchema.isValidSync(JSON.parse(stringifyValues)),
      [stringifyValues]
    )

    const handleConfirm = (values: ActionSendLineNodeData) => {
      const isNeedUpdateEdges =
        isTrackingEnabled !== Boolean(values.reactionTiming)

      if (isNeedUpdateEdges) {
        onDeleteEdges('source')
      }

      onChange(values)
      setIsOpenDialog(false)
    }

    const {
      msgChannel: lineProvider,
      isLoading,
      isSuccess,
    } = useMsgChannel({
      msgChannelId: initialValues.msgChannelId,
      mediumType: 'line_push',
    })

    useEffect(() => {
      if (isSuccess && !lineProvider) {
        onChange({
          ...initialValues,
          msgChannelId: undefined,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, lineProvider, onChange])

    useEffect(() => {
      // 在 isTrackingEnabled 變化時才更新，因為 Handle 位置會變化，不在這邊刪除 Edge。刪除 Edge 在 handleConfirm 裡面處理，避免第一次進來時就刪除 Edge
      updateNodeInternals(id)
    }, [id, updateNodeInternals, isTrackingEnabled])

    const getHandleStyles = useMemo(() => createGetHandleStyles(2), [])

    return (
      <>
        <BasicNode
          nodeType="action-send_line_push"
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isLoading={Boolean(initialValues.msgChannelId) && isLoading}
          isSettled={isSettled}
        >
          {lineProvider && (
            <>
              <p>{lineProvider.integrationLineOaName}</p>

              <Box sx={{ display: 'flex' }}>
                {lineProvider.providerName}

                <TooltipIcon
                  title={
                    <>
                      <p>{id}</p>
                      <p>
                        {t('workflow:node_action_campaign_hint', {
                          name: initialValues.campaignName,
                        })}
                      </p>
                    </>
                  }
                />
              </Box>
            </>
          )}
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        {!isTrackingEnabled ? (
          <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
          />
        ) : (
          <>
            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={isConnectable}
              style={getHandleStyles(0)}
              id="yes"
            >
              <HandleSourceText>
                {t('workflow:node_action_line_push_has_been_read_handler')}
              </HandleSourceText>
            </Handle>

            <Handle
              type="source"
              position={Position.Bottom}
              style={getHandleStyles(1)}
              isConnectable={isConnectable}
              id="no"
            >
              <HandleSourceText left={-2}>
                {t('workflow:node_action_line_push_has_not_been_read_handler')}
              </HandleSourceText>
            </Handle>
          </>
        )}

        {isOpenDialog && (
          <ActionSendLineNodeEditDialog
            initialValues={{
              ...initialValues,
              lineProvider: lineProvider?.integrationType as LineProvider, // initial lineProvider 由這邊傳下去就不需要在 ActionSendLineNodeEditDialog 裡面再重新取一次
            }}
            isOpen={isOpenDialog}
            onClose={() => setIsOpenDialog(false)}
            onConfirm={handleConfirm}
          />
        )}
      </>
    )
  }
)

export default ActionSendLineNode
