// 因為會 export 給 unlayerCustomTool 使用，暫時不支援多語系
export const PREDEFINED_METADATA_TAG_ENTITY = {
  unpurchased_product_name: {
    id: 'unpurchased_product_name',
    displayName: '未結帳商品名稱',
  },
  unpurchased_product_price: {
    id: 'unpurchased_product_price',
    displayName: '未結帳商品價格',
  },
  unpurchased_product_image_url: {
    id: 'unpurchased_product_image_url',
    displayName: '未結帳商品圖片網址',
    defaultValue: 'https://via.placeholder.com/384x384?text=Product',
  },
} as const

export type PredefinedMetadataTagId =
  keyof typeof PREDEFINED_METADATA_TAG_ENTITY

export const PREDEFINED_METADATA_TAG_ID_LIST: PredefinedMetadataTagId[] = [
  'unpurchased_product_image_url',
  'unpurchased_product_name',
  'unpurchased_product_price',
]
