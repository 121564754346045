import { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'

import type { FunnelChartData } from '@shared/api/rtkQuery'
import { COLORS, getLongestSeriesLenIdx } from '@shared/ui/charts'
import IndicatorList from '@shared/ui/charts/_shared/IndicatorList'
import type { FunnelChart } from '@widgets/analytics/funnel/_shared'
import {
  LineChart,
  LineChartDisplayControl,
} from '@widgets/analytics/funnel/_shared/ui/FunnelForm/DateAndChart'

import GroupPagination from '../_shared/GroupPagination'

type Props = {
  funnelId: number
  previewData: FunnelChart['trend_chart']
}

const LineChartControl = ({ funnelId, previewData }: Props) => {
  const [step, setStep] = useState<number | string>('all')
  const currStepData = useMemo(() => {
    return (
      previewData['chart'][step] || {
        categories: [],
        series: [],
      }
    )
  }, [previewData, step])
  const totalConversionData = previewData.totalConversionData
  const [visibilityFlags, setVisibilityFlags] = useState<boolean[]>([])

  const colors = COLORS.map((color, idx) =>
    visibilityFlags[idx] ? color : undefined
  ).filter(Boolean) as string[]

  useEffect(() => {
    if (!currStepData || currStepData.categories.length <= 0) {
      setVisibilityFlags([])
      return
    }

    const { maxLen } = getLongestSeriesLenIdx([currStepData])
    const defaultVisibilityFlags = Array(maxLen).fill(true)

    setVisibilityFlags(defaultVisibilityFlags)
  }, [currStepData, setVisibilityFlags])

  /*
    最後要放在 chart 上的資料會受到兩個因素控制
    - displayRangeData 決定 x 軸顯示資料的範圍
    - visibilityFlags 決定顯示 grouped 資料的範圍
  */
  const displayData = useMemo(
    () => ({
      categories: currStepData.categories,
      series: currStepData.series
        .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
        .filter(Boolean) as FunnelChartData['series'],
    }),
    [currStepData, visibilityFlags]
  )

  const displayIndicators = [
    {
      indicator: 'conversion_rate_total',
      value: `${totalConversionData.conversionRate.toFixed(0)}%`,
    },
    {
      indicator: 'conversion_number_total',
      value: totalConversionData.conversionNumber,
    },
  ]

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IndicatorList indicatorList={displayIndicators} />

        <LineChartDisplayControl
          previewData={previewData}
          step={step}
          setStep={setStep}
        />
      </Box>

      <LineChart data={displayData} colors={colors} size="small" />

      {currStepData.series.length > 1 && (
        <GroupPagination
          id={funnelId}
          data={[currStepData]}
          visibilityFlags={visibilityFlags}
          setVisibilityFlags={setVisibilityFlags}
        />
      )}
    </>
  )
}

export default LineChartControl
