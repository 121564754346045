import { useTranslation } from 'react-i18next'

import { BlueBorderButton, DeepBlueGradientButton } from '@shared/ui/buttons'

export type PreviewButtonProps = {
  isLoading: boolean
  onPreview: VoidFunction
}

export const PreviewButton = ({ isLoading, onPreview }: PreviewButtonProps) => {
  const { t } = useTranslation(['audience'])

  return (
    <BlueBorderButton
      disabled={isLoading}
      fullWidth
      onClick={() => onPreview()}
    >
      {t('audience:preview')}
    </BlueBorderButton>
  )
}

export type CreateButtonProps = {
  isCreating: boolean
  onCreate: VoidFunction
}

export const CreateButton = ({ isCreating, onCreate }: CreateButtonProps) => {
  const { t } = useTranslation(['common'])

  return (
    <DeepBlueGradientButton
      isFullWidth
      onClick={() => onCreate()}
      disabled={isCreating}
    >
      {t('common:create')}
    </DeepBlueGradientButton>
  )
}
