import { PayloadAction } from '@reduxjs/toolkit'
import { current } from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import type { Reducer } from 'use-immer'

import {
  getDefaultAttributeParams,
  getDimOperators,
  metadataTypeAttributeOperatorMap,
  resolveUiOperator,
} from '@features/filters/AttributeFilter'
import {
  AttributeOperator,
  AttributeParams,
  FunnelAnalyticsNode,
  MetadataType,
} from '@shared/api/rtkQuery'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType } from '@shared/ui/DateRangePicker'
import { getToday } from '@widgets/analytics/event/_shared'

export const createInitialFunnelAnalyticsNode = (): FunnelAnalyticsNode => ({
  funnelSteps: [
    {
      eventName: '',
      attributeFilter: {
        nodes: [],
        combination: 'and',
      },
      relevanceField: '',
      relevanceSource: '',
    },
  ],
  groupByFields: [],
  timeWindowMinutes: 7 * 24 * 60,
  timeRangeType: 'absolute_between',
  timeRangeParams: [
    getToday().nowStart.toISOString(),
    getToday().nowEnd.toISOString(),
  ],
  timeRangeTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  // 給後端永遠帶 50，前端目前沒有地方調整
  topN: 50,
})

export const initializer = (filter?: FunnelAnalyticsNode) => {
  return filter || createInitialFunnelAnalyticsNode()
}

export type FunnelAnalyticsFilterAction =
  | PayloadAction<FunnelAnalyticsNode | undefined, 'reset'>
  | PayloadAction<{ eventName: string }, 'addStep'>
  | PayloadAction<{ index: number }, 'removeStep'>
  | PayloadAction<{ index: number }, 'duplicateStep'>
  | PayloadAction<{ targetIndex: number; eventName: string }, 'updateEventName'>
  | PayloadAction<
      {
        source: string
        field: string
        dataType: MetadataType
        repeated: boolean
        parentIndex: number
      },
      'addAttributeFilter'
    >
  | PayloadAction<
      { targetIndex: number; parentIndex: number },
      'removeAttributeFilter'
    >
  | PayloadAction<
      { targetIndex: number; parentIndex: number },
      'duplicateAttributeFilter'
    >
  | PayloadAction<
      {
        parentIndex: number
        targetIndex: number
        field: string
        source: string
        operator: AttributeOperator
        params?: AttributeParams
      },
      'updateAttributeFilter'
    >
  | PayloadAction<{ field: string; source: string }, 'addGroupByFields'>
  | PayloadAction<{ targetIndex: number }, 'removeGroupByFields'>
  | PayloadAction<
      { targetIndex: number; field: string; source: string },
      'updateGroupByFields'
    >
  | PayloadAction<
      {
        targetIndex: number
        relevanceField: string
        relevanceSource: string
      },
      'updateRelevanceAttribute'
    >
  | PayloadAction<undefined, 'resetRelevanceAttribute'>
  | PayloadAction<{ index: number }, 'toggleAttributeFilterCombination'>
  | PayloadAction<{ mins: number }, 'updateTimeWindowMinites'>
  | PayloadAction<
      {
        timeRangeParams: NumberOrString[]
        onChanged: (filter: FunnelAnalyticsNode) => void
      },
      'updateTimeRangeParams'
    >

export const funnelAnalyticsReducer: Reducer<
  FunnelAnalyticsNode,
  FunnelAnalyticsFilterAction
> = (draft, action) => {
  switch (action.type) {
    case 'reset':
      return initializer(action.payload)
    case 'addStep': {
      const { eventName } = action.payload
      draft.funnelSteps.push({
        eventName,
        attributeFilter: {
          nodes: [],
          combination: 'and',
        },
        relevanceField: '',
        relevanceSource: '',
      })
      break
    }
    case 'removeStep': {
      const { index } = action.payload
      draft.funnelSteps.splice(index, 1)
      break
    }
    case 'duplicateStep': {
      const { index } = action.payload
      const copied = cloneDeep(draft.funnelSteps[index])
      draft.funnelSteps.splice(index, 0, copied)
      break
    }
    case 'updateEventName': {
      const { eventName, targetIndex } = action.payload
      draft.funnelSteps[targetIndex].eventName = eventName
      draft.funnelSteps[targetIndex].attributeFilter.nodes = []
      break
    }
    case 'addAttributeFilter': {
      const { source, field, dataType, repeated, parentIndex } = action.payload

      const [firstOperator] = metadataTypeAttributeOperatorMap[dataType]

      const [firstDimOperator] = getDimOperators(source, dataType, repeated)

      const defaultParams = getDefaultAttributeParams(dataType, firstOperator)

      draft.funnelSteps[parentIndex].attributeFilter.nodes.push({
        type: 'condition',
        source,
        field,
        dimOperator: firstDimOperator,
        operator: resolveUiOperator(firstOperator),
        params: defaultParams,
      })
      break
    }
    case 'removeAttributeFilter': {
      const { parentIndex, targetIndex } = action.payload
      draft.funnelSteps[parentIndex].attributeFilter.nodes.splice(
        targetIndex,
        1
      )
      break
    }
    case 'duplicateAttributeFilter': {
      const { parentIndex, targetIndex } = action.payload
      const copied = cloneDeep(
        draft.funnelSteps[parentIndex].attributeFilter.nodes[targetIndex]
      )
      draft.funnelSteps[parentIndex].attributeFilter.nodes.splice(
        targetIndex,
        0,
        copied
      )
      break
    }
    case 'updateAttributeFilter': {
      const { parentIndex, targetIndex, ...otherParams } = action.payload

      draft.funnelSteps[parentIndex].attributeFilter.nodes[targetIndex] = {
        type: 'condition',
        ...otherParams,
      }
      break
    }
    case 'addGroupByFields': {
      const { field, source } = action.payload
      draft.groupByFields.push({
        field,
        source,
      })
      break
    }
    case 'removeGroupByFields': {
      const { targetIndex } = action.payload
      draft.groupByFields.splice(targetIndex, 1)
      break
    }
    case 'updateGroupByFields': {
      const { targetIndex, field, source } = action.payload
      draft.groupByFields[targetIndex].field = field
      draft.groupByFields[targetIndex].source = source
      break
    }
    case 'updateRelevanceAttribute': {
      const { targetIndex, relevanceField, relevanceSource } = action.payload
      draft.funnelSteps[targetIndex].relevanceField = relevanceField
      draft.funnelSteps[targetIndex].relevanceSource = relevanceSource
      break
    }
    case 'resetRelevanceAttribute': {
      for (let i = 0; i < draft.funnelSteps.length; i++) {
        draft.funnelSteps[i].relevanceField = ''
        draft.funnelSteps[i].relevanceSource = ''
      }
      break
    }
    case 'toggleAttributeFilterCombination': {
      const { index } = action.payload
      draft.funnelSteps[index].attributeFilter.combination =
        draft.funnelSteps[index].attributeFilter.combination === 'and'
          ? 'or'
          : 'and'
      break
    }
    case 'updateTimeWindowMinites': {
      const { mins } = action.payload
      draft.timeWindowMinutes = mins
      break
    }
    case 'updateTimeRangeParams': {
      const { timeRangeParams, onChanged } = action.payload
      draft.timeRangeType = getRangeType(timeRangeParams)
      draft.timeRangeParams = timeRangeParams

      const filter = current(draft)
      onChanged(filter)
      break
    }
    default:
      return draft
  }
}
