import { useTranslation } from 'react-i18next'

import SectionTitle from '@shared/ui/SectionTitle'
import ThirdPartyForm from '@widgets/tag/_shared/components/ThirdPartyForm'

const ThirdParty = () => {
  const { t } = useTranslation(['tag'])

  return (
    <>
      <SectionTitle title={t('tag:create_step_2_fill_tag_information')} />
      <ThirdPartyForm />
    </>
  )
}

export default ThirdParty
