import { Trans } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import DatePicker from '@shared/ui/DatePicker'
import DropdownList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'
import { REACT_FLOW_NO_DRAG } from '@widgets/workflow/_shared/nodes/_shared/constants'

import {
  DEFAULT_AT,
  DEFAULT_SPECIFIED_DAY,
  DEFAULT_VALUE,
  hourOptions,
  minuteOptions,
} from '../_shared/constants'
import { TimeData } from '../timeReducer'

export type AdvDateNodeData = Pick<TimeData, 'at' | 'specifiedDay'>

type Props = {
  isError?: boolean
  isEditable: boolean
  isColumnEditable?: boolean
  isHideHourAndMinute?: boolean
  timeData: AdvDateNodeData
  onTimeDataUpdate: (timeData: AdvDateNodeData) => void
  minDate?: Date
}

const AdvDate = ({
  isError = false,
  isEditable,
  isColumnEditable = false,
  isHideHourAndMinute = false,
  timeData,
  onTimeDataUpdate,
  minDate = new Date(),
}: Props) => {
  const { t } = useTranslation(['workflow'])
  const isDisabled = !isEditable || !isColumnEditable
  const hasError = isError && !isDisabled

  return (
    <>
      <FormControlLabel
        disabled={!isEditable}
        value="specifiedDay"
        onChange={() =>
          onTimeDataUpdate({
            at: DEFAULT_AT,
            specifiedDay: DEFAULT_SPECIFIED_DAY,
          })
        }
        control={<Radio color="primary" />}
        label={
          <Box
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {t('workflow:node_timing_delay_advanced_options_specified_day')}
          </Box>
        }
        sx={{ marginRight: 0 }}
      />
      <Box display="flex" ml={4} alignItems="center">
        <Box mr={1.5}>
          <Icon icon={ICON.schedule} color={theme.colors.brightBlue} />
        </Box>

        <Box>
          <DatePicker
            value={
              timeData.specifiedDay &&
              new Date(
                timeData.specifiedDay.year,
                timeData.specifiedDay.month - 1,
                timeData.specifiedDay.day
              )
            }
            minDate={minDate}
            isError={hasError}
            bgColor={theme.colors.bgPrimaryGrey}
            isDisabled={isDisabled}
            isShowUnsetButton={false}
            popperDisablePortal
            onValueChanged={date => {
              if (date) {
                onTimeDataUpdate({
                  at: timeData.at,
                  specifiedDay: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate(),
                  },
                })
              }
            }}
          />
        </Box>

        {!isHideHourAndMinute && (
          <Box ml={2}>
            <Trans
              ns="common"
              i18nKey="time_selector"
              components={{
                Hours: (
                  <DropdownList
                    isDisabled={!isEditable || !isColumnEditable}
                    onChange={({ value }) => {
                      const data = {
                        hour: getIntSafe(value, DEFAULT_AT.hour),
                        minute: timeData.at?.minute || DEFAULT_AT.minute,
                      }

                      onTimeDataUpdate({
                        specifiedDay: timeData.specifiedDay,
                        at: data,
                      })
                    }}
                    options={hourOptions}
                    popperClassName={REACT_FLOW_NO_DRAG}
                    popperDisablePortal
                    value={`${timeData.at?.hour || DEFAULT_VALUE.HOUR}`}
                  />
                ),
                Minutes: (
                  <DropdownList
                    isDisabled={!isEditable || !isColumnEditable}
                    onChange={({ value }) => {
                      const data = {
                        hour: timeData.at?.hour || DEFAULT_VALUE.HOUR,
                        minute: getIntSafe(value, DEFAULT_VALUE.MINUTE),
                      }

                      onTimeDataUpdate({
                        specifiedDay: timeData.specifiedDay,
                        at: data,
                      })
                    }}
                    options={minuteOptions}
                    popperClassName={REACT_FLOW_NO_DRAG}
                    popperDisablePortal
                    value={`${timeData.at?.minute || DEFAULT_VALUE.MINUTE}`}
                  />
                ),
              }}
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default AdvDate
