import styled from '@emotion/styled'

import { Footer, Header, HEADER_HEIGHT } from '@entities/ProductionIntro'
import BaseCard from '@shared/ui/Card'
import { SpacingColumn, SpacingRow } from '@shared/ui/rows'

export const Wrapper = styled.div``

export const Content = styled.div`
  margin: ${HEADER_HEIGHT}px 20% 120px 20%;

  @media (max-width: 860px) {
    margin: 40px 16%;
  }
`

export const PageTitle = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 120px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.black};
  font-size: 35px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
`

export const UpdatedTime = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 32px;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 14px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
`

export const Card = styled(BaseCard)`
  margin-bottom: 100px;
  padding: 64px 56px;
`

export const Text1 = styled.div`
  font-size: 18px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 500;
`

export const Text2 = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.blackTwo};
  font-size: 13px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 400;
  line-height: 20px;

  a {
    color: ${({ theme }) => theme.colors.brightBlue};
    text-decoration: underline;
  }

  a:visited {
    color: ${({ theme }) => theme.colors.brightBlue};
  }
`

export const TextUnderline = styled.span`
  text-decoration: underline;
`

export const TextBold = styled(Text2)`
  font-weight: 500;
`

export const List = styled.ul``

export const ListItem = styled.li`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.blackTwo};
  font-size: 13px;
  font-family: 'Roboto', '微軟正黑體', sans-serif;
  font-weight: 400;
  line-height: 20px;
  list-style: outside;
`

export const HorizontalLine = styled.div`
  margin: 16px 0;

  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.lightBlueGrey};
`

const Flex = styled.div`
  display: flex;
`

const ListTitle = styled(TextBold)`
  flex-shrink: 0;
`

const PrivacyPolicy = () => {
  return (
    <Wrapper>
      <Header />

      <Content>
        <PageTitle>Privacy Policy</PageTitle>

        <UpdatedTime>上次更新時間：2021 年 6 月 18 日。</UpdatedTime>

        <Card>
          <SpacingRow marginBottomRatio={2}>
            <Text1>收集的個人資訊之種類</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              為了協助使用者檢視和理解其資料，我們會透過不同的方式收集個人資訊。有些資訊是由您直接提供給我們的；在您造訪我們的網站或使用我們的產品與服務時，我們便會收到某些資訊；另外，我們也會收到來自第三方的資訊
              。我們所收集的資訊類別，取決於您與 iKala CDP 的互動情況：
            </Text2>
            <SpacingRow marginLeftRatio={4}>
              <List>
                {[
                  {
                    title: '聯絡資訊：',
                    content: '電子郵件地址、使用者名稱和密碼。',
                  },
                  {
                    title: '網站資料：',
                    content:
                      '造訪 iKala CDP 網站時，我們可能會從您的電腦或行動裝置收集線上及技術資訊（例如瀏覽器類型、網際網路通訊協定 (IP) 位址，以及從您的 IP 位址衍生而得的地理區域）；關於存取時間和造訪時間長度的時間戳記記錄；您在造訪 iKala CDP 網站之前造訪過的網頁（來源 URL）；以及與您在我們網站上進行之活動相關的其他使用情況資料，包括您要求的頁面。',
                  },
                  {
                    title: '產品相關資料：',
                    content:
                      '使用 iKala CDP 產品或服務時，我們會收集您使用我們的產品與服務時所使用或上傳之相關資訊。',
                  },
                  {
                    title: '第三方資料：',
                    content:
                      '我們可能會從第三方供應商或合作夥伴收到您的個人資訊。如果您與第三方社交網站上的 iKala CDP 帳戶建立連接，則我們可能會收到有關您社交網路帳戶的資訊，例如您的姓名、使用者名稱或顯示名稱、公開資料和電子郵件地址。我們可能會將您提供的資訊與我們自動收集到的資料以及我們從第三方收到的資料結合起來。',
                  },
                  {
                    title: 'Google User Data：',
                    content:
                      '您在登入時，會授權 iKala CDP 存取使用您的 Google 帳號公開個人資料，Google 帳號電子郵件，以及 AdWords  Campaign 管理權限。 ',
                  },
                ].map(({ title, content }) => (
                  <ListItem>
                    <Flex>
                      <ListTitle>{title}</ListTitle>
                      <Text2>{content}</Text2>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </SpacingRow>

            <Text2>
              如果您拒絕提供您的個人資訊或要求我們將其刪除，我們可能無法繼續提供或支援我們的產品或服務。
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>使用個人資訊的方式</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              我們會將我們收集的個人資訊用於本隱私權聲明中所述之用途，如任何包含本隱私權聲明的協議所規範，或在任何與我們網站相關的情況下向您揭露。例如，我們將使用您的資訊來：
            </Text2>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <List>
                {[
                  '供應產品或服務，包括軟體更新；',
                  '運作及改善我們的運營、系統、產品與服務；',
                  '瞭解您與您的喜好，以便為您提供更優質的體驗；',
                  '回應您的意見和問題，並提供客戶服務；',
                  '將您的相關資訊與我們從第三方收到的其他個人資訊結合，以便更加瞭解您的需求並為您提供更好、更個人化的服務；',
                  '提供服務和支援，例如傳送確認、技術公告、更新、安全性警示和管理訊息，以及提供客戶支援和排除障礙；',
                  '就 iKala CDP 與精選的合作夥伴所提供的產品和服務，與您和您的推薦人溝通有關促銷活動、活動預告等事宜；',
                  '落實條款與條件的內容，或保護我們的業務、合作夥伴或使用者；',
                  '預防、調查並阻止欺詐性、未經授權或非法的活動；',
                  '維護產品、設施和服務的安全；',
                  '我們會儲存您的 Google 帳號電子郵件，作為您登入 iKala CDP 的身份識別之用；',
                  '我們會使用您授權的 AdWords  Campaign 權限，實現 iKala CDP 和 Google Ads 的名單同步整合功能。',
                ].map(text => (
                  <ListItem>{text}</ListItem>
                ))}
              </List>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>使用您的資訊的理由</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              只有在滿足以下至少一項條件時，我們才會處理您的個人資訊：
            </Text2>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <List>
                {[
                  '我們需使用您的資料，才能根據與您簽訂的合約來履行我們的責任，並為您提供工具和服務。',
                  '我們有合法的理由收集和使用您的個人資訊。例如，以下事項符合我們的合法利益：使用包括您的個人資訊在內的使用情況資料來改善我們的產品和服務、告訴您本隱私權聲明和其他政策的變更、告訴您有關新產品及服務和活動或我們的產品、服務或網站的變更、出於商業理由 (如開發和拓展我們的業務)，以及告訴您我們正在進行的優惠或促銷活動；',
                  '您已同意我們使用您的個人資訊。如果您需要撤回同意，可以透過以下聯絡方式與我們聯絡以撤回同意。',
                  '當我們有必要使用您的個人資訊來遵守法律義務時。',
                  '您已選擇將資訊公開。您不應共享您希望保密的任何個人資訊。',
                  '我們需使用您提供的授權資訊，以實現 iKala CDP 的功能。譬如透過您授權 AdWords  Campaign 權限，CDP 可幫您將受眾名單，同步至您管理的 Google Ads 帳戶下，作為後續投放使用。',
                ].map(text => (
                  <ListItem>{text}</ListItem>
                ))}
              </List>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>分享個人資訊</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              iKala CDP
              致力於保護您個人資訊的機密性和安全性。在某些情況下，iKala CDP
              可能會與第三方分享您的資訊，例如：
            </Text2>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <List>
                {[
                  '我們向代表 iKala CDP 或為 iKala CDP 提供服務的受信任合作夥伴提供個人資訊。舉例來說，我們分享聯絡資訊的對象包含我們的訓練和認證合作夥伴；經銷商和服務合作夥伴；資料儲存、客戶支援和行銷廠商；以及我們的軟體供應商。這些公司可能會使用您的個人資訊來執行服務，並幫助 iKala CDP 就 iKala CDP 及其合作夥伴提供得產品/服務與您溝通。iKala CDP 會與這些公司簽署合約，以按照本隱私權聲明和任何法律責任限制其存取、使用和揭露個人資訊；',
                  '如果您透過我們的產品來連接您的第三方帳戶，我們將使用該資訊來驗證您的身分、列舉出可供您使用的資料來源、下載您要求我們提供的任何資料，並且下載和重新整理身分驗證權杖或持續性身分驗證資訊 (如使用者名稱與密碼)，以持續與這些資料保持連線；',
                  '我們可能會與目前或未來的關係企業 (包括母公司、子公司、合資公司或其他共同控制的公司) 分享您的聯絡方式和交易資訊，在這種情況下，我們也將要求這些關係企業遵守本隱私權聲明；',
                  '我們可能會為了符合法律的要求 (如回應法院命令或傳票) 而揭露您的個人資訊。我們還可能會應執法機構的要求，或在我們認為有必要針對以下事件進行調查、確認、預防、強制遵守法律或提起訴訟，我們也會揭露您的個人資訊：非法或涉嫌非法的活動；涉嫌欺詐；涉及對任何人的人身安全造成潛在威脅的情況；保護 iKala CDP、我們的代理商、客戶或其他人的權利和財產；違反或涉嫌違反我們的協議和政策，或法律要求或允許或符合法律要求的其他情況；',
                  '我們可能出於公司原因而轉移或揭露您的個人資訊。例如，我們可能會因為任何企業合併、融資、收購、破產或類似交易談判或在談判過程中向第三方提供您的個人資訊。我們也可能會與稽核人員、律師或公司職能部門有關的其他顧問分享您的個人資訊；',
                  '最後，我們還可能出於各種目的 (包括改善 iKala CDP 和其他產品的產品和服務)，與其他人分享有關您的彙總、匿名或統計資訊 (包括人口統計資料)。',
                ].map(text => (
                  <ListItem>{text}</ListItem>
                ))}
              </List>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>您的個人資訊的儲存和安全性</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              為了保護您的個人資訊，我們制訂了合理且適當的物理、電子和管理程序。但是，您應該知道，包括
              iKala CDP
              在內的任何公司均無法徹底消除與個人資訊相關的安全風險。為了保護您自己，請使用強式密碼，不要使用與其他帳戶或服務相同的密碼來存取
              iKala CDP
              帳戶，同時保護好您的使用者名稱和密碼，以防止他人使用您的帳戶和服務。
            </Text2>
            <Text2>
              由 iKala CDP 或代表 iKala CDP
              收集的資訊可以儲存於您的電腦、行動裝置或我們的伺服器上，而且可能會傳輸到美國和其他國家/地區
              (台灣、新加坡)，以及 iKala CDP
              或其服務供應商擁有生產設施或支援中心的任何其他國家/地區
              ，由這些國家/地區的人員存取，或在這些國家/地區儲存和處理 。
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>控制您的個人資訊</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              當您使用我們的網站時，您可以選擇將瀏覽器設為移除 Cookie
              並拒絕來自我們伺服器的 Cookie。如果您選擇移除或拒絕
              Cookie，這可能會影響我們網站的某些功能或服務。根據您所在地區的資料保護法，您可能擁有某些與您個人資訊相關的權利。視適用法律規定，此類權利可能包含以下權利，或進行以下事項的權利：
            </Text2>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <List>
                {[
                  '存取我們持有的您的個人資訊；',
                  '在考量處理個人資訊之目的的情況下，修正不準確的個人資訊，並確定資訊完整；',
                  '在適用資料保護法律允許的範圍內，清除或刪除您的個人資訊（又稱為被遺忘權）；',
                  '在法律允許的範圍內，限制我們對您個人資訊的處理；',
                  '在可行的範圍內，將您的個人資訊傳輸給另一個控制者（資料可攜權）；',
                  '反對個人資訊的處理方式。若我們針對自己的直接行銷用途處理您的個人資訊，或針對第三方的直接行銷用途將資訊分享給第三方，您可以隨時行使反對此類處理的權利，而且不必針對此類反對情事提供任何具體的原因；',
                  '選擇在某些情況下，不向第三方揭露個人資訊；',
                  '不因行使上述權利而遭歧視；',
                  '在我們根據您的同意而處理資訊時，您可隨時撤回您的同意，且在撤銷前不會影響根據此類同意進行處理的合法性。',
                ].map(text => (
                  <ListItem>{text}</ListItem>
                ))}
              </List>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>Cookie 和 Google Analytics</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={3}>
            <Text2>
              我們的網站會使用各種軟體技術，包括「Cookie」、「Google
              Analytics」。Cookie
              是我們和其他人可能置入訪客電腦瀏覽器中用於儲存其喜好設定的小型文字檔。我們使用
              Google Analytics
              (置於網頁上或電子郵件內文中的小塊代碼)，以監控行為並收集有關檢視網頁的訪客相關資料。Google
              Analytics 可用於統計造訪網頁的使用者或向檢視該網頁的訪客瀏覽器傳送
              Cookie。
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={4}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>如何聯絡我們</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={3}>
            <Text2>
              iKala CDP 是由愛卡拉互動媒體股份有限公司提供之服務(
              <a href="https://ikala.tv/">https://ikala.tv/</a>
              )。如對我們如何使用您的個人資訊有任何疑問，或者您有任何其他與隱私相關的問題，請透過iKala
              CDP 之官方網站(
              <a href="https://portal.ikala-c4m.io/">
                https://portal.ikala-c4m.io/
              </a>
              )聯絡我們。
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={3}>
            <HorizontalLine />
          </SpacingRow>

          <SpacingRow marginBottomRatio={2}>
            <Text1>更新</Text1>
          </SpacingRow>
          <SpacingColumn>
            <Text2>
              我們會不時更新本隱私權聲明。如果我們的資訊做法發生重大變更，我們將在
              iKala CDP
              的隱私權聲明網頁上註明這些變更，並在某些情況下透過電子郵件傳送變更通知。
            </Text2>
          </SpacingColumn>
        </Card>
      </Content>

      <Footer />
    </Wrapper>
  )
}

export default PrivacyPolicy
