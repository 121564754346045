import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatCurrency } from '@shared/lib/utils/number'
import type { TableColumn } from '@shared/ui/table'
import type { UsageDataSumType } from '@widgets/usageManagement/SendingAmount/lib/helper'
import { TABLE_DATE_COLUMN_RATION } from '@widgets/usageManagement/SendingAmount/model/constant'

const useFooterColumns = () => {
  const { t } = useTranslation(['common', 'insight'])

  const columns: TableColumn<UsageDataSumType>[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'name',
        align: 'right',
        colRatio: TABLE_DATE_COLUMN_RATION,
        valueFormatter: value => (
          <Box
            fontWeight="bold"
            sx={theme => ({
              color: theme.colors.black,
            })}
          >
            {t('insight:amount')}
          </Box>
        ),
      },
      {
        headerName: '',
        field: 'email',
        align: 'right',
        valueFormatter: values => (
          <Box fontWeight="bold" sx={theme => ({ color: theme.colors.black })}>
            <Box>
              {t('common:unit.sms_email_count_number', {
                value: values.counts.toLocaleString(),
              })}
            </Box>
            <Box>{formatCurrency(values.amount)}</Box>
          </Box>
        ),
      },
      {
        headerName: '',
        align: 'right',
        field: 'sms',
        valueFormatter: values => (
          <Box fontWeight="bold" sx={theme => ({ color: theme.colors.black })}>
            <Box>
              {t('common:unit.sms_email_count_number', {
                value: values.counts.toLocaleString(),
              })}
            </Box>
            <Box>{formatCurrency(values.amount)}</Box>
          </Box>
        ),
      },
      {
        headerName: '',
        align: 'right',
        field: 'totalAmount',
        valueFormatter: value => (
          <Box
            fontWeight="bold"
            sx={theme => ({ color: theme.colors.brightBlue })}
          >
            <Box>{formatCurrency(value)}</Box>
          </Box>
        ),
      },
    ],
    [t]
  )

  return columns
}

export default useFooterColumns
