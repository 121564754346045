import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { AigcProductsDialog, useAigcProductList } from '@features/aigcProducts'
import {
  AutoAigcWithEventRule,
  ProductAigc,
  useGetProductsKeywordsAigcListQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { MAX_TAG_ANALYSIS_COUNT } from '@shared/model/constants/validation'
import Card from '@shared/ui/Card'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import useTagValidator from '../../hooks/useTagValidator'

type Props = Pick<
  AutoAigcWithEventRule,
  'relatedAllAigcTarget' | 'relatedAigcTargetIds'
> & {
  onProductUpdate: (values: {
    isSelectAll: boolean
    selectedProductIds: string[]
  }) => void
  onUpdate?: () => Promise<void>
  isEnableEdit?: boolean
  isDisabled?: boolean
}

const TagAigcProduct = ({
  isEnableEdit = false,
  isDisabled = false,
  relatedAllAigcTarget,
  relatedAigcTargetIds = [],
  onProductUpdate,
  onUpdate,
}: Props) => {
  // isEnableEdit= false 的時候表示在create tag, isEnableEdit= true 的時候表示在edit tag
  const [isEdit, setIsEdit] = useState<boolean>(!isEnableEdit)
  const { t } = useTranslation(['tag', 'common'])
  const [selectedProducts, setSelectedProducts] = useState<ProductAigc[]>([])
  const [isProductDialogOpen, setIsProductDialogOpen] = useState<boolean>(false)
  const [isGetInitProductList, setIsGetInitProductList] =
    useState<boolean>(false)
  const { autoAigcDataValidator } = useTagValidator()

  const {
    aigcProductList,
    isFetching,
    isLoading,
    isSuccess,
    hasMore,
    onSearchByName,
    searchName,
    onLoadMore,
    totalCount,
  } = useAigcProductList()

  const hasSelectAll = relatedAllAigcTarget
  const hasSelectProduct = !hasSelectAll && relatedAigcTargetIds.length > 0
  const hasEmpty = !hasSelectAll && relatedAigcTargetIds.length === 0
  const isNeedToSetGaEec = !totalCount

  const { data: productKeywordList = [], isLoading: isProductKeywordsLoading } =
    useGetProductsKeywordsAigcListQuery(
      {
        productIdList: relatedAigcTargetIds,
        allProduct: false,
        pagination: {
          page: 1,
          perPage: MAX_TAG_ANALYSIS_COUNT,
        },
      },
      {
        skip: !hasSelectProduct || isGetInitProductList,
      }
    )

  const handleOnOpen = () => setIsProductDialogOpen(true)
  const handelOnClose = () => setIsProductDialogOpen(false)

  const handleOnSelect = (values: {
    isSelectAll: boolean
    selectedList: ProductAigc[]
  }) => {
    onProductUpdate({
      isSelectAll: values.isSelectAll,
      selectedProductIds: values.selectedList.map(({ productId }) => productId),
    })
    setSelectedProducts(values.selectedList)
  }

  const handleOnUpdate = async () => {
    if (!isEnableEdit || isDisabled || !onUpdate) {
      return
    }

    if (
      !autoAigcDataValidator({
        relatedAllAigcTarget,
        relatedAigcTargetIds,
      })
    ) {
      return
    }

    await onUpdate()

    setIsEdit(false)
  }

  useEffect(() => {
    if (
      hasSelectProduct &&
      !isProductKeywordsLoading &&
      !isGetInitProductList
    ) {
      setSelectedProducts(productKeywordList)
      setIsGetInitProductList(true)
    }
  }, [
    hasSelectProduct,
    isGetInitProductList,
    isNeedToSetGaEec,
    isProductKeywordsLoading,
    productKeywordList,
  ])

  return (
    <Card>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent={isEnableEdit ? 'space-between' : 'flex-start'}
        sx={theme => ({
          borderBottom: `1px solid ${theme.colors.black6}`,
        })}
      >
        <Box
          fontWeight={500}
          fontSize={14}
          lineHeight="20px"
          mb={2.5}
          pb={2.5}
          sx={theme => ({
            color: theme.colors.black,
          })}
        >
          {t('tag:behavior_tag_recommend_tag_config')}
        </Box>
        {isEnableEdit && (
          <Box display="flex" fontSize={16} whiteSpace="nowrap">
            {isEdit ? (
              <>
                <Button onClick={() => setIsEdit(false)} color="inherit">
                  {t('common:cancel')}
                </Button>

                <Button onClick={handleOnUpdate} color="primary" type="submit">
                  {t('common:save')}
                </Button>
              </>
            ) : (
              <Button onClick={() => setIsEdit(true)} color="primary">
                {t('common:edit')}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box
        px={4}
        py={3}
        sx={theme => ({
          backgroundColor: theme.colors.bgPrimaryGrey,
          borderRadius: '5px',
          border: `1px solid ${theme.colors.brightBlue}`,
        })}
      >
        <Box
          width="100%"
          py={0.5}
          pl={3}
          sx={theme => ({
            borderLeft: `5px solid ${theme.colors.brightBlue}`,
          })}
        >
          <Button
            disabled={isNeedToSetGaEec || isDisabled || !isEdit}
            disableRipple
            onClick={handleOnOpen}
            fullWidth
            sx={theme => ({
              backgroundColor: theme.colors.white,
              color: theme.colors.textPrimaryBlue,
              lineHeight: '20px',
              fontSize: '14px',
              fontWeight: 500,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '16px',
              // disabled style
              ':disabled': {
                backgroundColor: theme.colors.veryLightBlueTwo,
              },
            })}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexWrap="wrap"
              mr={1}
              minHeight={32}
            >
              {hasSelectAll && (
                <Box
                  display="inline-block"
                  textAlign="left"
                  m={0.5}
                  px={1.5}
                  py={0.5}
                  minHeight="24px"
                  borderRadius="2px"
                  fontWeight={600}
                  fontSize={14}
                  lineHeight="18px"
                  sx={theme => ({
                    color: theme.colors.white,
                    backgroundColor: theme.colors.textPrimaryBlue,
                  })}
                >
                  {t('common:select_all')}
                </Box>
              )}
              {hasSelectProduct &&
                selectedProducts.map(product => (
                  <Box
                    key={product.productId}
                    display="inline-block"
                    textAlign="left"
                    m={0.5}
                    px={1.5}
                    py={0.5}
                    minHeight="24px"
                    borderRadius="2px"
                    fontWeight={600}
                    fontSize={14}
                    lineHeight="18px"
                    sx={theme => ({
                      color: theme.colors.white,
                      backgroundColor: theme.colors.textPrimaryBlue,
                    })}
                  >
                    {product.name}
                  </Box>
                ))}
              {hasEmpty && (
                <> {t('tag:behavior_tag_recommend_tag_config_select_tag')}</>
              )}
            </Box>
            <Icon
              icon={ICON.arrowDown}
              fontSize="small"
              color={theme.colors.textPrimaryBlue}
            />
          </Button>
        </Box>
      </Box>
      　
      {!isNeedToSetGaEec && !isProductKeywordsLoading && (
        <AigcProductsDialog
          defaultValues={selectedProducts.map(product => ({
            productId: product.productId,
            name: product.name,
          }))}
          isFetching={isFetching}
          hasMore={isSuccess && hasMore}
          onSearchByName={onSearchByName}
          searchName={searchName}
          onLoadMore={onLoadMore}
          data={aigcProductList}
          isOpen={isProductDialogOpen}
          isSelectAll={hasSelectAll}
          onSelect={handleOnSelect}
          onClose={handelOnClose}
          isLoading={isLoading}
        />
      )}
    </Card>
  )
}

export default TagAigcProduct
