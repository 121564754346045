// FIXME 0307 - 只讓有 intelligent_analysis 權限的帳號看到 intelligent_analysis 選單，測試過後要移除 iam 相關的程式碼

import { Fragment, MouseEvent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import Paper from '@mui/material/Paper'
import type { ParseKeys } from 'i18next'

import { ICON, LAYOUT } from '@shared/model/constants/styles'
import { ClickAwayListener, Popup } from '@shared/ui'
import FeatureBadge from '@shared/ui/FeatureBadge'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

import useRouteByCategory from './hooks/useRouteByCategory'
import type { CDPRoute } from './constants'
import SidebarContext from './contexts'
import { getCurrCategoryByURL, getRoutesByCategory } from './helpers'
import LogoToolbar from './LogoToolbar'
import { StyledListItem, StyledListItemText, StyledSidebar } from './styles'

interface RouteByCategory {
  category: string
  text: string
  routes: CDPRoute[]
}

type ListMenuProps = {
  route: CDPRoute
}

const ListMenu = ({ route: { path, text, badgeText } }: ListMenuProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('common')
  const { isSidebarOpen } = useContext(SidebarContext)

  const isInCurrPath = pathname.includes(path)

  return (
    <Link to={path}>
      <StyledListItem
        button
        isSidebarOpen={isSidebarOpen}
        isNested
        isActive={isInCurrPath}
      >
        <StyledListItemText isActive={isInCurrPath}>
          {t(text as ParseKeys<'common'>)}
        </StyledListItemText>
        {badgeText ? <FeatureBadge>{badgeText}</FeatureBadge> : null}
      </StyledListItem>
    </Link>
  )
}

/**
 * @description 根據 url(pathname) 判斷要展開的 menu category
 *
 * 例如：pathname === '/non-member'，map = { 'users': true }
 *
 * 例如：pathname === '/persona'，map = { 'analytics': true }
 */
const useInitialMenuOpenMap = (routesByCategory: RouteByCategory[]) => {
  const { pathname } = useLocation()
  const defaultCategory = '/analytics'
  const category =
    getCurrCategoryByURL(routesByCategory, pathname) || defaultCategory

  return { initialMenuOpenMap: new Map<string, boolean>([[category, true]]) }
}

const Sidebar = () => {
  const categories = useRouteByCategory()

  const { pathname } = useLocation()
  const { t } = useTranslation('common')
  const { isSidebarOpen, setIsSidebarOpen } = useContext(SidebarContext)

  const { initialMenuOpenMap } = useInitialMenuOpenMap(categories)
  const [menuOpenMap, setMenuOpenMap] = useState(initialMenuOpenMap)
  const [anchorId, setAnchorId] = useState<string>('')
  const [popupMenu, setPopupMenu] = useState<CDPRoute[]>([])

  const handleSidebarToggle = () => {
    const nextStatus = !isSidebarOpen
    setIsSidebarOpen(nextStatus)

    if (!nextStatus) {
      // 如果收合 menu 把 popup menu 重置
      setAnchorId('')
    }
  }

  const handleListMenuToggle = (category: string) => (e: MouseEvent) => {
    e.stopPropagation()

    if (menuOpenMap.has(category)) {
      const isCurrentItemOpen = menuOpenMap.get(category)
      menuOpenMap.set(category, !isCurrentItemOpen)
    } else {
      menuOpenMap.set(category, true)
    }
    setMenuOpenMap(new Map(menuOpenMap))
  }

  const handlePopupMenuClose = () => {
    setAnchorId('')
  }

  const handlePopupMenuOpen = (anchorId: string, category: string) => {
    setPopupMenu(getRoutesByCategory(categories, category))
    setAnchorId(anchorId)
  }

  return (
    <Fragment>
      <StyledSidebar variant="permanent" isSidebarOpen={isSidebarOpen}>
        <LogoToolbar handleSidebarToggle={handleSidebarToggle} />

        <List>
          {categories.map(route => {
            const { category, text, icon, routes = [] } = route
            const currCategory = getCurrCategoryByURL(categories, pathname)
            const isInCurrCategory = currCategory === category
            const isOpen = menuOpenMap.get(category) || false
            const expandIcon = isOpen ? ICON.arrowUp : ICON.arrowDown
            const anchorId = `popup-anchor-id-${category}`
            const iconColor = isInCurrCategory
              ? theme.colors.brightBlue
              : theme.colors.sidebarIconBlueGrey

            return (
              <Fragment key={category}>
                <StyledListItem
                  button
                  id={anchorId}
                  isSidebarOpen={isSidebarOpen}
                  isActive={isInCurrCategory}
                  onClick={handleListMenuToggle(category)}
                  onMouseEnter={() => {
                    if (!isSidebarOpen) {
                      handlePopupMenuOpen(anchorId, category)
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flex: '1 1 auto',
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: '50px' }}>
                      <Icon icon={icon} color={iconColor} fontSize="small" />
                    </ListItemIcon>
                    <StyledListItemText isActive={isInCurrCategory}>
                      {t(text as ParseKeys<'common'>) as string}
                    </StyledListItemText>
                  </Box>
                  <Icon
                    icon={expandIcon}
                    color={theme.colors.textPrimaryBlue}
                  />
                </StyledListItem>

                {isSidebarOpen && (
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {routes.map((route, idx) => (
                        <ListMenu key={idx} route={route} />
                      ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            )
          })}
        </List>
      </StyledSidebar>

      {!isSidebarOpen && (
        <ClickAwayListener
          anchorIds={[anchorId]}
          onClickAway={handlePopupMenuClose}
        >
          <Popup
            anchorId={anchorId}
            closePopup={handlePopupMenuClose}
            customStyle={{
              // 需要加上 Layout.headerHeight, 因為 Sidebar 中有 element 之 position 不為 static
              offsetTop: LAYOUT.headerHeight,
              offsetLeft: 5,
            }}
            placement="right-start"
            positionType="fixed"
          >
            <Paper>
              <MenuList>
                {popupMenu.map(({ path, text }) => (
                  <Link key={path} to={path}>
                    <MenuItem>
                      {t(text as ParseKeys<'common'>) as string}
                    </MenuItem>
                  </Link>
                ))}
              </MenuList>
            </Paper>
          </Popup>
        </ClickAwayListener>
      )}
    </Fragment>
  )
}

export default Sidebar
