import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormik } from 'formik'
import range from 'lodash/range'

import { SendSmsDialog, SmsEditor } from '@entities/mediumTemplates'
import {
  INITIAL_PAGINATION_RESPONSE,
  SmsProvider,
  useGetMsgChannelListQuery,
} from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks/store'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { getIntSafe } from '@shared/lib/utils/number'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'
import { ConfirmDialog } from '@shared/ui/dialogs'
import NestedList, { NestedOption } from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import theme from '@theme'
import { useWorkflowFormState } from '@widgets/workflow/_shared/WorkflowFormStateContext'

import {
  CAMPAIGN_NAME_MAX_LENGTH,
  DEFAULT_TIMING_DATA,
  FieldGrid,
  REACT_FLOW_NO_DRAG,
  Timing,
} from '../../_shared'
import {
  ActionSendSmsNodeData,
  actionSendSmsNodeSchema,
} from '../actionSendSmsNodeSchema'
import SelectSmsTemplateDialog from './SelectSmsTemplateDialog'

type ActionSendSmsNodeEditDialogProps = {
  isOpen: boolean
  initialValues: Partial<ActionSendSmsNodeData>
  onConfirm: (values: Partial<ActionSendSmsNodeData>) => void
  onClose: () => void
}

export const ActionSendSmsNodeEditDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: ActionSendSmsNodeEditDialogProps) => {
  const formState = useWorkflowFormState()

  const {
    values,
    touched,
    errors,
    isValidating,
    setFieldValue,
    setValues,
    setTouched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      campaignName: '',
      content: '',
      title: '',
      msgChannelId: undefined,
      replacementData: undefined,
      reactionTiming: undefined,
      retryHour: 24,
      ...initialValues,
    },
    validationSchema: actionSendSmsNodeSchema,
    onSubmit: values => {
      if (!formState.isEditable) {
        onClose()
        return
      }

      onConfirm({
        ...values,
        // 如果屬性的值是 undefined 會被從 values 移除，造成 object spread 失效：
        // ex.
        // const before = { reactionTiming: '1' }
        // const after = { reactionTiming: undefined } => {}
        // const merged = { ...before, ...after } => { reactionTiming: '1' }
        // const expected = { reactionTiming: undefined }
        //
        // 另外只加上 reactionTiming 是因為只有這個屬性會在 undefined 與值之間切換
        reactionTiming: values.reactionTiming,
      })
    },
  })

  useEffect(() => {
    if (!formState.isSubmitted) {
      return
    }

    setTouched(
      {
        campaignName: true,
        msgChannelId: true,
        retryHour: true,
        title: true,
        content: true,
        reactionTiming: true,
        replacementData: true,
      },
      true
    )
  }, [formState.isSubmitted, setTouched])

  const [openDialog, setOpenDialog] = useState<
    'setting' | 'warning' | 'template' | 'sendSms' | undefined
  >(isOpen ? 'setting' : undefined)

  const { t } = useTranslation([
    'audience',
    'contentTemplate',
    'workflow',
    'common',
    'settings',
  ])

  const retryHourOptions = useMemo(
    () =>
      range(1, 25).map<NestedOption>(hour => ({
        label: `${hour} ${t('audience:export_sms.retry_period_hours')}`,
        value: `${hour}`,
      })),
    [t]
  )

  const isEnableTestingSms = useAppSelector(
    state => state.auth.plan.workflow.testingSms
  )

  const { msgChannelOptions = [], isMsgChannelLoading } =
    useGetMsgChannelListQuery(
      { page: 1, perPage: THIRD_PARTY_MAX_COUNT, mediumType: 'sms' },
      {
        selectFromResult: ({
          data = INITIAL_PAGINATION_RESPONSE,
          isLoading,
        }) => ({
          msgChannelOptions: data.items.map(
            ({ id, title, integrationType, integrationId }) => ({
              value: String(id),
              label: title,
              integrationType,
              integrationId,
            })
          ),
          isMsgChannelLoading: isLoading,
        }),
      }
    )

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const { thirdPartyAppIntegrationId, thirdPartyAppName, thirdPartyAppType } =
    useMemo(() => {
      const msgChannel = msgChannelOptions.find(
        ({ value }) => value === String(values.msgChannelId)
      )

      if (!msgChannel) {
        return {
          thirdPartyAppIntegrationId: 0,
          thirdPartyAppName: '',
          thirdPartyAppType: '',
        }
      }

      return {
        thirdPartyAppIntegrationId: msgChannel.integrationId,
        thirdPartyAppType: msgChannel.integrationType,
        thirdPartyAppName: getThirdPartyProviderName(
          msgChannel.integrationType
        ),
      }
    }, [getThirdPartyProviderName, msgChannelOptions, values.msgChannelId])

  const isEnableTracking = useMemo(
    () => Boolean(values.reactionTiming),
    [values.reactionTiming]
  )

  return (
    <>
      <ConfirmDialog
        className={REACT_FLOW_NO_DRAG}
        // 加上額外的樣式為了讓 toolBar 下拉選單能夠完整展開
        contentStyles={{
          minHeight: '60vh',
          maxHeight: 'calc(100vh - 200px)',
        }}
        isOpen={openDialog === 'setting'}
        isLoading={isValidating}
        maxWidth="lg"
        modalTitle={t('workflow:node_action_sms_configuration')}
        onConfirm={handleSubmit}
        onClose={onClose}
      >
        <FieldGrid pb={2} mb={2}>
          <Box component="label" htmlFor="msgChannelId" fontWeight="medium">
            {t('workflow:node_action_third_party')} *
          </Box>

          <NestedList
            anchorId="msgChannelId"
            isFullWidth
            isDisabled={!formState.isEditable || isMsgChannelLoading}
            isError={touched.msgChannelId && Boolean(errors.msgChannelId)}
            label={t('workflow:node_action_third_party_select_hint')}
            onChange={({ value }) =>
              setFieldValue('msgChannelId', getIntSafe(value))
            }
            options={msgChannelOptions}
            popperClassName={REACT_FLOW_NO_DRAG}
            popperZIndex={Z_INDEX.dialog}
            value={`${values.msgChannelId}`}
          />
          {thirdPartyAppName && (
            <Box sx={{ gridColumnStart: 2 }}>
              {t('settings:service')}：${thirdPartyAppName}
            </Box>
          )}

          <Box component="label" htmlFor="campaignName" fontWeight="medium">
            {t('workflow:node_action_campaign_name')} *
          </Box>

          <PrimaryGreyInput
            disabled={!formState.isEditable}
            fullWidth
            id="campaignName"
            isError={touched.campaignName && Boolean(errors.campaignName)}
            onChange={({ target: { value } }) =>
              setFieldValue(
                'campaignName',
                value.substring(0, CAMPAIGN_NAME_MAX_LENGTH)
              )
            }
            placeholder={t('workflow:node_action_campaign_name_placeholder')}
            value={values.campaignName}
          />

          <Box component="label" htmlFor="title" fontWeight="medium">
            {t('workflow:node_action_sms_subject')}
          </Box>

          <PrimaryGreyInput
            disabled={!formState.isEditable}
            fullWidth
            id="title"
            onChange={({ target: { value } }) =>
              setFieldValue(
                'title',
                value.substring(0, CAMPAIGN_NAME_MAX_LENGTH)
              )
            }
            placeholder={t('workflow:node_action_sms_subject_placeholder')}
            value={values.title}
          />

          <Box component="label" htmlFor="retryHour" fontWeight="medium">
            {t('audience:export_sms.retry_period')}
          </Box>

          <NestedList
            anchorId="retryHour"
            isDisabled={!formState.isEditable}
            onChange={({ value }) =>
              setFieldValue('retryHour', getIntSafe(value))
            }
            options={retryHourOptions}
            popperClassName={REACT_FLOW_NO_DRAG}
            popperZIndex={Z_INDEX.dialog}
            value={`${values.retryHour}`}
          />
        </FieldGrid>

        <FieldGrid mb={2}>
          <Box component="label" htmlFor="isEnableTracking" fontWeight="medium">
            {t('workflow:node_action_sms_click_tracking_condition')}
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isEnableTracking}
                  color="primary"
                  disabled={!formState.isEditable}
                  id="isEnableTracking"
                  onChange={() =>
                    setFieldValue(
                      'reactionTiming',
                      values.reactionTiming ? undefined : DEFAULT_TIMING_DATA,
                      true
                    )
                  }
                />
              }
              label={
                <Box fontSize={14} fontWeight="bold">
                  {t('workflow:node_action_sms_track')}
                </Box>
              }
            />

            <Box component="span" color={theme.colors.chartRed}>
              {errors.reactionTiming}
            </Box>
          </Box>

          <Collapse
            in={isEnableTracking}
            timeout="auto"
            sx={{ gridColumnStart: 2 }}
          >
            <Box pl={3} pt={2} borderTop={`1px solid ${theme.colors.black6}`}>
              <Timing
                isEditable={formState.isEditable && isEnableTracking}
                timeData={values.reactionTiming}
                onTimeDataChange={reactionTiming => {
                  if (isEnableTracking) {
                    setFieldValue('reactionTiming', reactionTiming)
                  }
                }}
                isHideDurationHourAndMinute
                defaultTimeData={DEFAULT_TIMING_DATA}
              />
            </Box>
          </Collapse>
        </FieldGrid>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box component="label" htmlFor="content" fontWeight="medium">
            {t('contentTemplate:sms_content')}
          </Box>

          <div>
            {isEnableTestingSms && (
              <Button
                variant="outlined"
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => setOpenDialog('sendSms')}
                disabled={!thirdPartyAppIntegrationId}
              >
                {t('contentTemplate:send_test_sms')}
              </Button>
            )}

            <Button
              disabled={!formState.isEditable}
              variant="outlined"
              color="primary"
              onClick={() => {
                if (values.title || values.content) {
                  setOpenDialog('warning')
                  return
                }

                setOpenDialog('template')
              }}
              startIcon={<Icon icon={ICON.plusCircle} color="inherit" />}
            >
              {t('contentTemplate:load_content_template')}
            </Button>
          </div>
        </Box>

        <Box mb={2}>
          <SmsEditor
            smsProvider={thirdPartyAppType as SmsProvider}
            integrationId={thirdPartyAppIntegrationId}
            isError={
              touched.content &&
              (Boolean(errors.content) || Boolean(errors.replacementData))
            }
            isEditable={formState.isEditable}
            content={values.content}
            replacementData={values.replacementData}
            onContentChange={content => setFieldValue('content', content)}
            onReplacementDataChange={replacementData =>
              setFieldValue('replacementData', replacementData)
            }
          />

          <Box mt={1} color={theme.colors.chartRed}>
            {errors.replacementData}
          </Box>
        </Box>
      </ConfirmDialog>

      <ConfirmDialog
        className={REACT_FLOW_NO_DRAG}
        modalTitle={t('workflow:node_action_sms_replace_template_hint')}
        isOpen={openDialog === 'warning'}
        onConfirm={() => setOpenDialog('template')}
        onClose={() => setOpenDialog('setting')}
      />

      <SendSmsDialog
        className={REACT_FLOW_NO_DRAG}
        isOpen={openDialog === 'sendSms'}
        onClose={() => setOpenDialog('setting')}
        sendType="sms"
        integrationId={thirdPartyAppIntegrationId}
        messageData={{
          subject: values.title,
          message: values.content,
          replacementData: values.replacementData,
        }}
      />

      <SelectSmsTemplateDialog
        isOpen={openDialog === 'template'}
        onConfirm={({ title, body, replacementData }) => {
          setValues(prev => ({
            ...prev,
            title,
            content: body,
            replacementData,
          }))
          setOpenDialog('setting')
        }}
        onClose={() => setOpenDialog('setting')}
      />
    </>
  )
}

export default ActionSendSmsNodeEditDialog
