import { useState } from 'react'
import Box from '@mui/material/Box'

import type { ChartData } from '@shared/ui/charts'
import {
  BarChart,
  BarChartDisplayType,
  COLORS,
  EventChartType,
  EventRulePieChart,
  getLongestSeriesLenIdx,
  IndicatorList,
  LineChart,
  SizeMode,
} from '@shared/ui/charts'

import BarChartDisplayTypeRadioButtons from '../_shared/BarChartDisplayTypeRadioButtons'
import GroupPagination from '../_shared/GroupPagination'

type Props = {
  eventId: number
  chartTitle?: string
  data: ChartData[]
  size?: SizeMode
  defaultChartType?: EventChartType
  barChartType?: BarChartDisplayType
}

const ChartControl = ({
  eventId,
  chartTitle = '',
  data,
  size,
  defaultChartType,
  barChartType = 'bar',
}: Props) => {
  // 如果有對比時間的資料，只能看 line chart
  const isCompareData = data.length > 1

  const [chartType] = useState<EventChartType>(
    isCompareData ? 'line_chart' : defaultChartType || 'line_chart'
  )
  const [barType, setBarType] = useState<BarChartDisplayType>(barChartType)

  // 在現在時間與對比時間中，選 series 長度較長的 idx 做 iteration
  // 選最長的 series 作為 visibilityFlags 的 length
  const { maxLen, longestSeriesLenIdx } = getLongestSeriesLenIdx(data)
  const defaultVisibilityFlags = Array(maxLen).fill(true)

  const [visibilityFlags, setVisibilityFlags] = useState(defaultVisibilityFlags)
  const colors = COLORS.map((data, idx) =>
    visibilityFlags[idx] ? data : undefined
  ).filter(Boolean) as string[]

  const displayData = data.map(obj => ({
    categories: obj.categories,
    series: obj.series
      .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
      .filter(Boolean) as ChartData['series'],
  }))

  return (
    <Box sx={{ position: 'relative' }}>
      {chartType === 'bar_chart' && (
        // 由於空間不足將元件疊到圖表上方，增加額外 z-index 避免被覆蓋
        <Box sx={{ position: 'absolute', top: 0, right: 4, zIndex: 1 }}>
          <BarChartDisplayTypeRadioButtons
            displayType={barType}
            onDisplayTypeChange={setBarType}
          />
        </Box>
      )}

      {chartType === 'line_chart' && (
        <LineChart
          chartTitle={chartTitle}
          colors={colors}
          data={displayData}
          size={size}
        />
      )}

      {chartType === 'bar_chart' && (
        <BarChart
          displayType={barType}
          chartTitle={chartTitle}
          colors={colors}
          // BarChart 不支援對比時間
          data={displayData[0]}
          size={size}
        />
      )}

      {chartType === 'pie_chart' && (
        <EventRulePieChart
          colors={colors}
          // BarChart 不支援對比時間
          data={displayData[0]}
          size="small"
        >
          {({ indicatorList }) => (
            <IndicatorList indicatorList={indicatorList} />
          )}
        </EventRulePieChart>
      )}

      {data.length > 0 && data[longestSeriesLenIdx].series.length > 0 && (
        <GroupPagination
          id={eventId}
          data={data}
          visibilityFlags={visibilityFlags}
          setVisibilityFlags={setVisibilityFlags}
        />
      )}
    </Box>
  )
}

export default ChartControl
