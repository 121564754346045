import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

import { GROUP_NAME_ACTION_BUTTON_ID } from '../_shared/constants'
import type { AnalyticsType } from '../_shared/type'
import { GroupNameButtonWrapper } from './style'

type Props = {
  id: number
  name: string
  isActive: boolean
  onClick: () => void
  dropDownOptions?: {
    label: string
    value: string
    func: (id: number, name: string, type: AnalyticsType) => void
  }[]
  analyticsType: AnalyticsType
}

const ChartGroupName = ({
  id,
  name,
  isActive,
  onClick,
  analyticsType,
  dropDownOptions = [],
}: Props) => {
  const { t } = useTranslation('dashboard')

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)

  const hasAction = dropDownOptions.length > 0

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        setIsOpen={currIsOpen => {
          if (!currIsOpen) {
            setDropdownOpen(false)
          }
        }}
        placement="bottom-end"
        popperWidth={140}
        anchorElem={
          <GroupNameButtonWrapper
            ml="12px"
            pl="18px"
            pr={hasAction ? '-18px' : 0}
            color={isActive ? 'primary' : 'inherit'}
            style={{
              backgroundColor: isActive ? theme.colors.brightBlue : '',
            }}
            variant="contained"
            size="small"
            onClick={e => {
              // TODO: 這邊改法微髒，但是為了點擊區域可以維持在整個按鈕上，所以先這樣做
              const target = e.target as HTMLElement

              // 這邊的判斷是為了避免點擊到下拉選單的時候，會觸發 onClick
              if (target.id !== GROUP_NAME_ACTION_BUTTON_ID) {
                onClick()
              }
            }}
            endIcon={
              <i
                id={GROUP_NAME_ACTION_BUTTON_ID}
                onClick={() => {
                  if (hasAction) {
                    setDropdownOpen(!dropdownOpen)
                  }
                }}
                style={{
                  color: !hasAction
                    ? 'transparent'
                    : isActive
                    ? theme.colors.white
                    : theme.colors.textPrimaryBlue,
                  marginRight: !hasAction ? -5 : 0,
                }}
                className={ICON.ellipsisV}
              />
            }
          >
            <span>{name || t('widget_default_group')}</span>
          </GroupNameButtonWrapper>
        }
      >
        <MenuList onMouseLeave={() => {}}>
          {dropDownOptions.map((option, idx) => (
            <MenuItem
              key={`${option.value}_${idx}`}
              onClick={() => {
                option.func(id, name, analyticsType)
                setDropdownOpen(false)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Dropdown>
    </>
  )
}

export default ChartGroupName
