import { useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { IconButton } from '@mui/material'

import { LAYOUT } from '@shared/model/constants/styles'

import SidebarContext from './contexts'

const StyledToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 3px;
  min-height: ${LAYOUT.headerHeight}px;
`

const IKalaLogoIcon = styled.img`
  margin-right: 40px;
  height: 26px;
`

const StyledIconButton = styled.div<{ isSidebarOpen: boolean }>`
  padding-right: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '8px')};
`

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
`

type Props = {
  handleSidebarToggle: () => void
}

const IconToolbar = ({ handleSidebarToggle }: Props) => {
  const { isSidebarOpen } = useContext(SidebarContext)

  return (
    <StyledToolbar>
      {isSidebarOpen && (
        // 導向根頁面，實際轉址的路徑交給 App.tsx 的 DefaultRedirectRoutes 決定
        <Link to={'/'}>
          <IKalaLogoIcon src="/images/ikala-cdp-logo.png" />
        </Link>
      )}
      <StyledIconButton
        isSidebarOpen={isSidebarOpen}
        onClick={handleSidebarToggle}
      >
        <IconButton>
          <MenuIcon src="/images/menu.svg" />
        </IconButton>
      </StyledIconButton>
    </StyledToolbar>
  )
}

export default IconToolbar
