import DefaultReactCalendar from 'react-calendar'
import styled from '@emotion/styled'
import MuiButton, { ButtonProps } from '@mui/material/Button'

import BaseCard from '@shared/ui/Card'

export const DurationButton = styled(
  ({
    bgColor,
    ...otherProps
  }: {
    bgColor?: string
  } & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton {...otherProps} />
  )
)`
  && {
    padding: 8px;
    padding-right: ${({ disabled }) => (disabled ? 16 : 8)}px;
    padding-left: 16px;
    height: 42px;

    background-color: ${({ theme, bgColor, disabled }) =>
      disabled ? theme.colors.veryLightBlueTwo : bgColor || theme.colors.white};
    color: ${({ theme }) => theme.colors.lightNavyBlue};
    font-size: 13px;

    text-transform: none;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};

    :hover {
      background-color: ${({ theme }) => theme.colors.lightGreyBlueTwo};
    }
  }

  &.MuiButton-root.Mui-disabled {
    color: ${({ theme }) => theme.colors.lightNavyBlue};
  }
`

export const Card = styled(BaseCard)`
  padding: 0 0 12px 0;
`

export const Wrapper = styled.div<{
  justifyContent?: 'flex-start' | 'flex-end'
  flexDirection?: 'row' | 'column'
  alignItems?: 'stretch' | 'center'
  padding?: string
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  padding: ${({ padding }) => padding || 0};
`

export const ModeTab = styled.div<{ isActive?: boolean; isHide?: boolean }>`
  flex: 1;

  display: ${({ isHide }) => (isHide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;

  padding: 12px;
  width: 250px;

  border-bottom: solid
    ${({ isActive, theme }) =>
      isActive
        ? `3px ${theme.colors.brightBlue}`
        : `1px ${theme.colors.black6}`};

  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brightBlue : theme.colors.cloudyBlue};

  cursor: pointer;
  transition: 0.25s;
`

export const ShortcutButton = styled(MuiButton)`
  && {
    margin-bottom: 8px;
    padding: 3px 0;
    width: calc((100% - 12px) / 2);

    background-color: ${({ theme }) => theme.colors.bgPaleGrey};
    color: ${({ theme }) => theme.colors.brightBlue};
    font-size: 12px;
    text-transform: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.bgPaleGrey};
      color: ${({ theme }) => theme.colors.brightBlue};
      opacity: 0.7;
    }
  }
`

export const ShortcutWrapper = styled.div`
  align-self: baseline;
  display: flex;
  flex-wrap: wrap;

  padding: 8px 12px;
  width: 250px;

  ${ShortcutButton}:nth-of-type(even) {
    margin-left: 12px;
  }
`

export const ReactCalendar = styled(DefaultReactCalendar)`
  padding: 8px 12px;
  width: 250px;
`

export const Header = styled.div`
  flex: 1;
  padding: 12px 12px 6px 12px;

  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
`

export const ButtonWrapper = styled.div`
  margin-right: 12px;
`

export const HorizontalLine = styled.div`
  margin-bottom: 12px;

  border: 1px solid ${({ theme }) => theme.colors.black6};
`

export const DatePickerWrapper = styled.div`
  margin-left: 12px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
`
