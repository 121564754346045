import Box from '@mui/material/Box'

type Props = {
  children: React.ReactNode
}

const Column = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gap: 1,
        alignItems: 'center',
        gridTemplateColumns: 'auto minmax(240px, 1fr)',
      }}
    >
      {children}
    </Box>
  )
}

export default Column
