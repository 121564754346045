import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import type { WorkflowListItem } from '@shared/api/rtkQuery/workflow'
import { useAppSelector } from '@shared/lib/hooks/store'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import {
  formatDateDisplay,
  formatDateTimeDisplay,
} from '@shared/lib/utils/time'
import {
  CATEGORY,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import Switch from '@shared/ui/Switch'
import type { TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { TooltipIcon } from '../_shared'

const TextNoWrap = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const NAME_MIN_WIDTH = 140

type Props = {
  isOpenDeleteDialog: boolean
  isOpenRenameDialog: boolean
  openActionMenuId: number | undefined
  setIsOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenRenameDialog: React.Dispatch<React.SetStateAction<boolean>>
  setOpenActionMenuId: React.Dispatch<React.SetStateAction<number | undefined>>
  setRenameText: React.Dispatch<React.SetStateAction<string>>
  setToggleWorkflowId: React.Dispatch<React.SetStateAction<number | undefined>>
  totalCount: number
}

const useWorkflowListColumns = ({
  isOpenDeleteDialog,
  isOpenRenameDialog,
  openActionMenuId,
  setIsOpenDeleteDialog,
  setIsOpenRenameDialog,
  setOpenActionMenuId,
  setRenameText,
  setToggleWorkflowId,
  totalCount,
}: Props) => {
  const { t } = useTranslation(['common', 'workflow'])
  const maxCount = useAppSelector(state => state.auth.plan.workflow.maxCount)
  const isEnableSaving = useAppSelector(
    state => state.auth.plan.workflow.enableWorkflow
  )
  const { checkHasArchivedTracingUrlInReplacementData } = useTracingUrlStatus()

  const columns: TableColumn<WorkflowListItem>[] = useMemo(
    () => [
      {
        colRatio: 0.25,
        field: 'id',
        headerName: '',
        valueFormatter: (_, { campaigns, subscriptions }) => {
          let errorTitle = ''

          const campaignStatus = (campaigns ?? []).map(
            x => x.lastFinishSendStatus
          )

          if (campaignStatus.includes('msg_channel_not_found')) {
            errorTitle = t('workflow:errors.third_party_has_been_deleted')
          }

          const subscriptionStatus = (subscriptions ?? []).map(
            x => x.lastFinishSendStatus
          )

          if (subscriptionStatus.includes('bad')) {
            errorTitle = t('workflow:errors.subscription_endpoint_invalid')
          }

          if (subscriptionStatus.includes('deleted')) {
            errorTitle = t('workflow:errors.subscription_endpoint_deleted')
          }

          if (errorTitle) {
            return (
              <Tooltip title={errorTitle}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="#ff614f"
                >
                  <Icon
                    color="inherit"
                    fontSize="small"
                    icon={ICON.exclamationTriangle}
                  />
                </Box>
              </Tooltip>
            )
          }

          const hasTracingUrlArchived = campaigns?.some(campaign =>
            checkHasArchivedTracingUrlInReplacementData(
              campaign?.replacementData
            )
          )

          if (hasTracingUrlArchived) {
            return (
              <Tooltip
                title={t(
                  'workflow:errors.tracing_url_is_archived_plz_recreate'
                )}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="#ff614f"
                >
                  <Icon
                    color="inherit"
                    fontSize="small"
                    icon={ICON.exclamationTriangle}
                  />
                </Box>
              </Tooltip>
            )
          }
        },
      },
      {
        headerName: 'name',
        field: 'name',
        valueFormatter: name => <Box minWidth={NAME_MIN_WIDTH}>{name}</Box>,
      },
      {
        headerName: 'workflow_trigger_type',
        field: 'triggerNodeFlowSubtype',
        colRatio: 0.5,
        valueFormatter: (triggerNodeType, { endAt }) => {
          // 只有 enter_audience_rule 才會有結束日
          if (triggerNodeType === 'enter_audience_rule') {
            return (
              <Box display="flex" whiteSpace="nowrap">
                {t('workflow:workflow_trigger_periodically')}
                {endAt && (
                  <TooltipIcon
                    title={t('workflow:workflow_end_date_at', {
                      date: formatDateDisplay(endAt),
                    })}
                  />
                )}
              </Box>
            )
          }

          if (triggerNodeType === 'current_audience_rule') {
            return t('workflow:workflow_trigger_one-time')
          }

          return '-'
        },
      },
      {
        align: 'center',
        headerName: 'status',
        field: 'id',
        colRatio: 0.5,
        valueFormatter: (
          id,
          { status: typedStatus, triggerNodeFlowSubtype: triggerNodeType }
        ) => {
          // 靜態名單 && 已啟用 || 過期 : disabled

          const isDisabled =
            (triggerNodeType === 'current_audience_rule' &&
              typedStatus === 'enabled') ||
            typedStatus === 'end'

          return (
            // 草稿、啟用、停用
            <Tooltip title={t(`common:${typedStatus}`)}>
              {/** tooltip & span 元件在這邊會歪掉，額外加上一層 div */}
              <div>
                {typedStatus === 'draft' ? (
                  t('workflow:draft')
                ) : (
                  <Switch
                    disabled={isDisabled}
                    checked={typedStatus === 'enabled'}
                    name={`workflowStatus_${id}`}
                    onClick={event => {
                      event.stopPropagation()

                      setToggleWorkflowId(getIntSafe(id))
                    }}
                  />
                )}
              </div>
            </Tooltip>
          )
        },
      },
      {
        headerName: 'workflow_trigger',
        field: 'sourceName',
        valueFormatter: sourceName => {
          return sourceName
            ? t('workflow:trigger_source', { name: sourceName })
            : '-'
        },
      },
      {
        headerName: 'workflow_total_triggers',
        field: 'totalTriggeredUser',
        colRatio: 0.5,
        align: 'right',
        info: t('workflow:instruction.workflow_total_triggers_info'),
        valueFormatter: totalTriggeredUser => (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatNumber(totalTriggeredUser)}
          </span>
        ),
      },
      {
        headerName: 'workflow_completion_rate',
        field: 'id',
        info: t('workflow:instruction.workflow_completion_rate_info'),
        valueFormatter: (
          _,
          { totalCompletedUser: curr = 0, totalTriggeredUser: total = 0 }
        ) => {
          if (total === 0) {
            return `0%`
          }

          // 轉回數字是為了移除小數點後的 0 (ex. 5.00)
          return `${+((curr / total) * 100).toFixed(2)}%`
        },
        colRatio: 0.5,
      },
      {
        headerName: 'workflow_total_sent',
        field: 'id',
        valueFormatter: (
          _,
          { totalSentEmail, totalSentLinePush, totalSentSms }
        ) => (
          <>
            <TextNoWrap>
              {t('common:medium_type.email')}: {totalSentEmail}
            </TextNoWrap>
            <TextNoWrap>LINE: {totalSentLinePush}</TextNoWrap>
            <TextNoWrap>
              {t('common:medium_type.sms')}: {totalSentSms}
            </TextNoWrap>
          </>
        ),
        align: 'right',
        paddingTop: 1,
        paddingBottom: 1,
      },
      {
        headerName: 'last_edited_by',
        field: 'lastUpdatedBy',
      },
      {
        headerName: 'last_edited_at',
        field: 'updatedAt',
        valueFormatter: lastEditedAt => formatDateTimeDisplay(lastEditedAt),
      },
      {
        headerName: '',
        field: 'id',
        colRatio: 0.5,
        valueFormatter: (_, { id, name, status, triggerNodeFlowSubtype }) => {
          const currId = getIntSafe(id)
          return (
            <Dropdown
              isOpen={openActionMenuId === currId}
              setIsOpen={() => {
                if (isOpenDeleteDialog || isOpenRenameDialog) {
                  setOpenActionMenuId(openActionMenuId)
                  return
                }

                setOpenActionMenuId(undefined)
              }}
              anchorElem={
                <IconButton
                  onClick={event => {
                    event.stopPropagation()
                    setOpenActionMenuId(currId)
                  }}
                >
                  <Icon
                    icon={ICON.ellipsisV}
                    color={theme.colors.textPrimaryBlue}
                    fontSize="small"
                  />
                </IconButton>
              }
              placement="left-start"
            >
              <MenuList
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation()
                }}
              >
                {totalCount >= maxCount ? (
                  <Tooltip
                    title={t('workflow:instruction.upper_limit', {
                      maxCount,
                    })}
                  >
                    <div>
                      <MenuItem disabled={true}>
                        {t('workflow:copy_and_edit')}
                      </MenuItem>
                    </div>
                  </Tooltip>
                ) : (
                  <Link
                    to={`/${CATEGORY.ma}/${PAGE_ROOT.workflows}/new?${QUERY_STRING.audiences.referenceId}=${currId}`}
                  >
                    <MenuItem>{t('workflow:copy_and_edit')}</MenuItem>
                  </Link>
                )}

                <MenuItem
                  onClick={() => {
                    setRenameText(name)
                    setIsOpenRenameDialog(true)
                  }}
                >
                  {t('common:rename')}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setIsOpenDeleteDialog(true)
                  }}
                >
                  {t('common:delete')}
                </MenuItem>

                {isEnableSaving &&
                  triggerNodeFlowSubtype === 'enter_audience_rule' && (
                    <MenuItem
                      onClick={() => {
                        setToggleWorkflowId(getIntSafe(id))
                      }}
                    >
                      {status === 'enabled'
                        ? t('common:disable')
                        : t('common:enable')}
                    </MenuItem>
                  )}
              </MenuList>
            </Dropdown>
          )
        },
      },
    ],
    [
      t,
      checkHasArchivedTracingUrlInReplacementData,
      setToggleWorkflowId,
      openActionMenuId,
      totalCount,
      maxCount,
      isEnableSaving,
      isOpenDeleteDialog,
      isOpenRenameDialog,
      setOpenActionMenuId,
      setRenameText,
      setIsOpenRenameDialog,
      setIsOpenDeleteDialog,
    ]
  )

  return columns
}

export default useWorkflowListColumns
