import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from 'react'
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Box, IconButton as MuiIconButton } from '@mui/material'

import { formatNumberWithSign } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import StatusCaption from './StatusCaption'

const Wrapper = styled.div<{ isDarkBGColor: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 272px;

  border: 2px dashed ${({ theme }) => theme.colors.brightBlue};
  border-radius: 5px;
  background-color: ${({ theme, isDarkBGColor }) =>
    isDarkBGColor ? theme.colors.bgIceBlue : theme.colors.bgPrimaryGrey};

  transition: background-color 0.25s;
`

const ProgressWrapper = styled.div<{ isLessHeight?: boolean }>`
  display: flex;

  margin-bottom: 16px;
  padding: 12px 20px;
  width: 50%;
  min-width: 400px;
  max-width: 560px;
  height: ${({ isLessHeight }) => (isLessHeight ? 80 : 96)}px;

  background-color: ${({ theme }) => theme.colors.white};
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  font-size: 40px;
`

const ProgressInfoWrapper = styled.div`
  /* 56px 是左側固定的 file upload icon */
  width: calc(100% - 56px);
`

const FilenameText = styled.div`
  overflow: hidden;
  max-width: 80%;
  height: 31px;

  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  text-overflow: ellipsis;
  line-height: 33px;

  white-space: nowrap;
`

const ProgressBarBG = styled.div`
  position: relative;

  /*  因為右邊的 delete icon 有 padding，所以設 98% 看起來左邊比較對齊 */
  width: 98%;
  height: 10px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.veryLightBlueTwo};
`

const ProgressBar = styled.div<{ progressWidth: number }>`
  position: absolute;

  width: ${({ progressWidth }) => progressWidth || 0}%;
  height: 10px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.brightBlue};

  transition: 0.25s;
`

const IconButton = styled(MuiIconButton)`
  && {
    padding: 4px;
  }
`

const ProgressLabel = styled.div`
  margin-right: 6px;

  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 9px;
`

const CloudUploadIconWrapper = styled.div`
  margin-bottom: 24px;

  font-size: 88px;
`

const Caption = styled.div`
  margin: -20px 0 4px 0;

  color: ${({ theme }) => theme.colors.sidebarIconBlueGrey};
  font-size: 14px;
`

const ChooseFileLabel = styled.label`
  margin-right: 0.5em;

  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 14px;
  text-decoration-line: underline;
  cursor: pointer;
`

const HiddenFileInput = styled.input`
  width: 0;
  visibility: hidden;
`

const DropFileLabel = styled.label`
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 14px;
`

const UPLOAD_STATUS = {
  initial: 'initial',
  start: 'start',
  end: 'end',
  error: 'error',
}

export type UploadStatus = keyof typeof UPLOAD_STATUS

type Props = {
  fileExtension: string
  fileMaxSize: number
  uploadStatus: UploadStatus
  setUploadStatus: Dispatch<SetStateAction<UploadStatus>>
  setFile: Dispatch<SetStateAction<File | null>>
}

const FileUpload = ({
  fileExtension,
  fileMaxSize,
  uploadStatus,
  setUploadStatus,
  setFile,
}: Props) => {
  const { t } = useTranslation('dataImport')

  const [percentLoaded, setPercentLoaded] = useState(0)
  const [filename, setFilename] = useState('')

  const handleUploadFileByFileReader = (file: File) => {
    if (file.size >= fileMaxSize) {
      setUploadStatus('error')
      setPercentLoaded(0)
      setFilename(file.name)
      setFile(null)
      return
    }

    setFilename(file.name)

    const reader: FileReader = new FileReader()

    reader.onloadstart = () => {
      setUploadStatus('start')
    }

    // note: onprogress() 裡無法得知 useState 的改變
    reader.onprogress = e => {
      // 確定 e 是否為 ProgressEvent
      if (e.lengthComputable) {
        const percentLoaded = Math.round((e.loaded / e.total) * 100)
        setPercentLoaded(percentLoaded)
      }
    }

    reader.onload = () => {
      setFile(file)
      setUploadStatus('end')
    }

    reader.onerror = () => {
      setUploadStatus('error')
    }

    reader.onabort = () => {
      setUploadStatus('initial')
    }

    // 防止檔案太大超過 memory 上限
    reader.readAsArrayBuffer(file)
  }

  const checkIsFilesValidToDrop = (files: FileList) => {
    const onlyOneFile = files.length === 1
    const isFileTypeCorrect = files[0]?.name?.endsWith(fileExtension)

    return onlyOneFile && isFileTypeCorrect
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (!files || files.length === 0 || !checkIsFilesValidToDrop(files)) {
      return
    }
    handleUploadFileByFileReader(files[0])
  }

  const handleReset = () => {
    setUploadStatus('initial')
    setPercentLoaded(0)
    setFilename('')
    setFile(null)
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      const files = monitor.getItem<{ files: FileList }>()?.files

      if (checkIsFilesValidToDrop(files)) {
        handleUploadFileByFileReader(files[0])
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isDragTargetHover = canDrop && isOver

  return (
    <Wrapper ref={drop} isDarkBGColor={isDragTargetHover}>
      {uploadStatus === 'initial' && (
        <Fragment>
          <CloudUploadIconWrapper>
            <Icon
              icon={ICON.cloudUpload}
              color={
                isDragTargetHover
                  ? theme.colors.brightBlue
                  : theme.colors.lightGreyBlueOne
              }
              fontSize="inherit"
            />
          </CloudUploadIconWrapper>
          <Caption>
            {t('upload.allow_file', {
              fileExtension: fileExtension.toUpperCase(),
              fileSize: formatNumberWithSign(fileMaxSize, 0),
            })}
          </Caption>
          <Box display="flex" justifyContent="center">
            <ChooseFileLabel htmlFor="dm-import-file-input">
              {t('upload.choose_file')}
            </ChooseFileLabel>
            <HiddenFileInput
              type="file"
              accept={fileExtension}
              onChange={handleFileChange}
              id="dm-import-file-input" // id 是給 ChooseFileLabel 用的
            />

            <DropFileLabel>{t('upload.drop_file_here')}</DropFileLabel>
          </Box>
        </Fragment>
      )}

      {(uploadStatus === 'start' || uploadStatus === 'end') && (
        <Fragment>
          <ProgressWrapper>
            <IconWrapper>
              <Icon
                icon={ICON.fileUploadAlt}
                color={theme.colors.brightBlue}
                fontSize="inherit"
              />
            </IconWrapper>

            <ProgressInfoWrapper>
              <Box display="flex" justifyContent="space-between" mb={0.5}>
                <FilenameText>{filename}</FilenameText>

                {uploadStatus === 'end' && (
                  <IconButton onClick={handleReset}>
                    <Icon
                      icon={ICON.trashAlt}
                      color={theme.colors.textPrimaryBlue}
                      fontSize="small"
                    />
                  </IconButton>
                )}
              </Box>

              <Box display="flex" mb={1}>
                <ProgressBarBG>
                  <ProgressBar progressWidth={percentLoaded} />
                </ProgressBarBG>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <ProgressLabel>
                  {percentLoaded === 100
                    ? t('upload.success')
                    : `${t('upload.processing')}... ${percentLoaded}%`}
                </ProgressLabel>
              </Box>
            </ProgressInfoWrapper>
          </ProgressWrapper>

          <StatusCaption uploadStatus={uploadStatus} />
        </Fragment>
      )}

      {uploadStatus === 'error' && (
        <Fragment>
          <ProgressWrapper isLessHeight>
            <IconWrapper>
              <Icon
                icon={ICON.fileTimesAlt}
                color={theme.colors.orangeyRed}
                fontSize="inherit"
              />
            </IconWrapper>

            <ProgressInfoWrapper>
              <Box display="flex" justifyContent="space-between" mb={0.5}>
                <FilenameText>{filename || t('upload.failure')}</FilenameText>
              </Box>

              <Box display="flex" mb={1}>
                <ProgressBarBG />
              </Box>
            </ProgressInfoWrapper>
          </ProgressWrapper>

          <StatusCaption
            uploadStatus={uploadStatus}
            onTryAgainButtonClick={handleReset}
          />
        </Fragment>
      )}
    </Wrapper>
  )
}

export default FileUpload
