import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import SectionTitle from '@shared/ui/SectionTitle'

import RfmCardList from './RfmCardList'
import UserValuesTable from './UserValuesTable'

const SectionAnalytics = () => {
  const { t } = useTranslation(['userProfile'])
  return (
    <Box component="section">
      <SectionTitle title={t('userProfile:analysis')} />
      <Box sx={{ display: 'grid', gap: 4 }}>
        <RfmCardList />
        <UserValuesTable />
      </Box>
    </Box>
  )
}

export default SectionAnalytics
