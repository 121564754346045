import { useTranslation } from 'react-i18next'
import type { OrderedSummary } from 'src/shared/api/rtkQuery'

import { NO_DATA } from '@widgets/audience/model/constant'

type Props = {
  orderedByCountSummary: OrderedSummary | undefined
}

const useGroupData = ({ orderedByCountSummary }: Props): OrderedSummary => {
  const { t } = useTranslation(['audience'])

  if (!orderedByCountSummary) {
    return []
  }

  return orderedByCountSummary.map(item => ({
    ...item,
    groupName:
      item.groupName === NO_DATA ? t('audience:no_data') : item.groupName,
  }))
}

export default useGroupData
