import type { ThirdPartyAppProvider } from '@shared/api/rtkQuery'
import { THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST } from '@shared/model/constants/thirdParty'

const getProviderGroup = (provider: ThirdPartyAppProvider | '') => {
  if (provider === '') {
    return ''
  }

  return THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST.find(x =>
    x.providerList.includes(provider)
  )?.group
}

export default getProviderGroup
