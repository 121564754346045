import styled from '@emotion/styled'

import { DeepBlueGradientButton } from '@shared/ui/buttons'

// 範例： AudienceBuilder 的 Duplicate 按鈕
export const LightBlueGradientButton = styled(DeepBlueGradientButton)`
  && {
    background-image: linear-gradient(
      to left,
      ${({ theme }) => theme.colors.chartLightBlue},
      ${({ theme }) => theme.colors.brightSkyBlue}
    );

    &:hover {
      background-image: linear-gradient(
        ${({ theme }) => theme.colors.azureFour},
        ${({ theme }) => theme.colors.azureFour}
      );
    }
  }
`

export default LightBlueGradientButton
