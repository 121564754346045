import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'

import type { JobStatus } from '@shared/api/rtkQuery/audience'
import { FailedIcon, ProgressIcon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
`

type StatusWithTooltipProps = {
  status: JobStatus
}

const StatusWithTooltip = ({ status }: StatusWithTooltipProps) => {
  const { t } = useTranslation(['common'])

  if (status === 'created' || status === 'processing') {
    return (
      <Tooltip title={t('common:creating')}>
        <IconContainer>
          <ProgressIcon color={theme.colors.brightBlue} />
        </IconContainer>
      </Tooltip>
    )
  }

  if (status === 'failed') {
    return (
      <Tooltip title={t('common:failure_create')}>
        <IconContainer>
          <FailedIcon color={theme.colors.blueyGrey} />
        </IconContainer>
      </Tooltip>
    )
  }

  return null
}

export default StatusWithTooltip
