import type { UserProfileEventReport } from '@shared/api/rtkQuery'

const calculateEventReportData = (
  eventReport: UserProfileEventReport['eventReport']
) => {
  if (!eventReport || eventReport.length === 0) {
    return []
  }

  return eventReport[0].values.map((_, index) =>
    eventReport.reduce(
      (row, event) => {
        row[event.name] = event.values[index]
        return row
      },
      { id: [String(index)] } as Record<string, string[]>
    )
  )
}

export default calculateEventReportData
