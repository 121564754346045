import {
  INITIAL_PAGINATION_RESPONSE,
  ThirdPartyAppGroup,
  ThirdPartyAppIntegrationListItem,
  useGetThirdPartyAppIntegrationListQuery,
} from '@shared/api/rtkQuery'
import {
  THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST,
  THIRD_PARTY_MAX_COUNT,
  ThirdPartyAppProviderListGroup,
} from '@shared/model/constants/thirdParty'

export type ThirdPartyGroupProviderListMap = Record<
  ThirdPartyAppGroup,
  {
    group: ThirdPartyAppGroup
    thirdPartyAppList: Pick<
      ThirdPartyAppIntegrationListItem,
      'id' | 'name' | 'type' | 'lineOaName'
    >[]
  }
>

const initialThirdPartyGroupProviderListMapFactory =
  (): ThirdPartyGroupProviderListMap => ({
    line: { group: 'line', thirdPartyAppList: [] },
    sms: { group: 'sms', thirdPartyAppList: [] },
    edm: { group: 'edm', thirdPartyAppList: [] },
    iterable: { group: 'iterable', thirdPartyAppList: [] },
  })

const useThirdPartyGroupProviderListMap = (
  groupProviderList: ThirdPartyAppProviderListGroup[] = THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST
): ThirdPartyGroupProviderListMap => {
  const {
    thirdPartyGroupProviderListMap = initialThirdPartyGroupProviderListMapFactory(),
  } = useGetThirdPartyAppIntegrationListQuery(
    {
      page: 1,
      perPage: THIRD_PARTY_MAX_COUNT,
    },
    {
      selectFromResult: ({ data = INITIAL_PAGINATION_RESPONSE }) => {
        const thirdPartyGroupProviderListMap =
          data.items.reduce<ThirdPartyGroupProviderListMap>((acc, curr) => {
            const group = groupProviderList.find(x =>
              x.providerList.includes(curr.type)
            )?.group

            if (!group) {
              return acc
            }

            acc[group].thirdPartyAppList.push(curr)

            return acc
          }, initialThirdPartyGroupProviderListMapFactory())

        return {
          thirdPartyGroupProviderListMap,
        }
      },
    }
  )

  return thirdPartyGroupProviderListMap
}

export default useThirdPartyGroupProviderListMap
