import Box from '@mui/material/Box'

import theme from '@theme'

import BasicInput, { Props as BasicInputProps } from './BasicInput'

type Props = BasicInputProps & {
  isError?: boolean
  hasShadow?: boolean
}

const SearchInput = ({
  defaultValue,
  placeholder,
  isError,
  hasShadow,
  onSearch,
  onChange,
}: Props) => (
  <Box
    sx={{
      width: '100%',
      form: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        pl: 2,
        width: '100%',
        minWidth: '280px',
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        outline: theme =>
          isError ? `1px solid ${theme.colors.orangeyRed}` : 'none',
        boxShadow: theme =>
          hasShadow ? `0px 4px 9px ${theme.colors.black6}` : 'none',
      },
      input: {
        width: '100%',
        height: 42,
        border: 'none',
        fontSize: '13px',
        '&::placeholder': {
          color: theme.colors.textSecondBlue,
        },
        '&:focus': {
          outline: 'none',
        },
      },
      button: {
        width: '48px',
        height: '42px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
    }}
  >
    <BasicInput
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
    />
  </Box>
)

export default SearchInput
