import Box from '@mui/material/Box'

import type { TracingUrlInsight } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import useGetErrorHint from './hooks/useGetErrorHint'

type Props = {
  tracingUrlInsights?: Array<TracingUrlInsight>
}

const ErrorHint = ({ tracingUrlInsights }: Props) => {
  const hint = useGetErrorHint({ tracingUrlInsights })

  if (!hint) {
    return null
  }

  return (
    <Tooltip title={hint}>
      <Box>
        <Icon
          color={theme.colors.orangeyRed}
          fontSize="small"
          icon={ICON.exclamationTriangle}
        />
      </Box>
    </Tooltip>
  )
}

export default ErrorHint
