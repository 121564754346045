import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  type SchedulingType,
  type ThirdPartyAppProvider,
  useRetryLatestExportEvery8dMutation,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { ConfirmDialog } from '@shared/ui/dialogs'
import {
  ExportActionButtons,
  formatExportAudienceRuleTitle,
  getExportPollingInterval,
} from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport/_shared'

import { SUPPORT_EXPORT_SMS_PROVIDER_LIST } from '../../_shared/constants'
import useAudienceRuleUserExportDetails from './useAudienceRuleUserExportDetails'
import useAudienceRuleUserExportStatus from './useAudienceRuleUserExportStatus'
import useCancelAudienceRuleUserAutoExport from './useCancelAudienceRuleUserAutoExport'
import useExportAudienceRuleUser from './useExportAudienceRuleUser'
import useGetAudienceRuleUserLatestExport from './useGetAudienceRuleUserLatestExport'

type DialogType = 'confirm' | 'cancel'

type Props = {
  audienceRuleId: number
  audienceRuleTitle: string
  schedulingType: SchedulingType
  name: string
  thirdPartyAppId: number
  provider: ThirdPartyAppProvider
  lineOaName?: string
  onDialogClose: VoidFunction
  onDialogOpen: VoidFunction
  onExported?: VoidFunction
}

export const ExportThirdPartyById = ({
  audienceRuleId,
  audienceRuleTitle,
  schedulingType,
  name,
  thirdPartyAppId,
  provider,
  lineOaName,
  onDialogClose,
  onDialogOpen,
  onExported,
}: Props) => {
  const [pollingInterval, setPollingInterval] = useState<number>()

  const { data: latestExport } = useGetAudienceRuleUserLatestExport(provider)(
    {
      audienceRuleId,
      integrationIds: thirdPartyAppId,
      withDetail: SUPPORT_EXPORT_SMS_PROVIDER_LIST.includes(provider),
    },
    { pollingInterval }
  )

  useEffect(() => {
    setPollingInterval(getExportPollingInterval(latestExport?.status))
  }, [latestExport])

  const [exportAudienceRuleUser, { isLoading: isSubmitting }] =
    useExportAudienceRuleUser(provider)()

  const [cancelAudienceRuleUserAutoExport, { isLoading: isCancelling }] =
    useCancelAudienceRuleUserAutoExport(provider)()

  const [retryLatestExportEvery8d] = useRetryLatestExportEvery8dMutation()

  const exportDetails = useAudienceRuleUserExportDetails(
    audienceRuleId,
    schedulingType,
    provider,
    latestExport
  )

  const exportStatus = useAudienceRuleUserExportStatus(
    audienceRuleId,
    provider,
    latestExport
  )

  const [openDialog, setOpenDialog] = useState<DialogType>()

  const onOpenDialogChange = (dialogType?: DialogType) => {
    setOpenDialog(dialogType)

    dialogType === undefined ? onDialogClose() : onDialogOpen()
  }

  const handleExport = async () => {
    await exportAudienceRuleUser?.({
      audienceRuleId,
      name: exportName,
      integrationId: thirdPartyAppId,
    }).unwrap()

    onExported?.()
    onOpenDialogChange(undefined)
  }

  const exportName = useMemo(
    () =>
      formatExportAudienceRuleTitle({
        title: audienceRuleTitle,
        isDynamic: schedulingType === 'default',
      }),
    [audienceRuleTitle, schedulingType]
  )

  const { t } = useTranslation(['audience', 'common', 'settings'])

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 88,
          py: 2,
        }}
      >
        <Box sx={{ flexGrow: 1, fontWeight: 500, mr: 4 }}>
          <Box component="p" sx={{ mb: 1 }}>
            {t('common:route.third_party')}

            <Box
              component="span"
              sx={{ color: theme => theme.colors.brightBlue, ml: 2 }}
            >
              {name}
            </Box>
          </Box>
          <p>
            {t('audience:export_third_party_app')}：
            {getThirdPartyProviderName(provider)}
            {lineOaName && (
              <Box component="span" sx={{ ml: 2 }}>
                LINE OA：{lineOaName}
              </Box>
            )}
          </p>

          <ul>
            {exportDetails.map(x => (
              <Box component="li" key={x.label} sx={{ mt: 1 }}>
                {x.label}
                <Box
                  component="span"
                  sx={{
                    ml: 2,
                    fontWeight: 400,
                    color: theme => theme.colors.textSecondBlue,
                  }}
                >
                  {x.text}
                </Box>
              </Box>
            ))}
          </ul>

          {exportStatus && (
            <Box
              component="p"
              sx={{
                mt: 1,
                fontWeight: 400,
                color: theme => theme.colors.textSecondBlue,
              }}
            >
              {exportStatus}
            </Box>
          )}
        </Box>

        <ExportActionButtons
          schedulingType={schedulingType}
          provider={provider}
          status={latestExport?.status}
          thirdPartyAppId={thirdPartyAppId}
          onExport={() => onOpenDialogChange('confirm')}
          onRetry={() => {
            // 因為發送 sms 要填很多資訊，使用 retryLatestExportSms 讓前端只需要填 audienceRuleId 與 integrationId 即可重送上次的簡訊
            if (provider === 'every8d' || provider === 'every8d_exclusive') {
              retryLatestExportEvery8d({
                audienceRuleId,
                integrationId: thirdPartyAppId,
              })
              return
            }

            onOpenDialogChange('confirm')
          }}
          onAutoExportCancel={() => onOpenDialogChange('cancel')}
        />
      </Box>

      <ConfirmDialog
        isOpen={openDialog === 'confirm'}
        isLoading={isSubmitting}
        modalTitle={t('audience:export_dialog_title_iterable', {
          listName: audienceRuleTitle,
          maTool: getThirdPartyProviderName(provider),
        })}
        confirmText={t('common:export')}
        onClose={() => onOpenDialogChange(undefined)}
        onConfirm={handleExport}
      >
        <Box component="span" sx={{ fontWeight: 500, mr: 2 }}>
          {t('common:name')}
        </Box>

        <Box
          component="span"
          sx={{ color: theme => theme.colors.textSecondBlue }}
        >
          {exportName}
        </Box>
      </ConfirmDialog>

      <ConfirmDialog
        isOpen={openDialog === 'cancel'}
        isLoading={isCancelling}
        modalTitle={t('audience:export_stop_auto', {
          maTool: getThirdPartyProviderName(provider),
        })}
        onClose={() => onOpenDialogChange(undefined)}
        onConfirm={async () => {
          if (!latestExport) {
            return
          }

          await cancelAudienceRuleUserAutoExport?.(latestExport.id).unwrap()

          onOpenDialogChange(undefined)
        }}
      />
    </>
  )
}

export default ExportThirdPartyById
