import isNumber from 'lodash/fp/isNumber'

import { getFloatSafe, getIntSafe } from '@shared/lib/utils/number'
import type { NumberOrString } from '@shared/lib/utils/type'

const mapTargetValueWithMaxMin = ({
  input,
  isFloat = false,
  max,
  min,
}: {
  input: NumberOrString
  isFloat?: boolean
  max?: number
  min?: number
}) => {
  let value = isFloat ? getFloatSafe(input, '') : getIntSafe(input, '')

  if ((!isNumber(max) && !isNumber(min)) || !isNumber(value)) {
    return value
  }

  if (isNumber(min)) {
    value = Math.max(value, min)
  }

  if (isNumber(max)) {
    value = Math.min(value, max)
  }

  return value
}

export default mapTargetValueWithMaxMin
