import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'

import { getIntSafe } from '@shared/lib/utils/number'
import { PrimaryGreyInput } from '@shared/ui/inputs'

import { DEFAULT_DURATION } from '../_shared/constants'

// 為了維持資料結構的一致性, 還是要用一個 duration 物件
export type DurationNodeData = {
  duration?: {
    day: number
    hour: number
    minute: number
  }
}

type Props = {
  isError?: boolean
  isEditable: boolean
  isColumnEditable?: boolean
  isHideHourAndMinute?: boolean
  timeData: DurationNodeData
  onTimeDataUpdate: (timeData: DurationNodeData) => void
  defaultData?: Required<DurationNodeData>
}

const Duration = ({
  isError = false,
  isEditable,
  isColumnEditable = false,
  isHideHourAndMinute = false,
  timeData,
  onTimeDataUpdate,
  defaultData = { duration: DEFAULT_DURATION },
}: Props) => {
  const { t } = useTranslation(['workflow'])

  const handleDurationChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = {
      duration: {
        ...timeData?.duration,
        [target.name]: Math.max(
          Math.min(getIntSafe(target.value), getIntSafe(target.max)),
          getIntSafe(target.min)
        ),
      },
    } as DurationNodeData

    onTimeDataUpdate(newDuration)
  }

  return (
    <>
      <FormControlLabel
        disabled={!isEditable}
        value="duration"
        control={<Radio color="primary" />}
        onChange={() => onTimeDataUpdate(defaultData)}
        label={
          <Box
            sx={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {t('workflow:node_timing_delay_hint')}
          </Box>
        }
        sx={{ marginRight: 0 }}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        ml={4}
      >
        <Box display="flex" alignItems="center" gap={2} width="100%">
          <PrimaryGreyInput
            disabled={!isEditable || !isColumnEditable}
            isError={isError && !Number.isInteger(timeData.duration?.day)}
            max="365"
            min="0"
            name="day"
            onChange={handleDurationChange}
            type="number"
            value={timeData.duration?.day || defaultData.duration.day}
          />
          <Box style={{ whiteSpace: 'nowrap' }}>
            {t('node_timing_delay_unit.day')}
          </Box>
          {!isHideHourAndMinute && (
            <>
              <PrimaryGreyInput
                disabled={!isEditable || !isColumnEditable}
                isError={isError && !Number.isInteger(timeData.duration?.hour)}
                max="23"
                min="0"
                name="hour"
                onChange={handleDurationChange}
                type="number"
                value={timeData.duration?.hour || defaultData.duration.hour}
              />
              <Box style={{ whiteSpace: 'nowrap' }}>
                {t('node_timing_delay_unit.hour')}
              </Box>
              <PrimaryGreyInput
                disabled={!isEditable || !isColumnEditable}
                isError={
                  isError && !Number.isInteger(timeData.duration?.minute)
                }
                max="59"
                min="0"
                name="minute"
                onChange={handleDurationChange}
                type="number"
                value={timeData.duration?.minute || defaultData.duration.minute}
              />
              <Box style={{ whiteSpace: 'nowrap' }}>
                {t('node_timing_delay_unit.minute')}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default Duration
