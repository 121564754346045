import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetWorkflowQuery } from '@shared/api/rtkQuery'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import DateRangePicker, { DatePickerWrapper } from '@shared/ui/DateRangePicker'
import { UniIcon as Icon } from '@shared/ui/icons'
import { DotLoader } from '@shared/ui/loaders'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import {
  useSetSharedState,
  useSharedState,
} from '@widgets/insight/lib/SharedStateContext'

import MediumTable from './MediumTable'
import SubscriptionTable from './SubscriptionTable'

const DetailInsightDataTable = () => {
  const sharedState = useSharedState()
  const setSharedState = useSetSharedState()
  const { t } = useTranslation(['insight', 'common'])

  const { id } = useParams<{ id: string }>()
  const workflowId = getIntSafe(id)

  const {
    data: workflow = {
      conversionEventEnable: false,
      conversionEvent: '',
      conversionValue: '',
      name: '',
    },
    isLoading,
  } = useGetWorkflowQuery(workflowId)

  useEffect(() => {
    trackEvent('AutomationJourneyPerformanceDetailViewed', { workflowId })
  }, [workflowId])

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Breadcrumbs>
          <Link
            to={`/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.workflowInsight}`}
          >
            {t('common:route.workflow_insight')}
          </Link>
          {workflow.name && (
            <Box>
              <Tooltip title={workflow.name}>
                <Box>{workflow.name}</Box>
              </Tooltip>
            </Box>
          )}
        </Breadcrumbs>
        <Box display="flex" justifyContent="start" alignItems="center">
          <Icon
            icon={ICON.schedule}
            color={theme.colors.brightBlue}
            fontSize="small"
          />
          <DatePickerWrapper>
            <DateRangePicker
              values={sharedState.timeRangeParams}
              durationBgColor={theme.colors.white}
              onChange={values =>
                setSharedState(prev => ({ ...prev, timeRangeParams: values }))
              }
              isHideDynamicTab
            />
          </DatePickerWrapper>
        </Box>
      </Box>
      {isLoading ? (
        <Box width="100%" display="flex" justifyContent="center">
          <DotLoader />
        </Box>
      ) : (
        <>
          <MediumTable
            id={Number(workflowId)}
            conversionEventEnable={workflow.conversionEventEnable}
            conversionEvent={workflow.conversionEvent}
            conversionEventField={workflow.conversionValue}
          />
          <SubscriptionTable id={Number(workflowId)} />
        </>
      )}
    </>
  )
}

export default DetailInsightDataTable
