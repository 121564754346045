import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'
import isEmpty from 'lodash/isEmpty'

import { useMetadataQuery } from '@shared/lib/utils/metadata'
import type { BasicNodeOperation } from '@widgets/workflow/_shared/hooks/useWorkflow'

import { BasicNode, createGetHandleStyles, HandleSourceText } from '../_shared'
import LogicEventFilterNodeEditDialog from './LogicEventFilterNodeEditDialog'
import {
  LogicEventFilterNodeData,
  logicEventFilterNodeSchema,
} from './logicEventFilterNodeSchema'

type Props = BasicNodeOperation<Partial<LogicEventFilterNodeData>>

const LogicEventFilterNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const { eventEntity } = useMetadataQuery()

    const tooltipLabel = useMemo(() => {
      if (!initialValues.filter) {
        return ''
      }

      if (
        initialValues.filter.filters &&
        initialValues.filter.filters.length > 0
      ) {
        const eventNames = initialValues.filter.filters.reduce<string[]>(
          (prev, currFilter) => {
            if (currFilter.type === 'event') {
              return currFilter.node.nodes.reduce((prevName, currNode) => {
                return prevName.concat(
                  currNode.nodes.map(item => item.eventName)
                )
              }, prev)
            }

            return prev
          },
          []
        )

        return (
          eventNames
            // 排除重複事件
            .filter((value, index) => eventNames.indexOf(value) === index)
            .map(item => eventEntity.events.entities[item]?.displayName || item)
            .join(' / ')
        )
      }

      return ''
    }, [initialValues.filter, eventEntity])
    const stringifyValues = JSON.stringify(initialValues)

    const [isSettled, setIsSettled] = useState(false)

    const [errors, setErrors] = useState<Partial<Record<'filter', string>>>({})

    const { t } = useTranslation(['workflow'])

    useEffect(() => {
      const validateValues = async () => {
        const values: LogicEventFilterNodeData = JSON.parse(
          stringifyValues ?? {}
        )

        try {
          await logicEventFilterNodeSchema.validate(values)

          setIsSettled(true)
          setErrors({})
        } catch (error) {
          if (error instanceof Error && !isEmpty(values)) {
            // 代表有設定但驗證失敗，因此將 isSettled 設為 true
            setIsSettled(true)
            setErrors({ filter: t(error.message) })
            return
          }

          setIsSettled(false)
        }
      }

      validateValues()
    }, [stringifyValues, t])

    const handleConfirm = (values: Partial<LogicEventFilterNodeData>) => {
      onChange(values)

      setIsOpenDialog(false)
    }

    const handleClose = () => {
      setIsOpenDialog(false)
    }

    const getHandleStyles = useMemo(() => createGetHandleStyles(2), [])

    return (
      <>
        <BasicNode
          nodeType="logic-event_filter"
          errorHint={errors.filter}
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          tooltipContent={
            <>
              <p>{id}</p>
              <p>
                {t('workflow:node_logic_condition_hint', {
                  name: tooltipLabel,
                })}
              </p>
            </>
          }
          isSettled={isSettled}
          triggerId={triggerId}
          usersInNode={usersInNode}
        />

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        <Handle
          type="source"
          position={Position.Bottom}
          id="yes"
          style={getHandleStyles(0)}
          isConnectable={isConnectable}
        >
          <HandleSourceText>YES</HandleSourceText>
        </Handle>

        <Handle
          type="source"
          position={Position.Bottom}
          id="no"
          style={getHandleStyles(1)}
          isConnectable={isConnectable}
        >
          <HandleSourceText>NO</HandleSourceText>
        </Handle>

        {isOpenDialog && (
          <LogicEventFilterNodeEditDialog
            isOpen
            initialValues={initialValues}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        )}
      </>
    )
  }
)

export default LogicEventFilterNode
