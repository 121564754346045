import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  ContentTemplate,
  INITIAL_PAGINATION_RESPONSE,
  useContentTemplateListQuery,
} from '@shared/api/rtkQuery'
import InfiniteScrollLoader from '@shared/ui/loaders/InfiniteScrollLoader'
import { Tab } from '@shared/ui/tabs'

import templates, { TemplateEmail, TemplateType } from './defaultEmailTemplates'
import TemplateOption, { TEMPLATE_GAP } from './TemplateOption'

type TabMode = 'default' | 'custom'

type EmailTemplateProps = {
  defaultTab?: TabMode
  isHideTabs?: boolean
  onSelect: (template?: TemplateEmail) => void
}

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 12

export const EmailTemplate = ({
  defaultTab = 'default',
  isHideTabs = false,
  onSelect,
}: EmailTemplateProps) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [mode, setMode] = useState<TabMode>(defaultTab)

  const [selection, setSelection] = useState<TemplateEmail | undefined>()

  const {
    data: contentTemplateListData = INITIAL_PAGINATION_RESPONSE,
    isFetching: isFetchingContentTemplateList,
  } = useContentTemplateListQuery({
    page: page,
    perPage: DEFAULT_PER_PAGE,
    mediumTypes: ['email'],
  })

  const [combinedCustomTemplateList, setCombinedCustomTemplateList] = useState<
    ContentTemplate[]
  >([])

  const onSelectTemplate = useCallback(
    (template?: TemplateEmail) => {
      setSelection(template)
      onSelect(template)
    },
    [onSelect]
  )

  useEffect(() => {
    if (selection) {
      return
    }

    const [firstSelection] =
      mode === 'default' ? templates : combinedCustomTemplateList

    onSelectTemplate(firstSelection)
  }, [combinedCustomTemplateList, mode, onSelectTemplate, selection])

  useEffect(() => {
    if (contentTemplateListData.items.length === 0) {
      return
    }

    setCombinedCustomTemplateList(prev =>
      prev.concat(contentTemplateListData.items)
    )
  }, [contentTemplateListData.items])

  const handleTabClick = (tabMode: TabMode) => {
    setMode(tabMode)

    const [firstTemplate] =
      tabMode === 'default' ? templates : combinedCustomTemplateList

    onSelectTemplate(firstTemplate)
  }

  const isFirstFetching =
    isFetchingContentTemplateList && contentTemplateListData.totalCount === 0

  return (
    <>
      {!isHideTabs && (
        <Tab
          currTab={mode}
          tabOptions={[
            {
              type: 'default',
              name: t('contentTemplate:default_content_template'),
            },
            {
              type: 'custom',
              name: t('contentTemplate:custom_content_template'),
            },
          ]}
          onTabChange={handleTabClick}
        />
      )}

      {mode === 'default' && (
        <Box display="flex" flexWrap="wrap" gap={TEMPLATE_GAP}>
          {templates.map(template => (
            <TemplateOption
              key={`default_template_${template.id}`}
              isSelected={template.id === selection?.id}
              title={t(
                `contentTemplate:default_template.${
                  template.title as TemplateType
                }`
              )}
              img={template.previewUrl}
              onSelect={() => {
                onSelectTemplate(template)
              }}
            />
          ))}
        </Box>
      )}

      {mode === 'custom' && (
        <>
          <Box display="flex" flexWrap="wrap" gap={TEMPLATE_GAP}>
            {combinedCustomTemplateList.map(contentTemplate => (
              <TemplateOption
                key={`custom_template_${contentTemplate.id}`}
                isSelected={contentTemplate.id === selection?.id}
                title={contentTemplate.title}
                img={contentTemplate.previewUrl}
                onSelect={() => {
                  onSelectTemplate(contentTemplate)
                }}
              />
            ))}
          </Box>

          {(isFirstFetching ||
            combinedCustomTemplateList.length <
              contentTemplateListData.totalCount) && (
            <Box mt={2}>
              <InfiniteScrollLoader
                isLoading={isFetchingContentTemplateList}
                loadMore={() => {
                  setPage(nowPage => nowPage + 1)
                }}
              />
            </Box>
          )}
        </>
      )}
    </>
  )
}

export default EmailTemplate
