import Box from '@mui/material/Box'
import TableFooter from '@mui/material/TableFooter'

import { useGetCampaignsStatisticQuery } from '@shared/api/rtkQuery'
import type { NumberOrString } from '@shared/lib/utils/type'
import {
  Table,
  TableBody,
  TableContainer,
  TableHeadEnhanced,
  TableRowListSkeleton,
} from '@shared/ui/table'
import useTable from '@widgets/usageManagement/SendingAmount/lib/hooks/useTable'

import { TableContainerCard } from '../_shared/style'
import TableRow from './TableRow'

type Props = {
  timeRangeParams: NumberOrString[]
}
const DataTable = ({ timeRangeParams }: Props) => {
  const { data: statisticData = [], isFetching: isStatisticDataFetching } =
    useGetCampaignsStatisticQuery({
      startTime: timeRangeParams[0],
      endTime: timeRangeParams[1],
    })

  const { tableData, tableSumData, tableColumns, tableFooterColumns } =
    useTable({ data: statisticData })

  return (
    <Box mt={4}>
      <TableContainer component={TableContainerCard}>
        <Table>
          <TableHeadEnhanced columns={tableColumns} />
          <TableBody>
            {isStatisticDataFetching ? (
              <TableRowListSkeleton columnCount={tableColumns.length} />
            ) : (
              tableData.map(data => (
                <TableRow
                  key={data.id}
                  columns={tableColumns}
                  data={data}
                  hasDetail
                />
              ))
            )}
          </TableBody>
          {!isStatisticDataFetching && (
            <TableFooter
              sx={theme => ({
                backgroundColor: theme.colors.bgPrimaryGrey,
              })}
            >
              <TableRow columns={tableFooterColumns} data={tableSumData} />
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  )
}

export default DataTable
