import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { PointerEventsInitial } from './styles'

type TooltipIconProps = {
  title: JSX.Element | string
}

export const TooltipIcon = ({ title }: TooltipIconProps) => (
  <Box ml={0.5} display="flex" justifyContent="flex-start" alignItems="center">
    <Tooltip title={title} placement={'right'}>
      <PointerEventsInitial>
        <Icon
          color={theme.colors.textPrimaryBlue}
          fontSize="xs"
          icon={ICON.infoCircle}
        />
      </PointerEventsInitial>
    </Tooltip>
  </Box>
)

export default TooltipIcon
