import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { DataTable, TableColumn } from '@shared/ui/table'

import {
  changeRowsPerPage,
  fetchApplicationList,
  itemsPerPageSelector,
} from './slice'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const routeStateCreator = ({ page = 0 }: { page?: number }) => ({ page })

const Overview = () => {
  const { t } = useTranslation(['common'])
  const history = useHistory()
  const { pathname, state: routeState = routeStateCreator({}) } = useLocation<{
    page: number
  }>()

  const { currPage, rowsPerPage, totalCount } = useAppSelector(
    state => state.v1.settings.application
  )
  const itemsPerPage = useAppSelector(itemsPerPageSelector)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(fetchApplicationList({ page: routeState.page }))
  }, [dispatch, routeState.page])

  const handleChangePage = (newPage: number) => {
    history.push(`${pathname}`, routeStateCreator({ page: newPage }))
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    dispatch(changeRowsPerPage(getIntSafe(rowsPerPage)))
    dispatch(fetchApplicationList({ page: 0 }))
  }

  const columns: TableColumn<{
    appId: string
    name: string
    type: string
    createdTime: string
    creator: string
  }>[] = [
    { headerName: 'name', field: 'name' },
    {
      headerName: 'type',
      field: 'type',
    },
    {
      headerName: 'create_time',
      field: 'createdTime',
      valueFormatter: date => formatDateTimeDisplay(date as string),
    },
    { headerName: 'creator', field: 'creator' },
  ]

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Box>{t('common:route.applications')}</Box>
        </Breadcrumbs>

        <Link to={`/${CATEGORY.settings}/${PAGE_ROOT.apiTokens}/new`}>
          <DeepBlueGradientButton width={200}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Link>
      </Box>

      <DataTable
        columns={columns}
        rows={itemsPerPage}
        count={totalCount}
        page={currPage}
        rowsPerPage={rowsPerPage}
        rowIdKey="appId"
        isRowClickable
        isShowCopyrightWhenLoading
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Wrapper>
  )
}

export default Overview
