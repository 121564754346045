import camelcaseKeys from 'camelcase-keys'

import { ACTION_URL_REGEX } from '@entities/mediumTemplates'
import type {
  ReplacementData,
  ReplacementDataActionURL,
} from '@shared/api/rtkQuery'

export const recoverUrlFromReplacementData = (
  html: string = '',
  replacementData: ReplacementData = {}
) => {
  if (html && replacementData) {
    return html.replace(ACTION_URL_REGEX, match => {
      const actionUrl = match.replace('{{', '').replace('}}', '')
      const data = camelcaseKeys(replacementData[actionUrl], {
        deep: true,
      }) as ReplacementDataActionURL

      return data?.urlParams?.host ?? ''
    })
  }
  return html
}

export default recoverUrlFromReplacementData
