import React, { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Prompt } from 'react-router'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import isEqual from 'lodash/isEqual'

import {
  createInitialWorkflowConfiguration,
  useGetWorkflowConfigurationQuery,
  useUpdateWorkflowConfigurationMutation,
  WorkflowConfiguration as Configuration,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import SectionTitle from '@shared/ui/SectionTitle'

import {
  ConfigurationListItem,
  FONT_WEIGHT,
  HighlightText,
  INPUT_WIDTH,
  SentLimitHint,
  SUBMIT_BUTTON_WIDTH,
} from './styles'
import useAccountSentInfo from './useAccountSentInfo'

const CHECKBOX_MARGIN_RIGHT = 1

const WorkflowConfiguration = () => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation(['common', 'workflow'])

  const { data: configuration } = useGetWorkflowConfigurationQuery()

  const [formValues, setFormValues] = useState<Configuration>(
    createInitialWorkflowConfiguration()
  )

  useEffect(() => {
    if (configuration) {
      setFormValues(prevConfiguration => ({
        ...prevConfiguration,
        ...configuration,
      }))
    }
  }, [configuration, setFormValues])

  const isModified = useMemo(() => {
    if (!configuration) {
      return false
    }

    return !isEqual(configuration, formValues)
  }, [configuration, formValues])

  const handleInputChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [target.name]: Math.max(+target.value, +target.min),
    }))
  }

  const handleCheckboxChange = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      [target.name]: target.checked,
    }))
  }

  const [updateConfiguration] = useUpdateWorkflowConfigurationMutation()

  const handleSubmit =
    (values: Configuration) => (events: React.FormEvent<HTMLFormElement>) => {
      events.preventDefault()

      updateConfiguration(values)
        .unwrap()
        .then(() => {
          dispatch(
            openToast({
              message: t('common:save_success'),
              status: 'success',
            })
          )
        })
        .catch(() => {
          dispatch(
            openToast({
              message: t('common:save_failure'),
              status: 'error',
            })
          )
        })
    }

  const accountSentInfo = useAccountSentInfo()

  return (
    <form onSubmit={handleSubmit(formValues)}>
      <SectionTitle title={t('workflow:frequency_management')} />

      <Card mb={5} py={1}>
        <Box component="ul">
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maWorkflowSameUserRunLimitEnabled}
              name="maWorkflowSameUserRunLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />

            <Box display="inline" fontWeight={FONT_WEIGHT}>
              {/** 設定單一顧客同時進入的旅程總量上限 */}
              <Trans
                ns="workflow"
                i18nKey="frequency_management_at_once"
                components={{
                  Limit: (
                    <PrimaryGreyInput
                      disabled={
                        formValues.maWorkflowSameUserRunLimitEnabled === false
                      }
                      min="1"
                      name="maWorkflowSameUserRunLimit"
                      onChange={handleInputChange}
                      type="number"
                      value={formValues.maWorkflowSameUserRunLimit}
                      width={INPUT_WIDTH}
                    />
                  ),
                  MarginX: <Box display="inline" mx={1} />,
                }}
              />
            </Box>
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              name="maCampaignUserSentLimitEnabled"
              checked={formValues.maCampaignUserSentLimitEnabled}
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            <Box display="inline" fontWeight={FONT_WEIGHT}>
              {/** 設定單一顧客行銷訊息發送頻率上限 x 小時內，最多 y 則 */}
              <Trans
                ns="workflow"
                i18nKey="frequency_management_send_limit"
                components={{
                  Hours: (
                    <PrimaryGreyInput
                      disabled={
                        formValues.maCampaignUserSentLimitEnabled === false
                      }
                      min="1"
                      name="maCampaignUserSentLimitPeriodHours"
                      onChange={handleInputChange}
                      type="number"
                      value={formValues.maCampaignUserSentLimitPeriodHours}
                      width={INPUT_WIDTH}
                    />
                  ),
                  Limit: (
                    <PrimaryGreyInput
                      disabled={
                        formValues.maCampaignUserSentLimitEnabled === false
                      }
                      min="1"
                      name="maCampaignUserSentLimitCount"
                      onChange={handleInputChange}
                      type="number"
                      value={formValues.maCampaignUserSentLimitCount}
                      width={INPUT_WIDTH}
                    />
                  ),
                  MarginX: <Box display="inline" mx={1} />,
                }}
              />
            </Box>
          </ConfigurationListItem>
        </Box>
      </Card>

      <SectionTitle title={t('workflow:budget_management')} />

      <Card mb={5} pt={3} pb={1} fontWeight={FONT_WEIGHT}>
        {t('workflow:budget_management_email_title')}
        <Box component="ul">
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignEmailDaySentLimitEnabled}
              color="primary"
              name="maCampaignEmailDaySentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定電子郵件每日上限 n 封付費信件 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_email"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignEmailDaySentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignEmailDaySentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignEmailDaySentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_day') }}
            />
            {formValues.maCampaignEmailDaySentLimitEnabled &&
              accountSentInfo.email.day.count >=
                formValues.maCampaignEmailDaySentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignEmailWeekSentLimitEnabled}
              color="primary"
              name="maCampaignEmailWeekSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定電子郵件每週上限 n 封付費信件 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_email"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignEmailWeekSentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignEmailWeekSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignEmailWeekSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_week') }}
            />
            {formValues.maCampaignEmailWeekSentLimitEnabled &&
              accountSentInfo.email.week.count >=
                formValues.maCampaignEmailWeekSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignEmailMonthSentLimitEnabled}
              color="primary"
              name="maCampaignEmailMonthSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定電子郵件每月上限 n 封付費信件 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_email"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignEmailMonthSentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignEmailMonthSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignEmailMonthSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_month') }}
            />
            {formValues.maCampaignEmailMonthSentLimitEnabled &&
              accountSentInfo.email.month.count >=
                formValues.maCampaignEmailMonthSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
        </Box>
      </Card>

      <Card mb={5} pt={3} pb={1} fontWeight={FONT_WEIGHT}>
        {t('workflow:budget_management_line_title')}

        <Box component="ul">
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignLinePushDaySentLimitEnabled}
              color="primary"
              name="maCampaignLinePushDaySentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定 LINE 訊息每日上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_line"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignLinePushDaySentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignLinePushDaySentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignLinePushDaySentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_day') }}
            />
            {formValues.maCampaignLinePushDaySentLimitEnabled &&
              accountSentInfo.line_push.day.count >=
                formValues.maCampaignLinePushDaySentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignLinePushWeekSentLimitEnabled}
              color="primary"
              name="maCampaignLinePushWeekSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定 LINE 訊息每週上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_line"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignLinePushWeekSentLimitEnabled ===
                      false
                    }
                    min="1"
                    name="maCampaignLinePushWeekSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignLinePushWeekSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_week') }}
            />
            {formValues.maCampaignLinePushWeekSentLimitEnabled &&
              accountSentInfo.line_push.week.count >=
                formValues.maCampaignLinePushWeekSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignLinePushMonthSentLimitEnabled}
              color="primary"
              name="maCampaignLinePushMonthSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定 LINE 訊息每月上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_line"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignLinePushMonthSentLimitEnabled ===
                      false
                    }
                    min="1"
                    name="maCampaignLinePushMonthSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignLinePushMonthSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_month') }}
            />
            {formValues.maCampaignLinePushMonthSentLimitEnabled &&
              accountSentInfo.line_push.month.count >=
                formValues.maCampaignLinePushMonthSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
        </Box>
      </Card>

      <Card mb={5} pt={3} pb={1} fontWeight={FONT_WEIGHT}>
        {t('workflow:budget_management_sms_title')}

        <Box component="ul">
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignSmsDaySentLimitEnabled}
              color="primary"
              name="maCampaignSmsDaySentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定文字簡訊每日上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_sms"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignSmsDaySentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignSmsDaySentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignSmsDaySentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_day') }}
            />
            {formValues.maCampaignSmsDaySentLimitEnabled &&
              accountSentInfo.sms.day.count >=
                formValues.maCampaignSmsDaySentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignSmsWeekSentLimitEnabled}
              color="primary"
              name="maCampaignSmsWeekSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定文字簡訊每週上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_sms"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignSmsWeekSentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignSmsWeekSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignSmsWeekSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_week') }}
            />
            {formValues.maCampaignSmsWeekSentLimitEnabled &&
              accountSentInfo.sms.week.count >=
                formValues.maCampaignSmsWeekSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
          <ConfigurationListItem>
            <Checkbox
              checked={formValues.maCampaignSmsMonthSentLimitEnabled}
              color="primary"
              name="maCampaignSmsMonthSentLimitEnabled"
              onChange={handleCheckboxChange}
              sx={{ mr: CHECKBOX_MARGIN_RIGHT }}
            />
            {/** 設定文字簡訊每月上限 n 封付費訊息 */}
            <Trans
              ns="workflow"
              i18nKey="budget_management_sms"
              components={{
                HighlightText: <HighlightText />,
                Limit: (
                  <PrimaryGreyInput
                    disabled={
                      formValues.maCampaignSmsMonthSentLimitEnabled === false
                    }
                    min="1"
                    name="maCampaignSmsMonthSentLimit"
                    onChange={handleInputChange}
                    type="number"
                    value={formValues.maCampaignSmsMonthSentLimit}
                    width={INPUT_WIDTH}
                  />
                ),
                MarginX: <Box display="inline" mx={1} />,
              }}
              values={{ period: t('workflow:budget_management_period_month') }}
            />
            {formValues.maCampaignSmsMonthSentLimitEnabled &&
              accountSentInfo.sms.month.count >=
                formValues.maCampaignSmsMonthSentLimit && (
                <SentLimitHint>
                  {t('workflow:budget_management_warning_limit')}
                </SentLimitHint>
              )}
          </ConfigurationListItem>
        </Box>
      </Card>

      <Prompt when={isModified} message={t('workflow:leave_without_save')} />

      <DeepBlueGradientButton width={SUBMIT_BUTTON_WIDTH} type="submit">
        {t('common:save')}
      </DeepBlueGradientButton>
    </form>
  )
}

export default WorkflowConfiguration
