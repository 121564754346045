import { PartialDeep } from '@shared/lib/utils/type'

import {
  api,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import {
  NotificationRule,
  NotificationRuleFormValues,
  NotificationRuleList,
  NotificationUsers,
} from './notificationRuleType'

export const UPDATE_HOUR = 10

const NotificationRuleAnalyticsApi = api.injectEndpoints({
  endpoints: builder => ({
    getNotificationRuleList: builder.query<
      NotificationRuleList,
      { page: number; perPage: number }
    >({
      query: params => ({
        url: '/event_rules/notification_rules',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as NotificationRuleList),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'NotificationRuleList' as const,
                id,
              })),
              { type: 'NotificationRuleList', id: 'LIST' },
            ]
          : [{ type: 'NotificationRuleList', id: 'LIST' }],
    }),
    getNotificationRule: builder.query<NotificationRule, number>({
      query: id => ({
        url: `/event_rules/notification_rules/${id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as NotificationRule),
      providesTags: (result, error, arg) => [
        { type: 'NotificationRuleList', id: arg },
      ],
    }),
    createNotificationRule: builder.mutation<void, NotificationRuleFormValues>({
      query: body => ({
        url: '/event_rules/notification_rules',
        method: 'POST',
        body,
      }),
      invalidatesTags: [{ type: 'NotificationRuleList', id: 'LIST' }],
    }),
    deleteNotificationRule: builder.mutation<void, number>({
      query: id => ({
        url: `/event_rules/notification_rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'NotificationRuleList', id: 'LIST' }],
    }),
    patchNotificationRule: builder.mutation<
      void,
      PartialDeep<NotificationRuleFormValues> & { id: number }
    >({
      query: body => ({
        url: `/event_rules/notification_rules/${body.id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'NotificationRuleList', id: arg.id },
      ],
    }),
    getNotificationUsers: builder.query<NotificationUsers, null>({
      query: () => ({
        url: '/notification/users',
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as NotificationUsers),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'NotificationUsers' as const,
                id,
              })),
              { type: 'NotificationUsers', id: 'LIST' },
            ]
          : [{ type: 'NotificationUsers', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNotificationRuleListQuery,
  useGetNotificationRuleQuery,
  useCreateNotificationRuleMutation,
  useDeleteNotificationRuleMutation,
  usePatchNotificationRuleMutation,
  useGetNotificationUsersQuery,
} = NotificationRuleAnalyticsApi
