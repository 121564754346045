import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'

import type {
  ExportAudienceRuleUserFacebookAds,
  SchedulingType,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'

export type FormValues = Pick<
  ExportAudienceRuleUserFacebookAds,
  'name' | 'updatePolicy'
>

type Props = {
  isOpen: boolean
  isSubmitting: boolean
  listName: string
  schedulingType: SchedulingType
  onCancel: () => void
  onSubmit: (values: FormValues) => void
}

const FacebookAdsCreateDialog = ({
  isOpen,
  isSubmitting,
  listName,
  schedulingType,
  onCancel: handleCancel,
  onSubmit: handleSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])

  const formik = useFormik<FormValues>({
    initialValues: {
      name: listName,
      updatePolicy: schedulingType === 'default' ? 'replace' : undefined,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  })

  return (
    <ConfirmDialog
      isOpen={isOpen}
      isLoading={isSubmitting}
      modalTitle={t('audience:export_dialog_title_facebook_ads', {
        listName,
      })}
      onClose={handleCancel}
      onConfirm={() => formik.submitForm()}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'minmax(80px, auto) 1fr',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            color: theme => theme.colors.textPrimaryBlue,
            fontWeight: 'medium',
          }}
        >
          {t('common:name')}
        </Box>

        <Box
          component="span"
          sx={{ color: theme => theme.colors.textSecondBlue }}
        >
          {formik.values.name}
        </Box>

        {schedulingType === 'default' && (
          <>
            <Box
              sx={{
                color: theme => theme.colors.textPrimaryBlue,
                fontWeight: 'medium',
              }}
            >
              {t('audience:export.update_policy')}
            </Box>

            <RadioGroup
              name="updatePolicy"
              row
              value={formik.values.updatePolicy}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="replace"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('audience:export.update_policy_replace')}
                    &nbsp;
                    <Tooltip
                      title={t('audience:export.update_policy_replace_tooltip')}
                    >
                      <span>
                        <Icon icon={ICON.infoCircle} fontSize="small" />
                      </span>
                    </Tooltip>
                  </Box>
                }
              />

              <FormControlLabel
                value="add"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('audience:export.update_policy_add')}
                    &nbsp;
                    <Tooltip
                      title={t('audience:export.update_policy_add_tooltip')}
                    >
                      <span>
                        <Icon icon={ICON.infoCircle} fontSize="small" />
                      </span>
                    </Tooltip>
                  </Box>
                }
              />
            </RadioGroup>
          </>
        )}
      </Box>
    </ConfirmDialog>
  )
}

export default FacebookAdsCreateDialog
