import { api, transformResponseCamelCaseKeys } from '@shared/api/rtkQuery/api'

import type { BlackList } from './type'

const productsKeywordsBlacklist = api.injectEndpoints({
  endpoints: builder => ({
    getProductsKeywordsBlacklist: builder.query<BlackList, void>({
      query: () => ({
        url: '/products/keywords/blacklist',
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as BlackList),
      providesTags: result =>
        result
          ? [
              ...result?.map(({ keyword }) => ({
                type: 'ProductsKeywordsBlacklist' as const,
                id: keyword,
              })),
              { type: 'ProductsKeywordsBlacklist', id: 'LIST' },
            ]
          : [{ type: 'ProductsKeywordsBlacklist', id: 'LIST' }],
    }),
    postProductsKeywordsBlacklist: builder.mutation<void, string[]>({
      query: keywords => ({
        url: '/products/keywords/blacklist',
        method: 'POST',
        body: keywords,
      }),
      invalidatesTags: [{ type: 'ProductsKeywordsBlacklist', id: 'LIST' }],
    }),
    deleteProductsKeywordsBlacklist: builder.mutation<void, string>({
      query: keyword => ({
        url: `/products/keywords/blacklist/${keyword}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ProductsKeywordsBlacklist', id: arg },
      ],
    }),
  }),
})

export const {
  useGetProductsKeywordsBlacklistQuery,
  usePostProductsKeywordsBlacklistMutation,
  useDeleteProductsKeywordsBlacklistMutation,
} = productsKeywordsBlacklist
