import Box from '@mui/material/Box'

import { PrimaryGreyInput } from '@shared/ui/inputs'

type Props = {
  disabled: boolean
  required: boolean
  isError: boolean
  label: string
  placeholder: string
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TextInputField = ({
  disabled,
  isError,
  required,
  label,
  placeholder,
  value,
  onChange,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        component="label"
        sx={{
          width: '200px',
          fontSize: '14px',
          fontFamily: 'inherit',
          fontWeight: 500,
          color: theme => theme.colors.lightNavyBlue,
          '&::after': {
            content: required ? "'*'" : "''",
          },
        }}
      >
        {label}
      </Box>
      <PrimaryGreyInput
        type="text"
        fullWidth
        placeholder={placeholder}
        value={value}
        isError={isError}
        disabled={disabled}
        onChange={onChange}
      />
    </Box>
  )
}

export default TextInputField
