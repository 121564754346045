import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { EmailEditor, useEmailEditor } from '@entities/mediumTemplates'
import { useContentTemplateQuery } from '@shared/api/rtkQuery'
import { CONTENT_TEMPLATE_ROOT_PATH } from '@widgets/contentSetting/ui/ContentTemplate/constants'
import shouldRedirectByMediumType from '@widgets/contentSetting/ui/ContentTemplate/utils/shouldRedirectByMediumType'

import SectionHeader from './SectionHeader'

const Edit = () => {
  const { contentTemplateId } = useParams<{ contentTemplateId: string }>()
  const history = useHistory()
  const { isReady, emailEditorRef, onLoadDesign, onReady, onExportDesign } =
    useEmailEditor()

  const { data, isFetching } = useContentTemplateQuery(
    parseInt(contentTemplateId, 10),
    {
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    if (!shouldRedirectByMediumType(data?.mediumType || null)) {
      return
    }
    history.push(CONTENT_TEMPLATE_ROOT_PATH)
  }, [data?.mediumType, history])

  useEffect(() => {
    if (!isReady || !data?.editorJson) {
      return
    }

    onLoadDesign(data.editorJson)
  }, [isReady, data, onLoadDesign])

  if (isFetching) {
    return null
  }

  return (
    <>
      <SectionHeader
        title={data?.title || ''}
        contentTemplateId={contentTemplateId}
        onExportDesign={onExportDesign}
      />

      <EmailEditor minHeight="85vh" ref={emailEditorRef} onReady={onReady} />
    </>
  )
}

export default Edit
