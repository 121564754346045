import Box from '@mui/material/Box'

import DataTable from '@shared/ui/table/DataTable'

import useUserValuesTableColumn from './hooks/useUserValuesTableColumn'

const UserValuesTable = () => {
  const { data, isLoading } = useUserValuesTableColumn()
  return (
    <DataTable
      rows={data}
      isLoading={isLoading}
      columns={[
        {
          field: 'fieldName',
          headerName: '',
          valueFormatter: value => (
            <Box sx={{ fontWeight: 500 }}>{value as string}</Box>
          ),
        },
        {
          field: '30',
          headerName: 'the_last_30_days',
          align: 'right',
          valueFormatter: value => (
            <Box sx={{ fontWeight: 500 }}>{value as string}</Box>
          ),
        },
        {
          field: '60',
          headerName: 'the_last_60_days',
          align: 'right',
          valueFormatter: value => (
            <Box sx={{ fontWeight: 500 }}>{value as string}</Box>
          ),
        },
        {
          field: '90',
          headerName: 'the_last_90_days',
          align: 'right',
          valueFormatter: value => (
            <Box sx={{ fontWeight: 500 }}>{value as string}</Box>
          ),
        },
        {
          field: '180',
          headerName: 'the_last_180_days',
          align: 'right',
          valueFormatter: value => (
            <Box sx={{ fontWeight: 500 }}>{value as string}</Box>
          ),
        },
      ]}
      rowIdKey="fieldName"
      isHidePagination
    />
  )
}

export default UserValuesTable
