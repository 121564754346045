import { useTranslation } from 'react-i18next'

import type { TaskEntity } from '@features/infoBox/model/taskSlice'

export const useResourceTypeText = (
  resourceType: TaskEntity['resourceType']
) => {
  const { t } = useTranslation(['dataImport'])

  switch (resourceType) {
    case 'event':
      return t('dataImport:import_type.event')

    case 'tag_user':
      return t('dataImport:import_type.user_tagging')

    case 'user_profile':
      return t('dataImport:import_type.user_profile')

    case 'dimension':
      return t('dataImport:import_type.dimension')

    case 'behavior_tag_user':
      return t('dataImport:import_type.behavior_tag_user')

    default:
      return ''
  }
}

export default useResourceTypeText
