import { ChangeEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import { getIntSafe } from '@shared/lib/utils/number'
import { ROWS_PER_PAGE_OPTIONS } from '@shared/model/constants/table'
import Card from '@shared/ui/Card'

import TableBodyCell from './TableCell/TableBodyCell'
import TableHeadEnhanced, {
  DataTableSortProps,
  TableColumn,
} from './TableHeadEnhanced'
import TableLoadingContainerWithCopyright from './TableLoadingContainerWithCopyright'
import TablePagination from './TablePagination'
import TableRow from './TableRow'
import TableRowListSkeleton from './TableRowListSkeleton'

export type TableRowDisabled = {
  isDisabled?: boolean
}

type CreateRouteStateParams = { fromPage?: number }

type DataTableProps<T extends {}> = {
  columns: TableColumn<T>[]
  containerType?: 'card' | 'border'
  count?: number
  isLoading?: boolean
  isRowClickable?: boolean
  isShowCopyrightWhenLoading?: boolean
  isHidePagination?: boolean
  onPageChange?: (page: number) => void
  onRowsPerPageChange?: (rowsPerPage: number) => void
  onRowClick?: (data: T) => void
  page?: number
  rowIdKey?: keyof T
  rows: (T & TableRowDisabled)[]
  rowsPerPage?: number
  rowsPerPageOptions?: number[]
} & DataTableSortProps

export const TableContainerCard = styled(Card)`
  padding: 8px 0;
`

export const TableContainerBorder = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black6};
  border-radius: 3px;
`

export const createRouteState = ({ fromPage = 0 }: CreateRouteStateParams) => ({
  fromPage,
})

export const defaultValueFormatter = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.join(', ')
  }

  if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  if (typeof value === 'number' || typeof value === 'string') {
    return value
  }

  return ''
}

// ref: https://material-ui.com/components/tables/#data-table
const DataTable = <TItem extends {}>({
  columns,
  containerType = 'card',
  count,
  isLoading = false,
  isRowClickable = false,
  isShowCopyrightWhenLoading = false,
  isHidePagination = false,
  onPageChange,
  onRowsPerPageChange,
  onRowClick,
  page,
  rowIdKey = 'id' as keyof TItem,
  rows,
  rowsPerPage = ROWS_PER_PAGE_OPTIONS[0],
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  onSorting,
  defaultSortBy = '',
  defaultSortDirection = 'asc',
}: DataTableProps<TItem>) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { t } = useTranslation('table')

  const onTableRowClick = (data: TItem, rowKey: keyof TItem) => {
    if (!isRowClickable || isLoading) {
      return
    }

    if (onRowClick) {
      onRowClick(data)
      return
    }

    history.push(
      `${pathname}/${data[rowKey]}`,
      createRouteState({ fromPage: page })
    )
  }

  const tableRef = useRef<HTMLTableElement>(null)

  const scrollToTableTop = () => {
    if (tableRef.current) {
      // 20 是魔術數字，目的是讓捲軸停在表格上方，視覺上比較舒服
      window.scrollTo(0, tableRef.current.offsetTop - 20)
    }
  }

  const handlePageChange = (_e: unknown, newPage: number) => {
    if (onPageChange) {
      onPageChange(newPage)

      scrollToTableTop()
    }
  }

  const handleRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(getIntSafe(e.target.value))

      scrollToTableTop()
    }
  }

  return (
    <TableLoadingContainerWithCopyright
      isLoading={rows.length !== 0 && isLoading}
      isShowCopyrightWhenLoading={isShowCopyrightWhenLoading}
    >
      <TableContainer
        component={
          containerType === 'border' ? TableContainerBorder : TableContainerCard
        }
        // 隱藏導覽列時，移除 padding-bottom 讓陰影能夠貼齊在表格
        sx={isHidePagination ? { pb: 0 } : undefined}
      >
        <Table ref={tableRef}>
          <TableHeadEnhanced
            columns={columns}
            onSorting={onSorting}
            defaultSortBy={defaultSortBy}
            defaultSortDirection={defaultSortDirection}
          />

          <TableBody>
            {isLoading && rows.length === 0 ? (
              <TableRowListSkeleton columnCount={columns.length} />
            ) : (
              rows.map((rowItem, rowIndex) => {
                const isCurrRowClickable =
                  isRowClickable && !rowItem?.isDisabled

                return (
                  <TableRow
                    isAllowHoverOnChild
                    isBodyCell
                    isClickable={!isLoading && isCurrRowClickable}
                    key={`${rowItem[rowIdKey]}`}
                    onClick={() => {
                      if (!isCurrRowClickable) {
                        return
                      }

                      onTableRowClick(rowItem, rowIdKey)
                    }}
                  >
                    {columns.map(
                      (
                        {
                          field,
                          valueFormatter = defaultValueFormatter,
                          paddingTop,
                          paddingBottom,
                          align,
                        },
                        columnIndex
                      ) => {
                        const isFirstRowCell = columnIndex === 0
                        const currValue = Array.isArray(field)
                          ? field.map((item: keyof TItem) => rowItem[item])
                          : rowItem[field as keyof TItem]

                        const unTypedValueFormatter = valueFormatter as (
                          value: unknown,
                          data: TItem
                        ) => React.ReactNode

                        return (
                          <TableBodyCell
                            align={align}
                            isDisabledStyle={rowItem?.isDisabled}
                            isFirstRowCell={isFirstRowCell}
                            isLastRowCell={columnIndex === columns.length - 1}
                            isWordBreakAll
                            key={`${rowIndex}_${columnIndex}`}
                            paddingBottom={paddingBottom}
                            paddingTop={paddingTop}
                          >
                            {/* 第一個欄位(name, email...etc. 可以按右鍵開新分頁) */}
                            {isFirstRowCell &&
                            isCurrRowClickable &&
                            !onRowClick ? (
                              <Link
                                to={{
                                  pathname: `${pathname}/${rowItem[rowIdKey]}`,
                                  state: createRouteState({
                                    fromPage: page,
                                  }),
                                }}
                                onClick={e => {
                                  e.stopPropagation()
                                }}
                              >
                                {unTypedValueFormatter(currValue, rowItem)}
                              </Link>
                            ) : (
                              unTypedValueFormatter(currValue, rowItem)
                            )}
                          </TableBodyCell>
                        )
                      }
                    )}
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>

        {!isHidePagination && (
          <TablePagination
            count={count || 0}
            isShowPageInput
            labelRowsPerPage={t('table_rows_per_page')}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            page={page || 0}
            rowsPerPage={rowsPerPage || 0}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </TableContainer>
    </TableLoadingContainerWithCopyright>
  )
}

export default DataTable
