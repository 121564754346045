import lodashIsNumber from 'lodash/isNumber'

const signs = [
  {
    value: 1e12,
    symbol: 'T',
  },
  {
    value: 1e9,
    symbol: 'G',
  },
  {
    value: 1e6,
    symbol: 'M',
  },
  {
    value: 1e3,
    symbol: 'K',
  },
  {
    value: 1,
    symbol: '',
  },
]

/**
 * 將數字換算成 K、M、G、T
 * @param num
 * @param digits
 * @description
 * 1234 => 1.2K
 *
 * 987654 => 987.7K
 *
 * 98765432 => 98.8M
 */
export const formatNumberWithSign = (
  num: number,
  digits: number = 1
): string => {
  if (num <= 0) {
    return '0'
  }

  const matchedSign = signs.find(sign => num >= sign.value) || {
    value: 1,
    symbol: '',
  }
  const { value, symbol } = matchedSign

  // 若不到千位，不顯示小數點
  const modifiedDigits = value === 1 ? 0 : digits

  return `${(num / value).toFixed(modifiedDigits)}${symbol}`
}

export const formatNumber = (num: number) => {
  if (typeof num === 'number' && !isNaN(num)) {
    return new Intl.NumberFormat().format(num)
  }

  return 0
}

export const getIntSafe = <T = number>(
  input: unknown,
  defaultValue: T | number = 0
) => {
  const result = parseInt(`${input}`, 10)
  return isNaN(result) ? defaultValue : result
}

export const getFloatSafe = <T = number>(
  input: unknown,
  defaultValue: T | number = 0
) => {
  const result = parseFloat(`${input}`)
  return isNaN(result) ? defaultValue : result
}

/**
 * 取得 0 ~ 最大值之間的隨機整數
 * @param max 最大值
 */
export const random = (max: number) => {
  return Math.floor(Math.random() * max)
}

/**
 * 取從 start 到 stop - 1 的連續數字陣列
 * ref: https://underscorejs.org/docs/underscore-esm.html#section-208
 */
export const range = (start: number, stop?: number, step?: number) => {
  if (stop == null) {
    stop = start || 0
    start = 0
  }
  if (!step) {
    step = stop < start ? -1 : 1
  }

  var length = Math.max(Math.ceil((stop - start) / step), 0)
  var range = Array(length)

  for (var idx = 0; idx < length; idx++, start += step) {
    range[idx] = start
  }

  return range
}

export const isNumber = (value: any) => {
  return lodashIsNumber(value)
}

type CurrencyType = 'USD' | 'JPY' | 'TWD'

export const formatCurrency = (
  value: number,
  option?: {
    defaultValue?: string
    currency?: CurrencyType
    fractionDigits?: number
  }
): string => {
  if (typeof value === 'number' && !isNaN(value)) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: option?.currency || 'USD',
      maximumFractionDigits: option?.fractionDigits,
      minimumFractionDigits: option?.fractionDigits,
    }).format(value)
  }

  return option?.defaultValue || '0'
}

export const formatNumberByLang = (
  num: number,
  option: {
    language: string
    digits: number
  }
): string => {
  const enUint = [
    {
      value: 1e9,
      symbol: 'Billion',
    },
    {
      value: 1e6,
      symbol: 'Million',
    },
    {
      value: 1e3,
      symbol: 'Thousand',
    },
    {
      value: 1,
      symbol: '',
    },
  ]

  const zhUint = [
    {
      value: 1e8,
      symbol: '億',
    },
    {
      value: 1e4,
      symbol: '萬',
    },
    {
      value: 1,
      symbol: '',
    },
  ]

  const unit = option.language.includes('zh') ? zhUint : enUint

  if (num <= 0) {
    return num.toString()
  }

  const matchedUnit = unit.find(sign => num >= sign.value) || unit[0]

  const { value, symbol } = matchedUnit

  const modifiedDigits = value === 1 ? 0 : option.digits

  return `${(num / value).toFixed(modifiedDigits)} ${symbol}`
}
