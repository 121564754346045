import { useTranslation } from 'react-i18next'

import { formatRTKQueryError } from '@entities/apiHandler'
import type { TracingUrl } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { isUrlEqual } from '@shared/lib/utils/string'
import { CONTENT_TEMPLATE_ERROR_CODE } from '@shared/model/constants/contentTemplate'
import { openToast } from '@shared/model/slices'

type Props = {
  tracingUrlList: TracingUrl[]
}

const useTracingUrlError = ({ tracingUrlList }: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const dispatch = useAppDispatch()

  const handleError = ({ url, error }: { url: string; error: unknown }) => {
    const formatError = formatRTKQueryError(error)
    const { errorCode } = formatError.data || {}

    if (errorCode === CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_NAME) {
      dispatch(
        openToast({
          message: t('contentTemplate:tracing_url.errors.duplicate_name'),
          status: 'error',
        })
      )

      return
    }

    if (errorCode === CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_URL) {
      const { name = '' } =
        tracingUrlList?.find(item => isUrlEqual(item.url, url)) || {}
      dispatch(
        openToast({
          message: t('contentTemplate:tracing_url.errors.duplicate_url', {
            name,
          }),
          status: 'error',
        })
      )

      return
    }

    dispatch(
      openToast({
        message: t('common:failure_create'),
        status: 'error',
      })
    )
  }

  return { handleError }
}

export default useTracingUrlError
