import type {
  CreateTagUserIngestionParams,
  TagUserIngestion,
} from '@shared/model/types/ingestion'

import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'

const tagUser = api.injectEndpoints({
  endpoints: builder => ({
    getTagUserIngestionList: builder.query<
      PaginationResponse<TagUserIngestion>,
      PaginationParams & { tagId: number }
    >({
      query: params => ({
        url: '/tag_user/ingestions',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(
          data as PaginationResponse<TagUserIngestion>
        ),
      providesTags: () => ['TagUserIngestion'],
    }),
    createTagUserIngestion: builder.mutation<
      void,
      CreateTagUserIngestionParams
    >({
      query: params => ({
        url: `/tag_user/ingestions`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: () => ['TagUserIngestion', 'TagSummary'],
    }),
    deleteTagUserIngestion: builder.mutation<void, number>({
      query: id => ({
        url: `/tag_user/ingestions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['TagUserIngestion', 'TagSummary'],
    }),
    getTagUserIngestion: builder.query<TagUserIngestion, number>({
      query: id => ({
        url: `/tag_user/ingestions/${id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as TagUserIngestion),
      providesTags: ['TagUserIngestion'],
    }),
    triggerTagUserIngestion: builder.mutation<void, number>({
      query: id => ({
        url: `/tag_user/ingestions/${id}/trigger`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useDeleteTagUserIngestionMutation,
  useGetTagUserIngestionListQuery,
  useCreateTagUserIngestionMutation,
  useGetTagUserIngestionQuery,
  useTriggerTagUserIngestionMutation,
} = tagUser
