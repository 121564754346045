import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { TracingUrl } from '@shared/api/rtkQuery'
import {
  useCreateTracingUrlMutation,
  useDeleteTracingUrlMutation,
  useGetTracingUrlListQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { trackEvent } from '@shared/lib/utils/amplitude'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { openToast } from '@shared/model/slices'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import { TracingUrlDialog } from '@shared/ui/dialogs'
import { SearchInput } from '@shared/ui/searchInput'
import type { TableColumn } from '@shared/ui/table'
import { DataTable } from '@shared/ui/table'
import useTracingUrlError from '@widgets/contentSetting/lib/hooks/useTracingUrlError'

import TableMoreAction from './TableMoreAction'

const Overview = () => {
  const { t } = useTranslation(['common', 'contentTemplate'])
  const dispatch = useAppDispatch()
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
  const [openDropdownId, setOpenDropdownId] = useState(-1)
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [deleteDialogConfig, setDeleteDialogConfig] = useState<
    { id: number; name: string } | undefined
  >(undefined)
  const [tracingUrlDialogConfig, setTracingUrlDialogConfig] = useState<
    { url: string; name: string } | undefined
  >(undefined)
  const [trackingUrlDialogMode, setTracingUrlDialogMode] = useState<
    'create' | 'preview'
  >('preview')

  let { data: tracingUrlList = [], isFetching: isLoadingTracingUrlList } =
    useGetTracingUrlListQuery()

  // 將 filter 與 slice 分開寫，才能在用戶 search 顯示正確的總數
  tracingUrlList = tracingUrlList.filter(item =>
    search ? item.name.includes(search) || item.creator.includes(search) : true
  )

  const currPageTracingUrlList = tracingUrlList.slice(
    (page - 1) * perPage,
    Math.min(tracingUrlList.length, page * perPage)
  )

  const [deleteTracingUrl, { isLoading: isDeleting }] =
    useDeleteTracingUrlMutation()

  const [createTracingUrl, { isLoading: isCreating }] =
    useCreateTracingUrlMutation()

  const { handleError } = useTracingUrlError({ tracingUrlList })

  const onRowClick = ({ name, url }: TracingUrl) => {
    setTracingUrlDialogConfig({ name, url })
    setTracingUrlDialogMode('preview')
  }

  const columns: TableColumn<TracingUrl>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'name',
      },
      {
        field: 'createdAt',
        headerName: 'create_time',
        valueFormatter: createTime => (
          <span>{formatDateTimeDisplay(createTime)}</span>
        ),
      },
      {
        field: 'creator',
        headerName: 'creator',
      },
      {
        field: 'id',
        headerName: '',
        colRatio: 0.1,
        valueFormatter: (_, { id, name, url }) => (
          <div
            onClick={event => {
              // 避免觸發 TableRow 的導頁行為
              event.stopPropagation()
            }}
          >
            <TableMoreAction
              isOpen={id === openDropdownId}
              onOpen={currentValue => {
                if (currentValue === false) {
                  // 代表 clickAway
                  setOpenDropdownId(-1)
                  return
                }
                setOpenDropdownId(id)
              }}
              onClose={() => {
                setOpenDropdownId(-1)
              }}
              onDelete={() => {
                setDeleteDialogConfig({ id, name })
              }}
              onCopy={() => {
                setTracingUrlDialogConfig({ name, url })
                setTracingUrlDialogMode('create')
              }}
            />
          </div>
        ),
      },
    ],
    [openDropdownId]
  )

  const handleSave = async (payload: { name: string; url: string }) => {
    try {
      await createTracingUrl(payload).unwrap()

      trackEvent('MarketingAutomationTemplateConfigured', {
        templateName: payload.name,
        templateType: 'medium_template',
      })

      setTracingUrlDialogConfig(undefined)
      setTracingUrlDialogMode('preview')
    } catch (error) {
      handleError({
        url: payload.url,
        error,
      })
    }
  }

  return (
    <>
      <Box
        sx={{
          mb: 5,
          display: 'grid',
          justifyContent: 'flex-end',
        }}
      >
        <SearchInput
          hasShadow
          placeholder={t('common:search')}
          onSearch={searchWord => {
            setPage(1)
            setSearch(searchWord)
          }}
        />
      </Box>
      <DataTable
        columns={columns}
        rows={currPageTracingUrlList}
        count={tracingUrlList.length}
        isLoading={isLoadingTracingUrlList}
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="id"
        isRowClickable
        onRowClick={onRowClick}
        onPageChange={newPage => {
          setPage(newPage + 1)
        }}
        onRowsPerPageChange={setPerPage}
      />
      {tracingUrlDialogConfig && (
        <TracingUrlDialog
          mode={trackingUrlDialogMode}
          data={tracingUrlDialogConfig}
          isLoading={isCreating}
          onClose={() => setTracingUrlDialogConfig(undefined)}
          onSave={handleSave}
        />
      )}
      {deleteDialogConfig && (
        <DeleteConfirmDialog
          modalTitle={t('contentTemplate:tracing_url.confirm_to_delete', {
            name: deleteDialogConfig.name,
          })}
          isOpen
          onClose={() => {
            setDeleteDialogConfig(undefined)
          }}
          isLoading={isDeleting}
          onConfirm={async () => {
            const { id } = deleteDialogConfig || {}
            if (!id) {
              return
            }
            try {
              await deleteTracingUrl(id).unwrap()
            } catch (_error) {
              dispatch(
                openToast({
                  message: t('common:failure_delete'),
                  status: 'error',
                })
              )
            } finally {
              setDeleteDialogConfig(undefined)
            }
          }}
        />
      )}
    </>
  )
}

export default Overview
