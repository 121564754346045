import axios from 'axios'

import api from '@shared/api/axios'
import type {
  CreateIngestionParams,
  IngestionAPIMethod,
  IngestionData,
  IngestionErrorCode,
  IngestionResourceType,
  IngestionResponse,
  UploadFileToGCSProps,
} from '@shared/model/types/ingestion'

const getIngestionErrorCode = (errorCode: number): IngestionErrorCode => {
  switch (errorCode) {
    case 1:
      return 'internalError'
    case 10000:
      return 'dataFormatError'
    case 10008:
      return 'tooManyRecords'

    // behavior_tag error code
    case 10002:
      return 'userLabelCountExceed'
    case 10003:
      return 'labelLengthExceed'
    case 10004:
      return 'userNotExist'
    case 10005:
      return 'totalLabelCountExceed'

    case 0:
    default:
      return 'none'
  }
}

const mapIngestionData = <T extends IngestionResourceType>(
  resourceType: T,
  ingestion: IngestionResponse<T>
): IngestionData => {
  return {
    id: ingestion.id,
    resourceType:
      'resourceType' in ingestion ? ingestion.resourceType : resourceType,
    status: ingestion.status,
    errorCode: getIngestionErrorCode(ingestion.errorCode),
    errorMessage: 'errorMessage' in ingestion ? ingestion.errorMessage : '',
    gcsPutUrl: ingestion.putUrl,
    gcsPutUrlExpiredAt: ingestion.putUrlExpiredAt,
  }
}

export const putUploadFileToGCS = async ({
  uploadURL,
  file,
  contentType = 'application/octet-stream',
}: UploadFileToGCSProps) => {
  await axios.put(uploadURL, file, {
    headers: {
      'Content-Type': contentType,
    },
  })
}

export const behaviorTagUserAPI: IngestionAPIMethod<'behavior_tag_user'> = {
  create: async (payload: CreateIngestionParams<'behavior_tag_user'>) =>
    mapIngestionData(
      'behavior_tag_user',
      (
        await api.post<IngestionResponse<'behavior_tag_user'>>(
          '/behavior_tag/ingestion',
          payload
        )
      ).data
    ),
  query: async (ingestionId: number) =>
    mapIngestionData(
      'behavior_tag_user',
      (
        await api.get<IngestionResponse<'behavior_tag_user'>>(
          `/behavior_tag/ingestion/${ingestionId}`
        )
      ).data
    ),

  trigger: async (ingestionId: number) => {
    await api.post(`/behavior_tag/ingestion/${ingestionId}/trigger`)
  },
}

export const tagUserAPI: IngestionAPIMethod<'tag_user'> = {
  create: async (payload: CreateIngestionParams<'tag_user'>) =>
    mapIngestionData(
      'tag_user',
      (
        await api.post<IngestionResponse<'tag_user'>>(
          '/tag_user/ingestions',
          payload
        )
      ).data
    ),
  query: async (ingestionId: number) =>
    mapIngestionData(
      'tag_user',
      (
        await api.get<IngestionResponse<'tag_user'>>(
          `/tag_user/ingestions/${ingestionId}`
        )
      ).data
    ),
  trigger: async (ingestionId: number) => {
    await api.post(`/tag_user/ingestions/${ingestionId}/trigger`)
  },
}
export const eventAPI: IngestionAPIMethod<'event'> = {
  create: async (payload: CreateIngestionParams<'event'>) =>
    mapIngestionData(
      'event',
      (await api.post<IngestionResponse<'event'>>('/event/ingestions', payload))
        .data
    ),
  query: async (ingestionId: number) =>
    mapIngestionData(
      'event',
      (
        await api.get<IngestionResponse<'event'>>(
          `/event/ingestions/${ingestionId}`
        )
      ).data
    ),
  trigger: async (ingestionId: number) => {
    await api.post(`/event/ingestions/${ingestionId}/trigger`)
  },
}
export const dimensionAPI: IngestionAPIMethod<'dimension'> = {
  create: async (payload: CreateIngestionParams<'dimension'>) =>
    mapIngestionData(
      'dimension',
      (
        await api.post<IngestionResponse<'dimension'>>(
          '/dimension/ingestions',
          payload
        )
      ).data
    ),
  query: async (ingestionId: number) =>
    mapIngestionData(
      'dimension',
      (
        await api.get<IngestionResponse<'dimension'>>(
          `/dimension/ingestions/${ingestionId}`
        )
      ).data
    ),
  trigger: async (ingestionId: number) => {
    await api.post(`/dimension/ingestions/${ingestionId}/trigger`)
  },
}
export const userProfileAPI: IngestionAPIMethod<'user_profile'> = {
  create: async (payload: CreateIngestionParams<'user_profile'>) =>
    mapIngestionData(
      'user_profile',
      (
        await api.post<IngestionResponse<'user_profile'>>(
          '/user_profile/ingestions',
          payload
        )
      ).data
    ),
  query: async (ingestionId: number) =>
    mapIngestionData(
      'user_profile',
      (
        await api.get<IngestionResponse<'user_profile'>>(
          `/user_profile/ingestions/${ingestionId}`
        )
      ).data
    ),
  trigger: async (ingestionId: number) => {
    await api.post(`/user_profile/ingestions/${ingestionId}/trigger`)
  },
}

export const ingestionAPI: Record<
  IngestionResourceType,
  IngestionAPIMethod<IngestionResourceType>
> = {
  behavior_tag_user: behaviorTagUserAPI,
  event: eventAPI,
  tag_user: tagUserAPI,
  dimension: dimensionAPI,
  user_profile: userProfileAPI,
}
