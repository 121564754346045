import styled from '@emotion/styled'

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 0 6px 0;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
`

export const Text = styled.div`
  width: 200px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 14px;
  font-family: inherit;
  font-weight: 500;
`

export const Required = styled.span`
  color: ${({ theme }) => theme.colors.chartRed};
`
