import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const baseURL = import.meta.env.VITE_API_URL_EDITOR

export const POLLING_INTERVAL = 10000

export type PaginationParams = {
  page?: number
  perPage?: number
}

export type PaginationResponse<T> = {
  items: T[]
  totalCount: number
}

export const INITIAL_PAGINATION_RESPONSE: PaginationResponse<never> = {
  items: [],
  totalCount: 0,
}

export const paginationResponseHandler = async <T>(
  response: Response
): Promise<PaginationResponse<T>> => ({
  items: await response.json(),
  totalCount: parseInt(response.headers.get('total') || '0', 10),
})

// 未來都改用此函式替換自定義的 transformResponse 後，即可將這段邏輯移到 global
export const transformResponseCamelCaseKeys = <T extends Object>(response: T) =>
  camelcaseKeys(response, { deep: true })

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers, { getState }) => {
    const {
      firebase: { idToken },
      cdpAuthToken,
    } = (getState() as RootState).auth

    if (idToken) {
      headers.set('X-ID-TOKEN', idToken)
    }

    if (cdpAuthToken) {
      headers.set('Authorization', cdpAuthToken)
    }

    return headers
  },
})

const baseQueryWithApiHandler: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  if (typeof args !== 'string') {
    if (args.params) {
      args.params = snakecaseKeys(args.params, { deep: true })
    }

    if (args.body) {
      args.body = snakecaseKeys(args.body, { deep: true })
    }
  }

  const result = await baseQuery(args, api, extraOptions)

  // TODO: 啟用全域的 camelcaseKeys 的轉換
  // if (result.data) {
  //   result.data = camelcaseKeys(result.data as Object, {
  //     deep: true,
  //     // replacement_data 內有 action_url_1 這類屬性，如果轉成 camel case 會無法轉回正確的 snake case
  //     // ex.
  //     // action_url_1 => actionUrl1 (camel case) => action_url1 (snake case)
  //     // 'action_url_1' !== 'action_url1'
  //     stopPaths: ['replacement_data'],
  //   })
  // }

  if (result.error) {
    switch (result.error.status) {
      case 401: {
        // 避免依賴循環參考不使用 action creator: logout()
        api.dispatch({ type: 'logout' })
      }
    }
  }

  return result
}

export const api = createApi({
  baseQuery: baseQueryWithApiHandler,
  tagTypes: [
    'Audience',
    'BehaviorTag',
    'BehaviorTagIngestion',
    'CampaignInsight',
    'CampaignInsightDetail',
    'CampaignList',
    'CampaignsStatistic',
    'CdpUserThirdPartyTagList',
    'Consumptions',
    'ContentTemplate',
    'ContentTemplateCoolBeWyeth',
    'ContentTemplateMaac',
    'ContentTemplateOmnichat',
    'ContentTemplateOmnichatGroup',
    'ContentTemplateSingle',
    'ContentTemplateSuper8',
    'DataExporterExports',
    'DimensionIngestion',
    'EventIngestion',
    'EventRuleGroup',
    'EventRuleList',
    'ExportAudienceRule',
    'ExportCSV',
    'ExportFacebookAds',
    'ExportGoogleAds',
    'ExportIterable',
    'FunnelRuleGroup',
    'FunnelRuleList',
    'MetadataDimensionTables',
    'MetadataEvents',
    'MetadataUserProfiles',
    'MsgChannel',
    'MsgSender',
    'NotificationRuleList',
    'NotificationUsers',
    'Policy',
    'Product',
    'ProductHashtags',
    'ProductKol',
    'ProductKolTag',
    'ProductsAigc',
    'ProductsKeywordsAigc',
    'ProductsKeywordsBlacklist',
    'ProjectUser',
    'Rfm',
    'Subscription',
    'Tag',
    'TagGroup',
    'TagSummary',
    'TagUserIngestion',
    'ThirdPartyAppIntegration',
    'ThirdPartyAppIntegrationGroup',
    'ThirdPartyTag',
    'ThirdPartyTagDetail',
    'TracingUrl',
    'UserProfileConfigs',
    'UserProfileIngestion',
    'Workflow',
    'WorkflowConfiguration',
    'WorkflowInsightDetail',
    'WorkflowsInsight',
    'WorkflowSubscriptionInsight',
    'WorkflowSubscriptionInsightDetail',
  ],
  endpoints: () => ({}),
})

// 需要配合 msw handler 設定使用。 baseUrl port 設定需要和 vite config 一致

export const mockApi = createApi({
  reducerPath: 'mockRTKQuery',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3000' }),
  endpoints: () => ({}),
})
