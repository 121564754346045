import {
  createMetadataPropertyId,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'

const useConversionDisplayName = ({
  conversionEventEnable,
  conversionEvent,
  conversionEventField,
}: {
  conversionEventEnable: boolean
  conversionEvent: string
  conversionEventField: string
}): {
  conversionEventDisplayName: string
  conversionValueDisplayName: string
} => {
  const {
    eventEntity,
    isLoading: isMetadataLoading,
    isErrorEventEntity,
  } = useMetadataQuery()

  if (isMetadataLoading || isErrorEventEntity || !conversionEventEnable) {
    return {
      conversionEventDisplayName: '',
      conversionValueDisplayName: '',
    }
  }

  const currentEvent = eventEntity.events.entities[conversionEvent]
  const conversionEventDisplayName = currentEvent?.displayName || ''

  const conversionValueDisplayName =
    currentEvent.propertiesEntity[
      // 目前 source type 只會是 events
      createMetadataPropertyId('events', conversionEventField)
    ]?.displayName || ''

  return {
    conversionEventDisplayName,
    conversionValueDisplayName,
  }
}

export default useConversionDisplayName
