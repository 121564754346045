import ReactApexChart from 'react-apexcharts'
import Box from '@mui/material/Box'
import i18n from 'i18next'

import theme from '@theme'
import { getChartTooltipRow } from '@widgets/audience/lib/chartUtils'
import { CHART_HEIGHT } from '@widgets/audience/model/constant'
import ChartWrapper from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/ChartWrapper'

type Props = {
  data: Array<{
    x: string
    y: number
  }>
  colors: string[]
  total: number
}

const TreemapChart = ({ data, total, colors }: Props) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    dataLabels: {
      formatter: function (val, opts) {
        const { value } = opts

        if (Array.isArray(val) || value / total < 0.02) {
          return ''
        }

        return val
      },
    },
    colors,
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
      },
    },
    stroke: {
      width: 1,
      colors: [theme.colors.white],
    },
    tooltip: {
      custom: ({ w: { config } }) => {
        const { series, colors } = config
        const result: string[] = []

        series[0].data.forEach((s: Props['data'][number], index: number) => {
          const ration = s.y / total

          result.push(
            getChartTooltipRow({
              label: s.x,
              color: colors[index],
              value: i18n.t('audience:unit.people', {
                number: s.y.toLocaleString(),
              }),
              ration,
            })
          )
        })

        return `<div class="audience-tooltip-wrapper">
                  <div class="audience-tooltip-column">${result.join('')}</div>
                </div>`
      },
    },
  }

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <ChartWrapper>
        <ReactApexChart
          options={options}
          series={[{ data }]}
          type="treemap"
          height={CHART_HEIGHT}
          width="100%"
        />
      </ChartWrapper>
    </Box>
  )
}

export default TreemapChart
