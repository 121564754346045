import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import range from 'lodash/range'

import type { Option } from '@shared/ui/Dropdown/DropdownList'

export const useTimeOptions = () => {
  const hourOptions: Option[] = useMemo(
    () =>
      range(0, 24).map(value => ({
        label: `${value}`.padStart(2, '0'),
        value,
      })),
    []
  )

  const minuteOptions: Option[] = useMemo(
    () =>
      range(0, 60).map(value => ({
        label: `${value}`.padStart(2, '0'),
        value,
      })),
    []
  )

  const { t } = useTranslation(['audience'])

  const retryOptions: Option[] = useMemo(
    () =>
      range(1, 25).map(value => ({
        label: `${value}  ${t('audience:export_sms.retry_period_hours')}`,
        value,
      })),
    [t]
  )

  return {
    hourOptions,
    minuteOptions,
    retryOptions,
  }
}

export default useTimeOptions
