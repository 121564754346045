import ReactApexChart from 'react-apexcharts'
import styled from '@emotion/styled'

import { getFloatSafe } from '@shared/lib/utils/number'
import theme from '@theme'

import type { ChartData } from './_shared'

const Wrapper = styled.div`
  position: absolute;
  top: -40px;
  left: 42px;
  width: calc(100% - 46px);

  transform: scale(1, 0.8);
  z-index: 0;
`

/*

  const data = [
    {
      categories: ['5-1', '5-2', '5-3'],
      series: [
        {
          name: '',
          data: [1, 2, 3]
        },
        {
          name: '',
          data: [4, 5, 6]
        },
        {
          name: '',
          data: [6, 7, 8]
        }
      ]
    }
  ];

  // getSeries(data) output
  {
    name: "",
    data: [(1 + 4 + 6), (2 + 5 + 7), (3 + 6 + 8)]
  }

*/
const getSeries = (data: ChartData<number | null>[]) => {
  const { categories, series } = data[0]
  const len = categories.length
  const sumSeries = {
    name: '',
    data: [] as number[],
  }

  for (let i = 0; i < len; i++) {
    sumSeries.data[i] = series.reduce((acc, curr) => {
      const { data } = curr
      return acc + getFloatSafe(data[i])
    }, 0)
  }

  return [sumSeries]
}

export const FullRangeLineChart = (props: {
  data: ChartData<number | null>[]
}) => {
  const { data } = props

  if (data.length === 0) {
    return null
  }

  const series = getSeries(data)

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.colors.veryLightPink],
    stroke: {
      width: 2,
      curve: 'smooth',
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [],
      tooltip: {
        enabled: false,
      },
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    grid: {
      borderColor: 'transparent',
    },
  }

  return (
    <Wrapper>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={100}
      />
    </Wrapper>
  )
}

export default FullRangeLineChart
