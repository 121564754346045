import type { Edge, Node } from 'reactflow'

import type { Simplify } from '@shared/lib/utils/type'

import type { MediumType, ReplacementData } from '../contentTemplate'

export type FlowSubTypeMapped = {
  action: 'send_email' | 'send_sms' | 'send_line_push' | 'publish_subscription'
  exit: never
  logic: 'event_filter' | 'group' | 'best_channel'
  timing: 'best'
  trigger: 'enter_audience_rule' | 'current_audience_rule'
}

export type FlowType = Simplify<keyof FlowSubTypeMapped>

export type FlowSubType = FlowSubTypeMapped[FlowType]

export const FLOW_TYPE_CONNECTOR = '-'

type CreateNodeType<T> = T extends FlowType
  ? FlowSubTypeMapped[T] extends never
    ? `${T}`
    : `${T}${typeof FLOW_TYPE_CONNECTOR}${FlowSubTypeMapped[T]}`
  : never

// 由於 timing node 的形式無法透過推斷得知，故額外加上
export type NodeType = CreateNodeType<FlowType> | 'timing'

export type TargetNodeValue = 'default' | 'yes' | 'no'

export type WorkflowStatus =
  | 'draft'
  | 'enabled'
  | 'disabled'
  | 'deleted'
  | 'end'

export type WorkflowNodeFromApi = {
  display?: {
    x_pos: number
    y_pos: number
  }
  flow: {
    sub_type?: FlowSubType
    type: FlowType
    node_id: string
    node_input?: Record<string, unknown>
    to_node?: {
      [name in TargetNodeValue]?: string
    }
  }
  users_in_node?: number
  // 後端啟用 workflow 時設定的 id (對應到 triggerId)
  node_id?: number
}

export type WorkflowFromApi = {
  id?: number
  name: string
  nodes: WorkflowNodeFromApi[]
  same_user_run_limit_enabled: boolean
  same_user_run_limit: number
  status: WorkflowStatus
  user_lifetime_limit_enabled: boolean
  user_lifetime_limit: number
  conversion_event_enable: boolean
  conversion_event: string
  conversion_value: string
  end_at?: string // YYYY/MM/DD
}

export type Workflow = {
  id: number | undefined
  edges: Edge[]
  name: string
  nodes: Node[]
  sameUserRunLimit: number
  sameUserRunLimitEnabled: boolean
  status: WorkflowStatus
  userLifetimeLimit: number
  userLifetimeLimitEnabled: boolean
  conversionEventEnable: boolean
  conversionEvent: string
  conversionValue: string
  endAt?: string // 格式是 YYYY/MM/DD
}

export type CampaignStatus =
  | ''
  | 'authorize_failed'
  | 'ses_invalid_msg_sender'
  | 'maac_template_deleted'
  | 'maac_template_with_params'
  | 'msg_channel_not_found'

// unknown 狀態是建立後都尚未驗證，也沒有任何旅程觸發過
export type SubscriptionStatus = '' | 'unknown' | 'ok' | 'bad' | 'deleted'

export type WorkflowListItem = {
  id: number
  lastEnabledAt: string
  lastUpdatedBy: string
  name: string
  nodes: {
    display?: { xPos: number; yPos: number }
    flow: {
      subType?: FlowSubType
      type: FlowType
      nodeId: string
      nodeInput?: Record<string, unknown>
      toNode?: { [name in TargetNodeValue]?: string }
    }
    usersInNode?: number
    nodeId?: number
  }[]
  sameUserRunLimitEnabled: boolean
  sameUserRunLimit: number
  sourceName: string
  sourceId: number
  status: WorkflowStatus
  totalCompletedUser: number
  totalSentEmail: number
  totalSentLinePush: number
  totalSentSms: number
  totalTriggeredUser: number
  updatedAt: string
  userLifetimeLimitEnabled: boolean
  userLifetimeLimit: number
  campaigns?: {
    id: number
    mediumType: MediumType
    previewUrl: string
    title: string
    triggerId: number
    lastFinishSendStatus: CampaignStatus
    sentConfig: Record<string, object | null>
    replacementData?: ReplacementData
  }[]
  subscriptions?: {
    triggerId: number
    subscriptionNodeId: number
    subscriptionName: string
    lastFinishSendStatus: SubscriptionStatus
  }[]
  triggerNodeFlowSubtype: 'enter_audience_rule' | 'current_audience_rule'
  endAt?: string
}

export type CreateWorkflowResponse = {
  workflowId: number
}

export type WorkflowFeature = {
  bestEngagement: boolean
}
