import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { PAGE_ROOT } from '@shared/model/constants/routes'
import AudienceCreate from '@widgets/audience/ui/AudienceCreate'
import AudienceDetailExport, {
  ExportTextMessage,
} from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport'
import AudienceDetailLayout from '@widgets/audience/ui/AudienceDetail/AudienceDetailLayout'
import AudienceEdit from '@widgets/audience/ui/AudienceEdit'
import AudienceList from '@widgets/audience/ui/AudienceList'

import AudienceDetailAnalysis from './AudienceDetail/AudienceDetailAnalysis'

const AudienceDetailRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <AudienceDetailLayout>
      <Switch>
        <Route exact path={path}>
          <AudienceDetailExport />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.analysis}`}>
          <AudienceDetailAnalysis />
        </Route>

        <Redirect to={path} />
      </Switch>
    </AudienceDetailLayout>
  )
}

export const AudienceRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.new}`}>
        <AudienceCreate />
      </Route>

      <Route
        path={`${path}/:id/${PAGE_ROOT.thirdParty}/:thirdPartyId/${PAGE_ROOT.exportTextMessage}`}
      >
        <ExportTextMessage />
      </Route>

      <Route path={`${path}/:id/${PAGE_ROOT.edit}`}>
        <AudienceEdit />
      </Route>

      <Route path={`${path}/:id`}>
        <AudienceDetailRoutes />
      </Route>

      <Route exact path={path}>
        <AudienceList />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.new}`} />
    </Switch>
  )
}

export default AudienceRoutes
