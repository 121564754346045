import * as yup from 'yup'

import i18n from '@i18n'

export const logicGroupNodeSchema = yup.object({
  groups: yup
    .array()
    .of(yup.number().required())
    .required()
    .test(
      'check-sum-100',
      () =>
        i18n.t('workflow:node_logic_group.edit_dialog.errors.sum_must_be_100'),
      values => {
        const sum =
          values?.reduce((acc = 0, curr = 0) => {
            return acc + curr
          }, 0) || 0
        return sum === 100
      }
    ),
})

export interface LogicGroupNodeData
  extends yup.InferType<typeof logicGroupNodeSchema> {}
