import type { ThirdPartyAppProvider } from '@shared/api/rtkQuery'

export const BUTTON_WIDTH = 200
export const INPUT_NAME_WIDTH = 400

type ThirdPartyAuthType = 'apiKey' | 'accountAndPassword'

export const thirdPartyAuthTypeMap: {
  [name in ThirdPartyAppProvider]: ThirdPartyAuthType
} = {
  maac: 'apiKey',
  iterable: 'apiKey',
  every8d: 'accountAndPassword',
  mitake: 'accountAndPassword',
  super8: 'apiKey',
  coolbe_wyeth: 'apiKey',
  every8d_exclusive: 'accountAndPassword',
  newsleopard: 'apiKey',
  ses: 'apiKey',
  omnichat: 'apiKey',
}

export const STATUS_CODE = {
  CREATE_MAX_COUNT: 406,
  NAME_CONFLICT: 409,
}
