import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  InputArea,
  InputAreaGrid,
  LabelArea,
} from '@features/subscription/_shared'
import {
  SubscriptionFormValues,
  useValidateSubscriptionUrlMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { BlueBorderButton } from '@shared/ui/buttons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'

type Props = {
  id?: number
  value: SubscriptionFormValues['url']
  onChange?: (value: SubscriptionFormValues['url']) => void
  isDisabled?: boolean
  isError?: boolean
  isValidator?: boolean
}

const Endpoint = ({
  id,
  value,
  onChange,
  isDisabled = false,
  isError = false,
  isValidator = false,
}: Props) => {
  const { t } = useTranslation(['contentTemplate'])
  const [validateSubscriptionUrl, { isLoading: isValidating }] =
    useValidateSubscriptionUrlMutation()

  const dispatch = useAppDispatch()

  const handleValidateSubscriptionUrl = async () => {
    try {
      const { statusCode, responseMessage } = await validateSubscriptionUrl({
        id,
        url: value,
      }).unwrap()

      if (statusCode === 200) {
        dispatch(
          openToast({
            status: 'success',
            message: t(
              'contentTemplate:subscription.dialog.common.validate_endpoint_success'
            ),
          })
        )
        return
      }

      dispatch(
        openToast({
          status: 'error',
          message: t(
            'contentTemplate:subscription.dialog.common.validate_endpoint_fail',
            {
              message: responseMessage,
            }
          ),
        })
      )
    } catch (error) {
      const typedError = formatRTKQueryError(error)

      dispatch(handleApiError(typedError))
    }
  }

  return (
    <InputAreaGrid flexAlignItems="flex-start">
      <LabelArea pt={1}>
        {t('contentTemplate:subscription.dialog.common.endpoint')}*
      </LabelArea>
      <InputArea>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <PrimaryGreyTextarea
            resize={isDisabled ? 'none' : 'vertical'}
            disabled={isDisabled}
            isError={isError}
            onChange={({ target: { value } }) => onChange?.(value)}
            placeholder={t(
              'contentTemplate:subscription.dialog.common.endpoint_placeholder'
            )}
            value={value}
          />
          {isValidator && (
            <BlueBorderButton
              disabled={isDisabled || isValidating || isError || !value}
              onClick={handleValidateSubscriptionUrl}
              ml={2}
            >
              <Box whiteSpace="nowrap">
                {isValidating
                  ? t(
                      'contentTemplate:subscription.dialog.common.validating_endpoint'
                    )
                  : t(
                      'contentTemplate:subscription.dialog.common.validate_endpoint'
                    )}
              </Box>
            </BlueBorderButton>
          )}
        </Box>
      </InputArea>
    </InputAreaGrid>
  )
}

export default Endpoint
