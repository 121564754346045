import { forwardRef } from 'react'
import styled from '@emotion/styled'
import MuiMenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import { spacing } from '@mui/system'

export const MenuItem = styled(
  forwardRef<HTMLLIElement, MenuItemProps>(
    ({ disabled, ...otherProps }, ref) => (
      <MuiMenuItem
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
        ref={ref}
      />
    )
  )
)`
  && {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.black25 : theme.colors.textSecondBlue};

    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

    ${spacing}
  }
`

export default MenuItem
