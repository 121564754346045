import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { checkIsTaiwanPhone } from '@shared/lib/utils/validation'

export type SendSmsSchema = {
  tagInput?: string
  phoneNumbers: string[]
  integrationId: number
}

const useSendSmsSchema = () => {
  const { t } = useTranslation(['common'])

  const sendSmsSchema = yup.object<SendSmsSchema>({
    tagInput: yup.string().test({
      name: 'phoneNumberInput',
      message: t('common:errors.invalid'),
      test: value => {
        if (!value) {
          return true
        }

        return checkIsTaiwanPhone(value)
      },
    }),

    phoneNumbers: yup.array().test({
      name: 'phoneNumbers',
      message: t('common:errors.required'),
      test: (value, { parent }) => {
        const { tagInput } = parent

        return (value && value.length > 0) || Boolean(tagInput)
      },
    }),
    integrationId: yup
      .number()
      .required()
      .positive(t('common:errors.required')),
  })

  return { sendSmsSchema }
}

export default useSendSmsSchema
