import {
  AttributeConditionFilterNode,
  AttributeDimOperator,
  AttributeOperator,
  AttributeOperatorUI,
  AttributeParams,
  DIMENSION_SOURCE_PREFIX,
  MetadataType,
} from '@shared/api/rtkQuery'
import { formatDateDisplay } from '@shared/lib/utils/time'

export { default as calculateNumberInputStep } from './calculateNumberInputStep'

// ref: https://docs.google.com/spreadsheets/d/1mNMS6VOx7ipRlcBA9dLQybekNjZQNEKtR1ajxdjN3ZA/edit#gid=0
export const metadataTypeAttributeOperatorMap: {
  [name in MetadataType]: AttributeOperatorUI[]
} = {
  INTEGER: ['=', '!=', '>', '>=', '<', '<=', 'between', 'is_null', 'not_null'],
  FLOAT: ['=', '!=', '>', '>=', '<', '<=', 'between', 'is_null', 'not_null'],
  STRING: [
    '=',
    '!=',
    'contain',
    'not_contain',
    'start_at',
    'not_start_at',
    'end_at',
    'not_end_at',
    'is_empty',
    'not_empty',
    'is_null',
    'not_null',
    'match_regex',
  ],
  BOOL: ['is_true', 'is_false', 'is_null', 'not_null'],
  ENUM: ['=', '!=', 'is_null', 'not_null'],
  DATE: [
    'absolute_between',
    'relative_between',
    'relative_between_truncated+past',
    'relative_between_truncated',
    'is_null',
    'not_null',
  ],
  TIMESTAMP: [
    'absolute_between',
    'relative_between',
    'relative_between_truncated+past',
    'relative_between_truncated',
    'is_null',
    'not_null',
  ],
}

export const getDimOperators = (
  source: string,
  dataType: MetadataType,
  repeated: boolean = false
): AttributeDimOperator[] => {
  if (!source.startsWith(DIMENSION_SOURCE_PREFIX)) {
    return []
  }

  if (dataType === 'STRING' && repeated) {
    return ['any_match']
  }

  if (dataType === 'INTEGER' || dataType === 'FLOAT') {
    return ['all_match', 'any_match', 'sum']
  }

  return ['all_match', 'any_match']
}

export const formatDateOrTimestamp = (date: Date, format: MetadataType) => {
  if (format === 'TIMESTAMP') {
    return date.toISOString()
  }

  if (format === 'DATE') {
    return formatDateDisplay(date)
  }

  return ''
}

const notSetStartDate = new Date(0, 0, 1, 0, 0, 0)
const notSetEndDate = new Date(2199, 11, 31, 23, 59, 59)

export const NOT_SET_DATE: {
  [name in Extract<MetadataType, 'DATE' | 'TIMESTAMP'>]: {
    START_DATE: string
    END_DATE: string
  }
} = {
  DATE: {
    START_DATE: formatDateOrTimestamp(notSetStartDate, 'DATE'),
    END_DATE: formatDateOrTimestamp(notSetEndDate, 'DATE'),
  },
  TIMESTAMP: {
    START_DATE: formatDateOrTimestamp(notSetStartDate, 'TIMESTAMP'),
    END_DATE: formatDateOrTimestamp(notSetEndDate, 'TIMESTAMP'),
  },
} as const

export const getDefaultAttributeParams = (
  dataType: MetadataType,
  operator: AttributeOperatorUI
): AttributeParams | undefined => {
  if (
    operator === 'is_null' ||
    operator === 'not_null' ||
    operator === 'is_empty' ||
    operator === 'not_empty' ||
    operator === 'is_true' ||
    operator === 'is_false'
  ) {
    return undefined
  }

  if (dataType === 'INTEGER' || dataType === 'FLOAT') {
    if (operator === 'between') {
      return [0, 0]
    }
    return [0]
  }

  if (dataType === 'STRING') {
    return ['']
  }

  if (dataType === 'DATE' || dataType === 'TIMESTAMP') {
    if (operator === 'absolute_between') {
      // 此兩值分別代表不限制起始與結束時間
      return [
        NOT_SET_DATE[dataType].START_DATE,
        NOT_SET_DATE[dataType].END_DATE,
      ]
    }

    if (operator === 'relative_between') {
      return [-1, 0, 'day']
    }

    if (operator === 'relative_between_truncated+past') {
      return [-1, 0, 'day']
    }

    if (operator === 'relative_between_truncated') {
      return [0, 1, 'day']
    }
  }

  if (dataType === 'ENUM') {
    return []
  }

  return undefined
}

export const createAttributeCondition = ({
  field,
  dimOperator,
  operator,
  params,
  source,
}: Omit<
  AttributeConditionFilterNode,
  'type'
>): AttributeConditionFilterNode => ({
  type: 'condition',
  field,
  dimOperator,
  operator,
  params,
  source,
})

export const createUiOperator = (
  operator: AttributeOperator,
  params: AttributeParams | undefined
): AttributeOperatorUI => {
  if (operator !== 'relative_between_truncated') {
    return operator
  }

  return Array.isArray(params) && +params[0] < 0
    ? 'relative_between_truncated+past'
    : 'relative_between_truncated'
}

export const resolveUiOperator = (
  uiOperator: AttributeOperatorUI
): AttributeOperator => uiOperator.replace(/[+].+/, '') as AttributeOperator
