import { useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'

import type { MediumType } from '@shared/api/rtkQuery'
import {
  type MsgChannelList,
  INITIAL_PAGINATION_RESPONSE,
  useGetMsgChannelListQuery,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'

type Props = {
  mediumType: MediumType
  page?: number
  perPage?: number
}

const useGetMsgChannelForSelectOptionsQuery = ({
  mediumType,
  page = 1,
  perPage = THIRD_PARTY_MAX_COUNT,
}: Props) => {
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const selector = useMemo(
    () =>
      createSelector<
        [
          (state: { data?: MsgChannelList }) => MsgChannelList['items'],
          (state: { isLoading: boolean }) => boolean
        ],
        {
          msgChannelOptions: {
            value: string
            label: string
            integrationType: string
            integrationId: number
            integrationLineOaName: string
            providerName: string
          }[]
          isMsgChannelLoading: boolean
        }
      >(
        [
          state => (state.data ?? INITIAL_PAGINATION_RESPONSE).items,
          state => state.isLoading,
        ],
        (msgChannelOptions, isMsgChannelLoading) => ({
          msgChannelOptions: msgChannelOptions.map(
            ({ id, title, integrationType, integrationId }) => ({
              value: String(id),
              label: title,
              integrationType,
              integrationId,
              integrationLineOaName: mediumType === 'line_push' ? title : '',
              providerName: getThirdPartyProviderName(integrationType),
            })
          ),
          isMsgChannelLoading,
        })
      ),
    [getThirdPartyProviderName, mediumType]
  )

  const result = useGetMsgChannelListQuery(
    {
      page,
      perPage,
      mediumType,
    },
    {
      selectFromResult: selector,
    }
  )

  return result
}

export default useGetMsgChannelForSelectOptionsQuery
