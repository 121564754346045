export const getToday = () => {
  const nowStart = new Date()
  nowStart.setHours(0, 0, 0, 0)
  const nowEnd = new Date()
  nowEnd.setHours(23, 59, 59)

  return {
    nowStart,
    nowEnd,
  }
}
