import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  useGetAudienceSummaryRetentionQuery,
  useGetUserProfileConfigQuery,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'
import EmptyData from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/EmptyData'

import ColumnChart from './ColumnChart'

const Retention = () => {
  const { t } = useTranslation(['audience'])
  const { audienceId } = useAudienceDetailState()
  const [retentionId, setRetentionId] = useState<number>(NO_DATA_ID)

  const {
    retentionOptions,
    isRetentionOptionsSuccess,
    isRetentionOptionsLoading,
    isRetentionOptionsUninitialized,
  } = useGetUserProfileConfigQuery(
    {
      type: 'retention',
    },
    {
      skip: audienceId === NO_DATA_ID,
      selectFromResult: ({
        data = [],
        isSuccess,
        isLoading,
        isUninitialized,
      }) => ({
        retentionOptions: data.map(ele => {
          return {
            value: ele.id,
            label: ele.triggerSchema.columnDisplayPrefix,
          }
        }),
        isRetentionOptionsSuccess: isSuccess,
        isRetentionOptionsLoading: isLoading,
        isRetentionOptionsUninitialized: isUninitialized,
      }),
    }
  )

  const {
    data: audienceSummaryRetentionData,
    isError: isAudienceSummaryRetentionError,
    isSuccess: isAudienceSummaryRetentionSuccess,
    isFetching: isAudienceSummaryRetentionFetching,
    isLoading: isAudienceSummaryRetentionLoading,
    isUninitialized: isAudienceSummaryRetentionUninitialized,
  } = useGetAudienceSummaryRetentionQuery(
    { audienceId, retentionId },
    {
      skip: audienceId === NO_DATA_ID || retentionId === NO_DATA_ID,
    }
  )

  const chartTitle = useMemo(() => {
    if (
      !audienceSummaryRetentionData ||
      !audienceSummaryRetentionData.mainGroup
    ) {
      return ''
    }

    const { name, count, ratio } = audienceSummaryRetentionData.mainGroup

    return (
      <Box>
        <Box>{t(`audience:${name}`)}</Box>
        <Box>{`${t('audience:unit.people', {
          number: count.toLocaleString(),
        })} (${(ratio * 100).toFixed(0)}%)`}</Box>
      </Box>
    )
  }, [audienceSummaryRetentionData, t])

  useEffect(() => {
    if (
      retentionId === NO_DATA_ID &&
      isRetentionOptionsSuccess &&
      retentionOptions.length > 0
    ) {
      setRetentionId(retentionOptions[0].value)
    }
  }, [isRetentionOptionsSuccess, retentionId, retentionOptions])

  return (
    <BasicCard
      chartName="retention"
      isSuccess={isAudienceSummaryRetentionSuccess && isRetentionOptionsSuccess}
      isLoading={
        isAudienceSummaryRetentionLoading ||
        isRetentionOptionsLoading ||
        isRetentionOptionsUninitialized ||
        isAudienceSummaryRetentionUninitialized
      }
      isError={
        isAudienceSummaryRetentionError ||
        (isRetentionOptionsSuccess && retentionOptions.length === 0)
      }
      isFetching={isAudienceSummaryRetentionFetching}
      subject={t('audience:retention_rate')}
      dropdownListNode={
        audienceSummaryRetentionData ? (
          <DropdownList
            options={retentionOptions}
            value={retentionId}
            onValueChanged={option => {
              setRetentionId(getIntSafe(option.value))
            }}
          />
        ) : (
          ''
        )
      }
      title={chartTitle}
      nonMembers={getIntSafe(
        audienceSummaryRetentionData?.totalNonMembers,
        ''
      ).toLocaleString()}
    >
      {audienceSummaryRetentionData ? (
        <ColumnChart
          series={audienceSummaryRetentionData.orderedByNameSummary.map(
            ele => ele.count
          )}
          categories={audienceSummaryRetentionData.orderedByNameSummary.map(
            ele => t(`audience:${ele.groupName}`)
          )}
          total={getIntSafe(audienceSummaryRetentionData.totalMembers)}
        />
      ) : (
        <EmptyData />
      )}
    </BasicCard>
  )
}

export default Retention
