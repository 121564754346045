import { MEDIUM_GROUP_INDEX } from '@features/filters/CampaignEventFilter/MeasureFilter/constants'
import type {
  CampaignEventAttributeFilterNodes,
  CampaignMediumEvent,
  MediumType,
} from '@shared/api/rtkQuery'

import findMediumGroupField from '../findMediumGroupField'

const getSafeMediumGroupParam = <T extends 'medium_type' | 'action'>({
  data,
  field,
  defaultValue,
}: {
  data?: CampaignEventAttributeFilterNodes[typeof MEDIUM_GROUP_INDEX]
  field: T
  defaultValue: T extends 'medium_type' ? MediumType : CampaignMediumEvent
}) => {
  const values = findMediumGroupField(data, field)

  if (!values) {
    return defaultValue
  }

  // medium 相關設定的 params 只會有一筆
  return values.params[0] as T extends 'medium_type'
    ? MediumType
    : CampaignMediumEvent
}

export default getSafeMediumGroupParam
