import {
  defaultValueFormatter,
  TableBodyCell,
  TableColumn,
  TableRow,
} from '@shared/ui/table'
import type { UsageDataItemType } from '@widgets/usageManagement/SendingAmount/lib/helper'

type Props = {
  detailData: UsageDataItemType
  columns: TableColumn<UsageDataItemType>[]
}

const TableDetailRow = ({ detailData, columns }: Props) => {
  return (
    <TableRow isBodyCell isStripped>
      {columns.map(
        (
          { align, field, valueFormatter = defaultValueFormatter },
          columnIndex
        ) => {
          const unTypedValueFormatter = valueFormatter as (
            value: unknown,
            data: UsageDataItemType
          ) => React.ReactNode

          const currValue = Array.isArray(field)
            ? field.map((item: keyof UsageDataItemType) => detailData[item])
            : detailData[field]

          return (
            <TableBodyCell align={align} key={`${columnIndex}`}>
              {unTypedValueFormatter(currValue, detailData)}
            </TableBodyCell>
          )
        }
      )}
    </TableRow>
  )
}

export default TableDetailRow
