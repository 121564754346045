import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetRfmUserGroupsQuery } from '@shared/api/rtkQuery'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import RfmCard from './RfmCard'

const RfmCardList = () => {
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const { data, isFetching } = useGetRfmUserGroupsQuery({ cdpUserId })

  if (isFetching) {
    return <LoadingSkeleton height={240} />
  }

  if (!data?.rfms.length) {
    return null
  }

  return (
    <Box component="ul" sx={{ display: 'grid', gap: 4 }}>
      {data.rfms.map(item => (
        <RfmCard key={item.rfmInfo.rfmId} data={item} />
      ))}
    </Box>
  )
}

export default RfmCardList
