import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import pipe from 'lodash/fp/pipe'

import LoadingContainer from '@entities/Loader/LoadingContainer'
import { useGetUserJourneyEventsQuery } from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList, {
  Option as CheckboxOption,
} from '@shared/ui/Dropdown/CheckboxList'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { EVENT_PROPERTY_IDS_DEFAULT_MAP } from './constants'
import {
  filterUserJourneyEventsByEventNameList,
  filterUserJourneyEventsByPeriod,
  getEventIcon,
  groupUserJourneyEventsByDate,
  selectUserJourneyEvents,
} from './helpers'
import { useGetEventPropertyTemplate } from './hooks'
import type { UserJourneyEventPeriod, UserJourneyEventType } from './types'

type JourneysProps = {
  userId: string
}

export const Journeys = ({ userId }: JourneysProps) => {
  const { t } = useTranslation(['userProfile', 'workflow', 'common'])

  const { userJourneyEvents, userJourneyEventEntity, isLoading } =
    useGetUserJourneyEventsQuery(
      { cdpUserId: userId },
      { selectFromResult: selectUserJourneyEvents }
    )

  const eventOptions = useMemo<CheckboxOption[]>(
    () =>
      userJourneyEventEntity.ids.map(id => ({
        label: userJourneyEventEntity.entities[id].displayName ?? id,
        value: id,
      })),
    [userJourneyEventEntity.entities, userJourneyEventEntity.ids]
  )

  const [eventNameList, setEventNameList] = useState<string[]>([])

  const periodOptions: Option<UserJourneyEventPeriod>[] = [
    { label: t('common:all'), value: 'all' },
    { label: t('userProfile:journeys.last_n_days', { days: 30 }), value: 30 },
    { label: t('userProfile:journeys.last_n_days', { days: 60 }), value: 60 },
    { label: t('userProfile:journeys.last_n_days', { days: 90 }), value: 90 },
    {
      label: t('userProfile:journeys.last_n_days', { days: 180 }),
      value: 180,
    },
  ]

  const [period, setPeriod] = useState<UserJourneyEventPeriod>('all')

  const useJourneyEventsGrouped = useMemo(
    () =>
      pipe(
        filterUserJourneyEventsByEventNameList(eventNameList),
        filterUserJourneyEventsByPeriod(period),
        groupUserJourneyEventsByDate('zh-TW')
      )(userJourneyEvents),
    [period, eventNameList, userJourneyEvents]
  )

  const getEventProperty = (eventId: string, propertyId: string) =>
    userJourneyEventEntity.entities[eventId]?.propertyEntity.entities[
      propertyId
    ]

  const getEventPropertyOptions = (eventId: string) =>
    userJourneyEventEntity.entities[eventId]?.propertyEntity.ids.map(id => ({
      label: getEventProperty(eventId, id)?.displayName ?? id,
      value: id,
    }))

  const [selectedEventPropertyIdsMap, setSelectedEventPropertyIdsMap] =
    useState(EVENT_PROPERTY_IDS_DEFAULT_MAP)

  const [getEventPropertyTemplate] = useGetEventPropertyTemplate()

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Icon
          icon={ICON.filter}
          color={theme.colors.brightBlue}
          fontSize="small"
          sx={{ mr: 1 }}
        />

        <DropdownCheckboxList
          checkedValues={eventNameList}
          hasSearchBar
          hasSelectAll
          hasShadow
          label={`${t('userProfile:journeys.display_events')} ${
            eventNameList.length
          }/${eventOptions.length}`}
          onChange={setEventNameList}
          options={eventOptions}
        />

        <Icon
          icon={ICON.calendarAlt}
          color={theme.colors.brightBlue}
          fontSize="small"
          sx={{ ml: 2, mr: 1 }}
        />

        <DropdownList
          options={periodOptions}
          bgColor={theme.colors.white}
          hasShadow
          value={period}
          onValueChanged={({ value }) => setPeriod(value)}
        />
      </Box>

      <LoadingContainer isLoading={isLoading} isBlur={false}>
        <Box
          sx={{
            px: 5,
            py: 4,
            backgroundColor: theme => theme.colors.white,
            borderRadius: 1,
            minHeight: 120,
            boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            component="ul"
            sx={{
              ':before': {
                position: 'absolute',
                left: 0,
                top: 16,
                width: 2,
                height: '100%',
                backgroundColor: theme => theme.colors.brightBlue,
                content: "''",
              },
              pl: 3,
              position: 'relative',
            }}
          >
            {useJourneyEventsGrouped.ids.map(date => (
              <Box
                key={date}
                component="li"
                sx={{
                  position: 'relative',
                  ':before': {
                    position: 'absolute',
                    display: 'inline-block',
                    backgroundColor: theme => theme.colors.brightBlue,
                    height: 14,
                    width: 14,
                    borderRadius: '50%',
                    left: -30,
                    top: 8,
                    content: "''",
                  },
                }}
              >
                <Box
                  component="p"
                  sx={{
                    color: theme => theme.colors.black,
                    fontSize: 20,
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  {date}
                </Box>

                <Box
                  component="ul"
                  sx={{
                    backgroundColor: theme => theme.colors.bgPrimaryGrey,
                    borderRadius: 2,
                    mb: 4,
                    px: 4,
                  }}
                >
                  {useJourneyEventsGrouped.entities[date].events.map(
                    (event, index) => {
                      const selectedEventPropertyIds =
                        selectedEventPropertyIdsMap[
                          event.name as UserJourneyEventType
                        ] ?? []
                      const eventPropertyOptions = getEventPropertyOptions(
                        event.name
                      )
                      const eventIcon = getEventIcon(event.name)

                      const eventDisplayName =
                        userJourneyEventEntity.entities[event.name]
                          ?.displayName ?? event.name

                      return (
                        <Box
                          key={index}
                          component="li"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 2,
                            py: 3,
                            borderTop: '1px solid #E5E5E5',
                            ':first-of-type': {
                              border: 'none',
                            },
                          }}
                        >
                          <Box
                            component="span"
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              flexBasis: 320,
                              flexShrink: 0,
                              gap: 3,
                              fontWeight: 500,
                            }}
                          >
                            {new Date(event.createdAt).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit',
                              hourCycle: 'h23',
                            })}
                            <Tooltip title={eventDisplayName}>
                              <span>
                                <IconButton
                                  size="small"
                                  sx={{
                                    p: 1,
                                    backgroundColor: eventIcon.color,
                                    fontSize: 16,
                                    pointerEvents: 'none',
                                  }}
                                >
                                  <Icon
                                    icon={eventIcon.icon}
                                    color={theme.colors.white}
                                    fontSize="inherit"
                                    sx={eventIcon.sx}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                            &nbsp;
                            {eventDisplayName}
                          </Box>

                          <Box component="ul" sx={{ flexGrow: 1 }}>
                            {selectedEventPropertyIds.map(propertyId => {
                              const property = getEventProperty(
                                event.name,
                                propertyId
                              )

                              if (!property) {
                                return null
                              }

                              return (
                                <li key={propertyId}>
                                  {property ? `${property.displayName}：` : ''}

                                  {getEventPropertyTemplate(
                                    propertyId,
                                    event.propertyRecord
                                  )}
                                </li>
                              )
                            })}
                          </Box>

                          <DropdownCheckboxList
                            key={selectedEventPropertyIds.join()}
                            hasSelectAll
                            anchorElem={
                              <Tooltip
                                title={t(
                                  'userProfile:journeys.display_columns'
                                )}
                              >
                                <IconButton size="small" sx={{ p: 1 }}>
                                  <Icon
                                    icon={ICON.slidersVAlt}
                                    color={theme.colors.textPrimaryBlue}
                                    fontSize="inherit"
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                            checkedValues={selectedEventPropertyIds}
                            hasSearchBar
                            options={eventPropertyOptions}
                            placement="bottom-end"
                            onChange={values => {
                              setSelectedEventPropertyIdsMap(prev => ({
                                ...prev,
                                [event.name]: values,
                              }))
                            }}
                          />
                        </Box>
                      )
                    }
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          {useJourneyEventsGrouped.ids.length === 0 && isLoading === false && (
            <Box component="p" sx={{ textAlign: 'center', lineHeight: 4 }}>
              {t('userProfile:no_data')}
            </Box>
          )}
        </Box>
      </LoadingContainer>
    </>
  )
}

export default Journeys
