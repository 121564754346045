import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  useGetEventRuleQuery,
  useUpdateEventRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import { Tooltip } from '@shared/ui/tooltips'
import { AnalyticsFormBreadcrumbs as EventFormBreadcrumbs } from '@widgets/analytics/_shared'
import { NO_DATA_ID } from '@widgets/analytics/_shared/constants'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import type { EventRuleFormValues } from '../_shared/types'
import EventRuleForm from '../_shared/ui/EventRuleForm'

const EventRuleEdit = () => {
  const { t } = useTranslation(['analytics', 'common'])
  const { id } = useParams<{ id: string }>()
  const eventId = getIntSafe(id, NO_DATA_ID)

  const { data: eventRule, isFetching: isFetchingEventRule } =
    useGetEventRuleQuery(eventId, {
      skip: eventId === NO_DATA_ID,
    })

  const [updateEventRule, { isLoading: isUpdatingEventRule }] =
    useUpdateEventRuleMutation()

  const dispatch = useAppDispatch()
  const { handleError } = useErrorHandler({ type: 'event' })

  const handleSubmit = async (formValues: EventRuleFormValues) => {
    try {
      await updateEventRule({
        ...formValues,
        id: eventId,
      }).unwrap()

      dispatch(
        openToast({
          message: t('analytics:event.save_success'),
        })
      )
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <EventRuleForm
      header={({
        isExportingCSV,
        isExportDisabled,
        isSaveDisabled,
        onReset: handleReset,
        onExport: handleExport,
        onShowConfirmDialog: handleShowConfirmDialog,
      }) => (
        <EventFormBreadcrumbs
          isExportDisabled={isExportDisabled}
          isExportingCSV={isExportingCSV}
          isSaveDisabled={isSaveDisabled}
          onExport={handleExport}
          onReset={handleReset}
          onShowConfirmDialog={handleShowConfirmDialog}
        >
          <Link to={`/${CATEGORY.analytics}/${PAGE_ROOT.events}/new`}>
            {t('common:route.analytics_events')}
          </Link>

          {eventRule?.title && (
            <Tooltip title={eventRule.title}>
              <Box>{eventRule.title}</Box>
            </Tooltip>
          )}
        </EventFormBreadcrumbs>
      )}
      formValues={eventRule}
      isFetchingFormValues={isFetchingEventRule}
      isSubmitting={isUpdatingEventRule}
      onSubmit={handleSubmit}
    />
  )
}

export default EventRuleEdit
