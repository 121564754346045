import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { BehaviorTag, ThirdPartyTag } from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import Card from '@shared/ui/Card'
import SectionTitle from '@shared/ui/SectionTitle'
import { Tooltip } from '@shared/ui/tooltips'
import {
  ThirdPartyTagDetail,
  ThirdPartyTagDetailNumber,
  ThirdPartyTagDetailText,
} from '@widgets/tag/detail/ThirdParty/styles'

type Props = {
  tagStatisticalData:
    | ThirdPartyTag['tagStatistics']
    | BehaviorTag['tagStatistics']
}

const TagStatistic = ({ tagStatisticalData }: Props) => {
  const { t } = useTranslation(['tag'])

  return (
    <Box mt={5}>
      <SectionTitle title={t('tag:tag_result')} />
      <Card>
        <Box display="flex" justifyContent="flex-start">
          <Box display="flex" flexDirection="column">
            <ThirdPartyTagDetailText>
              {t('tag:total_tagged')}
            </ThirdPartyTagDetailText>
            <ThirdPartyTagDetailNumber>
              {getIntSafe(tagStatisticalData?.userCount, '-')}
            </ThirdPartyTagDetailNumber>
          </Box>
          <Box display="flex" flexDirection="column" ml={6}>
            <ThirdPartyTagDetailText>
              {t('tag:tag_amount')}
            </ThirdPartyTagDetailText>
            <ThirdPartyTagDetailNumber>
              {getIntSafe(tagStatisticalData?.userTagCount, '-')}
            </ThirdPartyTagDetailNumber>
          </Box>
        </Box>
        <Box
          my={4}
          sx={theme => ({
            borderBottom: `1px solid ${theme.colors.black6}`,
          })}
        />
        <Box>
          {(tagStatisticalData?.userTagToCount ?? []).map(tagCount => (
            <Tooltip
              key={`${tagCount.userTag}_${tagCount.count}`}
              title={`${t('tag:tagged_total_count')} ${formatNumber(
                tagCount.count
              )}`}
            >
              <ThirdPartyTagDetail>{tagCount.userTag}</ThirdPartyTagDetail>
            </Tooltip>
          ))}
        </Box>
      </Card>
    </Box>
  )
}

export default TagStatistic
