import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

// ref: https://loading.io/css/
const ldsEllipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ldsEllipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`

const ldsEllipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const Loader = styled.div`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.brightBlue};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-of-type(1) {
    left: 8px;
    animation: ${ldsEllipsis1} 0.6s infinite;
  }

  div:nth-of-type(2) {
    left: 8px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  div:nth-of-type(3) {
    left: 32px;
    animation: ${ldsEllipsis2} 0.6s infinite;
  }

  div:nth-of-type(4) {
    left: 56px;
    animation: ${ldsEllipsis3} 0.6s infinite;
  }
`

type Props = {
  className?: string
}

export const DotLoader = ({ className }: Props) => (
  <Loader className={className}>
    <div />
    <div />
    <div />
    <div />
  </Loader>
)

export default DotLoader
