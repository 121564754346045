import type { Aggregator, MetadataType } from '@shared/api/rtkQuery'

export const defaultAggregators: Aggregator[] = [
  'count',
  'count_distinct',
  'count_per_user',
]

export const dataTypeExtraAggregatorsMap: {
  [name in MetadataType]: Aggregator[]
} = {
  INTEGER: ['sum', 'avg', 'max', 'min', 'sum_per_user'],
  FLOAT: ['sum', 'avg', 'max', 'min', 'sum_per_user'],
  STRING: ['count_distinct'],
  BOOL: ['count_distinct'],
  ENUM: ['count_distinct'],
  DATE: ['count_distinct'],
  TIMESTAMP: ['count_distinct'],
}

export const dimensionExtraAggregators: Aggregator[] = [
  'count_id',
  'count_distinct_id',
  'count_id_per_user',
  'count_distinct_id_per_user',
]
