import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import debounce from 'lodash/debounce'

import {
  INITIAL_PAGINATION_RESPONSE,
  ProductKol,
  useGetProductKolQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import InfiniteScrollLoader from '@shared/ui/loaders/InfiniteScrollLoader'
import SectionTitle from '@shared/ui/SectionTitle'

import { LIST_PER_PAGE, SCROLL_DEBOUNCE_TIME } from './constants'
import KolCard from './KolCard'

type Props = {
  productId: string
}

const Overview = ({ productId }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [kolList, setKolList] = useState<ProductKol[]>([])

  const {
    data: kolData = INITIAL_PAGINATION_RESPONSE,
    isFetching,
    isLoading: isFirstFetching,
    isSuccess,
  } = useGetProductKolQuery({
    productId,
    page,
    perPage: LIST_PER_PAGE,
  })
  const pageLimit = kolData.totalCount / LIST_PER_PAGE
  const hasMore = page < pageLimit && kolData.totalCount > 0

  useEffect(() => {
    setKolList(prev => [...prev, ...kolData.items])
  }, [kolData])

  const handleScrollWithDebounce = useMemo(
    () =>
      debounce(() => {
        if (hasMore) {
          setPage(page + 1)
        }
      }, SCROLL_DEBOUNCE_TIME),
    [hasMore, page]
  )

  return (
    <>
      <SectionTitle
        title={t('aiMarketingAssistant:kol_recommendation.analysis_result')}
      />
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        fontWeight={600}
        fontSize={14}
        lineHeight="20px"
        mb={3}
      >
        <Box
          sx={theme => ({
            color: theme.colors.textPrimaryBlue,
          })}
        >
          {t('aiMarketingAssistant:kol_recommendation.sort_by')}：
        </Box>
        <Box
          px={1.5}
          py={0.5}
          borderRadius="3px"
          sx={theme => ({
            backgroundColor: theme.colors.brightBlue,
            color: theme.colors.white,
          })}
        >
          {t('aiMarketingAssistant:kol_recommendation.interaction_rate')}
        </Box>
      </Box>
      <Box>
        {kolList.map(kol => (
          <KolCard kolData={kol} key={kol.kolId} />
        ))}
        {((isSuccess && hasMore) || isFirstFetching) && (
          <InfiniteScrollLoader
            isLoading={isFetching}
            loadMore={handleScrollWithDebounce}
          />
        )}
      </Box>
    </>
  )
}

export default Overview
