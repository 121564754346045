import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { DataTable } from '@shared/ui/table'

import { Radio } from './_shared/styles'
import type { ActionSendLineNodeData } from '../actionSendLineNodeSchema'
import { trialTemplate } from './trialTemplate'

type Props = {
  trialTemplateConfig: ActionSendLineNodeData['trialConfig']
  isEditable: boolean
  setTemplateConfig: (config: ActionSendLineNodeData['trialConfig']) => void
}

export const Trial = memo(
  ({ trialTemplateConfig, isEditable, setTemplateConfig }: Props) => {
    const { t } = useTranslation(['workflow'])

    const dataTableRows = useMemo(
      () =>
        trialTemplate.map(x => ({
          ...x,
          isDisabled: !isEditable,
        })),
      [isEditable]
    )

    useEffect(() => {
      if (dataTableRows.length <= 0 || trialTemplateConfig) {
        return
      }

      setTemplateConfig({ templateId: dataTableRows[0]?.id ?? '0' })
    }, [dataTableRows, trialTemplateConfig, setTemplateConfig])

    return (
      <>
        <Box mb={2} fontWeight="medium">
          {t('workflow:node_action_line_template')}
        </Box>

        <Box mb={2}>
          <DataTable
            columns={[
              {
                field: 'id',
                headerName: '',
                colRatio: 0.5,
                valueFormatter: id => (
                  <Radio
                    color="primary"
                    checked={trialTemplateConfig?.templateId === String(id)}
                    disabled={false}
                  />
                ),
              },
              {
                field: 'id',
                headerName: 'content_template_id',
                colRatio: 0.5,
              },
              { field: 'name', headerName: 'content_template_name' },
            ]}
            containerType="border"
            count={trialTemplate.length}
            isRowClickable
            onRowClick={({ id }) =>
              setTemplateConfig({ templateId: String(id) })
            }
            page={0}
            rows={dataTableRows}
            rowsPerPage={trialTemplate.length}
          />
        </Box>
      </>
    )
  }
)

export default Trial
