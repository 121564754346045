import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { ProductKol } from '@shared/api/rtkQuery'
import { isNumber } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { StaticsNumber, StaticsType } from './styles'

// 取出 ProductKol['statistic'] 當作 key，並且將其值轉為 Record<string, number>
// 寫法有點複雜，但是可以讓 typescript 自動找到定義好的 ProductKol['statistic']的key
// 因為不是每個 key 都需要使用到, 所以也用 Partial 包起來
// 預期的 type 如下
/**
 * {
 *  engagementRate?: Record<string, number>
 *  viewsRate?: Record<string, number>
 *  mentionRate?: Record<string, number>
 * }
 */

type Props = Partial<
  Record<keyof ProductKol['statistic'], Record<string, number>>
> &
  Pick<ProductKol, 'scoring'>

const Statistic = ({ scoring, engagementRate, viewsRate }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])

  return (
    <>
      <Box mb={3}>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textSecondaryGrey}
          mb={0.5}
        >
          <StaticsType>
            {t('aiMarketingAssistant:kol_recommendation.interaction_rate')}
          </StaticsType>
          <Tooltip
            title={t(
              'aiMarketingAssistant:kol_recommendation.interaction_rate_tooltip'
            )}
          >
            <Box>
              <Icon icon={ICON.infoCircle} color="inherit" fontSize="small" />
            </Box>
          </Tooltip>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textPrimaryGrey}
        >
          <Icon icon={ICON.thumbsUp} color="inherit" />
          <StaticsNumber>
            {isNumber(engagementRate?.m6) && engagementRate
              ? `${(engagementRate.m6 * 100).toFixed(2)}%`
              : '-'}
          </StaticsNumber>
        </Box>
      </Box>
      <Box mb={3}>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textSecondaryGrey}
          mb={0.5}
        >
          <StaticsType>
            {t('aiMarketingAssistant:kol_recommendation.view_rate')}
          </StaticsType>
          <Tooltip
            title={t(
              'aiMarketingAssistant:kol_recommendation.view_rate_tooltip'
            )}
          >
            <Box>
              <Icon icon={ICON.infoCircle} color="inherit" fontSize="small" />
            </Box>
          </Tooltip>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textPrimaryGrey}
        >
          <Icon icon={ICON.eye} color={theme.colors.textPrimaryGrey} />
          <StaticsNumber>
            {isNumber(viewsRate?.m6) && viewsRate
              ? `${(viewsRate.m6 * 100).toFixed(2)}%`
              : '-'}
          </StaticsNumber>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textSecondaryGrey}
        >
          <StaticsType>
            {t('aiMarketingAssistant:kol_recommendation.score')}
          </StaticsType>
          <Tooltip
            title={t('aiMarketingAssistant:kol_recommendation.score_tooltip')}
          >
            <Box>
              <Icon icon={ICON.infoCircle} color="inherit" fontSize="small" />
            </Box>
          </Tooltip>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          color={theme.colors.textPrimaryGrey}
        >
          <Icon icon={ICON.chartBar} color={theme.colors.textPrimaryGrey} />
          <StaticsNumber>
            {isNumber(scoring) ? scoring.toFixed(2) : '-'}
          </StaticsNumber>
        </Box>
      </Box>
    </>
  )
}

export default Statistic
