import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'

import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import theme from '@theme'

type Props = {
  isOpen: boolean
  onDelete: () => void
  onCopy: () => void
  onClose: () => void
  onOpen: (currentValue: boolean) => void
}

const TableMoreAction = memo(
  ({ isOpen, onClose, onDelete, onCopy, onOpen }: Props) => {
    const { t } = useTranslation(['common'])
    return (
      <Dropdown
        isOpen={isOpen}
        placement="left-start"
        setIsOpen={currentValue => {
          onOpen(currentValue as boolean)
        }}
        anchorElem={
          <IconButton>
            <Icon
              icon={ICON.ellipsisV}
              fontSize="small"
              color={theme.colors.textPrimaryBlue}
            />
          </IconButton>
        }
      >
        <MenuList onMouseLeave={onClose} width={188}>
          <MenuItem onClick={onDelete}>{t('common:delete')}</MenuItem>
          <MenuItem onClick={onCopy}>{t('common:copy')}</MenuItem>
        </MenuList>
      </Dropdown>
    )
  }
)

export default TableMoreAction
