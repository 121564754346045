import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { PrivacyLevel } from '@shared/api/rtkQuery'
import trialDashboard from '@shared/assets/images/dashboard/trialDashboard.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { trackEvent } from '@shared/lib/utils/amplitude'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { Tab } from '@shared/ui/tabs'

import type { AnalyticsType } from './_shared/type'
import ChartGroup from './ChartGroup'
import EventList from './EventList'
import FunnelList from './FunnelList'

export const DashboardRoutes = () => {
  const { t } = useTranslation(['common', 'dashboard'])

  const [privacyLevel, setPrivacyLevel] = useState<PrivacyLevel>('public')
  const [analyticsType, setAnalyticsType] = useState<AnalyticsType>('event')
  const [activeGroupId, setActiveGroupId] = useState<number>(0)

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.dashboard.introduction
  )

  useEffect(() => {
    trackEvent('DashboardViewed', {
      analysisType: analyticsType,
      groupId: activeGroupId,
    })
  }, [activeGroupId, analyticsType])

  return (
    <main>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('dashboard:instruction.title')}
          description={t('dashboard:instruction.description')}
          imageUrl={trialDashboard}
        />
      )}

      <Breadcrumbs>
        <Box>{t('common:route.dashboard')}</Box>
      </Breadcrumbs>

      <Tab
        tabOptions={[
          {
            type: 'public',
            name: t('dashboard:public'),
          },
          {
            type: 'private',
            name: t('dashboard:private'),
          },
        ]}
        currTab={privacyLevel}
        onTabChange={setPrivacyLevel}
        tabStyle="brick"
      />

      <ChartGroup
        privacyLevel={privacyLevel}
        analyticsType={analyticsType}
        onTypeChange={setAnalyticsType}
        setActiveGroupId={setActiveGroupId}
        activeGroupId={activeGroupId}
      />

      {/* 將 privacyLevel + activeGroupId 設定 key 在切換 公開/私人 時會重置所有內部狀態，從第一頁載入 */}
      {analyticsType === 'event' && (
        <EventList
          key={`${privacyLevel}_${activeGroupId}`}
          privacyLevel={privacyLevel}
          activeGroupId={activeGroupId}
        />
      )}

      {analyticsType === 'funnel' && (
        <FunnelList
          key={`${privacyLevel}_${activeGroupId}`}
          privacyLevel={privacyLevel}
          activeGroupId={activeGroupId}
        />
      )}
    </main>
  )
}

export default DashboardRoutes
