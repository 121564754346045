import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import DropdownCheckboxList, { Option } from '@shared/ui/Dropdown/CheckboxList'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type MediumTypeDropdownListProps = {
  onChange: (values: string[]) => void
}

const MediumTypeDropdownList = ({ onChange }: MediumTypeDropdownListProps) => {
  const { t } = useTranslation(['contentTemplate', 'common'])

  const options = useMemo<Option[]>(
    () => [
      { label: t('common:medium_type.email'), value: 'email' },
      { label: t('common:medium_type.sms'), value: 'sms' },
    ],
    [t]
  )

  const [selectedMediumTypeList, setSelectedMediumTypeList] = useState<
    string[]
  >([])

  const handleChange = (values: string[]) => {
    setSelectedMediumTypeList(values)
    onChange(values)
  }

  return (
    <>
      <Box
        sx={{
          mx: 1,
          fontSize: '18px',
        }}
      >
        <Icon
          id="icon-filter"
          icon={ICON.filter}
          color={theme.colors.brightBlue}
        />
      </Box>

      <Box sx={{ mx: 1 }}>
        <DropdownCheckboxList
          hasShadow
          checkedValues={selectedMediumTypeList}
          hasSelectAll
          label={t('contentTemplate:type')}
          onChange={handleChange}
          options={options}
        />
      </Box>
    </>
  )
}

export default MediumTypeDropdownList
