import { useCallback, useEffect, useRef, useState } from 'react'
import type { EditorRef } from 'react-email-editor'
import { useTranslation } from 'react-i18next'
import pipe from 'lodash/fp/pipe'
import mustache from 'mustache'

import type {
  Design,
  EditorExportData,
  ExportDesignFunction,
} from '@entities/mediumTemplates'
import {
  appendUnsubscribeFooter,
  fetchActionUrl,
  fetchMergeTag,
  fetchTracingUrl,
  recoverToOriginalLink,
} from '@entities/mediumTemplates'
import { putUploadFileToGCS } from '@shared/api/axios/ingestion'
import {
  useGetImageUploadUrlMutation,
  useGetTracingUrlListQuery,
  useSetImagePublicMutation,
} from '@shared/api/rtkQuery'
import {
  useAppDispatch,
  useMetadataUserProfileFromUsers,
} from '@shared/lib/hooks'
import htmlToImageFile from '@shared/lib/utils/htmlToImageFile'
import { createPreviewTemplateData } from '@shared/model/constants/contentTemplate'
import { PREDEFINED_METADATA_TAG_ENTITY } from '@shared/model/constants/metadata'
import { openToast } from '@shared/model/slices'
import theme from '@theme'

import useLinkTypeConfig from './useLinkTypeConfig'

export const useEmailEditor = () => {
  const emailEditorRef = useRef<EditorRef>(null)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['common'])
  const [getImageUploadUrl] = useGetImageUploadUrlMutation()
  const [setImagePublic] = useSetImagePublicMutation()

  const [isReady, setIsReady] = useState(false)
  const [isExporting, setIsExporting] = useState(false)

  const userProfilePropertyList = useMetadataUserProfileFromUsers(
    ({ id, displayName }) => ({ id, displayName })
  )
  const { linkTypes } = useLinkTypeConfig()

  useEffect(() => {
    if (!emailEditorRef.current?.editor || !isReady) {
      return
    }

    emailEditorRef.current.editor.setBodyValues({
      backgroundColor: theme.colors.white,
    })

    // 設定顧客資料變數
    const mergeTags = userProfilePropertyList.reduce<
      Record<
        string,
        | { name: string; value: string }
        | {
            name: string
            rules?: { repeat: { name: string; before: string; after: string } }
            mergeTags?: Record<string, { name: string; value: string }>
          }
      >
    >(
      (acc, curr) => {
        acc[curr.id] = { name: curr.displayName, value: `{{${curr.id}}}` }
        return acc
      },
      {
        personalized_replacement: {
          name: '用戶購物車',
          rules: {
            repeat: {
              name: '前 20 個未結帳商品',
              before: '{{#personalized_replacement}}',
              after: '{{/personalized_replacement}}',
            },
          },
          mergeTags: {
            unpurchased_product_name: {
              name: `${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_name'].displayName}`,
              value: `{{${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_name'].id}}}`,
            },
            unpurchased_product_price: {
              name: `${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_price'].displayName}`,
              value: `{{${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_price'].id}}}`,
            },
            unpurchased_product_image_url: {
              name: `${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_image_url'].displayName}`,
              value: `{{${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_image_url'].id}}}`,
            },
          },
        },
      }
    )

    emailEditorRef.current.editor.setMergeTags(mergeTags)

    emailEditorRef.current.editor.registerCallback(
      'previewHtml',
      (params: { html: string }, done: (params: { html: string }) => void) => {
        done({
          html: mustache.render(params.html, createPreviewTemplateData()),
        })
      }
    )

    // UTM 相關設定
    emailEditorRef.current.editor.setLinkTypes(linkTypes)
  }, [isReady, linkTypes, userProfilePropertyList])

  // 將使用者圖片上傳到 GCS
  useEffect(() => {
    if (!emailEditorRef.current?.editor || !isReady) {
      return
    }

    try {
      emailEditorRef.current.editor.registerCallback(
        'image',
        async (
          file: { attachments: File[] },
          done: (arg: { progress: number; url: string }) => void
        ) => {
          const urlData = await getImageUploadUrl().unwrap()

          await putUploadFileToGCS({
            uploadURL: urlData.uploadUrl,
            file: file.attachments[0],
          })

          const publicImage = await setImagePublic({
            contentType: 'image/jpeg',
            imagePath: urlData.imagePath,
          }).unwrap()

          done({ progress: 100, url: publicImage.publicUrl })
        }
      )
    } catch (error) {
      dispatch(
        openToast({
          message: t('common:upload.failure'),
          status: 'error',
        })
      )
    }
  }, [isReady, getImageUploadUrl, setImagePublic, t, dispatch])

  const { data: tracingUrlList = [] } = useGetTracingUrlListQuery()

  const exportHtml = useCallback(
    () =>
      new Promise<EditorExportData>((resolve, reject) => {
        if (!emailEditorRef.current?.editor || !isReady) {
          return reject(null)
        }

        emailEditorRef.current.editor.exportHtml(({ design, html: _html }) => {
          const { html, replacementData } = pipe(
            fetchMergeTag(_html),
            fetchTracingUrl(_html, tracingUrlList),
            fetchActionUrl(_html)
          )({})

          resolve({
            html,
            replacementData,
            previewUrl: '',
            design,
          })
        })
      }),
    [isReady, tracingUrlList]
  )

  const fetchPreviewUrl = useCallback(
    async (html: string) => {
      try {
        const contentType = 'image/jpeg'
        const { uploadUrl, imagePath } = await getImageUploadUrl().unwrap()
        const file = await htmlToImageFile({ html, contentType })
        if (!file) {
          return ''
        }

        await putUploadFileToGCS({
          uploadURL: uploadUrl,
          file,
        })
        const { publicUrl } = await setImagePublic({
          contentType: 'image/jpeg',
          imagePath,
        }).unwrap()

        return publicUrl
      } catch {
        return ''
      }
    },
    [getImageUploadUrl, setImagePublic]
  )

  const onReady = () => {
    setIsReady(true)
  }

  const onLoadDesign = useCallback(
    (design: Design) => {
      if (!isReady) {
        return
      }
      emailEditorRef.current?.editor?.loadDesign(design)
    },
    [isReady]
  )

  const onExportDesign: ExportDesignFunction = useCallback(
    async params => {
      const {
        shouldExportPreviewUrl = true,
        shouldAppendUnsubscribeFooter = true,
        shouldRecoverToOriginalLink = false,
        preheaderText,
      } = params || {}
      setIsExporting(true)
      if (preheaderText) {
        emailEditorRef.current?.editor?.setBodyValues({ preheaderText })
      }
      const data = await exportHtml()

      const previewUrl = shouldExportPreviewUrl
        ? await fetchPreviewUrl(data.html)
        : ''

      const html = pipe(
        (html: string) =>
          shouldAppendUnsubscribeFooter ? appendUnsubscribeFooter(html) : html,
        (html: string) =>
          shouldRecoverToOriginalLink
            ? recoverToOriginalLink(html, tracingUrlList)
            : html
      )(data.html)

      setIsExporting(false)

      return { ...data, html, previewUrl }
    },
    [exportHtml, fetchPreviewUrl, tracingUrlList]
  )

  return {
    isReady,
    isExporting,
    emailEditorRef,
    onReady,
    onLoadDesign,
    onExportDesign,
  }
}

export default useEmailEditor
