import styled from '@emotion/styled'
import {
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  spacing,
  SpacingProps,
  typography,
  TypographyProps,
} from '@mui/system'

export const Card = styled.div<
  SpacingProps & TypographyProps & DisplayProps & FlexboxProps
>`
  padding: 32px;
  width: 100%;

  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${spacing}
  ${typography}
  ${display}
  ${flexbox}
`

export default Card
