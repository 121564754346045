import {
  api,
  PaginationParams,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '@shared/api/rtkQuery'

import {
  Subscription,
  SubscriptionFormValues,
  SubscriptionList,
  SubscriptionUrlValidate,
} from './type'

const subscriptions = api.injectEndpoints({
  endpoints: build => ({
    getSubscriptionList: build.query<
      { items: SubscriptionList; totalCount: number },
      PaginationParams & Pick<Subscription, 'name'>
    >({
      query: params => ({
        url: '/subscriptions',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(
          res as {
            items: SubscriptionList
            totalCount: number
          }
        ),
      providesTags: result =>
        result
          ? [
              ...result.items.subscriptions?.map(({ id }) => ({
                type: 'Subscription' as const,
                id,
              })),
              { type: 'Subscription', id: 'LIST' },
            ]
          : [{ type: 'Subscription', id: 'LIST' }],
    }),
    createSubscription: build.mutation<Subscription, SubscriptionFormValues>({
      query: body => ({
        method: 'POST',
        url: '/subscriptions',
        body,
      }),
      invalidatesTags: [{ type: 'Subscription', id: 'LIST' }],
    }),
    putSubscription: build.mutation<
      Subscription,
      Omit<Subscription, 'name' | 'healthStatus'>
    >({
      query: body => ({
        method: 'PUT',
        url: `/subscriptions/${body.id}`,
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Subscription', id: arg.id },
      ],
    }),
    getSubscription: build.query<Subscription, Subscription['id']>({
      query: id => `/subscriptions/${id}`,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as Subscription),
      providesTags: (result, error, id) => [{ type: 'Subscription', id }],
    }),
    deleteSubscription: build.mutation<void, Subscription['id']>({
      query: id => ({
        method: 'DELETE',
        url: `/subscriptions/${id}`,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Subscription', id: arg },
      ],
    }),
    validateSubscriptionUrl: build.mutation<
      SubscriptionUrlValidate,
      {
        url: string
        id?: number
      }
    >({
      query: body => ({
        method: 'POST',
        url: '/subscriptions/validation',
        body,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as SubscriptionUrlValidate),
      invalidatesTags: [{ type: 'Subscription', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetSubscriptionListQuery,
  useGetSubscriptionQuery,
  useCreateSubscriptionMutation,
  usePutSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useValidateSubscriptionUrlMutation,
} = subscriptions
