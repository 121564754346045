import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import {
  DataGrid,
  GridColumnMenuColumnsItem,
  GridColumnMenuContainer,
  GridColumnMenuProps,
} from '@mui/x-data-grid'

import type { CampaignInsight, MediumType } from '@shared/api/rtkQuery'
import useTableAggregationData from '@shared/lib/hooks/useTableAggregationData'
import SectionTitle from '@shared/ui/SectionTitle'
import { ColumnsPanel } from '@widgets/insight/ui/workflow/DetailInsightDataTable/_shared/DataGridSlots'

import useColumns from './useColumns'

type Props = {
  title: string
  isLoading: boolean
  mediumType: MediumType
  data: CampaignInsight[]
  isConversionEnable: boolean
  conversionEventDisplayName: string
  conversionValueDisplayName: string
}

const SORT_IGNORE_ID = 'campaign_insight_aggregation'

const Table = ({
  title,
  isLoading,
  mediumType,
  data,
  isConversionEnable,
  conversionEventDisplayName,
  conversionValueDisplayName,
}: Props) => {
  const { t } = useTranslation(['table'])

  const tableColumns = useColumns(
    mediumType,
    {
      isConversionEnable,
      conversionEventDisplayName,
      conversionValueDisplayName,
    },
    SORT_IGNORE_ID
  )

  const tableAggregationData = useTableAggregationData(data, {
    initialColumnValues: {
      id: SORT_IGNORE_ID,
      groupByValues: [],
      title: '',
      msgChannelTitle: '',
      mediumType,
      triggerGroupTitle: '',
      triggerGroupId: undefined,
      tracingUrls: [],
    },
    ignoreKeys: [
      'id',
      'groupByValues',
      'title',
      'msgChannelTitle',
      'triggerGroupTitle',
      'triggerGroupId',
      'mediumType',
      'tracingUrls',
    ],
  })

  return (
    <Box mb={5} width="100%">
      <SectionTitle title={title} />
      <DataGrid
        loading={isLoading}
        rows={data.concat([tableAggregationData])}
        columns={tableColumns}
        getRowClassName={params => {
          if (params.row.id === SORT_IGNORE_ID) {
            return 'MuiDataGrid-row--transparent MuiDataGrid-row--boldFont MuiDataGrid-row--blackColor'
          }

          return ''
        }}
        getRowId={row => `${row.id}_${row.groupByValues}_${row.title}`}
        slots={{
          columnMenuHideIcon: () => <></>,
          columnMenuManageColumnsIcon: () => <></>,
          columnsPanel: () => <ColumnsPanel />,
          columnMenu: ({
            hideMenu,
            colDef,
            ...others
          }: GridColumnMenuProps) => (
            <GridColumnMenuContainer
              hideMenu={hideMenu}
              colDef={colDef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...others}
            >
              <GridColumnMenuColumnsItem onClick={hideMenu} colDef={colDef} />
            </GridColumnMenuContainer>
          ),
        }}
        getRowHeight={() => 'auto'}
        rowSelection={false}
        localeText={{
          columnMenuHideColumn: t('table:hide_column'),
          columnMenuManageColumns: t('table:manage_columns'),
          columnMenuLabel: '',
        }}
        autoHeight
        disableRowSelectionOnClick
        disableVirtualization
        disableColumnFilter
        hideFooter
      />
    </Box>
  )
}

export default Table
