import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { PAGE_MODE } from '@shared/model/constants/routes'

import {
  BEHAVIOR_TAG_PATH,
  GRADING_TAG_PATH,
  INTELLIGENT_TAG_PATH,
  THIRD_PARTY_TAG_PATH,
} from './_shared/constants'
import TagCopy from './_shared/TagCopy'
import TagCreate from './_shared/TagCreate'
import TagImport from './_shared/TagImport'
import { GradingDetail } from './detail/Grading'
import { IntelligentDetail } from './detail/Intelligent'
import { MultiTagDetail } from './detail/Multi'
import { ThirdPartyDetail } from './detail/ThirdParty'
import Overview from './Overview'

export const TagRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/${PAGE_MODE.create}/:tagSourceType?`}>
        <TagCreate />
      </Route>

      <Route exact path={`${path}/${PAGE_MODE.import}`}>
        <TagImport />
      </Route>

      <Route exact path={`${path}/${GRADING_TAG_PATH}/${PAGE_MODE.copy}/:id`}>
        <TagCopy tagType="grading" />
      </Route>

      <Route exact path={`${path}/${BEHAVIOR_TAG_PATH}/${PAGE_MODE.copy}/:id`}>
        <TagCopy tagType="behavior" />
      </Route>

      <Route exact path={`${path}/${GRADING_TAG_PATH}/:id`}>
        <GradingDetail />
      </Route>

      <Route exact path={`${path}/${THIRD_PARTY_TAG_PATH}/:id`}>
        <ThirdPartyDetail />
      </Route>

      <Route exact path={`${path}/${INTELLIGENT_TAG_PATH}/:id`}>
        <IntelligentDetail />
      </Route>

      <Route exact path={`${path}/${BEHAVIOR_TAG_PATH}/:id`}>
        <MultiTagDetail />
      </Route>

      <Route path={`${path}/:tagPath`}>
        <Overview />
      </Route>

      <Redirect to={`${path}/grading`} />
    </Switch>
  )
}

export default TagRoutes
