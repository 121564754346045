import { api, transformResponseCamelCaseKeys } from '../api'
import type { CreateMsgSenderPayload, MsgSender } from './type'

const msgSender = api.injectEndpoints({
  endpoints: builder => ({
    getMsgSenderList: builder.query<MsgSender[], void>({
      query: () => '/msg_senders',
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgSender[]),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'MsgSender' as const, id })),
              { type: 'MsgSender', id: 'LIST' },
            ]
          : [{ type: 'MsgSender', id: 'LIST' }],
    }),
    getMsgSender: builder.query<MsgSender, number>({
      query: id => `/msg_senders/${id}`,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgSender),
      providesTags: (result, error, id) => [{ type: 'MsgSender', id }],
    }),
    getDefaultMsgSender: builder.query<MsgSender, void>({
      query: () => '/msg_senders/default',
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgSender),
      providesTags: (result, error) => [{ type: 'MsgSender', id: result?.id }],
    }),
    createMsgSender: builder.mutation<MsgSender, CreateMsgSenderPayload>({
      query: payload => ({
        url: '/msg_senders',
        method: 'POST',
        body: payload,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgSender),
      invalidatesTags: [{ type: 'MsgSender', id: 'LIST' }],
    }),
    deleteMsgSender: builder.mutation<void, number>({
      query: id => ({
        url: `/msg_senders/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'MsgSender', id: 'LIST' },
        { type: 'MsgSender', id },
      ],
    }),
  }),
})

export const {
  useCreateMsgSenderMutation,
  useDeleteMsgSenderMutation,
  useGetDefaultMsgSenderQuery,
  useGetMsgSenderListQuery,
  useGetMsgSenderQuery,
} = msgSender
