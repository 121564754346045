import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  SendType,
  ThirdPartyAppProvider,
  useExportAudienceRuleUserEvery8dMutation,
  useGetAudienceRuleQuery,
  useGetEvery8dCreditQuery,
  useGetThirdPartyAppIntegrationQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { useMetadataUserProfileFromUsers } from '@shared/lib/hooks'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { getIntSafe } from '@shared/lib/utils/number'
import { extractMergeTagList } from '@shared/lib/utils/replacementData/mergeTag'
import {
  extractImageTypeAndBase64,
  getLastReserveTime,
} from '@shared/lib/utils/textMessage/utils'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { checkIsAlphabetOrNumber } from '@shared/lib/utils/validation'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { UNAUTHORIZED_ERROR_CODES } from '@shared/model/constants/thirdParty'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import DatePicker from '@shared/ui/DatePicker'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import theme from '@theme'

import { formatExportAudienceRuleTitle } from '../_shared'
import type { RouteState } from '../ExportThirdParty'
import {
  MERGE_TAG_AMOUNT_LIMIT,
  NO_DATA_ID,
  SUBJECT_MAX_LENGTH,
  SUBJECT_WITH_CHINESE_MAX_LENGTH,
  UPLOAD_IMAGE_MAX_SIZE,
} from './constants'
import { MmsFields, SmsFields } from './fields'
import Preview from './Preview'
import PriceEvaluation from './PriceEvaluation'
import SendTestingTextMessageButton from './SendTestingTextMessageButton'
import { Container, Icon, Label, Section } from './styles'
import useTimeOptions from './useTimeOptions'
import useTotalCostAndSize from './useTotalCostAndSize'

type RouteParams = {
  id: string
  thirdPartyId: string
}

export type FormValues = {
  sendType: SendType
  subject: string
  message: string
  reserveTime: Date
  retryTime: number
  isBooking: boolean
  uploadImageDataURL: string
  summary?: string
}

export const ExportTextMessage = () => {
  const { t, i18n } = useTranslation(['audience', 'common'])

  const { id, thirdPartyId } = useParams<RouteParams>()

  const audienceRuleId = getIntSafe(id, NO_DATA_ID)
  const thirdPartyAppId = getIntSafe(thirdPartyId, NO_DATA_ID)

  const { data: audienceRule } = useGetAudienceRuleQuery(audienceRuleId)
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const { data: thirdPartyApp } =
    useGetThirdPartyAppIntegrationQuery(thirdPartyAppId)

  const userProfileIdList = useMetadataUserProfileFromUsers(({ id }) => id)

  const { credit, creditTypedError } = useGetEvery8dCreditQuery(
    {
      integrationId: thirdPartyAppId,
    },
    {
      selectFromResult: ({ data, error }) => ({
        credit: data ?? 0,
        creditTypedError: formatRTKQueryError(error),
      }),
    }
  )

  const [exportAudienceRuleUserToEvery8d, { isLoading: isEvery8dSubmitting }] =
    useExportAudienceRuleUserEvery8dMutation()

  const history = useHistory<RouteState>()

  const dispatch = useAppDispatch()

  const formik = useFormik<FormValues>({
    initialValues: {
      sendType: 'sms',
      subject: '',
      message: '',
      // 頁面預選時間為最後預約時間 (10 分鐘) 再加上 5 分鐘
      reserveTime: new Date(+getLastReserveTime() + 5 * 60 * 1000),
      retryTime: 24,
      isBooking: false,
      uploadImageDataURL: '',
    },
    validate: values => {
      const errors: Partial<Record<keyof FormValues, string>> = {}

      const mergeTagList = extractMergeTagList(values.message)

      mergeTagList.forEach(mergeTag => {
        if (!userProfileIdList.includes(mergeTag)) {
          errors.message = t('audience:export_sms.merge_tag.error_tag')
        }
      })

      if (values.sendType === 'sms') {
        if (mergeTagList.length > 5) {
          errors.message = t('audience:export_sms.merge_tag.too_many', {
            amount_limitation: MERGE_TAG_AMOUNT_LIMIT,
          })
        }

        if (values.message.length === 0) {
          errors.message = t('audience:export_sms.message_placeholder')
        }
      }

      if (values.sendType === 'mms') {
        if (mergeTagList.length > 0) {
          errors.message = t('audience:export_sms.merge_tag.mms_not_available')
        }

        if (values.subject.length === 0) {
          errors.subject = t('audience:export_sms.title_error_required', {
            message_type: values.sendType.toUpperCase(),
          })
        }

        if (
          values.subject.length >
          (checkIsAlphabetOrNumber(values.subject)
            ? SUBJECT_MAX_LENGTH
            : SUBJECT_WITH_CHINESE_MAX_LENGTH)
        ) {
          errors.subject = t('audience:export_sms.title_error_maxlength')
        }

        if (values.uploadImageDataURL === '') {
          errors.uploadImageDataURL = t('audience:export_sms.image_error')
        }

        if (totalKB >= UPLOAD_IMAGE_MAX_SIZE) {
          errors.summary = t('audience:export_sms.max_size_error')
        }
      }

      // 必須先判斷帳號密碼有無錯誤，再判斷是否有足夠點數
      if (
        UNAUTHORIZED_ERROR_CODES.includes(
          creditTypedError.data?.errorCode as number
        )
      ) {
        errors.summary = t('audience:export_sms.errors.unauthorized')
      } else {
        if (totalCost > credit) {
          errors.summary = t('audience:export_sms.insufficient_credit', {
            sms_provider: getThirdPartyProviderName(
              thirdPartyApp?.type as ThirdPartyAppProvider
            ),
          })
        }
      }

      const lastReserveTime = getLastReserveTime()

      if (values.isBooking && values.reserveTime < lastReserveTime) {
        errors.reserveTime = t(
          'audience:export_sms.send_settings_scheduled_error'
        )
      }

      return errors
    },
    onSubmit: async values => {
      const { imageType, base64 } = extractImageTypeAndBase64(
        values.uploadImageDataURL ?? ''
      )

      try {
        await exportAudienceRuleUserToEvery8d({
          audienceRuleId,
          name: formatExportAudienceRuleTitle({
            title: audienceRule?.title ?? '',
            isDynamic: false,
          }),
          type: values.sendType,
          subject: values.subject,
          message: values.message,
          sendTime: values.isBooking ? values.reserveTime.toISOString() : '',
          isBooking: values.isBooking,
          retryTime: values.retryTime,
          attachment:
            base64 && imageType
              ? {
                  binary: base64,
                  type: imageType,
                }
              : undefined,
          integrationId: thirdPartyAppId,
        }).unwrap()

        history.push(
          `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${audienceRuleId}`,
          { integrationGroup: 'sms' }
        )
      } catch (error) {
        const formatError = formatRTKQueryError(error)

        if (formatError.statusCode === 409) {
          history.push(
            `/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${audienceRuleId}`,
            { integrationGroup: 'sms' }
          )
          return
        }

        dispatch(handleApiError(formatError))
      }
    },
  })

  const { messageLength, totalCost, totalKB } = useTotalCostAndSize(
    formik.values.sendType,
    formik.values.message,
    audienceRule?.latestMobileCount,
    formik.values.uploadImageDataURL
  )

  const { hourOptions, minuteOptions, retryOptions } = useTimeOptions()

  const onReserveTimeChange = (date: Date, hours: number, minutes: number) => {
    const target = new Date(+date)
    target.setHours(hours, minutes, 0, 0)

    formik.setFieldValue('isBooking', true)
    formik.setFieldValue('reserveTime', target)
  }

  const [isOpenDialog, setIsOpenDialog] = useState(false)

  return (
    <>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`}>
          {t('common:route.audience_list')}
        </Link>
        <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/${id}`}>
          {audienceRule?.title}
        </Link>
        <Box>{t('audience:export_sms.create')}</Box>
      </Breadcrumbs>

      <Card>
        <Section hasBorderBottom>
          <Box sx={{ fontSize: 16, fontWeight: 500, mb: 0.5 }}>
            {t('audience:export_sms.target_audiences')}
            <i className={ICON.arrowRight} />
            {audienceRule?.title}
          </Box>

          <Box sx={{ color: theme => theme.colors.textSecondBlue }}>
            {t('audience:export_sms.target_audiences_count', {
              total_count: audienceRule?.latestUserCount ?? 0,
              target_count: audienceRule?.latestMobileCount ?? 0,
            })}
          </Box>
        </Section>

        <Box sx={{ display: 'flex', alignItems: 'stretch' }}>
          <Container>
            <Section hasBorderBottom>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <Label>{t('audience:export_third_party_app')}</Label>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {thirdPartyApp?.name}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: 4 }}>
                <Label>{t('audience:export_sms.message_type')}</Label>

                <RadioGroup
                  row
                  value={formik.values.sendType}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('sendType', value as SendType)
                  }
                >
                  <FormControlLabel
                    value="sms"
                    control={<Radio color="primary" />}
                    label={t('audience:export_sms.message_type_sms')}
                  />
                  <FormControlLabel
                    value="mms"
                    control={<Radio color="primary" />}
                    label={t('audience:export_sms.message_type_mms')}
                  />
                </RadioGroup>
              </Box>

              {formik.values.sendType === 'sms' && (
                <SmsFields
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  priceEvaluation={
                    <PriceEvaluation
                      sendType="sms"
                      validCount={audienceRule?.latestMobileCount}
                      messageLength={messageLength}
                      totalCost={totalCost}
                      totalKB={totalKB}
                      credit={credit}
                      provider={thirdPartyApp?.type || ''}
                    />
                  }
                  onSubjectChange={value =>
                    formik.setFieldValue('subject', value)
                  }
                  onMessageChange={value =>
                    formik.setFieldValue('message', value)
                  }
                />
              )}

              {formik.values.sendType === 'mms' && (
                <MmsFields
                  values={formik.values}
                  touched={formik.touched}
                  errors={formik.errors}
                  priceEvaluation={
                    <PriceEvaluation
                      sendType="mms"
                      validCount={audienceRule?.latestMobileCount}
                      messageLength={messageLength}
                      totalCost={totalCost}
                      totalKB={totalKB}
                      credit={credit}
                      provider={thirdPartyApp?.type || ''}
                    />
                  }
                  onSubjectChange={value =>
                    formik.setFieldValue('subject', value)
                  }
                  onMessageChange={value =>
                    formik.setFieldValue('message', value)
                  }
                  onUploadImageDataUrlChange={value => {
                    formik.setFieldValue('uploadImageDataURL', value)
                  }}
                />
              )}
            </Section>

            <Section hasBorderBottom>
              <Box sx={{ display: 'flex' }}>
                <Label>{t('audience:export_sms.send_settings')}</Label>

                <RadioGroup
                  value={`${formik.values.isBooking}`}
                  onChange={({ target: { value } }) =>
                    formik.setFieldValue('isBooking', value === 'true')
                  }
                >
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label={t('audience:export_sms.send_settings_immediate')}
                  />

                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                    <FormControlLabel
                      value="true"
                      control={<Radio color="primary" />}
                      label={t('audience:export_sms.send_settings_scheduled')}
                    />
                    <Icon color={theme.colors.brightBlue} lineHeight={42}>
                      <i className={ICON.schedule} />
                    </Icon>

                    <Box>
                      <Box display="inline" mr={1}>
                        <DatePicker
                          value={formik.values.reserveTime}
                          minDate={getLastReserveTime()}
                          isShowUnsetButton={false}
                          isError={formik.errors.reserveTime !== undefined}
                          onValueChanged={date => {
                            if (date) {
                              onReserveTimeChange(
                                date,
                                formik.values.reserveTime.getHours(),
                                formik.values.reserveTime.getMinutes()
                              )
                            }
                          }}
                        />
                      </Box>

                      <Trans
                        ns="common"
                        i18nKey="time_selector"
                        components={{
                          Hours: (
                            <DropdownList
                              key={`hour_${i18n.language}`}
                              uniqueId={`hour_${i18n.language}`}
                              options={hourOptions}
                              defaultOption={hourOptions.find(
                                item =>
                                  item.value ===
                                  formik.values.reserveTime.getHours()
                              )}
                              isError={formik.errors.reserveTime !== undefined}
                              onValueChanged={({ value: hours }) => {
                                onReserveTimeChange(
                                  formik.values.reserveTime,
                                  hours as number,
                                  formik.values.reserveTime.getMinutes()
                                )
                              }}
                            />
                          ),
                          Minutes: (
                            <DropdownList
                              key={`minute_${i18n.language}`}
                              uniqueId={`minute_${i18n.language}`}
                              options={minuteOptions}
                              defaultOption={minuteOptions.find(
                                item =>
                                  item.value ===
                                  formik.values.reserveTime.getMinutes()
                              )}
                              isError={formik.errors.reserveTime !== undefined}
                              onValueChanged={({ value: minutes }) => {
                                onReserveTimeChange(
                                  formik.values.reserveTime,
                                  formik.values.reserveTime.getHours(),
                                  minutes as number
                                )
                              }}
                            />
                          ),
                        }}
                      />

                      {formik.errors.reserveTime && (
                        <Box
                          sx={{
                            color: theme => theme.colors.orangeyRed,
                            mt: 1,
                          }}
                        >
                          <>{formik.errors.reserveTime}</>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </RadioGroup>
              </Box>
            </Section>
            <Section>
              <Box sx={{ display: 'flex' }}>
                <Label>{t('audience:export_sms.retry_period')}</Label>
                <Box>
                  <DropdownList
                    key={`retry_${i18n.language}`}
                    uniqueId={`retry_${i18n.language}`}
                    options={retryOptions}
                    defaultOption={retryOptions.find(
                      item => item.value === formik.values.retryTime
                    )}
                    onValueChanged={({ value }) =>
                      formik.setFieldValue('retryTime', value as number)
                    }
                  />
                </Box>
              </Box>
            </Section>
            <DeepBlueGradientButton
              width={200}
              disabled={!audienceRule || audienceRule.latestMobileCount === 0}
              onClick={() => setIsOpenDialog(true)}
            >
              {t('audience:export_sms.send')}
            </DeepBlueGradientButton>
            <SendTestingTextMessageButton
              sendType={formik.values.sendType}
              subject={formik.values.subject}
              message={formik.values.message}
              uploadImageDataURL={formik.values.uploadImageDataURL}
              appIntegrationId={thirdPartyAppId}
            />
          </Container>

          <Preview
            isShow={formik.values.sendType === 'mms'}
            title={
              formik.values.subject ||
              t('audience:export_sms.title_default_value')
            }
            content={
              formik.values.message ||
              t('audience:export_sms.message_default_value')
            }
            imageURL={formik.values.uploadImageDataURL}
          />
        </Box>

        <Box mt={2} minHeight={24} color={theme.colors.orangeyRed}>
          <p>{formik.errors.summary}</p>
        </Box>

        <ConfirmDialog
          isOpen={isOpenDialog}
          isLoading={isEvery8dSubmitting}
          modalTitle={
            formik.values.isBooking ? (
              <>
                {t('audience:export_sms.confirm_submit.schedule_1')}
                &nbsp;
                <Box
                  component="span"
                  sx={{ color: theme => theme.colors.brightBlue }}
                >
                  {formatDateTimeDisplay(formik.values.reserveTime)}
                </Box>
                &nbsp;
                {t('audience:export_sms.confirm_submit.schedule_2', {
                  sms_provider: thirdPartyApp?.type?.toUpperCase(),
                })}
              </>
            ) : (
              <>{t('audience:export_sms.confirm_submit.immediate')}</>
            )
          }
          onClose={() => setIsOpenDialog(false)}
          onConfirm={async () => {
            await formik.submitForm()

            setIsOpenDialog(false)
          }}
        />
      </Card>
    </>
  )
}

export default ExportTextMessage
