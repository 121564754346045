import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useCreateProjectUserMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, SimpleButton } from '@shared/ui/buttons'

import UserForm, { BUTTON_WIDTH, FormValues, STATUS_CODE } from './UserForm'

const LITE_MAX_USER_COUNT = 5

const Create = () => {
  const { t } = useTranslation(['common', 'iam'])

  const [createProjectUser] = useCreateProjectUserMutation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const userCenterUsersOverviewLink = `/${CATEGORY.userCenter}/${PAGE_ROOT.users}`

  const handleSubmit = async (values: FormValues) => {
    try {
      await createProjectUser({
        email: values.email,
        description: values.description,
        policyIds: values.userPolicyList.map(x => x.id),
      }).unwrap()

      history.push(userCenterUsersOverviewLink)
    } catch (error) {
      const typedError = formatRTKQueryError(error)

      if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
        dispatch(
          openToast({
            message: t('common:this_function_is_not_allowed'),
            status: 'error',
          })
        )
        return
      }

      // 目前只有 lite 版本會有這個錯誤
      if (typedError.statusCode === STATUS_CODE.REACH_LIMIT) {
        dispatch(
          openToast({
            message: t('iam:amount_of_users_reach_limit', {
              num: LITE_MAX_USER_COUNT,
            }),
            status: 'error',
          })
        )
        return
      }

      if (typedError.statusCode === STATUS_CODE.DUPLICATE_EMAIL) {
        dispatch(
          openToast({
            message: t('iam:user.email_error_duplicate'),
            status: 'error',
          })
        )
        return
      }

      handleApiError(typedError)
    }
  }

  return (
    <>
      <Breadcrumbs>
        <Link to={userCenterUsersOverviewLink}>
          {t('common:route.iam_user')}
        </Link>
        <Box>{t('common:create')}</Box>
      </Breadcrumbs>

      <UserForm onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={4}>
            <SimpleButton
              onClick={() => {
                history.push(userCenterUsersOverviewLink)
              }}
            >
              {t('common:cancel')}
            </SimpleButton>
          </Box>

          <DeepBlueGradientButton type="submit" width={BUTTON_WIDTH}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Box>
      </UserForm>
    </>
  )
}

export default Create
