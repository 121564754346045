import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import type { PrivacyLevel } from '@shared/api/rtkQuery'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { DeepBlueGradientButton } from '@shared/ui/buttons'

import { EmptyTextWrapper, EmptyWrapper } from '../Style'
import type { AnalyticsType } from '../type'

type Props = {
  analyticsType: AnalyticsType
  privacyLevel: PrivacyLevel
  groupId: number
}

const Empty = ({ analyticsType, privacyLevel, groupId }: Props) => {
  const { t } = useTranslation(['common', 'dashboard'])

  const createLink =
    analyticsType === 'event'
      ? `/${CATEGORY.analytics}/${PAGE_ROOT.events}/new?privacyLevel=${privacyLevel}&groupId=${groupId}`
      : `/${CATEGORY.analytics}/${PAGE_ROOT.funnel}/new?privacyLevel=${privacyLevel}&groupId=${groupId}`

  return (
    <EmptyWrapper>
      <EmptyTextWrapper>{t('dashboard:empty_chart_group')}</EmptyTextWrapper>
      <Link to={createLink}>
        <DeepBlueGradientButton width={200}>
          {t('common:create')}
        </DeepBlueGradientButton>
      </Link>
    </EmptyWrapper>
  )
}

export default Empty
