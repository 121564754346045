import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  useProjectUserQuery,
  useUpdateProjectUserMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton, SimpleButton } from '@shared/ui/buttons'
import { Tooltip } from '@shared/ui/tooltips'

import UserForm, {
  BUTTON_WIDTH,
  FormValues,
  STATUS_CODE,
  transformToPolicyServiceEntity,
} from './UserForm'

const Edit = () => {
  const { id } = useParams<{ id: string }>()
  const userId = getIntSafe(id)

  const { projectUser } = useProjectUserQuery(userId, {
    selectFromResult: result => ({
      projectUser: result.data
        ? {
            id: result.data.id,
            email: result.data.email,
            description: result.data.description ?? '',
            userPolicyList:
              result.data.policies?.map(transformToPolicyServiceEntity) ?? [],
          }
        : undefined,
    }),
  })

  const { t } = useTranslation(['common', 'iam'])

  const history = useHistory()

  const dispatch = useAppDispatch()

  const [updateProjectUser] = useUpdateProjectUserMutation()

  const handleSubmit = async (values: FormValues) => {
    try {
      await updateProjectUser({
        id: userId,
        email: values.email,
        description: values.description,
        policyIds: values.userPolicyList.map(x => x.id),
      }).unwrap()

      history.push(`/${CATEGORY.userCenter}/${PAGE_ROOT.users}/${userId}`)
    } catch (error) {
      const typedError = formatRTKQueryError(error)

      if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
        dispatch(
          openToast({
            message: t('common:this_function_is_not_allowed'),
            status: 'error',
          })
        )
        return
      }

      handleApiError(typedError)
    }
  }

  return (
    <>
      <Breadcrumbs>
        <Link to={`/${CATEGORY.userCenter}/${PAGE_ROOT.users}`}>
          {t('common:route.iam_user')}
        </Link>
        {projectUser?.email && (
          <Tooltip title={projectUser.email}>
            <Box>{projectUser.email}</Box>
          </Tooltip>
        )}
      </Breadcrumbs>
      <UserForm formValues={projectUser} onSubmit={handleSubmit}>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={4}>
            <SimpleButton
              onClick={() => {
                history.push(
                  `/${CATEGORY.userCenter}/${PAGE_ROOT.users}/${userId}`
                )
              }}
            >
              {t('common:cancel')}
            </SimpleButton>
          </Box>

          <DeepBlueGradientButton width={BUTTON_WIDTH} type="submit">
            {t('common:apply')}
          </DeepBlueGradientButton>
        </Box>
      </UserForm>
    </>
  )
}

export default Edit
