import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import PolicyCreate from './Create'
import PolicyDetail from './Detail'
import PolicyEdit from './Edit'
import PolicyList from './List'

export const PolicyRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/new`}>
        <PolicyCreate />
      </Route>

      <Route path={`${path}/:id/edit`}>
        <PolicyEdit />
      </Route>

      <Route path={`${path}/:id`}>
        <PolicyDetail />
      </Route>

      <Route path={`${path}`}>
        <PolicyList />
      </Route>

      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default PolicyRoutes
