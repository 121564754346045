import {
  DEFAULT_TIMING_DATA,
  INITIAL_TIME_DATA,
} from '@widgets/workflow/_shared/nodes/_shared/Timing/_shared/constants'
import type {
  TimeData,
  TimeType,
} from '@widgets/workflow/_shared/nodes/_shared/Timing/timeReducer'

export const getTimeNodeData = (
  timeNodeType: TimeType,
  data: TimeData
): TimeData => {
  if (timeNodeType === 'duration') {
    return {
      ...INITIAL_TIME_DATA.duration,
      ...data,
    }
  }

  if (timeNodeType === 'dayOfWeek') {
    return {
      ...INITIAL_TIME_DATA.dayOfWeek,
      ...data,
    }
  }

  if (timeNodeType === 'specifiedDay') {
    return {
      ...INITIAL_TIME_DATA.specifiedDay,
      ...data,
    }
  }

  return DEFAULT_TIMING_DATA
}

export default getTimeNodeData
