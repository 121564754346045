import type { AudienceRuleUserExport } from '@shared/api/rtkQuery'

export const checkIsNeedPollingExportInfo = (
  status?: AudienceRuleUserExport['status']
) => {
  if (!status) {
    return false
  }

  return status === 'initialized' || status === 'processing'
}
