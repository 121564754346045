import { FunnelChart } from '../../model/types'

export const getInitPreviewResponse = (): FunnelChart => ({
  funnel_chart: {
    totalConversionData: {
      label: '',
      conversionRate: 0,
      conversionNumber: 0,
    },
    groupConversionRates: [],
    chart: {
      categories: [],
      series: [],
    },
  },
  trend_chart: {
    totalConversionData: {
      label: '',
      conversionRate: 0,
      conversionNumber: 0,
    },
    chart: {},
  },
})
