import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import type { SegmentStyleConfig } from '@entities/rfm/model'
import { Score } from '@entities/rfm/ui'
import type { Rfm } from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import BaseCard from '@shared/ui/Card'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

const Card = styled(BaseCard)`
  && {
    padding: 0 0 24px 0;
    border-radius: 27px;

    :hover {
      user-select: none;
      box-shadow: 0 3px 24px 0 ${({ theme }) => theme.colors.black425};
    }
  }
`
export type Props = SegmentStyleConfig &
  Pick<Rfm['rfmGroups'][number], 'userCount'> & { proportion: number }

const SegmentCard = ({
  segmentType,
  displayNameI18n,
  userCount,
  proportion,
  themeColor,
  range,
}: Props) => {
  const { t } = useTranslation('rfm')

  return (
    <Card>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        borderRadius="27px 27px 0 0"
        sx={{
          backgroundColor: themeColor,
        }}
      >
        <Box color={theme => theme.colors.white} fontSize={28} fontWeight={600}>
          {t(displayNameI18n as ParseKeys<'rfm'>)}
        </Box>
        <Box
          component="img"
          width="54px"
          height="54px"
          sx={{
            borderRadius: '50%',
          }}
          src={`/images/avatar-${segmentType}.png`}
        />
      </Box>
      <Box display="flex" flexDirection="column" pt={2} px={3}>
        <Box
          mb={2}
          minHeight={60}
          color={theme.colors.textSecondBlue}
          fontSize={13}
        >
          {t(`characteristic.${segmentType}`)}
        </Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Icon icon={ICON.usersAlt} color={theme.colors.black} />
          <Box
            ml={1}
            color={theme.colors.black}
            fontSize={20}
            fontWeight={500}
          >{`${formatNumber(userCount)} (${formatNumber(
            proportion * 100
          )}%)`}</Box>
        </Box>
        <Score badge="R" range={range.rScore} />
        <Score badge="F" range={range.fScore} />
      </Box>
    </Card>
  )
}

export default SegmentCard
