import type {
  CustomLineProvider,
  ThirdPartyAppGroup,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'

export const THIRD_PARTY_MAX_COUNT = 100

export type ThirdPartyAppProviderListGroup = {
  group: ThirdPartyAppGroup
  providerList: ThirdPartyAppProvider[]
}

export const THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST: ThirdPartyAppProviderListGroup[] =
  [
    { group: 'edm', providerList: ['newsleopard'] },
    { group: 'line', providerList: ['super8', 'maac', 'omnichat'] },
    { group: 'iterable', providerList: ['iterable'] },
    { group: 'sms', providerList: ['every8d', 'mitake'] },
  ]

export const THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST_AUDIENCE: ThirdPartyAppProviderListGroup[] =
  [
    { group: 'line', providerList: ['super8', 'maac', 'omnichat'] },
    { group: 'iterable', providerList: ['iterable'] },
    { group: 'sms', providerList: ['every8d', 'every8d_exclusive', 'mitake'] },
  ]

export const THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP: Record<
  ThirdPartyAppGroup,
  string
> = {
  line: 'LINE',
  sms: 'SMS',
  edm: 'EDM',
  iterable: 'Iterable',
}

export const THIRD_PARTY_APP_PROVIDER_DISPLAY_NAME_MAP: Record<
  ThirdPartyAppProvider,
  string
> = {
  coolbe_wyeth: 'CoolBe-Wyeth',
  every8d_exclusive: 'EVERY8D（iKala）',
  every8d: 'EVERY8D',
  mitake: 'mitake',
  iterable: 'Iterable',
  maac: 'MAAC',
  newsleopard: 'Newsleopard',
  ses: 'Amazon SES',
  super8: 'Super8',
  omnichat: 'Omnichat',
}

export const CUSTOM_LINE_PROVIDER: CustomLineProvider[] = ['coolbe_wyeth']

const ERROR_CODE = {
  NO_SUCH_ACCOUNT: 10101,
  WRONG_PASSWORD: 10102,
  EMPTY_ACCOUNT_PASSWORD: 10103,
  ACCOUNT_OR_PASSWORD_NOT_CORRECT: 10104,
}

export const UNAUTHORIZED_ERROR_CODES = [
  ERROR_CODE.EMPTY_ACCOUNT_PASSWORD,
  ERROR_CODE.NO_SUCH_ACCOUNT,
  ERROR_CODE.WRONG_PASSWORD,
  ERROR_CODE.ACCOUNT_OR_PASSWORD_NOT_CORRECT,
] as const
