import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { getFloatSafe } from '@shared/lib/utils/number'

const heightOfScrollbar = 9

const Track = styled.div`
  height: ${heightOfScrollbar}px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.borderColor};
`

const Thumb = styled.div<{ widthRatio: number; translateX: number }>`
  width: ${({ widthRatio }) => (widthRatio ? `${widthRatio}%` : '0px')};
  height: ${heightOfScrollbar}px;
  border-radius: 4px;

  background-color: ${({ theme }) => theme.colors.brightBlue};
  transition: 0.1s;

  transform: ${({ translateX }) => `translateX(${translateX}px)`};
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`

const getStartIdx = (
  trackWidth: number,
  translateX: number,
  numOfData: number
) => {
  const oneItemRatio = getFloatSafe(1 / numOfData) * 100
  const currTranslateXRatio = getFloatSafe(translateX / trackWidth) * 100
  const startIdx = Math.round(getFloatSafe(currTranslateXRatio / oneItemRatio))
  return startIdx
}

type Props = {
  lengthOfData: number
  setStartIdx: Dispatch<SetStateAction<number>>
  maxVisibleChartsNum?: number
}

const RangeScrollbar = ({
  lengthOfData,
  setStartIdx,
  maxVisibleChartsNum = 5,
}: Props) => {
  const { t } = useTranslation('dateAndChart')
  const trackRef = useRef<HTMLDivElement>(null)
  const thumbRef = useRef<HTMLDivElement>(null)
  const [translateX, setTranslateX] = useState(0)
  const [lastX, setLastX] = useState<null | number>(null)
  const [isMoving, setIsMoving] = useState(false)

  const handleMousemove = useCallback(
    // FIXME: remove any
    (e: any) => {
      if (!isMoving) {
        return
      }

      const trackWidth = trackRef?.current?.offsetWidth || 0
      const thumbWidth = thumbRef?.current?.offsetWidth || 0
      if (lastX && trackWidth && thumbWidth) {
        const dx = e.clientX - lastX
        const nextTranslateX = translateX + dx

        if (nextTranslateX >= 0 && nextTranslateX <= trackWidth - thumbWidth) {
          const nextStartIdx = getStartIdx(trackWidth, translateX, lengthOfData)
          setStartIdx(nextStartIdx)
          setTranslateX(nextTranslateX)
        }
      }

      setLastX(e.clientX)
    },
    [isMoving, lastX, translateX, lengthOfData, setStartIdx]
  )

  const handleMousedown = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsMoving(true)
  }

  const handleMouseup = useCallback(() => {
    setIsMoving(false)
    setLastX(null)
  }, [])

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseup)
    window.addEventListener('mousemove', handleMousemove)
    return () => {
      window.removeEventListener('mouseup', handleMouseup)
      window.removeEventListener('mousemove', handleMousemove)
    }
  }, [handleMouseup, handleMousemove])

  useEffect(() => {
    setTranslateX(0)

    return () => {
      setStartIdx(0)
    }
  }, [maxVisibleChartsNum, setStartIdx])

  const thumbWidthRatio = Math.round(
    Math.min(getFloatSafe(maxVisibleChartsNum / lengthOfData) * 100, 100)
  )

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <Box mr={1.5}>
        <Label>{t('scroll')}</Label>
      </Box>

      <Box width="100%">
        <Track ref={trackRef}>
          <Thumb
            ref={thumbRef}
            widthRatio={thumbWidthRatio}
            translateX={translateX}
            onMouseDown={handleMousedown}
            onMouseUp={handleMouseup}
          />
        </Track>
      </Box>
    </Box>
  )
}

export default RangeScrollbar
