/** @jsxImportSource @emotion/react */
// FIXME 0307 - 只讓有 intelligent_analysis 權限的帳號看到 intelligent_analysis 選單，測試過後要移除 iam 相關的程式碼

import { useEffect } from 'react'
import {
  Redirect,
  Route,
  Router,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { css, Global, useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import { ErrorBoundary } from '@rollbar/react'
import reset from 'emotion-reset'

import { history } from '@app/store'
import { initAuth } from '@entities/auth/model/slices'
import ErrorPage from '@entities/ErrorPage'
import LoadingPage from '@entities/Loader/LoadingPage'
import PrivacyPolicy from '@entities/PrivacyPolicy'
import ProductIntro from '@entities/ProductionIntro'
import SignIn from '@features/auth/ui/SignIn'
import { useIamServiceEntityQuery } from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { initAmplitude } from '@shared/lib/utils/amplitude'
import {
  CATEGORY,
  PAGE_MODE,
  PAGE_ROOT,
  QUERY_STRING,
} from '@shared/model/constants/routes'
import { LAYOUT } from '@shared/model/constants/styles'
import { closeToast } from '@shared/model/slices'
import ScrollToTop from '@shared/ui/ScrollToTop'
import TermsOfService from '@shared/ui/TermsOfService'
import Toast from '@shared/ui/Toast'
import { AutoTagAnalysis } from '@widgets/aiMarketingAssistant/autoTagAnalysis'
import { KolRecommendRoute } from '@widgets/aiMarketingAssistant/kolRecommend'
import { SocialMediaTagsAnalysisRoute } from '@widgets/aiMarketingAssistant/socialMediaTagsAnalysis'
import { EventRoutes } from '@widgets/analytics/event'
import { FunnelRoutes } from '@widgets/analytics/funnel'
import { NotificationRuleRoutes } from '@widgets/analytics/notificationRule'
import AudienceRoutes from '@widgets/audience/ui/AudienceRoutes'
import { ContentSettingRoutes } from '@widgets/contentSetting'
import { DashboardRoutes } from '@widgets/dashboard'
import { DataImportRoutes } from '@widgets/dataImport'
import { InsightRoutes } from '@widgets/insight'
import ContentWrapper from '@widgets/layout/ui/ContentWrapper'
import Header from '@widgets/layout/ui/Header'
import NotificationBar from '@widgets/layout/ui/NotificationBar'
import Sidebar, { SidebarProvider } from '@widgets/layout/ui/Sidebar'
import { RfmRoutes } from '@widgets/rfm'
import { SettingsRoutes } from '@widgets/settings'
import { TagRoutes } from '@widgets/tag'
import { TagGroupRoutes } from '@widgets/tagGroup'
import { UsageManagement } from '@widgets/usageManagement'
import { UserCenterRoutes } from '@widgets/userCenter'
import { UserProfileRoutes } from '@widgets/userProfile'
import { WorkflowRoutes } from '@widgets/workflow'

import 'react-calendar/dist/Calendar.css'

const GlobalStyle = () => {
  const theme = useTheme()

  return (
    <Global
      styles={css`
        ${reset}

        html {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        *,
        *::before,
        *::after {
          box-sizing: inherit;
        }

        strong,
        b {
          font-weight: 700;
        }

        body {
          margin: 0;
          background-color: ${theme.colors.bgPrimaryGrey};
          color: ${theme.colors.textPrimaryBlue};
          font-size: 0.875rem;
          font-family: 'Roboto', 'PingFang', 'Microsoft JhengHei', 'Helvetica',
            'Arial', sans-serif;
          font-weight: 400;
          letter-spacing: 0.01em;
          line-height: 1.5;
        }
        a {
          text-decoration: none;
        }
        a:link {
          color: inherit;
          text-decoration: inherit;
        }

        a:visited {
          color: inherit;
          text-decoration: inherit;
        }
        @media print {
          body {
            background-color: #fff;
          }
        }
        body::backdrop {
          background-color: #fafafa;
        }

        /* React-Calendar */
        .react-calendar {
          border: none;
        }

        abbr {
          color: ${theme.colors.black86};
          font-family: inherit;
          text-decoration: none;
        }

        .react-calendar__navigation {
          margin-bottom: 4px;
          height: 36px;
        }

        /* 年月(e.g.: 2021年4月) */
        .react-calendar__navigation__label {
          font-weight: 550;
        }
        .react-calendar__navigation button {
          min-width: 36px;
          height: 36px;
        }

        .react-calendar__navigation button:enabled:hover,
        .react-calendar__navigation button:enabled:focus,
        .react-calendar--selectRange .react-calendar__tile--hover {
          background-color: ${theme.colors.bgPaleGrey};
        }

        /* 星期字樣(e.g.: 週一、週二...) */
        .react-calendar__month-view__weekdays__weekday abbr {
          color: ${theme.colors.black38};
        }

        /* 日期數字 */
        .react-calendar__tile--hoverEnd,
        .react-calendar__tile--active,
        .react-calendar__tile:enabled:hover,
        .react-calendar__tile:enabled:focus {
          background-color: ${theme.colors.bgPaleGrey};
        }

        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus {
          background-color: ${theme.colors.brightBlue};
        }
        .react-calendar__tile--active:enabled:hover abbr {
          color: ${theme.colors.white};
        }
        .react-calendar__tile--active:enabled:focus abbr {
          color: ${theme.colors.white};
        }

        /* 多一個 & 蓋權重 */
        &.react-calendar--selectRange .react-calendar__tile--hover {
          background-color: ${theme.colors.bgPaleGrey};
        }

        .react-calendar__tile--now {
          background-color: ${theme.colors.chartRed};
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .react-calendar__tile--now abbr {
          color: ${theme.colors.white};
        }

        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
          background-color: ${theme.colors.chartRed};
        }

        .react-calendar__tile--now.react-calendar__tile--hover {
          background-color: ${theme.colors.chartRed};
        }
        /* stylelint-disable-next-line no-descending-specificity */
        .react-calendar__tile--now.react-calendar__tile--hover abbr {
          color: ${theme.colors.white};
        }

        /* now & disabled */
        .react-calendar__tile--now.react-calendar__tile:disabled {
          background-color: ${theme.colors.calendarDisabledGrey};
        }
        .react-calendar__tile--now.react-calendar__tile:disabled abbr {
          color: ${theme.colors.black86};
        }
      `}
    />
  )
}

if (import.meta.env.DEV) {
  import('mock/browser')
    .then(({ worker }) => {
      worker.start()

      if (import.meta.hot) {
        // reload-msw 是從 vite-plugin-mock-watcher 發出
        import.meta.hot.on('reload-msw', () => {
          console.log('Reloading MSW worker...')

          worker.stop()
          worker.start()
        })
      }
    })
    .catch(err => {
      console.error('Failed to start MSW:', err)
    })
}

const AuthRoute = ({
  children,
  path,
}: {
  path: string
  children: React.ReactNode
}) => {
  const isAuth = useAppSelector(state => state.auth.isAuth)

  return (
    <Route
      path={path}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/${PAGE_ROOT.signIn}`,
              search: `?${QUERY_STRING.auth.returnURL}=${encodeURIComponent(
                location.pathname + location.search
              )}`,
            }}
          />
        )
      }
    />
  )
}

const AnalyticsRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.rfm}`}>
        <RfmRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.events}`}>
        <EventRoutes />
      </Route>
      <Route path={`${path}/${PAGE_ROOT.funnel}`}>
        <FunnelRoutes />
      </Route>
      <Route path={`${path}/${PAGE_ROOT.notificationRule}`}>
        <NotificationRuleRoutes />
      </Route>
      <Route path={`${path}/${PAGE_ROOT.dashboard}`}>
        <DashboardRoutes />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.rfm}`} />
    </Switch>
  )
}

const CustomerDataManagementRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.userProfile}`}>
        <UserProfileRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.audiences}`}>
        <AudienceRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.tags}`}>
        <TagRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.tagGroups}`}>
        <TagGroupRoutes />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.audiences}`} />
    </Switch>
  )
}

const MarketingAutomationRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.workflows}`}>
        <WorkflowRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.contentSetting}`}>
        <ContentSettingRoutes />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.insight.root}/:type`}>
        <InsightRoutes />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.workflows}`} />
    </Switch>
  )
}

const AiMarketingAssistantRoutes = () => {
  const { data: iamServiceEntity = { entities: {}, ids: [] }, isLoading } =
    useIamServiceEntityQuery()
  const { path } = useRouteMatch()

  const iamIntelligentAnalysis =
    iamServiceEntity.entities['intelligent_analysis']

  if (isLoading || !iamIntelligentAnalysis.activated) {
    return null
  }

  return (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.aiMarketingAssistant.kolRecommend}`}>
        <KolRecommendRoute />
      </Route>

      <Route
        path={`${path}/${PAGE_ROOT.aiMarketingAssistant.socialMediaLabelAnalysis}`}
      >
        <SocialMediaTagsAnalysisRoute />
      </Route>
      <Route
        path={`${path}/${PAGE_ROOT.aiMarketingAssistant.autoTagEstablish}`}
      >
        <Redirect
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${PAGE_MODE.create}/behavior`}
        />
      </Route>
      <Route path={`${path}/${PAGE_ROOT.aiMarketingAssistant.autoTagAnalysis}`}>
        <AutoTagAnalysis />
      </Route>
    </Switch>
  )
}

const App = () => {
  const isInitialAuthDone = useAppSelector(
    state => state.auth.isInitialAuthDone
  )
  const toast = useAppSelector(state => state.toast)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(initAuth())
  }, [dispatch])

  useEffect(() => {
    initAmplitude()
  }, [])

  if (!isInitialAuthDone) {
    return <LoadingPage />
  }

  return (
    <SidebarProvider>
      <Router history={history}>
        <GlobalStyle />
        <ScrollToTop />
        <Toast
          isOpen={toast.isOpen}
          message={toast.message}
          status={toast.status}
          onClose={() => {
            dispatch(closeToast())
          }}
        />
        <Switch>
          <Route path={`/${PAGE_ROOT.signIn}`}>
            <SignIn />
          </Route>

          <Route path={`/${PAGE_ROOT.home}`}>
            <ProductIntro />
          </Route>

          <Route path={`/${PAGE_ROOT.privacyPolicy}`}>
            <PrivacyPolicy />
          </Route>

          <Route path={`/${PAGE_ROOT.termsOfService}`}>
            <TermsOfService />
          </Route>

          <Route path={`/${PAGE_ROOT.error404}`}>
            <ErrorPage statusCode={404} />
          </Route>

          <Route path={`/${PAGE_ROOT.error500}`}>
            <ErrorPage statusCode={500} />
          </Route>

          <AuthRoute path="/">
            <Box display="flex">
              <Sidebar />
              <Box
                flexBasis="100%"
                flexShrink={1}
                minWidth={LAYOUT.contentMinWidth}
              >
                <Header />

                <ErrorBoundary fallbackUI={ErrorPage}>
                  <ContentWrapper>
                    <NotificationBar />

                    <Switch>
                      <Route path={`/${CATEGORY.cdm}`}>
                        <CustomerDataManagementRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.ma}`}>
                        <MarketingAutomationRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.analytics}`}>
                        <AnalyticsRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.settings}`}>
                        <SettingsRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.dataImport}`}>
                        <DataImportRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.userCenter}`}>
                        <UserCenterRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.aiMarketingAssistant}`}>
                        <AiMarketingAssistantRoutes />
                      </Route>

                      <Route path={`/${CATEGORY.usageManagement}`}>
                        <UsageManagement />
                      </Route>

                      <Redirect to={`/${CATEGORY.cdm}`} />
                    </Switch>
                  </ContentWrapper>
                </ErrorBoundary>
              </Box>
            </Box>
          </AuthRoute>
        </Switch>
      </Router>
    </SidebarProvider>
  )
}

export default App
