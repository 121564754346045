import type { UserOverviewValue } from '@shared/api/rtkQuery'
import type { TableColumn } from '@shared/ui/table'

const emptyValueHandler = (value: unknown) => (value || '-') as React.ReactNode

export const TABLE_COLUMN_SCHEMA: TableColumn<UserOverviewValue>[] = [
  {
    field: 'appUserId',
    headerName: 'user_id',
    valueFormatter: emptyValueHandler,
  },
  {
    field: 'name',
    headerName: 'first_name_last_name',
    valueFormatter: emptyValueHandler,
  },
  {
    field: 'mobilePhone',
    headerName: 'mobile_phone',
    valueFormatter: emptyValueHandler,
  },
  {
    field: 'email',
    headerName: 'email',
    valueFormatter: emptyValueHandler,
  },
]
