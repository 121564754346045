import ReactApexChart from 'react-apexcharts'
import Box from '@mui/material/Box'
import round from 'lodash/round'

import { COLORS } from '@shared/ui/charts'
import { getChartTooltip } from '@widgets/audience/lib/chartUtils'
import { CHART_HEIGHT } from '@widgets/audience/model/constant'
import ChartWrapper from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/ChartWrapper'

type Props = {
  labels: string[]
  series: number[]
  total: number
}

const DonutChart = ({ labels, series, total }: Props) => {
  const pieStartAngle = round((series[0] / total) * 180, 0)

  const options: ApexCharts.ApexOptions = {
    labels,
    chart: {
      type: 'donut',
      sparkline: {
        enabled: false,
      },
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    stroke: {
      width: 0,
    },
    colors: COLORS,
    dataLabels: {
      distributed: false,
      formatter: val => round(+val) + '%',
      dropShadow: { enabled: false },
    },
    plotOptions: {
      pie: {
        startAngle: -pieStartAngle,
        endAngle: 360 - pieStartAngle,
        customScale: 1,
        donut: {
          labels: {
            show: false,
            total: {
              showAlways: false,
            },
          },
        },
      },
    },
    tooltip: {
      custom: getChartTooltip({ total }),
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  }

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      alignItems="center"
      height={CHART_HEIGHT}
      mt={4}
    >
      <ChartWrapper>
        <ReactApexChart
          height={CHART_HEIGHT}
          options={options}
          series={series}
          type="donut"
        />
      </ChartWrapper>
    </Box>
  )
}

export default DonutChart
