import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiCircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import {
  BehaviorTag,
  DATA_SOURCE_TYPE_AUTO_AIGC_RULE,
  DATA_SOURCE_TYPE_IMPORT,
  DataSourceType,
} from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_MODE, PAGE_ROOT } from '@shared/model/constants/routes'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import theme from '@theme'
import { BEHAVIOR_TAG_PATH } from '@widgets/tag/_shared/constants'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'
type Props = {
  openDropdownTagId: number | undefined
  tagGroupMap: TagGroupMapType
  setOpenDropdownTagId: React.Dispatch<React.SetStateAction<number>>
  setRenameTagId: React.Dispatch<React.SetStateAction<number | undefined>>
  setRenameTagTitle: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmTagId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  handlePostJob: (tagId: number) => void
}

const useMultiTagColumns = ({
  openDropdownTagId,
  tagGroupMap,
  setOpenDropdownTagId,
  setRenameTagId,
  setRenameTagTitle,
  setDeleteConfirmTagId,
  handlePostJob,
}: Props) => {
  const { t } = useTranslation(['tag', 'common'])

  const columns: TableColumn<BehaviorTag>[] = useMemo(
    () => [
      {
        field: 'id',

        headerName: '',
        colRatio: 0.5,
        paddingTop: 1,
        paddingBottom: 1,
        valueFormatter: (
          _,
          {
            latestAutoAigcWithEventJobStatus: jobStatus,
            tagStatistics: statistics,
            dataSourceType: sourceType,
          }
        ) => {
          if (sourceType === DATA_SOURCE_TYPE_IMPORT) {
            if (!statistics) {
              return (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <IconButton>
                    <Icon
                      icon={'uil uil-times-circle'}
                      color={theme.colors.orangeyRed}
                    />
                  </IconButton>
                </Box>
              )
            }
          }

          if (sourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE) {
            if (jobStatus === 'error') {
              return (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Icon
                    icon={'uil uil-times-circle'}
                    color={theme.colors.orangeyRed}
                  />
                </Box>
              )
            }

            if (jobStatus === 'processing') {
              return (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <MuiCircularProgress size={18} />
                </Box>
              )
            }

            if (jobStatus === 'success') {
              return ''
            }
          }
        },
      },
      {
        field: 'title',
        colRatio: 1.5,
        headerName: 'tag_name',
      },
      {
        field: 'groupId',
        colRatio: 1.5,
        headerName: 'tag_group_list',
        valueFormatter: groupId => {
          if (typeof groupId === 'number') {
            return tagGroupMap[getIntSafe(groupId)]
          }
          return ''
        },
      },
      {
        field: 'tagStatistics',
        colRatio: 1.5,
        headerName: 'people_amount',

        valueFormatter: values => {
          const tagStatistics = values as BehaviorTag['tagStatistics']

          if (tagStatistics && typeof tagStatistics.userCount === 'number') {
            return (
              <span style={{ whiteSpace: 'nowrap' }}>
                {formatNumber(tagStatistics.userCount)}
              </span>
            )
          }

          return '-'
        },
        align: 'right',
      },
      {
        field: 'dataSourceType',
        headerName: 'create_way',
        valueFormatter: value => {
          const dataSourceType = value as DataSourceType

          if (dataSourceType === DATA_SOURCE_TYPE_IMPORT) {
            return <Box component="span">{t('tag:create_way.import')}</Box>
          }

          if (dataSourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE) {
            return (
              <Box component="span">{t('tag:create_way.rule_creation')}</Box>
            )
          }

          return ''
        },
      },

      {
        field: 'id',
        headerName: 'update_way',
        valueFormatter: (
          _,
          { schedulingType: type, dataSourceType: sourceType }
        ) => {
          if (sourceType === DATA_SOURCE_TYPE_IMPORT) {
            return (
              <Box component="span" sx={{ color: theme.colors.brightBlue }}>
                {t('tag:update_way.import')}
              </Box>
            )
          }

          if (sourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE) {
            if (type === 'none') {
              return (
                <Box component="span" sx={{ color: theme.colors.brightBlue }}>
                  {t('tag:update_way.manual')}
                </Box>
              )
            }

            if (type === 'daily') {
              return (
                <Box component="span" sx={{ color: theme.colors.brightBlue }}>
                  {t('tag:update_way.scheduled')}
                </Box>
              )
            }
          }
        },
      },
      {
        field: 'createdAt',
        headerName: 'created_time',
        valueFormatter: createdTime =>
          formatDateTimeDisplay(createdTime as string),
      },
      { field: 'creatorName', headerName: 'created_by' },
      {
        field: 'tagStatistics',
        headerName: 'latest_tag_version',
        valueFormatter: values => {
          const tagStatistics = values as BehaviorTag['tagStatistics']

          return tagStatistics?.currentTagSyncAt
            ? formatDateTimeDisplay(tagStatistics.currentTagSyncAt)
            : '-'
        },
      },
      {
        field: 'id',
        colRatio: 0.5,
        headerName: '',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: (_, { id: tagId, title: tagName, dataSourceType }) => (
          <div
            onClick={event => {
              // 避免觸發 TableRow 的導頁行為
              event.stopPropagation()
            }}
          >
            <Dropdown
              isOpen={openDropdownTagId === tagId}
              placement="left-start"
              setIsOpen={currIsOpen => {
                if (currIsOpen === false) {
                  // 代表 clickAway
                  setOpenDropdownTagId(-1)
                  return
                }
                setOpenDropdownTagId(tagId)
              }}
              anchorElem={
                <IconButton>
                  <Icon
                    icon={'uil uil-ellipsis-v'}
                    fontSize="small"
                    color={'#27527e'}
                  />
                </IconButton>
              }
            >
              <MenuList onMouseLeave={() => setOpenDropdownTagId(-1)}>
                <Link
                  to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${BEHAVIOR_TAG_PATH}/${tagId}`}
                >
                  <MenuItem>{t('tag:tag_detail')}</MenuItem>
                </Link>
                {(dataSourceType as DataSourceType) ===
                  DATA_SOURCE_TYPE_AUTO_AIGC_RULE && (
                  <Link
                    to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${BEHAVIOR_TAG_PATH}/${PAGE_MODE.copy}/${tagId}`}
                  >
                    <MenuItem>{t('tag:copy_then_edit_condition')}</MenuItem>
                  </Link>
                )}
                {(dataSourceType as DataSourceType) ===
                  DATA_SOURCE_TYPE_AUTO_AIGC_RULE && (
                  <MenuItem onClick={() => handlePostJob(tagId)}>
                    {t('tag:update_tag')}
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() => {
                    setRenameTagId(tagId)
                    setRenameTagTitle(tagName)
                  }}
                >
                  {t('common:rename')}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    setDeleteConfirmTagId(tagId)
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              </MenuList>
            </Dropdown>
          </div>
        ),
      },
    ],
    [
      tagGroupMap,
      t,
      openDropdownTagId,
      setOpenDropdownTagId,
      handlePostJob,
      setRenameTagId,
      setRenameTagTitle,
      setDeleteConfirmTagId,
    ]
  )

  return columns
}

export default useMultiTagColumns
