import type { ParseKeys } from 'i18next'

import type { ThirdPartyAppGroup } from '@shared/api/rtkQuery'

export const THIRD_PARTY_APP_GROUP_MAP: Record<
  ThirdPartyAppGroup,
  {
    IMAGE_URL: string
    TITLE: ParseKeys<['audience']>
    SUBTITLE: ParseKeys<['audience']>
    EXPORT_TEXT: ParseKeys<['audience']>
  }
> = {
  line: {
    IMAGE_URL: '/images/logo-line.svg',
    TITLE: 'audience:export_third_party_app_group.line.title',
    SUBTITLE: 'audience:export_third_party_app_group.line.subtitle',
    EXPORT_TEXT: 'audience:export_third_party_app_group.line.export_text',
  },
  sms: {
    IMAGE_URL: '/images/icon-comment-alt-message.svg',
    TITLE: 'audience:export_third_party_app_group.sms.title',
    SUBTITLE: 'audience:export_third_party_app_group.sms.subtitle',
    EXPORT_TEXT: 'audience:export_third_party_app_group.sms.export_text',
  },
  edm: {
    IMAGE_URL: '',
    TITLE: 'audience:export_third_party_app_group.edm.title',
    SUBTITLE: 'audience:export_third_party_app_group.edm.subtitle',
    EXPORT_TEXT: 'audience:export_third_party_app_group.edm.export_text',
  },
  iterable: {
    IMAGE_URL: '/images/logo-iterable.svg',
    TITLE: 'audience:export_third_party_app_group.iterable.title',
    SUBTITLE: 'audience:export_third_party_app_group.iterable.subtitle',
    EXPORT_TEXT: 'audience:export_third_party_app_group.iterable.export_text',
  },
}
