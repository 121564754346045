import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import { Checkbox as BaseCheckbox } from '@shared/ui/CheckboxList'

export const ControlSection = styled.div`
  display: flex;
  overflow-y: hidden;

  width: 100%;
  height: 40px;
`

export const ItemTrack = styled.div<{ posLeft: number }>`
  position: relative;
  display: flex;

  margin: 0 8px;

  transform: ${({ posLeft }) => `translateX(${posLeft}px)`};
  transition: 0.36s ease;
`

export const ItemWrapper = styled(Box)`
  overflow-x: hidden;

  width: 100%;
`

export const Item = styled.div`
  display: flex;
  align-items: center;

  margin-right: 8px;

  cursor: pointer;
`

export const IconWrapper = styled.div<{
  disabled: boolean
  isCollapseLeft?: boolean
  isCollapseRightt?: boolean
}>`
  display: flex;
  align-items: center;

  ${({ isCollapseLeft }) =>
    isCollapseLeft &&
    `
    margin-left: -4px;
  `}
  ${({ isCollapseRightt }) =>
    isCollapseRightt &&
    `
    margin-right: -4px;
  `}

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.lightGreyBlue : theme.colors.textSecondBlue};

  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  transition: 0.2s;

  :hover {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.lightGreyBlue : theme.colors.black};
  }
`

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0px 4px;
  min-width: 30px;

  color: ${({ theme }) => theme.colors.textSecondBlue};
  white-space: nowrap;
`

export const Checkbox = styled(BaseCheckbox)`
  &.MuiButtonBase-root {
    margin-left: -3px;
    padding-right: 3px;
    padding-left: 0px;
  }
`

export const Label = styled.div<{ isVisible: boolean }>`
  overflow: hidden;
  color: ${({ isVisible, theme }) =>
    isVisible ? theme.colors.blackTwo : theme.colors.cloudyBlue};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Circle = styled.div<{ bgColor?: string; isVisible?: boolean }>`
  margin-right: 8px;
  width: 12px;
  height: 12px;

  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.white};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.4)};
`
