import { ChartDataWithEventIndex } from '@shared/api/rtkQuery'
import type { ChartData } from '@shared/ui/charts'

import { MAX_TOP_N } from '../models/constants'
import { EventChartTitle } from '../types'
import { addGroupName } from './addGroupName'
import { filterAndSortDataCompared } from './filterAndSortDataCompared'
import { mergeToChartData } from './mergeToChartData'

/**
 * 根據來源資料與來源比對資料決定圖表顯示資訊
 * @param chartDataList 來源資料
 * @param chartDataListCompared 來源比對資料
 * @param eventNameList 當前選擇的事件名稱列表
 * @param maxCount 每個事件顯示的數量
 * @returns 圖表顯示資訊
 */
export const getChartDataDisplayList = (
  chartDataList?: ChartDataWithEventIndex[],
  chartDataListCompared?: ChartDataWithEventIndex[],
  eventTitleList: EventChartTitle[] = [],
  maxCount = MAX_TOP_N
): ChartData[] => {
  if (chartDataList === undefined || chartDataList.length === 0) {
    return []
  }

  const chartDataDisplayList =
    chartDataListCompared && chartDataListCompared.length > 0
      ? filterAndSortDataCompared(
          addGroupName(chartDataList, eventTitleList),
          addGroupName(chartDataListCompared, eventTitleList)
        )
      : [addGroupName(chartDataList, eventTitleList)]

  return chartDataDisplayList.map(x => mergeToChartData(x, maxCount))
}
