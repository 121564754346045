import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import type { SpacingProps } from '@mui/system'

import type {
  SchedulingType,
  ThirdPartyAppGroup,
  ThirdPartyAppIntegrationListItem,
} from '@shared/api/rtkQuery'
import { THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP } from '@shared/model/constants/thirdParty'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { ConditionalTooltip } from '@shared/ui/tooltips'

import { EXPORT_BUTTON_WIDTH, ExportCard } from '../_shared'
import ExportThirdPartyById from './ExportThirdPartyById'
import { THIRD_PARTY_APP_GROUP_MAP } from './utils'

export type RouteState = {
  integrationGroup: ThirdPartyAppGroup
}

type Props = {
  group: ThirdPartyAppGroup
  audienceRuleId: number
  audienceRuleTitle: string
  schedulingType: SchedulingType
  thirdPartyAppList: Pick<
    ThirdPartyAppIntegrationListItem,
    'id' | 'name' | 'type' | 'lineOaName'
  >[]
  onExported?: VoidFunction
} & SpacingProps

const ExportThirdParty = ({
  group,
  audienceRuleId,
  audienceRuleTitle,
  schedulingType,
  thirdPartyAppList,
  onExported: handleExported,
  ...spacingProps
}: Props) => {
  const { t } = useTranslation(['audience', 'common'])

  const {
    location: { state: routeState },
  } = useHistory<RouteState | undefined>()

  const [isOpen, setIsOpen] = useState(routeState?.integrationGroup === group)

  const handleClose = () => setIsOpen(false)

  const isExportButtonDisabled = thirdPartyAppList.length === 0

  return (
    <>
      <ExportCard
        imageURL={THIRD_PARTY_APP_GROUP_MAP[group].IMAGE_URL}
        title={t(THIRD_PARTY_APP_GROUP_MAP[group].TITLE) as string}
        description={t(THIRD_PARTY_APP_GROUP_MAP[group].SUBTITLE)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...spacingProps}
      >
        <ConditionalTooltip
          title={t('audience:export_require_api_key', {
            maTool: THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP[group],
          })}
          isShow={isExportButtonDisabled}
        >
          <DeepBlueGradientButton
            width={EXPORT_BUTTON_WIDTH}
            onClick={() => setIsOpen(true)}
            disabled={isExportButtonDisabled}
          >
            {t(THIRD_PARTY_APP_GROUP_MAP[group].EXPORT_TEXT)}
          </DeepBlueGradientButton>
        </ConditionalTooltip>
      </ExportCard>

      <ConfirmDialog
        isOpen={!isExportButtonDisabled && isOpen}
        hasConfirmBtn={false}
        maxWidth="md"
        modalTitle={t(THIRD_PARTY_APP_GROUP_MAP[group].EXPORT_TEXT)}
        cancelText={t('common:back')}
        // 由於 ExportThirdPartyById 會開啟其他 Dialog
        // 為了避免同時出現多個彈窗因此將此 Dialog 隱藏
        // 如果不用 keepMounted 會導致 DOM (連同子元件開啟的彈窗) 一起被移除
        keepMounted
        onConfirm={handleClose}
        onClose={handleClose}
      >
        <Box sx={{ borderTop: theme => `1px solid ${theme.colors.black6}` }}>
          {thirdPartyAppList
            .filter(thirdPartyApp => thirdPartyApp.type !== 'mitake') // mitake 不顯示匯出，因為未來 sms 可能會完全移除，所以就不特別處理其他 sms provider 情況。
            .map(thirdPartyApp => (
              <Box
                key={thirdPartyApp.id}
                sx={{
                  borderBottom: theme => `1px solid ${theme.colors.black6}`,
                }}
              >
                <ExportThirdPartyById
                  audienceRuleId={audienceRuleId}
                  audienceRuleTitle={audienceRuleTitle}
                  schedulingType={schedulingType}
                  name={thirdPartyApp.name}
                  thirdPartyAppId={thirdPartyApp.id}
                  provider={thirdPartyApp.type}
                  lineOaName={thirdPartyApp.lineOaName}
                  // 當子層開啟彈窗時關閉父層彈窗，反之亦然
                  onDialogOpen={() => setIsOpen(false)}
                  onDialogClose={() => setIsOpen(true)}
                  onExported={handleExported}
                />
              </Box>
            ))}
        </Box>
      </ConfirmDialog>
    </>
  )
}

export default ExportThirdParty
