import { createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import type { SpacingProps } from '@mui/system'

import {
  useExportAudienceRuleUserCSVMutation,
  useGetAudienceRuleUserLatestExportCSVQuery,
} from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks/store'
import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import CSVButton from '@shared/ui/buttons/CSVButton'
import ContactUs from '@shared/ui/ContactUs'
import { ConfirmDialog } from '@shared/ui/dialogs'
import { DialogActions, DialogContent } from '@shared/ui/dialogs'
import { Dialog } from '@shared/ui/dialogs/base'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import {
  EXPORT_BUTTON_WIDTH,
  ExportCard,
  getExportPollingInterval,
} from '../_shared'
import { checkIsExpired, getFilename, getStatusText } from './helpers'
import { BlueBorderDownloadButton } from './styles'

const NO_DATA_ID = 0

type Props = {
  audienceRuleId: number
  latestAudienceRuleUserCreatedAt: string
  onExported?: VoidFunction
} & SpacingProps

const ExportCSV = ({
  audienceRuleId,
  latestAudienceRuleUserCreatedAt,
  onExported,
  ...spacingProps
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])

  const isExportCSVAvailable = useAppSelector(
    state => state.auth.plan.audience.exportCSV
  )

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false)
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false)
  const [downloadedCache, setDownloadedCache] = useState<number[]>([])

  const [pollingInterval, setPollingInterval] = useState<number>()

  const { data: exportInfo } = useGetAudienceRuleUserLatestExportCSVQuery(
    audienceRuleId,
    { skip: audienceRuleId === NO_DATA_ID, pollingInterval }
  )

  const linkRefs = useRef([])
  if (exportInfo?.downloadUrls) {
    linkRefs.current = exportInfo.downloadUrls.map(
      (_, i) => linkRefs.current[i] ?? createRef()
    )
  }

  const safeCloseAllPopup = () => {
    setIsOpenDialog(false)
    setIsOpenConfirmDialog(false)
  }

  useEffect(() => {
    setPollingInterval(getExportPollingInterval(exportInfo?.status))
  }, [exportInfo])

  const [exportAudienceRuleUserCSV] = useExportAudienceRuleUserCSVMutation()

  const handleExportCsv = async () => {
    await exportAudienceRuleUserCSV(audienceRuleId)

    setDownloadedCache([])
    onExported?.()
  }

  return (
    <>
      <ExportCard
        imageURL="/images/icon-file-alt.svg"
        title={t('audience:export_csv')}
        description={
          <>
            {getStatusText(t, exportInfo?.status)}

            {exportInfo?.status === 'failed' && (
              <>
                {`${t('common:dot')}${t('common:please_try_again_or')}`}
                <ContactUs text={t('common:contact_us')} />
              </>
            )}
          </>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...spacingProps}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CSVButton
            isExpired={checkIsExpired(
              latestAudienceRuleUserCreatedAt,
              exportInfo?.createdAt
            )}
            isDisabled={!isExportCSVAvailable}
            status={exportInfo?.status || 'none'}
            onExportCSV={handleExportCsv}
            onDownloadCSV={() => {
              setIsOpenDialog(true)
            }}
          />
          {!isExportCSVAvailable && (
            <Box mt="10px" color={theme.colors.textSecondBlue}>
              {t('common:trial.not_available_in_trial')}
            </Box>
          )}
        </Box>
      </ExportCard>

      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={safeCloseAllPopup}
        open={isOpenDialog}
        overflowY="auto"
        scroll="paper"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box fontSize={16} mt={1}>
              {t('audience:export_csv_dialog_title')}
            </Box>
            <Box m={-0.5} ml={2}>
              <IconButton onClick={safeCloseAllPopup}>
                <Icon icon={ICON.multiply} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent overflowY={'auto'}>
          {exportInfo?.downloadUrls &&
            exportInfo?.downloadUrls.map((url, index) => {
              const isDownloaded: boolean = downloadedCache.includes(index)

              return (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="20px"
                  mb="20px"
                >
                  <span>{getFilename(url)}</span>
                  <Box
                    component="a"
                    href={url}
                    ref={linkRefs.current[index]}
                    onClick={() => {
                      setDownloadedCache(prev => {
                        return [...prev, index]
                      })
                    }}
                  >
                    <BlueBorderDownloadButton isDownloaded={isDownloaded}>
                      {isDownloaded
                        ? t('audience:downloaded')
                        : t('audience:Download')}
                    </BlueBorderDownloadButton>
                  </Box>
                </Box>
              )
            })}
        </DialogContent>

        {exportInfo?.downloadUrls && exportInfo.downloadUrls.length > 1 && (
          <DialogActions>
            <Box display="flex" justifyContent="center" width="100%" mb="20px">
              <DeepBlueGradientButton
                width={EXPORT_BUTTON_WIDTH}
                onClick={() => {
                  if (exportInfo?.downloadUrls) {
                    setIsOpenConfirmDialog(true)
                  }
                }}
              >
                {t('audience:download_all')}
              </DeepBlueGradientButton>
            </Box>
          </DialogActions>
        )}
      </Dialog>

      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        maxWidth="xs"
        modalTitle={t('audience:sure_to_download_all')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          linkRefs.current.forEach(({ current }, index) => {
            // 不用 setTimeout 做時間差間隔的話，會觸發一個叫 Provisional headers are shown 的錯誤，另外其實有時間差也對用戶體驗比較好
            setTimeout(() => {
              ;(current as HTMLAnchorElement).click()

              if (index + 1 === linkRefs.current.length) {
                safeCloseAllPopup()
              }
            }, index * 1000)
          })
        }}
      />
    </>
  )
}

export default ExportCSV
