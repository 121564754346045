import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import type { SearchColumnAlias } from '@shared/api/rtkQuery'
import { useSearchUserQuery } from '@shared/api/rtkQuery'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import DataTable from '@shared/ui/table/DataTable'

import SearchBar from '../_shared/SearchBar'
import { TABLE_COLUMN_SCHEMA } from './constants'

type Props = {
  searchColumn: SearchColumnAlias
  searchValue: string
}

const UserTable = ({ searchColumn, searchValue }: Props) => {
  const { t } = useTranslation(['common', 'userProfile'])
  const history = useHistory()
  const { data = [], isFetching } = useSearchUserQuery({
    searchColumnAlias: searchColumn,
    searchValue,
    searchOperator: 'like',
    perPage: 100,
  })
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          gridTemplateColumns: 'repeat(2, max-content)',
          gap: 3,
          mb: 4,
        }}
      >
        <Breadcrumbs sx={{ mb: 0 }}>
          <Box>{t('common:route.user_profile')}</Box>
        </Breadcrumbs>
        <Box
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            color: theme => theme.colors.textSecondBlue,
          }}
        >
          {t('userProfile:user_profile_list.limit_hint')}
        </Box>
      </Box>
      <Box sx={{ mb: 3 }}>
        <SearchBar searchColumn={searchColumn} searchValue={searchValue} />
      </Box>
      <DataTable
        columns={TABLE_COLUMN_SCHEMA}
        isHidePagination
        isLoading={isFetching}
        isRowClickable
        rows={data}
        rowIdKey="cdpUserId"
        onRowClick={rowData => {
          history.push(
            `/${CATEGORY.cdm}/${PAGE_ROOT.userProfile}/${rowData.cdpUserId}`
          )
        }}
      />
    </>
  )
}

export default UserTable
