import { MAX_TOP_N } from '../models/constants'
import type { EventRuleSettings } from '../types'

export const createInitialEventRuleSettings = (
  privacyLevel: EventRuleSettings['privacyLevel'] = 'public',
  groupId: EventRuleSettings['groupId'] = 0
): EventRuleSettings => ({
  title: '',
  chartType: 'line_chart',
  indicator: { latest: true, sum: false, avg: false, type: 'bar' },
  privacyLevel,
  groupId,
  topN: MAX_TOP_N,
  timeRangeParamsCompared: undefined,
})
