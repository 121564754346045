import { forwardRef, useState } from 'react'
import styled from '@emotion/styled'
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'

import { ICON, LAYOUT } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme, { ThemeColor } from '@theme'

type ButtonProps = {
  bgColor?: string
  disableBgColor?: string
  hasShadow?: boolean
  height?: number
  hoverBgColor?: string
  isDisabled?: boolean
  isError?: boolean
  isFullWidth?: boolean
  isMinWidth?: boolean
  marginRightRatio?: number
  maxWidth?: number
}

const IconWrapper = styled.div<{
  height?: number
  isShow?: boolean
}>`
  display: flex;
  align-items: center;

  padding-right: 4px;
  height: ${({ height }) => height || LAYOUT.audienceBuilder.heightOfRow}px;

  cursor: pointer;

  opacity: ${({ isShow }) => (isShow ? 100 : 0)};

  :hover {
    /* ICON */
    div {
      color: ${theme.colors.brightBlue};
    }
  }
`

export const Button = styled(
  forwardRef<HTMLButtonElement, ButtonProps & MuiButtonProps>(
    (
      {
        bgColor,
        disableBgColor,
        hasShadow,
        height,
        hoverBgColor,
        isDisabled,
        isError,
        isFullWidth,
        isMinWidth,
        marginRightRatio,
        maxWidth,
        ...otherProps
      },
      ref
    ) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <MuiButton {...otherProps} ref={ref} />
    )
  )
)`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : 'none')};
  && {
    justify-content: space-between;

    margin-right: ${({ marginRightRatio }) => (marginRightRatio || 0) * 12}px;
    padding: 4px 8px 4px 16px;
    width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
    min-width: ${({ isMinWidth }) => (isMinWidth ? '180px' : 'auto')};
    height: ${({ height }) => height || LAYOUT.audienceBuilder.heightOfRow}px;

    border: 1px solid transparent;
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.orangeyRed : 'transparent'};
    border-radius: ${LAYOUT.audienceBuilder.inputBorderRadius}px;
    background-color: ${({ isDisabled, disableBgColor, bgColor, theme }) =>
      isDisabled
        ? disableBgColor || theme.colors.veryLightBlueTwo
        : bgColor || theme.colors.bgPrimaryGrey};

    color: ${({ theme }) => theme.colors.textSecondBlue};
    text-transform: none;
    letter-spacing: normal;
    line-height: normal;

    :hover {
      background-color: ${({ hoverBgColor, theme }) =>
        hoverBgColor || theme.colors.lightGreyBlueTwo};
    }

    box-shadow: ${({ theme, hasShadow }) =>
      hasShadow ? `0px 4px 9px ${theme.colors.black6}` : 'initial'};

    ${({ isDisabled }) =>
      isDisabled &&
      `
        pointer-events: none;
    `}
  }
`

const Label = styled.div<{ color?: string; opacity?: number }>`
  overflow: hidden;
  margin-right: 8px;
  color: ${({ color, theme }) => color || theme.colors.lightNavyBlue};
  font-size: 13px;
  font-family: inherit;
  font-weight: 500;
  text-overflow: ellipsis;

  white-space: nowrap;

  opacity: ${({ opacity }) => (typeof opacity === 'number' ? opacity : 1)};
`

const FilterIconWrapper = styled.figure`
  margin-right: 8px;
`

type ActionButtonProps = {
  anchorId?: string
  bgColor?: string
  color?: ThemeColor
  disableBgColor?: string
  enableResetButton?: boolean
  hasShadow?: boolean
  height?: number
  hoverBgColor?: string
  isDisabled?: boolean
  isError?: boolean
  isFullWidth?: boolean
  isLowOpacityWhenDisabled?: boolean
  isMinWidth?: boolean
  isShowFilterIcon?: boolean
  label?: string
  marginRightRatio?: number
  maxWidth?: number
  onClick?: () => void
  onReset?: () => void
}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      anchorId = '',
      bgColor,
      color,
      disableBgColor,
      enableResetButton = false,
      hasShadow = false,
      height,
      hoverBgColor,
      isDisabled = false,
      isError,
      isFullWidth = false,
      isLowOpacityWhenDisabled = false,
      isMinWidth = false,
      isShowFilterIcon,
      label = '',
      marginRightRatio = 0,
      maxWidth,
      onClick = () => {},
      onReset = () => {},
    },
    ref
  ) => {
    const [isShowResetValueButton, setIsShowResetValueButton] = useState(false)
    return (
      <Button
        id={anchorId}
        onClick={onClick}
        onMouseEnter={() => {
          if (enableResetButton) {
            setIsShowResetValueButton(true)
          }
        }}
        onMouseLeave={() => {
          if (enableResetButton) {
            setIsShowResetValueButton(false)
          }
        }}
        maxWidth={maxWidth}
        isDisabled={isDisabled}
        isMinWidth={isMinWidth}
        isFullWidth={isFullWidth}
        marginRightRatio={marginRightRatio}
        height={height}
        bgColor={bgColor}
        disableBgColor={disableBgColor}
        hoverBgColor={hoverBgColor}
        isError={isError}
        hasShadow={hasShadow}
        ref={ref}
      >
        {isShowFilterIcon && (
          <FilterIconWrapper>
            <Icon
              id="icon-filter"
              icon={ICON.filter}
              color={theme.colors.brightBlue}
              fontSize="small"
            />
          </FilterIconWrapper>
        )}

        <Label color={color} opacity={isLowOpacityWhenDisabled ? 0.25 : 1}>
          {label}
        </Label>

        {enableResetButton && (
          <IconWrapper
            isShow={isShowResetValueButton}
            height={height}
            onClick={() => {
              if (isShowResetValueButton) {
                onReset()
              }
            }}
          >
            <Icon
              icon={ICON.timesCircle}
              fontSize="small"
              color={theme.colors.textSecondBlue}
            />
          </IconWrapper>
        )}

        <Icon
          icon={ICON.arrowDown}
          fontSize="small"
          color={color || theme.colors.textPrimaryBlue}
          opacity={isLowOpacityWhenDisabled ? 0.25 : 1}
        />
      </Button>
    )
  }
)

export default ActionButton
