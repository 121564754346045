import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  TAG_TYPE_INTERNAL_OFFLINE,
  useTagListQuery,
} from '@shared/api/rtkQuery'

import { mergeMetadataCategoryById } from './helpers'
import {
  getCategoriesOfTagUsers,
  getCategoriesOfUserProfileEntity,
} from './metadata'
import useCategoriesOfEventProperties from './useCategoriesOfEventProperties'
import useMetadataQuery from './useMetadataQuery'

export const useCategoriesOfMetadata = (eventName?: string) => {
  const { t } = useTranslation(['audience'])
  const {
    userProfileEntity,
    userProfileIntelligence,
    userThirdPartyTagEntity,
    userProfileDimension,
    userAutoTagEntity,
    userBehaviorTagEntity,
  } = useMetadataQuery()

  const { tagList = [] } = useTagListQuery(
    {
      usable: true,
      page: 1,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    {
      selectFromResult: ({ data = [] }) => ({
        tagList: data.filter(({ type }) => type !== TAG_TYPE_INTERNAL_OFFLINE),
      }),
    }
  )

  const categoriesOfTagUsers = useMemo(
    () => getCategoriesOfTagUsers(tagList, t),
    [t, tagList]
  )

  const categoriesOfUserProfile = getCategoriesOfUserProfileEntity({
    id: 'users',
    name: t('audience:v3.user_profile'),
    entity: userProfileEntity,
  })

  const categoriesOfUserThirdPartyTag = useMemo(
    () =>
      getCategoriesOfUserProfileEntity({
        id: 'thirdPartyTags',
        name: t('audience:v3.third_party_tag'),
        entity: userThirdPartyTagEntity,
      }),
    [t, userThirdPartyTagEntity]
  )

  const categoriesOfUserProfileDimension = getCategoriesOfUserProfileEntity({
    id: 'dimension',
    name: t('audience:v3.dimension_table'),
    entity: userProfileDimension,
  })

  const categoriesOfIntelligenceUserProfile = getCategoriesOfUserProfileEntity({
    id: 'users_profile_intelligence',
    name: t('audience:v3.user_profile_intelligence'),
    entity: userProfileIntelligence,
  })

  const categoriesOfUserAutoTag = getCategoriesOfUserProfileEntity({
    id: 'autoTag',
    name: t('audience:v3.auto_tag'),
    entity: userAutoTagEntity,
  })

  const categoriesOfUserBehaviorTag = getCategoriesOfUserProfileEntity({
    id: 'behaviorTagImport',
    name: t('audience:v3.behavior_tag_import'),
    entity: userBehaviorTagEntity,
  })

  const categoriesOfEventProperties = useCategoriesOfEventProperties(eventName)

  return mergeMetadataCategoryById(
    categoriesOfEventProperties
      .concat(categoriesOfUserProfile)
      .concat(categoriesOfUserProfileDimension)
      .concat(categoriesOfIntelligenceUserProfile)
      .concat(categoriesOfTagUsers)
      .concat(categoriesOfUserThirdPartyTag)
      .concat(categoriesOfUserAutoTag)
      .concat(categoriesOfUserBehaviorTag)
  )
}

export default useCategoriesOfMetadata
