import type { GridComparatorFn } from '@mui/x-data-grid'

export const sortRowWithIgnoreId: (id: string) => GridComparatorFn =
  (id: string) => (v1, v2, param1, param2) => {
    if (String(param1.id).includes(id) || String(param2.id).includes(id)) {
      return 0
    }

    return v1 - v2
  }
