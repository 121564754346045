import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import MuiCircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import {
  TAG_TYPE_OFFLINE,
  TagProps,
  TagSchedulingType,
  TagType,
} from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_MODE, PAGE_ROOT } from '@shared/model/constants/routes'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import theme from '@theme'
import { GRADING_TAG_PATH } from '@widgets/tag/_shared/constants'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'

import { ALL_RENDERING_STATUS, createMethod } from '../_shared/constant'
import type { TagJobStatus } from '../_shared/type'

type Props = {
  openDropdownTagId: number | undefined
  tagGroupMap: TagGroupMapType
  setOpenDropdownTagId: React.Dispatch<React.SetStateAction<number>>
  setRenameTagId: React.Dispatch<React.SetStateAction<number | undefined>>
  setRenameTagTitle: React.Dispatch<React.SetStateAction<string>>
  setDeleteConfirmTagId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
  handlePostJob: (tagId: number) => void
}

const useLocalTagColumns = ({
  openDropdownTagId,
  tagGroupMap,
  setOpenDropdownTagId,
  setRenameTagId,
  setRenameTagTitle,
  setDeleteConfirmTagId,
  handlePostJob,
}: Props) => {
  const { t } = useTranslation(['tag', 'common'])

  const columns: TableColumn<TagProps>[] = useMemo(
    () => [
      {
        field: 'latestJobStatus',
        headerName: '',
        colRatio: 0.5,
        paddingTop: 1,
        paddingBottom: 1,
        valueFormatter: status => {
          if (status === 'failed') {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton>
                  <Icon
                    icon={'uil uil-times-circle'}
                    color={theme.colors.orangeyRed}
                  />
                </IconButton>
              </Box>
            )
          }

          if (status === '') {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <IconButton>
                  <Icon
                    icon={'uil uil-times-circle'}
                    color={theme.colors.orangeyRed}
                  />
                </IconButton>
              </Box>
            )
          }

          if (ALL_RENDERING_STATUS.includes(status as TagJobStatus)) {
            return (
              <Box display="flex" justifyContent="center" alignItems="center">
                <MuiCircularProgress size={18} />
              </Box>
            )
          }

          return null
        },
      },
      {
        field: 'title',
        colRatio: 1.5,
        headerName: 'tag_name',
      },
      {
        field: 'groupId',
        colRatio: 1.5,
        headerName: 'tag_group_list',
        valueFormatter: groupId => {
          if (typeof groupId === 'number') {
            return tagGroupMap[getIntSafe(groupId)]
          }
          return ''
        },
      },
      {
        field: 'latestTagUsersCount',
        colRatio: 1.5,
        headerName: 'people_amount',

        valueFormatter: count => {
          if (typeof count === 'number') {
            return (
              <span style={{ whiteSpace: 'nowrap' }}>
                {formatNumber(count)}
              </span>
            )
          }
          return '-'
        },
        align: 'right',
      },
      {
        field: 'type',
        headerName: 'create_way',
        valueFormatter: type => {
          return createMethod(t)[type as TagType]
        },
      },
      {
        field: 'id',
        headerName: 'update_way',
        valueFormatter: (_, { type, schedulingType }) => {
          const updateMethod = (
            type: TagType,
            scheduleType: TagSchedulingType
          ): string => {
            if (type === TAG_TYPE_OFFLINE) {
              return t('tag:update_way.import')
            } else {
              if (scheduleType === 'none') {
                return t('tag:update_way.manual')
              } else {
                return t('tag:update_way.scheduled')
              }
            }
          }

          return (
            <Box component="span" sx={{ color: theme.colors.brightBlue }}>
              {updateMethod(type, schedulingType)}
            </Box>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: 'created_time',
        valueFormatter: createdTime =>
          formatDateTimeDisplay(createdTime as string),
      },
      { field: 'creatorName', headerName: 'created_by' },
      {
        field: 'latestTagUsersCreatedAt',
        headerName: 'latest_tag_version',
        valueFormatter: latestTagUsersCreatedTime => {
          return latestTagUsersCreatedTime
            ? formatDateTimeDisplay(latestTagUsersCreatedTime as string)
            : '-'
        },
      },

      {
        field: 'id',
        colRatio: 0.5,
        headerName: '',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: (_, { id: tagId, title: tagName, type }) => (
          <div
            onClick={event => {
              // 避免觸發 TableRow 的導頁行為
              event.stopPropagation()
            }}
          >
            <Dropdown
              isOpen={openDropdownTagId === tagId}
              placement="left-start"
              setIsOpen={currIsOpen => {
                if (currIsOpen === false) {
                  // 代表 clickAway
                  setOpenDropdownTagId(-1)
                  return
                }
                setOpenDropdownTagId(tagId)
              }}
              anchorElem={
                <IconButton>
                  <Icon
                    icon={'uil uil-ellipsis-v'}
                    fontSize="small"
                    color={'#27527e'}
                  />
                </IconButton>
              }
            >
              <MenuList onMouseLeave={() => setOpenDropdownTagId(-1)}>
                <Link
                  to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${GRADING_TAG_PATH}/${tagId}`}
                >
                  <MenuItem>{t('tag:tag_detail')}</MenuItem>
                </Link>
                {(type as string) !== TAG_TYPE_OFFLINE && (
                  <Link
                    to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${GRADING_TAG_PATH}/${PAGE_MODE.copy}/${tagId}`}
                  >
                    <MenuItem>{t('tag:copy_then_edit_condition')}</MenuItem>
                  </Link>
                )}
                <MenuItem
                  onClick={() => {
                    setRenameTagId(tagId)
                    setRenameTagTitle(tagName)
                  }}
                >
                  {t('common:rename')}
                </MenuItem>
                {(type as string) !== TAG_TYPE_OFFLINE && (
                  <MenuItem onClick={() => handlePostJob(tagId)}>
                    {t('tag:update_tag')}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setDeleteConfirmTagId(tagId)
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              </MenuList>
            </Dropdown>
          </div>
        ),
      },
    ],
    [
      tagGroupMap,
      t,
      openDropdownTagId,
      setOpenDropdownTagId,
      setRenameTagId,
      setRenameTagTitle,
      handlePostJob,
      setDeleteConfirmTagId,
    ]
  )

  return columns
}

export default useLocalTagColumns
