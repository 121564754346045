const RFM_NAME_MAP = {
  at_risk: '半沈睡客戶',
  cannot_lose: '重要挽留客戶',
  champions: '忠誠客戶',
  lost: '沈睡客戶',
  new: '新進客戶',
  promising: '潛力客戶',
}

const getRfmTemplate = (
  source: string,
  values: (keyof typeof RFM_NAME_MAP)[]
) => {
  const params = values.map(
    (value: keyof typeof RFM_NAME_MAP) => RFM_NAME_MAP[value] || value
  )

  return {
    combination: 'and',
    filters: [
      {
        node: {
          combination: 'and',
          nodes: [
            {
              combination: 'and',
              nodes: [
                {
                  field: 'value',
                  operator: '=',
                  params,
                  source,
                  type: 'condition',
                },
              ],
              type: 'group',
            },
          ],
          type: 'group',
        },
        type: 'user_profile_tag',
      },
    ],
  }
}

export default getRfmTemplate
