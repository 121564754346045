import styled from '@emotion/styled'
import MuiButton, { ButtonProps } from '@mui/material/Button'
import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiDialogContent, {
  DialogContentProps,
} from '@mui/material/DialogContent'
import { spacing } from '@mui/system'

export const DIALOG_PROGRESS_HEIGHT = 4

export const Dialog = styled(
  ({ overflowY, ...otherProps }: DialogProps & { overflowY?: string }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialog {...otherProps} />
  )
)`
  & .MuiDialog-paper {
    overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  }
`

export const DialogActions = styled(MuiDialogActions)`
  && {
    padding: 8px 24px;
    ${spacing}
  }
`

export const DialogContent = styled(
  ({
    overflowY,
    ...otherProps
  }: DialogContentProps & { overflowY?: string }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiDialogContent {...otherProps} />
  )
)`
  && {
    overflow-y: ${({ overflowY }) => overflowY || 'auto'};
  }
`

export const Button = styled(
  ({
    isPrimaryColor,
    ...otherProps
  }: {
    isPrimaryColor?: boolean
  } & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton {...otherProps} />
  )
)`
  && {
    color: ${({ isPrimaryColor, theme }) =>
      isPrimaryColor ? theme.colors.brightBlue : theme.colors.textSecondBlue};
    font-family: inherit;
    font-weight: 500;
    text-transform: none;
  }
`

export const ButtonWithBG = styled(
  ({
    bgColor,
    ...otherProps
  }: {
    bgColor?: string
  } & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiButton {...otherProps} />
  )
)`
  && {
    background-color: ${({ bgColor, theme }) =>
      bgColor || theme.colors.brightBlue};

    color: ${({ theme }) => theme.colors.white};
    font-family: inherit;
    font-weight: 500;
    text-transform: none;

    transition: 0.25s;

    :hover {
      background-color: ${({ bgColor, theme }) =>
        bgColor || theme.colors.brightBlue};
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.7;
    }
  }
`
