import type { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

import Copyright from '@shared/ui/Copyright'
import DotLoader from '@shared/ui/loaders/DotLoader'

type TableLoadingContainerWithCopyrightProps = {
  isLoading: boolean
  isShowCopyrightWhenLoading?: boolean
}

const TableLoadingContainerWithCopyright = ({
  isLoading,
  isShowCopyrightWhenLoading = false,
  children,
}: PropsWithChildren<TableLoadingContainerWithCopyrightProps>) => (
  <>
    {isLoading ? (
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <DotLoader />
        </Box>

        {children}
      </Box>
    ) : (
      children
    )}

    {/**
     * ContentWrapper 在 apiHandler.isLoading = true 時會將頁面下方 copyright 區塊移除，造成頁面高度改變，因此部分頁面需要將 isShowCopyrightWhenLoading 設定為 true 以維持頁面高度
     */}
    {isShowCopyrightWhenLoading && isLoading && <Copyright />}
  </>
)

export default TableLoadingContainerWithCopyright
