import pipe from 'lodash/fp/pipe'
import qs from 'qs'
import snakecaseKeys from 'snakecase-keys'

import type { ReplacementDataActionURL } from '@shared/api/rtkQuery'
import checkHasUtmProperties from '@shared/lib/utils/checkHasUtmProperties'
import deleteEmptyKeys from '@shared/lib/utils/deleteEmptyKeys'

const andRegex = /&amp;/g

export const mapUrlParams = (
  host: string,
  query: string
): ReplacementDataActionURL['urlParams'] => {
  if (checkHasUtmProperties(host)) {
    return {
      host,
      utmCampaignId: '',
      utmMedium: '',
      utmSource: '',
      utmCampaign: '',
      utmTerm: '',
      utmContent: '',
    }
  }

  const hostWithUtmQuerystring = pipe(
    value => value.replace(andRegex, '&'),
    qs.parse,
    deleteEmptyKeys,
    snakecaseKeys,
    qs.stringify,
    value => (value ? `${host}?${value}` : host)
  )(query) as string

  return {
    host: hostWithUtmQuerystring,
    utmCampaignId: '',
    utmMedium: '',
    utmSource: '',
    utmCampaign: '',
    utmTerm: '',
    utmContent: '',
  }
}

export default mapUrlParams
