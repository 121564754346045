import escape from 'lodash/escape'

import { getDisplayData } from './getDisplayData'

// tooltip class 定義在 LineChart 的 Wrapper
export const getTooltipHTMLString = (props: {
  header: string[]
  colors: string[]
  names: string[]
  data2D: (string | number | null)[][]
}) => {
  const { header, colors, names, data2D } = props

  const result: string[] = []

  header.forEach((value, i) => {
    result.push(`<div class="tooltip-column">`)

    if (i === 0) {
      const chartTitle = value
      result.push(
        `<div class="tooltip-name">${
          data2D.length <= 1 ? '' : chartTitle
        }</div>`
      )
      colors.forEach((color, j) => {
        result.push(`<div class="tooltip-row">`)
        result.push(
          `<div class="tooltip-circle" style="background-color: ${color};"></div>`
        )
        result.push(
          `<div class="tooltip-name">${
            escape(names[j]) || (data2D.length <= 1 ? chartTitle : '')
          }</div>`
        )
        result.push(`</div>`)
      })
    } else {
      result.push(`<div class="tooltip-value">${getDisplayData(value)}</div>`)
      data2D.forEach(values => {
        result.push(
          `<div class="tooltip-value">${getDisplayData(values[i - 1])}</div>`
        )
      })
    }

    // close tooltip-column
    result.push(`</div>`)
  })

  /*
      final result:
  
      |---------------------------------|
      |  some-chart-title    1/2   10/2 |
      |  安安                  6      14 |
      |  你好                null     21 |
      |  嗎嗎                  12      9 |
      |---------------------------------|
    */

  return `<div class="tooltip-wrapper">${result.join('')}</div>`
}
