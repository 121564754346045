import jwtDecode from 'jwt-decode'

import type { JWTPayload } from '@shared/api/axios'

import getUniqueUserId from './getUniqueUserId'

type CdpAuthTokenDecoded = {
  authType: string
  email: string
  expirationTime: Date
  ikaId: string
  projectName: string
  userId: string
}

export const decodeCdpAuthToken = (
  cdpAuthToken: string
): CdpAuthTokenDecoded | undefined => {
  if (!cdpAuthToken) {
    return undefined
  }

  try {
    const {
      auth_type: authType = 'sso',
      email,
      exp = 0,
      ika_id: ikaId,
      project_name: projectName,
      user_id: userId,
    } = jwtDecode<JWTPayload>(cdpAuthToken)

    // JWT 的過期時間單位是秒，先換算為毫秒再轉成 Date 格式
    const expirationTime = new Date(exp * 1000)

    return {
      authType,
      email,
      expirationTime,
      ikaId,
      projectName,
      userId: getUniqueUserId(projectName, userId),
    }
  } catch (err) {
    return undefined
  }
}
