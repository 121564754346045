import { useEffect, useState } from 'react'

import { INITIAL_POS, Placement, PositionType } from './models'
import { Wrapper } from './styles'
import { getTopAndLeft, getTransform } from './utils'

export const Popup = ({
  children,
  selfId = '',
  anchorId,
  closePopup = () => {},
  customStyle = { offsetTop: 0, offsetLeft: 0 },
  width = 'auto',
  placement = 'top',
  positionType = 'absolute',
}: {
  children: React.ReactNode
  selfId?: string
  anchorId: string
  closePopup?: () => void
  customStyle?: {
    offsetTop: number
    offsetLeft: number
  }
  width?: number | 'auto'
  placement?: Placement
  positionType?: PositionType
}) => {
  const [pos, setPos] = useState({ top: INITIAL_POS, left: INITIAL_POS })

  const handleMouseLeave = () => {
    closePopup()
  }

  useEffect(() => {
    if (anchorId) {
      const anchorElement = document.querySelector<HTMLElement>(`#${anchorId}`)

      if (anchorElement) {
        const { top, left } = getTopAndLeft(
          anchorElement,
          placement,
          customStyle
        )

        if (top !== pos.top || left !== pos.left) {
          setPos({ top, left })
        }
      }
    }
  }, [anchorId, customStyle, placement, pos.left, pos.top])

  const transformStyle = getTransform(placement)

  return (
    <Wrapper
      id={selfId}
      isOpen={Boolean(anchorId)}
      onMouseLeave={handleMouseLeave}
      top={pos.top}
      left={pos.left}
      width={width}
      placement={placement}
      transformStyle={transformStyle}
      positionType={positionType}
    >
      {children}
    </Wrapper>
  )
}

export default Popup
