import type { AudienceRuleUserExportFacebookAds } from '@shared/api/rtkQuery'
import type { FacebookAdAccount } from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport/ExportFacebookAds/utils'

export const formatFacebookAdsExportList = (
  accountList: FacebookAdAccount[] = [],
  exportList: AudienceRuleUserExportFacebookAds[] = []
) => {
  const fbAdsList = accountList.map(({ id, name }) => {
    const exportStatus = exportList.find(data => data.accountId === id)

    return {
      accountId: id,
      accountName: name,
      ...exportStatus,
    }
  })

  return fbAdsList
}
