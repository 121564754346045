import { NodeKey } from 'lexical'

import type {
  ReplacementDataActionURL,
  ReplacementDataTracingURL,
} from '@shared/api/rtkQuery'

import ActionUrlChip from './ActionUrlChip'
import TracingUrlChip from './TracingUrlChip'

export type Props = {
  replacementKey: string
  replacementData: ReplacementDataActionURL | ReplacementDataTracingURL
  nodeKey: NodeKey
}

const UtmComponent = ({ replacementKey, replacementData, nodeKey }: Props) => {
  if (replacementData.type === 'action_url') {
    return (
      <ActionUrlChip
        replacementData={replacementData}
        replacementKey={replacementKey}
        nodeKey={nodeKey}
      />
    )
  }
  if (replacementData.type === 'tracing_url') {
    return (
      <TracingUrlChip
        replacementData={replacementData}
        replacementKey={replacementKey}
        nodeKey={nodeKey}
      />
    )
  }
  return null
}

export default UtmComponent
