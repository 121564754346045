import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface State {
  isLoading: boolean
  isOpenDialog: boolean
  message: string
}

const initialState: State = {
  isLoading: false,
  isOpenDialog: false,
  message: '',
}

const apiHandler = createSlice({
  name: 'apiHandler',
  initialState,
  reducers: {
    setLoading: () => {},
    startLoading: state => {
      state.isLoading = true
    },
    endLoading: state => {
      state.isLoading = false
    },
    handleApiError: (
      state,
      action: PayloadAction<{
        statusCode: number
        message?: string
      }>
    ) => {},
    showDialog: (state, action: PayloadAction<{ message: string }>) => {
      state.isOpenDialog = true
      state.message = action.payload.message
    },
    closeDialog: state => {
      state.isOpenDialog = false
      state.message = ''
    },
  },
})

export const {
  setLoading,
  startLoading,
  endLoading,
  handleApiError,
  showDialog,
  closeDialog,
} = apiHandler.actions

export type ApiHandlerAction =
  | ReturnType<typeof setLoading>
  | ReturnType<typeof startLoading>
  | ReturnType<typeof endLoading>
  | ReturnType<typeof handleApiError>
  | ReturnType<typeof showDialog>
  | ReturnType<typeof closeDialog>

export default apiHandler.reducer
