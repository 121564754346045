import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetCdpUserThirdPartyTagListQuery } from '@shared/api/rtkQuery'
import { Card } from '@shared/ui'
import SectionTitle from '@shared/ui/SectionTitle'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import TagDetail from './TagDetail'

const ThirdParty = () => {
  const { t } = useTranslation(['tag', 'userProfile', 'common'])
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const {
    data: thirdPartyTagData = { thirdPartyTags: null },
    isFetching: isThirdPartyTagDataFetching,
  } = useGetCdpUserThirdPartyTagListQuery({ cdpUserId })

  const hasNoData =
    (thirdPartyTagData.thirdPartyTags ?? []).length === 0 &&
    !isThirdPartyTagDataFetching

  if (isThirdPartyTagDataFetching) {
    return (
      <Box mb={5}>
        <SectionTitle title={t('tag:tag_source.third_party')} />
        <LoadingSkeleton height={125} />
      </Box>
    )
  }

  return (
    <Box>
      <SectionTitle title={t('tag:tag_source.third_party')} />
      {hasNoData && (
        <Card mb={5}>
          <Box
            fontSize={15}
            lineHeight="21px"
            fontWeight={500}
            sx={theme => ({
              color: theme.colors.textSecondBlue,
            })}
          >
            {t('common:empty_data')}
          </Box>
        </Card>
      )}

      {(thirdPartyTagData.thirdPartyTags ?? []).map(thirdPartyTag => (
        <Card key={thirdPartyTag.name} mb={5}>
          <Box fontSize={16} lineHeight="24px" fontWeight={600} mb={2}>
            {thirdPartyTag.name}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
            gap={1}
          >
            {thirdPartyTag.tags.map(item => (
              <TagDetail key={item} name={item} />
            ))}
          </Box>
        </Card>
      ))}
    </Box>
  )
}

export default ThirdParty
