import { api, transformResponseCamelCaseKeys } from './api'

type LineOA = {
  name: string
  displayName: string
}

const lineOA = api.injectEndpoints({
  endpoints: builder => ({
    getLineOaList: builder.query<LineOA[], void>({
      query: () => '/line_oas',
      transformResponse: res => transformResponseCamelCaseKeys(res as LineOA[]),
    }),
  }),
  overrideExisting: false,
})

export const { useGetLineOaListQuery } = lineOA
