import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { LinkTypes } from '@entities/mediumTemplates'
import { useGetTracingUrlListQuery } from '@shared/api/rtkQuery'
import getTracingUrlId from '@shared/lib/utils/getTracingUrlId'
import { TRACING_URL_WORD } from '@shared/model/constants/contentTemplate'

export const useLinkTypeConfig = () => {
  const { t } = useTranslation(['contentTemplate'])
  const { data: tracingUrlList, isFetching } = useGetTracingUrlListQuery()

  const linkTypes: LinkTypes = useMemo(
    () => [
      {
        name: 'web',
        label: t('contentTemplate:tracing_url.editors.email.label_web'),
        attrs: {
          // 這樣在輸出 HTML 時會被轉成 {{tracing_url1}}，後續交給 fetchTracingUrl 處理
          href: `{{{{${TRACING_URL_WORD}}}}}`,
          target: '_blank',
        },
        fields: [
          {
            name: TRACING_URL_WORD,
            label: t('contentTemplate:tracing_url.editors.email.label_web'),
            defaultValue: '',
            inputType: 'select',
            placeholderText: undefined,
            options: tracingUrlList?.map(item => ({
              label: item.name,
              value: getTracingUrlId(item.id),
            })),
          },
        ],
      },
      {
        name: 'origin_url',
        label: t('contentTemplate:tracing_url.editors.email.label_url'),
        attrs: { href: '{{url}}', target: '_blank' },
        fields: [
          {
            name: 'url',
            label: t('contentTemplate:tracing_url.editors.email.label_url'),
            defaultValue: '',
            inputType: 'url',
          },
        ],
      },
      {
        name: 'phone',
        label: '',
        enabled: false,
      },
      {
        name: 'email',
        label: '',
        enabled: false,
      },
      {
        name: 'sms',
        label: '',
        enabled: false,
      },
    ],
    [t, tracingUrlList]
  )

  return { linkTypes, isLoadingLinkTypeConfig: isFetching }
}

export default useLinkTypeConfig
