import styled from '@emotion/styled'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

import BaseCard from '@shared/ui/Card'
import { Checkbox as BaseCheckbox } from '@shared/ui/CheckboxList'

import {
  CONTROL_SECTION_MARGIN_LEFT,
  CONTROL_SECTION_WIDTH,
} from './models/constants'

export const Container = styled.section`
  position: relative;
`

export const LoadingWrapper = styled.div<{ isDisabled: boolean }>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'initial')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  transition: opacity 0.5s;
`

export const Loading = styled(
  ({ isShow, ...otherProps }: CircularProgressProps & { isShow: boolean }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <CircularProgress {...otherProps} />
  )
)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: ${({ isShow }) => (isShow ? 'initial' : 'none')};
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  z-index: 1;
  transition: opacity 0.5s;
`

export const Card = styled(BaseCard)``

export const HorizontalLine = styled.div`
  margin: 16px 0;

  border: 1px solid ${({ theme }) => theme.colors.black6};
`

export const ChartSection = styled.div`
  width: calc(100% - ${CONTROL_SECTION_WIDTH + CONTROL_SECTION_MARGIN_LEFT}px);
  min-height: 365px;
`

export const ControlSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  margin-top: 29px;
  margin-left: ${CONTROL_SECTION_MARGIN_LEFT}px;
  padding-top: 4px;
  width: ${CONTROL_SECTION_WIDTH}px;
  max-height: 300px;
  border-top: 2px solid ${({ theme }) => theme.colors.black6};

  /* 為了讓 Sidebar 開闔時 Chart 的中間變化狀態不會看起來像破版 */
  background-color: ${({ theme }) => theme.colors.white};
  /* 高於 ChartSection 即可 */
  z-index: 2;
`

export const ItemCountWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-bottom: -10px;
  width: ${CONTROL_SECTION_WIDTH}px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;

  margin-right: 8px;
  margin-bottom: 4px;

  cursor: pointer;
`

export const Checkbox = styled(BaseCheckbox)`
  &.MuiButtonBase-root {
    margin-left: -3px;
    padding-right: 3px;
    padding-left: 0px;
  }
`

export const Label = styled.div<{ isVisible: boolean }>`
  overflow: hidden;

  max-width: ${CONTROL_SECTION_WIDTH}px;

  color: ${({ isVisible, theme }) =>
    isVisible ? theme.colors.textPrimaryBlue : theme.colors.cloudyBlue};
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Circle = styled.div<{ bgColor?: string; isVisible?: boolean }>`
  flex-shrink: 0;

  margin-right: 8px;
  width: 12px;
  height: 12px;

  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => bgColor || theme.colors.white};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0.4)};
`

export const Figcaption = styled.figcaption`
  display: flex;
  align-items: flex-start;
  margin: 4px 0;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`

export const List = styled.ul`
  overflow: hidden;
`

export const Figure = styled.figure<{ open: boolean }>`
  ${Figcaption} + ${List} {
    margin-left: 20px;
    transition: height 0.25s;
  }

  ${List} {
    height: ${({ open }) => (open ? '100%' : 0)};
  }
`

export const RadioButton = styled.div<{
  isActive?: boolean
  isDisabled?: boolean
}>`
  display: flex;
  align-items: center;

  padding: 4px 8px;

  border-radius: 4px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.bgPrimaryGrey};
  color: ${({ isActive, isDisabled, theme }) =>
    isDisabled
      ? theme.colors.lightGreyBlue
      : isActive
      ? theme.colors.brightBlue
      : theme.colors.textSecondBlue};

  cursor: pointer;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};

  :hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brightBlue};

    /* icon */
    div {
      color: ${({ theme }) => theme.colors.brightBlue};
    }
  }
`

export const RadioButtonWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 8px;

  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
`
