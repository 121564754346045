import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import type { TFunction } from 'i18next'

import { SEGMENT_STYLE_CONFIG } from '@entities/rfm/model/constants'
import type { Rfm, SegmentType } from '@shared/api/rtkQuery'
import { formatNumber } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { SharedIcon } from '@shared/ui/icons'
import type { TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'

const columns = (
  tableSource: string,
  t: TFunction<'rfm'[]>
): TableColumn<Rfm['rfmGroups'][number]>[] => [
  {
    headerName: 'segmentation',
    field: 'name',
    colRatio: 1.25,
    valueFormatter: value => {
      const themeColor = SEGMENT_STYLE_CONFIG.find(
        ({ segmentType }) => segmentType === value
      )?.themeColor

      return (
        <Box display="flex" alignItems="center">
          <Box
            mr={2}
            width={16}
            height={16}
            sx={{
              backgroundColor: themeColor,
            }}
          />
          <Box
            component="span"
            fontWeight={500}
            fontSize={16}
            lineHeight="22px"
            color={theme => theme.colors.black}
            mr={2}
          >
            {t(`rfm:segment.${value as SegmentType}`)}
          </Box>
          <Tooltip title={t('rfm:export_to_audience')}>
            <Link
              to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}/new?template_id=rfm&template_params={"source":"${tableSource}","value":["${value}"]}`}
            >
              <Box
                width={32}
                height={32}
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  },
                }}
              >
                <SharedIcon />
              </Box>
            </Link>
          </Tooltip>
        </Box>
      )
    },
  },
  {
    headerName: 'user_count',
    field: 'userCount',
    isSortable: true,
    colRatio: 0.5,
    align: 'right',
    valueFormatter: value => formatNumber(value as number),
  },
  {
    headerName: 'arpu',
    field: 'arpu',
    // 目前沒有值可排序
    // isSortable: true,
    colRatio: 0.25,
    align: 'right',
    valueFormatter: value =>
      String(value) !== 'undefined' ? formatNumber(value as number) : '-',
  },
  {
    headerName: 'avg_pltv',
    field: 'avgPltv180D',
    // 目前沒有值可排序
    // isSortable: true,
    colRatio: 0.5,
    align: 'right',
    valueFormatter: value =>
      String(value) !== 'undefined' ? formatNumber(value as number) : '-',
  },
]

export default columns
