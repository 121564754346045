import { Link } from 'react-router-dom'

import type { WorkflowSubscriptionInsight } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import type { TableColumn } from '@shared/ui/table'

const columns = (): TableColumn<WorkflowSubscriptionInsight>[] => [
  {
    headerName: 'name',
    field: 'id',
    colRatio: 0.35,
    valueFormatter: (id, { name }) => (
      <Link
        to={`/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.workflowInsight}/${id}`}
      >
        {name}
      </Link>
    ),
  },
  {
    headerName: 'last_enabled_at',
    field: 'lastEnabledAt',
    colRatio: 0.15,
    valueFormatter: value => formatDateTimeDisplay(value),
  },
]

export default columns
