import { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import type { ProductList } from '@shared/api/rtkQuery'
import {
  INITIAL_PAGINATION_RESPONSE,
  useGetProductsListQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'

const PER_PAGE = DEFAULT_PER_PAGE * 2
const SCROLL_DEBOUNCE_TIME = 2000

const useProductList = () => {
  const [search, setSearch] = useState<string>('')
  const [page, setPage] = useState<number>(DEFAULT_PAGE)
  const [productList, setProductList] = useState<ProductList['items']>([])

  const { data = INITIAL_PAGINATION_RESPONSE, ...getProductsListQueryStatus } =
    useGetProductsListQuery({
      page,
      perPage: PER_PAGE,
      name: search,
    })

  const hasMore = useMemo(() => {
    const { totalCount } = data
    const pageLimit = Math.ceil(totalCount / PER_PAGE)
    return page < pageLimit && totalCount > 0
  }, [data, page])

  const onSearchByName = (value: string) => {
    setProductList([])
    setPage(DEFAULT_PAGE)
    setSearch(value)
  }

  const onLoadMore = useMemo(
    () =>
      debounce(() => {
        if (!hasMore) {
          return
        }
        setPage(page + 1)
      }, SCROLL_DEBOUNCE_TIME),
    [hasMore, page]
  )

  useEffect(() => {
    if (!data?.items.length) {
      return
    }
    setProductList(prev => [...prev, ...data.items])
  }, [data])

  return {
    ...getProductsListQueryStatus,
    data: productList,
    totalCount: data.totalCount,
    search,
    hasMore,
    onSearchByName,
    onLoadMore,
  }
}

export default useProductList
