import styled from '@emotion/styled'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import ListItem, { ListItemProps } from '@mui/material/ListItem'

import { LAYOUT } from '@shared/model/constants/styles'

export type StyledListItemProps = {
  isSidebarOpen: boolean
  isActive: boolean
  isNested?: boolean
}

const getListItemPaddingLeft = (isSidebarOpen: boolean, isNested?: boolean) => {
  if (isNested) {
    return LAYOUT.listItemPaddingLeftNested
  }
  return isSidebarOpen
    ? LAYOUT.listItemPaddingLeft
    : LAYOUT.listItemPaddingLeftSidebarOpen
}

export const StyledListItem = styled(
  ({
    isSidebarOpen,
    isActive,
    isNested,
    ...otherProps
  }: // workaround: https://github.com/mui/material-ui/issues/16245
  StyledListItemProps & ListItemProps<'div', { button: true }>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListItem {...otherProps} />
  )
)`
  && {
    overflow: hidden;

    padding-right: ${LAYOUT.listItemPaddingRight}px;
    padding-left: ${({ isSidebarOpen, isNested }) =>
      getListItemPaddingLeft(isSidebarOpen, isNested)}px;

    width: ${({ isSidebarOpen }) =>
      isSidebarOpen ? LAYOUT.sidebarWidth : LAYOUT.sidebarWidthCollapse}px;
    height: ${({ isNested }) =>
      isNested ? LAYOUT.listItemHeightNested : LAYOUT.listItemHeight}px;
    border-left: ${LAYOUT.listItemBorderLeft}px solid transparent;
    border-left-color: ${({ isActive, isNested, theme }) =>
      isActive && isNested ? theme.colors.brightBlue : 'transparent'};
    background-color: ${({ isActive, isNested, theme }) =>
      isActive && isNested ? theme.colors.bgPaleGrey : 'inherit'};
  }
`

export const StyledListItemText = styled.div<{
  isActive: boolean
}>`
  margin-top: 4px;
  margin-right: 8px;
  margin-bottom: 4px;
  min-width: 0;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brightBlue : theme.colors.lightNavyBlue};

  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  letter-spacing: normal;
  line-height: normal;

  white-space: break-spaces;
`

export type StyledSidebarProps = {
  isSidebarOpen: boolean
}

const getSidebarTransitionStyle = (isSidebarOpen: boolean) =>
  isSidebarOpen
    ? `
      width: ${LAYOUT.sidebarWidth}px;
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    `
    : `
      overflow-x: hidden;
      transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      width: ${LAYOUT.sidebarWidthCollapse}px;
    `

// 直取 material-ui 的 classname 覆寫
export const StyledSidebar = styled(
  ({ isSidebarOpen, ...otherProps }: StyledSidebarProps & DrawerProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Drawer {...otherProps} />
  )
)`
  flex-shrink: 0;
  white-space: nowrap;

  ${({ isSidebarOpen }) => getSidebarTransitionStyle(isSidebarOpen)}

  .MuiDrawer-paper {
    ${({ isSidebarOpen }) => getSidebarTransitionStyle(isSidebarOpen)}
  }

  .MuiDrawer-paperAnchorDockedLeft {
    border: none;
    box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.05);
  }
`
