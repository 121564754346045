import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatCurrency, getIntSafe } from '@shared/lib/utils/number'
import type { TableColumn } from '@shared/ui/table'
import type { UsageDataType } from '@widgets/usageManagement/SendingAmount/lib/helper'
import { TABLE_DATE_COLUMN_RATION } from '@widgets/usageManagement/SendingAmount/model/constant'

const useColumns = () => {
  const { t } = useTranslation(['common'])

  const columns: TableColumn<UsageDataType>[] = useMemo(
    () => [
      { headerName: 'date', field: 'date', colRatio: TABLE_DATE_COLUMN_RATION },
      {
        headerName: 'email_sent_counts',
        field: 'totalEmailCounts',
        align: 'right',

        valueFormatter: value =>
          t('common:unit.sms_email_count_number', {
            value: getIntSafe(value, '-').toLocaleString(),
          }),
      },
      {
        headerName: 'sms_reception_counts',
        align: 'right',
        field: 'totalSmsCounts',
        valueFormatter: value =>
          t('common:unit.sms_email_count_number', {
            value: getIntSafe(value, '-').toLocaleString(),
          }),
      },
      {
        headerName: 'subtotal',
        align: 'right',
        field: 'totalAmount',
        valueFormatter: value => (
          <Box fontWeight={700} sx={theme => ({ color: theme.colors.black })}>
            {formatCurrency(value ?? 0)}
          </Box>
        ),
      },
    ],
    [t]
  )

  return columns
}

export default useColumns
