import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import Chip from '@entities/mediumTemplates/ui/SmsEditor/_shared/Chip'

import { DELETE_METADATA_TAG_COMMAND } from '../MetadataTagPlugin'

export type Props = {
  displayName: string
  replacementKey: string
  nodeKey: string
}

const MetadataTagComponent = ({
  displayName,
  replacementKey, // 主要是為了在取得decorator的時候可以取到replacementKey, 邏輯和utmNode一致
  nodeKey,
}: Props): JSX.Element => {
  const [editor] = useLexicalComposerContext()

  const handleDelete = () => {
    editor.dispatchCommand(DELETE_METADATA_TAG_COMMAND, {
      nodeKey,
      replacementKey,
    })
  }

  return (
    <Chip disabled={false} onDelete={handleDelete}>
      {displayName}
    </Chip>
  )
}

export default MetadataTagComponent
