import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import TrailDataImportImgUrl from '@shared/assets/images/trial/trialDataImport.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { CATEGORY, PAGE_MODE, PAGE_ROOT } from '@shared/model/constants/routes'
import TrialFeatureCover from '@shared/ui/TrialFeatureCover'

import ImportDimensions from './ImportDimensions'
import ImportEvents from './ImportEvents'
import ImportUsers from './ImportUsers'

export const DataImportRoutes = () => {
  const { path } = useRouteMatch()
  const { t } = useTranslation(['common'])

  const isDataImportDisable = useAppSelector(
    state => state.auth.plan.dataImport.disabled
  )

  return isDataImportDisable ? (
    <TrialFeatureCover
      title={t('common:route.category.data_import')}
      description={t('common:trial.data_import.description')}
      notice={t('common:trial.data_import.notice')}
      bgImageUrl={TrailDataImportImgUrl}
    />
  ) : (
    <Switch>
      <Route path={`${path}/${PAGE_ROOT.v3.importUsers}`}>
        <ImportUsers />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.v3.importEvents}`}>
        <ImportEvents />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.v3.importDimensionTables}`}>
        <ImportDimensions />
      </Route>

      <Route path={`${path}/${PAGE_ROOT.v3.importTags}`}>
        <Redirect
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${PAGE_MODE.import}`}
        />
      </Route>

      <Redirect to={`${path}/${PAGE_ROOT.v3.importUsers}`} />
    </Switch>
  )
}

export default DataImportRoutes
