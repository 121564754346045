import { useMemo, useRef } from 'react'
import { FormikValues, useFormik } from 'formik'
import { object, ObjectSchema } from 'yup'

import { FormField } from '../models/types'

type UseFormBuilder<T> = {
  fields: FormField<T>[]
  initialValues: T
  onSubmit: (values: T) => void
  validationSchema?: ObjectSchema<FormikValues>
}

export const useFormBuilder = <T extends FormikValues>({
  fields,
  initialValues,
  onSubmit,
  validationSchema,
}: UseFormBuilder<T>) => {
  const fieldRef = useRef(fields)
  const validateSchemaRef = useRef(validationSchema)

  const fieldsSchema = useMemo(() => {
    const fieldSchema = fieldRef.current.reduce((schema, field) => {
      if (field.validateSchema) {
        return schema.shape({
          [field.name]: field.validateSchema,
        })
      }

      return schema
    }, validateSchemaRef.current?.clone() ?? object({}))

    return fieldSchema
  }, [])

  const {
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    submitForm,
    touched,
    values,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: fieldsSchema,
    onSubmit,
  })

  return {
    errors,
    handleChange,
    handleFieldChange: setFieldValue,
    handleFormSubmit: handleSubmit,
    resetForm,
    submitForm,
    touched,
    values,
  }
}

export default useFormBuilder
