import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useCreateEventRuleMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import { AnalyticsFormBreadcrumbs as EventFormBreadcrumbs } from '@widgets/analytics/_shared'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import type { EventRuleFormValues } from '../_shared/types'
import EventRuleForm from '../_shared/ui/EventRuleForm'

const EventRuleCreate = () => {
  const { t } = useTranslation(['analytics', 'common'])

  const [createEventRule, { isLoading: isCreatingEventRule }] =
    useCreateEventRuleMutation()

  const dispatch = useAppDispatch()
  const { handleError } = useErrorHandler({ type: 'event' })

  const handleSubmit = async (formValues: EventRuleFormValues) => {
    try {
      await createEventRule(formValues).unwrap()

      dispatch(
        openToast({
          message: t('analytics:event.save_success'),
        })
      )
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <EventRuleForm
      header={({
        isExportingCSV,
        isExportDisabled,
        isSaveDisabled,
        onReset: handleReset,
        onExport: handleExport,
        onShowConfirmDialog: handleShowConfirmDialog,
      }) => (
        <EventFormBreadcrumbs
          isExportDisabled={isExportDisabled}
          isExportingCSV={isExportingCSV}
          isSaveDisabled={isSaveDisabled}
          onExport={handleExport}
          onReset={handleReset}
          onShowConfirmDialog={handleShowConfirmDialog}
        >
          <Link to={`/${CATEGORY.analytics}/${PAGE_ROOT.events}/new`}>
            {t('common:route.analytics_events')}
          </Link>

          <Box>{t('common:create')}</Box>
        </EventFormBreadcrumbs>
      )}
      isSubmitting={isCreatingEventRule}
      onSubmit={handleSubmit}
    />
  )
}

export default EventRuleCreate
