import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import type { ParseKeys } from 'i18next'
import pipe from 'lodash/fp/pipe'
import range from 'lodash/range'

import type {
  ExportAudienceRuleUserGoogleAds,
  SchedulingType,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import { formatExportAudienceRuleTitle } from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport/_shared'

const DESCRIPTION_MAX_LENGTH = 100
const UPDATE_PERIOD_DAILY_MIN_VALUE = 1

export type FormValues = Pick<
  ExportAudienceRuleUserGoogleAds,
  'name' | 'description' | 'updatePolicy' | 'updatePeriodType' | 'updatePeriod'
>

type Props = {
  isOpen: boolean
  isSubmitting: boolean
  listName: string
  schedulingType: SchedulingType
  onCancel: () => void
  onSubmit: (values: FormValues) => void
}

const GoogleAdsCreateDialog = ({
  isOpen,
  isSubmitting,
  listName,
  schedulingType,
  onCancel: handleCancel,
  onSubmit,
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])

  const weeklyOptions = useMemo<Option[]>(
    () =>
      range(0, 7).map(i => ({
        label: t(`common:week.${i}` as ParseKeys<['common']>) as string,
        value: i,
      })),
    [t]
  )

  const monthlyOptions = useMemo<Option[]>(
    () =>
      // Spec 只顯示 1 ~ 28 日
      range(1, 29)
        .map(i => ({
          label: t('common:month.n_day', { day: i }),
          value: i,
        }))
        .concat([{ label: t('common:month.last_day'), value: -1 }]),
    [t]
  )

  const formik = useFormik<
    FormValues & {
      // UI 拆成三個欄位，為了避免互相影響使用三個狀態保存值
      updatePeriod_daily?: number
      updatePeriod_weekly?: number
      updatePeriod_monthly?: number
    }
  >({
    initialValues: {
      name: formatExportAudienceRuleTitle({
        title: listName,
        isDynamic: schedulingType === 'default',
      }),
      description: '',
      ...(schedulingType === 'default'
        ? {
            updatePolicy: 'replace',
            updatePeriodType: 'daily',
            updatePeriod_daily: 1,
            updatePeriod_weekly: 0,
            updatePeriod_monthly: 1,
          }
        : undefined),
    },
    validate: values => {
      const errors: Partial<FormValues> = {}

      if (values.description.length > DESCRIPTION_MAX_LENGTH) {
        errors.description = t('audience:export_dialog_limit_word_length')
      }

      return errors
    },
    onSubmit: values => {
      onSubmit({
        name: values.name,
        description: values.description,
        updatePolicy: values.updatePolicy,
        updatePeriodType: values.updatePeriodType,
        updatePeriod: values.updatePeriodType
          ? values[`updatePeriod_${values.updatePeriodType}`]
          : undefined,
      })
    },
  })

  return (
    <ConfirmDialog
      modalTitle={t('audience:export_dialog_title_google_ads', { listName })}
      isOpen={isOpen}
      isLoading={isSubmitting}
      onClose={handleCancel}
      contentOverflowY="visible"
      onConfirm={() => formik.submitForm()}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'minmax(80px, auto) 1fr',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            color: theme => theme.colors.textPrimaryBlue,
            fontWeight: 'medium',
          }}
        >
          {t('common:name')}
        </Box>

        <Box
          component="span"
          sx={{ color: theme => theme.colors.textSecondBlue }}
        >
          {formik.values.name}
        </Box>

        <Box
          sx={{
            color: theme => theme.colors.textPrimaryBlue,
            fontWeight: 'medium',
            alignSelf: 'start',
          }}
        >
          {t('common:description')}
        </Box>

        <TextField
          autoFocus
          fullWidth
          helperText={t('audience:export_dialog_limit_word_length')}
          margin="dense"
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          name="description"
          placeholder={t('audience:export_dialog_input_placeholder')}
          sx={{
            mt: -0.5,
            input: {
              fontSize: 14,
              '::placeholder': {
                color: theme => theme.colors.textSecondBlue,
                fontSize: 14,
              },
            },
          }}
          value={formik.values.description}
          variant="standard"
          onChange={formik.handleChange}
        />

        {schedulingType === 'default' && (
          <>
            <Box
              sx={{
                color: theme => theme.colors.textPrimaryBlue,
                fontWeight: 'medium',
              }}
            >
              {t('audience:export.update_policy')}
            </Box>

            <RadioGroup
              name="updatePolicy"
              row
              value={formik.values.updatePolicy}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="replace"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('audience:export.update_policy_replace')}
                    &nbsp;
                    <Tooltip
                      title={t('audience:export.update_policy_replace_tooltip')}
                    >
                      <span>
                        <Icon icon={ICON.infoCircle} fontSize="small" />
                      </span>
                    </Tooltip>
                  </Box>
                }
              />

              <FormControlLabel
                value="add"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('audience:export.update_policy_add')}
                    &nbsp;
                    <Tooltip
                      title={t('audience:export.update_policy_add_tooltip')}
                    >
                      <span>
                        <Icon icon={ICON.infoCircle} fontSize="small" />
                      </span>
                    </Tooltip>
                  </Box>
                }
              />
            </RadioGroup>

            <Box
              sx={{
                color: theme => theme.colors.textPrimaryBlue,
                fontWeight: 'medium',
                alignSelf: 'start',
                mt: 1,
              }}
            >
              {t('audience:export.update_period')}
            </Box>

            <RadioGroup
              name="updatePeriodType"
              value={formik.values.updatePeriodType}
              onChange={formik.handleChange}
              sx={{ '& > .MuiFormControlLabel-root': { mb: 1 } }}
            >
              <FormControlLabel
                value="daily"
                control={<Radio color="primary" size="small" />}
                label={
                  // 每 <Day /> 天
                  <Trans
                    ns="audience"
                    i18nKey="export.update_period_daily"
                    components={{
                      Day: (
                        <PrimaryGreyInput
                          name="updatePeriod_daily"
                          value={formik.values.updatePeriod_daily}
                          type="number"
                          width={80}
                          marginLeftRatio={1}
                          marginRightRatio={1}
                          onChange={event => {
                            const period = pipe(
                              (value: unknown) =>
                                getIntSafe(
                                  value,
                                  UPDATE_PERIOD_DAILY_MIN_VALUE
                                ),
                              (value: number) =>
                                Math.max(value, UPDATE_PERIOD_DAILY_MIN_VALUE)
                            )(event.target.value)

                            formik.setFieldValue('updatePeriod_daily', period)
                          }}
                        />
                      ),
                    }}
                  />
                }
              />

              <>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" size="small" />}
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {t('audience:export.update_period_weekly')}
                      &nbsp;
                      <Icon
                        icon={ICON.schedule}
                        color={theme.colors.brightBlue}
                      />
                      &nbsp;
                      <DropdownList
                        options={weeklyOptions}
                        value={formik.values.updatePeriod_weekly}
                        onValueChanged={option =>
                          formik.setFieldValue(
                            'updatePeriod_weekly',
                            +option.value
                          )
                        }
                        popperDisablePortal
                      />
                    </Box>
                  }
                />
              </>

              <FormControlLabel
                value="monthly"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {t('audience:export.update_period_monthly')}
                    &nbsp;
                    <DropdownList
                      options={monthlyOptions}
                      value={formik.values.updatePeriod_monthly}
                      onValueChanged={option =>
                        formik.setFieldValue(
                          'updatePeriod_monthly',
                          +option.value
                        )
                      }
                      popperDisablePortal
                    />
                  </Box>
                }
              />
            </RadioGroup>
          </>
        )}
      </Box>
    </ConfirmDialog>
  )
}

export default GoogleAdsCreateDialog
