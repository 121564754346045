import Box from '@mui/material/Box'

import SectionAnalytics from './SectionAnalytics'
import SectionEventReport from './SectionEventReport'
import SectionProfile from './SectionProfile'

const Overview = () => {
  return (
    <Box sx={{ display: 'grid', gap: 5 }}>
      <SectionProfile />
      <SectionEventReport />
      <SectionAnalytics />
    </Box>
  )
}

export default Overview
