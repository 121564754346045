import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  POLLING_INTERVAL,
  useGetAudienceRuleUserExportListFacebookAdsQuery,
} from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import {
  checkIsNeedPollingExportInfo,
  ExportActionButtons,
  UPDATE_POLICY_TEXT_MAP,
} from '@widgets/audience/ui/AudienceDetail/AudienceDetailExport/_shared'

import useIsValid from '../../_shared/utils/useIsValid'
import type { FacebookAdAccount } from '../utils'
import useFormatFacebookAdsExportStatus from './useFormatFacebookAdsExportStatus'
import { formatFacebookAdsExportList } from './utils'

type Props = {
  accountList: FacebookAdAccount[]
  audienceRuleId: number
  onExport: (payload: {
    accountId: string
    exportId?: number | undefined
    listName?: string
  }) => void
}

const FacebookAdsExportList = ({
  accountList,
  audienceRuleId,
  onExport = () => {},
}: Props) => {
  const [isNeedPolling, setIsNeedPolling] = useState<boolean>(false)

  const facebookAuth = useAppSelector(state => state.auth.facebook)

  const isAccessTokenValid = useIsValid(facebookAuth.expirationTime)

  const { data: exportList } = useGetAudienceRuleUserExportListFacebookAdsQuery(
    { audienceRuleId, facebookAccessToken: facebookAuth.accessToken },
    {
      pollingInterval: isNeedPolling ? POLLING_INTERVAL : undefined,
      skip: !isAccessTokenValid,
    }
  )

  const exportListByCustomerId = useMemo(
    () => formatFacebookAdsExportList(accountList, exportList),
    [exportList, accountList]
  )

  useEffect(() => {
    if (exportListByCustomerId.length === 0) {
      return
    }

    setIsNeedPolling(
      exportListByCustomerId.some(x => checkIsNeedPollingExportInfo(x.status))
    )
  }, [exportListByCustomerId])

  const formatFacebookAdsExportStatus = useFormatFacebookAdsExportStatus()

  const { t } = useTranslation(['common', 'audience'])

  if (exportListByCustomerId.length === 0) {
    return null
  }

  return (
    <Box sx={{ borderTop: theme => `1px solid ${theme.colors.black6}` }}>
      {exportListByCustomerId.map(
        ({
          accountId,
          accountName,
          failCount,
          fbError,
          id,
          status = 'none',
          name,
          successCount,
          updatedAt,
          updatePolicy,
        }) => {
          const exportStatusDesc = formatFacebookAdsExportStatus(
            status,
            updatedAt,
            fbError
          )

          return (
            <Box
              key={accountId}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 2,
                minHeight: 88,
                borderBottom: theme => `1px solid ${theme.colors.black6}`,
              }}
            >
              <Box sx={{ flexGrow: 1, fontWeight: 500, mr: 4 }}>
                <p>
                  {accountId}
                  <Box
                    component="span"
                    sx={{ color: theme => theme.colors.brightBlue, ml: 2 }}
                  >
                    {accountName}
                  </Box>
                </p>

                {status === 'completed' && (
                  <>
                    {updatePolicy && (
                      <Box sx={{ mt: 1 }}>
                        {t('audience:export.update_policy')}
                        {t('common:punctuation.colon')}

                        <Box
                          sx={{ display: 'inline-flex', alignItems: 'center' }}
                        >
                          {t(UPDATE_POLICY_TEXT_MAP.LABEL[updatePolicy])}
                          &nbsp;
                          <Tooltip
                            title={
                              t(
                                UPDATE_POLICY_TEXT_MAP.TOOLTIP[updatePolicy]
                              ) as string
                            }
                          >
                            <span>
                              <Icon icon={ICON.infoCircle} fontSize="small" />
                            </span>
                          </Tooltip>
                        </Box>
                      </Box>
                    )}

                    {Number.isInteger(successCount) && (
                      <Box component="p" sx={{ mt: 1 }}>
                        {t('audience:export_counts_succeed')}
                        {t('common:punctuation.colon')}
                        {successCount}
                      </Box>
                    )}

                    {Number.isInteger(failCount) && (
                      <Box component="p" sx={{ mt: 1 }}>
                        {t('audience:export_counts_failure')}
                        {t('common:punctuation.colon')}
                        {failCount}
                      </Box>
                    )}
                  </>
                )}

                {exportStatusDesc && (
                  <Box
                    component="p"
                    sx={{ mt: 1, color: theme => theme.colors.textSecondBlue }}
                  >
                    {exportStatusDesc}
                  </Box>
                )}
              </Box>

              <ExportActionButtons
                status={status}
                // 目前 Facebook Ads 不支援自動匯出
                schedulingType={'none'}
                onExport={() =>
                  onExport({ accountId, exportId: id, listName: name })
                }
                onRetry={() =>
                  onExport({ accountId, exportId: id, listName: name })
                }
              />
            </Box>
          )
        }
      )}
    </Box>
  )
}

export default FacebookAdsExportList
