import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SEGMENT_STYLE_CONFIG } from '@entities/rfm/model/constants'
import {
  useGetAudienceSummaryRfmQuery,
  useRfmListQuery,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import theme from '@theme'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { NO_DATA, NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'
import EmptyData from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/EmptyData'

import TreemapChart from './TreemapChart'

const Rfm = () => {
  const { t } = useTranslation(['audience', 'rfm'])
  const { audienceId } = useAudienceDetailState()
  const [rfmId, setRfmId] = useState<number>(NO_DATA_ID)

  const {
    rfmOptions,
    isRfmOptionsSuccess,
    isRfmOptionLoading,
    isRfmOptionUninitialized,
  } = useRfmListQuery(undefined, {
    skip: audienceId === NO_DATA_ID,
    selectFromResult: ({
      data = [],
      isSuccess,
      isLoading,
      isUninitialized,
    }) => ({
      rfmOptions: data.map(ele => {
        return {
          value: ele.id,
          label: ele.rfmDisplayName,
        }
      }),
      isRfmOptionsSuccess: isSuccess,
      isRfmOptionLoading: isLoading,
      isRfmOptionUninitialized: isUninitialized,
    }),
  })

  const {
    data: audienceSummaryRfmData,
    isError: isAudienceSummaryRfmError,
    isSuccess: isAudienceSummaryRfmSuccess,
    isFetching: isAudienceSummaryRfmFetching,
    isLoading: isAudienceSummaryRfmLoading,
    isUninitialized: isAudienceSummaryRfmUninitialized,
  } = useGetAudienceSummaryRfmQuery(
    { audienceId, rfmId },
    {
      skip: audienceId === NO_DATA_ID || rfmId === NO_DATA_ID,
    }
  )

  const chartTitle = useMemo(() => {
    if (!audienceSummaryRfmData) {
      return ''
    }

    return audienceSummaryRfmData.orderedByCountSummary[0].groupName === NO_DATA
      ? t('audience:no_data')
      : t(
          `rfm:segment.${audienceSummaryRfmData.orderedByCountSummary[0].groupName}`
        )
  }, [audienceSummaryRfmData, t])

  useEffect(() => {
    if (rfmId === NO_DATA_ID && isRfmOptionsSuccess && rfmOptions.length > 0) {
      setRfmId(rfmOptions[0].value)
    }
  }, [isRfmOptionsSuccess, rfmId, rfmOptions])

  return (
    <BasicCard
      chartName="rfm"
      isSuccess={isAudienceSummaryRfmSuccess && isRfmOptionsSuccess}
      isLoading={
        isAudienceSummaryRfmLoading ||
        isRfmOptionLoading ||
        isRfmOptionUninitialized ||
        isAudienceSummaryRfmUninitialized
      }
      isError={
        isAudienceSummaryRfmError ||
        (isRfmOptionsSuccess && rfmOptions.length === 0)
      }
      isFetching={isAudienceSummaryRfmFetching}
      subject={t('audience:main_group')}
      dropdownListNode={
        audienceSummaryRfmData ? (
          <DropdownList
            options={rfmOptions}
            value={rfmId}
            onValueChanged={option => {
              setRfmId(getIntSafe(option.value))
            }}
          />
        ) : (
          ''
        )
      }
      title={chartTitle}
      nonMembers={getIntSafe(
        audienceSummaryRfmData?.totalNonMembers,
        ''
      ).toLocaleString()}
    >
      {audienceSummaryRfmData ? (
        <TreemapChart
          data={audienceSummaryRfmData.orderedByCountSummary.map(ele => ({
            x:
              ele.groupName === NO_DATA
                ? t(`audience:${ele.groupName}`)
                : t(`rfm:segment.${ele.groupName}`),
            y: ele.count,
          }))}
          total={getIntSafe(audienceSummaryRfmData.totalMembers)}
          colors={
            audienceSummaryRfmData.orderedByCountSummary.map(ele => {
              const config = SEGMENT_STYLE_CONFIG.find(
                config => config.segmentType === ele.groupName
              )

              // 沒有在 RFM 裡面的是 NO_DATA，所以放 blueyGrey
              return config?.themeColor || theme.colors.blueyGrey
            }) || []
          }
        />
      ) : (
        <EmptyData />
      )}
    </BasicCard>
  )
}

export default Rfm
