import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import MuiCheckbox from '@mui/material/Checkbox'

import cloneDeep from '@shared/lib/utils/cloneDeep'
import { MenuItem, MenuList } from '@shared/ui/menu'

export type Option = { label: string; value: string; disabled?: boolean }

const List = styled(MenuList)<{ maxHeight: number }>`
  max-height: ${({ maxHeight }) => maxHeight}px;
`

export const CheckboxItem = styled(MenuItem)`
  && {
    padding: 0 32px 0 16px;
  }
`

export const Checkbox = styled(MuiCheckbox)<{ checked: boolean }>`
  &.MuiButtonBase-root.Mui-checked {
    color: ${({ checked, theme }) =>
      checked ? theme.colors.brightBlue : theme.colors.cloudyBlue};
  }
  &.MuiButtonBase-root.Mui-disabled {
    color: ${({ theme }) => theme.colors.black25};
  }
`

const OptionWrapper = styled.div`
  display: block;
  max-width: 1000px;
  white-space: pre-wrap;
  word-break: break-all;
`

type CheckboxListProps = {
  checkedValues: string[]
  hasSelectAll?: boolean
  maxHeight?: number
  onChange: (value: string[], current?: string) => void
  options: Option[]
  renderOption?: (option: Option) => string | ReactElement[]
  selectAllLabel?: string
}

const CheckboxList = ({
  checkedValues,
  hasSelectAll,
  maxHeight = 320,
  onChange,
  options,
  renderOption = option => option.label,
  selectAllLabel,
}: CheckboxListProps) => {
  const { t } = useTranslation(['common'])

  const handleToggle = (item: Option) => () => {
    const currentIndex = checkedValues.findIndex(value => value === item.value)
    const newChecked = cloneDeep(checkedValues)

    if (currentIndex === -1) {
      newChecked.push(item.value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    onChange(newChecked, item.value)
  }

  const handleSelectAll = () => {
    const newChecked =
      checkedValues.length === options.length ? [] : cloneDeep(options)

    onChange(newChecked.map(option => option.value))
  }

  return (
    <List maxHeight={maxHeight}>
      {hasSelectAll && (
        <CheckboxItem onClick={handleSelectAll}>
          <Checkbox
            checked={checkedValues.length > 0}
            color="primary"
            indeterminate={
              checkedValues.length > 0 &&
              checkedValues.length !== options.length
            }
          />
          {selectAllLabel || t('common:select_all')}
        </CheckboxItem>
      )}

      {options.map(option => (
        <CheckboxItem
          key={option.label}
          onClick={handleToggle(option)}
          disabled={option.disabled}
        >
          <Checkbox
            disabled={option.disabled}
            checked={
              checkedValues.findIndex(value => value === option.value) !== -1
            }
            name={`check_${option.value}`}
            color="primary"
          />
          <OptionWrapper>{renderOption(option)}</OptionWrapper>
        </CheckboxItem>
      ))}
    </List>
  )
}

export default CheckboxList
