import { createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import type { FunnelAnalyticsNode } from '@shared/api/rtkQuery'

export const selectFunnelStepEventNameList = createSelector(
  (filter: FunnelAnalyticsNode) => filter.funnelSteps,
  funnelSteps => funnelSteps.map(x => x.eventName),
  { memoizeOptions: { resultEqualityCheck: isEqual } }
)

export default selectFunnelStepEventNameList
