import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  type NodeType,
  useGetWorkflowNodesAvailabilityQuery,
} from '@shared/api/rtkQuery'
import FeatureBadge from '@shared/ui/FeatureBadge'

import { useNodeTypeMap } from './hooks'
import {
  Aside,
  AsideTitle,
  CategoryList,
  CategoryListItem,
  CategoryTitle,
  NodeList,
  NodeListItem,
} from './styles'
import { useWorkflowFormState } from './WorkflowFormStateContext'

type NodeCategory = {
  category: string
  nodeTypeList: NodeType[]
}

const DropMenu = () => {
  const formState = useWorkflowFormState()

  const onDragStart = (event: React.DragEvent, nodeType: string) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }

  const { t } = useTranslation(['workflow'])

  const { data: features } = useGetWorkflowNodesAvailabilityQuery()

  const nodeCategoryList: NodeCategory[] = useMemo(
    () => [
      {
        category: t('workflow:node_timing'),
        nodeTypeList: ['timing'],
      },
      {
        category: t('workflow:node_logic'),
        nodeTypeList: ['logic-event_filter', 'logic-group'],
      },
      {
        category: t('workflow:node_action'),
        nodeTypeList: features?.bestEngagement
          ? [
              'logic-best_channel',
              'action-send_email',
              'action-send_line_push',
              'action-send_sms',
              'action-publish_subscription',
            ]
          : [
              'action-send_email',
              'action-send_line_push',
              'action-send_sms',
              'action-publish_subscription',
            ],
      },
      {
        category: t('workflow:node_exit'),
        nodeTypeList: ['exit'],
      },
    ],
    [features?.bestEngagement, t]
  )

  const nodeTypeMap = useNodeTypeMap()

  return (
    <Aside>
      <AsideTitle>{t('workflow:node_menu')}</AsideTitle>
      <CategoryList>
        {nodeCategoryList.map(parent => (
          <CategoryListItem key={parent.category}>
            <CategoryTitle>{parent.category}</CategoryTitle>

            <NodeList>
              {parent.nodeTypeList.map(nodeType => (
                <NodeListItem
                  key={nodeType}
                  className="dndnode"
                  onDragStart={event => onDragStart(event, nodeType)}
                  draggable={formState.isEditable}
                >
                  <Box
                    component="img"
                    src={nodeTypeMap[nodeType].iconUrl}
                    alt="icon"
                    sx={{ mr: 2, maxWidth: 24 }}
                  />
                  {nodeTypeMap[nodeType].name}

                  {nodeTypeMap[nodeType]?.badgeText && (
                    <FeatureBadge sx={{ ml: 2 }}>
                      {nodeTypeMap[nodeType].badgeText}
                    </FeatureBadge>
                  )}
                </NodeListItem>
              ))}
            </NodeList>
          </CategoryListItem>
        ))}
      </CategoryList>
    </Aside>
  )
}

export default DropMenu
