import Box from '@mui/material/Box'

import theme from '@theme'

import BasicInput, { Props } from './BasicInput'

const SearchRoundedBar = ({ defaultValue, placeholder, onSearch }: Props) => (
  <Box
    sx={{
      width: '100%',
      form: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        '& > span': {
          position: 'absolute',
          right: '16px',
        },
      },
      button: {
        width: '48px',
        height: '42px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
      input: {
        m: 1,
        p: '0 32px 0 16px',
        width: '100%',
        height: 32,
        border: '2px solid transparent',
        borderRadius: '16px',
        backgroundColor: theme.colors.veryLightBlueThree,
        color: theme.colors.black,
        fontSize: '12px',
        transition: 'border-color 0.25s',
        '::placeholder': {
          color: theme.colors.textPrimaryBlue,
          fontWeight: '500',
        },
        '::-moz-placeholder': {
          opacity: 1,
        },

        '&:focus': {
          borderColor: theme.colors.brightBlue,
          outline: 'none',
        },
      },
    }}
  >
    <BasicInput
      defaultValue={defaultValue}
      placeholder={placeholder}
      onSearch={onSearch}
    />
  </Box>
)

export default SearchRoundedBar
