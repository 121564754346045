import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import TagListDialog, { Option } from '@entities/dialogs/TagListDialog'
import { INPUT_WIDTH_MEDIUM } from '@features/filters/_shared/constants'
import type {
  AttributeOperatorUI,
  AttributeParams,
  MetadataType,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import DatePicker from '@shared/ui/DatePicker'
import { NumberRangeInput, PrimaryGreyInput } from '@shared/ui/inputs'
import type { ThemeColor } from '@theme'

import mapTargetValueWithMaxMin from './utils/mapTargetValueWithMaxMin'
import {
  calculateNumberInputStep,
  formatDateOrTimestamp,
  NOT_SET_DATE,
} from './utils'

const DatePickerLabel = styled.span`
  align-self: center;
  flex-shrink: 0;

  margin-right: 8px;
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-weight: 500;
`

type Props = {
  dataType: MetadataType
  operator: AttributeOperatorUI
  valueOptions: Option[]
  values?: AttributeParams
  bgColor?: ThemeColor
  maxLength?: number
  minLength?: number
  maxValue?: number
  minValue?: number
  isDisabled: boolean
  onChange: (value: AttributeParams) => void
}

const AttributeValueInput = ({
  dataType,
  operator,
  valueOptions,
  values,
  bgColor,
  maxLength,
  minLength,
  maxValue,
  minValue,
  isDisabled,
  onChange,
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])
  if (
    operator === 'is_null' ||
    operator === 'not_null' ||
    operator === 'is_empty' ||
    operator === 'not_empty' ||
    operator === 'is_true' ||
    operator === 'is_false' ||
    values === undefined
  ) {
    return null
  }

  if (dataType === 'INTEGER' || dataType === 'FLOAT') {
    if (operator === 'between') {
      const [start, end] = values
      return (
        <Box display="flex" alignItems="center">
          <NumberRangeInput
            values={[start, end]}
            bgColor={bgColor}
            startAdornment={<Box mr={1}>{t('audience:v3.between')}</Box>}
            isFloat={dataType === 'FLOAT'}
            min={minValue}
            max={maxValue}
            step={calculateNumberInputStep(maxValue)}
            isDisabled={isDisabled}
            onChange={values => {
              const isFloat = dataType === 'FLOAT'
              const targetValues = values.map(value =>
                mapTargetValueWithMaxMin({
                  input: value,
                  isFloat,
                  max: maxValue,
                  min: minValue,
                })
              )

              onChange(targetValues)
            }}
          />
        </Box>
      )
    }

    const currValue = (values as (number | '')[])[0]
    return (
      <PrimaryGreyInput
        type="number"
        value={currValue}
        bgColor={bgColor}
        max={maxValue}
        min={minValue}
        isError={currValue === ''}
        disabled={isDisabled}
        onChange={e => {
          const isFloat = dataType === 'FLOAT'
          const targetValue = mapTargetValueWithMaxMin({
            input: e.target.value,
            isFloat,
            max: maxValue,
            min: minValue,
          })

          onChange([targetValue])
        }}
      />
    )
  }

  if (dataType === 'STRING') {
    const currValue = (values as string[])[0]
    const currValueLength = currValue.length || 0
    return (
      <PrimaryGreyInput
        type="string"
        value={currValue as string}
        bgColor={bgColor}
        maxLength={maxLength}
        minLength={minLength}
        isError={minLength !== undefined && currValueLength < minLength}
        disabled={isDisabled}
        onChange={e => {
          onChange([e.target.value])
        }}
      />
    )
  }

  if (dataType === 'ENUM' && valueOptions.length > 0) {
    return (
      <TagListDialog
        options={valueOptions}
        values={values as string[]}
        bgColor={bgColor}
        isDisabled={isDisabled}
        isRequired
        onValueChanged={onChange}
      />
    )
  }

  // TODO: TIMESTAMP 加上時間選擇
  if (dataType === 'DATE' || dataType === 'TIMESTAMP') {
    if (operator === 'absolute_between') {
      const [startStr, endStr] = values as string[]

      // undefined 代表不在月曆上選擇任何日期
      const startDate =
        startStr !== NOT_SET_DATE[dataType].START_DATE
          ? new Date(startStr)
          : undefined
      const endDate =
        endStr !== NOT_SET_DATE[dataType].END_DATE
          ? new Date(endStr)
          : undefined
      return (
        <>
          <DatePickerLabel>{t('audience:v3.from')}</DatePickerLabel>
          <Box mr={1}>
            <DatePicker
              value={startDate}
              maxDate={endDate}
              bgColor={bgColor}
              isDisabled={isDisabled}
              popperDisablePortal
              onValueChanged={startDate => {
                onChange([
                  startDate
                    ? formatDateOrTimestamp(startDate, dataType)
                    : NOT_SET_DATE[dataType].START_DATE,
                  endStr,
                ])
              }}
            />
          </Box>
          <DatePickerLabel>{t('audience:v3.to')}</DatePickerLabel>
          <DatePicker
            value={endDate}
            minDate={startDate}
            bgColor={bgColor}
            isDisabled={isDisabled}
            popperDisablePortal
            onValueChanged={endDate => {
              if (endDate) {
                endDate.setHours(23, 59, 59)
              }
              onChange([
                startStr,
                endDate
                  ? formatDateOrTimestamp(endDate, dataType)
                  : NOT_SET_DATE[dataType].END_DATE,
              ])
            }}
          />
        </>
      )
    }

    if (operator === 'relative_between') {
      const [from] = values as number[]

      const UI_DEFAULT_VALUE = 0

      return (
        <PrimaryGreyInput
          type="number"
          value={`${Math.abs(getIntSafe(from, UI_DEFAULT_VALUE))}`}
          bgColor={bgColor}
          max={maxValue}
          min={minValue}
          width={INPUT_WIDTH_MEDIUM}
          disabled={isDisabled}
          onChange={e => {
            const currValue = Math.abs(
              getIntSafe(e.target.value, UI_DEFAULT_VALUE)
            )

            // 「過去 N 天」需要轉換成 [-N, 0, 'day'] 代表過去 N 到 今天
            onChange([currValue * -1, 0, 'day'])
          }}
        />
      )
    }

    if (operator === 'relative_between_truncated+past') {
      const [from, to, unit] = values as [number, number, string]

      // 資料實際是兩個負數 [-1, 0, 'day'] 代表今天到昨天
      // 只是為了口語通順將輸入框順序顛倒 (先填 TO) 以及改成正數，因此預設值為 1 跟 0
      const UI_DEFAULT_VALUE = { FROM: 1, TO: 0 }

      return (
        <Box display="flex" alignItems="center">
          {t('common:order')}
          <PrimaryGreyInput
            mx={1}
            type="number"
            value={`${Math.abs(getIntSafe(to, UI_DEFAULT_VALUE.TO))}`}
            bgColor={bgColor}
            max={maxValue}
            min={UI_DEFAULT_VALUE.TO}
            width={INPUT_WIDTH_MEDIUM}
            disabled={isDisabled}
            onBlur={e => {
              const targetTo =
                Math.abs(getIntSafe(e.target.value, UI_DEFAULT_VALUE.TO)) * -1

              if (from > targetTo) {
                onChange([targetTo, targetTo, unit])
              }
            }}
            onChange={e => {
              const targetTo =
                Math.abs(getIntSafe(e.target.value, UI_DEFAULT_VALUE.TO)) * -1

              onChange([from, targetTo, unit])
            }}
          />

          {` ${t('common:days')} ${t('common:to')} ${t('common:order')} `}

          <PrimaryGreyInput
            mx={1}
            type="number"
            value={`${Math.abs(getIntSafe(from, UI_DEFAULT_VALUE.FROM))}`}
            bgColor={bgColor}
            max={maxValue}
            min={UI_DEFAULT_VALUE.FROM}
            width={INPUT_WIDTH_MEDIUM}
            disabled={isDisabled}
            onBlur={e => {
              const targetFrom =
                Math.abs(getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)) * -1

              if (targetFrom > to) {
                onChange([to, to, unit])
              }
            }}
            onChange={e => {
              const targetFrom =
                Math.abs(getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)) * -1

              onChange([targetFrom, to, unit])
            }}
          />
          {t('common:days')}
        </Box>
      )
    }

    if (operator === 'relative_between_truncated') {
      // 未來時間數值一定是正數，不進行額外處理
      // 例如 [2, 3, 'day'] 代表未來兩天到未來三天
      const [from, to, unit] = values as [number, number, string]

      const UI_DEFAULT_VALUE = { FROM: 0, TO: 1 }

      return (
        <Box display="flex" alignItems="center">
          {t('common:order')}
          <PrimaryGreyInput
            mx={1}
            type="number"
            value={from}
            bgColor={bgColor}
            max={maxValue}
            min={UI_DEFAULT_VALUE.FROM}
            width={INPUT_WIDTH_MEDIUM}
            disabled={isDisabled}
            onBlur={e => {
              const targetFrom = Math.abs(
                getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)
              )

              if (targetFrom > to) {
                onChange([targetFrom, targetFrom, unit])
              }
            }}
            onChange={e => {
              const targetFrom = Math.abs(
                getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)
              )

              onChange([targetFrom, to, unit])
            }}
          />

          {` ${t('common:days')} ${t('common:to')} ${t('common:order')} `}

          <PrimaryGreyInput
            mx={1}
            type="number"
            value={to}
            bgColor={bgColor}
            max={maxValue}
            min={from}
            width={INPUT_WIDTH_MEDIUM}
            disabled={isDisabled}
            onBlur={e => {
              const targetTo = Math.abs(
                getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)
              )

              if (from > targetTo) {
                onChange([from, from, unit])
              }
            }}
            onChange={e => {
              const targetTo = Math.abs(
                getIntSafe(e.target.value, UI_DEFAULT_VALUE.FROM)
              )

              onChange([from, targetTo, unit])
            }}
          />
          {t('common:days')}
        </Box>
      )
    }
  }

  return null
}

export default AttributeValueInput
