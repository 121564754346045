import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'
import { spacing } from '@mui/system'

import { AUDIENCE_FILTER_EVENT_MAX_COUNT } from './constants'

const AUDIENCE_FILTER_MIN_WIDTH = 600
const AUDIENCE_FILTER_ITEM_PADDING_LEFT = 40

export const AudienceFilterContainer = styled.div`
  margin-left: 3px;
  min-width: ${AUDIENCE_FILTER_MIN_WIDTH + AUDIENCE_FILTER_ITEM_PADDING_LEFT}px;
  border-left: ${({ theme }) => `1px dotted ${theme.colors.brightBlue}`};
`

export const AudienceFilterItemHeader = styled.div<{ isActive?: boolean }>`
  margin-left: -3px;
  padding-left: 28px;

  border-left: ${({ theme }) => `5px solid ${theme.colors.brightBlue}`};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};

  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.brightBlue : theme.colors.textPrimaryBlue};

  line-height: 50px;
`

export const AudienceFilterItemContainer = styled.div`
  padding: 16px 0 16px ${AUDIENCE_FILTER_ITEM_PADDING_LEFT}px;
`

export const AudienceFilterListItem = styled.li`
  position: relative;

  margin-bottom: 48px;
  min-width: ${AUDIENCE_FILTER_MIN_WIDTH}px;

  border: 1px solid currentColor;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};

  color: ${({ theme }) => theme.colors.brightBlue};

  &::before {
    position: absolute;
    bottom: -24px;
    left: 0;
    display: block;
    width: 100%;
    border-bottom: 1px solid currentColor;
    content: '';
  }

  &::after {
    position: absolute;
    bottom: -32px;
    left: 0;
    padding-right: 8px;
    background-color: white;
    color: currentColor;
    font-size: 12px;
    content: attr(data-combination);
  }

  &:nth-of-type(${AUDIENCE_FILTER_EVENT_MAX_COUNT}) {
    margin-bottom: 24px;

    &::after,
    &::before {
      content: unset;
    }
  }
`

export const AudienceFilterListItemHeader = styled.div`
  padding: 16px 0 0 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brightBlue};
`

export const AudienceFilterListItemContainer = styled.div`
  padding: 24px 32px;
`

export const AudienceFilterNestedListItem = styled.li`
  position: relative;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &:nth-of-type(${AUDIENCE_FILTER_EVENT_MAX_COUNT}) {
    padding-bottom: 0;
    border-bottom: none;
  }

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
    content: '';
  }

  &::after {
    position: absolute;
    bottom: -8px;
    left: 0;
    padding-right: 8px;
    background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};
    color: ${({ theme }) => theme.colors.lightGreyBlue};
    font-size: 12px;
    content: attr(data-combination);
  }
`

export const AudienceFilterNestedListItemContainer = styled.div`
  padding: 8px 0 0 24px;
  border-left: 5px solid ${({ theme }) => theme.colors.brightBlue};
  color: ${({ theme }) => theme.colors.textPrimaryBlue};

  ${spacing}
`

export const CombinationButton = styled(IconButton)`
  &.MuiButtonBase-root {
    width: 48px;
    height: 48px;

    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.veryLightBlueTwo : theme.colors.white};
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background-color: ${({ theme }) => theme.colors.calendarDisabledGrey};
      color: ${({ theme }) => theme.colors.brightBlue};
    }
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  }
`
