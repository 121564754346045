import html2canvas from 'html2canvas'

/**
 * @description 將 HTML string 螢幕截圖，並轉成 File
 */
const htmlToImageFile = async ({
  html,
  contentType,
}: {
  html: string
  contentType: string
}): Promise<File | null> => {
  /**
   *  canvas 只能擷取實際在 DOM 上面的 HTML
   *  所以需要先 append 到 body 上，再 remove
   *
   *  因為 append 到 body 使用者就會看到 preview html
   *  故把包一個 container opacity 設為 0
   *  display:none or visibility:hidden canvas 都會擷取不到 HTML
   */
  const container = document.createElement('section')
  const wrapper = document.createElement('div')
  wrapper.innerHTML = html
  container.appendChild(wrapper)
  container.style.opacity = '0'
  container.style.position = 'fixed'
  container.style.zIndex = '-999'

  document.body.appendChild(container)
  const canvas = await html2canvas(wrapper, {
    useCORS: true,
  })
  document.body.removeChild(container)

  return new Promise(resolve => {
    canvas.toBlob(blob => {
      const imageFile = blob
        ? new File([blob], '', { type: contentType })
        : null
      resolve(imageFile)
    })
  })
}
export default htmlToImageFile
