import { useEffect } from 'react'
import type { Reducer } from 'use-immer'
import { useImmerReducer } from 'use-immer'

import isInvalidUrlOrUTMPropertiesInUrlChecker from '@entities/mediumTemplates/ui/SmsEditor/utm/nodes/UtmLinkDialog/utils/isInvalidUrlOrUTMPropertiesInUrlChecker'
import type { ReplacementDataActionURL } from '@shared/api/rtkQuery/contentTemplate'
import removeObjectValueSpaces from '@shared/lib/utils/removeObjectValueSpaces'
import { removeSpaces } from '@shared/lib/utils/string'

export type State = {
  form: ReplacementDataActionURL['urlParams']
  isUTMFieldsDisabled: boolean
}

type Action =
  | { type: 'SET_HOST'; payload: string }
  | { type: 'SET_UTM_CAMPAIGN_ID'; payload: string }
  | { type: 'SET_UTM_MEDIUM'; payload: string }
  | { type: 'SET_UTM_SOURCE'; payload: string }
  | { type: 'SET_UTM_CAMPAIGN'; payload: string }
  | { type: 'SET_UTM_TERM'; payload: string }
  | { type: 'SET_UTM_CONTENT'; payload: string }
  | { type: 'INITIAL'; payload: ReplacementDataActionURL['urlParams'] }

const createInitValues = (): ReplacementDataActionURL['urlParams'] => ({
  host: '',
  utmCampaignId: '',
  utmMedium: '',
  utmSource: '',
  utmCampaign: '',
  utmTerm: '',
  utmContent: '',
})

const reducer: Reducer<State, Action> = (draft, action) => {
  switch (action.type) {
    case 'INITIAL': {
      const isInvalidUrlOrUTMPropertiesInUrl =
        isInvalidUrlOrUTMPropertiesInUrlChecker(action.payload.host)
      const data = removeObjectValueSpaces(action.payload)
      draft.isUTMFieldsDisabled = isInvalidUrlOrUTMPropertiesInUrl
      draft.form = isInvalidUrlOrUTMPropertiesInUrl
        ? {
            host: data.host,
            utmCampaignId: '',
            utmMedium: '',
            utmSource: '',
            utmCampaign: '',
            utmTerm: '',
            utmContent: '',
          }
        : {
            ...data,
          }
      return
    }
    case 'SET_HOST': {
      const isInvalidUrlOrUTMPropertiesInUrl =
        isInvalidUrlOrUTMPropertiesInUrlChecker(action.payload)
      draft.isUTMFieldsDisabled = isInvalidUrlOrUTMPropertiesInUrl
      draft.form = isInvalidUrlOrUTMPropertiesInUrl
        ? {
            host: action.payload,
            utmCampaignId: '',
            utmMedium: '',
            utmSource: '',
            utmCampaign: '',
            utmTerm: '',
            utmContent: '',
          }
        : {
            ...draft.form,
            host: removeSpaces(action.payload),
          }
      return
    }
    case 'SET_UTM_CAMPAIGN_ID':
      draft.form.utmCampaignId = removeSpaces(action.payload)
      return
    case 'SET_UTM_MEDIUM':
      draft.form.utmMedium = removeSpaces(action.payload)
      return
    case 'SET_UTM_SOURCE':
      draft.form.utmSource = removeSpaces(action.payload)
      return
    case 'SET_UTM_CAMPAIGN':
      draft.form.utmCampaign = removeSpaces(action.payload)
      return
    case 'SET_UTM_TERM':
      draft.form.utmTerm = removeSpaces(action.payload)
      return
    case 'SET_UTM_CONTENT':
      draft.form.utmContent = removeSpaces(action.payload)
      return
    default:
      throw new Error('action type is not defined')
  }
}

const useInit = ({
  data,
  isOpen,
}: {
  data: ReplacementDataActionURL
  isOpen: boolean
}) => {
  const [state, setState] = useImmerReducer(reducer, {
    form: createInitValues(),
    isUTMFieldsDisabled: false,
  })

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setState({
      type: 'INITIAL',
      payload: {
        // 避免 urlParams 有欄位沒填導致輸入元件變成 uncontrolled
        ...createInitValues(),
        ...data.urlParams,
      },
    })
    // dialog 重新開啟時，需要把 state 重新設定
    // 否則之前的 state 會被暫存起來
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return { state, setState }
}

export default useInit
