import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

import { useGetCampaignsStatisticExportMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getYYYYMMDDHHMMSS } from '@shared/lib/utils/time'
import type { NumberOrString } from '@shared/lib/utils/type'
import { openToast } from '@shared/model/slices'
import CSVButton from '@shared/ui/buttons/CSVButton'

type Props = {
  timeRangeParams: NumberOrString[]
}

const ExportCSV = ({ timeRangeParams }: Props) => {
  const { t } = useTranslation(['common', 'audience', 'aiMarketingAssistant'])
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string>('')

  const dispatch = useAppDispatch()

  const [exportCSV, { isLoading: isExporting, isError: isExportError }] =
    useGetCampaignsStatisticExportMutation()

  const handleDownload = async () => {
    if (fileDownloadUrl) {
      try {
        const { data: csvData } = await axios.get(fileDownloadUrl)
        const blob = new Blob([csvData], {
          type: 'text/csv;charset=utf-8;',
        })
        const $a = document.createElement('a')
        $a.href = window.URL.createObjectURL(blob)
        const nowDate = getYYYYMMDDHHMMSS(new Date())
        $a.download = `usage_${nowDate}.csv`
        $a.style.display = 'none'
        document.body.appendChild($a)
        $a.click()
        $a.remove()
      } catch (e) {
        dispatch(
          openToast({
            message: t('common:errors.download_fail'),
            status: 'error',
          })
        )
      }
    }
  }

  const handleExport = async () => {
    try {
      const { downloadUrl } = await exportCSV({
        startTime: timeRangeParams[0],
        endTime: timeRangeParams[1],
      }).unwrap()

      setFileDownloadUrl(downloadUrl)
    } catch (e) {
      dispatch(
        openToast({
          message: t('aiMarketingAssistant:auto_tag_analysis.export_failed'),
          status: 'error',
        })
      )
    }
  }

  useEffect(() => setFileDownloadUrl(''), [timeRangeParams])

  const csvButtonStatus = useMemo(() => {
    if (isExporting) {
      return 'processing'
    }
    if (fileDownloadUrl) {
      return 'completed'
    }
    if (isExportError) {
      return 'failed'
    }
    return 'none'
  }, [isExporting, fileDownloadUrl, isExportError])

  return (
    <CSVButton
      status={csvButtonStatus}
      onExportCSV={handleExport}
      onDownloadCSV={handleDownload}
      isDisabled={false}
      isExpired={false}
      buttonText={{
        export: t('common:export'),
      }}
    />
  )
}

export default ExportCSV
