import type { ThirdPartyAppIntegrationListItem } from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import type { TableColumn } from '@shared/ui/table'

const useColumns = (): TableColumn<ThirdPartyAppIntegrationListItem>[] => {
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  return [
    { headerName: 'name', field: 'name' },
    {
      headerName: 'type',
      field: 'type',
      valueFormatter: value => (value ? getThirdPartyProviderName(value) : '-'),
    },
    {
      headerName: 'create_time',
      field: 'createdAt',
      valueFormatter: date => formatDateTimeDisplay(date),
    },
    { headerName: 'creator', field: 'creator' },
  ]
}

export default useColumns
