import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import { useFormik } from 'formik'

import checkHasUtmProperties from '@shared/lib/utils/checkHasUtmProperties'
import mapPreviewUrlWithUtm from '@shared/lib/utils/mapPreviewUrlWithUtm'
import { removeSpaces } from '@shared/lib/utils/string'
import { ICON } from '@shared/model/constants/styles'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { Dialog, DialogContent } from '@shared/ui/dialogs'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyTextarea } from '@shared/ui/inputs'
import theme from '@theme'

import type { FormValues } from './schema'
import { getFormFields, validationSchema } from './schema'
import TextInputField from './TextInputField'
import { mapInitialValues } from './utils'

export type TracingUrlDialogProps = {
  data?: { name: string; url: string }
  mode?: 'create' | 'preview'
  isLoading?: boolean
  onSave?: ({ name, url }: { name: string; url: string }) => void
  onClose: () => void
}

const TracingUrlDialog = ({
  data,
  mode = 'create',
  isLoading = false,
  onClose,
  onSave,
}: TracingUrlDialogProps) => {
  const [isUTMFieldDisabled, setIsUTMFieldDisabled] = useState(false)
  const formFields = getFormFields()

  const getPreviewUrl = useCallback(
    (formValues: FormValues) =>
      mapPreviewUrlWithUtm({
        host: formValues.url || '',
        utmCampaignId: formValues.utmCampaignId || '',
        utmSource: formValues.utmCampaignSource || '',
        utmMedium: formValues.utmCampaignMedium || '',
        utmCampaign: formValues.utmCampaignName || '',
        utmTerm: formValues.utmCampaignTerm || '',
        utmContent: formValues.utmCampaignContent || '',
      }),
    []
  )

  const { setFieldValue, submitForm, errors, values, touched } =
    useFormik<FormValues>({
      initialValues: mapInitialValues(data),
      validationSchema,
      onSubmit: submitValues => {
        onSave?.({ name: submitValues.name, url: getPreviewUrl(submitValues) })
      },
    })

  const getIsError = useCallback(
    (key: keyof FormValues) => Boolean(touched?.[key] && errors?.[key]),
    [errors, touched]
  )

  const getIsDisabled = useCallback(
    (isUTMSettingField: boolean) => {
      if (mode === 'preview') {
        return true
      }

      if (isUTMSettingField) {
        return isUTMFieldDisabled
      }

      return false
    },
    [isUTMFieldDisabled, mode]
  )

  const { t } = useTranslation(['contentTemplate', 'common'])

  const onFieldChange = useCallback(
    (fieldName: keyof FormValues, value: string) => {
      if (fieldName === 'url') {
        setIsUTMFieldDisabled(() => checkHasUtmProperties(value))
      }
      setFieldValue(fieldName, removeSpaces(value))
    },
    [setFieldValue]
  )

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent
        sx={{
          color: theme.colors.textSecondBlue,
          ml: '8px',
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
          },
          '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
          },
        }}
      >
        <Box
          component="h2"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0 0 6px 0',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '44px',
            color: theme => theme.colors.textPrimaryBlue,
          }}
        >
          {t('contentTemplate:utm_link')}
          <IconButton onClick={onClose}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Box>

        <Box sx={{ display: 'grid', gap: 4 }}>
          {formFields.map(
            ({
              name: fieldName,
              label,
              placeholder,
              isRequired,
              isUTMSettingField,
            }) => (
              <TextInputField
                key={fieldName}
                label={label}
                placeholder={placeholder}
                disabled={getIsDisabled(isUTMSettingField)}
                value={values[fieldName]}
                isError={getIsError(fieldName)}
                required={isRequired}
                onChange={e => {
                  onFieldChange(fieldName, e.target.value)
                }}
              />
            )
          )}
        </Box>

        <Box
          my={3.5}
          width="100%"
          height="1px"
          sx={{ backgroundColor: theme.colors.bgPaleGrey }}
        />
        <PrimaryGreyTextarea
          style={{ width: '100%' }}
          resize={'none'}
          disabled={true}
          value={getPreviewUrl(values) || '-'}
        />
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          pt={3.5}
          pb={5}
        >
          {mode === 'preview' ? (
            <DeepBlueGradientButton width={188} onClick={onClose}>
              {t('common:confirm')}
            </DeepBlueGradientButton>
          ) : (
            <DeepBlueGradientButton
              width={188}
              onClick={submitForm}
              disabled={isLoading}
            >
              {t('common:create')}
            </DeepBlueGradientButton>
          )}
        </Box>
      </DialogContent>
      <Box sx={{ height: '4px' }}>{isLoading && <LinearProgress />}</Box>
    </Dialog>
  )
}

export default TracingUrlDialog
