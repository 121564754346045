import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  INITIAL_PAGINATION_RESPONSE,
  useCreateAudienceRuleJobMutation,
  useDeleteWorkflowMutation,
  useGetWorkflowListQuery,
  usePatchUpdateWorkflowMutation,
  WorkflowStatus,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  ROWS_PER_PAGE_OPTIONS,
} from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import DropdownCheckboxList, { Option } from '@shared/ui/Dropdown/CheckboxList'
import { UniIcon as Icon } from '@shared/ui/icons'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { SearchBar } from '@shared/ui/searchInput'
import {
  Table,
  TableBody,
  TableContainer,
  TableContainerCard,
  TableHeadEnhanced,
  TablePagination,
  TableRowListSkeleton,
} from '@shared/ui/table'
import theme from '@theme'

import { STATUS_CODE } from '../_shared/constants'
import useWorkflowListColumns from './useWorkflowListColumns'
import WorkflowListRow from './WorkflowListRow'

const WorkflowList = () => {
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const [searchText, setSearchText] = useState('')
  const [statusFilters, setStatusFilters] = useState<WorkflowStatus[]>([])

  const [createAudienceRuleJob, { isLoading: isCreatingAudienceRuleJob }] =
    useCreateAudienceRuleJobMutation()

  const {
    data: { items: workflowList, totalCount } = INITIAL_PAGINATION_RESPONSE,
    isLoading,
  } = useGetWorkflowListQuery({
    page,
    perPage,
    name: searchText,
    statusTypes: statusFilters,
  })

  const [patchUpdateWorkflow, { isLoading: isUpdatingWorkflow }] =
    usePatchUpdateWorkflowMutation()

  const [deleteWorkflow, { isLoading: isDeletingWorkflow }] =
    useDeleteWorkflowMutation()

  const { t } = useTranslation(['common', 'table', 'workflow'])

  const [openActionMenuId, setOpenActionMenuId] = useState<number>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false)
  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState(false)
  const [renameText, setRenameText] = useState('')

  const [toggleWorkflowId, setToggleWorkflowId] = useState<number>()
  const toggleWorkflow = workflowList.find(x => x.id === toggleWorkflowId)

  const columns = useWorkflowListColumns({
    isOpenDeleteDialog,
    isOpenRenameDialog,
    openActionMenuId,
    setIsOpenDeleteDialog,
    setIsOpenRenameDialog,
    setOpenActionMenuId,
    setRenameText,
    setToggleWorkflowId,
    totalCount,
  })

  const dispatch = useAppDispatch()

  const safeCloseAllPopup = useCallback(() => {
    // 需要照順序關閉彈窗避免頁面跳動
    setOpenActionMenuId(undefined)
    setIsOpenRenameDialog(false)
    setIsOpenDeleteDialog(false)
    setToggleWorkflowId(undefined)
  }, [])

  const [serverError, setServerError] = useState({
    renameText: '',
  })

  const errors = {
    renameText:
      renameText.length === 0
        ? t('workflow:workflow_name_required', {
            count: MAX_LIST_NAME_LENGTH,
          })
        : serverError.renameText,
  }

  const statusFilterOptions: Option[] = useMemo(
    () => [
      {
        label: t('common:enabled'),
        value: 'enabled',
      },
      {
        label: t('common:disabled'),
        value: 'disabled',
      },
      {
        label: t('common:archived'),
        value: 'end',
      },
      {
        label: t('common:draft'),
        value: 'draft',
      },
    ],
    [t]
  )
  const [selectedStatusFilterList, setSelectedStatusFilterList] = useState<
    string[]
  >([])

  return (
    <>
      <Box
        mb={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="inline-flex" alignItems="center">
          <Box display="inline" mr={2}>
            <Icon icon={ICON.filter} color={theme.colors.brightBlue} />
          </Box>

          <DropdownCheckboxList
            hasSearchBar
            hasShadow
            checkedValues={selectedStatusFilterList}
            hasSelectAll
            label={t('common:status')}
            onChange={values => {
              setSelectedStatusFilterList(values)
              setStatusFilters(values.toSorted() as WorkflowStatus[])
            }}
            options={statusFilterOptions}
          />
        </Box>
        <SearchBar
          defaultValue={searchText}
          onSearch={setSearchText}
          placeholder={t('common:search')}
        />
      </Box>

      <TableContainer component={TableContainerCard}>
        <Table>
          <TableHeadEnhanced columns={columns} />

          <TableBody>
            {isLoading && workflowList.length === 0 ? (
              <TableRowListSkeleton columnCount={columns.length} />
            ) : (
              workflowList.map(workflow => (
                <WorkflowListRow
                  columns={columns}
                  currPage={page}
                  data={workflow}
                  key={workflow.id}
                />
              ))
            )}
          </TableBody>
        </Table>

        <TablePagination
          count={totalCount}
          isShowPageInput
          labelRowsPerPage={t('table:table_rows_per_page')}
          onPageChange={(e, newPage) => {
            setPage(newPage + 1)
          }}
          onRowsPerPageChange={({ target: { value: newPerPage } }) =>
            setPerPage(getIntSafe(newPerPage))
          }
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        />
      </TableContainer>

      <ConfirmDialog
        isLoading={isUpdatingWorkflow}
        isOpen={isOpenRenameDialog}
        modalTitle={t('workflow:workflow_name')}
        onClose={safeCloseAllPopup}
        onConfirm={async () => {
          if (!openActionMenuId || !renameText) {
            return
          }

          try {
            await patchUpdateWorkflow({
              id: openActionMenuId,
              name: renameText,
            }).unwrap()

            safeCloseAllPopup()
          } catch (error) {
            const typedError = formatRTKQueryError(error)

            if (typedError.statusCode === STATUS_CODE.DUPLICATE_NAME) {
              setServerError({
                renameText: t('workflow:errors.workflow_name_invalid'),
              })

              return
            }

            dispatch(handleApiError(typedError))
          }
        }}
      >
        <TextInput
          error={Boolean(errors.renameText)}
          fullWidth
          onChange={({ target }) => {
            const newWorkflowName = target.value.substring(
              0,
              MAX_LIST_NAME_LENGTH
            )
            setRenameText(newWorkflowName)
            setServerError({ renameText: '' })
          }}
          placeholder={t('workflow:workflow_name_placeholder')}
          value={renameText}
        />
        <TextInputErrorText>{errors.renameText}</TextInputErrorText>
      </ConfirmDialog>

      <DeleteConfirmDialog
        isLoading={isDeletingWorkflow}
        isOpen={isOpenDeleteDialog}
        onClose={safeCloseAllPopup}
        onConfirm={async () => {
          if (!openActionMenuId) {
            return
          }

          try {
            await deleteWorkflow(openActionMenuId).unwrap()

            safeCloseAllPopup()
          } catch (error) {
            const typedError = formatRTKQueryError(error)

            if (typedError.statusCode === STATUS_CODE.UPDATE_RACE_CONDITION) {
              dispatch(
                openToast({
                  message: t('workflow:errors.workflow_status_race_condition'),
                  status: 'error',
                })
              )

              return
            }
            if (typedError.statusCode === STATUS_CODE.INTERNAL_SERVER_ERROR) {
              // 後端在特殊情況會產生無法被刪除的自動化旅程，預期應該不常發生
              dispatch(
                openToast({
                  message: t('common:api_error.409_unsupported'),
                  status: 'error',
                })
              )

              return
            }

            dispatch(handleApiError(typedError))
          }
        }}
        modalTitle={t('workflow:confirm_delete')}
      >
        <Box color={theme.colors.textSecondBlue} mb={2}>
          {t('workflow:confirm_delete_hint')}
        </Box>
      </DeleteConfirmDialog>

      {toggleWorkflow && (
        <ConfirmDialog
          isLoading={isUpdatingWorkflow || isCreatingAudienceRuleJob}
          isOpen
          modalTitle={
            toggleWorkflow.status === 'enabled'
              ? t('workflow:disable_workflow')
              : t('workflow:enable_workflow')
          }
          onClose={safeCloseAllPopup}
          onConfirm={async () => {
            try {
              const nextStatus =
                toggleWorkflow.status === 'enabled' ? 'disabled' : 'enabled'

              await patchUpdateWorkflow({
                id: toggleWorkflow.id,
                status: nextStatus,
              }).unwrap()

              if (
                nextStatus === 'enabled' &&
                toggleWorkflow.triggerNodeFlowSubtype === 'enter_audience_rule'
              ) {
                await createAudienceRuleJob(toggleWorkflow.sourceId).unwrap()
              }

              safeCloseAllPopup()
            } catch (error) {
              const typedError = formatRTKQueryError(error)

              if (
                typedError.statusCode === STATUS_CODE.NODE_ID_INVALID ||
                typedError.statusCode === STATUS_CODE.NODE_INVALID ||
                typedError.statusCode === STATUS_CODE.RESOURCE_INVALID
              ) {
                dispatch(
                  openToast({
                    message: t('workflow:errors.workflow_invalid', {
                      name: toggleWorkflow.name,
                    }),
                    status: 'error',
                  })
                )

                return
              }

              dispatch(handleApiError(typedError))
            }
          }}
        >
          <Box color={theme.colors.textSecondBlue} mb={2}>
            <p>
              {toggleWorkflow?.status === 'enabled'
                ? t('workflow:disable_workflow_hint')
                : t('workflow:enable_workflow_hint')}
            </p>
          </Box>
        </ConfirmDialog>
      )}
    </>
  )
}

export default WorkflowList
