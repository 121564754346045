import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  useTagListQuery,
} from '@shared/api/rtkQuery'
import TrialTag from '@shared/assets/images/trial/trialTag.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { createRouteState, RouteState } from '@shared/lib/utils/routeUtils'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { Tab } from '@shared/ui/tabs'
import {
  BEHAVIOR_TAG_PATH,
  GRADING_TAG_PATH,
  INTELLIGENT_TAG_PATH,
  TagSourceRoutesMap,
  THIRD_PARTY_TAG_PATH,
} from '@widgets/tag/_shared/constants'
import type { TagRoutes } from '@widgets/tag/_shared/type'

import CreateButton from './_shared/CreateButton'
import { useTagSourceOption } from './_shared/hooks/useTagSourceOptions'
import Grading from './Grading'
import Intelligent from './Intelligent'
import Multi from './Multi'
import ThirdParty from './ThirdParty'

const DEFAULT_TAG_ROUTE = GRADING_TAG_PATH

const Overview = () => {
  const { t } = useTranslation(['tag', 'common'])
  const { tagPath = DEFAULT_TAG_ROUTE } = useParams<{ tagPath: TagRoutes }>()

  const maxCount = useAppSelector(state => state.auth.plan.tag.maxCount)
  const isShowInstruction = useAppSelector(
    state => state.auth.plan.tag.introduction
  )

  const {
    pathname,
    state: { page, perPage } = createRouteState({
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
    }),
  } = useLocation<RouteState>()

  const history = useHistory()

  const { isExceededLimit = false } = useTagListQuery(
    {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    {
      selectFromResult: ({ data = [] }) => ({
        isExceededLimit: data.length >= maxCount,
      }),
    }
  )
  const tagOptions = useTagSourceOption()

  return (
    <Box display="flex" flexDirection="column">
      {isShowInstruction && (
        <FeatureInstruction
          title={t('tag:instruction.tag_instruction_title')}
          description={t('tag:instruction.tag_instruction_description')}
          notice={t('tag:instruction.tag_instruction_notice', { maxCount })}
          imageUrl={TrialTag}
        />
      )}
      <Breadcrumbs
        actionBar={<CreateButton isExceededLimit={isExceededLimit} />}
      >
        <Box>{t('common:route.tag_list')}</Box>
      </Breadcrumbs>

      <Tab
        tabOptions={tagOptions}
        currTab={TagSourceRoutesMap.getSourceType(tagPath)}
        onTabChange={nextTagType => {
          history.push(
            pathname.replace(tagPath, TagSourceRoutesMap.getRoute(nextTagType)),
            {
              page: DEFAULT_PAGE,
              perPage: DEFAULT_PER_PAGE,
            }
          )
        }}
      />

      {tagPath === GRADING_TAG_PATH && (
        <Grading
          routeState={{
            page,
            perPage,
          }}
        />
      )}
      {tagPath === BEHAVIOR_TAG_PATH && (
        <Multi
          routeState={{
            page,
            perPage,
          }}
        />
      )}
      {tagPath === INTELLIGENT_TAG_PATH && (
        <Intelligent
          routeState={{
            page,
            perPage,
          }}
        />
      )}
      {tagPath === THIRD_PARTY_TAG_PATH && (
        <ThirdParty
          routeState={{
            page,
            perPage,
          }}
        />
      )}
    </Box>
  )
}

export default Overview
