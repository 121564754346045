import { useEffect, useState } from 'react'

import {
  MediumType,
  TimeRangeUnit,
  useLazyGetAccountSentCountsByMediumTypeQuery,
} from '@shared/api/rtkQuery'

const TIME_RANGE_UNIT_LIST: TimeRangeUnit[] = ['day', 'week', 'month']
const MEDIUM_TYPE_LIST: MediumType[] = ['email', 'line_push', 'sms']

type AccountSentInfo = Record<
  MediumType,
  Record<
    TimeRangeUnit,
    {
      count: number
    }
  >
>

const useAccountSentInfo = () => {
  const [getAccountSendCountsByMediumType] =
    useLazyGetAccountSentCountsByMediumTypeQuery()

  const [accountSentInfo, setAccountSentInfo] = useState<AccountSentInfo>({
    email: {
      day: { count: 0 },
      week: { count: 0 },
      month: { count: 0 },
    },
    sms: {
      day: { count: 0 },
      week: { count: 0 },
      month: { count: 0 },
    },
    line_push: {
      day: { count: 0 },
      week: { count: 0 },
      month: { count: 0 },
    },
  })

  useEffect(() => {
    MEDIUM_TYPE_LIST.forEach(async mediumType => {
      try {
        const result = await getAccountSendCountsByMediumType(
          mediumType
        ).unwrap()

        TIME_RANGE_UNIT_LIST.forEach(timeUnit => {
          const targetProp = result[`${timeUnit}SentCount`]
          if (targetProp) {
            setAccountSentInfo(prev => ({
              ...prev,
              [mediumType]: {
                ...prev[mediumType],
                [timeUnit]: {
                  count: targetProp.count || 0,
                },
              },
            }))
          }
        })
      } catch {
        // 僅在 2xx 狀態設定 accountSentCounts 因此 catch 不做任何事情
      }
    })
  }, [getAccountSendCountsByMediumType])

  return accountSentInfo
}

export default useAccountSentInfo
