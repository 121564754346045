import type { Reducer } from 'use-immer'

import {
  createInitialState as createCampaignEventFilterInitialState,
  reducer as campaignEventFilterReducer,
} from '@features/filters/CampaignEventFilter/campaignEventFilterReducer'
import {
  createInitialState as createEventFilterInitialState,
  reducer as eventFilterReducer,
} from '@features/filters/EventFilter/eventFilterReducer'
import {
  createInitialState as createUserProfileAndTagFilterInitialState,
  reducer as userProfileAndTagReducer,
} from '@features/filters/UserProfileAndTagFilter/userProfileAndTagReducer'
import type {
  AudienceFilterNode,
  CampaignEventFilterNode,
  EventFilterNode,
  UserProfileAndTagFilterNode,
} from '@shared/api/rtkQuery'

import type { AudienceFilterAction } from './audienceFilterReducer.type'

export const createInitialState = (): AudienceFilterNode => ({
  filters: [],
  combination: 'and',
})

export const initializer = (
  currState?: AudienceFilterNode
): AudienceFilterNode => {
  return currState || createInitialState()
}

export const reducer: Reducer<AudienceFilterNode, AudienceFilterAction> = (
  draft,
  action
) => {
  switch (action.type) {
    case 'event': {
      let currIndex = draft.filters.findIndex(x => x.type === 'event')
      if (currIndex === -1) {
        currIndex = draft.filters.push(createEventFilterInitialState()) - 1
      }
      eventFilterReducer(
        draft.filters[currIndex] as EventFilterNode,
        action.payload
      )
      if (draft.filters[currIndex]?.node.nodes.length === 0) {
        draft.filters.splice(currIndex, 1)
      }
      break
    }

    case 'userProfileTag': {
      let currIndex = draft.filters.findIndex(
        x => x.type === 'user_profile_tag'
      )
      if (currIndex === -1) {
        currIndex =
          draft.filters.push(createUserProfileAndTagFilterInitialState()) - 1
      }
      userProfileAndTagReducer(
        draft.filters[currIndex] as UserProfileAndTagFilterNode,
        action.payload
      )
      if (draft.filters[currIndex]?.node.nodes.length === 0) {
        draft.filters.splice(currIndex, 1)
      }
      break
    }
    case 'campaignEvent': {
      let currIndex = draft.filters.findIndex(x => x.type === 'campaign_event')
      if (currIndex === -1) {
        currIndex =
          draft.filters.push(createCampaignEventFilterInitialState()) - 1
      }
      campaignEventFilterReducer(
        draft.filters[currIndex] as CampaignEventFilterNode,
        action.payload
      )
      if (draft.filters[currIndex]?.node.nodes.length === 0) {
        draft.filters.splice(currIndex, 1)
      }
      break
    }

    case 'toggleCombination':
      draft.combination = draft.combination === 'and' ? 'or' : 'and'
      break

    case 'reset':
      return initializer(action.payload)

    default:
      return draft
  }
}
