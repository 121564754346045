import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import type { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'

import { ICON } from '@shared/model/constants/styles'
import { RedButton } from '@shared/ui/buttons'
import { UniIcon as Icon } from '@shared/ui/icons'

import {
  Dialog,
  DIALOG_PROGRESS_HEIGHT,
  DialogActions,
  DialogContent,
} from './base'

type Props = {
  children?: React.ReactNode
  isLoading?: boolean
  isOpen: boolean
  maxWidth?: DialogProps['maxWidth']
  modalTitle?: string
  onClose?: () => void
  onConfirm?: () => void
}

export const DeleteConfirmDialog = ({
  children,
  isLoading,
  isOpen,
  maxWidth = 'sm',
  modalTitle = '',
  onClose = () => {},
  onConfirm: handleConfirm = () => {},
}: Props) => {
  const { t } = useTranslation('common')

  const handleClose = () => {
    if (isLoading) {
      return
    }

    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box fontSize={16} mt={1}>
            {modalTitle}
          </Box>
          <Box m={-0.5} ml={2}>
            <IconButton onClick={handleClose}>
              <Icon icon={ICON.multiply} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {children && <DialogContent>{children}</DialogContent>}

      <DialogActions>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: 'repeat(2, 200px)',
          }}
        >
          <Button variant="text" color="inherit" onClick={handleClose}>
            {t('cancel')}
          </Button>

          <RedButton disabled={isLoading} onClick={handleConfirm}>
            {t('delete')}
          </RedButton>
        </Box>
      </DialogActions>
      <Box height={DIALOG_PROGRESS_HEIGHT}>
        {isLoading && <LinearProgress color="secondary" />}
      </Box>
    </Dialog>
  )
}

export default DeleteConfirmDialog
