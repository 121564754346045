import { createContext, useContext, useState } from 'react'

type SharedState = {
  isEmptyTags: boolean
}

const SharedStateContext = createContext<SharedState | null>(null)
const SharedSetStateContext = createContext<React.Dispatch<
  React.SetStateAction<SharedState>
> | null>(null)

const SharedStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<SharedState>({
    isEmptyTags: false,
  })

  return (
    <SharedStateContext.Provider value={state}>
      <SharedSetStateContext.Provider value={setState}>
        {children}
      </SharedSetStateContext.Provider>
    </SharedStateContext.Provider>
  )
}

export function useSharedState() {
  const context = useContext(SharedStateContext)
  if (!context) {
    throw new Error(
      'useSharedState must be used within the SharedStateProvider'
    )
  }

  return context
}

export function useSharedSetState() {
  const setContext = useContext(SharedSetStateContext)
  if (!setContext) {
    throw new Error(
      'useSharedSetState must be used within the SharedStateProvider'
    )
  }

  return setContext
}

export default SharedStateProvider
