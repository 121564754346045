import type { MediumType } from '..'
import {
  api,
  PaginationParams,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'
import type { MsgChannel, MsgChannelList } from './type'

const msgChannel = api.injectEndpoints({
  endpoints: builder => ({
    getMsgChannelList: builder.query<
      MsgChannelList,
      PaginationParams & { mediumType?: MediumType }
    >({
      query: params => ({
        url: '/msg_channels',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgChannelList),

      providesTags: result =>
        result
          ? [
              ...result.items?.map(({ id }) => ({
                type: 'MsgChannel' as const,
                id,
              })),
              { type: 'MsgChannel', id: 'LIST' },
            ]
          : [{ type: 'MsgChannel', id: 'LIST' }],
    }),
    getMsgChannel: builder.query<MsgChannel, number>({
      query: id => `/msg_channels/${id}`,
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as MsgChannel),
      providesTags: (result, error, id) => [{ type: 'MsgChannel', id }],
    }),
  }),
})

export const { useGetMsgChannelListQuery, useGetMsgChannelQuery } = msgChannel
