import styled from '@emotion/styled'
import Button from '@mui/material/Button'

export const RoundedButton = styled(Button)`
  && {
    border-radius: 18px;
    background-color: transparent;

    :focus,
    :hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`
