export type FormError = {
  [field in string]: string
}

export const checkHasError = (errors: FormError) => {
  for (let field in errors) {
    if (errors[field] !== '') {
      return true
    }
  }

  return false
}
