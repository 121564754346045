import styled from '@emotion/styled'

const Container = styled.div<{ isShow: boolean }>`
  flex-shrink: 1;
  flex-basis: ${({ isShow }) => (isShow ? 800 : 0)}px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  margin-left: ${({ isShow }) => (isShow ? 32 : 0)}px;
  min-width: ${({ isShow }) => (isShow ? 300 : 0)}px;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.colors.bgPrimaryGrey};

  transition: flex-basis 0.3s, min-width 0.3s;
  background-image: url('/images/cellphone.svg');
`

const Screen = styled.div`
  flex-shrink: 0;
  overflow-y: scroll;

  padding: 6px;

  width: 220px;
  height: 384px;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  line-height: 1.5;

  white-space: pre-line;
  word-break: break-all;
`

const Title = styled.h1`
  margin-bottom: 8px;
`

const Img = styled.img`
  max-width: 100%;
`

type Props = {
  isShow: boolean
  title: string
  content: string
  imageURL?: string
}

const Preview = ({ isShow, title, content, imageURL = '' }: Props) => (
  <Container isShow={isShow}>
    <Screen>
      <Title>{title}</Title>
      {imageURL && <Img src={imageURL} alt="upload file" />}
      <p>{content}</p>
    </Screen>
  </Container>
)

export default Preview
