import { Box } from '@mui/system'

import MetadataTag from './MetadataTag'
import Utm from './Utm'

const SmsToolbar = (): JSX.Element => {
  return (
    <Box display="flex">
      <MetadataTag />
      <Utm />
    </Box>
  )
}

export default SmsToolbar
