import Box from '@mui/material/Box'

import IconWrapper from './IconWrapper'

// svg 沒有要改顏色就放 /public/images 動態載入
export const RedoIcon = ({
  marginLeft,
  id = '',
}: {
  marginLeft?: number
  id?: string
}) => (
  <IconWrapper id={id} marginLeft={marginLeft}>
    <Box component="img" sx={{ width: '100%' }} src="/images/icon-redo.svg" />
  </IconWrapper>
)

export default RedoIcon
