import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetAudienceSummaryAgeGenderQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { useAudienceDetailState } from '@widgets/audience/lib/AudienceDetailContext'
import { NO_DATA, NO_DATA_ID } from '@widgets/audience/model/constant'
import BasicCard from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/BasicCard'
import EmptyData from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/EmptyData'

import ColumnChart from './ColumnChart'
import StackedColumnChart from './StackedColumnChart'

const AgeGender = () => {
  const { t } = useTranslation(['audience'])
  const { audienceId } = useAudienceDetailState()

  const { data, isError, isSuccess, isFetching, isLoading, isUninitialized } =
    useGetAudienceSummaryAgeGenderQuery(audienceId, {
      skip: audienceId === NO_DATA_ID,
    })

  const chartSeriesData = useMemo(() => {
    if (isSuccess && data) {
      const { orderedByAgeSummary, genderSet, mainGenderGroup } = data

      if (!mainGenderGroup) {
        return data.orderedByAgeSummary.map(summary => summary.groupMembers)
      }

      return genderSet.map(gender => {
        const count = orderedByAgeSummary.map(summary => {
          const countSummary = summary.orderByCountSummary?.find(
            countSummary => countSummary.groupName === gender
          )

          return countSummary?.count || 0
        })

        return {
          name: gender === NO_DATA ? t('audience:no_data') : gender,
          data: count,
        }
      })
    }

    return []
  }, [data, isSuccess, t])

  const chartCategories: string[] = useMemo(() => {
    if (isSuccess && data) {
      return data.orderedByAgeSummary.map(summary =>
        summary.ageGroup !== NO_DATA
          ? `${t(`audience:${summary.ageGroup}`)} ${t(
              'audience:unit.years_old'
            )}`
          : `${t(`audience:no_data`)}`
      )
    }

    return []
  }, [isSuccess, data, t])

  const chartTitle = useMemo(() => {
    if (!data) {
      return ''
    }

    return `${t(`audience:${data.mainAgeGroup}`)} ${t(
      'audience:unit.years_old'
    )} | ${
      data.mainGenderGroup
        ? data.mainGenderGroup === NO_DATA
          ? t(`audience:no_data`)
          : data.mainGenderGroup
        : ''
    }`
  }, [data, t])

  return (
    <BasicCard
      chartName="ageGender"
      subject={t('audience:major_age_distribution')}
      title={chartTitle}
      nonMembers={getIntSafe(data?.totalNonMembers, '').toLocaleString()}
      isError={isError}
      isFetching={isFetching}
      isSuccess={isSuccess}
      isLoading={isUninitialized || isLoading}
    >
      <>
        {data ? (
          data.mainGenderGroup ? (
            <StackedColumnChart
              series={
                chartSeriesData as Array<{ name: string; data: number[] }>
              }
              categories={chartCategories}
              total={getIntSafe(data.totalMembers)}
            />
          ) : (
            <ColumnChart
              series={chartSeriesData as number[]}
              categories={chartCategories}
              total={getIntSafe(data.totalMembers)}
            />
          )
        ) : (
          <EmptyData />
        )}
      </>
    </BasicCard>
  )
}

export default AgeGender
