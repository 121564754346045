import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

const EmptyAudience = () => {
  const { t } = useTranslation(['audience'])

  return (
    <Box
      py={8}
      borderRadius="5px"
      width="100%"
      sx={theme => ({ backgroundColor: theme.colors.bgIceBlue })}
    >
      <Box
        fontWeight={600}
        fontSize={15}
        lineHeight="24px"
        textAlign="center"
        sx={theme => ({
          color: theme.colors.textPrimaryBlue,
        })}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={theme.colors.brightBlue}
          fontSize={48}
          mb={2.5}
        >
          <Icon
            color="inherit"
            fontSize="inherit"
            icon={ICON.exclamationTriangle}
          />
        </Box>
        {t('audience:empty_audience_for_analysis')}
      </Box>
    </Box>
  )
}

export default EmptyAudience
