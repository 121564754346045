import { forwardRef } from 'react'
import styled from '@emotion/styled'
import Paper, { PaperProps } from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

// ref: https://mui.com/material-ui/react-dialog/#transitions
export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="down" ref={ref} {...props} />
})

export const ToastContent = styled(
  forwardRef<HTMLDivElement, { isError?: boolean } & PaperProps>(
    ({ isError, ...otherProps }, ref) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Paper {...otherProps} ref={ref} />
    )
  )
)`
  display: flex;
  align-items: center;

  padding: 10px 16px;

  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;

  &.MuiPaper-root {
    flex-grow: initial;
    min-width: 288px;
    max-width: 400px;

    background-color: ${({ theme, isError }) =>
      isError ? theme.colors.snackbarRed : theme.colors.spray};
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
    font-weight: 500;
  }
`

export const Icon = styled.span`
  align-self: flex-start;
  margin-right: 6px;
  font-size: 16px;
`

export const Error = styled.span`
  color: ${({ theme }) => theme.colors.orangeyRed};
`
