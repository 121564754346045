import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { NodeKey } from 'lexical'

import Chip from '@entities/mediumTemplates/ui/SmsEditor/_shared/Chip'
import { DELETE_UTM_COMMAND } from '@entities/mediumTemplates/ui/SmsEditor/utm/UtmPlugin'
import type { ReplacementDataTracingURL } from '@shared/api/rtkQuery'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import { TracingUrlDialog } from '@shared/ui/dialogs'
import theme from '@theme'

export type Props = {
  replacementKey: string
  replacementData: ReplacementDataTracingURL
  nodeKey: NodeKey
}

const TracingUrlChip = ({
  replacementKey,
  replacementData,
  nodeKey,
}: Props) => {
  const { t } = useTranslation(['contentTemplate'])
  const [editor] = useLexicalComposerContext()
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  const { checkTracingUrlIsArchived } = useTracingUrlStatus()

  const isArchived = useMemo(
    () =>
      checkTracingUrlIsArchived(
        replacementData.urlParams.tracingUrlId,
        replacementData.urlParams.archivedAt
      ),
    [
      checkTracingUrlIsArchived,
      replacementData.urlParams.archivedAt,
      replacementData.urlParams.tracingUrlId,
    ]
  )

  const chipProps = useMemo(() => {
    if (isArchived) {
      return {
        tooltipTitle: t('contentTemplate:tracing_url.link_is_archived'),
        bgColor: theme.colors.chipRed,
      }
    }
    return {}
  }, [isArchived, t])

  const isEditorDisabled = !editor.isEditable()

  const onClick = () => {
    if (isArchived) {
      return
    }
    setIsDialogVisible(true)
  }

  const onDelete = () => {
    editor.dispatchCommand(DELETE_UTM_COMMAND, {
      nodeKey,
      replacementKey,
    })
  }

  return (
    <>
      <Chip
        bgColor={chipProps.bgColor}
        tooltipTitle={chipProps.tooltipTitle}
        disabled={isEditorDisabled}
        onClick={onClick}
        onDelete={onDelete}
      >
        {replacementData.urlParams.name}
      </Chip>
      {isDialogVisible && (
        <TracingUrlDialog
          mode="preview"
          data={{
            name: replacementData.urlParams.name,
            url: replacementData.urlParams.url,
          }}
          onClose={() => {
            setIsDialogVisible(false)
          }}
        />
      )}
    </>
  )
}

export default TracingUrlChip
