import { CustomTag, Rfm, ThirdParty } from './TagType'

const Tag = () => {
  return (
    <>
      <CustomTag />
      <Rfm />
      <ThirdParty />
    </>
  )
}

export default Tag
