import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Paper } from '@mui/material'

import { ICON } from '@shared/model/constants/styles'
import { AddButton } from '@shared/ui/buttons'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { TablePagination as BaseTablePagination } from '@shared/ui/table'
import { StyledLoader as Loader } from '@shared/ui/TextSearchField'
import theme from '@theme'

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;
`

const Input = styled.input`
  margin: 8px;
  padding: 0 16px;
  width: 100%;
  height: 32px;
  border: 2px solid transparent;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.veryLightBlueThree};

  ::placeholder {
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brightBlue};
    outline: none;
  }
`

const Button = styled.button`
  width: 48px;
  height: 42px;
  border: none;
  border-radius: 4px;
  background-color: transparent;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const TextField = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  & > ${Button} {
    position: absolute;
    right: 8px;
  }
`

const Container = styled.section<{ height?: number; width?: number }>`
  position: relative;
  overflow-y: scroll;
  width: ${({ width }) => (width ? width + 'px' : 'auto')};
  height: ${({ height }) => (height ? height + 'px' : 'auto')};
  border-top: 1px solid ${({ theme }) => theme.colors.black6};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

const TablePagination = styled(BaseTablePagination)`
  &.MuiTablePagination-root {
    margin-top: 0;
  }

  & > .MuiTablePagination-toolbar {
    padding-right: 6px;
    min-height: 48px;
  }

  & .MuiButtonBase-root {
    padding: 8px;
  }
`

const PaginationListWithSearchBar = ({
  disabled,
  marginRightRatio,
  bgColor,
  isFetching,
  paginationInfo,
  onPageChange,
  onItemSearch,
  label,
  childrenHeight,
  childrenWidth,
  children,
}: {
  disabled?: boolean
  marginRightRatio?: number
  bgColor?: string
  isFetching: boolean
  paginationInfo: {
    totalCount: number
    rowsPerPage: number
    currPage: number
  }
  label: string
  onPageChange: (newPage: number) => void
  onItemSearch: (text: string) => void
  childrenHeight?: number
  childrenWidth?: number
  children: React.ReactNode
}) => {
  const { t } = useTranslation(['common'])

  const [searchText, setSearchText] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const { totalCount, rowsPerPage, currPage } = paginationInfo

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleSearchFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onItemSearch(searchText)
  }

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    onPageChange(page)
  }

  return (
    <Dropdown
      anchorElem={
        <AddButton label={label} disabled={disabled} onClick={() => {}} />
      }
      marginRightRatio={marginRightRatio}
      bgColor={bgColor}
      isOpen={isOpen}
      isDisabled={disabled}
      setIsOpen={setIsOpen}
    >
      <Paper>
        <form onSubmit={handleSearchFormSubmit}>
          <TextField>
            <Input
              type="text"
              value={searchText}
              placeholder={t('common:search')}
              onChange={handleInputChange}
            />
            <Button type="submit">
              <Icon
                id="icon-search"
                icon={ICON.search}
                color={theme.colors.brightBlue}
                fontSize="small"
              />
            </Button>
          </TextField>
        </form>
        <Container height={childrenHeight} width={childrenWidth}>
          {isFetching && (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          )}

          {children}
        </Container>
        <TablePagination
          count={totalCount}
          rowsPerPageOptions={[rowsPerPage]}
          rowsPerPage={rowsPerPage}
          page={currPage}
          isShowPageInput
          onPageChange={handlePageChange}
        />
      </Paper>
    </Dropdown>
  )
}

export default PaginationListWithSearchBar
