import { useTranslation } from 'react-i18next'

import {
  useDeleteEventRuleGroupMutation,
  useDeleteFunnelRuleGroupMutation,
} from '@shared/api/rtkQuery'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'

import type { AnalyticsType } from '../../_shared/type'

type Props = {
  id: number
  isOpen: boolean
  onDelete: (id: number) => void
  onClose: () => void
  analyticsType: AnalyticsType
}

const DeleteDialog = ({
  id,
  isOpen,
  onDelete,
  onClose,
  analyticsType,
}: Props) => {
  const { t } = useTranslation(['common', 'dashboard'])
  const [deleteEventRuleGroup, { isLoading: isDeletingEventRuleGroup }] =
    useDeleteEventRuleGroupMutation()
  const [deleteFunnelRuleGroup, { isLoading: isDeletingFunnelRuleGroup }] =
    useDeleteFunnelRuleGroupMutation()

  const handleConfirm = async () => {
    try {
      if (analyticsType === 'funnel') {
        await deleteFunnelRuleGroup(id).unwrap()
      } else {
        await deleteEventRuleGroup(id).unwrap()
      }

      onDelete(id)
    } catch (e) {}

    onClose()
  }

  return (
    <DeleteConfirmDialog
      modalTitle={t('dashboard:delete_group_confirm_message')}
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isDeletingEventRuleGroup || isDeletingFunnelRuleGroup}
      onConfirm={handleConfirm}
    />
  )
}

export default DeleteDialog
