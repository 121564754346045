import { ChangeEvent, memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'

import type { FunnelAnalyticsNode, MetadataType } from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  DEFAULT_EVENT_TABLE_NAME,
  getCategoriesOfClassification,
  useGetMetadataProperty,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { AddButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import {
  StatisticMeasureGroupingFilter,
  useCategoriesOfIntersectionEvents,
} from '@widgets/analytics/_shared'
import {
  FUNNEL_STEPS_MAX_COUNT,
  selectFunnelStepEventNameList,
} from '@widgets/analytics/funnel/_shared'

import type { FunnelAnalyticsFilterAction } from './funnelAnalyticsFilterReducer'
import StepFilter from './StepFilter'
import TimeWindow from './TimeWindow'

const StepsListItem = styled.li`
  margin-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brightBlue};
`

const Text = styled.span`
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 13px;
  font-weight: 500;
`

type RelevanceAttributeError = 'REQUIRED_MINIMUM' | 'MULTIPLE_DATATYPE' | ''

export type FilterError = {
  funnelSteps?: 'EMPTY_EVENT_NAME' | 'EMPTY_ATTRIBUTE_PARAMS' | ''
  groupByFields?: 'OUT_OF_RANGE' | ''
  timeRangeParams?: 'EMPTY' | ''
  relevanceAttribute?: RelevanceAttributeError
}

const getStepsError = (funnelSteps: FunnelAnalyticsNode['funnelSteps']) => {
  for (let item of funnelSteps) {
    if (item.eventName === '') {
      return 'EMPTY_EVENT_NAME'
    }

    for (let attributeFilter of item.attributeFilter.nodes) {
      if (attributeFilter.params?.length === 0) {
        return 'EMPTY_ATTRIBUTE_PARAMS'
      }
    }
  }

  return ''
}

const getRelevanceAttributeError = (
  funnelSteps: FunnelAnalyticsNode['funnelSteps'],
  getDataTypeAndRange: ReturnType<typeof useGetMetadataProperty>
): RelevanceAttributeError => {
  const filtered = funnelSteps.filter(s => s.relevanceField)

  if (filtered.length === 1) {
    return 'REQUIRED_MINIMUM'
  }

  const dataTypeSet = new Set<MetadataType>()
  filtered.forEach(s => {
    const { dataType } = getDataTypeAndRange(
      s.relevanceSource || DEFAULT_EVENT_TABLE_NAME,
      s.relevanceField
    )
    dataTypeSet.add(dataType)
  })

  if (dataTypeSet.size > 1) {
    return 'MULTIPLE_DATATYPE'
  }

  return ''
}

type Props = {
  state: FunnelAnalyticsNode
  dispatch: (action: FunnelAnalyticsFilterAction) => void
  onValidate?: (errors: FilterError) => void
}

const FunnelAnalyticsFilter = ({ state, dispatch, onValidate }: Props) => {
  const { t } = useTranslation(['analytics', 'audience'])

  const [isRelevanceAttribute, setIsRelevanceProperty] = useState(false)

  const { eventEntity } = useMetadataQuery()
  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )

  const eventNameList = selectFunnelStepEventNameList(state)

  const { groupByFields = [] } = state

  const categoriesOfIntersectionEvents = useCategoriesOfIntersectionEvents(
    eventNameList,
    {
      isNeedDimensionColumnFromEvent: !isRelevanceAttribute, // 開啟關聯屬性就不允許選擇維度
      groupByFields,
    }
  )

  const intersectionEventAttributeList = useMemo(
    () =>
      categoriesOfIntersectionEvents.flatMap(x => x.options.map(y => y.value)),
    [categoriesOfIntersectionEvents]
  )

  const getMetadataProperty = useGetMetadataProperty('')

  const errors: FilterError = useMemo(() => {
    return {
      statisticMeasures: getStepsError(state.funnelSteps),
      groupByFields:
        state.groupByFields.length > 0 &&
        state.groupByFields.some(
          ({ source, field }) =>
            !intersectionEventAttributeList.includes(
              createMetadataPropertyId(source, field)
            )
        )
          ? 'OUT_OF_RANGE'
          : '',
      timeRangeParams: state.timeRangeParams.length === 0 ? 'EMPTY' : '',
      relevanceAttribute: getRelevanceAttributeError(
        state.funnelSteps,
        getMetadataProperty
      ),
    }
  }, [
    intersectionEventAttributeList,
    state.groupByFields,
    state.funnelSteps,
    state.timeRangeParams.length,
    getMetadataProperty,
  ])

  useEffect(() => {
    if (onValidate === undefined) {
      return
    }

    onValidate(errors)
  }, [errors, onValidate])

  useEffect(() => {
    const filtered = state.funnelSteps.filter(
      step => step.relevanceField !== ''
    )
    if (filtered.length >= 2) {
      setIsRelevanceProperty(true)
    }
  }, [state.funnelSteps])

  const isDisabledAddButton = state.funnelSteps.length >= FUNNEL_STEPS_MAX_COUNT

  return (
    <>
      <Card mb={5}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Box component="h2">
            <Text>{t('analytics:funnel.step')}</Text>
          </Box>

          <Box>
            <TimeWindow state={state} dispatch={dispatch} />
            <Box display="inline">
              <Text>{t('analytics:funnel.relevance_attribute_set')}</Text>
            </Box>

            <Switch
              color="primary"
              checked={isRelevanceAttribute}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const value = e.target.checked
                setIsRelevanceProperty(value)
                if (!value) {
                  dispatch({
                    type: 'resetRelevanceAttribute',
                    payload: undefined,
                  })
                }
              }}
            />
          </Box>
        </Box>

        <ul>
          {state.funnelSteps.map((item, index) => (
            // 使用 index 作為 key，若有排序需求需自行產生 id
            <StepsListItem key={index}>
              <StepFilter
                state={item}
                currIndex={index}
                isDeletable={state.funnelSteps.length > 1}
                isReplicable={state.funnelSteps.length < FUNNEL_STEPS_MAX_COUNT}
                isRelevanceAttribute={isRelevanceAttribute}
                dispatch={dispatch}
              />
            </StepsListItem>
          ))}
        </ul>

        <CategoryList
          categories={categoriesOfClassification}
          anchorElem={
            <ConditionalTooltip
              title={t('audience:v3.filter_max_hint_event_analytics', {
                max: FUNNEL_STEPS_MAX_COUNT,
              })}
              isShow={isDisabledAddButton}
            >
              <AddButton
                label={t('analytics:funnel.step_add')}
                size="small"
                disabled={isDisabledAddButton}
              />
            </ConditionalTooltip>
          }
          isDisabled={isDisabledAddButton}
          onValueChanged={eventName => {
            dispatch({
              type: 'addStep',
              payload: { eventName },
            })
          }}
        />
      </Card>

      <Card>
        <StatisticMeasureGroupingFilter
          categoryList={categoriesOfIntersectionEvents}
          error={errors.groupByFields}
          eventNameList={eventNameList}
          values={state.groupByFields}
          onAdd={({ source, field }) => {
            dispatch({
              type: 'addGroupByFields',
              payload: { field, source },
            })
          }}
          onUpdate={({ source, field, index: targetIndex }) => {
            dispatch({
              type: 'updateGroupByFields',
              payload: { targetIndex, field, source },
            })
          }}
          onDelete={targetIndex => {
            dispatch({
              type: 'removeGroupByFields',
              payload: {
                targetIndex,
              },
            })
          }}
        />
      </Card>
    </>
  )
}

export default memo(FunnelAnalyticsFilter)
