import { useTranslation } from 'react-i18next'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import Box from '@mui/material/Box'

import RequireIAM from '@features/auth/ui/RequireIAM'
import noPermissionBgUrl from '@shared/assets/images/iamModule/customerData.png'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { TabItem, TabWrapper } from '@shared/ui/tabs'

import Journeys from './Journeys'
import Overview from './Overview'
import Tag from './Tag'

export const UserProfileDetailRoutes = () => {
  const { path, url } = useRouteMatch()

  const { pathname } = useLocation()
  const { userId } = useParams<{ userId: string }>()

  const { t } = useTranslation(['common, iam'])

  return (
    <RequireIAM
      moduleName="user_profile"
      noPermission={{
        title: t('common:route.user_profile'),
        description: t('iam:no_permission'),
        bgImageUrl: noPermissionBgUrl,
      }}
    >
      <Breadcrumbs>
        <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.userProfile}`}>
          {t('common:route.user_profile')}
        </Link>
        <Box>{userId}</Box>
      </Breadcrumbs>

      <TabWrapper>
        <Link to={url}>
          <TabItem isSelected={url === pathname}>
            {t(`userProfile:layout.tab.overview`)}
          </TabItem>
        </Link>

        <Link to={`${url}/${PAGE_ROOT.tags}`}>
          <TabItem isSelected={`${url}/${PAGE_ROOT.tags}` === pathname}>
            {t(`userProfile:layout.tab.tag`)}
          </TabItem>
        </Link>

        <Link to={`${url}/${PAGE_ROOT.journeys}`}>
          <TabItem isSelected={`${url}/${PAGE_ROOT.journeys}` === pathname}>
            {t(`userProfile:tab_journey`)}
          </TabItem>
        </Link>
      </TabWrapper>

      <Switch>
        <Route exact path={path}>
          <Overview />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.tags}`}>
          <Tag />
        </Route>

        <Route path={`${path}/${PAGE_ROOT.journeys}`}>
          <Journeys userId={userId} />
        </Route>

        <Redirect to={path} />
      </Switch>
    </RequireIAM>
  )
}

export default UserProfileDetailRoutes
