import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import TagGroupList from './TagGroupList'

export const TagGroupRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <TagGroupList />
      </Route>

      <Redirect to={path} />
    </Switch>
  )
}

export default TagGroupRoutes
