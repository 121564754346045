import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { resetGoogleAuth } from '@entities/auth/model/slices'
import { useGetGoogleAdsCustomerListQuery } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'

const STATUS_CODE = {
  UNAUTHORIZED: 401,
  NO_PERMISSION: 403,
}

const UNAUTHORIZED_OAUTH_TOKEN_GOOGLE_ADS =
  'OAuth token for Google Ads is unauthorized'

type UseCustomerListParams = {
  googleAccessToken: string
  isGoogleAccessTokenValid: boolean
}

export const useCustomerList = ({
  googleAccessToken,
  isGoogleAccessTokenValid,
}: UseCustomerListParams) => {
  const {
    data: customerList,
    isSuccess: isGetCustomerListSuccess,
    error: customerListError,
  } = useGetGoogleAdsCustomerListQuery(googleAccessToken, {
    skip: !isGoogleAccessTokenValid,
  })

  const dispatch = useAppDispatch()

  const { t } = useTranslation(['audience'])

  useEffect(() => {
    if (
      isGetCustomerListSuccess &&
      (!customerList || customerList.length === 0)
    ) {
      dispatch(
        openToast({
          message: t('audience:export_google_ads.failure_no_account'),
          status: 'error',
        })
      )
    }
  }, [customerList, dispatch, isGetCustomerListSuccess, t])

  useEffect(() => {
    if (!customerListError) {
      return
    }

    const typedError = formatRTKQueryError(customerListError)

    if (
      typedError.statusCode === STATUS_CODE.UNAUTHORIZED &&
      typedError.message === UNAUTHORIZED_OAUTH_TOKEN_GOOGLE_ADS
    ) {
      dispatch(resetGoogleAuth())
      return
    }

    // IAM 權限不足
    if (typedError.statusCode === STATUS_CODE.NO_PERMISSION) {
      dispatch(resetGoogleAuth())
      dispatch(handleApiError(typedError))
      return
    }

    dispatch(handleApiError(typedError))
  }, [dispatch, customerListError])

  return isGoogleAccessTokenValid ? customerList : undefined
}

export default useCustomerList
