import styled from '@emotion/styled'
import IconButton from '@mui/material/IconButton'

import { NODE_CONTENT_MIN_HEIGHT, NODE_WIDTH } from '../constants'

export const DeleteBtnContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  transform: translate(60%, -60%);

  opacity: 0;
  transition: opacity 0.15s;
`

export const DeleteBtn = styled(IconButton)`
  && {
    background-color: ${({ theme }) => theme.colors.blackTwo};

    &:hover {
      background-color: ${({ theme }) => theme.colors.black};
    }
  }
`

export const Container = styled.div<{ isError?: boolean }>`
  position: relative;

  width: ${NODE_WIDTH}px;

  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.colors.chartRed : theme.colors.blackTwo};
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 9px ${({ theme }) => theme.colors.black25};

  transition: border 0.25s;

  &:hover ${DeleteBtnContainer} {
    opacity: 1;
  }
`

export const NodeContent = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 16px;
  padding-top: 8px;

  min-height: ${NODE_CONTENT_MIN_HEIGHT}px;
  border-top: 1px solid ${({ theme }) => theme.colors.black6};
`

export const PointerEventsInitial = styled.div`
  cursor: default;
  /* 為了讓 node disable 時仍能查看 tooltip 內容 */
  pointer-events: initial;
`
