import { ICON } from '@shared/model/constants/styles'
import theme from '@theme'

import { EVENT_ICON_MAP } from '../constants'
import type { UserJourneyEventType } from '../types'

const DEFAULT_ICON = {
  color: theme.colors.textSecondBlue,
  icon: ICON.checkSquare,
  sx: {},
} as const

export const getEventIcon = (eventId: string) =>
  EVENT_ICON_MAP[eventId as UserJourneyEventType] ?? DEFAULT_ICON

export default getEventIcon
