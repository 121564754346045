import type { Category } from '@shared/ui/Dropdown/CategoryList'

export const createMetadataPropertyId = (source: string, field: string) =>
  `${source}.${field}`

export const resolveMetadataPropertyId = (metadataPropertyId: string) => {
  const [source, field] = metadataPropertyId.split('.')
  return { source, field }
}

export const mergeMetadataCategoryById = (arr: Category[]): Category[] => {
  const categoriesMap = new Map()
  arr.forEach(item => {
    const prevItem = categoriesMap.get(item.id)

    categoriesMap.set(item.id, {
      ...prevItem,
      ...item,
      options: [...(prevItem?.options ?? []), ...item.options],
    })
  })

  return Array.from(categoriesMap.values())
}
