import {
  ChartDataFromApi,
  ConversionRateData,
  FunnelChartData,
  FunnelChartSeriesData,
  MetadataEventEntity,
} from '@shared/api/rtkQuery'

import { getGroupByValues } from './getGroupByValues'

export const transformPreviewResponseBarChart = (
  res: ChartDataFromApi,
  eventEntity: MetadataEventEntity
) => {
  const categories = res.event_step_names.map(
    eventName =>
      eventEntity.events.entities[eventName]?.displayName || eventName
  )

  if (res.all_days_statistic === null) {
    return {
      totalConversionData: {
        label: '',
        conversionRate: 0,
        conversionNumber: 0,
      },
      groupConversionRates: [],
      chart: {
        categories: [],
        series: [],
      },
    }
  }

  // 漏斗圖用的 groupConversionRates 只用來顯示每個 group 的總轉換率
  const groupConversionRates: ConversionRateData[] = [
    {
      label: '',
      conversionRate: res.all_days_statistic.all_step_conversion_rate,
      conversionNumber:
        res.all_days_statistic.funnel_statistics[
          res.all_days_statistic.funnel_statistics.length - 1
        ].unique_user,
    },
  ]
  res.all_days_group_statistics.forEach(d => {
    groupConversionRates.push({
      label: getGroupByValues(d.group_by_values),
      conversionRate: d.all_step_conversion_rate,
      conversionNumber:
        d.funnel_statistics[d.funnel_statistics.length - 1].unique_user,
    })
  })

  const barChartSeries: FunnelChartSeriesData[] = [
    res.all_days_statistic.funnel_statistics.reduce(
      (acc, curr, idx) => {
        const lostRate = 100 - curr.conversion_rate
        const prevUniqueUser =
          idx === 0
            ? 0
            : res.all_days_statistic.funnel_statistics[idx - 1].unique_user
        const lostNumber = idx === 0 ? 0 : prevUniqueUser - curr.unique_user

        acc.data.push(curr.conversion_rate)
        acc.rawData.push(curr.unique_user)
        acc.lostRate.push(lostRate)
        acc.lostNumber.push(lostNumber)
        return acc
      },
      {
        name: '',
        data: [],
        rawData: [],
        lostRate: [],
        lostNumber: [],
      } as FunnelChartSeriesData
    ),
  ]

  const barChartGroupSeries: FunnelChartSeriesData[] =
    res.all_days_group_statistics.map(d =>
      d.funnel_statistics.reduce(
        (acc, curr, idx) => {
          const lostRate = 100 - curr.conversion_rate
          const prevUniqueUser =
            idx === 0 ? 0 : d.funnel_statistics[idx - 1].unique_user
          const lostNumber = idx === 0 ? 0 : prevUniqueUser - curr.unique_user

          acc.data.push(curr.conversion_rate)
          acc.rawData.push(curr.unique_user)
          acc.lostRate.push(lostRate)
          acc.lostNumber.push(lostNumber)
          return acc
        },
        {
          name: getGroupByValues(d.group_by_values),
          data: [],
          rawData: [],
          lostRate: [],
          lostNumber: [],
        } as FunnelChartSeriesData
      )
    )

  const chart: FunnelChartData = {
    categories,
    series: res.all_days_group_statistics.length
      ? barChartGroupSeries
      : barChartSeries,
  }

  return {
    totalConversionData: {
      label: '',
      conversionRate: groupConversionRates[0].conversionRate,
      conversionNumber: groupConversionRates[0].conversionNumber,
    },
    groupConversionRates,
    chart,
  }
}
