import { useTranslation } from 'react-i18next'

import { CreationAndExportStatus } from '@shared/model/constants/listStatus'
import {
  ButtonWithCompleteIcon,
  ButtonWithFailedIcon,
  ButtonWithProcessingIcon,
} from '@shared/ui/buttons'
import { DeepBlueGradientButton } from '@shared/ui/buttons'

const EXPORT_BUTTON_WIDTH = 192

type Props = {
  status: CreationAndExportStatus
  onExportCSV: () => void
  onDownloadCSV: () => void
  isDisabled: boolean
  isExpired: boolean
  buttonText?: {
    export?: string
    processing?: string
    download?: string
    tryAgain?: string
  }
}

const CSVButton = ({
  status,
  onExportCSV: handleExportCSV,
  onDownloadCSV: handleDownloadCSV,
  isDisabled = true,
  isExpired = false,
  buttonText,
}: Props) => {
  const { t } = useTranslation(['common', 'audience'])

  if (status === 'processing' || status === 'initialized') {
    return (
      <ButtonWithProcessingIcon
        width={EXPORT_BUTTON_WIDTH}
        wording={buttonText?.processing || t('audience:processing')}
      />
    )
  }

  if (status === 'none' || isExpired) {
    return (
      <DeepBlueGradientButton
        width={EXPORT_BUTTON_WIDTH}
        onClick={handleExportCSV}
        disabled={isDisabled}
      >
        {buttonText?.export || t('common:export_manual')}
      </DeepBlueGradientButton>
    )
  }

  if (status === 'completed') {
    return (
      <ButtonWithCompleteIcon
        width={EXPORT_BUTTON_WIDTH}
        wording={buttonText?.download || t('audience:Download')}
        onClick={handleDownloadCSV}
      />
    )
  }

  if (status === 'failed') {
    return (
      <ButtonWithFailedIcon
        width={EXPORT_BUTTON_WIDTH}
        wording={buttonText?.tryAgain || t('audience:try_again')}
        onClick={handleExportCSV}
      />
    )
  }

  return null
}

export default CSVButton
