import snakecaseKeys from 'snakecase-keys'

import { PrivacyLevel } from '../analytics'
import { api, PaginationParams } from '../api'
import { FunnelRuleGroup } from './funnelGroupType'

const funnelGroupsApi = api.injectEndpoints({
  endpoints: builder => ({
    createFunnelRuleGroup: builder.mutation<
      void,
      { name: string; iam: { ownerId: string } | null }
    >({
      query: payload => ({
        url: '/funnel_groups',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: [{ type: 'FunnelRuleGroup', id: 'LIST' }],
    }),
    updateFunnelRuleGroup: builder.mutation<void, { id: number; name: string }>(
      {
        query: payload => ({
          url: `/funnel_groups/${payload.id}`,
          method: 'PUT',
          body: snakecaseKeys(payload, { deep: true }),
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'FunnelRuleGroup', id: arg.id },
        ],
      }
    ),
    deleteFunnelRuleGroup: builder.mutation<void, number>({
      query: id => ({
        url: `/funnel_groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'FunnelRuleGroup', id }],
    }),
    getFunnelRuleGroupList: builder.query<
      FunnelRuleGroup[],
      PaginationParams & { funnelRuleType: PrivacyLevel }
    >({
      query: params => ({
        url: 'funnel_groups',
        params: snakecaseKeys(params, { deep: true }),
      }),
      providesTags: (result, error, privacyLevel) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'FunnelRuleGroup' as const,
                id,
              })),
              { type: 'FunnelRuleGroup', id: 'LIST' },
            ]
          : [{ type: 'FunnelRuleGroup', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateFunnelRuleGroupMutation,
  useUpdateFunnelRuleGroupMutation,
  useDeleteFunnelRuleGroupMutation,
  useGetFunnelRuleGroupListQuery,
} = funnelGroupsApi
