// 操作replacement_data的部分, 會透過command的方式, 由外部傳入的onReplacementDataChange, onReplacementDataDelete來處理

import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getNodeByKey,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  createCommand,
  LexicalCommand,
} from 'lexical'

import { ReplacementData, ReplacementDataMetadata } from '@shared/api/rtkQuery'

import {
  $createMetadataTagNode,
  $isMetadataTagNode,
  MetadataTagNode,
} from './nodes/MetadataTagNode'

type InsertMetadataTagCommand = {
  displayName: string
  replacementKey: string
  replacementData: ReplacementDataMetadata
}

type DeleteMetadataTagCommand = {
  nodeKey: string
  replacementKey: string
}

export const INSERT_METADATA_TAG_COMMAND: LexicalCommand<InsertMetadataTagCommand> =
  createCommand('INSERT_METADATA_TAG_NODE_COMMAND')

export const DELETE_METADATA_TAG_COMMAND: LexicalCommand<DeleteMetadataTagCommand> =
  createCommand('DELETE_METADATA_TAG_COMMAND')

type Props = {
  replacementData?: ReplacementData
  onReplacementDataChange: (replacementData: ReplacementData) => void
}

const MetadataTagPlugin = ({
  replacementData,
  onReplacementDataChange,
}: Props): null => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([MetadataTagNode])) {
      throw new Error(
        'MetadataTagPlugin: MetadataTagNode not registered on editor (initialConfig.nodes)'
      )
    }

    return mergeRegister(
      editor.registerCommand<InsertMetadataTagCommand>(
        INSERT_METADATA_TAG_COMMAND,
        payload => {
          const node = $createMetadataTagNode(
            payload.displayName,
            payload.replacementKey
          )
          $insertNodes([node])

          // 如果是parent是root, 就包一層p, 游標會自動移到p的最後面
          if ($isRootOrShadowRoot(node.getParentOrThrow())) {
            $wrapNodeInElement(node, $createParagraphNode).selectEnd()
          }

          onReplacementDataChange({
            ...replacementData,
            [payload.replacementKey]: payload.replacementData,
          })

          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand<DeleteMetadataTagCommand>(
        DELETE_METADATA_TAG_COMMAND,
        payload => {
          const node = $getNodeByKey(payload.nodeKey)

          if (node && $isMetadataTagNode(node) && replacementData) {
            const cloneReplacementData = { ...replacementData }

            node.remove()

            delete cloneReplacementData[payload.replacementKey]

            onReplacementDataChange(cloneReplacementData)
          }

          return true
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, onReplacementDataChange, replacementData])

  return null
}

export default MetadataTagPlugin
