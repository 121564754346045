import snakecaseKeys from 'snakecase-keys'

import type { PrivacyLevel } from '../analytics'
import { api, PaginationParams } from '../api'
import type { EventRuleGroup } from './eventGroupType'

const eventGroupsApi = api.injectEndpoints({
  endpoints: builder => ({
    createEventRuleGroup: builder.mutation<
      void,
      { name: string; iam: { ownerId: string } | null }
    >({
      query: payload => ({
        url: '/event_groups',
        method: 'POST',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: [{ type: 'EventRuleGroup', id: 'LIST' }],
    }),
    updateEventRuleGroup: builder.mutation<void, { id: number; name: string }>({
      query: payload => ({
        url: `/event_groups/${payload.id}`,
        method: 'PUT',
        body: snakecaseKeys(payload, { deep: true }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'EventRuleGroup', id: arg.id },
      ],
    }),
    deleteEventRuleGroup: builder.mutation<void, number>({
      query: id => ({
        url: `/event_groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'EventRuleGroup', id }],
    }),
    getEventRuleGroupList: builder.query<
      EventRuleGroup[],
      PaginationParams & { eventRuleType: PrivacyLevel }
    >({
      query: params => ({
        url: '/event_groups',
        params: snakecaseKeys(params, { deep: true }),
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'EventRuleGroup' as const,
                id,
              })),
              { type: 'EventRuleGroup', id: 'LIST' },
            ]
          : [{ type: 'EventRuleGroup', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetEventRuleGroupListQuery,
  useCreateEventRuleGroupMutation,
  useUpdateEventRuleGroupMutation,
  useDeleteEventRuleGroupMutation,
} = eventGroupsApi
