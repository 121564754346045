/**
 * 取得在圖表顯示的 label 名稱
 * @param eventName 用戶互動
 * @param aggregatorName 總次數
 * @returns 圖表顯示的 label 名稱
 */
export const getEventRulePieChartGroupLabelName = (
  eventName?: string,
  aggregatorName?: string
) => `${eventName} | ${aggregatorName}`
