import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'

import { formatRTKQueryError } from '@entities/apiHandler'
import { useCreateMsgSenderMutation } from '@shared/api/rtkQuery'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import {
  DialogActions,
  DialogContent,
  FormBuilderDialog,
} from '@shared/ui/dialogs'
import { FormBuilder, useFormBuilder } from '@shared/ui/forms'

import { STATUS_CODE } from './models/constants'
import type { FormValues } from './models/types'
import { useWorkflowSenderFormFields } from './hooks'

const WORKFLOW_LIST_PATH = `/${CATEGORY.ma}/${PAGE_ROOT.workflows}/${PAGE_ROOT.senders}`

export const WorkflowSenderCreate = () => {
  const formFields = useWorkflowSenderFormFields()

  const history = useHistory()

  const [createMsgSender, { isLoading: isCreating }] =
    useCreateMsgSenderMutation()

  const dispatch = useDispatch()

  const { t } = useTranslation(['workflow', 'common'])

  const { errors, handleFieldChange, submitForm, touched, values } =
    useFormBuilder({
      fields: formFields,
      initialValues: {
        integrationType: 'ses',
        name: '',
        email: '',
        confirm: false,
      },
      onSubmit: async (values: FormValues) => {
        try {
          await createMsgSender(values).unwrap()

          if (values.integrationType === 'ses') {
            dispatch(
              openToast({
                status: 'success',
                message: t('workflow:senders.need_validate'),
              })
            )
          }

          history.push(WORKFLOW_LIST_PATH)
        } catch (error) {
          const typedError = formatRTKQueryError(error)

          if (typedError.statusCode === STATUS_CODE.DUPLICATE_NAME_AND_EMAIL) {
            dispatch(
              openToast({
                status: 'error',
                message: t('workflow:senders.errors.duplicate'),
              })
            )

            return
          }

          if (typedError.statusCode === STATUS_CODE.SES_DOMAIN_NOT_VERIFIED) {
            dispatch(
              openToast({
                status: 'error',
                message: t('workflow:senders.errors.not_verified'),
              })
            )

            return
          }

          if (typedError.statusCode === STATUS_CODE.EXCEEDED_CREATE_LIMIT) {
            dispatch(
              openToast({
                status: 'error',
                message: t('workflow:senders.errors.exceeded_limit'),
              })
            )

            return
          }

          dispatch(
            openToast({ status: 'error', message: t('common:failure_create') })
          )
        }
      },
    })

  const handleClose = () => {
    history.push(WORKFLOW_LIST_PATH)
  }

  return (
    <FormBuilderDialog
      isLoading={isCreating}
      isOpen
      modalTitle={t('workflow:senders.title')}
      onClose={handleClose}
    >
      <FormBuilder
        component={DialogContent}
        errors={errors}
        fields={formFields}
        onFieldChange={handleFieldChange}
        touched={touched}
        values={values}
      />

      <DialogActions>
        <Button color="inherit" disabled={isCreating} onClick={handleClose}>
          {t('common:cancel')}
        </Button>

        <Button type="submit" disabled={isCreating} onClick={submitForm}>
          {t('common:confirm')}
        </Button>
      </DialogActions>
    </FormBuilderDialog>
  )
}

export default WorkflowSenderCreate
