import type { Action, PayloadAction } from '@reduxjs/toolkit'
import type { Reducer } from 'use-immer'

import {
  aggregatorOperatorMap,
  defaultAggregatorOperatorParamsMap,
} from '@features/filters/_shared'
import {
  getDefaultAttributeParams,
  getDimOperators,
  metadataTypeAttributeOperatorMap,
  resolveUiOperator,
} from '@features/filters/AttributeFilter'
import {
  Aggregator,
  AggregatorOperator,
  AggregatorParameters,
  AttributeConditionFilterNode,
  EventOperator,
  Measure,
  MeasureFilterNode,
  MetadataType,
} from '@shared/api/rtkQuery'
import { DEFAULT_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType, getToday } from '@shared/ui/DateRangePicker'

import { defaultMeasureMap } from './utils'

const DEFAULT_AGGREGATOR: Aggregator = 'count'
const DEFAULT_AGGREGATOR_OPERATOR: AggregatorOperator = '='

export const createMeasureFilterInitialState = (
  eventName: string = ''
): MeasureFilterNode => {
  const today = getToday()
  return {
    type: 'measure',
    attributeFilter: {
      nodes: [],
      combination: 'and',
    },
    eventName,
    eventOperator: DEFAULT_AGGREGATOR_OPERATOR,
    params: defaultAggregatorOperatorParamsMap.get(DEFAULT_AGGREGATOR_OPERATOR),
    measure: defaultMeasureMap.get(DEFAULT_AGGREGATOR),
    timeRangeType: 'absolute_between',
    timeRangeParams: [today.nowStart.toISOString(), today.nowEnd.toISOString()],
  }
}

export type MeasureFilterAction =
  | Action<'toggleAttributeFilterCombination'>
  | PayloadAction<
      {
        field: string
        source: string
        dataType: MetadataType
        repeated: boolean
      },
      'addAttributeFilterCondition'
    >
  | PayloadAction<{ sourceIndex: number }, 'cloneAttributeFilterCondition'>
  | PayloadAction<
      {
        index: number
        value: AttributeConditionFilterNode
      },
      'updateAttributeFilterCondition'
    >
  | PayloadAction<number, 'removeAttributeFilterCondition'>
  | PayloadAction<EventOperator | '', 'updateEventOperator'>
  | PayloadAction<Pick<Measure, 'aggregator' | 'field'>, 'updateMeasure'>
  | PayloadAction<string, 'updateEventName'>
  | PayloadAction<NumberOrString[], 'updateTimeRangeParams'>
  | PayloadAction<AggregatorParameters, 'updateParams'>

export const reducer: Reducer<MeasureFilterNode, MeasureFilterAction> = (
  draft,
  action
) => {
  switch (action.type) {
    case 'toggleAttributeFilterCombination':
      draft.attributeFilter.combination =
        draft.attributeFilter.combination === 'and' ? 'or' : 'and'
      break
    case 'addAttributeFilterCondition': {
      const { source, field, dataType, repeated } = action.payload

      const [firstOperator] = metadataTypeAttributeOperatorMap[dataType]

      const [firstDimOperator] = getDimOperators(source, dataType, repeated)

      const defaultParams = getDefaultAttributeParams(dataType, firstOperator)

      draft.attributeFilter.nodes.push({
        type: 'condition',
        source,
        field,
        dimOperator: firstDimOperator,
        operator: resolveUiOperator(firstOperator),
        params: defaultParams,
      })
      break
    }
    case 'cloneAttributeFilterCondition':
      draft.attributeFilter.nodes.push(
        draft.attributeFilter.nodes[action.payload.sourceIndex]
      )
      break
    case 'updateAttributeFilterCondition':
      draft.attributeFilter.nodes[action.payload.index] = action.payload.value
      break
    case 'removeAttributeFilterCondition':
      draft.attributeFilter.nodes.splice(action.payload, 1)
      break
    case 'updateEventOperator': {
      draft.eventOperator = action.payload

      // 未發生
      if (action.payload === 'not_happened') {
        draft.measure = undefined
        draft.params = undefined
        return
      }

      // 發生
      if (action.payload === '') {
        const { measure, params } = createMeasureFilterInitialState()
        draft.measure = measure
        draft.params = params
        return
      }

      // 其他透過 AggregatorFilter 更新的 Operator
      const currDefaultAggregatorValue = defaultAggregatorOperatorParamsMap.get(
        action.payload
      )
      draft.params = currDefaultAggregatorValue
      break
    }
    case 'updateMeasure': {
      const { aggregator, field } = action.payload
      const predefinedMeasure = defaultMeasureMap.get(aggregator)
      draft.measure = predefinedMeasure ?? {
        aggregator,
        field,
        source: DEFAULT_EVENT_TABLE_NAME,
      }
      const [firstOperator] = aggregatorOperatorMap[aggregator]
      const defaultParams =
        defaultAggregatorOperatorParamsMap.get(firstOperator)
      draft.eventOperator = firstOperator
      draft.params = defaultParams
      break
    }
    case 'updateEventName':
      draft.eventName = action.payload

      if (draft.eventOperator !== 'not_happened') {
        // 使用者可能會填入不支援的格式，因此將 measure 重設為預設值
        const { measure, eventOperator, params } =
          createMeasureFilterInitialState()
        draft.measure = measure
        draft.eventOperator = eventOperator
        draft.params = params
      }
      break
    case 'updateTimeRangeParams':
      draft.timeRangeType = getRangeType(action.payload)
      draft.timeRangeParams = action.payload
      break
    case 'updateParams':
      draft.params = action.payload
      break
    default:
      return draft
  }
}
