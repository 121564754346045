import { useParams } from 'react-router-dom'

import { useGetUserProfileEventReportQuery } from '@shared/api/rtkQuery'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import ReportTable from './ReportTable'

const SectionEventReport = () => {
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const {
    data: userProfileEventReport = undefined,
    isLoading: isUserProfileEventReportLoading,
  } = useGetUserProfileEventReportQuery({
    cdpUserId,
  })

  if (isUserProfileEventReportLoading) {
    return <LoadingSkeleton height={240} />
  }

  if (userProfileEventReport) {
    return <ReportTable userProfileEventReport={userProfileEventReport} />
  }

  return <></>
}

export default SectionEventReport
