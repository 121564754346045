import { useMemo } from 'react'
import { useImmerReducer } from 'use-immer'

import { useCategoriesOfIntersectionEvents } from '@widgets/analytics/_shared'

import {
  createInitialEventRuleNode,
  eventRuleFilterReducer,
  initializer,
} from '../models/eventRuleFilterReducer'
import {
  getEventRuleFilterErrors,
  selectStatisticMeasureEventNameList,
} from '../utils'

export const useEventRuleFilter = () => {
  const [filter, dispatch] = useImmerReducer(
    eventRuleFilterReducer,
    createInitialEventRuleNode(),
    initializer
  )

  const eventNameList = selectStatisticMeasureEventNameList(filter)

  const { groupByFields = [] } = filter

  const categoriesOfIntersectionEvents = useCategoriesOfIntersectionEvents(
    eventNameList,
    { isNeedDimensionColumnFromEvent: true, groupByFields }
  )

  const checkFilterHasError = useMemo(() => {
    const intersectionEventAttributeList =
      categoriesOfIntersectionEvents.flatMap(x => x.options.map(y => y.value))

    return getEventRuleFilterErrors(intersectionEventAttributeList)
  }, [categoriesOfIntersectionEvents])

  const errors = useMemo(
    () => checkFilterHasError(filter),
    [checkFilterHasError, filter]
  )

  return { filter, dispatch, errors }
}

export default useEventRuleFilter
