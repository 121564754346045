import type { ParseKeys } from 'i18next'

import type {
  ExportUpdatePolicy,
  SchedulingType,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'

export const EXPORT_BUTTON_WIDTH = 192

export const EXPORT_BUTTON_TEXT_MAP: Record<
  SchedulingType,
  ParseKeys<['common']>
> = {
  // 手動匯出
  none: 'common:export_manual',
  // 自動匯出
  default: 'common:export_auto',
}

export const UPDATE_POLICY_TEXT_MAP: Record<
  'LABEL' | 'TOOLTIP',
  Record<ExportUpdatePolicy, ParseKeys<['audience']>>
> = {
  LABEL: {
    // 新增
    add: 'audience:export.update_policy_add',
    // 取代
    replace: 'audience:export.update_policy_replace',
  },
  TOOLTIP: {
    // 將新增名單加入
    add: 'audience:export.update_policy_add_tooltip',
    // 將整批名單全部取代
    replace: 'audience:export.update_policy_replace_tooltip',
  },
}

export const UPDATE_PERIOD_WEEKLY_TEXT_MAP: Record<
  number,
  ParseKeys<['common']>
> = {
  0: 'common:week.0',
  1: 'common:week.1',
  2: 'common:week.2',
  3: 'common:week.3',
  4: 'common:week.4',
  5: 'common:week.5',
  6: 'common:week.6',
}

export const SUPPORT_EXPORT_SMS_PROVIDER_LIST: ThirdPartyAppProvider[] = [
  'every8d',
  'every8d_exclusive',
]
