import {
  MEDIUM_GROUP_ACTION_INDEX,
  MEDIUM_GROUP_INDEX,
  MEDIUM_GROUP_MEDIUM_TYPE_INDEX,
} from '@features/filters/CampaignEventFilter/MeasureFilter/constants'
import type {
  CampaignEventMediumEventFilterNode,
  CampaignEventMediumTypeFilterNode,
  CampaignMeasureFilterNode,
} from '@shared/api/rtkQuery'

const findMediumGroupField = <T extends 'medium_type' | 'action'>(
  data:
    | CampaignMeasureFilterNode['attributeFilter']['nodes'][typeof MEDIUM_GROUP_INDEX]
    | undefined,
  field: T
) => {
  const fieldIndex =
    field === 'medium_type'
      ? MEDIUM_GROUP_MEDIUM_TYPE_INDEX
      : MEDIUM_GROUP_ACTION_INDEX

  return data?.nodes?.[fieldIndex] as T extends 'medium_type'
    ? CampaignEventMediumTypeFilterNode
    : CampaignEventMediumEventFilterNode
}

export default findMediumGroupField
