import { useEffect } from 'react'
import axios from 'axios'

import { useLazyExportEventRuleCSVQuery } from '@shared/api/rtkQuery'
import type { NumberOrString } from '@shared/lib/utils/type'

import { getExportCsvFileName } from '../utils'

export const useExportEventRule = (timeRangeParams: NumberOrString[]) => {
  const [exportEventRule, { data: exportResult, isFetching: isExporting }] =
    useLazyExportEventRuleCSVQuery()

  const exportDownloadURL = exportResult?.downloadUrl
  const exportCsvFileName = getExportCsvFileName(timeRangeParams)

  useEffect(() => {
    if (exportDownloadURL) {
      axios.get(exportDownloadURL).then(res => {
        const blob = new Blob([res.data], {
          type: 'text/csv;charset=utf-8;',
        })
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(blob)
        a.download = exportCsvFileName
        a.style.display = 'none'

        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    }
    // 僅在 exportDownloadURL 變化時，重新下載 CSV 檔案
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportDownloadURL])

  return [exportEventRule, { isExporting }] as const
}
