import { createContext, useContext, useState } from 'react'

export type WorkflowFormState = {
  isEditable: boolean
  isSubmitted: boolean
}

// ref: https://github.com/kentcdodds/react-performance/blob/main/src/final/05.extra-1.js
// 將 state 與 setState 拆成兩個 context，不會因為解構賦值造成每次更新而渲染
const WorkflowFormStateContext = createContext<WorkflowFormState | null>(null)
const WorkflowSetFormStateContext = createContext<React.Dispatch<
  React.SetStateAction<WorkflowFormState>
> | null>(null)

const WorkflowFormStateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [formState, setFormState] = useState<WorkflowFormState>({
    isSubmitted: false,
    isEditable: false,
  })

  return (
    <WorkflowFormStateContext.Provider value={formState}>
      <WorkflowSetFormStateContext.Provider value={setFormState}>
        {children}
      </WorkflowSetFormStateContext.Provider>
    </WorkflowFormStateContext.Provider>
  )
}

export function useWorkflowFormState() {
  const context = useContext(WorkflowFormStateContext)
  if (!context) {
    throw new Error(
      'useWorkflowFormState must be used within the WorkflowFormStateProvider'
    )
  }

  return context
}

export function useWorkflowSetFormState() {
  const setContext = useContext(WorkflowSetFormStateContext)
  if (!setContext) {
    throw new Error(
      'useWorkflowSetFormState must be used within the WorkflowFormStateProvider'
    )
  }

  return setContext
}

export default WorkflowFormStateProvider
