import { api, PaginationParams, transformResponseCamelCaseKeys } from '../api'
import type {
  Tag,
  TagPayload,
  TagPreview,
  TagRule,
  TagRuleValues,
  TagType,
  TagUserSummary,
} from './type'

export const DEFAULT_TAG_LIST_PRE_PAGE = 300
export const TAG_TYPE_INTERNAL_OFFLINE: TagType = 'internal_offline'
export const TAG_TYPE_INTERNAL: TagType = 'internal'
export const TAG_TYPE_OFFLINE: TagType = 'offline'

const tagApi = api.injectEndpoints({
  endpoints: builder => ({
    tagList: builder.query<Tag[], PaginationParams & { usable?: boolean }>({
      query: params => ({
        url: '/tags',
        params,
      }),
      transformResponse: data => transformResponseCamelCaseKeys(data as Tag[]),
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Tag' as const,
                id,
              })),
              { type: 'Tag', id: 'LIST' },
            ]
          : [{ type: 'Tag', id: 'LIST' }],
    }),
    createTag: builder.mutation<Tag, TagPayload>({
      query: payload => ({
        url: `/tags`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
    }),
    createTagRule: builder.mutation<
      void,
      {
        id: number
        body: TagRuleValues
      }
    >({
      query: ({ id, body }) => ({
        url: `/tags/${id}/tag_rules`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tag', id: arg.id }],
    }),
    postTagJob: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/tags/${id}/tag_jobs`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tag', id: arg.id }],
    }),
    getTag: builder.query<Tag, number>({
      query: id => `/tags/${id}`,
      transformResponse: data => transformResponseCamelCaseKeys(data as Tag),
      providesTags: (result, error, id) => [{ type: 'Tag', id }],
    }),
    updateTag: builder.mutation<Tag, Partial<TagPayload & { id: number }>>({
      query: payload => ({
        url: `/tags/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tag', id: arg.id }],
    }),
    updateTagRule: builder.mutation<
      Tag,
      {
        tagId: number
        id: number
        body: Partial<TagRuleValues>
      }
    >({
      query: ({ tagId, id, body }) => ({
        url: `/tags/${tagId}/tag_rules/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tag', id: arg.tagId }],
    }),
    tagRuleList: builder.query<TagRule[], number>({
      query: id => ({
        url: `/tags/${id}/tag_rules`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as TagRule[]),
    }),
    deleteTag: builder.mutation<void, number>({
      query: id => ({
        url: `/tags/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Tag', id }],
    }),
    deleteTagRule: builder.mutation<void, { tagId: number; id: number }>({
      query: ({ tagId, id }) => ({
        url: `/tags/${tagId}/tag_rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Tag', id: arg.tagId }],
    }),
    tagUserSummaries: builder.query<
      TagUserSummary[],
      {
        id: number
        startDate: string
        endDate: string
      }
    >({
      query: ({ id, startDate, endDate }) => ({
        url: `/tags/${id}/user_summaries?start_date=${startDate}&end_date=${endDate}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as TagUserSummary[]),
      providesTags: () => ['TagSummary'],
    }),
    getTagPreview: builder.query<
      TagPreview,
      { tagRules: Pick<TagRuleValues, 'tagValue' | 'filter'>[] }
    >({
      query: body => ({
        url: `/tags/preview_user_summary`,
        method: 'POST',
        body,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as TagPreview),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateTagMutation,
  useCreateTagRuleMutation,
  useDeleteTagMutation,
  useDeleteTagRuleMutation,
  useLazyGetTagPreviewQuery,
  useGetTagQuery,
  usePostTagJobMutation,
  useTagListQuery,
  useTagRuleListQuery,
  useTagUserSummariesQuery,
  useUpdateTagMutation,
  useUpdateTagRuleMutation,
} = tagApi
