import type { TagProps } from '@shared/api/rtkQuery'
import type { Filter } from '@widgets/tag/Overview/_shared/type'
import {
  filterByCreateWay,
  filterBySearchText,
  filterByStatus,
  filterByUpdateWay,
} from '@widgets/tag/Overview/Grading/_shared/utils'

type Props = {
  data: TagProps[]
  filter: Filter
}

const useFilter = ({
  data,
  filter,
}: Props): { items: TagProps[]; totalCount: number } => {
  if (!filter) {
    return { items: data, totalCount: data.length }
  }

  const filteredData = data.filter(
    item =>
      filterBySearchText(item, filter.searchText as string) &&
      filterByStatus(item, filter.status) &&
      filterByCreateWay(item, filter.createMethod) &&
      filterByUpdateWay(item, filter.updateMethod)
  )

  return { items: filteredData, totalCount: filteredData.length }
}

export default useFilter
