import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import Detail from './Detail'
import Overview from './Overview'

export const NotificationRuleRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/new`} key={'new'}>
        <Detail isCreateMode />
      </Route>

      <Route exact path={path}>
        <Overview />
      </Route>

      <Route path={`${path}/:notificationRuleId`}>
        <Detail />
      </Route>

      <Redirect to={path} />
    </Switch>
  )
}

export default NotificationRuleRoutes
