import { createSelector } from '@reduxjs/toolkit'

import type { OmnichatTemplate } from '@shared/api/rtkQuery'
import type { Option } from '@shared/ui/CheckboxList'

export const selectOmnichatGroupListOption = createSelector<
  [
    (state: { data?: OmnichatTemplate[] }) => OmnichatTemplate[],
    (state: { isLoading: boolean }) => boolean
  ],
  { groupOption: Option[]; isGroupOptionLoading: boolean }
>(
  [state => state.data ?? [], state => state.isLoading],
  (list, isGroupOptionLoading) => ({
    groupOption: list.map(robot => ({
      label: robot.name,
      value: robot.id,
    })),
    isGroupOptionLoading,
  })
)

export const selectOmnichatTemplateListOption = createSelector<
  [
    (state: { data?: OmnichatTemplate[] }) => OmnichatTemplate[],
    (state: { isLoading: boolean }) => boolean
  ],
  { templateOption: Option[]; isTemplateLoading: boolean }
>(
  [state => state.data ?? [], state => state.isLoading],
  (templateList, isTemplateLoading) => ({
    templateOption: templateList.map(template => ({
      label: template.name,
      value: template.id,
    })),
    isTemplateLoading,
  })
)
