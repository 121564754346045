import styled from '@emotion/styled'
import MuiTableRow, {
  TableRowProps as MuiTableRowProps,
} from '@mui/material/TableRow'

type TableRowProps = {
  isClickable?: boolean
  isBodyCell?: boolean
  isAllowHoverOnChild?: boolean
  isStripped?: boolean
}

const TableRow = styled(
  ({
    isClickable,
    isBodyCell,
    isAllowHoverOnChild,
    isStripped,
    ...otherProps
  }: TableRowProps & MuiTableRowProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiTableRow {...otherProps} />
  )
)`
  && {
    ${({ isStripped, theme }) =>
      isStripped &&
      `
      &:nth-of-type(odd) {
        background-color: ${theme.colors.greyF5F5F5};
      }

      &:nth-of-type(even) {
        background-color: ${theme.colors.veryLightBlueTwo};
      }
    `}

    pointer-events: ${({ isAllowHoverOnChild, isClickable }) =>
      isAllowHoverOnChild || isClickable ? 'auto' : 'none'};

    :hover {
      ${({ isBodyCell, isClickable, theme }) =>
        isBodyCell &&
        isClickable &&
        `
            background-color: ${theme.colors.dodgerBlue5};
        `}

      ${({ isClickable }) =>
        isClickable &&
        `
            cursor: pointer;
        `}
    }
  }
`

export default TableRow
