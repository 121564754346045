import styled from '@emotion/styled'

import {
  Card,
  Content,
  List as ListNonOrder,
  ListItem as ListItemDot,
  PageTitle,
  Text1,
  Text2,
  UpdatedTime,
  Wrapper,
} from '@entities/PrivacyPolicy'
import { Footer, Header } from '@entities/ProductionIntro'
import { SpacingColumn, SpacingRow } from '@shared/ui/rows'

const ListOrder = styled.ol``

const ListItemNumber = styled(ListItemDot)`
  list-style: decimal;
`

const TermsOfService = () => {
  return (
    <Wrapper>
      <Header />

      <Content>
        <PageTitle>Terms of Service</PageTitle>

        <UpdatedTime>最後修正日期：2021年 06月 18日</UpdatedTime>

        <Card>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              iKala CDP 使用者條款（以下簡稱「本協議」），是 iKala
              CDP（以下簡稱「iKala CDP」、「本服務」、「本公司」或「我們」） 與
              iKala CDP
              的一般使用者（指未註冊成為會員之使用者，以下簡稱「使用者」、「一般使用者」或「您」）及註冊會員（以下簡稱「會員」或「您」）的共同協議。
              iKala CDP
              是由愛卡拉互動媒體股份有限公司所擁有的產品/服務，會員及所有使用者均為使用
              iKala CDP 服務的貴賓。您使用或訪問 iKala CDP 網站或任何 iKala CDP
              產品、軟體、資料源，以及所有通過 iKala CDP
              向您提供的服務時，即視為您已知悉、並完全同意本協議的所有約定，並同意受其及相關法律之約束。
            </Text2>

            <Text2>
              您應定期查閱本協議之最新版本。iKala CDP
              可依其自身決定隨時修改或更正本協議及相關政策；如您繼續使用本服務即表示您同意接受上述修改或更正的約束。
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第一條：本服務內容及費用</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              本服務之實際內容及您的使用權限及費用請參雙方簽訂之「顧客數據平台服務合約」。如您尚未與我們締結合約，請聯繫：cdp-support@ikala.tv
            </Text2>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第二條：本服務之提供方式</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '當您於 iKala CDP 完成簽約程序並由本公司完成設定後，您即取得本公司會員之資格，可以開始使用本服務之會員功能。',
                  '如本公司對個別活動另訂其他使用規範或約定時，您應同時遵守各活動之使用規範及相關規定；如各活動之使用規範或約定與本協議有所衝突時，本公司保留最終解釋適用之權利。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第三條：上傳內容之注意事項</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '您透過本服務所使用或上傳之數據、文件、文本、軟體、腳本、圖形、照片、互動功能和其他材料等（以下簡稱「內容」)，應自行擔保上傳內容未侵害第三方著作權、專利、商標、營業秘密、個人資料或其他第三方專有權利（以下合稱「第三方權利」）。',
                  '倘您上傳之內容所涉及之第三方權利非您所有，您於使用或上傳時應擔保您已獲得內容合法所有人的許可授權，並有權利進一步授予 iKala CDP 於本服務中進行利用。',
                  '倘本公司因您上傳至本服務平台之內容，遭受第三方主張侵害其權利時，本公司除得立即移除前述提交內容外，如因此導致本公司受損時，您應就本公司所受之損害負賠償責任（包括但不限於訴訟費用及律師費用等）。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第四條：禁止事項</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  {
                    first: '您不得直接或間接利用本公司服務，從事下列行為：',
                    second: [
                      '企圖入侵本公司伺服器、資料庫、破解本公司安全機制或其他危害本服務提供安全性或穩定性之行為。',
                      '對本服務的任何部分作改變或修改。',
                      '不得通過非經iKala CDP授權之其他技術或方法訪問本服務網站、程式及所有內容。',
                      '使用或運行任何自動化系統（包括但不限於，"機器人"、"蜘蛛" 或 "離線閱讀器"）造訪本服務。',
                      '直接或間接利用本公司服務，為任何違反法律或侵害他人權利之行為。',
                    ],
                  },
                  {
                    first: '未經 iKala CDP 之同意，您不得從事下列行為：',
                    second: [
                      '對本服務進行販售、出租或為其他未經本公司許可的營利行為。',
                    ],
                  },
                ].map(({ first, second }) => (
                  <ListItemNumber>
                    <SpacingRow marginBottomRatio={1}>{first}</SpacingRow>
                    <ListNonOrder>
                      {second.map(text => (
                        <SpacingRow marginLeftRatio={3}>
                          <ListItemDot>{text}</ListItemDot>
                        </SpacingRow>
                      ))}
                    </ListNonOrder>
                  </ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第五條：責任</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '當您使用本服務時，請您自行確認所使用之電腦、行動裝置或其他硬體設備、網路環境等均符合本服務得以正常提供之必備之條件。',
                  '您使用本服務所衍生之法律責任，由您自行負責，本公司依法將配合檢調單位之偵查，提供相關記錄或資訊。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第六條：風險承擔</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '您瞭解 iKala CDP 須透過連網提供相關服務，在穩定性、安全性上具有相當程度的風險。本公司所提供之各項資訊、電腦程式等，因電腦軟、硬體環境之不同，可能與您個人終端裝置或其他使用本公司服務之設備相衝突或造成系統不穩定。',
                  '本服務亦有可能因斷電或任何非得由本服務控制等因素所造成的網路連接、或因網路連接造成服務無法提供。',
                  '您同意自行承擔前述所有風險，對於本條前述原因所生之所受損害，本公司不負任何責任。',
                  '本服務可能會出現無預警中斷或故障之情形，並造成您無法使用、資料喪失、錯誤、或其他經濟上損失等情形，您同意除因本公司故意或重大過失所致者外，本公司不負任何責任。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第七條：服務終止</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '您瞭解並同意 iKala CDP 可於合理之預告期間後，根據營運之實際情況中斷本服務之全部或一部分服務，且不需對任何個人或第三方負責。',
                  '您同意本公司得自行判斷因任何理由，包含但不限於一定期間未使用、法院或政府機關命令、本服務無法繼續、服務內容實質變更、無法預期之技術或安全因素或問題、您所為詐欺或違法行為、未依約支付費用，或其他本公司認為會員已經違反本協議的明文規定及精神，而終止或限制會員使用帳號（或其任何部分）或本服務之使用，並將本服務內相關會員內容加以移除並刪除。',
                  '當我們對您終止本公司之全部或一部份之服務時，若涉及會員已預付費用之服務，則應於至少一個月前通知會員，並公告於本服務之明顯處所。若本公司全部或一部份之服務終止，將導致會員預付費用於終止時無法使用完畢時，本公司將依法公布退款辦法，以維護會員之權益。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第八條：本服務智慧財產權保護與軟體授權及下載規範</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '本服務所提供之影片、文字、聲音、圖形、電腦程式、商標等，其智慧財產權均屬於本公司或其授權人所有，非經本公司合法授權之利用行為，均屬智慧財產權之侵害。',
                  '本公司提供之服務及軟體僅供您使用本公司服務之用，會員及一般使用者僅得將本公司所提供之服務軟體，於使用本服務之目的作為授權範圍內利用，且同時應尊重智慧財產權，如有違反，違反者應對 iKala CDP 負損害賠償責任（包括但不限於訴訟費用及律師費用等），本公司並得逕予將您的帳號停權或為其他處置。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第九條：責任限制</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  {
                    first:
                      '您明確瞭解我們對於本公司所提供之服務，除本協議或另有規定者外，不提供任何明示或默示的擔保，前述不擔保之範圍包含但不限於商業適用性、特定目的之適用性及未侵害他人權利。',
                    second: [],
                  },
                  {
                    first: '本公司特別聲明對以下事項並不提供任何保證：',
                    second: [
                      '本服務符合會員之期待或需求。',
                      '本服務以永久、及時、不受干擾、安全、穩定、無錯誤的方式提供。',
                    ],
                  },
                  {
                    first:
                      '您同意我們對於非因本公司故意或重大過失所致之責任，無須負任何責任；若依法本公司須負法律責任時，您同意本公司所負之賠償責任上限，為您當年度使用本服務所支付予本公司之費用總額。',
                    second: [],
                  },
                ].map(({ first, second }) => (
                  <ListItemNumber>
                    <SpacingRow marginBottomRatio={1}>{first}</SpacingRow>
                    <ListNonOrder>
                      {second.map(text => (
                        <SpacingRow marginLeftRatio={3}>
                          <ListItemDot>{text}</ListItemDot>
                        </SpacingRow>
                      ))}
                    </ListNonOrder>
                  </ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第十條：免責聲明</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              在法律允許的最大限度內，iKala CDP
              茲聲明免予承擔所有有關本服務或與其連結的網站內容的準確性或完整性的保證、條件、條款和陳述，並且不承擔下列責任：
            </Text2>

            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <ListNonOrder>
                {[
                  '本服務中出現之錯誤或內容失實；',
                  '您訪問或使用本服務引起任何性質的人身傷害或財產損害；',
                  '未經授權訪問或使用有安全措施的伺服器和/或儲存在伺服器上的任何個人資訊和/或財務資訊；',
                  '我們服務的中斷或停止傳輸；',
                  '第三方可能通過我們的服務傳輸的任何軟體錯誤、病毒、木馬或類似性質的程式；和/或',
                  '任何內容中的錯誤或疏忽，或由於使用發佈、郵件傳送、傳輸或通過服務的其他方式獲得的內容，導致的任何形式的損失或損害。 iKala CDP 不保證、擔保或承擔由任何第三方通過服務或任何超連結服務或橫幅功能或其他廣告發佈或提供的產品或服務的責任，以及您與第三方提供商就商品或服務的交易。於前述情況下，您應當盡到自己最佳的判斷和合理的謹慎。',
                ].map(text => (
                  <ListItemDot>{text}</ListItemDot>
                ))}
              </ListNonOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第十一條：本協議修訂及生效</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <Text2>
              iKala CDP
              有權修改本協議，本協議條款一旦經修正，將在本服務在重要頁面上公告修改內容，該修正內容一經公告即生效力。
              iKala CDP 保留修改本協議而不需通知會員的權利。 iKala CDP
              對於本協議之修正，不需對會員或第三方負責。
              倘您對本協議之增修不與同意，或對 iKala CDP
              的服務不滿，可以行使如下權利：
            </Text2>

            <SpacingRow marginBottomRatio={2} marginLeftRatio={4}>
              <ListNonOrder>
                {[
                  '停止使用 iKala CDP 的服務。',
                  '通知 iKala CDP 停止對您會員帳號的服務。',
                  '結束會員服務後，會員使用服務的權利馬上中止。自該時起，會員沒有權利、 iKala CDP 也沒有義務傳送任何未處理的信息或未完成的服務給會員。',
                ].map(text => (
                  <ListItemDot>{text}</ListItemDot>
                ))}
              </ListNonOrder>
            </SpacingRow>
          </SpacingColumn>

          <SpacingRow marginBottomRatio={2}>
            <Text1>第十二條：其他</Text1>
          </SpacingRow>
          <SpacingColumn marginBottomRatio={2}>
            <SpacingRow marginBottomRatio={2} marginLeftRatio={3}>
              <ListOrder>
                {[
                  '本協議使用或說明各條標題，僅為查閱而設，不影響有關條款之解釋、說明及瞭解。',
                  '本協議所定之一部份條款被司法機關確認為無效時，並不影響其他條款之效力，於本公司修改該無效之條款前，應依中華民國相關法律規定處理。',
                  '本協議之解釋與適用，悉依中華民國法律予以處理，會員並同意因本協議所生之爭議，除法律另有規定者外，應以台灣台北地方法院為第一審管轄法院。',
                ].map(text => (
                  <ListItemNumber>{text}</ListItemNumber>
                ))}
              </ListOrder>
            </SpacingRow>
          </SpacingColumn>

          {/*  */}
        </Card>
      </Content>

      <Footer />
    </Wrapper>
  )
}

export default TermsOfService
