import { useTranslation } from 'react-i18next'

import {
  MAX_LABEL_COUNT,
  MAX_LABEL_LENGTH,
  MAX_USER_LABEL_COUNT,
} from '@features/infoBox/model/constants'
import type { IngestionErrorCode } from '@shared/model/types/ingestion'

export const useErrorMsg = (errorCode: IngestionErrorCode | undefined) => {
  const { t } = useTranslation(['dataImport'])

  switch (errorCode) {
    case 'dataFormatError':
      return t('dataImport:error.format_error')

    case 'labelLengthExceed':
      return t('dataImport:error.behavior_tag_user_length_exceed', {
        max_count: MAX_LABEL_LENGTH,
      })

    case 'userLabelCountExceed':
      return t('dataImport:error.behavior_tag_user_user_label_count_exceed', {
        max_count: MAX_USER_LABEL_COUNT,
      })

    case 'userNotExist':
      return t('dataImport:error.behavior_tag_user_user_not_exist')

    case 'totalLabelCountExceed':
      return t('dataImport:error.behavior_tag_user_total_label_count_exceed', {
        max_count: MAX_LABEL_COUNT,
      })

    default:
      return ''
  }
}
