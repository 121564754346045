import i18n from '@i18n'
import type {
  CampaignMediumEvent,
  CampaignsEventSchema,
  MediumType,
} from '@shared/api/rtkQuery'
import type { Option } from '@shared/ui/Dropdown/DropdownList'

const mapCampaignEventSchemaToOptions = (data?: CampaignsEventSchema) => {
  const rules = data?.value_mapping?.find(
    item =>
      item.from_table === 'campaign_events' &&
      item.from_column === 'medium_type'
  )?.rules as Record<MediumType, CampaignMediumEvent[]> | undefined

  if (!rules) {
    return undefined
  }

  const mediumTypeOptions: Option[] = (Object.keys(rules) as MediumType[]).map(
    (item: MediumType) => ({
      label: i18n.t(`common:medium_type.${item}`),
      value: item,
    })
  )

  const campaignMediumEventOptionsDict = Object.entries(rules).reduce(
    (collection, current) => {
      const [mediumType, events] = current
      collection[mediumType as MediumType] = events.map(event => ({
        label: i18n.t(`audience:campaigns.event_actions.${event}`),
        value: event,
      }))
      return collection
    },
    {} as Record<MediumType, Option[]>
  )

  return {
    mediumTypeOptions,
    campaignMediumEventOptionsDict,
  }
}

export default mapCampaignEventSchemaToOptions
