import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { CreateSmsDialog } from '@entities/mediumTemplates'
import { CreateSubscription } from '@features/subscription/Modal'
import {
  useCreateTracingUrlMutation,
  useGetSubscriptionListQuery,
  useGetTracingUrlListQuery,
} from '@shared/api/rtkQuery'
import { PAGE_ROOT } from '@shared/model/constants/routes'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { TracingUrlDialog } from '@shared/ui/dialogs'
import DeepBlueGradientButton from '@shared/ui/Dropdown/DeepBlueGradientButton'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import useTracingUrlError from '@widgets/contentSetting/lib/hooks/useTracingUrlError'
import {
  CONTENT_SETTING_PATH,
  TRACING_URL_LIMIT,
} from '@widgets/contentSetting/model/constants'

const CreateButton = () => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const { tracingUrlList, isOverLimit, isTracingUrlFetching } =
    useGetTracingUrlListQuery(undefined, {
      selectFromResult: ({ data = [], isFetching }) => ({
        tracingUrlList: data,
        // 不確定資料來源是否可能為 null，保留原寫法
        isOverLimit: (data?.length || 0) >= TRACING_URL_LIMIT,
        isTracingUrlFetching: isFetching,
      }),
    })

  const { data: subscriptionsListData, isLoading: isSubscriptionsListLoading } =
    useGetSubscriptionListQuery({
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      name: '',
    })

  const [openDialog, setOpenDialog] = useState<
    'sms' | 'tracingUrl' | 'subscription' | undefined
  >(undefined)

  const [createTracingUrl, { isLoading: isTracingUrlCreating }] =
    useCreateTracingUrlMutation()

  const { handleError } = useTracingUrlError({ tracingUrlList })

  return (
    <>
      <DeepBlueGradientButton buttonText={t('common:create')}>
        <MenuList>
          <MenuItem onClick={() => setOpenDialog('sms')}>
            {t('contentTemplate:message_sms')}
          </MenuItem>

          <MenuItem
            component={Link}
            to={`${CONTENT_SETTING_PATH.CONTENT_TEMPLATE}/${PAGE_ROOT.new}`}
          >
            {t('contentTemplate:email')}
          </MenuItem>

          <ConditionalTooltip
            isShow={isOverLimit}
            title={t('contentTemplate:tracing_url.limit', {
              limit: TRACING_URL_LIMIT,
            })}
            containerDisplay="block"
            placement="left"
          >
            <MenuItem
              disabled={isTracingUrlFetching || isOverLimit}
              onClick={() => setOpenDialog('tracingUrl')}
            >
              {t('common:route.ma_tracing_url')}
            </MenuItem>
          </ConditionalTooltip>

          <ConditionalTooltip
            isShow={subscriptionsListData?.items.limitExceeded || false}
            title={t('contentTemplate:subscription.error.create_limit', {
              num: subscriptionsListData?.items.createLimit,
            })}
            containerDisplay="block"
            placement="left"
          >
            <MenuItem
              disabled={
                isSubscriptionsListLoading ||
                subscriptionsListData?.items.limitExceeded
              }
              onClick={() => setOpenDialog('subscription')}
            >
              {t('common:route.ma_subscription')}
            </MenuItem>
          </ConditionalTooltip>
        </MenuList>
      </DeepBlueGradientButton>

      {openDialog === 'sms' && (
        <CreateSmsDialog onClose={() => setOpenDialog(undefined)} />
      )}

      {openDialog === 'tracingUrl' && (
        <TracingUrlDialog
          isLoading={isTracingUrlCreating}
          onSave={async ({ name, url }) => {
            try {
              await createTracingUrl({ name, url }).unwrap()
              setOpenDialog(undefined)
            } catch (error) {
              handleError({ url, error })
            }
          }}
          onClose={() => setOpenDialog(undefined)}
        />
      )}

      {openDialog === 'subscription' && (
        <CreateSubscription isOpen onClose={() => setOpenDialog(undefined)} />
      )}
    </>
  )
}

export default CreateButton
