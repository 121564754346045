import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'

const rotate = keyframes`
  from { transform: rotate(0deg); }

  to { transform: rotate(360deg); }
`

export const IconWrapper = styled.div<{
  isRotate?: boolean
  marginLeft?: number
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  width: 25px;
  height: 25px;

  ${({ isRotate }) =>
    isRotate &&
    css`
      animation: ${rotate} 1.2s linear infinite;
    `}
`

export default IconWrapper
