import type {
  CampaignEventAttributeFilterNodes,
  CampaignEventCampaignGroupFilterGroup,
  CampaignEventMediumEventFilterNode,
  CampaignEventMediumGroupFilterNode,
  CampaignEventMediumTypeFilterNode,
  CampaignMeasureFilterNode,
} from '@shared/api/rtkQuery'

/** 直接在 findIndex 裡寫 find 會踩到 ERROR: This expression is not callable.
 * 所以先把不要的條件 mapping 成 false
 * 再用 findIndex 找出 Truly
 */
const findMediumGroupIndex = (data: CampaignEventAttributeFilterNodes) =>
  data
    .map(group =>
      group.nodes?.some(item => {
        if (item.type === 'group') {
          return false
        }
        return item?.field === 'medium_type'
      })
    )
    .findIndex(Boolean)

const getSortedMediumGroup = (
  data: CampaignEventMediumGroupFilterNode
): CampaignEventMediumGroupFilterNode => {
  /**
   * 先把排序成
   * nodes[0] -> medium_type
   * nodes[1] -> action
   * 方便後面處理
   */
  const mediumGroupNodes = data.nodes?.slice().sort((a, b) => {
    const order = ['medium_type', 'action']
    return order.indexOf(a.field) - order.indexOf(b.field)
  }) as [CampaignEventMediumTypeFilterNode, CampaignEventMediumEventFilterNode]

  return {
    ...data,
    nodes: mediumGroupNodes,
  }
}

const getSortedTriggerGroup = (
  data?: CampaignEventCampaignGroupFilterGroup
): CampaignEventCampaignGroupFilterGroup | undefined => {
  if (!data) {
    return undefined
  }
  /**
   * 先把排序成
   * nodes[0] -> trigger_group_type
   * nodes[1] -> trigger_group_id (workflow id)
   * nodes[2] -> campaign_id
   * nodes[3] -> tracing_url_id
   * 方便後面處理
   */
  const triggerGroupNodes = data?.nodes?.slice().map(node => {
    const nodes = node.nodes.slice().sort((a, b) => {
      const order = [
        'trigger_group_type',
        'trigger_group_id',
        'campaign_id',
        'tracing_url_id',
      ]
      return order.indexOf(a.field) - order.indexOf(b.field)
    })

    return {
      ...node,
      nodes,
    }
  })
  return {
    ...data,
    nodes: triggerGroupNodes,
  }
}

/**
 * 先把排序成
 * nodes[0] -> medium group
 * nodes[1] -> trigger group
 * 方便後面處理
 */
const sortTheCampaignAttributeFilter = (
  data: CampaignMeasureFilterNode['attributeFilter']['nodes']
) => {
  const mediumGroupIndex = findMediumGroupIndex(data)
  const mediumGroup = data[
    mediumGroupIndex
  ] as CampaignEventMediumGroupFilterNode

  const triggerGroup = data.filter(
    (_, index) => index !== mediumGroupIndex
  )[0] as CampaignEventCampaignGroupFilterGroup

  return [
    getSortedMediumGroup(mediumGroup),
    getSortedTriggerGroup(triggerGroup),
  ] as CampaignMeasureFilterNode['attributeFilter']['nodes']
}

export default sortTheCampaignAttributeFilter
