import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  DEFAULT_TAG_LIST_PRE_PAGE,
  POLLING_INTERVAL,
  TAG_TYPE_INTERNAL_OFFLINE,
  TagProps,
  useTagGroupsQuery,
  useTagListQuery,
} from '@shared/api/rtkQuery'
import { createRouteState } from '@shared/lib/utils/routeUtils'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import { DataTable } from '@shared/ui/table'
import type { TagRouteSate } from '@widgets/tag/_shared/type'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'

import { ALL_RENDERING_STATUS } from '../_shared/constant'
import useIntelligentTagColumns from './useIntelligentTagColumns'

type Props = {
  routeState: TagRouteSate
}

const Intelligent = ({ routeState: { page, perPage } }: Props) => {
  const { t } = useTranslation(['common', 'audience', 'tag'])
  const [openDropdownTagId, setOpenDropdownTagId] = useState<number>(-1)

  const {
    pathname,
    state: routeState = createRouteState({
      page,
      perPage,
    }),
  } = useLocation<TagRouteSate>()

  const history = useHistory<TagRouteSate>()

  const [pollingInterval, setPollingInterval] = useState<number>()

  const { data = [], isLoading } = useTagListQuery(
    {
      page: DEFAULT_PAGE,
      perPage: DEFAULT_TAG_LIST_PRE_PAGE,
    },
    {
      pollingInterval,
      selectFromResult: ({ data = [], isLoading }) => ({
        data: data.filter(({ type }) => type === TAG_TYPE_INTERNAL_OFFLINE),
        isLoading,
      }),
    }
  )
  const { tagGroupMap, isLadingTagGroups } = useTagGroupsQuery(null, {
    selectFromResult: ({ data = [], isLoading }) => ({
      tagGroupMap: data.reduce(
        (acc, cur) => {
          acc[cur.id as number] = cur.title
          return acc
        },
        { 0: t('tag:no_group') } as TagGroupMapType
      ),

      isLadingTagGroups: isLoading,
    }),
  })

  const handleChangePage = (newPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: newPage + 1,
      })
    )
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: DEFAULT_PAGE,
        perPage: rowsPerPage,
      })
    )
  }

  const isNeedPolling = data.filter((item: TagProps) =>
    ALL_RENDERING_STATUS.includes(item.latestJobStatus)
  ).length

  useEffect(() => {
    if (isNeedPolling) {
      setPollingInterval(POLLING_INTERVAL)
      return
    }
    setPollingInterval(undefined)
  }, [isNeedPolling])

  const columns = useIntelligentTagColumns({
    openDropdownTagId,
    tagGroupMap,
    setOpenDropdownTagId,
  })
  const filteredDataByPage = data.slice(
    (routeState.page - 1) * routeState.perPage,
    Math.min(data.length, routeState.page * routeState.perPage)
  )

  return (
    <DataTable
      columns={columns}
      rows={filteredDataByPage}
      count={data.length}
      page={routeState.page - 1}
      rowsPerPage={routeState.perPage}
      rowIdKey="id"
      isRowClickable
      isLoading={isLoading || isLadingTagGroups}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default Intelligent
