import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const strategies: {
  condition: (propertyId: string, value: unknown) => boolean
  transform: ({
    value,
    propertyRecord,
    campaignEventActionsDisplayNameMap,
  }: {
    value: unknown
    propertyRecord: Record<string, unknown>
    campaignEventActionsDisplayNameMap: Map<string, string>
  }) => string
}[] = [
  {
    condition: propertyId => propertyId === 'dimension_ga_eec_items.price',
    transform: ({ value, propertyRecord }) => {
      const nameList = propertyRecord['dimension_ga_eec_items.name']

      if (Array.isArray(value) && Array.isArray(nameList)) {
        return value
          .map((price, index) => `${nameList[index]} $${price}`)
          .join('、')
      }

      return `${value}`
    },
  },
  {
    condition: propertyId => propertyId === 'campaign_events.action',
    transform: ({ value: key, campaignEventActionsDisplayNameMap }) => {
      if (typeof key === 'string') {
        return campaignEventActionsDisplayNameMap.get(key) ?? key
      }

      return `${key}`
    },
  },
  {
    condition: (propertyId, value) => Array.isArray(value),
    transform: ({ value }) => (value as unknown[]).join('、'),
  },
]

export const useGetEventPropertyTemplate = () => {
  const { t } = useTranslation(['workflow'])

  const campaignEventActionsDisplayNameMap = useMemo(
    () =>
      new Map([
        ['sent', t('workflow:campaigns.event_actions.sent')],
        ['reception', t('workflow:campaigns.event_actions.reception')],
        ['open', t('workflow:campaigns.event_actions.open')],
        ['click', t('workflow:campaigns.event_actions.click')],
        ['bounce', t('workflow:campaigns.event_actions.bounce')],
        ['complaint', t('workflow:campaigns.event_actions.complaint')],
        [
          'unsubscription',
          t('workflow:campaigns.event_actions.unsubscription'),
        ],
      ]),
    [t]
  )

  const getter = useCallback(
    (propertyId: string, propertyRecord: Record<string, unknown>) =>
      strategies.reduce((result, strategy) => {
        if (strategy.condition(propertyId, result)) {
          return strategy.transform({
            value: result,
            propertyRecord,
            campaignEventActionsDisplayNameMap,
          })
        }

        return result
      }, propertyRecord[propertyId] ?? '') as string,
    [campaignEventActionsDisplayNameMap]
  )

  return [getter]
}

export default useGetEventPropertyTemplate
