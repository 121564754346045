import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'

import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import { resetTasks, selectIsAllTasksDone } from '../model/taskSlice'
import { Content, IconButton, Summary, SummaryLabel, Wrapper } from './styles'
import TaskBar from './TaskBar'

const InfoBox = () => {
  const [isCollapse, setIsCollapse] = useState(false)

  const taskIds = useAppSelector(state => state.infoBox.task.ids)
  const taskEntities = useAppSelector(state => state.infoBox.task.entities)

  const isShowCloseButton = useAppSelector(selectIsAllTasksDone)

  const dispatch = useAppDispatch()

  const handleCloseButtonClick = () => {
    dispatch(resetTasks())
  }

  useEffect(() => {
    // 若有檔案的上傳流程還沒走完，重整或是關閉網頁會跳提示
    const handleWindowBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!isShowCloseButton) {
        e.preventDefault()
        e.returnValue = ''
        return ''
      }
    }

    window.addEventListener('beforeunload', handleWindowBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleWindowBeforeUnload)
    }
  }, [isShowCloseButton])

  const { t } = useTranslation(['dataImport'])

  if (taskIds.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <Summary isCollapse={isCollapse} size="lg">
        <SummaryLabel>{t('dataImport:info_box_title')}</SummaryLabel>
        <Box display="flex">
          <IconButton
            marginRightRatio={isShowCloseButton ? -1 : 1}
            direction={isCollapse ? 'right' : 'down'}
            onClick={() => {
              setIsCollapse(prevState => !prevState)
            }}
          >
            <Icon icon={ICON.arrowRightB} color={theme.colors.white} />
          </IconButton>

          {isShowCloseButton && (
            <IconButton onClick={handleCloseButtonClick}>
              <Icon
                icon={ICON.multiply}
                color={theme.colors.white}
                fontSize="small"
              />
            </IconButton>
          )}
        </Box>
      </Summary>
      <Collapse in={!isCollapse} timeout="auto">
        <Content>
          {taskIds.map(taskId => {
            const currTask = taskEntities[taskId]
            const { id, type, name, status, errorCode, resourceType } = currTask

            return (
              <TaskBar
                key={id}
                status={status}
                type={type}
                resourceType={resourceType}
                name={name}
                errorCode={errorCode}
              />
            )
          })}
        </Content>
      </Collapse>
    </Wrapper>
  )
}

export default InfoBox
