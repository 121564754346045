import getRfmTemplate from './getRfmTemplate'

export const getTemplate = (templateId: string, templateParams: string) => {
  const params = JSON.parse(templateParams)
  switch (templateId) {
    case 'rfm':
      return getRfmTemplate(params.source, params.value)
    default:
      return null
  }
}

export default getTemplate
