import {
  type MediumType,
  INITIAL_PAGINATION_RESPONSE,
  useGetMsgChannelListQuery,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'

type UseMsgChannelParams = {
  msgChannelId?: number
  mediumType: MediumType
}

/**
 * 此 hook 是用來檢查當前填入的 msgChannelId 在後端是否仍有對應資源。
 *
 * msgChannel 跟 thirdParty 有對應關係但屬於不同資源。如果用戶從 UI 刪除了某個 thirdParty 不會影響到 msgChannel，也就是說無法透過 useGetMsgChannel (取得單獨 msgChannel) 確認 msgChannel 是否仍然存在。
 *
 * 後端提供的解法是，在回傳 msgChannel 列表時會額外過濾 thirdParty 被移除的項目，至於為什麼不直接用 thirdPartyId 就好？我也不知道。
 */
export const useMsgChannel = ({
  msgChannelId,
  mediumType,
}: UseMsgChannelParams) => {
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const { msgChannel, isLoading, isSuccess } = useGetMsgChannelListQuery(
    { page: 1, perPage: THIRD_PARTY_MAX_COUNT, mediumType },
    {
      skip: !msgChannelId,
      selectFromResult: ({
        data = INITIAL_PAGINATION_RESPONSE,
        isLoading,
        isSuccess,
      }) => {
        const msgChannel = data.items.find(item => item.id === msgChannelId)

        return {
          msgChannel: msgChannel
            ? {
                ...msgChannel,
                providerName: getThirdPartyProviderName(
                  msgChannel.integrationType
                ),
              }
            : undefined,
          isLoading,
          isSuccess,
        }
      },
    }
  )

  return { msgChannel, isLoading, isSuccess }
}

export default useMsgChannel
