import type {
  CreateUserProfileIngestionParams,
  UserProfileIngestion,
} from '@shared/model/types/ingestion'

import { api, transformResponseCamelCaseKeys } from '../api'

const userProfile = api.injectEndpoints({
  endpoints: builder => ({
    createUserProfileIngestion: builder.mutation<
      void,
      CreateUserProfileIngestionParams
    >({
      query: params => ({
        url: `/user_profile/ingestions`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: ['UserProfileIngestion'],
    }),
    getUserProfileIngestion: builder.query<UserProfileIngestion, number>({
      query: id => ({
        url: `/user_profile/ingestions/${id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as UserProfileIngestion),
      providesTags: ['TagUserIngestion'],
    }),
    triggerUserProfileIngestion: builder.mutation<void, number>({
      query: id => ({
        url: `/user_profile/ingestions/${id}/trigger`,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateUserProfileIngestionMutation,
  useGetUserProfileIngestionQuery,
  useLazyGetUserProfileIngestionQuery,
} = userProfile
