import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { FormikErrors, FormikTouched } from 'formik'

import { SmsTextarea } from '@entities/inputs'
import { ICON } from '@shared/model/constants/styles'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import { Tooltip } from '@shared/ui/tooltips'

import type { FormValues } from '../ExportTextMessage'
import { Label } from '../styles'

type FieldValues = Pick<FormValues, 'subject' | 'message'>

type Props = {
  values: FieldValues
  touched: FormikTouched<FieldValues>
  errors: FormikErrors<FieldValues>
  priceEvaluation: React.ReactNode
  onSubjectChange: (subject: string) => void
  onMessageChange: (message: string) => void
}

export const SmsFields = ({
  values,
  touched,
  errors,
  priceEvaluation,
  onSubjectChange,
  onMessageChange,
}: Props) => {
  const { t } = useTranslation(['audience'])

  return (
    <>
      <Box sx={{ display: 'flex', mb: 4 }}>
        <Label>
          {t('audience:export_sms.title', {
            message_type: 'SMS',
          })}
          &nbsp;
          <Tooltip title={t(`audience:export_sms.title_hint.sms`)}>
            <i className={ICON.infoCircle} />
          </Tooltip>
          &nbsp;
        </Label>

        <Box sx={{ flexGrow: 1 }}>
          <PrimaryGreyInput
            fullWidth
            placeholder={t('audience:export_sms.title_placeholder', {
              message_type: 'SMS',
            })}
            value={values.subject}
            isError={touched.subject && errors.subject !== undefined}
            onChange={({ target: { value } }) => onSubjectChange(value)}
          />

          {touched.subject && errors.subject && (
            <Box sx={{ color: theme => theme.colors.orangeyRed, mt: 1 }}>
              {errors.subject}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Label>{t('audience:export_sms.message')} *</Label>

        <Box sx={{ flexGrow: 1 }}>
          <SmsTextarea
            isError={touched.message && errors.message !== undefined}
            onChange={value => onMessageChange(value)}
            value={values.message}
          />

          <Box>
            {touched.message && errors.message !== undefined && (
              <Box sx={{ color: theme => theme.colors.orangeyRed, mt: 1 }}>
                {errors.message}
              </Box>
            )}

            <Box sx={{ mt: 1 }}>{priceEvaluation}</Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default SmsFields
