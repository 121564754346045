import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { CHART_HEIGHT } from '@widgets/audience/model/constant'

const EmptyData = () => {
  const { t } = useTranslation(['common'])

  return (
    <Box
      width="100%"
      height={CHART_HEIGHT}
      overflow="hidden"
      display="flex"
      justifyContent="center"
      alignItems="center"
      fontSize="15px"
      fontWeight={500}
      sx={theme => ({
        backgroundColor: theme.colors.bgPrimaryGrey,
        color: theme.colors.textPrimaryBlue,
        backgroundSize: 'cover',
      })}
    >
      {t('common:empty_data')}
    </Box>
  )
}

export default EmptyData
