import { PREDEFINED_METADATA_TAG_ENTITY } from './metadata'

export const ACTION_URL_WORD = 'action_url'

export const TRACING_URL_WORD = 'tracing_url'

export enum UtmPropertyEnum {
  UtmCampaignId = 'utm_campaign_id',
  UtmMedium = 'utm_medium',
  UtmSource = 'utm_source',
  UtmCampaign = 'utm_campaign',
  UtmTerm = 'utm_term',
  UtmContent = 'utm_content',
}

export const UTM_PROPERTIES = [
  UtmPropertyEnum.UtmCampaignId,
  UtmPropertyEnum.UtmMedium,
  UtmPropertyEnum.UtmSource,
  UtmPropertyEnum.UtmCampaign,
  UtmPropertyEnum.UtmTerm,
  UtmPropertyEnum.UtmContent,
]

export const CONTENT_TEMPLATE_ERROR_CODE = {
  DUPLICATE_NAME: 11001,
  DUPLICATE_URL: 20001,
  DUPLICATE_EMAIL_PERSONALIZED_BLOCK: 20002,
} as const

const UNPURCHASED_PRODUCT_COUNT = 3

export const createPreviewTemplateData = () => ({
  personalized_replacement: Array.from(
    { length: UNPURCHASED_PRODUCT_COUNT },
    _ => ({
      unpurchased_product_image_url:
        PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_image_url']
          .defaultValue,
      unpurchased_product_name: `{{${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_name'].displayName}}}`,
      unpurchased_product_price: `{{${PREDEFINED_METADATA_TAG_ENTITY['unpurchased_product_price'].displayName}}}`,
    })
  ),
})
