import { createContext, useContext, useState } from 'react'

import { ChartType, NO_DATA_ID } from '@widgets/audience/model/constant'

type AudienceDetailState = {
  isChartLoading: boolean
  // 因為需要額外判斷全圖表都錯誤才需要顯示提示
  isChartError: { [x in ChartType]?: boolean }
  hasChartSettings: boolean
  isEmptyAudience: boolean
  audienceId: number
}

const AudienceDetailStateContext = createContext<AudienceDetailState | null>(
  null
)
const AudienceAnalysisSetStateContext = createContext<React.Dispatch<
  React.SetStateAction<AudienceDetailState>
> | null>(null)

const AudienceDetailStateProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [audienceAnalysisState, setAudienceDetailState] =
    useState<AudienceDetailState>({
      isChartLoading: true,
      isChartError: {},
      hasChartSettings: false,
      isEmptyAudience: false,
      audienceId: NO_DATA_ID,
    })

  return (
    <AudienceDetailStateContext.Provider value={audienceAnalysisState}>
      <AudienceAnalysisSetStateContext.Provider value={setAudienceDetailState}>
        {children}
      </AudienceAnalysisSetStateContext.Provider>
    </AudienceDetailStateContext.Provider>
  )
}

export function useAudienceDetailState() {
  const context = useContext(AudienceDetailStateContext)
  if (!context) {
    throw new Error(
      'useAudienceDetailState must be used within the AudienceDetailStateProvider'
    )
  }

  return context
}

export function useAudienceDetailSetState() {
  const setContext = useContext(AudienceAnalysisSetStateContext)
  if (!setContext) {
    throw new Error(
      'useAudienceDetailSetState must be used within the AudienceDetailStateProvider'
    )
  }

  return setContext
}

export default AudienceDetailStateProvider
