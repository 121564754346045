import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { NotificationType } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import theme from '@theme'

type Props = {
  notificationType: NotificationType
}

const ThirdPartyApp = ({ notificationType }: Props) => {
  const { t } = useTranslation(['analytics'])

  return (
    <>
      <Box pt={2} pr={3} width="8px">
        {!notificationType.archivedAt && (
          <Box
            width="8px"
            height="8px"
            borderRadius="50%"
            sx={theme => ({
              backgroundColor: theme.colors.orangeyRed,
            })}
          />
        )}
      </Box>
      <Box
        component="i"
        py={0}
        px="7px"
        width="36px"
        height="36px"
        borderRadius="50%"
        fontSize="24px"
        lineHeight="36px"
        className={ICON.exclamationTriangle}
        sx={theme => ({
          backgroundColor: theme.colors.red015,
          color: theme.colors.chartRed,
        })}
      />
      <Box flexShrink="1" flexGrow="1" padding="2px 14px">
        <Box
          component="h2"
          sx={theme => ({
            color: theme.colors.black,
          })}
        >
          {t('analytics:notification.third_party_app_has_been_deleted', {
            thirdPartyAppName: notificationType.title,
          })}
        </Box>
        {Object.keys(notificationType.detail).map(key => {
          if (
            key === 'journey' ||
            key === 'audiences' ||
            key === 'thirdPartyTag'
          ) {
            return (
              <Box
                key={key}
                margin="10px 4px"
                padding="0 18px"
                borderLeft={`4px solid ${theme.colors.lightBlueGrey}`}
              >
                <Box component="p" fontSize="13px" lineHeight="24px">
                  {t(`analytics:notification.type.${key}`)}
                </Box>
                {notificationType.detail[key].map((item, x) => (
                  <Box
                    component="p"
                    key={`${x}_${item}`}
                    fontWeight={600}
                    fontSize="13px"
                    lineHeight="22px"
                    sx={theme => ({ color: theme.colors.black })}
                  >
                    {item}
                  </Box>
                ))}
              </Box>
            )
          }
          return null
        })}

        <Box
          component="p"
          fontSize="13px"
          lineHeight="20px"
          sx={theme => ({
            color: theme.colors.lightGreyBlue,
          })}
        >
          {formatDateTimeDisplay(notificationType.createdAt)}
        </Box>
      </Box>
    </>
  )
}

export default ThirdPartyApp
