import curry from 'lodash/fp/curry'

import {
  ChartData,
  getLongestSeriesLenIdx,
  getRatioIndex,
} from '@shared/ui/charts'
import { MAX_RANGE_SLIDER_VALUE } from '@shared/ui/RangeSlider'

export const getChartRangeData = curry(
  (start: number, end: number, chartData: ChartData[]) => {
    // 在現在時間與對比時間中，選 series 長度較長的 idx 做 iteration
    const { longestSeriesLenIdx } = getLongestSeriesLenIdx(chartData)

    // 根據 len 決定 rangeValue 與 categories idx 的比例尺
    const categoriesArrLen =
      chartData[longestSeriesLenIdx]?.categories?.length ?? 0

    // 根據 rangeValue 取出擷取過後的 startIdx & endIdx
    const startIdx = getRatioIndex(
      start / MAX_RANGE_SLIDER_VALUE,
      categoriesArrLen
    )
    const endIdx = getRatioIndex(end / MAX_RANGE_SLIDER_VALUE, categoriesArrLen)

    // 過濾 categories & series
    const chartRangeData = chartData.map(d => {
      const categories = d.categories.slice(startIdx, endIdx + 1)
      const series = d.series.map(s => {
        // 這裡可能要先防呆，如果 data engineer 還沒有把 data.length 對齊 categories
        const newData = s.data.slice(startIdx, endIdx + 1)

        return { ...s, data: newData }
      })

      return { categories, series }
    })

    return chartRangeData
  }
)
