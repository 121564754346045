import { useMemo } from 'react'

import {
  FunnelAnalyticsNode,
  useGetFunnelRuleSummaryQuery,
} from '@shared/api/rtkQuery'
import { useMetadataQuery } from '@shared/lib/utils/metadata'

import {
  getInitPreviewResponse,
  transformPreviewResponseBarChart,
  transformPreviewResponseLineChart,
} from '../utils'

export const usePreviewResponse = (
  filter: FunnelAnalyticsNode,
  { inView }: { inView: boolean }
) => {
  const { eventEntity } = useMetadataQuery()
  const { data, isFetching, error } = useGetFunnelRuleSummaryQuery(filter, {
    skip: !inView,
  })

  const previewData = useMemo(() => {
    // TODO: error handling
    if (!data || isFetching || error || 'error' in data) {
      return getInitPreviewResponse()
    }

    const funnel = transformPreviewResponseBarChart(data, eventEntity)
    const trend = {
      chart: transformPreviewResponseLineChart(data, eventEntity).chart,
      totalConversionData: funnel.totalConversionData,
    }
    return {
      funnel_chart: funnel,
      trend_chart: trend,
    }
  }, [eventEntity, data, isFetching, error])

  return {
    data: previewData,
    isFetching,
    error,
  }
}
