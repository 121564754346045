import { Link } from 'react-router-dom'
import type { TFunction } from 'i18next'

import type { Insight } from '@shared/api/rtkQuery/insight'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import type { TableColumn } from '@shared/ui/table'
import {
  InsightSummaryValue,
  insightValueFormatter,
} from '@widgets/insight/lib/utils/utils'
import { ErrorHint, TableCellWithHint } from '@widgets/insight/ui/_shared'

// TODO: Remove conversion columns temporarily
// isConversionEnable: boolean,
// hasConversionValue: boolean,

const columns = (
  t: TFunction<('common' | 'table')[]>
): TableColumn<Insight['items'][number]>[] => [
  {
    headerName: '',
    field: 'tracingUrls',
    colRatio: 0.1,
    valueFormatter: tracingUrls => (
      <ErrorHint tracingUrlInsights={tracingUrls} />
    ),
  },
  {
    headerName: 'campaign_name',
    field: 'title',
    colRatio: 2,
    valueFormatter: (title, { tracingUrls }) => (
      <TableCellWithHint tracingUrlInsights={tracingUrls}>
        {title}
      </TableCellWithHint>
    ),
  },
  {
    headerName: 'referred_workflow_title',
    field: 'id',
    colRatio: 0.21,
    valueFormatter: (_, { triggerGroupId, triggerGroupTitle }) => (
      <Link
        to={`/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.workflowInsight}/${triggerGroupId}`}
      >
        {triggerGroupTitle}
      </Link>
    ),
  },
  {
    headerName: 'medium_type',
    field: 'mediumType',
    colRatio: 0.1,
    valueFormatter: mediumType => {
      if (String(mediumType).toLowerCase().includes('email')) {
        return t('common:medium_type.email')
      } else if (String(mediumType).toLowerCase().includes('sms')) {
        return t('common:medium_type.sms')
      } else if (String(mediumType).toLowerCase().includes('line_push')) {
        return t('common:medium_type.line_push')
      } else {
        return ''
      }
    },
  },
  {
    headerName: 'sent_uu_count',
    field: 'sentUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.12,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  {
    headerName: 'reception_uu_count',
    field: 'receptionUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.12,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  {
    headerName: 'open_uu_count',
    field: 'openUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.12,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  {
    headerName: 'click_uu_count',
    field: 'clickUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.12,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  // {
  //   headerName: 'conversion_uu_count',
  //   field: 'conversionUuCount',
  //   isSortable: isConversionEnable,
  //   align: 'right',
  //   valueFormatter: value =>
  //     isConversionEnable
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
  // {
  //   headerName: 'conversion_value',
  //   field: 'conversionValue',
  //   isSortable: hasConversionValue,
  //   align: 'right',
  //   valueFormatter: value =>
  //     hasConversionValue
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
  // {
  //   headerName: 'conversion_value_per_sent',
  //   field: 'conversionValuePerSent',
  //   isSortable: hasConversionValue,
  //   align: 'right',
  //   valueFormatter: value =>
  //     hasConversionValue
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
]

export default columns
