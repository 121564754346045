import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'
import { useUpdateNodeInternals } from 'reactflow'
import Box from '@mui/material/Box'

import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import type { BasicNodeOperation } from '@widgets/workflow/_shared'

import {
  BasicNode,
  createGetHandleStyles,
  HandleSourceText,
  TooltipIcon,
} from '../_shared'
import { useMsgChannel } from '../_shared/hooks'
import ActionSendEmailNodeDialog from './ActionSendEmailNodeEditDialog'
import {
  ActionSendEmailNodeData,
  actionSendEmailNodeSchema,
} from './actionSendEmailNodeSchema'

type Props = BasicNodeOperation<ActionSendEmailNodeData>

const ActionSendEmailNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    isConnectable,
    type,
  }: NodeProps<Props>) => {
    const [openDialog, setOpenDialog] = useState(false)
    const {
      checkHasArchivedTracingUrlInReplacementData:
        hasArchivedTracingUrlInReplacementDataChecker,
    } = useTracingUrlStatus()

    const hasArchivedTracingUrl = hasArchivedTracingUrlInReplacementDataChecker(
      initialValues.replacementData
    )

    const isTrackingEnabled = Boolean(initialValues.reactionTiming)

    const { t } = useTranslation(['workflow', 'common'])

    const updateNodeInternals = useUpdateNodeInternals()

    const stringifyValues = JSON.stringify(initialValues)

    const isSettled = useMemo(
      () => actionSendEmailNodeSchema.isValidSync(JSON.parse(stringifyValues)),
      [stringifyValues]
    )

    const handleConfirm = (data: ActionSendEmailNodeData) => {
      const hasOpenRateSwitched =
        Boolean(initialValues.reactionTiming) !== Boolean(data.reactionTiming)

      if (onDeleteEdges && hasOpenRateSwitched) {
        onDeleteEdges('source')
      }

      onChange(data)
      setOpenDialog(false)
    }

    const {
      msgChannel: emailProvider,
      isLoading,
      isSuccess,
    } = useMsgChannel({
      msgChannelId: initialValues.msgChannelId,
      mediumType: 'email',
    })

    useEffect(() => {
      if (isSuccess && !emailProvider) {
        onChange({
          ...initialValues,
          msgChannelId: undefined,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, emailProvider, onChange])

    useEffect(() => {
      // 在 isTrackingEnabled 變化時才更新，因為 Handle 位置會變化，不在這邊刪除 Edge。刪除 Edge 在 handleConfirm 裡面處理，避免第一次進來時就刪除 Edge
      updateNodeInternals(id)
    }, [id, isTrackingEnabled, updateNodeInternals])

    const getHandleStyles = useMemo(() => createGetHandleStyles(2), [])

    return (
      <>
        <BasicNode
          nodeType="action-send_email"
          onDelete={handleDelete}
          onEdit={() => {
            setOpenDialog(true)
          }}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isLoading={isLoading}
          isSettled={isSettled}
          errorHint={
            hasArchivedTracingUrl
              ? t('workflow:errors.tracing_url_is_archived_plz_recreate')
              : ''
          }
        >
          {emailProvider && (
            <Box sx={{ display: 'flex' }}>
              {emailProvider.providerName}

              <TooltipIcon
                title={
                  <>
                    <p>{id}</p>
                    <p>
                      {t('workflow:node_action_campaign_hint', {
                        name: initialValues.campaignName,
                      })}
                    </p>
                  </>
                }
              />
            </Box>
          )}
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        {isTrackingEnabled ? (
          <>
            <Handle
              type="source"
              position={Position.Bottom}
              isConnectable={isConnectable}
              style={getHandleStyles(0)}
              id="yes"
            >
              <HandleSourceText>
                {t('workflow:node_action_email_opened_handler')}
              </HandleSourceText>
            </Handle>

            <Handle
              type="source"
              position={Position.Bottom}
              style={getHandleStyles(1)}
              isConnectable={isConnectable}
              id="no"
            >
              <HandleSourceText left={-2}>
                {t('workflow:node_action_email_ignore_handler')}
              </HandleSourceText>
            </Handle>
          </>
        ) : (
          <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
          />
        )}

        <ActionSendEmailNodeDialog
          id={id}
          initialValues={initialValues}
          isOpen={openDialog}
          onConfirm={handleConfirm}
          onClose={() => setOpenDialog(false)}
        />
      </>
    )
  }
)

export default ActionSendEmailNode
