import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'

import {
  PrivacyLevel,
  useGetEventRuleGroupListQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import type { EventChartType } from '@shared/ui/charts'
import { Dialog } from '@shared/ui/dialogs'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import {
  Container,
  DialogActions,
  DROPDOWN_MENU_WIDTH,
  Label,
  ProgressContainer,
  Title,
} from '@widgets/analytics/_shared/styles/formConfirmDialogStyle'
import { GROUP_UPPER_BOUNDARY } from '@widgets/dashboard/_shared/constants'

import type { EventRuleSettings } from '../../types'

type EventRuleFormConfirmDialogProps = {
  initialValues: EventRuleSettings
  isOpen: boolean
  isChartTypeDisabled?: boolean
  isIndicatorDisabled?: boolean
  isDisablePrivacyLevel?: boolean
  isSubmitting: boolean
  serverError?: string
  onConfirm: (settings: EventRuleSettings) => Promise<void>
  onCancel: () => void
}

const EventRuleFormConfirmDialog = ({
  initialValues,
  isOpen,
  isChartTypeDisabled,
  isIndicatorDisabled,
  isDisablePrivacyLevel,
  isSubmitting,
  serverError,
  onCancel: handleCancel,
  onConfirm,
}: EventRuleFormConfirmDialogProps) => {
  const { t } = useTranslation([
    'common',
    'dashboard',
    'dateAndChart',
    'analytics',
  ])

  const { values, errors, touched, handleChange, setFieldValue, submitForm } =
    useFormik<EventRuleSettings>({
      initialValues,
      validate(values) {
        const errors: Partial<Record<keyof EventRuleSettings, string>> = {}

        if (
          values.indicator.avg === false &&
          values.indicator.latest === false &&
          values.indicator.sum === false
        ) {
          errors.indicator = t('analytics:event.is_require')
        }

        if (values.title.length === 0) {
          errors.title = t('analytics:event.is_require')
        }

        if (values.title.length > MAX_LIST_NAME_LENGTH) {
          errors.title = t('common:errors.max_count', {
            count: MAX_LIST_NAME_LENGTH,
          })
        }

        return errors
      },
      onSubmit: onConfirm,
    })

  const { groupOptions, isRuleGroupListFetching } =
    useGetEventRuleGroupListQuery(
      {
        eventRuleType: values.privacyLevel,
        page: 1,
        perPage: GROUP_UPPER_BOUNDARY,
      },
      {
        selectFromResult: ({ data: ruleGroupList = [], isFetching }) => ({
          groupOptions: [
            { value: 0, label: t('dashboard:widget_default_group') },
          ].concat(
            ruleGroupList.map(({ id, name }) => ({ value: id, label: name }))
          ),
          isRuleGroupListFetching: isFetching,
        }),
      }
    )

  const handleSelectedGroupIdChange = (option: Option) => {
    setFieldValue('groupId', Number(option.value))
  }

  const handlePrivacyLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue('privacyLevel', event.target.value as PrivacyLevel)

    // 類型切換需要重新選擇群組
    setFieldValue('groupId', 0)
  }

  const firstError = useMemo(() => {
    if (serverError) {
      return serverError
    }

    for (let key in errors) {
      const typedKey = key as keyof EventRuleSettings
      if (errors[typedKey]) {
        return errors[typedKey] as string
      }
    }

    return ''
  }, [errors, serverError])

  const handleChartTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if ((value as EventChartType) === 'pie_chart') {
      setFieldValue('indicator.sum', true)
      setFieldValue('indicator.latest', false)
      setFieldValue('indicator.avg', false)
    } else {
      setFieldValue('indicator.sum', false)
      setFieldValue('indicator.latest', true)
      setFieldValue('indicator.avg', false)
    }

    handleChange(event)
  }

  const isIndicatorDisabledOrPieChart =
    isIndicatorDisabled || values.chartType === 'pie_chart'

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth>
      <Container>
        <Title>
          {t('dashboard:save_widget_to_dashboard')}
          <IconButton onClick={handleCancel}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Title>

        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dashboard:type')}</Label>
          <RadioGroup
            row={true}
            name="privacyLevel"
            value={values.privacyLevel}
            onChange={handlePrivacyLevelChange}
          >
            <FormControlLabel
              value="public"
              control={<Radio color="primary" />}
              label={t('dashboard:public')}
              disabled={isDisablePrivacyLevel}
            />
            <FormControlLabel
              value="private"
              control={<Radio color="primary" />}
              label={t('dashboard:private')}
              disabled={isDisablePrivacyLevel}
            />
          </RadioGroup>
        </Box>

        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dashboard:widget_group')}</Label>
          <DropdownList
            disabled={isRuleGroupListFetching}
            maxWidth={DROPDOWN_MENU_WIDTH}
            onValueChanged={handleSelectedGroupIdChange}
            options={groupOptions}
            popperDisablePortal
            popupMaxWidth={DROPDOWN_MENU_WIDTH}
            value={values.groupId}
          />
        </Box>

        <Box display="flex" alignItems="center" mb={1.5}>
          <Label isError={touched.title && Boolean(errors.title)}>
            {t('dashboard:widget_name')}
            <span>&nbsp;*</span>
          </Label>
          <TextInput
            color="primary"
            name="title"
            value={values.title}
            placeholder={t('dashboard:widget_name_placeholder')}
            fullWidth={true}
            error={touched.title && Boolean(errors.title)}
            onChange={handleChange}
          />
        </Box>

        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dateAndChart:chart_type')}</Label>
          <RadioGroup
            row={true}
            name="chartType"
            value={values.chartType}
            onChange={handleChartTypeChange}
          >
            <FormControlLabel
              value="line_chart"
              control={<Radio color="primary" />}
              label={t('dateAndChart:charts.line')}
              disabled={isChartTypeDisabled}
            />

            <FormControlLabel
              value="bar_chart"
              control={<Radio color="primary" />}
              label={t('dateAndChart:charts.bar')}
              disabled={isChartTypeDisabled}
            />

            <FormControlLabel
              value="pie_chart"
              control={<Radio color="primary" />}
              label={t('dateAndChart:charts.pie')}
              disabled={isChartTypeDisabled}
            />
          </RadioGroup>
        </Box>

        <Box display="flex" alignItems="center" mb={1.5}>
          <Label isError={touched.indicator && Boolean(errors.indicator)}>
            {t('dateAndChart:metrics')}
            <span>&nbsp;*</span>
          </Label>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="indicator.sum"
                  checked={values.indicator.sum}
                  disabled={isIndicatorDisabledOrPieChart}
                  onChange={handleChange}
                />
              }
              label={t('dateAndChart:statistics.sum')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="indicator.latest"
                  checked={values.indicator.latest}
                  disabled={isIndicatorDisabledOrPieChart}
                  onChange={handleChange}
                />
              }
              label={t('dateAndChart:statistics.latest')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="indicator.avg"
                  checked={values.indicator.avg}
                  disabled={isIndicatorDisabledOrPieChart}
                  onChange={handleChange}
                />
              }
              label={t('dateAndChart:statistics.average')}
            />
          </FormGroup>
        </Box>

        <TextInputErrorText mt={2}>
          {((touched.title && errors.title) ||
            (touched.indicator && errors.indicator)) && (
            <span>{firstError}</span>
          )}
        </TextInputErrorText>
      </Container>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>
          {t('common:cancel')}
        </Button>
        <Button disabled={isSubmitting} onClick={() => submitForm()}>
          {t('common:confirm')}
        </Button>
      </DialogActions>
      <ProgressContainer>
        {isSubmitting && <LinearProgress />}
      </ProgressContainer>
    </Dialog>
  )
}

export default EventRuleFormConfirmDialog
