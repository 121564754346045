import type { Configuration } from 'rollbar'

export const rollbarConfig: Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.VITE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: () => import.meta.env.DEV,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
      },
    },
  },
}
