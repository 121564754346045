import type { Workflow } from '@shared/api/rtkQuery'

type TRIGGER_NODE_TYPE =
  | ''
  | 'trigger-enter_audience_rule'
  | 'trigger-current_audience_rule'

export const getFormTriggerNodeType = (
  nodes: Workflow['nodes']
): TRIGGER_NODE_TYPE => {
  if (!nodes.length) {
    return ''
  }

  return nodes[0].type as TRIGGER_NODE_TYPE
}
