import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import type { ThemeColor } from '@theme'
import theme from '@theme'

type Props = {
  bgColor?: ThemeColor
  color?: ThemeColor
  children: React.ReactNode
  disabled: boolean
  tooltipTitle?: React.ReactNode
  onClick?: () => void
  onDelete?: () => void
}

const Wrapper = ({
  tooltipTitle,
  children,
}: {
  tooltipTitle?: React.ReactNode
  children: React.ReactNode
}) => {
  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle || ''}>{children as JSX.Element}</Tooltip>
    )
  }

  return <>{children}</>
}

const Chip = ({
  bgColor = theme.colors.textPrimaryBlue,
  color = theme.colors.white,
  children,
  disabled,
  tooltipTitle,
  onClick,
  onDelete,
}: Props) => {
  return (
    <Wrapper tooltipTitle={tooltipTitle}>
      <Box
        onClick={onClick}
        display="inline-block"
        sx={{
          pl: 1.5,
          pr: 0,
          mx: 0.5,
          my: 0.25,
          color,
          borderRadius: '2px',
          border: 'none',
          fontSize: '13px',
          fontWeight: 500,
          cursor: onClick ? 'pointer' : 'default',
          userSelect: 'none',
          backgroundColor: bgColor,
        }}
      >
        {children}
        <IconButton
          onClick={onDelete}
          disabled={disabled}
          disableRipple
          size="small"
          sx={{
            fontSize: '10px',
            paddingRight: '8px',
          }}
        >
          <Icon icon={ICON.multiply} color={color} fontSize="inherit" />
        </IconButton>
      </Box>
    </Wrapper>
  )
}

export default Chip
