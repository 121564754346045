import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetThirdPartyAppIntegrationListQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import { DataTable } from '@shared/ui/table'

import { BUTTON_WIDTH } from '../../model/constant'
import { Wrapper } from './styles'
import useColumns from './useColumns'

const Overview = () => {
  const { t } = useTranslation(['common'])
  const { pathname } = useLocation()

  const { data: thirdPartyListData = INITIAL_PAGINATION_RESPONSE, isFetching } =
    useGetThirdPartyAppIntegrationListQuery({
      page: 1,
      perPage: THIRD_PARTY_MAX_COUNT,
    })

  const [page, setPage] = useState(DEFAULT_PAGE)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)

  const rows = useMemo(() => {
    const start = (page - 1) * perPage

    return thirdPartyListData.items.slice(start, start + perPage).map(row => {
      if (row.managedByCdp) {
        return { ...row, isDisabled: true }
      }

      return row
    })
  }, [page, perPage, thirdPartyListData.items])

  const columns = useColumns()

  return (
    <Wrapper>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Box>{t('common:route.third_party')}</Box>
        </Breadcrumbs>

        <Link to={`${pathname}/new`}>
          <DeepBlueGradientButton width={BUTTON_WIDTH}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Link>
      </Box>

      <DataTable
        columns={columns}
        rows={rows}
        count={thirdPartyListData.totalCount}
        isLoading={isFetching}
        page={page - 1}
        rowsPerPage={perPage}
        rowIdKey="id"
        isRowClickable
        onPageChange={newPage => setPage(newPage + 1)}
        onRowsPerPageChange={newPerPage => {
          setPerPage(newPerPage)
          setPage(DEFAULT_PAGE)
        }}
      />
    </Wrapper>
  )
}

export default Overview
