import { INDICATOR_HEIGHT } from '../constants'
import { SizeMode } from '../types'

const DASHBOARD_CHART_HEIGHT = 300

export const getHeight = (size: SizeMode) => {
  switch (size) {
    case 'small':
      return DASHBOARD_CHART_HEIGHT - INDICATOR_HEIGHT

    case 'medium':
      return DASHBOARD_CHART_HEIGHT

    case 'large':
      return 360
  }
}
