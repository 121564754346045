import type { ParseKeys } from 'i18next'

export type QueryDuration = '60d' | '90d' | '180d'

export const QUERY_DURATION: QueryDuration[] = ['60d', '90d', '180d']

export const MAX_DURATION = 180

// 代表自訂時間
export const DATE_RANGE_CUSTOM_VALUE = -1

type DateRangeSequence = {
  key: ParseKeys<'common'>
  value: number
}

export const DATE_RANGE_SEQUENCE: DateRangeSequence[] = [
  {
    key: 'previous_day',
    value: 1,
  },
  {
    key: 'last_n_days',
    value: 7,
  },
  {
    key: 'last_n_days',
    value: 30,
  },
  {
    key: 'last_n_days',
    value: 60,
  },
  {
    key: 'last_n_days',
    value: 90,
  },
  {
    key: 'last_n_days',
    value: MAX_DURATION,
  },
  {
    key: 'custom',
    value: DATE_RANGE_CUSTOM_VALUE,
  },
]

export const ONE_DAY_MS = 24 * 60 * 60 * 1000
