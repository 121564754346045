import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'
import debounce from 'lodash/debounce'

import {
  INITIAL_PAGINATION_RESPONSE,
  SchedulingType,
  useGetAudienceRuleListQuery,
} from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '@shared/model/constants/table'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList from '@shared/ui/Dropdown/NestedList'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { REACT_FLOW_NO_DRAG } from '../_shared/constants'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import {
  TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP,
  TriggerNodeData,
  triggerNodeSchema,
} from './triggerNodeSchema'

const AUDIENCE_PER_PAGE = DEFAULT_PER_PAGE * 2

const SEARCH_TITLE_DEBOUNCE_TIME = 500

type TriggerNodeEditDialogProps = {
  isOpen: boolean
  initialValues: Partial<TriggerNodeData>
  audienceRule?: { title: string; schedulingType: SchedulingType }
  onConfirm: (
    values: Partial<TriggerNodeData>,
    schedulingType: SchedulingType
  ) => void
  onClose: () => void
}

export const TriggerNodeEditDialog = ({
  isOpen,
  initialValues,
  audienceRule,
  onConfirm,
  onClose,
}: TriggerNodeEditDialogProps) => {
  const [uiState, setUiState] = useState({
    isOpenDialog: false,
    page: DEFAULT_PAGE,
    audienceRule: audienceRule ?? { title: '', schedulingType: 'default' },
    searchTitle: '',
  })

  const formState = useWorkflowFormState()

  const {
    values,
    touched,
    errors,
    isSubmitting,
    isValidating,
    setFieldValue,
    setValues,
    setTouched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      // 這樣寫是因為後端儲存時會把設定成 undefined 的欄位移除，例如送出 `{ audienceRuleId: undefined }`，在取得時會變成 `{}`。
      // `{}` 會造成 formik 無法正確驗證表單，因此將預設值寫在此
      audienceRuleId: undefined,
      ...initialValues,
    },
    enableReinitialize: true,
    validationSchema: triggerNodeSchema,
    onSubmit: (values: Partial<TriggerNodeData>) => {
      if (!formState.isEditable) {
        onClose()
        return
      }

      onConfirm(values, uiState.audienceRule.schedulingType)
    },
  })

  const { audienceRuleListData, isAudienceFetching } =
    useGetAudienceRuleListQuery(
      {
        page: uiState.page,
        perPage: AUDIENCE_PER_PAGE,
        schedulingTypes: uiState.audienceRule.schedulingType,
        title: uiState.searchTitle,
      },
      {
        selectFromResult: ({
          data = INITIAL_PAGINATION_RESPONSE,
          isFetching,
        }) => ({
          audienceRuleListData: {
            items: data.items.map(a => ({
              label: a.title,
              value: `${a.id}`,
            })),
            totalCount: data.totalCount,
          },
          isAudienceFetching: isFetching,
        }),
      }
    )

  const handleSearchTitleChange = useMemo(
    () =>
      debounce(title => {
        setUiState(prev => ({
          ...prev,
          searchTitle: title,
          page: DEFAULT_PAGE,
        }))
      }, SEARCH_TITLE_DEBOUNCE_TIME),
    []
  )

  useEffect(() => {
    if (formState.isSubmitted === false) {
      return
    }

    setTouched({ audienceRuleId: true }, true)
  }, [formState.isSubmitted, setTouched])

  const { t } = useTranslation(['workflow', 'audience'])

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      contentOverflowY="visible"
      isOpen={isOpen}
      isLoading={isSubmitting || isValidating}
      modalTitle={t('workflow:node_trigger_configuration')}
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Box color={theme.colors.textSecondBlue} mb={1}>
        {t('workflow:node_trigger_hint')}
      </Box>

      <Box mb={1}>
        <RadioGroup
          value={uiState.audienceRule.schedulingType}
          onChange={({ target: { value } }) => {
            setValues({
              audienceRuleId: undefined,
            })

            setUiState(prev => ({
              ...prev,
              audienceRule: {
                title: '',
                schedulingType: value as SchedulingType,
              },
              searchTitle: '',
              page: DEFAULT_PAGE,
            }))
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap={6}
          >
            <Box display="flex" alignItems="center">
              <FormControlLabel
                value="default"
                disabled={!formState.isEditable}
                control={<Radio color="primary" size="small" />}
                label={
                  <Box
                    fontSize={14}
                    lineHeight="14px"
                    fontWeight={500}
                    color={theme.colors.textPrimaryBlue}
                    mr={-1}
                  >
                    {t('audience:type_dynamic_name')}
                  </Box>
                }
              />
              <Tooltip
                title={t('workflow:node_action_trigger_dynamic_audience_hint')}
              >
                <i
                  className={ICON.infoCircle}
                  color={theme.colors.textPrimaryBlue}
                />
              </Tooltip>
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                disabled={!formState.isEditable}
                value="none"
                control={<Radio color="primary" size="small" />}
                label={
                  <Box
                    fontSize={14}
                    lineHeight="14px"
                    fontWeight={500}
                    color={theme.colors.textPrimaryBlue}
                    mr={-1}
                  >
                    {t('audience:type_static_name')}
                  </Box>
                }
              />
              <Tooltip
                title={t('workflow:node_action_trigger_static_audience_hint')}
              >
                <i
                  className={ICON.infoCircle}
                  color={theme.colors.textPrimaryBlue}
                />
              </Tooltip>
            </Box>
          </Box>
        </RadioGroup>

        <Box width="100%">
          <DropdownList
            isFullWidth
            hasSearchBar
            isDisabled={!formState.isEditable || isAudienceFetching}
            isError={touched.audienceRuleId && Boolean(errors.audienceRuleId)}
            label={
              uiState.audienceRule.title ||
              t('workflow:node_trigger_dropdown_default')
            }
            onChange={({ value }) => {
              const currId = getIntSafe(value)
              TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP.set(currId, new Date())
              setFieldValue('audienceRuleId', currId)
            }}
            onFilterChange={handleSearchTitleChange}
            options={audienceRuleListData.items}
            popperClassName={REACT_FLOW_NO_DRAG}
            popperDisablePortal
            value={`${values.audienceRuleId}`}
            isShowPagination
            paginationProps={{
              totalCount: audienceRuleListData.totalCount,
              page: uiState.page - 1,
              rowsPerPage: 20,
              onPageChange: (_event, page) => {
                setUiState(prev => ({
                  ...prev,
                  page: page + 1,
                }))
              },
            }}
          />
        </Box>
      </Box>
    </ConfirmDialog>
  )
}

export default TriggerNodeEditDialog
