export type SupportedLanguage = 'zh-TW' | 'en-US'

export const SUPPORTED_LANGUAGE_MAP = {
  'zh-TW': '中文',
  'en-US': 'ENG',
}

export const SUPPORTED_LANGUAGES = [
  {
    value: 'zh-TW',
    label: SUPPORTED_LANGUAGE_MAP['zh-TW'],
  },
  {
    value: 'en-US',
    label: SUPPORTED_LANGUAGE_MAP['en-US'],
  },
] as const
