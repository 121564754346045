import { ReplacementData } from '@shared/api/rtkQuery/contentTemplate'

export type TemplateSms = {
  id: number
  title: string
  body: string
  replacementData?: ReplacementData
}

const templates: TemplateSms[] = [
  {
    id: 1,
    title: '新會員歡迎',
    body: `親愛的[姓名]會員您好！

非常開心您成為[公司/服務名]的專屬會員！立即登入觀看相關會員權益，祝您購物愉 [連結網址]`,
  },
  {
    id: 2,
    title: '棄置購物車提醒',
    body: `親愛的[姓名]

您的購物車似乎還有些未完成結帳的商品唷！趁有空的時候趕緊搭配優惠券[兌換碼]手刀結帳，以取得最佳優惠`,
  },
  {
    id: 3,
    title: '生日禮',
    body: `[姓名]您好！

[公司/服務名]要在這個特別的日子祝福您生日快樂！
獻給您限時的優惠碼[兌換碼]，本月使用即可享有全品項5折優惠 😽`,
  },
  {
    id: 4,
    title: 'VIP 優惠',
    body: `【專屬訊息，專屬為您】

專屬 VIP 會員您好，感謝您一直以來的支持與愛好。
這邊提供您不限時的專屬優惠碼[兌換碼]
    
為提供您更專屬的服務，往後對於購物流程有任何疑問皆可撥打專線[專線號碼]，將有專人為您服務`,
  },
  {
    id: 5,
    title: '限時行銷活動',
    body: `【OOO 桃園店】系統升級限時 5折！

為慶祝桃園服務處的盛大開幕，本月預約來電升級系統版本，提供您「5折」限時優惠；
    
活動只到本月底
立即預約：[活動網址]`,
  },
  {
    id: 6,
    title: '滿意度調查',
    body: `親愛的[姓名]會員您好

感謝您本月在商城內購買商品，希望您會喜歡！
為維護您的體驗與品質，邀請您撥空 30秒 填個問卷，還有機會獲得精美好禮喔 😉
    
[問卷網址]`,
  },
  {
    id: 7,
    title: '點數到期通知',
    body: `Hi [姓名]

感謝您對[公司/服務名]的長期支持與愛好，本訊息提醒您有點數即將到期，
請在截止前使用完畢，以免權益受到影響，非常感謝 🙌
    
查看點數[官網點數查詢連結]`,
  },
  {
    id: 8,
    title: '喚醒沈睡顧客',
    body: `【過新年，舊會員也要回娘家】

親愛的[姓名]您好
    
好久不見，最近過得好嗎？
過新年也回來看看最新的銷售排行商品吧，使用回娘家專屬優惠碼[兌換碼]，享有專屬 200元 折價！`,
  },
]

export default templates
