import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import ImportTagFile, {
  ImportButtonClickPayload,
} from '@entities/ImportFile/ImportTagFile'
import { useSingleMeasureFilter } from '@features/filters/SingleMeasureFilter'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import { createIngestion } from '@features/infoBox/model/taskSlice'
import {
  BehaviorTagPayload,
  DATA_SOURCE_TYPE_AUTO_AIGC_RULE,
  DATA_SOURCE_TYPE_IMPORT,
  INIT_BEHAVIOR_TAG_VALUE,
  useDeleteBehaviorTagMutation,
  useGetBehaviorTagQuery,
  usePatchBehaviorTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import ActionBar from '@shared/ui/ActionBar'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import DotLoader from '@shared/ui/loaders/DotLoader'
import SectionTitle from '@shared/ui/SectionTitle'
import { Tooltip } from '@shared/ui/tooltips'
import TagAigcProduct from '@widgets/tag/_shared/components/TagAigcProduct'
import TagInfo, { TagInfoValues } from '@widgets/tag/_shared/components/TagInfo'
import TagSingleMeasure from '@widgets/tag/_shared/components/TagSingleMeasure/TagSingleMeasure'
import TagStatistic from '@widgets/tag/_shared/components/TagStatistic'
import { BEHAVIOR_TAG_PATH } from '@widgets/tag/_shared/constants'
import useHandleError from '@widgets/tag/_shared/hooks/useHandleApiError'

const MultiTagDetail = () => {
  const { t } = useTranslation(['tag', 'common', 'settings'])
  const history = useHistory()

  const { id } = useParams<{ id: string }>()
  const tagId = getIntSafe(id)

  const dispatch = useAppDispatch()
  const { values: state, onDispatch } = useSingleMeasureFilter()
  const [tagValues, setTagValues] = useState<
    TagInfoValues<'behavior'> &
      Omit<BehaviorTagPayload, keyof TagInfoValues<'behavior'>>
  >(INIT_BEHAVIOR_TAG_VALUE)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const [deleteBehaviorTag, { isLoading: isDeletingBehaviorTag }] =
    useDeleteBehaviorTagMutation()
  const [updateBehaviorTag] = usePatchBehaviorTagMutation()
  const { data: tag, isLoading: isTagLoading } = useGetBehaviorTagQuery(tagId)
  const [handleApiError] = useHandleError('behavior')

  useEffect(() => {
    if (!tag) {
      return
    }

    setTagValues(
      tag as TagInfoValues<'behavior'> &
        Omit<BehaviorTagPayload, keyof TagInfoValues<'behavior'>>
    )
    if (tag.dataSourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE) {
      onDispatch({
        type: 'setMeasureFilterState',
        payload: {
          eventOperator: '',
          eventName: tag.autoAigcWithEventRule.eventName,
          timeRangeType: tag.autoAigcWithEventRule.timeRangeType,
          timeRangeParams: tag.autoAigcWithEventRule.timeRangeParams,
        },
      })
    }
  }, [onDispatch, tag])

  const handleUpdateBehaviorTagInfo = async () => {
    try {
      await updateBehaviorTag({
        id: tagId,
        title: tagValues.title,
        description: tagValues.description,
        groupId: tagValues.groupId,
        schedulingType: tagValues.schedulingType,
      }).unwrap()
    } catch (e) {
      handleApiError(e)
    }
  }

  const handleUpdateBehaviorTagAigcProduct = async () => {
    try {
      await updateBehaviorTag({
        id: tagId,
        // autoAigcWithEventRule 內層欄位無法 patch，需要整個欄位送出。因為是直接存入 DB
        autoAigcWithEventRule: {
          ...tagValues.autoAigcWithEventRule,
          relatedAllAigcTarget:
            tagValues.autoAigcWithEventRule?.relatedAllAigcTarget,
          relatedAigcTargetIds:
            tagValues.autoAigcWithEventRule?.relatedAigcTargetIds,
        },
      }).unwrap()
    } catch (e) {
      handleApiError(e)
    }
  }

  const handleUpdateBehaviorTagMeasure = async () => {
    try {
      await updateBehaviorTag({
        id: tagId,
        // autoAigcWithEventRule 內層欄位無法 patch，需要整個欄位送出。因為是直接存入 DB
        autoAigcWithEventRule: {
          ...tagValues.autoAigcWithEventRule,
          eventName: state.eventName,
          timeRangeType: state.timeRangeType,
          timeRangeParams: state.timeRangeParams,
        },
      }).unwrap()
    } catch (e) {
      handleApiError(e)
    }
  }

  const handleAigcProductUpdate = (aigcProductValues: {
    isSelectAll: boolean
    selectedProductIds: string[]
  }) =>
    setTagValues({
      ...tagValues,
      autoAigcWithEventRule: {
        ...tagValues.autoAigcWithEventRule,
        relatedAllAigcTarget: aigcProductValues.isSelectAll,
        relatedAigcTargetIds: aigcProductValues.selectedProductIds,
      },
    })

  const handleOnCancelAigcEventRule = () => {
    if (!tag) {
      return
    }

    onDispatch({
      type: 'setMeasureFilterState',
      payload: {
        eventOperator: '',
        eventName: tag.autoAigcWithEventRule.eventName,
        timeRangeType: tag.autoAigcWithEventRule.timeRangeType,
        timeRangeParams: tag.autoAigcWithEventRule.timeRangeParams,
      },
    })
  }

  const handleInfoOnCancel = () => {
    if (!tag) {
      return
    }

    setTagValues({
      ...tagValues,
      title: tag.title,
      description: tag.description,
      groupId: tag.groupId,
      schedulingType: tag.schedulingType,
    })
  }

  const handleDeleteTag = async () => {
    await deleteBehaviorTag(tagId).unwrap()
    setIsDeleteDialogOpen(false)

    history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${BEHAVIOR_TAG_PATH}`)
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${BEHAVIOR_TAG_PATH}`}>
            {t('common:route.tag_list')}
          </Link>
          {tag?.title && (
            <Tooltip title={tag.title}>
              <Box>{tag.title}</Box>
            </Tooltip>
          )}
        </Breadcrumbs>

        <ActionBar>
          <Button
            onClick={() => setIsDeleteDialogOpen(true)}
            startIcon={<i className={ICON.trashAlt} />}
            color="error"
            variant="contained"
          >
            {t('common:delete')}
          </Button>
        </ActionBar>
      </Box>

      {isTagLoading ? (
        <Box display="flex" justifyContent="center">
          <DotLoader />
        </Box>
      ) : (
        <>
          <TagInfo
            tagSourceType="behavior"
            isEnableEdit
            values={tagValues}
            onChange={tagInfo =>
              setTagValues(prev => ({
                ...prev,
                ...(tagInfo as TagInfoValues<'behavior'>),
              }))
            }
            onUpdate={handleUpdateBehaviorTagInfo}
            onCancel={handleInfoOnCancel}
          />

          {tagValues.dataSourceType === DATA_SOURCE_TYPE_IMPORT && (
            <Box mb={5}>
              <SectionTitle title={t('tag:import_tag_data')} />
              <Card>
                <ImportTagFile
                  defaultResourceActionType="behavior_tag_user.upsert"
                  tagResourceActionType={{
                    behavior_tag_user: ['upsert', 'delete'],
                  }}
                  onImportButtonClick={({
                    description,
                    file,
                    action,
                    tagResourceType,
                  }: ImportButtonClickPayload) => {
                    const createBehaviorTagUserPayload: CreateIngestionPayload<'behavior_tag_user'> =
                      {
                        createParams: {
                          action,
                          description,
                          tagId,
                        },
                        file,
                        resourceType: 'behavior_tag_user',
                      }
                    const createTagUserPayload: CreateIngestionPayload<'tag_user'> =
                      {
                        createParams: {
                          description,
                          resourceType: 'tag_user',
                          resourceId: tagId,
                        },
                        resourceType: 'tag_user',
                        file,
                      }

                    const payload =
                      tagResourceType === 'behavior_tag_user'
                        ? createBehaviorTagUserPayload
                        : createTagUserPayload

                    dispatch(createIngestion(payload))
                  }}
                />
              </Card>
            </Box>
          )}
          {tagValues.dataSourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE && (
            <>
              <SectionTitle title={t('tag:tag_rule')} />

              <TagSingleMeasure
                isEnableEdit
                isDisabled={isTagLoading}
                onDispatch={onDispatch}
                measureFilterState={state}
                onUpdate={handleUpdateBehaviorTagMeasure}
                onCancel={handleOnCancelAigcEventRule}
              />

              <TagAigcProduct
                isDisabled={isTagLoading}
                isEnableEdit
                relatedAllAigcTarget={
                  tagValues.autoAigcWithEventRule?.relatedAllAigcTarget ?? false
                }
                relatedAigcTargetIds={
                  tagValues.autoAigcWithEventRule?.relatedAigcTargetIds ?? []
                }
                onProductUpdate={handleAigcProductUpdate}
                onUpdate={handleUpdateBehaviorTagAigcProduct}
              />
            </>
          )}

          <TagStatistic tagStatisticalData={tag?.tagStatistics} />
        </>
      )}

      <DeleteConfirmDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isDeletingBehaviorTag}
        modalTitle={t('tag:sure_want_to_delete_this_tag')}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDeleteTag}
      />
    </>
  )
}

export default MultiTagDetail
