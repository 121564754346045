import styled from '@emotion/styled'

const FlexColumn = styled.div<{
  justifyContent?:
    | 'flex-end'
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignItems?:
    | 'flex-end'
    | 'flex-start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
`

/**
 * @deprecated 使用 mui/material/Box 代替
 */
export const SpacingColumn = styled(FlexColumn)<{
  marginBottomRatio?: number
  marginLeftRatio?: number
  marginRightRatio?: number
  paddingBottomRatio?: number
  paddingLeftRatio?: number
  paddingRightRatio?: number
}>`
  margin-right: ${({ marginRightRatio }) =>
    typeof marginRightRatio === 'number' ? marginRightRatio * 8 : 0}px;
  margin-bottom: ${({ marginBottomRatio }) =>
    typeof marginBottomRatio === 'number' ? marginBottomRatio * 8 : 0}px;
  margin-left: ${({ marginLeftRatio }) =>
    typeof marginLeftRatio === 'number' ? marginLeftRatio * 8 : 0}px;
  padding-right: ${({ paddingRightRatio }) =>
    typeof paddingRightRatio === 'number' ? paddingRightRatio * 8 : 0}px;
  padding-bottom: ${({ paddingBottomRatio }) =>
    typeof paddingBottomRatio === 'number' ? paddingBottomRatio * 8 : 0}px;
  padding-left: ${({ paddingLeftRatio }) =>
    typeof paddingLeftRatio === 'number' ? paddingLeftRatio * 8 : 0}px;
`

export default SpacingColumn
