import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import {
  DATA_SOURCE_TYPE_AUTO_AIGC_RULE,
  DataSourceType,
} from '@shared/api/rtkQuery'

import { InputArea, InputAreaGrid, LabelArea, Text } from '../styles'

type Props = {
  isEdit?: boolean
  dataSourceType: DataSourceType
  onChange: (value: DataSourceType) => void
}

const GenerationMethod = ({
  isEdit = false,
  dataSourceType,
  onChange,
}: Props) => {
  const { t } = useTranslation(['tag'])

  return (
    <InputAreaGrid mb={2}>
      <LabelArea>
        <Text>{`${t('tag:generation_method')}*`}</Text>
      </LabelArea>
      <InputArea>
        <RadioGroup
          row
          aria-label="data-source-type"
          name="data-source-type"
          value={dataSourceType}
          onChange={(_, value) => onChange(value as DataSourceType)}
        >
          <FormControlLabel
            checked
            value={DATA_SOURCE_TYPE_AUTO_AIGC_RULE}
            control={<Radio color="primary" size="small" />}
            label={<Box fontSize={14}>{t('tag:auto_updated_tag')}</Box>}
            disabled={!isEdit}
          />
        </RadioGroup>
      </InputArea>
    </InputAreaGrid>
  )
}

export default GenerationMethod
