import type { Simplify } from '@shared/lib/utils/type'
import type { TableRowDisabled } from '@shared/ui/table'

import type { AudienceFilterNode } from './types/filterNode'
import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'

export type SchedulingType = 'none' | 'default'

export type AudienceRuleFormValues = {
  title: string
  description: string
  schedulingType: SchedulingType
  filter: AudienceFilterNode
}

type PatchUpdateAudienceRule = Simplify<
  {
    id: number
  } & Partial<AudienceRuleFormValues>
>

export type JobStatus = 'created' | 'processing' | 'succeeded' | 'failed'

export type AudienceRule = {
  id: number
  title: string
  description: string
  creatorName: string
  schedulingType: SchedulingType
  latestUserCount: number
  latestMobileCount: number
  latestJobStatus: JobStatus
  // 上次排程完成的時間 => 可能成功 or 失敗
  latestJobFinishedAt: string
  // 上次成功產生名單的時間
  latestAudienceRuleUserCreatedAt: string
  updatedAt: string
  createdAt: string
}

export type AudienceRuleWithFilter = Simplify<
  AudienceRule & {
    filter: AudienceFilterNode
  }
>

/*
  如果用戶沒有啟用 pltv model，api response 裡 pltv30DAvg, pltv180DAvg, arpu180D 三個欄位不存在
*/
export type AudienceRulePreview = {
  usersCount: number
  totalCount: number
  pltv30DAvg?: number
  pltv180DAvg?: number
  arpu180D?: number
}

const transformResponseAudienceRuleList = ({
  items = [],
  totalCount,
}: PaginationResponse<AudienceRule>): PaginationResponse<
  AudienceRule & TableRowDisabled
> => ({
  items: items.map(currItem => ({
    ...currItem,
    isDisabled: currItem.latestJobStatus !== 'succeeded',
  })),
  totalCount,
})

export type AudienceRuleUser = {
  appUserId: string
  cdpUserId: string
  createdAt: string
}

const audienceApi = api.injectEndpoints({
  endpoints: builder => ({
    createAudienceRule: builder.mutation<AudienceRule, AudienceRuleFormValues>({
      query: payload => ({
        url: '/audience_rules',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (data: AudienceRule) =>
        transformResponseCamelCaseKeys(data),
      invalidatesTags: ['Audience'],
    }),
    getAudienceRuleList: builder.query<
      ReturnType<typeof transformResponseAudienceRuleList>,
      PaginationParams & { schedulingTypes: SchedulingType; title?: string }
    >({
      query: payload => ({
        url: '/audience_rules',
        params: payload,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (res: PaginationResponse<AudienceRule>) =>
        transformResponseAudienceRuleList(transformResponseCamelCaseKeys(res)),
      providesTags: ['Audience'],
    }),
    getAudienceRule: builder.query<AudienceRuleWithFilter, number>({
      query: id => `/audience_rules/${id}`,
      transformResponse: (res: AudienceRuleWithFilter) =>
        transformResponseCamelCaseKeys(res),
    }),
    patchUpdateAudienceRule: builder.mutation<
      AudienceRule,
      PatchUpdateAudienceRule
    >({
      query: payload => ({
        url: `/audience_rules/${payload.id}`,
        // 雖然 API 使用 PUT 但行為是 PATCH
        method: 'PUT',
        body: payload,
      }),
      transformResponse: (res: AudienceRule) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: ['Audience'],
    }),
    deleteAudienceRule: builder.mutation<void, number>({
      query: id => ({
        url: `/audience_rules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Audience'],
    }),
    createAudienceRuleJob: builder.mutation<void, number>({
      query: id => ({
        url: `/audience_rules/${id}/audience_jobs`,
        method: 'POST',
      }),
      invalidatesTags: ['Audience'],
    }),
    getAudienceRuleUserList: builder.query<
      PaginationResponse<AudienceRuleUser>,
      { id: number } & PaginationParams
    >({
      query: payload => ({
        url: `/audience_rules/${payload.id}/audience_rule_users`,
        params: payload,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (res: PaginationResponse<AudienceRuleUser>) =>
        transformResponseCamelCaseKeys(res),
    }),
    getAudienceRulePreview: builder.query<
      AudienceRulePreview,
      AudienceFilterNode
    >({
      query: payload => ({
        url: '/audience_rules/preview_user_summary',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (res: AudienceRulePreview) =>
        transformResponseCamelCaseKeys(res),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateAudienceRuleMutation,
  useGetAudienceRuleListQuery,
  useGetAudienceRuleQuery,
  usePatchUpdateAudienceRuleMutation,
  useDeleteAudienceRuleMutation,
  useCreateAudienceRuleJobMutation,
  useGetAudienceRuleUserListQuery,
  useLazyGetAudienceRulePreviewQuery,
} = audienceApi
