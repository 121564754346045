import type { CDPRoute } from './constants'

interface RouteByCategory {
  category: string
  text: string
  routes: CDPRoute[]
}

/**
 * @description 根據目前 url 判斷現在 route 的 category
 *
 * @param routesByCategory 定義在 /src/components/Sidebar/constants
 * @param pathname 當前 url
 */
export const getCurrCategoryByURL = (
  routesByCategory: RouteByCategory[],
  pathname: string
) => {
  const categories = routesByCategory
    .filter(({ routes }) =>
      routes.some(({ path }) => pathname.includes(path, 0))
    )
    .map(({ category }) => category)

  return categories[0]
}

/**
 * @description 根據目前 route 的 category 取出 routes(定義在 routesByCategory)
 *
 * @param routesByCategory 定義在 /src/components/Sidebar/constants
 * @param category 目前選的 category
 */
export const getRoutesByCategory = (
  routesByCategory: RouteByCategory[],
  category: string
) => {
  const routeByCategory = routesByCategory.find(
    routeInfo => routeInfo.category === category
  )

  return routeByCategory ? routeByCategory.routes : []
}
