import type { ProductTagsList } from '@shared/api/rtkQuery'
import type { TableColumn } from '@shared/ui/table'

const columns: TableColumn<ProductTagsList['items'][number]>[] = [
  { headerName: 'ai_marketing_assistant_hashtag', field: 'hashtag' },
  { headerName: 'ai_marketing_assistant_referred_platform', field: 'platform' },
  {
    headerName: 'ai_marketing_assistant_mention_rate',
    field: 'mentionRate',
    isSortable: true,
    align: 'right',
    valueFormatter: value => `${(value * 100).toFixed(0)}%`,
  },
  {
    headerName: 'ai_marketing_assistant_post_count',
    field: 'postCount',
    isSortable: true,
    align: 'right',
  },
  {
    headerName: 'ai_marketing_assistant_sponsor_count',
    field: 'sponsorCount',
    isSortable: true,
    align: 'right',
  },
]

export default columns
