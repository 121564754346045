import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetAudienceRuleQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { Tooltip } from '@shared/ui/tooltips'
import { NO_DATA_ID } from '@widgets/audience/model/constant'

const SectionBreadcrumbs = () => {
  const { t } = useTranslation(['common'])

  const { id } = useParams<{ id: string }>()
  const audienceId = getIntSafe(id, NO_DATA_ID)
  const { data: audienceRule } = useGetAudienceRuleQuery(audienceId, {
    skip: audienceId === NO_DATA_ID,
  })

  return (
    <Breadcrumbs>
      <Link to={`/${CATEGORY.cdm}/${PAGE_ROOT.audiences}`}>
        {t('common:route.audience_list')}
      </Link>
      {audienceRule?.title && (
        <Tooltip title={audienceRule.title}>
          <Box>{audienceRule.title}</Box>
        </Tooltip>
      )}
    </Breadcrumbs>
  )
}

export default SectionBreadcrumbs
