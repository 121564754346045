import styled from '@emotion/styled'

export const StaticsType = styled.div`
  margin-right: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`
export const StaticsNumber = styled.div`
  margin-left: 4px;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
`
