import type { TFunction } from 'i18next'

import { getMMDDWithHyphen, isISODate, WEEKDAYS } from '@shared/lib/utils/time'

export const getIndicatorDisplayText = (
  key: string,
  t: TFunction<'dateAndChart'[]>
) => {
  if (isISODate(key)) {
    const targetWeekday = new Date(key).getDay()

    return `${getMMDDWithHyphen(key)} (${t(
      `dateAndChart:weekday.${WEEKDAYS[targetWeekday]}`
    )})`
  }

  switch (key) {
    case 'latest':
    case 'sum':
    case 'average':
    case 'conversion_rate_total':
    case 'conversion_number_total':
      return t(`dateAndChart:statistics.${key}`)
    default:
      return key
  }
}
