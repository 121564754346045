import * as yup from 'yup'

import type { SearchColumnAlias } from '@shared/api/rtkQuery'

export type FormValues = {
  searchColumn: SearchColumnAlias
  searchValue: string
}

export const schema = yup.object<FormValues>({
  searchColumn: yup
    .mixed()
    .oneOf<SearchColumnAlias>(['app_user_id', 'name', 'mobile_phone', 'email'])
    .required(),
  searchValue: yup.string().required(),
})
