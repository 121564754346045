import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { COLORS } from '@shared/ui/charts'
import theme from '@theme'
import { getChartTooltip } from '@widgets/audience/lib/chartUtils'
import { CHART_HEIGHT } from '@widgets/audience/model/constant'
import ChartWrapper from '@widgets/audience/ui/AudienceDetail/AudienceDetailAnalysis/Charts/_shared/ChartWrapper'

import {
  DataArea,
  LegendArea,
  LegendAreaGrid,
} from './HorizontalBarChartLegend.style'

type Props = {
  series: number[]
  categories: string[]
  total: number
}

const HorizontalBarChart = ({ series, categories, total }: Props) => {
  const { t } = useTranslation(['audience'])

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    colors: COLORS,
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        barHeight: '75%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'bottom',
        },
      },
    },
    stroke: {
      width: 1,
      colors: [theme.colors.white],
    },
    xaxis: {
      type: 'category',
      categories,
      labels: {
        hideOverlappingLabels: true,
        formatter: value =>
          t('audience:unit.people', { number: (+value).toLocaleString() }),
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: theme.colors.textPrimaryBlue,
          fontSize: '14px',
          fontWeight: 600,
        },
      },
    },
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center',
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        radius: 12,
      },
    },
    grid: {
      borderColor: theme.colors.borderColor,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    tooltip: {
      enabled: true,
      custom: getChartTooltip({ total }),
    },
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      justifyContent="center"
      alignItems="center"
    >
      <ChartWrapper>
        <ReactApexChart
          options={options}
          series={[
            {
              data: series,
            },
          ]}
          type="bar"
          height={CHART_HEIGHT - 60}
          width="100%"
        />
      </ChartWrapper>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        overflow="scroll"
      >
        {series.map((s, index) => {
          const rationValue = `(${((s * 100) / total).toFixed(0)}%)`

          return (
            <Box key={categories[index]} mr={1}>
              <LegendAreaGrid>
                <LegendArea>
                  <Box
                    width="10px"
                    height="10px"
                    borderRadius="50%"
                    sx={{
                      backgroundColor: COLORS[index],
                    }}
                  />
                </LegendArea>
                <DataArea
                  fontSize="16px"
                  fontWeight={600}
                  whiteSpace="nowrap"
                  sx={theme => ({
                    color: theme.colors.black,
                  })}
                >
                  {categories[index]}
                </DataArea>
              </LegendAreaGrid>
              <LegendAreaGrid>
                <DataArea
                  fontSize="14px"
                  fontWeight={600}
                  sx={theme => ({
                    color: theme.colors.textPrimaryBlue,
                  })}
                >
                  {t('audience:unit.people', { number: s.toLocaleString() })}
                </DataArea>
              </LegendAreaGrid>
              <LegendAreaGrid>
                <DataArea
                  fontSize="14px"
                  fontWeight={600}
                  sx={theme => ({
                    color: theme.colors.textPrimaryBlue,
                  })}
                >
                  {rationValue}
                </DataArea>
              </LegendAreaGrid>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default HorizontalBarChart
