import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import LadingImage from '@shared/assets/images/userProfile/landingImage.png'
import Breadcrumbs from '@shared/ui/Breadcrumbs'

import SearchBar from '../_shared/SearchBar'

const Landing = () => {
  const { t } = useTranslation(['common', 'userProfile'])
  return (
    <>
      <Breadcrumbs>
        <Box>{t('common:route.user_profile')}</Box>
      </Breadcrumbs>
      <Box
        sx={{
          display: 'grid',
          justifyItems: 'center',
          alignContent: 'start',
          gap: 4,
          margin: '0 auto',
          minHeight: '600px',
        }}
      >
        <img width="450" src={LadingImage} alt="landing" />
        <Box
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            color: theme => theme.colors.textPrimaryBlue,
          }}
          component="h3"
        >
          {t('userProfile:user_profile_list.default_hint')}
        </Box>
        <SearchBar />
      </Box>
    </>
  )
}

export default Landing
