import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { ConfirmDialog } from '@shared/ui/dialogs'

import { GroupNameInput } from '../style'

type Props = {
  mode: 'add' | 'edit'
  isOpen: boolean
  isSubmitting: boolean
  name?: string
  id?: number
  onClose: () => void
  onSubmit: (name: string) => void
}

const ChartGroupDialog = ({
  mode,
  isOpen,
  isSubmitting,
  name = '',
  onClose: handleClose,
  onSubmit: handleSubmit,
}: Props) => {
  const { t } = useTranslation(['dashboard', 'common'])

  const formik = useFormik<{ groupName: string }>({
    enableReinitialize: true,
    initialValues: {
      groupName: name,
    },
    validate: values => {
      const errors: Record<string, string> = {}

      if (values.groupName === '') {
        errors.groupName = t('common:errors.required')
      }

      return errors
    },
    onSubmit: async values => {
      await handleSubmit(values.groupName)

      formik.resetForm()
    },
  })

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="md"
      modalTitle={
        mode === 'add' ? t('dashboard:add_group') : t('common:rename')
      }
      onConfirm={formik.submitForm}
      isLoading={isSubmitting}
    >
      <GroupNameInput
        variant="standard"
        disabled={isSubmitting}
        name="name"
        value={formik.values.groupName}
        error={Boolean(formik.errors.groupName)}
        placeholder={t('dashboard:please_enter_group_name')}
        helperText={
          formik.errors.groupName ||
          t('common:input.limit_word_length', {
            max_length: MAX_LIST_NAME_LENGTH,
          })
        }
        fullWidth
        onChange={e => {
          formik.setFieldValue(
            'groupName',
            e.target.value.slice(0, MAX_LIST_NAME_LENGTH)
          )
        }}
      />
    </ConfirmDialog>
  )
}

export default ChartGroupDialog
