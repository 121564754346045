// 單一 user 可以有多組 tag
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import {
  formatApiError,
  formatRTKQueryError,
  handleApiError,
} from '@entities/apiHandler'
import {
  INITIAL_PAGINATION_RESPONSE,
  POLLING_INTERVAL,
  useDeleteBehaviorTagMutation,
  useGetBehaviorTagListQuery,
  usePatchBehaviorTagMutation,
  usePostBehaviorTagJobMutation,
  useTagGroupsQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { createRouteState } from '@shared/lib/utils/routeUtils'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { closeToast, openToast } from '@shared/model/slices'
import { ConfirmDialog, DeleteConfirmDialog } from '@shared/ui/dialogs'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { DataTable } from '@shared/ui/table'
import type { TagRouteSate } from '@widgets/tag/_shared/type'
import type { TagGroupMapType } from '@widgets/tag/Overview/_shared/type'

import useHandleApiError from '../../_shared/hooks/useHandleApiError'
import useMultiTagColumns from './useMultiTagColumns'

type Props = {
  routeState: TagRouteSate
}

const Multi = ({ routeState: { page, perPage } }: Props) => {
  const { t } = useTranslation(['common', 'audience', 'tag'])
  const {
    pathname,
    state: routeState = createRouteState({
      page,
      perPage,
    }),
  } = useLocation<TagRouteSate>()
  const [handleTagApiError] = useHandleApiError('behavior')

  const history = useHistory<TagRouteSate>()
  const [pollingInterval, setPollingInterval] = useState<number>()

  const { data: tagListData = INITIAL_PAGINATION_RESPONSE, isLoading } =
    useGetBehaviorTagListQuery(
      {
        page,
        perPage,
      },
      {
        pollingInterval,
      }
    )

  const { tagGroupMap, isLadingTagGroups } = useTagGroupsQuery(null, {
    selectFromResult: ({ data = [], isLoading }) => ({
      tagGroupMap: data.reduce(
        (acc, cur) => {
          acc[cur.id as number] = cur.title
          return acc
        },
        { 0: t('tag:no_group') } as TagGroupMapType
      ),

      isLadingTagGroups: isLoading,
    }),
  })

  const [updateTag, { isLoading: isUpdatingTag }] =
    usePatchBehaviorTagMutation()
  const [deleteTag, { isLoading: isDeletingTag }] =
    useDeleteBehaviorTagMutation()

  const [postBehaviorTagJob] = usePostBehaviorTagJobMutation()

  const dispatch = useAppDispatch()

  const handleChangePage = (newPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: newPage + 1,
      })
    )
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: DEFAULT_PAGE,
        perPage: rowsPerPage,
      })
    )
  }

  const [openDropdownTagId, setOpenDropdownTagId] = useState<number>(-1)
  const [deleteConfirmTagId, setDeleteConfirmTagId] = useState<number>()
  const [renameTagId, setRenameTagId] = useState<number>()
  const [renameTagTitle, setRenameTagTitle] = useState<string>('')

  const errors = {
    renameText:
      renameTagTitle.length === 0
        ? t('common:errors.max_count', {
            count: MAX_LIST_NAME_LENGTH,
          })
        : '',
  }

  const safeCloseAllPopup = () => {
    // 需要照順序關閉彈窗避免頁面跳動
    setRenameTagId(undefined)
    setDeleteConfirmTagId(undefined)
  }

  const isNeedPolling = tagListData.items.some(
    tag => tag.latestAutoAigcWithEventJobStatus === 'processing'
  )

  useEffect(() => {
    if (isNeedPolling) {
      setPollingInterval(POLLING_INTERVAL)
      return
    }

    setPollingInterval(undefined)
  }, [isNeedPolling])

  const handlePostJob = useCallback(
    (id: number) => {
      postBehaviorTagJob({ id: String(id) })
        .then(() => {
          dispatch(
            openToast({
              message: t('tag:updating'),
              status: 'success',
            })
          )
        })
        .catch(() => {
          openToast({
            message: t('tag:update_failed'),
            status: 'error',
          })
        })
        .finally(() => {
          closeToast()
        })
    },
    [dispatch, postBehaviorTagJob, t]
  )

  const columns = useMultiTagColumns({
    openDropdownTagId,
    tagGroupMap,
    setOpenDropdownTagId,
    setRenameTagId,
    setRenameTagTitle,
    setDeleteConfirmTagId,
    handlePostJob,
  })

  return (
    <>
      <DataTable
        columns={columns}
        rows={tagListData.items}
        count={tagListData.totalCount}
        page={routeState.page - 1}
        rowsPerPage={routeState.perPage}
        rowIdKey="id"
        isRowClickable
        isLoading={isLoading || isLadingTagGroups}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <DeleteConfirmDialog
        isOpen={deleteConfirmTagId !== undefined}
        isLoading={isDeletingTag}
        modalTitle={t('tag:sure_want_to_delete_this_tag')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (deleteConfirmTagId) {
            deleteTag(+deleteConfirmTagId)
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                dispatch(handleApiError(formatApiError(error)))
              })
          }
        }}
      />

      <ConfirmDialog
        isLoading={isUpdatingTag}
        isOpen={Boolean(renameTagId)}
        maxWidth="xs"
        modalTitle={t('tag:new_tag_name')}
        onClose={safeCloseAllPopup}
        onConfirm={() => {
          if (renameTagId && renameTagTitle) {
            updateTag({
              id: +renameTagId,
              title: renameTagTitle,
            })
              .unwrap()
              .then(safeCloseAllPopup)
              .catch(error => {
                handleTagApiError(error)
                const formatError = formatRTKQueryError(error)

                dispatch(handleApiError(formatError))
              })
          }
        }}
      >
        <TextInput
          fullWidth
          placeholder={t('tag:please_enter_new_tag_name')}
          error={Boolean(errors.renameText)}
          onChange={({ target }) => {
            const newTagTitle = target.value.substring(0, MAX_LIST_NAME_LENGTH)
            setRenameTagTitle(newTagTitle)
          }}
          value={renameTagTitle}
        />
        <TextInputErrorText>
          {errors.renameText && <span>{errors.renameText}</span>}
        </TextInputErrorText>
      </ConfirmDialog>
    </>
  )
}

export default Multi
