import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import ImportFile from '@entities/ImportFile'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import { createIngestion } from '@features/infoBox/model/taskSlice'
import { useAppDispatch } from '@shared/lib/hooks'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'

const IMPORT_FILE_TYPE = 'user_profile'

const ImportUsers = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['common'])

  return (
    <>
      <Breadcrumbs>
        <Box>{t('common:route.v3.import_users')}</Box>
      </Breadcrumbs>
      <Card>
        <ImportFile
          type={IMPORT_FILE_TYPE}
          onImportButtonClick={({ description, file, action }) => {
            const payload: CreateIngestionPayload<'user_profile'> = {
              createParams: {
                action,
                resourceType: IMPORT_FILE_TYPE,
                description,
              },
              resourceType: IMPORT_FILE_TYPE,
              file,
            }
            dispatch(createIngestion(payload))
          }}
        />
      </Card>
    </>
  )
}

export default ImportUsers
