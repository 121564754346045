import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import {
  NotificationRuleFormValues,
  NotificationUsers,
  useGetNotificationUsersQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

type Props = {
  isDisabled: boolean
  userIds: NotificationRuleFormValues['userIds']
  onUserIdsChange: (userIds: NotificationRuleFormValues['userIds']) => void
}

const NotificationTarget = ({
  isDisabled,
  userIds,
  onUserIdsChange,
}: Props) => {
  const { t } = useTranslation(['analytics', 'common'])
  const [isAutocompleteOptionOpen, setIsAutocompleteOptionOpen] =
    useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')

  // notificationUserList 是可以接收到通知的所有人
  const { notificationUserList = [], isNotificationUserListLoading } =
    useGetNotificationUsersQuery(null, {
      selectFromResult: ({ data = [], isLoading }) => ({
        notificationUserList: data.filter(user => !user.archivedAt),
        isNotificationUserListLoading: isLoading,
      }),
    })

  const handleAutoCompleteChange = (
    _: React.SyntheticEvent,
    value: NotificationUsers
  ) => onUserIdsChange(value.map(({ id }) => id))

  const handleDelete = (deleteId: number) =>
    onUserIdsChange(userIds.filter(id => id !== deleteId))

  const handleOptionClose = () => {
    setIsAutocompleteOptionOpen(false)
    setSearchText('')
  }

  const handleOptionOpen = () => setIsAutocompleteOptionOpen(true)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value)

  return (
    <Box width="100%">
      <Autocomplete
        id="notification-target"
        autoComplete
        value={notificationUserList.filter(({ id }) => userIds.includes(id))}
        multiple
        fullWidth
        disablePortal={false}
        popupIcon={null}
        noOptionsText={
          Boolean(searchText) &&
          t('analytics:notificationRule.notification_target_no_options')
        }
        size="small"
        disabled={isDisabled}
        loading={isNotificationUserListLoading}
        inputValue={searchText}
        open={isAutocompleteOptionOpen && Boolean(searchText)} // 有輸入才開啟
        getOptionLabel={({ email }) => email}
        options={notificationUserList.filter(({ id }) => !userIds.includes(id))}
        // ToDo 未來如果使用者量體太大，再考慮用滾動載入，屆時 api 也會改成支援分頁
        // ListboxComponent={forwardRef((props, ref) => {
        //   const pageLimit = projectUserListTotalCount / DEFAULT_PER_PAGE
        //   const hasMore = page < pageLimit && projectUserListTotalCount > 0
        //   const isFirstFetching =
        //     isProjectUserListLoading && userList.length === 0

        //   return (
        //     // eslint-disable-next-line react/jsx-props-no-spreading
        //     <ul {...props} ref={ref as ForwardedRef<HTMLUListElement>}>
        //       {props.children}
        //       {((isProjectUserListSuccess && hasMore) || isFirstFetching) && (
        //         <InfiniteScrollLoader
        //           isLoading={isProjectUserListFetching}
        //           loadMore={debounce(() => {
        //             if (hasMore) {
        //               setPage(page + 1)
        //             }
        //           }, 2000)}
        //         />
        //       )}
        //     </ul>
        //   )
        // })}
        onOpen={handleOptionOpen}
        onClose={handleOptionClose}
        onChange={handleAutoCompleteChange}
        // FIXME: remove any
        renderInput={(params: any) => (
          <TextField
            onChange={handleInputChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            sx={theme => ({
              '& .MuiInputBase-root': {
                backgroundColor: params.inputProps.disabled
                  ? theme.colors.veryLightBlue
                  : theme.colors.bgPrimaryGrey,
                color: theme.colors.black,
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '18px',
              },
              fieldset: {
                display: 'none',
              },
            })}
          />
        )}
        renderTags={(tags = [], getTagProps) => (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flexWrap="wrap"
          >
            {tags.map((tag, index) => (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                key={tag.id}
                fontSize={13}
                mr={1.5}
                my={0.5}
                height={24}
                lineHeight={24}
                fontWeight={600}
                borderRadius="2px"
                py={1}
                pl={1.5}
                sx={theme => ({
                  backgroundColor: theme.colors.textPrimaryBlue,
                  color: theme.colors.white,
                  '.MuiSvgIcon-root': {
                    m: 0,
                    backgroundColor: 'transparent',
                    color: theme.colors.textPrimaryBlue,
                  },
                })}
              >
                {tag.email}
                <IconButton
                  onClick={e => {
                    handleDelete(tag.id)

                    getTagProps({ index }).onDelete(e)
                  }}
                  disabled={isDisabled}
                  disableRipple
                  sx={{
                    fontSize: 12,
                  }}
                >
                  <Icon
                    icon={ICON.multiply}
                    fontSize="inherit"
                    color={theme.colors.white}
                  />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      />
    </Box>
  )
}

export default memo(NotificationTarget)
