import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { ProductsKeywordsAigc } from '@shared/api/rtkQuery'
import Card from '@shared/ui/Card'

import Keyword from '../../Keyword'

type Props = Pick<ProductsKeywordsAigc, 'name' | 'keywords' | 'extendKeywords'>

const ProductKeyword = ({ name, keywords, extendKeywords }: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])

  return (
    <Card mb={5}>
      <Box
        fontSize={16}
        fontWeight={600}
        lineHeight="24px"
        mb={3}
        sx={theme => ({
          color: theme.colors.textPrimaryBlue,
        })}
      >
        {name}
      </Box>
      <Box
        display="flex"
        fontSize={14}
        fontWeight={500}
        pb={3}
        mb={3}
        sx={theme => ({
          borderBottom: `2px solid ${theme.colors.black6}`,
        })}
      >
        <Box
          minWidth={120}
          lineHeight="20px"
          textAlign="left"
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {t('aiMarketingAssistant:auto_tag_analysis.keywords')}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          {keywords.map((keyword, idx) => (
            <Keyword key={`keywords_${keyword}_${idx}`} keyword={keyword} />
          ))}
        </Box>
      </Box>
      <Box display="flex" fontSize={14} fontWeight={500} pb={3}>
        <Box
          minWidth={120}
          lineHeight="20px"
          textAlign="left"
          sx={theme => ({
            color: theme.colors.textSecondBlue,
          })}
        >
          {t('aiMarketingAssistant:auto_tag_analysis.extend_keywords')}
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexWrap="wrap"
        >
          {extendKeywords.map((keyword, idx) => (
            <Keyword
              key={`extendKeywords_${keyword}_${idx}`}
              keyword={keyword}
            />
          ))}
        </Box>
      </Box>
    </Card>
  )
}

export default ProductKeyword
