import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'

import eventTrialImgUrl from '@shared/assets/images/analytics/event/eventTrial.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { formatEventRule, trackEvent } from '@shared/lib/utils/amplitude'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import type { AnalyticsFormHeaderRenderProps } from '@widgets/analytics/_shared/types'

import {
  useChartTitleList,
  useEventRuleChartEditList,
  useEventRuleForm,
  useExportEventRule,
} from '../../hooks'
import { PREVIEW_BUTTON_MIN_WIDTH } from '../../models/constants'
import type { EventRuleFormValues, EventRuleSettings } from '../../types'
import { getFilterCompared } from '../../utils'
import EventRuleChart from '../EventRuleChart'
import EventRuleFilter from '../EventRuleFilter'
import EventRuleFormConfirmDialog from './EventRuleFormConfirmDialog'

type EventRuleFormProps = {
  formValues?: EventRuleFormValues
  isFetchingFormValues?: boolean
  isSubmitting: boolean
  header: AnalyticsFormHeaderRenderProps
  onSubmit: (values: EventRuleFormValues) => Promise<void>
}

const EventRuleForm = ({
  formValues,
  isFetchingFormValues = false,
  isSubmitting,
  header,
  onSubmit,
}: EventRuleFormProps) => {
  const { t } = useTranslation(['audience', 'analytics'])

  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false)

  const { maxCount, introduction: hasInstruction } = useAppSelector(
    state => state.auth.plan.analytics.event
  )

  const {
    dispatch,
    errors,
    handleBarChartTypeChange,
    handleChartTypeChanged,
    handleTimeRangeParamsChange,
    handleTimeRangeParamsComparedChange,
    handleTopNChange,
    handleUnitChange,
    resetValues,
    setEventRuleSettings,
    values,
  } = useEventRuleForm({ initialValues: formValues })

  const hasFilterError = isEmpty(errors.filter) === false

  const eventNameList = useChartTitleList(values.filter.statisticMeasures)

  const [getEventRuleChartDataList, { chartDataList, isFetching }] =
    useEventRuleChartEditList({
      eventNameList,
      timeRangeParamsCompared: values.eventRuleSettings.timeRangeParamsCompared,
      topN: values.eventRuleSettings.topN,
    })

  const [exportEventRule, { isExporting }] = useExportEventRule(
    values.filter.timeRangeParams
  )

  const handleEventRuleExport = () => {
    exportEventRule(omit(values.filter, 'topN'))

    trackEvent('EventAnalysisExported', formatEventRule(values.filter))
  }

  const handleEventRulePreview = () => {
    if (hasFilterError) {
      return
    }

    getEventRuleChartDataList(
      values.filter,
      values.eventRuleSettings.timeRangeParamsCompared
    )

    trackEvent('EventAnalysisPreviewed', formatEventRule(values.filter))
  }

  const handleEventRuleFormDialogCancel = () => {
    if (isSubmitting) {
      return
    }

    setIsShowConfirmDialog(false)
  }

  const currentUserEmail = useAppSelector(state => state.auth.userInfo.email)

  const handleEventRuleFormDialogConfirm = async (
    settings: EventRuleSettings
  ) => {
    setEventRuleSettings(settings)

    // 將當前 topN 寫回 filter 提交給後端 API
    const targetFilter = cloneDeep(values.filter)
    targetFilter.topN = values.eventRuleSettings.topN

    await onSubmit({
      title: settings.title,
      dashboardConfig: {
        chartType: settings.chartType,
        displayVisibility: settings.indicator,
      },
      iam:
        settings.privacyLevel === 'public'
          ? null
          : { ownerId: currentUserEmail },
      groupId: settings.groupId,
      filters: values.eventRuleSettings.timeRangeParamsCompared
        ? [
            targetFilter,
            getFilterCompared(
              targetFilter,
              values.eventRuleSettings.timeRangeParamsCompared
            ),
          ]
        : [targetFilter],
    })

    trackEvent('EventAnalysisCreated', formatEventRule(targetFilter))

    setIsShowConfirmDialog(false)
  }

  // 變更維度分析時，切換顯示指標
  useEffect(() => {
    if (values.filter.groupByFields.length === 0) {
      return
    }

    setEventRuleSettings(prev => ({
      ...prev,
      indicator:
        prev.chartType === 'pie_chart'
          ? { ...prev.indicator, sum: true, avg: false, latest: false }
          : { ...prev.indicator, latest: true, sum: false, avg: false },
    }))
  }, [setEventRuleSettings, values.filter.groupByFields])

  // 自動 refetch 圖表時機
  // 1. 時間區間改變
  // 2. 對比時間區間改變
  // 3. 統計單位改變 (天、週、月)
  useEffect(() => {
    if (hasFilterError) {
      return
    }

    getEventRuleChartDataList(
      values.filter,
      values.eventRuleSettings.timeRangeParamsCompared
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getEventRuleChartDataList,
    values.eventRuleSettings.timeRangeParamsCompared,
    values.filter.timeRangeParams,
    values.filter.timeRangeUnit,
  ])

  return (
    <>
      {hasInstruction && (
        <FeatureInstruction
          title={t('analytics:event.instruction.title')}
          description={t('analytics:event.instruction.description')}
          notice={t('analytics:event.instruction.notice', { maxCount })}
          imageUrl={eventTrialImgUrl}
        />
      )}
      {header({
        isExportingCSV: isExporting,
        isExportDisabled: hasFilterError,
        isSaveDisabled: hasFilterError,
        onReset: resetValues,
        onExport: handleEventRuleExport,
        onShowConfirmDialog: () => setIsShowConfirmDialog(true),
      })}

      <Box mb={5}>
        <EventRuleFilter
          state={values.filter}
          dispatch={dispatch}
          errors={errors.filter}
        />
        {/* <pre>{JSON.stringify(filter, null, 4)}</pre> */}
      </Box>

      <Box mb={5}>
        <DeepBlueGradientButton
          minWidth={PREVIEW_BUTTON_MIN_WIDTH}
          disabled={isFetching || hasFilterError}
          onClick={handleEventRulePreview}
        >
          {t('audience:v3.preview_event_analytics')}
        </DeepBlueGradientButton>
      </Box>

      <EventRuleChart
        barChartDisplayType={values.eventRuleSettings.indicator.type}
        chartType={values.eventRuleSettings.chartType}
        chartData={chartDataList}
        timeRangeParams={values.filter.timeRangeParams}
        timeRangeParamsCompared={
          values.eventRuleSettings.timeRangeParamsCompared
        }
        unit={values.filter.timeRangeUnit}
        isLoading={isFetching || isFetchingFormValues}
        topN={values.eventRuleSettings.topN}
        eventCount={values.filter.statisticMeasures.length}
        onBarChartTypeChange={handleBarChartTypeChange}
        onChartTypeChanged={handleChartTypeChanged}
        onTimeRangeParamsChange={handleTimeRangeParamsChange}
        onTimeRangeParamsComparedChange={handleTimeRangeParamsComparedChange}
        onTopNChange={handleTopNChange}
        onUnitChange={handleUnitChange}
      />

      {/* 
        在表單內使用 enableReinitialize 遇到某些屬性 (ex. `sum: true`) 無法重設的問題
        改成每次開啟 EventFormConfirmDialog 時才渲染表單。
      */}
      {isShowConfirmDialog && (
        <EventRuleFormConfirmDialog
          initialValues={values.eventRuleSettings}
          isOpen
          isChartTypeDisabled={
            values.eventRuleSettings.timeRangeParamsCompared !== undefined
          }
          isIndicatorDisabled={values.filter.groupByFields.length > 0}
          isDisablePrivacyLevel={formValues !== undefined}
          isSubmitting={isSubmitting}
          onCancel={handleEventRuleFormDialogCancel}
          onConfirm={handleEventRuleFormDialogConfirm}
        />
      )}
    </>
  )
}

export default EventRuleForm
