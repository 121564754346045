import type { CSSProperties } from 'react'
import kebabCase from 'lodash/fp/kebabCase'

export const CREATE_SMS_STATUS_CODE = {
  DUPLICATE_NAME: 409,
}

export const URL_WITH_UTM_REGEX = /urlWithUtmStart:(http.+):urlWithUtmEnd/g

export const ACTION_URL_REGEX = /{{action_url_[0-9]+}}/g

export const ACTION_URL_WORD = 'action_url'

export const TRACING_URL_REGEX = /{{tracing_url([0-9]+)}}/g

// mergeTag 排除 tracing_url 與 action_url 的格式
export const MERGE_TAG_REGEX =
  /{{((?!tracing_url[0-9]+|action_url_[0-9]+)[a-zA-Z0-9_\s]+)}}/g

export const UNLAYER_TOOLS_COMPONENT_TYPE_OPTIONS = [
  { label: '商品卡片', value: 'product' },
  { label: '未結帳商品', value: 'unpurchasedProduct' },
] as const

export type UnlayerToolsComponentType =
  (typeof UNLAYER_TOOLS_COMPONENT_TYPE_OPTIONS)[number]['value']

export const UNSUBSCRIBE_KEYWORD = 'ikala_cdp_unsubscription_url'
export const UNSUBSCRIBE_KEYWORD_REG_EXP = new RegExp(UNSUBSCRIBE_KEYWORD, 'g')

export const FOOTER_STYLE = Object.entries({
  display: 'flex',
  marginTop: '32px',
} as CSSProperties).reduce(
  (collation, [key, value]) => collation + `${kebabCase(key)}:${value};`,
  ''
)

export const FOOTER_CONTENT_STYLE = Object.entries({
  margin: '0 auto',
  color: 'darkgray',
} as CSSProperties).reduce(
  (collation, [key, value]) => collation + `${kebabCase(key)}:${value};`,
  ''
)

export const LINK_STYLE = Object.entries({
  color: '#15c',
  textDecoration: 'underline',
} as CSSProperties).reduce(
  (collation, [key, value]) => collation + `${kebabCase(key)}:${value};`,
  ''
)
