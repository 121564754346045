import { useState } from 'react'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

export type Props = {
  defaultValue?: string
  placeholder?: string
  onChange?: (text: string) => void
  onSearch: (text: string) => void
}

const BasicInput = ({
  defaultValue,
  placeholder,
  onChange,
  onSearch,
}: Props) => {
  const [text, setText] = useState(defaultValue || '')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
    onChange?.(event.target.value)
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSearch(text)
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <input
        type="text"
        value={text}
        placeholder={placeholder}
        onChange={handleInputChange}
      />
      <button type="submit">
        <Icon
          id="icon-search"
          icon={ICON.search}
          color={theme.colors.brightBlue}
          fontSize="small"
        />
      </button>
    </form>
  )
}

export default BasicInput
