import {
  regexAlphabetOrNumbers,
  regexEmail,
  regexSpaceAround,
  regexTaiwanPhone,
  regexTitle,
} from '@shared/model/constants/validation'

const checkHasSpaceAround = (text: string) => regexSpaceAround.test(text)

/**
 * 檢查輸入字串是否符合「標題」格式：
 * 1. 中日韓的漢字
 * 2. 英數字
 * 3. 長度為 1 ~ 100 字
 * 4. 前後不可有空白
 * @param text 任意字串
 */
export const checkIsTitle = (text: string) =>
  regexTitle.test(text) && !checkHasSpaceAround(text)

export const checkIsEmail = (email: string) => regexEmail.test(email)

export const checkIsTaiwanPhone = (phone: string) =>
  regexTaiwanPhone.test(phone)

export const checkIsAlphabetOrNumber = (text: string) =>
  regexAlphabetOrNumbers.test(text)
