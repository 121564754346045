import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Button, Description, Img, ImgWrapper, Text, Wrapper } from './styles'

type Props = {
  resetError?: () => void
  statusCode?: 404 | 500
}

export const ErrorPage = ({ resetError, statusCode = 500 }: Props) => {
  const { t } = useTranslation(['common'])
  const history = useHistory()

  const handleGoBackToHomepage = () => {
    resetError?.()
    history.push('/')
  }

  return (
    <Wrapper>
      <ImgWrapper>
        <Img src={`/images/error-${statusCode}.svg`} />
      </ImgWrapper>

      {statusCode === 404 && (
        <Fragment>
          <Text>{t('common:api_error.404')}</Text>
          <Description>{t('common:api_error.404_description')}</Description>
        </Fragment>
      )}

      {statusCode === 500 && (
        <Fragment>
          <Text>{t('common:api_error.500')}</Text>
          <Description>{t('common:api_error.500_description')}</Description>
          <Description>
            {t('common:please_try_again_or')}
            <a href="mailto:cdp-support@ikala.tv">{t('common:contact_us')}</a>
          </Description>
        </Fragment>
      )}

      <Button onClick={handleGoBackToHomepage}>
        {t('common:back_to_home_page')}
      </Button>
    </Wrapper>
  )
}

export default ErrorPage
