import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'

import { formatRTKQueryError } from '@entities/apiHandler'
import {
  type ExportDesignFunction,
  useMsgSenderOptions,
} from '@entities/mediumTemplates'
import { useSendTestingEmailMutation } from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks/store'
import useGetMsgChannelForSelectOptionsQuery from '@shared/lib/hooks/useGetMsgChannelForSelectOptionsQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { CONTENT_TEMPLATE_ERROR_CODE } from '@shared/model/constants/contentTemplate'
import { Z_INDEX } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog } from '@shared/ui/dialogs'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import TagsField from '@shared/ui/inputs/TagsField'

import FormRow from './FormRow'
import { FormValues, schema } from './schema'

type Props = {
  className?: string
  isLoading?: boolean
  defaultValues?: {
    msgChannelId?: number
    msgSenderId?: number | null
    title?: string
    preheader?: string
  }
  onClose: () => void
  onExportDesign: ExportDesignFunction
}

export const SendEmailDialog = ({
  className,
  defaultValues,
  isLoading,
  onClose,
  onExportDesign,
}: Props) => {
  const { t } = useTranslation([
    'common',
    'contentTemplate',
    'settings',
    'workflow',
  ])

  const userInfoEmail = useAppSelector(state => state.auth.userInfo.email)

  const { msgChannelOptions, isMsgChannelLoading } =
    useGetMsgChannelForSelectOptionsQuery({
      mediumType: 'email',
    })

  const [sendTestingEmail, { isLoading: isEmailSending }] =
    useSendTestingEmailMutation()

  const dispatch = useAppDispatch()

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
  } = useFormik<FormValues>({
    initialValues: {
      title: defaultValues?.title ?? '',
      msgChannelId: defaultValues?.msgChannelId,
      msgSenderId: defaultValues?.msgSenderId,
      target: 'self',
      tagInput: '',
      emails: [],
    },
    validationSchema: schema,
    onSubmit: async ({
      target,
      emails = [],
      msgChannelId,
      msgSenderId,
      title = '',
      preheader,
    }) => {
      try {
        const { html, replacementData } = await onExportDesign({
          shouldRecoverToOriginalLink: true,
          shouldExportPreviewUrl: false,
          preheaderText: preheader,
        })

        await sendTestingEmail({
          email: target === 'self' ? userInfoEmail : emails.join(),
          msgChannelId: msgChannelId!,
          msgSenderId: msgSenderId!,
          sentBody: html,
          subject: title,
          replacementData,
        }).unwrap()

        dispatch(
          openToast({
            message: t('contentTemplate:send_email_result.succeed'),
          })
        )

        onClose()
      } catch (error) {
        const typedError = formatRTKQueryError<{ errorCode: number }>(error)

        if (
          typedError?.data?.errorCode ===
          CONTENT_TEMPLATE_ERROR_CODE.DUPLICATE_EMAIL_PERSONALIZED_BLOCK
        ) {
          dispatch(
            openToast({
              message: t('contentTemplate:errors.duplicate_personalized_block'),
              status: 'error',
            })
          )
          return
        }

        dispatch(
          openToast({
            message: t('contentTemplate:send_email_result.failed'),
            status: 'error',
          })
        )
      }
    },
  })

  const msgSenderOptions = useMsgSenderOptions({
    msgChannelId: values.msgChannelId,
    msgChannelOptions,
  })

  const thirdPartyAppType = useMemo(
    () =>
      msgChannelOptions.find(
        ({ value }) => value === String(values.msgChannelId)
      )?.providerName,
    [msgChannelOptions, values.msgChannelId]
  )

  return (
    <ConfirmDialog
      className={className}
      confirmText={t('contentTemplate:send')}
      isLoading={isLoading || isEmailSending}
      isOpen
      modalTitle={t('contentTemplate:send_test_email')}
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gap={3.5}>
        <FormRow>
          <RadioGroup
            row
            value={values.target}
            onChange={e => {
              setFieldValue('target', e.target.value as FormValues['target'])
            }}
          >
            <FormControlLabel
              value="self"
              control={<Radio />}
              label={
                <Box sx={{ fontWeight: 500 }}>
                  {t('contentTemplate:send_to_yourself')}
                </Box>
              }
            />
            <FormControlLabel
              value="specific"
              control={<Radio />}
              label={
                <Box sx={{ fontWeight: 500 }}>
                  {t('contentTemplate:send_to_specific_email')}
                </Box>
              }
            />
          </RadioGroup>
        </FormRow>

        <FormRow
          label={t('contentTemplate:send_test_email_form.labels.third_party')}
          required
        >
          <NestedList
            anchorId="msgChannelId"
            isFullWidth
            isDisabled={isMsgChannelLoading}
            isError={touched.msgChannelId && Boolean(errors.msgChannelId)}
            label={t(
              'contentTemplate:send_test_email_form.placeholders.third_party'
            )}
            onChange={({ value }) => {
              setFieldValue('msgChannelId', getIntSafe(value))
            }}
            options={msgChannelOptions}
            popperZIndex={Z_INDEX.dialog}
            value={`${values.msgChannelId}`}
          />
          {thirdPartyAppType && (
            <Box>
              {t('contentTemplate:send_test_email_form.labels.service')}：
              {thirdPartyAppType}
            </Box>
          )}
        </FormRow>

        <FormRow label={t('workflow:node_action_email_from_name')} required>
          <DropdownList
            disabledPreselect
            isError={touched.msgSenderId && Boolean(errors.msgSenderId)}
            isFullWidth
            onValueChanged={({ value }) => setFieldValue('msgSenderId', value)}
            options={msgSenderOptions}
            popperZIndex={Z_INDEX.dialog}
            value={values.msgSenderId}
          />
        </FormRow>

        <FormRow label={t('workflow:node_action_email_subject')} required>
          <PrimaryGreyInput
            fullWidth
            isError={touched.title && Boolean(errors.title)}
            name="title"
            onChange={event => {
              setFieldValue('title', event.target.value)
            }}
            placeholder={t('workflow:node_action_email_subject_placeholder')}
            value={values.title}
          />
        </FormRow>

        {values.target === 'specific' && (
          <FormRow label={t('contentTemplate:send_test_email_hint')} required>
            <Box>
              <TagsField
                helperText={
                  touched.emails
                    ? (errors.emails as string) ?? errors.tagInput
                    : ''
                }
                maxCount={5}
                onChange={emails => {
                  setFieldValue('emails', emails)
                }}
                onInputChange={text => {
                  setFieldTouched('emails', true)
                  setFieldValue('tagInput', text)
                }}
                onValidate={() => !errors.tagInput}
                placeholder={t('contentTemplate:send_test_email_placeholder')}
                values={values.emails}
              />
            </Box>
          </FormRow>
        )}
      </Box>
    </ConfirmDialog>
  )
}

export default SendEmailDialog
