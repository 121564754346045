import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import TableBodyCell from './TableCell/TableBodyCell'
import TableRow from './TableRow'

const waveKeyframe = keyframes`
0% {
  background-position: 0% center;
}
100% {
  background-position: 200% center;
}
`

const Skeleton = styled.div`
  height: 16px;
  border-radius: 4px;

  background-size: 50%;
  background-color: ${({ theme }) => theme.colors.black6};
  background-image: ${({ theme }) => `linear-gradient(
  45deg,
  transparent,
  ${theme.colors.black6},
  transparent
)`};

  animation: ${waveKeyframe} 1.5s linear infinite;
`

type TableRowListSkeletonProps = {
  columnCount: number
  rowCount?: number
}

const TableRowListSkeleton = ({
  columnCount,
  rowCount = 3,
}: TableRowListSkeletonProps) => (
  <>
    {Array(rowCount)
      .fill('')
      .map((r, rIdx) => (
        <TableRow key={rIdx}>
          {Array(columnCount)
            .fill('')
            .map((c, cIdx) => (
              <TableBodyCell key={`${rIdx}_${cIdx}`}>
                <Skeleton />
              </TableBodyCell>
            ))}
        </TableRow>
      ))}
  </>
)

export default TableRowListSkeleton
