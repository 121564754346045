import { Children } from 'react'
import Box from '@mui/material/Box'

type Props = {
  children: React.ReactNode
}

export const ActionBar = ({ children }: Props) => (
  <Box
    sx={{
      py: 0.5,
      px: 1,
      borderRadius: '3px',
      bgcolor: theme => theme.colors.bgIceBlue,
      height: 'max-content',
    }}
  >
    {Children.map(children, child => (
      <Box sx={{ display: 'inline', mx: 0.5 }}>{child}</Box>
    ))}
  </Box>
)

export default ActionBar
