import MuiTooltip from '@mui/material/Tooltip'

import type { Placement } from './types'

type TooltipProps = {
  title: NonNullable<React.ReactNode>
  placement?: Placement
  isDisabled?: boolean
  children: JSX.Element
}

export const Tooltip = ({
  children,
  title,
  placement = 'top',
  isDisabled = false,
}: TooltipProps) => (
  <MuiTooltip
    title={isDisabled ? '' : title}
    arrow
    placement={placement}
    classes={{
      // className 放在 src/index.css
      arrow: 'custom-tooltip-overwrite-arrow',
      tooltip: 'custom-tooltip-overwrite',
    }}
  >
    {children}
  </MuiTooltip>
)

export default Tooltip
