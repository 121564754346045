import type { AxiosError } from 'axios'
import { Epic } from 'redux-observable'
import { concat, EMPTY, from, of } from 'rxjs'
import { catchError, delay, filter, mergeMap } from 'rxjs/operators'

import { getNotificationList } from '@shared/api/axios/notification'
import { UPDATE_HOUR } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'

import {
  fetchNotificationCertainPage,
  fetchNotificationCertainPageSuccess,
  fetchNotificationFailure,
  fetchNotificationSuccess,
  pollingNotification,
} from './slices'

const getNextUpdateDate = () => {
  const now = new Date()
  const todayUpdateHour = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    UPDATE_HOUR,
    10,
    0,
    0
  )

  return todayUpdateHour.getTime() > now.getTime()
    ? todayUpdateHour
    : todayUpdateHour.setDate(todayUpdateHour.getDate() + 1)
}

const pollingNotificationEpic: Epic<RootAction, RootAction, RootState> =
  actions$ =>
    actions$.pipe(
      filter(pollingNotification.match),
      mergeMap(action =>
        from(getNotificationList()).pipe(
          mergeMap((value, index) => {
            return concat(
              of(
                fetchNotificationSuccess({
                  result: [...value.data],
                  total: getIntSafe(value.headers.total),
                })
              ),
              of(pollingNotification()).pipe(delay(getNextUpdateDate()))
            )
          }),
          catchError((error: AxiosError) => {
            return of(fetchNotificationFailure())
          })
        )
      )
    )

const fetchNotificationCertainPageEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = actions$ =>
  actions$.pipe(
    filter(fetchNotificationCertainPage.match),
    mergeMap(action =>
      from(getNotificationList(action.payload)).pipe(
        mergeMap((value, index) => {
          return of(
            fetchNotificationCertainPageSuccess({
              result: [...value.data],
              total: getIntSafe(value.headers.total),
            })
          )
        }),
        catchError((error: AxiosError) => {
          return EMPTY
        })
      )
    )
  )

const epics = [pollingNotificationEpic, fetchNotificationCertainPageEpic]

export default epics
