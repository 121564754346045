import { useTranslation } from 'react-i18next'

import type { TaskEntity } from '@features/infoBox/model/taskSlice'
import type {
  IngestionErrorCode,
  IngestionStatus,
} from '@shared/model/types/ingestion'

export const useStatusText = (
  taskType: TaskEntity['type'],
  status: IngestionStatus,
  errorCode?: IngestionErrorCode
) => {
  const { t } = useTranslation(['dataImport'])

  switch (status) {
    case 'created':
    case 'uploaded':
      return t('dataImport:status.after_created')

    case 'validating':
    case 'validated':
      return t('dataImport:status.after_validating')

    case 'processing':
      return t('dataImport:status.after_processing')

    case 'succeeded':
      return t('dataImport:status.succeeded')

    case 'failed':
      switch (errorCode) {
        case 'formatValidating':
        case 'dataFormatError':
        case 'labelLengthExceed':
        case 'userLabelCountExceed':
          return t('dataImport:error.after_validating')

        case 'cloudFunction':
        case 'userNotExist':
        case 'totalLabelCountExceed':
          return t('dataImport:error.after_processing')

        case 'gcsUrlExpiredBeforePutRequest':
          return t('dataImport:error.link_expired')

        case 'internal':
        default:
          return t('dataImport:error.internal')
      }
  }
}

export default useStatusText
