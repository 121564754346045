// onBlur時，刪除不在text content裡的replacement data

import { useEffect } from 'react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical'

import { ReplacementData } from '@shared/api/rtkQuery'

import type { DecoratorType } from '../type'

type Props = {
  replacementData?: ReplacementData
  onReplacementDataChange: (replacementData: ReplacementData) => void
}

const BlurPlugin = ({
  replacementData,
  onReplacementDataChange,
}: Props): null => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<void>(
        BLUR_COMMAND,
        () => {
          if (replacementData) {
            const cloneReplacementData = { ...replacementData }

            const allDecorators = editor.getDecorators<DecoratorType>()

            // 把目前所有decorator的replacementKey取出來, 不再分是不是utm/metadata tag
            const allReplacementKey = Object.keys(allDecorators).map(
              key => allDecorators[key].props.replacementKey
            )

            /** 找到不在replacementData裡的dataKey */
            const missingReplacementKey = Object.keys(
              cloneReplacementData
            ).filter(key => !allReplacementKey.includes(key))
            missingReplacementKey.forEach(key => {
              delete cloneReplacementData[key]
            })

            onReplacementDataChange(cloneReplacementData)

            return true
          }

          return false
        },
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor, replacementData, onReplacementDataChange])

  return null
}

export default BlurPlugin
