import isNumber from 'lodash/fp/isNumber'

import type {
  UserValueFromAIColumnName,
  UserValuesFromAIResponse,
} from '@shared/api/rtkQuery'
import { formatCurrency } from '@shared/lib/utils/number'

import type { UserValuesTableColumn } from '../../types'
import { FIELD_NAME } from './constants'

const mapUserValuesToTableColumn = (
  data?: UserValuesFromAIResponse
): UserValuesTableColumn[] => {
  if (!data?.values?.length) {
    return [
      {
        fieldName: FIELD_NAME,
        '30': '-',
        '60': '-',
        '90': '-',
        '180': '-',
      },
    ]
  }
  const {
    pltv_30d = '-',
    pltv_60d = '-',
    pltv_90d = '-',
    pltv_180d = '-',
  } = data.values.reduce((collection, current) => {
    collection[current.columnName] = isNumber(current.floatValue)
      ? `${formatCurrency(current.floatValue)}`
      : '-'

    return collection as { [key in UserValueFromAIColumnName]: string }
  }, {} as { [key in UserValueFromAIColumnName]: string })

  return [
    {
      fieldName: FIELD_NAME,
      '30': pltv_30d,
      '60': pltv_60d,
      '90': pltv_90d,
      '180': pltv_180d,
    },
  ]
}

export default mapUserValuesToTableColumn
