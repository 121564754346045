import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import {
  PrivacyLevel,
  useGetEventRuleGroupListQuery,
  useGetFunnelRuleGroupListQuery,
} from '@shared/api/rtkQuery'
import { CarouselItem, CarouselWrapper } from '@shared/ui/carousel'
import { GROUP_UPPER_BOUNDARY } from '@widgets/dashboard/_shared/constants'
import type { AnalyticsType } from '@widgets/dashboard/_shared/type'

import ChartGroupName from './ChartGroupName'
import { CreateChartGroup, DeleteDialog, UpdateChartGroup } from './Dialog'
import { AnalyticsTypeWrapper, ChartGroupWrapper } from './style'

type Props = {
  privacyLevel: PrivacyLevel
  analyticsType: AnalyticsType
  onTypeChange: (type: AnalyticsType) => void
  setActiveGroupId: (id: number) => void
  activeGroupId: number
}

const ChartGroup = ({
  privacyLevel,
  analyticsType,
  onTypeChange,
  setActiveGroupId,
  activeGroupId,
}: Props) => {
  const { t } = useTranslation(['common', 'dashboard'])
  const [isGroupEditDialogOpen, setIsGroupEditDialogOpen] =
    useState<boolean>(false)
  const [isDeletedDialogOpen, setIsDeletedDialogOpen] = useState<boolean>(false)
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0)
  const [selectedGroupName, setSelectedGroupName] = useState<string>('')
  const [selectedAnalyticsType, setSelectedAnalyticsType] =
    useState<AnalyticsType>(analyticsType)

  const { data: eventGroupListData = [], isLoading: isEventRuleGroupLoading } =
    useGetEventRuleGroupListQuery({
      eventRuleType: privacyLevel,
      page: 1,
      perPage: GROUP_UPPER_BOUNDARY,
    })

  const groupWrapperClassname: Record<
    'event' | 'funnel',
    { wrapper: string; item: string }
  > = {
    event: {
      wrapper: 'Dashboard_Group_Slide_Wrapper_event',
      item: 'Dashboard_Group_Slide_Item',
    },
    funnel: {
      wrapper: 'Dashboard_Group_Slide_Wrapper_funnel',
      item: 'Dashboard_Group_Slide_Item',
    },
  }

  const {
    data: funnelGroupListData = [],
    isLoading: isFunnelRuleGroupLoading,
  } = useGetFunnelRuleGroupListQuery({
    funnelRuleType: privacyLevel,
    page: 1,
    perPage: GROUP_UPPER_BOUNDARY,
  })

  useEffect(() => {
    setActiveGroupId(0)
  }, [privacyLevel, setActiveGroupId])

  const dropDownOptions = [
    {
      label: t('common:rename'),
      value: 'rename',
      func: (id: number, name: string, analyticsType: AnalyticsType) => {
        setIsGroupEditDialogOpen(true)
        setSelectedGroupId(id)
        setSelectedGroupName(name)
        setSelectedAnalyticsType(analyticsType)
      },
    },
    {
      label: t('common:delete'),
      value: 'delete',
      func: (id: number, name: string, analyticsType: AnalyticsType) => {
        setIsDeletedDialogOpen(true)
        setSelectedGroupId(id)
        setSelectedGroupName(name)
        setSelectedAnalyticsType(analyticsType)
      },
    },
  ]

  const handleOnDeleteGroup = useCallback(
    (id: number) => {
      setIsDeletedDialogOpen(false)

      if (activeGroupId === id) {
        setActiveGroupId(0)
      }
    },

    [activeGroupId, setActiveGroupId]
  )

  const handleOnClick = useCallback(
    (id: number, type: AnalyticsType) => {
      setActiveGroupId(id)
      onTypeChange(type)
    },
    [setActiveGroupId, onTypeChange]
  )

  const getIsActive = useCallback(
    (id: number, type: AnalyticsType) =>
      id === activeGroupId && type === analyticsType,

    [activeGroupId, analyticsType]
  )

  return (
    <>
      <ChartGroupWrapper>
        <Box mb={2} display="flex" alignItems="center" height={37}>
          {!isEventRuleGroupLoading && (
            <>
              <AnalyticsTypeWrapper>
                {t('common:route.analytics_events')}
              </AnalyticsTypeWrapper>
              ：
              <CarouselWrapper
                // Update Wrapper when user switched privacy level
                key={`${privacyLevel}_${eventGroupListData.length}`}
                carouselClassname={groupWrapperClassname.event}
              >
                <CarouselItem classname={groupWrapperClassname.event.item}>
                  <ChartGroupName
                    id={0}
                    name=""
                    isActive={getIsActive(0, 'event')}
                    onClick={() => handleOnClick(0, 'event')}
                    analyticsType="event"
                  />
                </CarouselItem>
                {eventGroupListData.map(({ id, name }) => (
                  <CarouselItem
                    key={`event_group_${id}`}
                    classname={groupWrapperClassname.event.item}
                  >
                    <ChartGroupName
                      id={id}
                      name={name}
                      isActive={getIsActive(id, 'event')}
                      onClick={() => handleOnClick(id, 'event')}
                      dropDownOptions={dropDownOptions}
                      analyticsType="event"
                    />
                  </CarouselItem>
                ))}
                <CarouselItem classname={groupWrapperClassname.event.item}>
                  <CreateChartGroup
                    analyticsType="event"
                    privacyLevel={privacyLevel}
                  />
                </CarouselItem>
              </CarouselWrapper>
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center" height={37}>
          {!isFunnelRuleGroupLoading && (
            <>
              <AnalyticsTypeWrapper>
                {t('common:route.analytics_funnel')}
              </AnalyticsTypeWrapper>
              ：
              <CarouselWrapper
                key={`${privacyLevel}_${funnelGroupListData.length}`}
                carouselClassname={groupWrapperClassname.funnel}
              >
                <CarouselItem classname={groupWrapperClassname.funnel.item}>
                  <ChartGroupName
                    id={0}
                    name=""
                    isActive={getIsActive(0, 'funnel')}
                    onClick={() => handleOnClick(0, 'funnel')}
                    analyticsType="funnel"
                  />
                </CarouselItem>
                {funnelGroupListData.map(({ id, name }) => (
                  <CarouselItem
                    key={`funnel_group_${id}`}
                    classname={groupWrapperClassname.funnel.item}
                  >
                    <ChartGroupName
                      id={id}
                      name={name}
                      isActive={getIsActive(id, 'funnel')}
                      onClick={() => handleOnClick(id, 'funnel')}
                      dropDownOptions={dropDownOptions}
                      analyticsType="funnel"
                    />
                  </CarouselItem>
                ))}
                <CarouselItem classname={groupWrapperClassname.funnel.item}>
                  <CreateChartGroup
                    analyticsType="funnel"
                    privacyLevel={privacyLevel}
                  />
                </CarouselItem>
              </CarouselWrapper>
            </>
          )}
        </Box>
      </ChartGroupWrapper>
      <UpdateChartGroup
        isOpen={isGroupEditDialogOpen}
        analyticsType={selectedAnalyticsType}
        onClose={() => setIsGroupEditDialogOpen(false)}
        name={selectedGroupName || t('dashboard:widget_default_group')}
        id={selectedGroupId}
      />
      <DeleteDialog
        isOpen={isDeletedDialogOpen}
        analyticsType={selectedAnalyticsType}
        id={selectedGroupId}
        onDelete={handleOnDeleteGroup}
        onClose={() => setIsDeletedDialogOpen(false)}
      />
    </>
  )
}

export default ChartGroup
