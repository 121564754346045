import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useFormik } from 'formik'

import { trackEvent } from '@shared/lib/utils/amplitude'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { SearchInput } from '@shared/ui/searchInput'
import theme from '@theme'

import { SearchQueryEnum } from '../constants'
import { SEARCH_COLUMN_OPTIONS } from './constants'
import type { FormValues } from './schema'
import { schema } from './schema'

type Props = Partial<FormValues>

const SearchBar = ({
  searchColumn = 'app_user_id',
  searchValue = '',
}: Props) => {
  const history = useHistory()
  const { values, touched, errors, setFieldValue, submitForm } =
    useFormik<FormValues>({
      validationSchema: schema,
      initialValues: {
        searchColumn,
        searchValue,
      },
      onSubmit: ({ searchColumn, searchValue }) => {
        trackEvent('AudienceSearched', {
          keyword: searchValue,
          keywordType: searchColumn,
        })

        const params = new URLSearchParams()
        params.append(SearchQueryEnum.filter, searchColumn)
        params.append(SearchQueryEnum.query, searchValue)

        history.push({ search: params.toString() })
      },
    })

  const checkIsError = (field: keyof FormValues) =>
    touched[field] && Boolean(errors[field])

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '192px auto',
        mx: 'auto',
        gap: 1,
      }}
    >
      <DropdownList
        bgColor={theme.colors.white}
        hasShadow
        isFullWidth
        isShowFilterIcon
        isError={checkIsError('searchColumn')}
        isPopupAlignAnchorWidth
        options={SEARCH_COLUMN_OPTIONS()}
        value={values.searchColumn}
        onValueChanged={({ value }) => {
          setFieldValue('searchColumn', value)
        }}
      />
      <SearchInput
        defaultValue={values.searchValue}
        isError={checkIsError('searchValue')}
        hasShadow
        onChange={value => {
          setFieldValue('searchValue', value)
        }}
        onSearch={() => submitForm()}
      />
    </Box>
  )
}

export default SearchBar
