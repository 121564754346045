import type { ProjectPlanType } from '@shared/api/axios'

import type { Plan } from './types'

export { Plan }

export const plan: Record<ProjectPlanType, Plan> = {
  trial: {
    planType: 'trial',
    header: {
      languageSwitch: false,
    },
    audience: {
      dynamicList: {
        maxCount: 5,
      },
      exportCSV: false,
      exportGoogleAds: false,
      exportFacebookAds: false,
      introduction: true,
    },
    tag: {
      local: {
        import: false,
      },
      thirdParty: {
        import: false,
      },
      maxCount: 5,
      introduction: true,
    },
    workflow: {
      maxCount: 5,
      testingEmail: false,
      testingSms: false,
      enableWorkflow: false,
      introduction: true,
    },
    insight: {
      disabled: true,
    },
    contentTemplate: {
      testingSms: false,
      testingEmail: false,
      introduction: true,
    },
    analytics: {
      event: {
        maxCount: 5,
        export: false,
        introduction: true,
      },
      funnel: {
        maxCount: 5,
        export: false,
        introduction: true,
      },
      export: false,
    },
    dashboard: {
      introduction: true,
    },
    dataImport: {
      disabled: true,
    },
    settings: {
      thirdParty: {
        disabled: true,
      },
      application: {
        disabled: true,
      },
    },
    iam: {
      user: {
        introduction: true,
      },
      policy: {
        introduction: true,
      },
    },
  } as Plan,
  standard: {
    planType: 'standard',
    header: {
      languageSwitch: true,
    },
    audience: {
      dynamicList: {
        maxCount: 300,
      },
      exportCSV: true,
      exportGoogleAds: true,
      exportFacebookAds: true,
      introduction: false,
    },
    tag: {
      local: {
        import: true,
      },
      thirdParty: {
        import: true,
      },
      maxCount: 300,
      introduction: false,
    },
    workflow: {
      maxCount: 300,
      testingEmail: true,
      testingSms: true,
      enableWorkflow: true,
      introduction: false,
    },
    insight: {
      disabled: false,
    },
    contentTemplate: {
      testingEmail: true,
      testingSms: true,
      introduction: false,
    },
    analytics: {
      event: {
        maxCount: 100,
        export: true,
        introduction: false,
      },
      funnel: {
        maxCount: 100,
        export: true,
        introduction: false,
      },
      export: true,
    },
    dashboard: {
      introduction: false,
    },
    dataImport: {
      disabled: false,
    },
    settings: {
      thirdParty: {
        disabled: false,
        maxCount: 100,
      },
      application: {
        disabled: false,
      },
    },
    iam: {
      user: {
        introduction: false,
      },
      policy: {
        introduction: false,
      },
    },
  } as Plan,
  lite: {
    planType: 'lite',
    header: {
      languageSwitch: true,
    },
    audience: {
      dynamicList: {
        maxCount: 300,
      },
      exportCSV: true,
      exportGoogleAds: true,
      exportFacebookAds: true,
      introduction: false,
    },
    tag: {
      local: {
        import: true,
      },
      thirdParty: {
        import: true,
      },
      maxCount: 300,
      introduction: false,
    },
    workflow: {
      maxCount: 300,
      testingEmail: true,
      testingSms: true,
      enableWorkflow: true,
      introduction: false,
    },
    insight: {
      disabled: false,
    },
    contentTemplate: {
      testingEmail: true,
      testingSms: true,
      introduction: false,
    },
    analytics: {
      event: {
        maxCount: 100,
        export: true,
        introduction: false,
      },
      funnel: {
        maxCount: 100,
        export: true,
        introduction: false,
      },
      export: true,
    },
    dashboard: {
      introduction: false,
    },
    dataImport: {
      disabled: false,
    },
    settings: {
      thirdParty: {
        disabled: false,
        maxCount: 1,
      },
      application: {
        disabled: false,
      },
    },
    iam: {
      user: {
        introduction: false,
      },
      policy: {
        introduction: false,
      },
    },
  } as Plan,
}

// one hour
export const GOOGLE_AUTH_TIME = 60 * 60 * 1000

// one hour
export const FACEBOOK_AUTH_TIME = 60 * 60 * 1000
