import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import { MENU_HEIGHT, MenuItem, MenuList } from '@shared/ui/menu'
import type { Placement } from '@shared/ui/Popup'
import { Tooltip } from '@shared/ui/tooltips'
import type { ThemeColor } from '@theme'

import ActionButton from './ActionButton'
import Dropdown from './index'
import RoundedSearchBar from './RoundedSearchBar'

export interface Option<T = string | number | boolean> {
  disabled?: boolean
  label: string
  value: T
  tooltip?: string
}

interface Props<T = string | number | boolean> {
  bgColor?: string
  buttonHeight?: number
  color?: ThemeColor
  defaultOption?: Option<T>
  disableBgColor?: string
  disabled?: boolean
  disabledPreselect?: boolean
  hasSearchBar?: boolean
  hasShadow?: boolean
  hoverBgColor?: string
  id?: string
  isError?: boolean
  isFullWidth?: boolean
  isLowOpacityWhenDisabled?: boolean
  isMinWidth?: boolean
  isPopupAlignAnchorWidth?: boolean
  marginRightRatio?: number
  maxWidth?: number
  options: Option<T>[]
  placement?: Placement
  popperDisablePortal?: boolean
  popupMaxHeight?: number
  popupMaxWidth?: number
  popperZIndex?: number
  uniqueId?: number | string
  value?: T
  onValueChanged: (option: Option<T>) => void
  placeholder?: string
  isShowFilterIcon?: boolean
}

const DropdownList = <T,>({
  bgColor,
  buttonHeight,
  color,
  defaultOption,
  disableBgColor,
  disabled,
  disabledPreselect,
  hasSearchBar,
  hasShadow = false,
  hoverBgColor,
  id,
  isError,
  isFullWidth,
  isLowOpacityWhenDisabled,
  isMinWidth,
  isPopupAlignAnchorWidth,
  marginRightRatio,
  maxWidth,
  options,
  placement = 'bottom-start',
  popperDisablePortal,
  popupMaxHeight: defaultPopupMaxHeight,
  popupMaxWidth,
  popperZIndex = Z_INDEX.popup,
  value,
  isShowFilterIcon,
  onValueChanged,
  placeholder,
}: Props<T>) => {
  const { t } = useTranslation(['common'])
  const [isOpen, setIsOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const anchorElemRef = useRef<HTMLButtonElement>(null)

  const firstNotDisabledOption = options.find(option => !option.disabled)
  const [currentValue, setCurrentValue] = useState<Option<T | ''>>(() => {
    if (disabledPreselect) {
      return { label: '', value: '' }
    }
    return defaultOption || firstNotDisabledOption || { label: '', value: '' }
  })

  const isControlled = useMemo(() => value !== undefined, [value])
  const controlledValue = options.find(option => option.value === value)

  const handleValueChanged = useCallback(
    (option: Option<T>) => {
      if (isControlled) {
        onValueChanged(option)
        return
      }

      setCurrentValue(option)
      onValueChanged(option)
    },
    [isControlled, onValueChanged]
  )

  // set initial value back to parent
  useEffect(() => {
    if (disabledPreselect) {
      return
    }

    if (
      // 僅在未給定 value 時 (uncontrolled 用法) 才觸發預選值的邏輯
      value === undefined &&
      (!defaultOption || defaultOption !== currentValue)
    ) {
      handleValueChanged(currentValue as Option<T>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 關閉 dialog 時，清空 search text
  useEffect(() => {
    if (isOpen || !hasSearchBar) {
      return
    }

    setSearchText('')
  }, [isOpen, hasSearchBar])

  // 有傳 popupMaxHeight 進來就用傳進來的
  // 如果沒傳，用 options.length 決定，小於等於五，不給 max-height，大於五就給 max-height
  const popupMaxHeight =
    defaultPopupMaxHeight || options.length > 5
      ? MENU_HEIGHT.fourAndAHalf
      : 'none'

  return (
    <Fragment>
      <Dropdown
        anchorId={id}
        anchorElem={
          <ActionButton
            anchorId={id}
            ref={anchorElemRef}
            isDisabled={disabled}
            isLowOpacityWhenDisabled={isLowOpacityWhenDisabled}
            label={(() => {
              if (!isControlled) {
                return (
                  currentValue.label ||
                  placeholder ||
                  t('common:dropdownlist_default')
                )
              }

              return controlledValue?.label || t('common:dropdownlist_default')
            })()}
            maxWidth={maxWidth}
            isMinWidth={isMinWidth}
            isFullWidth={isFullWidth}
            marginRightRatio={marginRightRatio}
            height={buttonHeight}
            bgColor={bgColor}
            disableBgColor={disableBgColor}
            color={color}
            hoverBgColor={hoverBgColor}
            isError={isError}
            isShowFilterIcon={isShowFilterIcon}
            hasShadow={hasShadow}
          />
        }
        isDisabled={disabled}
        isFullWidth={isFullWidth}
        isOpen={isOpen}
        placement={placement}
        popperZIndex={popperZIndex}
        popperDisablePortal={popperDisablePortal}
        setIsOpen={setIsOpen}
      >
        {hasSearchBar && (
          <>
            <RoundedSearchBar
              value={searchText}
              onChange={text => {
                setSearchText(text)
              }}
            />
            <Divider />
          </>
        )}
        <MenuList
          maxHeight={popupMaxHeight}
          maxWidth={popupMaxWidth}
          width={
            isPopupAlignAnchorWidth
              ? anchorElemRef.current?.getBoundingClientRect()?.width
              : undefined
          }
        >
          {options
            .filter(option => {
              if (!searchText) {
                return true
              }
              return option.label
                .toLowerCase()
                .includes(searchText.toLowerCase())
            })
            .map((option, idx) => (
              <MenuItem
                key={`${option.value}_${idx}`}
                disabled={option.disabled}
                onClick={() => {
                  handleValueChanged(option)
                  setIsOpen(false)
                }}
              >
                <Box
                  sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {option.label}
                </Box>
                {option.tooltip && (
                  <Box ml={0.5}>
                    <Tooltip title={option.tooltip}>
                      <i className={ICON.infoCircle} />
                    </Tooltip>
                  </Box>
                )}
              </MenuItem>
            ))}
        </MenuList>
      </Dropdown>
    </Fragment>
  )
}

export default DropdownList
