import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { ParseKeys } from 'i18next'

import { useIamServiceEntityQuery } from '@shared/api/rtkQuery'
import theme from '@theme'

import { ListItem } from './styled'

type Props = {
  serviceName: string
  actionGroupEntity: {
    [groupName: string]: string[]
  }
}

// 1. 如果 service 內所有 action 都被選擇，會顯示 'Full access'
// 2. 否則顯示 groupList ex. list, write, read
//   2.1. 如果 group 內所有 action 都被選擇，會顯示完整的 groupName ex. list
//   2.2. 否則加上 Limited 前綴 ex. Limited list
const ServiceListItem = ({ serviceName, actionGroupEntity }: Props) => {
  const { t } = useTranslation(['iam'])

  const { data: iamServiceEntity = { ids: [], entities: {} } } =
    useIamServiceEntityQuery()

  let selectedActionCount = 0
  let totalActionCount = 0

  const actionGroupList =
    iamServiceEntity.entities[serviceName]?.actionGroups.reduce(
      (groupList, group) => {
        const actionCountByGroupCount = actionGroupEntity[group.name]?.length

        if (actionCountByGroupCount > 0) {
          groupList.push(
            (actionCountByGroupCount === group.actions.length
              ? t(`iam:user.all_actions.${group.name}` as ParseKeys<['iam']>)
              : t(
                  `iam:user.all_actions.${`limited_${group.name}`}` as ParseKeys<
                    ['iam']
                  >
                )) as string
          )
          selectedActionCount += actionCountByGroupCount
        }

        totalActionCount += group.actions.length

        return groupList
      },
      [] as string[]
    ) ?? []

  return (
    <ListItem color={theme.colors.textPrimaryBlue}>
      <Box flex={2}>
        {t(`iam:policy.${serviceName}.name` as ParseKeys<['iam']>)}
      </Box>

      <Box flex={3}>
        {totalActionCount === selectedActionCount
          ? t('iam:user.all_actions.full_access')
          : actionGroupList.join(', ')}
      </Box>
    </ListItem>
  )
}

export default ServiceListItem
