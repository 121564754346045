import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { Z_INDEX } from '@shared/model/constants/styles'

type Props = {
  isBlur?: boolean
  isLoading: boolean
  zIndex?: number
  children: React.ReactNode
}

const LoadingContainer = ({
  isBlur = true,
  isLoading,
  zIndex,
  children,
}: Props) => (
  <Box sx={{ position: 'relative' }}>
    {isLoading && (
      <Box
        sx={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          zIndex: `${zIndex ?? Z_INDEX.loader}`,
        }}
      >
        <CircularProgress />
      </Box>
    )}

    <Box
      sx={{
        pointerEvents: isLoading ? 'none' : 'initial',
        filter: isLoading
          ? isBlur
            ? 'blur(2px)'
            : // 亮度 0 與反向會讓背景變成全白
              'brightness(0) invert(1)'
          : 'none',
        transition: isBlur ? 'filter 0.5s' : 'none',
      }}
    >
      {children}
    </Box>
  </Box>
)

export default LoadingContainer
