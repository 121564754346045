import { useEffect, useMemo, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

import type { ChartData } from '@shared/ui/charts'

import type { DashboardIndicator } from './_shared/IndicatorList'
import type { Option } from '../Dropdown/DropdownList'
import DropdownList from '../Dropdown/DropdownList'
import {
  ChartContainer,
  createInitialEventRulePieChartData,
  EventRulePieChartData,
  getEventRulePieChartGroupLabelName,
  getHeight,
  SizeMode,
} from './_shared'

type EventRulePieChartProps = {
  data: ChartData
  size?: SizeMode
  colors: string[]
  containerSx?: SxProps<Theme>
  children: (props: { indicatorList: DashboardIndicator[] }) => React.ReactNode
}

export const EventRulePieChart = ({
  data,
  size = 'large',
  colors,
  containerSx,
  children,
}: EventRulePieChartProps) => {
  const { t } = useTranslation(['dateAndChart', 'common'])

  const [selectedGroup, setSelectedGroup] = useState('')

  const groupOptions = useMemo(() => {
    if (!data) {
      return []
    }

    const isGroupExist: Record<string, boolean> = {}

    return data.series.reduce<Option[]>((acc, curr) => {
      const groupLabelName = getEventRulePieChartGroupLabelName(
        curr.groupName,
        curr.label
      )

      if (!curr.groupName || isGroupExist[groupLabelName]) {
        return acc
      }

      isGroupExist[groupLabelName] = true
      acc.push({ label: groupLabelName, value: groupLabelName })

      return acc
    }, [])
  }, [data])

  const stringifyGroupOptionValues = groupOptions
    .map(({ value }) => value)
    .join()

  useEffect(() => {
    const [firstValue] = stringifyGroupOptionValues.split(',')
    setSelectedGroup(firstValue)

    // 確保 options 的內容有改變時才重新 setSelectedGroup
  }, [stringifyGroupOptionValues])

  const { labels, series } = useMemo(() => {
    if (!data) {
      return createInitialEventRulePieChartData()
    }

    return data.series.reduce<EventRulePieChartData>((acc, curr) => {
      // 資料有 groupName 代表有維度分析
      if (curr.groupName) {
        // 有維度分析會出現下拉選單，label 只顯示事件名稱
        acc.labels.push(curr.name)

        const groupLabelName = getEventRulePieChartGroupLabelName(
          curr.groupName,
          curr.label
        )

        // 直接篩選 data.series 會影響圖表顏色順序，故只把非選定 group 的資料設為 0
        if (selectedGroup === groupLabelName) {
          acc.series.push(curr.data.reduce((acc, curr) => acc + curr, 0))
        } else {
          acc.series.push(0)
        }

        return acc
      }

      const labelName = getEventRulePieChartGroupLabelName(
        curr.name,
        curr.label
      )

      acc.labels.push(labelName)
      acc.series.push(curr.data.reduce((acc, curr) => acc + curr, 0))

      return acc
    }, createInitialEventRulePieChartData())
  }, [data, selectedGroup])

  const [total, setTotal] = useState(0)

  useEffect(() => {
    setTotal(series.reduce((acc, curr) => acc + curr, 0))
  }, [series])

  const options: ApexCharts.ApexOptions = {
    labels,
    chart: {
      animations: { enabled: false },
      type: 'donut',
      parentHeightOffset: 0,
      sparkline: { enabled: false },
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    colors,
    dataLabels: {
      distributed: false,
      dropShadow: { enabled: false },
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          labels: {
            show: false,
          },
        },
        expandOnClick: false,
      },
    },
    tooltip: {
      custom: ({
        series,
        seriesIndex,
        w,
      }: {
        series: number[]
        seriesIndex: number
        w: { config: { labels: string[] }; globals: { seriesTotals: number[] } }
      }) => {
        const value = series[seriesIndex]
        const label = w.config.labels[seriesIndex]

        const seriesTotal =
          w.globals.seriesTotals.reduce((acc, curr) => acc + curr, 0) || 1

        const percent = ((value / seriesTotal) * 100).toFixed(1)

        const result: string[] = []
        result.push(`<div class="tooltip-row">`)
        result.push(
          `<div class="tooltip-circle" style="background-color: ${colors[seriesIndex]};"></div>`
        )
        result.push(
          `<div class="tooltip-name tooltip-name-center">${label}</div>`
        )
        result.push(
          `<div class="tooltip-value tooltip-value-right ml-2">${Number(
            value
          ).toLocaleString()} (${percent}%)</div>`
        )
        result.push(`</div>`)

        return `<div class="tooltip-wrapper">${result.join('')}</div>`
      },
    },
    noData: {
      // 讓空資料文字顯示在視覺中心
      offsetY: -32,
      text: t('common:empty_data'),
    },
  }

  const indicatorList: DashboardIndicator[] = [
    {
      indicator: t('dateAndChart:statistics.sum'),
      label: '',
      value: total.toLocaleString(),
    },
  ]

  const height = getHeight(size)

  return (
    <ChartContainer sx={{ ...containerSx }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {children({ indicatorList })}

        {groupOptions.length > 0 && (
          <DropdownList
            options={groupOptions}
            value={selectedGroup}
            disabled={groupOptions.length === 1}
            onValueChanged={({ value }) => setSelectedGroup(value as string)}
          />
        )}
      </Box>

      <Box sx={{ height }}>
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          height={height}
        />
      </Box>
    </ChartContainer>
  )
}

export default EventRulePieChart
