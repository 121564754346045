import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { AudienceFilterNode } from '@shared/api/rtkQuery'
import FeatureBadge from '@shared/ui/FeatureBadge'

import {
  AudienceFilterContainer,
  AudienceFilterItemContainer,
  AudienceFilterItemHeader,
  FilterCombinator,
} from '../_shared'
import CampaignEventFilter from '../CampaignEventFilter'
import EventFilter from '../EventFilter'
import UserProfileAndTagFilter from '../UserProfileAndTagFilter'
import type { AudienceFilterAction } from './audienceFilterReducer.type'
import { createFilterEntity } from './utils'

export type { AudienceFilterAction }

type Props = {
  state: AudienceFilterNode
  dispatch?: (action: AudienceFilterAction) => void
  isDisabled?: boolean
}

const AudienceFilter = ({ state, dispatch, isDisabled = !dispatch }: Props) => {
  const { t } = useTranslation(['audience'])

  const filterEntity = useMemo(
    () => createFilterEntity(state.filters),
    [state.filters]
  )

  const onDispatch = (action: AudienceFilterAction) => {
    if (!dispatch) {
      return
    }

    dispatch(action)
  }

  return (
    <>
      <FilterCombinator
        combination={state.combination}
        isDisabled={isDisabled}
        onChange={() => {
          onDispatch({ type: 'toggleCombination' })
        }}
      />

      <AudienceFilterContainer>
        <AudienceFilterItemHeader>
          {t('audience:v3.filter_events')}
        </AudienceFilterItemHeader>

        <AudienceFilterItemContainer>
          <EventFilter
            state={filterEntity['event']}
            isDisabled={isDisabled}
            dispatch={subAction =>
              onDispatch({
                type: 'event',
                payload: subAction,
              })
            }
          />
        </AudienceFilterItemContainer>

        <AudienceFilterItemHeader>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {t('audience:campaigns.filter.name')}
            <FeatureBadge
              sx={{ display: 'flex', height: 24, alignItems: 'center' }}
            >
              NEW
            </FeatureBadge>
          </Box>
        </AudienceFilterItemHeader>

        <AudienceFilterItemContainer>
          <CampaignEventFilter
            state={filterEntity['campaign_event']}
            isDisabled={isDisabled}
            dispatch={subAction =>
              onDispatch({
                type: 'campaignEvent',
                payload: subAction,
              })
            }
          />
        </AudienceFilterItemContainer>

        <AudienceFilterItemHeader>
          {t('audience:v3.filter_user_profile_tags')}
        </AudienceFilterItemHeader>

        <AudienceFilterItemContainer>
          <UserProfileAndTagFilter
            state={filterEntity['user_profile_tag']}
            isDisabled={isDisabled}
            dispatch={subAction => {
              onDispatch({
                type: 'userProfileTag',
                payload: subAction,
              })
            }}
          />
        </AudienceFilterItemContainer>
      </AudienceFilterContainer>
    </>
  )
}

export default memo(AudienceFilter)
