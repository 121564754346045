import { Edge, Node } from 'reactflow'
import camelcaseKeys from 'camelcase-keys'

import { getIntSafe } from '@shared/lib/utils/number'

import {
  FLOW_TYPE_CONNECTOR,
  TargetNodeValue,
  WorkflowNodeFromApi,
} from '../types'
import createNewEdge from './createNewEdge'

export const convertWorkflowNodeFromApi = (
  sourceNodes: WorkflowNodeFromApi[]
): {
  nodes: Node[]
  edges: Edge[]
} => {
  const nodes: Node[] = []
  const edges: Edge[] = []

  sourceNodes.forEach(({ flow, display, users_in_node, node_id }) => {
    let editorJson = flow.node_input?.editor_json

    try {
      // 為了避免破壞 email editor 結構將其存入 editor_json_string
      // 並且向後相容也會讀取 editor_json 屬性
      editorJson = flow.node_input?.editor_json_string
        ? JSON.parse(flow.node_input.editor_json_string as string)
        : editorJson
    } catch {
      console.log('parse editor_json_string error')
    }

    nodes.push({
      id: `${flow.node_id}`,
      type: flow.sub_type
        ? `${flow.type}${FLOW_TYPE_CONNECTOR}${flow.sub_type}`
        : `${flow.type}`,
      position: {
        x: getIntSafe(display?.x_pos),
        y: getIntSafe(display?.y_pos),
      },
      data: camelcaseKeys(
        {
          ...flow.node_input,
          users_in_node,
          trigger_id: flow.type === 'exit' ? undefined : node_id,
          editor_json: editorJson,
        },
        { deep: true, stopPaths: ['replacement_data', 'editor_json'] }
      ),
    })

    if (flow.to_node) {
      const targetNodeValues = Object.keys(flow.to_node) as TargetNodeValue[]
      targetNodeValues.forEach(value => {
        const toNode = sourceNodes.find(
          node => node.flow.node_id === flow.to_node?.[value]
        )

        edges.push(
          createNewEdge({
            source: flow.node_id,
            sourceHandle: value === 'default' ? null : value,
            target: `${flow.to_node?.[value]}`,
            targetHandle: toNode
              ? toNode.flow?.sub_type
                ? `${toNode.flow.type}-${toNode.flow.sub_type}`
                : toNode.flow.type
              : null,
          })
        )
      })
    }
  })

  return { edges, nodes }
}

export default convertWorkflowNodeFromApi
