import type { Node } from 'reactflow'

import type { NodeType } from '@shared/api/rtkQuery'
import type { SubscriptionNodeData } from '@widgets/workflow/_shared/nodes'

export const setSubscriptionNodeId = (
  nodes: Node[]
): Node<unknown, NodeType>[] => {
  if (!nodes) {
    return []
  }

  const subscriptionNodes = (
    nodes as Node<SubscriptionNodeData, NodeType>[]
  ).filter(node => node.type === 'action-publish_subscription')

  const serialNumList = subscriptionNodes.reduce(
    (acc, { data: { subscriptionId } }) => {
      if (!acc[subscriptionId]) {
        acc[subscriptionId] = 1

        return acc
      }

      acc[subscriptionId] += 1

      return acc
    },
    {} as Record<string, number>
  )

  const updatedNodes = nodes.map(node => {
    if (node.type !== 'action-publish_subscription') {
      return node
    }

    const data = node.data as SubscriptionNodeData

    const _node = {
      ...node,
      data: {
        ...data,
        subscriptionNodeId: serialNumList[data.subscriptionId],
      },
    }

    serialNumList[data.subscriptionId] -= 1

    return _node
  })

  return updatedNodes as Node<unknown, NodeType>[]
}
