import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import type {
  ThirdPartyAppIntegration,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { checkIsTitle } from '@shared/lib/utils/validation'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import getProviderGroup from '@widgets/settings/thirdParty/lib/getProviderGroup'
import { thirdPartyAuthTypeMap } from '@widgets/settings/thirdParty/model/constant'

export type FormValues = Pick<
  ThirdPartyAppIntegration,
  | 'name'
  | 'maskedApiKey'
  | 'account'
  | 'maskedPassword'
  | 'lineOaName'
  | 'channelId'
> & {
  type: ThirdPartyAppProvider

  // 此頁特殊的表單設計要根據情境送出不同欄位
  // 由於想共用驗證規則，設定 submitType 代表要使用哪種方式送出表單
  // 'form' => 整個表單送出
  // 'name' => 更新名稱
  // 'secret' => 更新 apiKey 或是 account & password
  submitType?: 'form' | 'name' | 'secret'
}

export const useThirdPartySchema = () => {
  const { t } = useTranslation(['common', 'settings'])
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const thirdPartySchema = useMemo(
    () =>
      yup.object<FormValues>({
        name: yup
          .string()
          .required(t('common:errors.required'))
          .test(
            'isTitle',
            t('settings:hint_name', {
              words: MAX_LIST_NAME_LENGTH,
            }),
            checkIsTitle
          ),
        lineOaName: yup
          .string()
          .test(
            'lineOaName',
            t('settings:errors.line_oa_required'),
            (value, { parent }) =>
              getProviderGroup(parent.type) === 'line' ? !!value : true
          ),
        maskedApiKey: yup
          .string()
          .test(
            'maskedApiKey',
            t('settings:hint_api_key'),
            (value, { parent }: { parent: FormValues }) =>
              thirdPartyAuthTypeMap[parent.type] === 'apiKey' ? !!value : true
          ),
        account: yup.string().test({
          name: 'account',
          test: (value, ctx) => {
            const { type } = ctx.parent as FormValues

            if (thirdPartyAuthTypeMap[type] === 'accountAndPassword') {
              if (!value) {
                return ctx.createError({
                  message: t('settings:hint_account', {
                    merchant: getThirdPartyProviderName(type),
                  }),
                  path: ctx.path,
                })
              }
            }

            return true
          },
        }),
        maskedPassword: yup.string().test({
          name: 'maskedPassword',
          test: (value, ctx) => {
            const { type } = ctx.parent as FormValues

            if (thirdPartyAuthTypeMap[type] === 'accountAndPassword') {
              if (!value) {
                return ctx.createError({
                  message: t('settings:hint_password', {
                    merchant: getThirdPartyProviderName(type),
                  }),
                  path: ctx.path,
                })
              }
            }

            return true
          },
        }),
        channelId: yup.string().when('type', {
          is: 'omnichat',
          then: () => yup.string().required(t('settings:hint_channel_id')),
        }),
      }),
    [getThirdPartyProviderName, t]
  )

  return { thirdPartySchema }
}

export default useThirdPartySchema
