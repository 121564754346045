export const MUST_SUBSCRIPTION_COLUMNS = ['c_app_user_id']

// 目前處理 CREATE_LIMIT, COLUMN_NUMBER_EXCEEDS_LIMIT, DUPLICATE_NAME
export const SUBSCRIPTION_ERR_CODE = {
  CREATE_LIMIT: 3,
  COLUMN_NUMBER_EXCEEDS_LIMIT: 40000,
  COLUMN_NOT_IN_CDP_USER_PROFILES: 40001,
  ANOTHER_INVALID_INPUT: 40002,
  DUPLICATE_NAME: 11001,
}

export const SUBSCRIPTION_MAX_COLUMN = 10
