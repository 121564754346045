import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position, useUpdateNodeInternals } from 'reactflow'
import Box from '@mui/material/Box'

import { camelToSnakeCase } from '@shared/lib/utils/string'
import type { BasicNodeOperation } from '@widgets/workflow/_shared/hooks/useWorkflow'

import {
  BasicNode,
  createGetHandleStyles,
  HandleSourceText,
  TooltipIcon,
} from '../_shared'
import LogicBestChannelNodeDialog from './LogicBestChannelNodeDialog'
import {
  LogicBestChannelNodeData,
  logicBestChannelNodeSchema,
  LogicBestChannelType,
} from './logicBestChannelNodeSchema'

type Props = BasicNodeOperation<LogicBestChannelNodeData>

const LogicBestChannelNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete: handleDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const updateNodeInternals = useUpdateNodeInternals()

    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const stringifyValues = JSON.stringify(initialValues)

    const { t } = useTranslation(['workflow', 'common'])

    const channelNum = useMemo(
      () =>
        Object.keys(initialValues).filter(channel =>
          Boolean(initialValues[channel as LogicBestChannelType])
        ).length,
      [initialValues]
    )

    const getHandleStyles = useMemo(
      () => createGetHandleStyles(channelNum),
      [channelNum]
    )
    const isSettled = useMemo(
      () => logicBestChannelNodeSchema.isValidSync(JSON.parse(stringifyValues)),
      [stringifyValues]
    )

    const handleConfirm = (values: LogicBestChannelNodeData) => {
      updateNodeInternals(id)
      onDeleteEdges('source')

      onChange(values)

      setIsOpenDialog(false)
    }

    const handleClose = () => {
      setIsOpenDialog(false)
    }

    return (
      <>
        <BasicNode
          nodeType="logic-best_channel"
          onDelete={handleDelete}
          onEdit={() => setIsOpenDialog(true)}
          isSettled={isSettled}
          triggerId={triggerId}
          usersInNode={usersInNode}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box component="span">
              {t('workflow:node_logic_best_channel.node_hint', {
                num: channelNum,
              })}
            </Box>
            <TooltipIcon title={id} />
          </Box>
        </BasicNode>

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />

        {isSettled &&
          Object.keys(initialValues)
            .filter(channel =>
              Boolean(initialValues[channel as LogicBestChannelType])
            )
            .map((channel, index) => (
              <Handle
                key={channel}
                type="source"
                position={Position.Bottom}
                // 因為 logic-group 使用 group_a... 的格式，不更改 convertWorkflowNodeFromApi 的轉換邏輯下，這邊也要改成 snake case
                id={camelToSnakeCase(channel)}
                style={getHandleStyles(index)}
                isConnectable={isConnectable}
              >
                <HandleSourceText>
                  {t(
                    `workflow:node_logic_best_channel.edit_dialog.channel_type.${channel}`
                  )}
                </HandleSourceText>
              </Handle>
            ))}

        {isOpenDialog && (
          <LogicBestChannelNodeDialog
            isOpen
            initialValues={initialValues}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        )}
      </>
    )
  }
)

export default LogicBestChannelNode
