import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import LinearProgress from '@mui/material/LinearProgress'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useFormik } from 'formik'

import {
  PrivacyLevel,
  useGetFunnelRuleGroupListQuery,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { Dialog } from '@shared/ui/dialogs'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import { UniIcon as Icon } from '@shared/ui/icons'
import {
  Container,
  DialogActions,
  DROPDOWN_MENU_WIDTH,
  HelperText,
  Input,
  Label,
  ProgressContainer,
  Title,
} from '@widgets/analytics/_shared/styles/formConfirmDialogStyle'
import { GROUP_UPPER_BOUNDARY } from '@widgets/dashboard/_shared/constants'

import type { FunnelChartSettings } from '../../model/types'

type Props = {
  initialValues: FunnelChartSettings
  isOpen: boolean
  isDisableChartType?: boolean
  isDisableIndicator?: boolean
  isDisablePrivacyLevel?: boolean
  isSubmitting: boolean
  serverError?: string
  onConfirm: (settings: FunnelChartSettings) => Promise<void>
  onCancel: () => void
}

const FunnelFormConfirmDialog = ({
  initialValues,
  isOpen,
  isDisableChartType,
  isDisablePrivacyLevel,
  isSubmitting,
  serverError,
  onCancel: handleCancel,
  onConfirm,
}: Props) => {
  const { t } = useTranslation([
    'common',
    'dashboard',
    'dateAndChart',
    'analytics',
  ])

  const { values, errors, touched, handleChange, setFieldValue, submitForm } =
    useFormik<FunnelChartSettings>({
      enableReinitialize: true,
      initialValues,
      validate(values) {
        const errors: Partial<Record<keyof FunnelChartSettings, string>> = {}

        if (values.title.length === 0) {
          errors.title = t('analytics:event.is_require')
        }

        if (values.title.length > MAX_LIST_NAME_LENGTH) {
          errors.title = t('common:errors.max_count', {
            count: MAX_LIST_NAME_LENGTH,
          })
        }

        return errors
      },
      onSubmit: onConfirm,
    })

  const { groupOptions, isRuleGroupListFetching } =
    useGetFunnelRuleGroupListQuery(
      {
        funnelRuleType: values.privacyLevel,
        page: 1,
        perPage: GROUP_UPPER_BOUNDARY,
      },
      {
        selectFromResult: ({ data: ruleGroupList = [], isFetching }) => ({
          groupOptions: [
            { value: 0, label: t('dashboard:widget_default_group') },
          ].concat(
            ruleGroupList.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          ),
          isRuleGroupListFetching: isFetching,
        }),
      }
    )

  const handleSelectedGroupIdChange = (option: Option) => {
    setFieldValue('groupId', Number(option.value))
  }

  const handlePrivacyLevelChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue('privacyLevel', event.target.value as PrivacyLevel)

    // 類型切換需要重新選擇群組
    setFieldValue('groupId', 0)
  }

  const firstError = useMemo(() => {
    if (serverError) {
      return serverError
    }

    for (let key in errors) {
      const typedKey = key as keyof FunnelChartSettings
      if (errors[typedKey]) {
        return errors[typedKey] as string
      }
    }

    return ''
  }, [errors, serverError])

  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth>
      <Container>
        <Title>
          {t('dashboard:save_widget_to_dashboard')}
          <IconButton onClick={handleCancel}>
            <Icon icon={ICON.multiply} />
          </IconButton>
        </Title>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dashboard:type')}</Label>
          <RadioGroup
            row={true}
            name="privacyLevel"
            value={values.privacyLevel}
            onChange={handlePrivacyLevelChange}
          >
            <FormControlLabel
              value="public"
              control={<Radio color="primary" />}
              label={t('dashboard:public')}
              disabled={isDisablePrivacyLevel}
            />
            <FormControlLabel
              value="private"
              control={<Radio color="primary" />}
              label={t('dashboard:private')}
              disabled={isDisablePrivacyLevel}
            />
          </RadioGroup>
        </Box>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dashboard:widget_group')}</Label>
          <DropdownList
            popperDisablePortal
            disabled={isRuleGroupListFetching}
            options={groupOptions}
            value={values.groupId}
            onValueChanged={handleSelectedGroupIdChange}
            popupMaxWidth={DROPDOWN_MENU_WIDTH}
            maxWidth={DROPDOWN_MENU_WIDTH}
          />
        </Box>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Label isError={touched.title && Boolean(errors.title)}>
            {t('dashboard:widget_name')}
            <span>&nbsp;*</span>
          </Label>
          <Input
            color="primary"
            name="title"
            value={values.title}
            placeholder={t('dashboard:widget_name_placeholder')}
            fullWidth={true}
            error={touched.title && Boolean(errors.title)}
            onChange={handleChange}
          />
        </Box>
        <Box display="flex" alignItems="center" mb={1.5}>
          <Label>{t('dateAndChart:chart_type')}</Label>
          <RadioGroup
            row={true}
            name="chartType"
            value={values.chartType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="funnel_chart"
              control={<Radio color="primary" />}
              label={t('dateAndChart:charts.funnel')}
              disabled={isDisableChartType}
            />
            <FormControlLabel
              value="trend_chart"
              control={<Radio color="primary" />}
              label={t('dateAndChart:charts.trend')}
              disabled={isDisableChartType}
            />
          </RadioGroup>
        </Box>
        <HelperText>
          {touched.title && errors.title && <span>{firstError}</span>}
        </HelperText>
      </Container>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>
          {t('common:cancel')}
        </Button>
        <Button color="primary" disabled={isSubmitting} onClick={submitForm}>
          {t('common:confirm')}
        </Button>
      </DialogActions>
      <ProgressContainer>
        {isSubmitting && <LinearProgress />}
      </ProgressContainer>
    </Dialog>
  )
}

export default FunnelFormConfirmDialog
