import type {
  CreateEventIngestionParams,
  EventIngestion,
} from '@shared/model/types/ingestion'

import {
  api,
  PaginationParams,
  PaginationResponse,
  paginationResponseHandler,
  transformResponseCamelCaseKeys,
} from '../api'

const event = api.injectEndpoints({
  endpoints: builder => ({
    getEventIngestionList: builder.query<
      PaginationResponse<EventIngestion>,
      PaginationParams
    >({
      query: params => ({
        method: 'GET',
        url: '/event/ingestions',
        params,
        responseHandler: paginationResponseHandler,
      }),
      transformResponse: (data: PaginationResponse<EventIngestion>) =>
        transformResponseCamelCaseKeys(data),
      providesTags: result =>
        result
          ? [
              ...result.items.map(({ id }) => ({
                type: 'EventIngestion' as const,
                id,
              })),
              { type: 'EventIngestion', id: 'LIST' },
            ]
          : [{ type: 'EventIngestion', id: 'LIST' }],
    }),
    createEventIngestion: builder.mutation<void, CreateEventIngestionParams>({
      query: params => ({
        method: 'POST',
        url: `/event/ingestions`,
        body: params,
      }),
      invalidatesTags: ['EventIngestion'],
    }),
    deleteEventIngestion: builder.mutation<void, number>({
      query: id => ({
        method: 'DELETE',
        url: `/event/ingestions/${id}`,
      }),
      invalidatesTags: ['EventIngestion'],
    }),
    getEventIngestion: builder.query<EventIngestion, number>({
      query: id => ({
        method: 'GET',
        url: `/event/ingestions/${id}`,
      }),
      transformResponse: (data: EventIngestion) =>
        transformResponseCamelCaseKeys(data),
      providesTags: ['EventIngestion'],
    }),
    triggerEventIngestion: builder.mutation<void, number>({
      query: id => ({
        method: 'DELETE',
        url: `/event/ingestions/${id}`,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetEventIngestionListQuery,
  useDeleteEventIngestionMutation,
} = event
