import { useCallback, useMemo } from 'react'

import { MetadataProperty, TagRuleList } from '@shared/api/rtkQuery'

import { TAG_FIELD } from './metadata'
import useMetadataQuery from './useMetadataQuery'

type GetMetadataProperty = (source: string, field: string) => MetadataProperty

type MetadataPropertyEntity = {
  [source: string]: {
    [field: string]: MetadataProperty
  }
}

export const useGetMetadataProperty = (
  eventName: string,
  tagInfo?: TagRuleList[number]
): GetMetadataProperty => {
  const {
    eventEntity,
    userProfileEntity,
    userProfileIntelligence,
    userThirdPartyTagEntity,
    userProfileDimension,
    userAutoTagEntity,
    userBehaviorTagEntity,
  } = useMetadataQuery()

  const propertyEntity: MetadataPropertyEntity = useMemo(() => {
    const currEntity: MetadataPropertyEntity = {}

    const currentEvent = eventEntity.events.entities[eventName]

    currentEvent?.propertiesIds.forEach(propertyId => {
      const targetProperty = currentEvent.propertiesEntity[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userProfileEntity.ids.forEach(propertyId => {
      const targetProperty = userProfileEntity.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userThirdPartyTagEntity.ids.forEach(propertyId => {
      const targetProperty = userThirdPartyTagEntity.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userAutoTagEntity.ids.forEach(propertyId => {
      const targetProperty = userAutoTagEntity.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userBehaviorTagEntity.ids.forEach(propertyId => {
      const targetProperty = userBehaviorTagEntity.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userProfileIntelligence.ids.forEach(propertyId => {
      const targetProperty = userProfileIntelligence.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    userProfileDimension.ids.forEach(propertyId => {
      const targetProperty = userProfileDimension.entities[propertyId]

      if (!currEntity[targetProperty.tableName]) {
        currEntity[targetProperty.tableName] = {}
      }

      currEntity[targetProperty.tableName][targetProperty.id] = targetProperty
    })

    if (tagInfo) {
      const { tagUsersTableName, title, tagValues } = tagInfo

      currEntity[tagUsersTableName] = {
        [TAG_FIELD]: {
          id: tagUsersTableName,
          displayName: title,
          dataType: 'ENUM',
          tableName: tagUsersTableName,
          isFromColumnOfDimTable: false,
          repeated: false,
          range: {
            allEnumValue: tagValues || [],
          },
        },
      }
    }

    return currEntity
  }, [
    eventEntity.events,
    eventName,
    userProfileEntity.ids,
    userProfileEntity.entities,
    userThirdPartyTagEntity.ids,
    userThirdPartyTagEntity.entities,
    userAutoTagEntity.ids,
    userAutoTagEntity.entities,
    userBehaviorTagEntity.ids,
    userBehaviorTagEntity.entities,
    userProfileIntelligence.ids,
    userProfileIntelligence.entities,
    userProfileDimension.ids,
    userProfileDimension.entities,
    tagInfo,
  ])

  const getMetadataProperty = useCallback<GetMetadataProperty>(
    (source: string, field: string) => {
      return (
        // ENUM 屬性需要打 API，沒有辦法第一時間取得 dataType 的則當作列舉
        propertyEntity[source]?.[field] || { dataType: 'ENUM', range: {} }
      )
    },
    [propertyEntity]
  )

  return getMetadataProperty
}

export default useGetMetadataProperty
