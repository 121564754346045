import { memo } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'

import BasicNode from './_shared/BasicNode'
import type { BasicNodeOperation } from '../hooks/useWorkflow'

const ExitNode = memo(
  ({
    data: { onDelete: handleDelete },
    type,
    isConnectable,
  }: NodeProps<BasicNodeOperation<{}>>) => {
    return (
      <>
        <BasicNode nodeType="exit" isSettled onDelete={handleDelete} />

        <Handle
          type="target"
          position={Position.Top}
          isConnectable={isConnectable}
          id={type}
        />
      </>
    )
  }
)

export default ExitNode
