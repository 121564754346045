import Box, { BoxProps } from '@mui/material/Box'

import InstagramSvg from '@shared/assets/images/icons/icon-instagram.svg?react'

export const InstagramIcon = (props: BoxProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box {...props}>
    <InstagramSvg />
  </Box>
)

export default InstagramIcon
