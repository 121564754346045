import type { Edge, Node } from 'reactflow'
import snakecaseKeys from 'snakecase-keys'

import { getIntSafe } from '@shared/lib/utils/number'

import {
  FLOW_TYPE_CONNECTOR,
  FlowSubType,
  FlowType,
  TargetNodeValue,
  WorkflowFromApi,
  WorkflowNodeFromApi,
} from '../types'

export const convertWorkflowNodeForApi = ({
  nodes,
  edges,
}: {
  nodes?: Node<{ usersInNode: number } & Record<string, unknown>>[]
  edges?: Edge[]
}): WorkflowNodeFromApi[] | undefined =>
  nodes?.reduce<WorkflowFromApi['nodes']>((prevNodes, currNode) => {
    const [type, subType] = (currNode.type || '').split(FLOW_TYPE_CONNECTOR)

    if (edges) {
      // 找出 source 為當前節點的 edge
      const sourceEdges = edges?.filter(x => x.source === currNode.id)
      const targetNode =
        sourceEdges.length > 0
          ? sourceEdges.reduce((prevEdge, currEdge) => {
              const targetNodeValue = (currEdge.sourceHandle ||
                'default') as TargetNodeValue
              prevEdge[targetNodeValue] = currEdge.target
              return prevEdge
            }, {} as Record<TargetNodeValue, string>)
          : undefined

      const { usersInNode, ...nodeData } = currNode.data
      const { editorJson, ...restNodeData } = nodeData

      prevNodes.push({
        display: {
          x_pos: getIntSafe(currNode.position.x),
          y_pos: getIntSafe(currNode.position.y),
        },
        flow: {
          node_id: currNode.id,
          type: type as FlowType,
          sub_type: subType as FlowSubType,
          node_input: snakecaseKeys(
            {
              ...restNodeData,
              // 由於 email editor 的屬性名稱同時包含 camelcase 與 snakecase，例如： popupOverlay_backgroundColor
              // 如果直接透過 snakecaseKeys 轉換會破壞原本結構，目前使用與 contentTemplate 一致的處理方式將 JSON 轉換成字串，並於收到資料後再額外呼叫 JSON.parse
              editor_json_string: JSON.stringify(editorJson),
            },
            { deep: true }
          ),
          to_node: targetNode,
        },
      })
    }

    return prevNodes
  }, [])

export default convertWorkflowNodeForApi
