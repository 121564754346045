import type { Edge, Node } from 'reactflow'
import type { ParseKeys } from 'i18next'

import type { NodeType } from '@shared/api/rtkQuery'

import { NODE_TYPE_SCHEMA_MAP } from '../constants'

type Handle = { nodeId: string; handleId: string | null }

export const validateNodes = async (
  nodes: Node[],
  edges: Edge[],
  sourceHandles: Handle[]
): Promise<ParseKeys<'workflow'> | ''> => {
  // * 若有任何節點的連結點尚未設定：提示「所有節點之連結點均須設定連線，請確認後再啟用」
  // 在 onConnect 函式已經限制同一節點不能建立多條線，因此可以使用 edges 與 nodes 的數量判斷是否全部節點都被連接
  if (edges.length + 1 !== nodes.length) {
    return 'errors.connection_invalid'
  }

  // * 有路徑末端沒有「旅程終點」：提示「所有路徑均須設定旅程終點，請確認後再啟用」
  // 檢查所有 sourceHandles 都有連結的 edge
  if (
    sourceHandles.some(
      handle =>
        !edges.some(
          x => x.source === handle.nodeId && x.sourceHandle === handle.handleId
        )
    )
  ) {
    return 'errors.node_exit_invalid'
  }

  // * 若有節點處於尚未設定的空白狀態：提示「有節點尚未完成設定，請確認後再啟用」，並在畫布上以視覺提示標示未設定之節點
  try {
    await nodes.reduce(async (prevValidator, currNode) => {
      await prevValidator
      return (
        NODE_TYPE_SCHEMA_MAP[currNode.type as NodeType]?.validate(
          currNode.data
        ) ?? Promise.resolve()
      )
    }, Promise.resolve() as Promise<unknown>)
  } catch {
    return 'errors.node_invalid'
  }

  // pass
  return ''
}
