import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'

import { formatRTKQueryError } from '@entities/apiHandler'
import {
  type SupportThirdPartyTagsProvide,
  type ThirdPartyTagFormValues,
  useCreateThirdPartyTagMutation,
  useGetThirdPartyAppIntegrationListQuery,
  useUpdateThirdPartyTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP } from '@shared/model/constants/thirdParty'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import theme from '@theme'
import {
  STATUS_CODE,
  THIRD_PARTY_TAG_PATH,
} from '@widgets/tag/_shared/constants'

import {
  InputArea,
  InputAreaGrid,
  LabelArea,
  Text,
} from '../../../detail/ThirdParty/styles'
import { selectAvailableThirdParty } from './selector'
import useThirdPartySchema from './useThirdPartySchema'

type Props = {
  isLoading?: boolean
  tagId?: string
} & Partial<ThirdPartyTagFormValues>

const ThirdPartyForm = ({
  isLoading = false,
  tagId = '',
  name = '',
  description = '',
  thirdPartyAppId = 0,
  appType = '',
}: Props) => {
  const isCreateMode = !tagId
  const { t, i18n } = useTranslation(['common', 'tag', 'settings'])
  const [isEditable, setIsEditable] = useState(!tagId)
  const { thirdPartySchema } = useThirdPartySchema()
  const { getThirdPartyProviderName } = useThirdPartyProviderName()
  const { thirdPartyList, isStatusListFetching } =
    useGetThirdPartyAppIntegrationListQuery(
      {
        page: 1,
        perPage: THIRD_PARTY_MAX_COUNT,
        groupType: 'line',
      },
      {
        selectFromResult: selectAvailableThirdParty,
      }
    )

  const [createThirdPartyTag, { isLoading: isTagCreating }] =
    useCreateThirdPartyTagMutation()

  const [updateThirdPartyTag, { isLoading: isTagUpdating }] =
    useUpdateThirdPartyTagMutation()

  const history = useHistory()

  const dispatch = useAppDispatch()

  const goToOverview = () =>
    history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${THIRD_PARTY_TAG_PATH}`)

  const isRelatedThirdPartyAppDeleted = !isCreateMode && !thirdPartyAppId

  const providerOption = useMemo(() => {
    // 沒有支援的第三方 app || 編輯模式下, 原本關聯的第三方 app 被刪除
    if (thirdPartyList.length === 0 || isRelatedThirdPartyAppDeleted) {
      return [{ label: '-', value: -1 }]
    }

    return thirdPartyList.map(thirdParty => ({
      value: thirdParty.id,
      label: thirdParty.name,
    }))
  }, [isRelatedThirdPartyAppDeleted, thirdPartyList])

  const { values, setFieldValue, touched, errors, handleSubmit, resetForm } =
    useFormik<ThirdPartyTagFormValues>({
      initialValues: {
        name,
        description,
        thirdPartyAppId,
        appType,
      },
      enableReinitialize: true,
      validationSchema: thirdPartySchema,
      onSubmit: async values => {
        try {
          if (isCreateMode) {
            await createThirdPartyTag(values).unwrap()

            goToOverview()

            return
          }

          await updateThirdPartyTag({
            id: tagId,
            ...values,
          }).unwrap()
          setIsEditable(false)
        } catch (e) {
          const typedError = formatRTKQueryError(e)

          if (typedError.statusCode === STATUS_CODE.THIRD_TAG_SOURCE_CONFLICT) {
            const errorMsg = JSON.parse(typedError.message)

            /** 錯誤訊息 enum: [ "idx_tag_name is duplicate",  "idx_app_id is duplicate"] */
            if (errorMsg.error_message === 'idx_app_id is duplicate') {
              dispatch(
                openToast({
                  message: t('tag:error.third_party_source_already_exists'),
                  status: 'error',
                })
              )
            }

            if (errorMsg.error_message === 'idx_tag_name is duplicate') {
              dispatch(
                openToast({
                  message: t('tag:error.third_party_tag_already_exists'),
                  status: 'error',
                })
              )
            }

            return
          }

          dispatch(
            openToast({
              message: t('common:failure_create'),
              status: 'error',
            })
          )
        }
      },
    })

  const lineProvider = useMemo(
    () =>
      thirdPartyList.find(
        thirdParty => thirdParty.id === values.thirdPartyAppId
      ),
    [values.thirdPartyAppId, thirdPartyList]
  )

  const handleOnCancel = () => {
    resetForm()
    setIsEditable(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <InputAreaGrid>
          <LabelArea>
            <Text>{t('tag:tag_name')}*</Text>
          </LabelArea>
          <InputArea>
            <Box width="100%">
              <Box width="100%" display="flex" justifyContent="space-between">
                <PrimaryGreyInput
                  name="name"
                  type="text"
                  value={values.name}
                  placeholder={t('tag:please_enter_tag_name')}
                  onChange={({ target: { value = '' } }) =>
                    setFieldValue('name', value.slice(0, MAX_LIST_NAME_LENGTH))
                  }
                  width={400}
                  disabled={!isEditable || isLoading}
                  isError={Boolean(touched.name && errors.name)}
                />
                {!isCreateMode && (
                  <Box display="flex" ml={4}>
                    {isEditable ? (
                      <>
                        <Button
                          color="inherit"
                          onClick={handleOnCancel}
                          style={{ marginRight: '40px' }}
                        >
                          {t('common:cancel')}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isTagUpdating || isLoading}
                        >
                          {t('common:save')}
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setIsEditable(true)}
                        disabled={isLoading || isTagUpdating}
                      >
                        {t('common:edit')}
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              <Text isError>{touched.name && errors.name}</Text>
            </Box>
          </InputArea>
        </InputAreaGrid>
        <InputAreaGrid>
          <LabelArea>
            <Text>{t('tag:tag_description')}</Text>
          </LabelArea>
          <InputArea>
            <Box display="flex" flex={1} flexDirection="column">
              <PrimaryGreyInput
                type="text"
                value={values.description}
                placeholder={t('tag:enter_short_description_for_tag')}
                onChange={({ target: { value = '' } }) =>
                  setFieldValue(
                    'description',
                    value.slice(0, MAX_LIST_NAME_LENGTH)
                  )
                }
                disabled={!isEditable || isLoading}
                isError={Boolean(touched.description && errors.description)}
              />
              <Text isError>{touched.description && errors.description}</Text>
            </Box>
          </InputArea>
        </InputAreaGrid>
        <InputAreaGrid>
          <LabelArea>
            <Text>{t('tag:third_party_tag_source')}*</Text>
          </LabelArea>
          <InputArea>
            <Box display="flex" flex={1} flexDirection="column">
              <DropdownList
                value={values.thirdPartyAppId}
                key={`${i18n.language}_${providerOption.length}`}
                maxWidth={400}
                options={providerOption}
                isError={touched.appType && Boolean(errors.appType)}
                onValueChanged={async option => {
                  await setFieldValue('thirdPartyAppId', option.value)

                  const selectedType = thirdPartyList.find(
                    thirdParty => thirdParty.id === option.value
                  )?.type

                  await setFieldValue(
                    'appType',
                    selectedType as SupportThirdPartyTagsProvide
                  )
                }}
                uniqueId="third-party-third-party-app-id"
                disabled={isStatusListFetching || isLoading || !isCreateMode}
                popupMaxWidth={460}
                marginRightRatio={3}
              />
              <Text isError>{touched.appType && errors.appType}</Text>
            </Box>
            <Text>
              <>
                {lineProvider?.type && (
                  <Box mr={1}>
                    {t('settings:service')}：
                    {THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP.line}-
                    {!isStatusListFetching &&
                      getThirdPartyProviderName(lineProvider.type)}
                  </Box>
                )}
                {lineProvider?.lineOaName && (
                  <>LINE OA：{lineProvider.lineOaName}</>
                )}
              </>
            </Text>
          </InputArea>
        </InputAreaGrid>
        <Box mb={4} borderBottom={`1px solid ${theme.colors.black6}`} />
        <Box display="flex" alignItems="flex-start">
          <Text>{t('tag:update')}</Text>
          <Text>{t('tag:third_party_update_period_tutorial')}</Text>
        </Box>
        {isCreateMode && (
          <Box display="flex" justifyContent="flex-end" mt={4}>
            <Button
              color="inherit"
              onClick={goToOverview}
              style={{ marginRight: '40px' }}
            >
              {t('common:cancel')}
            </Button>
            <DeepBlueGradientButton
              width={200}
              type="submit"
              disabled={isTagCreating}
            >
              {t('common:create')}
            </DeepBlueGradientButton>
          </Box>
        )}
      </Card>
    </form>
  )
}

export default ThirdPartyForm
