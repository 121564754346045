export default class BiDirectionalMap<K, V> {
  private keyToValueMap: Map<K, V>
  private valueToKeyMap: Map<V, K>

  constructor(pairs?: Array<[K, V]>) {
    this.keyToValueMap = new Map<K, V>()
    this.valueToKeyMap = new Map<V, K>()

    if (!pairs || !pairs.length) {
      return
    }

    pairs.forEach(([key, value]) => {
      this.set(key, value)
    })
  }

  public set(key: K, value: V): void {
    this.keyToValueMap.set(key, value)
    this.valueToKeyMap.set(value, key)
  }

  public getValue(key: K): V | undefined {
    return this.keyToValueMap.get(key)
  }

  public getKey(value: V): K | undefined {
    return this.valueToKeyMap.get(value)
  }

  public delete(key: K): void {
    const value = this.keyToValueMap.get(key)
    if (value !== undefined) {
      this.keyToValueMap.delete(key)
      this.valueToKeyMap.delete(value)
    }
  }

  public clear(): void {
    this.keyToValueMap.clear()
    this.valueToKeyMap.clear()
  }

  public size(): number {
    return this.keyToValueMap.size
  }

  public hasKey(key: K): boolean {
    return this.keyToValueMap.has(key)
  }

  public hasValue(value: V): boolean {
    return this.valueToKeyMap.has(value)
  }
}
