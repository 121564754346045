import styled from '@emotion/styled'

export const EventContainer = styled.div`
  padding-bottom: 24px;
`

export const EventSelector = styled.div`
  padding: 4px 24px;
  border-left: 5px solid ${({ theme }) => theme.colors.brightBlue};
`

export const StatisticMeasureListItem = styled.li`
  margin-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.brightBlue};
`

export const AttributeFilterContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.black6};
`

export const AttributeFilterList = styled.ul`
  padding-left: 24px;

  border-left: 5px solid ${({ theme }) => theme.colors.brightLightBlueTwo};
`
