import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import type { FieldProps } from './models/types'
import * as Field from './styles'

export interface CheckBoxFieldProps extends FieldProps<boolean> {}

export const CheckboxField = ({
  id,
  description,
  error,
  label,
  name,
  onChange,
  value,
}: CheckBoxFieldProps) => {
  return (
    <>
      <FormControlLabel
        control={<Checkbox id={id} color="primary" name={name} />}
        checked={value}
        onChange={(_, checked) => onChange?.(checked)}
        label={
          <Box component="span" sx={{ fontSize: 14, fontWeight: 600 }}>
            {label}
          </Box>
        }
      />

      {(description || error) && (
        <Box sx={{ ml: 4, mt: -1 }}>
          {description && <Field.Description>{description}</Field.Description>}

          {error && <Field.Error>{error}</Field.Error>}
        </Box>
      )}
    </>
  )
}
