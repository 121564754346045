import { useContext } from 'react'
import styled from '@emotion/styled'

import { LAYOUT, Z_INDEX } from '@shared/model/constants/styles'
import DotLoader from '@shared/ui/loaders/DotLoader'
// FIXME: This is a bad practice to import context from widgets
import { SidebarContext } from '@widgets/layout/ui/Sidebar'

const LoadingBG = styled.div<{ isWhiteBG: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX.loader};

  ${({ isWhiteBG, theme }) =>
    isWhiteBG &&
    `
      opacity: 0.7;
      background-color: ${theme.colors.white};
  `}
`

const LoadingWrapper = styled.div<{
  isSidebarOpen: boolean
}>`
  position: relative;
  top: 45%;
  left: ${({ isSidebarOpen }) =>
    `calc(50% + ${
      (isSidebarOpen ? LAYOUT.sidebarWidth : LAYOUT.sidebarWidthCollapse) / 2
    }px)`};
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  isWhiteBG?: boolean
}

const Loader = ({ isWhiteBG = false }: Props) => {
  const { isSidebarOpen } = useContext(SidebarContext)

  return (
    <LoadingBG isWhiteBG={isWhiteBG}>
      <LoadingWrapper isSidebarOpen={isSidebarOpen}>
        <DotLoader />
      </LoadingWrapper>
    </LoadingBG>
  )
}

export default Loader
