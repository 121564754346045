import i18n from 'i18next'
import round from 'lodash/round'

export const getChartTooltipRow = ({
  label,
  value,
  color,
  ration,
}: {
  label?: string
  color: string
  value: string
  ration: number
}): string => {
  const tooltipRowString = []

  tooltipRowString.push(
    `<div class="audience-tooltip-row">
      <div class="audience-tooltip-circle" style="background-color: ${color};"></div>
      <div class="audience-tooltip-row">
        ${label ? `<div class="audience-tooltip-name">${label}</div>` : ''}
        <div class="audience-tooltip-value audience-tooltip-value-right">${value} (${(
      ration * 100
    ).toFixed(0)}%)</div>
      </div>
    </div>`
  )

  return tooltipRowString.join('')
}

export const getChartTooltip =
  ({ total }: { total: number }) =>
  (opt: {
    w: {
      config: {
        labels: string[]
        colors: string[]
        xaxis: {
          categories: string[]
        }
      }
    }
    series: number[] | number[][]
  }) => {
    const { w, series } = opt
    const seriesArr = (
      Array.isArray(series[0]) ? series[0] : series
    ) as number[]
    const result: string[] = []

    seriesArr.forEach((s: number, idx: number) => {
      const label = w.config.labels[idx] || w.config.xaxis.categories[idx]
      const color = w.config.colors[idx]
      const value = i18n.t('audience:unit.people', {
        number: s.toLocaleString(),
      })
      const ration = total ? round(s / total, 2) : 0

      result.push(getChartTooltipRow({ label, color, value, ration }))
    })

    return `<div class="audience-tooltip-wrapper">
              <div class="audience-tooltip-column">${result.join('')}</div>
            </div>`
  }
