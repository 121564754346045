import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GetCDPAuthTokenPayload,
  ProjectPlanType,
  setApiHeaders,
  SSOAuthPayload,
} from '@shared/api/axios'
import storage from '@shared/lib/storage'
import { getIntSafe } from '@shared/lib/utils/number'

import { decodeCdpAuthToken } from '../utils'
import { FACEBOOK_AUTH_TIME, GOOGLE_AUTH_TIME, Plan, plan } from './constants'

interface UserInfo {
  displayName: string
  email: string
  photoURL: string
  userId: string
}

export interface ProjectIdInfo {
  id: number
  ikaId: string
  planType: ProjectPlanType
  /**
   * @deprecated 僅提供兼容 v1 頁面
   */
  rollingdataUpdatedAt?: string
}

interface State {
  cdpAuthToken: string
  isAuth: boolean
  isInitialAuthDone: boolean
  isNoProjectListError: boolean
  plan: Plan
  projectIKAId: string
  projectList: ProjectIdInfo[]
  projectName: string
  userInfo: UserInfo
  firebase: {
    idToken: string
    authTime: number
  }
  google: {
    accessToken: string
    refreshToken: string
    expirationTime: number
  }
  facebook: {
    accessToken: string
    userId: string
    expirationTime: number
  }
}

const initialState: State = {
  cdpAuthToken: storage.getCDPAuthToken() || '',
  projectIKAId: storage.getProjectIKAId() || '',
  projectList: [],
  projectName: '',
  userInfo: {
    displayName: '',
    email: '',
    photoURL: '',
    userId: '',
  },
  isAuth: false,
  isInitialAuthDone: false,
  isNoProjectListError: false,
  plan: plan['standard'],
  firebase: {
    idToken: '',
    authTime: getIntSafe(storage.getFirebaseAuthTime()),
  },
  google: {
    accessToken: '',
    refreshToken: '',
    expirationTime: 0,
  },
  facebook: {
    accessToken: '',
    userId: '',
    expirationTime: 0,
  },
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: state => {
      state.isInitialAuthDone = false
    },
    initAuthDone: state => {
      state.isInitialAuthDone = true
    },
    initSSOAuth: (state, action: PayloadAction<SSOAuthPayload>) => {},

    initFirebaseAuth: () => {},
    signInFirebase: () => {},
    setFirebaseAuth: (
      state,
      {
        payload: { idToken, authTime },
      }: PayloadAction<{ idToken: string; authTime: number }>
    ) => {
      state.firebase.idToken = idToken
      state.firebase.authTime = authTime

      // FIXME: side effect
      setApiHeaders({ key: 'X-ID-TOKEN', value: idToken })
      storage.setFirebaseAuthTime(`${authTime}`)
    },
    refreshFirebaseAuth: () => {},

    setUserInfo: (state, action: PayloadAction<Omit<UserInfo, 'userId'>>) => {
      state.userInfo.displayName = action.payload.displayName
      state.userInfo.email = action.payload.email
      state.userInfo.photoURL = action.payload.photoURL
    },

    fetchProjectList: () => {},
    fetchProjectListSuccess: (
      state,
      action: PayloadAction<{
        projectList: ProjectIdInfo[]
        ikaId: string
      }>
    ) => {
      const { projectList, ikaId } = action.payload
      state.projectList = projectList

      if (state.projectIKAId !== ikaId) {
        state.projectIKAId = ikaId

        storage.setProjectIKAId(ikaId)
      }
    },
    fetchProjectListFailure: state => {
      state.isNoProjectListError = true
    },
    setProjectIKAId: (state, action: PayloadAction<string>) => {
      const projectIKAId = action.payload
      state.projectIKAId = projectIKAId

      // FIXME: side effect
      storage.setProjectIKAId(projectIKAId)
    },
    changeProjectIKAId: (state, action: PayloadAction<string>) => {},

    fetchCDPAuthToken: (
      state,
      action: PayloadAction<GetCDPAuthTokenPayload | undefined>
    ) => {},
    fetchCDPAuthTokenSuccess: (state, action: PayloadAction<string>) => {
      const cdpAuthToken = action.payload
      const cdpAuthPayload = decodeCdpAuthToken(cdpAuthToken)

      if (cdpAuthPayload === undefined) {
        return
      }

      state.projectIKAId = cdpAuthPayload.ikaId
      state.projectName = cdpAuthPayload.projectName
      state.userInfo.email = cdpAuthPayload.email
      state.userInfo.userId = cdpAuthPayload.userId

      state.cdpAuthToken = cdpAuthToken
      state.isAuth = true

      // FIXME: side effect
      setApiHeaders({ key: 'Authorization', value: cdpAuthToken })
      storage.setCDPAuthToken(cdpAuthToken)
    },

    setGoogleAuth: (
      state,
      action: PayloadAction<{ accessToken?: string; refreshToken: string }>
    ) => {
      const { accessToken, refreshToken } = action.payload

      if (accessToken) {
        state.google.accessToken = accessToken
        state.google.refreshToken = refreshToken

        const authTime = new Date().getTime()
        // expire after one hour
        state.google.expirationTime = authTime + GOOGLE_AUTH_TIME
      }
    },
    resetGoogleAuth: state => {
      state.google.expirationTime = 0
      state.google.accessToken = ''
      state.google.refreshToken = ''
    },

    setFacebookAuth: (
      state,
      {
        payload: { accessToken, userId },
      }: PayloadAction<{ accessToken: string; userId: string }>
    ) => {
      state.facebook.accessToken = accessToken
      state.facebook.userId = userId

      const authTime = new Date().getTime()
      state.facebook.expirationTime = authTime + FACEBOOK_AUTH_TIME
    },
    resetFacebookAuth: state => {
      state.facebook.accessToken = ''
      state.facebook.userId = ''
      state.facebook.expirationTime = 0
    },

    logout: () => {},
    logoutDone: state => {
      state.firebase.idToken = ''
      state.google.accessToken = ''
      state.cdpAuthToken = ''
      state.projectIKAId = ''
      state.projectList = []
      state.projectName = ''
      state.userInfo = {
        displayName: '',
        email: '',
        photoURL: '',
        userId: '',
      }
      state.isAuth = false
      state.isInitialAuthDone = true

      // FIXME: side effect
      storage.removeCDPAuthToken()
    },

    changePlan: (state, action: PayloadAction<ProjectPlanType>) => {
      state.plan = plan[action.payload]
    },
  },
})

const { actions, reducer } = auth

export const {
  initAuth,
  initAuthDone,
  initSSOAuth,
  initFirebaseAuth,
  signInFirebase,
  setFirebaseAuth,
  refreshFirebaseAuth,
  setUserInfo,
  setGoogleAuth,
  resetGoogleAuth,
  setFacebookAuth,
  resetFacebookAuth,
  fetchProjectList,
  fetchProjectListSuccess,
  fetchProjectListFailure,
  setProjectIKAId,
  changeProjectIKAId,
  fetchCDPAuthToken,
  fetchCDPAuthTokenSuccess,
  logout,
  logoutDone,
  changePlan,
} = actions

export type SignInAction =
  | ReturnType<typeof initAuth>
  | ReturnType<typeof initAuthDone>
  | ReturnType<typeof initSSOAuth>
  | ReturnType<typeof initFirebaseAuth>
  | ReturnType<typeof signInFirebase>
  | ReturnType<typeof refreshFirebaseAuth>
  | ReturnType<typeof setFirebaseAuth>
  | ReturnType<typeof setUserInfo>
  | ReturnType<typeof setGoogleAuth>
  | ReturnType<typeof resetGoogleAuth>
  | ReturnType<typeof setFacebookAuth>
  | ReturnType<typeof resetFacebookAuth>
  | ReturnType<typeof fetchProjectList>
  | ReturnType<typeof fetchProjectListSuccess>
  | ReturnType<typeof fetchProjectListFailure>
  | ReturnType<typeof setProjectIKAId>
  | ReturnType<typeof changeProjectIKAId>
  | ReturnType<typeof fetchCDPAuthToken>
  | ReturnType<typeof fetchCDPAuthTokenSuccess>
  | ReturnType<typeof logout>
  | ReturnType<typeof logoutDone>
  | ReturnType<typeof changePlan>

export default reducer

/**
 * @deprecated 僅提供兼容 v1 頁面
 */
export const rollingdataUpdatedAtSelector = createSelector(
  [
    (state: RootState) => state.auth.projectList,
    state => state.auth.projectIKAId,
  ],
  (projectList, projectIKAId) =>
    projectList.find(({ ikaId }) => ikaId === projectIKAId)
      ?.rollingdataUpdatedAt || ''
)
