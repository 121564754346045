import Box from '@mui/material/Box'
import round from 'lodash/round'

import { SEGMENT_STYLE_CONFIG } from '@entities/rfm/model'
import type { Rfm } from '@shared/api/rtkQuery'

import SegmentCard, { Props as SegmentCardProps } from './SegmentCard'

type Props = Pick<Rfm, 'rfmGroups'>

const SegmentCardsSection = ({ rfmGroups }: Props) => {
  const segmentCards = rfmGroups.map(group => {
    const { name, userCount } = group
    const config = SEGMENT_STYLE_CONFIG.find(
      config => config.segmentType === name
    )

    return {
      ...config,
      userCount,
    } as SegmentCardProps
  })

  const allUserCount = rfmGroups.reduce(
    (acc, group) => acc + group.userCount,
    0
  )

  return (
    <Box
      display="grid"
      mb={5}
      sx={{
        gridGap: '24px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: 'repeat(2, auto-fit)',
        gridTemplateAreas: "'area0 area1 area2' 'area3 area4 area5'",
      }}
    >
      {segmentCards.map((segmentCard, idx) => {
        const area = `area${idx}`

        const { segmentType, displayNameI18n, userCount, themeColor, range } =
          segmentCard

        const proportion = round(userCount / allUserCount, 2)

        return (
          <Box
            key={segmentType}
            sx={{
              gridArea: area,
            }}
          >
            <SegmentCard
              segmentType={segmentType}
              displayNameI18n={displayNameI18n}
              userCount={userCount}
              proportion={proportion}
              themeColor={themeColor}
              range={range}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default SegmentCardsSection
