import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import TrialThirdPartyBgUrl from '@shared/assets/images/trial/trialThirdParty.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import TrialFeatureCover from '@shared/ui/TrialFeatureCover'

import Detail from './ui/Detail/Detail'
import Overview from './ui/Overview/Overview'

export const ThirdPartyRoutes = () => {
  const { t } = useTranslation(['common'])
  const isThirdPartyDisable = useAppSelector(
    state => state.auth.plan.settings.thirdParty.disabled
  )

  const { path } = useRouteMatch()

  return isThirdPartyDisable ? (
    <TrialFeatureCover
      title={t('common:route.third_party')}
      description={t('common:trial.third_party.description')}
      bgImageUrl={TrialThirdPartyBgUrl}
      notice={t('common:trial.third_party.notice')}
    />
  ) : (
    <Switch>
      <Route path={`${path}/new`}>
        <Detail />
      </Route>

      <Route path={`${path}/:applicationId`}>
        <Detail />
      </Route>

      <Route path={`${path}`}>
        <Overview />
      </Route>

      <Redirect to={`${path}`} />
    </Switch>
  )
}

export default ThirdPartyRoutes
