import { AxiosError } from 'axios'

import { Pages } from '@shared/model/constants/routes'

import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_STATUS } from '../model/constants'

// TODO: 如果有哪些 api 的 error 要忽略，再讓 pageNames 吃 undefined
export const formatApiError = (
  error: AxiosError,
  pageNames: Pages | Pages[] = []
) => ({
  statusCode: error?.response?.status || DEFAULT_ERROR_STATUS,
  message:
    error?.response?.data?.error || error?.message || DEFAULT_ERROR_MESSAGE,
  pageNames: Array.isArray(pageNames)
    ? pageNames
    : pageNames
    ? [pageNames]
    : [],
})
