import Box, { BoxProps } from '@mui/material/Box'

import FacebookSvg from '@shared/assets/images/icons/icon-facebook.svg?react'

export const FacebookIcon = (props: BoxProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box {...props}>
    <FacebookSvg />
  </Box>
)

export default FacebookIcon
