/*
  input: 
  [
    [1, 2, 3],
    [4, 5, 6]
  ]

  output:
  [
    [1, 4],
    [2, 5],
    [3, 6]
  ]
*/
export const getTransposeArray = <T extends {} | null>(arr: T[][]) => {
  const result = []
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      if (!Array.isArray(result[j])) {
        result[j] = [] as T[]
      }
      result[j][i] = arr[i][j]
    }
  }
  return result
}
