import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import {
  AttributeConditionFilterNode,
  AttributeDimOperator,
  AttributeOperator,
  AttributeOperatorVirtual,
  AttributeParams,
  useGetTagQuery,
} from '@shared/api/rtkQuery'
import {
  createMetadataPropertyId,
  resolveMetadataPropertyId,
  useGetMetadataProperty,
} from '@shared/lib/utils/metadata'
import CategoryList, { Category } from '@shared/ui/Dropdown/CategoryList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import { ThemeColor } from '@theme'

import AttributeValueInput from './AttributeValueInput'
import {
  createAttributeCondition,
  createUiOperator,
  getDefaultAttributeParams,
  getDimOperators,
  metadataTypeAttributeOperatorMap,
  resolveUiOperator,
} from './utils'

const NO_TAG_DATA_ID = ''

type Props = {
  bgColor?: ThemeColor
  categories: Category[]
  dimOperator?: AttributeDimOperator
  eventName: string
  field: string
  isDisabled?: boolean
  operator: AttributeOperator
  params?: AttributeParams
  source: string
  onChange: (value: AttributeConditionFilterNode) => void
}

const AttributeFilter = ({
  bgColor,
  categories,
  dimOperator,
  eventName,
  field,
  isDisabled = false,
  operator,
  params,
  source,
  onChange,
}: Props) => {
  const { t } = useTranslation(['metadata'])

  // 移除既有 operator 的時候應該考慮是否向後相容，避免之前設定的條件無法對應
  const operatorEntity = useMemo<
    { [name in AttributeOperator | AttributeOperatorVirtual]: string }
  >(
    () => ({
      '=': t('metadata:operator.equal'),
      '!=': t('metadata:operator.is_not_equal'),
      '>': t('metadata:operator.greater_than'),
      '>=': t('metadata:operator.greater_equal_than'),
      '<': t('metadata:operator.less_than'),
      '<=': t('metadata:operator.less_equal_than'),
      between: t('metadata:operator.range'),
      is_null: t('metadata:operator.is_null'),
      not_null: t('metadata:operator.not_null'),
      contain: t('metadata:operator.contains'),
      not_contain: t('metadata:operator.not_contains'),
      start_at: t('metadata:operator.begins_with'),
      not_start_at: t('metadata:operator.not_begins_with'),
      end_at: t('metadata:operator.ends_with'),
      not_end_at: t('metadata:operator.not_ends_with'),
      is_empty: t('metadata:operator.empty_string'),
      not_empty: t('metadata:operator.not_empty_string'),
      is_true: t('metadata:operator.is_true'),
      is_false: t('metadata:operator.is_false'),
      match_regex: t('metadata:operator.match_regex'),
      absolute_between: t('metadata:operator.static_time'),
      relative_between: t('metadata:operator.in_n_days'),
      relative_between_truncated: t(
        'metadata:operator.from_future_N_days_to_M_days'
      ),
      'relative_between_truncated+past': t(
        'metadata:operator.from_past_N_days_to_M_days'
      ),
    }),
    [t]
  )

  const dimOperatorEntity = useMemo<{ [name in AttributeDimOperator]: string }>(
    () => ({
      all_match: t('metadata:dim_operator.all_match'),
      any_match: t('metadata:dim_operator.any_match'),
      sum: t('metadata:dim_operator.sum'),
    }),
    [t]
  )

  const hasTagId = /^tag_[0-9]+/g.test(source)
  const tagId = source.match(/\d+/g)?.[0] || NO_TAG_DATA_ID

  const { data: tagInfo, isFetching: isFetchingTagInfo } = useGetTagQuery(
    +tagId,
    { skip: !hasTagId }
  )

  const getMetadataProperty = useGetMetadataProperty(eventName, tagInfo)
  const { dataType, range, repeated } = getMetadataProperty(source, field)

  const valueOptions = (range.allEnumValue || []).map(x => ({
    label: x,
    value: x,
  }))

  if (!dataType) {
    return null
  }

  const operatorOptions: Category['options'] = metadataTypeAttributeOperatorMap[
    dataType
  ].map<Category['options'][number]>(currOperator => ({
    label: operatorEntity[currOperator],
    value: currOperator,
  }))

  const uiOperator = createUiOperator(operator, params)

  const dimOperators = getDimOperators(source, dataType, repeated)

  const dimOperatorOptions = dimOperators.map<Category['options'][number]>(
    x => ({
      label: dimOperatorEntity[x],
      value: x,
    })
  )

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      <CategoryList
        bgColor={bgColor}
        categories={categories}
        selectedValue={createMetadataPropertyId(source, field)}
        isDisabled={isDisabled}
        popperDisablePortal
        onValueChanged={propertyId => {
          const { source: targetSource, field: targetField } =
            resolveMetadataPropertyId(propertyId)

          const { dataType: currDataType, repeated } = getMetadataProperty(
            targetSource,
            targetField
          )

          if (currDataType) {
            const [firstFilterOperator] =
              metadataTypeAttributeOperatorMap[currDataType]

            const [firstDimOperator] = getDimOperators(
              targetSource,
              currDataType,
              repeated
            )

            onChange(
              createAttributeCondition({
                field: targetField,
                source: targetSource,
                operator: resolveUiOperator(firstFilterOperator),
                dimOperator: firstDimOperator,
                params: getDefaultAttributeParams(
                  currDataType,
                  firstFilterOperator
                ),
              })
            )
          }
        }}
      />

      {dimOperatorOptions.length > 0 && (
        <DropdownList
          options={dimOperatorOptions}
          value={dimOperator}
          disabled={isDisabled}
          popperDisablePortal
          bgColor={bgColor}
          onValueChanged={({ value }) => {
            onChange(
              createAttributeCondition({
                source,
                field,
                operator,
                dimOperator: value as AttributeDimOperator,
                params,
              })
            )
          }}
        />
      )}

      <DropdownList
        options={operatorOptions}
        value={uiOperator}
        disabled={isDisabled}
        popperDisablePortal
        bgColor={bgColor}
        onValueChanged={({ value }) => {
          onChange(
            createAttributeCondition({
              source,
              field,
              operator: resolveUiOperator(value as AttributeOperator),
              dimOperator,
              params: getDefaultAttributeParams(
                dataType,
                value as AttributeOperator
              ),
            })
          )
        }}
      />

      {isFetchingTagInfo ? (
        <CircularProgress size={24} />
      ) : (
        <AttributeValueInput
          operator={uiOperator}
          dataType={dataType}
          valueOptions={valueOptions}
          values={params}
          bgColor={bgColor}
          maxLength={range.maxStringLength}
          minLength={range.minStringLength}
          maxValue={range.maxFloatValue ?? range.maxIntegerValue}
          minValue={range.minFloatValue ?? range.minIntegerValue}
          isDisabled={isDisabled}
          onChange={values => {
            onChange(
              createAttributeCondition({
                source,
                field,
                operator,
                dimOperator,
                params: values,
              })
            )
          }}
        />
      )}
    </Box>
  )
}

export default AttributeFilter
