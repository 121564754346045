import SharedSVG from '@shared/assets/images/icons/icon-shared.svg?react'
import theme from '@theme'

import IconWrapper from './IconWrapper'

export const SharedIcon = ({
  color = theme.colors.white,
  marginLeft,
}: {
  color?: string
  marginLeft?: number
}) => (
  <IconWrapper marginLeft={marginLeft}>
    <SharedSVG fill={color} />
  </IconWrapper>
)
