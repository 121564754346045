import type { TagSourceType } from '@widgets/tag/_shared/type'

export const tagLocalesMap: {
  [name in TagSourceType]: { nameKey: string; tooltipKey: string }
} = {
  grading: {
    nameKey: 'grading',
    tooltipKey: 'grading',
  },
  behavior: {
    nameKey: 'behavior',
    tooltipKey: 'behavior',
  },
  thirdParty: {
    nameKey: 'third_party',
    tooltipKey: 'third_party',
  },
  intelligent: {
    nameKey: '',
    tooltipKey: '',
  },
}
