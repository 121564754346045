export const removeSpaces = (input: string) => input.replace(/\s/g, '')

export const isUrlEqual = (url1: string, url2: string) => {
  try {
    const sortQueryString = (queryString: string): string => {
      const params = new URLSearchParams(queryString)
      const sortedParams = Array.from(params.entries())
        .sort(([key1], [key2]) => key1.localeCompare(key2))
        .map(([key, value]) => `${key}=${value}`)
        .join('&')

      return sortedParams
    }

    const parsedUrl1 = new URL(url1)
    const parsedUrl2 = new URL(url2)

    return (
      parsedUrl1.origin === parsedUrl2.origin &&
      sortQueryString(parsedUrl1.search) === sortQueryString(parsedUrl2.search)
    )
  } catch (error) {
    return false
  }
}

export const camelToSnakeCase = (str: string): string =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
