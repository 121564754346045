import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'

type SimpleButtonProps = { width?: number }

export const SimpleButton = styled(
  ({ width, ...otherProps }: SimpleButtonProps & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} />
  )
)`
  && {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};

    color: ${({ theme }) => theme.colors.textSecondBlue};
    font-family: inherit;
    font-weight: 500;
    text-transform: none;

    transition: 0.25s;

    :hover {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

export default SimpleButton
