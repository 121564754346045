import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Handle, NodeProps, Position } from 'reactflow'
import Box from '@mui/material/Box'

import {
  NodeType,
  SchedulingType,
  useGetAudienceRuleQuery,
} from '@shared/api/rtkQuery'

import BasicNode, { TooltipIcon } from '../_shared/BasicNode'
import type { BasicNodeOperation } from '../../hooks/useWorkflow'
import TriggerNodeEditDialog from './TriggerNodeEditDialog'
import {
  TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP,
  TriggerNodeData,
} from './triggerNodeSchema'

type Props = BasicNodeOperation<Partial<TriggerNodeData>>

const TriggerNode = memo(
  ({
    id,
    data: {
      onChange,
      onDelete,
      onDeleteEdges,
      onNodeTypeChange,
      triggerId,
      usersInNode,
      ...initialValues
    },
    type,
    isConnectable,
  }: NodeProps<Props>) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false)

    const handleClose = () => {
      setIsOpenDialog(false)
    }

    const handleConfirm = (
      values: Partial<TriggerNodeData>,
      schedulingType: SchedulingType
    ) => {
      onChange(values)
      setIsOpenDialog(false)

      onNodeTypeChange(
        schedulingType === 'default'
          ? 'trigger-enter_audience_rule'
          : 'trigger-current_audience_rule'
      )
    }

    const { audienceRule, isSettled, isFetching } = useGetAudienceRuleQuery(
      initialValues.audienceRuleId ?? 0,
      {
        skip: !initialValues.audienceRuleId,
        selectFromResult: ({ data, isSuccess, isFetching }) => {
          if (data) {
            TRIGGER_NODE_AUDIENCE_RULE_CACHE_MAP.set(data.id, new Date())
          }

          return {
            audienceRule: data,
            isSettled: isSuccess,
            isFetching,
          }
        },
      }
    )

    const { t } = useTranslation(['workflow', 'audience'])

    const nodeType = type as NodeType

    return (
      <>
        <BasicNode
          nodeType={nodeType}
          onEdit={() => setIsOpenDialog(true)}
          triggerId={triggerId}
          usersInNode={usersInNode}
          isLoading={isFetching}
          isSettled={isSettled}
        >
          <Box
            component="p"
            sx={{
              overflow: 'hidden',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
            }}
          >
            {audienceRule?.title}
          </Box>

          <Box display="flex">
            {nodeType === 'trigger-enter_audience_rule'
              ? t('audience:type_dynamic_name')
              : t('audience:type_static_name')}

            <TooltipIcon
              title={
                <>
                  <p>{id}</p>
                  <p>
                    {nodeType === 'trigger-enter_audience_rule' &&
                      t('workflow:node_trigger_rule_dynamic_audience')}

                    {nodeType === 'trigger-current_audience_rule' &&
                      t('workflow:node_trigger_rule_static_audience')}
                  </p>
                </>
              }
            />
          </Box>
        </BasicNode>

        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={isConnectable}
        />

        {isOpenDialog && (
          <TriggerNodeEditDialog
            isOpen
            initialValues={initialValues}
            audienceRule={audienceRule}
            onConfirm={handleConfirm}
            onClose={handleClose}
          />
        )}
      </>
    )
  }
)

export default TriggerNode
