import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  INITIAL_PAGINATION_RESPONSE,
  PolicyType,
  usePolicyListQuery,
} from '@shared/api/rtkQuery'
import TrialPolicy from '@shared/assets/images/trial/trialPolicy.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import CheckboxList from '@shared/ui/CheckboxList'
import Dropdown from '@shared/ui/Dropdown'
import FeatureInstruction from '@shared/ui/FeatureIntroduction'
import { SearchBar } from '@shared/ui/searchInput'
import { DataTable } from '@shared/ui/table'
import theme from '@theme'

type RouteState = {
  page: number
  perPage: number
  types: PolicyType[]
  search: string
}

const InitialRouteState: RouteState = {
  page: 1,
  perPage: 10,
  types: ['default', 'custom'],
  search: '',
}

// 由於 history.push 第二個參數型別是 unknown
// 使用額外函式確保傳入的參數符合 RouteState 型別
const createRouteState = (state: RouteState) => state

const List = () => {
  const { pathname, state: routeState = InitialRouteState } =
    useLocation<RouteState>()

  const isShowInstruction = useAppSelector(
    state => state.auth.plan.iam.policy.introduction
  )

  const {
    data: policyListData = INITIAL_PAGINATION_RESPONSE,
    isLoading: isFetchingPolicyList,
  } = usePolicyListQuery({
    types: routeState.types,
    page: routeState.page,
    perPage: routeState.perPage,
    search: routeState.search,
  })

  const history = useHistory()

  const handleTextSearch = (text: string) => {
    history.push(
      `${pathname}`,
      createRouteState({
        ...routeState,
        search: text,
        page: 1,
      })
    )
  }

  const handleSelectedTypeChange = (values: string[]) => {
    history.push(
      `${pathname}`,
      createRouteState({
        ...routeState,
        types: values as PolicyType[],
        page: 1,
      })
    )
  }

  const handleChangeRowsPerPage = (perPage: number) => {
    history.push(
      `${pathname}`,
      createRouteState({
        ...routeState,
        page: 1,
        perPage,
      })
    )
  }

  const handleChangePage = (page: number) => {
    history.push(
      `${pathname}`,
      createRouteState({
        ...routeState,
        page: page + 1,
      })
    )
  }

  const { t, i18n } = useTranslation(['iam', 'common'])

  const typeOptions = useMemo(
    () => [
      {
        label: t('iam:policy.dropdown_option_default'),
        value: 'default',
      },
      {
        label: t('iam:policy.dropdown_option_custom'),
        value: 'custom',
      },
    ],
    [t]
  )

  const selectedTypeList = typeOptions
    .filter(item => routeState.types.includes(item.value as PolicyType))
    .map(option => option.value)

  const [isOpenTypeOptions, setIsOpenTypeOptions] = useState(false)

  return (
    <>
      {isShowInstruction && (
        <FeatureInstruction
          title={t('iam:policy.instruction.policy_instruction_title')}
          description={t(
            'iam:policy.instruction.policy_instruction_description'
          )}
          notice={t('iam:policy.instruction.policy_instruction_notice')}
          imageUrl={TrialPolicy}
        />
      )}
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Box>{t('common:route.iam_policy')}</Box>
        </Breadcrumbs>

        <Link to={`${pathname}/new`}>
          <DeepBlueGradientButton width={200}>
            {t('common:create')}
          </DeepBlueGradientButton>
        </Link>
      </Box>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box
          borderRight={`1px solid ${theme.colors.bgPrimaryGrey}`}
          boxShadow={`0px 4px 9px ${theme.colors.black6}`}
        >
          <Dropdown
            key={i18n.language}
            label={t('iam:policy.dropdown_label')}
            bgColor={theme.colors.white}
            isOpen={isOpenTypeOptions}
            isShowFilterIcon
            setIsOpen={setIsOpenTypeOptions}
          >
            <CheckboxList
              options={typeOptions}
              checkedValues={selectedTypeList}
              onChange={handleSelectedTypeChange}
            />
          </Dropdown>
        </Box>

        <SearchBar
          key={routeState.search}
          defaultValue={routeState.search}
          placeholder={t('iam:policy.search_bar_placeholder')}
          onSearch={handleTextSearch}
        />
      </Box>

      <DataTable
        columns={[
          { headerName: 'name', field: 'name' },
          { headerName: 'description', field: 'description' },
          { headerName: 'type', field: 'type' },
          {
            headerName: 'create_time',
            field: 'createdAt',
            valueFormatter: date => formatDateTimeDisplay(date as string),
          },
          { headerName: 'creator', field: 'creator' },
        ]}
        rows={policyListData.items}
        count={policyListData.totalCount}
        page={policyListData.totalCount === 0 ? 0 : routeState.page - 1}
        rowsPerPage={routeState.perPage}
        isLoading={isFetchingPolicyList}
        isRowClickable
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}

export default List
