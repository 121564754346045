import curry from 'lodash/fp/curry'

import type { CampaignStatistic } from '@shared/api/rtkQuery'
import type { NumericKeys } from '@shared/lib/utils/type'

export const calculateAmount = curry(
  (field: NumericKeys<CampaignStatistic>, data: CampaignStatistic[]) => {
    if (!data.length) {
      return 0
    }

    return data.reduce((acc, curr) => acc + curr[field], 0)
  }
)
