import type {
  ResourceTypeAction,
  TagResourceType,
} from '@shared/model/types/ingestion'

import type { TemplateFile } from '../_shared/constants'

export const tagResourceTypeActionTemplateMap: {
  [name in `${TagResourceType}.${ResourceTypeAction}`]?: TemplateFile
} = {
  'tag_user.insert': {
    filename: 'tag_user_insert_template.ndjson',
  },
  'behavior_tag_user.upsert': {
    filename: 'behavior_tag_user_upsert_template.ndjson',
  },
  'behavior_tag_user.delete': {
    filename: 'behavior_tag_user_delete_template.ndjson',
  },
}
