import type { Policy } from '@shared/api/rtkQuery'

const DEFAULT_CREATOR = 'iKala'

export const checkIsEditable = (policy?: Policy) => {
  if (policy === undefined) {
    return false
  }

  return policy.creator !== DEFAULT_CREATOR
}

export type ServiceEntity = {
  ids: string[]
  entities: {
    [id: string]: {
      isActivated: boolean
      actionRecord: Record<string, boolean>
    }
  }
}

export const createActionsFromServiceEntity = (
  entity: ServiceEntity
): string[] =>
  entity.ids.reduce<string[]>((acc, curr) => {
    const actionEntity = entity.entities[curr]
    if (actionEntity.isActivated) {
      for (let action in actionEntity.actionRecord) {
        if (actionEntity.actionRecord[action]) {
          acc.push(action)
        }
      }
    }

    return acc
  }, [])

export const createPolicyServiceEntity = (policy?: Policy) => {
  if (!policy) {
    return undefined
  }

  return {
    name: policy.name,
    description: policy.description,
    serviceEntity: (policy.services ?? []).reduce<ServiceEntity>(
      (acc, curr) => {
        acc.ids.push(curr.name)
        acc.entities[curr.name] = {
          isActivated: curr.activated,
          actionRecord: curr.actionGroups.reduce<Record<string, boolean>>(
            (groupAcc, groupCurr) =>
              groupCurr.actions.reduce((actionAcc, actionCurr) => {
                actionAcc[actionCurr] = true
                return actionAcc
              }, groupAcc),
            {}
          ),
        }

        return acc
      },
      { ids: [], entities: {} }
    ),
  }
}
