import type { AudienceFilterNode } from '@shared/api/rtkQuery'

export const getAudienceFilterInitialState = (
  filter: Partial<AudienceFilterNode> | undefined
): AudienceFilterNode | undefined => {
  if (!filter || !filter.combination || !filter.filters) {
    return undefined
  }

  return { combination: filter.combination, filters: filter.filters }
}

export default getAudienceFilterInitialState
