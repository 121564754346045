import styled from '@emotion/styled'

import { DeepBlueGradientButton } from '@shared/ui/buttons'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 64px;
`

export const ImgWrapper = styled.div`
  margin-bottom: 16px;
  width: 80%;
  max-width: 776px;
`

export const Img = styled.img`
  width: 100%;
`

export const Text = styled.div`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 24px;
  font-family: inherit;
  font-weight: 500;
`

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.textSecondBlue};
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
`

export const Button = styled(DeepBlueGradientButton)`
  && {
    margin-top: 24px;
    width: 300px;
  }
`
