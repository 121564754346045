import * as yup from 'yup'

import type { FlowSubTypeMapped } from '@shared/api/rtkQuery'

import type { TimeData } from '../_shared/Timing/timeReducer'

export type TimingNodeSchema = TimeData & {
  type?: FlowSubTypeMapped['timing'] | 'manual'
}

const checkIsValid = (data: TimingNodeSchema): boolean => {
  // 沒有設定的情況當成 manual(指定時間發送) - 向前相容
  if (!data?.type || data?.type === 'manual') {
    if (data?.duration) {
      const { day, hour, minute } = data.duration
      return (
        Number.isInteger(day) &&
        Number.isInteger(hour) &&
        Number.isInteger(minute)
      )
    }

    if (data?.dayOfWeek) {
      return Boolean(data.dayOfWeek.length > 0)
    }

    if (data?.specifiedDay) {
      return true
    }
  }

  if (data?.type === 'best') {
    if (data.duration) {
      const { day } = data.duration
      return Number.isInteger(day)
    }

    if (data.dayOfWeek) {
      return Boolean(data.dayOfWeek.length > 0)
    }

    if (data.specifiedDay) {
      return true
    }
  }

  return false
}

export const timingNodeSchema = yup
  .object<TimingNodeSchema>()
  .test('isValid', 'Invalid timing data', checkIsValid)
