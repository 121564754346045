import { type Middleware, configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'

import rtkQuery, { mockApi as mockRtkQuery } from '@shared/api/rtkQuery'

import rootEpic from './rootEpic'
import rootReducer, {
  createReduxHistory,
  routerMiddleware,
} from './rootReducer'

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>()

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      routerMiddleware,
      epicMiddleware,
      rtkQuery.middleware,
      ...((import.meta.env.DEV
        ? [mockRtkQuery.middleware]
        : []) as Middleware[]),
    ]),
  devTools: import.meta.env.NODE_ENV !== 'production',
})

export type AppDispatch = typeof store.dispatch

epicMiddleware.run(rootEpic)

export const history = createReduxHistory(store)

export default store
