import styled from '@emotion/styled'
import Box from '@mui/material/Box'

export const LegendAreaGrid = styled(Box)`
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-areas: 'legend data';
  align-items: center;
  justify-items: center;
  width: 100%;
`

export const LegendArea = styled(Box)`
  grid-area: legend;
  justify-content: center;
  align-items: center;
`

export const DataArea = styled(Box)`
  display: flex;
  grid-area: data;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
