import api from '@shared/api/axios'
import type { OperatorType } from '@shared/api/rtkQuery/analytics/notificationRuleType'

export const NOTIFICATION_PER_PAGE = 30

export type NotificationsDetailType = 'journey' | 'audiences' | 'thirdPartyTag'

export type NotificationType = {
  id: number
  title: string
  createdAt: string
  type: 'event' | 'tentacle' | 'general'
  archivedAt: string | null
  detail: { [key in NotificationsDetailType]: string[] } & {
    eventDisplayName: string
    name: string
    rule: {
      condition: {
        operator: OperatorType
        daysBefore: number
        value: number
      }
    }
  } & {
    message: string
  }
}

export const getNotificationList = async (page?: number) => {
  const query = page
    ? `page=${page}&per_page=${NOTIFICATION_PER_PAGE}`
    : `page=1&per_page=${NOTIFICATION_PER_PAGE}`
  return await api.get<NotificationType[]>(`/notifications?${query}`)
}
