import styled from '@emotion/styled'
import { spacing } from '@mui/system'

import { LAYOUT } from '@shared/model/constants/styles'

export const Text = styled.div<{
  isError?: boolean
  marginRightRatio?: number
}>`
  display: flex;
  align-items: center;

  margin-right: ${({ marginRightRatio }) => (marginRightRatio || 0) * 8}px;
  min-width: 80px;
  height: ${LAYOUT.audienceBuilder.heightOfRow}px;

  color: ${({ isError, theme }) =>
    isError ? theme.colors.chartRed : theme.colors.lightNavyBlue};
  font-size: 14px;
  font-weight: 500;
`

export const InputAreaGrid = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-areas: 'label input';
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
  ${spacing}
`

export const LabelArea = styled.div`
  grid-area: label;
  ${spacing}
`

export const InputArea = styled.div`
  display: flex;
  grid-area: input;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  ${spacing}
`

export const ThirdPartyTagDetailText = styled.div`
  color: ${({ theme }) => theme.colors.textPrimaryBlue};

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

export const ThirdPartyTagDetailNumber = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 40px;
  font-weight: 700;
  line-height: 47px;
`

export const ThirdPartyTagDetail = styled.div`
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 14px;
  padding-top: 2px;
  padding-right: 10px;
  padding-left: 8px;
  min-height: 24px;
  border: ${({ theme }) => `1px solid ${theme.colors.tagBackground}`};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.tagBackground};
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 13px;
  font-weight: 500;
  cursor: default;
`
