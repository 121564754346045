import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import D3WordCloud from 'react-d3-cloud'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import max from 'lodash/max'
import min from 'lodash/min'

import {
  INITIAL_PAGINATION_RESPONSE,
  useGetProductHashTagsQuery,
} from '@shared/api/rtkQuery'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import Card from '@shared/ui/Card'

import { useSharedSetState, useSharedState } from '../SharedStateContext'
import Tooltip, { Props as TooltipProps } from './Tooltip'

const HASHTAGS_MAX_COUNT = 30
const MAX_FONT_SIZE = 50
const MIN_FONT_SIZE = 20

type Props = {
  productId: string
}

const WordCloud = ({ productId }: Props) => {
  const { t } = useTranslation(['table'])
  const sharedState = useSharedState()
  const setSharedState = useSharedSetState()
  const wordCloudContainerRef = useRef<HTMLDivElement>(null)
  const [tipText, setTipText] = useState<TooltipProps['text']>('')
  const [tipPosition, setTipPosition] = useState<TooltipProps['position']>({
    x: 0,
    y: 0,
  })
  const [tipIsShow, setTipIsShow] = useState<boolean>(false)

  const {
    data: productTagsList = INITIAL_PAGINATION_RESPONSE,
    isFetching: isProductTagsListFetching,
    isSuccess: isProductTagsListSuccess,
  } = useGetProductHashTagsQuery({
    productId,
    page: DEFAULT_PAGE,
    perPage: HASHTAGS_MAX_COUNT,
  })

  useEffect(() => {
    if (isProductTagsListSuccess && productTagsList.items.length === 0) {
      setSharedState(prev => ({
        ...prev,
        isEmptyTags: true,
      }))

      return
    }

    setSharedState(prev => ({
      ...prev,
      isEmptyTags: false,
    }))
  }, [isProductTagsListSuccess, productTagsList.items.length, setSharedState])

  const handleMouseIn = useCallback(
    // FIXME: remove any
    (e: any, word: any) => {
      setTipIsShow(true)

      setTipText(
        `${t('table:ai_marketing_assistant_mention_rate')} : ${(
          word.value * 100
        ).toFixed(0)}%`
      )

      const textTargetRect = e.target.getBoundingClientRect()

      const containerRect =
        wordCloudContainerRef.current?.getBoundingClientRect() || {
          left: 0,
          top: 0,
        }

      setTipPosition({
        x: textTargetRect.left - containerRect.left + textTargetRect.width / 2,
        y: textTargetRect.top - containerRect.top - textTargetRect.height / 2,
      })
    },
    [t]
  )

  const handleMouseOut = useCallback(() => {
    setTipIsShow(false)
  }, [])

  const productHashtagsData = useMemo(
    () =>
      productTagsList.items.map(item => ({
        text: item.hashtag,
        value: item.mentionRate,
      })),
    [productTagsList.items]
  )

  const { maxMentionRate, minMentionRate } = useMemo(() => {
    const maxMentionRate = max(productHashtagsData.map(item => item.value))
    const minMentionRate = min(productHashtagsData.map(item => item.value))

    return {
      maxMentionRate,
      minMentionRate,
    }
  }, [productHashtagsData])

  const fontSize = useCallback(
    // FIXME: remove any
    (word: any) => {
      if (
        typeof maxMentionRate === 'undefined' ||
        typeof minMentionRate === 'undefined'
      ) {
        return 0
      }

      const size =
        ((word.value - minMentionRate) / (maxMentionRate - minMentionRate)) *
          (MAX_FONT_SIZE - MIN_FONT_SIZE) +
        MIN_FONT_SIZE

      return size
    },
    [minMentionRate, maxMentionRate]
  )

  if (sharedState.isEmptyTags) {
    return null
  }

  return (
    <Card>
      <Box
        position="relative"
        ref={wordCloudContainerRef}
        sx={{
          text: {
            userSelect: 'none',
          },
        }}
        minHeight={300}
      >
        {!isProductTagsListFetching && (
          <>
            <Tooltip isShow={tipIsShow} text={tipText} position={tipPosition} />
            <D3WordCloud
              data={productHashtagsData}
              font="'Microsoft JhengHei', '微軟正黑體', 'Microsoft YaHei', sans-serif"
              fontSize={fontSize}
              rotate={0}
              spiral="rectangular"
              onWordMouseOver={handleMouseIn}
              onWordMouseOut={handleMouseOut}
              height={250}
              padding={1}
            />
          </>
        )}
      </Box>
    </Card>
  )
}

export default WordCloud
