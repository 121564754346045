import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useFormik } from 'formik'
import cloneDeep from 'lodash/cloneDeep'

import type { LineProvider, ThirdPartyAppProvider } from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks/store'
import useGetMsgChannelForSelectOptionsQuery from '@shared/lib/hooks/useGetMsgChannelForSelectOptionsQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { getIntSafe } from '@shared/lib/utils/number'
import { Z_INDEX } from '@shared/model/constants/styles'
import { ConfirmDialog } from '@shared/ui/dialogs'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import theme from '@theme'

import {
  CAMPAIGN_NAME_MAX_LENGTH,
  REACT_FLOW_NO_DRAG,
} from '../_shared/constants'
import { FieldGrid } from '../_shared/styles'
import Timing from '../_shared/Timing'
import { DEFAULT_TIMING_DATA } from '../_shared/Timing/_shared/constants'
import type { TimeData } from '../_shared/Timing/timeReducer'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import {
  type ActionSendLineNodeData,
  type OmnichatConfig,
  actionSendLineNodeSchema,
} from './actionSendLineNodeSchema'
import {
  CoolBeWyeth as CoolBeWyethTemplate,
  MAAC as MAACTemplate,
  Omnichat as OmnichatTemplate,
  Super8 as Super8Template,
  Trial as TrialTemplate,
} from './templates'

type ActionSendLineNodeEditDialogProps = {
  isOpen: boolean
  initialValues: ActionSendLineNodeData
  onConfirm: (values: ActionSendLineNodeData) => void
  onClose: () => void
}

const ActionSendLineNodeEditDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: ActionSendLineNodeEditDialogProps) => {
  const { t } = useTranslation(['workflow', 'settings'])
  const formState = useWorkflowFormState()
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setTouched,
    handleSubmit,
    setValues,
  } = useFormik<ActionSendLineNodeData>({
    initialValues: {
      campaignName: '',
      msgChannelId: undefined,
      reactionTiming: undefined,
      maacConfig: undefined,
      super8Config: undefined,
      trialConfig: undefined,
      coolbeWyethConfig: undefined,
      omnichatConfig: undefined,
      lineProvider: undefined,
      ...initialValues,
    },
    enableReinitialize: true,
    validationSchema: actionSendLineNodeSchema,
    onSubmit: values => {
      if (!formState.isEditable) {
        onClose()

        return
      }

      const formValues = cloneDeep(values)
      delete formValues.lineProvider

      onConfirm({ ...formValues, reactionTiming: formValues.reactionTiming })
    },
  })

  const isEnableWorkflow = useAppSelector(
    state => state.auth.plan.workflow.enableWorkflow
  )

  const { msgChannelOptions, isMsgChannelLoading } =
    useGetMsgChannelForSelectOptionsQuery({ mediumType: 'line_push' })

  const msgChannelApp = useMemo(
    () =>
      msgChannelOptions.find(
        ({ value }) => value === String(values.msgChannelId)
      ),
    [msgChannelOptions, values.msgChannelId]
  )

  const isEnableTracking = useMemo(
    () => Boolean(values.reactionTiming),
    [values.reactionTiming]
  )

  useEffect(() => {
    if (!formState.isSubmitted) {
      return
    }

    setTouched({ campaignName: true, msgChannelId: true }, true)
  }, [formState.isSubmitted, setTouched])

  return (
    <>
      <ConfirmDialog
        className={REACT_FLOW_NO_DRAG}
        isOpen={isOpen}
        maxWidth="lg"
        modalTitle={t('workflow:node_action_line_configuration')}
        onConfirm={handleSubmit}
        onClose={onClose}
      >
        <FieldGrid pb={2} mb={2}>
          <Box component="label" htmlFor="msgChannelId" fontWeight="medium">
            {t('workflow:node_action_third_party')} *
          </Box>

          <NestedList
            anchorId="msgChannelId"
            isFullWidth
            isDisabled={!formState.isEditable || isMsgChannelLoading}
            isError={Boolean(errors.msgChannelId)}
            label={t('workflow:node_action_third_party_select_hint')}
            onChange={({ value }) => {
              const lineProvider = msgChannelOptions.find(
                option => option.value === value
              )?.integrationType as LineProvider

              switch (lineProvider) {
                case 'coolbe_wyeth': {
                  setValues({
                    ...values,
                    msgChannelId: getIntSafe(value),
                    lineProvider,
                    maacConfig: undefined,
                    super8Config: undefined,
                    trialConfig: undefined,
                    omnichatConfig: undefined,
                    coolbeWyethConfig: {
                      templateId: '',
                      messageTemplateType: 'system_template',
                    },
                  })

                  break
                }

                case 'maac': {
                  setValues({
                    ...values,
                    msgChannelId: getIntSafe(value),
                    lineProvider,
                    super8Config: undefined,
                    trialConfig: undefined,
                    coolbeWyethConfig: undefined,
                    omnichatConfig: undefined,
                    maacConfig: {
                      templateId: 0,
                    },
                  })

                  break
                }

                case 'omnichat': {
                  setValues({
                    ...values,
                    msgChannelId: getIntSafe(value),
                    lineProvider,
                    maacConfig: undefined,
                    super8Config: undefined,
                    trialConfig: undefined,
                    coolbeWyethConfig: undefined,
                    omnichatConfig: {
                      groupId: '',
                      templateId: '',
                    },
                  })

                  break
                }
                case 'super8': {
                  setValues({
                    ...values,
                    msgChannelId: getIntSafe(value),
                    lineProvider,
                    maacConfig: undefined,
                    trialConfig: undefined,
                    coolbeWyethConfig: undefined,
                    omnichatConfig: undefined,
                    super8Config: {
                      templateId: '',
                    },
                  })
                  break
                }

                default: {
                  setValues({
                    ...values,
                    msgChannelId: getIntSafe(value),
                    lineProvider,
                    maacConfig: undefined,
                    super8Config: undefined,
                    trialConfig: undefined,
                    coolbeWyethConfig: undefined,
                    omnichatConfig: undefined,
                  })
                }
              }
            }}
            options={msgChannelOptions}
            popperClassName={REACT_FLOW_NO_DRAG}
            popperZIndex={Z_INDEX.dialog}
            value={`${values.msgChannelId}`}
          />

          {msgChannelApp && (
            <Box sx={{ gridColumnStart: 2 }}>
              <Box display="inline" mr={2}>
                LINE OA：{msgChannelApp.integrationLineOaName}
              </Box>
              {`${t('settings:service')}：${getThirdPartyProviderName(
                msgChannelApp.integrationType as ThirdPartyAppProvider
              )}`}
            </Box>
          )}

          <Box component="label" htmlFor="campaignName" fontWeight="medium">
            {t('workflow:node_action_campaign_name')} *
          </Box>

          <PrimaryGreyInput
            disabled={!formState.isEditable}
            fullWidth
            id="campaignName"
            isError={touched.campaignName && Boolean(errors.campaignName)}
            name="campaignName"
            onChange={({ target: { value } }) =>
              setFieldValue(
                'campaignName',
                value.substring(0, CAMPAIGN_NAME_MAX_LENGTH)
              )
            }
            placeholder={t('workflow:node_action_campaign_name_placeholder')}
            value={values.campaignName}
          />
        </FieldGrid>

        {
          /* coolbe_wyeth do not support tracking */
          msgChannelApp?.integrationType !== 'coolbe_wyeth' && (
            <FieldGrid mb={2}>
              <Box
                component="label"
                htmlFor="isEnableTracking"
                fontWeight="medium"
              >
                {t('workflow:node_action_line_push_tracking_condition')}
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEnableTracking}
                    color="primary"
                    disabled={!formState.isEditable}
                    id="isEnableTracking"
                    onChange={() =>
                      setFieldValue(
                        'reactionTiming',
                        values.reactionTiming ? undefined : DEFAULT_TIMING_DATA,
                        true
                      )
                    }
                  />
                }
                label={
                  <Box component="span" fontSize={14} fontWeight="bold">
                    {t('workflow:node_action_sms_track')}
                  </Box>
                }
              />

              <Collapse
                in={isEnableTracking}
                timeout="auto"
                sx={{ gridColumnStart: 2 }}
              >
                <Box
                  pl={3}
                  pt={2}
                  borderTop={`1px solid ${theme.colors.black6}`}
                >
                  <Timing
                    isEditable={formState.isEditable && isEnableTracking}
                    timeData={values.reactionTiming}
                    onTimeDataChange={(reactionTiming: TimeData) => {
                      if (isEnableTracking) {
                        setFieldValue('reactionTiming', reactionTiming)
                      }
                    }}
                    isHideDurationHourAndMinute
                    defaultTimeData={DEFAULT_TIMING_DATA}
                  />
                </Box>
              </Collapse>
            </FieldGrid>
          )
        }

        {isEnableWorkflow && msgChannelApp?.integrationType === 'maac' && (
          <MAACTemplate
            integrationId={msgChannelApp.integrationId}
            isEditable={formState.isEditable}
            maacTemplateConfig={values.maacConfig}
            setTemplateConfig={config => setFieldValue('maacConfig', config)}
          />
        )}

        {isEnableWorkflow && msgChannelApp?.integrationType === 'super8' && (
          <Super8Template
            integrationId={msgChannelApp.integrationId}
            isEditable={formState.isEditable}
            super8TemplateConfig={values.super8Config}
            setTemplateConfig={config => setFieldValue('super8Config', config)}
          />
        )}

        {isEnableWorkflow &&
          msgChannelApp?.integrationType === 'coolbe_wyeth' && (
            <CoolBeWyethTemplate
              integrationId={msgChannelApp.integrationId}
              isEditable={formState.isEditable}
              coolBeWyethTemplateConfig={{
                templateId: values.coolbeWyethConfig?.templateId ?? '',
                messageTemplateType:
                  values.coolbeWyethConfig?.messageTemplateType ??
                  'system_template',
              }}
              setTemplateConfig={config =>
                setFieldValue('coolbeWyethConfig', config)
              }
            />
          )}

        {isEnableWorkflow && msgChannelApp?.integrationType === 'omnichat' && (
          <OmnichatTemplate
            errors={
              (errors?.omnichatConfig ?? {}) as Record<
                keyof OmnichatConfig,
                string
              >
            }
            touched={
              (touched?.omnichatConfig ?? {}) as Record<
                keyof OmnichatConfig,
                boolean
              >
            }
            integrationId={msgChannelApp.integrationId}
            isEditable={formState.isEditable}
            omnichatTemplateConfig={values.omnichatConfig}
            setTemplateConfig={config =>
              setFieldValue('omnichatConfig', config)
            }
          />
        )}

        {!isEnableWorkflow && (
          <TrialTemplate
            isEditable={formState.isEditable}
            trialTemplateConfig={values.trialConfig}
            setTemplateConfig={config => setFieldValue('trialConfig', config)}
          />
        )}
      </ConfirmDialog>
    </>
  )
}

export default ActionSendLineNodeEditDialog
