import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import {
  BehaviorTagSchedulingType,
  DataSourceType,
  TagSchedulingType,
  TagType,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'

import { TagSourceType } from '../../type'
import {
  autoAigcSchema,
  behaviorInfoSchema,
  behaviorTagValidateSchema,
  gradingTagRuleSchema,
  gradingTagSchema,
  infoSchema,
} from './tagSchema'
import type {
  AutoAigcRule,
  BehaviorInfo,
  GradingTagRule,
  Info,
  TagDataValues,
} from './type'

const useTagValidator = (): {
  tagDataValidator: (
    tagSourceType: TagSourceType,
    tagValue: TagDataValues<TagSourceType>
  ) => Promise<boolean>
  autoAigcDataValidator: (autoAigc: AutoAigcRule) => boolean
  infoDataValidator: (info: Info) => boolean
  gradingTagRulesValidator: (tagRules: GradingTagRule[]) => boolean
  behaviorInfoDataValidator: (info: BehaviorInfo) => boolean
} => {
  const dispatch = useAppDispatch()

  const { t } = useTranslation(['tag'])

  const handleErrorMsg = useCallback(
    (yupValidationError: yup.ValidationError): boolean => {
      const errMsg = yupValidationError.errors[0]

      if (errMsg) {
        dispatch(
          openToast({
            message: errMsg,
            status: 'error',
          })
        )

        return false
      }

      dispatch(
        openToast({
          message: t('tag:unknown_error') as string,
          status: 'error',
        })
      )

      return false
    },
    [dispatch, t]
  )

  const tagDataValidator = useCallback(
    async (
      tagSourceType: TagSourceType,
      tagValue: TagDataValues<TagSourceType>
    ) => {
      if (tagSourceType === 'grading') {
        const tag = tagValue as TagDataValues<'grading'>

        try {
          // 因為 validateSync 只會把最後一筆錯誤傳出，不會先遇到錯誤就拋出。
          // 所以使用 validate
          await gradingTagSchema.validate({
            title: tag.title,
            description: tag.description,
            groupId: tag.groupId,
            type: tag.type,
            schedulingType: tag.schedulingType,
            tagRules: tag.tagRules,
          })

          return true
        } catch (validationError) {
          return handleErrorMsg(validationError as yup.ValidationError)
        }
      }

      if (tagSourceType === 'behavior') {
        const tag = tagValue as TagDataValues<'behavior'>

        try {
          // 因為 validateSync 只會把最後一筆錯誤傳出，不會先遇到錯誤就拋出。
          // 所以使用 validate
          await behaviorTagValidateSchema.validate({
            title: tag.title,
            description: tag.description,
            groupId: tag.groupId,
            schedulingType: tag.schedulingType,
            dataSourceType: tag.dataSourceType,
            autoAigcWithEventRule: {
              relatedAllAigcTarget:
                tag.autoAigcWithEventRule?.relatedAllAigcTarget || false,
              relatedAigcTargetIds:
                tag.autoAigcWithEventRule?.relatedAigcTargetIds || [],
            },
          })

          return true
        } catch (validationError) {
          return handleErrorMsg(validationError as yup.ValidationError)
        }
      }

      return false
    },
    [handleErrorMsg]
  )

  const autoAigcDataValidator = useCallback(
    (autoAigc: AutoAigcRule) => {
      try {
        autoAigcSchema.validateSync(autoAigc)

        return true
      } catch (validationError) {
        return handleErrorMsg(validationError as yup.ValidationError)
      }
    },
    [handleErrorMsg]
  )

  const infoDataValidator = useCallback(
    (info: {
      id?: number
      title: string
      description?: string
      groupId: number
      type: TagType
      schedulingType: TagSchedulingType
    }) => {
      try {
        infoSchema.validateSync(info)

        return true
      } catch (validationError) {
        return handleErrorMsg(validationError as yup.ValidationError)
      }
    },
    [handleErrorMsg]
  )

  const gradingTagRulesValidator = useCallback(
    (gradingTagRules: GradingTagRule[]) => {
      try {
        gradingTagRuleSchema.validateSync(gradingTagRules)

        return true
      } catch (validationError) {
        return handleErrorMsg(validationError as yup.ValidationError)
      }
    },
    [handleErrorMsg]
  )

  const behaviorInfoDataValidator = useCallback(
    (info: {
      id?: number
      title: string
      description?: string
      groupId: number
      dataSourceType: DataSourceType
      schedulingType: BehaviorTagSchedulingType
    }) => {
      try {
        behaviorInfoSchema.validateSync(info)

        return true
      } catch (validationError) {
        return handleErrorMsg(validationError as yup.ValidationError)
      }
    },
    [handleErrorMsg]
  )

  return {
    tagDataValidator,
    autoAigcDataValidator,
    infoDataValidator,
    gradingTagRulesValidator,
    behaviorInfoDataValidator,
  }
}

export default useTagValidator
