import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import type { CampaignMediumEvent, MediumType } from '@shared/api/rtkQuery'
import useTracingUrlStatus from '@shared/lib/hooks/useTracingUrlStatus'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import useGetTriggerGroupOptions from './hooks/useGetTriggerGroupOptions'
import { CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT } from '../constants'
import CampaignTriggerGroupDropdownList from './CampaignTriggerGroupDropdownList'
import Column from './Column'

type CampaignTriggerGroupFilterProps = {
  isDisabled: boolean
  isDisableCloneOrDelete: boolean
  mediumType: MediumType
  mediumEvent: CampaignMediumEvent
  workflowId?: number
  campaignId?: number
  tracingUrlId?: number
  onWorkflowEventChange: (value: number) => void
  onCampaignChange: (value: number) => void
  onTracingUrlChange: (value: number) => void
  onCloneTriggerGroupFilter: () => void
  onDeleteTriggerGroupFilter: () => void
}

const CampaignTriggerGroupFilter = ({
  isDisabled,
  isDisableCloneOrDelete,
  mediumType,
  mediumEvent,
  workflowId,
  campaignId,
  tracingUrlId,
  onWorkflowEventChange,
  onCampaignChange,
  onTracingUrlChange,
  onCloneTriggerGroupFilter,
  onDeleteTriggerGroupFilter,
}: CampaignTriggerGroupFilterProps) => {
  const { t } = useTranslation(['common', 'audience'])

  const { data: workflowOptions, isLoading } = useGetTriggerGroupOptions({
    mediumEvent,
    mediumType,
  })

  const { checkTracingUrlIsArchived } = useTracingUrlStatus()

  const campaignOptions = useMemo(
    () =>
      workflowOptions.find(item => item.value === workflowId)?.campaigns || [],
    [workflowOptions, workflowId]
  )
  const tracingUrlOptions = useMemo(
    () =>
      campaignOptions
        .find(item => item.value === campaignId)
        ?.tracingUrls?.filter(
          ({ value }) => !checkTracingUrlIsArchived(value)
        ) || [],
    [campaignId, campaignOptions, checkTracingUrlIsArchived]
  )

  const errors = useMemo(() => {
    if (!isDisabled) {
      return {}
    }

    const errors: Partial<
      Record<'workflow' | 'campaign' | 'tracingUrl', string>
    > = {}

    // 只有設定過 workflowId 才需要檢查是否被刪除
    const isWorkflowDeleted = workflowId
      ? !workflowOptions.find(item => item.value === workflowId)
      : false

    if (isWorkflowDeleted) {
      errors.workflow = t(
        'audience:campaigns.filter.options.workflow.has_been_deleted'
      )
      errors.campaign = t(
        'audience:campaigns.filter.options.workflow.has_been_deleted'
      )
      errors.tracingUrl = t(
        'audience:campaigns.filter.options.workflow.has_been_deleted'
      )

      return errors
    }

    // 只有設定 campaignId 才需要檢查是否被刪除
    const isCampaignDeleted = campaignId
      ? !campaignOptions.find(campaign => campaign.value === campaignId)
      : false

    if (isCampaignDeleted) {
      errors.campaign = t(
        'audience:campaigns.filter.options.campaign.has_been_deleted'
      )
      errors.tracingUrl = t(
        'audience:campaigns.filter.options.campaign.has_been_deleted'
      )

      return errors
    }

    // 只有設定 tracingUrlId 才需要檢查是否被刪除
    const isTracingUrlDeleted = tracingUrlId
      ? !tracingUrlOptions.some(tracingUrl => tracingUrl.value === tracingUrlId)
      : false

    if (isTracingUrlDeleted) {
      errors.tracingUrl = t(
        'audience:campaigns.filter.options.tracing_url.has_been_deleted'
      )

      return errors
    }

    return errors
  }, [
    campaignId,
    campaignOptions,
    isDisabled,
    t,
    tracingUrlId,
    tracingUrlOptions,
    workflowId,
    workflowOptions,
  ])

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Box display="flex" gap={2} flexDirection="column">
        <Column>
          <Box>{t('audience:campaigns.filter.options.workflow.label')}</Box>
          <CampaignTriggerGroupDropdownList
            options={workflowOptions}
            value={workflowId}
            error={errors.workflow}
            disabled={isDisabled}
            placeholder={t(
              'audience:campaigns.filter.options.workflow.placeholder'
            )}
            onChange={onWorkflowEventChange}
          />
        </Column>
        <Column>
          <Box>{t('audience:campaigns.filter.options.campaign.label')}</Box>
          <CampaignTriggerGroupDropdownList
            key={workflowId}
            options={campaignOptions}
            error={errors.campaign}
            value={campaignId}
            disabled={isDisabled || !workflowId}
            placeholder={t(
              'audience:campaigns.filter.options.campaign.placeholder'
            )}
            onChange={onCampaignChange}
          />
        </Column>
        {mediumType !== 'line_push' && (
          <Column>
            <Box>
              {t('audience:campaigns.filter.options.tracing_url.label')}
            </Box>
            <CampaignTriggerGroupDropdownList
              key={`${workflowId}-${campaignId}`}
              options={tracingUrlOptions}
              error={errors.tracingUrl}
              value={tracingUrlId}
              disabled={isDisabled || !campaignId}
              placeholder={t(
                'audience:campaigns.filter.options.tracing_url.placeholder'
              )}
              onChange={onTracingUrlChange}
            />
          </Column>
        )}
      </Box>
      {!isDisabled && (
        <>
          <Tooltip
            title={
              isDisableCloneOrDelete
                ? t('audience:v3.filter_max_hint_condition', {
                    max: CAMPAIGN_TRIGGER_GROUP_FILTER_MAX_COUNT,
                  })
                : t('audience:v3.filter_duplicate')
            }
          >
            <span>
              <IconButton
                disabled={isDisableCloneOrDelete}
                onClick={onCloneTriggerGroupFilter}
              >
                <Icon icon={ICON.copy} color={theme.colors.textPrimaryBlue} />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('audience:v3.filter_delete')}>
            <IconButton onClick={onDeleteTriggerGroupFilter}>
              <Icon icon={ICON.times} />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
}

export default CampaignTriggerGroupFilter
