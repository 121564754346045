import type { UserJourneyEvent } from '@shared/api/rtkQuery'
import type { Entity } from '@shared/model/types/common'

type UserJourneyEventEntity = Entity<{
  displayName: string
  propertyEntity: Entity<{ displayName: string }>
}>

export const transformUserJourneyToEntity = (
  events: UserJourneyEvent['events']
): UserJourneyEventEntity => {
  const ids: string[] = []

  const entities = events.reduce((acc, event) => {
    if (!acc[event.name]) {
      ids.push(event.name)
    }

    acc[event.name] = {
      displayName: event.displayName,
      propertyEntity: event.columns.reduce((record, column) => {
        const recordId = `${column.tableName}.${column.name}`

        if (!record.entities[recordId]) {
          record.ids.push(recordId)
          record.entities[recordId] = { displayName: column.displayName }
        }

        return record
      }, acc[event.name]?.propertyEntity ?? { ids: [], entities: {} }),
    }

    return acc
  }, {} as UserJourneyEventEntity['entities'])

  return { ids, entities }
}

export default transformUserJourneyToEntity
