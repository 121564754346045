import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import {
  INITIAL_PAGINATION_RESPONSE,
  useGetThirdPartyTagListQuery,
  useUpdateThirdPartyTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { createRouteState } from '@shared/lib/utils/routeUtils'
import { DEFAULT_PAGE } from '@shared/model/constants/table'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { ConfirmDialog } from '@shared/ui/dialogs'
import TextInput, { TextInputErrorText } from '@shared/ui/inputs/TextInput'
import { DataTable } from '@shared/ui/table'
import { TagDeleteConfirmDialog } from '@widgets/tag/_shared/components/TagDialogs'
import type { TagRouteSate } from '@widgets/tag/_shared/type'

import useHandleApiError from '../../_shared/hooks/useHandleApiError'
import useColumns from './useColumns'
type Props = {
  routeState: TagRouteSate
}

const ThirdParty = ({ routeState: { page, perPage } }: Props) => {
  const {
    pathname,
    state: routeState = createRouteState({
      page,
      perPage,
    }),
  } = useLocation<TagRouteSate>()

  const { t } = useTranslation(['tag', 'common'])
  const history = useHistory<TagRouteSate>()
  const [handleTagApiError] = useHandleApiError('thirdParty')

  const [openActionMenuId, setOpenActionMenuId] = useState<string>()
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false)
  const [isOpenRenameDialog, setIsOpenRenameDialog] = useState<boolean>(false)
  const [renameName, setRenameName] = useState<string>('')

  const {
    data: thirdPartyTagData = INITIAL_PAGINATION_RESPONSE,
    isLoading: isLoadingTag,
  } = useGetThirdPartyTagListQuery({
    page: routeState.page,
    perPage: routeState.perPage,
  })
  const [updateThirdPartyTag, { isLoading: isUpdatingTag }] =
    useUpdateThirdPartyTagMutation()

  const dispatch = useAppDispatch()

  const columns = useColumns({
    openActionMenuId,
    setOpenActionMenuId,
    setIsOpenDeleteDialog,
    isOpenDeleteDialog,
    setIsOpenRenameDialog,
    isOpenRenameDialog,
    setRenameName,
  })

  const safeCloseAllPopup = () => {
    // 需要照順序關閉彈窗避免頁面跳動
    setOpenActionMenuId(undefined)
    setIsOpenRenameDialog(false)
    setIsOpenDeleteDialog(false)
  }

  const handleChangePage = (newPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: newPage + 1,
      })
    )
  }

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    history.push(
      pathname,
      createRouteState({
        ...routeState,
        page: DEFAULT_PAGE,
        perPage: rowsPerPage,
      })
    )
  }

  const errors = {
    renameName:
      renameName.length === 0 &&
      t('common:errors.required', {
        count: MAX_LIST_NAME_LENGTH,
      }),
  }

  return (
    <>
      <DataTable
        columns={columns}
        rows={thirdPartyTagData.items}
        count={thirdPartyTagData.totalCount}
        page={routeState.page - 1}
        rowsPerPage={routeState.perPage}
        rowIdKey="id"
        isRowClickable
        isLoading={isLoadingTag}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ConfirmDialog
        isOpen={isOpenRenameDialog}
        isLoading={isUpdatingTag}
        modalTitle={t('tag:new_tag_name')}
        onClose={safeCloseAllPopup}
        onConfirm={async () => {
          if (!openActionMenuId || !renameName) {
            return
          }

          try {
            await updateThirdPartyTag({
              id: openActionMenuId,
              name: renameName,
              description: undefined,
            }).unwrap()

            safeCloseAllPopup()
          } catch (error) {
            handleTagApiError(error)
            const typedError = formatRTKQueryError(error)

            dispatch(handleApiError(typedError))
          }
        }}
      >
        <Box>
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <TextInput
              disabled={isUpdatingTag}
              error={Boolean(errors.renameName)}
              fullWidth
              onChange={({ target }) => {
                const newThirdPartyName = target.value.substring(
                  0,
                  MAX_LIST_NAME_LENGTH
                )
                setRenameName(newThirdPartyName)
              }}
              placeholder={t('tag:please_enter_tag_name')}
              value={renameName}
            />
          </Box>
          <TextInputErrorText>{errors.renameName}</TextInputErrorText>
        </Box>
      </ConfirmDialog>

      <TagDeleteConfirmDialog
        tagId={openActionMenuId}
        isOpen={isOpenDeleteDialog}
        onClose={safeCloseAllPopup}
        onFinished={safeCloseAllPopup}
      />
    </>
  )
}

export default ThirdParty
