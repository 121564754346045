import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'

import type { UserProfile } from '@shared/api/rtkQuery'
import { useGetUserProfileQuery } from '@shared/api/rtkQuery'
import {
  formatDateDisplay,
  formatDateTimeDisplay,
} from '@shared/lib/utils/time'
import { LineClamp } from '@shared/ui/text'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import ProfileRow from './ProfileRow'

const SectionProfile = () => {
  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const { t } = useTranslation(['userProfile'])
  const { data: profileData = {} as UserProfile, isFetching } =
    useGetUserProfileQuery({
      cdpUserId,
    })

  if (isFetching) {
    return (
      <Box
        component="section"
        sx={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr',
          gap: 5,
          height: '500px',
        }}
      >
        <LoadingSkeleton />
        <LoadingSkeleton />
      </Box>
    )
  }

  return (
    <Box
      component="section"
      sx={{
        display: 'grid',
        gridTemplateColumns: '2fr 1fr',
        gap: 5,
      }}
    >
      <Paper
        sx={{
          display: 'grid',
          gridTemplateColumns: '240px 2fr',
          gap: 5,
          p: 5,
          boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box>
          <LineClamp
            sx={{
              fontWeight: 'bold',
              fontSize: '24px',
              mb: 3,
              color: theme => theme.colors.black,
            }}
            maxLine={2}
          >
            {profileData.name || '-'}
          </LineClamp>
          <Box
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: theme => theme.colors.textPrimaryBlue,
            }}
          >
            {profileData.gender || '-'}
          </Box>
        </Box>
        <Box sx={{ display: 'grid', gap: 2.5 }}>
          <ProfileRow
            label={t('userProfile:overview.profile.register_time')}
            value={formatDateTimeDisplay(profileData.registerTime)}
          />
          <ProfileRow
            label={t('userProfile:overview.profile.user_id')}
            value={profileData.appUserId}
          />
          <ProfileRow
            label={t('userProfile:overview.profile.birthday')}
            value={formatDateDisplay(profileData.birthday)}
          />
          <ProfileRow
            label={t('userProfile:overview.profile.address')}
            value={profileData.address}
          />
          <ProfileRow
            label={t('userProfile:overview.profile.mobile_phone')}
            value={profileData.mobilePhone}
          />
          <ProfileRow
            label={t('userProfile:overview.profile.email')}
            value={profileData.email}
          />
        </Box>
      </Paper>
      <Paper sx={{ p: 4, boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)' }}>
        <Box sx={{ mb: 2, fontSize: '16px', fontWeight: 'bold' }}>
          {t('userProfile:overview.profile.note')}
        </Box>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: theme => theme.colors.bgPrimaryGrey,
            color: theme => theme.colors.black,
            p: 2,
            overflowY: 'auto',
            height: '400px',
            wordBreak: 'break-word',
          }}
        >
          {profileData.note || '-'}
        </Paper>
      </Paper>
    </Box>
  )
}

export default SectionProfile
