import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'

import { useAppSelector } from '@shared/lib/hooks/store'
import { CATEGORY, PAGE_MODE, PAGE_ROOT } from '@shared/model/constants/routes'
import DeepBlueGradientButton from '@shared/ui/Dropdown/DeepBlueGradientButton'
import { ConditionalTooltip } from '@shared/ui/tooltips'

const CreateButton = ({ isExceededLimit }: { isExceededLimit: boolean }) => {
  const { t } = useTranslation(['common', 'tag'])

  const maxCount = useAppSelector(state => state.auth.plan.tag.maxCount)

  const isEnableImporting = useAppSelector(
    state => state.auth.plan.tag.local.import
  )

  return (
    <DeepBlueGradientButton buttonText={t('common:create')}>
      <MenuList>
        <ConditionalTooltip
          containerDisplay="block"
          isShow={isExceededLimit}
          placement="left"
          title={t('tag:instruction.upper_limit', {
            maxCount: maxCount,
          })}
        >
          <MenuItem
            component={Link}
            disabled={isExceededLimit}
            to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${PAGE_MODE.create}`}
          >
            {t('tag:create_tag_rule')}
          </MenuItem>
        </ConditionalTooltip>
        <ConditionalTooltip
          containerDisplay="block"
          isShow={isEnableImporting === false}
          placement="left"
          title={t('common:trial_not_support')}
        >
          <MenuItem
            component={Link}
            disabled={!isEnableImporting}
            to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${PAGE_MODE.import}`}
          >
            {t('tag:create_tag_import')}
          </MenuItem>
        </ConditionalTooltip>
      </MenuList>
    </DeepBlueGradientButton>
  )
}

export default CreateButton
