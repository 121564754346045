import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'

import type { FunnelChartData } from '@shared/api/rtkQuery'
import { getLongestSeriesLenIdx, getRatioIndex } from '@shared/ui/charts'
import RangeSlider, { MAX_RANGE_SLIDER_VALUE } from '@shared/ui/RangeSlider'
import {
  ChartSection,
  checkDataExist,
  ControlSection,
  VisibilityList,
} from '@widgets/analytics/event/_shared/ui/EventRuleChart'

import type { FunnelChart } from '../../../model/types'
import LineChart from './LineChart'

type Props = {
  previewData: FunnelChart['trend_chart']
  topN: number
  step: number | string
  colors: string[]
  visibilityFlags: boolean[]
  setVisibilityFlags: Dispatch<SetStateAction<boolean[]>>
}

const LineChartControl = ({
  previewData,
  topN,
  step,
  colors,
  visibilityFlags,
  setVisibilityFlags,
}: Props) => {
  const currStepData = useMemo(() => {
    const d = previewData['chart'][step]
    if (!d) {
      return {
        categories: [],
        series: [],
      }
    }

    return {
      ...d,
      series: d.series.filter((_, idx) => idx < topN),
    }
  }, [previewData, step, topN])
  const [displayRangeData, setDisplayRangeData] =
    useState<FunnelChartData>(currStepData)
  const [rangeValue, setRangeValue] = useState<number[]>([0, 100])

  const debounceUpdateDisplayRangeData = useMemo(
    () =>
      debounce(
        ({
          startValue,
          endValue,
        }: {
          startValue: number
          endValue: number
        }) => {
          // 根據 len 決定 rangeValue 與 categories idx 的比例尺
          const categoriesArrLen = currStepData?.categories?.length || 0
          // 根據 rangeValue 取出擷取過後的 startIdx & endIdx
          const startIdx = getRatioIndex(
            startValue / MAX_RANGE_SLIDER_VALUE,
            categoriesArrLen
          )
          const endIdx = getRatioIndex(
            endValue / MAX_RANGE_SLIDER_VALUE,
            categoriesArrLen
          )

          // 過濾 categories & series
          const newDisplayData = {
            categories: currStepData.categories.slice(startIdx, endIdx + 1),
            series: currStepData.series.map(s => {
              // 這裡可能要先防呆，如果 data engineer 還沒有把 data.length 對齊 categories
              const newData = s.data.slice(startIdx, endIdx + 1)
              const newRawData = s.rawData.slice(startIdx, endIdx + 1)
              const newLostRate = s.lostRate.slice(startIdx, endIdx + 1)
              const newLostNumber = s.lostNumber.slice(startIdx, endIdx + 1)
              return {
                groupName: s.groupName,
                name: s.name,
                data: newData,
                rawData: newRawData,
                lostRate: newLostRate,
                lostNumber: newLostNumber,
              }
            }),
          }

          setDisplayRangeData(newDisplayData)
        },
        300
      ),
    [currStepData]
  )

  const handleDisplayDataRangeChanged = (
    event: Event,
    newRangeValue: number | number[]
  ) => {
    const [startValue, endValue] = newRangeValue as number[]
    debounceUpdateDisplayRangeData({ startValue, endValue })
    setRangeValue([startValue, endValue])
  }

  useEffect(() => {
    if (!currStepData || currStepData.categories.length <= 0) {
      setDisplayRangeData({ categories: [], series: [] })
      setVisibilityFlags([])
      return
    }

    const { maxLen } = getLongestSeriesLenIdx([currStepData])
    const defaultVisibilityFlags = Array(maxLen).fill(true)

    setVisibilityFlags(defaultVisibilityFlags)

    setDisplayRangeData(currStepData)
  }, [currStepData, setVisibilityFlags])

  /*
    最後要放在 chart 上的資料會受到兩個因素控制
    - displayRangeData 決定 x 軸顯示資料的範圍
    - visibilityFlags 決定顯示 grouped 資料的範圍
  */
  const displayData = useMemo(
    () => ({
      categories: displayRangeData.categories,
      series: displayRangeData.series
        .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
        .filter(Boolean) as FunnelChartData['series'],
    }),
    [displayRangeData, visibilityFlags]
  )

  return (
    <>
      <ChartSection>
        <LineChart data={displayData} colors={colors} />

        {currStepData && checkDataExist([currStepData]) && (
          <RangeSlider
            data={[currStepData]}
            rangeValue={rangeValue}
            handleDisplayDataRangeChanged={handleDisplayDataRangeChanged}
          />
        )}
      </ChartSection>

      <ControlSection>
        <VisibilityList
          data={[currStepData]}
          visibilityFlags={visibilityFlags}
          setVisibilityFlags={setVisibilityFlags}
        />
      </ControlSection>
    </>
  )
}

export default LineChartControl
