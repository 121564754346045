import FailedSVG from '@shared/assets/images/icons/icon-failed.svg?react'
import theme from '@theme'

import IconWrapper from './IconWrapper'

// svg 有要改顏色就放 src/assets/images 打包進 bundle
export const FailedIcon = ({
  color = theme.colors.white,
  marginLeft,
}: {
  color?: string
  marginLeft?: number
}) => (
  <IconWrapper marginLeft={marginLeft}>
    <FailedSVG fill={color} />
  </IconWrapper>
)

export default FailedIcon
