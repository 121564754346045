import { useTranslation } from 'react-i18next'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { useDeleteThirdPartyTagMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'

type Props = {
  isOpen: boolean
  tagId?: string
  onClose: () => void
  onFinished: () => void
}

export const TagDeleteConfirmDialog = ({
  isOpen,
  tagId,
  onClose,
  onFinished,
}: Props) => {
  const { t } = useTranslation(['tag'])
  const dispatch = useAppDispatch()
  const [deleteThirdPartyTag, { isLoading: isDeletingTag }] =
    useDeleteThirdPartyTagMutation()

  return (
    <DeleteConfirmDialog
      isOpen={isOpen}
      isLoading={isDeletingTag}
      modalTitle={t('tag:sure_want_to_delete_this_tag')}
      onClose={onClose}
      onConfirm={async () => {
        if (tagId) {
          try {
            await deleteThirdPartyTag(tagId).unwrap()
            onFinished()
          } catch (error) {
            const typedError = formatRTKQueryError(error)

            dispatch(handleApiError(typedError))
          }
        }
      }}
    />
  )
}
