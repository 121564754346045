import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useGetOmnichatGroupListQuery } from '@shared/api/rtkQuery'
import DropdownList from '@shared/ui/Dropdown/NestedList'
import {
  INIT_PAGE,
  LIST_PER_PAGE,
  REACT_FLOW_NO_DRAG,
} from '@widgets/workflow/_shared/nodes/_shared/constants'

import type { OmnichatConfig } from '../../actionSendLineNodeSchema'
import { selectOmnichatGroupListOption } from './selector'

type Props = {
  groupId: OmnichatConfig['groupId']
  integrationId: number
  isEditable: boolean
  isError?: boolean
  setGroupId: (groupId: OmnichatConfig['groupId']) => void
}

export const OmnichatGroupSelect = memo(
  ({
    groupId,
    integrationId,
    isEditable,
    isError = false,
    setGroupId,
  }: Props) => {
    const { t } = useTranslation(['workflow'])
    const [page, setPage] = useState<number>(INIT_PAGE)
    const [searchTxt, setSearchTxt] = useState<string>('')

    const { groupOption, isGroupOptionLoading } = useGetOmnichatGroupListQuery(
      {
        integrationId,
      },
      {
        selectFromResult: selectOmnichatGroupListOption,
      }
    )

    const dropdownListLabel = useMemo(() => {
      const group = groupOption.find(({ value }) => value === groupId)

      return (
        group?.label ?? t('workflow:node_action_line_template_omnichat_robot')
      )
    }, [groupOption, t, groupId])

    const filteredOption = useMemo(
      () =>
        groupOption.filter(({ label }) =>
          label.toLowerCase().includes(searchTxt.toLocaleLowerCase())
        ),
      [groupOption, searchTxt]
    )

    const dropdownGroupOption = useMemo(() => {
      const startIndex = (page - 1) * LIST_PER_PAGE
      const endIndex = startIndex + LIST_PER_PAGE

      return filteredOption.slice(startIndex, endIndex)
    }, [page, filteredOption])

    const handleSearchTitleChange = (searchTxt: string) => {
      setSearchTxt(searchTxt)
      setPage(INIT_PAGE)
    }

    const handleOnGroupChange = ({ value }: { value: string }) =>
      setGroupId(value)

    return (
      <Box width="100%">
        <DropdownList
          isError={isError}
          isFullWidth
          hasSearchBar
          isDisabled={isGroupOptionLoading || !isEditable}
          label={dropdownListLabel}
          onChange={handleOnGroupChange}
          onFilterChange={handleSearchTitleChange}
          options={dropdownGroupOption}
          popperClassName={REACT_FLOW_NO_DRAG}
          popperDisablePortal
          placement="bottom-start"
          value={groupId}
          isShowPagination
          paginationProps={{
            totalCount: filteredOption.length,
            page: page - 1,
            rowsPerPage: LIST_PER_PAGE,
            onPageChange: (_event, page) => setPage(page + 1),
          }}
        />
      </Box>
    )
  }
)

export default OmnichatGroupSelect
