import range from 'lodash/range'

import i18n from '@i18n'
import { getToday } from '@shared/lib/utils/time'
import type { NestedOption as Option } from '@shared/ui/Dropdown/NestedList'

import type { TimeData, TimeType } from '../timeReducer'
export const WEEKDAY: { [name: string]: number } = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const dayOfWeekMap = (): { [name: number]: string } => ({
  [WEEKDAY.SUNDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.sun'),
  [WEEKDAY.MONDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.mon'),
  [WEEKDAY.TUESDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.tue'),
  [WEEKDAY.WEDNESDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.wed'),
  [WEEKDAY.THURSDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.thu'),
  [WEEKDAY.FRIDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.fri'),
  [WEEKDAY.SATURDAY]: i18n.t('workflow:node_timing_delay_unit.weekday.sat'),
})

export const DEFAULT_VALUE = {
  YEAR: new Date().getFullYear(),
  MONTH: 1,
  DAY: 0,
  HOUR: 0,
  MINUTE: 0,
} as const

export const hourOptions: Option[] = range(0, 24).map(x => ({
  label: `${x}`.padStart(2, '0'),
  value: `${x}`,
}))

export const minuteOptions: Option[] = range(0, 60).map(x => ({
  label: `${x}`.padStart(2, '0'),
  value: `${x}`,
}))

export const DEFAULT_DURATION = {
  day: DEFAULT_VALUE.DAY,
  hour: DEFAULT_VALUE.HOUR,
  minute: DEFAULT_VALUE.MINUTE,
}

export const DEFAULT_SPECIFIED_DAY = {
  day: getToday().getDate(),
  month: getToday().getMonth() + 1,
  year: getToday().getFullYear(),
}

export const DEFAULT_AT = {
  hour: DEFAULT_VALUE.HOUR,
  minute: DEFAULT_VALUE.MINUTE,
}

export const DEFAULT_TIMING_DATA: TimeData = {
  duration: { day: 2, hour: 0, minute: 0 },
}

export const INITIAL_TIME_DATA: Record<TimeType, TimeData> = {
  duration: {
    duration: DEFAULT_DURATION,
    specifiedDay: undefined,
    dayOfWeek: undefined,
    at: undefined,
  },
  dayOfWeek: {
    duration: undefined,
    specifiedDay: undefined,
    dayOfWeek: [WEEKDAY.MONDAY],
    at: DEFAULT_AT,
  },
  specifiedDay: {
    duration: undefined,
    specifiedDay: DEFAULT_SPECIFIED_DAY,
    dayOfWeek: undefined,
    at: DEFAULT_AT,
  },
}
