import { createTheme } from '@mui/material/styles'
// ref: https://mui.com/x/react-data-grid/getting-started/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation' // 讓 createTheme 可以支援 MuiDataGrid

const theme = {
  colors: {
    // grey
    sidebarIconBlueGrey: '#89a5c6',
    bgPaleGrey: '#f0f4fb',
    paleGrey: '#f0f7fb',
    paleGreyTwo: '#f0f8fc',
    bgPrimaryGrey: '#f5f7fa',
    lightBlueGrey: '#c7c9cb',
    borderColor: '#dbdfe9',
    blueyGrey: '#94979b',
    raven: '#6c757d',
    brownishGrey65: 'rgba(118, 118, 118, 0.65)',
    brownishGrey: '#5c5c5c',
    brownishGreyTwo: '#717171',
    lightGreyBlue: '#acb5bf',
    lightGreyBlueOne: '#9db4d0',
    lightGreyBlueTwo: '#eef2f6',
    veryLightGrey: '#f2f2f2',
    textPrimaryGrey: '#3e3d3d',
    textSecondaryGrey: '#6f6e6e',
    greyF5F5F5: '#f5f5f5',

    blue: '#0050ce',
    brightBlue: '#005cec',
    chartLightBlue: '#32c5ff',
    deepSkyBlue: '#0081ff',
    deepSkyBlueTwo: '#0082ff',
    darkSkyBlue: '#28b8fc',
    electricBlue: '#0154ff',
    electricBlueTwo: '#014cff',
    vibrantBlue: '#012fff',
    persianBlue: '#1726CF',
    blueTwo: '#0921FA',
    midnightBlue: '#1A237E',
    mediumBlue: '#0011B9',
    freeSpeechBlue: '#3B44AC',
    clearBlue65: 'rgba(45, 124, 255, 0.65)',
    textPrimaryBlue: '#27527e',
    lightNavyBlue: '#30527b',
    cobalt: '#005DA7',
    iceBlue70: 'rgba(233, 239, 244, 0.7)',
    iceBlue: '#f0f9fb',
    waterBlue: '#196ad6',
    bgIceBlue: '#ecf0f5',
    sky: '#7ddaff',
    chartBlue: '#3772ff',
    chartTransparentBlue: 'rgba(55, 114, 255, 0.15)',
    chartAqua: '#21c9e4',
    irisBlue: '#00B0CC',
    robinsEgg: '#75e3f5',
    robinsEggBlue: '#90e4f1',
    robinEggBlueTwo: '#94ecf1',
    azul: '#2e61d9',
    topaz: '#1cabc2',
    greyBlue: '#63c1d0',
    azure: '#2aa7d9',
    azureTwo: '#019fff',
    azureThree: '#018aff',
    azureFour: '#00a8ea',
    clearBlue: '#1f60fd',
    clearBlueTwo: '#2881fa',
    aqua: '#17c1dc',
    brightAqua: '#29dae4',
    brightSkyBlue: '#00bdff',
    deepSkyBlueThree: '#00B6FF',
    brightLightBlueTwo: '#2bc4ff',
    powderBlue: '#aad2ff',
    powderBlueTwo: '#aae5ff',
    aquaTwo: '#27FFFF',
    turquoiseBlue: '#04afcb',
    turquoiseBlueTwo: '#0dc8cb',
    turquoise: '#0ab5ac',
    tealBlue: '#0094ac',
    denim2: '#1b62ac',
    bondiBlue: '#0081C3',
    sapphire: '#2C55AA',
    jellyBean: '#2C8DAA',
    lochmara: '#048ABE',
    spray: '#71F0D7',
    onahau: '#BEF3FF',
    lightSeaGreen: '#0DBAA0',
    tagBackground: '#f1f4fa',

    pastelGreen: '#65DB61',
    islamicGreen: '#00B40D',
    darkPastelGreen: '#00CB39',
    conifer: '#AAF55F',
    malachite: '#1BFD5B',
    bilbao: '#438600',
    lawnGreen: '#70DF00',

    // yellow
    chartMaize: '#ffcd42',
    wheat: '#ffd87e',
    goldenYellow: '#FFE603',
    macaroniAndCheese: '#d9ae38',
    tan: '#d9b76b',
    wattle: '#D2CC38',
    brightSun: '#F7DF3B',
    texasRose: '#FFAB52',
    pumpkin: '#FF810D',
    internationalOrange: '#F75209',

    // red
    chartRed: '#ff614f',
    salmon: '#ff8679',
    tequila: '#F5CCA2',
    wewak: '#FFA2A4',
    salmonTwo: '#FF7A7A',
    darkCoral: '#d95243',
    darkPeach: '#d97266',
    carol: '#fd5d4c',
    watermelon: '#fb3d4e',
    orangeyRed: '#ff3e28',
    razzmatazz: '#F31266',
    chipRed: '#F37272',
    torchRed: '#FF2630',
    radicalRedTwo: '#F3465B',
    guardsmanRed: '#BE040A',
    radicalRed: '#FF3741',
    burntOrange: '#FF6D2D',
    alizarin: '#EA262A',
    wildStrawberry: '#FF2F8A',
    hotMagenta: '#FF01C7',
    neonPink: '#FF6BC4',
    deepPink: '#FF1A7E',
    persianRed: '#C62828',
    jazzberryJam: '#AD1457',
    freeSpeechRed: '#A70909',
    snackbarRed: '#ffbebe',
    red015: 'rgba(255, 97, 79, 0.15)',

    // purple
    chartPeriwinkle: '#847bff',
    darkPeriwinkle: '#7068d9',
    periwinkleBlue: '#a39cff',
    perrywinkle: '#8a84d9',
    softBlue: '#6487d9',
    bilobaFlower: '#B29DE6',
    skyBlue: '#769fff',
    cornflower: '#7268ff',
    fuchsia: '#C452B4',
    fuchsiaTwo: '#C14DB1',
    blueVioletTwo: '#7E41FF',
    blueViolet: '#9D1BEC',
    blueMarguerite: '#7659B1',
    heliotrope: '#C667FF',
    violet: '#110B47',
    hotPurple: '#4B154F',
    purple: '#610068',
    purpleHeart: '#6C00AD',

    // white
    white: '#ffffff',
    whiteTwo: '#f7f7f7',
    whiteThree: '#f9f9f9',
    dodgerBlue5: 'rgba(58, 132, 255, 0.05)',
    veryLightPink: '#d0d0d0',
    veryLightBlue: '#e5e9ee',
    veryLightBlueTwo: '#e8ebee',
    veryLightBlueThree: '#f5f9ff',
    cloudyBlue: '#bfc1c3',
    calendarDisabledGrey: '#f0f0f0',
    nepal: '#98B0C2',
    cloud: '#C3BEB8',

    blackPearl: '#09172F',
    blackPearlTwo: '#061122',
    dark: '#2e384d',
    black: '#000000',
    black5: 'rgba(0, 0, 0, 0.05)',
    black25: 'rgba(0, 0, 0, 0.25)',
    black6: 'rgba(0, 0, 0, 0.06)',
    black425: 'rgba(0, 0, 0, 0.425)',
    black86: 'rgba(0, 0, 0, 0.86)',
    black38: 'rgba(0, 0, 0, 0.38)',
    blackTwo: '#353232',
    textSecondBlue: '#536a82',
    greyishBrown: '#3d3a39',
    cocoaBrown: '#3B2629',
    bakersChocolate: '#643200',
    tuscany: '#AB5D44',
    mangoTango: '#D76B00',
    kabul: '#615D59',
  },
} as const

declare module '@mui/material/styles' {
  interface Theme {
    colors: (typeof theme)['colors']
  }
  interface ThemeOptions {
    colors?: (typeof theme)['colors']
  }
}

export const muiTheme = createTheme({
  colors: theme.colors,
  palette: {
    primary: {
      main: theme.colors.brightBlue,
    },
    secondary: {
      main: theme.colors.textPrimaryBlue,
    },
    text: {
      primary: theme.colors.textPrimaryBlue,
    },
  },
  components: {
    // 覆寫到預設以外的樣式比較不用擔心影響之前透過 styled(MuiButton) 建立的元件
    // 預設：
    // color = default
    // size = medium
    // variant = text
    // ref: https://material-ui.com/zh/api/button/
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        textPrimary: {
          color: theme.colors.brightBlue,
        },
        sizeSmall: {
          padding: '0 8px',
        },
        contained: {
          backgroundColor: theme.colors.bgIceBlue,
          color: theme.colors.textPrimaryBlue,
          '&:hover': {
            backgroundColor: theme.colors.white,
          },
        },
        containedSizeMedium: {
          maxHeight: '36px',
          padding: '8px 12px',
        },
        containedPrimary: {
          backgroundColor: theme.colors.brightBlue,
          color: theme.colors.white,
          '&:hover': {
            backgroundColor: theme.colors.blue,
          },
        },
        containedError: {
          backgroundColor: theme.colors.orangeyRed,
          color: theme.colors.white,
          '&:hover': {
            backgroundColor: theme.colors.chartRed,
          },
        },
        outlinedPrimary: {
          color: theme.colors.brightBlue,
        },
        outlinedSecondary: {
          border: `1px solid ${theme.colors.textPrimaryBlue}`,
          '&:hover': {
            backgroundColor: theme.colors.textPrimaryBlue,
            color: theme.colors.white,
          },
        },
        iconSizeMedium: {
          marginRight: '4px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: theme.colors.snackbarRed,
        },
        barColorSecondary: {
          backgroundColor: theme.colors.chartRed,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: theme.colors.textSecondBlue,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          color: theme.colors.textSecondBlue,
          fontSize: '13px',
          fontWeight: 600,
          letterSpacing: '0.45px',

          /* .MuiMenuItem-root 設定是 line-height: 1.5
          會讓 MenuItem 高變成 43
          設回 line-height: normal; MenuItem 高變成 42 */
          lineHeight: 'normal',

          ':hover': {
            backgroundColor: theme.colors.veryLightBlueThree,
            color: theme.colors.brightBlue,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          color: theme.colors.textSecondBlue,
          fontWeight: 600,
          letterSpacing: '0.45px',
          fontSize: '13px',
          lineHeight: '18px',

          '&.Mui-focused': {
            backgroundColor: `${theme.colors.veryLightBlueThree} !important`,
            color: theme.colors.brightBlue,

            '&.Mui-focusVisible': {
              backgroundColor: theme.colors.veryLightBlueThree,
              color: theme.colors.brightBlue,
            },
          },
        },
        paper: {
          background: theme.colors.white,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.167504)',
          borderRadius: ' 2px',
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px',
        },
        li: {
          marginRight: '8px',
          height: '44px',
          lineHeight: '44px',
          fontSize: '24px',
          fontWeight: 500,
          letterSpacing: '0.17px',
          color: theme.colors.lightNavyBlue,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          maxWidth: '250px',
          whiteSpace: 'nowrap',

          '*': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '250px',
            whiteSpace: 'nowrap',
          },

          a: {
            cursor: 'pointer',
            color: theme.colors.brightBlue,
            paddingBottom: '6px',
            borderBottom: `2px solid ${theme.colors.brightBlue}`,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          // 此設定幫助 Radio 的 FormControlLabel 在預設樣式使用較小的字級呈現
          fontSize: '14px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 9px 0 rgba(0, 0, 0, 0.05)',
          border: 'none',

          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.colors.white,
          },

          '.MuiDataGrid-columnHeader': {
            // columnHeader 不支援 width: 'auto'，所以要用 !important 覆寫
            width: 'auto !important',
            maxWidth: 'none !important',

            '&:first-of-type': {
              paddingLeft: '20px',
            },

            '&:focus': {
              outline: 'none',
            },

            '&:focus-within': {
              outline: 'none',
            },
          },
          '.MuiDataGrid-columnHeaderTitleContainer': {
            fontWeight: '500',
            letterSpacing: '1.25px',
            color: theme.colors.blueyGrey,
          },
          '.MuiDataGrid-columnHeader--sorted': {
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              color: theme.colors.textPrimaryBlue,
            },
          },
          '.MuiDataGrid-cell': {
            padding: '16px 16px 16px 16px',

            '&:first-of-type': {
              paddingLeft: '20px',
            },
          },

          '.MuiDataGrid-row': {
            backgroundColor: theme.colors.white,

            '&:hover': {
              backgroundColor: theme.colors.white,
            },
          },
          '.MuiDataGrid-row--transparent': {
            backgroundColor: 'transparent',

            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
          '.MuiDataGrid-row--boldFont': {
            fontWeight: '600',
          },
          'MuiDataGrid-row--blackColor': {
            color: theme.colors.black,
          },

          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: `"Roboto", "PingFang", "Microsoft JhengHei", "Helvetica", "Arial", sans-serif`,
  },
})

type BaseTheme = typeof theme

// https://stackoverflow.com/questions/50044023/get-union-type-from-indexed-object-values
export type ThemeColor = (typeof theme.colors)[keyof typeof theme.colors]

declare module '@emotion/react' {
  export interface Theme extends BaseTheme {}
}

export default theme
