import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { ThirdPartyTagList } from '@shared/api/rtkQuery'
import { formatNumber, getIntSafe } from '@shared/lib/utils/number'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

type Props = {
  openActionMenuId: string | undefined
  setOpenActionMenuId: React.Dispatch<React.SetStateAction<string | undefined>>
  setIsOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  isOpenDeleteDialog: boolean
  setIsOpenRenameDialog: React.Dispatch<React.SetStateAction<boolean>>
  isOpenRenameDialog: boolean
  setRenameName: React.Dispatch<React.SetStateAction<string>>
}

const useTagColumns = ({
  openActionMenuId,
  setOpenActionMenuId,
  setIsOpenDeleteDialog,
  isOpenDeleteDialog,
  setIsOpenRenameDialog,
  isOpenRenameDialog,
  setRenameName,
}: Props) => {
  const { t } = useTranslation(['tag', 'common'])

  const columns: TableColumn<ThirdPartyTagList['items'][number]>[] = useMemo(
    () => [
      {
        field: 'updatable',
        headerName: '',
        valueFormatter: updatable => {
          if (typeof updatable !== 'undefined' && !updatable) {
            return (
              <Tooltip title={t('tag:third_party_has_been_deleted_error')}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={theme => ({
                    color: theme.colors.chartRed,
                  })}
                >
                  <Icon
                    color="inherit"
                    fontSize="small"
                    icon={ICON.exclamationTriangle}
                  />
                </Box>
              </Tooltip>
            )
          }

          return null
        },
      },
      {
        field: 'name',
        headerName: 'tag_name',
      },
      {
        field: 'appType',
        headerName: 'tag_source',
        valueFormatter: appType => String(appType || '-'),
      },
      {
        field: 'userCount',
        headerName: 'people_amount',
        align: 'right',
        valueFormatter: count => {
          if (typeof count === 'number') {
            return formatNumber(count)
          }
          return '-'
        },
      },
      {
        field: 'createdTime',
        headerName: 'created_time',

        valueFormatter: time => formatDateTimeDisplay(time as string),
      },
      {
        field: 'creator',
        headerName: 'creator',
      },
      {
        field: 'currentTagSyncAt',
        headerName: 'last_update_time',
        valueFormatter: time => formatDateTimeDisplay(time as string),
      },
      {
        field: 'id',
        colRatio: 0.5,
        headerName: '',
        paddingTop: 0.5,
        paddingBottom: 0.5,
        valueFormatter: (_, { id, name }) => {
          const currId = String(getIntSafe(id))

          return (
            <Dropdown
              isOpen={openActionMenuId === currId}
              placement="left-start"
              setIsOpen={() => {
                if (isOpenDeleteDialog || isOpenRenameDialog) {
                  setOpenActionMenuId(openActionMenuId)
                  return
                }

                setOpenActionMenuId(undefined)
              }}
              anchorElem={
                <IconButton
                  onClick={event => {
                    event.stopPropagation()

                    setOpenActionMenuId(currId)
                  }}
                >
                  <Icon
                    icon={ICON.ellipsisV}
                    color={theme.colors.textPrimaryBlue}
                    fontSize="small"
                  />
                </IconButton>
              }
            >
              <MenuList
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation()
                }}
              >
                <Link
                  to={`/${CATEGORY.cdm}/${
                    PAGE_ROOT.tags
                  }/${TagSourceRoutesMap.getRoute('thirdParty')}/${id}`}
                >
                  <MenuItem>{t('tag:tag_detail')}</MenuItem>
                </Link>
                <MenuItem
                  onClick={() => {
                    setIsOpenRenameDialog(true)
                    setRenameName(name)
                  }}
                >
                  {t('common:rename')}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setIsOpenDeleteDialog(true)
                  }}
                >
                  {t('common:delete')}
                </MenuItem>
              </MenuList>
            </Dropdown>
          )
        },
      },
    ],
    [
      openActionMenuId,
      t,
      isOpenDeleteDialog,
      isOpenRenameDialog,
      setOpenActionMenuId,
      setIsOpenRenameDialog,
      setRenameName,
      setIsOpenDeleteDialog,
    ]
  )

  return columns
}

export default useTagColumns
