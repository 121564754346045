import { api, transformResponseCamelCaseKeys } from '../api'
import { GetGoogleAccessTokenParams, GoogleOAuthCredential } from './type'

const oauth = api.injectEndpoints({
  endpoints: builder => ({
    getGoogleOAuth: builder.query<
      GoogleOAuthCredential,
      GetGoogleAccessTokenParams
    >({
      query: params => ({
        url: '/google_oauth/token',
        method: 'POST',
        body: params,
      }),
      transformResponse: res =>
        transformResponseCamelCaseKeys(res as GoogleOAuthCredential),
    }),
  }),
  overrideExisting: false,
})

export const { useLazyGetGoogleOAuthQuery } = oauth
