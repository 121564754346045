import cloneDeep from 'lodash/cloneDeep'
import curry from 'lodash/fp/curry'

import { URL_WITH_UTM_REGEX } from '@entities/mediumTemplates'
import type { ReplacementData } from '@shared/api/rtkQuery'
import { ACTION_URL_WORD } from '@shared/model/constants/contentTemplate'

import mapUrlParams from './mapUrlParams'

export const fetchActionUrl = curry(
  (html: string, replacementData: ReplacementData) => {
    const replacementDataCloned = cloneDeep(replacementData)

    let count = 1
    const newHtml = html.replace(URL_WITH_UTM_REGEX, (_match, p1) => {
      const key = `${ACTION_URL_WORD}_${count}`
      const [host, query] = p1.split(':borderBetweenHostAndUtm:')

      replacementDataCloned[key] = {
        type: ACTION_URL_WORD,
        urlParams: mapUrlParams(host, query || ''),
      }
      count++
      return `{{${key}}}`
    })

    return {
      html: newHtml,
      replacementData: replacementDataCloned,
    }
  }
)

export default fetchActionUrl
