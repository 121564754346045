import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme, { ThemeColor } from '@theme'

import BlueBorderButton from './BlueBorderButton'

type AddButtonProps = {
  bgColor?: string
  color?: ThemeColor
  disabled?: boolean
  label: string
  onClick?: () => void
  size?: 'medium' | 'small'
}

export const AddButton = ({
  bgColor,
  color,
  disabled,
  label,
  onClick,
  size = 'medium',
}: AddButtonProps) => (
  <BlueBorderButton
    disabled={disabled}
    bgColor={bgColor}
    color={color}
    size={size}
    onClick={() => {
      if (!disabled && onClick) {
        onClick()
      }
    }}
  >
    <Box my={0.5} display="flex" alignItems="center">
      <Icon
        icon={ICON.plusCircle}
        color={
          disabled
            ? theme.colors.lightGreyBlue
            : color || theme.colors.brightBlue
        }
        fontSize="small"
      />
      <Box component="span" mx={1}>
        {label}
      </Box>
    </Box>
  </BlueBorderButton>
)

export default AddButton
