import type {
  TimeData,
  TimeType,
} from '@widgets/workflow/_shared/nodes/_shared/Timing/timeReducer'

export const getTimeNodeType = (data: TimeData = {}): TimeType => {
  if (data?.duration) {
    return 'duration'
  }

  if (data?.specifiedDay) {
    return 'specifiedDay'
  }

  if (data?.dayOfWeek) {
    return 'dayOfWeek'
  }

  return 'duration'
}

export default getTimeNodeType
