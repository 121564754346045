import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import ImportTagFile, {
  ImportButtonClickPayload,
} from '@entities/ImportFile/ImportTagFile'
import type { CreateIngestionPayload } from '@features/infoBox/model/taskSlice'
import {
  createIngestion,
  resetCurrIngestionId,
  selectCurrIngestion,
} from '@features/infoBox/model/taskSlice'
import {
  TAG_TYPE_OFFLINE,
  useCreateBehaviorTagMutation,
  useCreateTagMutation,
  usePatchBehaviorTagMutation,
  useUpdateTagMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import Card from '@shared/ui/Card'
import SectionTitle from '@shared/ui/SectionTitle'
import TagInfo, { TagInfoValues } from '@widgets/tag/_shared/components/TagInfo'
import TagTypeRadioGroup from '@widgets/tag/_shared/components/TagType'
import { TagSourceRoutesMap } from '@widgets/tag/_shared/constants'

import useHandleApiError from '../hooks/useHandleApiError'
import type { TagSourceType } from '../type'

type ImportTagSourceType = Extract<TagSourceType, 'grading' | 'behavior'>

export const TagImport = () => {
  const currIngestion = useAppSelector(selectCurrIngestion)
  const history = useHistory()
  const { t } = useTranslation(['tag', 'common'])
  const [importTagType, setImportTagType] =
    useState<ImportTagSourceType>('grading')

  const [currentTagId, setCurrentTagId] = useState<number>()

  const [tagInfo, setTagInfo] = useState<TagInfoValues<ImportTagSourceType>>({
    title: '',
    description: '',
    schedulingType: 'none',
    type: TAG_TYPE_OFFLINE,
    groupId: 0,
  })

  const [updateTag] = useUpdateTagMutation()
  const [createTag, { isLoading: isTagCreating }] = useCreateTagMutation()
  const [updateBehaviorTagUser] = usePatchBehaviorTagMutation()
  const [createBehaviorTagUser, { isLoading: isBehaviorTagCreating }] =
    useCreateBehaviorTagMutation()

  const dispatch = useAppDispatch()
  const [handleTagApiError] = useHandleApiError(importTagType)

  const handleImportButtonClick = async ({
    description,
    file,
    action,
  }: ImportButtonClickPayload) => {
    if (!tagInfo.title) {
      dispatch(
        openToast({
          message: t('tag:please_enter_tag_name'),
          status: 'error',
        })
      )

      return
    }

    try {
      if (importTagType === 'grading' && !currentTagId) {
        const { id } = await createTag({
          title: tagInfo.title,
          description: tagInfo.description,
          groupId: tagInfo.groupId,
          schedulingType: 'none',
          type: 'offline',
          tagRules: [],
        }).unwrap()

        setCurrentTagId(id)

        const payload: CreateIngestionPayload<'tag_user'> = {
          createParams: {
            resourceId: id,
            resourceType: 'tag_user',
            description,
          },
          resourceType: 'tag_user',
          file,
        }
        dispatch(createIngestion(payload))

        return
      }

      if (importTagType === 'grading' && currentTagId) {
        await updateTag({ ...tagInfo, tagRules: [], id: currentTagId }).unwrap()
        const payload: CreateIngestionPayload<'tag_user'> = {
          createParams: {
            resourceId: currentTagId,
            resourceType: 'tag_user',
            description,
          },
          resourceType: 'tag_user',
          file,
        }
        dispatch(createIngestion(payload))

        return
      }

      if (importTagType === 'behavior' && !currentTagId) {
        const { id } = await createBehaviorTagUser({
          title: tagInfo.title,
          description: tagInfo.description,
          groupId: tagInfo.groupId,
          schedulingType: 'none',
          dataSourceType: 'import',
        }).unwrap()

        setCurrentTagId(id)

        const payload: CreateIngestionPayload<'behavior_tag_user'> = {
          createParams: {
            tagId: id,
            description,
            action,
          },
          file,
          resourceType: 'behavior_tag_user',
        }

        dispatch(createIngestion(payload))

        return
      }

      if (importTagType === 'behavior' && currentTagId) {
        await updateBehaviorTagUser({
          title: tagInfo.title,
          description: tagInfo.description,
          id: currentTagId,
          groupId: tagInfo.groupId,
        }).unwrap()

        const payload: CreateIngestionPayload<'behavior_tag_user'> = {
          createParams: {
            tagId: currentTagId,
            description,
            action,
          },
          file,
          resourceType: 'behavior_tag_user',
        }

        dispatch(createIngestion(payload))

        return
      }
    } catch (e) {
      handleTagApiError(e)
    }
  }

  const handleTagUpdate = async () => {
    try {
      if (importTagType === 'grading') {
        await updateTag({ ...tagInfo, tagRules: [], id: currentTagId })
      }

      if (importTagType === 'behavior') {
        await updateBehaviorTagUser({
          title: tagInfo.title,
          description: tagInfo.description,
          id: currentTagId,
          groupId: tagInfo.groupId,
        })
      }
    } catch (error) {
      throw error
    }
  }

  const handleImportTagTypeChange = (type: TagSourceType) => {
    setCurrentTagId(undefined)
    setImportTagType(type as ImportTagSourceType)
  }

  useEffect(() => {
    return () => {
      dispatch(resetCurrIngestionId())
    }
  }, [dispatch])

  useEffect(() => {
    if (currIngestion?.status === 'succeeded') {
      history.push(
        `/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${TagSourceRoutesMap.getValue(
          importTagType
        )}`
      )
    }
  }, [currIngestion?.status, history, importTagType])

  const isCreating = isTagCreating || isBehaviorTagCreating

  return (
    <>
      <Breadcrumbs>
        <Link
          to={`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${TagSourceRoutesMap.getRoute(
            importTagType
          )}`}
        >
          {t('common:route.tag_list')}
        </Link>
        <Box>{t('tag:tag_import')}</Box>
      </Breadcrumbs>

      <SectionTitle title={t('tag:create_step_1_select_tag_type')} />

      <TagTypeRadioGroup
        tagTypes={[
          {
            tagSourceType: 'grading',
            isEnable: !currentTagId && !isCreating,
          },
          {
            tagSourceType: 'behavior',
            isEnable: !currentTagId && !isCreating,
          },
        ]}
        value={importTagType}
        onChange={handleImportTagTypeChange}
      />

      <SectionTitle title={t('tag:create_step_2_fill_tag_information')} />

      <TagInfo
        values={tagInfo}
        onChange={setTagInfo}
        onUpdate={handleTagUpdate}
      />

      <SectionTitle title={t('tag:create_step_3_select_tag_source_import')} />

      <Card mb={5}>
        <ImportTagFile
          key={importTagType}
          defaultResourceActionType={
            importTagType === 'grading'
              ? 'tag_user.insert'
              : 'behavior_tag_user.upsert'
          }
          tagResourceActionType={
            importTagType === 'grading'
              ? {
                  tag_user: ['insert'],
                }
              : {
                  behavior_tag_user: ['upsert'],
                }
          }
          onImportButtonClick={handleImportButtonClick}
          buttonWord={t('tag:create_tag_and_import_data')}
        />
      </Card>
    </>
  )
}

export default TagImport
