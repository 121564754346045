import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'

import AttributeFilter from '@features/filters/AttributeFilter'
import type { UserProfileAndTagFilterNode } from '@shared/api/rtkQuery'
import {
  useCategoriesOfMetadata,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { ICON } from '@shared/model/constants/styles'
import { AddButton } from '@shared/ui/buttons'
import { UniIcon as Icon } from '@shared/ui/icons'
import { ConditionalTooltip } from '@shared/ui/tooltips'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import {
  AUDIENCE_FILTER_EVENT_MAX_COUNT,
  AudienceFilterListItem,
  AudienceFilterListItemContainer,
  AudienceFilterListItemHeader,
  AudienceFilterNestedListItem,
  AudienceFilterNestedListItemContainer,
  FilterCombinator,
} from '../_shared'
import { createInitialState } from './userProfileAndTagReducer'
import type { UserProfileAndTagAction } from './userProfileAndTagReducer.type'

type Props = {
  state?: UserProfileAndTagFilterNode
  dispatch: (action: UserProfileAndTagAction) => void
  isDisabled: boolean
}

const UserProfileAndTagFilter = ({
  state: { node: filter } = createInitialState(),
  dispatch,
  isDisabled = !dispatch,
}: Props) => {
  const { t } = useTranslation(['audience'])

  const { userProfileEntity, isLoading, isErrorUserProfileEntity } =
    useMetadataQuery()
  const categoriesOfMetadata = useCategoriesOfMetadata()

  if (isLoading) {
    return <CircularProgress />
  }

  if (isErrorUserProfileEntity) {
    return null
  }

  return (
    <>
      {filter.nodes.length > 0 && (
        <FilterCombinator
          combination={filter.combination}
          isDisabled={isDisabled}
          onChange={() => {
            dispatch({ type: 'toggleAudienceFilterCombination' })
          }}
        />
      )}

      <ul>
        {filter.nodes.map((item, index) => (
          <AudienceFilterListItem
            key={`user-profile-tag-filter-${index}`}
            data-combination={filter.combination.toUpperCase()}
          >
            {item.type === 'group' && item.nodes.length > 1 && (
              <AudienceFilterListItemHeader>
                <FilterCombinator
                  combination={item.combination}
                  onChange={() => {
                    dispatch({
                      type: 'toggleAudienceFilterGroupCombination',
                      payload: index,
                    })
                  }}
                />
              </AudienceFilterListItemHeader>
            )}

            <AudienceFilterListItemContainer>
              <ul>
                {item.nodes.map((group, nestedIndex) => (
                  <AudienceFilterNestedListItem
                    key={`user-profile-tag-filter-${index}-${nestedIndex}`}
                    data-combination={
                      item.nodes.length > 1
                        ? filter.nodes[index].combination.toUpperCase()
                        : ''
                    }
                  >
                    <AudienceFilterNestedListItemContainer py={0.5}>
                      <Box display="flex" alignItems="center">
                        <Box mr={1}>
                          <AttributeFilter
                            key={`user-profile-tag-filter-${index}-${nestedIndex}`}
                            eventName=""
                            categories={categoriesOfMetadata}
                            field={group.field}
                            dimOperator={group.dimOperator}
                            operator={group.operator}
                            params={group.params}
                            source={group.source}
                            bgColor={theme.colors.white}
                            isDisabled={isDisabled}
                            onChange={currValue => {
                              dispatch({
                                type: 'updateNestedCondition',
                                payload: {
                                  parentIndex: index,
                                  nestedIndex,
                                  value: currValue,
                                },
                              })
                            }}
                          />
                        </Box>
                        {!isDisabled && (
                          <Tooltip title={t('audience:v3.filter_delete')}>
                            <IconButton
                              onClick={() => {
                                dispatch({
                                  type: 'removeNestedCondition',
                                  payload: { parentIndex: index, nestedIndex },
                                })
                              }}
                            >
                              <Icon icon={ICON.times} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </AudienceFilterNestedListItemContainer>
                  </AudienceFilterNestedListItem>
                ))}
              </ul>

              {!isDisabled && (
                <ConditionalTooltip
                  title={t('audience:v3.filter_max_hint_user_profile_tags', {
                    max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
                  })}
                  isShow={item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
                >
                  <AddButton
                    label={t('audience:add')}
                    size="small"
                    color={theme.colors.textPrimaryBlue}
                    disabled={
                      item.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT
                    }
                    onClick={() => {
                      const [firstUserPropertyId] = userProfileEntity.ids
                      const {
                        dataType,
                        tableName: source,
                        repeated,
                      } = userProfileEntity.entities[firstUserPropertyId]

                      dispatch({
                        type: 'addNestedCondition',
                        payload: {
                          index,
                          source,
                          field: firstUserPropertyId,
                          dataType,
                          repeated,
                        },
                      })
                    }}
                  />
                </ConditionalTooltip>
              )}
            </AudienceFilterListItemContainer>
          </AudienceFilterListItem>
        ))}
      </ul>
      {!isDisabled && (
        <ConditionalTooltip
          title={t('audience:v3.filter_max_hint_user_profile_tags', {
            max: AUDIENCE_FILTER_EVENT_MAX_COUNT,
          })}
          isShow={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
        >
          <AddButton
            label={t('audience:add')}
            size="small"
            disabled={filter.nodes.length >= AUDIENCE_FILTER_EVENT_MAX_COUNT}
            onClick={() => {
              const [firstUserPropertyId] = userProfileEntity.ids

              const {
                dataType,
                tableName: source,
                repeated,
              } = userProfileEntity.entities[firstUserPropertyId]

              dispatch({
                type: 'addAudienceFilterGroupItem',
                payload: {
                  source,
                  field: firstUserPropertyId,
                  dataType,
                  repeated,
                },
              })
            }}
          />
        </ConditionalTooltip>
      )}
    </>
  )
}

export default UserProfileAndTagFilter
