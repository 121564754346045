import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useCreateFunnelRuleMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import { AnalyticsFormBreadcrumbs as FunnelFormBreadcrumbs } from '@widgets/analytics/_shared'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import { FunnelForm, FunnelRuleFormValues } from '../_shared'

const FunnelCreate = () => {
  const [createFunnelRule, { isLoading: isCreatingFunnelRule }] =
    useCreateFunnelRuleMutation()

  const { handleError } = useErrorHandler({ type: 'funnel' })

  const { t } = useTranslation(['analytics', 'common'])
  const dispatch = useAppDispatch()

  return (
    <FunnelForm
      header={({
        isExportingCSV,
        isExportDisabled,
        isSaveDisabled,
        onReset: handleReset,
        onExport: handleExport,
        onShowConfirmDialog: handleShowConfirmDialog,
      }) => (
        <FunnelFormBreadcrumbs
          isExportDisabled={isExportDisabled}
          isExportingCSV={isExportingCSV}
          isSaveDisabled={isSaveDisabled}
          onExport={handleExport}
          onReset={handleReset}
          onShowConfirmDialog={handleShowConfirmDialog}
        >
          <Link to={`/${CATEGORY.analytics}/${PAGE_ROOT.funnel}/new`}>
            {t('common:route.analytics_funnel')}
          </Link>

          <Box>{t('common:create')}</Box>
        </FunnelFormBreadcrumbs>
      )}
      isSubmitting={isCreatingFunnelRule}
      onSubmit={async (data: FunnelRuleFormValues) => {
        try {
          await createFunnelRule(data).unwrap()

          dispatch(
            openToast({
              message: t('analytics:event.save_success'),
            })
          )
        } catch (e) {
          handleError(e)
        }
      }}
    />
  )
}

export default FunnelCreate
