import styled from '@emotion/styled'

export const Title = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`

export const Description = styled.div`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.textSecondBlue};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: pre-line;
`

export const Notice = styled.div`
  color: ${({ theme }) => theme.colors.brightBlue};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`
