import {
  CheckboxField,
  FileUploadField,
  SelectField,
  TextField,
} from '../fields'
import type { FieldComponentType } from './types'

export const FIELD_COMPONENT_MAP: Record<FieldComponentType, React.FC> = {
  CheckboxField,
  FileUploadField,
  SelectField,
  TextField,
}
