import * as yup from 'yup'

import type { Design } from '@entities/mediumTemplates'
import type { ReplacementData } from '@shared/api/rtkQuery'
import type { TimeData } from '@widgets/workflow/_shared/nodes/_shared/Timing/timeReducer'

import { CAMPAIGN_NAME_MAX_LENGTH } from '../_shared/constants'

export type ActionSendEmailNodeData = {
  campaignName?: string
  content?: string
  editorJson?: Design
  preheader?: string
  previewUrl?: string | null
  replacementData?: ReplacementData
  title?: string
  reactionTiming?: TimeData
  msgChannelId?: number
  msgSenderId?: number | null
}

export const actionSendEmailNodeSchema = yup.object<ActionSendEmailNodeData>({
  campaignName: yup.string().max(CAMPAIGN_NAME_MAX_LENGTH).required(),
  content: yup.string(),
  editorJson: yup.mixed<Design>(),
  preheader: yup.string().max(CAMPAIGN_NAME_MAX_LENGTH).required(),
  previewUrl: yup.string().optional().nullable(),
  replacementData: yup.mixed<ReplacementData>(),
  title: yup.string().max(CAMPAIGN_NAME_MAX_LENGTH).required(),
  reactionTiming: yup.mixed<TimeData>(),
  msgChannelId: yup.number().required(),
  msgSenderId: yup.number().required(),
})
