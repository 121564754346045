import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import {
  EventRule,
  EventRuleGroup,
  useDeleteEventRuleMutation,
  useGetEventRuleSummaryQuery,
  useUpdateEventRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import { EventChartType, getDashboardIndicatorList } from '@shared/ui/charts'
import IndicatorList from '@shared/ui/charts/_shared/IndicatorList'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import { NestedMenu } from '@shared/ui/menu'
import theme from '@theme'
import {
  getChartDataDisplayList,
  useChartTitleList,
} from '@widgets/analytics/event/_shared'

import Error from '../_shared/Error'
import { Card, CircularProgress, Header, Title } from '../_shared/Style'
import { LoadingWrapper } from '../_shared/Style'
import ChartControl from './ChartControl'
import Duration from './Duration'

type Props = {
  eventId: number
  title: string
  chartType: EventChartType
  metric: EventRule['dashboardConfig']['displayVisibility']
  filters: EventRule['filters']
  groupList?: EventRuleGroup[]
  updateList?: (eventId: number) => void
}

const EventChart = ({
  eventId,
  title,
  chartType,
  metric,
  filters,
  groupList = [],
  updateList,
}: Props) => {
  const { t } = useTranslation(['common', 'dateAndChart', 'dashboard'])
  const [filter, filterCompared] = filters
  const [isOpenActionPopup, setIsOpenActionPopup] = useState(false)
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const dispatch = useAppDispatch()

  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true,
  })

  const {
    data: previewData = [],
    isFetching: isFetchingPreviewData,
    error: previewDataError,
  } = useGetEventRuleSummaryQuery(filter, {
    skip: !inView,
  })

  const {
    data: previewDataCompared,
    isFetching: isFetchingPreviewDataCompared,
    error: previewDataComparedError,
  } = useGetEventRuleSummaryQuery(filterCompared, {
    skip: filterCompared === undefined || !inView,
  })

  const [deleteEventRule, { isLoading: isDeletingEventRule }] =
    useDeleteEventRuleMutation()

  const [updateEventRule, { isLoading: isUpdatingEventRule }] =
    useUpdateEventRuleMutation()

  const chartsTitleList = useChartTitleList(filter.statisticMeasures)

  const chartDataList = getChartDataDisplayList(
    previewData,
    previewDataCompared,
    chartsTitleList
  )

  const isPreViewDataGrouping = previewData[0]?.series?.length > 1
  const isPreviewDataMultiEvent = previewData.length > 1

  const hasIndicatorList =
    // Pie Chart 內建篩選器，不需要顯示指標列表
    chartType !== 'pie_chart' &&
    (!isPreViewDataGrouping || !isPreviewDataMultiEvent)

  const indicatorList = hasIndicatorList
    ? getDashboardIndicatorList(chartDataList, metric)
    : []

  const isFetchingChartDataList =
    isFetchingPreviewData || isFetchingPreviewDataCompared

  const chartDataListError = previewDataError || previewDataComparedError

  const handleGroupListMenuItemClick = (group: EventRuleGroup) => {
    updateEventRule({
      title,
      dashboardConfig: { chartType, displayVisibility: metric },
      filters,
      id: eventId,
      groupId: group.id,
    })
      .unwrap()
      .then(() => updateList?.(eventId))
      .catch(() => {
        dispatch(
          openToast({
            message: t('dashboard:error.update_group_error'),
            status: 'error',
          })
        )
      })
  }

  return (
    <Card ref={ref}>
      <Header>
        <Title>{title}</Title>

        <Dropdown
          anchorElem={
            <IconButton>
              <Icon
                icon={ICON.ellipsisV}
                fontSize="small"
                color={theme.colors.textPrimaryBlue}
              />
            </IconButton>
          }
          isOpen={isOpenActionPopup}
          setIsOpen={setIsOpenActionPopup}
          placement="bottom-end"
        >
          <MenuList>
            <Link
              to={`/${CATEGORY.analytics}/${PAGE_ROOT.events}/${eventId}/edit`}
            >
              <MenuItem>{t('common:edit')}</MenuItem>
            </Link>

            {groupList.length > 0 && (
              <NestedMenu label={t('common:group')}>
                <Box maxWidth={600}>
                  {groupList.map(({ id, name, disabled }) => (
                    <MenuItem
                      disabled={disabled}
                      key={`${name}_${id}`}
                      onClick={() => handleGroupListMenuItemClick({ id, name })}
                    >
                      <Box
                        width="100%"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {name}
                      </Box>
                    </MenuItem>
                  ))}
                </Box>
              </NestedMenu>
            )}

            <MenuItem
              onClick={() => {
                setIsOpenActionPopup(false)
                setIsShowDeleteDialog(true)
              }}
            >
              {t('common:delete')}
            </MenuItem>
          </MenuList>
        </Dropdown>
      </Header>

      <Duration
        isHideLatestDate={!metric.latest}
        isMultiEvents={isPreviewDataMultiEvent}
        isGroupingData={isPreViewDataGrouping}
        timeRangeParams={filter.timeRangeParams}
        compareToTimeRangeParams={filterCompared?.timeRangeParams ?? []}
      />

      {(isFetchingChartDataList ||
        isDeletingEventRule ||
        isDeleted ||
        isUpdatingEventRule) && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}

      {!isFetchingChartDataList &&
        !isUpdatingEventRule &&
        chartDataListError && <Error error={chartDataListError} />}

      {!isFetchingChartDataList &&
        !isDeletingEventRule &&
        !isUpdatingEventRule &&
        !isDeleted &&
        !chartDataListError &&
        chartDataList &&
        inView && (
          <>
            {hasIndicatorList && (
              <IndicatorList indicatorList={indicatorList} />
            )}

            <ChartControl
              eventId={eventId}
              chartTitle={title}
              data={chartDataList}
              size={hasIndicatorList ? 'small' : 'medium'}
              defaultChartType={chartType}
              barChartType={metric.type}
            />
          </>
        )}

      <DeleteConfirmDialog
        modalTitle={t('common:delete_header', { name: title })}
        isOpen={isShowDeleteDialog}
        onClose={() => {
          setIsShowDeleteDialog(false)
        }}
        onConfirm={() => {
          deleteEventRule(eventId)
            .unwrap()
            .then(() => {
              setIsDeleted(true)

              if (updateList) {
                updateList(eventId)
              }
            })
          setIsShowDeleteDialog(false)
        }}
      />
    </Card>
  )
}

export default memo(EventChart)
