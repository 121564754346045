import styled from '@emotion/styled'
import { spacing, SpacingProps } from '@mui/system'

export const Label = styled.div<
  {
    isError?: boolean
  } & SpacingProps
>`
  display: flex;
  align-items: center;

  padding: 12px 0;
  min-width: 52px;
  min-height: 44px;

  color: ${({ isError, theme }) =>
    isError ? theme.colors.chartRed : theme.colors.lightNavyBlue};
  font-size: 14px;
  font-weight: 500;

  ${spacing}
`

export const HorizontalLine = styled.div`
  margin-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
`

export const Container = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid ${({ theme }) => theme.colors.brightBlue};
  }
`

export const ListItem = styled.li<{ color?: string }>`
  display: flex;
  align-items: center;
  padding: 10px 52px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black6};
  color: ${({ color }) => color || 'inherit'};

  &:nth-of-type(odd) {
    background-color: ${({ theme }) => theme.colors.white};
  }

  &:nth-of-type(even) {
    background-color: ${({ theme }) => theme.colors.veryLightBlueThree};
  }
`
