import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import {
  TAG_TYPE_INTERNAL,
  TAG_TYPE_INTERNAL_OFFLINE,
  TAG_TYPE_OFFLINE,
  TagSchedulingType,
  TagType,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { InputArea, InputAreaGrid, LabelArea, Text } from '../styles'

export type Props = {
  tagType: TagType
  value: TagSchedulingType
  onChange: (value: TagSchedulingType) => void
  isEdit?: boolean
}

const UpdateMethod = ({ tagType, value, onChange, isEdit = false }: Props) => {
  const { t } = useTranslation(['tag'])

  return (
    <InputAreaGrid>
      <LabelArea>
        <Text>{`${t('tag:update_type')}*`}</Text>
      </LabelArea>

      <InputArea>
        {tagType === TAG_TYPE_INTERNAL && (
          <RadioGroup
            row
            aria-label="tag-type"
            name="tag-type"
            value={value}
            onChange={(_, value) => onChange(value as TagSchedulingType)}
          >
            <FormControlLabel
              value="none"
              control={<Radio color="primary" size="small" />}
              label={<Box fontSize={14}>{t('tag:manual')}</Box>}
              disabled={!isEdit}
            />

            <Tooltip title={t('tag:must_be_manually_restarted_to_update')}>
              <Box m="auto" ml="-12px" mr="24px">
                <Icon
                  icon={ICON.infoCircle}
                  fontSize="small"
                  color={theme.colors.textPrimaryBlue}
                />
              </Box>
            </Tooltip>

            <FormControlLabel
              value="default"
              control={<Radio color="primary" size="small" />}
              label={<Box fontSize={14}>{t('tag:regular')}</Box>}
              disabled={!isEdit}
            />

            <Tooltip title={t('tag:will_automatically_update_at')}>
              <Box m="auto" ml="-12px" mr="12px">
                <Icon
                  icon={ICON.infoCircle}
                  fontSize="small"
                  color={theme.colors.textPrimaryBlue}
                />
              </Box>
            </Tooltip>
          </RadioGroup>
        )}
        {tagType === TAG_TYPE_OFFLINE && (
          <Box
            component="span"
            sx={theme => ({ color: theme.colors.textPrimaryBlue })}
          >
            {t('tag:imported_tag_updated_automatically')}
          </Box>
        )}
        {tagType === TAG_TYPE_INTERNAL_OFFLINE && (
          <Box
            fontSize="14px"
            fontWeight={500}
            sx={theme => ({
              color: theme.colors.lightNavyBlue,
            })}
          >
            {t('tag:third_party_update_period_tutorial')}
          </Box>
        )}
      </InputArea>
    </InputAreaGrid>
  )
}

export default UpdateMethod
