import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import { Subscription, SubscriptionList } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import Dropdown from '@shared/ui/Dropdown'
import { UniIcon as Icon } from '@shared/ui/icons'
import { MenuItem, MenuList } from '@shared/ui/menu'
import type { TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

const NAME_MIN_WIDTH = 140

type Props = {
  openActionMenuId: Subscription['id'] | undefined
  isOpenDeleteDialog: boolean
  isOpenEditModal: boolean
  setOpenActionMenuId: React.Dispatch<
    React.SetStateAction<Subscription['id'] | undefined>
  >
  setIsOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
  setIsOpenEditModal: React.Dispatch<React.SetStateAction<boolean>>
  validateUrl: () => Promise<void>
}

const useColumns = ({
  openActionMenuId,
  isOpenDeleteDialog,
  isOpenEditModal,
  setOpenActionMenuId,
  setIsOpenDeleteDialog,
  setIsOpenEditModal,
  validateUrl,
}: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])

  const columns: TableColumn<SubscriptionList['subscriptions'][number]>[] = [
    {
      colRatio: 0.1,
      field: 'healthStatus',
      headerName: '',
      valueFormatter: value => {
        if (value === 'bad') {
          return (
            <Tooltip
              title={t(
                'contentTemplate:subscription.health_status.end_point_failed'
              )}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={theme => ({
                  color: theme.colors.chartRed,
                })}
              >
                <Icon
                  color="inherit"
                  fontSize="small"
                  icon={ICON.exclamationTriangle}
                />
              </Box>
            </Tooltip>
          )
        }

        return null
      },
    },
    {
      headerName: 'name',
      field: 'name',
      valueFormatter: name => <Box minWidth={NAME_MIN_WIDTH}>{name}</Box>,
    },
    {
      headerName: 'created_time',
      field: 'createdAt',
      valueFormatter: createdAt => formatDateTimeDisplay(createdAt),
    },
    {
      headerName: 'created_by',
      field: 'creator',
    },
    {
      headerName: '',
      field: 'id',
      colRatio: 0.5,
      valueFormatter: id => {
        const handleOpenEditModal: MouseEventHandler<HTMLLIElement> = e => {
          e.stopPropagation()
          setIsOpenEditModal(true)
        }

        const handleOpenDeleteDialog: MouseEventHandler<HTMLLIElement> = e => {
          e.stopPropagation()

          setIsOpenDeleteDialog(true)
        }

        return (
          <Dropdown
            isOpen={openActionMenuId === id}
            setIsOpen={() => {
              if (isOpenDeleteDialog || isOpenEditModal) {
                setOpenActionMenuId(openActionMenuId)
                return
              }

              setOpenActionMenuId(undefined)
            }}
            anchorElem={
              <IconButton
                onClick={event => {
                  event.stopPropagation()

                  setOpenActionMenuId(id)
                }}
              >
                <Icon
                  icon={ICON.ellipsisV}
                  color={theme.colors.textPrimaryBlue}
                  fontSize="small"
                />
              </IconButton>
            }
            placement="left-start"
          >
            <MenuList>
              <MenuItem onClick={handleOpenEditModal}>
                {t('common:edit')}
              </MenuItem>
              <MenuItem onClick={handleOpenDeleteDialog}>
                {t('common:delete')}
              </MenuItem>
              <MenuItem onClick={validateUrl}>{t('common:validate')}</MenuItem>
            </MenuList>
          </Dropdown>
        )
      },
    },
  ]

  return columns
}

export default useColumns
