import { memo, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import MuiButton from '@mui/material/Button'
import type { FormikProps } from 'formik'

import {
  Aggregator,
  EventRuleNode,
  NotificationRuleFormValues,
  OperatorType,
  usePatchNotificationRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import {
  createMetadataPropertyId,
  getCategoriesOfClassification,
  resolveMetadataPropertyId,
  useMetadataQuery,
} from '@shared/lib/utils/metadata'
import { getFloatSafe } from '@shared/lib/utils/number'
import { ICON } from '@shared/model/constants/styles'
import { openToast } from '@shared/model/slices'
import Card from '@shared/ui/Card'
import CategoryList from '@shared/ui/Dropdown/CategoryList'
import DropdownList from '@shared/ui/Dropdown/DropdownList'
import NestedList from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { PrimaryGreyInput as Input } from '@shared/ui/inputs'
import theme from '@theme'
import {
  checkIsPredefinedField,
  useAggregatorOptions,
} from '@widgets/analytics/_shared'
import type { EventRuleFilterAction } from '@widgets/analytics/event/_shared/models/eventRuleFilterReducer'

import { daysBefores, operators } from '../../constant'

type Props = {
  id: number | undefined
  condition: NotificationRuleFormValues['rule']['condition']
  onFieldChange: FormikProps<NotificationRuleFormValues>['setFieldValue']
  reset: (field: string) => void
  eventFilterDispatch: React.Dispatch<EventRuleFilterAction>
  eventFilter: EventRuleNode
  isCreateMode: boolean
}

const NotificationCondition = ({
  id,
  eventFilter,
  eventFilterDispatch,
  condition,
  onFieldChange,
  reset,
  isCreateMode,
}: Props) => {
  const { eventEntity } = useMetadataQuery()
  const { t, i18n } = useTranslation(['analytics', 'common'])

  const operatorOptions = useMemo(() => {
    return operators.map(ele => {
      return {
        label: t(`analytics:notificationRule.operatorOptions.${ele}`),
        value: ele,
      }
    })
  }, [t])

  const daysBeforeOptions = useMemo(() => {
    return daysBefores.map(ele => {
      return {
        label: t(`analytics:notificationRule.daysBeforeOptions.${ele}`),
        value: ele,
      }
    })
  }, [t])

  const dispatch = useAppDispatch()
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [patchNotificationRule, { isLoading: isPatching }] =
    usePatchNotificationRuleMutation()

  const state = eventFilter.statisticMeasures[0]
  const aggregatorOptions = useAggregatorOptions(state.eventName)
  const categoriesOfClassification = useMemo(
    () => getCategoriesOfClassification(eventEntity),
    [eventEntity]
  )

  const handleOnPatch = async () => {
    if (id && !isCreateMode && isEditable) {
      try {
        await patchNotificationRule({
          id,
          filter: eventFilter,
          rule: {
            condition,
          },
        }).unwrap()

        dispatch(
          openToast({
            message: t('common:save_success'),
          })
        )
      } catch (error) {
        dispatch(
          openToast({
            message: t('common:save_failure'),
            status: 'error',
          })
        )
      }
    }

    setIsEditable(false)
  }

  const handleCancel = () => {
    setIsEditable(false)
    reset('rule.condition')
    reset('filter')
  }

  const isEditableMode = (isCreateMode || isEditable) && !isPatching

  return (
    <Card mb={5}>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box display="flex">
          <Box>
            <Box mb={2} component="h2">
              {t('analytics:notificationRule.notification_rule_selection')}
            </Box>
            <Box
              ml={1.25}
              py={0.5}
              px={3}
              sx={theme => ({
                borderLeft: `5px solid ${theme.colors.brightBlue}`,
              })}
            >
              <Box display="inline" mr={2}>
                <CategoryList
                  categories={categoriesOfClassification}
                  selectedValue={state.eventName}
                  isDisabled={!isEditableMode}
                  onValueChanged={targetEventName => {
                    eventFilterDispatch({
                      type: 'updateEventName',
                      payload: {
                        targetIndex: 0,
                        eventName: targetEventName,
                      },
                    })
                  }}
                />
              </Box>
              {state.eventName && (
                <>
                  <Box display="inline" mr={2}>
                    <NestedList
                      options={aggregatorOptions}
                      parentValue={
                        checkIsPredefinedField(state.field)
                          ? undefined
                          : createMetadataPropertyId(state.source, state.field)
                      }
                      value={state.aggregator}
                      hasSearchBar
                      isDisabled={!isEditableMode}
                      onChange={({
                        parentValue: propertyId = '',
                        value: aggregator,
                      }) => {
                        const { source, field } =
                          resolveMetadataPropertyId(propertyId)

                        eventFilterDispatch({
                          type: 'updateAggregator',
                          payload: {
                            aggregator: aggregator as Aggregator,
                            field,
                            source,
                            targetIndex: 0,
                          },
                        })
                      }}
                    />
                  </Box>
                  <Box display="inline" mr={2}>
                    <DropdownList
                      disabled={!isEditableMode}
                      key={`${i18n.language}_${condition.operator}`}
                      defaultOption={operatorOptions.find(
                        option =>
                          option.value === (condition.operator as string)
                      )}
                      options={operatorOptions}
                      onValueChanged={option => {
                        onFieldChange(
                          'rule.condition.operator',
                          option.value as OperatorType
                        )
                      }}
                      bgColor={theme.colors.bgPrimaryGrey}
                    />
                  </Box>
                  <Box display="inline" mr={2}>
                    <Input
                      disabled={!isEditableMode}
                      type="number"
                      value={condition.value}
                      min={0}
                      width={140}
                      marginRightRatio={4}
                      onChange={e => {
                        onFieldChange(
                          'rule.condition.value',
                          getFloatSafe(e.target.value)
                        )
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          {condition.operator !== 'absolute_higher' &&
            condition.operator !== 'absolute_lower' && (
              <Box>
                <Box mb={2} component="h2">
                  {t('analytics:notificationRule.compare_time_period')}
                </Box>
                <Box mb={2} style={{ display: 'flex', alignItems: 'center' }}>
                  <Box mr={1.25}>
                    <Icon
                      icon={ICON.schedule}
                      color={theme.colors.brightBlue}
                    />
                  </Box>

                  <DropdownList
                    key={`${daysBeforeOptions[0].label}_${condition.daysBefore}`}
                    defaultOption={daysBeforeOptions.find(
                      option => +option.value === condition.daysBefore
                    )}
                    disabled={!isEditableMode}
                    options={daysBeforeOptions}
                    onValueChanged={option => {
                      onFieldChange('rule.condition.daysBefore', +option.value)
                    }}
                    bgColor={theme.colors.bgPrimaryGrey}
                  />
                </Box>
              </Box>
            )}
        </Box>

        <Box display="flex" alignItems="flex-start" justifyContent="flex-start">
          {!isCreateMode && !isEditable && (
            <MuiButton
              disabled={isPatching}
              onClick={() => setIsEditable(true)}
              sx={{
                paddingTop: 0,
                '&.MuiButton-root': {
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              {t('common:edit')}
            </MuiButton>
          )}
          {!isCreateMode && isEditable && (
            <>
              <MuiButton
                disabled={isPatching}
                onClick={handleCancel}
                sx={{
                  paddingTop: 0,
                  color: 'inherit',

                  '&.MuiButton-root': {
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              >
                {t('common:cancel')}
              </MuiButton>
              <MuiButton
                disabled={isPatching}
                onClick={handleOnPatch}
                sx={{
                  paddingTop: 0,
                  '&.MuiButton-root': {
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  },
                }}
              >
                {t('common:save')}
              </MuiButton>
            </>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default memo(NotificationCondition)
