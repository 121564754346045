import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  NOTIFICATION_PER_PAGE,
  NotificationType,
} from '@shared/api/axios/notification'

interface State {
  isOpen: boolean
  isAllRead: boolean
  notifications: NotificationType[]
  currentPage: number
  isRefetching: boolean
  isFetchingNext: boolean
  isAlreadyFetchAll: boolean
}

const initialState: State = {
  isOpen: false,
  isAllRead: true,
  notifications: [],
  currentPage: 1,
  isRefetching: false,
  isFetchingNext: false,
  isAlreadyFetchAll: false,
}

const notification = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    toggleIsOpen: state => {
      state.isOpen = !state.isOpen
    },
    setAllRead: state => {
      state.isAllRead = true
      state.notifications = state.notifications.map(data => {
        data.archivedAt = 'done'
        return data
      })
    },
    pollingNotification: state => {
      state.isRefetching = true
    },
    fetchNotificationSuccess: (
      state,
      action: PayloadAction<{ result: NotificationType[]; total: number }>
    ) => {
      state.isRefetching = false
      state.notifications = action.payload.result
      state.currentPage = 1

      if (action.payload.result.length === action.payload.total) {
        state.isAlreadyFetchAll = true
      } else {
        state.isAlreadyFetchAll = false
      }

      if (action.payload.result.some(ele => !ele.archivedAt)) {
        state.isAllRead = false
      } else {
        state.isAllRead = true
      }
    },
    fetchNotificationFailure: (state, action: PayloadAction<void>) => {
      state.isRefetching = false
    },
    fetchNotificationCertainPage: (state, action: PayloadAction<number>) => {
      state.isFetchingNext = true
    },
    fetchNotificationCertainPageSuccess: (
      state,
      action: PayloadAction<{ result: NotificationType[]; total: number }>
    ) => {
      state.isFetchingNext = false

      // 如果 id 銜接不上代表剛好剛全部重新更新過，就不顯示資料了
      // if (
      //   state.notifications.length &&
      //   action.payload.result.length &&
      //   state.notifications[state.notifications.length - 1].id - 1 >
      //     action.payload.result[0].id
      // ) {
      //   state.currentPage = 1
      //   return
      // }

      // 原本那頁有可能是 [13, 14]，但拉資料時已有新資料，所以下一頁是 [14, 15]，不能直接連接要做額外處理
      const index = action.payload.result.findIndex(notification => {
        return (
          notification.id <
          state.notifications[state.notifications.length - 1].id
        )
      })

      state.currentPage = state.currentPage + 1

      if (state.currentPage * NOTIFICATION_PER_PAGE >= action.payload.total) {
        state.isAlreadyFetchAll = true
      }

      if (index > -1) {
        state.notifications = [
          ...state.notifications,
          ...action.payload.result.slice(index),
        ]
      }
    },
  },
})

const { actions, reducer } = notification

export const {
  pollingNotification,
  toggleIsOpen,
  setAllRead,
  fetchNotificationSuccess,
  fetchNotificationFailure,
  fetchNotificationCertainPageSuccess,
  fetchNotificationCertainPage,
} = actions

export type NotificationAction =
  | ReturnType<typeof pollingNotification>
  | ReturnType<typeof toggleIsOpen>
  | ReturnType<typeof setAllRead>
  | ReturnType<typeof fetchNotificationSuccess>
  | ReturnType<typeof fetchNotificationFailure>
  | ReturnType<typeof fetchNotificationCertainPage>
  | ReturnType<typeof fetchNotificationCertainPageSuccess>

export default reducer
