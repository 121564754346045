import { useRef, useState } from 'react'
import Box from '@mui/material/Box'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import { InvisibleInput, Outer, Tag } from './styles'

type Props = {
  disabled?: boolean
  helperText?: string
  maxCount?: number
  onChange: (values: string[]) => void
  onInputChange?: (text: string) => void
  onValidate?: (text: string) => boolean
  placeholder?: string
  values: string[]
}

export const TagsField = ({
  disabled,
  helperText,
  maxCount,
  onChange,
  onInputChange,
  onValidate,
  placeholder,
  values,
}: Props) => {
  const [text, setText] = useState('')

  const isCompositionEnd = useRef(true)
  const inputRef = useRef<HTMLInputElement>(null)

  const hasInput =
    !disabled && (maxCount === undefined || values.length < maxCount)

  const appendItem = (text: string) => {
    if (text.length === 0) {
      return
    }

    const targetText = text.trim()
    const isValid = onValidate?.(targetText) ?? true

    if (!isValid) {
      return
    }

    onChange(values.concat(targetText))

    setText('')
  }

  const handleComposition = (e: React.CompositionEvent<HTMLInputElement>) => {
    isCompositionEnd.current = e.type === 'compositionend'
  }

  return (
    <>
      <Outer
        disabled={disabled}
        hasInput={hasInput}
        isError={Boolean(helperText)}
        onClick={() => inputRef.current?.focus()}
      >
        {values.map((value, idx) => (
          <Tag key={idx}>
            {value}
            &nbsp;
            <Box
              component="span"
              sx={{ cursor: 'pointer' }}
              onClick={(
                event: React.MouseEvent<HTMLSpanElement, MouseEvent>
              ) => {
                event.stopPropagation()

                onChange(
                  values
                    .slice(0, idx)
                    .concat(values.slice(idx + 1, values.length))
                )
              }}
            >
              <Icon icon={ICON.multiply} color="inherit" fontSize="inherit" />
            </Box>
          </Tag>
        ))}

        {hasInput && (
          <InvisibleInput
            ref={inputRef}
            placeholder={values.length === 0 ? placeholder : undefined}
            type="text"
            value={text}
            isError={Boolean(helperText)}
            onChange={({ target: { value } }) => {
              setText(value)
              onInputChange?.(value)
            }}
            onCompositionStart={handleComposition}
            onCompositionUpdate={handleComposition}
            onCompositionEnd={handleComposition}
            onKeyDown={e => {
              if (e.key !== 'Enter' || !isCompositionEnd.current) {
                return
              }

              appendItem(text)
            }}
            onBlur={() => appendItem(text)}
          />
        )}
      </Outer>

      <Box
        display="flex"
        alignItems="center"
        minHeight={32}
        color={theme.colors.orangeyRed}
      >
        {helperText}
      </Box>
    </>
  )
}

export default TagsField
