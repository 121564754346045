import {
  AudienceRuleUserExportGoogleAds,
  GoogleAdsCustomer,
} from '@shared/api/rtkQuery'

export const formatGoogleAdsExportList = (
  // 當前使用者能夠取得的 customer 列表
  customerList: GoogleAdsCustomer[] = [],
  // 匯出紀錄
  exportList: AudienceRuleUserExportGoogleAds[] = []
) => {
  const googleAdsList = customerList.map(({ customerId, descriptiveName }) => {
    const exportStatus = exportList.find(data => data.customerId === customerId)

    return {
      accountName: descriptiveName,
      customerId,
      ...exportStatus,
    }
  })

  return googleAdsList
}
