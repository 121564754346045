import { Route, Switch, useRouteMatch } from 'react-router-dom'

import CampaignInsightDataTable from './CampaignInsightDataTable'

const CampaignInsight = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <CampaignInsightDataTable />
      </Route>
    </Switch>
  )
}

export default CampaignInsight
