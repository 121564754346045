import { useTranslation } from 'react-i18next'

import type { Category } from '@shared/ui/Dropdown/CategoryList'

import { createMetadataPropertyId } from './helpers'
import useMetadataQuery from './useMetadataQuery'

type UseCategoriesOfEventPropertiesOption = {
  skipDimensionColumn?: boolean
}

export const useCategoriesOfEventProperties = (
  eventName: string = '',
  { skipDimensionColumn }: UseCategoriesOfEventPropertiesOption = {
    skipDimensionColumn: false,
  }
): Category[] => {
  const { eventEntity } = useMetadataQuery()

  const { t } = useTranslation(['audience'])

  if (!eventName) {
    return []
  }

  const eventPropertiesCategories = {
    id: 'events',
    name: t('audience:v3.event_attributes'),
    options: [] as Category['options'],
  }

  const dimensionPropertiesCategories = {
    id: 'dimension',
    name: t('audience:v3.dimension_table'),
    options: [] as Category['options'],
  }

  const currentEvent = eventEntity.events.entities[eventName]

  currentEvent?.propertiesIds.forEach(propertyId => {
    const {
      displayName,
      tableName: source,
      id: field,
      isFromColumnOfDimTable,
      category,
    } = currentEvent.propertiesEntity[propertyId]

    // 此欄位只有事件分析的 aggregator 可以使用
    if (isFromColumnOfDimTable) {
      return
    }

    const option = {
      label: displayName,
      value: createMetadataPropertyId(source, field),
    }

    if (category === 'dimension' && !skipDimensionColumn) {
      dimensionPropertiesCategories.options.push(option)

      return
    }

    if (category === 'event') {
      eventPropertiesCategories.options.push(option)

      return
    }
  })

  if (skipDimensionColumn) {
    return [eventPropertiesCategories]
  }

  return [eventPropertiesCategories, dimensionPropertiesCategories]
}

export default useCategoriesOfEventProperties
