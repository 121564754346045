import styled from '@emotion/styled'

import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

const InputAdornment = styled.span``

const Container = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  & > ${InputAdornment} {
    position: absolute;
    right: 16px;
  }
`

const Input = styled.input`
  margin: 8px;
  padding: 0 32px 0 16px;

  width: 100%;
  height: 32px;

  border: 2px solid transparent;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.veryLightBlueThree};

  color: ${({ theme }) => theme.colors.black};
  font-size: 12px;

  transition: border-color 0.25s;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textPrimaryBlue};
    font-weight: 500;
  }

  ::-moz-placeholder {
    opacity: 1;
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.brightBlue};
    outline: none;
  }
`

type Props = {
  value: string
  placeholder?: string
  autoFocus?: boolean
  disabled?: boolean
  onChange: (text: string) => void
}

const RoundedSearchBar = ({
  value,
  placeholder,
  autoFocus = false,
  disabled = false,
  onChange,
}: Props) => (
  <Container>
    <Input
      type="text"
      value={value}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={({ target: { value } }) => {
        onChange(value)
      }}
      disabled={disabled}
    />
    <InputAdornment>
      <Icon
        id="icon-search"
        icon={ICON.search}
        color={theme.colors.brightBlue}
        fontSize="small"
      />
    </InputAdornment>
  </Container>
)

export default RoundedSearchBar
