import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'

import { formatRTKQueryError, handleApiError } from '@entities/apiHandler'
import { Subscription, usePutSubscriptionMutation } from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { openToast } from '@shared/model/slices'
import { ConfirmDialog } from '@shared/ui/dialogs'

import {
  SUBSCRIPTION_ERR_CODE,
  SUBSCRIPTION_MAX_COLUMN,
  subscriptionSchema,
} from '../_shared'
import { Columns, Endpoint, Name } from './SubscriptionForm'

type Props = {
  subscription: Subscription
  isOpen: boolean
  onClose: () => void
}

const EditSubscription = ({ isOpen, onClose, subscription }: Props) => {
  const { t } = useTranslation(['common', 'contentTemplate'])
  const dispatch = useAppDispatch()
  const [putSubscription, { isLoading: isPutting }] =
    usePutSubscriptionMutation()

  const { values, touched, errors, setFieldValue, setTouched, handleSubmit } =
    useFormik<Subscription>({
      initialValues: subscription,
      validationSchema: subscriptionSchema,
      onSubmit: async values => {
        try {
          await putSubscription({
            id: values.id,
            url: values.url,
            columns: values.columns,
          }).unwrap()

          onClose()
        } catch (error) {
          const typedError = formatRTKQueryError(error)

          if (typedError && typedError.data) {
            if (
              typedError.data.errorCode ===
              SUBSCRIPTION_ERR_CODE.COLUMN_NUMBER_EXCEEDS_LIMIT
            ) {
              dispatch(
                openToast({
                  status: 'error',
                  message: t(
                    'contentTemplate:subscription.error.column_number_exceeds_limit',
                    {
                      num: SUBSCRIPTION_MAX_COLUMN,
                    }
                  ),
                })
              )
              return
            }
          }

          dispatch(handleApiError(typedError))
        }
      },
    })

  return (
    <ConfirmDialog
      isOpen={isOpen}
      isLoading={isPutting}
      modalTitle={t('contentTemplate:subscription.dialog.common.modal_title')}
      onClose={onClose}
      onConfirm={handleSubmit}
      contentOverflowY="visible"
      maxWidth="md"
    >
      <Name
        value={values.name}
        onChange={value => {
          setTouched({ name: true })
          setFieldValue('name', value)
        }}
        isDisabled
      />
      <Columns
        values={values.columns}
        onChange={value => {
          setTouched({ columns: true })
          setFieldValue('columns', value)
        }}
        isError={Boolean(errors.columns && touched.columns)}
        isDisabled={isPutting}
      />
      <Endpoint
        id={getIntSafe(subscription.id, 0)}
        value={values.url}
        onChange={value => {
          setTouched({ url: true })
          setFieldValue('url', value)
        }}
        isDisabled={isPutting}
        isError={Boolean(errors.url && touched.url)}
        isValidator
      />
    </ConfirmDialog>
  )
}

export default EditSubscription
