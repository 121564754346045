import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button'

type BlackBGWhiteTextButtonProps = {
  height?: number
  width?: number
}

export const BlackBGWhiteTextButton = styled(
  ({
    height,
    width,
    ...otherProps
  }: BlackBGWhiteTextButtonProps & ButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Button {...otherProps} />
  )
)`
  && {
    padding: 4px 16px;
    width: ${({ width }) => `${width}px` || 'auto'};
    height: ${({ height }) => `${height}px` || 'auto'};
    border-radius: 3px;
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    text-transform: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.black425};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

export default BlackBGWhiteTextButton
