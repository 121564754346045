import { useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'

import { formatRTKQueryError } from '@entities/apiHandler'
import {
  type ThirdPartyAppProvider,
  useCreateThirdPartyAppIntegrationMutation,
  useDeleteThirdPartyAppIntegrationByIdMutation,
  useGetLineOaListQuery,
  useGetThirdPartyAppIntegrationQuery,
  usePatchUpdateThirdPartyAppIntegrationMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { useAppSelector } from '@shared/lib/hooks/store'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { ICON } from '@shared/model/constants/styles'
import {
  THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP,
  THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST,
} from '@shared/model/constants/thirdParty'
import { UNAUTHORIZED_ERROR_CODES } from '@shared/model/constants/thirdParty'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'
import { openToast } from '@shared/model/slices'
import ActionBar from '@shared/ui/ActionBar'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import { DeepBlueGradientButton } from '@shared/ui/buttons'
import Card from '@shared/ui/Card'
import { DeleteConfirmDialog } from '@shared/ui/dialogs'
import NestedList, { type NestedOption } from '@shared/ui/Dropdown/NestedList'
import { PrimaryGreyInput } from '@shared/ui/inputs'
import DotLoader from '@shared/ui/loaders/DotLoader'
import SectionTitle from '@shared/ui/SectionTitle'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import getProviderGroup from '../../lib/getProviderGroup'
import {
  BUTTON_WIDTH,
  INPUT_NAME_WIDTH,
  STATUS_CODE,
  thirdPartyAuthTypeMap,
} from '../../model/constant'
import {
  Container,
  TextFieldAction,
  TextFieldError,
  TextFieldGrid,
  TextFieldInput,
  TextFieldLabel,
} from './styles'
import TextFieldActionButtons from './TextFieldActionButtons'
import useThirdPartySchema, { type FormValues } from './useThirdPartySchema'

const Detail = () => {
  const { t, i18n } = useTranslation(['common', 'settings'])
  const { applicationId = '' } = useParams<{ applicationId: string }>()
  const appId = getIntSafe(applicationId)
  const isCreateMode = !applicationId
  const history = useHistory()
  const dispatch = useAppDispatch()
  const planType = useAppSelector(state => state.auth.plan.planType)
  const maxCount = useAppSelector(
    state => state.auth.plan.settings.thirdParty.maxCount
  )
  const [isShowDeleteDialog, setIsShowDeleteDialog] = useState(false)

  const { getThirdPartyProviderName } = useThirdPartyProviderName()
  const { thirdPartySchema } = useThirdPartySchema()

  const { data: formValues, isLoading } = useGetThirdPartyAppIntegrationQuery(
    appId,
    {
      skip: isCreateMode,
    }
  )

  const [createThirdPartyAppIntegration, { isLoading: isCreating }] =
    useCreateThirdPartyAppIntegrationMutation()

  const [patchUpdateThirdPartyAppIntegration, { isLoading: isUpdating }] =
    usePatchUpdateThirdPartyAppIntegrationMutation()

  const [deleteThirdPartyAppIntegration, { isLoading: isDeleting }] =
    useDeleteThirdPartyAppIntegrationByIdMutation()

  const goToOverview = () =>
    history.push(`/${CATEGORY.settings}/${PAGE_ROOT.thirdParty}`)

  const providerOptions: NestedOption[] = useMemo(
    () =>
      THIRD_PARTY_APP_PROVIDER_LIST_GROUP_LIST.filter(
        ({ group }) => group !== 'iterable'
      ).map(({ group, providerList }) => ({
        label: THIRD_PARTY_APP_GROUP_DISPLAY_NAME_MAP[group],
        value: group,
        options: providerList.toSorted().map(provider => ({
          label: getThirdPartyProviderName(provider),
          value: provider,
        })),
      })),
    [getThirdPartyProviderName]
  )

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleSubmit,
    resetForm,
  } = useFormik<FormValues>({
    initialValues: {
      name: '',
      type: 'super8',
      maskedApiKey: '',
      account: '',
      maskedPassword: '',
      lineOaName: '',
      submitType: 'form',
      channelId: '',
      ...formValues,
    },
    enableReinitialize: true,
    validationSchema: thirdPartySchema,
    onSubmit: async values => {
      if (isCreating || isUpdating) {
        return
      }

      try {
        if (values.submitType === 'name' && appId) {
          await patchUpdateThirdPartyAppIntegration({
            id: appId,
            name: values.name,
          }).unwrap()

          setFieldValue('submitType', 'form')

          return
        }

        if (values.submitType === 'secret' && appId) {
          await patchUpdateThirdPartyAppIntegration({
            id: appId,
            apiKey: values.maskedApiKey,
            account: values.account,
            password: values.maskedPassword,
          }).unwrap()

          setFieldValue('submitType', 'form')

          return
        }

        await createThirdPartyAppIntegration({
          name: values.name,
          type: values.type,
          apiKey: values.maskedApiKey,
          account: values.account,
          password: values.maskedPassword,
          lineOaName: values.lineOaName,
          channelId: values.channelId,
        }).unwrap()

        goToOverview()
      } catch (error) {
        const typedError = formatRTKQueryError(error)
        if (
          UNAUTHORIZED_ERROR_CODES.includes(
            typedError.data?.errorCode as number
          )
        ) {
          dispatch(
            openToast({
              message: t('settings:errors.unauthorized'),
              status: 'error',
            })
          )

          return
        }

        if (typedError.statusCode === STATUS_CODE.CREATE_MAX_COUNT) {
          const errorMsg =
            planType === 'lite'
              ? t('settings:errors.type_reach_max_count', {
                  type: providerGroup,
                  count: maxCount,
                })
              : t('settings:errors.create_max_count', {
                  count: maxCount,
                })

          dispatch(
            openToast({
              message: errorMsg,
              status: 'error',
            })
          )

          return
        }

        if (typedError.statusCode === STATUS_CODE.NAME_CONFLICT) {
          setFieldError('name', t('settings:errors.duplicate_name'))

          dispatch(
            openToast({
              message: t('settings:errors.duplicate_name'),
              status: 'error',
            })
          )

          return
        }

        dispatch(
          openToast({
            message: t('common:failure_create'),
            status: 'error',
          })
        )
      }
    },
  })

  const handleCancel = () => resetForm()

  const providerGroup = getProviderGroup(values.type)

  const { lineOaOptions } = useGetLineOaListQuery(undefined, {
    skip: providerGroup !== 'line',
    selectFromResult: ({ data = [] }) => ({
      lineOaOptions: data.map(oa => ({
        label: oa.displayName,
        value: oa.name,
      })),
    }),
  })

  const handleDeleteThirdParty = async () => {
    try {
      await deleteThirdPartyAppIntegration(appId).unwrap()

      goToOverview()
    } catch {
      dispatch(
        openToast({
          message: t('common:failure_delete'),
          status: 'error',
        })
      )
    }
  }

  const nameEl = useRef<HTMLInputElement>(null)
  const apiKeyEl = useRef<HTMLInputElement>(null)

  return (
    <Container>
      <Box display="flex" justifyContent="space-between">
        <Breadcrumbs>
          <Link to={`/${CATEGORY.settings}/${PAGE_ROOT.thirdParty}`}>
            {t('common:route.third_party')}
          </Link>
          {<Box>{isCreateMode ? t('common:create') : formValues?.name}</Box>}
        </Breadcrumbs>

        {!isCreateMode && (
          <ActionBar>
            <Button
              variant="contained"
              color="error"
              startIcon={<i className={ICON.trashAlt} />}
              onClick={() => setIsShowDeleteDialog(true)}
            >
              {t('common:delete')}
            </Button>
          </ActionBar>
        )}
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <DotLoader />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <Card mb={5}>
            <TextFieldGrid>
              <TextFieldLabel>{t('common:name')}</TextFieldLabel>

              <TextFieldInput>
                <PrimaryGreyInput
                  type="text"
                  value={values.name}
                  onChange={({ target: { value = '' } }) =>
                    setFieldValue('name', value.slice(0, MAX_LIST_NAME_LENGTH))
                  }
                  placeholder={t('settings:hint_name_required')}
                  width={INPUT_NAME_WIDTH}
                  disabled={values.submitType !== 'name' && !isCreateMode}
                  isError={Boolean(touched.name && errors.name)}
                  ref={nameEl}
                />
              </TextFieldInput>

              {!isCreateMode && (
                <TextFieldAction>
                  <TextFieldActionButtons
                    editLabel={t('common:edit')}
                    isEditing={values.submitType === 'name'}
                    onEdit={() => {
                      setFieldValue('submitType', 'name')

                      if (nameEl.current) {
                        nameEl.current.disabled = false
                        nameEl.current.focus()
                      }
                    }}
                    onCancel={handleCancel}
                  />
                </TextFieldAction>
              )}

              <TextFieldError>{touched.name && errors.name}</TextFieldError>
            </TextFieldGrid>
          </Card>

          <SectionTitle title={t('settings:information')} />

          <Card mb={5}>
            <TextFieldGrid>
              <TextFieldLabel>{t('settings:service')}</TextFieldLabel>

              <TextFieldInput>
                <NestedList
                  key={`${i18n.language}_${values.type}`}
                  parentValue={providerGroup}
                  label={providerGroup === 'iterable' ? 'iterable' : undefined}
                  value={values.type}
                  options={providerOptions}
                  onChange={async option => {
                    // 因為 validate 會判斷 type 來顯示錯誤訊息，所以('type')需要等待非同步操作結束
                    await setFieldValue(
                      'type',
                      option.value as ThirdPartyAppProvider
                    )

                    await setFieldValue('lineOaName', '')
                  }}
                  isDisabled={!isCreateMode}
                />
              </TextFieldInput>
            </TextFieldGrid>

            {providerGroup === 'line' && (
              <Box mt={3}>
                <TextFieldGrid>
                  <TextFieldLabel>LINE OA</TextFieldLabel>

                  <TextFieldInput>
                    <NestedList
                      options={lineOaOptions}
                      value={values.lineOaName}
                      onChange={option => {
                        setFieldValue('lineOaName', option.value)
                      }}
                      isDisabled={!isCreateMode}
                      isError={Boolean(touched.lineOaName && errors.lineOaName)}
                    />

                    <Box
                      component="span"
                      sx={{ color: theme.colors.chartRed, ml: 2 }}
                    >
                      {touched.lineOaName && errors.lineOaName}
                    </Box>
                  </TextFieldInput>
                </TextFieldGrid>
              </Box>
            )}
          </Card>

          <Card mb={5}>
            {thirdPartyAuthTypeMap[values.type] === 'apiKey' &&
              values.type === 'omnichat' && (
                <TextFieldGrid mb={3}>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <TextFieldLabel>Channel ID</TextFieldLabel>
                    <Tooltip title={t('settings:tooltip_channel_id')}>
                      <i className={ICON.infoCircle} />
                    </Tooltip>
                  </Box>
                  <TextFieldInput>
                    <PrimaryGreyInput
                      type="text"
                      value={values.channelId}
                      onChange={({ target: { value } }) =>
                        setFieldValue('channelId', value.trim())
                      }
                      width={INPUT_NAME_WIDTH}
                      placeholder={t('settings:hint_channel_id')}
                      disabled={!isCreateMode}
                      isError={Boolean(touched.channelId && errors.channelId)}
                    />
                  </TextFieldInput>

                  <TextFieldError>
                    {touched.channelId && errors.channelId}
                  </TextFieldError>
                </TextFieldGrid>
              )}

            {thirdPartyAuthTypeMap[values.type] === 'apiKey' && (
              <TextFieldGrid>
                <TextFieldLabel>API key</TextFieldLabel>

                <TextFieldInput>
                  <PrimaryGreyInput
                    type="text"
                    value={values.maskedApiKey}
                    onChange={({ target: { value } }) => {
                      setFieldValue('maskedApiKey', value.trim())
                    }}
                    width={INPUT_NAME_WIDTH}
                    placeholder={t('settings:hint_api_key')}
                    disabled={values.submitType !== 'secret' && !isCreateMode}
                    isError={Boolean(
                      touched.maskedApiKey && errors.maskedApiKey
                    )}
                    ref={apiKeyEl}
                  />
                </TextFieldInput>

                <TextFieldError>
                  {touched.maskedApiKey && errors.maskedApiKey}
                </TextFieldError>

                {!isCreateMode && (
                  <TextFieldAction>
                    <TextFieldActionButtons
                      editLabel={t('settings:update_api_key')}
                      isEditing={values.submitType === 'secret'}
                      onEdit={() => {
                        setFieldValue('maskedApiKey', '')
                        setFieldValue('submitType', 'secret')

                        if (apiKeyEl.current) {
                          apiKeyEl.current.disabled = false
                          apiKeyEl.current.focus()
                        }
                      }}
                      onCancel={handleCancel}
                    />
                  </TextFieldAction>
                )}
              </TextFieldGrid>
            )}

            {thirdPartyAuthTypeMap[values.type] === 'accountAndPassword' && (
              <>
                <TextFieldGrid mb={2}>
                  <TextFieldLabel>{t('settings:account')}</TextFieldLabel>

                  <TextFieldInput>
                    <PrimaryGreyInput
                      type="text"
                      value={values.account}
                      onChange={({ target: { value = '' } }) => {
                        setFieldValue('account', value.trim())
                      }}
                      width={INPUT_NAME_WIDTH}
                      placeholder={t('settings:hint_account', {
                        merchant: getThirdPartyProviderName(values.type),
                      })}
                      disabled={values.submitType !== 'secret' && !isCreateMode}
                      isError={Boolean(touched.account && errors.account)}
                    />
                  </TextFieldInput>

                  <TextFieldError>
                    {touched.account && errors.account}
                  </TextFieldError>

                  {!isCreateMode && (
                    <TextFieldAction>
                      <TextFieldActionButtons
                        editLabel={t('settings:update_account_password')}
                        isEditing={values.submitType === 'secret'}
                        onEdit={() => {
                          setFieldValue('maskedPassword', '')
                          setFieldValue('submitType', 'secret')

                          if (apiKeyEl.current) {
                            apiKeyEl.current.disabled = false
                            apiKeyEl.current.focus()
                          }
                        }}
                        onCancel={handleCancel}
                      />
                    </TextFieldAction>
                  )}
                </TextFieldGrid>

                <TextFieldGrid>
                  <TextFieldLabel>{t('settings:password')}</TextFieldLabel>

                  <TextFieldInput>
                    <PrimaryGreyInput
                      type="text"
                      value={values.maskedPassword}
                      onChange={({ target: { value } }) => {
                        setFieldValue('maskedPassword', value.trim())
                      }}
                      width={INPUT_NAME_WIDTH}
                      placeholder={t('settings:hint_password', {
                        merchant: getThirdPartyProviderName(values.type),
                      })}
                      disabled={values.submitType !== 'secret' && !isCreateMode}
                      isError={Boolean(
                        touched.maskedPassword && errors.maskedPassword
                      )}
                      ref={apiKeyEl}
                    />
                  </TextFieldInput>

                  <TextFieldError>
                    {touched.maskedPassword && errors.maskedPassword}
                  </TextFieldError>
                </TextFieldGrid>
              </>
            )}
          </Card>

          {isCreateMode && (
            <DeepBlueGradientButton
              width={BUTTON_WIDTH}
              type="submit"
              disabled={isCreating}
            >
              {t('common:create')}
            </DeepBlueGradientButton>
          )}
        </form>
      )}

      {!isCreateMode && (
        <DeleteConfirmDialog
          isLoading={isDeleting}
          isOpen={isShowDeleteDialog}
          modalTitle={t('settings:delete_confirm.title')}
          onClose={() => setIsShowDeleteDialog(false)}
          onConfirm={handleDeleteThirdParty}
        >
          <Box sx={{ fontSize: '16px', fontWeight: 500 }}>
            <Box component="ul" mb={2}>
              <li>{t('settings:delete_confirm.items_1')}</li>
              <li>{t('settings:delete_confirm.items_2')}</li>
              <li>{t('settings:delete_confirm.items_3')}</li>
            </Box>

            <p>
              <Trans
                ns="settings"
                i18nKey="delete_confirm.message"
                components={{
                  Name: (
                    <Box
                      component="span"
                      sx={{ color: theme => theme.colors.black }}
                    />
                  ),
                }}
                values={{
                  name: formValues?.name,
                }}
              />
            </p>
          </Box>
        </DeleteConfirmDialog>
      )}
    </Container>
  )
}

export default Detail
