import { useMemo } from 'react'
import pipe from 'lodash/fp/pipe'

import type {
  IamServiceModule,
  IamServiceModuleName,
} from '@shared/api/rtkQuery'
import { useIamServiceEntityQuery } from '@shared/api/rtkQuery'
import type { SidebarCategory } from '@widgets/layout/ui/Sidebar'
import { ROUTES_BY_CATEGORY } from '@widgets/layout/ui/Sidebar'

const filterByRequireIAMs =
  (entities: Record<string, IamServiceModule>) =>
  (requiredIAMs?: IamServiceModuleName[]) => {
    if (!requiredIAMs?.length) {
      return true
    }
    return requiredIAMs.some(item => entities[item]?.activated)
  }

const useRouteByCategory = () => {
  const { data: iamEntity = { entities: {} } } = useIamServiceEntityQuery()

  const checkIAMPermissionFilter = useMemo(
    () => filterByRequireIAMs(iamEntity.entities),
    [iamEntity]
  )

  const categories: SidebarCategory[] = useMemo(
    () =>
      pipe(
        (data: SidebarCategory[]) =>
          data.filter(category =>
            checkIAMPermissionFilter(category.requiredIAMs)
          ),
        data =>
          data.map(category => {
            const routes = category.routes.filter(route =>
              checkIAMPermissionFilter(route.requiredIAMs)
            )
            return { ...category, routes }
          })
      )(ROUTES_BY_CATEGORY),
    [checkIAMPermissionFilter]
  )

  return categories
}

export default useRouteByCategory
