import Box from '@mui/material/Box'
import type { SxProps, Theme } from '@mui/material/styles'

import theme from '@theme'

type Props = {
  sx?: SxProps<Theme>
  children: React.ReactNode
}

export const ChartContainer = ({ sx, children }: Props) => (
  <Box
    width="100%"
    sx={{
      '.tooltip-wrapper': {
        display: 'flex',
        p: 1.5,
        backgroundColor: theme.colors.blackTwo,
        color: theme.colors.white,
      },

      '.tooltip-column': {
        display: 'flex',
        flexDirection: 'column',
      },

      '.tooltip-row': {
        display: 'flex',
        alignItems: 'center',
        mr: 1,
        height: '20px',
      },

      '.tooltip-circle': {
        mr: 1,
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: theme.colors.white,
      },

      '.tooltip-name': {
        overflow: 'hidden',
        pr: 1,
        maxWidth: '300px',
        height: '20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },

      '.tooltip-name-center': {
        pr: 0,
        alignSelf: 'center',
      },

      '.tooltip-value': {
        ml: 1.5,
        height: '20px',
        textAlign: 'right',
      },

      '.tooltip-value-left': {
        ml: 0,
        textAlign: 'left',
      },
      '.tooltip-value-right': {
        ml: 0,
        textAlign: 'right',
      },

      // TODO: 確認是否有更彈性的定義方式
      '.ml-2': { ml: 2 },
      ...sx,
    }}
  >
    {children}
  </Box>
)

export default ChartContainer
