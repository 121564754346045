import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { useGetProductsKeywordsBlacklistQuery } from '@shared/api/rtkQuery'
import Card from '@shared/ui/Card'

import Keyword from '../Keyword'
import AddBlacklistButton from './AddBlacklistButton'

const Blacklist = () => {
  const { t } = useTranslation(['aiMarketingAssistant'])
  const { data: blacklistKeywordsData = null } =
    useGetProductsKeywordsBlacklistQuery()

  return (
    <Card p={0} mb={5}>
      <Box
        width="100%"
        fontSize={16}
        fontWeight={600}
        py={2}
        px={3}
        sx={theme => ({
          backgroundColor: theme.colors.black,
          color: theme.colors.white,
        })}
      >
        {t('aiMarketingAssistant:auto_tag_analysis.tag_blacklist')}
      </Box>

      <Box pt={3} px={3} py={4}>
        <Box
          display="flex"
          justifyContent="flex-start"
          justifyItems="flex-start"
          flexWrap="wrap"
          pb={3}
          mb={3}
          sx={theme => ({
            borderBottom: `1px solid ${theme.colors.black6}`,
          })}
        >
          {blacklistKeywordsData?.map(({ keyword }, idx) => (
            <Keyword key={`${keyword}_${idx}`} keyword={keyword} isDeletable />
          ))}
        </Box>
        <AddBlacklistButton />
      </Box>
    </Card>
  )
}

export default Blacklist
