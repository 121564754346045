import { Link } from 'react-router-dom'

import type { Insight } from '@shared/api/rtkQuery'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import type { TableColumn } from '@shared/ui/table'
import {
  InsightSummaryValue,
  insightValueFormatter,
} from '@widgets/insight/lib/utils'

// TODO: Remove conversion columns temporarily

const columns = (): // isConversionEnable: boolean,
// hasConversionValue: boolean
TableColumn<Insight['items'][number]>[] => [
  {
    headerName: 'name',
    field: 'id',
    colRatio: 0.35,
    valueFormatter: (_, { id, title }) => (
      <Link
        to={`/${CATEGORY.ma}/${PAGE_ROOT.insight.root}/${PAGE_ROOT.insight.workflowInsight}/${id}`}
      >
        {title}
      </Link>
    ),
  },
  {
    headerName: 'last_enabled_at',
    field: 'workflowLastEnabledAt',
    colRatio: 0.15,
    valueFormatter: ({ time, valid }) => {
      if (valid && time) {
        return formatDateTimeDisplay(time)
      } else {
        return '-'
      }
    },
  },
  {
    headerName: 'sent_uu_count',
    field: 'sentUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.2,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  {
    headerName: 'click_uu_count',
    field: 'clickUuCount',
    isSortable: true,
    align: 'right',
    colRatio: 0.3,
    valueFormatter: value =>
      insightValueFormatter(value as InsightSummaryValue),
  },
  // ToDo - Remove conversion columns temporarily
  // {
  //   headerName: 'conversion_uu_count',
  //   field: 'conversionUuCount',
  //   isSortable: isConversionEnable,
  //   align: 'right',
  //   valueFormatter: value =>
  //     isConversionEnable
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
  // {
  //   headerName: 'conversion_value',
  //   field: 'conversionValue',
  //   isSortable: hasConversionValue,
  //   align: 'right',
  //   valueFormatter: value =>
  //     hasConversionValue
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
  // {
  //   headerName: 'conversion_value_per_sent',
  //   field: 'conversionValuePerSent',
  //   isSortable: hasConversionValue,
  //   align: 'right',
  //   valueFormatter: value =>
  //     hasConversionValue
  //       ? insightValueFormatter(value as InsightSummaryValue)
  //       : '-',
  // },
]

export default columns
