import i18n from 'i18next'
import * as yup from 'yup'

export type FormValues = {
  name: string
  url: string
  utmCampaignId?: string
  utmCampaignSource?: string
  utmCampaignMedium?: string
  utmCampaignName?: string
  utmCampaignTerm?: string
  utmCampaignContent?: string
}

export const validationSchema = yup.object({
  name: yup.string().required(),
  url: yup.string().url().required(),
  utmCampaignId: yup.string(),
  utmCampaignSource: yup.string(),
  utmCampaignMedium: yup.string(),
  utmCampaignName: yup.string(),
  utmCampaignTerm: yup.string(),
  utmCampaignContent: yup.string(),
})

export const getFormFields = (): Array<{
  name: keyof FormValues
  label: string
  placeholder: string
  isRequired: boolean
  isUTMSettingField: boolean
}> => [
  {
    name: 'name',
    label: i18n.t('common:name'),
    placeholder: i18n.t('contentTemplate:utm_placeholder.name'),
    isRequired: true,
    isUTMSettingField: false,
  },
  {
    name: 'url',
    label: 'URL',
    placeholder: i18n.t('contentTemplate:utm_placeholder.url'),
    isRequired: true,
    isUTMSettingField: false,
  },
  {
    name: 'utmCampaignId',
    label: 'utm campaign id',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_campaign_id'),
    isRequired: false,
    isUTMSettingField: true,
  },
  {
    name: 'utmCampaignSource',
    label: 'utm campaign source',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_source'),
    isRequired: false,
    isUTMSettingField: true,
  },
  {
    name: 'utmCampaignMedium',
    label: 'utm campaign medium',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_medium'),
    isRequired: false,
    isUTMSettingField: true,
  },
  {
    name: 'utmCampaignName',
    label: 'utm campaign name',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_campaign'),
    isRequired: false,
    isUTMSettingField: true,
  },
  {
    name: 'utmCampaignTerm',
    label: 'utm campaign term',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_term'),
    isRequired: false,
    isUTMSettingField: true,
  },
  {
    name: 'utmCampaignContent',
    label: 'utm campaign content',
    placeholder: i18n.t('contentTemplate:utm_placeholder.utm_content'),
    isRequired: false,
    isUTMSettingField: true,
  },
]
