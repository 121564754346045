import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetAudienceRuleQuery } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { DotLoader } from '@shared/ui/loaders'
import {
  useAudienceDetailSetState,
  useAudienceDetailState,
} from '@widgets/audience/lib/AudienceDetailContext'
import { checkAllChartError } from '@widgets/audience/lib/chartUtils'
import { NO_DATA_ID } from '@widgets/audience/model/constant'

import EmptyAudience from './Charts/EmptyAudience'
import Charts from './Charts'

const AudienceDetailAnalysis = () => {
  const setAudienceAnalysisState = useAudienceDetailSetState()
  const { isChartLoading, isEmptyAudience, hasChartSettings, isChartError } =
    useAudienceDetailState()

  const { id } = useParams<{ id: string }>()
  const audienceId = getIntSafe(id, NO_DATA_ID)
  const { data: audienceRule, isSuccess: isAudienceSuccess } =
    useGetAudienceRuleQuery(audienceId, {
      skip: audienceId === NO_DATA_ID,
    })

  useEffect(() => {
    if (isAudienceSuccess) {
      setAudienceAnalysisState(prev => ({
        ...prev,
        isEmptyAudience: !audienceRule?.latestUserCount,
        audienceId,
      }))
    }
  }, [
    setAudienceAnalysisState,
    audienceRule?.latestUserCount,
    isAudienceSuccess,
    audienceId,
  ])

  const isAllChartError = !isChartLoading && checkAllChartError(isChartError)

  // { hasChartSettings } 會根據 Charts 內的 api 狀態更新，所以這邊不作 early return
  return (
    <Box
      width="100%"
      height={isAllChartError ? '50vh' : 'auto'}
      sx={{
        backgroundImage: isAllChartError
          ? 'url(/images/audience-analysis-sample.png)'
          : '',
        backgroundSize: 'cover',
      }}
    >
      {isChartLoading && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <DotLoader />
        </Box>
      )}
      {isAudienceSuccess && <Charts />}
      {hasChartSettings && isEmptyAudience && <EmptyAudience />}
    </Box>
  )
}

export default AudienceDetailAnalysis
