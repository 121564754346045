import i18n from 'i18next'

import type { SearchColumnAlias } from '@shared/api/rtkQuery'

// 用 function 是為了讓 i18n.t 可以拿到 init 後的 i18n instance
export const SEARCH_COLUMN_OPTIONS: () => Array<{
  label: string
  value: SearchColumnAlias
}> = () => [
  {
    label: i18n.t('userProfile:overview.profile.user_id'),
    value: 'app_user_id',
  },
  {
    label: i18n.t('userProfile:overview.profile.name'),
    value: 'name',
  },
  {
    label: i18n.t('userProfile:overview.profile.mobile_phone'),
    value: 'mobile_phone',
  },
  {
    label: i18n.t('table:email'),
    value: 'email',
  },
]
