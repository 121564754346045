import * as yup from 'yup'

import i18n from '@i18n'
import type { DataSourceType, TagType } from '@shared/api/rtkQuery'
import { MAX_LIST_NAME_LENGTH } from '@shared/model/constants/validation'

import type { AutoAigcRule, BehaviorInfo, Info } from './type'

export const infoSchema = yup.object<Info>({
  id: yup.number(),
  title: yup
    .string()
    .required(() => `${i18n.t('tag:please_enter_tag_name')}`)
    .max(
      MAX_LIST_NAME_LENGTH,
      () =>
        `${i18n.t('common:errors.max_count', { count: MAX_LIST_NAME_LENGTH })}`
    ),
  description: yup.string(),
  groupId: yup.number().required(),
  type: yup
    .mixed()
    .oneOf<TagType>(['internal', 'offline', 'internal_offline'])
    .required(),
  schedulingType: yup.mixed().oneOf(['none', 'default']).required(),
})

export const gradingTagRuleSchema = yup
  .array()
  .min(1, () => `${i18n.t('tag:please_add_one_group_at_least')}`)

export const behaviorInfoSchema = yup.object<BehaviorInfo>({
  id: yup.number(),
  title: yup
    .string()
    .required(() => `${i18n.t('tag:please_enter_tag_name')}`)
    .max(
      MAX_LIST_NAME_LENGTH,
      () =>
        `${i18n.t('common:errors.max_count', { count: MAX_LIST_NAME_LENGTH })}`
    ),
  description: yup.string(),
  groupId: yup.number().required(),
  schedulingType: yup.mixed().oneOf(['none', 'daily']).required(),
  dataSourceType: yup
    .mixed()
    .oneOf<DataSourceType>(['import', 'auto_aigc_with_event_rule'])
    .required(),
})

export const autoAigcSchema = yup.object<AutoAigcRule>({
  relatedAllAigcTarget: yup.boolean().required().default(false),
  relatedAigcTargetIds: yup.array().when('relatedAllAigcTarget', {
    is: false,
    then: schema =>
      schema.min(1, () => `${i18n.t('tag:please_select_aigc_product')}`),
  }),
})

export const gradingTagSchema = infoSchema.shape({
  tagRules: gradingTagRuleSchema,
})

export const behaviorTagValidateSchema = behaviorInfoSchema.shape({
  autoAigcWithEventRule: autoAigcSchema,
})
