import { Z_INDEX } from '@shared/model/constants/styles'

import { DEFAULT_LABEL_WIDTH } from './models/constants'
import type { FieldProps } from './models/types'
import DropdownList from '../../Dropdown/DropdownList'
import * as Field from './styles'

export interface SelectFieldProps<T> extends FieldProps {
  options?: { label: string; description?: string; value: T }[]
  popperZIndex?: number
  popperDisablePortal?: boolean
}

export const SelectField = <T extends string>({
  description,
  error,
  isDisabled,
  isFullWidth,
  label,
  labelWidth = DEFAULT_LABEL_WIDTH,
  name,
  onChange,
  options = [],
  placeholder,
  popperZIndex = Z_INDEX.popup,
  popperDisablePortal = true,
  value,
}: SelectFieldProps<T>) => {
  const optionDescription = options.find(
    option => option.value === value
  )?.description

  const displayDescription = optionDescription ?? description

  return (
    <Field.Container>
      <Field.Label htmlFor={name} labelWidth={labelWidth}>
        {label}
      </Field.Label>

      <Field.Input>
        <DropdownList
          disabled={isDisabled}
          id={name}
          isError={Boolean(error)}
          isFullWidth={isFullWidth}
          onValueChanged={({ value }) => onChange?.(value as T)}
          options={options}
          placeholder={placeholder}
          popperDisablePortal={popperDisablePortal}
          popperZIndex={popperZIndex}
          value={value}
        />

        {displayDescription && (
          <Field.Description>{displayDescription}</Field.Description>
        )}

        {error && <Field.Error>{error}</Field.Error>}
      </Field.Input>
    </Field.Container>
  )
}

export default SelectField
