import type { TFunction } from 'i18next'

import type { TimeRangeType } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import { formatDateDisplay } from '@shared/lib/utils/time'
import type { NumberOrString } from '@shared/lib/utils/type'

export type ValueType = 'static' | 'dynamic'

const oneDayMilliseconds = 24 * 60 * 60 * 1000
const oneWeekMilliseconds = 7 * oneDayMilliseconds

export const getToday = () => {
  const nowStart = new Date()
  nowStart.setHours(0, 0, 0, 0)
  const nowEnd = new Date()
  nowEnd.setHours(23, 59, 59, 999)

  return {
    nowStart,
    nowEnd,
  }
}

export const getRangeType = (values: NumberOrString[]): TimeRangeType =>
  // 用第 0 個數是否可以轉型為 Number 判斷目標 time_range_type
  Number.isNaN(+values[0]) ? 'absolute_between' : 'relative_between_truncated'

export const getTodayStartEnd = () => {
  const { nowStart, nowEnd } = getToday()

  return {
    startDate: nowStart,
    endDate: nowEnd,
  }
}

export const getYesterdayStartEnd = () => {
  const { nowEnd, nowStart } = getToday()
  const yesterdayStart = new Date(nowStart.getTime() - oneDayMilliseconds)
  const yesterdayEnd = new Date(nowEnd.getTime() - oneDayMilliseconds)

  return {
    startDate: yesterdayStart,
    endDate: yesterdayEnd,
  }
}

// 週從星期天開始
export const getThisWeekStartEnd = () => {
  const { nowEnd, nowStart } = getToday()
  // 0 => 星期日, 1 => 星期一
  const nowDay = nowEnd.getDay()
  const weekStart = new Date(nowStart.getTime() - nowDay * oneDayMilliseconds)

  return {
    startDate: weekStart,
    endDate: nowEnd,
  }
}

// 週從星期天開始
export const getLastWeekStartEnd = () => {
  const { nowEnd, nowStart } = getToday()
  // 0 => 星期日, 1 => 星期一
  const nowDay = nowEnd.getDay()

  const lastWeekStart = new Date(
    nowStart.getTime() - nowDay * oneDayMilliseconds - oneWeekMilliseconds
  )
  const lastWeekEnd = new Date(
    nowEnd.getTime() - nowDay * oneDayMilliseconds - oneDayMilliseconds
  )

  return {
    startDate: lastWeekStart,
    endDate: lastWeekEnd,
  }
}

export const getThisMonthStartEnd = () => {
  const { nowEnd, nowStart } = getToday()
  const nowDate = nowEnd.getDate()

  const thisMonthStart = new Date(
    nowStart.getTime() - (nowDate - 1) * oneDayMilliseconds
  )

  return {
    startDate: thisMonthStart,
    endDate: nowEnd,
  }
}

export const getLastMonthStartEnd = () => {
  const { nowEnd } = getToday()
  const thisYear = nowEnd.getFullYear()

  // 0 => 一月, 1 => 二月
  const thisMonth = nowEnd.getMonth()

  // new Date(2022, -1) => "2021-12-01T00:00:00.000+0800"
  const lastMonthStart = new Date(thisYear, thisMonth - 1)

  const thisMonthStart = new Date(thisYear, thisMonth)
  const lastMonthEnd = new Date(thisMonthStart.getTime() - 1)

  return {
    startDate: lastMonthStart,
    endDate: lastMonthEnd,
  }
}

export const getThisYearStartEnd = () => {
  const { nowEnd } = getToday()
  // 0 => 一月, 1 => 二月
  const thisYear = nowEnd.getFullYear()
  const thisYearStart = new Date(`${thisYear}`)
  thisYearStart.setHours(0, 0, 0, 0)

  return {
    startDate: thisYearStart,
    endDate: nowEnd,
  }
}

export const getLastYearStartEnd = () => {
  const { nowEnd } = getToday()
  // 0 => 一月, 1 => 二月
  const thisYear = nowEnd.getFullYear()
  const lastYearStart = new Date(`${thisYear - 1}`)
  lastYearStart.setHours(0, 0, 0, 0)

  const thisYearStart = new Date(`${thisYear}`)
  thisYearStart.setHours(0, 0, 0, 0)

  const lastYearEnd = new Date(thisYearStart.getTime() - 1)

  return {
    startDate: lastYearStart,
    endDate: lastYearEnd,
  }
}

export const getLastNDays = (num: number) => {
  const { nowStart } = getToday()
  const { endDate: yesterdayEnd } = getYesterdayStartEnd()
  const lastNStart = new Date(nowStart.getTime() - num * oneDayMilliseconds)

  return {
    startDate: lastNStart,
    endDate: yesterdayEnd,
  }
}

export const getLastYearSameDate = (thisYearDate: Date) => {
  const thisYear = thisYearDate.getFullYear()
  const thisMonth = thisYearDate.getMonth() + 1
  const thisDate = thisYearDate.getDate()

  const lastYearDateStart = new Date(`${thisYear - 1}-${thisMonth}-${thisDate}`)
  lastYearDateStart.setHours(0, 0, 0, 0)

  const lastYearDateEnd = new Date(`${thisYear - 1}-${thisMonth}-${thisDate}`)
  lastYearDateEnd.setHours(23, 59, 59, 999)

  return {
    startDate: lastYearDateStart,
    endDate: lastYearDateEnd,
  }
}

export const getLastSamePeriod = (thisStartDate: Date, thisEndDate: Date) => {
  const diffMilliseconds = thisEndDate.getTime() - thisStartDate.getTime()
  const lastEndDate = new Date(thisStartDate.getTime() - 1) // 減 1 millisecond
  const lastStartDate = new Date(lastEndDate.getTime() - diffMilliseconds)

  return {
    startDate: lastStartDate,
    endDate: lastEndDate,
  }
}

export const parseIntSafeAbs = (value: NumberOrString) =>
  Math.abs(getIntSafe(value))

export const getValueType = (values: NumberOrString[]): ValueType => {
  // static: ["2021-12-27T00:00:00.000Z", "2021-12-28T23:59:59.999"]
  // dynamic: [-3, -1, "day"]
  return Number.isNaN(+values[0]) ? 'static' : 'dynamic'
}

const getStartAndEndOfDays = (start: number, end: number) => {
  const { nowStart } = getToday()
  const startDate = new Date(nowStart.getTime() + start * oneDayMilliseconds)
  const endDate = new Date(nowStart.getTime() + end * oneDayMilliseconds)

  return {
    startDate,
    endDate,
  }
}

export const getPastFutureDurationText = (
  values: NumberOrString[],
  t: TFunction<('common' | 'dateAndChart' | 'dashboard')[]>
) => {
  const valueType = getValueType(values)
  const [start, end] = values

  if (valueType === 'dynamic') {
    const range = [parseIntSafeAbs(start), parseIntSafeAbs(end)].sort(
      (a, b) => a - b
    )
    const pastOrFuture = +start < 0 ? t('common:past') : t('common:future')

    const { startDate, endDate } = getStartAndEndOfDays(
      getIntSafe(start),
      getIntSafe(end)
    )

    const startDateText = formatDateDisplay(startDate)
    const endDateText = formatDateDisplay(endDate)

    let rangeText
    if (range[0] === range[1]) {
      rangeText =
        range[0] === 0
          ? t('common:today')
          : `${pastOrFuture} ${t('common:order')} ${range[1]} ${t(
              'common:days'
            )}`
    } else {
      rangeText =
        range[0] === 0
          ? `${t('common:from')} ${t('common:today')} ${t(
              'common:to'
            )} ${pastOrFuture} ${t('common:order')} ${range[1]} ${t(
              'common:days'
            )}`
          : `${t('common:from')} ${pastOrFuture} ${t('common:order')} ${
              range[0]
            } ${t('common:days')} ${t('common:to')} ${t('common:order')} ${
              range[1]
            } ${t('common:days')}
    `
    }

    return `
    ${rangeText} ｜${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }

  if (valueType === 'static') {
    const startDateText = formatDateDisplay(values[0])
    const endDateText = formatDateDisplay(values[1])

    return `${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }
}

export const getDurationText = (
  values: NumberOrString[],
  t: TFunction<('common' | 'dateAndChart' | 'dashboard')[]>
) => {
  const valueType = getValueType(values)

  if (valueType === 'dynamic') {
    const range = parseIntSafeAbs(values[0])
    const { startDate, endDate } = getLastNDays(range)

    const startDateText = formatDateDisplay(startDate)
    const endDateText = formatDateDisplay(endDate)

    return `
    ${t('common:last_n_days', {
      days: range,
    })}｜${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }

  if (valueType === 'static') {
    const startDateText = formatDateDisplay(values[0])
    const endDateText = formatDateDisplay(values[1])

    return `${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }
}

export const getDuration = (
  values: NumberOrString[],
  t: TFunction<('common' | 'dateAndChart' | 'dashboard')[]>
): {
  dynamicDuration: string | null
  staticDuration: string
  latestDate: string
  isSameDate: boolean
} => {
  const valueType = getValueType(values)
  let dynamicDuration = null
  let staticDuration = ''
  let latestDate = ''
  let isSameDate = false

  if (valueType === 'dynamic') {
    const range = parseIntSafeAbs(values[0])
    const { startDate, endDate } = getLastNDays(range)

    const startDateText = formatDateDisplay(startDate)
    const endDateText = formatDateDisplay(endDate)
    isSameDate = startDateText === endDateText
    latestDate = endDateText
    dynamicDuration = t('common:last_n_days', {
      days: range,
    })
    staticDuration = isSameDate
      ? endDateText
      : `${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }

  if (valueType === 'static') {
    const startDateText = formatDateDisplay(values[0])
    const endDateText = formatDateDisplay(values[1])

    isSameDate = startDateText === endDateText
    latestDate = endDateText
    staticDuration = isSameDate
      ? endDateText
      : `${startDateText} ${t('dateAndChart:to')} ${endDateText}`
  }

  return { dynamicDuration, staticDuration, isSameDate, latestDate }
}
