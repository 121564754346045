import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import RequireIAM from '@features/auth/ui/RequireIAM'
import CampaignInsightBgUrl from '@shared/assets/images/iamModule/campaignInsight.svg'
import WorkflowInsightBgUrl from '@shared/assets/images/iamModule/workflowInsight.svg'
import { useAppSelector } from '@shared/lib/hooks/store'
import TrialFeatureCover from '@shared/ui/TrialFeatureCover'

import SharedStateProvider from './lib/SharedStateContext'
import CampaignInsight from './ui/campaign'
import WorkflowsInsight from './ui/workflow'

export const InsightRoutes = () => {
  const { params } = useRouteMatch<{ type: string }>()

  const isInsightDisabled = useAppSelector(
    state => state.auth.plan.insight.disabled
  )

  const { t } = useTranslation(['common', 'iam'])

  return (
    <SharedStateProvider>
      {params.type === 'campaign-insight' && (
        <RequireIAM
          moduleName="mkt_journey"
          noPermission={{
            title: t('common:route.campaign_insight'),
            description: t('iam:require_iam.campaign_insights'),
            bgImageUrl: CampaignInsightBgUrl,
          }}
        >
          {isInsightDisabled ? (
            <TrialFeatureCover
              title={t('common:route.campaign_insight')}
              description={t('iam:require_iam.campaign_insights')}
              bgImageUrl={CampaignInsightBgUrl}
              notice={t('common:trial.trial_launch_soon')}
            />
          ) : (
            <CampaignInsight />
          )}
        </RequireIAM>
      )}

      {params.type === 'workflow-insight' && (
        <RequireIAM
          moduleName="mkt_journey"
          noPermission={{
            title: t('common:route.workflow_insight'),
            description: t('iam:require_iam.automation_journey_insights'),
            bgImageUrl: WorkflowInsightBgUrl,
          }}
        >
          {isInsightDisabled ? (
            <TrialFeatureCover
              title={t('common:route.workflow_insight')}
              description={t('iam:require_iam.automation_journey_insights')}
              bgImageUrl={WorkflowInsightBgUrl}
              notice={t('common:trial.trial_launch_soon')}
            />
          ) : (
            <WorkflowsInsight />
          )}
        </RequireIAM>
      )}
    </SharedStateProvider>
  )
}

export default InsightRoutes
