import { useTranslation } from 'react-i18next'

import type { TaskEntity } from '@features/infoBox/model/taskSlice'

export const useTaskTypeText = (type: TaskEntity['type']) => {
  const { t } = useTranslation(['dataImport'])
  if (type === 'ingestion') {
    return t('dataImport:import_type.text')
  }
  return ''
}

export default useTaskTypeText
