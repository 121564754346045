import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import type { SpacingProps } from '@mui/system'

import {
  SchedulingType,
  useCancelAutoExportGoogleAdsMutation,
  useExportAudienceRuleUserGoogleAdsMutation,
} from '@shared/api/rtkQuery'
import { useAppSelector } from '@shared/lib/hooks/store'
import { BlueBorderButton, DeepBlueGradientButton } from '@shared/ui/buttons'
import { ConfirmDialog } from '@shared/ui/dialogs'
import theme from '@theme'

import { EXPORT_BUTTON_WIDTH, ExportCard } from '../_shared'
import GoogleAdsCreateDialog, { type FormValues } from './GoogleAdsCreateDialog'
import GoogleAdsExportList from './GoogleAdsExportList'
import useCustomerList from './useCustomerList'
import useGoogleOAuth from './useGoogleOAuth'

type Props = {
  audienceRuleId: number
  audienceRuleTitle: string
  schedulingType: SchedulingType
  onExported?: VoidFunction
} & SpacingProps

const ExportGoogleAds = ({
  audienceRuleId,
  audienceRuleTitle,
  schedulingType,
  onExported,
  ...spacingProps
}: Props) => {
  const [googleAuth, { login }] = useGoogleOAuth()

  const customerList = useCustomerList({
    googleAccessToken: googleAuth.accessToken,
    isGoogleAccessTokenValid: googleAuth.isAccessTokenValid,
  })

  const [customerId, setCustomerId] = useState<string>()

  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false)

  const [exportGoogleAds, { isLoading: isExporting }] =
    useExportAudienceRuleUserGoogleAdsMutation()

  const [cancelExportId, setCancelExportId] = useState<number>()

  const [cancelAutoExportGoogleAds, { isLoading: isCancelling }] =
    useCancelAutoExportGoogleAdsMutation()

  const isExportGoogleAdsAvailable = useAppSelector(
    state => state.auth.plan.audience.exportGoogleAds
  )

  const { t } = useTranslation(['audience', 'common'])

  const handleExportGoogleAds = async (values: FormValues) => {
    if (!customerId) {
      return
    }

    await exportGoogleAds({
      googleAccessToken: googleAuth.accessToken,
      googleRefreshToken: googleAuth.refreshToken,
      audienceRuleId,
      customerId,
      name: values.name,
      description: values.description,
      updatePeriod: values.updatePeriod,
      updatePeriodType: values.updatePeriodType,
      updatePolicy: values.updatePolicy,
    }).unwrap()

    setCustomerId(undefined)
    onExported?.()
  }

  return (
    <>
      <ExportCard
        imageURL="/images/logo-googleads.png"
        title={t('audience:export_google_ads.title')}
        description={
          googleAuth.isAccessTokenValid
            ? t('audience:export_google_ads.name_status_description')
            : t('audience:export_google_ads.authorize_description')
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...spacingProps}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {googleAuth.isAccessTokenValid ? (
            <DeepBlueGradientButton
              width={EXPORT_BUTTON_WIDTH}
              disabled={!isExportGoogleAdsAvailable}
              onClick={() => setIsSettingsDialogOpen(true)}
            >
              {t('audience:export.settings')}
            </DeepBlueGradientButton>
          ) : (
            <BlueBorderButton
              sx={{ height: 42, minWidth: EXPORT_BUTTON_WIDTH }}
              onClick={() => {
                login()
                setIsSettingsDialogOpen(true)
              }}
              disabled={!isExportGoogleAdsAvailable}
            >
              {t('audience:export_google_ads.authorize')}
            </BlueBorderButton>
          )}

          {!isExportGoogleAdsAvailable && (
            <Box mt={1} color={theme.colors.textSecondBlue}>
              {t('common:trial.not_available_in_trial')}
            </Box>
          )}
        </Box>
      </ExportCard>

      <ConfirmDialog
        cancelText={t('common:back')}
        hasConfirmBtn={false}
        isOpen={isSettingsDialogOpen && Boolean(customerList) && !customerId}
        // 使用 keepMounted 儘早透過 GoogleAdsExportList 取得匯出狀態
        keepMounted
        maxWidth="md"
        modalTitle={t('audience:export.settings')}
        onClose={() => setIsSettingsDialogOpen(false)}
      >
        <GoogleAdsExportList
          audienceRuleId={audienceRuleId}
          schedulingType={schedulingType}
          customerList={customerList}
          onCustomerIdExport={setCustomerId}
          onAutoExportIdCancel={setCancelExportId}
        />
      </ConfirmDialog>

      {/* 為了讓每次開啟 GoogleAdsCreateDialog 都是未設定的狀態 */}
      {customerId && (
        <GoogleAdsCreateDialog
          isOpen
          isSubmitting={isExporting}
          listName={audienceRuleTitle}
          schedulingType={schedulingType}
          onCancel={() => setCustomerId(undefined)}
          onSubmit={handleExportGoogleAds}
        />
      )}

      <ConfirmDialog
        isOpen={cancelExportId !== undefined}
        isLoading={isCancelling}
        modalTitle={t('audience:export_stop_auto', {
          maTool: 'Google Ads',
        })}
        onClose={() => setCancelExportId(undefined)}
        onConfirm={async () => {
          if (cancelExportId === undefined) {
            return
          }

          await cancelAutoExportGoogleAds(cancelExportId).unwrap()

          setCancelExportId(undefined)
        }}
      />
    </>
  )
}

export default ExportGoogleAds
