import qs from 'qs'

import type { FormValues } from '../../schema'
import type { TracingUrlDialogProps } from '../../TracingUrlDialog'

const mapInitialValues = (data: TracingUrlDialogProps['data']): FormValues => {
  if (!data) {
    return {
      name: '',
      url: '',
      utmCampaignId: '',
      utmCampaignSource: '',
      utmCampaignMedium: '',
      utmCampaignName: '',
      utmCampaignTerm: '',
      utmCampaignContent: '',
    }
  }

  /**
   * 取出 url 中 utm 相關 query string 的值
   */
  const [originUrl, querystring] = data.url.split('?')

  const {
    utm_campaign_id = '',
    utm_source = '',
    utm_medium = '',
    utm_campaign = '',
    utm_term = '',
    utm_content = '',
    ...otherQuerystring
  } = qs.parse(querystring)

  const url = [originUrl, qs.stringify(otherQuerystring)]
    .filter(Boolean)
    .join('?')

  return {
    name: data.name,
    url,
    utmCampaignId: utm_campaign_id as string,
    utmCampaignSource: utm_source as string,
    utmCampaignMedium: utm_medium as string,
    utmCampaignName: utm_campaign as string,
    utmCampaignTerm: utm_term as string,
    utmCampaignContent: utm_content as string,
  }
}

export default mapInitialValues
