import type { Policy } from '@shared/api/rtkQuery'

export type PolicyServiceEntity = {
  id: number
  name: string
  serviceEntity: {
    ids: string[]
    entities: {
      // 屬性陣列代表此使用者權限包含的功能
      // ex.
      // {
      //   application: {
      //     list: [ListApplications],
      //     read: [DescribeApplication],
      //     write: [CreateApplication, DeleteApplication]
      //   }
      // }
      [serviceName: string]: {
        [groupName: string]: string[]
      }
    }
  }
}

export const transformToPolicyServiceEntity = (
  data: Policy
): PolicyServiceEntity => {
  const serviceEntity = data.services?.reduce(
    (acc, curr) => {
      acc.ids.push(curr.name)

      if (!acc.entities[curr.name]) {
        acc.entities[curr.name] = {}
      }

      curr.actionGroups.forEach(group => {
        acc.entities[curr.name][group.name] = group.actions
      })

      return acc
    },
    {
      ids: [],
      entities: {},
    } as PolicyServiceEntity['serviceEntity']
  )

  return {
    id: data.id,
    name: data.name,
    serviceEntity: serviceEntity ?? { ids: [], entities: {} },
  }
}
