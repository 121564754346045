import React from 'react'
import styled from '@emotion/styled'

import DotLoader from '@shared/ui/loaders/DotLoader'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  box-sizing: border-box;
  padding-top: 40vh;
  width: 100%;

  font-size: 48px;
  font-weight: 600;
`

const LoadingPage = () => (
  <Wrapper>
    <DotLoader />
  </Wrapper>
)

export default LoadingPage
