import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import type { NumberOrString } from '@shared/lib/utils/type'
import { ICON } from '@shared/model/constants/styles'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import DateRangePicker, {
  DatePickerWrapper,
  getLastNDays,
} from '@shared/ui/DateRangePicker'
import { UniIcon as Icon } from '@shared/ui/icons'
import theme from '@theme'

import DataTable from './ui/DataTable'
import ExportCSV from './ui/ExportCSV'

const SendingAmount = () => {
  const lastMonthRange = getLastNDays(30)

  const [timeRangeParams, setTimeRangeParams] = useState<NumberOrString[]>([
    lastMonthRange.startDate.toISOString(),
    lastMonthRange.endDate.toISOString(),
  ])

  const { t } = useTranslation(['usageManagement'])

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        width="100%"
        alignItems="center"
      >
        <Breadcrumbs>
          <Box mr={2.5}>{t('usageManagement:sending_amount')}</Box>
        </Breadcrumbs>
        <Box fontSize={14} fontWeight="500" mb={5}>
          {t('usageManagement:sending_amount_note')}
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
        >
          <Icon
            icon={ICON.schedule}
            color={theme.colors.brightBlue}
            fontSize="small"
          />
          <DatePickerWrapper>
            <DateRangePicker
              values={timeRangeParams}
              durationBgColor={theme.colors.white}
              onChange={value => setTimeRangeParams(value)}
              isHideDynamicTab
            />
          </DatePickerWrapper>
        </Box>
        <ExportCSV timeRangeParams={timeRangeParams} />
      </Box>
      <DataTable timeRangeParams={timeRangeParams} />
    </>
  )
}

export default SendingAmount
