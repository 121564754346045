import type { Placement } from '../models'

export const getTopAndLeft = (
  element: HTMLElement,
  placement: Placement,
  customStyle: { offsetTop: number; offsetLeft: number }
) => {
  const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = element

  let top = customStyle.offsetTop
  let left = customStyle.offsetLeft

  switch (placement) {
    case 'right-start':
    case 'top-end':
      top += offsetTop
      left += offsetLeft + offsetWidth
      break
    case 'right':
      top += offsetTop + offsetHeight / 2
      left += offsetLeft + offsetWidth
      break
    case 'right-end':
    case 'bottom-end':
      top += offsetTop + offsetHeight
      left += offsetLeft + offsetWidth
      break
    case 'left-start':
    case 'top-start':
      top += offsetTop
      left += offsetLeft
      break
    case 'left':
      top += offsetTop + offsetHeight / 2
      left += offsetLeft
      break
    case 'left-end':
    case 'bottom-start':
      top += offsetTop + offsetHeight
      left += offsetLeft
      break
    case 'top':
      top += offsetTop
      left += offsetLeft + offsetWidth / 2
      break
    case 'bottom':
      top += offsetTop + offsetHeight
      left += offsetLeft + offsetWidth / 2
      break
    default:
      break
  }

  return { top, left }
}

export default getTopAndLeft
