import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  useGetFunnelRuleQuery,
  useUpdateFunnelRuleMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { getIntSafe } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import { Tooltip } from '@shared/ui/tooltips'
import { AnalyticsFormBreadcrumbs as FunnelFormBreadcrumbs } from '@widgets/analytics/_shared'
import { NO_DATA_ID } from '@widgets/analytics/_shared/constants'
import useErrorHandler from '@widgets/analytics/_shared/hooks/useErrorHandler'

import { FunnelForm, FunnelRuleFormValues } from '../_shared'

const FunnelEdit = () => {
  const { id } = useParams<{ id: string }>()
  const funnelId = getIntSafe(id, NO_DATA_ID)

  const { data: funnelRule, isFetching: isFetchingFunnelRule } =
    useGetFunnelRuleQuery(funnelId, { skip: funnelId === NO_DATA_ID })

  const [updateFunnelRule, { isLoading: isUpdatingFunnelRule }] =
    useUpdateFunnelRuleMutation()

  const { handleError } = useErrorHandler({ type: 'funnel' })

  const { t } = useTranslation(['analytics', 'common'])
  const dispatch = useAppDispatch()

  return (
    <FunnelForm
      header={({
        isExportingCSV,
        isExportDisabled,
        isSaveDisabled,
        onReset: handleReset,
        onExport: handleExport,
        onShowConfirmDialog: handleShowConfirmDialog,
      }) => (
        <FunnelFormBreadcrumbs
          isExportDisabled={isExportDisabled}
          isExportingCSV={isExportingCSV}
          isSaveDisabled={isSaveDisabled}
          onExport={handleExport}
          onReset={handleReset}
          onShowConfirmDialog={handleShowConfirmDialog}
        >
          <Link to={`/${CATEGORY.analytics}/${PAGE_ROOT.funnel}/new`}>
            {t('common:route.analytics_funnel')}
          </Link>

          {funnelRule?.title && (
            <Tooltip title={funnelRule.title}>
              <Box>{funnelRule.title}</Box>
            </Tooltip>
          )}
        </FunnelFormBreadcrumbs>
      )}
      formValues={funnelRule}
      isFetchingFormValues={isFetchingFunnelRule}
      isSubmitting={isUpdatingFunnelRule}
      onSubmit={async (data: FunnelRuleFormValues) => {
        try {
          await updateFunnelRule({
            ...data,
            id: funnelId,
          }).unwrap()

          dispatch(
            openToast({
              message: t('analytics:event.save_success'),
            })
          )
        } catch (e) {
          handleError(e)
        }
      }}
    />
  )
}

export default FunnelEdit
