import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { formatCurrency, getIntSafe } from '@shared/lib/utils/number'
import type { TableColumn } from '@shared/ui/table'
import type { UsageDataItemType } from '@widgets/usageManagement/SendingAmount/lib/helper'
import {
  TABLE_DATE_COLUMN_RATION,
  TABLE_DATE_COLUMN_SPAN,
} from '@widgets/usageManagement/SendingAmount/model/constant'

const useDetailColumns = () => {
  const { t } = useTranslation(['common', 'usageManagement'])
  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const columns: TableColumn<UsageDataItemType>[] = useMemo(
    () => [
      {
        headerName: 'workflow',
        field: 'triggerGroupName',
        colRatio: TABLE_DATE_COLUMN_RATION / TABLE_DATE_COLUMN_SPAN,
      },
      {
        headerName: 'campaign',
        field: 'campaignDetails',
        colRatio: TABLE_DATE_COLUMN_RATION / TABLE_DATE_COLUMN_SPAN,
        valueFormatter: campaignDetails =>
          campaignDetails.map(campaignDetail => (
            <Box key={campaignDetail.campaignName} py={2.5}>
              {campaignDetail.campaignName}
            </Box>
          )),
      },
      {
        headerName: 'third_party_app',
        field: 'campaignDetails',
        colRatio: TABLE_DATE_COLUMN_RATION / TABLE_DATE_COLUMN_SPAN,
        valueFormatter: campaignDetails =>
          campaignDetails.map(campaignDetail => (
            <Box
              key={`${campaignDetail.thirdPartyAppIntegrationType}_${campaignDetail.campaignId}`}
              py={2.5}
            >
              {getThirdPartyProviderName(
                campaignDetail.thirdPartyAppIntegrationType
              )}
            </Box>
          )),
      },
      {
        headerName: 'email_sent_counts',
        field: 'campaignDetails',
        align: 'right',
        valueFormatter: campaignDetails =>
          campaignDetails.map(campaignDetail => (
            <Box
              key={`${campaignDetail.thirdPartyAppIntegrationType}_${campaignDetail.campaignId}`}
              py={2.5}
            >
              {campaignDetail.mediumType === 'email'
                ? t('common:unit.sms_email_count_number', {
                    value: getIntSafe(
                      campaignDetail.actionCount,
                      '-'
                    ).toLocaleString(),
                  })
                : '-'}
            </Box>
          )),
      },
      {
        headerName: 'sms_reception_counts',
        field: 'campaignDetails',
        align: 'right',
        valueFormatter: campaignDetails =>
          campaignDetails.map(campaignDetail => (
            <Box
              key={`${campaignDetail.thirdPartyAppIntegrationType}_${campaignDetail.campaignId}`}
              py={2.5}
            >
              {campaignDetail.mediumType === 'sms'
                ? t('common:unit.sms_email_count_number', {
                    value: getIntSafe(
                      campaignDetail.actionCount,
                      '-'
                    ).toLocaleString(),
                  })
                : '-'}
            </Box>
          )),
      },
      {
        headerName: 'detail',
        field: 'campaignDetails',
        align: 'right',
        valueFormatter: campaignDetails =>
          campaignDetails.map(campaignDetail => (
            <Box
              key={campaignDetail.campaignId}
              whiteSpace="nowrap"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-end"
              py={2.5}
            >
              {campaignDetail.detail.map((detail, index) => {
                const usedSmsPoints = getIntSafe(
                  detail.point,
                  '-'
                ).toLocaleString()
                const totalCost = formatCurrency(detail.totalPrice, {
                  defaultValue: '$ -',
                })

                if (campaignDetail.mediumType === 'sms') {
                  return (
                    <Box key={`${campaignDetail.mediumType}_${index}`}>
                      {t('usageManagement:sms_sending_cost_detail', {
                        count_value: getIntSafe(
                          detail.count,
                          '$ -'
                        ).toLocaleString(),
                        point_value: usedSmsPoints,
                        cost_uint_value: formatCurrency(campaignDetail.price),
                        total_cost_value: totalCost,
                      })}
                    </Box>
                  )
                }

                return (
                  <Box key={`${campaignDetail.mediumType}_${index}`}>
                    {t('usageManagement:sending_cost_detail', {
                      count_value: getIntSafe(
                        detail.count,
                        '$ -'
                      ).toLocaleString(),
                      cost_uint_value: formatCurrency(campaignDetail.price),
                      total_cost_value: totalCost,
                    })}
                  </Box>
                )
              })}
            </Box>
          )),
      },
    ],
    [getThirdPartyProviderName, t]
  )

  return columns
}

export default useDetailColumns
