import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from '@mui/material/IconButton'

import type { MsgSender } from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { formatDateTimeDisplay } from '@shared/lib/utils/time'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import type { TableColumn } from '@shared/ui/table'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

type UseWorkflowSenderListColumnsProps = {
  onDelete: (id: number) => void
}

export const useWorkflowSenderListColumns = ({
  onDelete,
}: UseWorkflowSenderListColumnsProps) => {
  const { t } = useTranslation(['table', 'workflow'])

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const columns = useMemo<TableColumn<MsgSender>[]>(
    () => [
      {
        field: 'integrationType',
        headerName: t('workflow:senders.fields.integration_type.label'),
        colRatio: 2,
        valueFormatter: value => getThirdPartyProviderName(value),
      },
      {
        field: 'name',
        headerName: t('workflow:senders.fields.name.label'),
        colRatio: 2,
      },
      {
        field: 'email',
        headerName: t('workflow:senders.fields.email.label'),
        colRatio: 2,
      },
      {
        field: 'createdAt',
        headerName: t('table:created_time'),
        valueFormatter: value => formatDateTimeDisplay(value),
        colRatio: 2,
      },
      {
        field: 'creatorName',
        headerName: t('table:creator'),
        valueFormatter: value => value || '-',
        colRatio: 2,
      },
      {
        field: 'id',
        headerName: '',
        valueFormatter: (id: number) => (
          <Tooltip title={t('common:delete')}>
            <IconButton size="small" onClick={() => onDelete(id)}>
              <Icon icon={ICON.trashAlt} color={theme.colors.textPrimaryBlue} />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [getThirdPartyProviderName, onDelete, t]
  )

  return columns
}
