import {
  ThirdPartyAppProvider,
  useGetAudienceRuleUserLatestExportIterableQuery,
  useGetAudienceRuleUserLatestExportQuery,
} from '@shared/api/rtkQuery'

export const useGetAudienceRuleUserLatestExport = (
  provider: ThirdPartyAppProvider
):
  | typeof useGetAudienceRuleUserLatestExportIterableQuery
  | typeof useGetAudienceRuleUserLatestExportQuery => {
  switch (provider) {
    case 'every8d':
    case 'every8d_exclusive':
      return useGetAudienceRuleUserLatestExportQuery as typeof useGetAudienceRuleUserLatestExportQuery
    case 'iterable':
      return useGetAudienceRuleUserLatestExportIterableQuery as typeof useGetAudienceRuleUserLatestExportIterableQuery
    case 'maac':
    case 'super8':
      return useGetAudienceRuleUserLatestExportQuery as typeof useGetAudienceRuleUserLatestExportQuery
    // omnichat 不支援匯出
    case 'omnichat':
      return (() => ({
        data: undefined,
      })) as typeof useGetAudienceRuleUserLatestExportQuery

    default:
      throw new Error(`Invalid provider: ${provider}`)
  }
}

export default useGetAudienceRuleUserLatestExport
