import { $isElementNode, ElementNode, LexicalNode } from 'lexical'

export const getAllNodes = (root: ElementNode): Array<LexicalNode> => {
  const nodes = []
  let child = root.getFirstChild()

  while (child !== null) {
    nodes.push(child)

    if ($isElementNode(child)) {
      const subChildrenNodes = getAllNodes(child)

      nodes.push(...subChildrenNodes)
    }
    child = child.getNextSibling()
  }

  return nodes
}
