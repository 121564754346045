import { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'

import type { FunnelChartData } from '@shared/api/rtkQuery'
import { COLORS, getLongestSeriesLenIdx } from '@shared/ui/charts'
import IndicatorList from '@shared/ui/charts/_shared/IndicatorList'
import { checkDataExist } from '@widgets/analytics/event/_shared/ui/EventRuleChart'
import type { FunnelChart } from '@widgets/analytics/funnel/_shared'
import {
  BarChart,
  BarChartDisplayControl,
  RangeScrollbar,
} from '@widgets/analytics/funnel/_shared/ui/FunnelForm/DateAndChart'
import { DEFAULT_TOP_N_TO_SHOW } from '@widgets/analytics/funnel/_shared/ui/FunnelForm/DateAndChart'
import { getMaxVisibleGroupNum } from '@widgets/analytics/funnel/_shared/ui/FunnelForm/DateAndChart/helpers'

type Props = {
  previewData: FunnelChart['funnel_chart']
}

const BarChartControl = ({ previewData }: Props) => {
  const { chart: data, totalConversionData } = previewData

  const [displayRangeData, setDisplayRangeData] =
    useState<FunnelChartData>(data)
  const [startIdx, setStartIdx] = useState(0)
  const [visibilityFlags, setVisibilityFlags] = useState<boolean[]>([])

  const colors = COLORS.map((color, idx) =>
    visibilityFlags[idx] ? color : undefined
  ).filter(Boolean) as string[]

  /*
    最後要放在 chart 上的資料會受到兩個因素控制
    - displayRangeData 決定 x 軸顯示資料的範圍
    - visibilityFlags 決定顯示 grouped 資料的範圍
  */
  const visibleData = useMemo(
    () => ({
      categories: displayRangeData.categories,
      series: displayRangeData.series
        .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
        .filter(Boolean) as FunnelChartData['series'],
    }),
    [displayRangeData, visibilityFlags]
  )

  useEffect(() => {
    setStartIdx(0)

    if (data.categories.length <= 0) {
      setDisplayRangeData({ categories: [], series: [] })
      setVisibilityFlags([])

      return
    }

    const { maxLen } = getLongestSeriesLenIdx([data])

    const defaultVisibilityFlags = Array(maxLen)
      .fill(true)
      .map((_, idx) => (idx < DEFAULT_TOP_N_TO_SHOW ? true : false))

    setVisibilityFlags(defaultVisibilityFlags)

    setDisplayRangeData(data)
  }, [data, setVisibilityFlags, setDisplayRangeData])

  const displayIndicators = [
    {
      indicator: 'conversion_rate_total',
      value: `${totalConversionData.conversionRate.toFixed(0)}%`,
    },
    {
      indicator: 'conversion_number_total',
      value: totalConversionData.conversionNumber,
    },
  ]

  const numOfCategories = visibleData.categories.length
  const maxDisplayDataNum = getMaxVisibleGroupNum(visibleData.series.length)
  const hasRangeScrollbar = visibleData.categories.length > maxDisplayDataNum

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <IndicatorList indicatorList={displayIndicators} />

        <BarChartDisplayControl
          previewData={previewData}
          visibilityFlags={visibilityFlags}
          setVisibilityFlags={setVisibilityFlags}
        />
      </Box>

      <BarChart
        startIdx={startIdx}
        data={visibleData}
        colors={colors}
        size="small"
        maxVisibleChartsNum={maxDisplayDataNum}
      />

      {checkDataExist([data]) && hasRangeScrollbar && (
        <RangeScrollbar
          lengthOfData={numOfCategories}
          setStartIdx={setStartIdx}
          maxVisibleChartsNum={maxDisplayDataNum}
        />
      )}
    </>
  )
}
export default BarChartControl
