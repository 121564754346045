import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { EdmProvider, useGetMsgChannelListQuery } from '@shared/api/rtkQuery'
import useThirdPartyProviderName from '@shared/lib/hooks/useThirdPartyProviderName'
import { THIRD_PARTY_MAX_COUNT } from '@shared/model/constants/thirdParty'
import type { Option } from '@shared/ui/Dropdown/DropdownList'
import type { FormField } from '@shared/ui/forms'

import { selectIntegrationTypeList } from '../models/selectors'
import type { FormValues } from '../models/types'

export const useWorkflowSenderFormFields = () => {
  const { integrationTypeList, isLoading } = useGetMsgChannelListQuery(
    { page: 1, perPage: THIRD_PARTY_MAX_COUNT, mediumType: 'email' },
    { selectFromResult: selectIntegrationTypeList }
  )

  const { getThirdPartyProviderName } = useThirdPartyProviderName()

  const options: Option<EdmProvider>[] = useMemo(
    () =>
      integrationTypeList.map(value => ({
        label: getThirdPartyProviderName(value),
        value: value as EdmProvider,
      })),
    [getThirdPartyProviderName, integrationTypeList]
  )

  const { t } = useTranslation(['workflow', 'common'])

  const formFields = useMemo<FormField<FormValues>[]>(
    () => [
      {
        name: 'integrationType',
        component: 'SelectField',
        props: {
          label: t('workflow:senders.fields.integration_type.label'),
          options,
          placeholder: t(
            'workflow:senders.fields.integration_type.placeholder'
          ),
          isDisabled: isLoading,
        },
        validateSchema: yup.string().required(t('common:errors.required')),
      },
      {
        name: 'name',
        component: 'TextField',
        props: {
          label: t('workflow:senders.fields.name.label'),
          placeholder: t('workflow:senders.fields.name.placeholder'),
          description: t('workflow:senders.fields.name.description'),
        },
        validateSchema: yup
          .string()
          .required(t('common:errors.required'))
          .max(50, t('workflow:senders.fields.name.errors.max')),
      },
      {
        name: 'email',
        component: 'TextField',
        props: {
          label: t('workflow:senders.fields.email.label'),
          placeholder: t('workflow:senders.fields.email.placeholder'),
          description: t('workflow:senders.fields.email.description'),
        },
        validateSchema: yup
          .string()
          .email(t('common:errors.email'))
          .required(t('common:errors.required')),
      },
      {
        name: 'confirm',
        component: 'CheckboxField',
        props: {
          label: t('workflow:senders.fields.confirm.label'),
          description: t('workflow:senders.fields.confirm.description'),
        },
        validateSchema: yup
          .boolean()
          .required()
          .isTrue(t('workflow:senders.fields.confirm.errors.is_true')),
      },
    ],
    [isLoading, options, t]
  )

  return formFields
}

export default useWorkflowSenderFormFields
