import EmptyTags from './EmptyTags'
import ProductTagsTable from './ProductTagsTable'
import SharedStateProvider from './SharedStateContext'
import WordCloud from './WordCloud'

type Props = {
  productId: string
}

const Overview = ({ productId }: Props) => {
  return (
    <SharedStateProvider>
      <EmptyTags />
      <WordCloud productId={productId} />
      <ProductTagsTable productId={productId} />
    </SharedStateProvider>
  )
}

export default Overview
