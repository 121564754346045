import { useState } from 'react'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import theme from '@theme'

export const Caret = styled.span<{
  direction?: 'right' | 'down'
  color?: string
}>`
  display: inline-block;

  margin-left: 2px;

  border: 8px solid transparent;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-left-color: ${({ color, theme }) =>
    color || theme.colors.textPrimaryBlue};

  transform-origin: 4px;
  transform: ${({ direction }) =>
    direction === 'down' ? 'rotate(90deg)' : 'rotate(0)'};
  transition: transform 0.3s;
`
type SummarySize = 'md' | 'lg'

const summarySizeMap: {
  [name in SummarySize]: { height: number; gutter: number }
} = {
  md: { height: 40, gutter: 0.5 },
  lg: { height: 56, gutter: 1 },
}

export const Summary = styled.h2<{
  isCollapse: boolean
  color?: string
  bgColor?: string
  size: SummarySize
}>`
  display: flex;
  align-items: center;

  padding: 0 16px;
  height: ${({ size }) => summarySizeMap[size].height}px;

  border-radius: ${({ isCollapse }) => (isCollapse ? '4px' : '4px 4px 0 0')};
  background-color: ${({ theme, bgColor }) =>
    bgColor || theme.colors.textPrimaryBlue};
  color: ${({ theme, color }) => color || theme.colors.white};

  cursor: pointer;
`

const Content = styled.section<{ isCollapse: boolean }>`
  overflow: hidden;
  height: ${({ isCollapse }) => (isCollapse ? 0 : '100%')};
  border-radius: 0 0 4px 4px;
`

type Props = {
  label: string
  className?: string
  color?: string
  bgColor?: string
  summarySize?: 'md' | 'lg'
  isDefaultCollapse?: boolean
  labelOnRightSide?: string
  children: React.ReactNode
}

const Accordion = ({
  label,
  children,
  className,
  color,
  bgColor,
  summarySize = 'md',
  isDefaultCollapse,
  labelOnRightSide = '',
}: Props) => {
  const [isCollapse, setIsCollapse] = useState(isDefaultCollapse || false)

  return (
    <Box className={className} width="100%">
      <Summary
        onClick={() => {
          setIsCollapse(prevState => !prevState)
        }}
        isCollapse={isCollapse}
        color={color}
        bgColor={bgColor}
        size={summarySize}
      >
        <Box
          display="flex"
          justifyContent="center"
          ml={summarySize === 'lg' ? summarySizeMap[summarySize].gutter : 0}
          mr={summarySizeMap[summarySize].gutter}
        >
          <Caret
            color={color || theme.colors.white}
            direction={isCollapse ? 'right' : 'down'}
          />
        </Box>
        <Box flexGrow={1}>{label}</Box>

        <Box display="flex">
          <Box component="p" mr={1} color={theme.colors.brightBlue}>
            {labelOnRightSide}
          </Box>
        </Box>
      </Summary>
      <Content isCollapse={isCollapse}>{children}</Content>
    </Box>
  )
}

export default Accordion
