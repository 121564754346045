import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import aiMarketingAssistant from '../public/locales/zh-TW/aiMarketingAssistant.json'
import analytics from '../public/locales/zh-TW/analytics.json'
import audience from '../public/locales/zh-TW/audience.json'
import common from '../public/locales/zh-TW/common.json'
import consumptions from '../public/locales/zh-TW/consumptions.json'
import contentTemplate from '../public/locales/zh-TW/contentTemplate.json'
import dashboard from '../public/locales/zh-TW/dashboard.json'
import dataImport from '../public/locales/zh-TW/dataImport.json'
import dateAndChart from '../public/locales/zh-TW/dateAndChart.json'
// @deprecated, use analytics
import eventAnalytics from '../public/locales/zh-TW/eventAnalytics.json'
import iam from '../public/locales/zh-TW/iam.json'
import insight from '../public/locales/zh-TW/insight.json'
import metadata from '../public/locales/zh-TW/metadata.json'
import rfm from '../public/locales/zh-TW/rfm.json'
import settings from '../public/locales/zh-TW/settings.json'
import table from '../public/locales/zh-TW/table.json'
import tag from '../public/locales/zh-TW/tag.json'
import usageManagement from '../public/locales/zh-TW/usageManagement.json'
import userProfile from '../public/locales/zh-TW/userProfile.json'
import workflow from '../public/locales/zh-TW/workflow.json'

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common'
    resources: {
      aiMarketingAssistant: typeof aiMarketingAssistant
      analytics: typeof analytics
      audience: typeof audience
      common: typeof common
      consumptions: typeof consumptions
      contentTemplate: typeof contentTemplate
      dashboard: typeof dashboard
      dataImport: typeof dataImport
      dateAndChart: typeof dateAndChart
      eventAnalytics: typeof eventAnalytics
      iam: typeof iam
      insight: typeof insight
      metadata: typeof metadata
      rfm: typeof rfm
      settings: typeof settings
      table: typeof table
      tag: typeof tag
      usageManagement: typeof usageManagement
      userProfile: typeof userProfile
      workflow: typeof workflow
    }
  }
}

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(XHR)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'zh-TW',
    interpolation: {
      // react already safes from xss
      escapeValue: false,
    },
    /*
      Language codes to lookup, given set language is
      'en-US': 'all' --> ['en-US', 'en', 'dev'],
      'currentOnly' --> 'en-US',
      'languageOnly' --> 'en'
      @default 'all'
     */
    load: 'currentOnly',
    /*
      ns 有定義的 namespace 會一次載入，就不會等頁面發現要用到 audience 才去載 audience.json
      而在畫面短暫看到 key
    */
    ns: [
      'common',
      'rfm',
      'audience',
      'tag',
      'table',
      'metadata',
      'analytics',
      'workflow',
      'userProfile',
    ],
    defaultNS: 'common',

    // react i18next special options
    // learn more https://react.i18next.com/latest/i18next-instance
    react: {
      useSuspense: false,
    },

    // debug: true, // dev only
  })

export default i18n
