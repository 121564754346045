import styled from '@emotion/styled'

export const ConfigurationListItem = styled.li`
  display: flex;
  align-items: center;

  min-height: 64px;
`

export const HighlightText = styled.span`
  color: ${({ theme }) => theme.colors.brightBlue};
`

export const SentLimitHint = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.chartRed};
`

export const INPUT_WIDTH = 120
export const SUBMIT_BUTTON_WIDTH = 200
export const FONT_WEIGHT = 500
