import * as yup from 'yup'

import i18n from '@i18n'
import {
  MAX_LIST_NAME_LENGTH,
  regexAudienceListName,
} from '@shared/model/constants/validation'

export const audienceListNameSchema = yup
  .string()
  .required(() => i18n.t('common:errors.required'))
  .matches(regexAudienceListName, () => i18n.t('common:errors.invalid_symbol'))
  .max(MAX_LIST_NAME_LENGTH, () =>
    i18n.t('audience:v3.create_dialog_warning', { words: MAX_LIST_NAME_LENGTH })
  )

export default audienceListNameSchema
