import React, { type FormEvent } from 'react'
import Box from '@mui/material/Box'
import type { FormikErrors, FormikTouched, FormikValues } from 'formik'

import { FIELD_COMPONENT_MAP } from './models/constants'
import type { FormField } from './models/types'
import type { FieldComponent } from './fields'

type FormBuilderProps<Values extends FormikValues> = {
  children?: React.ReactNode
  component?: React.ElementType
  errors?: FormikErrors<Values>
  fields: FormField<Values>[]
  onFieldChange?: (name: string, values: unknown) => void
  onFormSubmit?: (e?: FormEvent<HTMLFormElement> | undefined) => void
  touched?: FormikTouched<Values>
  values: Values
}

export const FormBuilder = <Values extends FormikValues>({
  children,
  component = 'form',
  errors = {},
  fields,
  onFieldChange,
  onFormSubmit: handleSubmit,
  touched = {},
  values,
}: FormBuilderProps<Values>) => (
  <Box component={component} onSubmit={handleSubmit}>
    {fields.map((x, index) => {
      const Field = FIELD_COMPONENT_MAP[x.component] as FieldComponent

      return (
        <Box key={String(x.name)} sx={{ mt: index === 0 ? 0 : 2 }}>
          <Field
            error={touched[x.name] && (errors[x.name] as string)}
            id={String(x.name)}
            isFullWidth
            name={String(x.name)}
            onChange={
              ((value: unknown) => {
                onFieldChange?.(String(x.name), value)
                // 為了讓 CheckboxField 在 onChange 回傳 boolean
                // 會使 onChange 型別被推斷成 ((value: unknown) => void) | ((value: boolean) => void)
                // 導致傳入任何函式都無法通過型別檢查，只能先透過 as any 跳過
              }) as any
            }
            value={values[x.name]}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...x.props}
          />
        </Box>
      )
    })}

    {children}
  </Box>
)

export default FormBuilder
