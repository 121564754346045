import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import type { ParseKeys } from 'i18next'

import { ICON } from '@shared/model/constants/styles'
import Card from '@shared/ui/Card'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import type { TagSourceType } from '@widgets/tag/_shared/type'

import { tagLocalesMap } from './constant'

export type TagType = {
  tagSourceType: TagSourceType
  isEnable: boolean
  tooltipKey?: string
}

type Props = {
  tagTypes: TagType[]
  value: TagSourceType
  onChange?: (tagType: TagSourceType) => void
}

const TagTypeRadioGroup = ({ tagTypes, value, onChange }: Props) => {
  const { t } = useTranslation(['tag', 'common'])

  const handleOnChange = (
    _e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (onChange) {
      onChange(value as TagSourceType)
    }
  }

  return (
    <Card mb={4}>
      <Box
        fontWeight={500}
        fontSize={14}
        lineHeight="20px"
        mb={2.5}
        sx={theme => ({
          color: theme.colors.lightNavyBlue,
        })}
      >
        {t('tag:create_step_select_tag_type')}
      </Box>
      <RadioGroup
        name="importTagType"
        row
        value={value}
        onChange={handleOnChange}
      >
        {tagTypes.map(tag => {
          const i18nType: ParseKeys<['tag']> = `tag:tag_type.${
            tagLocalesMap[tag.tagSourceType].nameKey
          }` as ParseKeys<['tag']>

          const i18nTooltipTitle = (tag.tooltipKey ||
            `tag:tag_type_tooltip.${
              tagLocalesMap[tag.tagSourceType].tooltipKey
            }`) as ParseKeys<['tag']>

          return (
            <FormControlLabel
              disabled={!tag.isEnable}
              key={tag.tagSourceType}
              value={tag.tagSourceType}
              control={<Radio color="primary" size="small" />}
              label={
                <Box display="flex" alignItems="center" mr={2}>
                  {t(i18nType)}
                  &nbsp;
                  <Tooltip title={<span>{t(i18nTooltipTitle)}</span>}>
                    <Box>
                      <Icon
                        icon={ICON.infoCircle}
                        color="inherit"
                        fontSize="small"
                      />
                    </Box>
                  </Tooltip>
                </Box>
              }
            />
          )
        })}
      </RadioGroup>
    </Card>
  )
}

export default TagTypeRadioGroup
