export const trialTemplate: { id: string; name: string }[] = [
  {
    id: '0001',
    name: '新註冊會員歡迎問候',
  },
  {
    id: '0002',
    name: '會員滿額贈禮',
  },
  {
    id: '0003',
    name: '會員點數到期通知',
  },
  {
    id: '0004',
    name: 'LINE 好友限定專屬優惠禮券',
  },
  {
    id: '0005',
    name: '新品上市通知',
  },
]
