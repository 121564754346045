import { createSelector } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'

import type { UserJourneyEvent } from '@shared/api/rtkQuery'

import transformUserJourneyToEntity from './transformUserJourneyToEntity'

const INIT_USER_JOURNEY_EVENT_LIST_DATA: UserJourneyEvent['events'] = []

type State = {
  data?: UserJourneyEvent['events']
  isLoading: boolean
}

export const selectUserJourneyEvents = createSelector(
  [(state: State) => state.data, (state: State) => state.isLoading],
  (events = INIT_USER_JOURNEY_EVENT_LIST_DATA, isLoading) => ({
    userJourneyEvents: events,
    userJourneyEventEntity: transformUserJourneyToEntity(events),
    isLoading,
  }),
  { memoizeOptions: { resultEqualityCheck: isEqual } }
)

export default selectUserJourneyEvents
