import type {
  InsightSummary,
  MediumType,
  ThirdPartyAppProvider,
} from '@shared/api/rtkQuery'
import { getFloatSafe, getIntSafe } from '@shared/lib/utils/number'
import { NoWrap } from '@widgets/insight/ui/_shared/styles'

type InsightSummaryKey = keyof InsightSummary['statisticSummaries'][number]
export type InsightSummaryValue =
  | InsightSummary['statisticSummaries'][number][InsightSummaryKey]

export const ALL_PROVIDERS = 'allProviders'

export const insightValuesFormatterByProvider = (
  insightValue: {
    value: number | string
    mediumType?: MediumType
    provider: ThirdPartyAppProvider
  },
  option: {
    isPercentage?: boolean
    allowedMedium?: {
      [type in MediumType]?: (ThirdPartyAppProvider | typeof ALL_PROVIDERS)[]
    }
  }
) => {
  const { isPercentage = false, allowedMedium } = option
  const { value, mediumType, provider } = insightValue

  if (!String(value) || String(value) === 'undefined' || !mediumType) {
    return '-'
  }

  const isProviderSupported =
    allowedMedium &&
    (allowedMedium[mediumType]?.includes(ALL_PROVIDERS) ||
      allowedMedium[mediumType]?.includes(provider))

  if (!isProviderSupported) {
    return '-'
  }

  if (isPercentage) {
    return <NoWrap>{`${getFloatSafe(Number(value) * 100).toFixed(2)}%`}</NoWrap>
  }

  return <NoWrap>{getIntSafe(value).toLocaleString()}</NoWrap>
}

export const insightValueFormatter = (
  value: InsightSummaryValue,
  options?: { isPercentage?: boolean }
) => {
  if (!String(value) || String(value) === 'undefined') {
    return `-`
  }

  if (options?.isPercentage) {
    return <NoWrap>{`${getFloatSafe(Number(value) * 100).toFixed(2)}%`}</NoWrap>
  }

  return <NoWrap>{getIntSafe(value).toLocaleString()}</NoWrap>
}
