import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Box from '@mui/material/Box'

import { useGetUserProfileTagCategoriesQuery } from '@shared/api/rtkQuery'
import { Card } from '@shared/ui'
import SectionTitle from '@shared/ui/SectionTitle'
import { LoadingSkeleton } from '@widgets/userProfile/_shared'

import TagDetail from './TagDetail'

// 10 筆標籤的最大高度
const MAX_GRADING_TAG_BLOCK_HEIGHT = 500

const CustomTag = () => {
  const { t } = useTranslation(['tag', 'userProfile', 'common'])

  const { userId: cdpUserId } = useParams<{ userId: string }>()
  const {
    data: tagCategoriesData = [],
    isFetching: isTagCategoriesDataFetching,
  } = useGetUserProfileTagCategoriesQuery({ cdpUserId })

  const hasNoData =
    tagCategoriesData.length === 0 && !isTagCategoriesDataFetching

  if (isTagCategoriesDataFetching) {
    return (
      <Box mb={5}>
        <SectionTitle title={t('tag:tag_group')} />
        <LoadingSkeleton height={125} />
      </Box>
    )
  }

  return (
    <Box>
      <SectionTitle title={t('tag:tag_group')} />
      {hasNoData && (
        <Card mb={5}>
          <Box fontSize={16} lineHeight="24px" fontWeight={600} mb={2}>
            {t('tag:no_group')}
          </Box>
          <Box
            fontSize={15}
            lineHeight="21px"
            fontWeight={500}
            sx={theme => ({
              color: theme.colors.textSecondBlue,
            })}
          >
            {t('common:empty_data')}
          </Box>
        </Card>
      )}
      {tagCategoriesData.map(tagCategory => {
        const hasTag = Boolean(tagCategory.tags)
        const hasBehaviorTag = Boolean(tagCategory.behaviorTags)

        return (
          <Card key={tagCategory.tagGroupId} mb={5}>
            <Box
              maxHeight={MAX_GRADING_TAG_BLOCK_HEIGHT}
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              <Box fontSize={16} lineHeight="24px" fontWeight={600} mb={2}>
                {tagCategory.tagGroupTitle || t('tag:no_group')}
              </Box>
              {hasTag && (
                <Box>
                  {tagCategory.tags?.map(tag => (
                    <Box key={`tag_${tag.id}`} mb={3}>
                      <TagDetail name={tag.title} list={[tag.value]} />
                    </Box>
                  ))}
                </Box>
              )}
              {hasBehaviorTag && (
                <Box>
                  {tagCategory.behaviorTags?.map(behaviorTag => (
                    <Box key={`behaviorTag_${behaviorTag.id}`} mb={3}>
                      <TagDetail
                        key={behaviorTag.id}
                        name={behaviorTag.title}
                        list={behaviorTag.values}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Card>
        )
      })}
    </Box>
  )
}

export default CustomTag
