import type { PayloadAction } from '@reduxjs/toolkit'
import cloneDeep from 'lodash/cloneDeep'
import type { Reducer } from 'use-immer'

import {
  getDefaultAttributeParams,
  getDimOperators,
  metadataTypeAttributeOperatorMap,
  resolveUiOperator,
} from '@features/filters/AttributeFilter'
import type {
  Aggregator,
  AttributeDimOperator,
  AttributeOperator,
  AttributeParams,
  EventRuleNode,
  EventRuleTimeRangeUnit,
  MetadataType,
} from '@shared/api/rtkQuery'
import { DEFAULT_EVENT_TABLE_NAME } from '@shared/lib/utils/metadata'
import type { NumberOrString } from '@shared/lib/utils/type'
import { getRangeType } from '@shared/ui/DateRangePicker'
import { defaultAggregators } from '@widgets/analytics/_shared'

import { getToday } from '../utils'
import { AGGREGATOR_FIELD_MAP, MAX_TOP_N } from './constants'

export const createInitialEventRuleNode = (): EventRuleNode => {
  const firstAggregator = defaultAggregators[0]
  const { source, field } = AGGREGATOR_FIELD_MAP.get(firstAggregator) || {
    source: DEFAULT_EVENT_TABLE_NAME,
    field: '',
  }
  return {
    statisticMeasures: [
      {
        aggregator: firstAggregator,
        source,
        field,
        eventName: '',
        attributeFilter: {
          nodes: [],
          combination: 'and',
        },
      },
    ],
    groupByFields: [],
    timeRangeType: 'absolute_between',
    timeRangeParams: [
      getToday().nowStart.toISOString(),
      getToday().nowEnd.toISOString(),
    ],
    timeRangeUnit: 'day',
    timeRangeTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    topN: MAX_TOP_N,
  }
}

export const initializer = (measure?: EventRuleNode) => {
  return measure || createInitialEventRuleNode()
}

export type EventRuleFilterAction =
  | PayloadAction<{ eventName: string }, 'addStatisticMeasuresItem'>
  | PayloadAction<{ index: number }, 'removeStatisticMeasuresItem'>
  | PayloadAction<{ targetIndex: number; eventName: string }, 'updateEventName'>
  | PayloadAction<
      {
        targetIndex: number
        aggregator: Aggregator
        field?: string
        source?: string
      },
      'updateAggregator'
    >
  | PayloadAction<
      { targetIndex: number; field: string; source: string },
      'updateGroupByFields'
    >
  | PayloadAction<{ targetIndex: number }, 'removeGroupByFields'>
  | PayloadAction<{ field: string; source: string }, 'addGroupByFields'>
  | PayloadAction<EventRuleNode | undefined, 'reset'>
  | PayloadAction<
      {
        source: string
        field: string
        dataType: MetadataType
        repeated: boolean
        parentIndex: number
      },
      'addAttributeFilter'
    >
  | PayloadAction<
      {
        parentIndex: number
        targetIndex: number
        field: string
        source: string
        dimOperator?: AttributeDimOperator
        operator: AttributeOperator
        params?: AttributeParams
      },
      'updateAttributeFilter'
    >
  | PayloadAction<
      { targetIndex: number; parentIndex: number },
      'removeAttributeFilter'
    >
  | PayloadAction<
      { timeRangeParams: NumberOrString[] },
      'updateTimeRangeParams'
    >
  | PayloadAction<
      { timeRangeUnit: EventRuleTimeRangeUnit },
      'updateTimeRangeUnit'
    >
  | PayloadAction<{ index: number }, 'toggleAttributeFilterCombination'>
  | PayloadAction<{ index: number }, 'duplicateStatisticMeasuresItem'>
  | PayloadAction<
      { parentIndex: number; targetIndex: number },
      'duplicateAttributeFilter'
    >

export const eventRuleFilterReducer: Reducer<
  EventRuleNode,
  EventRuleFilterAction
> = (draft, action) => {
  switch (action.type) {
    case 'reset':
      return initializer(action.payload)
    case 'addStatisticMeasuresItem': {
      const { eventName } = action.payload
      const [aggregator] = defaultAggregators
      const { field, source } = AGGREGATOR_FIELD_MAP.get(aggregator)!
      draft.statisticMeasures.push({
        eventName,
        aggregator,
        field,
        source,
        attributeFilter: {
          nodes: [],
          combination: 'and',
        },
      })
      break
    }
    case 'removeStatisticMeasuresItem': {
      const { index } = action.payload
      draft.statisticMeasures.splice(index, 1)
      break
    }
    case 'duplicateStatisticMeasuresItem': {
      const { index } = action.payload
      const copied = cloneDeep(draft.statisticMeasures[index])
      draft.statisticMeasures.splice(index, 0, copied)

      break
    }
    case 'updateEventName': {
      const { targetIndex, eventName } = action.payload
      const [aggregator] = defaultAggregators
      const { field } = AGGREGATOR_FIELD_MAP.get(aggregator)!
      draft.statisticMeasures[targetIndex].eventName = eventName
      draft.statisticMeasures[targetIndex].aggregator = aggregator
      draft.statisticMeasures[targetIndex].field = field
      draft.statisticMeasures[targetIndex].attributeFilter.nodes = []
      break
    }
    case 'updateAggregator': {
      const { targetIndex, aggregator, field, source } = action.payload
      draft.statisticMeasures[targetIndex].aggregator = aggregator

      if (field) {
        draft.statisticMeasures[targetIndex].field = field
        draft.statisticMeasures[targetIndex].source =
          source || DEFAULT_EVENT_TABLE_NAME

        return
      }

      // 選定到特定 aggregator (ex. 總次數) 需要覆寫 field 與 source
      if (defaultAggregators.includes(aggregator)) {
        const { field: predefinedField, source: predefinedSource } =
          AGGREGATOR_FIELD_MAP.get(aggregator)!
        draft.statisticMeasures[targetIndex].field = predefinedField
        draft.statisticMeasures[targetIndex].source = predefinedSource

        return
      }

      break
    }
    case 'updateGroupByFields': {
      const { targetIndex, field, source } = action.payload
      draft.groupByFields[targetIndex].field = field
      draft.groupByFields[targetIndex].source = source
      break
    }
    case 'removeGroupByFields': {
      const { targetIndex } = action.payload
      draft.groupByFields.splice(targetIndex, 1)
      break
    }
    case 'addGroupByFields':
      const { field, source } = action.payload
      draft.groupByFields.push({
        field,
        source,
      })
      break
    case 'addAttributeFilter': {
      const { source, field, dataType, repeated, parentIndex } = action.payload

      const [firstOperator] = metadataTypeAttributeOperatorMap[dataType]

      const [firstDimOperator] = getDimOperators(source, dataType, repeated)

      const defaultParams = getDefaultAttributeParams(dataType, firstOperator)

      draft.statisticMeasures[parentIndex].attributeFilter.nodes.push({
        type: 'condition',
        source,
        field,
        dimOperator: firstDimOperator,
        operator: resolveUiOperator(firstOperator),
        params: defaultParams,
      })
      break
    }
    case 'updateAttributeFilter': {
      const { parentIndex, targetIndex, ...otherParams } = action.payload

      draft.statisticMeasures[parentIndex].attributeFilter.nodes[targetIndex] =
        {
          type: 'condition',
          ...otherParams,
        }
      break
    }
    case 'removeAttributeFilter': {
      const { parentIndex, targetIndex } = action.payload
      draft.statisticMeasures[parentIndex].attributeFilter.nodes.splice(
        targetIndex,
        1
      )
      break
    }
    case 'duplicateAttributeFilter': {
      const { parentIndex, targetIndex } = action.payload
      const copied = cloneDeep(
        draft.statisticMeasures[parentIndex].attributeFilter.nodes[targetIndex]
      )
      draft.statisticMeasures[parentIndex].attributeFilter.nodes.splice(
        targetIndex,
        0,
        copied
      )
      break
    }
    case 'updateTimeRangeParams': {
      const { timeRangeParams } = action.payload
      draft.timeRangeType = getRangeType(timeRangeParams)
      draft.timeRangeParams = timeRangeParams
      break
    }
    case 'updateTimeRangeUnit': {
      const { timeRangeUnit } = action.payload
      draft.timeRangeUnit = timeRangeUnit
      break
    }
    case 'toggleAttributeFilterCombination': {
      const { index } = action.payload
      draft.statisticMeasures[index].attributeFilter.combination =
        draft.statisticMeasures[index].attributeFilter.combination === 'and'
          ? 'or'
          : 'and'
      break
    }
    default:
      return draft
  }
}

export default eventRuleFilterReducer
