import styled from '@emotion/styled'

import { LAYOUT } from '@shared/model/constants/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const BreadcrumbsActionBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${LAYOUT.breadcrumbsMarginBottom}px;
`
