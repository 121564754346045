import styled from '@emotion/styled'

export const Title = styled.h1`
  display: flex;
  align-items: center;

  margin-right: 10px;
  height: 44px;

  border-bottom: 1.5px solid transparent;

  color: ${({ theme }) => theme.colors.lightNavyBlue};
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.17px;
  line-height: normal;
`
