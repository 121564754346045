import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import trialDashboard from '@shared/assets/images/dashboard/trialDashboard.svg'
import Breadcrumbs from '@shared/ui/Breadcrumbs'
import FeatureIntroduction from '@shared/ui/FeatureIntroduction'

import useProductList from '../../hooks/useProductList'
import ProductListDialog from './ProductListDialog'

type Props = {
  title: string
  children: (productId: string) => React.ReactNode
  breadcrumbText?: string
}

export const AiMarketingLayout = ({
  title,
  children,
  breadcrumbText,
}: Props) => {
  const { t } = useTranslation(['aiMarketingAssistant'])
  const [productId, setProductId] = useState('')
  const {
    data: productList,
    totalCount,
    search,
    hasMore,
    isFetching,
    isSuccess,
    isLoading,
    onLoadMore,
    onSearchByName,
  } = useProductList()

  return (
    <>
      {!totalCount && (
        <FeatureIntroduction
          title={title}
          description={''}
          notice={t('aiMarketingAssistant:empty_desc')}
          imageUrl={trialDashboard}
        />
      )}
      <Box
        sx={{
          filter: totalCount ? 'none' : 'blur(4px)',
        }}
      >
        <Breadcrumbs>
          <Box>{breadcrumbText}</Box>
        </Breadcrumbs>

        <ProductListDialog
          data={productList}
          search={search}
          isLoading={isFetching}
          enabledInfiniteScrollLoader={(isSuccess && hasMore) || isLoading}
          onLoadMore={onLoadMore}
          onSearchByName={onSearchByName}
          onChange={setProductId}
        />
        {children(productId)}
      </Box>
    </>
  )
}

export default AiMarketingLayout
