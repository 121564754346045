import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { formatRTKQueryError } from '@entities/apiHandler'
import {
  PrivacyLevel,
  useCreateEventRuleGroupMutation,
  useCreateFunnelRuleGroupMutation,
} from '@shared/api/rtkQuery'
import { useAppDispatch, useAppSelector } from '@shared/lib/hooks'
import { openToast } from '@shared/model/slices'
import { AddButton } from '@shared/ui/buttons'
import theme from '@theme'
import {
  GROUP_UPPER_BOUNDARY,
  STATUS_CODE,
} from '@widgets/dashboard/_shared/constants'
import type { AnalyticsType } from '@widgets/dashboard/_shared/type'

import { AddGroupBtnWrapper } from '../style'
import ChartGroupDialog from './ChartGroupDialog'

type Props = {
  analyticsType: AnalyticsType
  privacyLevel: PrivacyLevel
}

const CreateChartGroup = ({ analyticsType, privacyLevel }: Props) => {
  const { t } = useTranslation(['dashboard', 'common'])
  const [isGroupAddDialogOpen, setIsGroupAddDialogOpen] =
    useState<boolean>(false)
  const currentUserEmail = useAppSelector(state => state.auth.userInfo.email)
  const iam = privacyLevel === 'public' ? null : { ownerId: currentUserEmail }

  const [createEventRuleGroup, { isLoading: isCreatingEventRuleGroup }] =
    useCreateEventRuleGroupMutation()

  const [createFunnelRuleGroup, { isLoading: isCreatingFunnelRuleGroup }] =
    useCreateFunnelRuleGroupMutation()

  const dispatch = useAppDispatch()

  const handleSubmit = async (name: string) => {
    try {
      if (analyticsType === 'funnel') {
        await createFunnelRuleGroup({ name, iam }).unwrap()
      } else {
        await createEventRuleGroup({ name, iam }).unwrap()
      }

      dispatch(
        openToast({
          message: t('common:save_success'),
          status: 'success',
        })
      )
    } catch (e) {
      const formatError = formatRTKQueryError(e)
      if (formatError.statusCode === STATUS_CODE.CREATE_MAX_COUNT) {
        dispatch(
          openToast({
            message: t('dashboard:error.create_max_count_warning', {
              upper_boundary: GROUP_UPPER_BOUNDARY,
            }),
            status: 'error',
          })
        )
        return
      }

      openToast({
        message: t('common:save_failure'),
        status: 'success',
      })
    }

    setIsGroupAddDialogOpen(false)
  }

  return (
    <>
      <AddGroupBtnWrapper>
        <AddButton
          label={t('dashboard:add_group')}
          size="small"
          bgColor="transparent"
          color={theme.colors.textPrimaryBlue}
          onClick={() => setIsGroupAddDialogOpen(true)}
        />
      </AddGroupBtnWrapper>
      <ChartGroupDialog
        name=""
        mode="add"
        isOpen={isGroupAddDialogOpen}
        isSubmitting={isCreatingEventRuleGroup || isCreatingFunnelRuleGroup}
        onClose={() => setIsGroupAddDialogOpen(false)}
        onSubmit={handleSubmit}
      />
    </>
  )
}
export default CreateChartGroup
