import Box from '@mui/material/Box'

import type { ProductKol } from '@shared/api/rtkQuery'
import Card from '@shared/ui/Card'
import theme from '@theme'

import Avatar from './components/Avatar'
import Information from './components/Information'
import Statistic from './components/Statistic'

type Props = { kolData: ProductKol }

const KolCard = ({ kolData }: Props) => {
  const allPlatformNames = kolData.media.map(v => v.name)
  const allFollowers = kolData.media.reduce((acc, cur) => acc + cur.follower, 0)

  return (
    <Card mb={5}>
      <Box display="flex" alignItems="center">
        <Box
          flex={3.5}
          display="flex"
          alignItems="flex-start"
          borderRight={`1px solid ${theme.colors.borderColor}`}
        >
          <Avatar
            thumbnailUrl={kolData.thumbnailUrl}
            contactUrl={kolData.contactUrl}
          />
          <Information
            name={allPlatformNames[0]}
            platformNames={allPlatformNames}
            tags={kolData.tags}
            allFollowers={allFollowers}
            media={kolData.media}
          />
        </Box>
        <Box pl={5} flex={1} width={270}>
          <Statistic
            scoring={kolData.scoring}
            engagementRate={
              kolData.statistic.engagementRate as Record<string, number>
            }
            viewsRate={kolData.statistic.viewsRate as Record<string, number>}
          />
        </Box>
      </Box>
    </Card>
  )
}
export default KolCard
