import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'

import {
  AUDIENCE_PREVIEWER_STICKY_TOP,
  AudiencePreviewerField,
  TotalCountPreviewer,
} from '@entities/previewers'
import { useSingleMeasureFilter } from '@features/filters/SingleMeasureFilter'
import {
  AutoAigcWithEventRule,
  BehaviorTagPayload,
  BehaviorTagSchedulingType,
  INIT_BEHAVIOR_TAG_VALUE,
  useCreateBehaviorTagMutation,
  useGetBehaviorTagQuery,
  useLazyGetPreviewBehaviorTagQuery,
} from '@shared/api/rtkQuery'
import { useAppDispatch } from '@shared/lib/hooks'
import { formatNumber } from '@shared/lib/utils/number'
import { CATEGORY, PAGE_ROOT } from '@shared/model/constants/routes'
import { openToast } from '@shared/model/slices'
import SectionTitle from '@shared/ui/SectionTitle'
import TagAigcProduct from '@widgets/tag/_shared/components/TagAigcProduct'
import TagInfo, { TagInfoValues } from '@widgets/tag/_shared/components/TagInfo'
import TagSingleMeasure from '@widgets/tag/_shared/components/TagSingleMeasure/TagSingleMeasure'
import { BEHAVIOR_TAG_PATH } from '@widgets/tag/_shared/constants'
import useHandleError from '@widgets/tag/_shared/hooks/useHandleApiError'
import useTagValidator from '@widgets/tag/_shared/hooks/useTagValidator'

type Props = {
  tagId: number
}

const Behavior = ({ tagId }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation(['tag', 'settings'])
  const [handleTagApiError] = useHandleError('behavior')
  const { tagDataValidator } = useTagValidator()

  const { values: state, onDispatch } = useSingleMeasureFilter()

  const { data: tagData, isLoading: isLoadingTag } = useGetBehaviorTagQuery(
    tagId,
    {
      skip: !tagId,
    }
  )
  const [createBehaviorTag, { isLoading: isCreating }] =
    useCreateBehaviorTagMutation()

  const [getTagPreview, { data: previewData, isFetching: isFetchingPreview }] =
    useLazyGetPreviewBehaviorTagQuery()

  const [tagValues, setTagValues] = useState<
    TagInfoValues<'behavior'> &
      Omit<BehaviorTagPayload, keyof TagInfoValues<'behavior'>>
  >(INIT_BEHAVIOR_TAG_VALUE)

  useEffect(() => {
    setTagValues(prev => ({
      ...prev,
      autoAigcWithEventRule: {
        ...prev.autoAigcWithEventRule,
        eventName: state.eventName,
        timeRangeType: state.timeRangeType,
        timeRangeParams: state.timeRangeParams,
      },
    }))
  }, [state])

  useEffect(() => {
    if (!tagData) {
      return
    }

    setTagValues({
      ...tagData,
      schedulingType: tagData.schedulingType as BehaviorTagSchedulingType,
    })

    // 額外設定預設值
    onDispatch({
      type: 'setMeasureFilterState',
      payload: {
        eventName: tagData.autoAigcWithEventRule.eventName,
        timeRangeType: tagData.autoAigcWithEventRule.timeRangeType,
        timeRangeParams: tagData.autoAigcWithEventRule.timeRangeParams,
        eventOperator: '',
      },
    })
  }, [onDispatch, tagData])

  const handleTagCreate = async () => {
    try {
      const isValid = await tagDataValidator('behavior', tagValues)

      if (!isValid) {
        return
      }

      await createBehaviorTag(tagValues).unwrap()

      history.push(`/${CATEGORY.cdm}/${PAGE_ROOT.tags}/${BEHAVIOR_TAG_PATH}`)
    } catch (e) {
      handleTagApiError(e)
    }
  }

  const handleTagPreview = async () => {
    if (!tagValues.autoAigcWithEventRule) {
      return
    }
    try {
      await getTagPreview(
        tagValues.autoAigcWithEventRule as AutoAigcWithEventRule
      ).unwrap()
    } catch {
      dispatch(
        openToast({
          message: t('tag:failed_to_get_preview_data'),
          status: 'error',
        })
      )
    }
  }

  const handleAigcProductUpdate = (aigcProductValues: {
    isSelectAll: boolean
    selectedProductIds: string[]
  }) =>
    setTagValues({
      ...tagValues,
      autoAigcWithEventRule: {
        ...tagValues.autoAigcWithEventRule,
        relatedAllAigcTarget: aigcProductValues.isSelectAll,
        relatedAigcTargetIds: aigcProductValues.selectedProductIds,
      },
    })

  return (
    <Box>
      <SectionTitle title={t('tag:create_step_2_fill_tag_information')} />

      <Box sx={{ display: 'flex' }}>
        <Box sx={{ flexGrow: 1 }}>
          <TagInfo
            tagSourceType="behavior"
            values={tagValues}
            onChange={tagInfo =>
              setTagValues(prev => ({
                ...prev,
                ...tagInfo,
                schedulingType: (tagInfo as TagInfoValues<'behavior'>)
                  .schedulingType,
              }))
            }
          />
          <SectionTitle title={t('tag:create_step_3_select_tag_source_rule')} />
          <TagSingleMeasure
            onDispatch={onDispatch}
            measureFilterState={state}
            isDisabled={isLoadingTag}
          />

          <TagAigcProduct
            isDisabled={isLoadingTag}
            relatedAllAigcTarget={
              tagValues.autoAigcWithEventRule?.relatedAllAigcTarget ?? false
            }
            relatedAigcTargetIds={
              tagValues.autoAigcWithEventRule?.relatedAigcTargetIds ?? []
            }
            onProductUpdate={handleAigcProductUpdate}
          />
        </Box>

        {/** sticky 需要 parent 可以滾動，因此將 marginLeft 另外寫 */}
        <Box sx={{ ml: 2 }}>
          <Box sx={{ position: 'sticky', top: AUDIENCE_PREVIEWER_STICKY_TOP }}>
            <TotalCountPreviewer
              title={t('tag:total_tagged')}
              userCount={previewData?.taggedUserCount ?? 0}
              isLoading={isFetchingPreview}
              isCreating={isCreating}
              onPreview={handleTagPreview}
              onCreate={handleTagCreate}
            >
              <AudiencePreviewerField>
                <p>{t('tag:total')}</p>
                <p>
                  {isFetchingPreview
                    ? '-'
                    : formatNumber(previewData?.taggedUserCount ?? 0)}
                </p>
              </AudiencePreviewerField>
            </TotalCountPreviewer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Behavior
