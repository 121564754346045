import theme from '@theme'
export const AUDIENCE_PREVIEWER_STICKY_TOP = 88

export const widthOfDonut = 200
// 實際高度會由 ApexChart 控制
export const heightOfDonut = 200
export const radialBarChartConfig = {
  plotOptions: {
    radialBar: {
      hollow: { size: '70%' },
      dataLabels: { show: false },
    },
  },
  colors: [theme.colors.brightBlue],
}
