import { SxProps } from '@mui/material'

import { ICON, IconClassName } from '@shared/model/constants/styles'
import theme, { ThemeColor } from '@theme'

import type { UserJourneyEventType } from './types'

export const EVENT_ICON_MAP: Record<
  UserJourneyEventType,
  { icon: IconClassName; color: ThemeColor; sx?: SxProps<typeof theme> }
> = {
  add_to_cart: { icon: ICON.shoppingCart, color: theme.colors.chartPeriwinkle },
  campaign_events: {
    icon: ICON.codeBranch,
    color: theme.colors.deepSkyBlueThree,
    sx: { rotate: '180deg' },
  },
  click: { icon: ICON.mouse, color: theme.colors.brightBlue },
  file_download: { icon: ICON.import, color: theme.colors.brightBlue },
  first_visit: { icon: ICON.eye, color: theme.colors.brightBlue },
  form_start: { icon: ICON.fileEditAlt, color: theme.colors.brightBlue },
  form_submit: { icon: ICON.fileCheckAlt, color: theme.colors.brightBlue },
  generate_lead: { icon: ICON.userSquare, color: theme.colors.brightBlue },
  page_view: { icon: ICON.browser, color: theme.colors.brightBlue },
  purchase: { icon: ICON.shoppingBag, color: theme.colors.chartPeriwinkle },
  scroll: {
    icon: ICON.capture,
    color: theme.colors.brightBlue,
    sx: { rotate: '45deg' },
  },
  select_content: {
    icon: ICON.documentLayoutLeft,
    color: theme.colors.brightBlue,
  },
  video_complete: { icon: ICON.play, color: theme.colors.chartRed },
  video_progress: { icon: ICON.play, color: theme.colors.chartRed },
  video_start: { icon: ICON.play, color: theme.colors.chartRed },
  view_item_list: { icon: ICON.box, color: theme.colors.chartPeriwinkle },
  view_item: { icon: ICON.box, color: theme.colors.chartPeriwinkle },
  view_search_results: { icon: ICON.search, color: theme.colors.brightBlue },
}

export const EVENT_PROPERTY_IDS_DEFAULT_MAP: Record<
  UserJourneyEventType,
  string[]
> = {
  add_to_cart: ['dimension_ga_eec_items.name'],
  click: ['events.link_url'],
  file_download: ['events.file_name'],
  first_visit: ['events.campaign', 'events.source', 'events.medium'],
  form_start: ['events.page_title', 'events.form_name'],
  form_submit: ['events.page_title', 'events.form_name'],
  generate_lead: ['events.page_title', 'events.page_location'],
  page_view: [
    'events.page_title',
    'events.page_location',
    'events.campaign',
    'events.source',
    'events.medium',
  ],
  purchase: ['dimension_ga_eec_items.price'],
  scroll: [
    'events.page_title',
    'events.page_location',
    'events.percent_scrolled',
  ],
  select_content: ['events.content_type'],
  video_complete: ['events.video_title'],
  video_progress: ['events.video_title', 'events.video_percent'],
  video_start: ['events.video_title'],
  view_item: ['dimension_ga_eec_items.name'],
  view_item_list: ['events.item_list_name'],
  view_search_results: ['events.search_term'],
  campaign_events: [
    'workflows.name',
    'campaigns.title',
    'campaign_events.action',
  ],
}
