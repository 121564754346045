import { TAG_RULE_MAX_COUNT } from '@widgets/tag/_shared/components/TagRule/TagRule'

/**
 * 固定的 borderRadius 會造成當規則太多時看不出圓角或規則太少圓角破版
 * 採根據比例計算，當規則越少時圓角越大，最小則是 4px
 * 24 (筆規則) -> 4 (px)
 * 1 (筆規則) -> 12 (px)
 *
 * 最大最小的值是魔術數字，盡量在極端情境也能夠容易辨識
 *
 * @param dataLength 介於 0 ~ 24 (標籤規則最大數量) 的數字
 * @returns 介於 4 ~ 12 的數字
 */
export const getBarBorderRadius = (dataLength: number) => {
  const min = 4
  const max = 12

  const ratio = (max - min) / TAG_RULE_MAX_COUNT
  return Math.floor(max - ratio * dataLength)
}

/**
 * 固定的 barHeight 會造成規則太少 bar 太寬的問題
 *
 * 因為目前是採用水平的 barChart，當設定 `50%` 在 24 筆資料時看起來很正常，
 * 但只有 1 筆資料時，bar 會呈現 500px (圖表高度) * 50%，這麼寬
 *
 * 因此根據比例計算，當規則越少時 barHeight 越小，最大則是 60%
 * 24 (筆規則) -> '60%'
 * 1 (筆規則) -> '15%'
 *
 * 最大最小的值是魔術數字，盡量在極端情境也能夠容易辨識
 *
 * @param dataLength 介於 0 ~ 24 (標籤規則最大數量) 的數字
 * @returns 介於 '15%' ~ '60%' 的字串
 */
export const getBarHeightPercent = (dataLength: number) => {
  const min = 15
  const max = 60

  const ratio = dataLength / TAG_RULE_MAX_COUNT
  return `${Math.floor(min + ratio * (max - min))}%`
}
