import { api, transformResponseCamelCaseKeys } from '../api'
import type {
  AudienceRuleUserExportCSV,
  AudienceRuleUserExportFacebookAds,
  AudienceRuleUserExportGoogleAds,
  AudienceRuleUserExportIterable,
  AudienceRuleUserExportMAAC,
  AudienceRuleUserExportMATool,
  AudienceRuleUserExportSms,
  AudienceRuleUserExportSuper8,
  ExportAudienceRuleUserFacebookAds,
  ExportAudienceRuleUserGoogleAds,
  ExportAudienceRuleUserMAToolPayload,
  ExportAudienceRuleUserSmsPayload,
  GetAudienceRuleUserExportPayload,
  GoogleAdsCustomer,
  Mms,
  RetryLatestExportSmsPayload,
  Sms,
} from './type'
import { combineExportAudienceRuleId } from './utils'

const exporter = api.injectEndpoints({
  endpoints: builder => ({
    exportAudienceRuleUserCSV: builder.mutation<
      AudienceRuleUserExportCSV,
      number
    >({
      query: id => ({
        url: '/audience_rule_user_csv_exports',
        method: 'POST',
        body: { audience_rule_id: id },
      }),
      transformResponse: (res: AudienceRuleUserExportCSV) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [{ type: 'ExportCSV', id: arg }],
    }),
    getAudienceRuleUserLatestExportCSV: builder.query<
      AudienceRuleUserExportCSV | null,
      number
    >({
      query: audienceRuleId => ({
        url: `/audience_rule_user_csv_exports/latest?audience_rule_id=${audienceRuleId}`,
      }),
      transformResponse: (res: AudienceRuleUserExportCSV) =>
        transformResponseCamelCaseKeys(res),
      providesTags: (result, error, arg) => [{ type: 'ExportCSV', id: arg }],
    }),
    exportAudienceRuleUserIterable: builder.mutation<
      AudienceRuleUserExportIterable,
      ExportAudienceRuleUserMAToolPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_iterable_exports',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportIterable) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [
        { type: 'ExportIterable', id: arg.audienceRuleId },
      ],
    }),
    getAudienceRuleUserLatestExportIterable: builder.query<
      AudienceRuleUserExportIterable | null,
      GetAudienceRuleUserExportPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_iterable_exports/latest',
        params: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportIterable) =>
        transformResponseCamelCaseKeys(res),
      providesTags: (result, error, arg) => [
        { type: 'ExportIterable', id: arg.audienceRuleId },
        { type: 'ExportIterable', id: result?.id },
      ],
    }),
    cancelAutoExportIterable: builder.mutation<void, number>({
      query: exportId => ({
        url: `/audience_rule_user_iterable_exports/${exportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ExportIterable', id: arg },
      ],
    }),
    exportAudienceRuleUserMAAC: builder.mutation<
      AudienceRuleUserExportMAAC,
      ExportAudienceRuleUserMAToolPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_maac_exports',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportMAAC) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'ExportAudienceRule',
          id: combineExportAudienceRuleId(
            arg.audienceRuleId,
            arg.integrationId
          ),
        },
      ],
    }),
    cancelAutoExportMAAC: builder.mutation<void, number>({
      query: exportId => ({
        url: `/audience_rule_user_maac_exports/${exportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ExportAudienceRule', id: arg },
      ],
    }),
    exportAudienceRuleUserSuper8: builder.mutation<
      AudienceRuleUserExportSuper8,
      ExportAudienceRuleUserMAToolPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_super8_exports',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportSuper8) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'ExportAudienceRule',
          id: combineExportAudienceRuleId(
            arg.audienceRuleId,
            arg.integrationId
          ),
        },
      ],
    }),
    cancelAutoExportSuper8: builder.mutation<void, number>({
      query: exportId => ({
        url: `/audience_rule_user_super8_exports/${exportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'ExportAudienceRule', id: arg },
      ],
    }),
    exportAudienceRuleUserEvery8d: builder.mutation<
      AudienceRuleUserExportSms,
      ExportAudienceRuleUserSmsPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_every8d_exports',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportSms) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'ExportAudienceRule',
          id: combineExportAudienceRuleId(
            arg.audienceRuleId,
            arg.integrationId
          ),
        },
        { type: 'ExportAudienceRule', id: result?.id },
      ],
    }),
    retryLatestExportEvery8d: builder.mutation<
      AudienceRuleUserExportSms,
      RetryLatestExportSmsPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_every8d_exports',
        method: 'POST',
        body: { ...payload, is_retry: true },
      }),
      transformResponse: (res: AudienceRuleUserExportSms) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'ExportAudienceRule',
          id: combineExportAudienceRuleId(
            arg.audienceRuleId,
            arg.integrationId
          ),
        },
      ],
    }),
    getEvery8dCredit: builder.query<number, { integrationId: number }>({
      query: payload => ({
        url: '/every8d/credit',
        params: payload,
      }),
      transformResponse: (data: { credit: number }) => data.credit,
    }),
    getSmsCredit: builder.query<number, { integrationId: number }>({
      query: payload => ({
        url: '/sms/credit',
        params: payload,
      }),
      transformResponse: (data: { credit: number }) => data.credit,
    }),
    // TODO - 因為 mitake 實作在 third party gateway 上，所以已經統一改用 sms api 路徑，payload 都是相同的，未來 BE 把 every8d 搬進去後就可以改用新路徑。
    sendEvery8dSms: builder.mutation<void, Sms>({
      query: body => ({
        url: `/every8d/testing_sms`,
        method: 'POST',
        body,
      }),
    }),
    sendSmsTest: builder.mutation<void, Sms>({
      query: body => ({
        url: `/sms/test_send`,
        method: 'POST',
        body,
      }),
    }),
    sendEvery8dMms: builder.mutation<void, Mms>({
      query: body => ({
        url: `/every8d/testing_mms`,
        method: 'POST',
        body,
      }),
    }),
    exportAudienceRuleUserGoogleAds: builder.mutation<
      AudienceRuleUserExportGoogleAds,
      ExportAudienceRuleUserGoogleAds
    >({
      query: ({ googleAccessToken, googleRefreshToken, ...payload }) => ({
        url: '/audience_rule_user_google_ads_exports',
        method: 'POST',
        headers: {
          'GOOGLE-ACCESS-TOKEN': googleAccessToken,
          'GOOGLE-REFRESH-TOKEN': googleRefreshToken,
        },
        body: payload,
      }),
      transformResponse: (res: AudienceRuleUserExportGoogleAds) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: ['ExportGoogleAds'],
    }),
    getAudienceRuleUserExportListGoogleAds: builder.query<
      AudienceRuleUserExportGoogleAds[],
      number
    >({
      query: audienceRuleId => ({
        url: `/audience_rule_user_google_ads_exports/latest?audience_rule_id=${audienceRuleId}`,
      }),
      transformResponse: (res: AudienceRuleUserExportGoogleAds[]) =>
        transformResponseCamelCaseKeys(res ?? []),
      providesTags: ['ExportGoogleAds'],
    }),
    cancelAutoExportGoogleAds: builder.mutation<void, number>({
      query: exportId => ({
        url: `/audience_rule_user_google_ads_exports/${exportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ExportGoogleAds'],
    }),
    getGoogleAdsCustomerList: builder.query<GoogleAdsCustomer[], string>({
      query: googleAccessToken => ({
        url: '/google_ads/customers',
        headers: {
          'GOOGLE-ACCESS-TOKEN': googleAccessToken,
        },
      }),
      transformResponse: (res: GoogleAdsCustomer[]) =>
        transformResponseCamelCaseKeys(res ?? []),
    }),
    exportAudienceRuleUserFacebookAds: builder.mutation<
      AudienceRuleUserExportFacebookAds,
      ExportAudienceRuleUserFacebookAds
    >({
      query: ({ facebookAccessToken, ...otherPayload }) => ({
        url: '/audience_rule_user_facebook_ads_exports',
        method: 'POST',
        headers: {
          'FACEBOOK-ACCESS-TOKEN': facebookAccessToken,
        },
        body: otherPayload,
      }),
      transformResponse: (res: AudienceRuleUserExportFacebookAds) =>
        transformResponseCamelCaseKeys(res),
      invalidatesTags: ['ExportFacebookAds'],
    }),
    getAudienceRuleUserExportListFacebookAds: builder.query<
      AudienceRuleUserExportFacebookAds[],
      { audienceRuleId: number; facebookAccessToken: string }
    >({
      query: ({ audienceRuleId, facebookAccessToken }) => ({
        url: `/audience_rule_user_facebook_ads_exports/latests?audience_rule_id=${audienceRuleId}`,
        headers: {
          'FACEBOOK-ACCESS-TOKEN': facebookAccessToken,
        },
      }),
      transformResponse: (res: AudienceRuleUserExportFacebookAds[]) =>
        transformResponseCamelCaseKeys(res ?? []),
      providesTags: ['ExportFacebookAds'],
    }),
    getAudienceRuleUserLatestExport: builder.query<
      AudienceRuleUserExportMATool | AudienceRuleUserExportSms,
      GetAudienceRuleUserExportPayload
    >({
      query: payload => ({
        url: '/audience_rule_user_exports',
        params: payload,
      }),
      transformResponse: (
        res: (AudienceRuleUserExportMATool | AudienceRuleUserExportSms)[]
      ) => transformResponseCamelCaseKeys((res || [])[0]),
      providesTags: (result, error, arg) => [
        {
          type: 'ExportAudienceRule',
          id: combineExportAudienceRuleId(
            arg.audienceRuleId,
            arg.integrationIds
          ),
        },
        { type: 'ExportAudienceRule', id: result?.id },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCancelAutoExportGoogleAdsMutation,
  useCancelAutoExportIterableMutation,
  useCancelAutoExportMAACMutation,
  useCancelAutoExportSuper8Mutation,
  useExportAudienceRuleUserCSVMutation,
  useExportAudienceRuleUserEvery8dMutation,
  useExportAudienceRuleUserFacebookAdsMutation,
  useExportAudienceRuleUserGoogleAdsMutation,
  useExportAudienceRuleUserIterableMutation,
  useExportAudienceRuleUserMAACMutation,
  useExportAudienceRuleUserSuper8Mutation,
  useGetAudienceRuleUserExportListFacebookAdsQuery,
  useGetAudienceRuleUserExportListGoogleAdsQuery,
  useGetAudienceRuleUserLatestExportCSVQuery,
  useGetAudienceRuleUserLatestExportIterableQuery,
  useGetAudienceRuleUserLatestExportQuery,
  useGetEvery8dCreditQuery,
  useGetGoogleAdsCustomerListQuery,
  useRetryLatestExportEvery8dMutation,
  useSendEvery8dMmsMutation,
  useSendEvery8dSmsMutation,
  useGetSmsCreditQuery,
  useSendSmsTestMutation,
} = exporter
