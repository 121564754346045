import {
  INITIAL_STATE_EVENT,
  INITIAL_STATE_USER_PROFILE,
  INITIAL_STATE_USER_PROFILE_FROM_USERS,
  useMetadataEventEntityQuery,
  useMetadataUserProfileEntityQuery,
} from '@shared/api/rtkQuery/metadata'

export const useMetadataQuery = () => {
  const {
    data: eventEntity = INITIAL_STATE_EVENT,
    isLoading: isLoadingEventEntity,
    isError: isErrorEventEntity,
  } = useMetadataEventEntityQuery()

  const {
    data: userEntity = {
      userProfileEntity: INITIAL_STATE_USER_PROFILE_FROM_USERS,
      userThirdPartyTagEntity: INITIAL_STATE_USER_PROFILE,
      userProfileIntelligence: INITIAL_STATE_USER_PROFILE,
      userProfileDimension: INITIAL_STATE_USER_PROFILE,
      userAutoTagEntity: INITIAL_STATE_USER_PROFILE,
      userBehaviorTagEntity: INITIAL_STATE_USER_PROFILE,
    },
    isLoading: isLoadingUserProfileEntity,
    isError: isErrorUserProfileEntity,
  } = useMetadataUserProfileEntityQuery()

  return {
    eventEntity,
    userProfileEntity: userEntity.userProfileEntity,
    userThirdPartyTagEntity: userEntity.userThirdPartyTagEntity,
    userProfileIntelligence: userEntity.userProfileIntelligence,
    userProfileDimension: userEntity.userProfileDimension,
    userAutoTagEntity: userEntity.userAutoTagEntity,
    userBehaviorTagEntity: userEntity.userBehaviorTagEntity,
    isLoading: isLoadingEventEntity || isLoadingUserProfileEntity,
    isErrorUserProfileEntity,
    isErrorEventEntity,
  }
}

export default useMetadataQuery
