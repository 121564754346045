import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'

import { FieldGrid } from '@widgets/workflow/_shared/nodes/_shared/styles'

import type { OmnichatConfig } from '../../actionSendLineNodeSchema'
import OmnichatGroupSelect from './OmnichatGroupSelect'
import OmnichatTemplateSelect from './OmnichatTemplateSelect'

type Props = {
  omnichatTemplateConfig?: OmnichatConfig
  integrationId: number
  isEditable: boolean
  setTemplateConfig: (config: OmnichatConfig) => void
  errors: Record<keyof OmnichatConfig, string>
  touched: Record<keyof OmnichatConfig, boolean>
}

export const Omnichat = memo(
  ({
    errors,
    touched,
    omnichatTemplateConfig,
    integrationId,
    isEditable,
    setTemplateConfig,
  }: Props) => {
    const { t } = useTranslation(['workflow'])

    const handleSetGroupId = (groupId: string) =>
      setTemplateConfig({ groupId, templateId: '' })

    const handleSetTemplateId = (templateId: string) =>
      setTemplateConfig({
        groupId: omnichatTemplateConfig?.groupId ?? '',
        templateId,
      })

    return (
      <>
        <FieldGrid mb={2} isHideBorder>
          <Box fontWeight="medium">
            {t('workflow:node_action_line_template')}
          </Box>
          <OmnichatGroupSelect
            groupId={omnichatTemplateConfig?.groupId ?? ''}
            integrationId={integrationId}
            isEditable={isEditable}
            setGroupId={handleSetGroupId}
            isError={touched?.groupId && Boolean(errors?.groupId)}
          />
        </FieldGrid>
        <FieldGrid mb={2} isHideBorder>
          <Box />
          <OmnichatTemplateSelect
            groupId={omnichatTemplateConfig?.groupId}
            templateId={omnichatTemplateConfig?.templateId ?? ''}
            integrationId={integrationId}
            isEditable={isEditable}
            setTemplateId={handleSetTemplateId}
            isError={touched?.templateId && Boolean(errors?.templateId)}
          />
        </FieldGrid>
      </>
    )
  }
)

export default Omnichat
