import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import debounce from 'lodash/debounce'
import pipe from 'lodash/fp/pipe'

import type { EventRuleTimeRangeUnit } from '@shared/api/rtkQuery'
import { getIntSafe } from '@shared/lib/utils/number'
import type { NumberOrString } from '@shared/lib/utils/type'
import {
  BarChart,
  BarChartDisplayType,
  ChartData,
  COLORS,
  EventChartType,
  EventRulePieChart,
  getLongestSeriesLenIdx,
  LineChart,
} from '@shared/ui/charts'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import RangeSlider from '@shared/ui/RangeSlider'
import theme from '@theme'

import {
  CONTROL_SECTION_HEIGHT,
  CONTROL_SECTION_WIDTH,
  EVENT_CHART_HEIGHT,
  EVENT_CHART_MARGIN_RIGHT,
  EVENT_CHART_VISIBILITY_LIST_HEIGHT,
  TOP_N_OPTIONS,
} from './models/constants'
import BarChartDisplayTypeRadioButtons from './BarChartDisplayTypeRadioButtons'
import DateAndUnitControl from './DateAndUnitControl'
import EventChartTypeRadioButtons from './EventChartTypeRadioButtons'
import PieChartIndicatorList from './PieChartIndicatorList'
import {
  Card,
  Container,
  HorizontalLine,
  Loading,
  LoadingWrapper,
} from './styles'
import { checkDataExist, getChartRangeData } from './utils'
import VisibilityList from './VisibilityList'

const UPDATE_CHART_RANGE_DATA_DEBOUNCE_TIME = 300

type EventRuleChartProps = {
  barChartDisplayType?: BarChartDisplayType
  chartData?: ChartData[]
  chartType: EventChartType
  eventCount: number
  isLoading: boolean
  onBarChartTypeChange: (barChartType: BarChartDisplayType) => void
  onChartTypeChanged: (chartType: EventChartType) => void
  onTimeRangeParamsChange: (timeRangeParams: NumberOrString[]) => void
  onTimeRangeParamsComparedChange: (timeRangeParams?: NumberOrString[]) => void
  onTopNChange: (count: number) => void
  onUnitChange: (unit: EventRuleTimeRangeUnit) => void
  timeRangeParams: NumberOrString[]
  timeRangeParamsCompared?: NumberOrString[]
  topN: number
  unit: EventRuleTimeRangeUnit
}

const EventRuleChart = ({
  barChartDisplayType = 'bar',
  chartData = [],
  chartType,
  eventCount,
  isLoading,
  onBarChartTypeChange,
  onChartTypeChanged,
  onTimeRangeParamsChange,
  onTimeRangeParamsComparedChange,
  onTopNChange,
  onUnitChange,
  timeRangeParams,
  timeRangeParamsCompared,
  topN,
  unit,
}: EventRuleChartProps) => {
  const { t, i18n } = useTranslation('common')

  const [visibilityFlags, setVisibilityFlags] = useState<boolean[]>([])
  const [range, setRange] = useState<number[]>([0, 100])
  const [chartRangeData, setChartRangeData] = useState<ChartData[]>(chartData)

  const updateChartRangeDataDebounced = useMemo(
    () =>
      debounce(([start, end]: number[], data: ChartData[]) => {
        pipe(getChartRangeData(start, end), setChartRangeData)(data)
      }, UPDATE_CHART_RANGE_DATA_DEBOUNCE_TIME),
    []
  )

  const handleChartRangeDataChanged = (
    event: Event,
    newRange: number | number[]
  ) => {
    if (!Array.isArray(newRange)) {
      return
    }

    setRange(newRange)
    updateChartRangeDataDebounced(newRange, chartData)
  }

  useEffect(() => {
    if (chartData.length <= 0) {
      setChartRangeData([])
      return
    }

    const { maxLen } = getLongestSeriesLenIdx(chartData)
    const defaultVisibilityFlags = Array(maxLen).fill(true)

    setVisibilityFlags(defaultVisibilityFlags)
    setChartRangeData(chartData)
  }, [chartData])

  const topNMaxCount = Math.floor(COLORS.length / getIntSafe(eventCount, 1))
  const topNOptions: Option[] = TOP_N_OPTIONS.map(({ label, value }) => ({
    label: t(label, { num: value }) as string,
    value,
    disabled: value > topNMaxCount,
  }))

  const colors = COLORS.map((color, idx) =>
    visibilityFlags[idx] ? color : undefined
  ).filter(Boolean) as string[]

  /*
    最後要放在 chart 上的資料會受到兩個因素控制
    - chartRangeData 決定 x 軸顯示資料的範圍
    - visibilityFlags 決定顯示 grouped 資料的範圍
  */
  const chartDisplayData = useMemo(
    () =>
      chartRangeData.map(obj => ({
        categories: obj.categories,
        series: obj.series
          .map((data, idx) => (visibilityFlags[idx] ? data : undefined))
          .filter(Boolean) as ChartData['series'],
      })),
    [chartRangeData, visibilityFlags]
  )

  return (
    <Container>
      <LoadingWrapper isDisabled={isLoading}>
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <EventChartTypeRadioButtons
              chartType={chartType}
              onChartTypeChanged={onChartTypeChanged}
              hasTimeRangeParamsCompared={Boolean(timeRangeParamsCompared)}
            />

            <DateAndUnitControl
              chartType={chartType}
              timeRangeParams={timeRangeParams}
              timeRangeParamsCompared={timeRangeParamsCompared}
              unit={unit}
              onTimeRangeParamsChange={onTimeRangeParamsChange}
              onTimeRangeParamsComparedChange={onTimeRangeParamsComparedChange}
              onUnitChange={onUnitChange}
            />
          </Box>

          <HorizontalLine />

          <Box
            sx={{
              display: 'flex',
              height: CONTROL_SECTION_HEIGHT,
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mr: 3,
                }}
              >
                {chartType === 'bar_chart' && (
                  <BarChartDisplayTypeRadioButtons
                    displayType={barChartDisplayType}
                    onDisplayTypeChange={onBarChartTypeChange}
                  />
                )}
              </Box>
            </Box>

            <Box sx={{ flexBasis: CONTROL_SECTION_WIDTH }}>
              <DropdownList
                key={`${i18n.language}_${topN}_${topNMaxCount}`}
                defaultOption={topNOptions.find(
                  x => x.value === topN && x.disabled === false
                )}
                options={topNOptions}
                onValueChanged={option => {
                  onTopNChange(option.value as number)
                }}
                uniqueId={`chart-item-count-dropdown`}
                bgColor={theme.colors.bgPrimaryGrey}
                isFullWidth
              />
            </Box>
          </Box>

          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'relative',
                // 讓圖表與其他元件的視覺對齊
                top: -12,
                height: EVENT_CHART_HEIGHT,
                // 開合側欄會觸發 ApexChart 重新渲染，寫固定寬度會造成顯示異常，故用 calc 動態計算
                width: `calc(100% - ${
                  CONTROL_SECTION_WIDTH + EVENT_CHART_MARGIN_RIGHT
                }px)`,
              }}
            >
              {chartType === 'line_chart' && (
                <LineChart data={chartDisplayData} colors={colors} />
              )}

              {chartType === 'bar_chart' && (
                <BarChart
                  // BarChart 不支援對比時間
                  data={chartDisplayData[0]}
                  colors={colors}
                  displayType={barChartDisplayType}
                />
              )}

              {chartType === 'pie_chart' && (
                <EventRulePieChart
                  // PieChart 不支援對比時間
                  data={chartDisplayData[0]}
                  colors={colors}
                  // 圓餅圖內建篩選器，往上與頁面其他元件對齊
                  containerSx={{ position: 'relative', top: -32 }}
                >
                  {({ indicatorList }) => (
                    <PieChartIndicatorList indicatorList={indicatorList} />
                  )}
                </EventRulePieChart>
              )}

              {chartType !== 'pie_chart' && checkDataExist(chartData) && (
                <RangeSlider
                  data={chartData}
                  rangeValue={range}
                  handleDisplayDataRangeChanged={handleChartRangeDataChanged}
                />
              )}
            </Box>

            <Box
              sx={{
                width: CONTROL_SECTION_WIDTH,
                position: 'absolute',
                top: 0,
                right: 0,
              }}
            >
              <Box
                sx={{
                  borderTop: theme => `2px solid ${theme.colors.black6}`,
                  pt: 1,
                  mt: 2,
                  height: EVENT_CHART_VISIBILITY_LIST_HEIGHT,
                  overflowY: 'scroll',
                  backgroundColor: theme => theme.colors.white,
                }}
              >
                <VisibilityList
                  data={chartData}
                  visibilityFlags={visibilityFlags}
                  setVisibilityFlags={setVisibilityFlags}
                />
              </Box>
            </Box>
          </Box>
        </Card>
      </LoadingWrapper>

      <Loading isShow={isLoading} />
    </Container>
  )
}

export default memo(EventRuleChart)
