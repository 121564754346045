import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import Box from '@mui/material/Box'

import {
  AggregatorOperator,
  AggregatorOperatorVariant,
  AggregatorParameters,
  DIMENSION_SOURCE_PREFIX,
  MeasureAggregator,
} from '@shared/api/rtkQuery'
import { useMetadataQuery } from '@shared/lib/utils/metadata'
import { ICON, Z_INDEX } from '@shared/model/constants/styles'
import DropdownList, { Option } from '@shared/ui/Dropdown/DropdownList'
import NestedList, { NestedOption } from '@shared/ui/Dropdown/NestedList'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import AggregatorValueInput from './AggregatorValueInput'
import {
  aggregatorOperatorMap,
  dataTypeExtraAggregatorsMap,
  defaultAggregators,
  parseUIOperator,
} from './utils'

const AnchorContainer = styled.div<{ isFullWidth?: boolean }>`
  display: inline-block;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'auto')};
`

type Props = {
  field: string
  aggregator: MeasureAggregator | ''
  operator: AggregatorOperator | ''
  values: AggregatorParameters
  eventName: string
  isDisabled: boolean
  onAggregatorChange: (payload: {
    field: string
    aggregator: MeasureAggregator
  }) => void
  onOperatorChange: (operator: AggregatorOperator) => void
  onValuesChange: (values: AggregatorParameters) => void
}

export const AggregatorFilter = ({
  field,
  aggregator,
  operator,
  values,
  eventName,
  isDisabled,
  onAggregatorChange,
  onOperatorChange,
  onValuesChange,
}: Props) => {
  const { t } = useTranslation(['audience', 'metadata'])
  const { eventEntity, userProfileEntity, userProfileIntelligence } =
    useMetadataQuery()

  const aggregatorEntity = useMemo<{ [name in MeasureAggregator]: string }>(
    () => ({
      count: t('metadata:aggregator.total'),
      count_distinct: t('metadata:aggregator.total_days'),
      avg: t('metadata:aggregator.average'),
      max: t('metadata:aggregator.max'),
      min: t('metadata:aggregator.min'),
      sum: t('metadata:aggregator.sum'),
    }),
    [t]
  )

  const operatorEntity = useMemo<{ [name in AggregatorOperator]: string }>(
    () => ({
      '=': t('metadata:operator.equal'),
      '!=': t('metadata:operator.is_not_equal'),
      '>': t('metadata:operator.greater_than'),
      '>=': t('metadata:operator.greater_equal_than'),
      '<': t('metadata:operator.less_than'),
      '<=': t('metadata:operator.less_equal_than'),
      between: t('metadata:operator.range'),
      rank_asc: t('metadata:operator.rank_top'),
      rank_desc: t('metadata:operator.rank_last'),
      rank_between: t('metadata:operator.rank_range'),
      rank_asc_percent: t('metadata:operator.rank_top'),
      rank_desc_percent: t('metadata:operator.rank_last'),
      rank_between_percent: t('metadata:operator.rank_range'),
    }),
    [t]
  )

  const aggregatorOptions: NestedOption[] = useMemo(() => {
    const defaultOptions = defaultAggregators.map(x => ({
      label: aggregatorEntity[x],
      value: x,
    })) as NestedOption[]

    if (
      !eventEntity ||
      !userProfileEntity ||
      !userProfileIntelligence ||
      !eventName
    ) {
      return defaultOptions
    }

    const currentEvent = eventEntity.events.entities[eventName]

    const extraAggregatorOptions = currentEvent.propertiesIds
      ?.filter(propertyId => {
        const { dataType, tableName: source } =
          currentEvent.propertiesEntity[propertyId]

        return (
          // 維度表相關的欄位不提供額外 aggregator
          !source.startsWith(DIMENSION_SOURCE_PREFIX) &&
          dataTypeExtraAggregatorsMap[dataType].length > 0
        )
      })
      .map(propertyId => {
        const {
          displayName,
          id: field,
          dataType,
        } = currentEvent.propertiesEntity[propertyId]

        return {
          label: displayName,
          value: field,
          options: dataTypeExtraAggregatorsMap[dataType].map(x => ({
            label: aggregatorEntity[x],
            value: x,
          })),
        }
      }) as NestedOption[]

    return defaultOptions.concat(extraAggregatorOptions || [])
  }, [
    aggregatorEntity,
    eventEntity,
    eventName,
    userProfileEntity,
    userProfileIntelligence,
  ])

  const operatorOptions: Option[] = aggregator
    ? aggregatorOperatorMap[aggregator].map(x => ({
        label: operatorEntity[x],
        value: x,
      }))
    : []

  // UI 會將 `operator` 拆成兩個欄位顯示
  const [baseOperator, variantOperator] = parseUIOperator(operator)
  const variantOperatorOptions: {
    label: string
    value: AggregatorOperatorVariant | ''
  }[] = [
    {
      label: t('audience:v3.th'),
      value: '',
    },
    { label: '%', value: 'percent' },
  ]

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box mb={1} mr={1}>
        <NestedList
          options={aggregatorOptions}
          parentValue={field}
          value={aggregator}
          bgColor={theme.colors.white}
          hasSearchBar
          isDisabled={isDisabled}
          // 由於 disablePortal 後彈窗的位置會被父層的 overflow 限制
          // 且會在 Dialog 使用到 AggregatorFilter，因此改成提高 popperZIndex
          popperZIndex={Z_INDEX.dialog}
          onChange={({ parentValue, value }) => {
            if (parentValue) {
              onAggregatorChange({
                field: parentValue,
                aggregator: value as MeasureAggregator,
              })
              return
            }

            onAggregatorChange({
              field: '',
              aggregator: value as MeasureAggregator,
            })
          }}
        />
      </Box>

      {operatorOptions.length > 0 && (
        <Box mb={1} mr={1}>
          <DropdownList
            options={operatorOptions}
            value={baseOperator}
            bgColor={theme.colors.white}
            disabled={isDisabled}
            popperDisablePortal
            onValueChanged={({ value }) => {
              onOperatorChange(value as AggregatorOperator)
            }}
          />
        </Box>
      )}

      <Box display="flex" alignItems="center" mb={1} mr={1}>
        {aggregator && operator && (
          <Box mr={1}>
            <AnchorContainer>
              <AggregatorValueInput
                operator={operator}
                values={values}
                unitText={
                  aggregator === 'count_distinct' ? t('audience:v3.days') : ''
                }
                bgColor={theme.colors.white}
                isDisabled={isDisabled}
                onChange={onValuesChange}
              />
            </AnchorContainer>
          </Box>
        )}

        {(baseOperator === 'rank_asc' ||
          baseOperator === 'rank_desc' ||
          baseOperator === 'rank_between') && (
          <Box mr={1}>
            <DropdownList
              options={variantOperatorOptions}
              value={variantOperator}
              bgColor={theme.colors.white}
              disabled={isDisabled}
              popperDisablePortal
              onValueChanged={({ value }) => {
                onOperatorChange(
                  (value
                    ? `${baseOperator}_${value}`
                    : baseOperator) as AggregatorOperator
                )
              }}
            />
          </Box>
        )}

        {(operator === 'between' ||
          operator === 'rank_between' ||
          operator === 'rank_between_percent') && (
          <Tooltip title={t('audience:v3.include_range_boundaries')}>
            <Box>
              <Icon
                icon={ICON.infoCircle}
                color={theme.colors.textSecondBlue}
              />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default AggregatorFilter
