import styled from '@emotion/styled'
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton'

import { Z_INDEX } from '@shared/model/constants/styles'
import { Summary as AccordionSummary } from '@shared/ui/Accordion'

export const Wrapper = styled.div`
  position: fixed;
  right: 32px;
  bottom: 32px;

  width: 344px;

  border-radius: 4px;
  z-index: ${Z_INDEX.infoBox};
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.35);
`

export const Content = styled.section`
  overflow-y: scroll;
  max-height: 45vh;
  border-radius: 0 0 4px 4px;
`

export const Summary = styled(AccordionSummary)`
  padding: 0 4px 0 16px;
  cursor: auto;
`

export const SummaryLabel = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
`

export const TaskItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 8px;

  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  background-color: ${({ theme }) => theme.colors.white};
`

export const Filename = styled.div<{ isLongerMaxWidth?: boolean }>`
  overflow: hidden;

  color: ${({ theme }) => theme.colors.textPrimaryBlue};
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const IconButton = styled(
  ({
    marginRightRatio,
    direction,
    ...otherProps
  }: {
    marginRightRatio?: number
    direction?: 'right' | 'down'
  } & IconButtonProps) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiIconButton {...otherProps} />
  )
)`
  transform: ${({ direction }) =>
    direction === 'right' ? 'rotate(0deg)' : 'rotate(90deg)'};

  &.MuiButtonBase-root {
    margin-right: ${({ marginRightRatio }) =>
      typeof marginRightRatio === 'number' ? marginRightRatio * 8 : 0}px;
    margin-left: 4px;
    width: 48px;
    height: 48px;
  }
  &.MuiButtonBase-root:hover {
    background-color: rgba(240, 240, 240, 0.15);
  }
`
