import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import {
  useColon,
  useErrorMsg,
  useResourceTypeText,
  useStatusText,
  useTaskTypeText,
} from '@features/infoBox/lib/hooks'
import type { TaskEntity } from '@features/infoBox/model/taskSlice'
import { ICON } from '@shared/model/constants/styles'
import type {
  IngestionErrorCode,
  ResourceType,
  TagResourceType,
} from '@shared/model/types/ingestion'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { Filename, TaskItem } from './styles'

type Props = {
  status: TaskEntity['status']
  type: TaskEntity['type']
  name: string
  resourceType: ResourceType | TagResourceType
  errorCode?: IngestionErrorCode
}

const TaskBar = ({ status, type, name, resourceType, errorCode }: Props) => {
  const isFailed = status === 'failed'
  const isSucceeded = status === 'succeeded'
  const isProcessing = status !== 'failed' && status !== 'succeeded'

  const taskTypeText = useTaskTypeText(type)
  const resourceTypeText = useResourceTypeText(resourceType)
  const statusText = useStatusText(type, status, errorCode)
  const errorMsg = useErrorMsg(errorCode)

  const colon = useColon()

  return (
    <TaskItem>
      <Box m={1}>
        <Icon
          icon={isFailed ? ICON.fileTimesAlt : ICON.fileAlt}
          color={isFailed ? theme.colors.orangeyRed : theme.colors.brightBlue}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        minWidth="0"
        fontSize={10}
      >
        <Tooltip title={name}>
          <Filename>{name}</Filename>
        </Tooltip>
        <Box color={theme.colors.textSecondBlue}>
          {taskTypeText}
          {colon}
          {resourceTypeText}
        </Box>
        <Box
          color={isFailed ? theme.colors.orangeyRed : theme.colors.brightBlue}
        >
          {statusText}
          {isProcessing && '...'}
          {isFailed && <p>{errorMsg}</p>}
        </Box>
      </Box>

      {isProcessing && (
        <Box m={1}>
          <CircularProgress size={24} />
        </Box>
      )}

      {isSucceeded && (
        <Box m={1}>
          <Icon icon={ICON.checkCircle} color={theme.colors.brightBlue} />
        </Box>
      )}
    </TaskItem>
  )
}

export default TaskBar
