import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControlLabel } from '@mui/material'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { useFormik } from 'formik'

import { ConfirmDialog } from '@shared/ui/dialogs'
import ReadMore from '@shared/ui/ReadMore'

import { REACT_FLOW_NO_DRAG } from '../_shared/constants'
import { useWorkflowFormState } from '../../WorkflowFormStateContext'
import {
  LogicBestChannelNodeData,
  logicBestChannelNodeSchema,
  LogicBestChannelType,
} from './logicBestChannelNodeSchema'

type LogicGroupingNodeEditDialogProps = {
  isOpen: boolean
  initialValues: LogicBestChannelNodeData
  onConfirm: (values: LogicBestChannelNodeData) => void
  onClose: () => void
}

export const LogicBestChannelNodeDialog = ({
  isOpen,
  initialValues,
  onConfirm,
  onClose,
}: LogicGroupingNodeEditDialogProps) => {
  const { t } = useTranslation(['workflow', 'common'])

  const formState = useWorkflowFormState()

  const { values, setFieldValue, handleSubmit } =
    useFormik<LogicBestChannelNodeData>({
      initialValues: {
        email: initialValues.email,
        linePush: initialValues.linePush,
        sms: initialValues.sms,
      },
      enableReinitialize: true,
      validateOnMount: true,
      validationSchema: logicBestChannelNodeSchema,
      onSubmit: async values => {
        if (!formState.isEditable) {
          onClose()
          return
        }

        onConfirm(values)
      },
    })

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    setFieldValue(e.target.name, checked)

  return (
    <ConfirmDialog
      className={REACT_FLOW_NO_DRAG}
      maxWidth="sm"
      isOpen={isOpen}
      modalTitle={t('workflow:node_logic_best_channel.edit_dialog.title')}
      onConfirm={handleSubmit}
      onClose={onClose}
    >
      <Box width="100%">
        <ReadMore
          summary={t('workflow:node_logic_best_channel.edit_dialog.notation')}
          details={t(
            'workflow:node_logic_best_channel.edit_dialog.notation_read_more'
          )}
        />
      </Box>
      <Box
        fontSize={14}
        fontWeight={500}
        my={2}
        sx={theme => ({
          color: theme.colors.textPrimaryBlue,
        })}
      >
        {t('workflow:node_logic_best_channel.edit_dialog.select_channel')}
      </Box>
      <Box display="flex">
        {Object.keys(values).map(channel => (
          <FormControlLabel
            key={channel}
            control={
              <Checkbox
                color="primary"
                name={channel}
                checked={Boolean(values[channel as LogicBestChannelType])}
                disabled={!formState.isEditable}
                onChange={handleOnChange}
              />
            }
            label={t(
              `workflow:node_logic_best_channel.edit_dialog.channel_type.${channel}`
            )}
            disabled={!formState.isEditable}
          />
        ))}
      </Box>
    </ConfirmDialog>
  )
}

export default LogicBestChannelNodeDialog
