import { push } from 'redux-first-history'
import { Epic } from 'redux-observable'
import { EMPTY, of } from 'rxjs'
import { filter, mergeMap, switchMap } from 'rxjs/operators'

import { PAGE_ROOT, PATH_RULES } from '@shared/model/constants/routes'

import { endLoading, handleApiError, setLoading, showDialog } from './slices'

const setLoadingEpic: Epic<RootAction, RootAction, RootState> = (
  actions$,
  state$
) =>
  actions$.pipe(
    filter(setLoading.match),
    switchMap(() => {
      const { pathname = '' } = window.location
      const { settings } = state$.value.v1
      let fetchingStatus = [] as boolean[]
      /* 
        這個狀態是指，所有 flag 完成才結束 loading 還是只要有一個 flag 完成就結束 loading
        類似 Promise.all 及 Promise.race 的差別
      */
      let isWaitAllFlags = true

      if (PATH_RULES.settingsApplicationOverview.test(pathname)) {
        fetchingStatus = [settings.application.isFetching]
      } else if (PATH_RULES.settingApplicationDetail.test(pathname)) {
        fetchingStatus = [settings.application.isDetailFetching]
      }

      if (isWaitAllFlags) {
        return fetchingStatus.every(status => status === false)
          ? of(endLoading())
          : EMPTY
      }

      return fetchingStatus.some(status => status === false)
        ? of(endLoading())
        : EMPTY
    })
  )

const errorHandlerEpic: Epic<RootAction, RootAction, RootState> = actions$ =>
  actions$.pipe(
    filter(handleApiError.match),
    mergeMap(action => {
      const { statusCode, message = '' } = action.payload

      console.log(`api statusCode: ${statusCode}, message: ${message}`)

      switch (statusCode) {
        case 401:
          // 為了避免直接 import entities/auth 的 action，才寫成這個形式
          return of({ type: 'auth/logout' as const, payload: undefined })
        case 403:
          return of(showDialog({ message: 'api_error.403' }))
        case 404:
          return of(push(`/${PAGE_ROOT.error404}`))
        case 500:
          return of(push(`/${PAGE_ROOT.error500}`))
        default:
          return EMPTY
      }
    })
  )

const epics = [errorHandlerEpic, setLoadingEpic]

export default epics
