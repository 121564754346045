import { forwardRef } from 'react'
import styled from '@emotion/styled'
import MuiMenuList, { MenuListProps } from '@mui/material/MenuList'

export const MenuList = styled(
  forwardRef<
    HTMLUListElement,
    {
      height?: number
      width?: number | string
      maxHeight?: number | string
      maxWidth?: number
    } & MenuListProps
  >(({ maxHeight, maxWidth, height, ...otherProps }, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MuiMenuList {...otherProps} ref={ref} />
  ))
)`
  overflow-y: ${({ maxHeight }) =>
    typeof maxHeight === 'number' ? 'scroll' : 'auto'};
  width: ${({ width }) =>
    typeof width === 'number' ? `${width}px` : width || 'auto'};
  max-width: ${({ maxWidth }) =>
    typeof maxWidth === 'number' ? `${maxWidth}px` : 'none'};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : 'auto'};
  max-height: ${({ maxHeight }) =>
    typeof maxHeight === 'number' ? `${maxHeight}px` : 'none'};

  &:focus {
    outline: none;
  }
`

export default MenuList
