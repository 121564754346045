// 因為行為標籤的 scheduling_type 改為 none 和 daily ，為了不要再改在 UpdateMethod，
// 所以這邊另外複製一個 BehaviorUpdateMethod，未來標籤如果會有 refactor 的話，就可以直接改全部。

import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'

import {
  BehaviorTagSchedulingType,
  DATA_SOURCE_TYPE_AUTO_AIGC_RULE,
  DATA_SOURCE_TYPE_IMPORT,
  DataSourceType,
} from '@shared/api/rtkQuery'
import { ICON } from '@shared/model/constants/styles'
import { UniIcon as Icon } from '@shared/ui/icons'
import { Tooltip } from '@shared/ui/tooltips'
import theme from '@theme'

import { InputArea, InputAreaGrid, LabelArea, Text } from '../styles'

export type Props = {
  value: BehaviorTagSchedulingType
  dataSourceType: DataSourceType
  onChange: (value: BehaviorTagSchedulingType) => void
  isEdit?: boolean
}

const BehaviorUpdateMethod = ({
  value,
  dataSourceType,
  onChange,
  isEdit = false,
}: Props) => {
  const { t } = useTranslation(['tag'])

  return (
    <InputAreaGrid>
      <LabelArea>
        <Text>{`${t('tag:update_type')}*`}</Text>
      </LabelArea>

      {dataSourceType === DATA_SOURCE_TYPE_IMPORT && (
        <InputArea>{t('tag:imported_tag_updated_automatically')}</InputArea>
      )}

      {dataSourceType === DATA_SOURCE_TYPE_AUTO_AIGC_RULE && (
        <InputArea>
          <RadioGroup
            row
            aria-label="tag-scheduling-type"
            name="tag-scheduling-type"
            value={value}
            onChange={(_, value) =>
              onChange(value as BehaviorTagSchedulingType)
            }
          >
            <FormControlLabel
              value="none"
              control={<Radio color="primary" size="small" />}
              label={<Box fontSize={14}>{t('tag:manual')}</Box>}
              disabled={!isEdit}
            />

            <Tooltip title={t('tag:must_be_manually_restarted_to_update')}>
              <Box m="auto" ml="-12px" mr="24px">
                <Icon
                  icon={ICON.infoCircle}
                  fontSize="small"
                  color={theme.colors.textPrimaryBlue}
                />
              </Box>
            </Tooltip>

            <FormControlLabel
              value="daily"
              control={<Radio color="primary" size="small" />}
              label={<Box fontSize={14}>{t('tag:regular')}</Box>}
              disabled={!isEdit}
            />

            <Tooltip title={t('tag:will_automatically_update_at')}>
              <Box m="auto" ml="-12px" mr="12px">
                <Icon
                  icon={ICON.infoCircle}
                  fontSize="small"
                  color={theme.colors.textPrimaryBlue}
                />
              </Box>
            </Tooltip>
          </RadioGroup>
        </InputArea>
      )}
    </InputAreaGrid>
  )
}

export default BehaviorUpdateMethod
