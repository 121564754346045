import { api, transformResponseCamelCaseKeys } from '../api'
import type { DateExport } from './exportsType'

const dataExporterExportsApi = api.injectEndpoints({
  endpoints: builder => ({
    getDataExports: builder.query<DateExport, { id: string }>({
      query: params => ({
        url: `/data_exports/${params.id}`,
      }),
      transformResponse: data =>
        transformResponseCamelCaseKeys(data as DateExport),
      providesTags: result =>
        result
          ? [
              { type: 'DataExporterExports', id: result.id },
              { type: 'DataExporterExports', id: 'LIST' },
            ]
          : [{ type: 'DataExporterExports', id: 'LIST' }],
    }),
  }),
})

export const { useGetDataExportsQuery } = dataExporterExportsApi
